import { isAxiosError } from '@seiue/axios'
import { NoneMetaError } from '@seiue/error-handler'
import { useMemo } from 'react'

import { useHasProfilePermissionOfStudent } from 'packages/features/profiles/utils/apis'
import { useHasCurrentPermission } from 'packages/features/roles'
import { useCurrentReflection } from 'packages/features/sessions'
import {
  GroupTypeEnum,
  PermissionNameEnum,
  RoleEnum,
  customGroupApi$loadCustomGroup,
  groupApi$queryGroupsMembers,
} from 'packages/sdks-next/chalk'

import { useIsCustomGroupAdmin } from '../customized-groups/utils/index'

import { GroupPermissionTypeEnum } from './types'

export type GroupType =
  | GroupTypeEnum.AdminclassGroup
  | GroupTypeEnum.ClassGroup
  | GroupTypeEnum.DormGroup
  | `group.${string}` // 主要应对自定义群组类型 `group.{customGroupTypeId}`

const isInGroupTypeEnum = (type?: string) => {
  return Object.values(GroupTypeEnum).includes(type as GroupTypeEnum)
}

// 群组类型与基础权限的对应关系，没有匹配的权限则指定为 CoreUserRead
const getAdminPermissionByGroupType = (type: string | undefined) => {
  if (isInGroupTypeEnum(type)) {
    const permission = {
      [GroupTypeEnum.AdminclassGroup]: PermissionNameEnum.CoreAdminClassRead,
      [GroupTypeEnum.ClassGroup]: PermissionNameEnum.CoreClassRead,
      [GroupTypeEnum.DormGroup]: PermissionNameEnum.CoreDormRead,
    }[type as string]

    if (permission) return permission
  }

  return PermissionNameEnum.CoreUserRead
}

// 群组类型与档案权限的对应关系，没有匹配的权限则指定为 CoreUserRead
const getProfilePermissionByGroupType = (type: string | undefined) => {
  if (isInGroupTypeEnum(type)) {
    const permission = {
      [GroupTypeEnum.AdminclassGroup]: GroupPermissionTypeEnum.GroupAdminClass,
      [GroupTypeEnum.ClassGroup]: GroupPermissionTypeEnum.GroupClass,
      [GroupTypeEnum.DormGroup]: GroupPermissionTypeEnum.GroupDorm,
    }[type as string]

    if (permission) return permission
  }

  return type
}

const ignoreSomeError = (e: unknown) => {
  if (isAxiosError(e, 403) || isAxiosError(e, 404)) {
    return new NoneMetaError({
      report: false,
    })
  }

  return e
}

/**
 * 检查是否可以查看某个群组
 *
 * @param args - 参数
 * @param args.targetRid - 目标用户，如果 targetRid 是别人，那么意味着要以他人视角来看群组，就必须有档案权限
 * @param args.groupType - 群组类型
 * @param args.groupId - 群组 id（自定义群组时使用）
 * @param args.groupBizId - 群组对应的业务 id（一般群组时使用）
 * @param args.disable - 是否禁用相关请求
 * @returns boolean
 */
export const useGroupViewable = ({
  targetRid,
  groupId,
  groupBizId,
  groupType,
  disable,
}: {
  targetRid: number
  groupId?: number
  groupBizId?: number
  groupType?: string
  disable?: boolean
}) => {
  const { id: myRid, role } = useCurrentReflection()

  const isCurrentReflection = !targetRid || targetRid === myRid

  const isNormalGroup = groupType && isInGroupTypeEnum(groupType)
  const isCustomGroup = groupType && !isInGroupTypeEnum(groupType)

  const isGuardian = role === RoleEnum.Guardian
  // 是否是一般群组成员（课程班，行政班，宿舍）
  const { data: groupsMembers } = groupApi$queryGroupsMembers.useApi(
    {
      groupType: groupType as GroupTypeEnum,
      // 如果是家长，以孩子的 id 来查询群组成员
      ownerId: isGuardian ? targetRid : undefined,
      groupBizIdIn: isNormalGroup ? `${groupBizId}` : undefined,
      paginated: 0,
    },
    {
      disable: !isNormalGroup || !groupType || !groupBizId || disable,
    },
  )

  // 是否是通用群组成员
  const { data: customGroup } = customGroupApi$loadCustomGroup.useApi(
    {
      id: groupId || 0,
      query: {
        expand: ['typeEntity', 'members'] as const,
        tryExpand: ['schedules', ['members', 'reflection']],
      },
    },
    {
      disable: !isCustomGroup || !groupType || !groupId || disable,
      catchError: ignoreSomeError,
    },
  )

  // 是组内成员
  const isGroupMember = useMemo(
    () =>
      !!(groupsMembers || [])
        .concat(customGroup?.members || [])
        .find(member => member.memberId === targetRid),
    [customGroup?.members, groupsMembers, targetRid],
  )

  // 是否有用户管理权限
  const hasUserManagerPermission = useHasCurrentPermission({
    permission: PermissionNameEnum.CoreUserRead,
    isManager: true,
  })

  // 是否有群组对应的资源管理权限
  const sourceAdminPermission = getAdminPermissionByGroupType(groupType)

  const hasManagerPermission = useHasCurrentPermission({
    permission: sourceAdminPermission,
    isManager: true,
  })

  // 如果群组是自定义群组，那么检查是否有群组插件的管理权限
  const isCustomGroupAdmin = useIsCustomGroupAdmin(customGroup?.typeEntity)

  // 是否有档案的权限
  const profilePermission = getProfilePermissionByGroupType(groupType)

  const [, hasPermission] = useHasProfilePermissionOfStudent(targetRid)

  const hasGroupReadPermission =
    (isCustomGroup && isCustomGroupAdmin) ||
    (isNormalGroup && hasManagerPermission) ||
    isGroupMember ||
    hasUserManagerPermission

  return isCurrentReflection
    ? hasGroupReadPermission
    : // 如果要看的是别人的群组，那么必须有对方的档案群组权限
      profilePermission &&
        hasPermission(profilePermission) &&
        hasGroupReadPermission
}

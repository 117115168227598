export interface AssessmentAfterthoughtDataInterface {
  /**
   * 总成绩反思数量
   */
  assessmentCount?: number
  /**
   * 评价项反思数量
   */
  itemCount?: number
}

export class AssessmentAfterthoughtData
  implements AssessmentAfterthoughtDataInterface
{
  /**
   * 总成绩反思数量
   */
  assessmentCount?: number
  /**
   * 评价项反思数量
   */
  itemCount?: number

  constructor(props: AssessmentAfterthoughtDataInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      assessmentCount: _assessmentCount,
      itemCount: _itemCount,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.assessmentCount = _assessmentCount
    this.itemCount = _itemCount
  }

  static propKeys = ['assessmentCount', 'itemCount']
}

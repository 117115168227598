/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { CertAdminPolicyEnum } from './cert-admin-policy-enum'
import { Certification, CertificationInterface } from './extends/certification'
import type { QueryCertificationsQuery } from './query-certifications-query'

const buildConfig = createConfigBuilder('sgms')

export const certificationApi$queryCertifications = {
  config(
    policy: CertAdminPolicyEnum,
    schoolPluginId: number,
    queryCertificationsQuery?: QueryCertificationsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/certification/certifications',
      {
        policy,
        schoolPluginId,
      },
      queryCertificationsQuery,
    )
  },

  /**
   * @summary 插件管理员/负责人 - 认证列表
   * 负责人只能看到自己负责的认证
   * @param policy
   * @param schoolPluginId 所属子插件 ID
   * @param [queryCertificationsQuery]
   * @param [certificationQuery.expand]
   * @param [certificationQuery.paginated] 是否分页，默认 1
   * @param [certificationQuery.page] 显示的数据页，默认 1
   * @param [certificationQuery.perPage] 每页显示数量，默认 20
   * @param [certificationQuery.fields]
   * @param [certificationQuery.id]
   * @param [certificationQuery.idIn]
   * @param [certificationQuery.nameLike]
   * @param [certificationQuery.nameIn]
   * @param [certificationQuery.statusIn]
   * @param [certificationQuery.canApply]
   * @param [certificationQuery.adminTypesOverlaps]
   * @param [certificationQuery.certificationTypeIdIn]
   * @param [certificationQuery.adminReflection.account]
   * @param [certificationQuery.adminReflection.usin]
   * @param [certificationQuery.adminReflection.idcard]
   * @param [certificationQuery.adminReflection.outerId]
   * @param [certificationQuery.adminReflection.idIn]
   * @param [certificationQuery.adminReflection.nameIn]
   * @param [certificationQuery.adminReflection.nameLike]
   * @param [certificationQuery.adminReflection.enameIn]
   * @param [certificationQuery.adminReflection.enameLike]
   * @param [certificationQuery.adminReflection.identity] name, usin, ename, pinyin 的模糊搜索
   * @param [certificationQuery.adminReflection.accountLike]
   * @param [certificationQuery.adminReflection.accountIn]
   * @param [certificationQuery.adminReflection.usinLike]
   * @param [certificationQuery.adminReflection.usinIn]
   * @param [certificationQuery.adminReflection.genderIn]
   * @param [certificationQuery.adminReflection.statusIn]
   * @param [certificationQuery.adminReflection.archivedTypeIdIn]
   * @param [certificationQuery.adminReflection.phoneIn]
   * @param [certificationQuery.adminReflection.phoneLike]
   * @param [certificationQuery.adminReflection.emailIn]
   * @param [certificationQuery.adminReflection.emailLike]
   * @param [certificationQuery.adminReflection.idcardIn]
   * @param [certificationQuery.adminReflection.idcardLike]
   * @param [certificationQuery.adminReflection.enteredOnElt]
   * @param [certificationQuery.adminReflection.enteredOnEgt]
   * @param [certificationQuery.adminReflection.workStartOnElt]
   * @param [certificationQuery.adminReflection.workStartOnEgt]
   * @param [certificationQuery.adminReflection.enteredYearsElt]
   * @param [certificationQuery.adminReflection.enteredYearsEgt]
   * @param [certificationQuery.adminReflection.workYearsElt]
   * @param [certificationQuery.adminReflection.workYearsEgt]
   * @param [certificationQuery.adminReflection.adminClassesContains]
   * @param [certificationQuery.adminReflection.adminClassesOverlaps]
   * @param [certificationQuery.adminReflection.adminClassesLike]
   * @param [certificationQuery.adminReflection.dorm]
   * @param [certificationQuery.adminReflection.dormLike]
   * @param [certificationQuery.adminReflection.dormIn]
   * @param [certificationQuery.adminReflection.dormAdminsContains]
   * @param [certificationQuery.adminReflection.dormAdminsOverlaps]
   * @param [certificationQuery.adminReflection.dormAdminsLike]
   * @param [certificationQuery.adminReflection.role]
   * @param [certificationQuery.adminReflection.roleIn]
   * @param [certificationQuery.credit]
   * @param [certificationQuery.creditEgt]
   * @param [certificationQuery.creditElt]
   * @param [certificationQuery.creditIsEmpty]
   * @param [certificationQuery.certReflectionsCountEgt] 未归档的学生已通过的认证记录大于等于
   * @param [certificationQuery.certReflectionsCountElt] 未归档的学生已通过的认证记录大于等于
   * @param [certificationQuery.displayTypeIn] 证书 - 显示形式
   * @param [certificationQuery.levelNameIn] 证书 - 认证级别
   * @param [certificationQuery.subjectIdIn] 证书 - 认证科目
   * @param [options]
   * @return AxiosResponsePromise<Certification[]>
   */

  api<const TQuery extends QueryCertificationsQuery>(
    policy: CertAdminPolicyEnum,
    schoolPluginId: number,
    queryCertificationsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Certification, TQuery['expand']>[]> {
    return axios(
      certificationApi$queryCertifications.config(
        policy,
        schoolPluginId,
        queryCertificationsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Certification(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryCertificationsQuery,
    TSelected = Expand<Certification, TQuery['expand']>[],
  >(
    {
      policy,
      schoolPluginId,
      query,
    }: {
      policy: CertAdminPolicyEnum
      schoolPluginId: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<Certification, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Certification, TQuery['expand']>[], TSelected>(
      certificationApi$queryCertifications.api,
      'Certification.queryCertifications',
      queryOptions,
      policy,
      schoolPluginId,
      query,
    )
  },
}

// @ts-ignore
certificationApi$queryCertifications.api._name_ = `Certification.queryCertifications`

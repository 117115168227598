/**
 * @file Counter 相关 Hooks
 */

import { moment } from '@seiue/moment'
import { compact, useOnUpdate } from '@seiue/util'
import { useCallback, useMemo } from 'react'

import {
  Counter as CounterOrigin,
  counterApi$listMyCounters,
} from 'packages/sdks-next/chalk'
import { useLocalStorageStateV3 } from 'packages/utils/use-local-storage-state-v3'

import { useCurrentReflection } from '../sessions'

interface Counter extends CounterOrigin {
  // 计数器对应的业务 id，仅 subjectEnumWithId 的 Counter 有
  subjectId?: string
}

// 现有支持 Counter 的内容
export enum CounterSubjectEnum {
  PendingTodo = 'pending_todo', // 待办卡片
  UnreadMessage = 'unread_messages', // 未读消息数量
  UnreadNotifications = 'unread_notifications', // 未读通知数量
  ImportantNotifications = 'important_notifications', // 未读重要通知数量
  TotalPermissions = 'total_permissions', // 总权限计数（未实现）
  ForcedEvaluations = 'forced_evaluations', // 未完成强制评教
  ForcedQuestionnaires = 'forced_questionnaires', // 未完成强制问卷
  DiscussionUnread = 'discussion-unread', // 未读留言数量
  DiscussionTeamUnread = 'discussion-team-unread', // 未读团队留言数量
  DiscussionPrivateUnread = 'discussion-private-unread', // 未读私信数量
  ForcedHomeworkSurveyQuestionnaires = 'forced_sz-homework-survey', // 作业调查(无代码应用)未完成强制问卷
}

const counterSubject = Object.values(CounterSubjectEnum)

/**
 * 会以单个 Subject 出现在 Counter 中的资源
 * 比如重要通知，有 N 个重要通知，你将获取到 N 的 Counter（但这些 Counter 的 count 数都是 1），而每个 counter 的 subjectId 对应其业务 Id。
 * 而普通的业务，比如消息数量，则将始终是一个 Counter（但 Counter 的 count 数为消息的数量）
 */
export const subjectEnumWithId = [
  CounterSubjectEnum.ImportantNotifications,
  CounterSubjectEnum.ForcedEvaluations,
  CounterSubjectEnum.ForcedQuestionnaires,
  CounterSubjectEnum.DiscussionUnread,
  CounterSubjectEnum.DiscussionPrivateUnread,
  CounterSubjectEnum.DiscussionTeamUnread,
  CounterSubjectEnum.ForcedHomeworkSurveyQuestionnaires,
]

/**
 * 获取指定的内容的计数器内容
 *
 * @param subject - 指定 Subject
 * @returns 计数器数据
 */
export const useCounters = (subject: CounterSubjectEnum) => {
  const { data, loading, reload, refetching } =
    counterApi$listMyCounters.useApi({
      staleTime: 5, // 缓存一个短时间
    })

  const { id: rid } = useCurrentReflection()

  useOnUpdate(() => {
    reload()
  }, [rid])

  const counterMap = useMemo(() => {
    const result = data?.reduce<{
      [subjectName in CounterSubjectEnum]?: Counter[]
    }>((r, counter) => {
      // 如果在 Subject 之中
      const targetSubject = counterSubject.find(subjectName =>
        counter.subject.includes(subjectName),
      )

      if (targetSubject) {
        // 如果是以独立 Subject 出现的 Counter
        if (subjectEnumWithId.includes(targetSubject)) {
          // 如果 counter 没有计数，那么是无效 counter（后续会被自动清除
          if (!counter.count) return r

          // 如果 counter 也过期，那也不计入
          if (moment().isAfter(counter.expiredAt)) return r

          const subjectId = counter.subject.replace(`${targetSubject}/`, '')

          const counterWithId = {
            ...counter,
            subjectId: subjectId || undefined,
          }

          const targetCounterMapData = r[targetSubject]
          if (!targetCounterMapData) {
            return {
              ...r,
              [targetSubject]: [counterWithId],
            }
          }

          targetCounterMapData.push(counterWithId)

          return r
        }

        return {
          ...r,
          [targetSubject]: [counter],
        }
      }

      return r
    }, {})

    return result || {}
  }, [data])

  const counters = useMemo(
    () => counterMap[subject] || [],
    [counterMap, subject],
  )

  const [skipedCountSubjectIds, setSkipedCountSubjectIds] =
    useLocalStorageStateV3('skipCountSubject', {
      defaultValue: {} as {
        [subject in CounterSubjectEnum]?: {
          [subjectId: string]: boolean
        }
      },
      listenStorageChange: true,
    })

  const skip = useCallback(
    (targetSubject: CounterSubjectEnum, subjectId: string) => {
      setSkipedCountSubjectIds(prev => {
        const newSkipedCountSubjectIds = {
          ...prev,
          [targetSubject]: {
            ...prev?.[targetSubject],
            [subjectId]: true,
          },
        }

        return newSkipedCountSubjectIds
      })
    },
    [setSkipedCountSubjectIds],
  )

  const unskipCounters = useMemo(() => {
    return counters.filter(counter => {
      if (!counter.subjectId) return true

      const targetSubject = counterSubject.find(subjectName =>
        counter.subject.includes(subjectName),
      )

      return !skipedCountSubjectIds?.[targetSubject as CounterSubjectEnum]?.[
        counter.subjectId
      ]
    })
  }, [counters, skipedCountSubjectIds])

  const counterSubjectIds = useMemo(
    () => compact(unskipCounters.map(counter => counter.subjectId)),
    [unskipCounters],
  )

  return {
    counters: unskipCounters,
    counterSubjectIds,
    loading,
    refetching,
    refresh: reload,
    skip,
  }
}

/**
 * @file register evaluation messages
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Evaluation,
  getLabel: () => $t('评教'),
  labelStyle: calculateLabelStyle('#eeecff', '#6a4bbe'),
  messages: [
    'uncompleted_notice_for_student',
    'uncompleted_notice_for_mentor',
    'uncompleted_notice_for_admin_class_teacher',
    { type: 'completed_notice_for_subject', important: true },
  ],
})

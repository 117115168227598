import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import { Attachment, AttachmentInterface } from './attachment'

export interface CommentInterface {
  /**
   * 审批单节点 ID
   */
  flowNodeId: number
  /**
   * expand 评论人
   */
  reflection?: SecuredReflectionInterface
  /**
   * 内容
   */
  content: string
  /**
   * 审批单 ID
   */
  flowId: number
  /**
   * 附件
   */
  attachments?: AttachmentInterface[] | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Comment implements CommentInterface {
  /**
   * 审批单节点 ID
   */
  flowNodeId: number
  /**
   * expand 评论人
   */
  reflection?: SecuredReflection
  /**
   * 内容
   */
  content: string
  /**
   * 审批单 ID
   */
  flowId: number
  /**
   * 附件
   */
  attachments?: Attachment[] | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: CommentInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      flowNodeId: _flowNodeId,
      reflection: _reflection,
      content: _content,
      flowId: _flowId,
      attachments: _attachments,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.flowNodeId = _flowNodeId
    this.reflection = _reflection
      ? new SecuredReflection(_reflection)
      : _reflection
    this.content = _content
    this.flowId = _flowId
    this.attachments = _attachments
      ? _attachments.map(i => new Attachment(i))
      : _attachments
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'flowNodeId',
    'reflection',
    'content',
    'flowId',
    'attachments',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

import { atom } from 'jotai'

import { NoCodeApp } from './app'

/**
 * 当前 App 实例
 */
export const currentAppAtom = atom<NoCodeApp | undefined>(undefined)

/**
 * 已加载的 App 实例
 */
export const loadedAppsAtom = atom<NoCodeApp[]>([])

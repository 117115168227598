import { ExaminerLimitTypeEnum } from './examiner-limit-type-enum'

export interface ExaminerGlobalRulesInterface {
  /**
   * 每场考试监考老师数量
   */
  countPerExamVenue: number
  /**
   * 每场自习监考老师数量
   */
  countPerStudyVenue: number
  /**
   * 每场考试考务教师数量
   */
  countTotalPatroller?: number
  /**
   * 是否显示课程检测冲突
   */
  displayConflicts: boolean
  /**
   * 监考限制方式
   */
  limitType: ExaminerLimitTypeEnum
}

export class ExaminerGlobalRules implements ExaminerGlobalRulesInterface {
  /**
   * 每场考试监考老师数量
   */
  countPerExamVenue: number
  /**
   * 每场自习监考老师数量
   */
  countPerStudyVenue: number
  /**
   * 每场考试考务教师数量
   */
  countTotalPatroller?: number
  /**
   * 是否显示课程检测冲突
   */
  displayConflicts: boolean
  /**
   * 监考限制方式
   */
  limitType: ExaminerLimitTypeEnum

  constructor(props: ExaminerGlobalRulesInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      countPerExamVenue: _countPerExamVenue,
      countPerStudyVenue: _countPerStudyVenue,
      countTotalPatroller: _countTotalPatroller,
      displayConflicts: _displayConflicts,
      limitType: _limitType,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.countPerExamVenue = _countPerExamVenue
    this.countPerStudyVenue = _countPerStudyVenue
    this.countTotalPatroller = _countTotalPatroller
    this.displayConflicts = _displayConflicts
    this.limitType = _limitType
  }

  static propKeys = [
    'countPerExamVenue',
    'countPerStudyVenue',
    'countTotalPatroller',
    'displayConflicts',
    'limitType',
  ]
}

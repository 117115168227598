import { isArray, isString, keyBy } from '@seiue/util'
import { useAtom } from 'jotai'
import React, { useMemo } from 'react'

import { useSelectedSemester } from 'packages/feature-utils/semesters'
import { useGraduateAndGradeWithSemester } from 'packages/features/semesters'
import { useCurrentReflection } from 'packages/features/sessions'
import {
  PupilWithUser,
  Reflection,
  RoleEnum,
  reflectionApi$loadGuardian,
} from 'packages/sdks-next/chalk'
import { Schcal, SchcalScope } from 'packages/sdks-next/scms'

import { schoolCalendarsLoadableAtom, updateCalendarsFlagAtom } from './atoms'

/**
 * 获取当前选中学期所有的校历
 *
 * @returns [data, reload, state]
 */
export const useSchoolCalendars = () => {
  const result = useAtom(schoolCalendarsLoadableAtom)[0]
  const reload = useUpdateSchoolCalendars()

  if (result.state === 'loading' || result.state === 'hasError') {
    return [[], reload, result.state] as const
  }

  return [result.data, reload, result.state] as const
}

/**
 * 更新 store 中的 SchoolCalendars
 *
 * @returns 更新方法
 */
const useUpdateSchoolCalendars = () => {
  const [num, update] = useAtom(updateCalendarsFlagAtom)

  return () => update(num + 1)
}

/**
 * 获取家长完整信息
 *
 * @param param - assignment object
 * @param param.id - 学生 id
 * @param options - api options
 * @param options.disable - 不发请求
 * @returns 家长完整信息
 */
const useCurrentGuardian = (
  {
    id,
  }: {
    id: number
  },
  { disable = false },
) => {
  const { data, loading } = reflectionApi$loadGuardian.useApi(
    {
      id,
      query: {
        tryExpand: ['pupil', 'user', ['pupil', 'user'], ['pupil', 'grade']],
      },
    },
    { disable },
  )

  return { data, loading }
}

/**
 * 获取可见范围内的校历 ids
 * 全校校历默认全员可见，自定义校历根据设置处可见范围进行判断-其中家长可见范围跟随所对应学生
 *
 * @returns 可见范围内的校历 ids
 */
export const useVisibleSchoolCalendarsIds = () => {
  const [schoolCalendars] = useSchoolCalendars()
  const reflection = useCurrentReflection()

  const [selectedSemester] = useSelectedSemester()
  // 年级和届别列表
  const graduationGradePairs = useGraduateAndGradeWithSemester(selectedSemester)
  // 以届别 id 为 key 的 map
  const graduationGradeMap = keyBy(graduationGradePairs, 'graduate.id')

  const { data: currentGuardianInfo } = useCurrentGuardian(
    {
      id: reflection.id,
    },
    { disable: reflection.role !== RoleEnum.Guardian },
  )

  const computedReflection:
    | (Reflection & { graduatesInId?: number })
    | PupilWithUser
    | null
    | undefined = useMemo(() => {
    if (reflection.role === RoleEnum.Guardian) {
      return currentGuardianInfo?.pupil
    }

    return reflection
  }, [currentGuardianInfo?.pupil, reflection])

  return React.useMemo(() => {
    const visibleId = (schoolCalendars as Schcal[])
      ?.filter((c: Schcal) => {
        const reflectionScope: SchcalScope | undefined = c.scopes?.find(
          s => s.role === computedReflection?.role,
        )

        /**
         * 如果没有配置 scope，说明该范围内人员不可见
         */
        if (!reflectionScope) return false
        /**
         * 如果配置了 scope，且该包含该范围内所有人员时，params = []
         */
        if (isArray(reflectionScope?.params)) return true
        let inCondition = true
        Object.entries(reflectionScope?.params || {}).forEach(
          ([key, value]) => {
            if (isString(value)) {
              if (!inCondition) return
              /**
               * 设置处 scope param数据均为 {[`${key}`In]: 'value1,value2,value3...'}, key 为对应 scope 人员相应字段名称
               * 故采用以下逻辑进行判断
               */
              inCondition = value.includes(
                `${
                  computedReflection?.[
                    (key.includes('In')
                      ? key.slice(0, -2)
                      : key) as keyof Reflection
                  ]
                }`,
              )
            }

            // 年级筛选时，value 为 {idIn: ''} 格式，所以增加额外判断
            if (
              key === 'grade' &&
              computedReflection?.role === RoleEnum.Student
            ) {
              if (!inCondition) return
              inCondition = value.idIn
                ?.split(',')
                ?.map(
                  (id: number) =>
                    (
                      selectedSemester?.gradeMaps as { [key: string]: number }
                    )?.[id],
                )
                .join(',')
                .includes(computedReflection?.graduatesInId)
            }

            // 行政班筛选时，value 为 ['xxx 行政班','xxx 行政班+gradeId'] 格式，所以增加额外判断
            if (
              key === 'adminClassesOverlaps' &&
              computedReflection?.role === RoleEnum.Student
            ) {
              const graduationGrade = computedReflection?.graduatesInId
                ? graduationGradeMap[computedReflection?.graduatesInId]
                : undefined

              const adminClassesWithGradeId = (
                computedReflection?.adminClasses || []
              ).map(name =>
                graduationGrade?.grade
                  ? `${name}+${graduationGrade.grade.id}`
                  : name,
              )

              inCondition = value.split(',').some((item: string) => {
                if (/(.*)\+(\d+)/.test(item)) {
                  return adminClassesWithGradeId.includes(item)
                }

                return computedReflection?.adminClasses?.includes(item)
              })
            }
          },
        )

        return inCondition
      })
      .map(item => item.id)

    /**
     * 全校校历默认可见
     */
    return [...visibleId, 0]
  }, [
    schoolCalendars,
    computedReflection,
    selectedSemester?.gradeMaps,
    graduationGradeMap,
  ])
}

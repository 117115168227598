/**
 * @file 注册通用群组消息
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import { registerMessageNavigator } from 'packages/features/messages/utils'

import { AttendanceTypes } from './message-type'

registerMessageNavigator(
  `${MessageDomainEnum.AdminClass}_${AttendanceTypes.Student}`,
  message => {
    const attributes = message.attributes as {
      recordId: number
      bizId: number
      attendanceTimeId: number
    }

    if (!attributes?.bizId || !attributes?.attendanceTimeId) return null

    return {
      path: `/admin-classes/${attributes.bizId}/attendance`,
    }
  },
)

registerMessageNavigator(
  `${MessageDomainEnum.AdminClass}_${AttendanceTypes.Guardian}`,
  message => ({
    modalName: 'MessagesShowModal',
    options: { messageId: message.id },
  }),
)

registerMessageNavigator(
  `${MessageDomainEnum.AdminClass}_${AttendanceTypes.Teacher}`,
  message => {
    const attributes = message.attributes as {
      bizType: string
      bizId: number
      bizName: string
      attendanceTimeId: number
      attendanceTimeStart: string
      attendanceTimeEnd: string
    }

    if (!attributes?.bizId || !attributes?.attendanceTimeId) return null

    return {
      modalName: 'AdminClassInputAttendanceModal',
      options: {
        adminClassId: attributes.bizId,
        startAt: attributes.attendanceTimeStart,
        endAt: attributes.attendanceTimeEnd,
        attendanceTimeId: attributes.attendanceTimeId,
        displayStartTime: attributes.attendanceTimeStart,
        displayEndTime: attributes.attendanceTimeEnd,
        displayTitle: attributes.bizName,
      },
    }
  },
)

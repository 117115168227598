import {
  GzzxExamScoreAnalysisCommonRule,
  GzzxExamScoreAnalysisCommonRuleInterface,
} from './gzzx-exam-score-analysis-common-rule'

export interface GzzxExamScoreAnalysisSportsTestingRuleInterface {
  /**
   * 科目名称
   */
  subjectName: string
  /**
   * 科目排序
   */
  sort: number
  /**
   * 分析类型：item - 体育细项，total - 体育总评
   */
  type: string
  /**
   * 分析规则
   */
  rules?: GzzxExamScoreAnalysisCommonRuleInterface[] | null
  /**
   * 学生是否可见分数
   */
  displayScore: boolean
  /**
   * 学生是否可见等级
   */
  displayLevel: boolean
  /**
   * 满分标准
   */
  fullScoreStandard?: string | null
}

export class GzzxExamScoreAnalysisSportsTestingRule
  implements GzzxExamScoreAnalysisSportsTestingRuleInterface
{
  /**
   * 科目名称
   */
  subjectName: string
  /**
   * 科目排序
   */
  sort: number
  /**
   * 分析类型：item - 体育细项，total - 体育总评
   */
  type: string
  /**
   * 分析规则
   */
  rules?: GzzxExamScoreAnalysisCommonRule[] | null
  /**
   * 学生是否可见分数
   */
  displayScore: boolean
  /**
   * 学生是否可见等级
   */
  displayLevel: boolean
  /**
   * 满分标准
   */
  fullScoreStandard?: string | null

  constructor(props: GzzxExamScoreAnalysisSportsTestingRuleInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      subjectName: _subjectName,
      sort: _sort,
      type: _type,
      rules: _rules,
      displayScore: _displayScore,
      displayLevel: _displayLevel,
      fullScoreStandard: _fullScoreStandard,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.subjectName = _subjectName
    this.sort = _sort
    this.type = _type
    this.rules = _rules
      ? _rules.map(i => new GzzxExamScoreAnalysisCommonRule(i))
      : _rules
    this.displayScore = _displayScore
    this.displayLevel = _displayLevel
    this.fullScoreStandard = _fullScoreStandard
  }

  static propKeys = [
    'subjectName',
    'sort',
    'type',
    'rules',
    'displayScore',
    'displayLevel',
    'fullScoreStandard',
  ]
}

import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import {
  findEnabledChildPluginsAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import {
  getOverlappingSemestersByCurrentDate,
  selectedSemesterAtom,
  semestersAtom,
} from 'packages/feature-utils/semesters'
import { getCurrentReflection } from 'packages/feature-utils/store'
import {
  GroupStatusEnum,
  GroupType,
  JoinedGroup,
  customGroupApi$queryGroupTypes,
  groupApi$findJoinedGroups,
} from 'packages/sdks-next/chalk'

const refreshSignAtom = atom(0)

export const joinedGroupsAtom = atom<Promise<[JoinedGroup[], GroupType[]]>>(
  async get => {
    get(refreshSignAtom)

    const selectedSemester = get(selectedSemesterAtom)

    if (!selectedSemester) {
      return [[], []]
    }

    const currentReflection = getCurrentReflection()
    const cgPlugins = get(
      findEnabledChildPluginsAtom(PluginEnum.CustomizedGroup),
    )

    // 通用群组列表
    const cgTypes: GroupType[] = []

    if (cgPlugins.length) {
      const { data: groupTypes } = await customGroupApi$queryGroupTypes.api({
        idIn: cgPlugins.map(plugin => `${plugin.bizId}`).join(','),
      })

      cgTypes.push(...groupTypes)
    }

    const semesters = get(semestersAtom)

    // 课程班范围包含已选学期和与已选学期重叠的其他学期的课程班
    const classScope = [
      `class.semester-${selectedSemester.id}`,
      ...getOverlappingSemestersByCurrentDate(selectedSemester, semesters).map(
        semester => `class.semester-${semester.id}`,
      ),
    ]

    // 参与的所有群组（课程班/行政班/通用群组等等）
    const { data: groups } = await groupApi$findJoinedGroups.api(
      currentReflection.id,
      {
        scopeIn: [
          'adminclass',
          'course',
          'dorm',
          ...classScope,
          ...cgTypes.map(type => `custom_group.${type.machineName}`),
        ].join(','),
        status: GroupStatusEnum.Normal,
        paginated: 0,
      },
    )

    return [groups, cgTypes]
  },
)

export const joinedGroupsLoadableAtom = loadable(
  atom(get => get(joinedGroupsAtom)),
)

export const reloadJoinedGroupsAtom = atom(null, async (_get, _set) => {
  _set(refreshSignAtom, _get(refreshSignAtom) + 1)
})

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Schcal, SchcalInterface } from './schcal'
import type { QuerySchcalsQuery } from './query-schcals-query'

const buildConfig = createConfigBuilder('scms')

export const schcalApi$querySchcals = {
  config(
    semesterId: number,
    querySchcalsQuery?: QuerySchcalsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/schcal/schcals',
      {
        semesterId,
      },
      querySchcalsQuery,
    )
  },

  /**
   * @summary 查询校历
   * @param semesterId
   * @param [querySchcalsQuery]
   * @param [schcalQuery.idIn]
   * @param [schcalQuery.nameLike]
   * @param [options]
   * @return AxiosResponsePromise<Schcal[]>
   */

  api(
    semesterId: number,
    querySchcalsQuery?: QuerySchcalsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Schcal[]> {
    return axios(
      schcalApi$querySchcals.config(semesterId, querySchcalsQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Schcal(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = Schcal[]>(
    {
      semesterId,
      query,
    }: {
      semesterId: number
      query?: QuerySchcalsQuery
    },
    queryOptions?: QueryOptionsWithSelect<Schcal[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Schcal[], TSelected>(
      schcalApi$querySchcals.api,
      'Schcal.querySchcals',
      queryOptions,
      semesterId,
      query,
    )
  },
}

// @ts-ignore
schcalApi$querySchcals.api._name_ = `Schcal.querySchcals`

import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import { AdminClass, AdminClassInterface } from './extends/admin-class'
import { Score, ScoreInterface } from './extends/score'
import { Grade, GradeInterface } from './extends/grade'

export interface SummaryInterface {
  owner?: SecuredReflectionInterface
  adminClass?: AdminClassInterface
  scores?: ScoreInterface[]
  grade?: GradeInterface | null
  stageGrades?: GradeInterface[]
}

export class Summary implements SummaryInterface {
  owner?: SecuredReflection
  adminClass?: AdminClass
  scores?: Score[]
  grade?: Grade | null
  stageGrades?: Grade[]

  constructor(props: SummaryInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      owner: _owner,
      adminClass: _adminClass,
      scores: _scores,
      grade: _grade,
      stageGrades: _stageGrades,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.owner = _owner ? new SecuredReflection(_owner) : _owner
    this.adminClass = _adminClass ? new AdminClass(_adminClass) : _adminClass
    this.scores = _scores ? _scores.map(i => new Score(i)) : _scores
    this.grade = _grade ? new Grade(_grade) : _grade
    this.stageGrades = _stageGrades
      ? _stageGrades.map(i => new Grade(i))
      : _stageGrades
  }

  static propKeys = ['owner', 'adminClass', 'scores', 'grade', 'stageGrades']
}

/**
 * @file types
 */
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import React from 'react'

import { FormFeedbackReq } from 'packages/sdks-next/form'

import { LegoFormField } from './field'

export * from './field'
export * from './field-container'
export * from './field-option'
export * from './table-field'
export * from './field-answer'

export type LegoFormFeedback = FormFeedbackReq

export type LegoFormTemplate = {
  name?: React.ReactNode
  description?: React.ReactNode
  enableFeedback?: boolean
  feedbackOptions?: string[]
  hideFieldNumber?: boolean
  inactivateable?: boolean
}

export type LegoFormBuilderItem = {
  /*
   * 字段类型，类型是 LegoFormFieldTypeEnum | 业务自定义类型
   */
  type: string
  component?: string
  name: string
  icon: IconDefinition | React.ReactNode
  label: string
  isOnly?: boolean
  defaultField?: LegoFormField
  isBiz?: boolean
}

export type DragField = {
  index: number
  type: 'draggableField'
}

export type DragFieldOption = {
  index: number
  type: 'draggableOption'
}

export type DragTypeItem = {
  type: 'draggableType'
  builderItem: LegoFormBuilderItem
}

export enum LegoFormDateFormatter {
  FullDateTime = 'YYYY/MM/DD HH:mm',
  Date = 'YYYY/MM/DD',
  Time = 'HH:mm',
}

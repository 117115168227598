/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Summary, SummaryInterface } from './summary'
import type { QueryKlassAssessmentSummariesQuery } from './query-klass-assessment-summaries-query'

const buildConfig = createConfigBuilder('vnas')

export const klassApi$queryKlassAssessmentSummaries = {
  config(
    id: number,
    queryKlassAssessmentSummariesQuery?: QueryKlassAssessmentSummariesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/klass/assessments/{id}/summaries',
      {
        id,
      },
      queryKlassAssessmentSummariesQuery,
    )
  },

  /**
   * @summary 查询成绩汇总
   * @param id
   * @param [queryKlassAssessmentSummariesQuery]
   * @param [klassQuery.ownerIdIn]
   * @param [klassQuery.sort] sort 规则参见 queryKlassAssessmentScopes
   * @param [klassQuery.paginated] 是否分页，默认 1
   * @param [klassQuery.page] 显示的数据页，默认 1
   * @param [klassQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<Summary[]>
   */

  api(
    id: number,
    queryKlassAssessmentSummariesQuery?: QueryKlassAssessmentSummariesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Summary[]> {
    return axios(
      klassApi$queryKlassAssessmentSummaries.config(
        id,
        queryKlassAssessmentSummariesQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Summary(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = Summary[]>(
    {
      id,
      query,
    }: {
      id: number
      query?: QueryKlassAssessmentSummariesQuery
    },
    queryOptions?: QueryOptionsWithSelect<Summary[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Summary[], TSelected>(
      klassApi$queryKlassAssessmentSummaries.api,
      'Klass.queryKlassAssessmentSummaries',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
klassApi$queryKlassAssessmentSummaries.api._name_ = `Klass.queryKlassAssessmentSummaries`

/**
 * @file 打底的多语言设置，在加载远程文本之前或失败时，为那些信息提供翻译
 */

import { LocaleTextLocaleEnum } from 'packages/sdks-next/chalk'

import { UsefulLocaleEnum } from '../types'

import baseMessagesCn from './cn.json'
import baseMessagesEn from './en.json'

export const baseLocales: {
  [key in UsefulLocaleEnum]: any
} = {
  [LocaleTextLocaleEnum.EnUS]: baseMessagesEn,
  [LocaleTextLocaleEnum.ZhCN]: baseMessagesCn,
}

import { FormTemplateFieldOptionTypeEnum } from './form-template-field-option-type-enum'

export interface FormTemplateFieldOptionInterface {
  type?: FormTemplateFieldOptionTypeEnum
  name?: string
  label?: string
  score?: string
  required?: boolean
  sort?: number
  /**
   * 支持一对多 - 传值逗号分割
   */
  targetName?: string
  vid?: number
  id?: number
  /**
   * 级联组件使用，级联组件使用 id, parent_id 和 label 构成一棵树
   */
  parentId?: number
}

export class FormTemplateFieldOption
  implements FormTemplateFieldOptionInterface
{
  type?: FormTemplateFieldOptionTypeEnum
  name?: string
  label?: string
  score?: string
  required?: boolean
  sort?: number
  /**
   * 支持一对多 - 传值逗号分割
   */
  targetName?: string
  vid?: number
  id?: number
  /**
   * 级联组件使用，级联组件使用 id, parent_id 和 label 构成一棵树
   */
  parentId?: number

  constructor(props: FormTemplateFieldOptionInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      type: _type,
      name: _name,
      label: _label,
      score: _score,
      required: _required,
      sort: _sort,
      targetName: _targetName,
      vid: _vid,
      id: _id,
      parentId: _parentId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.type = _type
    this.name = _name
    this.label = _label
    this.score = _score
    this.required = _required
    this.sort = _sort
    this.targetName = _targetName
    this.vid = _vid
    this.id = _id
    this.parentId = _parentId
  }

  static propKeys = [
    'type',
    'name',
    'label',
    'score',
    'required',
    'sort',
    'targetName',
    'vid',
    'id',
    'parentId',
  ]
}

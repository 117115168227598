import { Place, PlaceInterface } from './place'

export interface AttendanceTimeInterface {
  schoolId: number
  attendanceId: number
  semesterId: number
  groupId: number
  placeId?: number | null
  startAt: string
  endAt: string
  planId?: number | null
  planName?: string
  weekDay: number
  date: string
  attendanceTimeRangeRef?: string | null
  /**
   * expand place
   */
  place?: PlaceInterface | null
  attendanceBizId?: number
  /**
   * 创建时间
   * 格式为 RFC3339 规则定义，UTC 2017-12-08T00:00:00.00Z CST 2017-12-08T00:08:00.00+08:00
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  /**
   * 创建人.
   */
  createdBy?: number | null
  /**
   * 更新人.
   */
  updatedBy?: number | null
  /**
   * 主键 ID
   */
  id: number
}

export class AttendanceTime implements AttendanceTimeInterface {
  schoolId: number
  attendanceId: number
  semesterId: number
  groupId: number
  placeId?: number | null
  startAt: string
  endAt: string
  planId?: number | null
  planName?: string
  weekDay: number
  date: string
  attendanceTimeRangeRef?: string | null
  /**
   * expand place
   */
  place?: Place | null
  attendanceBizId?: number
  /**
   * 创建时间
   * 格式为 RFC3339 规则定义，UTC 2017-12-08T00:00:00.00Z CST 2017-12-08T00:08:00.00+08:00
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  /**
   * 创建人.
   */
  createdBy?: number | null
  /**
   * 更新人.
   */
  updatedBy?: number | null
  /**
   * 主键 ID
   */
  id: number

  constructor(props: AttendanceTimeInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      attendanceId: _attendanceId,
      semesterId: _semesterId,
      groupId: _groupId,
      placeId: _placeId,
      startAt: _startAt,
      endAt: _endAt,
      planId: _planId,
      planName: _planName,
      weekDay: _weekDay,
      date: _date,
      attendanceTimeRangeRef: _attendanceTimeRangeRef,
      place: _place,
      attendanceBizId: _attendanceBizId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      createdBy: _createdBy,
      updatedBy: _updatedBy,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.attendanceId = _attendanceId
    this.semesterId = _semesterId
    this.groupId = _groupId
    this.placeId = _placeId
    this.startAt = _startAt
    this.endAt = _endAt
    this.planId = _planId
    this.planName = _planName
    this.weekDay = _weekDay
    this.date = _date
    this.attendanceTimeRangeRef = _attendanceTimeRangeRef
    this.place = _place ? new Place(_place) : _place
    this.attendanceBizId = _attendanceBizId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
    this.createdBy = _createdBy
    this.updatedBy = _updatedBy
    this.id = _id
  }

  static propKeys = [
    'schoolId',
    'attendanceId',
    'semesterId',
    'groupId',
    'placeId',
    'startAt',
    'endAt',
    'planId',
    'planName',
    'weekDay',
    'date',
    'attendanceTimeRangeRef',
    'place',
    'attendanceBizId',
    'createdAt',
    'updatedAt',
    'createdBy',
    'updatedBy',
    'id',
  ]
}

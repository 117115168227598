export interface ConfigItemInterface {
  /**
   * 作用域
   */
  scope: string
  /**
   * 所属服务
   */
  service: string
  /**
   * 配置组
   */
  group: string
  /**
   * 配置名称
   */
  name: string
}

export class ConfigItem implements ConfigItemInterface {
  /**
   * 作用域
   */
  scope: string
  /**
   * 所属服务
   */
  service: string
  /**
   * 配置组
   */
  group: string
  /**
   * 配置名称
   */
  name: string

  constructor(props: ConfigItemInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      scope: _scope,
      service: _service,
      group: _group,
      name: _name,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.scope = _scope
    this.service = _service
    this.group = _group
    this.name = _name
  }

  static propKeys = ['scope', 'service', 'group', 'name']
}

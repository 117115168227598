import { useMemo } from 'react'

import { useCurrentSchool } from 'packages/features/schools/hooks'
import { useCurrentReflection } from 'packages/features/sessions'
import { QueryOptionsWithSelect, wait } from 'packages/sdks-next'
import {
  GroupStatusEnum,
  acmApi$queryConfigItemsByService,
  groupApi$findJoinedGroups,
} from 'packages/sdks-next/chalk'
import {
  QueryKlassAssessmentScopesQuery,
  SecuredReflection,
  Summary,
  klassApi$queryKlassAssessmentScopes,
  klassApi$queryKlassAssessmentSummaries,
} from 'packages/sdks-next/vnas'
import {
  SchoolConfigGroupNameEnum,
  ServiceEnum,
} from 'packages/shared-stores/configs'

import { useReflectionsPinyinSort } from '../reflections/hooks'
import {
  getOverlappingSemestersByCurrentDate,
  useCurrentSemester,
  useSemesters,
} from '../semesters'

/**
 * 课程介绍是否开启视频上传
 *
 * @returns 是否
 */
export const useClassDescriptionVideoEnabled = () => {
  const school = useCurrentSchool()

  const { data: electionConfigs } = acmApi$queryConfigItemsByService.useApi(
    {
      service: ServiceEnum.SCMS,
      scope: `school.${school?.id}`,
      query: {
        groupIn: SchoolConfigGroupNameEnum.ElectionSettings,
      },
    },
    {
      disable: !school?.id,
    },
  )

  const enableCourseDescriptionVideo = !!electionConfigs?.find(
    c => c.name === 'enable_course_desc_video',
  )?.value

  return enableCourseDescriptionVideo
}

/**
 * 获取课程班评价的人员范围，其中适配了中/英文下的人员姓名排序，具体为中文使用 pinyin，
 * 英文使用 ename（ename 不存在取 pinyin）
 *
 * @param args - args
 * @param args.assessmentId - 评价 id
 * @param args.query - 查询参数
 * @param args.queryOptions - 查询配置项
 * @returns 人员列表
 */
export const useClassAssessmentScopes = ({
  assessmentId,
  query,
  queryOptions,
}: {
  assessmentId: number
  query?: QueryKlassAssessmentScopesQuery
  queryOptions?: QueryOptionsWithSelect<
    SecuredReflection[],
    SecuredReflection[]
  >
}) => {
  const sort = useReflectionsPinyinSort(query?.sort)

  return klassApi$queryKlassAssessmentScopes.useApi(
    {
      id: wait(assessmentId),
      query: { ...query, sort },
    },
    queryOptions,
  )
}

/**
 * 获取课程班评价汇总，其中适配了中/英文下的人员姓名排序，具体为中文使用 pinyin，
 * 英文使用 ename（ename 不存在取 pinyin）
 *
 * @param args - args
 * @param args.assessmentId - 评价 id
 * @param args.query - 查询参数
 * @param args.queryOptions - 查询配置项
 * @returns 汇总列表
 */
export const useClassAssessmentSummaries = ({
  assessmentId,
  query,
  queryOptions,
}: {
  assessmentId: number
  query?: QueryKlassAssessmentScopesQuery
  queryOptions?: QueryOptionsWithSelect<Summary[], Summary[]>
}) => {
  const sort = useReflectionsPinyinSort(query?.sort)

  return klassApi$queryKlassAssessmentSummaries.useApi(
    {
      id: wait(assessmentId),
      query: { ...query, sort },
    },
    queryOptions,
  )
}

/**
 * 获取当前学期已加入的课程班群组
 *
 * @param params - 参数
 * @param params.rid - 用户 id
 * @param params.disable - 是否禁用请求
 * @returns 课程班群组
 */
export const useCurrentSemesterJoinedClassGroups = ({
  rid,
  disable,
}: {
  rid?: number
  disable?: boolean
} = {}) => {
  const [semesters] = useSemesters()
  const currentSemester = useCurrentSemester()

  // 课程班范围包含当前学期和与当前学期重叠的其他学期的课程班
  const classScopes = useMemo(() => {
    return !currentSemester
      ? []
      : [
          `class.semester-${currentSemester.id}`,
          ...getOverlappingSemestersByCurrentDate(
            currentSemester,
            semesters,
          ).map(semester => `class.semester-${semester.id}`),
        ]
  }, [currentSemester, semesters])

  const currentReflection = useCurrentReflection()

  return groupApi$findJoinedGroups.useApi(
    {
      id: rid || currentReflection.id,
      query: {
        scopeIn: classScopes.join(','),
        status: GroupStatusEnum.Normal,
        paginated: 0,
      },
    },
    {
      disable: disable || !classScopes.length,
    },
  )
}

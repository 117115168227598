import { AdminLabelEnum } from './admin-label-enum'

export interface GroupLabelsInterface {
  type?: string | null
  /**
   * 只有课程班群组（type=seiue.class_group）有该字段
   */
  semesterId?: string | null
  /**
   * 归属领域，如：role
   */
  domain?: string | null
  /**
   * 是否为管理员
   */
  admin?: AdminLabelEnum
  /**
   * 是否为模块总管理员
   */
  moduleAdmin?: AdminLabelEnum
  /**
   * 常用Id
   */
  taskId?: number
  talkId?: number
  reviewId?: number
  examId?: number
}

export class GroupLabels implements GroupLabelsInterface {
  type?: string | null
  /**
   * 只有课程班群组（type=seiue.class_group）有该字段
   */
  semesterId?: string | null
  /**
   * 归属领域，如：role
   */
  domain?: string | null
  /**
   * 是否为管理员
   */
  admin?: AdminLabelEnum
  /**
   * 是否为模块总管理员
   */
  moduleAdmin?: AdminLabelEnum
  /**
   * 常用Id
   */
  taskId?: number
  talkId?: number
  reviewId?: number
  examId?: number

  constructor(props: GroupLabelsInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      type: _type,
      semesterId: _semesterId,
      domain: _domain,
      admin: _admin,
      moduleAdmin: _moduleAdmin,
      taskId: _taskId,
      talkId: _talkId,
      reviewId: _reviewId,
      examId: _examId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.type = _type
    this.semesterId = _semesterId
    this.domain = _domain
    this.admin = _admin
    this.moduleAdmin = _moduleAdmin
    this.taskId = _taskId
    this.talkId = _talkId
    this.reviewId = _reviewId
    this.examId = _examId
  }

  static propKeys = [
    'type',
    'semesterId',
    'domain',
    'admin',
    'moduleAdmin',
    'taskId',
    'talkId',
    'reviewId',
    'examId',
  ]
}

export interface CalendarInitiatorInterface {
  id: number
  name: string
  ename?: string | null
  pinyin: string
}

export class CalendarInitiator implements CalendarInitiatorInterface {
  id: number
  name: string
  ename?: string | null
  pinyin: string

  constructor(props: CalendarInitiatorInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      name: _name,
      ename: _ename,
      pinyin: _pinyin,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
    this.name = _name
    this.ename = _ename
    this.pinyin = _pinyin
  }

  static propKeys = ['id', 'name', 'ename', 'pinyin']
}

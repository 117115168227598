/**
 * @file 全屏弹窗：header、content、footer
 */

import { ButtonProps } from '@seiue/ui'
import React from 'react'

import { ZIndex } from 'packages/themed'

import { Content, ContentRef } from './Components/Content'
import { Footer } from './Components/Footer'
import { Header } from './Components/Header'
import {
  PureFullScreenModal,
  PureFullScreenModalProps,
} from './PureFullScreenModal'

export interface FullScreenModalProps extends PureFullScreenModalProps {
  visible: boolean
  title: string
  onOk?: (e: React.MouseEvent<HTMLElement>) => void
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void
  onClose?: (e: React.MouseEvent<HTMLElement>) => void
  okText?: React.ReactNode
  okType?: ButtonProps['type']
  cancelText?: React.ReactNode
  okButtonProps?: ButtonProps
  cancelButtonProps?: ButtonProps
  header?: React.ReactNode
  footer?: React.ReactNode
  footerActionsLeft?: React.ReactNode
  zIndex?: number
  contentWidth?: number | string
  contentStyle?: React.CSSProperties
  contentWrapperStyle?: React.CSSProperties
  destroyContentWhenInvisible?: boolean
  hideFooter?: boolean
  /**
   * 位于 header 下方的内容，通常用于展示如 “当前为预览模式” 的 alert
   */
  afterHeader?: React.ReactNode
  contentPaddingTop?: number
  useBackToTop?: boolean
  /**
   * duplicate of afterHeader ?
   */
  headerBottom?: React.ReactNode
  children?: React.ReactNode
  /**
   * 自定义 header 右侧内容
   */
  headerRight?: React.ReactNode
  className?: string
}

export interface FullScreenModalRef {
  contentRef: ContentRef | null
}

export const FullScreenModal = React.forwardRef<
  FullScreenModalRef,
  FullScreenModalProps
>(
  (
    {
      visible,
      title,
      onClose,
      onCancel,
      onOk,
      okText,
      okType,
      okButtonProps,
      cancelText,
      cancelButtonProps,
      footer,
      header,
      children,
      zIndex = ZIndex.FullScreen,
      contentWidth = 960,
      contentStyle,
      contentWrapperStyle,
      destroyContentWhenInvisible,
      hideFooter,
      getContainer,
      footerActionsLeft,
      afterHeader,
      contentPaddingTop,
      useBackToTop,
      headerBottom,
      headerRight,
      className,
    },
    ref,
  ) => {
    const contentRef = React.useRef<ContentRef>(null)

    const [isReadyToRenderContent, setIsReadyToRenderContent] =
      React.useState(false)

    const _contentWidth =
      typeof contentWidth === 'string' ? contentWidth : `${contentWidth}px`

    const innerZIndex = zIndex + 10

    React.useImperativeHandle(ref, () => ({
      contentRef: contentRef?.current,
    }))

    return (
      <PureFullScreenModal
        visible={visible}
        zIndex={zIndex}
        getContainer={getContainer}
        destroyContentWhenInvisible={destroyContentWhenInvisible}
        onReadyStateChange={readyToRenderContent =>
          setIsReadyToRenderContent(readyToRenderContent)
        }
        className={className}
      >
        {header || (
          <Header
            style={{ zIndex: innerZIndex }}
            title={title}
            contentWidth={_contentWidth}
            headerBottom={headerBottom}
            headerRight={headerRight}
            onClose={onClose ?? onCancel}
          />
        )}
        {afterHeader}
        <Content
          zIndex={zIndex}
          useBackToTop={useBackToTop}
          contentWidth={_contentWidth}
          contentWrapperStyle={contentWrapperStyle}
          contentStyle={contentStyle}
          style={
            contentPaddingTop ? { paddingTop: contentPaddingTop } : undefined
          }
          readyToRenderContent={isReadyToRenderContent}
          ref={contentRef}
        >
          {children}
        </Content>
        {!hideFooter ? (
          <Footer
            style={{ zIndex: innerZIndex }}
            contentWidth={_contentWidth}
            cancelText={cancelText}
            cancelButtonProps={cancelButtonProps}
            onCancel={onCancel}
            okText={okText}
            okType={okType}
            okButtonProps={okButtonProps}
            onOk={onOk}
            footerActionsLeft={footerActionsLeft}
          >
            {footer}
          </Footer>
        ) : null}
      </PureFullScreenModal>
    )
  },
)

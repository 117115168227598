/**
 * @file 听评课-消息 domain 注册
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.ClassReview,
  getLabel: () => $t('听评课'),
  labelStyle: calculateLabelStyle('#F7E9FA', '#9E37B4'),
  messages: [
    'send_review_arrangement',
    'send_report',
    'invalid',
    'un_completed',
    {
      type: 'un_completed',
      important: true,
    },
  ],
})

// eslint-disable-next-line no-restricted-imports
import { useLocalStorageState as useLocalStorageStateOrigin } from '@seiue/util'

/**
 * export from @seiue/util，
 * 同名 native 文件支持 RN
 *
 * @param key - 键
 * @param defaultValue - 默认值
 * @returns 状态值
 */
export const useLocalStorageState = useLocalStorageStateOrigin

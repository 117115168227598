/**
 * @file 注册通用群组消息
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import { registerMessageNavigator } from 'packages/features/messages/utils'
import { GroupNotice } from 'packages/sdks-next/chalk'

import {
  NoticeTypes,
  SignupTypes,
  AttendanceTypes,
  MemberChanges,
} from './message-type'

registerMessageNavigator(
  `${MessageDomainEnum.CustomGroupNotice}.${NoticeTypes.Published}`,
  message => {
    const attributes = message.attributes as GroupNotice
    const id = attributes?.id
    const groupId = attributes?.groupId

    if (!id || !groupId) return null

    return {
      modalName: 'PluginCustomizedGroupsNoticeDetailModal',
      options: { nid: id, gid: groupId },
    }
  },
)

registerMessageNavigator(
  `${MessageDomainEnum.CustomGroupSignupSubmission}.${SignupTypes.Approved}`,
  message => {
    const attributes = message.attributes as {
      groupTypeId: number
      customGroupId: number
    }

    const groupTypeId = attributes?.groupTypeId
    const groupId = attributes?.customGroupId

    if (!groupTypeId || !groupId) return null

    return {
      path: `/plugin/customized-group/${groupTypeId}/groups/${groupId}`,
    }
  },
)

registerMessageNavigator(
  `${MessageDomainEnum.CustomGroup}.${AttendanceTypes.Student}`,
  message => {
    const attributes = message.attributes as {
      groupTypeId: number
      customGroupId: number
    }

    if (!attributes?.groupTypeId || !attributes?.customGroupId) return null

    return {
      path: `/plugin/customized-group/${attributes.groupTypeId}/groups/${attributes.customGroupId}/attendances`,
    }
  },
)

registerMessageNavigator(
  `${MessageDomainEnum.CustomGroup}.${AttendanceTypes.Guardian}`,
  message => ({
    modalName: 'MessagesShowModal',
    options: { messageId: message.id },
  }),
)

registerMessageNavigator(
  `${MessageDomainEnum.CustomGroup}.${AttendanceTypes.Teacher}`,
  message => {
    const attributes = message.attributes as {
      groupTypeId: number
      customGroupId: number
      startAt?: string
      endAt?: string
    }

    if (!attributes?.groupTypeId || !attributes?.customGroupId) return null

    return {
      path: `/plugin/customized-group/${attributes.groupTypeId}/groups/${
        attributes.customGroupId
      }/admin-attendances/day${
        attributes.startAt && attributes.endAt
          ? `?startAt=${attributes.startAt}&endAt=${attributes.endAt}`
          : ''
      }`,
    }
  },
)

registerMessageNavigator(
  `${MessageDomainEnum.CustomGroup}.${MemberChanges.Added}`,
  message => {
    const { groupTypeId, customGroupId } = message.attributes as {
      groupTypeId: number
      customGroupId: number
    }

    if (!groupTypeId || !customGroupId) return null

    return {
      path: `/plugin/customized-group/${groupTypeId}/groups/${customGroupId}/home`,
    }
  },
)

registerMessageNavigator(
  `${MessageDomainEnum.CustomGroup}.${MemberChanges.Removed}`,
  () => null,
)

/* eslint-disable import/order */
import { createEnumHelper } from '@seiue/util'

import redBadge1 from '../assets/red-badge-1.png'
import redBadge2 from '../assets/red-badge-2.png'
import redBadge3 from '../assets/red-badge-3.png'
import redBadge4 from '../assets/red-badge-4.png'
import redBadge5 from '../assets/red-badge-5.png'
import redBadge6 from '../assets/red-badge-6.png'
import redBadge7 from '../assets/red-badge-7.png'
import redBadge8 from '../assets/red-badge-8.png'
import redBadge9 from '../assets/red-badge-9.png'
import redBadge10 from '../assets/red-badge-10.png'

import blueBadge1 from '../assets/blue-badge-1.png'
import blueBadge2 from '../assets/blue-badge-2.png'
import blueBadge3 from '../assets/blue-badge-3.png'
import blueBadge4 from '../assets/blue-badge-4.png'
import blueBadge5 from '../assets/blue-badge-5.png'
import blueBadge6 from '../assets/blue-badge-6.png'
import blueBadge7 from '../assets/blue-badge-7.png'
import blueBadge8 from '../assets/blue-badge-8.png'
import blueBadge9 from '../assets/blue-badge-9.png'
import blueBadge10 from '../assets/blue-badge-10.png'

import purpleBadge1 from '../assets/purple-badge-1.png'
import purpleBadge2 from '../assets/purple-badge-2.png'
import purpleBadge3 from '../assets/purple-badge-3.png'
import purpleBadge4 from '../assets/purple-badge-4.png'
import purpleBadge5 from '../assets/purple-badge-5.png'
import purpleBadge6 from '../assets/purple-badge-6.png'
import purpleBadge7 from '../assets/purple-badge-7.png'
import purpleBadge8 from '../assets/purple-badge-8.png'
import purpleBadge9 from '../assets/purple-badge-9.png'
import purpleBadge10 from '../assets/purple-badge-10.png'

import orangeBadge1 from '../assets/orange-badge-1.png'
import orangeBadge2 from '../assets/orange-badge-2.png'
import orangeBadge3 from '../assets/orange-badge-3.png'
import orangeBadge4 from '../assets/orange-badge-4.png'
import orangeBadge5 from '../assets/orange-badge-5.png'
import orangeBadge6 from '../assets/orange-badge-6.png'
import orangeBadge7 from '../assets/orange-badge-7.png'
import orangeBadge8 from '../assets/orange-badge-8.png'
import orangeBadge9 from '../assets/orange-badge-9.png'
import orangeBadge10 from '../assets/orange-badge-10.png'

import greenBadge1 from '../assets/green-badge-1.png'
import greenBadge2 from '../assets/green-badge-2.png'
import greenBadge3 from '../assets/green-badge-3.png'
import greenBadge4 from '../assets/green-badge-4.png'
import greenBadge5 from '../assets/green-badge-5.png'
import greenBadge6 from '../assets/green-badge-6.png'
import greenBadge7 from '../assets/green-badge-7.png'
import greenBadge8 from '../assets/green-badge-8.png'
import greenBadge9 from '../assets/green-badge-9.png'
import greenBadge10 from '../assets/green-badge-10.png'

import redBadgePunishment1 from '../assets/red-badge-punishment1.png'
import orangeBadgePunishment1 from '../assets/orange-badge-punishment1.png'

import redBadgeCardFront from '../assets/red-badge-card-front.png'
import blueBadgeCardFront from '../assets/blue-badge-card-front.png'
import purpleBadgeCardFront from '../assets/purple-badge-card-front.png'
import orangeBadgeCardFront from '../assets/orange-badge-card-front.png'
import greenBadgeCardFront from '../assets/green-badge-card-front.png'

import redBadgeCardBack from '../assets/red-badge-card-back.png'
import blueBadgeCardBack from '../assets/blue-badge-card-back.png'
import purpleBadgeCardBack from '../assets/purple-badge-card-back.png'
import orangeBadgeCardBack from '../assets/orange-badge-card-back.png'
import greenBadgeCardBack from '../assets/green-badge-card-back.png'

/**
 * 处分徽章列表
 */
export const colorToPunishmentBadges = {
  red: createEnumHelper({
    redBadgePunishment1,
  }),
  blue: createEnumHelper({}),
  purple: createEnumHelper({}),
  orange: createEnumHelper({
    orangeBadgePunishment1,
  }),
  green: createEnumHelper({}),
}

/**
 * 激励徽章列表
 */
export const colorToIncentiveBadges = {
  red: createEnumHelper({
    redBadge1,
    redBadge2,
    redBadge3,
    redBadge4,
    redBadge5,
    redBadge6,
    redBadge7,
    redBadge8,
    redBadge9,
    redBadge10,
  }),
  blue: createEnumHelper({
    blueBadge1,
    blueBadge2,
    blueBadge3,
    blueBadge4,
    blueBadge5,
    blueBadge6,
    blueBadge7,
    blueBadge8,
    blueBadge9,
    blueBadge10,
  }),
  purple: createEnumHelper({
    purpleBadge1,
    purpleBadge2,
    purpleBadge3,
    purpleBadge4,
    purpleBadge5,
    purpleBadge6,
    purpleBadge7,
    purpleBadge8,
    purpleBadge9,
    purpleBadge10,
  }),
  orange: createEnumHelper({
    orangeBadge1,
    orangeBadge2,
    orangeBadge3,
    orangeBadge4,
    orangeBadge5,
    orangeBadge6,
    orangeBadge7,
    orangeBadge8,
    orangeBadge9,
    orangeBadge10,
  }),
  green: createEnumHelper({
    greenBadge1,
    greenBadge2,
    greenBadge3,
    greenBadge4,
    greenBadge5,
    greenBadge6,
    greenBadge7,
    greenBadge8,
    greenBadge9,
    greenBadge10,
  }),
}

/**
 * 实体卡片列表
 */
export const colorToBadgeCard = createEnumHelper({
  redBadgeCardFront,
  redBadgeCardBack,
  blueBadgeCardFront,
  blueBadgeCardBack,
  purpleBadgeCardFront,
  purpleBadgeCardBack,
  orangeBadgeCardFront,
  orangeBadgeCardBack,
  greenBadgeCardFront,
  greenBadgeCardBack,
})

export interface TaskReviewedAttachmentInterface {
  /**
   * 原始批阅附件的 hash
   */
  hash?: string
  /**
   * 原始批阅附件的 name eg: xx.excel
   */
  name?: string
  /**
   * 批阅后附件名，文件后缀为 pdf eg: xx.pdf
   */
  reviewedName?: string | null
  /**
   * 批阅后附件的 hash
   */
  reviewedHash?: string | null
  /**
   * 批阅数据（用于前端显示）
   */
  reviewedData?: object[]
  /**
   * 批阅后学生下载用的附件 hash
   */
  reviewedDownloadHash?: string | null
  /**
   * size in KB
   */
  size?: number
  /**
   * ext
   */
  ext?: string
}

export class TaskReviewedAttachment implements TaskReviewedAttachmentInterface {
  /**
   * 原始批阅附件的 hash
   */
  hash?: string
  /**
   * 原始批阅附件的 name eg: xx.excel
   */
  name?: string
  /**
   * 批阅后附件名，文件后缀为 pdf eg: xx.pdf
   */
  reviewedName?: string | null
  /**
   * 批阅后附件的 hash
   */
  reviewedHash?: string | null
  /**
   * 批阅数据（用于前端显示）
   */
  reviewedData?: object[]
  /**
   * 批阅后学生下载用的附件 hash
   */
  reviewedDownloadHash?: string | null
  /**
   * size in KB
   */
  size?: number
  /**
   * ext
   */
  ext?: string

  constructor(props: TaskReviewedAttachmentInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      hash: _hash,
      name: _name,
      reviewedName: _reviewedName,
      reviewedHash: _reviewedHash,
      reviewedData: _reviewedData,
      reviewedDownloadHash: _reviewedDownloadHash,
      size: _size,
      ext: _ext,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.hash = _hash
    this.name = _name
    this.reviewedName = _reviewedName
    this.reviewedHash = _reviewedHash
    this.reviewedData = _reviewedData
    this.reviewedDownloadHash = _reviewedDownloadHash
    this.size = _size
    this.ext = _ext
  }

  static propKeys = [
    'hash',
    'name',
    'reviewedName',
    'reviewedHash',
    'reviewedData',
    'reviewedDownloadHash',
    'size',
    'ext',
  ]
}

import { MemberTypeEnum } from './member-type-enum'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'

export interface MemberInterface {
  memberId: number
  type: MemberTypeEnum
  teamId?: number
  reflection?: SecuredReflectionInterface | null
}

export class Member implements MemberInterface {
  memberId: number
  type: MemberTypeEnum
  teamId?: number
  reflection?: SecuredReflection | null

  constructor(props: MemberInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      memberId: _memberId,
      type: _type,
      teamId: _teamId,
      reflection: _reflection,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.memberId = _memberId
    this.type = _type
    this.teamId = _teamId
    this.reflection = _reflection
      ? new SecuredReflection(_reflection)
      : _reflection
  }

  static propKeys = ['memberId', 'type', 'teamId', 'reflection']
}

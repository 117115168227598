/**
 * @file register plugins navigator
 */

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator(
  ['school_plugin.agreed', 'school_plugin.rejected'],
  ({ attributes }) => ({
    modalName: 'PluginDetail',
    options: {
      id: (attributes as any)?.pluginId,
    },
  }),
)

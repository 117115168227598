/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { GroupCount, GroupCountInterface } from './group-count'
import type { CountExecutorTodosQuery } from './count-executor-todos-query'

const buildConfig = createConfigBuilder('chalk')

export const todoApi$countExecutorTodos = {
  config(
    countFields: string,
    rid: number,
    countExecutorTodosQuery?: CountExecutorTodosQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/todo/executors/{rid}/todo-counts',
      {
        countFields,
        rid,
      },
      countExecutorTodosQuery,
    )
  },

  /**
   * @summary 统计按条件统计待办数量
   * @param countFields 需要统计的字段，支持 status 和 domain, 多个通过 逗号 分隔
   * @param rid 待办执行人 rid
   * @param [countExecutorTodosQuery]
   * @param [todoQuery.titleLike] title 模糊查询
   * @param [todoQuery.createdAtElt] 小于等于创建时间
   * @param [todoQuery.createdAtEgt] 大于等于创建时间
   * @param [options]
   * @return AxiosResponsePromise<GroupCount[]>
   */

  api(
    countFields: string,
    rid: number,
    countExecutorTodosQuery?: CountExecutorTodosQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<GroupCount[]> {
    return axios(
      todoApi$countExecutorTodos.config(
        countFields,
        rid,
        countExecutorTodosQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new GroupCount(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = GroupCount[]>(
    {
      countFields,
      rid,
      query,
    }: {
      countFields: string
      rid: number
      query?: CountExecutorTodosQuery
    },
    queryOptions?: QueryOptionsWithSelect<GroupCount[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<GroupCount[], TSelected>(
      todoApi$countExecutorTodos.api,
      'Todo.countExecutorTodos',
      queryOptions,
      countFields,
      rid,
      query,
    )
  },
}

// @ts-ignore
todoApi$countExecutorTodos.api._name_ = `Todo.countExecutorTodos`

/**
 * @file certification navigators
 */

import { registerMessageNavigator } from 'packages/features/messages/utils'
import { RoleEnum } from 'packages/sdks-next/chalk'
import { CertRelatedPolicyEnum } from 'packages/sdks-next/sgms'

registerMessageNavigator(
  'certification.certification_reflection_update',
  (message: any, reflection) => {
    if (message.owner?.rid && message.attributes?.id) {
      return {
        modalName: 'Plugin.CertificationReflectionModal',
        options: {
          rid: message.owner.rid,
          certReflectionId: message.attributes.id,
          policy:
            reflection.role === RoleEnum.Guardian
              ? CertRelatedPolicyEnum.ProfileRelated
              : CertRelatedPolicyEnum.Owner,
        },
      }
    }

    return null
  },
)

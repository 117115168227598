/**
 * @file view utils
 */
import {
  createEnumHelper,
  isArray,
  isNumber,
  isString,
  keyBy,
} from '@seiue/util'
import { useDrag, useDrop } from 'react-dnd'

import {
  DragField,
  DragFieldOption,
  LegoFormDateFormatter,
  LegoFormField,
  LegoFormTableFieldTypeEnum,
} from 'packages/components/LegoForm/types'
import { $t } from 'packages/locale'

/**
 * 审批单表单值定义
 */
type FlowFieldValue = {
  fieldName: string
  value: any
  /**
   * 格式化后的 value, 目前仅 select 题目才会有该字段
   */
  formattedValue?: any
  /**
   * 班级名称，只有选择班级的表单项用得到
   */
  className?: string
}

export enum GroupListType {
  General = 'general',
  Contact = 'contact',
  Helpful = 'helpful',
}

/**
 * 获取分组 label
 *
 * @param type - 分组类型
 * @returns 分组 label
 */
export const getGroupLabel = (type: string) =>
  ({
    [GroupListType.General]: $t('通用项目'),
    [GroupListType.Contact]: $t('联系信息'),
    [GroupListType.Helpful]: $t('辅助项目'),
  }[type])

export const dateFieldFormatToLabel = createEnumHelper(() => ({
  [LegoFormDateFormatter.FullDateTime]: $t('年-月-日 时:分'),
  [LegoFormDateFormatter.Date]: $t('年-月-日'),
  [LegoFormDateFormatter.Time]: $t('时:分'),
}))

/**
 *
 * 获取拖拽配置
 *
 * @param param - Argument Object
 * @param param.index - 下标
 * @param param.type - 类型
 * @param param.onMove - 移动回调
 * @returns 拖拽配置
 */
export const useDragConfig = ({
  index,
  type,
  onMove,
}: {
  index: number
  type: string
  onMove: (dragIndex: number, dropIndex: number) => void
}) => {
  const [{ opacity }, drag, preview] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  })

  const [, drop] = useDrop({
    accept: type,

    drop: (item: DragField | DragFieldOption) => {
      onMove(item.index, index)
    },
  })

  return [drag, drop, preview, opacity] as const
}

/**
 * 根据下标获取对应的英文字符表达，最多支持到 ZZ(26 * 26)
 *
 * @param index - 下标
 * @param mode - 返回的数据是大写还是小写，默认'uppercase' ,返回大写
 * @returns 根据下标获取对应的英文字符表达
 */
export const getLetterByIndex = (
  index: number,
  mode: 'lowercase' | 'uppercase' = 'uppercase',
): string => {
  if (index < 0) return ''
  /**
   * 利用 toString 特性获取对应字母
   * (10).toString(36) => a，(35).toString(36) => z
   */
  const letter = (
    mode === 'lowercase'
      ? String.prototype.toLowerCase
      : String.prototype.toUpperCase
  ).call(((index % 26) + 10).toString(36))

  const over26Count = Math.floor(index / 26)

  if (over26Count) {
    return `${getLetterByIndex(over26Count - 1)}${letter}`
  }

  return letter
}

/**
 * 根据 option.name 获取选项的文本
 *
 * @param field - 字段
 * @param optionName - 选项 name
 * @returns 选项的文本
 */
export const getFieldOptionLabelByName = (
  field: LegoFormField,
  optionName?: number | string | null,
) => {
  if (isString(optionName) && optionName.split(':')[0] === 'other') {
    return $t('其他：{value}', { value: optionName.split(':')?.[1] })
  }

  const options = field.container?.options || []
  return options.find(option => option.name === optionName)?.label
}

/**
 * 获取有选项字段的 value，优先会从字段的 options 中取 label，如果为空则取 formattedValue
 *
 * @param param - Argument Object
 * @param param.field - 字段
 * @param param.fieldValue - 字段 value
 * @param param.connector - 多个 value 时的连接符
 * @returns 字段 value
 */
export const getOptionFieldSafeValue = ({
  field,
  fieldValue,
  connector,
}: {
  field: LegoFormField
  fieldValue?: FlowFieldValue
  connector?: string
}) => {
  const selectedOptions = isArray(fieldValue?.value)
    ? fieldValue?.value
    : [fieldValue?.value]

  const value = selectedOptions
    ?.map(v => getFieldOptionLabelByName(field, v))
    .join(connector)

  return value || fieldValue?.formattedValue
}

/**
 * 格式化, 当前选项, 找到所有父级的 label
 *
 * @param root0 - 参数
 * @param root0.field  --- field
 * @param root0.fieldValue ---  值
 * @returns  string , 格式化的好的字符串 "父级/父级/子级/"
 */
export const getCascadeSelectorFieldValue = ({
  field,
  fieldValue,
}: {
  field: LegoFormField
  fieldValue?: FlowFieldValue
}) => {
  const options = field.container?.options || []
  const { value } = fieldValue || {}
  let label = ''
  let findId = value || 0
  if (!isNumber(findId)) {
    return ''
  }

  const op = keyBy(options, 'id')
  while (findId !== 0) {
    const o = op[findId]
    if (!o) {
      break
    }

    findId = o['parentId']
    if (!label) {
      label = o.label || ''
    } else {
      label = `${o.label}/${label}`
    }
  }

  return label
}

export const tableFieldTypeToLabel = createEnumHelper(() => ({
  [LegoFormTableFieldTypeEnum.Input]: $t('文本'),
  // [LegoFormTableFieldTypeEnum.Radio]: $t('单选'),
  [LegoFormTableFieldTypeEnum.Number]: $t('数字'),
  [LegoFormTableFieldTypeEnum.IdCard]: $t('身份证'),
  [LegoFormTableFieldTypeEnum.Phone]: $t('手机号'),
}))

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import type { QueryKlassAssessmentScopesQuery } from './query-klass-assessment-scopes-query'

const buildConfig = createConfigBuilder('vnas')

export const klassApi$queryKlassAssessmentScopes = {
  config(
    id: number,
    queryKlassAssessmentScopesQuery?: QueryKlassAssessmentScopesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/klass/assessments/{id}/scopes',
      {
        id,
      },
      queryKlassAssessmentScopesQuery,
    )
  },

  /**
   * @summary Class QueryAssessmentScopes
   * 查询所有参与评价的人员
   * @param id
   * @param [queryKlassAssessmentScopesQuery]
   * @param [klassQuery.nameLike]
   * @param [klassQuery.usinLike]
   * @param [klassQuery.sort] 支持字段 usin, name, account, grade.real_score, grade.score, grade.rank
   * 如果需要评价项分数排序，可传 item_1.score ，其中 1 表示评价项的 id
   * @param [klassQuery.withArchivedFor] 从哪个业务下查询已经退班的人 grade, stage, item
   * @param [klassQuery.withBizId] 业务Id grade 可以不传，stage stage_id, item item_id 必须传
   * @param [klassQuery.paginated] 是否分页，默认 1
   * @param [klassQuery.page] 显示的数据页，默认 1
   * @param [klassQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<SecuredReflection[]>
   */

  api(
    id: number,
    queryKlassAssessmentScopesQuery?: QueryKlassAssessmentScopesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<SecuredReflection[]> {
    return axios(
      klassApi$queryKlassAssessmentScopes.config(
        id,
        queryKlassAssessmentScopesQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new SecuredReflection(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = SecuredReflection[]>(
    {
      id,
      query,
    }: {
      id: number
      query?: QueryKlassAssessmentScopesQuery
    },
    queryOptions?: QueryOptionsWithSelect<SecuredReflection[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<SecuredReflection[], TSelected>(
      klassApi$queryKlassAssessmentScopes.api,
      'Klass.queryKlassAssessmentScopes',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
klassApi$queryKlassAssessmentScopes.api._name_ = `Klass.queryKlassAssessmentScopes`

import { isEqual } from '@seiue/util'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'

import { MenuType } from 'packages/web-layout/menu-types'

export type MenuRef = string[]

/**
 * 当前已经加载的菜单
 */
export const openedMenusAtom = atom<MenuRef[]>([])

/**
 * 当前展示的菜单类型
 */
export const currentMenuTypesAtom = atom<MenuType[]>([MenuType.Default])

/**
 * 返回菜单是否打开的 Atom
 */
export const isMenuOpenedAtom = atomFamily((menuRef: MenuRef) =>
  atom(get => {
    const menus = get(openedMenusAtom)

    return !!menus.find(ref => isEqual(ref, menuRef))
  }),
)

import { Expand } from 'packages/sdks-next'
import {
  ScopeAssignment,
  Role,
  Reflection,
  User,
  Scope,
  PermissionNameEnum,
  ScopeEnhancerEnum,
  OAuthToken,
  School,
  AuthTicket,
} from 'packages/sdks-next/chalk'
import { RequirePropPathList } from 'packages/utils/types'

export type AuthorizeParams =
  | {
      type: 'password'
      username: string
      password: string
    }
  | {
      type: 'captcha'
      phone: string
      secret: string
      reminderId: string
    }
  | {
      type: 'token'
      oAuthToken: OAuthToken
    }
  | {
      type: 'tencent'
      code: string
      appid: string
    }
  | {
      type: 'ticket'
      ticket?: AuthTicket
    }

export type SSOProvider = 'dingtalkv2' | 'wework'

export interface SessionState {
  state: 'pending' | 'created' | 'none'
  oAuthToken?: OAuthToken
  oAuthTokenFetchedAt: number
  authTicket?: AuthTicket
  currentUser: RequirePropPathList<User, ['reflections']>
  currentReflection?: Reflection
  currentSchool: Expand<School, ['customConstraints']>
  currentPermissions: ScopeAssignment[]
  currentManagerRoles: Role[]
  currentManagedRoles: Role[]
  currentScopes: {
    scopes: Scope[]
    enhancer: ScopeEnhancerEnum
    permission: PermissionNameEnum
  }[]

  /**
   * 用户登录类型，可用于辅助判断环境，比如 'tencent' 意味着在企业微信上
   */
  authType: AuthorizeParams['type'] | null

  /**
   * 是否是通过 Apollo 或其他取到获取 Token 后登录
   * 此标志位可以协助客户成功跳过一些强制交互
   */
  isApolloLogin: boolean

  // 单点登录，目前有别于 authType tencent 的小程序单点登录
  isSSOLogin: boolean

  /**
   * 用户是否选择跳过重置初始密码
   */
  skipResetPassword: boolean

  /**
   * 用户是否选择跳过绑定手机号（目前仅应用于 App）
   */
  skipBindPhone: boolean

  /**
   * 上次登录时间（YYYY-MM-DD HH:mm:ss）
   */
  lastLoginAt?: string

  /**
   * 从何处单点登录
   */
  ssoProvider?: SSOProvider
}

// 验证码登录时的 error code
export enum AuthErrorCode {
  // 验证码错误
  CaptchaInvalid = 1001,

  // reminder_id 错误
  ReminderIdInvalid = 1002,

  // 手机号未绑定
  PhoneNotBind = 1003,

  // 验证码已过期
  CaptchaExpired = 1004,
}

import { compact } from '@seiue/util'

import { $ct } from 'packages/locale'

import { PersonalAdminClass as PersonalAdminClassBase } from '../personal-admin-class'

import { statusToLabel } from './admin-class'

export type { PersonalAdminClassInterface } from '../personal-admin-class'

/**
 * 用户参与的行政班实例
 */
export class PersonalAdminClass extends PersonalAdminClassBase {
  /**
   * 获取一个带年级的行政班全名
   *
   * @returns 全名
   */
  get fullName() {
    const gradeName = this.grade?.name ? $ct(this.grade.name, 'grade') : ''
    return compact([gradeName, this.name]).join(' ')
  }

  /**
   * 获取 status (状态) 对应的文字标签
   *
   * @returns 标签
   */
  get statusLabel() {
    return statusToLabel.get(this.status)
  }
}

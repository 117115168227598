/**
 * @file AI 教师注册
 */

import { PluginEnum } from 'packages/feature-utils/plugins'
import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.AITeacher,
  getLabel: () => $t('AI教师'),
  labelStyle: calculateLabelStyle('#eeecff', '#6a4bbe'),
  icon: `plugin.${PluginEnum.AITeacher}`,
  messages: [
    'ai_teacher.unresolved_reminder',
    'ai_teacher.reply_reminder',
    'ai_teacher.question_reminder',
  ],
})

import { useAtomValue } from 'jotai'

import { PersonalAdminClass } from 'packages/sdks-next/scms'

import { myAdminClassesLoadableAtom } from './atoms'

/**
 * 获取我的行政班列表
 *
 * 若为教师，则返回班主任是「我」的行政班
 * 若为学生，则返回成员是「我」的行政班
 *
 * @returns [行政班列表, 加载状态]
 */
export const useMyAdminClasses = (): [PersonalAdminClass[], boolean] => {
  const myAdminClasses = useAtomValue(myAdminClassesLoadableAtom)

  if (
    myAdminClasses.state === 'loading' ||
    myAdminClasses.state === 'hasError'
  ) {
    return [[], myAdminClasses.state === 'loading']
  }

  return [myAdminClasses.data, false]
}

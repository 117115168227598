import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.AcademicAnswer,
  getLabel: () => $t('学术积分'),
  labelStyle: calculateLabelStyle('#eeecff', '#6a4bbe'),
  messages: ['updated'],
})

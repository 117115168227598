/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'

const buildConfig = createConfigBuilder('chalk')

export const groupApi$getPermissionsByReflection = {
  config(
    groupId: number,
    rid: number,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/group/groups/{groupId}/reflections/{rid}/permissions',
      {
        groupId,
        rid,
      },
    )
  },

  /**
   * @summary 获取成员在群组里的所有权限
   * @param groupId 群组ID
   * @param rid reflection id
   * @param [options]
   * @return AxiosResponsePromise<string[]>
   */

  api(
    groupId: number,
    rid: number,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<string[]> {
    return axios(
      groupApi$getPermissionsByReflection.config(groupId, rid, options),
    ) as any
  },

  useApi<TSelected = string[]>(
    {
      groupId,
      rid,
    }: {
      groupId: number
      rid: number
    },
    queryOptions?: QueryOptionsWithSelect<string[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<string[], TSelected>(
      groupApi$getPermissionsByReflection.api,
      'Group.getPermissionsByReflection',
      queryOptions,
      groupId,
      rid,
    )
  },
}

// @ts-ignore
groupApi$getPermissionsByReflection.api._name_ = `Group.getPermissionsByReflection`

import { Attachment, AttachmentInterface } from './attachment'

export interface FormAnswerAttributesInterface {
  attachments?: AttachmentInterface[]
  /**
   * 时间区间-开始
   */
  dateRangeStart?: string
  /**
   * 时间区间-结束
   */
  dateRangeEnd?: string
  /**
   * 表格字段值。e.g. [{name1: value1, name2: value2, ...}]
   */
  tableRows?: object[]
}

export class FormAnswerAttributes implements FormAnswerAttributesInterface {
  attachments?: Attachment[]
  /**
   * 时间区间-开始
   */
  dateRangeStart?: string
  /**
   * 时间区间-结束
   */
  dateRangeEnd?: string
  /**
   * 表格字段值。e.g. [{name1: value1, name2: value2, ...}]
   */
  tableRows?: object[]

  constructor(props: FormAnswerAttributesInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      attachments: _attachments,
      dateRangeStart: _dateRangeStart,
      dateRangeEnd: _dateRangeEnd,
      tableRows: _tableRows,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.attachments = _attachments
      ? _attachments.map(i => new Attachment(i))
      : _attachments
    this.dateRangeStart = _dateRangeStart
    this.dateRangeEnd = _dateRangeEnd
    this.tableRows = _tableRows
  }

  static propKeys = [
    'attachments',
    'dateRangeStart',
    'dateRangeEnd',
    'tableRows',
  ]
}

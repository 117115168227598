import { LevelSettingTypeEnum } from './level-setting-type-enum'
import {
  LevelSettingRule,
  LevelSettingRuleInterface,
} from './level-setting-rule'

export interface LevelSettingInterface {
  name: string
  type: LevelSettingTypeEnum
  rules?: LevelSettingRuleInterface[]
  subjectsTotal?: number | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class LevelSetting implements LevelSettingInterface {
  name: string
  type: LevelSettingTypeEnum
  rules?: LevelSettingRule[]
  subjectsTotal?: number | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: LevelSettingInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      type: _type,
      rules: _rules,
      subjectsTotal: _subjectsTotal,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.type = _type
    this.rules = _rules ? _rules.map(i => new LevelSettingRule(i)) : _rules
    this.subjectsTotal = _subjectsTotal
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'name',
    'type',
    'rules',
    'subjectsTotal',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

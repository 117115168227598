/**
 * @file message type
 */
export enum NoticeTypes {
  Created = 'chat_created',
  Changed = 'chat_changed',
  Canceled = 'chat_canceled',
  Apply = 'apply_chat',
  CancelChatByGuardian = 'cancel_chat_by_guardian',
  GuardianSubmitted = 'guardian_submitted',
}

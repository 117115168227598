/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  EvaluationWithIsFilled,
  EvaluationWithIsFilledInterface,
} from './extends/evaluation-with-is-filled'
import type { LoadEvaluationQuery } from './load-evaluation-query'

const buildConfig = createConfigBuilder('vnas')

export const evaluationApi$loadEvaluation = {
  config(
    id: number,
    loadEvaluationQuery?: LoadEvaluationQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/evaluation/evaluations/{id}',
      {
        id,
      },
      loadEvaluationQuery,
    )
  },

  /**
   * @summary 查看单个评教
   * @param id
   * @param [loadEvaluationQuery]
   * @param [evaluationQuery.expand]
   * @param [evaluationQuery.policy]
   * @param [options]
   * @return AxiosResponsePromise<EvaluationWithIsFilled>
   */

  api<const TQuery extends LoadEvaluationQuery>(
    id: number,
    loadEvaluationQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<EvaluationWithIsFilled, TQuery['expand']>> {
    return axios(
      evaluationApi$loadEvaluation.config(id, loadEvaluationQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new EvaluationWithIsFilled(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends LoadEvaluationQuery,
    TSelected = Expand<EvaluationWithIsFilled, TQuery['expand']>,
  >(
    {
      id,
      query,
    }: {
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<EvaluationWithIsFilled, TQuery['expand']>,
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<
      Expand<EvaluationWithIsFilled, TQuery['expand']>,
      TSelected
    >(
      evaluationApi$loadEvaluation.api,
      'Evaluation.loadEvaluation',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
evaluationApi$loadEvaluation.api._name_ = `Evaluation.loadEvaluation`

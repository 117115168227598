export interface ScopeInterface {
  id: number
  schoolId: number
  bizId: number
  description: string
  params: object
  labels?: object
  /**
   * 通过 params 解析后的资源 ids ，expanded
   * scope 未被业务更新时，该值为 null
   */
  resourceIds?: number[] | null
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Scope implements ScopeInterface {
  id: number
  schoolId: number
  bizId: number
  description: string
  params: object
  labels?: object
  /**
   * 通过 params 解析后的资源 ids ，expanded
   * scope 未被业务更新时，该值为 null
   */
  resourceIds?: number[] | null
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: ScopeInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      schoolId: _schoolId,
      bizId: _bizId,
      description: _description,
      params: _params,
      labels: _labels,
      resourceIds: _resourceIds,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
    this.schoolId = _schoolId
    this.bizId = _bizId
    this.description = _description
    this.params = _params
    this.labels = _labels
    this.resourceIds = _resourceIds
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'id',
    'schoolId',
    'bizId',
    'description',
    'params',
    'labels',
    'resourceIds',
    'createdAt',
    'updatedAt',
  ]
}

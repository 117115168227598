export interface GroupCountInterface {
  /**
   * 统计的组
   */
  group: string
  /**
   * 该组下统计数量
   */
  count: number
}

export class GroupCount implements GroupCountInterface {
  /**
   * 统计的组
   */
  group: string
  /**
   * 该组下统计数量
   */
  count: number

  constructor(props: GroupCountInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      group: _group,
      count: _count,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.group = _group
    this.count = _count
  }

  static propKeys = ['group', 'count']
}

import { StageTypeEnum } from './stage-type-enum'

export interface StageBaseInterface {
  name: string
  autoPublish: boolean
  type: StageTypeEnum
  endAt: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 学期 ID
   */
  semesterId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class StageBase implements StageBaseInterface {
  name: string
  autoPublish: boolean
  type: StageTypeEnum
  endAt: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 学期 ID
   */
  semesterId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: StageBaseInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      autoPublish: _autoPublish,
      type: _type,
      endAt: _endAt,
      id: _id,
      schoolId: _schoolId,
      semesterId: _semesterId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.autoPublish = _autoPublish
    this.type = _type
    this.endAt = _endAt
    this.id = _id
    this.schoolId = _schoolId
    this.semesterId = _semesterId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'name',
    'autoPublish',
    'type',
    'endAt',
    'id',
    'schoolId',
    'semesterId',
    'createdAt',
    'updatedAt',
  ]
}

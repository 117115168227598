import { AccConvertPlanTypeEnum } from './acc-convert-plan-type-enum'
import { AccConvertPlanConvertModelEnum } from './acc-convert-plan-convert-model-enum'
import { AccConvertPlanTargetEnum } from './acc-convert-plan-target-enum'
import {
  AccConvertPlanRule,
  AccConvertPlanRuleInterface,
} from './acc-convert-plan-rule'

export interface AccConverterPlanInterface {
  /**
   * 素养评价 ID ，当前仅支持 0 （表示全部通用）
   */
  assessmentId: number
  /**
   * 类型
   */
  type: AccConvertPlanTypeEnum
  /**
   * 转换算分方式
   */
  mode: AccConvertPlanConvertModelEnum
  /**
   * 转换目标
   */
  target: AccConvertPlanTargetEnum
  /**
   * 允许教师手动修改分数
   */
  allowManualEditScore: boolean
  /**
   * 转换规则
   */
  rules: AccConvertPlanRuleInterface[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class AccConverterPlan implements AccConverterPlanInterface {
  /**
   * 素养评价 ID ，当前仅支持 0 （表示全部通用）
   */
  assessmentId: number
  /**
   * 类型
   */
  type: AccConvertPlanTypeEnum
  /**
   * 转换算分方式
   */
  mode: AccConvertPlanConvertModelEnum
  /**
   * 转换目标
   */
  target: AccConvertPlanTargetEnum
  /**
   * 允许教师手动修改分数
   */
  allowManualEditScore: boolean
  /**
   * 转换规则
   */
  rules: AccConvertPlanRule[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: AccConverterPlanInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      assessmentId: _assessmentId,
      type: _type,
      mode: _mode,
      target: _target,
      allowManualEditScore: _allowManualEditScore,
      rules: _rules,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.assessmentId = _assessmentId
    this.type = _type
    this.mode = _mode
    this.target = _target
    this.allowManualEditScore = _allowManualEditScore
    this.rules = _rules ? _rules.map(i => new AccConvertPlanRule(i)) : _rules
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'assessmentId',
    'type',
    'mode',
    'target',
    'allowManualEditScore',
    'rules',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

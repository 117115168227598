/**
 * @file 待办全局缓存，Redux 实现
 */
import { RematchStore } from '@seiue/rematch-core'
import { merge, uniqBy } from '@seiue/util'

import {
  QueryExecutorTodosQuery,
  TodoStatusEnum,
  todoApi$queryExecutorTodos,
} from 'packages/sdks-next/chalk'

import { TodoType, TodoPatch } from './types'

export interface State {
  // 当前显示的待办，我们在这里做了一个断言：一个时间内仅有一个代办列表会被显示
  currentTodos: TodoType[]
  // 被标记为已完成的待办，被标记的不会显示
  doneTodoIds: number[]
  // 当前被点击打开的待办
  currentTodo?: TodoType
}

export const state: State = {
  currentTodos: [],
  doneTodoIds: [],
}

const reducers = {
  // 重设置待办数据
  resetCurrentTodos: (prevState: State, todos: TodoType[]) => ({
    ...prevState,
    currentTodos: todos,
    doneTodoIds: [],
  }),

  // 加载更多待办数据
  appendCurrentTodos: (prevState: State, todos: TodoType[] = []) => {
    const allTodos = [...(prevState.currentTodos || []), ...(todos || [])]
    return {
      ...prevState,
      currentTodos: uniqBy(allTodos, 'id'),
    }
  },

  // 标记某个待办为已完成
  markTodoAsDone: (prevState: State, todoId: number) => ({
    ...prevState,
    doneTodoIds: [...prevState.doneTodoIds, todoId],
    currentTodos: prevState.currentTodos?.map(todo => {
      if (todo.id === todoId) {
        return {
          ...todo,
          status: TodoStatusEnum.Completed,
        }
      }

      return todo
    }),
  }),

  // 手动更新某个待办状态
  updateTodos: (prevState: State, todoPatches: TodoPatch[]) => {
    const nextTodos = [...prevState.currentTodos]
    const { doneTodoIds } = prevState

    todoPatches.forEach(todoPatch => {
      const targetIndex = prevState.currentTodos.findIndex(
        ({ id }) => id === todoPatch.id,
      )

      if (targetIndex >= 0) {
        nextTodos[targetIndex] = merge(nextTodos[targetIndex], todoPatch, {
          status: doneTodoIds.includes(todoPatch.id)
            ? TodoStatusEnum.Completed
            : todoPatch.status,
        })
      }
    })

    return {
      ...prevState,
      currentTodos: nextTodos,
    }
  },

  setCurrentTodo: (prevState: State, todo?: TodoType) => ({
    ...prevState,
    currentTodo: todo,
  }),
}

const effects = (dispatch: any) => ({
  async queryExecutorTodos({
    rid,
    query,
    keepPrevData = false,
  }: {
    rid: number
    query: QueryExecutorTodosQuery
    keepPrevData?: boolean
  }) {
    const result = await todoApi$queryExecutorTodos.api(rid, query)

    if (result.data) {
      // 如果自定义了排序规则，那么忽略 todo.isTop 标志位
      // @ts-expect-error 后端接口声明不齐
      const data = query['sort']
        ? result.data.map(t => ({ ...t, isTop: false }))
        : result.data

      if (keepPrevData) {
        // 请求第一页的时候，把 todos 重置为从后端获取到的数据，以达到更新数据的目的
        if (query.page === 1) {
          dispatch.todos.resetCurrentTodos(data)
        } else {
          dispatch.todos.appendCurrentTodos(data)
        }
      } else {
        dispatch.todos.resetCurrentTodos(data)
      }
    }

    return result
  },
})

export const todosStore = {
  state,
  reducers,
  effects,
}

export type TodosRematchStore = RematchStore<{ todos: typeof todosStore }>
export type TodosRematchState = ReturnType<TodosRematchStore['getState']>

/*
 * FIXME 修正 TodosRematchDispatch 的类型推导
 * export type TodosRematchDispatch = RematchDispatch<ReturnType<TodosRematchStore['model']>>
 */
export type TodosRematchDispatch = any

/**
 * 当前运行环境的 redux root store
 */
let reduxRootStore: TodosRematchStore

/**
 * 设置当前运行环境的 redux root store
 *
 * @param store - redux root store
 */
export const setReduxRootStore = (store: TodosRematchStore) => {
  reduxRootStore = store
}

/**
 * 获取当前运行环境的 redux root store
 *
 * @returns redux root store
 */
export const getReduxRootStore = () => reduxRootStore

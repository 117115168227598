/**
 * @file Session 相关 utils
 */

import { parseURLQuery } from '@seiue/util'

import { AuthorizeParams } from './types'

export * from './apis'
export * from './data'
export * from './types'
export * from './axios-utils'

/**
 * 是否是单点登录，现在，仅有腾讯校园小程序是单点登录
 *
 * @param authType - 登录的方式
 * @returns 是否
 */
export const isSSOLogin = (authType: AuthorizeParams['type'] | null) =>
  !!authType && ['tencent'].includes(authType)

/**
 * 从 url 中获取值。该方法还会在 url query 中查找 redirectUrl，如果有，则再次解析 redirectUrl 中的 query
 * 前端存在一个 ReflectionTransfer 机制，在用户通过推送点入口进入应用时，会在 url 中携带 rid 和 redirectUrl
 * 通过 rid 确定用户，然后再跳转到 redirectUrl，而 redirectUrl 中可能还会携带一些参数，这些参数需要通过该方法获取
 *
 * @param key - key
 * @returns value
 */
const getValueFromUrl = (key: string) => {
  // why window optional? 因为在 React Native 中，window 不存在
  const { [key]: value } = parseURLQuery(window?.location?.search) || {}

  if (value) {
    return value
  }

  const { redirectUrl } = parseURLQuery(window?.location?.search) || {}

  if (redirectUrl) {
    const [, search] = (redirectUrl as string).split('?')

    const { [key]: _value } = parseURLQuery(search) || {}

    if (_value) {
      return _value
    }
  }

  return null
}

/**
 * 从 URL 中获取单点登录的提供商
 *
 * @returns 单点登录的提供商
 */
export const getSSOProviderFromUrl = (): string | null => {
  const ssoProvider = getValueFromUrl('ssoProvider')

  if (ssoProvider) {
    return ssoProvider as string
  }

  return null
}

/**
 * 从 URL 中获取学校 ID
 *
 * @returns 学校 ID
 */
export const getSchoolIdFromUrl = () => {
  const schoolId = getValueFromUrl('schoolId')

  if (schoolId) {
    return Number(schoolId)
  }

  return null
}

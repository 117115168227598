import { useMemo } from 'react'

import { useSchoolConfigs } from 'packages/feature-utils/school-configs'
import { useCurrentReflection } from 'packages/features/sessions/utils/data'
import { School } from 'packages/sdks-next/chalk'

import { LogoInfo } from '../types'

/**
 * 读取 logo, 主题相关配置
 *
 * @param schoolId - 学校 id, 不一定是当前学校
 * @returns [configs, loading]
 */
export const useThemeSettings = (schoolId?: number) =>
  useSchoolConfigs(
    {
      schoolId,
      service: 'seiue.chalk',
      groupIn: ['system.theme', 'system.global'],
    },
    {
      disable: !schoolId,
      omitAuthorizationHeader: true,
      omitReflectionHeaders: true,
    },
  )

/**
 * 读取当前学校的主题色配置
 *
 * @returns 主题色配置
 */
export const useThemeColors = () => {
  const schoolId = useCurrentReflection()?.schoolId
  const { configs: themeSettings } = useThemeSettings(schoolId)

  const themeColor = themeSettings?.main_color || '#477cff'
  const navbarBackgroundColor = themeSettings?.navbar_bg_color || '#3B404B'

  return {
    themeColor,
    navbarBackgroundColor,
  }
}

/**
 * 获取初始化的 logoInfo
 *
 * @param school - 学校
 * @param defaultValue - 默认值
 * @returns 学校 logo 设置
 */
export const useLogoInfo = (
  school: School | null | undefined,
  defaultValue?: LogoInfo,
) => {
  const { configs } = useThemeSettings(school?.id)

  return useMemo(() => {
    const info: LogoInfo = { ...defaultValue }

    if (configs) {
      info.name = configs.name
      info.ename = configs.ename
      info.logo = configs.logo
      info.logoHeight = configs.logo_height
      info.showNavSchoolName = !configs.hide_nav_school_name
      info.showNavSystemName = !configs.hide_nav_system_name
    }

    if (info.logo === info.loginLogo) {
      info.isDiffLoginLogo = false
    }

    if (info.logo === info.foldedLogo) {
      info.isDiffLogo = false
    }

    info.schoolName = school?.name
    info.schoolEName = school?.ename
    return info
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs, school])
}

/**
 * @file 模块所对应的配置项值
 */
import { createEnumHelper, formatFileSize } from '@seiue/util'

import { $t } from 'packages/locale'
import { ElectionModeEnum, GroupSysTypeEnum } from 'packages/sdks-next/apollo'
import { PricingPlanConstraints } from 'packages/sdks-next/chalk'

import { ModuleEnum, PluginEnum } from './types'

/**
 * 获取模块项的 Label
 */
export const getConstraintLabel = createEnumHelper(() => ({
  globalMaxAttachmentSize: $t('附件上传大小限制'),
  globalMaxAttachmentCount: $t('附件上传数量限制'),
  electionEnabledModes: $t('可用的选课模式'),
  groupEnabledTypes: $t('可用的群组类型'),
  reflectionMaxStudentNum: $t('在校学生数量限制'),
  schcalEnableMultiSchcals: $t('多校历'),
  semesterEnableMultiTimetables: $t('多课表'),
}))

/**
 * 获取模块配置项的显示文本
 *
 * @param constraint - 配置项 name
 * @param values - 配置项值
 * @returns string
 */
export const getConstraintValueStr = (
  constraint: keyof PricingPlanConstraints,
  values?: PricingPlanConstraints,
) => {
  let valueStr = '-'

  if (values) {
    switch (constraint) {
      case 'globalMaxAttachmentSize':
        valueStr = `${formatFileSize(values[constraint] || 0, 'M')}`
        break
      case 'globalMaxAttachmentCount':
        valueStr = $t('{count}个', {
          count: values[constraint] || 0,
        })

        break
      case 'electionEnabledModes':
        valueStr =
          values[constraint]
            ?.map(mode => getElectionModeLabel.get(mode))
            .join('、') || $t('无')

        break
      case 'groupEnabledTypes':
        valueStr =
          values[constraint]
            ?.map(type => getGroupSysTypeLabel.get(type))
            .join('、') || $t('无')

        break
      case 'reflectionMaxStudentNum':
        valueStr = $t('{count}人', {
          count: values[constraint] || 0,
        })

        break
      case 'schcalEnableMultiSchcals':
      case 'semesterEnableMultiTimetables':
        valueStr = values[constraint] ? $t('启用') : $t('不启用')
        break
      default:
        valueStr = ''
    }
  }

  return `${getConstraintLabel.get(constraint)}: ${valueStr}`
}

/**
 * 获取模块限制项所对应的模块
 */
export const getConstraintModule = createEnumHelper({
  globalMaxAttachmentSize: null,
  globalMaxAttachmentCount: null,
  electionEnabledModes: PluginEnum.Election,
  reflectionMaxStudentNum: ModuleEnum.Reflection,
  schcalEnableMultiSchcals: ModuleEnum.Semester,
  semesterEnableMultiTimetables: ModuleEnum.Semester,
  groupEnabledTypes: PluginEnum.CustomizedGroup,
})

/**
 * 获取模块所对应的配置项
 *
 * @param moduleName - 模块名
 * @returns 配置项
 */
export const getModuleConstraints = (moduleName: string | null) => {
  return getConstraintModule
    .listOptions('const', 'module')
    .filter(option => option.module === moduleName)
    .map(option => option.const) as (keyof PricingPlanConstraints)[]
}

/**
 * 获取选课模式的 Label
 */
export const getElectionModeLabel = createEnumHelper(() => ({
  [ElectionModeEnum.InOrder]: $t('抢课模式'),
  [ElectionModeEnum.Lotting]: $t('抽签模式'),
}))

/**
 * 获取通用群组类型的 Label
 */
export const getGroupSysTypeLabel = createEnumHelper(() => ({
  [GroupSysTypeEnum.Activity]: $t('活动'),
  [GroupSysTypeEnum.Custom]: $t('自定义'),
  [GroupSysTypeEnum.Mentor]: $t('导师'),
  [GroupSysTypeEnum.Org]: $t('社团'),
}))

import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'

export interface RelationInterface {
  /**
   * 学校ID
   */
  schoolId: number
  /**
   * 问卷ID
   */
  questionnaireId: number
  /**
   * 被管理者ID
   */
  memberIds?: number[]
  /**
   * 管理者ID
   */
  adminIds?: number[]
  /**
   * 管理员信息
   */
  adminReflections?: SecuredReflectionInterface[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Relation implements RelationInterface {
  /**
   * 学校ID
   */
  schoolId: number
  /**
   * 问卷ID
   */
  questionnaireId: number
  /**
   * 被管理者ID
   */
  memberIds?: number[]
  /**
   * 管理者ID
   */
  adminIds?: number[]
  /**
   * 管理员信息
   */
  adminReflections?: SecuredReflection[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: RelationInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      questionnaireId: _questionnaireId,
      memberIds: _memberIds,
      adminIds: _adminIds,
      adminReflections: _adminReflections,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.questionnaireId = _questionnaireId
    this.memberIds = _memberIds
    this.adminIds = _adminIds
    this.adminReflections = _adminReflections
      ? _adminReflections.map(i => new SecuredReflection(i))
      : _adminReflections
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'schoolId',
    'questionnaireId',
    'memberIds',
    'adminIds',
    'adminReflections',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

import { useMemo } from 'react'

import { getSchoolIdFromShareURL } from 'packages/feature-utils/schools/utils'
import { useCurrentSchool } from 'packages/features/schools/hooks'
import { useCurrentReflection } from 'packages/features/sessions'
import { LogoInfo } from 'packages/features/system-settings/types'
import {
  useLogoInfo,
  useThemeColors,
  useThemeSettings,
} from 'packages/features/system-settings/utils/data'
import { useLocale } from 'packages/locale'
import {
  LocaleTextLocaleEnum,
  School,
  systemApi$loadSchool,
} from 'packages/sdks-next/chalk'

import { PluginEnum, useHasEnabledParentPlugin } from '../plugins'

/**
 * 判断当前学校是否应该显示用户管理的编辑权限
 *
 * @returns 是否
 */
export const useCanWriteUser = () => {
  const currentSchool = useCurrentSchool()
  return currentSchool?.chalkVer === '3.0'
}

/**
 * 根据 id 获取学校信息，如果没有 id 则获取当前学校信息
 *
 * @param schoolId - 学校 id
 * @returns 学校信息
 */
export const useSchool = (schoolId?: number | null) => {
  const school = useCurrentSchool()

  const { data: presentedSchool } = systemApi$loadSchool.useApi(
    {
      id: String(schoolId),
      query: {
        expand: ['customConstraints'],
      },
    },
    {
      disable: !schoolId,
      omitAuthorizationHeader: true,
      omitReflectionHeaders: true,
    },
  )

  return schoolId ? presentedSchool : school
}

/**
 * 获取 Layout 中 logo 的相关数据
 *
 * @param param0 - 参数
 * @param param0.school - 学校信息
 * @param param0.defaultLogoInfo - 默认 logo 数据
 * @param param0.extendLogoInfo - 扩展 logo 数据
 * @returns logo 数据
 */
export const useSchoolLogo = ({
  school,
  defaultLogoInfo,
  extendLogoInfo,
}: {
  school: School | null | undefined
  defaultLogoInfo?: LogoInfo
  extendLogoInfo?: LogoInfo
}) => {
  const locale = useLocale()
  const isCnLocale = locale === LocaleTextLocaleEnum.ZhCN

  let systemInfo: LogoInfo = useLogoInfo(school, defaultLogoInfo)

  systemInfo = {
    ...systemInfo,
    ...extendLogoInfo,
  }

  let name = ''
  if (isCnLocale) {
    name = `${systemInfo?.showNavSchoolName ? systemInfo?.schoolName : ''}${
      systemInfo?.showNavSystemName ? systemInfo?.name : ''
    }`
  } else {
    name = `${systemInfo?.showNavSchoolName ? systemInfo?.schoolEName : ''}${
      systemInfo?.showNavSystemName ? systemInfo?.ename : ''
    }`
  }

  return {
    name,
    logo: systemInfo?.logo,
    height: systemInfo?.logoHeight,
    isCnLocale,
  }
}

/**
 * 读取当前学校的主题色配置
 *
 * @returns 主题色配置
 */
export const useSchoolThemeColors = () => {
  const { themeColor, navbarBackgroundColor } = useThemeColors()

  // 是否已开启高级个性化插件
  const enabledPersonalization = useHasEnabledParentPlugin(
    PluginEnum.Personalization,
  )

  return useMemo(() => {
    return !enabledPersonalization
      ? {
          themeColor: '#477cff',
          navbarBackgroundColor: '#3B404B',
        }
      : {
          themeColor,
          navbarBackgroundColor,
        }
  }, [enabledPersonalization, navbarBackgroundColor, themeColor])
}

/**
 * 分享页主题 , 根据学校 id 改变
 *
 * @returns 主题色
 */
export const useSchoolThemeColorsForShare = () => {
  const schoolId = useCurrentReflection()?.schoolId
  const schoolIdFromShareURL = getSchoolIdFromShareURL()
  const { configs: themeSettings } = useThemeSettings(
    schoolIdFromShareURL || schoolId,
  )

  const themeColor = themeSettings?.main_color || '#477cff'
  const navbarBackgroundColor = themeSettings?.navbar_bg_color || '#3B404B'
  const isDefault = !(
    themeSettings?.main_color && themeSettings?.navbar_bg_color
  )

  return useMemo(() => {
    if (schoolIdFromShareURL) {
      return {
        themeColor,
        navbarBackgroundColor,
        isDefault,
      }
    }

    return {
      themeColor,
      navbarBackgroundColor,
      isDefault,
    }
  }, [navbarBackgroundColor, schoolIdFromShareURL, themeColor, isDefault])
}

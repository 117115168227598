import { moment } from '@seiue/moment'

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator('abnormal_attendance', message => ({
  modalName: 'AttendMessageModal',
  options: { messageId: message.id },
}))

registerMessageNavigator('miss_attendance', (message: any) => ({
  modalName: 'AttendancesSubmitModal',
  options: {
    classLesson: {
      id: message.attributes.lessonId,
      classId: message.attributes.classId,
      lessonName: message.attributes.lessonName,
      date: moment(message.createdAt).format('YYYY-MM-DD'),
    },
  },
}))

/**
 * @file 注册 chalk 的通知详情导航
 */

import { PathToDetailEnum } from 'packages/feature-utils/moral-assessments'
import { registerMessageNavigator } from 'packages/features/messages/utils'
import { getPath } from 'packages/route'

registerMessageNavigator(
  [
    'moral_assessment.raw_score_addition_for_evaluated',
    'moral_assessment.raw_score_tag_for_evaluated',
    'moral_assessment.raw_score_special_for_evaluated',
    'moral_assessment.score_for_evaluated',
  ],
  msg => {
    const { assessmentId, itemId } =
      (msg.attributes as {
        assessmentId: number
        itemId: number
      }) || {}

    return {
      path: `/moral-assessments/${PathToDetailEnum.AssessMe}/student/${assessmentId}/analyses?itemId=${itemId}`,
    }
  },
)

registerMessageNavigator(
  [
    'moral_assessment.assessment_published_for_evaluated',
    'moral_assessment.assessment_revoked_for_evaluated',
  ],
  msg => {
    const { assessmentId } =
      (msg.attributes as {
        assessmentId: number
      }) || {}

    return {
      path: `/moral-assessments/${PathToDetailEnum.AssessMe}/student/${assessmentId}/student/analyses`,
    }
  },
)

registerMessageNavigator(
  ['moral_assessment.assessment_incomplete_for_evaluator'],
  msg => {
    const { assessmentId } =
      (msg.attributes as {
        assessmentId: number
      }) || {}

    return {
      path: `/moral-assessments/${assessmentId}/entry`,
    }
  },
)

registerMessageNavigator(
  [
    'moral_assessment.raw_score_addition_for_evaluated_to_mentor',
    'moral_assessment.raw_score_tag_for_evaluated_to_mentor',
    'moral_assessment.raw_score_special_for_evaluated_to_mentor',
    'moral_assessment.score_for_evaluated_to_mentor',
  ],
  msg => {
    const { assessmentId, itemId, studentId, studentName } =
      (msg.attributes as {
        assessmentId: number
        itemId: number
        studentId: number
        studentName: string
      }) || {}

    const path = getPath('StudentMoralGradeProfileDetails', {
      id: assessmentId,
      pathType: PathToDetailEnum.AssessMe,
      itemId,
      rid: studentId,
      showName: studentName,
    })

    return {
      path,
    }
  },
)

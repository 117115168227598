import {
  todoApi$ignoreTodos,
  todoApi$queryExecutorTodos,
  counterApi$listMyCounters,
} from 'packages/sdks-next/chalk'

/**
 * 获取忽略的待办事项
 *
 * @returns 忽略的待办事项
 */
export const useIgnoreTodos = () => {
  const { api: ignoreTodos } = todoApi$ignoreTodos.useApi({
    reload: [todoApi$queryExecutorTodos.api, counterApi$listMyCounters.api],
  })

  return ignoreTodos
}

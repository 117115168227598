import { MenuPlacementEnum } from './menu-placement-enum'
import { MenuTypeEnum } from './menu-type-enum'
import { Expr } from './expr'
import { MenuSetting, MenuSettingInterface } from './menu-setting'

export interface MenuInterface {
  type: 'menu'
  appId: number
  name: string
  label: string
  icon?: string | null
  placement: MenuPlacementEnum
  menuType: MenuTypeEnum
  items?: MenuInterface[]
  /**
   * 绑定页面的名称
   */
  ref?: string | null
  /**
   * 显示条件
   */
  visible?: Expr | null
  /**
   * 菜单的一些设置
   */
  settings?: MenuSettingInterface | null
}

export class Menu implements MenuInterface {
  type: 'menu'
  appId: number
  name: string
  label: string
  icon?: string | null
  placement: MenuPlacementEnum
  menuType: MenuTypeEnum
  items?: Menu[]
  /**
   * 绑定页面的名称
   */
  ref?: string | null
  /**
   * 显示条件
   */
  visible?: Expr | null
  /**
   * 菜单的一些设置
   */
  settings?: MenuSetting | null

  constructor(props: MenuInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      type: _type,
      appId: _appId,
      name: _name,
      label: _label,
      icon: _icon,
      placement: _placement,
      menuType: _menuType,
      items: _items,
      ref: _ref,
      visible: _visible,
      settings: _settings,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.type = _type
    this.appId = _appId
    this.name = _name
    this.label = _label
    this.icon = _icon
    this.placement = _placement
    this.menuType = _menuType
    this.items = _items ? _items.map(i => new Menu(i)) : _items
    this.ref = _ref
    this.visible = _visible
    this.settings = _settings ? new MenuSetting(_settings) : _settings
  }

  static propKeys = [
    'type',
    'appId',
    'name',
    'label',
    'icon',
    'placement',
    'menuType',
    'items',
    'ref',
    'visible',
    'settings',
  ]
}

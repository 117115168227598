/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { ScoreReq, ScoreReqInterface } from './score-req'
import { Score, ScoreInterface } from './extends/score'

const buildConfig = createConfigBuilder('vnas')

export const defaultApi$publishKlassScores = {
  config(
    id: number,
    scoreReq: ScoreReqInterface[],
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'put',
      '/klass/items/{id}/scores/publish/$batch',
      {
        id,
      },
      null,
      scoreReq,
      {
        format: 'json',
        isArray: true,
        defaults: {
          id: (v: any) => v || 0,
          type: (v: any) => v || null,
          ownerId: (v: any) => v || 0,
          valid: (v: any) => v || false,
          invalidTypeId: (v: any) => (v === 0 ? v : v || null),
          invalidReason: (v: any) => v || '',
          tag: (v: any) => v || '',
          score: (v: any) => v || null,
          review: (v: any) => v || '',
          attachments: (v: any) => v || [],
          editReason: (v: any) => v || '',
          relatedData: (v: any) => v || null,
          generatedDate: (v: any) => v || null,
          status: (v: any) => v || null,
        },
      },
    )
  },

  /**
   * @summary Class PublishKlassScoresApi
   * 分数「发布」
   * @param id 评价项 ID
   * @param scoreReq
   * @param [options]
   * @return AxiosResponsePromise<Score[]>
   */

  api(
    id: number,
    scoreReq: ScoreReqInterface[],
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Score[]> {
    return axios(defaultApi$publishKlassScores.config(id, scoreReq, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Score(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({ id, payload }: { id: number; payload: ScoreReqInterface[] }) =>
        defaultApi$publishKlassScores.api(id, payload),
      mutationOptions,
    )
  },
}

// @ts-ignore
defaultApi$publishKlassScores.api._name_ = `Default.publishKlassScores`

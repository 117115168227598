import { AccItemBase, AccItemBaseInterface } from './acc-item-base'
import { AccItemTypeEnum } from './acc-item-type-enum'
import { AccItemScoringTypeEnum } from './acc-item-scoring-type-enum'
import { AccItemAggregatedTypeEnum } from './acc-item-aggregated-type-enum'
import { AccItemRelation, AccItemRelationInterface } from './acc-item-relation'
import { AccItemGauge, AccItemGaugeInterface } from './acc-item-gauge'

export interface AccItemInterface {
  parents?: AccItemBaseInterface[]
  /**
   * 素养评价 ID
   */
  assessmentId: number
  /**
   * 父维度 ID ，顶级维度为 null
   */
  parentId?: number | null
  /**
   * 默认为素养 item
   */
  type: AccItemTypeEnum
  /**
   * 开启算分
   */
  enableAggregated: boolean
  /**
   * 打分方式，默认为 null
   */
  scoringType?: AccItemScoringTypeEnum | null
  /**
   * 算分方式，默认为 null
   */
  aggregatedType?: AccItemAggregatedTypeEnum | null
  /**
   * 满分
   */
  fullScore?: string | null
  /**
   * 点亮条件分数
   */
  accomplishedScore?: string | null
  /**
   * 排序 0 ~ 65535
   */
  weight: number
  /**
   * 素养项、维度名称
   */
  name: string
  /**
   * 标签
   */
  tag?: string | null
  /**
   * 素养 item 的关联资源 expanded
   */
  itemRelations?: AccItemRelationInterface[]
  /**
   * 量规
   */
  gauges?: AccItemGaugeInterface[]
  /**
   * 素养名称 expanded
   */
  assessmentName?: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class AccItem implements AccItemInterface {
  parents?: AccItemBase[]
  /**
   * 素养评价 ID
   */
  assessmentId: number
  /**
   * 父维度 ID ，顶级维度为 null
   */
  parentId?: number | null
  /**
   * 默认为素养 item
   */
  type: AccItemTypeEnum
  /**
   * 开启算分
   */
  enableAggregated: boolean
  /**
   * 打分方式，默认为 null
   */
  scoringType?: AccItemScoringTypeEnum | null
  /**
   * 算分方式，默认为 null
   */
  aggregatedType?: AccItemAggregatedTypeEnum | null
  /**
   * 满分
   */
  fullScore?: string | null
  /**
   * 点亮条件分数
   */
  accomplishedScore?: string | null
  /**
   * 排序 0 ~ 65535
   */
  weight: number
  /**
   * 素养项、维度名称
   */
  name: string
  /**
   * 标签
   */
  tag?: string | null
  /**
   * 素养 item 的关联资源 expanded
   */
  itemRelations?: AccItemRelation[]
  /**
   * 量规
   */
  gauges?: AccItemGauge[]
  /**
   * 素养名称 expanded
   */
  assessmentName?: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: AccItemInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      parents: _parents,
      assessmentId: _assessmentId,
      parentId: _parentId,
      type: _type,
      enableAggregated: _enableAggregated,
      scoringType: _scoringType,
      aggregatedType: _aggregatedType,
      fullScore: _fullScore,
      accomplishedScore: _accomplishedScore,
      weight: _weight,
      name: _name,
      tag: _tag,
      itemRelations: _itemRelations,
      gauges: _gauges,
      assessmentName: _assessmentName,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.parents = _parents ? _parents.map(i => new AccItemBase(i)) : _parents
    this.assessmentId = _assessmentId
    this.parentId = _parentId
    this.type = _type
    this.enableAggregated = _enableAggregated
    this.scoringType = _scoringType
    this.aggregatedType = _aggregatedType
    this.fullScore = _fullScore
    this.accomplishedScore = _accomplishedScore
    this.weight = _weight
    this.name = _name
    this.tag = _tag
    this.itemRelations = _itemRelations
      ? _itemRelations.map(i => new AccItemRelation(i))
      : _itemRelations
    this.gauges = _gauges ? _gauges.map(i => new AccItemGauge(i)) : _gauges
    this.assessmentName = _assessmentName
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'parents',
    'assessmentId',
    'parentId',
    'type',
    'enableAggregated',
    'scoringType',
    'aggregatedType',
    'fullScore',
    'accomplishedScore',
    'weight',
    'name',
    'tag',
    'itemRelations',
    'gauges',
    'assessmentName',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

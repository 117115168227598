export enum GroupTypeEnum {
  PersonalGroup = 'seiue.personal_group',
  ClassGroup = 'seiue.class_group',
  CourseGroup = 'seiue.course_group',
  RelationGroup = 'seiue.relation_group',
  AdminclassGroup = 'seiue.adminclass_group',
  DormGroup = 'seiue.dorm_group',
  DepartmentGroup = 'seiue.department_group',
  RoleRelation = 'seiue.role_relation',
  PsychologicalCollaboratorGroup = 'seiue.psychological_collaborator_group',
}

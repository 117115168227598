export interface TermInterface {
  schoolId: number
  /**
   * 父ID
   */
  parentId: number
  /**
   * 权重
   */
  weight: number
  /**
   * 名称
   */
  name: string
  /**
   * 标签
   * 带有 i18n 翻译的 name 字段，只在部分类型中有翻译，目前仅家长身份有
   */
  label?: string
  /**
   * 描述
   */
  description?: string
  /**
   * 类型
   */
  type: string
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 归档时间
   */
  archivedAt?: string | null
  /**
   * 主键 ID
   */
  id: number
}

export class Term implements TermInterface {
  schoolId: number
  /**
   * 父ID
   */
  parentId: number
  /**
   * 权重
   */
  weight: number
  /**
   * 名称
   */
  name: string
  /**
   * 标签
   * 带有 i18n 翻译的 name 字段，只在部分类型中有翻译，目前仅家长身份有
   */
  label?: string
  /**
   * 描述
   */
  description?: string
  /**
   * 类型
   */
  type: string
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 归档时间
   */
  archivedAt?: string | null
  /**
   * 主键 ID
   */
  id: number

  constructor(props: TermInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      parentId: _parentId,
      weight: _weight,
      name: _name,
      label: _label,
      description: _description,
      type: _type,
      createdAt: _createdAt,
      archivedAt: _archivedAt,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.parentId = _parentId
    this.weight = _weight
    this.name = _name
    this.label = _label
    this.description = _description
    this.type = _type
    this.createdAt = _createdAt
    this.archivedAt = _archivedAt
    this.id = _id
  }

  static propKeys = [
    'schoolId',
    'parentId',
    'weight',
    'name',
    'label',
    'description',
    'type',
    'createdAt',
    'archivedAt',
    'id',
  ]
}

import { Attachment, AttachmentInterface } from './attachment'
import { AssignmentStatusEnum } from './assignment-status-enum'
import { TaskAssessmentTypeEnum } from './task-assessment-type-enum'
import { TaskStatusEnum } from './task-status-enum'
import { TaskAssignment, TaskAssignmentInterface } from './task-assignment'

export interface TaskInterface {
  schoolId: number
  /**
   * 任务标题
   */
  title: string
  /**
   * 任务内容
   */
  content?: string
  /**
   * 过期时间
   */
  expiredAt?: string | null
  attachments?: AttachmentInterface[]
  /**
   * 允许逾期提交，默认为不允许（false）
   */
  allowOverdueSubmit: boolean
  /**
   * 是否为团队任务，默认为否（false）
   */
  isTeamWork: boolean
  /**
   * 创建人 id
   */
  creatorId: number
  /**
   * 角色 ID
   */
  roleId: number
  /**
   * 所有分配的执行这状态的整体状态 Overall Task 状态
   */
  overallTaskStatus?: AssignmentStatusEnum | null
  /**
   * 关联群组ID
   */
  groupId?: number | null
  /**
   * 关联业务ID（过评等)
   */
  bizId?: number | null
  /**
   * 预计用时(以分为单位)
   */
  expectedTakeMinites?: number | null
  /**
   * 评价类型（设置）
   */
  assessmentType?: TaskAssessmentTypeEnum | null
  /**
   * 任务状态
   */
  generalStatus?: TaskStatusEnum | null
  /**
   * 发布时间
   */
  publishedAt?: string | null
  /**
   * 任务时间(published_at OR created_at)
   */
  displayTime?: string | null
  /**
   * 分类（大纲）ID
   */
  outlineId?: number
  /**
   * 权重
   */
  weight?: number
  /**
   * 是否公开成果
   */
  isPublicSubmission?: boolean
  /**
   * 父任务 ID
   */
  parentTaskId?: number | null
  assignments?: TaskAssignmentInterface[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Task implements TaskInterface {
  schoolId: number
  /**
   * 任务标题
   */
  title: string
  /**
   * 任务内容
   */
  content?: string
  /**
   * 过期时间
   */
  expiredAt?: string | null
  attachments?: Attachment[]
  /**
   * 允许逾期提交，默认为不允许（false）
   */
  allowOverdueSubmit: boolean
  /**
   * 是否为团队任务，默认为否（false）
   */
  isTeamWork: boolean
  /**
   * 创建人 id
   */
  creatorId: number
  /**
   * 角色 ID
   */
  roleId: number
  /**
   * 所有分配的执行这状态的整体状态 Overall Task 状态
   */
  overallTaskStatus?: AssignmentStatusEnum | null
  /**
   * 关联群组ID
   */
  groupId?: number | null
  /**
   * 关联业务ID（过评等)
   */
  bizId?: number | null
  /**
   * 预计用时(以分为单位)
   */
  expectedTakeMinites?: number | null
  /**
   * 评价类型（设置）
   */
  assessmentType?: TaskAssessmentTypeEnum | null
  /**
   * 任务状态
   */
  generalStatus?: TaskStatusEnum | null
  /**
   * 发布时间
   */
  publishedAt?: string | null
  /**
   * 任务时间(published_at OR created_at)
   */
  displayTime?: string | null
  /**
   * 分类（大纲）ID
   */
  outlineId?: number
  /**
   * 权重
   */
  weight?: number
  /**
   * 是否公开成果
   */
  isPublicSubmission?: boolean
  /**
   * 父任务 ID
   */
  parentTaskId?: number | null
  assignments?: TaskAssignment[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: TaskInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      title: _title,
      content: _content,
      expiredAt: _expiredAt,
      attachments: _attachments,
      allowOverdueSubmit: _allowOverdueSubmit,
      isTeamWork: _isTeamWork,
      creatorId: _creatorId,
      roleId: _roleId,
      overallTaskStatus: _overallTaskStatus,
      groupId: _groupId,
      bizId: _bizId,
      expectedTakeMinites: _expectedTakeMinites,
      assessmentType: _assessmentType,
      generalStatus: _generalStatus,
      publishedAt: _publishedAt,
      displayTime: _displayTime,
      outlineId: _outlineId,
      weight: _weight,
      isPublicSubmission: _isPublicSubmission,
      parentTaskId: _parentTaskId,
      assignments: _assignments,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.title = _title
    this.content = _content
    this.expiredAt = _expiredAt
    this.attachments = _attachments
      ? _attachments.map(i => new Attachment(i))
      : _attachments
    this.allowOverdueSubmit = _allowOverdueSubmit
    this.isTeamWork = _isTeamWork
    this.creatorId = _creatorId
    this.roleId = _roleId
    this.overallTaskStatus = _overallTaskStatus
    this.groupId = _groupId
    this.bizId = _bizId
    this.expectedTakeMinites = _expectedTakeMinites
    this.assessmentType = _assessmentType
    this.generalStatus = _generalStatus
    this.publishedAt = _publishedAt
    this.displayTime = _displayTime
    this.outlineId = _outlineId
    this.weight = _weight
    this.isPublicSubmission = _isPublicSubmission
    this.parentTaskId = _parentTaskId
    this.assignments = _assignments
      ? _assignments.map(i => new TaskAssignment(i))
      : _assignments
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'schoolId',
    'title',
    'content',
    'expiredAt',
    'attachments',
    'allowOverdueSubmit',
    'isTeamWork',
    'creatorId',
    'roleId',
    'overallTaskStatus',
    'groupId',
    'bizId',
    'expectedTakeMinites',
    'assessmentType',
    'generalStatus',
    'publishedAt',
    'displayTime',
    'outlineId',
    'weight',
    'isPublicSubmission',
    'parentTaskId',
    'assignments',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

/**
 * @file 伤口啊
 */

import { isArray } from '@seiue/util'
import React, { LazyExoticComponent } from 'react'

import { CalendarEventType } from 'packages/features/calendars/utils/types'
import { CalendarEvent as OriginCalendarEvent } from 'packages/sdks-next/chalk'

/**
 * 事件的渲染器
 */
const eventRender: { [key: string]: LazyExoticComponent<React.FC<any>> } = {}

const eventTagFuncMap: {
  [key: string]: (() => string) | undefined
} = {}

export interface CalendarEvent {
  type: CalendarEventType
  Component: LazyExoticComponent<React.FC<any>>
  getEventTag: () => string
}

export interface RegisterCalendarEvents {
  (events: CalendarEvent | CalendarEvent[]): void
}

export const registerCalendarEvents: RegisterCalendarEvents = events => {
  const newEvents = isArray(events) ? events : [events]

  newEvents.forEach(e => {
    eventRender[e.type] = e.Component
    eventTagFuncMap[e.type] = e.getEventTag
  })
}

export const getEventType = (event: OriginCalendarEvent) =>
  event.type || 'unknown'

export const getEventRender = (event: OriginCalendarEvent) =>
  eventRender[getEventType(event)]

export const getEventTag = (type: CalendarEventType | undefined) =>
  type ? eventTagFuncMap[type]?.() : null

import { ProviderDefinitionTypeEnum } from './provider-definition-type-enum'

export interface ProviderDefinitionInterface {
  /**
   * egg: appId
   */
  name: string
  /**
   * 平台ID
   */
  label: string
  /**
   * 描述
   */
  description?: string
  required?: boolean
  /**
   * 字段类型
   */
  type: ProviderDefinitionTypeEnum
  default?: string | null
}

export class ProviderDefinition implements ProviderDefinitionInterface {
  /**
   * egg: appId
   */
  name: string
  /**
   * 平台ID
   */
  label: string
  /**
   * 描述
   */
  description?: string
  required?: boolean
  /**
   * 字段类型
   */
  type: ProviderDefinitionTypeEnum
  default?: string | null

  constructor(props: ProviderDefinitionInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      label: _label,
      description: _description,
      required: _required,
      type: _type,
      default: _default,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.label = _label
    this.description = _description
    this.required = _required
    this.type = _type
    this.default = _default
  }

  static propKeys = [
    'name',
    'label',
    'description',
    'required',
    'type',
    'default',
  ]
}

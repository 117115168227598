import { NetdiskPermissionTypeEnum } from './netdisk-permission-type-enum'
import { NetdiskPermissionPermissionsEnum } from './netdisk-permission-permissions-enum'
import {
  NetdiskPermissionOwner,
  NetdiskPermissionOwnerInterface,
} from './netdisk-permission-owner'

export interface NetdiskPermissionInterface {
  /**
   * 目录ID
   */
  fileId: number
  /**
   * 用户层面的 reflection_id 部门层面的 department_id
   */
  ownerId: number
  /**
   * 类型
   */
  type: NetdiskPermissionTypeEnum
  /**
   * 权限
   */
  permissions?: NetdiskPermissionPermissionsEnum[]
  /**
   * expand owner
   */
  owner?: NetdiskPermissionOwnerInterface | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
}

export class NetdiskPermission implements NetdiskPermissionInterface {
  /**
   * 目录ID
   */
  fileId: number
  /**
   * 用户层面的 reflection_id 部门层面的 department_id
   */
  ownerId: number
  /**
   * 类型
   */
  type: NetdiskPermissionTypeEnum
  /**
   * 权限
   */
  permissions?: NetdiskPermissionPermissionsEnum[]
  /**
   * expand owner
   */
  owner?: NetdiskPermissionOwner | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: NetdiskPermissionInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      fileId: _fileId,
      ownerId: _ownerId,
      type: _type,
      permissions: _permissions,
      owner: _owner,
      id: _id,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.fileId = _fileId
    this.ownerId = _ownerId
    this.type = _type
    this.permissions = _permissions
    this.owner = _owner ? new NetdiskPermissionOwner(_owner) : _owner
    this.id = _id
    this.schoolId = _schoolId
  }

  static propKeys = [
    'fileId',
    'ownerId',
    'type',
    'permissions',
    'owner',
    'id',
    'schoolId',
  ]
}

export interface CounterInterface {
  id: number
  schoolId: number
  ownerId: number
  subject: string
  count: number
  expiredAt: string | null
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Counter implements CounterInterface {
  id: number
  schoolId: number
  ownerId: number
  subject: string
  count: number
  expiredAt: string | null
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: CounterInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      schoolId: _schoolId,
      ownerId: _ownerId,
      subject: _subject,
      count: _count,
      expiredAt: _expiredAt,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
    this.schoolId = _schoolId
    this.ownerId = _ownerId
    this.subject = _subject
    this.count = _count
    this.expiredAt = _expiredAt
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'id',
    'schoolId',
    'ownerId',
    'subject',
    'count',
    'expiredAt',
    'createdAt',
    'updatedAt',
  ]
}

import { atom } from 'jotai'

import { TermTypeEnum } from 'packages/features/terms/types'
import { termApi$queryTerm } from 'packages/sdks-next/chalk'

/**
 * 更新报表分类 atom 的触发 atom
 */
export const updateReportCategoriesFlagAtom = atom(1)

/**
 * 获取报表分类 atom
 */
export const reportCategoriesAtom = atom(async get => {
  get(updateReportCategoriesFlagAtom)

  const { data: reportTerms } = await termApi$queryTerm.api(
    TermTypeEnum.ReportCategory,
  )

  return reportTerms
})

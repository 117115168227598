/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  MessageUpdationReq,
  MessageUpdationReqInterface,
} from './message-updation-req'
import type { UpdateAllQuery } from './update-all-query'

const buildConfig = createConfigBuilder('chalk')

export const receivedMessageApi$updateAll = {
  config(
    messageUpdationReq: MessageUpdationReqInterface,
    updateAllQuery?: UpdateAllQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'patch',
      '/me/received-messages',
      {},
      updateAllQuery,
      messageUpdationReq,
      {
        format: 'json',
        isArray: false,
        defaults: {
          readed: (v: any) => v || false,
          receipted: (v: any) => v || false,
          receiptedImage: (v: any) => v || null,
        },
      },
    )
  },

  /**
   * @summary Class UpdateAllApi
   * @param messageUpdationReq
   * @param [updateAllQuery]
   * @param [receivedMessageQuery.type]
   * @param [receivedMessageQuery.readed]
   * @param [receivedMessageQuery.domain]
   * @param [receivedMessageQuery.domainIn]
   * @param [receivedMessageQuery.notice]
   * @param [receivedMessageQuery.owner.id] message owner 的 id
   * @param [options]
   * @return AxiosResponsePromise<null>
   */

  api(
    messageUpdationReq: MessageUpdationReqInterface,
    updateAllQuery?: UpdateAllQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<null> {
    return axios(
      receivedMessageApi$updateAll.config(
        messageUpdationReq,
        updateAllQuery,
        options,
      ),
    ) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({
        payload,
        query,
      }: {
        payload: MessageUpdationReqInterface
        query?: UpdateAllQuery
      }) => receivedMessageApi$updateAll.api(payload, query),
      mutationOptions,
    )
  },
}

// @ts-ignore
receivedMessageApi$updateAll.api._name_ = `ReceivedMessage.updateAll`

import { DateRange, DateRangeInterface } from './date-range'
import {
  TimetableDetailLesson,
  TimetableDetailLessonInterface,
} from './timetable-detail-lesson'

export interface TimetableDetailInterface {
  /**
   * 生效的时间段, 可配置多个时间段
   */
  effectiveDates?: DateRangeInterface[]
  /**
   * 生效的星期[1-7]
   */
  weekDays?: number[]
  /**
   * 在指定时间段和星期下的课节配置
   */
  lessons?: TimetableDetailLessonInterface[]
  /**
   * 课节间隔时间（单位：分钟）
   */
  lessonInterval?: number
}

export class TimetableDetail implements TimetableDetailInterface {
  /**
   * 生效的时间段, 可配置多个时间段
   */
  effectiveDates?: DateRange[]
  /**
   * 生效的星期[1-7]
   */
  weekDays?: number[]
  /**
   * 在指定时间段和星期下的课节配置
   */
  lessons?: TimetableDetailLesson[]
  /**
   * 课节间隔时间（单位：分钟）
   */
  lessonInterval?: number

  constructor(props: TimetableDetailInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      effectiveDates: _effectiveDates,
      weekDays: _weekDays,
      lessons: _lessons,
      lessonInterval: _lessonInterval,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.effectiveDates = _effectiveDates
      ? _effectiveDates.map(i => new DateRange(i))
      : _effectiveDates
    this.weekDays = _weekDays
    this.lessons = _lessons
      ? _lessons.map(i => new TimetableDetailLesson(i))
      : _lessons
    this.lessonInterval = _lessonInterval
  }

  static propKeys = ['effectiveDates', 'weekDays', 'lessons', 'lessonInterval']
}

export interface ClassOverlappedLessonInterface {
  /**
   * 存在课时重叠的其他班
   */
  classId: number
  /**
   * 存在与其他班课时重叠的当前班课节信息
   */
  lessons?: string[]
}

export class ClassOverlappedLesson implements ClassOverlappedLessonInterface {
  /**
   * 存在课时重叠的其他班
   */
  classId: number
  /**
   * 存在与其他班课时重叠的当前班课节信息
   */
  lessons?: string[]

  constructor(props: ClassOverlappedLessonInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      classId: _classId,
      lessons: _lessons,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.classId = _classId
    this.lessons = _lessons
  }

  static propKeys = ['classId', 'lessons']
}

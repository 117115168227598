import {
  GzzxExamScoreAnalysisCommonRule,
  GzzxExamScoreAnalysisCommonRuleInterface,
} from './gzzx-exam-score-analysis-common-rule'

export interface GzzxExamScoreAnalysisTranscriptRuleInterface {
  /**
   * 科目名称
   */
  subjectName: string
  /**
   * 科目排序
   */
  sort: number
  /**
   * 分析类型：score - 按分数，rank - 按排名
   */
  type: string
  /**
   * 分析规则
   */
  rules?: GzzxExamScoreAnalysisCommonRuleInterface[] | null
  /**
   * 学生是否可见排名
   */
  displayRank: boolean
  /**
   * 学生是否可见分数
   */
  displayScore: boolean
  /**
   * 学生是否可见等级
   */
  displayLevel: boolean
  /**
   * 学生是否可见等级赋分
   */
  displayLevelScore: boolean
  /**
   * 当前科目是否开启等级赋分
   */
  enableLevelScore: boolean
  /**
   * 当前科目是否参与总分计算
   */
  enableTotalCount: boolean
}

export class GzzxExamScoreAnalysisTranscriptRule
  implements GzzxExamScoreAnalysisTranscriptRuleInterface
{
  /**
   * 科目名称
   */
  subjectName: string
  /**
   * 科目排序
   */
  sort: number
  /**
   * 分析类型：score - 按分数，rank - 按排名
   */
  type: string
  /**
   * 分析规则
   */
  rules?: GzzxExamScoreAnalysisCommonRule[] | null
  /**
   * 学生是否可见排名
   */
  displayRank: boolean
  /**
   * 学生是否可见分数
   */
  displayScore: boolean
  /**
   * 学生是否可见等级
   */
  displayLevel: boolean
  /**
   * 学生是否可见等级赋分
   */
  displayLevelScore: boolean
  /**
   * 当前科目是否开启等级赋分
   */
  enableLevelScore: boolean
  /**
   * 当前科目是否参与总分计算
   */
  enableTotalCount: boolean

  constructor(props: GzzxExamScoreAnalysisTranscriptRuleInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      subjectName: _subjectName,
      sort: _sort,
      type: _type,
      rules: _rules,
      displayRank: _displayRank,
      displayScore: _displayScore,
      displayLevel: _displayLevel,
      displayLevelScore: _displayLevelScore,
      enableLevelScore: _enableLevelScore,
      enableTotalCount: _enableTotalCount,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.subjectName = _subjectName
    this.sort = _sort
    this.type = _type
    this.rules = _rules
      ? _rules.map(i => new GzzxExamScoreAnalysisCommonRule(i))
      : _rules
    this.displayRank = _displayRank
    this.displayScore = _displayScore
    this.displayLevel = _displayLevel
    this.displayLevelScore = _displayLevelScore
    this.enableLevelScore = _enableLevelScore
    this.enableTotalCount = _enableTotalCount
  }

  static propKeys = [
    'subjectName',
    'sort',
    'type',
    'rules',
    'displayRank',
    'displayScore',
    'displayLevel',
    'displayLevelScore',
    'enableLevelScore',
    'enableTotalCount',
  ]
}

/**
 * @file locale store
 */

import { filterAndReportError } from '@seiue/error-handler'
import { reportToEngineer } from '@seiue/sentry'
import { isArray, isReactNative, omit, times } from '@seiue/util'

import { SessionState } from 'packages/features/sessions/utils/types'
import {
  LocaleTextLocaleEnum,
  LocaleTextBelongsToEnum,
  i18nApi$queryLocaleTextsInVersion,
} from 'packages/sdks-next/chalk'
import { DictPaths } from 'packages/shared-stores/dicts'

import { baseLocales } from './base-messages'
import { initCustomLocale } from './custom-locale'
import { getDefaultLanguage } from './get-default-language'
import { Messages, UsefulLocaleEnum } from './types'
import { getLocaleVersion } from './utils'

// 刷新缓存时间，Sec
const StaleTime = 60 * 10

export interface State {
  version: number
  locale: UsefulLocaleEnum | null
  schoolId: number
  messages: Messages
  cacheTime: number

  // refreshSign 用于决定页面是否根据当前的配置重载
  refreshSign: boolean

  // 是否已完成初始化
  inited: boolean
}

const state: State = {
  version: 0,
  locale: null,
  schoolId: 0,
  messages: {},
  refreshSign: false,
  cacheTime: Date.now(),
  inited: false,
}

const reducers = {
  setVersion(prevState: State, version: number) {
    return {
      ...prevState,
      version,
    }
  },

  setLocale(prevState: State, locale: LocaleTextLocaleEnum) {
    return {
      ...prevState,
      locale,
    }
  },

  setSchoolId(prevState: State, schoolId: number) {
    return {
      ...prevState,
      schoolId,
    }
  },

  setMessages(prevState: State, messages: Messages) {
    return {
      ...prevState,
      messages,
    }
  },

  setRefreshSign(prevState: State, refreshSign: boolean) {
    return {
      ...prevState,
      refreshSign,
    }
  },

  setInited(prevState: State, inited: boolean) {
    return {
      ...prevState,
      inited,
    }
  },

  refreshCacheTime(prevState: State) {
    return {
      ...prevState,
      cacheTime: Date.now(),
    }
  },
}

/**
 * 获取线上的语言设置，所有设备共用一个设置
 *
 * @param dispatch - dispatch
 * @returns 语言设置
 */
export const queryLocaleValue = async (dispatch: any) => {
  let localeValue
  try {
    localeValue = (await dispatch.dicts.find({
      path: DictPaths.ChalkLocale,
    })) as UsefulLocaleEnum | null
  } catch (e) {
    filterAndReportError(e, { ExceptionType: 'GetLocaleSetting' })
  }

  if (!localeValue) {
    /*
     * 如果线上没有保存设置，那么上传一份到线上去
     * 异步的去上传
     */
    dispatch.dicts
      .update({
        path: DictPaths.ChalkLocale,
        value: getDefaultLanguage(),
      })
      .catch((e: any) => {
        filterAndReportError(e, {
          ExceptionType: 'UploadLocaleSetting',
        })
      })
  }

  return localeValue || getDefaultLanguage()
}

const effects = (dispatch: any) => {
  const currentSystemVersion = getLocaleVersion()

  // 获取线上的语言包
  const queryLocaleTexts = async (
    version: number,
    locale: UsefulLocaleEnum,
    isLogined: boolean,
  ) => {
    try {
      const { data } = await i18nApi$queryLocaleTextsInVersion.api(
        `${version}`,
        locale,
        {
          belongsToIn: [
            LocaleTextBelongsToEnum.Fe,
            LocaleTextBelongsToEnum.Both,
          ].join(','),
          fields: 'content,ref_to',
          // schoolId 接口从 query > headers 中获取
          // 如果未登录，就在 query 中传 0（取的是 apollo 的多语言配置）
          // 登录了 query 中不传，会取 headers 里的 x-school-id（取的是个性化设置里用户自定义后的多语言配置）
          schoolId: isLogined ? undefined : 0,
        },
        !isLogined ? { omitAuthorizationHeader: true } : undefined,
      )

      let localeTexts = data || []

      if (!isArray(data)) {
        reportToEngineer(
          new Error('怪异的 Locale Data'),
          {
            ExceptionType: 'GetLocale',
          },
          {
            extra: {
              localeTexts: data,
            },
          },
        )

        localeTexts = []
      }

      // 因为多语言的数据相当大，所以将此处的数据处理 Chunk 化，使其不阻塞渲染
      const messages: Messages = {}

      const splitNum = 100
      const splitPromiseCount = Math.ceil(localeTexts.length / splitNum)

      await Promise.all(
        times(
          splitPromiseCount,
          index =>
            new Promise(resolve => {
              const splitedLocaleTexts = localeTexts.slice(
                index * 100,
                (index + 1) * 100,
              )

              splitedLocaleTexts.forEach(({ refTo, content }) => {
                messages[refTo] = content
              })

              resolve(undefined)
            }),
        ),
      )

      dispatch.locale.refreshCacheTime()
      dispatch.locale.setMessages(messages)
    } catch (e) {
      /*
       * 需要更进一步的错误处理
       * 此时为忽略并加载基础的错误
       */
      filterAndReportError(e, { ExceptionType: 'GetLocale' })

      dispatch.locale.setMessages(baseLocales[locale])
    }
  }

  return {
    // 刷新语言包，每次应用初始化时调用
    async inited(
      enabledI18n: boolean,
      { locale, session }: { locale: State; session: SessionState },
    ) {
      // 如果版本不同，则清空缓存，重新刷新
      if (locale.version && locale.version !== currentSystemVersion) {
        dispatch.locale.setVersion(0)
        return dispatch.locale.inited()
      }

      const sessionSchoolId = session.currentReflection?.schoolId || 0

      // 已登录
      if (session.state === 'created') {
        // go app 登录完成后，更新用户的多语言设置（如果用户用英文界面登录，那么说明他希望系统是英文的）
        // Chalk/go-web 相关逻辑已在 passport 中处理
        const localeFromNativeLogin = isReactNative ? locale.locale : null
        if (isReactNative && localeFromNativeLogin && enabledI18n) {
          await dispatch.dicts.update({
            path: DictPaths.ChalkLocale,
            value: localeFromNativeLogin,
          })
        }

        if (!locale.schoolId && sessionSchoolId) {
          dispatch.locale.setSchoolId(sessionSchoolId)
        } else if (sessionSchoolId !== locale.schoolId) {
          // 如果学校不同，则清空缓存，重新刷新
          dispatch.locale.setVersion(0)
          dispatch.locale.setSchoolId(sessionSchoolId)
          return dispatch.locale.inited()
        }

        await Promise.all([
          dispatch.locale.load(enabledI18n),
          initCustomLocale(),
        ])
      }
      // 未登录，或者无缓存的情况下，重置多语言
      else if (session.state === 'none' || !locale.locale) {
        const defaultLocale =
          locale.locale ||
          (enabledI18n ? getDefaultLanguage() : LocaleTextLocaleEnum.ZhCN)

        if (!locale.version) {
          await queryLocaleTexts(currentSystemVersion, defaultLocale, false)

          dispatch.locale.setLocale(defaultLocale)
          dispatch.locale.setVersion(currentSystemVersion)
        } else {
          await queryLocaleTexts(currentSystemVersion, defaultLocale, false)
        }
      }

      dispatch.locale.setInited(true)

      return true
    },

    // 从服务器加载配置
    async load(enabledI18n: boolean, { locale }: { locale: State }) {
      // 如果不启用本地化，那么始终获取中文文本
      const localeValue = !enabledI18n
        ? LocaleTextLocaleEnum.ZhCN
        : await queryLocaleValue(dispatch)

      // 如果没有缓存，获取数据进行缓存
      if (!locale.version) {
        await queryLocaleTexts(currentSystemVersion, localeValue, true)

        dispatch.locale.setLocale(localeValue)
        dispatch.locale.setVersion(currentSystemVersion)

        return true
      }

      // 如果本地缓存与线上所设置的语言环境不同，清空环境，重新加载
      if (locale.locale !== localeValue) {
        dispatch.locale.setVersion(0)

        return dispatch.locale.load(enabledI18n)
      }

      // 什么都一样的话，且超过缓存时间，后台刷新一下语言包，在下一次登陆时，会自动重载新的语言包。
      if (Date.now() - locale.cacheTime > StaleTime * 1000) {
        await queryLocaleTexts(currentSystemVersion, localeValue, true)
      }

      return true
    },

    // 主动更新 locale 语言和 dict 配置（chalk/go/go 登录页调用）
    async updateLocale(
      nextLocale: UsefulLocaleEnum,
      { locale, session }: { locale: State; session: SessionState },
    ) {
      if (nextLocale !== locale.locale) {
        await dispatch.dicts.update({
          path: DictPaths.ChalkLocale,
          value: nextLocale,
        })

        const isLogined = !!session.currentReflection
        await queryLocaleTexts(currentSystemVersion, nextLocale, isLogined)

        dispatch.locale.setLocale(nextLocale)

        requestAnimationFrame(() => {
          dispatch.locale.setRefreshSign(true)
        })
      }

      return true
    },
  }
}

export const localeStore = {
  state,
  reducers,
  effects,
}

export const localePersistTransform = {
  in: (persistState: any, key: any) => {
    if (key === 'locale') {
      // 忽略掉 refreshSign 和 inited 这两个标志位
      return omit(persistState, ['refreshSign', 'inited'])
    }

    return persistState
  },
  out: (val: any) => val,
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Item, ItemInterface } from './extends/item'
import type { LoadDormItemQuery } from './load-dorm-item-query'

const buildConfig = createConfigBuilder('vnas')

export const dormApi$loadDormItem = {
  config(
    id: number,
    loadDormItemQuery?: LoadDormItemQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/dorm/items/{id}',
      {
        id,
      },
      loadDormItemQuery,
    )
  },

  /**
   * @summary 获取单个评价结构
   * @param id
   * @param [loadDormItemQuery]
   * @param [dormQuery.expand]
   * @param [options]
   * @return AxiosResponsePromise<Item>
   */

  api<const TQuery extends LoadDormItemQuery>(
    id: number,
    loadDormItemQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Item, TQuery['expand']>> {
    return axios(dormApi$loadDormItem.config(id, loadDormItemQuery, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new Item(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends LoadDormItemQuery,
    TSelected = Expand<Item, TQuery['expand']>,
  >(
    {
      id,
      query,
    }: {
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<Item, TQuery['expand']>,
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Item, TQuery['expand']>, TSelected>(
      dormApi$loadDormItem.api,
      'Dorm.loadDormItem',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
dormApi$loadDormItem.api._name_ = `Dorm.loadDormItem`

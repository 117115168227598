import { useMemo } from 'react'

import { useEnabledPlugins } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PluginSourceEnum } from 'packages/sdks-next/chalk'

import { MessageDomainEnum } from '../types'

export const OpenPlatformDomainPrefix = 'open.'

/**
 * 获取消息领域选项
 *
 * @returns 选项
 */
export const useMessageDomainOptions = () => {
  const [plugins] = useEnabledPlugins()

  return useMemo(() => {
    return [
      ...getMessageDomainOptions(),
      // 获取三方应用，他们的 domain 以 OpenPlatformDomainPrefix 开头，并可能接入消息
      ...plugins
        .filter(plugin => {
          return plugin.plugin.source === PluginSourceEnum.OpenPlatform
        })
        .map(plugin => ({
          value: `${OpenPlatformDomainPrefix}${plugin.pluginName}`,
          label: plugin.label,
        })),
    ]
  }, [plugins])
}

/**
 * 获取消息领域选项
 *
 * @returns 选项
 */
const getMessageDomainOptions = () => [
  {
    value: MessageDomainEnum.ClassAssessment,
    label: $t('成绩'),
  },
  {
    value: MessageDomainEnum.DormAssessment,
    label: $t('评价'),
  },
  {
    value: MessageDomainEnum.Task,
    label: $t('任务'),
  },
  {
    value: MessageDomainEnum.Chat,
    label: $t('约谈'),
  },
  {
    value: MessageDomainEnum.PsyChat,
    label: $t('咨询'),
  },
  {
    value: MessageDomainEnum.Group,
    label: $t('讨论'),
  },
  {
    value: MessageDomainEnum.Dorm,
    label: $t('宿舍'),
  },
  {
    value: MessageDomainEnum.Attendance,
    label: $t('考勤'),
  },
  {
    value: MessageDomainEnum.LeaveFlow,
    label: $t('请假'),
  },
  {
    value: MessageDomainEnum.Schedule,
    label: $t('日程'),
  },
  {
    value: MessageDomainEnum.Schcal,
    label: $t('校历'),
  },
  {
    value: MessageDomainEnum.Notification,
    label: $t('通知'),
  },
  {
    value: MessageDomainEnum.ClassAdjustment,
    label: $t('调代课'),
  },
  {
    value: MessageDomainEnum.Evaluation,
    label: $t('评教'),
  },
  // FIXME MessageDomainEnum.MemberType 应该和 MessageDomainEnum.Class 合并
  {
    value: MessageDomainEnum.MemberType,
    label: $t('课程'),
  },
  {
    value: MessageDomainEnum.Class,
    label: $t('课程'),
  },
  {
    value: MessageDomainEnum.SubjectSelection,
    label: $t('选科'),
  },
  {
    value: MessageDomainEnum.Venue,
    label: $t('场地预约'),
  },
  {
    value: MessageDomainEnum.Declaration,
    label: $t('课程申报'),
  },
  {
    value: MessageDomainEnum.ExamSchedule,
    label: $t('排考场'),
  },
  {
    value: MessageDomainEnum.Certification,
    label: $t('认证'),
  },
  {
    value: MessageDomainEnum.ClassStop,
    label: $t('停课'),
  },
  {
    value: MessageDomainEnum.Election,
    label: $t('选课'),
  },
  {
    value: MessageDomainEnum.SchoolPlugin,
    label: $t('插件商店'),
  },
  {
    value: MessageDomainEnum.Event,
    label: $t('活动'),
  },
  {
    value: MessageDomainEnum.CustomGroupNotice,
    label: $t('群组公告'),
  },
  {
    value: MessageDomainEnum.CustomGroupSignupSubmission,
    label: $t('群组报名'),
  },
  {
    value: MessageDomainEnum.CustomGroup,
    label: $t('群组'),
  },
  {
    value: MessageDomainEnum.Approval,
    label: $t('审批'),
  },
  {
    value: MessageDomainEnum.Psychological,
    label: $t('心理'),
  },
  {
    value: MessageDomainEnum.ClassIn,
    label: $t('插件'),
  },
  {
    value: MessageDomainEnum.TeacherProfile,
    label: $t('教师档案'),
  },
  {
    value: MessageDomainEnum.Visitor,
    label: $t('访客'),
  },
  {
    value: MessageDomainEnum.Questionnaire,
    label: $t('问卷'),
  },
  {
    value: MessageDomainEnum.Handout,
    label: $t('学案'),
  },
  {
    value: MessageDomainEnum.AdminClass,
    label: $t('行政班'),
  },
  {
    value: MessageDomainEnum.Exam,
    label: $t('考试'),
  },
  {
    value: MessageDomainEnum.Contract,
    label: $t('契约'),
  },
  {
    value: MessageDomainEnum.SzHomeWorkSurvey,
    label: $t('作业调查'),
  },
  {
    value: MessageDomainEnum.IntlGoal,
    label: $t('升学目标'),
  },
  {
    value: MessageDomainEnum.MoralAssessment,
    label: $t('德育评价'),
  },
  {
    value: MessageDomainEnum.TeacherAssessment,
    label: $t('教师考核'),
  },
  {
    value: MessageDomainEnum.ClassReview,
    label: $t('听评课'),
  },
  {
    value: MessageDomainEnum.AITeacher,
    label: $t('AI教师'),
  },
]

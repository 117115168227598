/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { JoinedGroup, JoinedGroupInterface } from './joined-group'
import type { FindJoinedGroupsQuery } from './find-joined-groups-query'

const buildConfig = createConfigBuilder('chalk')

export const groupApi$findJoinedGroups = {
  config(
    id: number,
    findJoinedGroupsQuery?: FindJoinedGroupsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/group/reflections/{id}/groups',
      {
        id,
      },
      findJoinedGroupsQuery,
    )
  },

  /**
   * @summary Class FindJoinedGroups
   * @param id owner rid
   * @param [findJoinedGroupsQuery]
   * @param [groupQuery.scope] 指定群组作用域, 规则见 Group.scope 说明
   * @param [groupQuery.scopeIn] 指定群组作用域, 规则见 Group.scope 说明
   * @param [groupQuery.memberType] 成员类型，如管理员,教师等
   * @param [groupQuery.memberTypeIn] 成员类型，如管理员,教师，可选值见 MemberTypeEnum。
   * @param [groupQuery.enhancer] 已废弃，使用 scope 或 scope_in 代替
   * @param [groupQuery.calLeaveStatusByRid] 计算指定用户在群组中的状态
   * @param [groupQuery.withTrashed] 包含软删除的group
   * @param [groupQuery.gmWithTrashed] 包含成员已经离开的群组
   * @param [groupQuery.gmOnlyTrashed] 仅成员已经离开的群组
   * @param [groupQuery.semesterId] 需要指定学期时
   * @param [groupQuery.status] 群组状态
   * @param [groupQuery.fields]
   * @param [groupQuery.onlySystemGroup] 只查询系统群组包含自定义群组
   * @param [groupQuery.paginated] 是否分页，默认 1
   * @param [groupQuery.page] 显示的数据页，默认 1
   * @param [groupQuery.perPage] 每页显示数量，默认 20
   * @param [groupQuery.expand] expand
   * - members
   * - members.reflection : 成员基本信息
   * - members.reflection
   * - managers
   * - manageds
   * - related
   * - teams.members.reflection
   * - members.teams
   * - type_entity (仅用于通用群组)
   * @param [options]
   * @return AxiosResponsePromise<JoinedGroup[]>
   */

  api<const TQuery extends FindJoinedGroupsQuery>(
    id: number,
    findJoinedGroupsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<JoinedGroup, TQuery['expand']>[]> {
    return axios(
      groupApi$findJoinedGroups.config(id, findJoinedGroupsQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new JoinedGroup(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends FindJoinedGroupsQuery,
    TSelected = Expand<JoinedGroup, TQuery['expand']>[],
  >(
    {
      id,
      query,
    }: {
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<JoinedGroup, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<JoinedGroup, TQuery['expand']>[], TSelected>(
      groupApi$findJoinedGroups.api,
      'Group.findJoinedGroups',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
groupApi$findJoinedGroups.api._name_ = `Group.findJoinedGroups`

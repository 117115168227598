import { Ellipsis } from '@seiue/ui'
import { getTextWidth, useSize } from '@seiue/util'
import React, { CSSProperties, useMemo, useRef } from 'react'
import styled from 'styled-components'

import { useLogoData } from '@/App/hooks'

import seiueLogoCN from './assets/seiue_logo_cn.png'
import seiueLogoEn from './assets/seiue_logo_en.png'

// 获取一个满足剩余宽度的文本字号
const getMatchedFontSize = (availableWidth: number, text: string) => {
  return (
    [15, 14, 13].find(size => {
      const nameWidth = getTextWidth(
        text,
        `600 ${size}px "'Helvetica Neue', 'Roboto', 'Arial', 'PingFang SC', 'Hiragino Sans GB', 'Source Han Sans SC', 'Microsoft YaHei', 'SimSun', sans-serif"`,
      )

      return nameWidth < availableWidth
    }) || 13
  )
}

/**
 * 侧边栏 logo
 *
 * @param param0 - 参数
 * @param param0.presentedSchoolId - 学校 ID
 * @param param0.style - 样式
 * @param param0.onClick - 点击事件
 * @returns 组件
 */
export const SiderLogo: React.FC<{
  presentedSchoolId?: number | null
  style?: CSSProperties
  onClick?: () => void
}> = ({ presentedSchoolId, style, onClick }) => {
  const { name, logo, height, isCnLocale } = useLogoData(presentedSchoolId)

  const avatarRef = useRef(null)
  const avatarSize = useSize(avatarRef)
  // 可用宽度 = logo 总宽度 - 内边距 - logo 宽度
  const availableWidth = 215 - 28 - (avatarSize?.width || 0)
  const matchedFontSize = useMemo(
    () => getMatchedFontSize(availableWidth, name),
    [availableWidth, name],
  )

  return (
    <Logo
      style={{
        flexShrink: 0,
        ...style,
      }}
      onClick={onClick}
    >
      <SystemAvatarContainer ref={avatarRef}>
        {logo ? (
          <SystemAvatarImg height={height || 0} src={logo} />
        ) : (
          <img
            src={isCnLocale ? seiueLogoCN : seiueLogoEn}
            style={{
              height,
            }}
          />
        )}
      </SystemAvatarContainer>
      {name ? (
        <Ellipsis text={name} lineClamp={2}>
          <AppName size={matchedFontSize}>{name}</AppName>
        </Ellipsis>
      ) : null}
    </Logo>
  )
}

const Logo = styled.div`
  width: 215px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 28px;
  min-height: 40px;
  max-height: 52px;
  cursor: pointer;
  line-height: normal;
`

const SystemAvatarContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  max-height: 52px;
  padding-right: 8px;
  overflow: hidden;
`

const SystemAvatarImg = styled.img`
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`

const AppName = styled.div.attrs((props: { size: number }) => ({
  size: props.size,
}))`
  color: #fff;
  font-weight: 600;
  line-height: 18px;
  font-size: ${props => `${props.size}px`};
  word-break: break-word;
`

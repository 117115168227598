import { AssessmentStageStatusEnum } from './assessment-stage-status-enum'
import { StageBase, StageBaseInterface } from './stage-base'

export interface AssessmentStageInterface {
  assessmentId: number
  stageId: number
  status: AssessmentStageStatusEnum
  /**
   * expanded
   */
  stage?: StageBaseInterface
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
}

export class AssessmentStage implements AssessmentStageInterface {
  assessmentId: number
  stageId: number
  status: AssessmentStageStatusEnum
  /**
   * expanded
   */
  stage?: StageBase
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: AssessmentStageInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      assessmentId: _assessmentId,
      stageId: _stageId,
      status: _status,
      stage: _stage,
      id: _id,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.assessmentId = _assessmentId
    this.stageId = _stageId
    this.status = _status
    this.stage = _stage ? new StageBase(_stage) : _stage
    this.id = _id
    this.schoolId = _schoolId
  }

  static propKeys = [
    'assessmentId',
    'stageId',
    'status',
    'stage',
    'id',
    'schoolId',
  ]
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import type { BuildPermissionsQuery } from './build-permissions-query'

const buildConfig = createConfigBuilder('chalk')

export const searchApi$buildPermissions = {
  config(
    semesterId: number,
    buildPermissionsQuery?: BuildPermissionsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/search/permissions/build',
      {
        semesterId,
      },
      buildPermissionsQuery,
    )
  },

  /**
   * @summary 预构建查询权限数据
   * @param semesterId 当前学期
   * @param [buildPermissionsQuery]
   * @param [searchQuery.fePermissions] 前端声明的权限，例如 notice ，则匹配 search_items.permission_data.fe_permission:notice。不带 `fe_permission:` 前缀
   * @param [options]
   * @return AxiosResponsePromise<null>
   */

  api(
    semesterId: number,
    buildPermissionsQuery?: BuildPermissionsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<null> {
    return axios(
      searchApi$buildPermissions.config(
        semesterId,
        buildPermissionsQuery,
        options,
      ),
    ) as any
  },

  useApi<TSelected = null>(
    {
      semesterId,
      query,
    }: {
      semesterId: number
      query?: BuildPermissionsQuery
    },
    queryOptions?: QueryOptionsWithSelect<null, TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<null, TSelected>(
      searchApi$buildPermissions.api,
      'Search.buildPermissions',
      queryOptions,
      semesterId,
      query,
    )
  },
}

// @ts-ignore
searchApi$buildPermissions.api._name_ = `Search.buildPermissions`

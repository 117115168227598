import { OpenStatusEnum } from './open-status-enum'
import { OpenTimeRange, OpenTimeRangeInterface } from './open-time-range'
import { Term, TermInterface } from './extends/term'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import { Scope, ScopeInterface } from './scope'
import { Place, PlaceInterface } from './place'

export interface VenueInterface {
  id: number
  /**
   * 场地名称
   */
  name: string
  /**
   * 场地类型id 对应term_id
   */
  typeId?: number | null
  /**
   * 容量
   */
  capacity?: number | null
  /**
   * 是否需要审批 false不需要，true 需要
   */
  needApproval: boolean
  /**
   * 开放状态 (关闭，开放，维修)('opened','closed','repair')
   */
  openStatus: OpenStatusEnum
  /**
   * 场地说明
   */
  description: string
  /**
   * 预约说明是否显示
   */
  orderDescriptionDisplay?: boolean
  /**
   * 预约说明(1-9999)
   */
  orderDescription?: string | null
  /**
   * 审批中是否可以预约 false不能，true 能
   */
  approvalingOrderEnable: boolean
  /**
   * 开放对象ID
   */
  scopeIds: number[]
  /**
   * 预约时长上线（小时 ，0 不限）
   */
  timeLimit?: number | null
  /**
   * time_limit_enable  不限制时长 false, 限制时长true
   */
  timeLimitEnable: boolean
  /**
   * 可预约天数
   */
  maxOrderDays?: number
  /**
   * 是否启用可预约天数
   */
  enableMaxOrderDays?: boolean
  /**
   * 是否可见已预约的信息
   */
  showOrderDetail?: boolean
  /**
   * 提前时间(分钟，小时，天，具体看early_type)
   */
  earlyLimit?: number | null
  /**
   * 提前时间类型(minutes 分钟， hours小时，days天)
   */
  earlyType?: string
  /**
   * 场地开放时间
   */
  openTimeRanges: OpenTimeRangeInterface[]
  /**
   * 场地设备
   */
  equipments?: string[]
  /**
   * 场地管理员
   */
  adminIds: number[]
  type?: TermInterface | null
  admins?: SecuredReflectionInterface[]
  scopes?: ScopeInterface[]
  places?: PlaceInterface[]
  placeIds?: number[]
  orderCount?: number
  /**
   * 场地关注ID
   */
  vfId?: number | null
  /**
   * 场地ID
   */
  vfVenueId?: number | null
  /**
   * 场地关注人ID
   */
  vfFollowerId?: number | null
  /**
   * 场地关注开始时间
   */
  vfStartAt?: string | null
  /**
   * 场地关注结束时间
   */
  vfEndAt?: string | null
}

export class Venue implements VenueInterface {
  id: number
  /**
   * 场地名称
   */
  name: string
  /**
   * 场地类型id 对应term_id
   */
  typeId?: number | null
  /**
   * 容量
   */
  capacity?: number | null
  /**
   * 是否需要审批 false不需要，true 需要
   */
  needApproval: boolean
  /**
   * 开放状态 (关闭，开放，维修)('opened','closed','repair')
   */
  openStatus: OpenStatusEnum
  /**
   * 场地说明
   */
  description: string
  /**
   * 预约说明是否显示
   */
  orderDescriptionDisplay?: boolean
  /**
   * 预约说明(1-9999)
   */
  orderDescription?: string | null
  /**
   * 审批中是否可以预约 false不能，true 能
   */
  approvalingOrderEnable: boolean
  /**
   * 开放对象ID
   */
  scopeIds: number[]
  /**
   * 预约时长上线（小时 ，0 不限）
   */
  timeLimit?: number | null
  /**
   * time_limit_enable  不限制时长 false, 限制时长true
   */
  timeLimitEnable: boolean
  /**
   * 可预约天数
   */
  maxOrderDays?: number
  /**
   * 是否启用可预约天数
   */
  enableMaxOrderDays?: boolean
  /**
   * 是否可见已预约的信息
   */
  showOrderDetail?: boolean
  /**
   * 提前时间(分钟，小时，天，具体看early_type)
   */
  earlyLimit?: number | null
  /**
   * 提前时间类型(minutes 分钟， hours小时，days天)
   */
  earlyType?: string
  /**
   * 场地开放时间
   */
  openTimeRanges: OpenTimeRange[]
  /**
   * 场地设备
   */
  equipments?: string[]
  /**
   * 场地管理员
   */
  adminIds: number[]
  type?: Term | null
  admins?: SecuredReflection[]
  scopes?: Scope[]
  places?: Place[]
  placeIds?: number[]
  orderCount?: number
  /**
   * 场地关注ID
   */
  vfId?: number | null
  /**
   * 场地ID
   */
  vfVenueId?: number | null
  /**
   * 场地关注人ID
   */
  vfFollowerId?: number | null
  /**
   * 场地关注开始时间
   */
  vfStartAt?: string | null
  /**
   * 场地关注结束时间
   */
  vfEndAt?: string | null

  constructor(props: VenueInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      name: _name,
      typeId: _typeId,
      capacity: _capacity,
      needApproval: _needApproval,
      openStatus: _openStatus,
      description: _description,
      orderDescriptionDisplay: _orderDescriptionDisplay,
      orderDescription: _orderDescription,
      approvalingOrderEnable: _approvalingOrderEnable,
      scopeIds: _scopeIds,
      timeLimit: _timeLimit,
      timeLimitEnable: _timeLimitEnable,
      maxOrderDays: _maxOrderDays,
      enableMaxOrderDays: _enableMaxOrderDays,
      showOrderDetail: _showOrderDetail,
      earlyLimit: _earlyLimit,
      earlyType: _earlyType,
      openTimeRanges: _openTimeRanges,
      equipments: _equipments,
      adminIds: _adminIds,
      type: _type,
      admins: _admins,
      scopes: _scopes,
      places: _places,
      placeIds: _placeIds,
      orderCount: _orderCount,
      vfId: _vfId,
      vfVenueId: _vfVenueId,
      vfFollowerId: _vfFollowerId,
      vfStartAt: _vfStartAt,
      vfEndAt: _vfEndAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
    this.name = _name
    this.typeId = _typeId
    this.capacity = _capacity
    this.needApproval = _needApproval
    this.openStatus = _openStatus
    this.description = _description
    this.orderDescriptionDisplay = _orderDescriptionDisplay
    this.orderDescription = _orderDescription
    this.approvalingOrderEnable = _approvalingOrderEnable
    this.scopeIds = _scopeIds
    this.timeLimit = _timeLimit
    this.timeLimitEnable = _timeLimitEnable
    this.maxOrderDays = _maxOrderDays
    this.enableMaxOrderDays = _enableMaxOrderDays
    this.showOrderDetail = _showOrderDetail
    this.earlyLimit = _earlyLimit
    this.earlyType = _earlyType
    this.openTimeRanges = _openTimeRanges
      ? _openTimeRanges.map(i => new OpenTimeRange(i))
      : _openTimeRanges
    this.equipments = _equipments
    this.adminIds = _adminIds
    this.type = _type ? new Term(_type) : _type
    this.admins = _admins ? _admins.map(i => new SecuredReflection(i)) : _admins
    this.scopes = _scopes ? _scopes.map(i => new Scope(i)) : _scopes
    this.places = _places ? _places.map(i => new Place(i)) : _places
    this.placeIds = _placeIds
    this.orderCount = _orderCount
    this.vfId = _vfId
    this.vfVenueId = _vfVenueId
    this.vfFollowerId = _vfFollowerId
    this.vfStartAt = _vfStartAt
    this.vfEndAt = _vfEndAt
  }

  static propKeys = [
    'id',
    'name',
    'typeId',
    'capacity',
    'needApproval',
    'openStatus',
    'description',
    'orderDescriptionDisplay',
    'orderDescription',
    'approvalingOrderEnable',
    'scopeIds',
    'timeLimit',
    'timeLimitEnable',
    'maxOrderDays',
    'enableMaxOrderDays',
    'showOrderDetail',
    'earlyLimit',
    'earlyType',
    'openTimeRanges',
    'equipments',
    'adminIds',
    'type',
    'admins',
    'scopes',
    'places',
    'placeIds',
    'orderCount',
    'vfId',
    'vfVenueId',
    'vfFollowerId',
    'vfStartAt',
    'vfEndAt',
  ]
}

import { createEnumHelper } from '@seiue/util'
import { Getter } from 'jotai'

import { hasManageableParentPluginAtom } from 'packages/feature-utils/plugins'
import { useHasCurrentPermission } from 'packages/features/roles'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

import { BankGradeGroupIdEnum } from '../types'

/**
 * 学部 id 映射学部名
 */
export const gradeGroupIdToName = createEnumHelper(() => ({
  [BankGradeGroupIdEnum.MiddleSchool]: $t('初中'),
  [BankGradeGroupIdEnum.HighSchool]: $t('高中'),
})).get

/**
 * 学部 id 映射表格 label
 */
export const gradeGroupIdToTableLabel = createEnumHelper(() => ({
  [BankGradeGroupIdEnum.MiddleSchool]: $t('初中资源'),
  [BankGradeGroupIdEnum.HighSchool]: $t('高中资源'),
})).get

/**
 * 学部 id 映射匹配关系条数
 */
export const gradeGroupIdToRelationLength = createEnumHelper(() => ({
  [BankGradeGroupIdEnum.MiddleSchool]: 10,
  [BankGradeGroupIdEnum.HighSchool]: 9,
})).get

/**
 *学部 id 映射左侧菜单标签
 */
export const gradeGroupIdToSideMenuLabel = createEnumHelper(() => ({
  [BankGradeGroupIdEnum.MiddleSchool]: $t('初中题库'),
  [BankGradeGroupIdEnum.HighSchool]: $t('高中题库'),
})).get

/**
 * 启用的学部 id 生成左侧菜单
 *
 * @param gradeGroupIds - 学部 id
 * @param get - jotai getter
 * @returns menus
 */
export const gradeGroupIdsToMenus = (
  gradeGroupIds: BankGradeGroupIdEnum[],
  get: Getter,
) => {
  if (gradeGroupIds.length <= 1) {
    return [
      {
        path: `/admin/plugin/handouts/warehouse/gradeGroupId/${
          gradeGroupIds.at(0) ?? 0
        }`,
        label: $t('题库管理'),
        permission: (func: typeof useHasCurrentPermission) => {
          return (
            func({
              permission: PermissionNameEnum.BankRead,
            }) ||
            func({
              permission: PermissionNameEnum.CorePluginRead,
            }) ||
            get(hasManageableParentPluginAtom(PluginNameEnum.Handout))
          )
        },
        icon: 'Form' as const,
      },
    ]
  }

  return gradeGroupIds.map(gradeGroupId => ({
    path: `/admin/plugin/handouts/warehouse/gradeGroupId/${gradeGroupId}`,
    label: gradeGroupIdToSideMenuLabel(gradeGroupId),
    permission: (func: typeof useHasCurrentPermission) => {
      return (
        func({
          permission: PermissionNameEnum.BankRead,
        }) ||
        func({
          permission: PermissionNameEnum.CorePluginRead,
        }) ||
        get(hasManageableParentPluginAtom(PluginNameEnum.Handout))
      )
    },
    icon: 'Form' as const,
  }))
}

/**
 * @file chalk 的导航注册
 *
 * 由于现阶段 Go 需要 Chalk 的导航注册信息，所以将注册统一提至此处
 */

import 'packages/features/exam-rooms/message/chalk-navigator'
import 'packages/features/exams/message/chalk-navigator'
import 'packages/features/venues/message/chalk-navigator'
import 'packages/features/notices/message/chalk-navigators'
import 'packages/features/grades/messages/class/chalk-navigator'
import 'packages/features/grades/messages/dorm/chalk-navigator'
import 'packages/features/grades/messages/moral/chalk-navigator'
import 'packages/features/grades/messages/grade-reflection/chalk-navigator'
import 'packages/features/academics/message/chalk-navigator'
import 'packages/features/attendances/message/chalk-navigator'
import 'packages/features/tasks/message/chalk-navigator'
import 'packages/features/school-calendars/message/chalk-navigator'
import 'packages/features/class-declarations/message/chalk-navigator'
import 'packages/features/evaluations/message/chalk-navigator'
import 'packages/features/plugins/message/chalk-navigator'
import 'packages/features/classes/message/chalk-navigator'
import 'packages/features/subject-selections/message/chalk-navigator'
import 'packages/features/certification/message/chalk-navigator'
import 'packages/plugins/message/chalk-navigator'
import 'packages/features/elections/message/chalk-navigator'
import 'packages/features/cgr/message/chalk-navigator'
import 'packages/features/events/message/chalk-navigator'
import 'packages/features/questionnaire/message/chalk-navigator'
import 'packages/features/teacher-profile/message/chalk-navigator'
import 'packages/features/workflows/message/chalk-navigator'
import 'packages/features/admin-classes/message/chalk-navigator'
import 'packages/features/dorm/message/chalk-navigator'

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator('message', message => ({
  modalName: 'MessagesShowModal',
  options: { messageId: message.id },
}))

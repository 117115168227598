/**
 * @file register navigator
 */

import { registerMessageNavigator } from 'packages/features/messages/register'
import {
  ExamPublishCcRoleEnum,
  ExamTypeEnum,
  examApi$loadExam,
} from 'packages/sdks-next/vnas'

registerMessageNavigator('exam.stats_received', ({ attributes }) => {
  const examId = (attributes as any)?.exam?.id
  if (!examId) return null
  return {
    path: `/teacher/exams/exam-list/${examId}/analysis`,
  }
})

registerMessageNavigator(
  'exam.published_for_adminclass_teacher',
  ({ attributes }) => {
    const { exam, adminClass } = (attributes as any) || {}
    if (!exam || !adminClass) return null

    return async () => {
      const { data: newExam } = await examApi$loadExam.api(exam.id)

      if (
        !newExam.isPublished ||
        !newExam.isPublishCcRole(ExamPublishCcRoleEnum.AdminClassTeacher)
      ) {
        return null
      }

      return {
        path: `/teacher/exams/${exam.id}/admin-classes/${adminClass.id}/analysis`,
      }
    }
  },
)

registerMessageNavigator('exam.published_for_teacher', ({ attributes }) => {
  const { exam, subjectId, classId } = (attributes as any) || {}
  if (!exam || !subjectId || !classId) return null

  return async () => {
    const { data: newExam } = await examApi$loadExam.api(exam.id)

    if (
      !newExam.isPublished ||
      !newExam.isPublishCcRole(ExamPublishCcRoleEnum.Teacher)
    ) {
      return null
    }

    return {
      path: `/classes/${classId}/exams/${exam.id}/analysis/${subjectId}?needValidStatus=true`,
    }
  }
})

registerMessageNavigator('exam.published_for_mentor', ({ attributes }) => {
  const { exam, groupTypeId, groupId } = (attributes as any) || {}
  if (!exam || !groupTypeId || !groupId) return null

  return async () => {
    const { data: newExam } = await examApi$loadExam.api(exam.id)

    if (
      !newExam.isPublished ||
      !newExam.isPublishCcRole(ExamPublishCcRoleEnum.Mentor)
    ) {
      return null
    }

    return {
      path: `/plugin/customized-group/${groupTypeId}/groups/${groupId}/exams/${exam.id}/analysis/total-score?needValidStatus=true`,
    }
  }
})

registerMessageNavigator(
  ['exam.published_for_examinee', 'exam.published_scoring_for_examinee'],
  ({ attributes, owner }, currentReflection) => {
    const {
      exam: { id: examId, examType },
    } = attributes as {
      exam: { id: number; examType: ExamTypeEnum }
    }

    const rid = currentReflection.pupilId || owner?.id
    const scoringId = (attributes as any)?.exam?.scoringId

    if (!examId) return null
    return {
      modalName: 'StudentExamAnalysisReportModal',
      options: {
        rid,
        examId,
        scoringId,
        examType,
      },
    }
  },
)

// 考试缺考时候的消息 , 不进行跳转
registerMessageNavigator(
  ['exam.update_reflection_status_for_guardian_miss'],
  () => {
    return null
  },
)

// 缺考变为正常的时候消息
registerMessageNavigator(
  ['exam.update_reflection_status_for_guardian_normal'],
  () => {
    return null
  },
)

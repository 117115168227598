import { createEnumHelper } from '@seiue/util'

import { $t } from 'packages/locale'
import {
  CertificationReflectionRespSourceEnum,
  CertificationReflectionSourceEnum,
  CertificationReflectionStatusEnum,
  CertificationReflectionTypeEnum,
} from 'packages/sdks-next/sgms'

import {
  CertificationReflection as Base,
  CertificationReflectionInterface,
} from '../certification-reflection'

export type { CertificationReflectionInterface }

const typeToLabel = createEnumHelper(() => ({
  [CertificationReflectionTypeEnum.Apply]: $t('申请'),
  [CertificationReflectionTypeEnum.Give]: $t('授予'),
  [CertificationReflectionTypeEnum.Code]: $t('扫码获得'),
}))

const statusToLabel = createEnumHelper(() => ({
  [CertificationReflectionStatusEnum.Waiting]: $t('待审批'),
  [CertificationReflectionStatusEnum.Passed]: $t('已通过'),
  [CertificationReflectionStatusEnum.Rejected]: $t('已驳回'),
  [CertificationReflectionStatusEnum.Canceled]: $t('已取消'),
}))

const statusToTagStatus = createEnumHelper(() => ({
  [CertificationReflectionStatusEnum.Rejected]: 'danger' as const,
  [CertificationReflectionStatusEnum.Waiting]: 'warning' as const,
  [CertificationReflectionStatusEnum.Passed]: 'success' as const,
  [CertificationReflectionStatusEnum.Canceled]: 'invalid' as const,
}))

const sourceToLabel = createEnumHelper(() => ({
  [CertificationReflectionSourceEnum.App]: 'App',
  [CertificationReflectionSourceEnum.Web]: $t('网页'),
  [CertificationReflectionSourceEnum.Tv]: $t('大屏版'),
}))

/**
 * 认证申请人记录
 */
export class CertificationReflection extends Base {
  /**
   * 获取 type (认证获取方式) 的所有选项
   *
   * @param sourceKey - 原始值在选项 object 中的 key, 默认 value
   * @param mappedKey - 文字标签在选项 object 中的 key, 默认 label
   * @returns 选项数组, 默认 [{ value, label }]
   */
  static getTypeOptions<S extends string, M extends string>(
    sourceKey: S,
    mappedKey: M,
  ) {
    return typeToLabel.listOptions(sourceKey, mappedKey)
  }

  /**
   * 获取认证获取方式可读的文本标签
   *
   * @returns 文本标签
   */
  get typeLabel() {
    return typeToLabel.get(this.type)
  }

  /**
   * 获取认证获取方式可读的文本标签（带授予人信息）
   *
   * @returns 文本标签
   */
  get fullTypeLabel() {
    if (this.type === CertificationReflectionTypeEnum.Give) {
      return $t('{operator}授予', { operator: this.adminReflection?.name })
    }

    if (this.type === CertificationReflectionTypeEnum.Apply) {
      return $t('申请获得')
    }

    return $t('扫码获得')
  }

  /**
   * 获取认证申请状态可读的文本标签
   *
   * @returns 文本标签
   */
  get statusLabel() {
    return statusToLabel.get(this.status)
  }

  /**
   * 获取指定的申请状态可读的文本标签
   *
   * @param status - 申请状态
   * @returns 文本标签
   */
  static getStatusLabel(status: CertificationReflectionStatusEnum) {
    return statusToLabel.get(status)
  }

  /**
   * 获取认证申请状态对应的标签颜色
   *
   * @returns 标签颜色
   */
  get statusTagStatus() {
    return statusToTagStatus.get(this.status)
  }

  /**
   * 获取 status (认证申请状态) 的所有选项
   *
   * @param sourceKey - 原始值在选项 object 中的 key, 默认 value
   * @param mappedKey - 文字标签在选项 object 中的 key, 默认 label
   * @returns 选项数组, 默认 [{ value, label }]
   */
  static getStatusOptions<S extends string, M extends string>(
    sourceKey: S,
    mappedKey: M,
  ) {
    return statusToLabel.listOptions(sourceKey, mappedKey)
  }

  /**
   * 是否已授予
   *
   * @returns 是否
   */
  get isGiven() {
    return (
      [
        CertificationReflectionTypeEnum.Give,
        CertificationReflectionTypeEnum.Code,
      ].includes(this.type) &&
      this.status === CertificationReflectionStatusEnum.Passed
    )
  }

  /**
   * 授予/扫码方式的状态可读文本
   *
   * @returns 可读文本
   */
  get giveStatusLabel() {
    // 授予/扫码只有两种状态
    return this.isGiven ? $t('已授予') : $t('已取消')
  }

  /**
   * 授予/扫码方式的状态标签颜色
   *
   * @returns 标签颜色
   */
  get giveStatusTagStatus() {
    // 授予/扫码只有两种状态
    return this.isGiven ? ('success' as const) : ('invalid' as const)
  }

  /**
   * 获取认证来源可读的文本标签
   *
   * @returns 文本标签
   */
  get sourceLabel() {
    return this.source === CertificationReflectionRespSourceEnum.Unknown
      ? undefined
      : sourceToLabel.get(
          this.source as unknown as CertificationReflectionSourceEnum,
        )
  }

  /**
   * 获取认证来源的所有选项
   *
   * @param sourceKey - 原始值在选项 object 中的 key, 默认 value
   * @param mappedKey - 文字标签在选项 object 中的 key, 默认 label
   * @returns 选项数组, 默认 [{ value, label }]
   */
  static getSourceOptions<S extends string, M extends string>(
    sourceKey: S,
    mappedKey: M,
  ) {
    return sourceToLabel.listOptions(sourceKey, mappedKey)
  }
}

export interface ExaminerVenueScheduleResultInterface {
  /**
   * 考场号
   */
  venueNum: string
  /**
   * 当前场次占当前考场座位数，代指人数
   */
  sessionSeatCount: number
  /**
   * 考场名称
   */
  venueName: string
  /**
   * 监考老师姓名
   */
  examinerName: string
}

export class ExaminerVenueScheduleResult
  implements ExaminerVenueScheduleResultInterface
{
  /**
   * 考场号
   */
  venueNum: string
  /**
   * 当前场次占当前考场座位数，代指人数
   */
  sessionSeatCount: number
  /**
   * 考场名称
   */
  venueName: string
  /**
   * 监考老师姓名
   */
  examinerName: string

  constructor(props: ExaminerVenueScheduleResultInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      venueNum: _venueNum,
      sessionSeatCount: _sessionSeatCount,
      venueName: _venueName,
      examinerName: _examinerName,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.venueNum = _venueNum
    this.sessionSeatCount = _sessionSeatCount
    this.venueName = _venueName
    this.examinerName = _examinerName
  }

  static propKeys = [
    'venueNum',
    'sessionSeatCount',
    'venueName',
    'examinerName',
  ]
}

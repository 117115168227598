import { ClassReflectionEnum } from './class-reflection-enum'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import {
  MemberTypeSetting,
  MemberTypeSettingInterface,
} from './member-type-setting'

export interface ClassSelectionInterface {
  classId?: number
  reflectionId: number
  /**
   * 用户类型 [老师 学生]
   */
  type: ClassReflectionEnum
  /**
   * 是否可以同时上多节课
   */
  classTogether?: boolean | null
  /**
   * expand 用户信息
   */
  reflection?: SecuredReflectionInterface | null
  /**
   * 成员类型配置ID
   */
  typeSettingId?: number | null
  /**
   * expand 成员类型消息
   */
  typeSetting?: MemberTypeSettingInterface | null
  /**
   * 选课 ID 。如果为空，则表示为教务预置
   */
  electionId?: number | null
  /**
   * 创建人 ID 。可能为空，如用户被删除，旧数据等。
   */
  createdBy?: number | null
  /**
   * 删除原因，为空字符串表示未设置
   */
  deletedReason?: string
}

export class ClassSelection implements ClassSelectionInterface {
  classId?: number
  reflectionId: number
  /**
   * 用户类型 [老师 学生]
   */
  type: ClassReflectionEnum
  /**
   * 是否可以同时上多节课
   */
  classTogether?: boolean | null
  /**
   * expand 用户信息
   */
  reflection?: SecuredReflection | null
  /**
   * 成员类型配置ID
   */
  typeSettingId?: number | null
  /**
   * expand 成员类型消息
   */
  typeSetting?: MemberTypeSetting | null
  /**
   * 选课 ID 。如果为空，则表示为教务预置
   */
  electionId?: number | null
  /**
   * 创建人 ID 。可能为空，如用户被删除，旧数据等。
   */
  createdBy?: number | null
  /**
   * 删除原因，为空字符串表示未设置
   */
  deletedReason?: string

  constructor(props: ClassSelectionInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      classId: _classId,
      reflectionId: _reflectionId,
      type: _type,
      classTogether: _classTogether,
      reflection: _reflection,
      typeSettingId: _typeSettingId,
      typeSetting: _typeSetting,
      electionId: _electionId,
      createdBy: _createdBy,
      deletedReason: _deletedReason,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.classId = _classId
    this.reflectionId = _reflectionId
    this.type = _type
    this.classTogether = _classTogether
    this.reflection = _reflection
      ? new SecuredReflection(_reflection)
      : _reflection
    this.typeSettingId = _typeSettingId
    this.typeSetting = _typeSetting
      ? new MemberTypeSetting(_typeSetting)
      : _typeSetting
    this.electionId = _electionId
    this.createdBy = _createdBy
    this.deletedReason = _deletedReason
  }

  static propKeys = [
    'classId',
    'reflectionId',
    'type',
    'classTogether',
    'reflection',
    'typeSettingId',
    'typeSetting',
    'electionId',
    'createdBy',
    'deletedReason',
  ]
}

export enum MemberTypeEnum {
  Admin = 'admin',
  Member = 'member',
  Manager = 'manager',
  Managed = 'managed',
  Teacher = 'teacher',
  Student = 'student',
  Auditor = 'auditor',
  AdminClassTeacher = 'admin_class_teacher',
  DormManager = 'dorm_manager',
  Mentor = 'mentor',
  Guardian = 'guardian',
}

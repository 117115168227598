export interface ExamStatsDataInterface {
  /**
   * 已抄送的教师 rids
   */
  statsReceivers?: number[]
  /**
   * 考试成绩单等级转换规则
   */
  transcriptIndicatorGroup?: string | null
  /**
   * 考试成绩单字段->等级转换规则 map
   * {'grade': gid, subject_id: gid, subject_group_gid: gid}
   */
  transcriptFieldToIndicatorGroupId?: object | null
  /**
   * 档位组 IDS
   */
  indicatorGroups?: string[]
  /**
   * 科目组 Ids（默认继承上次考试）
   */
  subjectGroups?: string[]
}

export class ExamStatsData implements ExamStatsDataInterface {
  /**
   * 已抄送的教师 rids
   */
  statsReceivers?: number[]
  /**
   * 考试成绩单等级转换规则
   */
  transcriptIndicatorGroup?: string | null
  /**
   * 考试成绩单字段->等级转换规则 map
   * {'grade': gid, subject_id: gid, subject_group_gid: gid}
   */
  transcriptFieldToIndicatorGroupId?: object | null
  /**
   * 档位组 IDS
   */
  indicatorGroups?: string[]
  /**
   * 科目组 Ids（默认继承上次考试）
   */
  subjectGroups?: string[]

  constructor(props: ExamStatsDataInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      statsReceivers: _statsReceivers,
      transcriptIndicatorGroup: _transcriptIndicatorGroup,
      transcriptFieldToIndicatorGroupId: _transcriptFieldToIndicatorGroupId,
      indicatorGroups: _indicatorGroups,
      subjectGroups: _subjectGroups,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.statsReceivers = _statsReceivers
    this.transcriptIndicatorGroup = _transcriptIndicatorGroup
    this.transcriptFieldToIndicatorGroupId = _transcriptFieldToIndicatorGroupId
    this.indicatorGroups = _indicatorGroups
    this.subjectGroups = _subjectGroups
  }

  static propKeys = [
    'statsReceivers',
    'transcriptIndicatorGroup',
    'transcriptFieldToIndicatorGroupId',
    'indicatorGroups',
    'subjectGroups',
  ]
}

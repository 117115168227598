/**
 * @file Reflection 配置相关
 */

import { createEnumHelper } from '@seiue/util'

import { GroupSettingEnum } from 'packages/features/groups/types'
import { useCurrentReflection } from 'packages/features/sessions/utils/data'
import {
  ConfigItem,
  ConfigItemReqT,
  RoleEnum,
  acmApi$queryConfigItemsByGroup,
  acmApi$mutationConfigItemsByGroup,
} from 'packages/sdks-next/chalk'
import { ServiceEnum } from 'packages/shared-stores/configs'

const roleToGroupSetting = createEnumHelper({
  [RoleEnum.Student]: GroupSettingEnum.ReflectionStudentSetting,
  [RoleEnum.Teacher]: GroupSettingEnum.ReflectionTeacherSetting,
  [RoleEnum.Guardian]: GroupSettingEnum.ReflectionGuardianSetting,
  [RoleEnum.Staff]: GroupSettingEnum.ReflectionStaffSetting,
  [RoleEnum.Shadow]: '',
})

interface ReflectionSettingProps {
  role: RoleEnum
  disable?: boolean
}

// 该名称由后端定义
export enum ReflectionSettingName {
  ForceChangeInitPass = 'force_change_init_pass',
  ForceBindPhone = 'force_bind_phone',
  ShowStudentCreateSchedules = 'student_create_schedules',
  ShowTeacherCreateSchedules = 'teacher_create_schedules',
}

/**
 * 获取用户配置参数
 *
 * @param param0 - 参数
 * @param param0.role - 角色
 * @returns 参数
 */
export const useReflectionSettingParams = ({
  role,
}: ReflectionSettingProps) => {
  const me = useCurrentReflection()

  return {
    scope: `school.${me?.schoolId}`,
    group: roleToGroupSetting.get(role),
    service: ServiceEnum.Chalk,
  }
}

/**
 * 获取用户配置名称
 *
 * @param name - 配置名称
 * @param role - 角色
 * @returns 配置名称
 */
export const getReflectionSettingName = (
  name: ReflectionSettingName,
  role?: RoleEnum,
) => (role ? `${role}_${name}` : name)

/**
 * 从用户配置中查找配置
 *
 * @param param0 - 参数
 * @param param0.name - 配置名称
 * @param param0.role - 角色
 * @param param0.settings - 配置列表
 * @returns 配置
 */
export const findConfigFromReflectionSettings = ({
  name,
  role,
  settings,
}: {
  name: ReflectionSettingName
  role?: RoleEnum
  settings: ConfigItem[] | null
}) =>
  settings?.find(config => config.name === getReflectionSettingName(name, role))

/**
 * 获取用户配置
 *
 * @param props - 参数
 * @returns 用户配置
 */
export const useFindReflectionSetting = (props: ReflectionSettingProps) => {
  const params = useReflectionSettingParams(props)

  return acmApi$queryConfigItemsByGroup.useApi(params, {
    disable: props.disable || !params.group,
    staleTime: 60 * 2,
  })
}

/**
 * 是否需要强制重置初始密码
 *
 * @param props - 参数
 * @returns 是否需要强制重置初始密码
 */
export const useForceResetInitPassword = (props: ReflectionSettingProps) => {
  const { data, loading, refetching } = useFindReflectionSetting(props)

  return {
    data: !!findConfigFromReflectionSettings({
      role: props.role,
      name: ReflectionSettingName.ForceChangeInitPass,
      settings: data,
    })?.value,
    loading: loading || refetching,
  }
}

/**
 * 是否需要强制绑定手机号
 *
 * @param props - 参数
 * @returns 是否需要强制绑定手机号
 */
export const useForceBindPhone = (props: ReflectionSettingProps) => {
  const { data, loading, refetching } = useFindReflectionSetting(props)

  return {
    data: !!findConfigFromReflectionSettings({
      role: props.role,
      name: ReflectionSettingName.ForceBindPhone,
      settings: data,
    })?.value,
    loading: loading || refetching,
  }
}

/**
 * 更新用户配置
 *
 * @param props - 参数
 * @returns 更新用户配置
 */
export const useUpdateReflectionSetting = (props: ReflectionSettingProps) => {
  const { api, loading } = acmApi$mutationConfigItemsByGroup.useApi({
    reload: [acmApi$queryConfigItemsByGroup.api],
  })

  const parasm = useReflectionSettingParams(props)

  return {
    api: (payload: ConfigItemReqT[]) =>
      api({
        ...parasm,
        payload,
      }),
    loading,
  }
}

import { isProduction } from '@seiue/env'
import { reportToEngineer, reportToSentry } from '@seiue/sentry'
import { isArray, isObject } from '@seiue/util'

import { getPluginIcon, PluginEnum } from 'packages/feature-utils/plugins'
import { useEnabledPlugins } from 'packages/feature-utils/plugins/hooks'
import {
  DomainType,
  LabelStyle,
  MessageType,
  Navigator,
} from 'packages/features/messages/register/types'
import { $t } from 'packages/locale'
import {
  Message,
  Reflection,
  SecuredReflection,
} from 'packages/sdks-next/chalk'
import { TextColor } from 'packages/theme'

import { MessageDomainEnum } from '../types'
import { OpenPlatformDomainPrefix } from '../utils/messages'

import defaultPng from './default.png'

/**
 * 计算消息分类文本的背景色和文字色
 *
 * @param bg - 背景色
 * @param fontColor - 文字色
 * @returns 分类文本的样式
 */
export const calculateLabelStyle = (bg: string, fontColor: string) => ({
  backgroundColor: bg,
  color: fontColor,
})

const messageDomainMap: {
  [type: string]: DomainType
  unknown: DomainType
} = {
  unknown: {
    value: 'unknown',
    getLabel: () => $t('未分类'),
    labelStyle: { color: TextColor._2, backgroundColor: 'transparent' },
    icon: defaultPng,
    messages: [],
  },
}

const messageMap: {
  [type: string]: MessageType
} = {}

/**
 * 重要消息类型，某个消息被注册后，它新的未读消息将被更重要的交互所提醒
 */
export const importantMessageTypes: string[] = []

/**
 * 注册领域的消息类型
 *
 * @param domainType - 消息的领域类型
 * @param types - 消息类型
 */
const registerMessages = (
  domainType: string,
  types: (string | { type: string; important?: boolean })[],
) => {
  const domain = messageDomainMap[domainType]

  if (domain) {
    types.forEach(typeValue => {
      let isImportant = false
      let type = ''

      if (isObject(typeValue)) {
        type = typeValue.type
        isImportant = typeValue.important ?? false
      } else {
        type = typeValue
      }

      const msgType =
        type.split('.').length > 1
          ? type
          : `${domainType}${type ? `.${type}` : ''}`

      const msg = {
        value: msgType,
        domain,
      }

      domain.messages.push(msg)
      messageMap[msg.value] = msg

      if (isImportant) {
        importantMessageTypes.push(msg.value)
      }
    })
  }
}

type RegisterDomainMessageParams = {
  /**
   * 领域类型
   */
  type: MessageDomainEnum
  /**
   * 领域标签
   */
  getLabel: () => string
  /**
   * 领域图标（目前只有 app 上有展示）
   * 可以直接传入图片，参考 {@link file://./../../schedules/message/register.ts }
   * 或者传入对应插件名称(plugin.pluginName)，使用插件图标（子插件优先，然后父插件）
   * 如果都没有，则会使用默认图标
   */
  icon?: string | `plugin.${PluginEnum}`
  /**
   * 领域文本样式
   */
  labelStyle: LabelStyle
  /**
   * 领域下的消息类型
   */
  messages: (string | { type: string; important?: boolean })[]
}

/**
 * 注册消息
 *
 * @param {RegisterDomainMessageParams} domainMessage - 消息参数
 */
export const registerDomainMessage = (
  domainMessage: RegisterDomainMessageParams,
) => {
  const domain: DomainType = {
    value: domainMessage.type,
    getLabel: domainMessage.getLabel,
    icon: domainMessage.icon,
    labelStyle: domainMessage.labelStyle,
    messages: [],
  }

  messageDomainMap[domain.value] = domain

  registerMessages(domain.value, domainMessage.messages)
}

/**
 * 根据消息类型获取已注册消息
 *
 * @param type - 消息类型
 * @returns 已注册消息
 */
export const getMessage = (type: string): MessageType | undefined =>
  messageMap[type]

/**
 * 根据消息类型获取已注册的领域消息
 *
 * @param type - 消息类型
 * @returns 已注册领域消息
 */
export const getDomainMessage = (type: string): DomainType | undefined =>
  messageDomainMap[type]

/**
 * 根据消息类型获取领域
 *
 * @param type - 消息类型
 * @returns 消息领域
 */
export const getMessageDomain = (type: string): DomainType | undefined => {
  const msg = messageMap[type]

  return msg?.domain ? msg.domain : getDomainMessage('unknown')
}

/**
 * 根据消息类型获取消息的图标
 *
 * @param message - 消息
 * @returns 图标
 */
export const useMessageDomainIcon = (message: Message): string => {
  const [plugins] = useEnabledPlugins()

  const registeredDomainIcon = messageMap[message.type]?.domain?.icon

  let targetPluginName = ''
  if (message.domain?.startsWith(OpenPlatformDomainPrefix)) {
    targetPluginName = message.domain?.split('.')[1]
  }

  if (registeredDomainIcon?.startsWith?.('plugin')) {
    targetPluginName = registeredDomainIcon?.split('.')[1]
  }

  if (targetPluginName) {
    const targetPlugin = plugins.find(
      plugin => plugin.pluginName === targetPluginName,
    )

    if (targetPlugin) {
      return getPluginIcon(targetPlugin)
    }
  }

  return registeredDomainIcon || getDomainMessage('unknown')?.icon || defaultPng
}

const messageNavigatorMap: {
  [key: string]: Navigator
  unknown: Navigator
} = {
  unknown: () => null,
}

/**
 * 注册消息的导航器
 *
 * @param type - 消息类型
 * @param navigator - 导航器
 */
export const registerMessageNavigator = (
  type: string | string[],
  navigator: Navigator,
) => {
  const types = isArray(type) ? type : [type]

  types.forEach(_type => {
    const message = getMessage(_type) || getDomainMessage(_type)

    if (!message) {
      const noMsgError = new Error(
        // eslint-disable-next-line seiue/missing-formatted-message
        `[Message Register]：在注册消息导航「${_type}」前，应先使用 registerMessages 注册消息。`,
      )

      reportToEngineer(noMsgError, {
        ExceptionType: 'message',
      })
    }

    messageNavigatorMap[_type] = navigator
  })
}

export type RegisterNavigatorProps = Readonly<
  Parameters<typeof registerMessageNavigator>
>

/**
 * 根据消息类型获取导航器
 *
 * @param type - 消息类型
 * @returns 导航器
 */
export const getMessageNavigator = (type: string) => {
  if (messageNavigatorMap[type]) {
    return messageNavigatorMap[type]
  }

  const parentType = getMessage(type)?.domain.value

  if (parentType) {
    return messageNavigatorMap[parentType]
  }

  return messageNavigatorMap.unknown
}

/**
 * 获取消息导航器的执行结果
 *
 * @param message - 消息
 * @param currentReflection - 当前用户信息
 * @returns 导航器结果
 */
export const getMessageNavigatorResult = (
  message: Message,
  currentReflection: SecuredReflection | Reflection,
) => {
  const navigator = getMessageNavigator(message.type)

  if (!navigator) return null

  return navigator(message, currentReflection)
}

const messageClickMap: {
  [key: string]: (message: Message) => void
} = {}

const messageGoClickMap: {
  [key: string]: (message: Message) => void
} = {}

/**
 * 注册消息的点击事件
 *
 * @param type - 消息类型
 * @param fn - 点击事件
 */
export const hijackMessageClick = (
  type: string | string[],
  fn: (message: Message) => void,
) => {
  const types = isArray(type) ? type : [type]

  types.forEach(_type => {
    const message = getMessage(_type) || getDomainMessage(_type)

    if (!message) {
      const noMsgError = new Error(
        // eslint-disable-next-line seiue/missing-formatted-message
        `[Message Register]：在注册消息导航「${_type}」前，应先使用 registerMessages 注册消息。`,
      )

      if (isProduction()) {
        reportToSentry(noMsgError, {
          ExceptionType: 'message',
        })
      } else {
        throw noMsgError
      }
    }

    messageClickMap[_type] = fn
  })
}

/**
 * 注册 go 的消息的点击事件
 *
 * @param type - 消息类型
 * @param fn - 点击事件
 */
export const hijackGoMessageClick = (
  type: string | string[],
  fn: (message: Message) => void,
) => {
  const types = isArray(type) ? type : [type]

  types.forEach(_type => {
    const message = getMessage(_type) || getDomainMessage(_type)

    if (!message) {
      const noMsgError = new Error(
        // eslint-disable-next-line seiue/missing-formatted-message
        `[Message Register]：在注册消息导航「${_type}」前，应先使用 registerMessages 注册消息。`,
      )

      if (isProduction()) {
        reportToSentry(noMsgError, {
          ExceptionType: 'message',
        })
      } else {
        throw noMsgError
      }
    }

    messageGoClickMap[_type] = fn
  })
}

export type HijackMsgClickProps = Readonly<
  Parameters<typeof hijackMessageClick>
>

/**
 * 获取消息的点击事件
 *
 * @param type - 消息类型
 * @returns 点击事件
 */
export const getHijackMessageClickFn = (type: string) => {
  if (messageClickMap[type]) {
    return messageClickMap[type]
  }

  const parentType = getMessage(type)?.domain.value

  if (parentType) {
    return messageClickMap[parentType]
  }

  return null
}

/**
 * 获取 go 的消息的点击事件
 *
 * @param type - 消息类型
 * @returns 点击事件
 */
export const getGoHijackMessageClickFn = (type: string) => {
  if (messageGoClickMap[type]) {
    return messageGoClickMap[type]
  }

  const parentType = getMessage(type)?.domain.value

  if (parentType) {
    return messageGoClickMap[parentType]
  }

  return null
}

/**
 * @file register certification messages
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.ClassIn,
  getLabel: () => $t('插件'),
  labelStyle: calculateLabelStyle('#9e37b4', '#f7e9fa'),
  messages: [
    'account_insufficient',
    'account_unbind_admin',
    'account_unbind',
    'classin.unbind_notify_for_other',
  ],
})

/**
 * @file 调代课消息 Chalk 导航
 */
import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator('intl_goal', message => {
  const { ownerId } = message.attributes as { ownerId?: number }
  if (!ownerId) return null

  return {
    path: `/admin/profiles/${ownerId}/goals/intl-goal`,
  }
})

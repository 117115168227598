/**
 * @file Layout 相关的一些工具函数
 */
import { reverse } from '@seiue/util'

import { getPathAncestors } from 'packages/route'
import { matchMenu } from 'packages/web-layout/menu-utils'
import { MenuItemUnion } from 'packages/web-layout/types'

/**
 * 获取当前激活的菜单的路径
 * @param activePath
 * @param menus
 */
export const getActiveMenuPath = (
  activePath: string,
  menus: MenuItemUnion[],
) => {
  const pathAncestorsReverse = reverse(getPathAncestors(activePath))

  // 没有在侧边栏 Menus 中声明， 需要特殊处理侧边栏 active 样式的菜单
  const specialMenuPaths = [
    // 工作台应用中心
    '/user/applications-center',
  ]

  for (const p of pathAncestorsReverse) {
    /*
     * 先精确匹配，再模糊匹配
     * 精准匹配是为了处理课程班，这种动态路由在菜单栏中出现出多个，会一直匹配到第一个菜单的情况
     * 如: 动态路由是/classes/:id， 菜单有: /classes/50582、/classes/50583。
     *    当前所在菜单为/classes/50583， 那么模糊匹配在遍历第一个课程班菜单是否激活时, 就return true了
     */
    const exactMatched = matchMenu(p, menus)
    if (exactMatched && 'path' in exactMatched && exactMatched.path) {
      return exactMatched.path
    }

    const fuzzyMatched = matchMenu(p, menus, false)
    if (fuzzyMatched && 'path' in fuzzyMatched && fuzzyMatched.path) {
      return fuzzyMatched.path
    }

    if (specialMenuPaths.includes(p)) return p
  }

  return '/'
}

import { Moment, moment } from '@seiue/moment'
import { times } from '@seiue/util'
import React from 'react'

import {
  useGradeTerms,
  useSemesterById,
  useSemesters,
} from 'packages/feature-utils/semesters'
import { formatWeek } from 'packages/features/classes/utils'
import { TermTypeEnum } from 'packages/features/terms/types'
import { Semester, termApi$queryTerm } from 'packages/sdks-next/chalk'
import { Term } from 'packages/sdks-next/vnas'

import { dateToSemesterWeek } from '../utils'

/**
 * 获取学部和年级信息，主要提供给学期表单使用
 *
 * @returns 学部和年级信息
 */
export const useDivisionAndGradeList = (): [
  Term[] | undefined,
  (division: Term) => Term[],
  () => void,
] => {
  const { data: schoolStructureList, reload } = termApi$queryTerm.useApi({
    type: TermTypeEnum.SystemSchoolStructure,
  })

  const divisionList = React.useMemo(
    () => schoolStructureList?.filter(({ parentId }) => !parentId),
    [schoolStructureList],
  )

  const getGradeList = React.useCallback(
    (division: Term) =>
      schoolStructureList?.filter(({ parentId }) => parentId === division.id) ??
      [],
    [schoolStructureList],
  )

  return [divisionList, getGradeList, reload]
}

/**
 * 获取学期所设置的届别
 *
 * @param semester - 学期
 * @returns 届别
 */
export const useSemesterGraduateIn = (semester?: Semester) => {
  const { data: terms } = termApi$queryTerm.useApi({
    type: TermTypeEnum.SystemGraduatesIn,
  })

  const graduateIds = React.useMemo(
    () => Object.values(semester?.gradeMaps || {}),
    [semester],
  )

  return React.useMemo(
    () => terms?.filter(term => graduateIds.includes(term.id)),
    [terms, graduateIds],
  )
}

/**
 * 获取学期所设置的年级
 *
 * @param semester - 学期
 * @returns 年级
 */
export const useSemesterGrade = (semester?: Semester | null) => {
  const [gradeTerms] = useGradeTerms()
  const gradeIds = React.useMemo(
    () => Object.keys(semester?.gradeMaps || {}),
    [semester],
  )

  return React.useMemo(
    () => gradeTerms?.filter(term => gradeIds.includes(String(term.id))),
    [gradeTerms, gradeIds],
  )
}

/**
 * 根据学期设置，返回届别与届别对应的年级
 *
 * @param semester - 学期
 * @returns 年级和届别
 */
export const useGraduateAndGradeWithSemester = (semester?: Semester) => {
  const { data: graduateTerms } = termApi$queryTerm.useApi({
    type: TermTypeEnum.SystemGraduatesIn,
  })

  const { data: structureTerms } = termApi$queryTerm.useApi({
    type: TermTypeEnum.SystemSchoolStructure,
  })

  return React.useMemo(() => {
    const grades = Object.entries(semester?.gradeMaps || {})
    const result = (graduateTerms || []).map(term => {
      // 找一下学期里是不是设置了这个届别
      const matchedGrade = grades.find(
        ([, graduateId]) => Number(graduateId) === term.id,
      )

      // 再到年级里找一下是否还存在这个年级设置
      const gradeTerm = structureTerms?.find(
        _term => _term.id === Number(matchedGrade?.[0]),
      )

      // 找一下年级对应的学部
      const facultyTerm = structureTerms?.find(
        _term => _term.id === Number(gradeTerm?.parentId),
      )

      return {
        grade: gradeTerm,
        faculty: facultyTerm,
        graduate: term,
      }
    })

    return result
  }, [semester, graduateTerms, structureTerms])
}

/**
 * 获取学期选项列表
 *
 * @returns 学期选项列表
 */
export const useSemesterOptions = () => {
  const [semesters] = useSemesters()

  return React.useMemo(
    () =>
      semesters?.map(({ id, name }) => ({ label: name, value: `${id}` })) || [],
    [semesters],
  )
}

export type SemesterDateWeek = {
  week: number
  startAt: Moment
  endAt: Moment
}

/**
 * 获取该学期的周次
 *
 * @param semesterId - 学期 id
 * @param options - 选项
 * @param options.showSemesterPrefix - 是否显示学期前缀
 * @returns 学期周次选项列表
 */
export const useSemesterWeekOptions = (
  semesterId?: number,
  options?: {
    showSemesterPrefix?: boolean
  },
): {
  weekOptions: {
    label: string
    value: number
  }[]
  weeks: SemesterDateWeek[]
} => {
  const { showSemesterPrefix } = options || {}
  const semester = useSemesterById(Number(semesterId))

  return React.useMemo(() => {
    if (semester) {
      const endWeek = dateToSemesterWeek(semester.endAt, semester.startAt)

      const weekOptions = times(endWeek, i => {
        const weekLabel = formatWeek(i + 1)
        const label = showSemesterPrefix
          ? `${semester.name} ${weekLabel}`
          : weekLabel

        return {
          label,
          value: i + 1,
        }
      })

      return {
        weekOptions,
        weeks: weekOptions.map((weekOption, weekIdx) => {
          const currentWeekStart = moment(semester.startAt).add(
            weekIdx,
            'weeks',
          )

          // 当前周的开始时间
          const startAt =
            weekIdx === 0
              ? // 第一周的第一天就是学期的第一天
                moment(currentWeekStart.format('YYYY-MM-DD'))
              : // 其他周的第一天，始终从星期一开始
                moment(currentWeekStart.startOf('isoWeek').format('YYYY-MM-DD'))

          // 当前周的结束时间
          const endAt = moment(
            currentWeekStart.endOf('isoWeek').format('YYYY-MM-DD'),
          )

          // 最后一周的结束时间，如果是最后一周，则用学期的结束时间
          const finalEndAt = endAt.isBefore(semester.endAt)
            ? endAt
            : moment(semester.endAt)

          return {
            week: weekOption.value,
            startAt,
            endAt: finalEndAt,
          }
        }),
      }
    }

    return {
      weekOptions: [],
      weeks: [],
    }
  }, [semester, showSemesterPrefix])
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Assessment, AssessmentInterface } from './extends/assessment'
import type { QueryKlassAssessmentsQuery } from './query-klass-assessments-query'

const buildConfig = createConfigBuilder('vnas')

export const klassApi$queryKlassAssessments = {
  config(
    queryKlassAssessmentsQuery?: QueryKlassAssessmentsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/klass/assessments',
      {},
      queryKlassAssessmentsQuery,
    )
  },

  /**
   * @summary Class QueryAssessmentsApi
   * @param [queryKlassAssessmentsQuery]
   * @param [klassQuery.paginated] 是否分页，默认 1
   * @param [klassQuery.page] 显示的数据页，默认 1
   * @param [klassQuery.perPage] 每页显示数量，默认 20
   * @param [klassQuery.offset]
   * @param [klassQuery.limit]
   * @param [klassQuery.scrolled]
   * @param [klassQuery.klass.idIn]
   * @param [klassQuery.klass.idNotIn]
   * @param [klassQuery.klass.courseIdIn]
   * @param [klassQuery.klass.courseIdNotIn]
   * @param [klassQuery.klass.identity] csin/nama 或查询
   * @param [klassQuery.klass.csinIn]
   * @param [klassQuery.klass.csinLike]
   * @param [klassQuery.klass.nameIn]
   * @param [klassQuery.klass.nameLike]
   * @param [klassQuery.klass.creditEgt]
   * @param [klassQuery.klass.creditElt]
   * @param [klassQuery.klass.subjectIdIn]
   * @param [klassQuery.klass.subjectIdNotIn]
   * @param [klassQuery.klass.domainIdIn]
   * @param [klassQuery.klass.attributeIdIn]
   * @param [klassQuery.klass.classNameIn]
   * @param [klassQuery.klass.classNameLike]
   * @param [klassQuery.klass.classEnameIn]
   * @param [klassQuery.klass.classEnameLike]
   * @param [klassQuery.klass.capacityEgt]
   * @param [klassQuery.klass.capacityElt]
   * @param [klassQuery.klass.teachers] 任课教师筛选
   * @param [klassQuery.klass.substituteTeachers] 代课教师筛选
   * @param [klassQuery.klass.isMultiTeachers] 是多任课教师的课程
   * @param [klassQuery.klass.timetable]
   * @param [klassQuery.klass.classWeeksEgt]
   * @param [klassQuery.klass.classWeeksElt]
   * @param [klassQuery.klass.studentNumsEgt]
   * @param [klassQuery.klass.studentNumsElt]
   * @param [klassQuery.klass.classHourEgt]
   * @param [klassQuery.klass.classHourElt]
   * @param [klassQuery.klass.placeIdsOverlaps]
   * @param [klassQuery.klass.gradeIdsOverlaps]
   * @param [klassQuery.klass.timetableIdIn]
   * @param [klassQuery.klass.classTimes]
   * @param [klassQuery.klass.course]
   * @param [klassQuery.klass.tagIdIn]
   * @param [klassQuery.klass.classType] 课程班类型
   * @param [klassQuery.klass.classTypeIn]
   * @param [klassQuery.klass.classLessons]
   * @param [klassQuery.klass.hasAdminClass] true - 获取关联行政班的课程班信息
   * false - 此参数不生效
   * @param [klassQuery.klass.adminClasses]
   * @param [klassQuery.sort]
   * @param [klassQuery.expand] expand
   * - afterthought 反思
   * @param [klassQuery.withAll] 查询全部的评价
   * @param [klassQuery.withCorePermOnly] 只查询与基础权限相应的评价
   * @param [klassQuery.schoolId]
   * @param [klassQuery.idIn]
   * @param [klassQuery.nameLike]
   * @param [klassQuery.nameIn]
   * @param [klassQuery.semesterId]
   * @param [klassQuery.scopeType]
   * @param [klassQuery.scopeIdIn]
   * @param [klassQuery.statusIn]
   * @param [klassQuery.planIdIn]
   * @param [klassQuery.planIdIsEmpty]
   * @param [klassQuery.fullScoreEgt]
   * @param [klassQuery.fullScoreElt]
   * @param [klassQuery.totalItemScoreEgt]
   * @param [klassQuery.totalItemScoreElt]
   * @param [klassQuery.itemNumEgt]
   * @param [klassQuery.itemNumElt]
   * @param [klassQuery.scopeNumEgt]
   * @param [klassQuery.scopeNumElt]
   * @param [klassQuery.scoreLockStatusIn]
   * @param [klassQuery.settingLockStatusIn]
   * @param [klassQuery.individuation]
   * @param [klassQuery.isArchived]
   * @param [klassQuery.evaluators.memberId]
   * @param [klassQuery.evaluators.memberIdIn]
   * @param [klassQuery.evaluators.memberType]
   * @param [klassQuery.evaluators.memberTypeIn]
   * @param [klassQuery.evaluators.assessmentId]
   * @param [klassQuery.evaluators.assessmentIdIn]
   * @param [klassQuery.evaluators.reflection]
   * @param [klassQuery.evaluateTarget] 评价对象，德育评价使用
   * @param [klassQuery.meIsEvaluator] 是否参与评价（适用于家长、老师、学生场景）
   * @param [klassQuery.lockGrade] 是否锁定总成绩修改
   * @param [klassQuery.isTemplate] 是否为模板
   * @param [klassQuery.assessmentStages.status]
   * @param [klassQuery.assessmentStages.statusIn]
   * @param [klassQuery.assessmentStages.stageId]
   * @param [klassQuery.assessmentStages.stageIdIn]
   * @param [klassQuery.computeRuleIsEmpty] 计算规则是否为空
   * @param [options]
   * @return AxiosResponsePromise<Assessment[]>
   */

  api<const TQuery extends QueryKlassAssessmentsQuery>(
    queryKlassAssessmentsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Assessment, TQuery['expand']>[]> {
    return axios(
      klassApi$queryKlassAssessments.config(
        queryKlassAssessmentsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Assessment(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryKlassAssessmentsQuery,
    TSelected = Expand<Assessment, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<Assessment, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Assessment, TQuery['expand']>[], TSelected>(
      klassApi$queryKlassAssessments.api,
      'Klass.queryKlassAssessments',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
klassApi$queryKlassAssessments.api._name_ = `Klass.queryKlassAssessments`

import { Attachment, AttachmentInterface } from './attachment'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'

export interface DiscussionTopicHistoryInterface {
  discussionId: number
  historyOf: number
  content: string
  attachments?: AttachmentInterface[]
  creator?: SecuredReflectionInterface
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class DiscussionTopicHistory implements DiscussionTopicHistoryInterface {
  discussionId: number
  historyOf: number
  content: string
  attachments?: Attachment[]
  creator?: SecuredReflection
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: DiscussionTopicHistoryInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      discussionId: _discussionId,
      historyOf: _historyOf,
      content: _content,
      attachments: _attachments,
      creator: _creator,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.discussionId = _discussionId
    this.historyOf = _historyOf
    this.content = _content
    this.attachments = _attachments
      ? _attachments.map(i => new Attachment(i))
      : _attachments
    this.creator = _creator ? new SecuredReflection(_creator) : _creator
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'discussionId',
    'historyOf',
    'content',
    'attachments',
    'creator',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

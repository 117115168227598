import { schema } from '@seiue/normalizr'
import { ConfigItem } from './extends/config-item'

export const configItemSchema = new schema.Entity<ConfigItem>(
  'chalk_config_item',
  {},
  {
    idAttribute: field =>
      `${field.service}_${field.scope}_${field.group}_${field.name}`,
  },
)

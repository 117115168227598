/**
 * @file 表单字段 types
 */
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

import {
  FormAnswerReq,
  FormTemplateFieldOptionSerialNumberEnum,
} from 'packages/sdks-next/form'

import { LegoFormFieldContainer } from './field-container'
import { LegoFormTableField } from './table-field'

export type LegoFormField = {
  /**
   * 字段类型，类型是 LegoFormFieldTypeEnum | 业务自定义类型
   */
  type?: any

  /**
   * 字段 name
   */
  name?: string

  /**
   * 排序
   */
  sort?: number

  sortable?: boolean

  /**
   * 字段名称
   */
  label?: string

  /**
   * 字段描述
   */
  description?: string
  /**
   * 前端跳转逻辑用，可以设置规则选择了单选的选项几就跳转到第几题这种
   */
  targetedBy?: string
  /**
   * 依赖的题目，如果被依赖的题目没有设置，则禁用当前题目
   * 目前仅在「广中报修审批」中使用，该属性由后端配置
   */
  dependsOn?: string[]
  /**
   * 是否必填
   */
  required?: boolean

  /**
   * 字段的各种配置和规则
   */
  container?: LegoFormFieldContainer | null

  /**
   * 是否只读
   */
  readonly?: boolean

  /**
   * 默认值
   */
  defaultAnswer?: {
    name?: string
    label?: string
    score?: string
    attributes?: FormAnswerReq['attributes']
  } | null

  /**
   * 是否允许下载附件
   */
  allowDownloadAttachments?: boolean
  /**
   * 选项序号
   */
  optionSerialNumber?: FormTemplateFieldOptionSerialNumberEnum

  /**
   * 标记字段是否删除
   */
  deleted?: boolean
  /**
   * 标记字段是否停用
   */
  inactivated?: boolean
  /**
   * 选项分列，数字为列数。
   * 目前只适用于多选题目。
   * 如 optionsSplitBy：2 ，即分为两列
   */
  optionsSplitBy?: number

  /**
   * 表格中的列字段
   */
  tableFields?: LegoFormTableField[]

  pid?: number

  id?: number

  value?: any

  idx?: any

  [key: string]: any
}

export enum LegoFormFieldTypeEnum {
  Attachment = 'attachment',
  Checkbox = 'checkbox',
  Paragraph = 'paragraph',
  Radio = 'radio',
  Score = 'score',
  Select = 'select',
  Textarea = 'textarea',
  IdCard = 'idcard',
  Phone = 'phone',
  Nationality = 'nationality',
  Address = 'address',
  Input = 'input',
  Number = 'number',
  Money = 'money',
  DateTime = 'date_time',
  DateRange = 'date_range',
  RichText = 'rich_text',
  TravelCard = 'travel_card',
  Cascader = 'cascade_selector',
  Table = 'table',
  // 矩阵单选
  MatrixRadio = 'matrix_radio',
  /**
   * 自定义组件，需要外部传入 component
   */
  Biz = 'biz',
  /**
   * 自定义组件，需要外部传入 component
   *
   * @deprecated
   */
  Custom = 'custom',
}

// 业务 Field 定义
export interface BizFieldDefinition {
  /**
   * 名称，唯一
   */
  name: string
  /**
   * field 名称，决定左侧工具栏的图标名称与右侧表单插入时的名称
   */
  label: string
  /**
   * 决定左侧工具栏的图标
   */
  icon?: IconDefinition
  /**
   * 自定义组件，如果传入，则 type 将不影响表单渲染
   */
  component?: React.ReactNode
  /**
   * field 类型，决定图标渲染, 若传入 LegoFormFieldTypeEnum 有内置图标, 其余类型需要自行传入 icon
   *
   * 需要注意的是，现在业务组件的只读，仅支持 Attachment/Input/Textarea/Number/Score
   */
  type: string
  /**
   * 是否可设置必填，默认为 true
   */
  requirable?: boolean
  /**
   * 是否固定存在
   */
  fixed?: boolean
  /**
   * 是否有确定的排序顺序
   */
  sort?: number
  /**
   * 标题是否可编辑
   */
  labelEditable?: boolean
  /**
   * 群组名称
   */
  group?: string
  /**
   * 添加该项时的默认值
   */
  defaultField?: Partial<LegoFormField>
  /**
   * 是否可设置只读，默认为 true
   */
  readonliable?: boolean
  /**
   * 是否作为通用组件
   *
   * 设置后，该字段会被归类到通用组件中，且可以重复添加
   */
  isCommon?: boolean
  /**
   * 是否禁止添加, 当传入 string 时，将显示该字符串作为禁用原因, 默认 false
   */
  disabledAdd?: boolean | string
  /**
   * 是否在左侧工具栏隐藏, 默认 false
   */
  hiddenInToolbar?: boolean
  /**
   * 是否被纳入跳转
   * 如果是单选/多选/下拉，则可以设置跳转
   */
  jumpable?: boolean
}

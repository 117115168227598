import classNames from 'classnames'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import xss, { getDefaultWhiteList } from 'xss'

import fileSvg from './assets/file-svg.svg'

/**
 * 解码被后端编码过的 HTML 字符串
 *
 * @param str - 字符串
 * @returns 字符串
 */
export function htmlDecodeByRegExp(str?: string) {
  let s = ''
  if (!str) return ''
  s = str.replace(/&amp;/g, '&')
  s = s.replace(/&lt;/g, '<')
  s = s.replace(/&gt;/g, '>')
  s = s.replace(/&nbsp;/g, ' ')
  s = s.replace(/&#39;/g, "'")
  s = s.replace(/&quot;/g, '"')
  return s
}

/**
 * 用于渲染文章（富文本）
 *
 * @param content - 内容
 * @param enabledXSSFilter - 启用 xss 过滤
 * @param xssOptions - xss 配置
 * @param xssOptions.allowIFrame - 是否允许渲染 iframe 标签
 * @param onClickImage - 点击图片后的回调
 * @param onClickFile - 点击文件后的回调
 * @param onClickLink - 点击链接后的回调
 * @param className - 类名
 * @param style - 样式
 * @returns 组件
 */
export const Article: React.FC<{
  content: string
  enabledXSSFilter?: boolean
  xssOptions?: {
    allowIFrame?: boolean
  }
  onClickImage?: (imgUrls: string[], index: number) => void
  onClickFile?: (fileUrl: string, fileName: string) => void
  onClickLink?: (url: string, linkName: string) => void
  className?: string
  style?: React.CSSProperties
}> = React.memo(
  ({
    content,
    enabledXSSFilter,
    xssOptions,
    onClickImage,
    onClickFile,
    onClickLink,
    className,
    style,
  }) => {
    const html = useMemo(() => {
      let str = htmlDecodeByRegExp(content)

      if (enabledXSSFilter) {
        const whiteList = getDefaultWhiteList()

        if (xssOptions?.allowIFrame) {
          whiteList['iframe'] = ['src', 'style', 'width', 'height']
        }

        str = xss(str, {
          whiteList,
        })
      }

      return str
    }, [content, enabledXSSFilter, xssOptions?.allowIFrame])

    return (
      <Wrapper
        style={style}
        className={classNames(['seiue-article', className])}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
        ref={target => {
          if (target) {
            // 处理图片
            if (onClickImage) {
              const images = target.querySelectorAll('img')
              const imageUrlList: string[] = []
              for (let i = 0; i < images.length; i += 1) {
                imageUrlList.push(images[i].src)
              }

              for (let i = 0; i < images.length; i += 1) {
                const image = images[i]

                image.addEventListener('click', e => {
                  e.preventDefault()
                  e.stopPropagation()

                  onClickImage(imageUrlList, i)
                  return false
                })
              }
            }

            // 处理附件
            if (onClickFile) {
              const files = target.querySelectorAll(
                'a[data-flag="attachment"]',
              ) as NodeListOf<HTMLAnchorElement>

              for (let i = 0; i < files.length; i += 1) {
                const file = files[i]

                file.addEventListener('click', e => {
                  e.preventDefault()
                  e.stopPropagation()
                  onClickFile(file.href, file.innerText)

                  return false
                })
              }
            }

            // 处理链接
            if (onClickLink) {
              const links = target.querySelectorAll(
                'a.richtext__link',
              ) as NodeListOf<HTMLAnchorElement>

              for (let i = 0; i < links.length; i += 1) {
                const link = links[i]
                link.addEventListener('click', e => {
                  e.preventDefault()
                  e.stopPropagation()
                  onClickLink(link.href, link.innerText)

                  return false
                })
              }
            }
          }
        }}
      />
    )
  },
)

const fontSizeArticle = 15

const Wrapper = styled.div`
  white-space: pre-wrap;
  text-align: justify;
  word-break: break-all;

  p {
    margin: ${fontSizeArticle * 0.7}px 0;
    color: ${props => props.theme.text._1};
    font-size: ${fontSizeArticle}px;
    line-height: 1.5;
  }

  h1,
  h2,
  h3 {
    margin: ${fontSizeArticle * 0.7}px 0;
    color: ${props => props.theme.text._1};
    font-weight: bold;
    line-height: 1.5;
    white-space: pre-wrap;
  }
  h1 {
    margin: ${fontSizeArticle * 1.6 * 0.7}px 0;
    font-size: ${fontSizeArticle * 1.6}px;
  }
  h2 {
    margin: ${fontSizeArticle * 1.33 * 0.7}px 0;
    font-size: ${fontSizeArticle * 1.33}px;
  }

  h3 {
    margin: ${fontSizeArticle * 1.2 * 0.7}px 0;
    font-size: ${fontSizeArticle * 1.2}px;
  }

  p + h1,
  p + h2,
  p + h3 {
    margin-top: 24px;
  }

  p:first-child,
  h1:first-child,
  h2:first-child,
  h3:first-child {
    margin-top: 0;
  }

  a.file-link,
  .richtext__file {
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin: 15px 0;
    padding: 0 32px;
    overflow: hidden;
    color: ${p => p.theme.brand._1};
    font-size: ${fontSizeArticle}px;
    line-height: ${2 * fontSizeArticle}px;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    word-wrap: normal;
    background-color: transparent;
    background-image: url(${fileSvg});
    background-repeat: no-repeat;
    background-position: 0px 2px;
    background-size: 24px 24px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;

    &:hover {
      background-color: #f1f1f1;
    }
  }

  .richtext__image,
  img {
    display: block;
    max-width: 100%;
    margin-bottom: 15px;
  }

  .richtext__link,
  a {
    color: ${p => p.theme.brand._1};
    text-decoration: none;
    background-color: rgba(71, 124, 244, 0.1);
  }
`

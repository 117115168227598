import { omit } from '@seiue/util'
import { useAtomValue } from 'jotai'
import React from 'react'

import { getSavedScores } from 'packages/feature-utils/classes'
import { myTypeInMoralAssessmentsLoadableAtom } from 'packages/feature-utils/moral-assessments/atoms'
import { useLoadCurrentPupil } from 'packages/features/reflections/utils/apis'
import { useCurrentReflection } from 'packages/features/sessions'
import { Expand, MutationOptions, wait } from 'packages/sdks-next'
import { RoleEnum } from 'packages/sdks-next/chalk'
import {
  Assessment,
  EvaluatedScoreReq,
  EvaluateTargetEnum,
  GetMoralEvaluatedScoresQuery,
  ItemTypeEnum,
  MoralAssessmentTypeEnum,
  MoralPolicyEnum,
  QueryAssessmentsForEvaluatorQuery,
  QueryEvaluatedForAdminQuery,
  QueryEvaluatedForEvaluatorQuery,
  ScoreTypeEnum,
  Item,
  RelationsEnum,
  TeacherAssessmentRoleEnum,
  ScoreStatusEnum,
  ScoringTypeEnum,
  commonApi$queryAssessmentsForEvaluator,
  commonApi$queryAssessmentsForEvaluated,
  defaultApi$getItemScores,
  commonApi$queryEvaluatedForAdmin,
  commonApi$queryEvaluatedForEvaluator,
  commonApi$syncScoresForAdmin,
  commonApi$syncScoresForEvaluator,
  moralApi$queryWriteableItemIds,
  moralApi$queryEvaluatedForEvaluated,
  moralApi$getMoralEvaluatedScores,
  teacherAssessmentApi$queryTeacherAssessmentScoreLogs,
} from 'packages/sdks-next/vnas'

import { fulfillParentItems } from '../grades/utils'

import { SavedScoresType } from './types'

/**
 * 获取德育评价-我的录入，评价数据
 *
 *
 * @returns [评价数据, 加载状态]
 */
export const useMyTypeInMoralAssessmentsAtom = (): [Assessment[], boolean] => {
  const assessments = useAtomValue(myTypeInMoralAssessmentsLoadableAtom)
  if (assessments.state === 'loading' || assessments.state === 'hasError') {
    return [[], assessments.state === 'loading']
  }

  return [assessments.data, false]
}

/**
 * 获取德育评价-我的录入，评价数据
 *
 *
 * @returns UseQueryApiReturns<Assessment>
 */
export const useMyTypeInMoralAssessments = () => {
  const currentReflection = useCurrentReflection()
  const assessmentsForEvaluator = commonApi$queryAssessmentsForEvaluator.useApi(
    {
      operationType: MoralAssessmentTypeEnum.Moral,
    },
    {
      disable: currentReflection.role !== RoleEnum.Teacher,
    },
  )

  const assessmentsForEvaluated = commonApi$queryAssessmentsForEvaluated.useApi(
    {
      operationType: MoralAssessmentTypeEnum.Moral,
      ownerId: currentReflection.id,
      policy: MoralPolicyEnum.Evaluated,
      query: {
        meIsEvaluator: true,
      },
    },
    {
      disable: currentReflection.role === RoleEnum.Teacher,
    },
  )

  if (currentReflection.role === RoleEnum.Teacher) {
    return assessmentsForEvaluator
  }

  return assessmentsForEvaluated
}

/**
 * 获取计算后的评价人
 *
 * @param params - Argument Object
 * @param params.disable - 是否禁用
 * @returns 计算后的评价人
 */
export const useEvaluatedMember = (params?: { disable: boolean }) => {
  const currentReflection = useCurrentReflection()
  const [pupil, loadingPupil] = useLoadCurrentPupil({
    disable: params?.disable || currentReflection.role !== RoleEnum.Guardian,
  })

  return {
    // 如果当前是家长身份，要始终取孩子的 rid
    evaluatedMember:
      currentReflection.role === RoleEnum.Guardian ? pupil : currentReflection,
    loading: loadingPupil,
  }
}

type QueryParams = {
  operationType: MoralAssessmentTypeEnum
  policy: MoralPolicyEnum
  evaluatedRid?: number | null
  query?: QueryAssessmentsForEvaluatorQuery
}

/**
 * 获取查询「我的评价」的参数
 *
 * @param options - Argument Object
 * @param options.disable - 是否禁用
 * @returns [QueryParams, boolean]
 */
export const useEvaluatedQueryParams = (options?: {
  disable: boolean
}): [QueryParams, boolean] => {
  const { evaluatedMember, loading: loadingEvaluatedMember } =
    useEvaluatedMember({
      disable: !!options?.disable,
    })

  const params = {
    operationType: MoralAssessmentTypeEnum.Moral,
    policy: MoralPolicyEnum.Evaluated,
    evaluatedRid: evaluatedMember?.id,
  }

  return [params, loadingEvaluatedMember]
}

/**
 * 获取查询「我的录入」评价数据的参数
 *
 * @returns policy
 */
export const useTypeInPolicy = () => {
  const currentReflection = useCurrentReflection()

  const isTeacher = currentReflection.role === RoleEnum.Teacher
  /**
   * 评价人是学生或家长时，后端沿用之前逻辑的接口，policy 为 Evaluated
   * 2023-02-15
   */
  const policy = isTeacher
    ? MoralPolicyEnum.Evaluator
    : MoralPolicyEnum.Evaluated

  return policy
}

/**
 * 获取查询「我的录入」评价数据的参数
 *
 * @returns [QueryParams, boolean]
 */
export const useTypeInQueryParams = (): [QueryParams, boolean] => {
  const currentReflection = useCurrentReflection()

  const isTeacher = currentReflection.role === RoleEnum.Teacher
  const policy = useTypeInPolicy()

  const params = {
    operationType: MoralAssessmentTypeEnum.Moral,
    policy,
    evaluatedRid: currentReflection.id,
    query: !isTeacher
      ? {
          meIsEvaluator: true,
        }
      : undefined,
  }

  return [params, false]
}

/**
 * 评价数据处理 utils
 *
 * @param props - props
 * @param props.assessment - 评价
 * @param props.ownerIds - 被评价人 ids
 * @param props.item - 评价
 * @param props.policy - 权限策略
 * @param props.teacherAssessmentAsRole - 教师考核时，评价\审核人员的角色
 * @returns 评价数据和处理评价数据的各种方法
 */
export const useMoralEntryScores = ({
  assessment,
  ownerIds,
  item,
  policy,
  teacherAssessmentAsRole,
}: {
  assessment: Assessment
  ownerIds: number[]
  item?: Item
  policy: MoralPolicyEnum
  teacherAssessmentAsRole?: TeacherAssessmentRoleEnum
}) => {
  const itemId = item?.id
  const ownerIdIn = ownerIds.join(',')

  // 评价项分数列表
  const {
    data: scores,
    loading: loadingScores,
    reload: reloadScores,
    refetching: refetchingScores,
  } = defaultApi$getItemScores.useApi(
    {
      id: itemId || 0,
      query: {
        paginated: 0,
        type: ScoreTypeEnum.ItemScore,
        ownerIdIn,
        policy,
        teacherAssessmentAsRole,
      },
    },
    {
      disable: !itemId || !ownerIdIn,
      select: _scores => {
        return _scores.filter(score => score.status !== ScoreStatusEnum.Pending)
      },
    },
  )

  // 评价项子分数列表
  const {
    data: rawScoresOrigin,
    loading: loadingRawScores,
    reload: reloadRawScores,
    refetching: refetchingRawScores,
  } = defaultApi$getItemScores.useApi(
    {
      id: itemId || 0,
      query: {
        paginated: 0,
        type: ScoreTypeEnum.Raw,
        tryExpand: ['evaluator'],
        ownerIdIn,
        policy,
        teacherAssessmentAsRole,
      },
    },
    { disable: !itemId || !ownerIdIn },
  )

  const rawScores = React.useMemo(() => {
    // 如果是需审核的加减分项，需要过滤掉未审核的分数
    if (
      assessment.isTeacherAssessment &&
      item?.scoringType === ScoringTypeEnum.Addition &&
      item?.relationRules?.reviewRelationIds?.length
    ) {
      return rawScoresOrigin?.filter(score => {
        return scores?.some(_score => _score.ownerId === score.ownerId)
      })
    }

    return rawScoresOrigin
  }, [
    assessment.isTeacherAssessment,
    item?.scoringType,
    item?.relationRules?.reviewRelationIds?.length,
    rawScoresOrigin,
    scores,
  ])

  const [savedScores, setSavedScores] = React.useState<SavedScoresType>([])
  const [savedRawScores, setSavedRawScores] = React.useState<SavedScoresType>(
    [],
  )

  const reload = () => {
    reloadScores()
    reloadRawScores()
  }

  return {
    scores: scores || [],
    savedScores,
    setSavedScores: (innerScores: SavedScoresType) => {
      setSavedScores(prev => {
        const mergedScores = [...prev, ...innerScores]
        const newScores: SavedScoresType = []

        mergedScores.forEach(score => {
          const existedIndex = newScores.findIndex(
            _score => _score.ownerId === score.ownerId,
          )

          if (existedIndex !== -1) {
            const target = newScores[existedIndex]
            newScores.splice(existedIndex, 1, {
              ...target,
              ...score,
            })
          } else {
            newScores.push({
              ...score,
              type: ScoreTypeEnum.ItemScore,
            })
          }
        })

        return newScores
      })
    },
    rawScores: rawScores || [],
    savedRawScores,
    setSavedRawScores: (innerRawScores: SavedScoresType) => {
      setSavedRawScores(prev => {
        const mergedRawScores = [...prev, ...innerRawScores]
        const newRawScores: SavedScoresType = []

        mergedRawScores.forEach(score => {
          const existedIndex = newRawScores.findIndex(
            _score => _score.ownerId === score.ownerId,
          )

          if (existedIndex !== -1) {
            const target = newRawScores[existedIndex]
            newRawScores.splice(existedIndex, 1, {
              ...target,
              ...score,
            })
          } else {
            newRawScores.push({
              ...score,
              type: ScoreTypeEnum.Raw,
            })
          }
        })

        return newRawScores
      })
    },
    loadingScores: loadingScores || loadingRawScores,
    refetchingScores: refetchingScores || refetchingRawScores,
    reload,
    clearSavedScores: () => {
      setSavedScores([])
      setSavedRawScores([])
    },
  }
}

interface HooksProps {
  policy: MoralPolicyEnum
  operationType: MoralAssessmentTypeEnum
  item?: Item
  params: {
    assessmentId: number
    query: QueryEvaluatedForAdminQuery | QueryEvaluatedForEvaluatorQuery
  }
}

/**
 * 获取查询单个评价的被评价成员方法 hooks api
 *
 * @param props - props
 * @param props.policy - 管理者还是被评人
 * @param props.operationType - 评价类型
 * @param props.item - 评价项
 * @param props.params - 接口参数
 * @returns hooks api
 */
export const useQueryEvaluatedByPolicy = ({
  policy,
  operationType,
  item,
  params,
}: HooksProps) => {
  const { query } = params

  const isMarkInTeam = item?.relation === RelationsEnum.StudentMarkInTeam

  /**
   * 如果是小组互评，每个 item 的评价成员可能不同，所以需要传入 itemId
   */
  const finalQuery = isMarkInTeam
    ? {
        ...query,
        itemId: item.id,
      }
    : omit(query, 'itemId')

  // 如果是筛选的话, 需要传递 item_id_in
  let filterQuery = {}
  if ((query.tagIn || query.tagIsEmpty) && item) {
    filterQuery = {
      itemIdIn: String(item.id),
    }
  }

  const evaluatedForAdmin = commonApi$queryEvaluatedForAdmin.useApi(
    {
      operationType,
      id: params.assessmentId,
      query: { ...omit(params.query, 'itemId'), ...filterQuery },
    },
    {
      disable: policy !== MoralPolicyEnum.Admin,
    },
  )

  const evaluatedForEvaluator = commonApi$queryEvaluatedForEvaluator.useApi(
    {
      operationType,
      id: params.assessmentId,
      query: { ...finalQuery, ...filterQuery },
    },
    {
      disable: policy === MoralPolicyEnum.Admin,
    },
  )

  return policy === MoralPolicyEnum.Admin
    ? evaluatedForAdmin
    : evaluatedForEvaluator
}

/**
 * 获取教务批量同步（创建或修改）分数方法 hooks api
 *
 * @param policy - 管理者还是被评人
 * @param mutationOptions - hook 操作选项配置
 * @returns hooks api
 */
export const useSyncMoralScoresByPolicy = (
  policy: MoralPolicyEnum.Admin | MoralPolicyEnum.Evaluator,
  mutationOptions?: MutationOptions,
) =>
  policy === MoralPolicyEnum.Admin
    ? commonApi$syncScoresForAdmin.useApi(mutationOptions).api
    : commonApi$syncScoresForEvaluator.useApi(mutationOptions).api

/**
 * 查询评价人或者被评价人可录入的评价项
 *
 * @param props - props
 * @param props.assessment - 评价信息
 * @param props.policy - 权限策略
 * @param props.disabled - 是否禁用
 * @returns 可录入的评价项
 */
export const useWriteableItems = ({
  assessment,
  policy,
  disabled,
}: {
  assessment?: Expand<Assessment, ['items']> | null
  policy: MoralPolicyEnum
  disabled?: boolean
}) => {
  const { data: writeableItemIds, loading } =
    moralApi$queryWriteableItemIds.useApi(assessment?.id || 0, {
      disable: disabled || !assessment,
    })

  const originWriteableItems = writeableItemIds
    ? assessment?.items.filter(item => {
        if (
          policy !== MoralPolicyEnum.Evaluated &&
          item.type === ItemTypeEnum.AttendanceItem
        ) {
          return true
        }

        return writeableItemIds.includes(item.id)
      })
    : []

  if (!assessment || !originWriteableItems) {
    return {
      writeableItems: [],
      writeableItemIds: [],
      loading,
    }
  }

  const writeableItems = fulfillParentItems({
    assessment,
    items: originWriteableItems,
  })

  return {
    writeableItems,
    writeableItemIds,
    loading,
  }
}

/**
 * 获取我可查看的被评人列表，评价对象为行政班时可用
 *
 * @param param - Argument Object
 * @param param.assessment - 评价信息
 * @returns 我可查看的被评人列表
 */
export const useEvaluatedAdminClassMembers = ({
  assessment,
}: {
  assessment?: Assessment | null
}) => {
  return moralApi$queryEvaluatedForEvaluated.useApi(
    {
      id: assessment?.id || 0,
      query: {
        tryExpand: ['grade', ['grade', 'label']],
      },
    },
    {
      disable:
        !assessment ||
        assessment.evaluateTarget !== EvaluateTargetEnum.AdminclassGroup,
    },
  )
}

/**
 * 获取德育被评人所有分数(评价项分数、评价项子分数)
 *
 * @param param - 参数
 * @param param.assessment - 评价
 * @param param.rid - 被评人 id
 * @param param.query - 查询参数
 * @returns UseQueryApiReturns<Score[]>
 */
export const useMoralEvaluatedAllScores = ({
  assessment,
  rid,
  query,
}: {
  assessment: Assessment
  rid: number
  query?: GetMoralEvaluatedScoresQuery
}) => {
  // 评价项分数列表
  const {
    data: scores,
    loading: loadingScores,
    reload: reloadScores,
    refetching: refetchingScores,
  } = moralApi$getMoralEvaluatedScores.useApi({
    rid,
    id: assessment.id,
    query: {
      ...query,
      type: ScoreTypeEnum.ItemScore,
    },
  })

  // 评价项子分数列表
  const {
    data: rawScores,
    loading: loadingRawScores,
    reload: reloadRawScores,
    refetching: refetchingRawScores,
  } = moralApi$getMoralEvaluatedScores.useApi({
    rid,
    id: assessment.id,
    query: {
      ...query,
      type: ScoreTypeEnum.Raw,
    },
  })

  return {
    data: [...(scores || []), ...(rawScores || [])],
    loading: loadingRawScores || loadingScores,
    reload: () => {
      reloadScores()
      reloadRawScores()
    },
    refetching: refetchingRawScores || refetchingScores,
  }
}

/**
 * 评价数据处理 utils
 *
 * @param props - props
 * @param props.assessment - 评价
 * @param props.rid - 被评人 id
 * @returns 评价数据和处理评价数据的各种方法
 */
export const useMoralEntryEvaluatedScores = ({
  assessment,
  rid,
}: {
  assessment: Assessment
  rid?: number
}) => {
  const { data, loading, reload, refetching } = useMoralEvaluatedAllScores({
    assessment,
    rid: wait(rid),
    query: {
      tryExpand: ['evaluator'],
    },
  })

  const [savedScores, setSavedScores] = React.useState<EvaluatedScoreReq[]>([])

  return {
    scores: data,
    savedScores,
    setSavedScores: (innerScores: EvaluatedScoreReq[]) => {
      const newScores = getSavedScores({ innerScores, savedScores })
      setSavedScores(newScores)
    },
    loadingScores: loading,
    refetchingScores: refetching,
    reload,
    clearSavedScores: () => setSavedScores([]),
  }
}

/**
 * 教师评价项分数日志
 *
 * @param param0 - 参数
 * @param param0.assessment - 评价
 * @param param0.itemId - 评价项 id
 * @param param0.teacherAssessmentAsRole - 教师评价角色
 * @returns UseQueryApiReturns<BizLog[]>
 */
export const useAdminTeacherAssessmentScoreLogs = ({
  assessment,
  itemId,
  teacherAssessmentAsRole,
}: {
  assessment: Assessment
  itemId?: number | string
  teacherAssessmentAsRole?: TeacherAssessmentRoleEnum
}) => {
  return teacherAssessmentApi$queryTeacherAssessmentScoreLogs.useApi(
    {
      itemId: Number(itemId) || 0,
      query: {
        paginated: 0,
        policy: MoralPolicyEnum.Admin,
        teacherAssessmentAsRole,
      },
    },
    {
      disable: !assessment.isTeacherAssessment || !itemId,
    },
  )
}

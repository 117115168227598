/**
 * @file 日程消息类型
 */

export enum ScheduleMessageEnum {
  Reminded = 'reminded',
  /*
   * 创建 classin 时发起的提醒
   */
  ClassInAccountUnbind = 'classin_account_unbind',
  /*
   * 参与人拒绝了日程后，给发起者发送消息
   */
  ScheduleMemberUpdatedToOwner = 'schedule_member_updated_to_owner',
  /*
   * 参与人收到发起人邀约，给参与发送消息
   */
  ScheduleCreatedToPartner = 'schedule_created_to_partner',
  /*
   * 发起人发起日程后，对该日程进行修改，并通知参与人，收到消息
   */
  ScheduleUpdatedToPartner = 'schedule_updated_to_partner',
  /*
   * 发起人删除了日程后，参与人收到日程消息
   */
  ScheduleDeletedToPartner = 'schedule_deleted_to_partner',
}

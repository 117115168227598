/**
 * @file 待办类型枚举
 *
 * Todo 的类型，由后端提供的元信息，接入新业务时需扩展此处定义。
 * 该定义在后端是分布式注册的，因此不能直接定义在 openapi 文档中。
 * See full list at https://www.yuque.com/kovru3/gfdy75/ipc6u6
 */
import { createEnumHelper } from '@seiue/util'

import {
  FlowFieldValue,
  FlowStatusEnum,
} from 'packages/features/workflows/types'
import { DormDailyNoticeProps } from 'packages/plugins/features/dorms'
import { Expand } from 'packages/sdks-next'
import {
  Task,
  SignupStatusEnum,
  SignupSubmissionStats,
  SecuredReflection,
  Event,
} from 'packages/sdks-next/chalk'
import {
  Field,
  FlowNode,
  Questionnaire,
  TeacherProfile,
} from 'packages/sdks-next/form'
import { AttendanceTime } from 'packages/sdks-next/sams'
import {
  ClassAdjustment,
  ClassLesson,
  OwnerDeclarationTemplate,
  DirectionResult,
  FormTemplate,
  OrderTypeEnum,
  Reflection,
  Election as ElectionOrigin,
} from 'packages/sdks-next/scms'
import { Assessment, Goal } from 'packages/sdks-next/sgms'
import {
  AfterthoughtDomainEnum,
  Evaluation,
  SettingCodeMatchFieldEnum,
} from 'packages/sdks-next/vnas'

import { TodoDomainEnum } from './todo-domain-enum'

/**
 * 待办类型
 */
export enum TodoTypeEnum {
  CertificationApprove = 'seiue.certification.approve_todo',
  VenueOrderApprove = 'seiue.venue.order_approve',
  EvaluationAnswer = 'seiue.evaluation.answer',
  DormDailyNotice = 'seiue.attendance.dorm_daily_notice',
  Unknown = 'unknown',
  ClassAdjustmentApprovalProcess = 'seiue.class_adjustment.approval_process',
  /**
   * @deprecated
   */
  LeaveFlowApproval = 'seiue.leave_flow_approval',
  /**
   * @deprecated
   */
  LeaveFlowSubmitCertificate = 'seiue.leave_flow_submit_certificate',
  /**
   * @deprecated
   */
  LeaveFlowVerification = 'seiue.leave_flow_verification',
  TaskSubmitTodo = 'seiue.task_submit_todo',
  TaskApproveTodo = 'seiue.task_approve_todo',
  AttendanceTeacherDailyNotice = 'seiue.attendance.teacher_daily_notice',
  GoalNoRecordRemind = 'seiue.goal.no_record_remind',
  ClassAssessmentAfterthought = 'seiue.assessment_afterthought_todo',
  ClassAssessmentItemAfterthought = 'seiue.item_afterthought_todo',
  SubjectSelectionConfirmResult = 'seiue.direction.confirm_result',
  SubjectSelectionStartNotification = 'seiue.direction.start_notification',
  Declaration = 'seiue.declaration',
  ElectionTeacher = 'seiue.scms.election.teacher',
  ElectionStudent = 'seiue.scms.election.student',
  ElectionGuardian = 'seiue.scms.election.guardian',
  EventMemberApproval = 'seiue.event_member_approval',
  EventApproval = 'seiue.event_approval',
  EventAttendanceDailyNotice = 'seiue.attendance.event_daily_notice',
  CustomizedGroupSignupCreated = 'seiue.signup_created',
  CustomizedGroupSignupSubmissionReview = 'seiue.signup_submission_review',
  AcademicGoalConfirm = 'seiue.subject_selections.confirm_result',
  QuestionnaireAnswer = 'seiue.questionnaire.answer',
  QuestionnaireRelation = 'seiue.questionnaire.relation',
  CustomizedGroupInputAttendance = 'seiue.attendance.cg_daily_notice',
  WorkflowApprover = 'seiue.workflow_approver_approval',
  WorkflowHandler = 'seiue.workflow_handler_approval',
  WorkflowInitiator = 'seiue.workflow_initiator_evaluation',
  StudentStatusAnswer = 'seiue.student_status.answer',
  PsycoProblemReport = 'seiue.psychological.problem_report',
  PluginPsyStudentSubmitAssessment = 'seiue.psychological.assessment',
  ScheduleInvited = 'owner.calendar.invited',
  ScheduleReminded = 'owner.calendar.reminded',
  OnlineClassUnbindToReflection = 'online_class.unbind_to_reflection',
  // 约谈待办
  PluginChatSubmitRecord = 'chat.submit_record',
  PluginChatSubmitEvaluation = 'chat.submit_evaluation',
  PluginChatSubmitFeedback = 'chat.submit_feedback',
  PluginChatApplyChatTask = 'chat.apply_chat_task',
  // 心理咨询提交记录待办
  PluginPsyChatSubmitRecord = 'psy_chat.submit_record',
  TeacherProfileAnswer = 'seiue.teacher_profile.answer',
  NotificationDiscussionReplied = 'seiue.notification_discussion_replied',
  HandoutAnswer = 'handout.answer',
  HandoutReview = 'handout.review',
  AdminClassAttendanceDailyNotice = 'seiue.attendance.adminclass_daily_notice',
  ContractConfirm = 'seiue.contract.confirm',
  /**
   * 在线阅卷教师评分
   */
  OnlineScoringTeacherReview = 'seiue.exam_scoring.review',
  /**
   * 学段成绩录入
   */
  StageGradeSubmit = 'seiue.stage_grade_submit',
  /**
   * 教师赋分
   */
  GzAcademicCreditSubmission = 'gz_academic_credit.submission',
  GzAcademicCreditVerification = 'gz_academic_credit.verification',
  GzAcademicCreditAppeal = 'gz_academic_credit.appeal',
  /**
   * 作业调查
   */
  SzHomeworkSurveySubmission = 'sz_homework_survey.submission',
  /**
   *  课程班-评价项-学生自评
   */
  ItemStudentMarkStudent = 'seiue.item_student_mark_student',

  /**
   * 活动审批流待办
   */
  EventWorkflow = 'seiue.chalk:event',

  /**
   * 听评课邀请待办
   */
  ClassReviewInvited = 'class-review.invited',

  /**
   * 学期评语 - 评语录入待办
   */
  SemesterReviewSubmit = 'seiue.semester_review_submit',

  /**
   * 阅卷 - 问题卷
   */
  OnlineScoringProblemPage = 'seiue.exam_scoring.problem_page',
}

interface WorkflowTodoreated {
  flow: {
    id: number
    fieldValues: FlowFieldValue[]
    nodes?: FlowNode[]
    initiatorId: number
    initiator: Reflection
    status: FlowStatusEnum
    workflowId: number
  }
  workflow: {
    id: number
    fields: Field[]
    schoolPlugin: { label: string }
    extensionType?: string
  }
}

export type EventWorkflow = {
  flow: {
    id: number
    fieldValues: FlowFieldValue[]
    nodes?: FlowNode[]
    initiatorId: number
    initiator: Reflection
    status: FlowStatusEnum
    workflowId: number
  }
  workflow: {
    id: number
    fields: Field[]
    schoolPlugin: { label: string }
    extensionType?: string
  }
  event?: Expand<Event, ['sessions']>
}

/**
 * 具体待办的 related 数据声明
 */
export type SpecTodoRelated = {
  [TodoDomainEnum.Venue]: {
    orderId: number
    orderType: OrderTypeEnum
    venueId: number
    orderInitiator: Reflection
  }
  [TodoDomainEnum.Evaluation]: Expand<Evaluation, ['formTemplate']>
  [TodoDomainEnum.ClassAdjustment]: Expand<ClassAdjustment, ['stages', 'owner']>
  [TodoDomainEnum.Goal]: Goal
  [TodoDomainEnum.LeaveFlow]: {
    leaveMinutes: number
    nodeName: string
    reflectionCount: number
    reflectionName: string
    stageId: number
    workflowId: number
    flow: {
      id: number
      initiatorId: number
      initiator: {
        id: number
        name: string
      }
      type: string
    }
  }
  [TodoDomainEnum.Attendance]:
    | ClassLesson
    | {
        bizId: number
        attendanceTime: AttendanceTime
      }
  [TodoDomainEnum.Task]: Task & {
    parentTask?: Task
  }
  [TodoDomainEnum.Assessment]:
    | {
        domain: AfterthoughtDomainEnum
        bizId: number
        domainBizId: number
        scopeName: string
        teacherNames?: string | null
        classId?: number
        stageId?: number
      }
    | {
        assessmentId: number
        itemId: number
        classId: number
        class: {
          name: string
          className: string
        }
        lockRule: {
          endAt: string
        }
      }
  [TodoDomainEnum.SubjectSelection]: FormTemplate | DirectionResult
  [TodoDomainEnum.Certification]: {
    appliedAt: string
    certificationId: number
    certificationTypeId: number
    certificationTypeName: string
    reflectionId: number
    reflectionName: string
  }
  [TodoDomainEnum.Declaration]: OwnerDeclarationTemplate
  [TodoDomainEnum.Election]: ElectionOrigin
  [TodoDomainEnum.Event]: {
    minStartAt: string
    pendingCount: number
    allCount: number
    initiatorNames: string[]
  }
  [TodoDomainEnum.CustomGroup]:
    | {
        signupId: number
        status: SignupStatusEnum
        startAt?: string | null
        endAt?: string | null
      }
    | {
        signupId: number
        customGroupId: number
        signupSubmissionId: number
        signupEndAt?: string | null
        submissionStats?: SignupSubmissionStats | null
      }
    | {
        groupTypeId: number
        customGroupId: number
        attendanceTimeDate?: string
        attendanceTimeStartAt?: string
        attendanceTimeEndAt?: string
      }
  [TodoDomainEnum.AcademicGoal]: {
    ownerId: number
    confirmedRole: string
    ownerName?: string
    ownerUsin?: number
  }
  [TodoDomainEnum.Questionnaire]: Questionnaire
  [TodoDomainEnum.Workflow]: WorkflowTodoreated
  [TodoDomainEnum.NoCodeWorkflow]: WorkflowTodoreated
  [TodoDomainEnum.StudentStatus]: {
    id: number
    startAt: string
    endAt: string
    name: string
    studentStatusId: number
  }
  [TodoDomainEnum.PluginPsy]:
    | {
        bizId: number
        creatorName: string
      }
    | (Pick<Assessment, 'status' | 'startAt' | 'endAt'> & {
        assessmentId: Assessment['id']
        scaleId: Assessment['scaleId']
      })

  [TodoDomainEnum.Schedule]: {
    classinCourseId?: number | null
    classinLessonId?: number | null
    ownerName?: string
    castUrl?: string
  }
  [TodoDomainEnum.OnlineClass]: null
  [TodoDomainEnum.PsyChat]: {
    chatId: number
    creator: SecuredReflection
  }
  [TodoDomainEnum.Chat]: {
    chatId: number
    creator: SecuredReflection
    chatTaskId?: number
    instanceId?: number
    endAt?: string
  }
  [TodoDomainEnum.TeacherProfile]: TeacherProfile
  [TodoDomainEnum.Dorm]: DormDailyNoticeProps
  [TodoDomainEnum.Notification]: {
    notificationId: string
    title: string
    unreadDiscussionsCount: number
  }
  [TodoDomainEnum.Handout]: {
    classId: number
    handoutId: number
    outlineId: number
    subjectId: number | null
    classTagId: number
    subjectedCount: number
  }
  [TodoDomainEnum.Contract]: {
    endAt: string
    title: string
    id: number
    studentId: number
    studentName?: string
  }
  [TodoDomainEnum.OnlineScoring]: {
    examId: number
    examScoringId: number
    examSubjectId: number
    exceptionCount: number
    examCodeMatchField: SettingCodeMatchFieldEnum
  }
  [TodoDomainEnum.GzAcademicCredit]: {
    taskId: number
    formStatus?: string
    pendingCount?: number
    totalCount?: number
  }
  [TodoDomainEnum.SzHomeworkSurvey]: {
    taskId: number
    formId: number
  }
  [TodoDomainEnum.EventWorkflow]: EventWorkflow
  [TodoDomainEnum.ClassReview]: {
    scheduleId: number
    reflectionId: number
    classReviewId: number
    formTemplateId: number
    lessonFullName: string
    startTime: string
    endTime: string
    sectionMemberStatus: 'pending' | 'approved' | 'rejected'
  }
  [TodoDomainEnum.SemesterReview]: {
    reviewTaskId: number
    groupIds: number[]
  }
}

/**
 * 待办类型所对应的领域
 */
export const todoTypeToDomain = createEnumHelper({
  [TodoTypeEnum.CertificationApprove]: TodoDomainEnum.Certification,
  [TodoTypeEnum.VenueOrderApprove]: TodoDomainEnum.Venue,
  [TodoTypeEnum.EvaluationAnswer]: TodoDomainEnum.Evaluation,
  [TodoTypeEnum.ClassAdjustmentApprovalProcess]: TodoDomainEnum.ClassAdjustment,
  [TodoTypeEnum.TaskSubmitTodo]: TodoDomainEnum.Task,
  [TodoTypeEnum.TaskApproveTodo]: TodoDomainEnum.Task,
  [TodoTypeEnum.DormDailyNotice]: TodoDomainEnum.Dorm,
  [TodoTypeEnum.AttendanceTeacherDailyNotice]: TodoDomainEnum.Attendance,
  [TodoTypeEnum.GoalNoRecordRemind]: TodoDomainEnum.Goal,
  [TodoTypeEnum.LeaveFlowApproval]: TodoDomainEnum.LeaveFlow,
  [TodoTypeEnum.LeaveFlowSubmitCertificate]: TodoDomainEnum.LeaveFlow,
  [TodoTypeEnum.LeaveFlowVerification]: TodoDomainEnum.LeaveFlow,
  [TodoTypeEnum.ClassAssessmentAfterthought]: TodoDomainEnum.Assessment,
  [TodoTypeEnum.StageGradeSubmit]: TodoDomainEnum.Assessment,
  [TodoTypeEnum.ClassAssessmentItemAfterthought]: TodoDomainEnum.Assessment,
  [TodoTypeEnum.ItemStudentMarkStudent]: TodoDomainEnum.Assessment,
  [TodoTypeEnum.SubjectSelectionConfirmResult]: TodoDomainEnum.SubjectSelection,
  [TodoTypeEnum.SubjectSelectionStartNotification]:
    TodoDomainEnum.SubjectSelection,
  [TodoTypeEnum.Declaration]: TodoDomainEnum.Declaration,
  [TodoTypeEnum.ElectionTeacher]: TodoDomainEnum.Election,
  [TodoTypeEnum.ElectionStudent]: TodoDomainEnum.Election,
  [TodoTypeEnum.ElectionGuardian]: TodoDomainEnum.Election,
  [TodoTypeEnum.EventMemberApproval]: TodoDomainEnum.Event,
  [TodoTypeEnum.EventApproval]: TodoDomainEnum.Event,
  [TodoTypeEnum.EventAttendanceDailyNotice]: TodoDomainEnum.Event,
  [TodoTypeEnum.CustomizedGroupSignupCreated]: TodoDomainEnum.CustomGroup,
  [TodoTypeEnum.CustomizedGroupSignupSubmissionReview]:
    TodoDomainEnum.CustomGroup,
  [TodoTypeEnum.QuestionnaireAnswer]: TodoDomainEnum.Questionnaire,
  [TodoTypeEnum.QuestionnaireRelation]: TodoDomainEnum.Questionnaire,
  [TodoTypeEnum.CustomizedGroupInputAttendance]: TodoDomainEnum.CustomGroup,
  [TodoTypeEnum.AcademicGoalConfirm]: TodoDomainEnum.AcademicGoal,
  [TodoTypeEnum.WorkflowInitiator]: TodoDomainEnum.Workflow,
  [TodoTypeEnum.WorkflowHandler]: TodoDomainEnum.Workflow,
  [TodoTypeEnum.WorkflowApprover]: TodoDomainEnum.Workflow,
  [TodoTypeEnum.PsycoProblemReport]: TodoDomainEnum.PluginPsy,
  [TodoTypeEnum.PluginPsyStudentSubmitAssessment]: TodoDomainEnum.PluginPsy,
  [TodoTypeEnum.ScheduleInvited]: TodoDomainEnum.Schedule,
  [TodoTypeEnum.ScheduleReminded]: TodoDomainEnum.Schedule,
  [TodoTypeEnum.StudentStatusAnswer]: TodoDomainEnum.StudentStatus,
  [TodoTypeEnum.OnlineClassUnbindToReflection]: TodoDomainEnum.OnlineClass,
  [TodoTypeEnum.PluginChatSubmitRecord]: TodoDomainEnum.Chat,
  [TodoTypeEnum.PluginChatSubmitEvaluation]: TodoDomainEnum.Chat,
  [TodoTypeEnum.PluginChatSubmitFeedback]: TodoDomainEnum.Chat,
  [TodoTypeEnum.PluginChatApplyChatTask]: TodoDomainEnum.Chat,
  [TodoTypeEnum.TeacherProfileAnswer]: TodoDomainEnum.TeacherProfile,
  [TodoTypeEnum.NotificationDiscussionReplied]: TodoDomainEnum.Notification,
  [TodoTypeEnum.HandoutAnswer]: TodoDomainEnum.Handout,
  [TodoTypeEnum.HandoutReview]: TodoDomainEnum.Handout,
  [TodoTypeEnum.AdminClassAttendanceDailyNotice]: TodoDomainEnum.AdminClass,
  [TodoTypeEnum.ContractConfirm]: TodoDomainEnum.Certification,
  [TodoTypeEnum.OnlineScoringTeacherReview]: TodoDomainEnum.OnlineScoring,
  [TodoTypeEnum.GzAcademicCreditSubmission]: TodoDomainEnum.GzAcademicCredit,
  [TodoTypeEnum.GzAcademicCreditVerification]: TodoDomainEnum.GzAcademicCredit,
  [TodoTypeEnum.GzAcademicCreditAppeal]: TodoDomainEnum.GzAcademicCredit,
  [TodoTypeEnum.SzHomeworkSurveySubmission]: TodoDomainEnum.SzHomeworkSurvey,
  [TodoTypeEnum.PluginPsyChatSubmitRecord]: TodoDomainEnum.PsyChat,
  [TodoTypeEnum.EventWorkflow]: TodoDomainEnum.Event,
  [TodoTypeEnum.ClassReviewInvited]: TodoDomainEnum.ClassReview,
  [TodoTypeEnum.SemesterReviewSubmit]: TodoDomainEnum.SemesterReview,
  [TodoTypeEnum.OnlineScoringProblemPage]: TodoDomainEnum.OnlineScoring,
})

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Term, TermInterface } from './extends/term'
import type { QueryTermQuery } from './query-term-query'

const buildConfig = createConfigBuilder('chalk')

export const termApi$queryTerm = {
  config(
    type: string,
    queryTermQuery?: QueryTermQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/term/{type}/terms',
      {
        type,
      },
      queryTermQuery,
    )
  },

  /**
   * @summary Class QueryTermApi
   * @param type
   * @param [queryTermQuery]
   * @param [termQuery.name]
   * @param [termQuery.parentId]
   * @param [termQuery.schoolId]
   * @param [termQuery.archivedAtIsEmpty]
   * @param [termQuery.archivedAtIsNotEmpty]
   * @param [termQuery.sort]
   * @param [termQuery.expand]
   * @param [options]
   * @return AxiosResponsePromise<Term[]>
   */

  api<const TQuery extends QueryTermQuery>(
    type: string,
    queryTermQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Term, TQuery['expand']>[]> {
    return axios(termApi$queryTerm.config(type, queryTermQuery, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Term(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryTermQuery,
    TSelected = Expand<Term, TQuery['expand']>[],
  >(
    {
      type,
      query,
    }: {
      type: string
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<Term, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Term, TQuery['expand']>[], TSelected>(
      termApi$queryTerm.api,
      'Term.queryTerm',
      queryOptions,
      type,
      query,
    )
  },
}

// @ts-ignore
termApi$queryTerm.api._name_ = `Term.queryTerm`

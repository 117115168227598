/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Todo, TodoInterface } from './todo'
import type { QueryExecutorTodosQuery } from './query-executor-todos-query'

const buildConfig = createConfigBuilder('chalk')

export const todoApi$queryExecutorTodos = {
  config(
    rid: number,
    queryExecutorTodosQuery?: QueryExecutorTodosQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/todo/executors/{rid}/todos',
      {
        rid,
      },
      queryExecutorTodosQuery,
    )
  },

  /**
   * @summary Class QueryExecutorTodosApi
   * @param rid executor rid
   * @param [queryExecutorTodosQuery]
   * @param [todoQuery.type] 待办类型, 同之前的 enhancer
   * @param [todoQuery.domain] 业务领域，比 type 粒度更大的业务分类
   * @param [todoQuery.status] 待办的状态
   * @param [todoQuery.title] title模糊查询
   * @param [todoQuery.titleLike] title模糊查询
   * @param [todoQuery.read] read
   * @param [todoQuery.idIn]
   * @param [todoQuery.bizIdIn]
   * @param [todoQuery.related.subjectId]
   * @param [todoQuery.paginated] 是否分页，默认 1
   * @param [todoQuery.page] 显示的数据页，默认 1
   * @param [todoQuery.perPage] 每页显示数量，默认 20
   * @param [todoQuery.expand] expand
   *
   * 1 . executor 所关联的讨论区
   * 2 . related 相关业务
   * @param [todoQuery.expiredAtElt] 小于等于过期时间
   * @param [todoQuery.expiredAtEgt] 大于等于过期时间
   * @param [todoQuery.createdAtElt] 小于等于创建时间
   * @param [todoQuery.createdAtEgt] 大于等于创建时间
   * @param [options]
   * @return AxiosResponsePromise<Todo[]>
   */

  api<const TQuery extends QueryExecutorTodosQuery>(
    rid: number,
    queryExecutorTodosQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Todo, TQuery['expand']>[]> {
    return axios(
      todoApi$queryExecutorTodos.config(rid, queryExecutorTodosQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Todo(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryExecutorTodosQuery,
    TSelected = Expand<Todo, TQuery['expand']>[],
  >(
    {
      rid,
      query,
    }: {
      rid: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<Todo, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Todo, TQuery['expand']>[], TSelected>(
      todoApi$queryExecutorTodos.api,
      'Todo.queryExecutorTodos',
      queryOptions,
      rid,
      query,
    )
  },
}

// @ts-ignore
todoApi$queryExecutorTodos.api._name_ = `Todo.queryExecutorTodos`

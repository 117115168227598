import { getCompressImageUrl, ZoomMode } from '@seiue/file'
import React, { FC } from 'react'

import { useI18nName } from 'packages/feature-utils/reflections'

import { SeAvatar } from './SeAvatar'
import { useAvatarData } from './hook'
import { AvatarProps, AvatarTypeEnum } from './types'
import { getTextAvatarColors } from './utils'

export * from './utils'
export * from './types'

/**
 * 头像
 *
 * @param props - props
 * @returns component
 */
export const Avatar: FC<AvatarProps> = props => {
  const {
    reflection: targetReflection,
    size,
    onClick,
    forceFirst,
    usePaletteColor,
    useAvatarZoomIn = false,
    style,
    viewerMemberType,
    textFontSize,
  } = props

  const $name = useI18nName()

  const { type, url } = useAvatarData({
    viewerMemberType,
    targetReflection,
    forceFirst,
  })

  const src =
    type === AvatarTypeEnum.Avatar || type === AvatarTypeEnum.Photo
      ? getCompressImageUrl(url, {
          zoom: [ZoomMode.Fill, size, size],
        })
      : null

  return src ? (
    <SeAvatar
      src={src}
      size={size}
      style={{
        ...style,
        cursor: useAvatarZoomIn ? 'zoom-in' : 'auto',
      }}
      onClick={!onClick ? undefined : () => onClick(url)}
    />
  ) : (
    <SeAvatar
      size={size}
      text={$name({
        name: '?',
        ...(targetReflection ?? {}),
      })}
      textFontSize={textFontSize}
      style={style}
      onClick={!onClick ? undefined : () => onClick()}
      {...getTextAvatarColors({
        reflection: targetReflection,
        usePaletteColor,
      })}
    />
  )
}

import { Term, TermInterface } from './extends/term'
import {
  ReflectionProfile,
  ReflectionProfileInterface,
} from './reflection-profile'
import { Pupil, PupilInterface } from './pupil'
import { GuardianStatusEnum } from './guardian-status-enum'
import { RoleEnum } from './role-enum'
import { GenderEnum } from './gender-enum'
import { StatusEnum } from './status-enum'

export interface SecuredGuardianInterface {
  /**
   * 家长类型 expanded guardian only
   */
  guardianRole?: TermInterface
  /**
   * 家长类型 expanded reflection profile
   */
  reflectionProfile?: ReflectionProfileInterface | null
  /**
   * 届别 term ID ，student only
   */
  graduatesInId?: number | null
  /**
   * 届别 expanded, student only
   */
  graduatesIn?: TermInterface | null
  /**
   * 届别对应的年级 expanded student only
   */
  grade?: TermInterface | null
  /**
   * 宿舍 student only
   */
  dorm?: string | null
  /**
   * 宿舍管理员 student only
   */
  dormAdmins?: string[] | null
  /**
   * 床位 student only
   */
  berth?: string | null
  /**
   * 行政班班主任 student only
   */
  classTeachers?: string[] | null
  /**
   * 行政班列表 student only
   */
  adminClasses?: string[] | null
  /**
   * 学科 term ID teacher only
   */
  disciplineId?: number | null
  /**
   * 学科 teacher only
   */
  discipline?: TermInterface | null
  /**
   * 是否为默认身份
   */
  isDefault?: number | null
  guardianRoleId?: number | null
  pupilId?: number | null
  /**
   * 孩子信息 expanded guardian only
   */
  pupil?: PupilInterface | null
  enteredOn?: string | null
  workStartOn?: string | null
  enteredYears?: number | null
  workYears?: number | null
  birthday?: string | null
  graduationTime?: string | null
  guardianStatus?: GuardianStatusEnum | null
  /**
   * 是否需要修改密码
   */
  passwordChangeNeeded?: boolean | null
  /**
   * 年龄
   */
  age?: number | null
  /**
   * 是否是激活状态 - 学籍管理家长使用
   */
  activeStatus?: boolean | null
  /**
   * 导师 student only
   */
  mentors?: string[] | null
  schoolId: number
  name: string
  role: RoleEnum
  pinyin: string
  gender?: GenderEnum | null
  userId?: number | null
  account?: string
  usin?: string | null
  ename?: string | null
  email?: string | null
  phone?: string | null
  idcard?: string | null
  photo?: string | null
  /**
   * 头像
   */
  avatar?: string | null
  status?: StatusEnum
  disabled?: boolean
  archivedTypeId?: number | null
  archivedType?: TermInterface | null
  /**
   * 账户修改时间
   */
  accountUpdatedAt?: string | null
  /**
   * 外部人员的关联ID
   */
  outerId?: string | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class SecuredGuardian implements SecuredGuardianInterface {
  /**
   * 家长类型 expanded guardian only
   */
  guardianRole?: Term
  /**
   * 家长类型 expanded reflection profile
   */
  reflectionProfile?: ReflectionProfile | null
  /**
   * 届别 term ID ，student only
   */
  graduatesInId?: number | null
  /**
   * 届别 expanded, student only
   */
  graduatesIn?: Term | null
  /**
   * 届别对应的年级 expanded student only
   */
  grade?: Term | null
  /**
   * 宿舍 student only
   */
  dorm?: string | null
  /**
   * 宿舍管理员 student only
   */
  dormAdmins?: string[] | null
  /**
   * 床位 student only
   */
  berth?: string | null
  /**
   * 行政班班主任 student only
   */
  classTeachers?: string[] | null
  /**
   * 行政班列表 student only
   */
  adminClasses?: string[] | null
  /**
   * 学科 term ID teacher only
   */
  disciplineId?: number | null
  /**
   * 学科 teacher only
   */
  discipline?: Term | null
  /**
   * 是否为默认身份
   */
  isDefault?: number | null
  guardianRoleId?: number | null
  pupilId?: number | null
  /**
   * 孩子信息 expanded guardian only
   */
  pupil?: Pupil | null
  enteredOn?: string | null
  workStartOn?: string | null
  enteredYears?: number | null
  workYears?: number | null
  birthday?: string | null
  graduationTime?: string | null
  guardianStatus?: GuardianStatusEnum | null
  /**
   * 是否需要修改密码
   */
  passwordChangeNeeded?: boolean | null
  /**
   * 年龄
   */
  age?: number | null
  /**
   * 是否是激活状态 - 学籍管理家长使用
   */
  activeStatus?: boolean | null
  /**
   * 导师 student only
   */
  mentors?: string[] | null
  schoolId: number
  name: string
  role: RoleEnum
  pinyin: string
  gender?: GenderEnum | null
  userId?: number | null
  account?: string
  usin?: string | null
  ename?: string | null
  email?: string | null
  phone?: string | null
  idcard?: string | null
  photo?: string | null
  /**
   * 头像
   */
  avatar?: string | null
  status?: StatusEnum
  disabled?: boolean
  archivedTypeId?: number | null
  archivedType?: Term | null
  /**
   * 账户修改时间
   */
  accountUpdatedAt?: string | null
  /**
   * 外部人员的关联ID
   */
  outerId?: string | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: SecuredGuardianInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      guardianRole: _guardianRole,
      reflectionProfile: _reflectionProfile,
      graduatesInId: _graduatesInId,
      graduatesIn: _graduatesIn,
      grade: _grade,
      dorm: _dorm,
      dormAdmins: _dormAdmins,
      berth: _berth,
      classTeachers: _classTeachers,
      adminClasses: _adminClasses,
      disciplineId: _disciplineId,
      discipline: _discipline,
      isDefault: _isDefault,
      guardianRoleId: _guardianRoleId,
      pupilId: _pupilId,
      pupil: _pupil,
      enteredOn: _enteredOn,
      workStartOn: _workStartOn,
      enteredYears: _enteredYears,
      workYears: _workYears,
      birthday: _birthday,
      graduationTime: _graduationTime,
      guardianStatus: _guardianStatus,
      passwordChangeNeeded: _passwordChangeNeeded,
      age: _age,
      activeStatus: _activeStatus,
      mentors: _mentors,
      schoolId: _schoolId,
      name: _name,
      role: _role,
      pinyin: _pinyin,
      gender: _gender,
      userId: _userId,
      account: _account,
      usin: _usin,
      ename: _ename,
      email: _email,
      phone: _phone,
      idcard: _idcard,
      photo: _photo,
      avatar: _avatar,
      status: _status,
      disabled: _disabled,
      archivedTypeId: _archivedTypeId,
      archivedType: _archivedType,
      accountUpdatedAt: _accountUpdatedAt,
      outerId: _outerId,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.guardianRole = _guardianRole ? new Term(_guardianRole) : _guardianRole
    this.reflectionProfile = _reflectionProfile
      ? new ReflectionProfile(_reflectionProfile)
      : _reflectionProfile
    this.graduatesInId = _graduatesInId
    this.graduatesIn = _graduatesIn ? new Term(_graduatesIn) : _graduatesIn
    this.grade = _grade ? new Term(_grade) : _grade
    this.dorm = _dorm
    this.dormAdmins = _dormAdmins
    this.berth = _berth
    this.classTeachers = _classTeachers
    this.adminClasses = _adminClasses
    this.disciplineId = _disciplineId
    this.discipline = _discipline ? new Term(_discipline) : _discipline
    this.isDefault = _isDefault
    this.guardianRoleId = _guardianRoleId
    this.pupilId = _pupilId
    this.pupil = _pupil ? new Pupil(_pupil) : _pupil
    this.enteredOn = _enteredOn
    this.workStartOn = _workStartOn
    this.enteredYears = _enteredYears
    this.workYears = _workYears
    this.birthday = _birthday
    this.graduationTime = _graduationTime
    this.guardianStatus = _guardianStatus
    this.passwordChangeNeeded = _passwordChangeNeeded
    this.age = _age
    this.activeStatus = _activeStatus
    this.mentors = _mentors
    this.schoolId = _schoolId
    this.name = _name
    this.role = _role
    this.pinyin = _pinyin
    this.gender = _gender
    this.userId = _userId
    this.account = _account
    this.usin = _usin
    this.ename = _ename
    this.email = _email
    this.phone = _phone
    this.idcard = _idcard
    this.photo = _photo
    this.avatar = _avatar
    this.status = _status
    this.disabled = _disabled
    this.archivedTypeId = _archivedTypeId
    this.archivedType = _archivedType ? new Term(_archivedType) : _archivedType
    this.accountUpdatedAt = _accountUpdatedAt
    this.outerId = _outerId
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'guardianRole',
    'reflectionProfile',
    'graduatesInId',
    'graduatesIn',
    'grade',
    'dorm',
    'dormAdmins',
    'berth',
    'classTeachers',
    'adminClasses',
    'disciplineId',
    'discipline',
    'isDefault',
    'guardianRoleId',
    'pupilId',
    'pupil',
    'enteredOn',
    'workStartOn',
    'enteredYears',
    'workYears',
    'birthday',
    'graduationTime',
    'guardianStatus',
    'passwordChangeNeeded',
    'age',
    'activeStatus',
    'mentors',
    'schoolId',
    'name',
    'role',
    'pinyin',
    'gender',
    'userId',
    'account',
    'usin',
    'ename',
    'email',
    'phone',
    'idcard',
    'photo',
    'avatar',
    'status',
    'disabled',
    'archivedTypeId',
    'archivedType',
    'accountUpdatedAt',
    'outerId',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Score, ScoreInterface } from './extends/score'
import type { GetKlassEvaluatedScoresQuery } from './get-klass-evaluated-scores-query'

const buildConfig = createConfigBuilder('vnas')

export const klassScoreApi$getKlassEvaluatedScores = {
  config(
    rid: number,
    id: number,
    getKlassEvaluatedScoresQuery?: GetKlassEvaluatedScoresQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/klass/assessments/{id}/evaluated/{rid}/scores',
      {
        rid,
        id,
      },
      getKlassEvaluatedScoresQuery,
    )
  },

  /**
   * @summary 查看学生自评的所有分数
   * @param rid
   * @param id
   * @param [getKlassEvaluatedScoresQuery]
   * @param [klassScoreQuery.type]
   * @param [klassScoreQuery.expand] - item
   * @param [options]
   * @return AxiosResponsePromise<Score[]>
   */

  api<const TQuery extends GetKlassEvaluatedScoresQuery>(
    rid: number,
    id: number,
    getKlassEvaluatedScoresQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Score, TQuery['expand']>[]> {
    return axios(
      klassScoreApi$getKlassEvaluatedScores.config(
        rid,
        id,
        getKlassEvaluatedScoresQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Score(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends GetKlassEvaluatedScoresQuery,
    TSelected = Expand<Score, TQuery['expand']>[],
  >(
    {
      rid,
      id,
      query,
    }: {
      rid: number
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<Score, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Score, TQuery['expand']>[], TSelected>(
      klassScoreApi$getKlassEvaluatedScores.api,
      'KlassScore.getKlassEvaluatedScores',
      queryOptions,
      rid,
      id,
      query,
    )
  },
}

// @ts-ignore
klassScoreApi$getKlassEvaluatedScores.api._name_ = `KlassScore.getKlassEvaluatedScores`

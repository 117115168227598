/**
 * @file 选择弹窗方法
 */

import { noop } from '@seiue/util'
import React, { lazy, Suspense } from 'react'

import { registerGlobalPicker } from 'packages/components/Modal'

import type { PickerComponentProps, ItemType } from './types'

const SortablePicker = lazy(() =>
  import('./Component').then(m => ({ default: m.SortablePicker })),
)

type OnPickedParameters = Parameters<PickerComponentProps['onSubmit']>
type PickReturnType<T = any> = Array<T>

type Options<T extends ItemType> = Omit<
  PickerComponentProps<T>,
  'visible' | 'onCancel' | 'onSubmit'
>
export type PickerOptions<T extends ItemType> = Options<T> & {
  reset?: boolean
}

type Picker = <T extends ItemType = any>(
  options: PickerOptions<T>,
) => Promise<PickReturnType | false>

const { getPicker, getDestory, setPicker, setDestory } =
  registerGlobalPicker<Picker>('rootSortablePicker')

/**
 * 打开选择弹窗
 *
 * @param controlledOptions - 选择弹窗参数
 * @returns 选择结果
 */
export const openSortablePicker: <T extends ItemType = any>(
  props: PickerOptions<T>,
) => Promise<string[] | false> | undefined = controlledOptions =>
  getPicker()?.(controlledOptions)

/**
 * 关闭选择弹窗
 */
export const destoryRootSortablePicker = () => {
  getDestory?.()
}

type PickerWrapperProps = Omit<PickerComponentProps, 'visible'>
type PickerWrapperRef = {
  hide: () => void
}

const PickerWrapper = React.forwardRef<PickerWrapperRef, PickerWrapperProps>(
  ({ onSubmit, onCancel, ...props }, ref) => {
    const [visible, setVisible] = React.useState(true)

    const innerSubmit = (selectedItemsKeys: string[]) => {
      // 对外暴露结果
      onSubmit(selectedItemsKeys)
      setVisible(false)
    }

    const innerCancel = () => {
      onCancel()
      setVisible(false)
    }

    React.useImperativeHandle(ref, () => ({
      show: () => {
        setVisible(true)
      },
      hide: () => {
        setVisible(false)
      },
    }))

    return (
      <Suspense fallback={null}>
        <SortablePicker
          {...props}
          visible={visible}
          onSubmit={innerSubmit}
          onCancel={innerCancel}
        />
      </Suspense>
    )
  },
)

/**
 * 放在 App 组件根部的组件选择器，以供方法调用
 *
 * @returns 选择结果
 */
export const RootSortablePicker: React.FC = () => {
  const [inited, setInited] = React.useState(false)
  const [options, setOptions] = React.useState<Options<any>>({
    title: '',
    leftTitle: '',
    rightTitle: '',
    allItems: [''],
  })

  const pickerRef = React.useRef<any>()
  const submit = React.useRef<(settings: OnPickedParameters[0]) => void>(noop)

  const cancel = React.useRef<() => void>(noop)

  React.useEffect(() => {
    setPicker(async nextOptions => {
      const { reset, ...newOptions } = nextOptions || {}
      if (!inited) setInited(true)
      else if (reset) {
        setInited(false)

        requestAnimationFrame(() => {
          setInited(true)
        })
      }

      setOptions({
        ...{
          title: '',
          leftTitle: '',
          rightTitle: '',
          allItems: [''],
        },
        ...newOptions,
      })

      return new Promise(resolve => {
        submit.current = pickedItems => {
          resolve(pickedItems)
        }

        cancel.current = () => {
          resolve(false)
          pickerRef.current?.hide()
        }

        pickerRef.current?.show()
      })
    })

    setDestory(async () => {
      pickerRef.current?.hide()

      // remove Modal dom after transition finished
      setTimeout(() => {
        setInited(false)
      }, 320)
    })
  }, [inited, options])

  if (!inited) return null

  return (
    <PickerWrapper
      ref={pickerRef}
      {...options}
      onSubmit={submit.current}
      onCancel={cancel.current}
    />
  )
}

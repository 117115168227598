/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Score, ScoreInterface } from './extends/score'
import type { GetMoralEvaluatedScoresQuery } from './get-moral-evaluated-scores-query'

const buildConfig = createConfigBuilder('vnas')

export const moralApi$getMoralEvaluatedScores = {
  config(
    rid: number,
    id: number,
    getMoralEvaluatedScoresQuery?: GetMoralEvaluatedScoresQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/moral/assessments/{id}/evaluated/{rid}/scores',
      {
        rid,
        id,
      },
      getMoralEvaluatedScoresQuery,
    )
  },

  /**
   * @summary 查看被评价人的所有分数包括行政班
   * @param rid
   * @param id
   * @param [getMoralEvaluatedScoresQuery]
   * @param [moralQuery.type]
   * @param [moralQuery.expand] - attendance_info 包含考勤课节、班级、考勤录入人信息
   * - item
   * - evaluator
   * @param [moralQuery.evaluateTargetAdminClass] 评价对象是不是行政班
   * @param [options]
   * @return AxiosResponsePromise<Score[]>
   */

  api<const TQuery extends GetMoralEvaluatedScoresQuery>(
    rid: number,
    id: number,
    getMoralEvaluatedScoresQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Score, TQuery['expand']>[]> {
    return axios(
      moralApi$getMoralEvaluatedScores.config(
        rid,
        id,
        getMoralEvaluatedScoresQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Score(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends GetMoralEvaluatedScoresQuery,
    TSelected = Expand<Score, TQuery['expand']>[],
  >(
    {
      rid,
      id,
      query,
    }: {
      rid: number
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<Score, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Score, TQuery['expand']>[], TSelected>(
      moralApi$getMoralEvaluatedScores.api,
      'Moral.getMoralEvaluatedScores',
      queryOptions,
      rid,
      id,
      query,
    )
  },
}

// @ts-ignore
moralApi$getMoralEvaluatedScores.api._name_ = `Moral.getMoralEvaluatedScores`

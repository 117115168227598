import { ChatInstanceTypeEnum } from './chat-instance-type-enum'
import {
  ChatInstanceSettings,
  ChatInstanceSettingsInterface,
} from './extends/chat-instance-settings'
import { SchoolPlugin, SchoolPluginInterface } from './school-plugin'

export interface ChatInstanceInterface {
  schoolPluginId: number
  type: ChatInstanceTypeEnum
  settings?: ChatInstanceSettingsInterface
  schoolPlugin?: SchoolPluginInterface
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class ChatInstance implements ChatInstanceInterface {
  schoolPluginId: number
  type: ChatInstanceTypeEnum
  settings?: ChatInstanceSettings
  schoolPlugin?: SchoolPlugin
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: ChatInstanceInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolPluginId: _schoolPluginId,
      type: _type,
      settings: _settings,
      schoolPlugin: _schoolPlugin,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolPluginId = _schoolPluginId
    this.type = _type
    this.settings = _settings ? new ChatInstanceSettings(_settings) : _settings
    this.schoolPlugin = _schoolPlugin
      ? new SchoolPlugin(_schoolPlugin)
      : _schoolPlugin
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'schoolPluginId',
    'type',
    'settings',
    'schoolPlugin',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

import {
  BaseSeriesLabelType,
  BaseSeriesType,
  SeriesContentTypeEnum,
  WidgetChartOption,
  WithoutChartType,
} from 'packages/components/ReportCenter/Reporting'
import { PieChart, WidgetType } from 'packages/sdks-next/nuwa'

export enum PieSeriesLabelPosition {
  Outside = 'outside',
  Inside = 'inside',
}

type PieSeriesLabelType = BaseSeriesLabelType<
  PieSeriesLabelPosition,
  SeriesContentTypeEnum
>

type SeriesType = BaseSeriesType<PieSeriesLabelType>
export type PieChartOption = WidgetChartOption<SeriesType>
export type PieChartExtra = WithoutChartType<
  PieChart,
  WidgetType.PieChart,
  PieChartOption
>

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Assessment, AssessmentInterface } from './extends/assessment'
import type { QueryEvaluatedDormAssessmentsQuery } from './query-evaluated-dorm-assessments-query'

const buildConfig = createConfigBuilder('vnas')

export const dormApi$queryEvaluatedDormAssessments = {
  config(
    queryEvaluatedDormAssessmentsQuery?: QueryEvaluatedDormAssessmentsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/me/evaluated-dorm-assessments',
      {},
      queryEvaluatedDormAssessmentsQuery,
    )
  },

  /**
   * @summary 查询外部评价人的宿舍评价列表
   * @param [queryEvaluatedDormAssessmentsQuery]
   * @param [dormQuery.sort]
   * @param [dormQuery.expand]
   * @param [dormQuery.paginated] 是否分页，默认 1
   * @param [dormQuery.page] 显示的数据页，默认 1
   * @param [dormQuery.perPage] 每页显示数量，默认 20
   * @param [dormQuery.offset]
   * @param [dormQuery.limit]
   * @param [dormQuery.scrolled]
   * @param [dormQuery.idIn]
   * @param [dormQuery.nameLike]
   * @param [dormQuery.nameIn]
   * @param [dormQuery.semesterId]
   * @param [dormQuery.scopeType]
   * @param [dormQuery.scopeIdIn]
   * @param [dormQuery.statusIn]
   * @param [dormQuery.planIdIn]
   * @param [dormQuery.planIdIsEmpty]
   * @param [dormQuery.fullScoreEgt]
   * @param [dormQuery.fullScoreElt]
   * @param [dormQuery.totalItemScoreEgt]
   * @param [dormQuery.totalItemScoreElt]
   * @param [dormQuery.itemNumEgt]
   * @param [dormQuery.itemNumElt]
   * @param [dormQuery.scopeNumEgt]
   * @param [dormQuery.scopeNumElt]
   * @param [dormQuery.scoreLockStatusIn]
   * @param [dormQuery.settingLockStatusIn]
   * @param [dormQuery.individuation]
   * @param [dormQuery.isArchived]
   * @param [dormQuery.evaluators.memberId]
   * @param [dormQuery.evaluators.memberIdIn]
   * @param [dormQuery.evaluators.memberType]
   * @param [dormQuery.evaluators.memberTypeIn]
   * @param [dormQuery.evaluators.assessmentId]
   * @param [dormQuery.evaluators.assessmentIdIn]
   * @param [dormQuery.evaluators.reflection]
   * @param [dormQuery.evaluateTarget] 评价对象，德育评价使用
   * @param [dormQuery.meIsEvaluator] 是否参与评价（适用于家长、老师、学生场景）
   * @param [dormQuery.lockGrade] 是否锁定总成绩修改
   * @param [dormQuery.isTemplate] 是否为模板
   * @param [dormQuery.assessmentStages.status]
   * @param [dormQuery.assessmentStages.statusIn]
   * @param [dormQuery.assessmentStages.stageId]
   * @param [dormQuery.assessmentStages.stageIdIn]
   * @param [dormQuery.computeRuleIsEmpty] 计算规则是否为空
   * @param [options]
   * @return AxiosResponsePromise<Assessment[]>
   */

  api<const TQuery extends QueryEvaluatedDormAssessmentsQuery>(
    queryEvaluatedDormAssessmentsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Assessment, TQuery['expand']>[]> {
    return axios(
      dormApi$queryEvaluatedDormAssessments.config(
        queryEvaluatedDormAssessmentsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Assessment(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryEvaluatedDormAssessmentsQuery,
    TSelected = Expand<Assessment, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<Assessment, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Assessment, TQuery['expand']>[], TSelected>(
      dormApi$queryEvaluatedDormAssessments.api,
      'Dorm.queryEvaluatedDormAssessments',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
dormApi$queryEvaluatedDormAssessments.api._name_ = `Dorm.queryEvaluatedDormAssessments`

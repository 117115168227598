/**
 * @file 基础全屏弹窗
 */

import { noop } from '@seiue/util'
import Portal, { GetContainer } from 'rc-util/lib/PortalWrapper'
import React from 'react'
import styled from 'styled-components'

import { ZIndex, BackgroundColor } from 'packages/themed'

export interface PureFullScreenModalProps {
  visible: boolean
  zIndex?: number
  destroyContentWhenInvisible?: boolean
  getContainer?: GetContainer | false
  onReadyStateChange?: (readyToRenderChildren: boolean) => void
  className?: string
}

/**
 * 基础全屏弹窗
 *
 * @param {PureFullScreenModalProps} props - 参数
 * @returns component
 */
export const PureFullScreenModal: React.FC<
  PureFullScreenModalProps
> = props => {
  const {
    visible,
    zIndex = ZIndex.FullScreen,
    getContainer = false,
    destroyContentWhenInvisible,
    onReadyStateChange = noop,
    children,
    className,
  } = props

  React.useEffect(() => {
    // 防止出现双滚动条
    const origin = document.body.style.overflow
    if (visible) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = origin
    }
  }, [visible])

  const readyStateChange = React.useRef(onReadyStateChange)
  React.useEffect(() => {
    readyStateChange.current = onReadyStateChange
  }, [onReadyStateChange])

  const [slideUp, setSlideUp] = React.useState(false)
  const [innerVisible, setInnerVisible] = React.useState(false)
  const isInited = React.useRef(false)
  const setInited = React.useCallback((state: boolean) => {
    if (isInited.current !== state) {
      readyStateChange.current(state)
    }

    isInited.current = state
  }, [])

  const asyncCall = React.useRef({
    timer: 0,
    animationFrame: 0,
  })

  const clearAsyncCall = React.useCallback(() => {
    const { timer, animationFrame } = asyncCall.current
    if (timer) {
      clearTimeout(timer)
      asyncCall.current.timer = 0
    }

    if (animationFrame) {
      cancelAnimationFrame(animationFrame)
      asyncCall.current.animationFrame = 0
    }
  }, [])

  React.useEffect(() => {
    if (visible) {
      setInnerVisible(_innerVisible => {
        if (!_innerVisible) {
          clearAsyncCall()
          asyncCall.current.animationFrame = requestAnimationFrame(() => {
            setSlideUp(true)

            if (!isInited.current) {
              asyncCall.current.timer = window.setTimeout(() => {
                /*
                 * 等到上拉动画执行结束后，再执行 children 的渲染，防止动画与 children 同时渲染导致的卡顿和布局失效
                 * 仅当第一次打开弹窗时执行
                 */
                setInited(true)
                asyncCall.current.timer = 0
              }, 500)
            }

            asyncCall.current.animationFrame = 0
          })
        }

        return true
      })
    } else {
      clearAsyncCall()
      setSlideUp(false)
      if (destroyContentWhenInvisible) {
        setInited(false)
      }
    }
  }, [visible, destroyContentWhenInvisible, clearAsyncCall, setInited])

  if (getContainer === false) {
    return (
      <Wrapper
        data-test-id={innerVisible ? '弹窗' : undefined}
        visible={innerVisible}
        slideUp={slideUp}
        style={{ zIndex }}
        className={className}
        onTransitionEnd={() => {
          if (!slideUp) {
            setInnerVisible(false)
          }
        }}
      >
        <Container
          onTransitionEnd={e => {
            // 阻止子元素的 Transition 冒泡，防止其触发 Wrapper 的 onTransitionEnd
            e.stopPropagation()
          }}
        >
          {children}
        </Container>
      </Wrapper>
    )
  }

  return (
    <Portal visible={visible} getContainer={getContainer}>
      {() => (
        <Wrapper
          data-test-id={innerVisible ? '弹窗' : undefined}
          visible={innerVisible}
          slideUp={slideUp}
          style={{ zIndex }}
          className={className}
          onTransitionEnd={() => {
            if (!slideUp) {
              setInnerVisible(false)
            }
          }}
        >
          <Container
            onTransitionEnd={e => {
              // 阻止子元素的 Transition 冒泡，防止其触发 Wrapper 的 onTransitionEnd
              e.stopPropagation()
            }}
          >
            {children}
          </Container>
        </Wrapper>
      )}
    </Portal>
  )
}

const Wrapper = styled.div<{ visible: boolean; slideUp?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  background-color: ${BackgroundColor.White};
  transform: ${({ slideUp }) =>
    slideUp ? 'translate3d(0, 0, 0);' : 'translate3d(0, 100%, 0)'};
  transition: transform 0.3s ease-out;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${BackgroundColor.Base};
`

import {
  CertReviewStyleEnum,
  SemesterReviewLanguageEnum,
  SemesterReviewStyleEnum,
} from 'packages/sdks-next/ais'
import { NotificationOptions } from 'packages/sdks-next/scms'
import { ExportSummarizeScoreReqInterface } from 'packages/sdks-next/vnas'

/**
 * 个人表头设置的 key
 */
export enum PersonalTableConfigKey {
  // 行政班管理列表 (正常)
  ChalkAdminClassesNormal = 'chalk_admin_classes_normal_table_config',
  // 行政班管理 - 学生换班列表
  ChalkAdminClassesSwitch = 'chalk_admin_classes_switch_table_config',
  // 考勤记录列表
  ChalkAttendanceClassesRecord = 'chalk_attendance_classes_record_config',
  // 反馈管理列表
  ChalkFeedbacks = 'chalk_feedbacks_table_config',
  // 成绩管理列表
  ChalkAdminAssessments = 'chalk_assessment_table_config',
  // 宿舍管理 - 信息修改日志
  ChalkDormLogsTableConfig = 'chalk_dorm_logs_table_config',
  // 宿舍管理 - 后台宿生列表
  ChalkAdminDormMembersTableConfig = 'chalk_admin_dorm_members_table_config',
  // 宿舍管理 - 统计分析-宿生统计列表
  ChalkAdminDormMemberStatisticsTableConfig = 'chalk_admin_dorm_member_statistics_table_config',
  // 宿舍管理 - 前台宿生列表
  ChalkDormMembersTableConfig = 'chalk_dorm_members_table_config',
  // 课程班管理 - 自定义成员类型新课程班列表
  ChalkClassesCopiedClassTableConfig = 'chalk_classes_copied_class_table_config',
  // 课程班管理 - 自定义成员类型使用统计列表
  ChalkClassesMemberTypeUsedCount = 'chalk_classes_member_type_used_count',
  // 考试管理 - 分数录入
  ChalkExamEntryTableConfig = 'chalk_exam_entry_table_config',
  // 考试管理 - 考试分析 - 成绩总表
  ChalkExamTotalScoreAnalysisTableConfig = 'chalk_exam_total_score_analysi_table_config',
  // 空间管理 - 信息修改日志
  ChalkPlaceActivityConfig = 'chalk_place_activity_config',
  // 课节管理列表
  ChalkAdminKlassLessonTableConfig = 'chalk_admin_klass_lesson_table_config',
  // 教师课程
  ChalkAdminClassesClassTeachersConfig = 'chalk_admin_classes_class_teachers_config',
  // /admin/classes/userclasses/list 学生加退课
  AdminClassesUserclassesList = 'admin_classes_userclasses_list',
  // 成绩管理-学生成绩列表
  ChalkGradesNormalStudentGrades = 'chalk_normal_students_assessment_table_config',
  ChalkGradesArchivedStudentGrades = 'chalk_archived_students_assessment_table_config',
  // 请假管理-请假汇总列表
  ChalkAdminAbsenceSummaryList = 'chalk_admin_absence_summary_list_table_config',
  // 请假管理-请假统计表
  ChalkAdminAbsenceStatList = 'chalk_admin_absence_stat_list_table_config',
  // 选科管理选科结果
  ScmsSubjectSelectionAdminResultsTableConfig = 'scms_subject_selection_admin_results_table_config',
  // 选科管理填报详情
  ScmsSubjectSelectionQuestionnaireDetailTableConfig = 'scms_subject_selection_questionnaire_detail_table_config',
  // 场地预约管理 - 场地管理
  ChalkVenuesTableConfig = 'chalk_venues_table_config',
  // 场地预约管理 - 预约记录
  ChalkVenueReservationsTableConfig = 'chalk_venue_reservations_table_config',
  // 学生认证 - 某一认证所有已被认证学生的列表
  ChalkCertifiedStudentsListTableConfig = 'chalk_certified_students_list_table_config',
  // 课程申报 - 统计数据
  ChalkAdminClassDeclarationResultConfig = 'chalk_admin_class_declaration_result_config',
  // 通用群组插件群组管理
  PluginCustomizedGroupNormalGroups = 'plugin_customized_group_groups',
  // 通用群组插件已归档群组
  PluginCustomizedGroupArchivedGroups = 'plugin_customized_group_archived_groups',
  // 通用群组插件操作日志
  PluginCustomizedGroupOperationLogs = 'plugin_customized_group_operation_logs',
  // 通用群组插件成员
  PluginCustomizedGroupDetailMembers = 'plugin_customized_group_detail_members',
  // 档案群组已加入的群组列表
  ChalkProfileGroupCustomGroups = 'chalk_profile_group_customized_groups',
  // 活动插件-活动列表-未结束
  PluginEventsListActive = 'plugin_events_list_active',
  // 活动插件-申报列表
  PluginEventsApplicationList = 'plugin_events_application_list',
  // 网盘插件-网盘管理/学生网盘（正常）
  PluginNetDiskAdminStudentNormal = 'plugin_net_disk_admin_student_normal',
  // 网盘插件-网盘管理/文件列表
  PluginNetDiskAdminFileList = 'plugin_net_disk_admin_file_list',
  // 无需审核评价项列表
  ChalkReviewUnneededItemTableConfig = 'chalk_review_unneeded_item_table_config',
  // 毕业生信息列表
  PluginAcademicGoalGraduatesInfoConfig = 'plugin_academic_goal_graduates_info_config',
  // 心理档案-上报列表
  PsychologicalFileReportConfig = 'plugin_psychological_file_report_config',
  // 心理档案-档案信息列表
  PsychologicalProfileConfig = 'plugin_psychological_file_profile_config',
  // 心理档案-评估干预列表
  PsychologicalEvaluationInterventionConfig = 'plugin_psychological_file_evaluation_intervention_config',
  // 约谈列表
  ChalkPluginChatAdminChats = 'chalk_plugin_chat_admin_chats',
  // 短信任务列表
  ChalkPluginAdminSmsTasks = 'chalk_plugin_admin_sms_tasks',
  // 约谈统计
  ChalkPluginChatAdminStatistics = 'chalk_plugin_chat_admin_chat_statistics',
  // 教师约谈统计
  ChalkPluginChatAdminTeacherStatistics = 'chalk_plugin_chat_admin_chat_teacher_statistics',
  // 宿舍考勤-学生考勤统计
  ChalkDormUserAttendanceStudentList = 'chalk_dorm_user_attendance_student_list',
  //  人事管理-正常教师列表
  PluginPersonnelProfileTeacherNormalList = 'plugin_personnel_profile_teacher_normal',
  //  人事管理-已归档教师列表
  PluginPersonnelProfileTeacherArchivedList = 'plugin_personnel_profile_teacher_archived',
  //  学籍管理-学生列表
  PluginStudentStatusProfileStudentList = 'plugin_student_status_profile_student_list',
  // 成绩管理-学生成绩列表
  ChalkGradesStudentGrades = 'chalk_students_assessment_table_config',
  // 通用群组我参与的
  PluginCustomizedGroupParticipatedGroups = 'plugin_customized_group_participated_groups',
  // 通用群组我管理的
  PluginCustomizedGroupManagementGroups = 'plugin_customized_group_management_groups',
  // 通用群组任务统计
  PluginCustomizedGroupGroupTasksStat = 'plugin_customized_group_group_tasks_stat',
  // 通用群组成员任务统计
  PluginCustomizedGroupMemberTasksStat = 'plugin_customized_group_member_tasks_stat',
  // 通用群组发布任务统计
  PluginCustomizedGroupPostTasksStat = 'plugin_customized_group_post_tasks_stat',
  // 通用群组群组任务列表
  PluginCustomizedGroupGroupTaskList = 'plugin_customized_group_group_task_list',
  // 通用群组成员任务列表
  PluginCustomizedGroupMemberTaskList = 'plugin_customized_group_member_task_list',
  // ClassIn 线上课修改日志
  PluginClassInOnlineClassesOperationLogs = 'plugin_class_in_on_line_classes_operation_logs',
  // ClassIn 线上课管理标准课列表
  PluginClassInOnlineClasses = 'plugin_class_in_on_line_classes',
  // ClassIn 线上课管理 - 从课程同步表格
  PluginClassInSyncOnlineLessons = 'plugin_class_in_sync_online_lesson',
  // ClassIn 线上课管理 - 课节列表
  PluginClassInOnlineLessons = 'plugin_class_in_on_line_lessons',
  // ClassIn 线上课管理 - 课节管理 - 同步课节
  PluginClassInOnlineLessonSyncOnlineLessons = 'plugin_class_in_online_lesson_sync_online_lessons',
  //  Classin 跨校共享课-共享课列表
  PluginClassInSharedClasses = 'plugin_class_in_shared_classes',
  //  Classin 跨校共享课-我分享的课程
  PluginClassInMySharedClasses = 'plugin_class_in_my_shared_classes',
  //  人事管理-人事操作日志
  PluginPersonnelProfileTeacherLogsTableConfig = 'plugin_personnel_profile_teacher_logs_table_config',
  // 学籍管理-信息修改日志
  PluginStudentStatusProfileLogsTableConfig = 'plugin_student_status_profile_logs_table_config',
  // 广中教师赋分详情
  NuwaGzAcademicCreditTaskDetailListTab = 'nuwa_gz_academic_credit_task_detail_list_tab',
  // 宿舍管理 - 信息修改日志
  ChalkDormsLogsTableConfig = 'chalk_droms_logs_table_config',
  // 行政班 - 走班课后台管理列表
  ChalkAdminClassRelatedBackendGoClassesConfig = 'chalk_admin_class_related_backend_go_classes_table_config',
  // 契约契约管理 - 契约列表
  PluginContractContractListTableConfig = 'plugin_contract_contract_list_table_config',
  /**
   * 选择学生列表
   */
  ChalkStudentPickerTableConfig = 'chalk_student_picker_table_config',
  /**
   * 选择宿舍学生列表
   */
  ChalkDormStudentPickerTableConfig = 'chalk_dorm_student_picker_table_config',
  /**
   * 选择教师列表
   */
  ChalkTeacherPickerTableConfig = 'chalk_teacher_picker_table_config',
  /**
   * 选择家长列表
   */
  ChalkGuardianPickerTableConfig = 'chalk_guardian_picker_table_config',
  /**
   * 选择校外成员列表
   */
  ChalkStaffPickerTableConfig = 'chalk_staff_picker_table_config',
  /**
   * 导师约谈管理
   */
  ChalkMentorsTalksTableConfig = 'chalk_mentors_talks_table_config',
  /**
   * 导师任务管理
   */
  ChalkMentorsTasksTableConfig = 'chalk_mentors_tasks_table_config',
  /**
   * 导师人员管理
   */
  ChalkMentorsTableConfig = 'chalk_mentors_table_config',
  /**
   * 空间列表
   */
  ChalkPlacesTableConfig = 'chalk_places_table_config',
  /**
   * 宿舍列表
   */
  ChalkDormsTableConfig = 'chalk_dorms_table_config',
  /**
   * 宿舍评价列表
   */
  ChalkDormAssessmentsNormalTableConfig = 'chalk_dorm_assessments_normal_table_config',
  /**
   * 归档宿舍评价列表
   */
  ChalkDormAssessmentsArchivedTableConfig = 'chalk_dorm_assessments_archived_table_config',
  /**
   * 宿管宿舍评价列表
   */
  ChalkDormAssessmentsManagerTableConfig = 'chalk_dorm_assessments_manager_table_config',
  /**
   * 教师账号管理
   */
  ChalkUsersTeachersNormalTableConfig = 'chalk_users_teachers_normal_table_config',
  /**
   * 归档教师账号列表
   */
  ChalkUsersTeachersDisabledTableConfig = 'chalk_users_teachers_disabled_table_config',
  /**
   * 家长账号管理
   */
  ChalkUsersGuardiansNormalTableConfig = 'chalk_users_guardians_normal_table_config',
  /**
   * 归档家长账号列表
   */
  ChalkUsersGuardiansDisabledTableConfig = 'chalk_users_guardians_disabled_table_config',
  /**
   * 学生账号管理
   */
  ChalkUsersStudentsNormalTableConfig = 'chalk_users_students_normal_table_config',
  /**
   * 归档学生账号列表
   */
  ChalkUsersStudentsDisabledTableConfig = 'chalk_users_students_disabled_table_config',
  /**
   * 学生账号管理操作日志
   */
  ChalkUsersStudentsLogsTableConfig = 'chalk_users_students_logs_table_config',
  /**
   * 教师账号管理操作日志
   */
  ChalkUsersTeachersLogsTableConfig = 'chalk_users_teachers_logs_table_config',
  /**
   * 家长账号管理操作日志
   */
  ChalkUsersGuardiansLogsTableConfig = 'chalk_users_guardians_logs_table_config',
  /**
   * 校外人员账号管理
   */
  ChalkUsersStaffsNormalTableConfig = 'chalk_users_staffs_normal_table_config',
  /**
   * 归档校外人员账号管理
   */
  ChalkUsersStaffsDisabledTableConfig = 'chalk_users_staffs_disabled_table_config',
  /**
   * 归档校外人员账号管理操作日志
   */
  ChalkUsersStaffsLogsTableConfig = 'chalk_users_staffs_logs_table_config',
  /**
   * 已选课程列表
   */
  ChalkCoursesNormalTableConfig = 'chalk_courses_normal_table_config',
  /**
   * 选择课程列表
   */
  ChalkCoursesPickerTableConfig = 'chalk_courses_normal_table_config',
  /**
   * 行政班列表
   */
  ChalkAdminClassesNormalTableConfig = 'chalk_admin_classes_normal_table_config',
  /**
   * 行政班管理列表 (已归档)
   */
  ChalkAdminClassesArchived = 'chalk_admin_classes_archived_table_config',
  /**
   * 行政班选择器列表
   */
  ChalkAdminClassesPickerTableConfig = 'chalk_admin_classes_picker_table_config',
  /**
   * 课程禁用列表
   */
  ChalkCoursesDisabledTableConfig = 'chalk_courses_disabled_table_config',
  /**
   * 课程库操作日志列表
   */
  ChalkCoursesLogsTableConfig = 'chalk_courses_logs_table_config',
  /**
   * 课程班正常列表
   */
  ChalkClassesNormalTableConfig = 'chalk_classes_normal_table_config',
  /**
   * 课程班管理 - 学生课程
   */
  ChalkAdminClassesUserClassesConfig = 'chalk_admin_classes_user_classes_config',
  /**
   * 课程班选择器列表
   */
  ChalkClassesPickerTableConfig = 'chalk_classes_picker_table_config',
  /**
   * 课程班操作日志
   */
  ChalkClassesLogsTableConfig = 'chalk_classes_logs_table_config',
  /**
   * 课程任务统计
   */
  ChalkClassTaskKlassStatistics = 'chalk_class_task_klass_statistics',
  ChalkClassTaskStudentStatistics = 'chalk_class_task_student_statistics',
  ChalkClassTaskTeacherStatistics = 'chalk_class_task_teacher_statistics',
  ChalkClassTaskKlassTasks = 'chalk_class_task_klass_tasks',
  ChalkClassTaskTeacherTasks = 'chalk_class_task_teacher_tasks',
  /**
   * 课程班考勤统计
   */
  ChalkAttendanceClassListStatsConfig = 'chalk_attendance_class_list_stats_config',
  /**
   * 宿舍考勤统计
   */
  ChalkAttendanceDormListStatsConfig = 'chalk_attendance_dorm_list_stats_config',
  /**
   * 学生考勤统计
   */
  ChalkAttendanceStudentListStatsConfig = 'chalk_attendance_student_list_stats_config',
  /**
   * 课程班的学生考勤统计
   */
  ChalkAttendanceClassStudentListStatsConfig = 'chalk_attendance_class_student_list_stats_config',
  /**
   * 宿舍的学生考勤统计
   */
  ChalkAttendanceDormStudentListStatsConfig = 'chalk_attendance_dorm_student_list_stats_config',
  /**
   * 考勤管理考勤记录
   */
  ChalkAttendanceClassesRecordConfig = 'chalk_attendance_classes_record_config',
  /**
   * 总成绩列表
   */
  ChalkAssessmentTableConfig = 'chalk_assessment_table_config',
  /**
   * 待审核评价项列表
   */
  ChalkReviewNeededItemTableConfig = 'chalk_review_needed_item_table_config',
  /**
   * 成绩维度编辑列表
   */
  ChalkAssessmentDimensionTableConfig = 'chalk_assessment_dimension_table_config',
  /**
   * 成绩子维度编辑列表
   */
  ChalkAssessmentSubDimensionTableConfig = 'chalk_assessment_sub_dimension_table_config',
  /**
   * 成绩评价项编辑列表
   */
  ChalkAssessmentItemTableConfig = 'chalk_assessment_item_table_config',
  /**
   * 档案管理目标管理编辑列表
   */
  ChalkProfileGoalItemTableConfig = 'chalk_profile_goal_management_table_config',
  /**
   * 档案管群组课程班
   */
  ChalkProfileStudentGroupClassTableConfig = 'chalk_profile_student_group_class_table_config',
  /**
   * 档案管群组行政班
   */
  ChalkProfileStudentGroupAdminClassTableConfig = 'chalk_profile_student_group_admin_class_table_config',
  /**
   * 档案管群组宿舍
   */
  ChalkProfileStudentGroupDormTableConfig = 'chalk_profile_student_group_dorm_table_config',
  /**
   * 反馈管理列表
   */
  ChalkFeedbacksTableConfig = 'chalk_feedbacks_table_config',
  /**
   * 通知管理表格
   */
  ChalkManagerNoticeListTableConfig = 'chalk_manager_notice_list_table_config',
  /**
   * 学生成绩列表
   */
  ChalkStudentsAssessmentTableConfig = 'chalk_students_assessment_table_config',
  /**
   * 请假后台数据 - 请假人列表
   */
  ChalkAbsencesAbsencedTableConfig = 'chalk_absences_absenced_table_config',
  /**
   * 深圳中学成绩表格 - 成绩单列表
   */
  SzGradeReport = 'sz_grade_report',
  /**
   * 深圳湾成绩报告 - 成绩单列表
   */
  CgrGradeReport = 'cgr_grade_report',
  /**
   * 考试管理 - 批量关联评价项
   */
  ChalkExamsBatchRelateItemsConfig = 'chalk_exams_batch_relate_items_config',
  /**
   * 考试管理 - 信息修改日志
   */
  ChalkExamsLogsTableConfig = 'chalk_exams_logs_table_config',
  /**
   * 考试管理 - 选择课程班
   */
  ChalkExamsClassesTableConfig = 'chalk_exams_classes_table_config',
  /**
   * 评教详情 - 评教详情列表
   */
  ChalkEvaluationDetailsTableConfig = 'chalk_evaluation_details_table_config',
  /**
   * 评教评学 - 选择课程班列表
   */
  ChalkEvaluationsClassesTableConfig = 'chalk_evaluations_classes_table_config',
  /**
   * 评教评学 - 选择行政班列表
   */
  ChalkEvaluationsAdminClassesTableConfig = 'chalk_evaluations_admin_classes_table_config',
  /**
   * 行政班前台 - 课程班列表
   */
  ChalkAdminClassRelatedFrontendClassesConfig = 'chalk_admin_class_related_frontend_classes_table_config',
  /**
   * 行政班后台 - 课程班列表
   */
  ChalkAdminClassRelatedBackendClassesConfig = 'chalk_admin_class_related_backend_classes_table_config',
  /**
   * 课程申报 - 课程发布
   */
  ChalkAdminClassDeclarationPrePublishClassesConfig = 'chalk_admin_class_declaration_pre_publish_classes_config',
  /**
   * 行政班管理列表 (正常)
   */
  ChalkAdminClassNormalTableConfig = 'chalk_admin_class_normal_table_config',
  /**
   * ClassIn - 待分配课程列表
   */
  ChalkClassInUncompactedClassTableConfig = 'chalk_class_in_uncompacted_class_table_config',
  /**
   * 认证管理 - 认证列表
   */
  ChalkCertificationListTableConfig = 'chalk_certification_list_table_config',
  /**
   * 认证管理 - 单个认证（激励）已获得的学生列表
   */
  ChalkCertificationIncentiveCertifiedListTableConfig = 'chalk_certification_incentive_certified_list_table_config',
  /**
   * 认证管理 - 单个认证（处分）已获得的学生列表
   */
  ChalkCertificationPunishmentCertifiedListTableConfig = 'chalk_certification_punishment_certified_list_table_config',
  /**
   * 认证管理 - 单个学生已获得认证列表
   */
  ChalkCertificationStudentCertifiedListTableConfig = 'chalk_certification_student_certified_list_table_config',
  /**
   * 认证管理 - 学生统计
   */
  ChalkCertificationStudentStatsTableConfig = 'chalk_certification_student_stats_table_config',
  /**
   * 认证管理 - 申请记录
   */
  ChalkCertificationApplicationLogsTableConfig = 'chalk_certification_application_logs_table_config',
  /**
   * 认证积分管理 - 学生汇总
   */
  ChalkCertCreditStudentListTableConfig = 'chalk_cert_credit_student_list_table_config',
  /**
   * 认证积分管理 - 单个奖品兑换名单
   */
  ChalkCertCreditPrizeStudentListTableConfig = 'chalk_cert_credit_prize_student_list_table_config',
  /**
   * 学分管理 - 认定学分
   */
  ChalkCreditAwardsTableConfig = 'chalk_credit_awards_table_config',
  /**
   * 出入校管理 - 物联网人员管理
   */
  ChalkEemsIotUserListTableConfig = 'chalk_eems-iot_user_list_table_config',
  /**
   * 约谈管理 - 教师可预约时段
   */
  ChalkChatTeacherSectionsTableConfig = 'chalk_chat_teacher_sections_table_config',
  /**
   * 听评课管理列表
   */
  ChalkReviewLessonsTableConfig = 'chalk_review_lessons_table_config',
  /**
   * 听评课管理 - 安排听评课课节列表
   */
  ChalkReviewFormLessonsTableConfig = 'chalk_review_form_lessons_table_config',
  /**
   * 听评课管理 - 评课详情列表
   */
  ChalkReviewQuestionnaireDetailsTableConfig = 'chalk_review_questionnaire_details_table_config',
  /**
   * 听评课管理- 在校教师数据统计列表
   */
  ChalkReviewTeacherStatisticsTableConfig = 'chalk_review_teacher_statistics_table_config',
  /**
   * 听评课管理- 归档教师数据统计列表
   */
  ChalkReviewArchiveTeacherStatisticsTableConfig = 'chalk_review_archive_teacher_statistics_table_config',
  /**
   * 听评课管理 - 被评记录
   */
  ChalkReviewQuestionnaireReviewRecordsTableConfig = 'chalk_review_questionnaire_review_records_table_config',
  /**
   * 听评课管理 - 听课记录
   */
  ChalkReviewQuestionnaireListeningRecordsTableConfig = 'chalk_review_questionnaire_listening_records_table_config',
  /**
   * 学期评语 - 详情列表
   */
  ChalkSemesterReviewDetailTableConfig = 'chalk_semester_review_detail_table_config',
  /**
   * 教师考勤 - 时段统计
   */
  ChalkAdminTeacherAttendancePeriodTableConfig = 'chalk_admin_teacher_attendance_period_table_config',
  /**
   * 教师考勤 - 时段教师统计
   */
  ChalkAdminTeacherAttendancePeriodTeacherTableConfig = 'chalk_admin_teacher_attendance_period_teacher_table_config',
  /**
   * 教师考勤 - 教师统计
   */
  ChalkAdminTeacherAttendanceTeachersTableConfig = 'chalk_admin_teacher_attendance_teachers_table_config',
  /**
   * 教师考勤 - 教师考勤统计
   */
  ChalkAdminTeacherAttendanceTeacherTableConfig = 'chalk_admin_teacher_attendance_teacher_table_config',
  /**
   * 课程班管理 - 导出 - 课节信息
   */
  ChalkClassesNormalLessonExportConfig = 'chalk_classes_normal_lesson_export_config',
  /**
   * 课程班管理/导出/学生上课详情,记忆的导出设置项
   */
  ChalkClassesNormalExportStudentClassConfig = 'chalk_classes_normal_export_sutdent_class_config',
  /**
   * 课程班管理/导出/课程班课表
   */
  ChalkClassesCurriculumExportConfig = 'chalk_classes_curriculum_export_config',
  /**
   * 实验二小问卷分析导出自定义配置
   */
  Sy2xQuestionnaireExportConfig = 'sy2x_questionnaire_export_config',
}

/**
 * 个人设置中除表头设置外的其他 key
 */
export enum PersonalConfigKey {
  /**
   * 当前 Chalk 所使用的语言
   */
  ChalkLocale = 'chalk_locale',
  /**
   * 桌面端 - 应用中心常用应用
   */
  CommonUseApplicationsConfig = 'common_use_applications_config',
  /**
   * 移动端 - 应用中心常用应用
   */
  MobileCommonUseApplicationsConfig = 'mobile_common_use_applications_config',
  /**
   * 首页快捷入口
   */
  HomeShortcutEntryConfig = 'chalk_reflections_shortcut_entry_config',
  /**
   * 首页最近访问
   */
  HomeRecentEntryConfig = 'chalk_recent_entry_config',
  /**
   * 评价录入/汇总 - 排序规则
   */
  ChalkAssessmentSortBy = 'chalk_assessment_sort_by',
  /**
   * im 即时聊天 - 一对一/一对多免打扰设置
   */
  ImDisturbConfig = 'im_disturb_config',
  /**
   * 顶部下拉菜单-最近访问菜单
   */
  ChalkRecentVisitMenusConfig = 'chalk_recent_visit_menus_config',
  /**
   * 引导弹窗历史配置
   */
  ChalkGuideHistoryConfig = 'chalk_guide_history_config',
  /**
   * 课表查询展示配置
   */
  SzzxScheduleVisibleConfig = 'szzx_schedule_visible_config',
  /**
   * 考试管理 - 考试分析 - 历次考试对比
   */
  ChalkExamAnalysisComparedExamsConfig = 'chalk_exam_analysis_compared_exams_config',
  /**
   * 考试管理 - 考试分析 - 需关注的学生进步和退步的百分比
   */
  ChalkExamAnalysisStudentTrendsRateConfig = 'chalk_exam_analysis_student_trends_rate_config',
  /**
   * 考试管理 - 批量关联评价项
   */
  ChalkExamsBatchRelateItemsConfig = 'chalk_exams_batch_relate_items_config',
  /**
   * 通知管理 - 已经加载过的重要通知（但还未读）
   */
  ChalkLoadedImportantMessages = 'chalk_loaded_important_messages',
  /**
   * chalk ai 认证自定义评语风格
   */
  ChalkAICertReviewCustomWritingStyle = 'chalk_ai_cert_review_custom_writing_style',
  /**
   * chalk ai 学期评语教师印象配置
   */
  ChalkAISemesterReviewConfigs = 'chalk_ai_semester_review_configs',
  /**
   * 大屏 - 小红花
   */
  ChalkboardCertConfigs = 'chalkboard_cert_configs',
  /**
   * chalk ai 选择学生批量生成评语配置
   */
  ChalkAIBatchReviewConfigs = 'chalk_ai_batch_review_configs',
  /**
   * 我的签名
   */
  ChalkMySign = 'my_sign',
  /**
   * 成绩管理 - 导出学生成绩详情的配置
   */
  ChalkGradeExportStudentGradesConfig = 'chalk_grade_export_student_grades_config',

  /**
   * 课程班管理 - 教师课时统计 - 表头右侧的时间筛选（根据学期记忆）
   */
  ChalkTeacherClassHoursTimeRangeFilter = 'chalk_teacher_class_hours_time_range_filter',

  /**
   * 课程班管理 - 教师课时统计 - 按课程统计导出配置
   */
  ChalkTeacherClassHoursExportByClassConfig = 'chalk_teacher_class_hours_export_by_class_config',

  /**
   * 行政班管理 - 导出 - 班级课表的导出配置
   */
  ChalkAdminAdminClassScheduleExport = 'chalk_admin_admin_class_schedule_export',
  /**
   * 调代课 - 课节变动发送消息选项
   */
  ChalkClassAdjustmentNotificationOptions = 'chalk_class_adjustment_notification_options',
}

/**
 * 表头设置的数据格式
 */
export type TableConfig<T = any> = {
  /**
   * 表头设置
   */
  columnKeys?: string[]

  /**
   * 记住上次导出时选中的表头
   */
  exportColumnKeys?: string[]

  /**
   * 其他记住的自定义导出配置
   */
  customExportConfigs?: T
}

/**
 * 个人表头设置汇总
 */
export type PersonalTableConfigs = {
  [key in PersonalTableConfigKey]: TableConfig
}

/**
 * 个人设置汇总
 */
export type PersonalConfigs = PersonalTableConfigs & {
  [PersonalConfigKey.ChalkLocale]: 'zh-CN' | 'en-US'
  [PersonalConfigKey.CommonUseApplicationsConfig]: string[]
  [PersonalConfigKey.MobileCommonUseApplicationsConfig]: string[]
  [PersonalConfigKey.HomeShortcutEntryConfig]: {
    type: string
    value: string
  }[]
  [PersonalConfigKey.HomeRecentEntryConfig]: {
    type: string
    value: string
  }[]
  [PersonalConfigKey.ChalkAssessmentSortBy]: {
    [key: string]: string
  }
  [PersonalConfigKey.ImDisturbConfig]: {
    [key: string]: boolean
  }
  [PersonalConfigKey.ChalkRecentVisitMenusConfig]: {
    path: string
    label: string
  }[]
  [PersonalConfigKey.ChalkGuideHistoryConfig]: string[]
  [PersonalConfigKey.SzzxScheduleVisibleConfig]: {
    [key in 'teacher' | 'student']: {
      [k in 'showMine' | 'isMerge']: boolean
    }
  }
  [PersonalConfigKey.ChalkExamAnalysisComparedExamsConfig]: {
    /**
     * 考试 id
     */
    [examId: string]: number[]
  }
  [PersonalConfigKey.ChalkExamAnalysisStudentTrendsRateConfig]: {
    /**
     * 考试 id，值为 [进步百分比, 退步百分比]
     */
    [examId: string]: [number, number]
  }
  [PersonalConfigKey.ChalkExamsBatchRelateItemsConfig]: {
    columnKeys: string[]
  }
  [PersonalConfigKey.ChalkLoadedImportantMessages]: string[] // 消息 id
  [PersonalConfigKey.ChalkAICertReviewCustomWritingStyle]: {
    reviewStyle: CertReviewStyleEnum // 选择的评价风格
    customStyle: string | null // AI 认证评价自定义评语风格样例
  }
  [PersonalConfigKey.ChalkAISemesterReviewConfigs]: {
    studentTags: string[] | null // 学生标签
    language: SemesterReviewLanguageEnum | null // 记住用户勾选的语言
    style: SemesterReviewStyleEnum | null // 记住用户选择的评价风格
  }
  [PersonalConfigKey.ChalkboardCertConfigs]: {
    sortKey?: string // 排序字段
    filterKey?: string // 筛选字段
    soundOff?: boolean // 是否开启声音
  }
  [PersonalConfigKey.ChalkAIBatchReviewConfigs]: {
    language: SemesterReviewLanguageEnum | null // 记住用户勾选的语言
    style: SemesterReviewStyleEnum | null // 记住用户选择的评价风格
    studentTagGroups: Array<{
      name: string
      tags: string[]
    }> | null // 学生标签组
  }
  /**
   * 我的签名
   */
  [PersonalConfigKey.ChalkMySign]: string | null
  /**
   * 成绩管理 - 导出学生成绩详情的配置
   */
  [PersonalConfigKey.ChalkGradeExportStudentGradesConfig]: Pick<
    ExportSummarizeScoreReqInterface,
    | 'type'
    | 'aggregationType'
    | 'itemFieldsType'
    | 'gradeFields'
    | 'classFields'
    | 'studentFields'
    | 'splitByClass'
  >
  /**
   * 课程班管理 - 教师课时 - 表头右侧的时间范围筛选
   */
  [PersonalConfigKey.ChalkTeacherClassHoursTimeRangeFilter]: {
    [semesterId: number]: [string, string]
  }

  /**
   * 课程班管理 - 教师课时统计 - 按课程统计导出配置
   */
  [PersonalConfigKey.ChalkTeacherClassHoursExportByClassConfig]: {
    /**
     * 是否汇总
     */
    aggregate: boolean
    /**
     * 汇总字段
     */
    aggregateField?: string
    /**
     * 导出字段
     */
    exportFields: string[]
  }

  /**
   * 行政班管理 - 导出 - 班级课表的导出配置
   */
  [PersonalConfigKey.ChalkAdminAdminClassScheduleExport]: {
    fileType: 'Excel' | 'PDF'
    classTypes: ('adminClass' | 'goClass')[]
    fieldsInsideCell: string[]
  }
  /**
   * 调代课 - 课节变动发送消息选项
   */
  [PersonalConfigKey.ChalkClassAdjustmentNotificationOptions]: NotificationOptions
}

/**
 * 视图字体大小（值目前仅作为区分）
 */
export enum LayoutFontSize {
  /**
   * 标准
   */
  Normal = 100,
  /**
   * 大字体
   */
  Large = 114,
}

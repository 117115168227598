export interface MessageSenderInterface {
  /**
   * 发送者 reflection_id
   */
  id?: number
  /**
   * 发送者 uid
   */
  uid?: number
  /**
   * 发送者名称
   */
  name?: string
}

export class MessageSender implements MessageSenderInterface {
  /**
   * 发送者 reflection_id
   */
  id?: number
  /**
   * 发送者 uid
   */
  uid?: number
  /**
   * 发送者名称
   */
  name?: string

  constructor(props: MessageSenderInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      uid: _uid,
      name: _name,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
    this.uid = _uid
    this.name = _name
  }

  static propKeys = ['id', 'uid', 'name']
}

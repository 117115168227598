export interface DefaultAnswerInterface {
  name?: string
  label?: string
  score?: string
}

export class DefaultAnswer implements DefaultAnswerInterface {
  name?: string
  label?: string
  score?: string

  constructor(props: DefaultAnswerInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      label: _label,
      score: _score,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.label = _label
    this.score = _score
  }

  static propKeys = ['name', 'label', 'score']
}

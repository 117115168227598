/**
 * @file 用于注册在 Axios Hooks 当中，以获取登录信息
 */

import { RematchStore } from '@seiue/rematch-core'

export const getOAuthInfoFn = (store: RematchStore) => async () => {
  const { refreshOAuthTokenIfNeeded } = store.dispatch['session']

  // @ts-expect-error
  await refreshOAuthTokenIfNeeded()

  // 在此才能拿到刷新后的 token
  const {
    session: { oAuthToken },
  } = store.getState()

  return oAuthToken
}

export const getReflectionFn = (store: RematchStore) => () => {
  const {
    session: { currentReflection },
  } = store.getState()

  return currentReflection
}

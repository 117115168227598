import { NetdiskFileTypeEnum } from './netdisk-file-type-enum'
import { NetdiskFileStatusEnum } from './netdisk-file-status-enum'

export interface NetdiskParentFileInterface {
  /**
   * NetdiskOwner.id ，非 reflection.id
   */
  netdiskOwnerId: number
  /**
   * 父节点id ，顶级为 0
   */
  parentId: number
  /**
   * 是否是目录
   */
  isDir: boolean
  /**
   * 文件名称
   */
  name: string
  /**
   * 文件/文件夹 完整路径，不含文件名本身 e.g. /dir/subdir1
   */
  path: string
  /**
   * 文件类型
   */
  type: NetdiskFileTypeEnum
  mime?: string | null
  /**
   * 文件大小，当为 null 时表示目录文件正在异步计算
   */
  size?: number | null
  /**
   * 文件 MD5 值，文件夹没有 hash ，前端计算给出
   */
  hash?: string | null
  /**
   * 创建者 rid 。 APP 创建的文件该字段为 null
   */
  creatorRid?: number | null
  /**
   * 创建者 uid
   */
  creatorUid?: number | null
  /**
   * 分享次数
   */
  shareSum: number
  /**
   * 下载次数
   */
  downloadSum: number
  /**
   * 状态
   */
  status: NetdiskFileStatusEnum
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class NetdiskParentFile implements NetdiskParentFileInterface {
  /**
   * NetdiskOwner.id ，非 reflection.id
   */
  netdiskOwnerId: number
  /**
   * 父节点id ，顶级为 0
   */
  parentId: number
  /**
   * 是否是目录
   */
  isDir: boolean
  /**
   * 文件名称
   */
  name: string
  /**
   * 文件/文件夹 完整路径，不含文件名本身 e.g. /dir/subdir1
   */
  path: string
  /**
   * 文件类型
   */
  type: NetdiskFileTypeEnum
  mime?: string | null
  /**
   * 文件大小，当为 null 时表示目录文件正在异步计算
   */
  size?: number | null
  /**
   * 文件 MD5 值，文件夹没有 hash ，前端计算给出
   */
  hash?: string | null
  /**
   * 创建者 rid 。 APP 创建的文件该字段为 null
   */
  creatorRid?: number | null
  /**
   * 创建者 uid
   */
  creatorUid?: number | null
  /**
   * 分享次数
   */
  shareSum: number
  /**
   * 下载次数
   */
  downloadSum: number
  /**
   * 状态
   */
  status: NetdiskFileStatusEnum
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: NetdiskParentFileInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      netdiskOwnerId: _netdiskOwnerId,
      parentId: _parentId,
      isDir: _isDir,
      name: _name,
      path: _path,
      type: _type,
      mime: _mime,
      size: _size,
      hash: _hash,
      creatorRid: _creatorRid,
      creatorUid: _creatorUid,
      shareSum: _shareSum,
      downloadSum: _downloadSum,
      status: _status,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.netdiskOwnerId = _netdiskOwnerId
    this.parentId = _parentId
    this.isDir = _isDir
    this.name = _name
    this.path = _path
    this.type = _type
    this.mime = _mime
    this.size = _size
    this.hash = _hash
    this.creatorRid = _creatorRid
    this.creatorUid = _creatorUid
    this.shareSum = _shareSum
    this.downloadSum = _downloadSum
    this.status = _status
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'netdiskOwnerId',
    'parentId',
    'isDir',
    'name',
    'path',
    'type',
    'mime',
    'size',
    'hash',
    'creatorRid',
    'creatorUid',
    'shareSum',
    'downloadSum',
    'status',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

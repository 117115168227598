export interface ExamScoringPaperGenerationStatusesInterface {
  questionReady?: boolean
  /**
   * 客观题答案是否已设置
   */
  answerReady?: boolean
  /**
   * 知识点是否已设置
   */
  knowledgeReady?: boolean
  totalQuestionCounts?: number
  hasKnowledgeQuestionCounts?: number
  /**
   * 试卷是否已上传
   */
  testPaperAttachmentsReady?: boolean | null
}

export class ExamScoringPaperGenerationStatuses
  implements ExamScoringPaperGenerationStatusesInterface
{
  questionReady?: boolean
  /**
   * 客观题答案是否已设置
   */
  answerReady?: boolean
  /**
   * 知识点是否已设置
   */
  knowledgeReady?: boolean
  totalQuestionCounts?: number
  hasKnowledgeQuestionCounts?: number
  /**
   * 试卷是否已上传
   */
  testPaperAttachmentsReady?: boolean | null

  constructor(props: ExamScoringPaperGenerationStatusesInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      questionReady: _questionReady,
      answerReady: _answerReady,
      knowledgeReady: _knowledgeReady,
      totalQuestionCounts: _totalQuestionCounts,
      hasKnowledgeQuestionCounts: _hasKnowledgeQuestionCounts,
      testPaperAttachmentsReady: _testPaperAttachmentsReady,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.questionReady = _questionReady
    this.answerReady = _answerReady
    this.knowledgeReady = _knowledgeReady
    this.totalQuestionCounts = _totalQuestionCounts
    this.hasKnowledgeQuestionCounts = _hasKnowledgeQuestionCounts
    this.testPaperAttachmentsReady = _testPaperAttachmentsReady
  }

  static propKeys = [
    'questionReady',
    'answerReady',
    'knowledgeReady',
    'totalQuestionCounts',
    'hasKnowledgeQuestionCounts',
    'testPaperAttachmentsReady',
  ]
}

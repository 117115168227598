import { Schema, normalize } from '@seiue/normalizr'
import { forEach, reduce, pickBy, isEmpty } from '@seiue/util'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { ReferenceUpdate, State, ReferenceCount } from './types'

/**
 * 计算 entities 中存在的 entities 并返回 ReferenceCount（这里每个 key 对应的 count 的最大值为 1）
 * TODO 可以基于一些约定简化这个过程加速计算
 * @param denormalized
 * @param schema
 * @return ReferenceUpdate
 */
export function getReferenceUpdate(denormalized: any, schema: Schema) {
  const normalized = normalize(denormalized, schema)
  const referenceUpdate: ReferenceUpdate = {}
  forEach(normalized.entities, (values, key) => {
    referenceUpdate[key] = Object.keys(values)
  })

  return referenceUpdate
}

/**
 * 用于根据 denormalized entities 及其 schema 自动地计算 referenceUpdate 并缓存
 * @param denormalized
 * @param schema
 */
export function useGetReferenceUpdate(denormalized: any, schema: Schema) {
  return useMemo(() => {
    if (isEmpty(denormalized)) {
      return undefined
    }

    return getReferenceUpdate(denormalized, schema)
  }, [denormalized, schema])
}

/**
 * 用于根据 referenceUpdate 自动地增减 entities-store 中的引用计数
 * @param {ReferenceUpdate} referenceUpdate
 */
export function useUpdateReferenceCount(referenceUpdate?: ReferenceUpdate) {
  const dispatch: any = useDispatch()

  useEffect(() => {
    if (referenceUpdate) {
      dispatch.entities.updateReferenceCount(referenceUpdate, true)
      return () => {
        dispatch.entities.updateReferenceCount(referenceUpdate, false)
      }
    }

    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceUpdate])
}

/**
 * 用于根据 denormalized entities 及其 schema 自动地增减 entities-store 中的引用计数
 * @param {any} denormalized
 * @param {Schema} schema
 */
export function useReferenceCount(denormalized: any, schema: Schema) {
  const referenceUpdate = useGetReferenceUpdate(denormalized, schema)
  useUpdateReferenceCount(referenceUpdate)
}

export const entityCacheGC = (
  entityCache: State['entityCache'],
  referenceCount: ReferenceCount,
): [State['entityCache'], number] => {
  let totalItems = 0
  const res = reduce(
    entityCache,
    (m, map, key) => {
      const reference = referenceCount[key] || {}
      const picked = pickBy(map, (entity, id) => reference[`${id}`] > 0)
      totalItems += Object.keys(picked).length
      return {
        ...m,
        [key]: picked,
      }
    },
    {} as State['entityCache'],
  )

  return [res, totalItems]
}

/* eslint-disable no-param-reassign */
export const updateReferenceCount = (
  referenceCount: ReferenceCount,
  referenceUpdate: ReferenceUpdate,
  increase: boolean,
) => {
  forEach(referenceUpdate, (ids, key) => {
    referenceCount[key] = referenceCount[key] || {}
    ids.forEach((id: number | string) => {
      const current = referenceCount[key][id] || 0
      referenceCount[key][id] = increase
        ? current + 1
        : Math.max(current - 1, 0)
    })
  })
}
/* eslint-enable no-param-reassign */

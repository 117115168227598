export interface ExamGradeStatsInterface {
  maxScore?: string
  minScore?: string
  avgScore?: string
}

export class ExamGradeStats implements ExamGradeStatsInterface {
  maxScore?: string
  minScore?: string
  avgScore?: string

  constructor(props: ExamGradeStatsInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      maxScore: _maxScore,
      minScore: _minScore,
      avgScore: _avgScore,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.maxScore = _maxScore
    this.minScore = _minScore
    this.avgScore = _avgScore
  }

  static propKeys = ['maxScore', 'minScore', 'avgScore']
}

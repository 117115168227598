/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { LayoutPageIdentityEnum } from './layout-page-identity-enum'
import { LayoutPlatformEnum } from './layout-platform-enum'
import { Layout, LayoutInterface } from './layout'
import type { LoadMeCurrentLayoutQuery } from './load-me-current-layout-query'

const buildConfig = createConfigBuilder('chalk')

export const layoutApi$loadMeCurrentLayout = {
  config(
    pageIdentity: LayoutPageIdentityEnum,
    platform: LayoutPlatformEnum,
    loadMeCurrentLayoutQuery?: LoadMeCurrentLayoutQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/layout/me/layouts/current',
      {
        pageIdentity,
        platform,
      },
      loadMeCurrentLayoutQuery,
    )
  },

  /**
   * @summary 查询登录用户当前的布局视图（缓存）
   * @param pageIdentity 指定页面的视图布局
   * @param platform 平台
   * @param [loadMeCurrentLayoutQuery]
   * @param [layoutQuery.roleIds] 当前用户的权限角色 Ids
   * @param [options]
   * @return AxiosResponsePromise<Layout | null>
   */

  api(
    pageIdentity: LayoutPageIdentityEnum,
    platform: LayoutPlatformEnum,
    loadMeCurrentLayoutQuery?: LoadMeCurrentLayoutQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Layout | null> {
    return axios(
      layoutApi$loadMeCurrentLayout.config(
        pageIdentity,
        platform,
        loadMeCurrentLayoutQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new Layout(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = Layout | null>(
    {
      pageIdentity,
      platform,
      query,
    }: {
      pageIdentity: LayoutPageIdentityEnum
      platform: LayoutPlatformEnum
      query?: LoadMeCurrentLayoutQuery
    },
    queryOptions?: QueryOptionsWithSelect<Layout | null, TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Layout | null, TSelected>(
      layoutApi$loadMeCurrentLayout.api,
      'Layout.loadMeCurrentLayout',
      queryOptions,
      pageIdentity,
      platform,
      query,
    )
  },
}

// @ts-ignore
layoutApi$loadMeCurrentLayout.api._name_ = `Layout.loadMeCurrentLayout`

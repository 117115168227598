import {
  AnswerReq,
  AnswerStats,
  CreateReview,
  Review,
} from 'packages/sdks-next/chalk'

export * from './plugin-settings'

export enum HandoutSettingItemEnum {
  AllowTeacherEdit = 'allow_teacher_edit',
  HandoutPushMode = 'handout_push_mode',
  SubjectKnowledgePointIsOpen = 'subject_knowledge_point_is_open',
  SubjectCoreLiteracyIsOpen = 'subject_core_literacy_is_open',
  SubjectAbilityIsOpen = 'subject_ability_is_open',
  TagIsOpen = 'tag_is_open',
}

export type Extend<T> = T & {
  id?: number
  vid?: number
}
export type ExtendedAnswerReq = Extend<AnswerReq>
export type ExtendedCreateReview = Extend<CreateReview>

/**
 * 学案相关数据结构，例如：块数据/批阅/作答/作答统计等都是平铺的数组结构
 * 转成由 blockId 和 questionId 索引的 map 结构，方便取用
 */
export type TransformedData<T> = {
  [blockId: number]: {
    [questionId: number]: T
  }
}
/**
 * 转成 map 后的学生作答数据
 */
export type AnswerData = TransformedData<ExtendedAnswerReq>
/**
 * 转成 map 后的（教师批阅/学生自评）数据
 */
export type ReviewData = TransformedData<Review>
/**
 * 转成 map 后的作答统计数据
 */
export type AnswerStatData = TransformedData<AnswerStats>

/**
 * 学案类型枚举
 */
export enum HandoutTypeEnum {
  /**
   * 学案库学案，名 root 是因为最开始引用学案只能从这里引用
   *
   * 可引用关系：被引用: [课程班学案/课程库学案]; 引用自: []
   */
  RootHandout = 'root_handout',
  /**
   * 课程库学案
   *
   * 可引用关系：被引用: []; 引用自: [学案库学案]
   */
  CourseHandout = 'course_handout',
  /**
   * 课程班学案
   *
   * 可引用关系：被引用: [课程班学案]; 引用自: [学案库学案/课程班学案/题库];
   */
  ClassHandout = 'class_handout',
  /**
   * 测验任务关联的学案，相关逻辑和行为基本和课程班一致，但是还是单独给个枚举值 for future sake
   *
   * 可引用关系：被引用：[]; 引用自: [题库]
   */
  TaskRelatedHandout = 'task_related_handout',
  /**
   * 题库，不是一种学案类型，但是在引用的时候，和其他学案类型并列，所以这里也给个枚举值
   *
   * 可引用关系：被引用: [学案库/课程班学案/测验任务学案]; 引用自: []
   */
  QuestionPool = 'question_pool',
}

/**
 * 块的状态枚举
 */
export enum BlockStateEnum {
  Display = 'display',
  Selected = 'selected',
  Editing = 'editing',
  Reviewing = 'reviewing',
  /**
   * 块的引用态，用于在引用抽屉中块的展示
   */
  Refer = 'refer',
  Answering = 'answering',
}

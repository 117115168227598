export interface GstTemplateConstraintInterface {
  /**
   * 资源名称
   */
  resourceName: string
  /**
   * 指定最大成员小于等于的字段, 指定资源主体的字段名
   */
  maxMembersEltField: string
}

export class GstTemplateConstraint implements GstTemplateConstraintInterface {
  /**
   * 资源名称
   */
  resourceName: string
  /**
   * 指定最大成员小于等于的字段, 指定资源主体的字段名
   */
  maxMembersEltField: string

  constructor(props: GstTemplateConstraintInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      resourceName: _resourceName,
      maxMembersEltField: _maxMembersEltField,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.resourceName = _resourceName
    this.maxMembersEltField = _maxMembersEltField
  }

  static propKeys = ['resourceName', 'maxMembersEltField']
}

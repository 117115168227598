import { omit } from '@seiue/util'
// eslint-disable-next-line no-restricted-imports
import { Button as ButtonAntd } from 'antd'
import { ButtonProps as AntdButtonProps } from 'antd/lib/button'
import classNames from 'classnames'
import React, {
  useState,
  useEffect,
  useRef,
  CSSProperties,
  forwardRef,
} from 'react'
import styled, { css } from 'styled-components'

import { SeIcon, SeIconProp } from 'packages/components/Icon'

export * from 'antd/lib/button'
export const { Group } = ButtonAntd

export interface ButtonProps
  extends Omit<AntdButtonProps, 'type' | 'icon' | 'size' | 'loading'> {
  type?:
    | 'text'
    | 'special'
    | 'gray'
    | 'reversed'
    | 'default'
    | 'primary'
    | 'link'
    | 'dashed'
  size?: AntdButtonProps['size'] | 'xs'
  icon?: SeIconProp
  iconRight?: SeIconProp
  fontWeight?: CSSProperties['fontWeight']

  /**
   * true 则始终显示 loading 状态，false 则始终不显示，
   * 优先级高于 onClick 传 async function 时带来的 loading 状态
   */
  loading?: boolean
  loadingText?: React.ReactNode
}

/**
 * @deprecated 请用 import { Button } from '@seiue/ui' 替代
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ onClick, loading, loadingText, type = 'default', ...props }, ref) => {
    const [executingOnClick, setExecutingOnClick] = useState(false)

    const defaultThrottle = React.useRef(false)

    // 如果显示指定了 loading 就不再关心 executingOnClick
    const isLoading = loading ?? executingOnClick

    /*
     * 以此来阻止在 asyncOnClick 执行过程中发生了页面跳转，使得 ButtonRaw 被 unmount 后，再执行 setExecutingOnClick 引发错误
     * 该错误可能引发内存泄漏
     * 该用法可能是一种常见的该问题的解决方案
     */
    const mounted = useRef(false)

    useEffect(() => {
      mounted.current = true
      return () => {
        mounted.current = false
      }
    })

    let callback: ((e: any) => Promise<any>) | undefined

    if (onClick) {
      callback = async (e: any) => {
        if (defaultThrottle.current) return

        defaultThrottle.current = true
        const throttleTimer = setTimeout(() => {
          defaultThrottle.current = false
          clearTimeout(throttleTimer)
        }, 225)

        /*
         * 在按钮被触发后 200ms 后且方法尚未执行完毕时，将按钮设置为 loading 状态
         * 为了瞬间完成的按钮不闪一个 loading
         */
        const timer = setTimeout(() => {
          setExecutingOnClick(true)
        }, 200)

        try {
          await onClick(e)
        } finally {
          clearTimeout(timer)
          if (mounted.current) setExecutingOnClick(false)
        }
      }
    }

    const className = classNames(props.className, {
      [`se-button__${type}`]: !!type,
    })

    const antdBtnType = (
      ['ghost', 'link', 'default', 'primary', 'dashed', undefined].includes(
        type,
      )
        ? type
        : undefined
    ) as AntdButtonProps['type']

    return (
      <ButtonStyled
        loading={isLoading}
        onClick={callback}
        type={antdBtnType}
        {...omit(props, 'type', 'icon', 'iconRight', 'size')}
        // xs 是在 small 基础上再微调
        size={props.size === 'xs' ? 'small' : props.size}
        // https://styled-components.com/docs/faqs#why-am-i-getting-html-attribute-warnings
        xs={props.size === 'xs' ? 1 : 0}
        className={className}
        ref={ref}
      >
        {props.icon ? <SeIcon icon={props.icon} /> : null}
        {isLoading && loadingText ? loadingText : props.children}
        {props.iconRight ? <SeIcon icon={props.iconRight} /> : null}
      </ButtonStyled>
    )
  },
)

// @ts-expect-error 令其他 antd 组件可以识别该按钮为 antButton，比如 tooltip，相关 issue：https://github.com/ant-design/ant-design/issues/18137
Button.__ANT_BUTTON = true

const ButtonStyled = styled(ButtonAntd)<{
  fontWeight?: CSSProperties['fontWeight']
  xs: number
}>`
  & + &,
  & + .ant-btn,
  .ant-btn + &,
  & + .ant-btn-group,
  .ant-btn-group + & {
    margin-left: 12px;
  }

  &&& {
    ${props =>
      props.fontWeight
        ? css`
            font-weight: ${props.fontWeight};
          `
        : ''};
    ${props =>
      props.xs
        ? css`
            height: 24px;
            padding-top: 0;
            padding-bottom: 0;
            font-size: 13px;
            line-height: 23px;
          `
        : ''};

    & > .anticon + span,
    & > span + .anticon {
      margin-left: 6px;
    }

    &.ant-btn-sm {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.se-button__default {
      &:hover,
      &:focus {
        color: ${p => p.theme.text._2};
        background-color: ${p => p.theme.background._2};
        border-color: ${p => p.theme.border.greyNormal};
      }
      &:active {
        color: ${p => p.theme.text._1};
        background-color: ${p => p.theme.background._5};
        border-color: ${p => p.theme.border.greyNormal};
      }
      &[disabled],
      &[disabled]:hover {
        color: ${p => p.theme.text._4};
        background-color: white;
        border-color: ${p => p.theme.border.greyNormal};
      }
    }

    &.se-button__primary {
      color: white;
      background-color: ${p => p.theme.brand._1};
      border-color: ${p => p.theme.brand._1};
      &:hover,
      &:focus {
        background-color: ${p => p.theme.brand._2};
        border-color: ${p => p.theme.brand._2};
      }
      &:active {
        background-color: ${p => p.theme.brand.dark1};
        border-color: ${p => p.theme.brand.dark1};
      }
      &[disabled],
      &[disabled]:hover {
        color: white;
        background-color: ${p => p.theme.brand._4};
        border-color: ${p => p.theme.brand._4};
      }
    }

    &.se-button__link {
      padding-right: 0;
      padding-left: 0;
      font-weight: ${p => p.fontWeight || 'normal'};
      color: ${p => p.theme.brand.text0};

      &:hover,
      &:focus {
        color: ${p => p.theme.brand._2};
      }

      &[disabled],
      &[disabled]:hover {
        color: ${p => p.theme.brand._4};
      }
    }

    &.se-button__special {
      color: ${p => p.theme.brand.text0};
      background: #ffffff;
      border-color: ${p => p.theme.brand.text0};

      &:hover,
      &:focus {
        color: ${p => p.theme.brand.text0};
        background: ${p => p.theme.brand._5};
      }

      &:active {
        color: ${p => p.theme.brand.dark1};
        background: ${p => p.theme.brand._5};
        border-color: ${p => p.theme.brand.dark1};
      }

      &[disabled],
      &[disabled]:hover {
        color: ${p => p.theme.brand._4};
        background-color: white;
        border-color: ${p => p.theme.brand._4};
      }
    }
  }
`

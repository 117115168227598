/**
 * @file group apis
 */

import { isAxiosError } from '@seiue/axios'
import { isBoolean } from '@seiue/util'
import { useMemo } from 'react'

import { useGetGroupByBiz } from 'packages/feature-utils/groups/apis'
import {
  GroupScopeEnum,
  GroupSettingEnum,
} from 'packages/features/groups/types'
import { useSelectedSemesterId } from 'packages/features/semesters/utils/data'
import { useCurrentReflection } from 'packages/features/sessions/utils/data'
import { QueryOptions, useFindAll } from 'packages/sdks-next'
import {
  configItemSchema,
  GroupStatusEnum,
  GroupTypeEnum,
  MemberTypeEnum,
  groupApi$getPermissionsByReflection,
  acmApi$queryConfigItemsByGroup,
  groupApi$findJoinedGroups,
} from 'packages/sdks-next/chalk'
import { ServiceEnum } from 'packages/shared-stores/configs'

/**
 * 获取 group，不过我们忽略 403/404 错误
 *
 * 可能会因为 bizId 所对应的群组尚未生成，所以查不到内容
 * 但此时，并不希望像用户报出找不到群组的提示，静默失败即可
 *
 * 同时也忽略 403 错误，因为权限不在的情况下，也不希望报错
 *
 * @param args - 参数
 * @param args.type - 群组类型
 * @param args.bizId - 群组对应业务的业务数据 id
 * @param args.waitFor - 传入后，将等待该值为 true 时开始获取
 * @returns 群组信息
 */
export const useGroupOmitSomeError = ({
  type,
  bizId,
  waitFor,
}: {
  type: GroupTypeEnum
  bizId?: string | number | null
  waitFor?: boolean
}) => {
  const { data: group, loading: groupLoading } = useGetGroupByBiz(
    { type, bizId: `${bizId}` },
    {
      disable: isBoolean(waitFor) ? !waitFor : false,
      catchError: e => {
        if (isAxiosError(e, 404) || isAxiosError(e, 403)) {
          return undefined
        }

        return e
      },
    },
  )

  return [group, groupLoading] as const
}

/**
 * 获取当前 reflection 在指定群组（底层概念）中的权限，
 * 比如对于学生档案（基于群组实现）的权限
 *
 * @param args - 参数
 * @param args.type - 群组类型
 * @param args.groupId - 群组 id
 * @param args.bizId - 群组对应业务的业务数据 id
 * @param args.waitFor - 传入后，将等待该值为 true 时开始获取
 * @returns 群组信息
 */
export const useGroupPermissions = ({
  type,
  groupId,
  bizId,
  waitFor,
}: {
  type: GroupTypeEnum
  groupId?: number
  bizId?: string | number | null
  waitFor?: boolean
}) => {
  const [group, groupLoading] = useGroupOmitSomeError({
    type,
    bizId,
    waitFor,
  })

  const computeGroupId = groupId || group?.id
  const currentReflection = useCurrentReflection()

  const { data: permissions, loading: permissionsLoading } =
    groupApi$getPermissionsByReflection.useApi(
      {
        groupId: group?.id || 0,
        rid: currentReflection.id,
      },
      {
        disable: !computeGroupId || !currentReflection.id,
      },
    )

  const hasPermission = (permName: string) =>
    permissions?.includes(permName) || false

  return [
    permissions,
    hasPermission,
    groupLoading || permissionsLoading,
  ] as const
}

/**
 * 判断指定 rid 是否是离开了某个群组
 *
 * @param param0 - 参数
 * @param param0.rid - rid
 * @param param0.bizId - 群组业务 id
 * @param param0.type - 群组类型
 * @param param0.disable - 禁用
 * @returns [是否离开了群组，加载中]
 */
export const useHasLeavedGroup = ({
  rid,
  bizId,
  type,
  disable,
}: {
  bizId: string
  type: GroupTypeEnum
  rid: number
  disable?: boolean
}): [boolean, boolean] => {
  const { data: group, loading } = useGetGroupByBiz(
    {
      type,
      bizId,
      query: {
        calLeaveStatusByRid: Number(rid),
      },
    },
    {
      disable,
    },
  )

  return [group?.status === GroupStatusEnum.Leaved, loading]
}

/**
 * 获取某个 service 下的某个 group 的配置项，比如目标设置的配置项、任务设置的配置项
 *
 * @param service - ServiceEnum
 * @param group - GroupSettingEnum
 * @returns 配置项
 */
export function useFetchGroupConfigs(
  service: ServiceEnum,
  group: GroupSettingEnum,
) {
  const reflection = useCurrentReflection()
  return useFindAll({
    api: acmApi$queryConfigItemsByGroup.api,
    schema: configItemSchema,
    args: [`school.${reflection.schoolId}`, service, group] as const,
  })
}

interface HasGroupsQuery {
  scopes?: (
    | GroupScopeEnum.AdminClass
    | GroupScopeEnum.Class
    | GroupScopeEnum.Course
    | GroupScopeEnum.Dorm
  )[]
  memberTypes?: MemberTypeEnum[]
}

/**
 * 判断选中学期中，是否有某种正常状态的群组
 *
 * 本 Hooks 的实现会每次查询固定的群组数据，再根据传入的 query 进行过滤。以此来整合重复的查询请求
 * 选中学期的信息来自于 useSelectedSemesterId
 *
 * @param param0 - HasGroupsQuery
 * @param param0.scopes - GroupScopeEnum
 * @param param0.memberTypes - MemberTypeEnum[]
 * @param config - 查询设置参数
 * @returns [boolean, loading, groups]
 */
export function useHasNormalGroupsInSelectedSemester(
  { scopes, memberTypes }: HasGroupsQuery,
  config?: QueryOptions,
) {
  const selectSemesterId = useSelectedSemesterId()

  const { id: rid } = useCurrentReflection()

  const scopesStr = scopes?.join(',')

  const fullOuterScopes = useMemo(
    () =>
      scopes?.map(scope => {
        if (scope === GroupScopeEnum.Class) {
          return `${GroupScopeEnum.Class}.semester-${selectSemesterId}`
        }

        return scope
      }),
    // eslint-disable-next-line
    [scopesStr, selectSemesterId],
  )

  const query = useMemo(() => {
    const _query = {
      paginated: 0,
      status: GroupStatusEnum.Normal,
      scopeIn: '',
    }

    const _scopes: string[] = [
      GroupScopeEnum.AdminClass,
      GroupScopeEnum.Course,
      GroupScopeEnum.Dorm,
    ]

    if (selectSemesterId) {
      _scopes.push(`${GroupScopeEnum.Class}.semester-${selectSemesterId}`)
    }

    _query.scopeIn = _scopes.join(',')

    return _query
  }, [selectSemesterId])

  const { data, loading } = groupApi$findJoinedGroups.useApi(
    {
      id: rid,
      query,
    },
    {
      // 群组数据不会时常改变，设置一个缓存时间
      staleTime: 2 * 60,
      ...config,
    },
  )

  const memberTypesStr = memberTypes?.join(',')
  const groups = useMemo(
    () =>
      data?.filter(group => {
        if (fullOuterScopes?.length && !fullOuterScopes.includes(group.scope))
          return false

        return !(
          memberTypes?.length &&
          !memberTypes.includes(group.memberType as MemberTypeEnum)
        )
      }) || [],
    // eslint-disable-next-line
    [data, fullOuterScopes, memberTypesStr],
  )

  return [!!groups.length, loading, groups] as const
}

/**
 * @file Session Store
 */

import { reportToEngineer } from '@seiue/sentry'
import { merge, omit, uniqBy } from '@seiue/util'
import jwtDecode from 'jwt-decode'

import { Expand } from 'packages/sdks-next'
import {
  Reflection,
  ScopeAssignment,
  Role,
  User,
  Scope,
  PermissionNameEnum,
  ScopeEnhancerEnum,
  OAuthToken,
  School,
  AuthTicket,
} from 'packages/sdks-next/chalk'
import { RequirePropPathList } from 'packages/utils/types'

import { SessionState } from '../utils'

import { effects } from './sessions-effects'

export * from './sessions-effects'

const defaultState = {
  state: 'pending',
  currentPermissions: [],
  currentManagerRoles: [],
  currentManagedRoles: [],
  currentScopes: [],
  authType: null,
  isApolloLogin: false,
  isSSOLogin: false,
  skipResetPassword: false,
  skipBindPhone: false,
}

const reducers = {
  setState(state: SessionState, _state: SessionState['state']) {
    return {
      ...state,
      state: _state,
    }
  },

  setCurrentUser(
    state: SessionState,
    currentUser: RequirePropPathList<User, ['reflections']>,
  ) {
    return { ...state, currentUser }
  },

  setCurrentReflectionReducer(
    state: SessionState,
    currentReflection: Reflection,
  ) {
    return { ...state, currentReflection }
  },

  setOAuthToken(state: SessionState, oAuthToken: OAuthToken) {
    let isApolloLogin = false
    let { isSSOLogin, skipResetPassword } = state

    try {
      const tokenResult = jwtDecode(oAuthToken.accessToken) as {
        ctx: string | undefined
      }

      if (tokenResult.ctx?.includes('staff_id=')) {
        isApolloLogin = true
      }

      // 单点登录，跳过重置密码
      if (tokenResult.ctx?.includes('sso=1')) {
        isSSOLogin = true
        skipResetPassword = true
      }
    } catch (e) {
      reportToEngineer(e, { ExceptionType: 'token' })
    }

    return {
      ...state,
      oAuthToken,
      oAuthTokenFetchedAt: Date.now(),
      isApolloLogin,
      isSSOLogin,
      skipResetPassword,
    }
  },

  clearOAuthToken(state: SessionState) {
    return { ...state, oAuthToken: undefined, oAuthTokenFetchedAt: 0 }
  },

  setAuthType(state: SessionState, authType: SessionState['authType']) {
    return { ...state, authType }
  },

  resetState(state: SessionState, extraState?: Partial<SessionState>) {
    return { ...merge({}, defaultState, extraState) }
  },

  clearCurrentScopes(state: SessionState) {
    return { ...state, currentScopes: [] }
  },

  setCurrentScopes(
    state: SessionState,
    {
      scopes,
      permission,
      enhancer,
    }: {
      scopes: Scope[]
      permission: PermissionNameEnum
      enhancer: ScopeEnhancerEnum
    },
  ) {
    const nextCurrentScopes = [...state.currentScopes]
    const index = nextCurrentScopes.findIndex(
      scope => scope.enhancer === enhancer && scope.permission === permission,
    )

    if (index >= 0) {
      nextCurrentScopes[index].scopes = scopes
    } else {
      nextCurrentScopes.push({
        permission,
        enhancer,
        scopes,
      })
    }

    return {
      ...state,
      currentScopes: nextCurrentScopes,
    }
  },
  setCurrentPermissions(
    state: SessionState,
    currentPermissions: ScopeAssignment[],
  ) {
    return { ...state, currentPermissions }
  },

  mergeCurrentRoles(
    state: SessionState,
    {
      roles,
      isManager,
      reset,
    }: { roles: Role[]; isManager: boolean; reset?: boolean },
  ) {
    let nextRoles: Role[]
    if (reset) {
      nextRoles = roles
    } else {
      const targetRoles = isManager
        ? state.currentManagerRoles
        : state.currentManagedRoles

      const mergedRoles = [...roles, ...targetRoles]

      nextRoles = uniqBy(mergedRoles, 'id')
    }

    if (isManager) {
      return {
        ...state,
        currentManagerRoles: nextRoles,
      }
    }

    return {
      ...state,
      currentManagedRoles: nextRoles,
    }
  },

  setCurrentSchool(
    state: SessionState,
    school: Expand<School, ['customConstraints']>,
  ) {
    return {
      ...state,
      currentSchool: school,
    }
  },

  setIsApolloLogin(state: SessionState, flag: boolean) {
    return {
      ...state,
      isApolloLogin: flag,
    }
  },

  setSkipResetPassword(state: SessionState, flag: boolean) {
    return {
      ...state,
      skipResetPassword: flag,
    }
  },

  setSkipBindPhone(state: SessionState, flag: boolean) {
    return {
      ...state,
      skipBindPhone: flag,
    }
  },

  setLastLoginAt(state: SessionState, lastLoginAt: string) {
    return {
      ...state,
      lastLoginAt,
    }
  },

  setAuthTicket(state: SessionState, authTicket?: AuthTicket) {
    return {
      ...state,
      authTicket,
    }
  },

  setSsoProvider(state: SessionState, ssoProvider: 'dingtalkv2' | 'wework') {
    return {
      ...state,
      ssoProvider,
    }
  },
}

export const session = {
  state: defaultState as any as SessionState,
  reducers,
  effects,
}

export const sessionPersistTransform = {
  in: (persistState: any, key: any) => {
    if (key === 'session') {
      return omit(persistState, ['state', 'currentSchool'])
    }

    return persistState
  },
  out: (val: any) => val,
}

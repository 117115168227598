export enum FieldTypeEnum {
  Attachment = 'attachment',
  Biz = 'biz',
  Checkbox = 'checkbox',
  DateRange = 'date_range',
  DateTime = 'date_time',
  Hidden = 'hidden',
  Input = 'input',
  Klass = 'klass',
  Money = 'money',
  Number = 'number',
  Paragraph = 'paragraph',
  Radio = 'radio',
  Select = 'select',
  Table = 'table',
  Textarea = 'textarea',
  IdCard = 'id_card',
  Phone = 'phone',
  TimeRanges = 'time_ranges',
  Address = 'address',
  ReflectionPicker = 'reflection_picker',
  CascadeSelector = 'cascade_selector',
}

export interface PsychologicalGroupInterface {
  schoolId: number
  /**
   * 群组角色名称
   */
  roleName: string
  /**
   * 群组类型
   */
  type: string
  /**
   * 来源
   * psychological 心理档案权限角色（心理档案角色）
   * system 系统角色（班主任，课程班，导师，宿管）
   */
  from?: string
  /**
   * 主键 ID
   */
  id: number
}

export class PsychologicalGroup implements PsychologicalGroupInterface {
  schoolId: number
  /**
   * 群组角色名称
   */
  roleName: string
  /**
   * 群组类型
   */
  type: string
  /**
   * 来源
   * psychological 心理档案权限角色（心理档案角色）
   * system 系统角色（班主任，课程班，导师，宿管）
   */
  from?: string
  /**
   * 主键 ID
   */
  id: number

  constructor(props: PsychologicalGroupInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      roleName: _roleName,
      type: _type,
      from: _from,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.roleName = _roleName
    this.type = _type
    this.from = _from
    this.id = _id
  }

  static propKeys = ['schoolId', 'roleName', 'type', 'from', 'id']
}

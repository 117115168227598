import { moment } from '@seiue/moment'
import { cloneDeep, groupBy, mapValues, orderBy, uniqBy } from '@seiue/util'
import { useMemo } from 'react'

import {
  WeekCalendarProps,
  CalendarView,
  CalendarEventType,
} from 'packages/features/calendars/utils/types'
import {
  FULL_DAY_ITEM_HEIGHT,
  FULL_DAY_ITEM_MARGIN_TOP,
} from 'packages/features/calendars/utils/views'
import { useCurrentReflection } from 'packages/features/sessions/utils/data'
import { $t } from 'packages/locale'
import { CalendarEvent as OriginCalendarEvent } from 'packages/sdks-next/chalk'

import {
  filterSomeDaySources,
  formatDayViewFullDayCalendarEvents,
  formatWeekViewFullDayCalendarEvents,
  formatNonFullDayCalendarEvents,
  groupByCalendarEventsByDay,
  sortCalendarEventsAscByTime,
} from './data'

/**
 * 根据 sources 计算出最小时间和最大时间（HH:mm:ss）
 *
 * @param sources - sources
 * @param date - 日期
 * @param view - 视图
 * @param maxTimeHourDiff - 最大时间差
 * @returns [startTime, endTime, hour + 1]
 */
export const useCalcEventsMinMaxTime = (
  sources: OriginCalendarEvent[],
  date: string,
  view: CalendarView,
  maxTimeHourDiff?: number,
): [string | null, string | null, number] =>
  useMemo(() => {
    let startList = []
    let endList = []
    if (view === 'week') {
      // 周视图，取所有 sources 排序
      startList = sortCalendarEventsAscByTime(
        cloneDeep(sources || []),
        'startTime',
      )

      endList = sortCalendarEventsAscByTime(cloneDeep(sources || []), 'endTime')
    } else {
      // 日视图，只取所在天的 sources 排序，sourceData[current]
      const sourceData = groupByCalendarEventsByDay(sources || [])
      startList = sortCalendarEventsAscByTime(
        cloneDeep(sourceData[date] || []),
        'startTime',
      )

      endList = sortCalendarEventsAscByTime(
        cloneDeep(sourceData[date] || []),
        'endTime',
      )
    }

    if (startList.length && endList.length) {
      // 因为课表总是整时出现，所以起止时间也按照整时处理
      const first = moment(startList[0].startTime)
        .startOf('hour')
        .subtract(maxTimeHourDiff || 0, 'hour')

      const last = moment(endList[endList.length - 1].endTime).startOf('hour')

      // 将最晚时间限制在当天
      if (last.format('HH') < '23') {
        last.add(1, 'hour')
      } else {
        last.endOf('hour')
      }

      const hour = +moment(last).format('HH') - +moment(first).format('HH')

      return [
        first.format('HH:mm:ss'),
        last.format('HH:mm:ss'),
        /*
         * hour + 1 多显示一行，
         * 另外家长切换孩子日程时会出现短暂的负数，然后 rerender 的时候正常显示
         * 这儿需要处理一下
         */
        Math.abs(hour),
      ]
    }

    return [null, null, 0]
  }, [sources, date, view, maxTimeHourDiff])

/**
 * （非全天）将后端返回的 CalendarEvent 按类型转为日程组件需要的 CalendarEvent 数据
 *
 * @param sources - 源事件
 * @param date - 日视图日期
 * @param itemHeight - 元素高度
 * @param view - 视图
 * @param dates - 周视图日期数组
 * @param minTime - 最小时间
 * @param foldLessonInWeekView - 在周视图下，单节课程数量大于 2 是否折叠
 * @returns - FormattedNonFullDayEvents
 */
export const useFormatNonFullDayEvents = (
  sources: OriginCalendarEvent[] | null,
  date: string,
  itemHeight: number,
  view: CalendarView,
  dates: string[],
  minTime: string | null,
  foldLessonInWeekView?: boolean,
): WeekCalendarProps => {
  const foldGroupedNonFullDayEventsFn = useFoldGroupedNonFullDayEvents()
  return useMemo(() => {
    if (!minTime || !sources) return {}
    const sourceData = groupByCalendarEventsByDay(sources)
    const minHour = +moment(`${date} ${minTime}`).format('HH') + 1
    const offsetTop = minHour * itemHeight
    // 周视图
    if (view === 'week') {
      const foldSourceData = foldLessonInWeekView
        ? foldGroupedNonFullDayEventsFn(sourceData)
        : sourceData

      // 返回：key 为 date，value 为 events 的 object，然后对应日期显示
      return Object.entries(foldSourceData).reduce((m, [k, v]) => {
        const index = dates.findIndex(_ => _ === k)
        if (index !== -1) {
          /* eslint-disable no-param-reassign */
          m[k] = formatNonFullDayCalendarEvents({
            sources: v,
            gridWidth: itemHeight,
            offsetTop: -offsetTop,
          })
          /* eslint-disable no-param-reassign */
        }

        return m
      }, {} as WeekCalendarProps)
    }

    // 日视图，返回：key 为选中天，value 为 events 的 object，然后对应日期显示
    return {
      [date]: formatNonFullDayCalendarEvents({
        sources: sourceData[date],
        gridWidth: itemHeight,
        offsetTop: -offsetTop,
      }),
    }
  }, [
    minTime,
    sources,
    date,
    itemHeight,
    view,
    dates,
    foldGroupedNonFullDayEventsFn,
    foldLessonInWeekView,
  ])
}

/**
 * （全天）将后端返回的 CalendarEvent 按类型转为日程组件需要的 CalendarEvent 数据
 *
 * @param sources - 原始日历事件数组。
 * @param date - 一个表示特定日期的字符串。
 * @param view - 日历视图
 * @param dates - 周视图日期数组
 * @param platform - 执行环境，可为'web'或'mobile'。
 * @returns Array
 */
export const useFormatFullDayEvents = (
  sources: OriginCalendarEvent[] | null,
  date: string,
  view: CalendarView,
  dates: string[],
  platform: 'web' | 'mobile',
): [WeekCalendarProps, number, boolean] =>
  useMemo(() => {
    if (!sources) return [{}, 0, false]
    const maxLine = platform === 'mobile' ? 2 : 3
    // 周视图
    if (view === 'week') {
      const sortedList = orderBy(sources, 'custom.createdAt', 'desc')
      // 计算坐标
      const list = formatWeekViewFullDayCalendarEvents(sortedList, dates)
      // 取出计算出来的最大 top
      const maxHeight = Math.max(...list.map(v => v.style.top))
      // 那么，折叠区的高度是：最大高度 + 日程高度（20）
      const height = maxHeight + FULL_DAY_ITEM_HEIGHT
      // 是否需要折叠：显示的条数 > 3 条
      const needFold =
        height >
        FULL_DAY_ITEM_HEIGHT * maxLine +
          FULL_DAY_ITEM_MARGIN_TOP * (maxLine - 1)

      return [{ all: list }, height, needFold]
    }

    // 日视图，先筛选出选中天的全天日程
    const sourceData = filterSomeDaySources(sources, date)
    // 返回携带有坐标值的 events
    const list = formatDayViewFullDayCalendarEvents(sourceData)
    // 折叠区的高度是：日程高度* 日程个数 + 边距 * (日程个数 - 1)
    const height =
      list.length * FULL_DAY_ITEM_HEIGHT +
      (list.length - 1) * FULL_DAY_ITEM_MARGIN_TOP

    // 是否需要折叠：显示的条数 > 3 条
    const needFold = list.length > maxLine
    return [{ [date]: list }, height, needFold]
  }, [sources, platform, view, date, dates])

/**
 * 当前登录用户是否是课程的任课老师
 *
 * @param currentReflectionId - 当前登录用户 id
 * @param initiatorIds - 管理员 id 列表
 * @returns 是否
 */
export const isEventManager = (
  currentReflectionId: number,
  initiatorIds: number[],
) => initiatorIds.includes(currentReflectionId)

/**
 * 是否是课程的任课老师
 *
 * @param initiatorIds - initiatorIds
 * @returns 是否是管理员
 */
export const useIsEventManager = (initiatorIds: number[]) => {
  const reflection = useCurrentReflection()
  const currentReflectionId = reflection.id
  return isEventManager(currentReflectionId, initiatorIds)
}

/**
 * 格式化时间范围
 *
 * @param param - 参数
 * @param param.startTime - 开始时间
 * @param param.endTime - 结束时间
 * @returns 格式化后的时间范围
 */
export const formatTimeRange = ({
  startTime,
  endTime,
}: {
  startTime: string
  endTime: string
}) => {
  return `${startTime}-${endTime}`
}

/**
 * 格式化折叠周视图事件源
 *
 * @returns Fn
 */
export const useFoldGroupedNonFullDayEvents =
  () => (nonFullDayEvents: { [key: string]: OriginCalendarEvent[] }) => {
    const foldNonFullDayEvents = Object.entries(nonFullDayEvents).reduce(
      (obj, [key, value]) => {
        const groupedEvents = groupBy(value, item =>
          formatTimeRange({ startTime: item.startTime, endTime: item.endTime }),
        )

        const groupedEventCounts = mapValues(groupedEvents, v => v.length)

        const notLessonEvents =
          value?.filter(({ type }) => type !== CalendarEventType.Lesson) || []

        const noFoldValue =
          value?.filter(
            ({ type, startTime, endTime }) =>
              type === CalendarEventType.Lesson &&
              groupedEventCounts[formatTimeRange({ startTime, endTime })] < 3,
          ) || []

        const foldValue = value
          ?.filter(
            ({ type, startTime, endTime }) =>
              type === CalendarEventType.Lesson &&
              groupedEventCounts[formatTimeRange({ startTime, endTime })] > 2,
          )
          .map(v => {
            const timeStr = formatTimeRange({
              startTime: v.startTime,
              endTime: v.endTime,
            })

            const count = groupedEventCounts[timeStr]

            return {
              ...v,
              title: $t('{count}节走班课', {
                count,
              }),
              type: CalendarEventType.LessonFold,
              initiators: [],
              subject: {},
              custom: {
                id: v.custom?.id || 0,
                lessonName: v.custom?.['lessonName'],
              },
              // 折叠掉的事件
              children: groupedEvents?.[timeStr] || undefined,
            }
          })

        const uniqFoldValue = uniqBy(foldValue, ({ startTime, endTime }) =>
          formatTimeRange({
            startTime,
            endTime,
          }),
        )

        obj[key] = [...notLessonEvents, ...noFoldValue, ...uniqFoldValue]

        return obj
      },
      {} as { [key: string]: OriginCalendarEvent[] },
    )

    return foldNonFullDayEvents
  }

import { SchoolExtLocaleTextCategoryEnum } from './extends/school-ext-locale-text-category-enum'

export interface SchoolExtLocaleTextInterface {
  /**
   * 源文本
   */
  src: string
  /**
   * 英文翻译
   */
  dstEn: string
  category?: SchoolExtLocaleTextCategoryEnum
  /**
   * 主键 ID
   */
  id: number
}

export class SchoolExtLocaleText implements SchoolExtLocaleTextInterface {
  /**
   * 源文本
   */
  src: string
  /**
   * 英文翻译
   */
  dstEn: string
  category?: SchoolExtLocaleTextCategoryEnum
  /**
   * 主键 ID
   */
  id: number

  constructor(props: SchoolExtLocaleTextInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      src: _src,
      dstEn: _dstEn,
      category: _category,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.src = _src
    this.dstEn = _dstEn
    this.category = _category
    this.id = _id
  }

  static propKeys = ['src', 'dstEn', 'category', 'id']
}

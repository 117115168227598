export interface AccConvertPlanRuleInterface {
  /**
   * 素养分数
   */
  score: string
  /**
   * 得分率百分比（如果是 60% ，则传 60 ）
   */
  percent: string
  /**
   * 低于指定分数，默认为 false （表示高于或等于分数）
   * 比如及格线规则为 3 分以下，则 score=3,is_below=true
   */
  isBelow?: boolean
}

export class AccConvertPlanRule implements AccConvertPlanRuleInterface {
  /**
   * 素养分数
   */
  score: string
  /**
   * 得分率百分比（如果是 60% ，则传 60 ）
   */
  percent: string
  /**
   * 低于指定分数，默认为 false （表示高于或等于分数）
   * 比如及格线规则为 3 分以下，则 score=3,is_below=true
   */
  isBelow?: boolean

  constructor(props: AccConvertPlanRuleInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      score: _score,
      percent: _percent,
      isBelow: _isBelow,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.score = _score
    this.percent = _percent
    this.isBelow = _isBelow
  }

  static propKeys = ['score', 'percent', 'isBelow']
}

/**
 * @file 成绩反思消息
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

import icon from './subject.png'

registerDomainMessage({
  type: MessageDomainEnum.SubjectSelection,
  getLabel: () => $t('选科'),
  labelStyle: calculateLabelStyle('#9E37B4', '#F7E9FA'),
  icon,
  messages: [
    'uncompleted_notice_for_student',
    'uncompleted_notice_for_mentor',
    'uncompleted_notice_for_admin_class_teacher',
    'uncompleted_notice_for_guardian',
  ],
})

/**
 * @file 双端共用的重要通知配置
 */
import { uniq, useCountDown, useForceUpdate } from '@seiue/util'
import React, { useMemo, useRef } from 'react'

import { useCounters, CounterSubjectEnum } from 'packages/features/counter'
import { useReadNotice } from 'packages/features/messages/hooks'
import { convertMessageToNotification } from 'packages/features/notices/utils'
import { useCurrentReflection } from 'packages/features/sessions'
import { $t } from 'packages/locale'
import {
  Message,
  ReceiptedTypeEnum,
  receivedMessageApi$findAll,
} from 'packages/sdks-next/chalk'

/**
 * 获取重要弹窗的标题，当前显示通知的 Conversation id 等内容
 * 使用计数器实现，仅获取重要通知的数量和 id，降低首屏需要获取的数据大小
 *
 * @returns 重要通知弹窗设置
 */
export const useImportantNoticeModalSettings = () => {
  const { counterSubjectIds, loading, refetching, refresh, skip } = useCounters(
    CounterSubjectEnum.ImportantNotifications,
  )

  // 记录当下显示重要通知的 id，以判断显示的重要通知总数
  const importantIdsRef = useRef<string[]>([])

  // why idsRef，ids 需要立即响应 counterSubjectIds 的变化
  const idsRef = useRef<string[]>([])

  const [forceUpdate] = useForceUpdate()

  /*
   * why useMemo？
   * useMemo useMemo 会立即调用，即 counterSubjectIds 变化能立即变更 idsRef （而 useEfect 会在本次执行的最后调用）
   */
  useMemo(() => {
    idsRef.current = counterSubjectIds

    if (counterSubjectIds.length === 0) {
      importantIdsRef.current = []
    } else {
      importantIdsRef.current = uniq([
        ...importantIdsRef.current,
        ...counterSubjectIds,
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterSubjectIds.join(',')])

  const isDone = !idsRef.current.length && !loading

  const goNext = () => {
    const nextIds = [...idsRef.current]

    nextIds.shift()

    idsRef.current = nextIds

    forceUpdate()
  }

  const currentConversationId = idsRef.current[0]

  let title = $t('重要通知')

  if (importantIdsRef.current.length > 1) {
    title += `（${
      importantIdsRef.current.findIndex(id => currentConversationId === id) + 1
    }/${importantIdsRef.current.length}）`
  }

  return {
    title,
    currentConversationId,
    isDone,
    next: goNext,
    loading,
    refetching,
    refresh,
    skip,
  }
}

/**
 * 通知回执相关
 *
 * @param param0 - 参数
 * @param param0.receiptApi - 回执 api
 * @param param0.currentNotice - 当前通知
 * @param param0.callback - 回执成功后的回调
 * @returns 通知回执相关
 */
export const useImportantNoticeRecepit = ({
  receiptApi: receipt,
  currentNotice,
  callback,
}: {
  receiptApi: (id: string, bySign?: boolean) => void
  currentNotice?: Message | null
  callback: () => void
}) => {
  const currentNoticeId = currentNotice?.id
  const isNeedSignReceiptNotice =
    currentNotice?.receiptedType === ReceiptedTypeEnum.Signature

  const isReceipted = !!currentNotice?.receipted

  const {
    seconds: receiptCountdown,
    isCounting,
    startCounting,
    stopCounting,
  } = useCountDown({ seconds: 10 })

  React.useEffect(() => {
    if (currentNoticeId) {
      stopCounting()
      startCounting()
    }

    // eslint-disable-next-line
  }, [currentNoticeId])

  let receiptButtonText = isNeedSignReceiptNotice
    ? $t('签名回执')
    : $t('确认回执')

  if (isReceipted) {
    receiptButtonText = $t('已回执')
  } else if (isCounting) {
    receiptButtonText = `${receiptButtonText} ${receiptCountdown} s`
  }

  const sendReceipt = async () => {
    if (!currentNoticeId) return

    if (isReceipted) return

    await receipt(currentNoticeId, isNeedSignReceiptNotice)

    callback?.()
  }

  return {
    sendReceipt,
    receiptButtonText,
    receiptCountdown: isReceipted ? 10 : receiptCountdown,
  }
}

/**
 * 根据 conversationID，来获取对应的通知内容
 *
 * @param noticeConversationId - 通知 conversationID
 * @returns 通知内容
 */
export const useLoadImportantNotice = (noticeConversationId = '') => {
  const { id: rid } = useCurrentReflection()

  const read = useReadNotice()
  const { data: notices, loading } = receivedMessageApi$findAll.useApi(
    {
      sort: '-published_at',
      conversationId: noticeConversationId,
      tryExpand: ['discussion'],
      'owner.id': rid,
    },
    {
      disable: !noticeConversationId,
    },
  )

  const currentNotice = notices?.[0]

  const noticeForPreview = React.useMemo(() => {
    if (!currentNotice) return undefined

    return convertMessageToNotification(currentNotice)
  }, [currentNotice])

  React.useEffect(() => {
    if (currentNotice && !currentNotice.readed) {
      read(currentNotice.id)
    }
  }, [currentNotice, read])

  return {
    notice: currentNotice,
    noticeForPreview,
    loading,
  }
}

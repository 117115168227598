import {
  ExamScheduleNoticeSettingDetail,
  ExamScheduleNoticeSettingDetailInterface,
} from './exam-schedule-notice-setting-detail'

export interface ExamScheduleNoticeSettingsInterface {
  /**
   * 考生通知设置
   */
  examinee: ExamScheduleNoticeSettingDetailInterface
  /**
   * 监考通知设置 允许不设置
   */
  examiner?: ExamScheduleNoticeSettingDetailInterface | null
  patroller?: ExamScheduleNoticeSettingDetailInterface | null
  /**
   * 消息定时发送时间
   */
  publishedAt?: string
}

export class ExamScheduleNoticeSettings
  implements ExamScheduleNoticeSettingsInterface
{
  /**
   * 考生通知设置
   */
  examinee: ExamScheduleNoticeSettingDetail
  /**
   * 监考通知设置 允许不设置
   */
  examiner?: ExamScheduleNoticeSettingDetail | null
  patroller?: ExamScheduleNoticeSettingDetail | null
  /**
   * 消息定时发送时间
   */
  publishedAt?: string

  constructor(props: ExamScheduleNoticeSettingsInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      examinee: _examinee,
      examiner: _examiner,
      patroller: _patroller,
      publishedAt: _publishedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.examinee = _examinee
      ? new ExamScheduleNoticeSettingDetail(_examinee)
      : _examinee
    this.examiner = _examiner
      ? new ExamScheduleNoticeSettingDetail(_examiner)
      : _examiner
    this.patroller = _patroller
      ? new ExamScheduleNoticeSettingDetail(_patroller)
      : _patroller
    this.publishedAt = _publishedAt
  }

  static propKeys = ['examinee', 'examiner', 'patroller', 'publishedAt']
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'

const buildConfig = createConfigBuilder('vnas')

export const defaultApi$deleteKlassScores = {
  config(
    id: number,
    idIn: string,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(options, 'delete', '/klass/items/{id}/scores', {
      id,
      idIn,
    })
  },

  /**
   * @summary 批量删除分数
   * @param id 评价项 ID
   * @param idIn 分数 ID
   * @param [options]
   * @return AxiosResponsePromise<null>
   */

  api(
    id: number,
    idIn: string,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<null> {
    return axios(defaultApi$deleteKlassScores.config(id, idIn, options)) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({ id, idIn }: { id: number; idIn: string }) =>
        defaultApi$deleteKlassScores.api(id, idIn),
      mutationOptions,
    )
  },
}

// @ts-ignore
defaultApi$deleteKlassScores.api._name_ = `Default.deleteKlassScores`

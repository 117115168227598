import { ClassWeekTypeEnum } from './class-week-type-enum'

export interface ClassWeekInterface {
  weekType: ClassWeekTypeEnum
  startAt: number
  endAt: number
}

export class ClassWeek implements ClassWeekInterface {
  weekType: ClassWeekTypeEnum
  startAt: number
  endAt: number

  constructor(props: ClassWeekInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      weekType: _weekType,
      startAt: _startAt,
      endAt: _endAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.weekType = _weekType
    this.startAt = _startAt
    this.endAt = _endAt
  }

  static propKeys = ['weekType', 'startAt', 'endAt']
}

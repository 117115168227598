/**
 * @file register venue messages
 */

import { PluginEnum } from 'packages/feature-utils/plugins'
import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Venue,
  getLabel: () => $t('场地预约'),
  labelStyle: calculateLabelStyle('#c4f0e6', '#4b8678'),
  icon: `plugin.${PluginEnum.Venue}`,
  messages: [
    'order_canceled',
    'order_time_canceled',
    'order_violation',
    'order_approved',
    'order_rejected',
    'follower_trigger',
  ],
})

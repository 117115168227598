/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import type { GetRolesByReflectionQuery } from './get-roles-by-reflection-query'

const buildConfig = createConfigBuilder('chalk')

export const groupApi$getRolesByReflection = {
  config(
    groupId: number,
    rid: number,
    getRolesByReflectionQuery?: GetRolesByReflectionQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/group/groups/{groupId}/reflections/{rid}/roles',
      {
        groupId,
        rid,
      },
      getRolesByReflectionQuery,
    )
  },

  /**
   * @summary 获取成员在群组里的所有角色
   * @param groupId 群组ID
   * @param rid reflection id
   * @param [getRolesByReflectionQuery]
   * @param [groupQuery.status]
   * @param [options]
   * @return AxiosResponsePromise<string[]>
   */

  api(
    groupId: number,
    rid: number,
    getRolesByReflectionQuery?: GetRolesByReflectionQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<string[]> {
    return axios(
      groupApi$getRolesByReflection.config(
        groupId,
        rid,
        getRolesByReflectionQuery,
        options,
      ),
    ) as any
  },

  useApi<TSelected = string[]>(
    {
      groupId,
      rid,
      query,
    }: {
      groupId: number
      rid: number
      query?: GetRolesByReflectionQuery
    },
    queryOptions?: QueryOptionsWithSelect<string[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<string[], TSelected>(
      groupApi$getRolesByReflection.api,
      'Group.getRolesByReflection',
      queryOptions,
      groupId,
      rid,
      query,
    )
  },
}

// @ts-ignore
groupApi$getRolesByReflection.api._name_ = `Group.getRolesByReflection`

import { Term as TermBase, TermInterface } from '../term'

export type { TermInterface } from '../term'

/**
 *
 */
export class Term extends TermBase {
  /**
   * constructor
   *
   * @param props - props
   */
  constructor(props: TermInterface) {
    super(props)
    // term 的显示默认取 name 字段，某些 term (比如家长角色)的做了多语言的处理，这个时候就优先取 label 字段
    this.name = props.label || props.name
  }
}

/**
 * @file
 * 对于后端使用非 form 服务提供的数据（即 {key：value} 格式）
 * 提供的转换 helper
 * 只针对 legoForm 提供的题目类型，各业务的题目需要自行解析
 */

import {
  camelCase,
  compact,
  find,
  flatten,
  isArray,
  isString,
  mapKeys,
} from '@seiue/util'

import { LegoFormFieldAnswers } from 'packages/components/LegoForm/Renderer/types'
import {
  LegoFormAnswer,
  LegoFormField,
  LegoFormFieldTypeEnum,
} from 'packages/components/LegoForm/types'
import { FormAnswer } from 'packages/sdks-next/form'

export const LEGO_FLAG = 'lego_field'

// 阻止引用返回扩大，把 BizFieldPrefix 放一份在这里
const BizFieldPrefix = 'biz_'

/**
 * 判断是否为 LegoForm 内置的 field
 *
 * @param name - 字段 name
 * @returns 是否为 LegoForm 内置的 field
 */
export const isLegoFormBuiltInField = (name?: string) =>
  !!name?.startsWith(LEGO_FLAG)

/**
 * 按类型从 LegoFormFieldAnswers 提取出后端需要的 value
 * 将由于逻辑跳转而被隐藏的题目的答案置为空字符串或空数组，用于覆盖之前的答案
 *
 * @param f - 字段和答案
 * @param optionByLabel - 是否从 option 的 label 上取值，默认取 option 的 name
 * @returns 后端需要的 value
 */
export const extractValueFromFieldAnswer = (
  f: LegoFormFieldAnswers,
  optionByLabel?: boolean,
) => {
  const singleAnswer: LegoFormAnswer | undefined = f.answers[0]

  if (f.field.name) {
    switch (f.field.type) {
      case LegoFormFieldTypeEnum.Checkbox: {
        const nextAnswer = f.answers.filter(a => !a.deleted)

        if (nextAnswer.length < 1) return []

        return nextAnswer
          ?.map(a => {
            if (a.name === 'other') {
              return `${a.name}:${a.label}`
            }

            return optionByLabel ? a.label : a.name
          })
          .filter(v => !!v)
      }

      case LegoFormFieldTypeEnum.Select:
      case LegoFormFieldTypeEnum.Radio:
        if (singleAnswer?.deleted) return ''
        if (singleAnswer?.name === 'other') {
          return `${singleAnswer.name}:${singleAnswer.label}`
        }

        return optionByLabel ? singleAnswer?.label : singleAnswer?.name

      case LegoFormFieldTypeEnum.Textarea:
      case LegoFormFieldTypeEnum.Input:
      case LegoFormFieldTypeEnum.Number:
      case LegoFormFieldTypeEnum.Money:
      case LegoFormFieldTypeEnum.DateTime:
        if (singleAnswer?.deleted) return ''
        return singleAnswer?.label || ''
      case LegoFormFieldTypeEnum.DateRange:
        if (singleAnswer?.deleted) return ''
        return {
          start: singleAnswer?.attributes?.dateRangeStart,
          end: singleAnswer?.attributes?.dateRangeEnd,
        }
      case LegoFormFieldTypeEnum.Attachment:
        if (!singleAnswer || singleAnswer?.deleted) return []
        if (f.answers?.length && singleAnswer != null) {
          return singleAnswer?.attributes?.attachments
        }

        break
      case LegoFormFieldTypeEnum.Score:
        if (singleAnswer?.deleted) return ''
        return singleAnswer?.score

      case LegoFormFieldTypeEnum.Address:
        if (singleAnswer?.deleted) return ''
        return singleAnswer
      case LegoFormFieldTypeEnum.Cascader:
        return singleAnswer?.label
      default:
        return undefined
    }
  }

  return undefined
}

/**
 * 按类型从 LegoFormFieldAnswers 提取出后端需要的 formattedValue
 * 目前仅会作用到 Select 类型问题答案的展示上
 *
 * @param f - 字段和答案
 * @returns formattedValue
 */
export const extraFormattedValueFromFieldAnswer = (f: LegoFormFieldAnswers) => {
  const singleAnswer = f.answers[0]

  if (f.field.name && f.field.container?.optionSource) {
    switch (f.field.type) {
      case LegoFormFieldTypeEnum.Select:
        if (singleAnswer?.deleted) return ''
        if (singleAnswer?.name === 'other') {
          return `${singleAnswer.name}:${singleAnswer.label}`
        }

        return singleAnswer?.label
      case LegoFormFieldTypeEnum.Cascader:
        return singleAnswer?.label
      default:
        return undefined
    }
  }

  return undefined
}

/**
 * 将 legoform 提交时暴露的 values，转换为普通的对象
 *  提交 form 答案时使用
 *
 * @param values - 表单值
 * @param optionByLabel - 是否从 option 的 label 上取值，默认取 option 的 name
 * @returns 普通的对象
 */
export const transformAnswersToPlainObj = (
  values: LegoFormFieldAnswers[],
  optionByLabel?: boolean,
) => {
  if (!values) return {}
  const formValues = values.filter((v: any) =>
    isLegoFormBuiltInField(v.field.name),
  )

  const formAnswers = formValues.reduce(
    (filled: { [key: string]: any }, f: LegoFormFieldAnswers) => {
      const key = f.field.name

      if (key) {
        return {
          ...filled,
          [key]: extractValueFromFieldAnswer(f, optionByLabel),
        }
      }

      return filled
    },
    {},
  )

  return formAnswers
}

/**
 * 根据表单配置 LegoFormField 和后端提供的值，来提取 form 需要的 answer
 *
 * @param field - 字段
 * @param value - 答案
 * @returns form 需要的 answer
 */
export const extractAnswerFromValue = (field: LegoFormField, value: any) => {
  let fieldAnswer: any
  if (!value) return undefined

  // 置空已经“被删除”（由于逻辑跳转而被隐藏）的题目
  if (field.deleted) return ''

  switch (field.type) {
    case LegoFormFieldTypeEnum.Number:
    case LegoFormFieldTypeEnum.Textarea:
    case LegoFormFieldTypeEnum.Input:
    case LegoFormFieldTypeEnum.Money:
    case LegoFormFieldTypeEnum.Cascader:
    case LegoFormFieldTypeEnum.DateTime:
      fieldAnswer = { label: value }

      break
    case LegoFormFieldTypeEnum.Select:
    case LegoFormFieldTypeEnum.Radio: {
      let name = value
      let label =
        field.container?.options?.find(opt => opt.name === name)?.label || ''

      if (isString(value) && value.startsWith('other:')) {
        ;[name, label] = value.split(':')
      }

      fieldAnswer = {
        name: name === 'other' ? 'other' : name,
        label: label || '',
      }

      break
    }

    case LegoFormFieldTypeEnum.Checkbox:
      fieldAnswer = (value as string[]).map(v => {
        let name = v
        let label =
          field.container?.options?.find(opt => opt.name === name)?.label || ''

        if (isString(v) && v.startsWith('other:')) {
          ;[name, label] = v.split(':')
        }

        return {
          name: name === 'other' ? 'other' : name,
          label: label || '',
        }
      })

      break
    case LegoFormFieldTypeEnum.Attachment:
      fieldAnswer = {
        attributes: { attachments: value },
      }

      break
    case LegoFormFieldTypeEnum.Score:
      fieldAnswer = { score: value }

      break

    case LegoFormFieldTypeEnum.Address:
      fieldAnswer = value

      break

    case LegoFormFieldTypeEnum.DateRange:
      fieldAnswer =
        value?.['start'] && value?.['end']
          ? {
              attributes: {
                dateRangeStart: value['start'],
                dateRangeEnd: value['end'],
              },
            }
          : null

      break
    default:
      break
  }

  return fieldAnswer
}

/**
 * 将后端提供的对象转换为 form 需要的 answers
 * 填写 form 时使用
 *
 * @param values - 表单值
 * @param formTemplateField - 表单字段列表
 * @returns form 需要的 answers
 */
export const transformValueToFormAnswers = (
  values: { [key: string]: any },
  formTemplateField: LegoFormField[],
) => {
  const regularFormAnswer: FormAnswer[] = []

  formTemplateField.forEach((f, idx) => {
    const key = f.name
    if (key) {
      const value = findValueByFormattedKey(values, key)

      if (isLegoFormBuiltInField(key)) {
        const baseAnswer = {
          id: f.sort ?? idx,
          formId: f.pid || 0,
          formTemplateFieldId: f.sort ?? idx,
          name: '',
          label: '',
          score: '0',
        }

        const formAnswer = extractAnswerFromValue(f, value)

        if (isArray(formAnswer)) {
          const arrayAnswer = formAnswer.map((fa: any) => ({
            ...baseAnswer,
            ...fa,
          }))

          regularFormAnswer.push(...arrayAnswer)
        } else {
          regularFormAnswer.push({
            ...baseAnswer,
            ...formAnswer,
          })
        }
      }
    }
  })

  return regularFormAnswer
}

/**
 * 将后端返回的对象，以及 formfield 中对应的题目的 key 格式化为统一的 lowercase
 * 并以该 key 获取值
 *
 * @param value - 表单值
 * @param key - 字段 key
 * @returns key 对应的 value
 */
export const findValueByFormattedKey = (
  value: { [key: string]: any },
  key: string,
) => {
  const formattedKey = camelCase(key).toLowerCase()
  const formattedTarget = mapKeys(value, (v, k) => camelCase(k).toLowerCase())
  return formattedTarget[formattedKey]
}

const isFieldInTargetedBy = (fieldName: string, targetedBy?: string) => {
  if (!fieldName) return false

  return targetedBy?.split(',').some(name => {
    if (name === fieldName) {
      return true
    }

    return name.replace(BizFieldPrefix, '') === fieldName
  })
}

/**
 * 获取控制某道题目显示与隐藏的题目
 *
 * @param param - 参数
 * @param param.fieldsWithAnswers - 所有题目
 * @param param.field - 当前题目
 * @returns 控制某道题目显示与隐藏的题目
 */
export const getTargetFieldWidthAnswers = ({
  fieldsWithAnswers,
  field,
}: {
  fieldsWithAnswers: LegoFormFieldAnswers[]
  field: LegoFormField
}) => {
  // 控制当前题目显示的题目|跳转到当前题目的题目
  const targetFieldAnswers = fieldsWithAnswers.filter(item => {
    if (!item?.field?.name) return false

    return isFieldInTargetedBy(item.field.name, field.targetedBy)
  })

  return targetFieldAnswers
}

/**
 * 获取控制某道题目显示与隐藏呃选项配置
 *
 * @param param - 参数
 * @param param.targetFieldAnswers - 控制当前题目显示的题目|跳转到当前题目的题目
 * @param param.field - 当前题目
 * @returns options - 选项配置
 */
export const getTargetOptionConfig = ({
  targetFieldAnswers,
  field,
}: {
  targetFieldAnswers: LegoFormFieldAnswers[]
  field: LegoFormField
}) => {
  const targetOptionNames = flatten(
    targetFieldAnswers.map(targetFieldAnswer => {
      return compact(
        targetFieldAnswer.field?.container?.options
          ?.filter(option =>
            isFieldInTargetedBy(field.name || '', option.targetName),
          )
          .map(option => ({
            fieldName: targetFieldAnswer.field.name,
            optionName: option.name,
          })),
      )
    }),
  )

  return targetOptionNames
}

/**
 * 已作答的答案中是否包含目标字段的选项
 *
 * @param param - 参数
 * @param param.targetFieldAnswers - 目标字段的答案
 * @param param.targetOptionNames - 目标选项
 * @returns boolean
 */
export const existTargetAnswer = ({
  targetFieldAnswers,
  targetOptionNames,
}: {
  targetFieldAnswers: LegoFormFieldAnswers[]
  targetOptionNames: {
    fieldName?: string
    optionName?: string
  }[]
}) => {
  const result = targetFieldAnswers.some(targetFieldAnswer =>
    targetFieldAnswer.answers.some(
      answer =>
        !answer.deleted &&
        answer.name &&
        find(
          targetOptionNames,
          item =>
            item?.fieldName === targetFieldAnswer.field.name &&
            item?.optionName === answer.name,
        ),
    ),
  )

  return result
}

/**
 * 校验是否存在目标选项的答案
 *
 * @param param - 参数
 * @param param.fieldsWithAnswers - 所有题目
 * @param param.field - 当前题目
 * @returns boolean
 */
export const validateExistTargetAnswer = ({
  fieldsWithAnswers,
  field,
}: {
  fieldsWithAnswers: LegoFormFieldAnswers[]
  field: LegoFormField
}) => {
  // 控制当前题目显示的题目|跳转到当前题目的题目
  const targetFieldAnswers = getTargetFieldWidthAnswers({
    fieldsWithAnswers,
    field,
  })

  if (!targetFieldAnswers?.length) return false

  // 控制当前题目显示的题目|跳转到当前题目的题目中跳转到当前题目的选项
  const targetOptionNames = getTargetOptionConfig({
    targetFieldAnswers,
    field,
  })

  if (!targetOptionNames?.length) return false

  const isExist = existTargetAnswer({ targetFieldAnswers, targetOptionNames })

  return isExist
}

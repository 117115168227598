import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Group,
  getLabel: () => $t('讨论'),
  labelStyle: calculateLabelStyle('#FFF5E3', '#C47A00'),
  messages: [
    'discussion_replied',
    'discussion_commented',
    'discussion_comment_archived',
    'discussion_reply_archived',
    'discussion_member_banned_to_post',
    'discussion_member_banned_to_post_canceled',
    'discussion_topic_archived',
  ],
})

/**
 * @file 注册约谈消息
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import { registerMessageNavigator } from 'packages/features/messages/utils'

import { NoticeTypes } from './message-type'

registerMessageNavigator(
  [
    `${MessageDomainEnum.Chat}.${NoticeTypes.Created}`,
    `${MessageDomainEnum.Chat}.${NoticeTypes.Changed}`,
    `${MessageDomainEnum.Chat}.${NoticeTypes.Apply}`,
    `${MessageDomainEnum.Chat}.${NoticeTypes.GuardianSubmitted}`,
  ],
  message => {
    const attributes = message.attributes as {
      chatId: number
      instanceId?: number
    }

    const chatId = attributes?.chatId

    if (!chatId || !attributes.instanceId) return null

    return {
      modalName: 'Plugin.Chat.ChatDetailModal',
      options: { id: chatId, chatInstanceId: attributes.instanceId },
    }
  },
)

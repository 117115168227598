import { ZoomMode, getCompressImageUrl, seiueFiles } from '@seiue/file'
import { getPaletteColorByIndex } from '@seiue/theme'
import { userAgent } from '@seiue/ua'
import { isReactNative } from '@seiue/util'

import { RoleEnum } from 'packages/sdks-next/chalk'

import { AvatarProps, AvatarReflection, AvatarTypeEnum } from './types'

type GetAvatarDataParams = {
  /**
   * 判断优先级：1st；优先展示照片还是头像
   */
  forceFirst?: 'avatar' | 'photo'
  /**
   * 判断优先级：2nd；本人头像优先
   */
  isMySelf?: boolean
  /**
   * 判断优先级：3rd；在后台页面，且被查看者在查看者的管理范围内，则照片优先
   */
  hasPhotoAccessFromAdmin?: boolean
  /**
   * 判断优先级：4th；查看者与被查看者相同角色，优先展示头像
   */
  isSameRole?: boolean
  /**
   * 判断优先级：5th；被查看的用户角色里照片属于基础字段，则照片优先
   */
  isPhotoBaseField?: boolean
  /**
   * 判断优先级：6th；被查看者为学生且当前角色是否有被查看者照片的查看权限
   */
  hasStudentPhotoAccess?: boolean
  /**
   * 被查看的用户
   */
  targetReflection?: AvatarReflection
}

/**
 * 按照头像 => 文字的顺序返回头像信息
 *
 * @param reflection - 用户
 * @returns 头像信息
 */
const avatarFirst = (reflection: AvatarReflection) => {
  if (reflection.avatar) {
    return {
      type: AvatarTypeEnum.Avatar,
      url: seiueFiles.hashToUrl(reflection.avatar),
    }
  }

  return { type: AvatarTypeEnum.Text, url: '' }
}

/**
 * 按照照片 => 头像 => 文字的顺序返回头像信息
 *
 * @param reflection - 用户
 * @returns 头像信息
 */
const photoFirst = (reflection: AvatarReflection) => {
  if (reflection.photo) {
    return {
      type: AvatarTypeEnum.Photo,
      url: seiueFiles.hashToUrl(reflection.photo),
    }
  }

  return avatarFirst(reflection)
}

/**
 * 获取头像类型和地址，头像展示逻辑如下
 * 参数有优先级判断，优先级数字大的会被小的给覆盖，请仔细阅读
 *
 * 展示逻辑的大体优先级为：照片 => 头像 => 文字
 * 后台页面查看者有目标用户读权限时，直接开放照片查看
 * 查看者与被查看者相同角色时(教师看教师/学生看学生等)，头像 => 文字
 * 查看者与被查看者不同角色时(教师看学生/或者反之)，
 *  - 被查看者为学生时，判断学生档案权限设置 + 学生字段安全等级，决定照片还是头像优先
 *  - 被查看者不是学生时，判断安全等级决定展示顺序
 *
 * @param {GetAvatarDataParams} param0 - 参数对象
 * @returns 类型和地址
 */
export const getAvatarData = ({
  isMySelf,
  isSameRole,
  targetReflection,
  isPhotoBaseField,
  forceFirst,
  hasStudentPhotoAccess,
  hasPhotoAccessFromAdmin,
}: GetAvatarDataParams) => {
  if (!targetReflection) return { type: AvatarTypeEnum.Text, url: '' }

  // 以下 if 判断的有优先级顺序，请勿随意调整

  // 指定优先则按其展示
  if (forceFirst) {
    if (forceFirst === 'photo') return photoFirst(targetReflection)
    return avatarFirst(targetReflection)
  }

  // 查看自己，头像优先
  if (isMySelf) {
    return avatarFirst(targetReflection)
  }

  // 后台页面且被查看用户在当前用户管理范围内，照片优先
  if (hasPhotoAccessFromAdmin) {
    return photoFirst(targetReflection)
  }

  // 相同角色头像优先
  if (isSameRole) {
    return avatarFirst(targetReflection)
  }

  // 照片为基础字段时，照片优先
  if (isPhotoBaseField) {
    return photoFirst(targetReflection)
  }

  // 被查看者为学生且查看者身份有访问对应字段的查看权限
  if (hasStudentPhotoAccess) {
    return photoFirst(targetReflection)
  }

  return avatarFirst(targetReflection)
}

/**
 * 判断两个 reflection 是否为相同角色
 *
 * @param reflection1 - reflection
 * @param reflection2 - reflection
 * @returns 是否为相同角色
 */
export const isSameRoleFn = (
  reflection1: AvatarProps['reflection'],
  reflection2: AvatarProps['reflection'],
) => {
  return (
    !!reflection1?.role &&
    !!reflection2?.role &&
    reflection1.role === reflection2.role
  )
}

/**
 * 判断 reflection 是否为学生
 *
 * @param target - reflection
 * @returns 是否为学生
 */
export const isRoleStudent = (target: AvatarProps['reflection']) => {
  return target?.role === RoleEnum.Student
}

/**
 * 获取头像的地址
 *
 * @param args - args
 * @param args.type - 头像类型
 * @param args.size - 头像大小
 * @param args.url - 头像地址
 * @param args.reflection - 用户
 * @param args.usePaletteColor - 是否使用调色盘的颜色（仅对文字头像生效）
 * @returns 头像地址
 */
export const getAvatarSrc = ({
  type,
  size,
  url,
  reflection,
  usePaletteColor,
}: {
  type: AvatarTypeEnum
  size: number
  url: string
  reflection?: AvatarReflection
  usePaletteColor?: boolean
}) =>
  type === AvatarTypeEnum.Avatar || type === AvatarTypeEnum.Photo
    ? getCompressImageUrl(url, {
        zoom: [ZoomMode.Fill, size, size],
      })
    : getTextAvatar({ size, reflection, usePaletteColor })

/**
 * 绘制文字头像
 *
 * @param args - args
 * @param args.name - 文字名称
 * @param args.pinyin - 文字的拼音
 * @param args.role - 角色
 * @param args.size - 头像大小
 * @param args.targetCanvas - 所在的 canvas 容器
 * @param args.pixelRatio - 像素比例
 * @param args.usePaletteColor - 是否使用调色盘的颜色（仅对文字头像生效）
 * @returns 文字头像
 */
export const drawTextAvatar = ({
  name = '?',
  pinyin,
  role,
  size,
  targetCanvas,
  pixelRatio = 1,
  usePaletteColor = false,
}: {
  name?: string
  pinyin?: string
  role?: AvatarReflection['role']
  size: number
  targetCanvas: any
  pixelRatio?: number
  usePaletteColor?: boolean
}) => {
  let backgroundColor = ''
  switch (role) {
    case RoleEnum.Teacher:
    case RoleEnum.Shadow:
      backgroundColor = '#669FFF'
      break
    case RoleEnum.Guardian:
      backgroundColor = '#A488F2'
      break
    case RoleEnum.Student:
      backgroundColor = '#43D2B0'
      break
    case RoleEnum.Staff:
      backgroundColor = '#8F93B7'
      break
    default:
      backgroundColor = '#e8e8e8'
  }

  let textColor = '#ffffff'

  const paletteColor = !usePaletteColor
    ? null
    : getPaletteColorByIndex((pinyin || name).charCodeAt(0))

  if (paletteColor) {
    backgroundColor = paletteColor.backgroundLight
    textColor = paletteColor.text
  }

  const isChineseName = /[\u4e00-\u9fa5]/.test(name)
  const displayedName = name
    .slice(...(isChineseName ? [-2] : [0, 1]))
    .toUpperCase()

  const imageSize = size * pixelRatio
  /* eslint-disable no-param-reassign */
  targetCanvas.height = imageSize
  /* eslint-disable no-param-reassign */
  targetCanvas.width = imageSize
  const context = targetCanvas.getContext('2d')
  if (context) {
    context.fillStyle = backgroundColor
    context.fillRect(0, 0, imageSize, imageSize)
    context.fillStyle = textColor
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    context.font = isChineseName
      ? // NOTICE Safari 15 不支持多字体的中文显示，原因未知
        `${
          userAgent.isMobile || isReactNative
            ? `normal 900 ${Math.floor(imageSize * 0.3)}`
            : Math.ceil(imageSize * 0.333)
        }px Helvetica`
      : `${userAgent.isMobile || isReactNative ? `normal 900 ` : ''}${Math.ceil(
          imageSize * 0.55,
        )}px Helvetica, -apple-system, Arial, system-ui`

    context.fillText(displayedName, imageSize / 2, imageSize / 2, imageSize)
  }

  return targetCanvas
}

/**
 * 获取文字头像的背景色和文字色
 *
 * @param args - args
 * @param args.reflection - 用户信息
 * @param args.usePaletteColor - 是否使用调色盘的颜色（仅对文字头像生效）
 * @returns 背景色和文字色
 */
export const getTextAvatarColors = ({
  reflection,
  usePaletteColor,
}: {
  reflection?: AvatarReflection
  usePaletteColor?: boolean
}) => {
  let backgroundColor = ''

  switch (reflection?.role) {
    case RoleEnum.Teacher:
    case RoleEnum.Shadow:
      backgroundColor = '#669FFF'
      break
    case RoleEnum.Guardian:
      backgroundColor = '#A488F2'
      break
    case RoleEnum.Student:
      backgroundColor = '#43D2B0'
      break
    case RoleEnum.Staff:
      backgroundColor = '#8F93B7'
      break
    default:
      backgroundColor = '#e8e8e8'
  }

  let textColor = '#ffffff'

  const paletteColor = !usePaletteColor
    ? null
    : getPaletteColorByIndex(
        (reflection?.pinyin || reflection?.name || '?').charCodeAt(0),
      )

  if (paletteColor) {
    backgroundColor = paletteColor.backgroundLight
    textColor = paletteColor.text
  }

  return {
    textBackgroundColor: backgroundColor,
    textColor,
  }
}

/**
 * 绘制文字头像
 *
 * @param args - args
 * @param args.size - 大小
 * @param args.reflection - 用户信息
 * @param args.usePaletteColor - 是否从取色盘获取颜色
 * @returns canvas config
 */
export const getTextAvatar = ({
  size,
  reflection,
  usePaletteColor,
}: {
  size: number
  reflection?: AvatarReflection
  usePaletteColor?: boolean
}) => {
  // 没有 name 也返回 fallbackAvatar ，因为生成文字头像依赖 name
  const canvas = document.createElement('canvas')

  return drawTextAvatar({
    name: reflection?.name,
    pinyin: reflection?.pinyin,
    role: reflection?.role,
    size,
    targetCanvas: canvas,
    pixelRatio: 2,
    usePaletteColor,
  }).toDataURL()
}

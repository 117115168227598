export interface ScopePatchInterface {
  id?: number | null
  params: object
  description?: string | null
  resourceIds?: number[] | null
}

export class ScopePatch implements ScopePatchInterface {
  id?: number | null
  params: object
  description?: string | null
  resourceIds?: number[] | null

  constructor(props: ScopePatchInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      params: _params,
      description: _description,
      resourceIds: _resourceIds,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
    this.params = _params
    this.description = _description
    this.resourceIds = _resourceIds
  }

  static propKeys = ['id', 'params', 'description', 'resourceIds']
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { RoleEnum } from './role-enum'
import { SecurityGroup, SecurityGroupInterface } from './security-group'

const buildConfig = createConfigBuilder('chalk')

export const reflectionApi$querySecurityGroups = {
  config(
    role: RoleEnum,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(options, 'get', '/reflection/security-groups', {
      role,
    })
  },

  /**
   * @summary Class QuerySecurityGroupsApi
   * @param role
   * @param [options]
   * @return AxiosResponsePromise<SecurityGroup[]>
   */

  api(
    role: RoleEnum,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<SecurityGroup[]> {
    return axios(reflectionApi$querySecurityGroups.config(role, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new SecurityGroup(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = SecurityGroup[]>(
    role: RoleEnum,
    queryOptions?: QueryOptionsWithSelect<SecurityGroup[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<SecurityGroup[], TSelected>(
      reflectionApi$querySecurityGroups.api,
      'Reflection.querySecurityGroups',
      queryOptions,
      role,
    )
  },
}

// @ts-ignore
reflectionApi$querySecurityGroups.api._name_ = `Reflection.querySecurityGroups`

import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'

export interface TeacherHourInterface {
  /**
   * 教师课时
   */
  hour?: number
  /**
   * 教师基础信息
   */
  reflection?: SecuredReflectionInterface
}

export class TeacherHour implements TeacherHourInterface {
  /**
   * 教师课时
   */
  hour?: number
  /**
   * 教师基础信息
   */
  reflection?: SecuredReflection

  constructor(props: TeacherHourInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      hour: _hour,
      reflection: _reflection,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.hour = _hour
    this.reflection = _reflection
      ? new SecuredReflection(_reflection)
      : _reflection
  }

  static propKeys = ['hour', 'reflection']
}

/**
 * @file 课程班设置 hooks
 */

import { createEnumHelper } from '@seiue/util'

import { useCurrentReflection } from 'packages/features/sessions/utils'
import { $t } from 'packages/locale'
import {
  RoleEnum,
  acmApi$queryConfigItemsByGroup,
  acmApi$mutationConfigItemsByGroup,
} from 'packages/sdks-next/chalk'
import {
  SchoolConfigGroupNameEnum,
  ServiceEnum,
} from 'packages/shared-stores/configs'

export enum ClassHiddenTabEnum {
  Home = 'home',
  Attendance = 'attendance',
  Task = 'task',
  Grade = 'grade',
  Member = 'member',
  Schedule = 'schedule',
  Discussion = 'discussion',
}

export const classHiddenTabToLabel = createEnumHelper(() => ({
  [ClassHiddenTabEnum.Home]: $t('主页'),
  [ClassHiddenTabEnum.Attendance]: $t('考勤'),
  [ClassHiddenTabEnum.Task]: $t('任务'),
  [ClassHiddenTabEnum.Grade]: $t('成绩'),
  [ClassHiddenTabEnum.Member]: $t('成员'),
  [ClassHiddenTabEnum.Schedule]: $t('日程'),
  [ClassHiddenTabEnum.Discussion]: $t('讨论'),
}))

/**
 * 获取学生/家长对课程班的可见性设置
 *
 * @param args - args
 * @param args.disable - 是否禁用请求
 * @returns 可见性设置
 */
export const useClassHiddenTabSettings = ({
  disable,
}: { disable?: boolean } = {}) => {
  const currentReflection = useCurrentReflection()

  const { data: classSettings, loading } =
    acmApi$queryConfigItemsByGroup.useApi(
      {
        scope: `school.${currentReflection.schoolId}`,
        service: ServiceEnum.SCMS,
        group: SchoolConfigGroupNameEnum.ClassSettings,
      },
      { staleTime: 10 * 60, disable },
    )

  const hiddenForGuardian = (classSettings?.find(
    item => item.name === 'hidden_for_guardian',
  )?.value ?? []) as string[]

  const hiddenForStudent = (classSettings?.find(
    item => item.name === 'hidden_for_student',
  )?.value ?? []) as string[]

  return {
    hiddenForGuardian,
    hiddenForStudent,
    loading,
  }
}

/**
 * 获取当前登录用户对课程班的可见性设置
 *
 * @returns 可见性设置的函数
 */
export const useCurrentReflectionClassHiddenTabSettings = () => {
  const currentReflection = useCurrentReflection()
  const isStudent = currentReflection.role === RoleEnum.Student
  const isGuardian = currentReflection.role === RoleEnum.Guardian

  // 获取学生/家长对课程班的可见性设置
  const { hiddenForStudent, hiddenForGuardian, loading } =
    useClassHiddenTabSettings({
      disable: !isGuardian && !isStudent,
    })

  const isHidden = (tabName: ClassHiddenTabEnum) => {
    if (isStudent) return hiddenForStudent.includes(tabName)
    if (isGuardian) return hiddenForGuardian.includes(tabName)
    return false
  }

  return {
    isHidden,
    loading,
  }
}

/**
 * 更新家长对课程班的可见性设置
 *
 * @returns 更新函数
 */
export const useUpdateClassHiddenTabSettings = () => {
  const { api: update } = acmApi$mutationConfigItemsByGroup.useApi({
    reload: [acmApi$queryConfigItemsByGroup.api],
  })

  const currentReflection = useCurrentReflection()

  return (
    name: 'hidden_for_guardian' | 'hidden_for_student',
    value: string[],
  ) =>
    update({
      scope: `school.${currentReflection.schoolId}`,
      service: ServiceEnum.SCMS,
      group: SchoolConfigGroupNameEnum.ClassSettings,
      payload: [
        {
          name,
          value,
        },
      ],
    })
}

import {
  EchartWidgetExtra,
  ReportExtra,
  WidgetExtra,
  WidgetTableExtra,
} from 'packages/components/ReportCenter/Reporting'
import {
  BaseReportInterface,
  ReportLayoutType,
  Widget,
  WidgetType,
} from 'packages/sdks-next/nuwa'

const ChartWidgetTypes = [
  WidgetType.PieChart,
  WidgetType.LineChart,
  WidgetType.BarChart,
  WidgetType.StackedBarChart,
  WidgetType.RadarChart,
  WidgetType.ColumnChart,
  WidgetType.StackedColumnChart,
]

const TableWidgetTypes = [WidgetType.DataTable, WidgetType.PivotTable]

/**
 * 排序类型
 */
export enum SortOrderTypeEnum {
  ASC = 'asc',
  DESC = 'desc',
  CUSTOM = 'custom',
}

/**
 * 是否为表格类型组件
 *
 * @param widget - 组件
 * @returns boolean
 */
export const isTableWidget = (
  widget: Widget | WidgetExtra,
): widget is WidgetTableExtra => {
  return widget.type && isTableWidgetType(widget.type)
}

/**
 * 是否为图表类型组件
 *
 * @param widget - 组件
 * @returns boolean
 */
export const isChartWidget = (
  widget: Partial<WidgetExtra>,
): widget is EchartWidgetExtra => {
  return !!widget.type && isChartWidgetType(widget.type)
}

/**
 * 是否为表格类型
 *
 * @param widgetType - 组件类型
 * @returns boolean
 */
export const isTableWidgetType = (widgetType: WidgetType) => {
  return TableWidgetTypes.includes(widgetType)
}

/**
 * 是否为图表类型
 *
 * @param widgetType - 图表类型
 * @returns 是否
 */
export const isChartWidgetType = (widgetType: WidgetType) => {
  return ChartWidgetTypes.includes(widgetType)
}

/**
 * 是否为纸张布局模式
 *
 * @param report - 报表
 * @returns 是否
 */
export const isPaperLayout = (report?: ReportExtra | BaseReportInterface) => {
  return report?.layoutType === ReportLayoutType.Paper
}

/**
 * 判断是否是一个可被导出的组件
 *
 * @param widget - 组件
 * @returns boolean
 */
export const isExportableWidget = (
  widget: Widget | WidgetExtra,
): widget is WidgetTableExtra => {
  return isTableWidget(widget)
}

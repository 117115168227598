import {
  FormAnswerAttributes,
  FormAnswerAttributesInterface,
} from './form-answer-attributes'

export interface FormAnswerInterface {
  formId: number
  formTemplateFieldId: number
  name: string
  label: string
  score: string
  attributes?: FormAnswerAttributesInterface | null
  /**
   * 主键 ID
   */
  id: number
}

export class FormAnswer implements FormAnswerInterface {
  formId: number
  formTemplateFieldId: number
  name: string
  label: string
  score: string
  attributes?: FormAnswerAttributes | null
  /**
   * 主键 ID
   */
  id: number

  constructor(props: FormAnswerInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      formId: _formId,
      formTemplateFieldId: _formTemplateFieldId,
      name: _name,
      label: _label,
      score: _score,
      attributes: _attributes,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.formId = _formId
    this.formTemplateFieldId = _formTemplateFieldId
    this.name = _name
    this.label = _label
    this.score = _score
    this.attributes = _attributes
      ? new FormAnswerAttributes(_attributes)
      : _attributes
    this.id = _id
  }

  static propKeys = [
    'formId',
    'formTemplateFieldId',
    'name',
    'label',
    'score',
    'attributes',
    'id',
  ]
}

const AI_TEACHER_TOKEN_KEY = 'ai_teacher_auth'

/**
 * 随机生成指定长度的字符串
 *
 * @param length - 长度
 * @returns str
 */
export const generateRandomString = (length: number) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  let result = ''

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    result += characters[randomIndex]
  }

  return result
}

/**
 * ai 教师 session
 */
export const aiTeacherSession = {
  setToken(token: string) {
    const saveToken = token
      ? `${generateRandomString(4)}${token.slice(0, 9)}BsD${token.slice(
          9,
        )}${generateRandomString(5)}`
      : token

    sessionStorage.setItem(AI_TEACHER_TOKEN_KEY, saveToken)
  },

  getToken() {
    const saveToken = sessionStorage.getItem(AI_TEACHER_TOKEN_KEY)

    const token = saveToken
      ? saveToken.slice(4, 13) + saveToken.slice(16, -5)
      : ''

    return token
  },
}

export interface GroupRoleInterface {
  name: string
  machineName: string
  schoolId: number
  groupTypeId: number
  isAdmin: boolean
}

export class GroupRole implements GroupRoleInterface {
  name: string
  machineName: string
  schoolId: number
  groupTypeId: number
  isAdmin: boolean

  constructor(props: GroupRoleInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      machineName: _machineName,
      schoolId: _schoolId,
      groupTypeId: _groupTypeId,
      isAdmin: _isAdmin,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.machineName = _machineName
    this.schoolId = _schoolId
    this.groupTypeId = _groupTypeId
    this.isAdmin = _isAdmin
  }

  static propKeys = [
    'name',
    'machineName',
    'schoolId',
    'groupTypeId',
    'isAdmin',
  ]
}

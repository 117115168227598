import { moment } from '@seiue/moment'

import { Stage as StageBase } from '../stage'

export type { StageInterface } from '../stage'

/**
 * 学段
 */
export class Stage extends StageBase {
  /**
   * 是否已到截止日期
   *
   * @returns 是否
   */
  get isExpired() {
    return moment(this.endAt).isBefore(moment())
  }
}

/**
 * @file register evaluation navigator
 */

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator(
  'evaluation.uncompleted_notice_for_student',
  ({ attributes }) => ({
    modalName: 'SubmitEvaluationModal',
    options: {
      id: (attributes as any)?.evaluationId,
    },
  }),
)

registerMessageNavigator(
  [
    'evaluation.uncompleted_notice_for_admin_class_teacher',
    'evaluation.uncompleted_notice_for_mentor',
  ],
  ({ attributes }) => ({
    modalName: 'EvaluationUncompletedModal',
    options: {
      id: (attributes as any)?.evaluationId,
      role: (attributes as any)?.recipient,
    },
  }),
)

registerMessageNavigator(
  ['evaluation.completed_notice_for_subject'],
  ({ attributes }) => ({
    path: `/teacher/evaluations/${(attributes as any).evaluationId}/results`,
  }),
)

/**
 * @file 课程申报消息 Chalk 导航
 */
import { registerMessageNavigator } from 'packages/features/messages/register'

/*
 * 待插件通知注册机制完善之前，暂时将插件的注册置于此处。
 * TODO
 * 1. 于插件内注册
 * 2. 通知类型随插件开关/权限显示（如果一开始用户有权限，收到通知，再关掉权限后如何显示？）
 */
registerMessageNavigator('declaration', ({ attributes }) => ({
  path: `/plugin/class_declarations/${(attributes as any)?.id}/forms`,
}))

import { RoleEnum } from './role-enum'

export interface ExamSubjectRelationInterface {
  rid: number
  role: RoleEnum
  classId: number | null
  itemId: number | null
  deleted?: boolean
  /**
   * 主键 ID
   */
  id: number
}

export class ExamSubjectRelation implements ExamSubjectRelationInterface {
  rid: number
  role: RoleEnum
  classId: number | null
  itemId: number | null
  deleted?: boolean
  /**
   * 主键 ID
   */
  id: number

  constructor(props: ExamSubjectRelationInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      rid: _rid,
      role: _role,
      classId: _classId,
      itemId: _itemId,
      deleted: _deleted,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.rid = _rid
    this.role = _role
    this.classId = _classId
    this.itemId = _itemId
    this.deleted = _deleted
    this.id = _id
  }

  static propKeys = ['rid', 'role', 'classId', 'itemId', 'deleted', 'id']
}

import { LayoutDomainEnum } from './layout-domain-enum'
import { LayoutPageIdentityEnum } from './layout-page-identity-enum'
import { LayoutRoleEnum } from './layout-role-enum'
import { LayoutPlatformEnum } from './layout-platform-enum'
import { Widget, WidgetInterface } from './widget'

export interface LayoutInterface {
  /**
   * 布局名称
   */
  name: string
  /**
   * 说明
   */
  description?: string
  /**
   * 领域
   */
  domain: LayoutDomainEnum
  /**
   * 页面唯一标识
   */
  pageIdentity: LayoutPageIdentityEnum
  /**
   * 所属用户角色
   */
  role?: LayoutRoleEnum | null
  /**
   * 平台
   */
  platform: LayoutPlatformEnum
  /**
   * 用户范围
   */
  scopes: object
  /**
   * 组件
   */
  widgets?: WidgetInterface[] | null
  /**
   * 字体
   */
  fontsize?: number | null
  /**
   * 排序 0-100
   */
  sort: number
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
}

export class Layout implements LayoutInterface {
  /**
   * 布局名称
   */
  name: string
  /**
   * 说明
   */
  description?: string
  /**
   * 领域
   */
  domain: LayoutDomainEnum
  /**
   * 页面唯一标识
   */
  pageIdentity: LayoutPageIdentityEnum
  /**
   * 所属用户角色
   */
  role?: LayoutRoleEnum | null
  /**
   * 平台
   */
  platform: LayoutPlatformEnum
  /**
   * 用户范围
   */
  scopes: object
  /**
   * 组件
   */
  widgets?: Widget[] | null
  /**
   * 字体
   */
  fontsize?: number | null
  /**
   * 排序 0-100
   */
  sort: number
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: LayoutInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      description: _description,
      domain: _domain,
      pageIdentity: _pageIdentity,
      role: _role,
      platform: _platform,
      scopes: _scopes,
      widgets: _widgets,
      fontsize: _fontsize,
      sort: _sort,
      id: _id,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.description = _description
    this.domain = _domain
    this.pageIdentity = _pageIdentity
    this.role = _role
    this.platform = _platform
    this.scopes = _scopes
    this.widgets = _widgets ? _widgets.map(i => new Widget(i)) : _widgets
    this.fontsize = _fontsize
    this.sort = _sort
    this.id = _id
    this.schoolId = _schoolId
  }

  static propKeys = [
    'name',
    'description',
    'domain',
    'pageIdentity',
    'role',
    'platform',
    'scopes',
    'widgets',
    'fontsize',
    'sort',
    'id',
    'schoolId',
  ]
}

import { $t } from 'packages/locale'

import { LayoutFontSize } from './types'

/**
 * 视图字体大小的列表和获取
 *
 * @returns 工具函数
 */
export const fontSizeToLabel = () => {
  const options = [
    { label: $t('标准'), value: LayoutFontSize.Normal },
    { label: $t('大字体'), value: LayoutFontSize.Large },
  ]

  return {
    get: (value: number) =>
      options.find(option => option.value === value)?.label as string,
    options,
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  PsychologicalGroup,
  PsychologicalGroupInterface,
} from './psychological-group'
import type { GetPsychologicalGroupTypeApiQuery } from './get-psychological-group-type-api-query'

const buildConfig = createConfigBuilder('chalk')

export const groupApi$getPsychologicalGroupTypeApi = {
  config(
    getPsychologicalGroupTypeApiQuery?: GetPsychologicalGroupTypeApiQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/group/psychological/group-type',
      {},
      getPsychologicalGroupTypeApiQuery,
    )
  },

  /**
   * @summary 获取心理档案群组类型（系统群组 + 心理档案群组）
   * TODO 这个 API 不应该出现在这里
   * @param [getPsychologicalGroupTypeApiQuery]
   * @param [groupQuery.type]
   * @param [options]
   * @return AxiosResponsePromise<PsychologicalGroup[]>
   */

  api(
    getPsychologicalGroupTypeApiQuery?: GetPsychologicalGroupTypeApiQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<PsychologicalGroup[]> {
    return axios(
      groupApi$getPsychologicalGroupTypeApi.config(
        getPsychologicalGroupTypeApiQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new PsychologicalGroup(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = PsychologicalGroup[]>(
    query?: GetPsychologicalGroupTypeApiQuery,
    queryOptions?: QueryOptionsWithSelect<PsychologicalGroup[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<PsychologicalGroup[], TSelected>(
      groupApi$getPsychologicalGroupTypeApi.api,
      'Group.getPsychologicalGroupTypeApi',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
groupApi$getPsychologicalGroupTypeApi.api._name_ = `Group.getPsychologicalGroupTypeApi`

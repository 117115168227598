/**
 * @file ModalRoute
 * 用 Modal 的形式响应 Route 的变化。当 RoutePathQuery 命中 modal=ModalName 时，将 Model 显示。
 * 以实现在任何页面打开任意弹窗的交互。
 */

import { useForceUpdate, parseURLQuery } from '@seiue/util'
import React from 'react'
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import styled from 'styled-components'

import { Modal as SeModal } from 'packages/components/Modal'
import { $t } from 'packages/locale'
import { ModalRouteNode, RouteModalParamsList } from 'packages/route'
import { clearModalQuery } from 'packages/route/utils'

/**
 * @deprecated use RouteModalComponent instead
 */
export type ModalComponent<T = {}> = React.FC<
  {
    visible: boolean
    onCancel: () => void
    onError: (e: any) => void
  } & RouteProps &
    T
>

export type BasicRouteModalComponentProps = {
  /**
   * 是否显示弹窗
   */
  visible: boolean
  /**
   * 关闭弹窗时调用，将关闭弹窗和退出路由
   */
  onCancel: () => void
  /**
   * 弹窗内部出现时调用，目前的逻辑为
   * 1. 如果是 404 的 AxiosError，显示一个提示样式
   * 2. 其他的将关闭弹窗，退出路由
   */
  onError: (e: any) => void
}

export type RouteModalComponent<TRouteParamsList extends RouteModalParamsList> =
  React.FC<
    {
      route: ModalRouteNode & { name: keyof TRouteParamsList }
    } & BasicRouteModalComponentProps
  >

interface Props {
  name: string
  title?: string
  ModalComponent: ModalComponent
  route?: ModalRouteNode
}

const Modal: React.FC<Props & RouteComponentProps> = ({
  name,
  ModalComponent,
  title,
  ...routeProps
}) => {
  const { history, location } = routeProps

  /*
   * 采用 Ref 与 forceUpdate 来替代 useState
   * 原因是为了能让该组件在第一时间响应路由变化，释放旧的组件，使其不会受到路由变化的影响
   */
  const visibleRef = React.useRef(false)
  const [forceUpdate] = useForceUpdate()

  const setVisible = (v: boolean) => {
    if (visibleRef.current !== v) {
      visibleRef.current = v
      forceUpdate()
    }
  }

  const search = React.useMemo(
    () => parseURLQuery(location.search),
    [location.search],
  )

  const isVisible = !!(search['modal'] && search['modal'] === name)
  setVisible(isVisible)

  const [is404, setIs404] = React.useState(false)

  const goBack = () => {
    // 如果是在 chalk 内通过 react router 打开的弹窗，那么 window.history 会附带一个 state
    if (window.history.state) history.goBack()
    else {
      /*
       * 如果是直接复制 url 打开的弹窗，则 window.history 的 state 为空
       * 此时不再后退，而是把 modal 相关的 query 清空
       */
      history.replace(location.pathname, clearModalQuery(location.search))
    }
  }

  const handleCancel = () => {
    if (visibleRef.current) goBack()

    // 立即隐藏弹窗
    setVisible(false)
  }

  const handleError = (e: any) => {
    if (e?.response?.status === 404 || e?.status === 404) {
      setIs404(true)
    } else {
      goBack()
      throw e
    }
  }

  React.useEffect(() => {
    if (visibleRef.current) {
      if (title) window.document.title = title

      setIs404(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate, location])

  /*
   * FIXME：为了防止 ModalComponent 始终保持在内存中，因此在此处立刻释放 ModalComponent
   * 应有更完备的实现
   */
  if (!visibleRef.current) return null

  if (is404) {
    return (
      <SeModal visible={visibleRef.current} onCancel={handleCancel}>
        <NotFound>{$t('资料已删除')}</NotFound>
      </SeModal>
    )
  }

  return (
    <ModalComponent
      visible={visibleRef.current}
      onCancel={handleCancel}
      onError={handleError}
      {...routeProps}
    />
  )
}

/**
 * 路由弹窗组件
 *
 * @param props - props
 * @param props.route - 路由参数
 * @returns JSX.Element
 */
export const ModalRoute: React.FC<{ route: ModalRouteNode }> = ({ route }) => (
  <Route
    key={route.name}
    path={'/'}
    render={props => (
      <Modal
        name={route.name}
        title={route.getTitle()}
        ModalComponent={route.component}
        {...props}
        route={route}
      />
    )}
  />
)

const NotFound = styled.div`
  min-width: 400px;
  min-height: 400px;
  font-size: 20px;
  line-height: 360px;
  text-align: center;
`

import { isAxiosError } from '@seiue/axios'
import { uniq } from '@seiue/util'

import { ScopeDomain } from 'packages/feature-utils/plugins'
import {
  roleToScopeEnhancer,
  useIsManagedResource,
} from 'packages/features/roles'
import {
  useCurrentReflection,
  useIsCurrentReflection,
} from 'packages/features/sessions'
import { useIsAdminByPath } from 'packages/route/use-is-admin-by-path'
import { wait } from 'packages/sdks-next'
import {
  GroupTypeEnum,
  PermissionNameEnum,
  reflectionApi$querySecurityGroups,
  groupApi$getPermissionsByGroupType,
  groupApi$findJoinedGroups,
} from 'packages/sdks-next/chalk'

import { AvatarProps, AvatarReflection } from './types'
import { getAvatarData, isSameRoleFn, isRoleStudent } from './utils'

type UseAvatarDataParams = Pick<
  AvatarProps,
  'viewerMemberType' | 'forceFirst'
> & {
  /**
   * 被查看的用户
   */
  targetReflection?: AvatarReflection
}

/**
 * 获取头像类型和地址的 hook
 * 修改此处逻辑前，请先阅读 getAvatarData 中的逻辑，确保此处的 api hook 调用顺序一致
 * 即满足前序条件能取到照片权限的情况下，后续请求都无需发起
 *
 * @param {UseAvatarDataParams} param0 - 参数
 * @returns 类型和地址
 */
export const useAvatarData = ({
  viewerMemberType,
  targetReflection,
  forceFirst,
}: UseAvatarDataParams) => {
  const currentReflection = useCurrentReflection()
  const isMySelf = useIsCurrentReflection(targetReflection?.id || 0)

  // 可以不用发起请求的情况，随着下方请求权限的判断而动态增加
  let disableRequests = !targetReflection || !!forceFirst || isMySelf

  // 后台页面且被查看用户在当前用户管理范围内，则可以直接查看照片，无需调用下方接口查安全等级等
  const hasPhotoAccessFromAdmin = useHasPhotoAccessFromAdmin({
    targetReflection,
    disable: disableRequests,
  })

  const isSameRole = isSameRoleFn(targetReflection, currentReflection)

  // 被查看者角色照片的字段安全等级
  const photoSecurityLevel = usePhotoSecurityLevel({
    targetReflection,
    disable: (disableRequests =
      disableRequests || hasPhotoAccessFromAdmin || isSameRole),
  })

  const isPhotoBaseField = photoSecurityLevel === 'base'

  const isTargetStudent = isRoleStudent(targetReflection)

  // targetReflection 为学生时，查出学生档案权限设置中，可以查看学生照片的角色类型
  const studentPhotoAccessibleMemberTypes =
    useStudentPhotoAccessibleMemberTypes({
      photoSecurityLevel,
      disable: (disableRequests =
        disableRequests || isPhotoBaseField || !isTargetStudent),
    })

  // 如果传了 viewerMemberType，判断是否包含在可以查看学生照片的角色类型中
  const viewerMemberTypeAccessible =
    !!viewerMemberType &&
    studentPhotoAccessibleMemberTypes?.includes(viewerMemberType)

  // 在当前用户具有照片访问的群组关系中的学生，判断是否包含 targetReflection
  const hasTargetPhotoAccess = useTargetPhotoAccessible({
    studentPhotoAccessibleMemberTypes,
    targetReflection,
    disable: (disableRequests =
      disableRequests || !!viewerMemberTypeAccessible),
  })

  const hasStudentPhotoAccess =
    isTargetStudent && (!!viewerMemberTypeAccessible || !!hasTargetPhotoAccess)

  return getAvatarData({
    forceFirst,
    isMySelf,
    hasPhotoAccessFromAdmin,
    isSameRole,
    isPhotoBaseField,
    hasStudentPhotoAccess,
    targetReflection,
  })
}

/**
 * 在后台页面被查看者是否在查看者的管理范围内，超管默认有权限
 *
 * @param param0 - 参数
 * @param param0.targetReflection - 被查看者
 * @param param0.disable - api disable
 * @returns 在管理范围内
 */
const useHasPhotoAccessFromAdmin = ({
  targetReflection,
  disable,
}: {
  targetReflection: AvatarReflection | undefined
  disable?: boolean
}) => {
  const fromAdmin = useIsAdminByPath()

  const [isTargetMyManaged] = useIsManagedResource(
    {
      permission: PermissionNameEnum.CoreUserRead,
      domain: ScopeDomain.Reflection,
      scopeName: targetReflection?.role
        ? roleToScopeEnhancer.get(targetReflection?.role)
        : undefined,
      staleTime: 600,
    },
    !fromAdmin || disable ? undefined : targetReflection?.id,
  )

  return fromAdmin && isTargetMyManaged
}

/**
 * 查出被查看者角色的照片字段安全等级
 *
 * @param param0 - 参数
 * @param param0.targetReflection - 被查看者
 * @param param0.disable - api disable
 * @returns 照片字段安全等级
 */
const usePhotoSecurityLevel = ({
  targetReflection,
  disable,
}: {
  targetReflection: AvatarReflection | undefined
  disable?: boolean
}) => {
  const { data: photoSecurityLevel } = reflectionApi$querySecurityGroups.useApi(
    wait(targetReflection?.role),
    {
      staleTime: 600,
      select: securityGroups =>
        securityGroups.find(group => group.fieldNames?.includes('photo'))?.name,
      disable,
    },
  )

  return photoSecurityLevel
}

/**
 * 当被查看者为学生时，查出学生档案权限设置中，可以查看学生照片的角色类型
 *
 * @param param0 - 参数
 * @param param0.photoSecurityLevel - 照片字段安全等级
 * @param param0.disable - api disable
 * @returns 学生照片的角色类型
 */
const useStudentPhotoAccessibleMemberTypes = ({
  photoSecurityLevel,
  disable,
}: {
  photoSecurityLevel: string | undefined | null
  disable: boolean
}) => {
  const { data: studentPhotoAccessibleMemberTypes } =
    groupApi$getPermissionsByGroupType.useApi(GroupTypeEnum.RelationGroup, {
      staleTime: 600,
      disable: disable || !photoSecurityLevel,
      catchError: e => (isAxiosError(e, 403) ? null : e),
      select: permissions => {
        return uniq(
          permissions
            .filter(p => p.permName === `personal.${photoSecurityLevel || ''}`)
            .map(p => p.memberType),
        )
      },
    })

  return studentPhotoAccessibleMemberTypes
}

/**
 * 在当前用户具有照片访问的群组关系中的学生，判断是否包含 targetReflection
 *
 * @param param0 - 参数
 * @param param0.studentPhotoAccessibleMemberTypes - 可访问学生照片的角色类型
 * @param param0.targetReflection - 被查看者
 * @param param0.disable - api disable
 * @returns photo accessible
 */
const useTargetPhotoAccessible = ({
  studentPhotoAccessibleMemberTypes,
  targetReflection,
  disable,
}: {
  studentPhotoAccessibleMemberTypes: string[] | null
  targetReflection: AvatarReflection | undefined
  disable: boolean
}) => {
  const currentReflection = useCurrentReflection()
  // 找出当前角色具有照片访问权限的学生们，并判断是否包含 targetReflection
  const { data: hasTargetPhotoAccess } = groupApi$findJoinedGroups.useApi(
    {
      id: currentReflection.id,
      query: {
        scope: 'relation',
        memberTypeIn: studentPhotoAccessibleMemberTypes?.join(','),
        paginated: 0,
      },
    },
    {
      staleTime: 600,
      select: joinedGroups =>
        joinedGroups
          .map(g => g.bizId)
          .includes(`${targetReflection?.id || ''}`),
      disable,
    },
  )

  return hasTargetPhotoAccess
}

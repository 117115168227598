import { createEnumHelper } from '@seiue/util'

import { $t } from 'packages/locale'
import { PlanRoundTypeEnum } from 'packages/sdks-next/vnas'

import { Plan as Base, PlanInterface } from '../plan'

export type { PlanInterface }

export const roundTypeToLabel = createEnumHelper(() => ({
  [PlanRoundTypeEnum.Floor]: $t('向下取整'),
  [PlanRoundTypeEnum.Ceil]: $t('向上取整'),
  [PlanRoundTypeEnum.Round]: $t('四舍五入'),
}))

/**
 *
 */
export class Plan extends Base {
  /**
   * 获取 roundType (取证标准) 的所有选项
   *
   * @param sourceKey - 原始值在选项 object 中的 key, 默认 value
   * @param mappedKey - 文字标签在选项 object 中的 key, 默认 label
   * @returns 选项数组, 默认 [{ value, label }]
   */
  static getRoundTypeOptions<S extends string, M extends string>(
    sourceKey: S,
    mappedKey: M,
  ) {
    return roundTypeToLabel.listOptions(sourceKey, mappedKey)
  }
}

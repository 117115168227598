import { keyBy, isInteger, Dictionary } from '@seiue/util'

import { $t } from 'packages/locale'
import { ItemTag } from 'packages/sdks-next/vnas'

export type Tag = Omit<ItemTag, 'groupName'>

export interface TagGroup {
  name: string
  tags: Tag[]
}

/**
 * 标签形式，文字/徽章
 */
export type TagModeType = 'text' | 'badge'

/**
 * 将标签列表进行分组
 *
 * @param tags - 标签列表
 * @returns 分组后的标签
 */
export const convertTagsToTagGroups = (tags: ItemTag[]) =>
  Object.values(
    tags.reduce((result, tag) => {
      const nextTags = { ...result }
      const { groupName } = tag

      if (!groupName) return nextTags

      /*
       * 当 groupName 可被转化为整型时，他会在对象 key 里的排序会自动靠前
       * 所以为了稳定 tags 标签组的显示顺序，在此处将其转为非整型字符串
       */
      const name = isInteger(Number(groupName))
        ? `${$t('整型')}${groupName}`
        : groupName

      if (!nextTags[name]) {
        nextTags[name] = {
          name: groupName,
          tags: [],
        }
      }

      nextTags[name].tags.push(tag)

      return nextTags
    }, {} as { [key: string]: TagGroup }),
  )

/**
 * 将分组后的标签转换为标签列表
 *
 * @param tagGroups - 分组后的标签
 * @returns 标签列表
 */
export const convertTagGroupsToTags = (tagGroups: TagGroup[]) =>
  Object.values(
    tagGroups.reduce((result, { name, tags }) => {
      const tagMap = keyBy(
        tags.map(tag => ({ ...tag, groupName: name })),
        'name',
      )

      return {
        ...result,
        ...tagMap,
      }
    }, {} as { [key: string]: ItemTag }),
  )

export type ItemTagWithCount = ItemTag & {
  /**
   * 标签数量
   */
  tagCount?: number
}

/**
 * 根据标签数量更新对应的标签分数（分数累加）
 *
 * @param tags - 已选标签
 * @param counts - 标签数量，格式为 { [标签名称]: [标签数量] }
 * @returns 更新后的标签
 */
export const updateTagsScoreByCounts = (
  tags: ItemTag[],
  counts: Dictionary<number | null>,
): ItemTagWithCount[] => {
  return tags.reduce((prev, currentTag) => {
    const currentTagCount = counts[currentTag.name]
    if (!currentTagCount) return [...prev, currentTag]

    return [
      ...prev,
      {
        ...currentTag,
        score: `${Number(currentTag.score ?? 0) * currentTagCount}`,
        tagCount: currentTagCount,
      },
    ]
  }, [] as ItemTagWithCount[])
}

/**
 * 获取对应数量的标签累加总分
 *
 * @param tags - 已选标签
 * @param counts - 标签数量
 * @returns 对应数量的标签累加总分
 */
export const sumTagsByCounts = (
  tags: ItemTag[],
  counts: Dictionary<number | null>,
) => {
  return tags.reduce((prev, currentTag) => {
    const currentTagCount = counts[currentTag.name]
    if (!currentTagCount) return prev

    const currentTagTotalScore = Number(currentTag.score ?? 0) * currentTagCount
    return prev + currentTagTotalScore
  }, 0)
}

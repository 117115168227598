export * from './common'

const Red = '#C41A1D'
const Yellow = '#AD5B00'
const Green = '#00795C'
const DeepGray = 'rgba(6, 12, 25, 0.65)'

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const Color = {
  Black: 'rgba(6, 12, 25, 0.85)', // #323842 deprecated
  DeepGray, // rgba(6, 12, 25, 0.65) // #5E636E deprecated
  Gray: 'rgba(6, 12, 25, 0.45)', // #9398A1 deprecated
  LightGray: 'rgba(6, 12, 25, 0.25)', // #C6C9CF deprecated
  White: '#ffffff',

  Theme: '#447AE6',
  ElTheme: '#669FFF',
  Red,
  ElRed: '#F7585B',
  Yellow,
  ElYellow: '#FCA700',
  Green,
  ElGreen: '#16C89E',
  Danger: Red,
  Error: Red,
  Warning: Yellow,
  Pending: Yellow,
  Success: Green,
  Passed: Green,
  Disable: DeepGray,
  Cancel: DeepGray,
} as const

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const BorderWidth = {
  Light: '1px',
  Regular: '1px',
} as const

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const DividerColor = {
  Default: '#D8D8D8',
} as const

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const BorderRadius = {
  Default: '6px',
  Light: '4px',
} as const

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const FontSize = {
  Nav: '16px',
  Default: '13px',
  H1: '24px',
  H2: '18px',
  H3: '16px',
  H4: '14px',
  T1: '14px',
  T2: '13px',
  T3: '12px',
}

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const Border = {
  Default: '1px solid rgba(50,56,66,.06)',
  Deep: '1px solid rgba(50,56,66,.1)',
  Box: '1px solid #D3D5DB',
  Black: '1px solid rgba(6, 12, 25, .45)',
  Theme: '1px solid #447AE6',
  DisabledTheme: '1px solid #c3daff',
} as const

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const BoxShadow = {
  Default: '0 3px 16px 0 rgba(50, 56, 66, 0.04)',
}

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const Transition = {
  Default: '0.3s all ease-in-out',
}

export const VerticalCenter = {
  Flex: `
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
}

export const Ellipsis = `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
`
export const MultilineEllipsis = `
  ${Ellipsis}
  display:-webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  white-space:pre-line;
`
export const H1 = `
  font-size: ${FontSize.H1};
  font-weight: bold;
  margin-bottom: 0;
  color: ${Color.Black};
`

export const H2 = `
  font-size: ${FontSize.H2};
  font-weight: bold;
  margin-bottom: 0;
  color: ${Color.Black};
`

export const H3 = `
  font-size: ${FontSize.H3};
  font-weight: bold;
  margin-bottom: 0;
  color: ${Color.Black};
`

export const H4 = `
  font-size: ${FontSize.H4};
  font-weight: bold;
  margin-bottom: 0;
  color: ${Color.Black};
`

export const T3 = `
  font-size: ${FontSize.T3};
  margin-bottom: 0;
  color: ${Color.Gray};
`

export const Tip = `
  font-size: ${FontSize.T2};
  margin-bottom: 0;
  color: ${Color.Gray};
`

export const T1 = `
  font-size: ${FontSize.T1};
  margin-bottom: 0;
  color: ${Color.Black};
`

export const InputPlaceholder = `
  ::placeholder {
    color: ${Color.LightGray};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${Color.LightGray};
  }

  ::-ms-input-placeholder {
    color: ${Color.LightGray};
  }
`

/**
 * 无边框的输入框
 * 悬浮和获取焦点时出现边框
 */
export const InputBorderNone = `
  width: 100% !important;
  color: ${Color.Black} !important;
  border: 1px solid ${Color.White} !important;
  outline: none !important;
  transition: border-color .3s !important;
  line-height: 36px !important;
  padding: 3px 8px !important;
  border-radius: 4px !important;
  resize: none !important;

  &:hover, &:focus {
    border-color: ${Color.Gray} !important;
    box-shadow: none !important;
  }

  ${InputPlaceholder};
`

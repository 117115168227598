import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import { selectedSemesterAtom } from 'packages/feature-utils/semesters'
import { Schcal, schcalApi$querySchcals } from 'packages/sdks-next/scms'

/**
 * 更新所有校历 atom 的触发 atom
 */
export const updateCalendarsFlagAtom = atom(1)

/**
 * 选中学期的所有校历 atom
 */
export const schoolCalendarsAtom = atom<Promise<Schcal[]>>(async get => {
  const selectedSemester = get(selectedSemesterAtom)
  if (!selectedSemester) {
    return []
  }

  get(updateCalendarsFlagAtom)

  const { data: schcals } = await schcalApi$querySchcals.api(
    selectedSemester.id,
  )

  return schcals
})

/**
 * 加载完的选中学期所有校历 atom
 */
export const schoolCalendarsLoadableAtom = loadable(
  atom(get => get(schoolCalendarsAtom)),
)

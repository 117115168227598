/**
 * 微信 SDK Helper
 *
 * 对接相关文档：https://developer.campus.qq.com/docs/%E5%BC%80%E5%8F%91%E6%96%87%E6%A1%A3/%E5%BC%80%E5%8F%91%E5%89%8D%E5%BF%85%E8%AF%BB.html
 *
 * common
 */

import { filterAndReportError } from '@seiue/error-handler'
import { isFunction, isObject, loadScript, noop } from '@seiue/util'

interface WechatSdk {
  init: (err: any) => void
  setParams: (props: { appid: string; corpid?: string }) => void
  relogin: () => void
}

/**
 * 加载微信 sdk 并对其进行初始化
 *
 * @param params - 腾讯校园登录信息，用于初始化 sdk
 * @param params.appid - appid
 * @param params.corpid - 企业微信下才需要
 * @returns 微信 sdk
 */
export const loadWechatSdkScript = (params: {
  appid: string
  corpid?: string
}) =>
  new Promise(resolve => {
    // 载入微信 sdk
    loadScript(
      'https://static.educloud.qq.com/sdk/mp-js-sdk-1.2.0.js',
      async () => {
        const { wx } = window as unknown as { wx: WechatSdk | undefined }

        wx?.setParams(params)

        resolve(await getWechatSdk())
      },
    )
  })

/**
 * 调取微信 sdk
 *
 * @returns 微信 sdk
 */
export const getWechatSdk = async (): Promise<WechatSdk | null> =>
  new Promise(resolve => {
    const { wx } = window as unknown as { wx: WechatSdk | undefined }

    if (!isObject(wx)) {
      resolve(null)
      return
    }

    /*
     * 需要调查
     * 在一些奇怪的情况下，wx.init 不会是一个 function，但微信 sdk 要求在调用其他命令之前，要优先调用 init 命令
     */
    if (!isFunction(wx.init)) {
      resolve(null)
      return
    }

    wx.init((err: any) => {
      if (err) {
        filterAndReportError(err, { ExceptionType: 'wechatInitedFail' })
        resolve(null)
        return
      }

      resolve(wx)
    })
  })

/**
 * 仅在 native 环境下有效
 */
export const WeChat = {}

/**
 * 仅在 native 环境下有效
 */
export const registerWeChat = noop

/**
 * 仅在 native 环境下有效
 *
 * @returns 是否
 */
export const isWeChatInstalled = () => false

/**
 * 是否在微信小程序中
 *
 * @returns 是否
 */
export const isInWeChatMiniProgram = () => {
  // @ts-expect-error window.__wxjs_environment 推导错误
  return String(window.__wxjs_environment) === 'miniprogram'
}

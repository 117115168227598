export interface GroupTeacherBotInterface {
  /**
   * 外部智能体ID
   */
  externalBotId: string
  name: string
  avatar: string
  /**
   * 对话数量
   */
  conversationCount: number
  /**
   * 未解决提醒数量
   */
  unsolvedCount: number
  /**
   * 教师未查看学生提醒数量
   */
  unviewedReminderCount: number
  /**
   * 学生未查看教师回复数量
   */
  unviewedReplyCount: number
  description: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
}

export class GroupTeacherBot implements GroupTeacherBotInterface {
  /**
   * 外部智能体ID
   */
  externalBotId: string
  name: string
  avatar: string
  /**
   * 对话数量
   */
  conversationCount: number
  /**
   * 未解决提醒数量
   */
  unsolvedCount: number
  /**
   * 教师未查看学生提醒数量
   */
  unviewedReminderCount: number
  /**
   * 学生未查看教师回复数量
   */
  unviewedReplyCount: number
  description: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: GroupTeacherBotInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      externalBotId: _externalBotId,
      name: _name,
      avatar: _avatar,
      conversationCount: _conversationCount,
      unsolvedCount: _unsolvedCount,
      unviewedReminderCount: _unviewedReminderCount,
      unviewedReplyCount: _unviewedReplyCount,
      description: _description,
      id: _id,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.externalBotId = _externalBotId
    this.name = _name
    this.avatar = _avatar
    this.conversationCount = _conversationCount
    this.unsolvedCount = _unsolvedCount
    this.unviewedReminderCount = _unviewedReminderCount
    this.unviewedReplyCount = _unviewedReplyCount
    this.description = _description
    this.id = _id
    this.schoolId = _schoolId
  }

  static propKeys = [
    'externalBotId',
    'name',
    'avatar',
    'conversationCount',
    'unsolvedCount',
    'unviewedReminderCount',
    'unviewedReplyCount',
    'description',
    'id',
    'schoolId',
  ]
}

/* eslint-disable max-lines */

/**
 * @file 日程相关的数据类的 utils
 */

import { moment, Moment, MomentInput } from '@seiue/moment'
import { compact, groupBy, isNil, orderBy, times } from '@seiue/util'
import React from 'react'

import { useFindPersonalSchedules } from 'packages/features/calendars/utils/apis'
import { ClassEventSource } from 'packages/features/classes/calendar-events/types'
import { getTranslatedTag } from 'packages/features/classes/utils/translateToEn'
import { CustomizedGroupSource } from 'packages/features/customized-groups'
import { useLoadCurrentPupil } from 'packages/features/reflections/utils/apis'
import { useCurrentReflection } from 'packages/features/sessions/utils/data'
import { $t } from 'packages/locale'
import { useCustomGroupIdsAndGroupTimeIds } from 'packages/plugins/features/customized-groups/utils'
import { DormEventSource } from 'packages/plugins/features/dorms'
import {
  CalendarEvent as OriginCalendarEvent,
  RoleEnum,
} from 'packages/sdks-next/chalk'
import {
  AttendanceBizTypeEnum,
  attendanceApi$queryAttendancesInfo,
} from 'packages/sdks-next/sams'
import { Lesson } from 'packages/sdks-next/scms'

import {
  CalendarEventProps,
  CalendarEventType,
  CalendarEventWithIndex,
  WeekdayType,
} from './types'
import {
  FULL_DAY_ITEM_HEIGHT,
  FULL_DAY_ITEM_MARGIN_TOP,
  MOBILE_45_MINUTE_EVENT_CARD_HEIGHT,
  WEB_45_MINUTE_EVENT_CARD_HEIGHT,
  WEB_EVENT_CARD_MIN_HEIGHT,
} from './views'

/**
 * 格式化时间至 HH:mm 格式
 * 2020-08-08 12:30:00 -> 12:30
 *
 * @param time - 时间字符串
 * @returns 格式化后的时间字符串
 */
export const formatTimeToHour = (time: string) => moment(time).format('HH:mm')

/**
 * 格式化时间至 MM-DD 格式
 * 2020-08-08 12:30:00 -> 08-08
 *
 * @param time - 时间字符串
 * @returns 格式化后的时间字符串
 */
export const formatTimeToDay = (time: string) => moment(time).format('MM-DD')

const ONE_HOUR_TIMESTAMP = 60 * 60 * 1000
const ONE_DAY_TIMESTAMP = 24 * ONE_HOUR_TIMESTAMP

/**
 * 将日程分组为全天日程和非全天日程
 *
 * @param sources - 日程列表
 * @returns 分组后的日程列表
 */
export const groupByEventsByIsFullDay = (
  sources: OriginCalendarEvent[] | null,
): {
  fullDay: OriginCalendarEvent[]
  normal: OriginCalendarEvent[]
} => {
  if (!sources) {
    return {
      fullDay: [],
      normal: [],
    }
  }

  return sources.reduce(
    (m, v) => {
      if (v.custom?.['isFullday']) {
        m.fullDay.push(v)
      } else {
        m.normal.push(v)
      }

      return m
    },
    { fullDay: [], normal: [] } as {
      fullDay: OriginCalendarEvent[]
      normal: OriginCalendarEvent[]
    },
  )
}

/**
 * 计算当前时间刻度的 top，返回 null 表示不显示
 *
 * @param date - 日期
 * @param gridHeight - 格子高度
 * @param minTime - 最小时间
 * @param maxTime - 最大时间
 * @returns 当前时间刻度的 top
 */
export const computeCurrentTimeToToday = (
  date: string,
  gridHeight: number,
  minTime: string | null,
  maxTime: string | null,
) => {
  const currentDate = moment().format('YYYY-MM-DD')
  if (!minTime || !maxTime || date !== currentDate) return null
  const startHour = moment(`${currentDate} ${minTime}`).hour()
  const endHour = moment(`${currentDate} ${maxTime}`).hour()
  const currentHour = moment().hour()
  if (currentHour < startHour || currentHour > endHour) return null
  const currentDateTimestamp = moment().startOf('day').valueOf()
  const currentTimeTop =
    (moment().valueOf() - currentDateTimestamp) / ONE_HOUR_TIMESTAMP

  return (currentTimeTop - startHour) * gridHeight
}

/**
 * 根据最小时间和最大时间过滤 lessons
 *
 * @param date - 日期
 * @param minTime - 最小时间
 * @param maxTime - 最大时间
 * @param lessons - 课节次列表
 * @returns 过滤后的课节次列表
 */
export const filterLessonsByTime = (
  date: string,
  minTime: string | null,
  maxTime: string | null,
  lessons: Lesson[],
) => {
  if (lessons.length === 0 || !minTime || !maxTime) return []
  const minTimeValue = moment(`${date} ${minTime}`).valueOf()
  const maxTimeValue = moment(`${date} ${maxTime}`).valueOf()

  // 节次必须完整的在 minTime 和 maxTime 之间
  const list = lessons.filter((v: Lesson) => {
    const currMinValue = moment(`${date} ${v.startAt}`).valueOf()
    const currMaxValue = moment(`${date} ${v.endAt}`).valueOf()

    return currMinValue >= minTimeValue && currMaxValue <= maxTimeValue
  })

  /*
   * 1、左边的课节次是根据返回的日程（包括活动和其他）筛选最小的 startTime 和最大的 endTime，
   * 2、然后根据 startTime 和 endTime 筛选出在这个时间范围内的 lessons，
   * 如果只有一个 tasks 没有 lessons（多个 tasks 同 1），则筛选出的课节次是空数组，
   * 反映到页面上，只有一个活动，没有左边的节次信息，所以返回一个假的 lesson
   */
  if (list.length === 0) {
    const item = {
      name: '1',
      startAt: minTime,
      endAt: maxTime,
    }

    return [item]
  }

  return list
}

/**
 * 计算 lesson 的坐标
 *
 * @param startAt - 开始时间
 * @param endAt - 结束时间
 * @param gridHeight - 格子高度
 * @param offsetTop - 偏移量
 * @returns lesson 的坐标
 */
export const computeLessonAttrs = (
  startAt: string,
  endAt: string,
  gridHeight: number,
  offsetTop = 0,
) => {
  const currentDate = moment().format('YYYY-MM-DD')
  const currentDateTimestamp = moment(`${currentDate} 00:00:00`).valueOf()
  const startTimestamp = moment(`${currentDate} ${startAt}`).valueOf()
  const endTimestamp = moment(`${currentDate} ${endAt}`).valueOf()
  const startAtHour =
    (startTimestamp - currentDateTimestamp) / ONE_HOUR_TIMESTAMP + 1

  const endAtHour =
    (endTimestamp - currentDateTimestamp) / ONE_HOUR_TIMESTAMP + 1

  const top = startAtHour * gridHeight
  const height = (endAtHour - startAtHour) * gridHeight

  // 高度为 44 以下的不显示节次时间
  const showTime = height >= 44
  return { height, top: top + offsetTop, showTime }
}

/**
 * 返回辅助信息的行数
 *
 * @param height - 日程高度
 * @param width - 日程宽度
 * @param view - 日历视图
 * @param os - 操作系统
 * @returns 辅助信息的行数
 */
export const getSupplementLineNumber = (
  height: number,
  width: string,
  view: 'day' | 'week' | 'month' | string,
  os: 'web' | 'mobile',
): 1 | 2 => {
  if (view === 'day') {
    // 34 是指一行并排显示了 3 个日程
    if (os === 'web') {
      return parseInt(width, 10) < 34 &&
        height < WEB_45_MINUTE_EVENT_CARD_HEIGHT
        ? 2
        : 1
    }

    return parseInt(width, 10) < 34 &&
      height < MOBILE_45_MINUTE_EVENT_CARD_HEIGHT
      ? 2
      : 1
  }

  return height <= WEB_EVENT_CARD_MIN_HEIGHT ? 1 : 2
}

/**
 * 按天分组 events
 *
 * @param sources - 日程列表
 * @returns 按天分组后的日程列表
 */
export const groupByCalendarEventsByDay = <T extends OriginCalendarEvent>(
  sources: T[],
): Record<string, T[]> => {
  const sourceMap = sources.reduce((p, v) => {
    const date = v.startTime.substr(0, 10)
    /* eslint-disable no-param-reassign */
    p[date] = p[date] || []
    p[date].push(v)
    /* eslint-disable no-param-reassign */
    return p
  }, {} as { [key: string]: T[] })

  Object.keys(sourceMap).forEach(k => {
    /*
     * 将每天的日程排序，防止位置乱窜
     * 优先级按 title、address、subject.className（课程日程才有）
     * 对于没有 address、subject.className 字段的日程，则只按 title 排序
     * 支持 address 的日程类型：自定义日程、群组日程、活动任务日程、场地日程、调代课日程
     */
    sourceMap[k] = orderBy(
      sourceMap[k],
      ['title', 'address', 'subject.className'],
      'asc',
    )
  })

  return sourceMap
}

/**
 * （非全天）返回携带有坐标值的 events
 *
 * @param props - 参数
 * @param props.sources - 日程列表
 * @param props.gridWidth - 格子宽度
 * @param props.offsetTop - 偏移量
 * @returns 携带有坐标值的 events
 */
export const formatNonFullDayCalendarEvents = ({
  sources,
  gridWidth,
  offsetTop = 0,
}: {
  sources: OriginCalendarEvent[]
  gridWidth: number
  offsetTop?: number
}): CalendarEventProps[] => {
  if (!sources || !Array.isArray(sources) || !sources.length) return []
  const timestamp = moment(sources[0].startTime).startOf('day').valueOf()
  const styleMap: { [key: string]: any } = {}

  // 先按持续时间倒序排列，便于检测重叠的日程
  return sources
    .sort((a, b) => {
      const aDuration =
        moment(a.endTime).valueOf() - moment(a.startTime).valueOf()

      const bDuration =
        moment(b.endTime).valueOf() - moment(b.startTime).valueOf()

      return aDuration > bDuration ? -1 : 1
    })
    .map((source, index) => {
      // 检测是否有日程的结束时间和当前日程的开始时间重合，有的话，需要有一定的间隙隔开
      const hasPrevConnected = sources.some(v => v.startTime === source.endTime)
      const start = moment(source.startTime).valueOf()
      const end = moment(source.endTime).valueOf()
      const startHour = (start - timestamp) / ONE_HOUR_TIMESTAMP + 1
      const endHour = (end - timestamp) / ONE_HOUR_TIMESTAMP + 1
      const top = startHour * gridWidth
      const height = (endHour - startHour) * gridWidth

      // 筛选出跟当前日程 source 时间有交叉的日程
      const crossSources = sources
        .map((v, ii) => {
          const vStart = moment(v.startTime).valueOf()
          const vEnd = moment(v.endTime).valueOf()
          if (vStart >= end || vEnd <= start) {
            return null
          }

          return {
            ...v,
            index: ii,
            crossCount: 0,
            leftIndex: 0,
            duration:
              moment(v.endTime).valueOf() - moment(v.startTime).valueOf(),
          }
        })
        .filter(Boolean) as CalendarEventWithIndex[]

      // 已经计算过的日程不需要再重复计算
      const skipCalc = crossSources.every(v => !!styleMap[v.index]?.width)

      if (crossSources.length > 1 && !skipCalc) {
        /*
         * 表示有日程的时间重叠了，需要平分横向空间（ > 1 是因为包含了当前日程 source）
         * 取出持续时间最长的日程
         */
        const maxEvent = crossSources[0]
        // 持续时间最长的日程集（表现为：在左侧占据的时间、宽度都一样）
        const maxDurationEvents: CalendarEventWithIndex[] = []
        // 其他的交叉的日程按持续时间倒序排列
        const otherEvents: CalendarEventWithIndex[] = []
        // 将交叉的日程分为：持续时间最长的日程集 + 其他的交叉的日程
        crossSources.forEach(v => {
          if (
            v.startTime === maxEvent.startTime &&
            v.endTime === maxEvent.endTime
          ) {
            maxDurationEvents.push(v)
          } else {
            otherEvents.push(v)
          }
        })

        if (otherEvents.length) {
          // 其他的日程按持续时间分组（包含了相互交叉的情况）
          const otherEventsCrossCountDesc: CalendarEventWithIndex[] = []
          for (let i = 0; i < otherEvents.length; i += 1) {
            const iEvent = otherEvents[i]
            const iStart = moment(iEvent.startTime).valueOf()
            const iEnd = moment(iEvent.endTime).valueOf()
            const childEvents: CalendarEventWithIndex[] = []
            const iStartEvents: CalendarEventWithIndex[] = []
            const iContainEvents: CalendarEventWithIndex[] = []
            const iEndEvents: CalendarEventWithIndex[] = []
            const crossListNew = otherEvents.filter(v2 => {
              const v2Start = moment(v2.startTime).valueOf()
              const v2End = moment(v2.endTime).valueOf()
              return !(v2Start >= iEnd || v2End <= iStart)
            })

            if (crossListNew.length > 1) {
              for (let j = 0; j < crossListNew.length; j += 1) {
                const jEvent = crossListNew[j]
                const jStart = moment(jEvent.startTime).valueOf()
                const jEnd = moment(jEvent.endTime).valueOf()

                if (jEvent.index !== iEvent.index) {
                  // 检测和开始时间有交叉的日程
                  if (jStart <= iStart && jEnd >= iStart) {
                    iStartEvents.push(jEvent)
                  }

                  // 检测和结束时间有交叉的日程 fixme 此处竖直方向多个日程（不相互交叉）应该只考虑一个，并且应该是递归处理
                  if (jStart >= iStart && jEnd <= iEnd) {
                    iContainEvents.push(jEvent)
                  }

                  // 检测和结束时间有交叉的日程
                  if (jEnd >= iEnd && jStart <= iEnd) {
                    iEndEvents.push(jEvent)
                  }

                  // 检测有交叉的并且 duration 少于自己的日程，因为一开始就按 duration 做了降序处理
                  if (
                    !(jStart >= iEnd || jEnd <= iStart) &&
                    jEvent.duration <= iEvent.duration
                  ) {
                    childEvents.push(jEvent)
                  }
                }
              }

              iEvent.childEvents = childEvents

              if (!iEvent.crossCount) {
                if (iStartEvents.length === 0 && iEndEvents.length === 0) {
                  // 理论上不会出现这种情况
                  iEvent.crossCount = 0
                } else {
                  // 应该平分几等份空间，即宽度：取和开始时间、结束时间交叉最多的
                  iEvent.crossCount =
                    Math.max(
                      iStartEvents.length,
                      iContainEvents.length,
                      iEndEvents.length,
                    ) + 1
                }
              }

              // 计算 left：如果从自己之前的日程中的 childEvents 找到了自己，那么 left + 1，即挨个往右平移
              const temp = otherEvents
                .slice(0, i)
                .filter(
                  k =>
                    k.childEvents?.some(l => l.index === iEvent.index) || false,
                )

              iEvent.leftIndex = temp.length
            } else {
              iEvent.leftIndex = 0
              iEvent.crossCount = 0
            }

            otherEventsCrossCountDesc.push(iEvent)
          }

          const counts = otherEventsCrossCountDesc.map(v => v.crossCount || 0)
          let maxCrossCount = Math.max(...counts)
          // 1 右侧的日程没有相互交叉的情况，平分空间
          maxCrossCount =
            !maxCrossCount && otherEventsCrossCountDesc.length
              ? 1
              : maxCrossCount

          // 分成了几等份：右侧最多的 + maxDurationEvents.length
          const maxCount = maxCrossCount + maxDurationEvents.length
          // 持续时间最长的日程的宽度
          const minWidth = 1 / maxCount
          // 剩余空间
          const traceWidth = 1 - minWidth * maxDurationEvents.length
          // 剩余日程的宽度除去持续时间最长的日程的宽度之后，均分
          otherEventsCrossCountDesc.forEach(v => {
            if (!styleMap[v.index]) {
              if (v.crossCount === 0) {
                styleMap[v.index] = {
                  left: `${minWidth * maxDurationEvents.length * 100}%`,
                  width: `${traceWidth * 100}%`,
                }
              } else {
                styleMap[v.index] = {
                  left: `${
                    100 * ((v.leftIndex + maxDurationEvents.length) / maxCount)
                  }%`,
                  width: `${
                    ((v.crossCount === maxCrossCount ? 1 : v.crossCount) /
                      maxCrossCount) *
                    traceWidth *
                    100
                  }%`,
                }
              }
            }
          })

          // duration 最长的挨个排列
          maxDurationEvents.forEach((v, ii) => {
            if (!styleMap[v.index]) {
              styleMap[v.index] = {
                left: `${minWidth * 100 * ii}%`,
                width: `${minWidth * 100}%`,
              }
            }
          })
        } else {
          // 只包含了时间完全一样的日程，直接平分即可，不需要复杂的计算
          const width = 1 / maxDurationEvents.length
          // 这里使用 %，兼顾 chalk 和 go
          maxDurationEvents.forEach((v, ii) => {
            if (!styleMap[v.index]) {
              styleMap[v.index] = {
                left: `${width * ii * 100}%`,
                width: `${width * 100}%`,
              }
            }
          })
        }
      } else {
        // 同一时间没有日程的时间相互交叉，铺满整个父元素
        styleMap[index] = styleMap[index] ?? {
          left: '0%',
          width: '100%',
        }
      }

      return {
        source,
        type: source.type,
        title: source.title,
        start: source.startTime,
        end: source.endTime,
        hideActions:
          styleMap[index]?.width !== '100%' ||
          height <= WEB_EVENT_CARD_MIN_HEIGHT, // 宽度小于 1/2 或者高度小于 36（只有一行），不显示
        style: {
          ...(styleMap[index] || {}),
          position: 'absolute',
          borderRightWidth: 2,
          borderRightColor: '#fff',
          top: top + offsetTop,
          height: hasPrevConnected ? height - 3 : height,
        },
      }
    }) as CalendarEventProps[]
}

/**
 * 获取全天日程中指定日期的 events
 *
 * @param sources - 全天日程
 * @param date - 日期
 * @returns 全天日程中指定日期的 events
 */
export const filterSomeDaySources = (
  sources: OriginCalendarEvent[],
  date: string,
) => {
  const tempDate = moment(`${date} 12:00:00`).valueOf()
  const list = sources.filter(v => {
    const start = moment(v.startTime).valueOf()
    const end = moment(v.endTime).valueOf()
    return tempDate >= start && tempDate <= end
  })

  return orderBy(list, 'custom.createdAt', 'desc')
}

/**
 * （全天、日视图）返回携带有坐标值的 events
 *
 * @param sources - 全天日程
 * @returns 携带有坐标值的 events
 */
export const formatDayViewFullDayCalendarEvents = (
  sources: OriginCalendarEvent[],
): CalendarEventProps[] => {
  if (!sources.length) return []
  // 日式图，顺着排列，不需要计算坐标
  return sources.map((v, i) => ({
    source: v,
    type: v.type,
    title: v.title,
    start: v.startTime,
    end: v.endTime,
    style: {
      position: 'absolute',
      left: 0,
      top: i * FULL_DAY_ITEM_HEIGHT + FULL_DAY_ITEM_MARGIN_TOP * i,
      height: FULL_DAY_ITEM_HEIGHT,
      width: '100%',
    },
  }))
}

/**
 * （全天、周视图）返回携带有坐标值的 events
 *
 * @param sources - 全天日程
 * @param dates - 日期列表
 * @returns 携带有坐标值的 events
 */
export const formatWeekViewFullDayCalendarEvents = (
  sources: OriginCalendarEvent[],
  dates: string[],
): CalendarEventProps[] => {
  if (!sources.length) return []
  const leftMap = sources.reduce((m, v, i) => {
    const startDate = v.startTime.substr(0, 10)
    const leftIndex = dates.findIndex(_ => _ === startDate)
    if (leftIndex !== -1) {
      m[i] = `${((leftIndex / dates.length) * 100).toFixed(2)}%`
    }

    return m
  }, {} as { [key: string]: string })

  const styleMap: { [key: string]: any } = {}
  // 周式图，需要检测重叠、按优先级排序
  return sources.map((v, i) => {
    styleMap[i] = styleMap[i] || {}
    const start = moment(v.startTime).valueOf()
    const end = moment(v.endTime).valueOf()
    styleMap[i].left = leftMap[i] || '0.00%'
    styleMap[i].width = `${(
      ((end - start) / ONE_DAY_TIMESTAMP / dates.length) *
      100
    ).toFixed(2)}%`

    if (styleMap[i].top == null) {
      let crossList = sources
        .map((_, ii) => {
          if (styleMap[i].left === leftMap[ii]) {
            return { ..._, index: ii }
          }

          const vStart = moment(_.startTime).valueOf()
          const vEnd = moment(_.endTime).valueOf()
          if (vStart > end || vEnd < start) {
            return null
          }

          return { ..._, index: ii }
        })
        .filter(Boolean) as CalendarEventWithIndex[]

      crossList = crossList.sort((a, b) => {
        const aStart = moment(a.startTime).valueOf()
        const aEnd = moment(a.endTime).valueOf()
        const bStart = moment(b.startTime).valueOf()
        const bEnd = moment(b.endTime).valueOf()
        if (aStart < bStart) {
          return 1
        }

        if (aStart === bStart && aEnd < bEnd) {
          return 1
        }

        return -1
      })

      crossList.forEach((_, ii) => {
        styleMap[_.index] = styleMap[_.index] || {}
        styleMap[_.index].top =
          ii * FULL_DAY_ITEM_HEIGHT + FULL_DAY_ITEM_MARGIN_TOP * ii
      })
    }

    return {
      source: v,
      type: v.type,
      title: v.title,
      start: v.startTime,
      end: v.endTime,
      style: {
        position: 'absolute',
        top: 0,
        height: FULL_DAY_ITEM_HEIGHT,
        ...styleMap[i],
      },
    }
  })
}

/**
 * 解析日期至周几
 * date -> 周四
 *
 * @param date - 日期
 * @returns 周几
 */
export const parseWeekday = (date: Moment) => date.format('dddd')

/**
 * 解析日期至某月某日
 * date -> 9 月 4 号
 *
 * @param date - 日期
 * @returns 某月某日
 */
export const parseMonthAndDay = (date: Moment) => date.format('MMMDo')

/**
 * 根据传入的时间生成日历组件可用的那一周 7 天的数据结构
 *
 * @param date - 日期
 * @param options - 参数
 * @param options.includeSaturday - 是否包含周六, 默认 true
 * @param options.includeSunday - 是否包含周日, 默认 true
 * @returns 7 天的数据结构
 */
export const getCurrentWeekdays = (
  date: Date,
  options?: {
    includeSaturday: boolean
    includeSunday: boolean
  },
): WeekdayType[] => {
  const { includeSaturday = true, includeSunday = true } = options || {}
  return compact(
    times(7, index => {
      if (index === 5 && !includeSaturday) return null
      if (index === 6 && !includeSunday) return null
      const value = moment(date).weekday(index)

      return {
        label: value.format('dd'),
        value,
        content: value.format('DD'),
      }
    }),
  )
}

/**
 * 格式化课程时间
 *
 * @param time - 课程时间
 * @returns 格式化后的课程时间
 */
export const formatLessonTime = (time: string) => {
  const timeParts = time.split(':')
  return timeParts.length === 3 ? `${timeParts[0]}:${timeParts[1]}` : time
}

/**
 * 生成 00:00 - 24:00 时间表
 *
 * @param props - 参数
 * @param props.start - 开始时间
 * @param props.end - 结束时间
 * @returns 时间表
 */
export const getTimeSlotsByStartAndEnd = ({
  start,
  end,
}: {
  start: number
  end: number
}) => {
  const timeSlots = []

  if (start >= 0 && end <= 24 && start <= end) {
    const sign = end === 24

    for (let i = start; i <= (sign ? 23 : end); i += 1) {
      timeSlots.push(moment().set({ hour: i, minute: 0 }).format('HH:mm'))
    }

    if (sign) {
      timeSlots.push('24:00')
    }
  }

  return timeSlots
}

/**
 * 生成给定日期的所在周的日期数据
 *
 * @param date - 日期
 * @param options - 参数
 * @param options.includeSaturday - 是否包含周六, 默认 true
 * @param options.includeSunday - 是否包含周日, 默认 true
 * @returns 日期数据
 */
export function useGenerateWeekDates(
  date: MomentInput,
  options?: {
    includeSaturday: boolean
    includeSunday: boolean
  },
) {
  return React.useMemo(
    () =>
      getCurrentWeekdays(moment(date).toDate(), options).map(({ value }) =>
        value.format('YYYY-MM-DD'),
      ),
    [date, options],
  )
}

/**
 * 按指定 key 排序（升序）
 *
 * @param sources - 源数据
 * @param key - 排序的 key
 * @returns 排序后的数据
 */
export const sortCalendarEventsAscByTime = (
  sources: OriginCalendarEvent[],
  key: 'startTime' | 'endTime',
): OriginCalendarEvent[] => {
  if (sources.length === 0) return []
  if (!sources[0][key]) return sources
  // 取所有日程的时分秒进行排序，所以日期设置成一样的，这里写死为 2020-09-02
  return sources.sort((a: OriginCalendarEvent, b: OriginCalendarEvent) =>
    moment(`2020-09-02 ${a[key].slice(-8)}`).valueOf() >
    moment(`2020-09-02 ${b[key].slice(-8)}`).valueOf()
      ? 1
      : -1,
  )
}

/**
 * 根据最小时间和最大时间生成时间表
 *
 * @param minTime - 最小时间
 * @param maxTime - 最大时间
 * @returns 时间表
 */
export const genTimeSlotsByMinMaxTime = (minTime: string, maxTime: string) => {
  const currentDate = moment().format('YYYY-MM-DD')
  const min = +moment(`${currentDate} ${minTime}`).format('HH')
  const max = +moment(`${currentDate} ${maxTime}`).format('HH')
  return getTimeSlotsByStartAndEnd({ start: min, end: max })
}

/**
 * 处理课程标签字段
 *
 * @param events - 日程数据
 * @returns 处理后的日程数据
 */
export const translateCalendarTag = (
  events?: OriginCalendarEvent[] | null,
): OriginCalendarEvent[] | null => {
  if (!events) return null
  const newEvent = events.map(ev => ({
    ...ev,
    custom: {
      ...ev.custom,
      tagName: getTranslatedTag(ev?.custom?.['tagName']),
    },
  }))

  return newEvent as OriginCalendarEvent[]
}

/**
 * 获取经业务处理过的日程数据
 * 各业务的日程卡片显示不尽相同，有些需要注入更多的业务信息才能满足需求
 *
 * @param props - 参数
 * @param props.events - 日程数据
 * @param props.attendancesDisable - 是否禁用考勤
 * @returns 经业务处理过的日程数据
 */
export const useProcessedEvents = ({
  events,
  attendancesDisable,
}: {
  events?: OriginCalendarEvent[] | null
  attendancesDisable?: boolean
}) => {
  const {
    lesson: lessonEvents = [],
    dorm: dormEvents = [],
    group: groupEvents = [],
  } = groupBy(events || [], 'type') as {
    lesson?: ClassEventSource[]
    dorm?: DormEventSource[]
    group?: CustomizedGroupSource[]
  }

  const { groupIdIn, customGroupAttendanceTimes } =
    useCustomGroupIdsAndGroupTimeIds(groupEvents)

  const groupTimeIdIn = customGroupAttendanceTimes?.map(time => time.id) || []
  const lessonBizIdIn = compact(lessonEvents.map(event => event.subject?.id))
  const lessonTimeIdIn = compact(
    lessonEvents.map(event => event.custom.lessonId),
  )

  const dormIdIn = compact(dormEvents.map(event => event.custom.dormIdIn))
  const dormTimeIdIn = compact(dormEvents.map(event => event.custom.timeIdIn))

  const bizIdIn = [...lessonBizIdIn, ...dormIdIn, ...groupIdIn].join(',')
  const timeIdIn = [...lessonTimeIdIn, ...dormTimeIdIn, ...groupTimeIdIn].join(
    ',',
  )

  const { data: attendances, reload: reloadAttendances } =
    attendanceApi$queryAttendancesInfo.useApi(
      {
        bizTypeIn: [
          AttendanceBizTypeEnum.Class,
          AttendanceBizTypeEnum.Dorm,
          AttendanceBizTypeEnum.CustomGroup,
        ].join(','),
        bizIdIn,
        attendanceTimeIdIn: timeIdIn,
        query: {
          paginated: 0,
          expand: ['checkedAttendanceTimeIds'] as const,
        },
      },
      {
        disable: !bizIdIn || !timeIdIn || attendancesDisable,
      },
    )

  // 处理日程
  const processedEvents = React.useMemo(() => {
    if (!events || !attendances) return events

    return events.map(event => {
      if (event.type === CalendarEventType.Lesson) {
        const evt = event as ClassEventSource
        // 把考勤主体注入到对应的课节
        const atten = attendances.find(
          attendance =>
            attendance.bizId === evt.subject.id &&
            attendance.bizType === AttendanceBizTypeEnum.Class,
        )

        evt.custom.attendance = atten
        evt.custom.attendanceEnabled = !!atten?.enable

        if (atten?.enable) {
          evt.custom.attendanceButtonText =
            atten?.checkedAttendanceTimeIds?.includes(evt.custom.lessonId)
              ? $t('修改考勤')
              : $t('录入考勤')
        }

        return evt
      }

      if (event.type === CalendarEventType.Dorm) {
        const evt = event as DormEventSource
        // 把考勤主体注入到对应的宿舍
        const atten = attendances.find(
          attendance =>
            attendance.bizType === AttendanceBizTypeEnum.Dorm &&
            evt.custom.dormIdIn.includes(`${attendance.bizId}`),
        )

        evt.custom.attendance = atten
        evt.custom.attendanceEnabled = !!atten?.enable
        if (atten?.enable) {
          evt.custom.attendanceButtonText =
            atten?.checkedAttendanceTimeIds?.some(v =>
              evt.custom.dormIdIn.includes(`${v}`),
            )
              ? $t('修改考勤')
              : $t('录入考勤')
        }
      }

      if (event.type === CalendarEventType.CustomGroup) {
        const evt = event as CustomizedGroupSource
        const curCustomTimes = customGroupAttendanceTimes?.find(
          cus =>
            cus.attendanceBizId === evt.custom.id &&
            cus.startAt === evt.startTime &&
            cus.endAt === evt.endTime,
        )

        // 把考勤主体注入到对应的通用群组
        const atten = attendances.find(
          attendance =>
            attendance.bizType === AttendanceBizTypeEnum.CustomGroup &&
            evt.custom.id === attendance.bizId,
        )

        evt.custom.attendance = atten
        evt.custom.attendanceEnabled = !!atten?.enable
        evt.custom.attendanceTimeId = curCustomTimes?.id

        if (atten?.enable) {
          evt.custom.attendanceButtonText =
            curCustomTimes?.id &&
            atten.checkedAttendanceTimeIds.includes(curCustomTimes.id)
              ? $t('修改考勤')
              : $t('录入考勤')
        }
      }

      return event
    })
  }, [attendances, events, customGroupAttendanceTimes])

  const reloadEventRelateds = () => {
    reloadAttendances()
  }

  return [translateCalendarTag(processedEvents), reloadEventRelateds] as const
}

/**
 * 获取日程数据
 *
 * @param date - 日期
 * @param showPupilEvents - 是否显示学生日程
 * @param role - 角色
 * @returns 日程数据
 */
export const useCalendarData = (
  date: moment.Moment,
  showPupilEvents: boolean | undefined,
  role: RoleEnum,
) => {
  const dates = useGenerateWeekDates(date.format('YYYY-MM-DD'))
  const [pupil] = useLoadCurrentPupil()

  const {
    data: pupilEvents,
    loading: pupilLoading,
    reload: pupilReloadEvents,
  } = useFindPersonalSchedules({
    startTime: `${dates[0]} 00:00:00`,
    endTime: `${dates[6]} 23:59:59`,
    id: role === RoleEnum.Guardian ? pupil?.id : undefined,
  })

  const currentReflection = useCurrentReflection()
  const {
    data: events,
    loading,
    reload: reloadEvents,
  } = useFindPersonalSchedules({
    startTime: `${dates[0]} 00:00:00`,
    endTime: `${dates[6]} 23:59:59`,
    id: currentReflection.id,
  })

  const fullData = React.useMemo(
    () => [...(events || []), ...(pupilEvents || [])],
    [pupilEvents, events],
  )

  const mergeData = React.useMemo(() => {
    let data: OriginCalendarEvent[] = []

    if (isNil(showPupilEvents)) {
      data = [...(events || []), ...(pupilEvents || [])]
    } else if (showPupilEvents) {
      data = pupilEvents || []
    } else if (!showPupilEvents) {
      data = events || []
    }

    return data.sort(
      (a, b) => moment(a.startTime).valueOf() - moment(b.startTime).valueOf(),
    )
  }, [events, pupilEvents, showPupilEvents])

  return [
    mergeData,
    pupil,
    loading,
    pupilLoading,
    () => {
      reloadEvents()
      if (role === RoleEnum.Guardian) {
        pupilReloadEvents()
      }
    },
    fullData,
  ] as const
}

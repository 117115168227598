import { moment } from '@seiue/moment'
import { userAgent } from '@seiue/ua'
import { Dropdown, Icon, Modal } from '@seiue/ui'
import React, { useMemo } from 'react'

import {
  fontSizeToLabel,
  LayoutFontSize,
} from 'packages/feature-utils/personal-configs'
import {
  PluginEnum,
  useHasEnabledParentPlugin,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'

import { ControlBarIconWrapper } from '../styles'

const isAfter2024 = moment().isAfter('2024-11-30')

/**
 * 全局字体大小切换
 *
 * @returns component
 */
export const FontSize: React.FC = () => {
  // 是否已开启高级个性化插件
  const enabledPersonalization = useHasEnabledParentPlugin(
    PluginEnum.Personalization,
  )

  const { options: fontSizeOptions } = useMemo(fontSizeToLabel, [])

  return !enabledPersonalization || isAfter2024 ? null : (
    <Dropdown
      value={LayoutFontSize.Normal}
      closeOnChanged={false}
      trigger={'hover'}
      placement="bottom"
      items={fontSizeOptions}
      onChange={({ value }) => {
        if (value === LayoutFontSize.Large) {
          Modal.warning({
            title: $t('提示'),
            content: $t(
              '大字体模式会导致一些系统功能的异常，所以已暂时禁用。请使用浏览器自带的网页放大功能，快捷键为{shortcut}。',
              {
                shortcut: userAgent.isMacOS
                  ? '「Command (⌘) + +」'
                  : '「Ctrl + +」',
              },
            ),
          })
        }
      }}
    >
      <ControlBarIconWrapper>
        <Icon icon="Font" size={14} color="white" />
      </ControlBarIconWrapper>
    </Dropdown>
  )
}

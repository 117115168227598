import { isAxiosError } from '@seiue/axios'
import { reportToSentry } from '@seiue/sentry'
import { compact, groupBy } from '@seiue/util'
import { atom, useAtom, useAtomValue } from 'jotai'
import React, { useEffect, useMemo } from 'react'

import { isPluginForPlatform } from 'packages/feature-utils/plugins/utils'
import { $t } from 'packages/locale'
import { AppNameEnum } from 'packages/no-code/apps/types'
import { isNoCodeAppPlugin } from 'packages/no-code/basic/utils/permission'
import { Expand, QueryOptions } from 'packages/sdks-next'
import {
  EnabledSchoolPlugin,
  QueryEnabledSchoolPluginsQuery,
  SchoolPluginStatusEnum,
  Term,
  classinApi$loadPersonalClassinAccount,
  schoolPluginApi$queryEnabledSchoolPlugins,
} from 'packages/sdks-next/chalk'

import { classInAccountAtom } from '../class-in'
import { useTerms } from '../terms'

import {
  enabledPluginsAtom,
  findEnabledChildPluginsAtom,
  findEnabledParentPluginAtom,
  findManageableChildPluginsAtom,
  findManageableParentPluginAtom,
  findUsableOrManageableChildPluginAtom,
  findUsableOrManageableChildPluginsAtom,
  findUsablePluginsAtom,
  hasUsablePluginAtom,
  manageablePluginsAtom,
  usablePluginsAtom,
} from './atoms'
import { ModuleEnum, PluginEnum } from './types'

/*
 * 虽然 ClassIn 的插件可见范围为所有用户，但具体的业务可见范围要根据人员注册情况来判断.
 * 等第二个插件出现类似需求时, 可以迁移到插件初始化配置中, 添加一个 async isEnabled.
 */
const getClassInAccount = async () => {
  try {
    const { data } = await classinApi$loadPersonalClassinAccount.api()
    return data
  } catch (e) {
    // 404 是尚未注册, 不属于异常, 其他错误上报 sentry
    if (!isAxiosError(e, 404)) reportToSentry(e)
    return null
  }
}

const initedAtom = atom<'notInited' | 'inited' | 'initing'>('notInited')

/**
 * Hook of 当前学校启用的插件
 *
 * @param options - 选项
 * @param options.disable - 值为 true 时禁用请求
 * @returns [当前学校启用的插件, 数据已初始化, reload]
 */
export const useEnabledPlugins = ({
  disable,
}: {
  disable?: boolean
} = {}) => {
  const [inited, setInited] = useAtom(initedAtom)
  const [plugins, set] = useAtom(enabledPluginsAtom)

  const [, setClassInAccount] = useAtom(classInAccountAtom)

  const {
    data: _plugins,
    loading,
    refetching,
    reload: _reload,
  } = schoolPluginApi$queryEnabledSchoolPlugins.useApi(
    {
      expand: ['plugin'] as const,
    },
    {
      staleTime: 10 * 60,
      disable,
    },
  )

  useEffect(() => {
    if (!_plugins || loading || refetching || inited !== 'notInited') return

    setInited('initing')

    const classIn = _plugins?.find(p => p.pluginName === PluginEnum.ClassIn)
    if (!classIn) {
      set(_plugins)
      setInited('inited')
      return
    }

    getClassInAccount().then(classInAccount => {
      classIn.isVisible = !!classInAccount
      setClassInAccount(classInAccount)

      set(_plugins)
      setInited('inited')
    })
  }, [_plugins, inited, loading, refetching, set, setClassInAccount, setInited])

  const reload = React.useCallback(async () => {
    await _reload()
    setInited('notInited')
  }, [_reload, setInited])

  return [plugins, inited === 'inited', reload] as const
}

/**
 * 按名称搜索当前学校已启用的插件
 *
 * @param name - 插件名
 * @returns 插件列表
 */
export const useFindEnabledParentPlugin = (
  name: ModuleEnum | PluginEnum | AppNameEnum | string,
) => {
  return useAtom(findEnabledParentPluginAtom(name))[0]
}

/**
 * 检查当前学校是否已启用指定插件
 *
 * @param name - 插件名
 * @returns 是否
 */
export const useHasEnabledParentPlugin = (
  name: ModuleEnum | PluginEnum | AppNameEnum | string,
) => {
  return !!useFindEnabledParentPlugin(name)
}

/**
 * 返回一个检查当前用户是否为指定插件管理员的函数
 *
 * @returns 检查函数
 */
export const useHasManageablePluginFunction = () => {
  const [manageables] = useAtom(manageablePluginsAtom)

  return (
    name: ModuleEnum | PluginEnum | AppNameEnum,
    { id }: { id?: number } = {},
  ) => {
    return manageables.some(p => {
      return p.pluginName === name && (!id || id === p.id)
    })
  }
}

/**
 * 检查当前用户是否为指定插件管理员
 *
 * @param name - 插件名
 * @param options - 选项
 * @param options.id - 可进一步指定插件 id
 * @returns 是否
 */
export const useHasManageablePlugin = (
  name: ModuleEnum | PluginEnum | AppNameEnum,
  options: { id?: number } = {},
) => {
  return useHasManageablePluginFunction()(name, options)
}

/**
 * 返回当前用户是管理员的指定名称的子插件列表
 *
 * @param name - 插件名
 * @returns 子插件列表
 */
export const useFindManageableChildPlugins = (
  name: ModuleEnum | PluginEnum | AppNameEnum,
) => {
  return useAtom(findManageableChildPluginsAtom(name))[0]
}

/**
 * 按名称筛选当前用户可管理或可使用的子插件列表
 *
 * @param name - 插件名
 * @returns 子插件列表
 */
export const useUsableOrManageableChildPlugins = (
  name: ModuleEnum | PluginEnum | AppNameEnum,
) => {
  return useAtomValue(findUsableOrManageableChildPluginsAtom(name))
}

/**
 * 按名称筛选当前用户可使用的子插件列表
 *
 * @param name - 插件名
 * @returns 子插件列表
 */
export const useUsableChildPlugins = (
  name: ModuleEnum | PluginEnum | AppNameEnum,
) => {
  return useAtomValue(findUsablePluginsAtom(name)).filter(
    plugin => !!plugin.parentId,
  )
}

/**
 * 返回当前用户是管理员的指定名称的父插件
 *
 * @param name - 插件名
 * @returns 父插件
 */
export const useFindManageableParentPlugin = (
  name: ModuleEnum | PluginEnum | AppNameEnum,
) => {
  return useAtom(findManageableParentPluginAtom(name))[0]
}

/**
 * 返回一个获取当前用户是管理员的指定名称的父插件的函数
 *
 * @returns 获取函数
 */
export const useFindManageableParentPluginFunction = () => {
  const [manageablePlugins] = useAtom(manageablePluginsAtom)

  return (name: ModuleEnum | PluginEnum) => {
    return manageablePlugins.find(p => p.pluginName === name && !p.parentId)
  }
}

/**
 * 返回所有当前用户在可见范围中的插件列表
 *
 * @returns 插件列表
 */
export const useUsablePlugins = () => {
  return useAtom(usablePluginsAtom)[0]
}

/**
 * 返回当前用户在可见范围中的指定名称的插件列表
 *
 * @param name - 插件名
 * @returns 插件列表
 */
export const useFindUsablePlugins = (
  name: ModuleEnum | PluginEnum | AppNameEnum,
) => {
  return useAtom(findUsablePluginsAtom(name))[0]
}

/**
 * 返回一个获取当前用户在可见范围中的指定名称的插件列表的函数
 *
 * @returns 获取函数
 */
export const useFindUsablePluginsFunction = () => {
  const [usablePlugins] = useAtom(usablePluginsAtom)

  return (name: ModuleEnum | PluginEnum) => {
    return usablePlugins.filter(p => p.pluginName === name)
  }
}

/**
 * 返回一个检查当前用户是否在指定插件的可见范围中的函数
 *
 * @returns 检查函数
 */
export const useHasUsablePluginFunction = () => {
  const [usablePlugins] = useAtom(usablePluginsAtom)

  return (name: ModuleEnum | PluginEnum | AppNameEnum) => {
    return usablePlugins.some(p => p.pluginName === name)
  }
}

/**
 * 返回一个检查当前学校是否启用了某插件的函数
 *
 * @returns 检查函数
 */
export const useHasEnabledParentPluginFunction = () => {
  const [enabledPlugins] = useAtom(enabledPluginsAtom)

  return React.useCallback(
    (name: ModuleEnum | PluginEnum | AppNameEnum) => {
      return enabledPlugins.some(p => p.pluginName === name && !p.parentId)
    },
    [enabledPlugins],
  )
}

/**
 * 返回当前学校启用的指定名称的子插件列表
 *
 * @param name - 插件名
 * @returns 子插件列表
 */
export const useFindEnabledChildPlugins = (
  name: ModuleEnum | PluginEnum | AppNameEnum,
) => {
  return useAtom(findEnabledChildPluginsAtom(name))[0]
}

/**
 * 检查当前用户是否在指定应用的可见范围中。
 *
 * 注意：对于扩展类插件，不应该使用该函数，应该直接使用 `useHasEnabledParentPlugin()`
 *
 * @param name - 插件名
 * @returns 是否
 */
export const useHasUsablePlugin = (
  name: ModuleEnum | PluginEnum | AppNameEnum,
) => {
  return useAtom(hasUsablePluginAtom(name))[0]
}

/**
 * 返回一个函数, 可用于检查当前用户是否有指定插件的任意权限 (是管理员或在可见范围中)
 *
 * 同上，此函数仅用于应用类插件。
 *
 * @returns 检查函数
 */
export const useHasPluginWithAnyPermissionFunction = () => {
  const hasUsablePlugin = useHasUsablePluginFunction()
  const hasManageablePlugin = useHasManageablePluginFunction()

  return (name: ModuleEnum | PluginEnum | AppNameEnum) =>
    hasUsablePlugin(name) || hasManageablePlugin(name)
}

/**
 * 检查当前用户是否有指定插件的任意权限 (是管理员或在可见范围中)
 *
 * 同上，此函数仅用于应用类插件。
 *
 * @param name - 插件名
 * @returns 是否
 */
export const useHasPluginWithAnyPermission = (
  name: ModuleEnum | PluginEnum | AppNameEnum,
) => {
  const usable = useHasUsablePlugin(name)
  const manageable = useHasManageablePlugin(name)

  return usable || manageable
}

/**
 * 检查当前用户是否有任意插件的管理权限
 *
 * @returns 是否
 */
export const useHasAnyManageablePlugin = () => {
  const manageablePlugins = useAtomValue(manageablePluginsAtom)
  return !!manageablePlugins.length
}

/**
 * 根据插件 id 获取当前用户可管理或可使用的子插件
 *
 * @param pluginId - 插件 id
 * @returns 子插件
 */
export const useUsableOrManageableChildPlugin = (pluginId: number) => {
  return useAtomValue(findUsableOrManageableChildPluginAtom(pluginId))
}

type PluginGroup = Term & {
  plugins: Expand<EnabledSchoolPlugin, ['plugin']>[]
}

/**
 * 获取应用中心内的插件，并按分组组织数据
 *
 * @param args - 参数
 * @param args.query - 额外的查询参数
 * @param args.options - 选项
 * @param args.filterByIsVisible - 是否按 isVisible 字段过滤显示
 * @param args.filterByIsAdmin - 是否按 isAdmin 字段过滤显示
 * @returns 插件列表/按组归类的插件列表/是否加载中
 */
export const useQueryAppCenterPluginGroup = ({
  query,
  options,
  filterByIsVisible = true,
  filterByIsAdmin = true,
}: {
  query?: QueryEnabledSchoolPluginsQuery
  options?: QueryOptions
  filterByIsVisible?: boolean
  filterByIsAdmin?: boolean
} = {}) => {
  const { data: groups, loading: groupLoading } = useTerms({
    type: 'application.group',
  })

  const { data: plugins, loading: pluginsLoading } =
    schoolPluginApi$queryEnabledSchoolPlugins.useApi(
      {
        entrypointsOverlaps: 'application_center',
        isApp: true,
        enabled: true,
        status: SchoolPluginStatusEnum.Installed,
        paginated: 0,
        ...query,
        // expand 在 query 下面是为了 expand 类型推导，如果连 expand 都需要被覆写，则单独编写覆写逻辑
        expand: ['plugin'] as const,
      },
      options,
    )

  // isVisible 不支持筛选，所以需要自己过滤
  const visiblePlugins = useMemo(
    () =>
      plugins?.filter(plugin => {
        if (isNoCodeAppPlugin(plugin) && !isPluginForPlatform(plugin, 'web')) {
          return false
        }

        if (filterByIsVisible && filterByIsAdmin) {
          return plugin.isVisible || plugin.isAdmin
        }

        if (filterByIsVisible) {
          return plugin.isVisible
        }

        return plugin.isAdmin
      }),
    [filterByIsAdmin, filterByIsVisible, plugins],
  )

  const groupedPlugins = useMemo<PluginGroup[]>(() => {
    const pluginsByGroupdId = groupBy(visiblePlugins, 'groupId')

    const uncategorizedPlugins = pluginsByGroupdId[0]
    const grouped =
      groups?.map(group => {
        const pluginsUnderGroup = pluginsByGroupdId[group.id]

        return {
          ...group,
          plugins: pluginsUnderGroup ?? [],
        }
      }) ?? []

    return compact([
      ...grouped,
      uncategorizedPlugins?.length
        ? {
            id: 0,
            name: $t('未分类'),
            plugins: uncategorizedPlugins,
            schoolId: 0,
            parentId: 0,
            weight: 0,
            type: 'application.group',
            createdAt: '',
          }
        : null,
    ])
  }, [groups, visiblePlugins])

  return {
    plugins: visiblePlugins,
    group: groupedPlugins,
    loading: groupLoading || pluginsLoading,
  }
}

export interface MessageOwnerInterface {
  /**
   * message owner 的 id
   */
  id?: number
}

export class MessageOwner implements MessageOwnerInterface {
  /**
   * message owner 的 id
   */
  id?: number

  constructor(props: MessageOwnerInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
  }

  static propKeys = ['id']
}

import { PluginEnum } from 'packages/feature-utils/plugins'
import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.DormAssessment,
  getLabel: () => $t('宿舍'),
  labelStyle: calculateLabelStyle('#F7E9FA', '#9E37B4'),
  icon: `plugin.${PluginEnum.Dorm}`,
  messages: [
    // 加减分
    'raw_score_addition_for_teacher',
    'raw_score_addition_for_student',
    'raw_score_addition_for_cc_teacher',
    'raw_score_addition_for_cc_guardian',
    // 标签增删
    'raw_score_tag_for_teacher',
    'raw_score_tag_for_student',
    'raw_score_tag_for_cc_teacher',
    'raw_score_tag_for_cc_guardian',
    // 个性化评价增删
    'raw_score_special_for_teacher',
    'raw_score_special_for_student',
    'raw_score_special_for_cc_teacher',
    'raw_score_special_for_cc_guardian',
    // 普通分数发布、修改
    'score_for_teacher',
    'score_for_student',
    'score_for_cc_teacher',
    'score_for_cc_guardian',
    // 评价项发布、撤回、驳回
    'item_operated_for_teacher',
    'item_operated_for_cc_teacher',
    'item_operated_for_cc_guardian',
    'item_operated_for_student',
  ],
})

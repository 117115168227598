import { DependPlugin, DependPluginInterface } from './depend-plugin'
import { PluginTypeEnum } from './plugin-type-enum'
import { PluginSourceEnum } from './plugin-source-enum'
import { PluginDomainEnum } from './plugin-domain-enum'
import { PluginKindEnum } from './plugin-kind-enum'
import { PluginApplicationTypeEnum } from './plugin-application-type-enum'
import { PluginCategoryEnum } from './plugin-category-enum'

export interface ExpandPluginInterface {
  /**
   * expand，依赖的插件
   */
  dependPlugins?: DependPluginInterface[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  type: PluginTypeEnum
  source: PluginSourceEnum
  name: string
  domain?: PluginDomainEnum | null
  kind: PluginKindEnum
  applicationType: PluginApplicationTypeEnum
  /**
   * 使用的学校数量
   */
  usedSchoolNum: number
  category: PluginCategoryEnum
  label: string
  icon: string
  simpleDescription: string
  description: string
  platforms: string[]
  tags: string[]
  popular: boolean
  applicable: boolean
  needApproval: boolean
  author: string
  entryUrls: object
  settingUrl: string
  isSpecial?: boolean
  /**
   * 依赖的 plugin_names
   */
  depends: string[]
  /**
   * 模板属性，创建系统类型的学校插件时继承，目前支持 scopes（已废弃） 字段，为空标识未设置
   * display_scopes 字段，默认可见范围
   * disabled_set_display_scopes 字段，是否可自定义可见范围
   */
  templateAttributes?: object | null
}

export class ExpandPlugin implements ExpandPluginInterface {
  /**
   * expand，依赖的插件
   */
  dependPlugins?: DependPlugin[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  type: PluginTypeEnum
  source: PluginSourceEnum
  name: string
  domain?: PluginDomainEnum | null
  kind: PluginKindEnum
  applicationType: PluginApplicationTypeEnum
  /**
   * 使用的学校数量
   */
  usedSchoolNum: number
  category: PluginCategoryEnum
  label: string
  icon: string
  simpleDescription: string
  description: string
  platforms: string[]
  tags: string[]
  popular: boolean
  applicable: boolean
  needApproval: boolean
  author: string
  entryUrls: object
  settingUrl: string
  isSpecial?: boolean
  /**
   * 依赖的 plugin_names
   */
  depends: string[]
  /**
   * 模板属性，创建系统类型的学校插件时继承，目前支持 scopes（已废弃） 字段，为空标识未设置
   * display_scopes 字段，默认可见范围
   * disabled_set_display_scopes 字段，是否可自定义可见范围
   */
  templateAttributes?: object | null

  constructor(props: ExpandPluginInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      dependPlugins: _dependPlugins,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      type: _type,
      source: _source,
      name: _name,
      domain: _domain,
      kind: _kind,
      applicationType: _applicationType,
      usedSchoolNum: _usedSchoolNum,
      category: _category,
      label: _label,
      icon: _icon,
      simpleDescription: _simpleDescription,
      description: _description,
      platforms: _platforms,
      tags: _tags,
      popular: _popular,
      applicable: _applicable,
      needApproval: _needApproval,
      author: _author,
      entryUrls: _entryUrls,
      settingUrl: _settingUrl,
      isSpecial: _isSpecial,
      depends: _depends,
      templateAttributes: _templateAttributes,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.dependPlugins = _dependPlugins
      ? _dependPlugins.map(i => new DependPlugin(i))
      : _dependPlugins
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
    this.type = _type
    this.source = _source
    this.name = _name
    this.domain = _domain
    this.kind = _kind
    this.applicationType = _applicationType
    this.usedSchoolNum = _usedSchoolNum
    this.category = _category
    this.label = _label
    this.icon = _icon
    this.simpleDescription = _simpleDescription
    this.description = _description
    this.platforms = _platforms
    this.tags = _tags
    this.popular = _popular
    this.applicable = _applicable
    this.needApproval = _needApproval
    this.author = _author
    this.entryUrls = _entryUrls
    this.settingUrl = _settingUrl
    this.isSpecial = _isSpecial
    this.depends = _depends
    this.templateAttributes = _templateAttributes
  }

  static propKeys = [
    'dependPlugins',
    'id',
    'createdAt',
    'updatedAt',
    'type',
    'source',
    'name',
    'domain',
    'kind',
    'applicationType',
    'usedSchoolNum',
    'category',
    'label',
    'icon',
    'simpleDescription',
    'description',
    'platforms',
    'tags',
    'popular',
    'applicable',
    'needApproval',
    'author',
    'entryUrls',
    'settingUrl',
    'isSpecial',
    'depends',
    'templateAttributes',
  ]
}

import { GroupTypeEnum } from './group-type-enum'

export interface GroupRolePermissionInterface {
  /**
   * 群组类型
   */
  groupType: GroupTypeEnum
  /**
   * 成员类型，如管理员等
   */
  memberType: string
  /**
   * 权限名称
   */
  permName: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class GroupRolePermission implements GroupRolePermissionInterface {
  /**
   * 群组类型
   */
  groupType: GroupTypeEnum
  /**
   * 成员类型，如管理员等
   */
  memberType: string
  /**
   * 权限名称
   */
  permName: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: GroupRolePermissionInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      groupType: _groupType,
      memberType: _memberType,
      permName: _permName,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.groupType = _groupType
    this.memberType = _memberType
    this.permName = _permName
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'groupType',
    'memberType',
    'permName',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

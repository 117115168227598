import { Menu as Base, MenuInterface } from '../menu'
import { MenuTypeEnum } from '../menu-type-enum'

export type { MenuInterface } from '../menu'

/**
 * 菜单
 */
export class Menu extends Base {
  /**
   * 是否为菜单组
   *
   * @param menu - 菜单
   * @returns 是否
   */
  static isMenuGroup(menu: MenuInterface) {
    return isMenuGroup(menu)
  }

  /**
   * 是否为视图菜单
   *
   * @param menu - 菜单
   * @returns 是否
   */
  static isTabMenu(menu: MenuInterface) {
    return isTabMenu(menu)
  }

  /**
   * 是否为子菜单
   *
   * @param menu - 菜单
   * @returns 是否
   */
  static isSubMenu(menu: MenuInterface) {
    return isSubMenu(menu)
  }
}

const isMenuGroup = (menu: MenuInterface) => {
  return menu.menuType === MenuTypeEnum.Group
}

const isTabMenu = (menu: MenuInterface) => {
  return menu.menuType === MenuTypeEnum.Tab
}

const isSubMenu = (menu: MenuInterface) => {
  return menu.menuType === MenuTypeEnum.Menu
}

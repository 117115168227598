/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'

const buildConfig = createConfigBuilder('chalk')

export const personalDictApi$getPersonalDict = {
  config(rid: number, options?: AxiosRequestExtraConfig): AxiosRequestConfig {
    return buildConfig(options, 'get', '/dict/reflections/{rid}/dict', {
      rid,
    })
  },

  /**
   * @summary 获取个人 dict
   * // TODO 考虑更换成 owner 机制？不单单个人？
   * @param rid who's dict
   * @param [options]
   * @return AxiosResponsePromise<object>
   */

  api(
    rid: number,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<object> {
    return axios(personalDictApi$getPersonalDict.config(rid, options)) as any
  },

  useApi<TSelected = object>(
    rid: number,
    queryOptions?: QueryOptionsWithSelect<object, TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<object, TSelected>(
      personalDictApi$getPersonalDict.api,
      'PersonalDict.getPersonalDict',
      queryOptions,
      rid,
    )
  },
}

// @ts-ignore
personalDictApi$getPersonalDict.api._name_ = `PersonalDict.getPersonalDict`

import { MenuItemUnion } from 'packages/web-layout/types'

/**
 * 获取菜单跳转的路径，如果没有 path 则取 subMenu 中第一个菜单的 path
 *
 * @param menu - 菜单项
 * @returns path
 */
export const getDefaultMenuPath = (menu: MenuItemUnion) => {
  const { path } = menu
  const hasSubMenus = 'subMenus' in menu && menu.subMenus?.length
  const defaultPath = hasSubMenus ? menu.subMenus[0].path : path

  return defaultPath || '/404'
}

import { compact, concat, keyBy, uniqBy } from '@seiue/util'

import {
  ExtendedFieldTypeEnum,
  LocalField,
} from 'packages/features/workflows/types'
import {
  FieldPermInterface,
  Field,
  FlowNode,
  NodeTypeEnum,
  Workflow,
  Node,
  FieldTypeEnum,
} from 'packages/sdks-next/form'

/**
 * Get default field permissions by node type
 *
 * @param fields - fields of the node
 * @param nodeType - node type
 * @returns default field permissions
 */
export const getDefaultFieldPermsByNodeType = (
  fields: LocalField[],
  nodeType: NodeTypeEnum,
): FieldPermInterface[] => {
  const defaultFieldPerms: FieldPermInterface[] = []

  const getDefaultFieldPerm = (field: LocalField) => {
    return {
      field: field.name,
      readable: true,
      writable:
        nodeType === NodeTypeEnum.Entry &&
        field.type !== FieldTypeEnum.Paragraph,
    }
  }

  fields.forEach(_field => {
    if (_field.type === ExtendedFieldTypeEnum.Table && _field.fields?.length) {
      defaultFieldPerms.push(
        ...getDefaultFieldPermsByNodeType(_field.fields, nodeType),
      )
    } else {
      defaultFieldPerms.push(getDefaultFieldPerm(_field))
    }
  })

  return defaultFieldPerms
}

const getNodePerms = (currentNode: Node, fields: Field[]) => {
  return uniqBy(
    compact(
      concat(
        currentNode.fieldPerms,
        getDefaultFieldPermsByNodeType(fields, currentNode.type),
      ),
    ),
    'field',
  )
}

/**
 * 获取当前节点的字段权限
 *
 * @param workflow - 审批流信息
 * @param flowNodes - 相关节点
 * @returns 当前节点的字段权限
 */
export const getMyCurrentFieldPerms = (
  workflow?: Pick<Workflow, 'fields' | 'graph'>,
  flowNodes: FlowNode[] = [],
) => {
  if (!workflow) {
    return {
      fieldPermsMap: {},
      fieldPerms: [],
    }
  }

  const {
    fields,
    graph: { nodes },
  } = workflow

  const _flowNodes = [...flowNodes]

  const currentFlowNode = _flowNodes.pop()

  const currentNode = currentFlowNode
    ? nodes.find(node => node.name === currentFlowNode.nodeName) || nodes[0]
    : nodes[0]

  const fieldPerms = getNodePerms(currentNode, fields)

  // 根据路径上的所有节点，合并其可读权限
  _flowNodes.forEach(prevFlowNode => {
    const prevNode = nodes.find(node => node.name === prevFlowNode.nodeName)

    if (!prevNode) return

    const prevNodeFieldPerms = getNodePerms(prevNode, fields)

    prevNodeFieldPerms.forEach(prevNodeFieldPerm => {
      const targetFieldPerm = fieldPerms.find(
        fieldPerm => fieldPerm.field === prevNodeFieldPerm.field,
      )

      if (
        !targetFieldPerm ||
        targetFieldPerm.readable ||
        !prevNodeFieldPerm.readable
      )
        return

      targetFieldPerm.readable = true
    })
  })

  const fieldPermsMap = keyBy(fieldPerms, 'field')

  return {
    fieldPermsMap,
    fieldPerms,
  }
}

/**
 * 将字段列表平铺
 *
 * @param fields - 字段列表
 * @returns 平铺后的字段列表
 */
export const flattenFields = (fields: LocalField[]) => {
  const result: LocalField[] = []

  fields.forEach(field => {
    if (field.type === ExtendedFieldTypeEnum.Table && field.fields?.length) {
      result.push(...flattenFields(field.fields))
    } else {
      result.push(field)
    }
  })

  return result
}

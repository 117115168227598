import { registerMessageNavigator } from 'packages/features/messages/utils'

registerMessageNavigator('dorm.archive_student', message => {
  const { studentIds } = message.attributes as {
    studentIds: number[]
  }

  if (studentIds.length > 1) {
    return {
      modalName: 'StudentArchivedMessageModal',
      options: { messageId: message.id },
    }
  }

  return null
})

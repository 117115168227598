export interface DateRangeInterface {
  /**
   * 开始日期
   */
  startAt: string
  /**
   * 结束日期
   */
  endAt: string
}

export class DateRange implements DateRangeInterface {
  /**
   * 开始日期
   */
  startAt: string
  /**
   * 结束日期
   */
  endAt: string

  constructor(props: DateRangeInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      startAt: _startAt,
      endAt: _endAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.startAt = _startAt
    this.endAt = _endAt
  }

  static propKeys = ['startAt', 'endAt']
}

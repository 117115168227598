import { compact } from '@seiue/util'

/**
 * 判断字符串是否以数字或字母开头
 *
 * @param str - 字符串
 * @returns 是否以数字或字母开头
 */
export const startWithDigitOrLetter = (str: string) => /^[a-zA-Z0-9]/.test(str)

/**
 * 判断字符串是否以数字或字母结尾
 *
 * @param str - 字符串
 * @returns 是否以数字或字母结尾
 */
export const endWithDigitOrLetter = (str: string) => /[a-zA-Z0-9]$/.test(str)

/**
 * 通过判断前后两个相邻字符串，是否分别为数字/字母结束和开头
 * 来决定使用 ' ' 还是 '' 连接
 * 例如：年级 + 班级名，英文用 ' ' 连接，中文用 '' 连接
 *
 * 进行连接前会先过滤掉 falsey values
 *
 * @param args - 要连接的字符串
 * @returns 连接后的字符串
 */
export const i18nConnect = (...args: (string | null | undefined)[]) => {
  return compact(args).reduce((prev, curr) => {
    if (endWithDigitOrLetter(prev) && startWithDigitOrLetter(curr)) {
      return `${prev} ${curr}`
    }

    return `${prev}${curr}`
  }, '')
}

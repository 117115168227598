/**
 * @file 请假信息相关
 */

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator('leave_flow', ({ attributes }) => {
  const attrs = attributes as {
    absenceId?: number
    flowId?: number
    schoolPluginId?: number
    flow?: {
      id: number
      workflowId: number
    }
    commentId?: number
  }

  const flowId = attrs.flowId || attrs.flow?.id

  if (!flowId) return null

  return {
    modalName: 'ShowFlowModal',
    options: {
      workflowBizType: 'absence',
      flowId,
      commentId: attrs?.commentId,
    },
  }
})

registerMessageNavigator(
  'leave_flow.agented_absence_approved',
  ({ attributes }) => {
    const attrs = attributes as {
      absenceId: number
      flowId: number
      schoolPluginId: number
    }

    const { flowId } = attrs || {}

    if (!flowId) return null

    return {
      path: `/profiles/me/recent/absences/${flowId}`,
    }
  },
)

registerMessageNavigator('absence.flow_cc_node', ({ attributes }) => {
  const attrs = attributes as {
    flowId: number
  }

  const { flowId } = attrs || {}

  if (!flowId) return null

  return {
    modalName: 'ShowFlowModal',
    options: {
      workflowBizType: 'absence',
      flowId,
    },
  }
})

registerMessageNavigator(
  'absence.early_ended_for_initiator',
  ({ attributes }) => {
    const attrs = attributes as {
      flowId: number
    }

    const { flowId } = attrs || {}

    if (!flowId) return null

    return {
      modalName: 'ShowFlowModal',
      options: {
        workflowBizType: 'absence',
        flowId,
      },
    }
  },
)

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { ScopeAssignment, ScopeAssignmentInterface } from './scope-assignment'

const buildConfig = createConfigBuilder('chalk')

export const roleApi$ownedPermissions = {
  config(options?: AxiosRequestExtraConfig): AxiosRequestConfig {
    return buildConfig(options, 'get', '/me/permissions', {})
  },

  /**
   * @summary Class OwnedPermissionsApi
   * @param [options]
   * @return AxiosResponsePromise<ScopeAssignment[]>
   */

  api(
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<ScopeAssignment[]> {
    return axios(roleApi$ownedPermissions.config(options))
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new ScopeAssignment(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = ScopeAssignment[]>(
    queryOptions?: QueryOptionsWithSelect<ScopeAssignment[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<ScopeAssignment[], TSelected>(
      roleApi$ownedPermissions.api,
      'Role.ownedPermissions',
      queryOptions,
    )
  },
}

// @ts-ignore
roleApi$ownedPermissions.api._name_ = `Role.ownedPermissions`

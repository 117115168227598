import {
  SecuredReflectionWithoutDepartments,
  SecuredReflectionWithoutDepartmentsInterface,
} from './secured-reflection-without-departments'
import {
  GroupMemberReflectionWithoutDepartment,
  GroupMemberReflectionWithoutDepartmentInterface,
} from './group-member-reflection-without-department'
import { DepartmentTypeEnum } from './department-type-enum'

export interface ChalkDepartmentInterface {
  /**
   * 学校 ID
   */
  schoolId?: number
  /**
   * 需 expand
   */
  fullname?: string
  selfMemberCounts?: number
  allMemberCounts?: number
  admins?: SecuredReflectionWithoutDepartmentsInterface[]
  members?: GroupMemberReflectionWithoutDepartmentInterface[]
  /**
   * 创建时间
   */
  createdAt?: string
  /**
   * 更新时间
   */
  updatedAt?: string
  parentId?: number
  type?: DepartmentTypeEnum
  name: string
  description?: string
  weight?: number
  relatedGroupType?: string
  relatedGroupId?: number
  adminIds?: number[]
  /**
   * 主键 ID
   */
  id: number
}

export class ChalkDepartment implements ChalkDepartmentInterface {
  /**
   * 学校 ID
   */
  schoolId?: number
  /**
   * 需 expand
   */
  fullname?: string
  selfMemberCounts?: number
  allMemberCounts?: number
  admins?: SecuredReflectionWithoutDepartments[]
  members?: GroupMemberReflectionWithoutDepartment[]
  /**
   * 创建时间
   */
  createdAt?: string
  /**
   * 更新时间
   */
  updatedAt?: string
  parentId?: number
  type?: DepartmentTypeEnum
  name: string
  description?: string
  weight?: number
  relatedGroupType?: string
  relatedGroupId?: number
  adminIds?: number[]
  /**
   * 主键 ID
   */
  id: number

  constructor(props: ChalkDepartmentInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      fullname: _fullname,
      selfMemberCounts: _selfMemberCounts,
      allMemberCounts: _allMemberCounts,
      admins: _admins,
      members: _members,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      parentId: _parentId,
      type: _type,
      name: _name,
      description: _description,
      weight: _weight,
      relatedGroupType: _relatedGroupType,
      relatedGroupId: _relatedGroupId,
      adminIds: _adminIds,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.fullname = _fullname
    this.selfMemberCounts = _selfMemberCounts
    this.allMemberCounts = _allMemberCounts
    this.admins = _admins
      ? _admins.map(i => new SecuredReflectionWithoutDepartments(i))
      : _admins
    this.members = _members
      ? _members.map(i => new GroupMemberReflectionWithoutDepartment(i))
      : _members
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
    this.parentId = _parentId
    this.type = _type
    this.name = _name
    this.description = _description
    this.weight = _weight
    this.relatedGroupType = _relatedGroupType
    this.relatedGroupId = _relatedGroupId
    this.adminIds = _adminIds
    this.id = _id
  }

  static propKeys = [
    'schoolId',
    'fullname',
    'selfMemberCounts',
    'allMemberCounts',
    'admins',
    'members',
    'createdAt',
    'updatedAt',
    'parentId',
    'type',
    'name',
    'description',
    'weight',
    'relatedGroupType',
    'relatedGroupId',
    'adminIds',
    'id',
  ]
}

/**
 * @file register venue navigator
 */

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator('venue', ({ attributes }) => ({
  modalName: 'ShowVenueOrderModal',
  options: {
    id: (attributes as any)?.orderId,
  },
}))

registerMessageNavigator(['venue.follower_trigger'], () => ({
  path: '/venues/venue-list',
}))

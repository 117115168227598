/**
 * @file 作业调查-消息 domain 注册
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.SzHomeWorkSurvey,
  getLabel: () => $t('作业调查'),
  labelStyle: calculateLabelStyle('#F7E9FA', '#9E37B4'),
  messages: ['task_stats_released'],
})

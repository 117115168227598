/**
 * @file menu types
 */

import type { MenuGroup, MenuItem } from '@seiue/ui'

import { useHasCurrentPermission } from 'packages/features/roles'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

import type { MenuType } from './menu-types'

export type { MenuItem, MenuGroup } from '@seiue/ui'

export { MenuType } from './menu-types'

export type MenuPermissionFunc = (
  func: typeof useHasCurrentPermission,
) => boolean

export type MenuPermission = PermissionNameEnum[] | MenuPermissionFunc

export type ExtraMenuItem = MenuItem & {
  /**
   * 菜单类型
   */
  type?: MenuType
  /**
   * 菜单注册的前缀，[menu1.name, menu2.name]
   */
  prefix?: string[]
  /**
   * 菜单名称
   */
  name?: string
  /**
   * 菜单权限
   */
  permission?: MenuPermission
  /**
   * 是否出现在快捷入口中
   */
  shortcut?: boolean
  /**
   * 出现在快捷入口时的 Icon
   */
  shortcutIcon?: string
}

export type ExtraMenuGroup = Omit<MenuGroup, 'subMenus'> & {
  /**
   * 菜单组跳转地址，只有多插件才会需要定义 path，其他情况定义均视为无效
   */
  path?: string
  /**
   * 菜单类型
   */
  type?: MenuType
  /**
   * 菜单注册的前缀，[menu1.name, menu2.name]
   */
  prefix?: string[]
  /**
   * 菜单名称
   */
  name?: string
  /**
   * 默认隐藏
   */
  hidden?: boolean
  /**
   * 是否出现在快捷入口中
   */
  shortcut?: boolean
  /**
   * 懒加载菜单
   */
  lazy?: boolean
  /**
   * 菜单权限
   */
  permission?: MenuPermission
  /**
   * 子菜单
   */
  subMenus: MenuItemUnion[]
}

export type MenuItemUnion = ExtraMenuItem | ExtraMenuGroup

/**
 * 是否是菜单组
 *
 * @param menu - 菜单数据
 * @returns 是否
 */
export function isExtraMenuGroup(menu: MenuItemUnion): menu is ExtraMenuGroup {
  return !!(menu as any).subMenus
}

export type LayoutMenus = {
  [MenuType.Default]: MenuItemUnion[]
  [MenuType.Admin]: MenuItemUnion[]
  [MenuType.AdminApps]: MenuItemUnion[]
  [MenuType.Apps]: MenuItemUnion[]
}

import { ChatInstanceSettingsStudentApplyChatModeEnum } from './chat-instance-settings-student-apply-chat-mode-enum'

export interface ChatInstanceSettingsInterface {
  /**
   * 学生预约约谈限制
   */
  studentApplyChatLimit?: object
  /**
   * 家长预约约谈限制
   */
  guardianApplyChatLimit?: object
  /**
   * 学生提前预约时间
   */
  studentApplyChatBefore?: object
  /**
   * 家长提前预约时间
   */
  guardianApplyChatBefore?: object
  /**
   * 学生提前取消约谈的时间
   */
  studentCancelChatBefore?: object
  /**
   * 家长提前取消约谈的时间
   */
  guardianCancelChatBefore?: object
  /**
   * 学生和家长是否可查看约谈记录表
   * 0 不可
   * 1 可
   */
  studentGuardianViewPermission?: number
  /**
   * 约谈记录表录入锁定时间
   */
  inputLockingTime?: number
  /**
   * 学生/家长预约模式
   */
  studentApplyChatMode?: ChatInstanceSettingsStudentApplyChatModeEnum
}

export class ChatInstanceSettings implements ChatInstanceSettingsInterface {
  /**
   * 学生预约约谈限制
   */
  studentApplyChatLimit?: object
  /**
   * 家长预约约谈限制
   */
  guardianApplyChatLimit?: object
  /**
   * 学生提前预约时间
   */
  studentApplyChatBefore?: object
  /**
   * 家长提前预约时间
   */
  guardianApplyChatBefore?: object
  /**
   * 学生提前取消约谈的时间
   */
  studentCancelChatBefore?: object
  /**
   * 家长提前取消约谈的时间
   */
  guardianCancelChatBefore?: object
  /**
   * 学生和家长是否可查看约谈记录表
   * 0 不可
   * 1 可
   */
  studentGuardianViewPermission?: number
  /**
   * 约谈记录表录入锁定时间
   */
  inputLockingTime?: number
  /**
   * 学生/家长预约模式
   */
  studentApplyChatMode?: ChatInstanceSettingsStudentApplyChatModeEnum

  constructor(props: ChatInstanceSettingsInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      studentApplyChatLimit: _studentApplyChatLimit,
      guardianApplyChatLimit: _guardianApplyChatLimit,
      studentApplyChatBefore: _studentApplyChatBefore,
      guardianApplyChatBefore: _guardianApplyChatBefore,
      studentCancelChatBefore: _studentCancelChatBefore,
      guardianCancelChatBefore: _guardianCancelChatBefore,
      studentGuardianViewPermission: _studentGuardianViewPermission,
      inputLockingTime: _inputLockingTime,
      studentApplyChatMode: _studentApplyChatMode,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.studentApplyChatLimit = _studentApplyChatLimit
    this.guardianApplyChatLimit = _guardianApplyChatLimit
    this.studentApplyChatBefore = _studentApplyChatBefore
    this.guardianApplyChatBefore = _guardianApplyChatBefore
    this.studentCancelChatBefore = _studentCancelChatBefore
    this.guardianCancelChatBefore = _guardianCancelChatBefore
    this.studentGuardianViewPermission = _studentGuardianViewPermission
    this.inputLockingTime = _inputLockingTime
    this.studentApplyChatMode = _studentApplyChatMode
  }

  static propKeys = [
    'studentApplyChatLimit',
    'guardianApplyChatLimit',
    'studentApplyChatBefore',
    'guardianApplyChatBefore',
    'studentCancelChatBefore',
    'guardianCancelChatBefore',
    'studentGuardianViewPermission',
    'inputLockingTime',
    'studentApplyChatMode',
  ]
}

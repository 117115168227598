import { sortBy } from '@seiue/util'

import { FormTemplate as Base } from '../form-template'
import { FormTemplateField } from '../form-template-field'
import { FormTemplateFieldTypeEnum } from '../form-template-field-type-enum'

export type { FormTemplateInterface } from '../form-template'

/**
 * 表单模版
 */
export class FormTemplate extends Base {
  /**
   * 获取排序后的表单模板字段数组。
   * 实际字段排序从零开始，按照 'sort' 属性升序排序。
   * 如果字段类型为段落，则保持原样。
   * 对于其他字段类型，将其实际排序值递增，并更新 'sort' 属性。
   *
   * @returns 排序后的表单模板字段列表
   */
  get sortedFormTemplateFields() {
    // 初始实际字段排序值
    let realFieldSort = 0

    return sortBy(this.formTemplateFields ?? [], 'sort').map(field => {
      if (field.type === FormTemplateFieldTypeEnum.Paragraph) {
        return field
      }

      // 实际字段排序递增
      realFieldSort += 1

      // 返回一个新的 FormTemplateField 对象，更新 'sort' 属性
      return new FormTemplateField({
        ...field,
        sort: realFieldSort,
      })
    })
  }
}

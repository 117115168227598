/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Class, ClassInterface } from './extends/class'
import type { QueryClassesQuery } from './query-classes-query'

const buildConfig = createConfigBuilder('vnas')

export const classApi$queryClasses = {
  config(
    queryClassesQuery?: QueryClassesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/evaluation/classes',
      {},
      queryClassesQuery,
    )
  },

  /**
   * @summary Class QueryClassesApi
   * @param [queryClassesQuery]
   * @param [classQuery.evaluationId] expand teacher_hours_info 时使用，补全在课程班中已经移除但是评教未删除的教师的信息
   * 注意只在课程班评教时使用
   * @param [classQuery.semesterId]
   * @param [classQuery.expand]
   * @param [classQuery.paginated] 是否分页，默认 1
   * @param [classQuery.page] 显示的数据页，默认 1
   * @param [classQuery.perPage] 每页显示数量，默认 20
   * @param [classQuery.idIn]
   * @param [classQuery.idNotIn]
   * @param [classQuery.courseIdIn]
   * @param [classQuery.courseIdNotIn]
   * @param [classQuery.identity] csin/nama 或查询
   * @param [classQuery.csinIn]
   * @param [classQuery.csinLike]
   * @param [classQuery.nameIn]
   * @param [classQuery.nameLike]
   * @param [classQuery.creditEgt]
   * @param [classQuery.creditElt]
   * @param [classQuery.subjectIdIn]
   * @param [classQuery.subjectIdNotIn]
   * @param [classQuery.domainIdIn]
   * @param [classQuery.attributeIdIn]
   * @param [classQuery.classNameIn]
   * @param [classQuery.classNameLike]
   * @param [classQuery.classEnameIn]
   * @param [classQuery.classEnameLike]
   * @param [classQuery.capacityEgt]
   * @param [classQuery.capacityElt]
   * @param [classQuery.teachers.nameLike]
   * @param [classQuery.teachers.nameIn]
   * @param [classQuery.teachers.id]
   * @param [classQuery.teachers.idIn]
   * @param [classQuery.teachers.idNeq]
   * @param [classQuery.teachers.idNotIn]
   * @param [classQuery.substituteTeachers.nameLike]
   * @param [classQuery.substituteTeachers.nameIn]
   * @param [classQuery.substituteTeachers.id]
   * @param [classQuery.substituteTeachers.idIn]
   * @param [classQuery.substituteTeachers.idNeq]
   * @param [classQuery.substituteTeachers.idNotIn]
   * @param [classQuery.isMultiTeachers] 是多任课教师的课程
   * @param [classQuery.timetable.nameIn]
   * @param [classQuery.timetable.nameLike]
   * @param [classQuery.classWeeksEgt]
   * @param [classQuery.classWeeksElt]
   * @param [classQuery.studentNumsEgt]
   * @param [classQuery.studentNumsElt]
   * @param [classQuery.classHourEgt]
   * @param [classQuery.classHourElt]
   * @param [classQuery.placeIdsOverlaps]
   * @param [classQuery.gradeIdsOverlaps]
   * @param [classQuery.timetableIdIn]
   * @param [classQuery.classTimes.lessonName]
   * @param [classQuery.classTimes.lessonNameLike]
   * @param [classQuery.classTimes.lessonNameIn]
   * @param [classQuery.classTimes.day]
   * @param [classQuery.classTimes.dayIn]
   * @param [classQuery.classTimes.weekType]
   * @param [classQuery.classTimes.weekTypeIn]
   * @param [classQuery.course.idIn]
   * @param [classQuery.course.idNotIn]
   * @param [classQuery.course.csinIn]
   * @param [classQuery.course.csinLike]
   * @param [classQuery.course.nameIn]
   * @param [classQuery.course.nameLike]
   * @param [classQuery.course.gradeIdsOverlaps]
   * @param [classQuery.course.domainIdIn]
   * @param [classQuery.course.attributeIdIn]
   * @param [classQuery.course.semesterCategoryIdIn]
   * @param [classQuery.course.chargerIdIn]
   * @param [classQuery.course.status]
   * @param [classQuery.course.statusIn]
   * @param [classQuery.course.identity] csin/name 或查询
   * @param [classQuery.course.creditEgt]
   * @param [classQuery.course.creditElt]
   * @param [classQuery.course.teacherNumEgt]
   * @param [classQuery.course.teacherNumElt]
   * @param [classQuery.course.classNumEgt]
   * @param [classQuery.course.classNumElt]
   * @param [classQuery.course.subjectIdIn]
   * @param [classQuery.course.subjectIdNotIn]
   * @param [classQuery.course.semesterCategoryIdsOverlaps]
   * @param [classQuery.course.exclusiveIdsOverlaps]
   * @param [classQuery.course.prerequisiteIdsOverlaps]
   * @param [classQuery.tagIdIn]
   * @param [classQuery.classType] 课程班类型
   * @param [classQuery.classTypeIn]
   * @param [classQuery.classLessons.startAtGt]
   * @param [classQuery.classLessons.lessonName]
   * @param [classQuery.classLessons.lessonNameIn]
   * @param [classQuery.classLessons.day]
   * @param [classQuery.classLessons.dayIn]
   * @param [classQuery.hasAdminClass] true - 获取关联行政班的课程班信息
   * false - 此参数不生效
   * @param [classQuery.adminClasses.idIn]
   * @param [classQuery.adminClasses.nameLike]
   * @param [classQuery.adminClasses.nameIn]
   * @param [classQuery.adminClasses.graduatesInIdIn]
   * @param [classQuery.adminClasses.placeIdIn]
   * @param [classQuery.adminClasses.place]
   * @param [classQuery.adminClasses.placeIdIsEmpty]
   * @param [classQuery.adminClasses.studentNumsEgt]
   * @param [classQuery.adminClasses.studentNumsElt]
   * @param [classQuery.adminClasses.teachers]
   * @param [classQuery.adminClasses.status]
   * @param [classQuery.adminClasses.gradeIdIn]
   * @param [classQuery.adminClasses.graderParentIdIn]
   * @param [classQuery.adminClasses.option]
   * @param [classQuery.withTrashed] 查询包含已软删除的数据
   * @param [options]
   * @return AxiosResponsePromise<Class[]>
   */

  api<const TQuery extends QueryClassesQuery>(
    queryClassesQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Class, TQuery['expand']>[]> {
    return axios(classApi$queryClasses.config(queryClassesQuery, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Class(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryClassesQuery,
    TSelected = Expand<Class, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<Class, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Class, TQuery['expand']>[], TSelected>(
      classApi$queryClasses.api,
      'Class.queryClasses',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
classApi$queryClasses.api._name_ = `Class.queryClasses`

export interface ScopeParamInterface {
  idIn?: string
}

export class ScopeParam implements ScopeParamInterface {
  idIn?: string

  constructor(props: ScopeParamInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      idIn: _idIn,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.idIn = _idIn
  }

  static propKeys = ['idIn']
}

import { MessageDomainEnum } from 'packages/features/messages/types'
import { registerMessageNavigator } from 'packages/features/messages/utils'

registerMessageNavigator(MessageDomainEnum.DormAssessment, msg => {
  if (msg.type.includes('for_teacher')) {
    // for_teacher 表示直发，cc_xx 表示抄送
    const attrs = msg.attributes as any

    return {
      path: `/dorms/assessments/${attrs.assessmentId}/entry?itemId=${attrs.itemId}`,
    }
  }

  return null
})

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { ExamSubject, ExamSubjectInterface } from './extends/exam-subject'
import type { QueryPaperManagedExamSubjectsQuery } from './query-paper-managed-exam-subjects-query'

const buildConfig = createConfigBuilder('vnas')

export const examScoringApi$queryPaperManagedExamSubjects = {
  config(
    semesterId: number,
    queryPaperManagedExamSubjectsQuery?: QueryPaperManagedExamSubjectsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/exam_scoring/papers/managed_subjects',
      {
        semesterId,
      },
      queryPaperManagedExamSubjectsQuery,
    )
  },

  /**
   * @summary Class QueryExamSubjectsApi
   * 获取出题教师的考试科目列表
   * @param semesterId
   * @param [queryPaperManagedExamSubjectsQuery]
   * @param [examScoringQuery.idIn]
   * @param [examScoringQuery.nameLike]
   * @param [examScoringQuery.statusIn]
   * @param [examScoringQuery.createdAtEgt] 考试创建时间
   * @param [examScoringQuery.createdAtElt]
   * @param [examScoringQuery.expand]
   * @param [examScoringQuery.sort]
   * @param [examScoringQuery.paginated] 是否分页，默认 1
   * @param [examScoringQuery.page] 显示的数据页，默认 1
   * @param [examScoringQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<ExamSubject[]>
   */

  api<const TQuery extends QueryPaperManagedExamSubjectsQuery>(
    semesterId: number,
    queryPaperManagedExamSubjectsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<ExamSubject, TQuery['expand']>[]> {
    return axios(
      examScoringApi$queryPaperManagedExamSubjects.config(
        semesterId,
        queryPaperManagedExamSubjectsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new ExamSubject(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryPaperManagedExamSubjectsQuery,
    TSelected = Expand<ExamSubject, TQuery['expand']>[],
  >(
    {
      semesterId,
      query,
    }: {
      semesterId: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<ExamSubject, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<ExamSubject, TQuery['expand']>[], TSelected>(
      examScoringApi$queryPaperManagedExamSubjects.api,
      'ExamScoring.queryPaperManagedExamSubjects',
      queryOptions,
      semesterId,
      query,
    )
  },
}

// @ts-ignore
examScoringApi$queryPaperManagedExamSubjects.api._name_ = `ExamScoring.queryPaperManagedExamSubjects`

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Semester, SemesterInterface } from './semester'
import type { GetCurrentQuery } from './get-current-query'

const buildConfig = createConfigBuilder('chalk')

export const semesterApi$getCurrent = {
  config(
    getCurrentQuery?: GetCurrentQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/system/semesters/current',
      {},
      getCurrentQuery,
    )
  },

  /**
   * @summary Class GetCurrentSemesterApi
   * @param [getCurrentQuery]
   * @param [semesterQuery.date]
   * @param [options]
   * @return AxiosResponsePromise<Semester>
   */

  api(
    getCurrentQuery?: GetCurrentQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Semester> {
    return axios(semesterApi$getCurrent.config(getCurrentQuery, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new Semester(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = Semester>(
    query?: GetCurrentQuery,
    queryOptions?: QueryOptionsWithSelect<Semester, TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Semester, TSelected>(
      semesterApi$getCurrent.api,
      'Semester.getCurrent',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
semesterApi$getCurrent.api._name_ = `Semester.getCurrent`

/**
 * @file 绑定相关
 */

import { createEnumHelper } from '@seiue/util'

import { $t } from 'packages/locale'

export enum BindTypeEnum {
  Phone = 'phone',
  EMail = 'email',
  WeChat = 'wechat',
  DingTalk = 'dingtalk',
  WeWork = 'wework',
}

export const bindTypeEnumHelper = createEnumHelper(() => ({
  [BindTypeEnum.EMail]: $t('邮箱'),
  [BindTypeEnum.Phone]: $t('手机'),
  [BindTypeEnum.WeChat]: $t('微信'),
  [BindTypeEnum.DingTalk]: $t('钉钉'),
  [BindTypeEnum.WeWork]: $t('企业微信'),
}))

export const bindTextHelper = createEnumHelper(() => ({
  [BindTypeEnum.EMail]: $t(
    '邮箱的绑定作用于所有关联身份，登录密码为默认账号的密码。',
  ),
  [BindTypeEnum.Phone]: $t(
    '同一手机号可以绑定多个账号，被绑定的账号会自动关联。',
  ),
  [BindTypeEnum.WeChat]: '',
}))

export const unbindTextHelper = createEnumHelper(() => ({
  [BindTypeEnum.EMail]: $t('解绑后，此邮箱将与所有身份解除绑定关系。'),
  [BindTypeEnum.Phone]: $t(
    '手机号解绑后，所有身份将解除绑定关系，并同时解绑邮箱、微信及 OA 账号。',
  ),
  [BindTypeEnum.WeChat]: $t('解绑后，此微信将与所有身份解除绑定关系。'),
  [BindTypeEnum.WeWork]: $t('解绑后，此企业微信将与所有身份解除绑定关系。'),
  [BindTypeEnum.DingTalk]: $t('解绑后，此钉钉账号将与所有身份解除绑定关系。'),
}))

export const changeBindTextHelper = createEnumHelper(() => ({
  [BindTypeEnum.EMail]: $t(
    '更换所有身份绑定的邮箱，新邮箱将替代原邮箱和所有账号之间的绑定关系。',
  ),
  [BindTypeEnum.Phone]: $t(
    '更换所有身份绑定的手机号，新手机号将替代原手机号和所有账号之间的绑定关系。',
  ),
  [BindTypeEnum.WeChat]: $t(
    '更换所有身份绑定的微信，新微信将替代原微信和所有账号之间的绑定关系。',
  ),
}))

export enum BindActionEnum {
  Bind = 'bind',
  Change = 'change',
  Unbind = 'unbind',
}

export const bindActionEnumHelper = createEnumHelper(() => ({
  [BindActionEnum.Bind]: $t('绑定'),
  [BindActionEnum.Change]: $t('更换'),
  [BindActionEnum.Unbind]: $t('解绑'),
}))

import { LessonClass, LessonClassInterface } from './lesson-class'
import { Place, PlaceInterface } from './place'
import { ReflectionV3, ReflectionV3Interface } from './reflection-v-3'

export interface ClassLessonInterface {
  class?: LessonClassInterface | null
  classId: number
  /**
   * 周几
   */
  day: number
  /**
   * 节次名称
   */
  lessonName: string
  /**
   * 上课时间
   */
  startTime: string
  /**
   * 下课时间
   */
  endTime: string
  /**
   * 第几周
   */
  week: number
  /**
   * 空间
   */
  placeId?: number | null
  teacherIds?: number[] | null
  /**
   * 课节对应的具体日期
   */
  date: string
  number: number
  /**
   * 0 未考勤 1 已考勤
   */
  attendanceStatus: number
  /**
   * 空间 expanded
   */
  place?: PlaceInterface | null
  /**
   * 老师expanded
   */
  teachers?: ReflectionV3Interface[] | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 学期 ID
   */
  semesterId: number
}

export class ClassLesson implements ClassLessonInterface {
  class?: LessonClass | null
  classId: number
  /**
   * 周几
   */
  day: number
  /**
   * 节次名称
   */
  lessonName: string
  /**
   * 上课时间
   */
  startTime: string
  /**
   * 下课时间
   */
  endTime: string
  /**
   * 第几周
   */
  week: number
  /**
   * 空间
   */
  placeId?: number | null
  teacherIds?: number[] | null
  /**
   * 课节对应的具体日期
   */
  date: string
  number: number
  /**
   * 0 未考勤 1 已考勤
   */
  attendanceStatus: number
  /**
   * 空间 expanded
   */
  place?: Place | null
  /**
   * 老师expanded
   */
  teachers?: ReflectionV3[] | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 学期 ID
   */
  semesterId: number

  constructor(props: ClassLessonInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      class: _class,
      classId: _classId,
      day: _day,
      lessonName: _lessonName,
      startTime: _startTime,
      endTime: _endTime,
      week: _week,
      placeId: _placeId,
      teacherIds: _teacherIds,
      date: _date,
      number: _number,
      attendanceStatus: _attendanceStatus,
      place: _place,
      teachers: _teachers,
      id: _id,
      schoolId: _schoolId,
      semesterId: _semesterId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.class = _class ? new LessonClass(_class) : _class
    this.classId = _classId
    this.day = _day
    this.lessonName = _lessonName
    this.startTime = _startTime
    this.endTime = _endTime
    this.week = _week
    this.placeId = _placeId
    this.teacherIds = _teacherIds
    this.date = _date
    this.number = _number
    this.attendanceStatus = _attendanceStatus
    this.place = _place ? new Place(_place) : _place
    this.teachers = _teachers
      ? _teachers.map(i => new ReflectionV3(i))
      : _teachers
    this.id = _id
    this.schoolId = _schoolId
    this.semesterId = _semesterId
  }

  static propKeys = [
    'class',
    'classId',
    'day',
    'lessonName',
    'startTime',
    'endTime',
    'week',
    'placeId',
    'teacherIds',
    'date',
    'number',
    'attendanceStatus',
    'place',
    'teachers',
    'id',
    'schoolId',
    'semesterId',
  ]
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { BindPhone, BindPhoneInterface } from './bind-phone'
import { User, UserInterface } from './user'

const buildConfig = createConfigBuilder('chalk')

export const userApi$bindPhoneToUser = {
  config(
    id: number,
    bindPhone: BindPhoneInterface,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'post',
      '/user/users/{id}/phone',
      {
        id,
      },
      null,
      bindPhone,
      {
        format: 'json',
        isArray: false,
        defaults: {
          password: (v: any) => v || '',
          secret: (v: any) => v || '',
        },
      },
    )
  },

  /**
   * @summary 绑定用户手机号
   * @param id
   * @param bindPhone
   * @param [options]
   * @return AxiosResponsePromise<User>
   */

  api(
    id: number,
    bindPhone: BindPhoneInterface,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<User> {
    return axios(userApi$bindPhoneToUser.config(id, bindPhone, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new User(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({ id, payload }: { id: number; payload: BindPhoneInterface }) =>
        userApi$bindPhoneToUser.api(id, payload),
      mutationOptions,
    )
  },
}

// @ts-ignore
userApi$bindPhoneToUser.api._name_ = `User.bindPhoneToUser`

import { createEnumHelper } from '@seiue/util'

import { $t } from 'packages/locale'
import { NetdiskPermissionPermissionsEnum } from 'packages/sdks-next/chalk'

import {
  NetdiskPermission as NetdiskPermissionBase,
  NetdiskPermissionInterface,
} from '../netdisk-permission'

import { NetdiskPermissionReqInterface } from './netdisk-permission-req'

export type { NetdiskPermissionInterface }

export const permissionToLabel = createEnumHelper(() => ({
  [NetdiskPermissionPermissionsEnum.Read]: $t('可查看'),
  [NetdiskPermissionPermissionsEnum.Edit]: $t('可编辑'),
  [NetdiskPermissionPermissionsEnum.Manage]: $t('可管理'),
}))

/**
 *
 */
export class NetdiskPermission extends NetdiskPermissionBase {
  /**
   * 权限 label
   *
   * @returns 权限 label
   */
  get permissionLabel() {
    return getPermissionLabel(this)
  }

  /**
   * 权限标识
   *
   * @returns 权限标识
   */
  get permissionFlag() {
    return getPermissionFlag(this)
  }

  /**
   * 获取权限 label
   *
   * @param permission - 权限
   * @returns 权限 label
   */
  static getPermissionLabel(permission: NetdiskPermissionInterface) {
    return getPermissionLabel(permission)
  }

  /**
   * 获取权限标识
   *
   * @param permission - 权限
   * @returns 权限标识
   */
  static getPermissionFlag(permission: NetdiskPermissionInterface) {
    return getPermissionFlag(permission)
  }

  /**
   * 获取 permission 的所有选项
   *
   * @param sourceKey - 原始值在选项 object 中的 key, 默认 value
   * @param mappedKey - 文字标签在选项 object 中的 key, 默认 label
   * @returns 选项数组, 默认 [{ value, label }]
   */
  static getPermissionOptions<S extends string, M extends string>(
    sourceKey: S,
    mappedKey: M,
  ) {
    return permissionToLabel.listOptions(sourceKey, mappedKey)
  }
}

/**
 * 获取权限 label
 *
 * @param permission - 权限
 * @returns 权限 label
 */
export const getPermissionLabel = (
  permission: NetdiskPermissionInterface | NetdiskPermissionReqInterface,
) => {
  return permissionToLabel.get(getPermissionFlag(permission))
}

/**
 * 获取权限标识
 *
 * @param permission - 权限
 * @returns 权限标识
 */
export const getPermissionFlag = (
  permission: NetdiskPermissionInterface | NetdiskPermissionReqInterface,
) => {
  const { permissions = [] } = permission
  if (permissions.includes(NetdiskPermissionPermissionsEnum.Manage)) {
    return NetdiskPermissionPermissionsEnum.Manage
  }

  if (permissions.includes(NetdiskPermissionPermissionsEnum.Edit)) {
    return NetdiskPermissionPermissionsEnum.Edit
  }

  return NetdiskPermissionPermissionsEnum.Read
}

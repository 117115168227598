/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { BaseAttendance, BaseAttendanceInterface } from './base-attendance'
import type { QueryAttendancesInfoQuery } from './query-attendances-info-query'

const buildConfig = createConfigBuilder('sams')

export const attendanceApi$queryAttendancesInfo = {
  config(
    bizTypeIn: string,
    bizIdIn: string,
    attendanceTimeIdIn: string,
    queryAttendancesInfoQuery?: QueryAttendancesInfoQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/attendance/attendances-info',
      {
        bizTypeIn,
        bizIdIn,
        attendanceTimeIdIn,
      },
      queryAttendancesInfoQuery,
    )
  },

  /**
   * @summary 查询考勤主体的信息（是否开启考勤,课节是否考勤）
   * @param bizTypeIn
   * @param bizIdIn
   * @param attendanceTimeIdIn
   * @param [queryAttendancesInfoQuery]
   * @param [attendanceQuery.expand] - group
   * - checked_attendance_time_ids
   * @param [attendanceQuery.paginated] 是否分页，默认 1
   * @param [attendanceQuery.page] 显示的数据页，默认 1
   * @param [attendanceQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<BaseAttendance[]>
   */

  api<const TQuery extends QueryAttendancesInfoQuery>(
    bizTypeIn: string,
    bizIdIn: string,
    attendanceTimeIdIn: string,
    queryAttendancesInfoQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<BaseAttendance, TQuery['expand']>[]> {
    return axios(
      attendanceApi$queryAttendancesInfo.config(
        bizTypeIn,
        bizIdIn,
        attendanceTimeIdIn,
        queryAttendancesInfoQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new BaseAttendance(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryAttendancesInfoQuery,
    TSelected = Expand<BaseAttendance, TQuery['expand']>[],
  >(
    {
      bizTypeIn,
      bizIdIn,
      attendanceTimeIdIn,
      query,
    }: {
      bizTypeIn: string
      bizIdIn: string
      attendanceTimeIdIn: string
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<BaseAttendance, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<BaseAttendance, TQuery['expand']>[], TSelected>(
      attendanceApi$queryAttendancesInfo.api,
      'Attendance.queryAttendancesInfo',
      queryOptions,
      bizTypeIn,
      bizIdIn,
      attendanceTimeIdIn,
      query,
    )
  },
}

// @ts-ignore
attendanceApi$queryAttendancesInfo.api._name_ = `Attendance.queryAttendancesInfo`

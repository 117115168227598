export enum FormTemplateFieldTypeEnum {
  Attachment = 'attachment',
  Checkbox = 'checkbox',
  Paragraph = 'paragraph',
  Radio = 'radio',
  Score = 'score',
  Number = 'number',
  Select = 'select',
  Textarea = 'textarea',
  Input = 'input',
  RichText = 'rich_text',
  Custom = 'custom',
  DirectionSelector = 'direction_selector',
  Idcard = 'idcard',
  Phone = 'phone',
  Nationality = 'nationality',
  Address = 'address',
  DateRange = 'date_range',
  DateTime = 'date_time',
  TravelCard = 'travel_card',
  CascadeSelector = 'cascade_selector',
  Biz = 'biz',
  Table = 'table',
  MatrixRadio = 'matrix_radio',
}

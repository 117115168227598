import { DiscussionTopic, DiscussionTopicInterface } from './discussion-topic'

export interface DiscussionInterface {
  schoolId: number
  canPublicDiscuss: boolean
  canPrivateDiscuss: boolean
  canTeamDiscuss: boolean
  /**
   * expand
   */
  topics?: DiscussionTopicInterface[]
  isTopped?: boolean
  isCollected?: boolean
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Discussion implements DiscussionInterface {
  schoolId: number
  canPublicDiscuss: boolean
  canPrivateDiscuss: boolean
  canTeamDiscuss: boolean
  /**
   * expand
   */
  topics?: DiscussionTopic[]
  isTopped?: boolean
  isCollected?: boolean
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: DiscussionInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      canPublicDiscuss: _canPublicDiscuss,
      canPrivateDiscuss: _canPrivateDiscuss,
      canTeamDiscuss: _canTeamDiscuss,
      topics: _topics,
      isTopped: _isTopped,
      isCollected: _isCollected,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.canPublicDiscuss = _canPublicDiscuss
    this.canPrivateDiscuss = _canPrivateDiscuss
    this.canTeamDiscuss = _canTeamDiscuss
    this.topics = _topics ? _topics.map(i => new DiscussionTopic(i)) : _topics
    this.isTopped = _isTopped
    this.isCollected = _isCollected
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'schoolId',
    'canPublicDiscuss',
    'canPrivateDiscuss',
    'canTeamDiscuss',
    'topics',
    'isTopped',
    'isCollected',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

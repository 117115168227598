/**
 * @file 注册课程申报消息
 */

import { PluginEnum } from 'packages/feature-utils/plugins'
import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'
import { UIColor } from 'packages/theme'

/*
 * 待插件通知注册机制完善之前，暂时将插件的注册置于此处。
 * TODO
 * 1. 于插件内注册
 * 2. 通知类型随插件开关/权限显示（如果一开始用户有权限，收到通知，再关掉权限后如何显示？）
 */
registerDomainMessage({
  type: MessageDomainEnum.Election,
  getLabel: () => $t('选课'),
  icon: `plugin.${PluginEnum.Election}`,
  labelStyle: calculateLabelStyle(UIColor._2.backgroundLight, UIColor._2.text),
  messages: [{ type: 'lotting_result', important: true }],
})

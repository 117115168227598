/**
 * @file import plugins message chalk-navigator
 */

import '../features/customized-groups/message/chalk-navigator'
import '../features/szhpsy-grades-report/message/chalk-navigator'
import '../features/psychological-file/message/chalk-navigator'
import '../features/class-in/message/chalk-navigator'
import '../features/dorms/message/chalk-navigator'
import '../features/chat/message/chalk-navigator'
import '../features/eems/message/chalk-navigator'
import '../features/contract/message/register'
import '../features/sz-teaching-satisficing/message/chalk-navigator'
import '../features/absence/message/chalk-navigator'
import '../features/questionnaire/message/chalk-navigator'
import '../features/handout/message/chalk-navigator'
import '../features/teacher-assessment/chalk-navigator'
import '../features/report/chalk-navigator'
import '../features/sz-homework-survey/message/chalk-navigator'
import '../features/adjustment/message/chalk-navigator'
import '../features/intl-goal/message/chalk-navigator'
import '../features/class-review/message/chalk-navigator'
import '../features/ai-teacher/message/chalk-navigator'

export enum FlowStageStatusEnum {
  Waiting = 'waiting',
  Pending = 'pending',
  Withdrawn = 'withdrawn',
  Denied = 'denied',
  Approved = 'approved',
  Invalid = 'invalid',
  Stopped = 'stopped',
  Forwarded = 'forwarded',
  Returned = 'returned',
}

import { env, isDevelopment } from '@seiue/env'
import { isValidURL, parseURLQuery, stringifyURLQuery } from '@seiue/util'

export const DomainRegex =
  /^(http|https):\/\/((?:[a-zA-z0-9]+\.)*)([a-zA-z0-9]+\.[a-zA-z0-9]+)(\/.*)?/

/**
 * 用于获取 url 中的相关元信息
 *
 * @param url - url
 * @returns [protocal, subDomain, domain, path]
 */
export const parseUrl = (url: string) => {
  const [, protocal, subDomain, domain, path] = DomainRegex.exec(url) || []
  return [protocal, subDomain, domain, path] as const
}

/**
 * 从 url 中提取域名，包含 subdomain（如： https://aaa.bbb.ccc.com/a/b => aaa.bbb.ccc.com）
 *
 * @param url - url
 * @returns domain
 */
export const getDomainUrl = (url: string) => {
  const [, subDomain, domain] = parseUrl(url)
  return `${subDomain}${domain}`
}

/**
 *  用于判断两个 url 是否来自相同的顶级域名（不包含 subdomain）
 *
 * @param url - url
 * @param url2 - url
 * @returns boolean
 */
export const isSameDomain = (url: string, url2: string) => {
  const [, , domain] = DomainRegex.exec(url) || []
  const [, , domain2] = DomainRegex.exec(url2) || []
  // return true when both undefined (relative url)
  return domain === domain2
}

/**
 * 用于判断给定链接是否可信任(与当前 url 相同顶级域名或来自 seiue)
 *
 * @param url - url
 * @returns boolean
 */
export const isLinkTrusted = (url: string) => {
  const [, , domain] = parseUrl(url)
  if (domain === 'seiue.com') {
    return true
  }

  const currentUrl = window?.location?.href
  return isSameDomain(currentUrl, url)
}

/**
 * 编码
 *
 * @param props - 参数
 * @param props.params - URL 参数对象
 * @param props.encodeKey - key
 * @returns string
 */
export const encodeParams = async ({
  params,
  encodeKey,
}: {
  params: { [key: string]: unknown }
  encodeKey: string
}) => {
  const CryptoJS = await import('crypto-js')
  const message = JSON.stringify(params)
  const encryptedParam = CryptoJS.AES.encrypt(message, encodeKey).toString()
  // 将加密结果转换为 base64 字符串，生成的 encryptedParam 可能会有一些 url 不兼容的字符串（比如 /） ，容易导致生成错误的路由，这里处理一下避免出现问题
  const encodedCipherText = CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(encryptedParam),
  )

  return encodedCipherText
}

/**
 * 解码
 *
 * @param encodedString - string
 * @param key - key
 * @returns data
 */
export const decodeParams = async <T extends { [key: string]: unknown }>(
  encodedString: string,
  key: string,
): Promise<T | null> => {
  const CryptoJS = await import('crypto-js')
  try {
    const ciphertext = CryptoJS.enc.Base64.parse(encodedString).toString(
      CryptoJS.enc.Utf8,
    )

    const plaintext = CryptoJS.AES.decrypt(ciphertext, key).toString(
      CryptoJS.enc.Utf8,
    )

    return JSON.parse(plaintext)
  } catch (error) {
    return null
  }
}

/**
 * 添加新的 query 内容到 query string 当中
 *
 * @param prevQueryString - 旧的 queryString
 * @param toAppendQuery - 新的 query 内容
 * @returns query 字符串
 */
export function appendQueryString(
  prevQueryString: string,
  toAppendQuery: object,
): string {
  const newQuery = {
    ...parseURLQuery(prevQueryString),
    ...toAppendQuery,
  }

  return stringifyURLQuery(newQuery)
}

/**
 * 是否为桌面端 Chalk3 Url
 *
 * @param url - url
 * @returns 是否
 */
export const isChalkUrl = (url: string) => {
  if (isDevelopment()) {
    return url.startsWith('http://local.seiue.com:3000')
  }

  return isValidURL(url) && url.startsWith(env('CLIENT_CHALK_3'))
}

/**
 * 是否为 Go Url
 *
 * @param url - url
 * @returns 是否
 */
export const isGoUrl = (url: string) =>
  isValidURL(url) && url.startsWith(env('CLIENT_GO'))

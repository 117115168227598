/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { GroupTypePlugin, GroupTypePluginInterface } from './group-type-plugin'
import type { QueryGroupTypePluginsQuery } from './query-group-type-plugins-query'

const buildConfig = createConfigBuilder('chalk')

export const customGroupApi$queryGroupTypePlugins = {
  config(
    queryGroupTypePluginsQuery?: QueryGroupTypePluginsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/custom_group/group_type_plugins',
      {},
      queryGroupTypePluginsQuery,
    )
  },

  /**
   * @summary Class QueryGroupTypePluginsApi
   * @param [queryGroupTypePluginsQuery]
   * @param [customGroupQuery.idIn]
   * @param [customGroupQuery.id]
   * @param [customGroupQuery.schoolIdIn]
   * @param [customGroupQuery.schoolId]
   * @param [customGroupQuery.category]
   * @param [customGroupQuery.label]
   * @param [customGroupQuery.groupId]
   * @param [customGroupQuery.groupIdIn]
   * @param [customGroupQuery.labelLike]
   * @param [customGroupQuery.labelNotIn]
   * @param [customGroupQuery.parentId]
   * @param [customGroupQuery.status]
   * @param [customGroupQuery.enabled]
   * @param [customGroupQuery.shortcut]
   * @param [customGroupQuery.appliedBy]
   * @param [customGroupQuery.appliedIn]
   * @param [customGroupQuery.appliedAtEgt]
   * @param [customGroupQuery.appliedAtElt]
   * @param [customGroupQuery.applicant.account]
   * @param [customGroupQuery.applicant.usin]
   * @param [customGroupQuery.applicant.idcard]
   * @param [customGroupQuery.applicant.outerId]
   * @param [customGroupQuery.applicant.idIn]
   * @param [customGroupQuery.applicant.nameIn]
   * @param [customGroupQuery.applicant.nameLike]
   * @param [customGroupQuery.applicant.enameIn]
   * @param [customGroupQuery.applicant.enameLike]
   * @param [customGroupQuery.applicant.identity] name, usin, ename, pinyin 的模糊搜索
   * @param [customGroupQuery.applicant.accountLike]
   * @param [customGroupQuery.applicant.accountIn]
   * @param [customGroupQuery.applicant.usinLike]
   * @param [customGroupQuery.applicant.usinIn]
   * @param [customGroupQuery.applicant.genderIn]
   * @param [customGroupQuery.applicant.statusIn]
   * @param [customGroupQuery.applicant.archivedTypeIdIn]
   * @param [customGroupQuery.applicant.phoneIn]
   * @param [customGroupQuery.applicant.phoneLike]
   * @param [customGroupQuery.applicant.emailIn]
   * @param [customGroupQuery.applicant.emailLike]
   * @param [customGroupQuery.applicant.idcardIn]
   * @param [customGroupQuery.applicant.idcardLike]
   * @param [customGroupQuery.applicant.enteredOnElt]
   * @param [customGroupQuery.applicant.enteredOnEgt]
   * @param [customGroupQuery.applicant.workStartOnElt]
   * @param [customGroupQuery.applicant.workStartOnEgt]
   * @param [customGroupQuery.applicant.enteredYearsElt]
   * @param [customGroupQuery.applicant.enteredYearsEgt]
   * @param [customGroupQuery.applicant.workYearsElt]
   * @param [customGroupQuery.applicant.workYearsEgt]
   * @param [customGroupQuery.applicant.adminClassesContains]
   * @param [customGroupQuery.applicant.adminClassesOverlaps]
   * @param [customGroupQuery.applicant.adminClassFullnamesOverlaps]
   * @param [customGroupQuery.applicant.adminClassesLike]
   * @param [customGroupQuery.applicant.dorm]
   * @param [customGroupQuery.applicant.dormLike]
   * @param [customGroupQuery.applicant.dormIn]
   * @param [customGroupQuery.applicant.dormAdminsContains]
   * @param [customGroupQuery.applicant.dormAdminsOverlaps]
   * @param [customGroupQuery.applicant.dormAdminsLike]
   * @param [customGroupQuery.applicant.role]
   * @param [customGroupQuery.applicant.roleIn]
   * @param [customGroupQuery.parentIdIsEmpty]
   * @param [customGroupQuery.expand] - plugin 所属插件
   * - applicant 申请人
   * - admin_reflections 管理人员 reflections
   * @param [customGroupQuery.sort]
   * @param [customGroupQuery.entrypointsOverlaps] 入口
   * @param [customGroupQuery.isApp] 是否是应用，包含 plugin kind 为 application的，和所有的子插件
   * @param [customGroupQuery.adminId]
   * @param [options]
   * @return AxiosResponsePromise<GroupTypePlugin[]>
   */

  api<const TQuery extends QueryGroupTypePluginsQuery>(
    queryGroupTypePluginsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<GroupTypePlugin, TQuery['expand']>[]> {
    return axios(
      customGroupApi$queryGroupTypePlugins.config(
        queryGroupTypePluginsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new GroupTypePlugin(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryGroupTypePluginsQuery,
    TSelected = Expand<GroupTypePlugin, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<GroupTypePlugin, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<GroupTypePlugin, TQuery['expand']>[], TSelected>(
      customGroupApi$queryGroupTypePlugins.api,
      'CustomGroup.queryGroupTypePlugins',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
customGroupApi$queryGroupTypePlugins.api._name_ = `CustomGroup.queryGroupTypePlugins`

export interface RepeatSettingInterface {
  each?: number
  weekDays?: number[]
}

export class RepeatSetting implements RepeatSettingInterface {
  each?: number
  weekDays?: number[]

  constructor(props: RepeatSettingInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      each: _each,
      weekDays: _weekDays,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.each = _each
    this.weekDays = _weekDays
  }

  static propKeys = ['each', 'weekDays']
}

export enum TaskEnhancerEnum {
  PersonalTask = 'seiue.personal_task',
  ClassActivityTask = 'seiue.class_activity_task',
  ClassHomeworkTask = 'seiue.class_homework_task',
  ClassDocumentTask = 'seiue.class_document_task',
  ClassWebsiteTask = 'seiue.class_website_task',
  ClassQuestionnaireTask = 'seiue.class_questionnaire_task',
  ClassQuizTask = 'seiue.class_quiz_task',
  ClassLiveTask = 'seiue.class_live_task',
  ClassDiscuzTask = 'seiue.class_discuz_task',
  ChatTask = 'seiue.chat_task',
}

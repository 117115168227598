import { isWithinRange, MomentInput, moment, willOverlap } from '@seiue/moment'
import { keys, min, minBy } from '@seiue/util'
import { useMemo } from 'react'

import { useIsBDFZ } from 'packages/features/schools/hooks'
import { TermTypeEnum } from 'packages/features/terms/types'
import { QueryOptions } from 'packages/sdks-next'
import { Semester, Term } from 'packages/sdks-next/chalk'

import { useTerms } from '../terms'

/**
 * 按日期查询所在的学期
 *
 * @param semesters - 学期列表
 * @param date - 指定日期, 默认现在
 *
 * @returns 查到的学期
 */
export const findSemesterByDate = (
  semesters: Semester[],
  date?: MomentInput,
) => {
  return semesters.find(s =>
    isWithinRange(date ?? moment(), {
      startAt: s.startAt,
      endAt: s.endAt,
    }),
  )
}

/**
 * 查找离指定日期最近的学期, 优先返回日期所在学期, 其次返回开始时间或结束时间离指定日期最近的学期.
 *
 * @param semesters - 学期列表
 * @param date - 指定日期, 默认现在
 *
 * @returns 查到的学期
 */
export const findNearestSemester = (
  semesters: Semester[],
  date?: MomentInput,
) => {
  const semesterByDate = findSemesterByDate(semesters, date)
  return (
    semesterByDate ??
    minBy(semesters, s => {
      return min([
        Math.abs(moment(s.startAt).diff(date)),
        Math.abs(moment(s.endAt).diff(date)),
      ])
    })
  )
}

const gradeFilter = (items: Term[]) => items.filter(item => !!item.parentId)

/**
 * 仅获取年级 Term（不包含学部）
 *
 * @param queryOptions - 查询配置
 * @returns 年级
 */
export const useGradeTerms = (queryOptions?: QueryOptions) => {
  const { data: terms, loading } = useTerms(
    { type: TermTypeEnum.SystemSchoolStructure },
    {
      ...queryOptions,
      select: gradeFilter,
    },
  )

  return [terms, loading] as const
}

/**
 * 获取年级选项
 *
 * @returns 选项
 */
export const useGradeOptions = () => {
  const [terms] = useGradeTerms()

  return useMemo(
    () =>
      terms?.map(term => ({
        text: term.name,
        value: String(term.id),
      })),
    [terms],
  )
}

/**
 * 对传入的 Semester Data 做一些 hack 处理
 *
 * @param semesters - 学期信息
 * @returns hack 后的学期信息
 */
export const useHackSemesterData = (semesters: Semester[] | null) => {
  const isBDFZ = useIsBDFZ()

  const hackSemesters = useMemo(() => {
    if (!semesters?.length || !isBDFZ) return semesters

    // 为北大附中 hack 学期的开始时间
    const hackData: {
      // 学期 id: 学期开始时间展示
      [key: number]: string
    } = {
      // 2022-2023 学年第二学期
      49370: '2023-02-16 00:00:00',
      // 2023-2024 学年第二学期
      60760: '2024-02-26 00:00:00',
    }

    const hackSemesterIds = keys(hackData)

    const nextSemesters = [...semesters].map(smst => {
      if (!hackSemesterIds.includes(String(smst.id))) return smst

      return { ...smst, displayStartAt: hackData[smst.id] }
    })

    return nextSemesters
  }, [semesters, isBDFZ])

  return hackSemesters
}

/**
 * 根据当前日期获取与目标学期时间重叠的其他学期
 *
 * @param targetSemester - 目标学期
 * @param semesters - 学期列表
 * @returns 与目标学期时间重叠的其他学期
 */
export const getOverlappingSemestersByCurrentDate = (
  targetSemester: Semester,
  semesters: Semester[],
) => {
  return semesters.filter(
    semester =>
      // 非目标学期
      semester.id !== targetSemester.id &&
      // 与目标学期时间重叠
      willOverlap(targetSemester, semester, {
        inclusive: true,
      }) &&
      // 与当前日期时间重叠
      isWithinRange(moment(), semester, 'YYYY-MM-DD'),
  )
}

import { ClassWeek, ClassWeekInterface } from './extends/class-week'

export interface ClassScheduleWithIdInterface {
  /**
   * 周几
   */
  day: number
  /**
   * 节次名称
   */
  lessonName: string
  placeId?: number | null
  teacherIds?: number[]
  classWeek: ClassWeekInterface
  /**
   * 主键 ID
   */
  id: number
}

export class ClassScheduleWithId implements ClassScheduleWithIdInterface {
  /**
   * 周几
   */
  day: number
  /**
   * 节次名称
   */
  lessonName: string
  placeId?: number | null
  teacherIds?: number[]
  classWeek: ClassWeek
  /**
   * 主键 ID
   */
  id: number

  constructor(props: ClassScheduleWithIdInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      day: _day,
      lessonName: _lessonName,
      placeId: _placeId,
      teacherIds: _teacherIds,
      classWeek: _classWeek,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.day = _day
    this.lessonName = _lessonName
    this.placeId = _placeId
    this.teacherIds = _teacherIds
    this.classWeek = _classWeek ? new ClassWeek(_classWeek) : _classWeek
    this.id = _id
  }

  static propKeys = [
    'day',
    'lessonName',
    'placeId',
    'teacherIds',
    'classWeek',
    'id',
  ]
}

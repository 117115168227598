/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { AttendanceBizTypeEnum } from './attendance-biz-type-enum'
import { AttendanceTime, AttendanceTimeInterface } from './attendance-time'
import type { QueryBizAttendanceTimesQuery } from './query-biz-attendance-times-query'

const buildConfig = createConfigBuilder('sams')

export const attendanceRecordApi$queryBizAttendanceTimes = {
  config(
    bizType: AttendanceBizTypeEnum,
    bizId: number,
    queryBizAttendanceTimesQuery?: QueryBizAttendanceTimesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/attendance/{bizType}/{bizId}/attendance-times',
      {
        bizType,
        bizId,
      },
      queryBizAttendanceTimesQuery,
    )
  },

  /**
   * @summary 教务查询业务下考勤时间段
   * @param bizType
   * @param bizId
   * @param [queryBizAttendanceTimesQuery]
   * @param [attendanceRecordQuery.startAtElt]
   * @param [attendanceRecordQuery.startAtEgt]
   * @param [attendanceRecordQuery.endAtElt]
   * @param [attendanceRecordQuery.endAtEgt]
   * @param [attendanceRecordQuery.date]
   * @param [attendanceRecordQuery.expand]
   * @param [options]
   * @return AxiosResponsePromise<AttendanceTime[]>
   */

  api<const TQuery extends QueryBizAttendanceTimesQuery>(
    bizType: AttendanceBizTypeEnum,
    bizId: number,
    queryBizAttendanceTimesQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<AttendanceTime, TQuery['expand']>[]> {
    return axios(
      attendanceRecordApi$queryBizAttendanceTimes.config(
        bizType,
        bizId,
        queryBizAttendanceTimesQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new AttendanceTime(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryBizAttendanceTimesQuery,
    TSelected = Expand<AttendanceTime, TQuery['expand']>[],
  >(
    {
      bizType,
      bizId,
      query,
    }: {
      bizType: AttendanceBizTypeEnum
      bizId: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<AttendanceTime, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<AttendanceTime, TQuery['expand']>[], TSelected>(
      attendanceRecordApi$queryBizAttendanceTimes.api,
      'AttendanceRecord.queryBizAttendanceTimes',
      queryOptions,
      bizType,
      bizId,
      query,
    )
  },
}

// @ts-ignore
attendanceRecordApi$queryBizAttendanceTimes.api._name_ = `AttendanceRecord.queryBizAttendanceTimes`

export interface MemberTypeSettingInterface {
  name?: string
  joinAttendance?: number
  joinEvaluation?: number
  joinDiscuss?: number
  copyClass?: number
  usedCount?: number
  copiedClassCount?: number
  copiedClassIds?: number[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  /**
   * 学期 ID
   */
  semesterId: number
}

export class MemberTypeSetting implements MemberTypeSettingInterface {
  name?: string
  joinAttendance?: number
  joinEvaluation?: number
  joinDiscuss?: number
  copyClass?: number
  usedCount?: number
  copiedClassCount?: number
  copiedClassIds?: number[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  /**
   * 学期 ID
   */
  semesterId: number

  constructor(props: MemberTypeSettingInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      joinAttendance: _joinAttendance,
      joinEvaluation: _joinEvaluation,
      joinDiscuss: _joinDiscuss,
      copyClass: _copyClass,
      usedCount: _usedCount,
      copiedClassCount: _copiedClassCount,
      copiedClassIds: _copiedClassIds,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      semesterId: _semesterId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.joinAttendance = _joinAttendance
    this.joinEvaluation = _joinEvaluation
    this.joinDiscuss = _joinDiscuss
    this.copyClass = _copyClass
    this.usedCount = _usedCount
    this.copiedClassCount = _copiedClassCount
    this.copiedClassIds = _copiedClassIds
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
    this.semesterId = _semesterId
  }

  static propKeys = [
    'name',
    'joinAttendance',
    'joinEvaluation',
    'joinDiscuss',
    'copyClass',
    'usedCount',
    'copiedClassCount',
    'copiedClassIds',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
    'semesterId',
  ]
}

export enum PluginCategoryEnum {
  德育管理 = '德育管理',
  后勤管理 = '后勤管理',
  教师发展 = '教师发展',
  教务教学 = '教务教学',
  数据报告 = '数据报告',
  通用工具 = '通用工具',
  学生成长 = '学生成长',
  其他 = '其他',
}

/**
 * 在 redux -> jotai 的过渡期，通过以下方法允许 jotai 获取一些还存在 redux 中的数据。
 * 这些数据的获取方式不是 reactive 的，所以只适用于获取在一次网页 session 中不变的值，比如当前学校和用户的 id。
 */

import { RematchStore } from '@seiue/rematch-core'

import { Reflection } from 'packages/sdks-next/chalk'

let _store: RematchStore | null = null

/**
 * store setter
 *
 * @param s - store
 */
export const setStore = (s: RematchStore) => {
  _store = s
}

/**
 * store getter
 *
 * @returns store
 */
export const getStore = () => {
  if (!_store) throw new Error('Redux store not initialized')
  return _store
}

/**
 * 从 store 中取出当前 reflection id
 *
 * @returns 当前 reflection id
 */
export const getCurrentReflectionId = (): number | undefined => {
  return getStore().getState()['session'].currentReflection?.id
}

/**
 * 从 store 中取出当前 reflection
 *
 * @returns 当前 reflection
 */
export const getCurrentReflection = (): Reflection => {
  return getStore().getState()['session'].currentReflection
}

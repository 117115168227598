import {
  Expr,
  Variable,
  Literal,
  Value,
  FuncExpr,
  FieldExpr,
  BinaryExpr,
  BinaryOp,
  ColumnExpr,
  AndExpr,
  OrExpr,
  AggMethodEnum,
  AggExpr,
} from 'packages/sdks-next/nuwa'

/**
 * 判断是否是变量
 *
 * @param expr - 表达式
 * @returns boolean
 */
export const isVariable = (expr?: Expr): expr is Variable => {
  return expr?.type === 'variable'
}

/**
 * 判断是否是字面量表达式
 *
 * @param expr - 要判断的表达式
 * @returns 是否为字面量表达式
 */
export const isLiteral = (expr?: Expr): expr is Literal => {
  return expr?.type === 'literal'
}

/**
 * 判断是否是列引用表达式
 *
 * @param expr - 要判断的表达式
 * @returns 是否为列引用表达式
 */
export const isColumn = (expr?: Expr): expr is ColumnExpr => {
  return expr?.type === 'column'
}

/**
 * 判断是否是聚合表达式
 *
 * @param expr - 要判断的表达式
 * @returns 是否为聚合表达式
 */
export const isAggExpr = (expr?: Expr): expr is AggExpr => {
  return expr?.type === 'agg'
}

/**
 * 判断是否是函数表达式
 *
 * @param expr - 要判断的表达式
 * @returns 是否为函数表达式
 */
export const isFunc = (expr?: Expr): expr is FuncExpr => {
  return expr?.type === 'func'
}

/**
 * 判断是否是字段引用表达式
 *
 * @param expr - 要判断的表达式
 * @returns 是否为字段引用表达式
 */
export const isField = (expr?: Expr): expr is FieldExpr => {
  return expr?.type === 'field'
}

/**
 * 创建一个逻辑与表达式
 *
 * @param exprs - 要连接的多个表达式
 * @returns 逻辑与表达式
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const and_ = (...exprs: Expr[]): AndExpr => ({
  type: 'and',
  exprs,
})

/**
 * 创建一个逻辑或表达式
 *
 * @param exprs - 要连接的多个表达式
 * @returns 逻辑或表达式
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const or_ = (...exprs: Expr[]): OrExpr => ({
  type: 'or',
  exprs,
})

/**
 * 创建一个字面量表达式
 *
 * @param val - 字面量的值
 * @returns 字面量表达式
 */
export const lit = (val: Value | null): Literal => ({
  type: 'literal',
  value: val,
})

/**
 * 创建一个变量表达式
 *
 * @param name - 变量的名称
 * @returns 变量表达式
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const var_ = (name: string): Variable => ({
  type: 'variable',
  name,
})

/**
 * 创建一个函数调用表达式
 *
 * @param name - 函数的名称
 * @param args - 函数的参数
 * @returns 函数调用表达式
 */
export const func = (name: string, args: Expr[]): FuncExpr => ({
  type: 'func',
  name,
  args,
})

/**
 * 创建一个列引用表达式
 *
 * @param name - 列的名称
 * @returns 列引用表达式
 */
export const column = (name: string): ColumnExpr => ({
  type: 'column',
  name,
})

/**
 * 创建一个字段引用表达式
 *
 * @param ref - 引用的字段
 * @param name - 字段的名称
 * @returns 字段引用表达式
 */
export const field = (ref: string, name: string): FieldExpr => ({
  type: 'field',
  ref,
  name,
})

/**
 * 创建获取页面参数的表达式
 *
 * @param name -  参数名称
 * @returns 表达式
 */
export const pageParam = (name: string) => field('$params', name)

/**
 * 创建一个二元操作表达式
 *
 * @param left - 左操作数
 * @param op - 二元操作符
 * @param right - 右操作数
 * @returns 二元操作表达式
 */
export const binary = (left: Expr, op: BinaryOp, right: Expr): BinaryExpr => ({
  type: 'binary',
  op,
  left,
  right,
})

/**
 * 创建一个获取当前时间的表达式
 *
 * @param format - 格式，默认 RFC3339 即 Date.toISOString() 的格式
 * @returns 表达式
 */
export const now = (format?: string) => func('now', [lit(format || null)])

/**
 * 创建一个获取当前日期的表达式
 *
 * @returns 表达式
 */
export const date = () => func('date', [])

/**
 * 计数表达式
 *
 * @param expr - 内部表达式
 * @returns 表达式
 */
export const count = (expr: Expr): AggExpr => ({
  type: 'agg',
  method: AggMethodEnum.Count,
  expr,
})

/**
 * 是否是一个有效的运算表达式
 *
 * @param op - 运算符
 * @param left - 左值
 * @param right - 右值
 * @returns 是否有效
 */
export const isValidBinaryExpr = (
  op: BinaryOp,
  left: any,
  right: any,
): boolean => {
  if (
    [
      BinaryOp.Plus,
      BinaryOp.Minus,
      BinaryOp.Multiply,
      BinaryOp.Divide,
      BinaryOp.Mod,
      BinaryOp.Xor,
    ].includes(op)
  ) {
    return left != null && right != null
  }

  return true
}

import { moment, Moment } from '@seiue/moment'
import { isEqual } from '@seiue/util'
import React, { useMemo } from 'react'

import {
  filterTimetableDetailsByRange,
  useSemesterWeekOptions,
} from 'packages/features/semesters'
import { TimetableDetail } from 'packages/sdks-next/chalk'
import { timetableApi$queryTimetables } from 'packages/sdks-next/scms'

/**
 * 获取目标学期的课表结构
 *
 * @param targetSemesterId - 目标学期 id
 * @returns 课表结构
 */
export const useTimetables = (targetSemesterId?: number) => {
  const { data: timetables } = timetableApi$queryTimetables.useApi(
    {
      semesterId: targetSemesterId,
    },
    {
      disable: !targetSemesterId,
      staleTime: 5 * 60,
    },
  )

  return timetables || []
}

/**
 * 获取目标学期的课表结构 Enum
 *
 * @param targetSemesterId - 目标学期 id
 * @returns 课表结构 Enum
 */
export const useTimetableEnum = (targetSemesterId?: number) => {
  const timetables = useTimetables(targetSemesterId)
  const timetableEnum = React.useMemo(
    () =>
      timetables.map(timetable => ({
        label: timetable.name,
        value: timetable.id,
        isDefault: timetable.isDefault,
      })),
    [timetables],
  )

  return timetableEnum
}

/**
 * 获取指定学期、课表和周的可用课表详情, 会根据目标周的开始和结束时间，过滤出有效的课表结构，并合成出一个课表结构
 *
 * 1. 根据目标周的时间范围筛选出有效的课表记录
 * 2. 处理可能存在的课表时间重叠情况（如一周内存在新旧课表交替）
 * 3. 合并多个时间段的课表，生成完整的周课表结构
 *
 * @param param - 参数
 * @param param.semesterId - 学期 id
 * @param param.timetableId - 课表 id
 * @param param.week - 目标周
 * @param param.prioritizeMondayTimetable - 当新旧课表交替时，是否以周一的为准
 * @returns 包含课表及其详情的对象
 *
 * @example
 * // 示例：一周内新旧课表交替的数据结构
 * // 假设第 8 周的周三(2024-03-20)开始启用新课表
 * const timetableData = [
 *   {
 *     // 旧课表
 *     effectiveDates: [
 *       { startAt: '2024-03-01', endAt: '2024-03-19' },
 *     ],
 *     weekDays: [1, 2, 3, 4, 5],
 *     lessons: [
 *       { startAt: '08:00', endAt: '09:35', name: '第一节' },
 *       { startAt: '09:50', endAt: '11:25', name: '第二节' }
 *     ]
 *   },
 *   {
 *     // 新课表
 *     effectiveDates: [
 *       { startAt: '2024-03-20', endAt: '2024-07-31' },
 *     ],
 *     weekDays: [1, 2, 3, 4, 5],
 *     lessons: [
 *       { startAt: '08:30', endAt: '10:05', name: '第一节' },
 *       { startAt: '10:20', endAt: '11:55', name: '第二节' }
 *     ]
 *   }
 * ]
 *
 * @example
 * // 示例：当 prioritizeMondayTimetable = true 时
 * // 即使周三开始启用新课表，也会以周一的课表为准，返回：
 * const result = [
 *   {
 *     effectiveDates: [
 *       { startAt: '2024-03-01', endAt: '2024-03-19' },
 *     ],
 *     weekDays: [1, 2, 3, 4, 5], // 整周都使用旧课表的时间
 *     lessons: [
 *       { startAt: '08:00', endAt: '09:35', name: '第一节' },
 *       { startAt: '09:50', endAt: '11:25', name: '第二节' }
 *     ]
 *   }
 * ]
 *
 * @example
 * // 示例：当 prioritizeMondayTimetable = false 时
 * // 会保留新旧课表交替的结构，返回：
 * const result = [
 *   {
 *     effectiveDates: [
 *       { startAt: '2024-03-01', endAt: '2024-03-19' },
 *     ],
 *     weekDays: [1, 2],
 *     lessons: [
 *       { startAt: '08:00', endAt: '09:35', name: '第一节' },
 *       { startAt: '09:50', endAt: '11:25', name: '第二节' }
 *     ]
 *   },
 *   {
 *     effectiveDates: [
 *       { startAt: '2024-03-20', endAt: '2024-07-31' },
 *     ],
 *     weekDays: [3, 4, 5],
 *     lessons: [
 *       { startAt: '08:30', endAt: '10:05', name: '第一节' },
 *       { startAt: '10:20', endAt: '11:55', name: '第二节' }
 *     ]
 *   }
 * ]
 */
export const useFindTargetTimetableDetails = ({
  semesterId,
  timetableId,
  week,
  prioritizeMondayTimetable,
}: {
  semesterId: number
  timetableId?: number
  week?: number
  prioritizeMondayTimetable?: boolean
}) => {
  const timetables = useTimetables(semesterId)
  const { weeks: semesterWeeks } = useSemesterWeekOptions(semesterId)
  return useMemo(() => {
    if (!timetableId || !week) {
      return []
    }

    const targetWeek = semesterWeeks.find(
      semesterWeek => semesterWeek.week === week,
    )

    const timetable = timetables.find(tt => tt.id === timetableId)

    if (!timetable?.data || !targetWeek) return []

    // 构建目标周的时间范围
    const weekStart = moment(targetWeek.startAt).startOf('day')
    const weekEnd = moment(targetWeek.endAt).endOf('day')
    const filterTimeRange: [Moment, Moment] = [weekStart, weekEnd]

    // 获取在目标周时间范围内有效的课表
    const availableDetails = filterTimetableDetailsByRange(
      timetable.data,
      filterTimeRange,
    )

    if (!availableDetails.length) {
      return []
    }

    if (prioritizeMondayTimetable) {
      // 新旧课表交替时，以周一的为准
      const defaultDetail = availableDetails[0]

      // 同一个生效日期内，不同结构的课节会由多个 detail 组成，这里需要合并一下，组成一个完整周的结构
      const tableDetails = (timetable?.data || []).filter(d =>
        isEqual(defaultDetail?.effectiveDates, d?.effectiveDates),
      )

      return tableDetails
    }

    // 合并处理有效的课表记录，处理同一周内可能存在的课表时间重叠情况
    return availableDetails.reduce((acc, detail) => {
      // 由于课表生效范围可能不完整（如只包含部分周次），需要对每天进行有效性验证
      const validWeekDays = detail.weekDays?.filter(day => {
        const dayDate = moment(weekStart).add(day - 1, 'day')
        const dayRange: [Moment, Moment] = [
          dayDate.clone().startOf('day'),
          dayDate.clone().endOf('day'),
        ]

        return filterTimetableDetailsByRange([detail], dayRange).length > 0
      })

      // 如果当前课表在本周没有有效的上课日，跳过
      if (!validWeekDays?.length) {
        return acc
      }

      // 添加更新后的课表记录
      return [...acc, { ...detail, weekDays: validWeekDays }]
    }, [] as TimetableDetail[])
  }, [prioritizeMondayTimetable, semesterWeeks, timetableId, timetables, week])
}

/**
 * @file 埋点相关 utils
 */

import { useTracking } from '@seiue/tracking'
import { useEffect } from 'react'

import { useCurrentReflection } from 'packages/features/sessions/utils/data'
import { useLocalStorageState } from 'packages/utils/use-local-storage-state'

/**
 * 为埋点设置当前用户的 reflection id
 */
export const useSetTrackingUserId = () => {
  const { setUserId } = useTracking()
  const { id, schoolId, role } = useCurrentReflection()

  useEffect(() => {
    if (typeof id === 'symbol') return

    setUserId(`${schoolId}_${role}_${id}`)
  }, [id, schoolId, role, setUserId])
}

/**
 * 根据情况决定是否启用埋点
 *
 * @fixme 目前强制启用，后续需要根据学校配置来决定是否启用
 * @returns boolean
 */
export const useEnableTracking = () => {
  // WARNING！！ 请勿在此使用 useSchool（或者扩大范围，即 redux 相关的 hooks），否则可能导致主系统崩溃。具体原因尚不可知
  return true
}

export function useTrackEvent(options: {
  oncePerDay?: boolean
  bySchool: true
}): ({
  category,
  action,
}: {
  category: string
  action: string
  name?: string
}) => void

export function useTrackEvent(options?: {
  oncePerDay?: boolean
  bySchool?: false
}): ({
  category,
  action,
  name,
  value,
}: {
  category: string
  action: string
  name?: string
  value?: number
}) => void

/**
 * 埋点事件
 *
 * @param options - 配置项
 * @param options.oncePerDay - 是否每天只触发一次
 * @param options.bySchool - 是否根据学校触发
 *
 * @returns 埋点函数
 */
export function useTrackEvent(options?: {
  oncePerDay?: boolean
  bySchool?: boolean
}) {
  const { trackEvent } = useTracking()
  const { oncePerDay = false, bySchool = false } = options || {}
  const { schoolId } = useCurrentReflection()

  const [storageDate, setStorageDate] = useLocalStorageState<{
    [key: string]: string
  }>('seiue-event-tracking', {})

  return ({
    category,
    action,
    name,
    value,
  }: {
    category: string
    action: string
    name?: string
    value?: number
  }) => {
    if (oncePerDay) {
      const key = `${category}.${action}.${name || ''}`

      const lastDate = storageDate[key]

      if (lastDate === new Date().toDateString()) return

      setStorageDate({
        ...storageDate,
        [key]: new Date().toDateString(),
      })
    }

    let eventParams = {
      category,
      action,
      name,
      value,
    }

    if (bySchool) {
      eventParams = {
        ...eventParams,
        name: `school.${schoolId}${name ? `.${name}` : ''}`,
        value: undefined,
      }
    }

    trackEvent(eventParams)
  }
}

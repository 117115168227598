/**
 * @file redux 相关的 utils
 */

import { PersistedState } from 'redux-persist'

/*
 * 当前的 redux-persist 迁移策略是不迁移...
 * version 变更后废弃旧 version 的缓存
 */
export const migratePersistedState = async (
  state: PersistedState,
  version: number,
) => {
  const cacheVersion = state?._persist?.version
  return version === cacheVersion
    ? state
    : { _persist: { version, rehydrated: true } }
}

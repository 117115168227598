export enum AssignmentStatusEnum {
  Locked = 'locked',
  Pending = 'pending',
  Read = 'read',
  Submitted = 'submitted',
  Rejected = 'rejected',
  Overdue = 'overdue',
  Approved = 'approved',
  Resubmitted = 'resubmitted',
}

export interface RangeInterface {
  /**
   * plan_id+random
   */
  id?: string
  /**
   * 07:00:00
   */
  startAt?: string
  /**
   * 08:00:00
   */
  endAt?: string
}

export class Range implements RangeInterface {
  /**
   * plan_id+random
   */
  id?: string
  /**
   * 07:00:00
   */
  startAt?: string
  /**
   * 08:00:00
   */
  endAt?: string

  constructor(props: RangeInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      startAt: _startAt,
      endAt: _endAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
    this.startAt = _startAt
    this.endAt = _endAt
  }

  static propKeys = ['id', 'startAt', 'endAt']
}

import { atom } from 'jotai'

import { LocaleTextLocaleEnum } from 'packages/sdks-next/chalk'

import { UsefulLocaleEnum } from './types'

/**
 * 本地化 atom
 */
export const localeAtom = atom<UsefulLocaleEnum | null>(null)

/**
 * 是否为英文 atom
 */
export const isEnLocaleAtom = atom<boolean>(
  get => get(localeAtom) === LocaleTextLocaleEnum.EnUS,
)

import { useMemo } from 'react'

import { useHasPluginWithAnyPermission } from 'packages/feature-utils/plugins'
import { useAccScoreConverterPlan } from 'packages/plugins/features/acc-score-converter/utils/apis'
import { PluginNameEnum } from 'packages/plugins/types'
import {
  AccConverterPlan,
  AccScoreReq,
  Item,
  ScoringTypeEnum,
  accomplishmentApi$queryAccItems,
} from 'packages/sdks-next/vnas'

/**
 * 判断评价项分数是否为「自动计算」分数
 *
 * 需要满足三个条件：
 * 1. 开启素养转化成绩插件
 * 2. 打分方式为分数
 * 3. 至少关联了一个打分型素养
 *
 * @param param0 - 参数
 * @param param0.item - 评价项
 * @param param0.taskId - 任务 id
 * @param param0.formAccScores - 任务关联的打分型素养分数
 * @returns 自动计算结果
 */
export const useIsItemScoreAutoCalc = ({
  item,
  taskId,
  formAccScores,
}: {
  item: Item
  taskId?: number
  formAccScores?: (AccScoreReq & {
    enableAggregated: boolean
    // 未在范围中的学生
    ridsWithoutScope: number[]
  })[]
}) => {
  // 素养转化成绩插件
  const converterPluginEnabled = useHasPluginWithAnyPermission(
    PluginNameEnum.AccScoreConverter,
  )

  // 分数评价项
  const isScoreItem = item.scoringType === ScoringTypeEnum.Score

  const disable = !converterPluginEnabled || !isScoreItem

  // 任务关联的打分型素养
  const { data: scoreItems } = accomplishmentApi$queryAccItems.useApi(
    {
      'itemRelations.relationId': taskId,
      paginated: 0,
      // 开启算分的都是打分型素养
      enableAggregated: true,
    },
    { disable: disable || !taskId },
  )

  // 素养转化成绩的规则
  const { data: converterPlan } = useAccScoreConverterPlan({ disable })

  // 参与分数计算的素养点数量
  const validAccScoresCount = converterPluginEnabled
    ? formAccScores?.filter(
        accScore =>
          accScore.enableAggregated && !accScore.ridsWithoutScope.length,
      ).length || 0
    : 0

  return {
    autoCalc: !!validAccScoresCount || !!scoreItems?.length,
    validAccScoresCount,
    accScoreConverterPlan: converterPlan,
  }
}

/**
 * 计算素养同步到成绩的分数
 *
 * @param param0 - 参数
 * @param param0.isScoreAutoCalc - 是否自动计算分数
 * @param param0.accItemScores - 打分型素养分数
 * @param param0.accScoreConverterPlan - 素养转化成绩规则
 * @param param0.fullScore - 总分
 * @returns 自动计算分数
 */
export const useAutoCalcScoreByAccScores = ({
  isScoreAutoCalc,
  accItemScores,
  accScoreConverterPlan,
  fullScore,
}: {
  isScoreAutoCalc: boolean
  accItemScores?: (AccScoreReq & {
    enableAggregated: boolean
  })[]
  accScoreConverterPlan?: AccConverterPlan
  fullScore?: string | null
}) => {
  return useMemo(() => {
    const filteredScores =
      accItemScores?.filter(
        itemScore => itemScore.enableAggregated && !!itemScore.score,
      ) || []

    if (
      !isScoreAutoCalc ||
      !accScoreConverterPlan?.rules.length ||
      !fullScore ||
      !filteredScores.length
    ) {
      return null
    }

    const totalRate = filteredScores.reduce((prevRate, currentScore) => {
      const targetRule = accScoreConverterPlan.rules.find((rule, ruleIdx) => {
        const score = Number(currentScore.score)
        const ruleScore = Number(rule.score)

        if (ruleIdx === 0 && score >= ruleScore) {
          return true
        }

        if (rule.isBelow && score < ruleScore) {
          return true
        }

        return score >= ruleScore
      })

      return prevRate + Number(targetRule?.percent || 0)
    }, 0)

    const score = (totalRate / filteredScores.length / 100) * Number(fullScore)

    // 不四舍五入
    return Math.floor(score * 100) / 100
  }, [accItemScores, accScoreConverterPlan, fullScore, isScoreAutoCalc])
}

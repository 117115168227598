/**
 * @file 课程班 i18n 名称的工具方法
 */

import { compact, isNil, isString } from '@seiue/util'

import { $ct, $t } from 'packages/locale'
import { Course, Group, Term } from 'packages/sdks-next/chalk'
import { Class } from 'packages/sdks-next/scms'

import { i18nConnect } from '../i18n/utils'

// 课程和英文名字段结构
type I18nCourseName = Partial<Pick<Course, 'name' | 'ename'>>
// 课程班和英文名字段结构
type I18nClassName = Partial<Pick<Class, 'className' | 'classEname'>>
// 课程班全名结构（课程名 + 年级名 + 班级名）
type I18nClass = I18nCourseName &
  I18nClassName & {
    grades?: string[] | Term[] | null | undefined
  }

/**
 * 根据当前语言，获取课程名称
 *
 * @param course - 课程名字段
 * @param isEn - 是否为英文
 * @returns 课程名称
 */
export const getI18nCourseName = (
  course?: I18nCourseName | null | undefined,
  isEn?: boolean,
) => {
  if (isNil(course)) return ''
  return (isEn && course.ename ? course.ename : course.name) ?? ''
}

/**
 * 根据当前语言，获取课程班名称
 * 获取带课程/年级的全名请用 getClassDisplayNames 或者 class.getDisplayName(isEn)
 *
 * @param klass - 课程名字段
 * @param isEn - 是否为英文
 * @returns 课程名称
 */
export const getI18nClassName = (
  klass?: I18nClassName | null | undefined,
  isEn?: boolean,
) => {
  if (isNil(klass)) return ''
  return (isEn && klass.classEname ? klass.classEname : klass.className) ?? ''
}

/**
 * 根据当前语言，获取自定义语言翻译后的年纪名称
 *
 * @param grades - 年级名称数组，可以是 type 为 CourseSubject 的 Term[] 或者单纯字符串数组
 * @returns 年级名称
 */
export const getI18nGradeName = (
  grades: string[] | Term[] | null | undefined,
) => {
  if (!grades?.length) return ''

  const gradeNames = isString(grades[0])
    ? (grades as string[])
    : (grades as Term[]).map(g => g.name)

  return gradeNames.map(name => $ct(name, 'grade')).join($t('、'))
}

/**
 * 根据当前语言，获取课程班全名
 * 如果有字段缺失，则对应字段会返回 ''
 * 使用返回的数据拼接 join 时，注意要筛掉空字符串
 *
 * @param klass - 课程班
 * @param isEn - 是否为英文
 * @returns [课程名, 年级名, 班级名]
 */
export const getClassDisplayNames = (
  klass: I18nClass | null | undefined,
  isEn?: boolean,
) => {
  if (isNil(klass)) return ['', '', ''] as const

  const courseName = getI18nCourseName(klass, isEn)
  const klassName = getI18nClassName(klass, isEn)
  const gradeNames = getI18nGradeName(klass.grades)

  return [courseName, gradeNames, klassName] as const
}

/**
 * 根据当前语言，获取拼接后的课程班全名
 * 课程名 + 年级名 + 班级名
 *
 * @param klass - 课程班
 * @param isEn - 是否为英文
 * @returns 课程班全名
 */
export const getClassDisplayName = (
  klass: I18nClass | null | undefined,
  isEn?: boolean,
) => {
  if (isNil(klass)) return ''

  const [courseName, gradeName, className] = getClassDisplayNames(klass, isEn)

  return compact([courseName, i18nConnect(gradeName, className)]).join(' ')
}

/**
 * 从课程班对应的 group 中获取课程班全名
 *
 * @param group - 课程班对应的 group
 * @param isEn - 是否为英文
 * @returns 课程班全名
 */
export const getClassGroupDisplayName = (
  group: Pick<Group, 'extraFields'> | null | undefined,
  isEn?: boolean,
) => {
  if (isNil(group)) return ''

  return getClassDisplayName(
    {
      ...group.extraFields,
      grades: group.extraFields?.gradeNames?.split(','),
    },
    isEn,
  )
}

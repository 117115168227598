import { camelCase, noop } from '@seiue/util'
import React, { useMemo } from 'react'

import { useIsRcfdb } from 'packages/features/schools/hooks'
import { useCurrentReflection } from 'packages/features/sessions'
import { wait } from 'packages/sdks-next'
import { acmApi$queryConfigItemsByGroup } from 'packages/sdks-next/chalk'
import {
  Assessment,
  AssessmentTypeEnum,
  StageTypeEnum,
  commonApi$queryStages,
  defaultApi$publishKlassScores as vnasDefaultApi$publishKlassScores,
  defaultApi$loadKlassItem as vnasDefaultApi$loadKlassItem,
  defaultApi$submitKlassScores as vnasDefaultApi$submitKlassScores,
  defaultApi$patchKlassItem as vnasDefaultApi$patchKlassItem,
  klassApi$queryKlassAssessments,
  dormApi$publishDormItemScores,
  dormApi$loadDormItem,
  dormApi$patchDormItem,
  dormApi$queryDormAssessments,
  dormApi$queryDormGrades,
  dormApi$syncDormItemScores,
  dormApi$deleteDormItemScores,
  dormApi$patchDormItems,
  dormApi$revokeDormItem,
  klassApi$queryKlassGrades,
  defaultApi$syncKlassScores as vnasDefaultApi$syncKlassScores,
  defaultApi$deleteKlassScores as vnasDefaultApi$deleteKlassScores,
  defaultApi$patchKlassItems as vnasDefaultApi$patchKlassItems,
  defaultApi$revokeKlassItem as vnasDefaultApi$revokeKlassItem,
} from 'packages/sdks-next/vnas'
import {
  SchoolConfigGroupNameEnum,
  ServiceEnum,
} from 'packages/shared-stores/configs'

import { ApolloClassAssessmentConfig } from '../types'

export * from './acc-score-converter'

export const defaultSummaryColors = ['#21a18e', '#e79900', '#e4524b']

/**
 * 获取 Apollo 上的成绩评价配置
 *
 * @param assessment - 成绩评价
 * @returns 配置
 */
export const useClassAssessmentConfig = (assessment?: Assessment | null) => {
  const currentReflection = useCurrentReflection()

  const disable = assessment
    ? assessment?.type !== AssessmentTypeEnum.Class
    : false

  const { data: settings, loading } = acmApi$queryConfigItemsByGroup.useApi(
    {
      scope: `school.${currentReflection.schoolId}`,
      service: ServiceEnum.VNAS,
      group: SchoolConfigGroupNameEnum.ClassAssessment,
    },
    {
      staleTime: 10 * 60,
      disable,
    },
  )

  const config: ApolloClassAssessmentConfig | null = React.useMemo(() => {
    if (disable) return null

    return settings?.length
      ? settings.reduce(
          (prevValue, currentSetting) => ({
            ...prevValue,
            [camelCase(currentSetting.name)]: currentSetting.value,
          }),
          {} as ApolloClassAssessmentConfig,
        )
      : {
          expandSummaryStructureByDefault: 0,
          gradeRealScoreMode: 'suggest',
          summaryColors: defaultSummaryColors,
          hideUnpublishedGradeForStudent: 0,
        }
  }, [disable, settings])

  return {
    data: config,
    loading,
  }
}

/**
 * 获取指定学期的学段成绩列表
 *
 * @param args - args
 * @param args.semesterId - 学期 id
 * @param args.type - 学段类型
 * @returns 学段成绩列表以及加载状态
 */
export const useSemesterStages = ({
  semesterId,
  type,
}: {
  semesterId: number
  type: StageTypeEnum | undefined
}) => {
  return commonApi$queryStages.useApi(
    {
      type: wait(type),
      query: {
        semesterId,
        paginated: 0,
      },
    },
    { staleTime: 5 * 60, disable: !semesterId || !type },
  )
}

/**
 * 获取评价所在学期的学段列表
 *
 * @param assessment - 评价
 * @returns 评价所在学期的学段列表
 */
export const useAssessmentSemesterStages = (
  assessment: Assessment | undefined | null,
) => {
  return useSemesterStages({
    semesterId: assessment?.semesterId ?? 0,
    type: assessment?.isClassAssessment ? StageTypeEnum.Class : undefined,
  })
}

/**
 * Hack: RCFDB 成绩相关
 *
 * @param itemName - 维度名称
 * @returns boolean
 */
export const useHackGradeForRcfdb = (itemName?: string) => {
  const isRcfdb = useIsRcfdb()

  if (!itemName) return false

  return isRcfdb && itemName.includes('Midterm Exam')
}

export function useAssessmentApis(assessmentType: AssessmentTypeEnum.Dorm): {
  queryGrades: typeof dormApi$queryDormGrades.api
  syncScores: typeof dormApi$syncDormItemScores.api
  deleteScores: typeof dormApi$deleteDormItemScores.api
  publishScores: ReturnType<typeof dormApi$publishDormItemScores.useApi>['api']
  submitScores: typeof noop
  updateItem: ReturnType<typeof dormApi$patchDormItem.useApi>['api']
  batchUpdateItems: typeof dormApi$patchDormItems.api
  revokeItem: typeof dormApi$revokeDormItem.api
}
export function useAssessmentApis(assessmentType?: AssessmentTypeEnum): {
  queryGrades: typeof klassApi$queryKlassGrades.api
  syncScores: typeof vnasDefaultApi$syncKlassScores.api
  deleteScores: typeof vnasDefaultApi$deleteKlassScores.api
  publishScores: ReturnType<
    typeof vnasDefaultApi$publishKlassScores.useApi
  >['api']
  submitScores: ReturnType<
    typeof vnasDefaultApi$submitKlassScores.useApi
  >['api']
  updateItem: ReturnType<typeof vnasDefaultApi$patchKlassItem.useApi>['api']
  batchUpdateItems: typeof vnasDefaultApi$patchKlassItems.api
  revokeItem: typeof vnasDefaultApi$revokeKlassItem.api
}
/**
 * 根据 AssessmentType 的不同，返回不同的 Api
 *
 * @param assessmentType - 评价类型
 * @returns Apis
 */
export function useAssessmentApis(assessmentType?: any) {
  const { api: publishKlassScores } = vnasDefaultApi$publishKlassScores.useApi({
    reload: [vnasDefaultApi$loadKlassItem.api],
  })

  const { api: submitKlassScores } = vnasDefaultApi$submitKlassScores.useApi({
    reload: [vnasDefaultApi$loadKlassItem.api],
  })

  const { api: updateKlassItem } = vnasDefaultApi$patchKlassItem.useApi({
    reload: [
      vnasDefaultApi$loadKlassItem.api,
      klassApi$queryKlassAssessments.api,
    ],
  })

  const { api: publishDormScores } = dormApi$publishDormItemScores.useApi({
    reload: [dormApi$loadDormItem.api],
  })

  const { api: updateDormItem } = dormApi$patchDormItem.useApi({
    reload: [dormApi$loadDormItem.api, dormApi$queryDormAssessments.api],
  })

  return useMemo(() => {
    if (assessmentType === AssessmentTypeEnum.Dorm) {
      return {
        queryGrades: dormApi$queryDormGrades.api,
        syncScores: dormApi$syncDormItemScores.api,
        deleteScores: dormApi$deleteDormItemScores.api,
        publishScores: publishDormScores,
        submitScores: noop,
        updateItem: updateDormItem,
        batchUpdateItems: dormApi$patchDormItems.api,
        revokeItem: dormApi$revokeDormItem.api,
      }
    }

    return {
      queryGrades: klassApi$queryKlassGrades.api,
      syncScores: vnasDefaultApi$syncKlassScores.api,
      deleteScores: vnasDefaultApi$deleteKlassScores.api,
      publishScores: publishKlassScores,
      submitScores: submitKlassScores,
      updateItem: updateKlassItem,
      batchUpdateItems: vnasDefaultApi$patchKlassItems.api,
      revokeItem: vnasDefaultApi$revokeKlassItem.api,
    }
  }, [
    assessmentType,
    publishDormScores,
    publishKlassScores,
    submitKlassScores,
    updateKlassItem,
    updateDormItem,
  ])
}

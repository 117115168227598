import { MessageDomainEnum } from 'packages/features/messages/types'
import { registerMessageNavigator } from 'packages/features/messages/utils'
import { AfterthoughtDomainEnum } from 'packages/sdks-next/vnas'

registerMessageNavigator(
  [
    `${MessageDomainEnum.ClassAssessment}.afterthought_published_for_teacher`,
    `${MessageDomainEnum.ClassAssessment}.afterthought_message_received_for_teacher`,
  ],
  msg => {
    const attrs = msg.attributes as any

    if (attrs) {
      return {
        path:
          attrs.domain === AfterthoughtDomainEnum.Assessment
            ? `/classes/${attrs.bizId}/grades/analysis?studentId=${
                attrs.creatorId ?? ''
              }`
            : `/classes/${attrs.bizId}/grades/analysis?itemId=${
                attrs.domainBizId
              }&studentId=${attrs.creatorId ?? ''}`,
      }
    }

    return null
  },
)

registerMessageNavigator(
  [
    `${MessageDomainEnum.ClassAssessment}.afterthought_message_commented_for_student`,
    `${MessageDomainEnum.ClassAssessment}.afterthought_uncompleted_notice_for_student`,
    `${MessageDomainEnum.ClassAssessment}.afterthought_message_received_for_student`,
  ],
  msg => {
    const attrs = msg.attributes as any

    if (attrs) {
      return {
        path:
          attrs.domain === AfterthoughtDomainEnum.Assessment
            ? `/classes/${attrs.bizId}/student-grades?tab=assessment`
            : `/classes/${attrs.bizId}/student-grades?tab=assessment&itemId=${attrs.domainBizId}`,
      }
    }

    return null
  },
)

registerMessageNavigator(
  [
    `${MessageDomainEnum.ClassAssessment}.afterthought_deleted_for_student`,
    `${MessageDomainEnum.ClassAssessment}.afterthought_message_deleted_for_student`,
    `${MessageDomainEnum.ClassAssessment}.afterthought_message_deleted_for_teacher`,
  ],
  () => null,
)

export interface ItemTagInterface {
  /**
   * 等级名或者标签名
   */
  name: string
  /**
   * 等级描述或者标签的备注
   */
  description?: string
  /**
   * 加减分、等级时的分数
   */
  score?: string
  /**
   * 加减分或者标签的组
   */
  groupName?: string
  /**
   * 徽章
   */
  badge?: string
}

export class ItemTag implements ItemTagInterface {
  /**
   * 等级名或者标签名
   */
  name: string
  /**
   * 等级描述或者标签的备注
   */
  description?: string
  /**
   * 加减分、等级时的分数
   */
  score?: string
  /**
   * 加减分或者标签的组
   */
  groupName?: string
  /**
   * 徽章
   */
  badge?: string

  constructor(props: ItemTagInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      description: _description,
      score: _score,
      groupName: _groupName,
      badge: _badge,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.description = _description
    this.score = _score
    this.groupName = _groupName
    this.badge = _badge
  }

  static propKeys = ['name', 'description', 'score', 'groupName', 'badge']
}

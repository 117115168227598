/**
 * @file 颜色变量的 native 版本,
 * 与 web 区别是文字颜色不透明 (因为 android 对文字颜色透明度支持不完善)
 */

import { ColorScheme } from './types'

/**
 * 品牌主色
 */
export const BrandColor = {
  /**
   * 品牌主色
   */
  _1: '#477cff',
  /**
   * 品牌文字主色
   */
  Text0: '#447ae6',
  /**
   * 图形元素色
   */
  _2: '#6195ff',
  /**
   * 插画某些区域用色
   */
  _3: '#86b2ff',
  /**
   * 禁用背景色
   */
  _4: '#c3daff',
  /**
   * 主色日程背景色
   */
  _5: '#ecf4ff',
  /**
   * 彩色按钮背景色
   */
  _6: '#ecf4ff',
  /**
   * 插画大面积背景色
   */
  Dark1: '#2c57e9',
  /**
   * 悬停点按色
   */
  Dark2: '#3958c2',
} as const

/**
 * 背景色
 */
export const BackgroundColor = {
  /**
   * 白色
   */
  _0: '#fff',
  /**
   * 玻璃材质色，表格深色行背景
   */
  _1: '#f9fafc',
  /**
   * 卡片分区内容的背景色，控件悬停色
   */
  _2: '#f6f8fa',
  /**
   * 页面背景色、表头、弹窗标题栏背景色
   */
  _3: '#f3f4f8',
  /**
   * 输入控件禁用
   */
  _4: '#f0f1f5',
  /**
   * 点按、选中
   */
  _5: '#edeff2',
  /**
   * 开关、单选框、复选框等控件禁用
   */
  _6: '#e4e6eb',
} as const

/**
 * 字体颜色
 */
export const TextColor = {
  /**
   * 白色
   */
  _0: '#ffffff',
  /**
   * 与主色对应的文字色
   */
  Brand: '#447ae6',
  /**
   * 标题, 主要正文
   */
  _1: '#2b303b',
  /**
   * 次要正文, 文字按钮
   */
  _2: '#5d6169',
  /**
   * 页面内的注释信息
   */
  _3: '#8f9197',
  /**
   * 控件内的占位信息，禁用状态控件的文字
   */
  _4: '#c1c2c5',
} as const

/**
 * 分割线色
 */
export const DividerColor = {
  /**
   * 深色，用于常见的内容区分
   */
  _1: 'rgba(50, 56, 66, 0.1)',
  /**
   * 浅色分割线, 用于部分控件细节
   */
  _2: 'rgba(50, 56, 66, 0.06)',
} as const

/**
 * 边框颜色
 */
export const BorderColor = {
  /**
   * 控件边框浅色
   */
  GreyLight: 'rgba(50, 56, 66, 0.1)',
  /**
   * 控件边框默认色
   */
  GreyNormal: '#d3d5db',
  /**
   * 控件边框悬停点按色
   */
  GreyDark: '#8f9197',
} as const

/**
 * 状态色
 */
export const StatusColor: {
  [key in 'Success' | 'Ongoing' | 'Warning' | 'Danger' | 'Invalid']: ColorScheme
} = {
  /**
   * 成功状态色
   */
  Success: {
    /**
     * 浅色背景
     */
    backgroundLight: '#e3f8f3',
    /**
     * 图形元素
     */
    element: '#16c89e',
    /**
     * 默认背景
     */
    background: '#21a18e',
    /**
     * 文字
     */
    text: '#00795c',
  },
  /**
   * 进行中状态色
   */
  Ongoing: {
    /**
     * 浅色背景
     */
    backgroundLight: '#ecf4ff',
    /**
     * 图形元素
     */
    element: '#477cff',
    /**
     * 默认背景
     */
    background: '#477cff',
    /**
     * 文字
     */
    text: '#447ae6',
  },
  /**
   * 警告状态色
   */
  Warning: {
    /**
     * 浅色背景
     */
    backgroundLight: '#fff5e3',
    /**
     * 图形元素
     */
    element: '#fca700',
    /**
     * 默认背景
     */
    background: '#e79900',
    /**
     * 文字
     */
    text: '#ad5b00',
  },
  /**
   * 危险状态色
   */
  Danger: {
    /**
     * 浅色背景
     */
    backgroundLight: '#fdefef',
    /**
     * 图形元素
     */
    element: '#f7585b',
    /**
     * 浅色图形元素
     */
    elementLight: '#f8d4d2',
    /**
     * 默认背景
     */
    background: '#e4524b',
    /**
     * 文字
     */
    text: '#c41a1d',
  },
  /**
   * 失效状态色
   */
  Invalid: {
    /**
     * 浅色背景
     */
    backgroundLight: '#e4e6eb',
    /**
     * 图形元素
     */
    element: '#8f9197',
    /**
     * 默认背景
     */
    background: '#8f9197',
    /**
     * 文字
     */
    text: 'rgba(6, 12, 25, 0.65)',
  },
} as const

/**
 * 辅色
 */
export const UIColor = {
  _1: {
    /**
     * 背景色
     */
    background: '#477cff',
    /**
     * 浅背景色
     */
    backgroundLight: '#ecf4ff',
    /**
     * 文字颜色
     */
    text: '#447ae6',
  },
  _2: {
    /**
     * 背景色
     */
    background: '#15b8b1',
    /**
     * 浅背景色
     */
    backgroundLight: '#e1fbfa',
    /**
     * 文字颜色
     */
    text: '#1d8e89',
  },
  _3: {
    /**
     * 背景色
     */
    background: '#e79900',
    /**
     * 浅背景色
     */
    backgroundLight: '#fff5e3',
    /**
     * 文字颜色
     */
    text: '#ad5b00',
  },
  _4: {
    /**
     * 背景色
     */
    background: '#d45586',
    /**
     * 浅背景色
     */
    backgroundLight: '#ffebef',
    /**
     * 文字颜色
     */
    text: '#b5295f',
  },
  _5: {
    /**
     * 背景色
     */
    background: '#8e6bf1',
    /**
     * 浅背景色
     */
    backgroundLight: '#eeecff',
    /**
     * 文字颜色
     */
    text: '#6a4bbe',
  },
  _6: {
    /**
     * 背景色
     */
    background: '#5975ba',
    /**
     * 浅背景色
     */
    backgroundLight: '#dbe3f5',
    /**
     * 文字颜色
     */
    text: '#3f5a9b',
  },
  _7: {
    /**
     * 背景色
     */
    background: '#60c381',
    /**
     * 浅背景色
     */
    backgroundLight: '#e7f9ed',
    /**
     * 文字颜色
     */
    text: '#3e8b57',
  },
  _8: {
    /**
     * 背景色
     */
    background: '#8e6796',
    /**
     * 浅背景色
     */
    backgroundLight: '#ebe3ec',
    /**
     * 文字颜色
     */
    text: '#77577d',
  },
  _9: {
    /**
     * 背景色
     */
    background: '#ea7b25',
    /**
     * 浅背景色
     */
    backgroundLight: '#fff1e3',
    /**
     * 文字颜色
     */
    text: '#c05400',
  },
  _10: {
    /**
     * 背景色
     */
    background: '#757aa6',
    /**
     * 浅背景色
     */
    backgroundLight: '#e4e6f2',
    /**
     * 文字颜色
     */
    text: '#5c618f',
  },
  _11: {
    /**
     * 背景色
     */
    background: '#17a0de',
    /**
     * 浅背景色
     */
    backgroundLight: '#dcf4ff',
    /**
     * 文字颜色
     */
    text: '#137dad',
  },
  _12: {
    /**
     * 背景色
     */
    background: '#829b9e',
    /**
     * 浅背景色
     */
    backgroundLight: '#dae4e5',
    /**
     * 文字颜色
     */
    text: '#526c6f',
  },
  _13: {
    /**
     * 背景色
     */
    background: '#c85be0',
    /**
     * 浅背景色
     */
    backgroundLight: '#f7e9fa',
    /**
     * 文字颜色
     */
    text: '#9e37b4',
  },
  _14: {
    /**
     * 背景色
     */
    background: '#4a9995',
    /**
     * 浅背景色
     */
    backgroundLight: '#cde6e5',
    /**
     * 文字颜色
     */
    text: '#317572',
  },
} as const

/**
 * 配色方案
 */
export const Palette: readonly ColorScheme[] = [
  UIColor._1,
  UIColor._2,
  UIColor._3,
  UIColor._4,
  UIColor._5,
  UIColor._6,
  UIColor._7,
  UIColor._8,
  UIColor._9,
  UIColor._10,
  UIColor._11,
  UIColor._12,
  UIColor._13,
  UIColor._14,
] as const

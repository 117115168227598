export interface SchoolPluginAdminsInterface {
  /**
   * 管理员 ids 数组
   */
  idIn?: number[]
  /**
   * 前端渲染使用
   */
  data?: object
}

export class SchoolPluginAdmins implements SchoolPluginAdminsInterface {
  /**
   * 管理员 ids 数组
   */
  idIn?: number[]
  /**
   * 前端渲染使用
   */
  data?: object

  constructor(props: SchoolPluginAdminsInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      idIn: _idIn,
      data: _data,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.idIn = _idIn
    this.data = _data
  }

  static propKeys = ['idIn', 'data']
}

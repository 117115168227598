/**
 * @file 桌面端布局上下文
 */
import React, { Dispatch } from 'react'

import type { RouteCrumb } from 'packages/components/Page/Breadcrumbs'

export const LayoutContext = React.createContext<{
  collapsed: boolean
  contentContainerEl: HTMLDivElement | null
  activeMenuPath: string
  setIsCollapsed: Dispatch<React.SetStateAction<boolean>> | null
  formatBreadcrumbs?: (breadcrumbs: RouteCrumb[]) => RouteCrumb[]
}>({
  collapsed: false,
  contentContainerEl: null,
  activeMenuPath: '',
  setIsCollapsed: null,
  formatBreadcrumbs: undefined,
})

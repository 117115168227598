import { initI18n, changeLocale } from '@seiue/error-handler'

import { LocaleTextLocaleEnum } from 'packages/sdks-next/chalk'

import { UsefulLocaleEnum } from './types'

initI18n()

/**
 * 设置错误处理器的语言
 *
 * @param locale - 语言
 */
export const setErrorHandlerLocale = (
  locale: UsefulLocaleEnum = LocaleTextLocaleEnum.ZhCN,
) => {
  changeLocale(locale === LocaleTextLocaleEnum.ZhCN ? 'zh-cn' : 'en')
}

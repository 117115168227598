/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  ReflectionWithUser,
  ReflectionWithUserInterface,
} from './extends/reflection-with-user'
import type { LoadGuardianQuery } from './load-guardian-query'

const buildConfig = createConfigBuilder('chalk')

export const reflectionApi$loadGuardian = {
  config(
    id: number,
    loadGuardianQuery?: LoadGuardianQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/reflection/guardians/{id}',
      {
        id,
      },
      loadGuardianQuery,
    )
  },

  /**
   * @summary Class LoadGuardianApi
   * @param id
   * @param [loadGuardianQuery]
   * @param [reflectionQuery.expand] expand
   * - pupil
   * @param [reflectionQuery.fieldLevel] 字段级别，默认为 base 。高级别会自动包含低级别的字段
   * @param [reflectionQuery.withFields] 基于安全级别之上，额外获取的字段。仅 APP 用户可用
   * @param [options]
   * @return AxiosResponsePromise<ReflectionWithUser>
   */

  api<const TQuery extends LoadGuardianQuery>(
    id: number,
    loadGuardianQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<ReflectionWithUser, TQuery['expand']>> {
    return axios(
      reflectionApi$loadGuardian.config(id, loadGuardianQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new ReflectionWithUser(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends LoadGuardianQuery,
    TSelected = Expand<ReflectionWithUser, TQuery['expand']>,
  >(
    {
      id,
      query,
    }: {
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<ReflectionWithUser, TQuery['expand']>,
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<ReflectionWithUser, TQuery['expand']>, TSelected>(
      reflectionApi$loadGuardian.api,
      'Reflection.loadGuardian',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
reflectionApi$loadGuardian.api._name_ = `Reflection.loadGuardian`

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { GroupTeacherBot, GroupTeacherBotInterface } from './group-teacher-bot'

const buildConfig = createConfigBuilder('ais')

export const aiTeacherApi$queryGroupTeacherBots = {
  config(id: number, options?: AxiosRequestExtraConfig): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/teacher-bot/teacher-bots/groups/{id}/bots',
      {
        id,
      },
    )
  },

  /**
   * @summary 【前台：教师/学生】获取某群组下教师机器人列表
   * @param id
   * @param [options]
   * @return AxiosResponsePromise<GroupTeacherBot[]>
   */

  api(
    id: number,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<GroupTeacherBot[]> {
    return axios(aiTeacherApi$queryGroupTeacherBots.config(id, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new GroupTeacherBot(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = GroupTeacherBot[]>(
    id: number,
    queryOptions?: QueryOptionsWithSelect<GroupTeacherBot[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<GroupTeacherBot[], TSelected>(
      aiTeacherApi$queryGroupTeacherBots.api,
      'AiTeacher.queryGroupTeacherBots',
      queryOptions,
      id,
    )
  },
}

// @ts-ignore
aiTeacherApi$queryGroupTeacherBots.api._name_ = `AiTeacher.queryGroupTeacherBots`

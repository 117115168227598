/**
 * @file 注册审批流消息
 */

import { PluginEnum } from 'packages/feature-utils/plugins'
import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

import { WorkflowMessageType } from './message-type'

registerDomainMessage({
  type: MessageDomainEnum.Approval,
  getLabel: () => $t('审批'),
  labelStyle: calculateLabelStyle('#D9E9FF', '#516A9A'),
  icon: `plugin.${PluginEnum.Workflow}`,
  messages: [
    WorkflowMessageType.Reminder,
    WorkflowMessageType.Updated,
    WorkflowMessageType.StageUpdated,
    WorkflowMessageType.CommentRemind,
  ],
})

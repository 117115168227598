/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Counter, CounterInterface } from './counter'

const buildConfig = createConfigBuilder('chalk')

export const counterApi$listMyCounters = {
  config(options?: AxiosRequestExtraConfig): AxiosRequestConfig {
    return buildConfig(options, 'get', '/counter/my-counters', {})
  },

  /**
   * @summary 获取与我相关的计数器。
   * @param [options]
   * @return AxiosResponsePromise<Counter[]>
   */

  api(options?: AxiosRequestExtraConfig): AxiosResponsePromise<Counter[]> {
    return axios(counterApi$listMyCounters.config(options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Counter(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = Counter[]>(
    queryOptions?: QueryOptionsWithSelect<Counter[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Counter[], TSelected>(
      counterApi$listMyCounters.api,
      'Counter.listMyCounters',
      queryOptions,
    )
  },
}

// @ts-ignore
counterApi$listMyCounters.api._name_ = `Counter.listMyCounters`

import { faArrowRight, faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { Button, Space, ButtonProps } from '@seiue/ui'

import { $t } from 'packages/locale'

import { FullScreenModalWithStepsProps } from './FullScreenModalWithSteps'

/**
 * 步骤条的上一步/下一步按钮
 *
 * @param props - props
 * @param props.stepIndex - 当前步骤下标
 * @param props.steps - 步骤条
 * @param props.finishText - 完成按钮文字
 * @param props.nextButtonProps - 下一步按钮 props
 * @param props.onPrev - 点击上一步的回调
 * @param props.onNext - 点击下一步的回调
 * @param props.onFinish - 点击完成的回调
 * @returns component
 */
export const StepsButton: React.FC<{
  stepIndex: number
  steps: FullScreenModalWithStepsProps['stepProps']['steps']
  finishText?: string
  nextButtonProps?: ButtonProps
  onPrev: (prevIndex: number) => void
  onNext: (nextIndex: number) => void
  onFinish: () => void
}> = ({
  stepIndex,
  steps,
  finishText,
  nextButtonProps,
  onPrev,
  onNext,
  onFinish,
}) => {
  if (!steps.length) return null

  return (
    <Space>
      {/* 上一步 */}
      {stepIndex > 0 ? (
        <PrevStepButton onClick={() => onPrev(stepIndex - 1)} />
      ) : null}

      {/* 下一步 */}
      {stepIndex < steps.length - 1 ? (
        <NextStepButton
          {...nextButtonProps}
          onClick={() => onNext(stepIndex + 1)}
        />
      ) : null}

      {/* 完成 */}
      {stepIndex === steps.length - 1 ? (
        <Button type="primary" onClick={onFinish}>
          {finishText || $t('完成')}
        </Button>
      ) : null}
    </Space>
  )
}

/**
 * 下一步按钮
 *
 * @param props - ButtonProps
 * @returns component
 */
export const NextStepButton: React.FC<ButtonProps> = props => {
  return (
    <Button type="primary" iconRight={faArrowRight} {...props}>
      {$t('下一步')}
    </Button>
  )
}

/**
 * 上一步按钮
 *
 * @param props - props
 * @param props.onClick - 点击事件
 * @returns component
 */
export const PrevStepButton: React.FC<{
  onClick: () => void
}> = ({ onClick }) => {
  return (
    <Button icon={faArrowLeft} onClick={onClick}>
      {$t('上一步')}
    </Button>
  )
}

import { ElectionModeEnum } from './election-mode-enum'
import { GroupSysTypeEnum } from './group-sys-type-enum'

export interface PricingPlanConstraintsInterface {
  /**
   * 附件上传限制，单位 byte 。默认 300M （300 * 1024 * 1024）
   */
  globalMaxAttachmentSize?: number
  /**
   * 附件上传数量。默认 10
   */
  globalMaxAttachmentCount?: number
  /**
   * 选课，开启模式。默认全开
   */
  electionEnabledModes?: ElectionModeEnum[]
  /**
   * 用户，最大学生数量。 默认 99999
   */
  reflectionMaxStudentNum?: number
  /**
   * 校历，支持多校历。默认 true
   */
  schcalEnableMultiSchcals?: boolean
  /**
   * 学期，支持多课表。默认 true
   */
  semesterEnableMultiTimetables?: boolean
  /**
   * 通用群组，开启类型。默认全开
   */
  groupEnabledTypes?: GroupSysTypeEnum[]
}

export class PricingPlanConstraints implements PricingPlanConstraintsInterface {
  /**
   * 附件上传限制，单位 byte 。默认 300M （300 * 1024 * 1024）
   */
  globalMaxAttachmentSize?: number
  /**
   * 附件上传数量。默认 10
   */
  globalMaxAttachmentCount?: number
  /**
   * 选课，开启模式。默认全开
   */
  electionEnabledModes?: ElectionModeEnum[]
  /**
   * 用户，最大学生数量。 默认 99999
   */
  reflectionMaxStudentNum?: number
  /**
   * 校历，支持多校历。默认 true
   */
  schcalEnableMultiSchcals?: boolean
  /**
   * 学期，支持多课表。默认 true
   */
  semesterEnableMultiTimetables?: boolean
  /**
   * 通用群组，开启类型。默认全开
   */
  groupEnabledTypes?: GroupSysTypeEnum[]

  constructor(props: PricingPlanConstraintsInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      globalMaxAttachmentSize: _globalMaxAttachmentSize,
      globalMaxAttachmentCount: _globalMaxAttachmentCount,
      electionEnabledModes: _electionEnabledModes,
      reflectionMaxStudentNum: _reflectionMaxStudentNum,
      schcalEnableMultiSchcals: _schcalEnableMultiSchcals,
      semesterEnableMultiTimetables: _semesterEnableMultiTimetables,
      groupEnabledTypes: _groupEnabledTypes,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.globalMaxAttachmentSize = _globalMaxAttachmentSize
    this.globalMaxAttachmentCount = _globalMaxAttachmentCount
    this.electionEnabledModes = _electionEnabledModes
    this.reflectionMaxStudentNum = _reflectionMaxStudentNum
    this.schcalEnableMultiSchcals = _schcalEnableMultiSchcals
    this.semesterEnableMultiTimetables = _semesterEnableMultiTimetables
    this.groupEnabledTypes = _groupEnabledTypes
  }

  static propKeys = [
    'globalMaxAttachmentSize',
    'globalMaxAttachmentCount',
    'electionEnabledModes',
    'reflectionMaxStudentNum',
    'schcalEnableMultiSchcals',
    'semesterEnableMultiTimetables',
    'groupEnabledTypes',
  ]
}

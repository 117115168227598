import { createEnumHelper } from '@seiue/util'

import { $t } from 'packages/locale'
import {
  AssessmentStatusEnum,
  EvaluateTargetEnum,
  TrendEventTypeEnum,
} from 'packages/sdks-next/vnas'

/**
 * 德育内置的徽章
 *
 * @returns 内置徽章
 */
export const moralBadgeToImage = createEnumHelper({
  builtInBadge1: 'https://static.seiue.com/chalk/moral-assessments/badge-1.png',
  builtInBadge2: 'https://static.seiue.com/chalk/moral-assessments/badge-2.png',
  builtInBadge3: 'https://static.seiue.com/chalk/moral-assessments/badge-3.png',
  builtInBadge4: 'https://static.seiue.com/chalk/moral-assessments/badge-4.png',
  builtInBadge5: 'https://static.seiue.com/chalk/moral-assessments/badge-5.png',
  builtInBadge6: 'https://static.seiue.com/chalk/moral-assessments/badge-6.png',
  builtInBadge7: 'https://static.seiue.com/chalk/moral-assessments/badge-7.png',
  builtInBadge8: 'https://static.seiue.com/chalk/moral-assessments/badge-8.png',
  builtInBadge9: 'https://static.seiue.com/chalk/moral-assessments/badge-9.png',
  builtInBadge10:
    'https://static.seiue.com/chalk/moral-assessments/badge-10.png',
})

export const moralEvaluateTargetToLabel = createEnumHelper(() => ({
  [EvaluateTargetEnum.Reflection]: $t('学生'),
  [EvaluateTargetEnum.AdminclassGroup]: $t('行政班'),
}))

export const moralAssessmentStatusToLabel = createEnumHelper(() => ({
  [AssessmentStatusEnum.Pending]: $t('进行中'),
  [AssessmentStatusEnum.Published]: $t('已结算'),
}))

/**
 * 获取德育评价动态类型的 options
 *
 * @returns 动态类型的 options
 */
export const getMoralTrendTypeOptions = () => {
  return [
    {
      label: $t('发布'),
      value: [
        TrendEventTypeEnum.ScorePublished,
        TrendEventTypeEnum.ReviewPublished,
        TrendEventTypeEnum.AttendanceScoreAdded,
        TrendEventTypeEnum.AdditionScoreAdded,
        TrendEventTypeEnum.TagAdded,
        TrendEventTypeEnum.SpecialScoreAdded,
      ].join('+'),
    },
    {
      label: $t('修改'),
      value: [
        TrendEventTypeEnum.ScoreUpdated,
        TrendEventTypeEnum.ReviewUpdated,
      ].join('+'),
    },
    {
      label: $t('删除'),
      value: [
        TrendEventTypeEnum.AttendanceScoreRemoved,
        TrendEventTypeEnum.AdditionScoreRemoved,
        TrendEventTypeEnum.TagRemoved,
        TrendEventTypeEnum.SpecialScoreRemoved,
        TrendEventTypeEnum.ItemDeleted,
      ].join('+'),
    },
    {
      label: $t('更新'),
      value: [TrendEventTypeEnum.AttendanceScoreUpdated].join('+'),
    },
    {
      label: $t('结算'),
      value: [
        TrendEventTypeEnum.GradePublished,
        TrendEventTypeEnum.GradeRevoked,
      ].join('+'),
    },
  ]
}

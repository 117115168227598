/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { School, SchoolInterface } from './school'
import type { LoadSchoolQuery } from './load-school-query'

const buildConfig = createConfigBuilder('chalk')

export const systemApi$loadSchool = {
  config(
    id: string,
    loadSchoolQuery?: LoadSchoolQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/system/schools/{id}',
      {
        id,
      },
      loadSchoolQuery,
    )
  },

  /**
   * @summary 获取单个学校信息
   * @param id
   * @param [loadSchoolQuery]
   * @param [systemQuery.expand]
   * @param [options]
   * @return AxiosResponsePromise<School>
   */

  api<const TQuery extends LoadSchoolQuery>(
    id: string,
    loadSchoolQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<School, TQuery['expand']>> {
    return axios(systemApi$loadSchool.config(id, loadSchoolQuery, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new School(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends LoadSchoolQuery,
    TSelected = Expand<School, TQuery['expand']>,
  >(
    {
      id,
      query,
    }: {
      id: string
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<School, TQuery['expand']>,
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<School, TQuery['expand']>, TSelected>(
      systemApi$loadSchool.api,
      'System.loadSchool',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
systemApi$loadSchool.api._name_ = `System.loadSchool`

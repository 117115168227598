/**
 * @file 注册任务消息
 */

import { getStudentTaskNavigation } from 'packages/feature-utils/tasks'
import { registerMessageNavigator } from 'packages/features/messages/utils'
import { EnhancerEnum, RoleEnum } from 'packages/sdks-next/chalk'

import { TaskMessageAttributes } from '../types/message'

registerMessageNavigator(['task.discussion_liked'], message => {
  const attributes = message.attributes as any
  const taskId = attributes?.id

  if (!taskId) return null

  return {
    modalName: 'ClassTasksViewModal',
    options: { id: taskId },
  }
})

registerMessageNavigator('task', (message, currentReflection) => {
  const isTeacher = currentReflection.role === RoleEnum.Teacher

  const {
    id,
    type,
    parentTaskId,
    questionnaireId,
    relatedHandoutId,
    senderId = '',
  } = message.attributes as TaskMessageAttributes

  if (isTeacher) {
    return {
      modalName: 'ClassTasksViewModal',
      options: { id, activeRid: senderId, skipAdminPermission: 1 },
    }
  }

  if (
    !type ||
    (type === EnhancerEnum.ClassQuestionnaireTask && !questionnaireId) ||
    (type === EnhancerEnum.ClassQuizTask && !relatedHandoutId)
  ) {
    // 之前的消息，没有带上 type，或者测验/问卷任务没有相关的 id，则不跳转
    return null
  }

  const [modalName, options] = getStudentTaskNavigation({
    task: {
      id: id ?? 0,
      labels: { type: type as EnhancerEnum },
      parentTaskId,
      customFields: { questionnaireId, relatedHandoutId },
    },
  })

  return {
    modalName,
    options,
  }
})

registerMessageNavigator(
  [
    'task.deleted',
    'task.canceled',
    'task.discussion_topic_archived',
    'task.discussion_comment_archived',
    'task.discussion_reply_archived',
  ],
  () => null,
)

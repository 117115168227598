import { EnhancerEnum, Task, TaskEnhancerEnum } from 'packages/sdks-next/chalk'

export * from './assessment-item'
export * from './submission'

/**
 * 判断一个任务是否同步自 canvas
 *
 * @param task - 任务
 * @returns 结果
 */
export const isTaskFromCanvas = (task: Pick<Task, 'outerSource'>) => {
  return task.outerSource === '67'
}

/**
 * 判断一个任务是否同步自 Chalk2.0
 *
 * @param task - 任务
 * @returns 结果
 */
export const isTaskFromChalk = (task: Pick<Task, 'outerSource'>) => {
  return task.outerSource === 'chalk2'
}

/**
 * 获取 task 或者相关查询 query 中的 enhancerIn 参数
 *
 * @returns enhancerIn 参数
 */
export const getEnhancerIn = () => {
  return [
    TaskEnhancerEnum.ClassHomeworkTask,
    TaskEnhancerEnum.ClassActivityTask,
    TaskEnhancerEnum.ClassDocumentTask,
    TaskEnhancerEnum.ClassWebsiteTask,
    TaskEnhancerEnum.ClassQuestionnaireTask,
    TaskEnhancerEnum.ClassQuizTask,
    TaskEnhancerEnum.ClassDiscuzTask,
    TaskEnhancerEnum.ClassLiveTask,
  ].join(',')
}

type GetTaskNavigationParams = {
  /**
   * 任务信息
   */
  task: Pick<Task, 'id' | 'customFields' | 'labels' | 'parentTaskId'>
  /**
   * 如果不是测验/问卷任务，返回的 modal 名称
   */
  defaultModal?: 'ClassTasksViewModal'
}

/**
 * 获取任务接受者的跳转信息,如果是测验/问卷任务，直接返回对应的测验/问卷提交 modal 和参数
 * 而不是先进入 TasksShowModal/ClassTasksViewModal，然后在里面根据任务类型二次跳转（两个 modal 交替的时候回闪一下）
 * 适用于任务接受者的任务跳转处理，如学生的课程班任务列表/任务代办
 *
 * @param {GetTaskNavigationParams} params0 - 参数对象
 * @returns [modalName, modalParams, needExtraParams]，其中 needExtraParams 表示是否需要额外的参数
 */
export const getStudentTaskNavigation = ({
  task,
  defaultModal = 'ClassTasksViewModal',
}: GetTaskNavigationParams) => {
  const isQuestionnaireTask =
    task.labels.type === EnhancerEnum.ClassQuestionnaireTask

  const isQuizTask = task.labels.type === EnhancerEnum.ClassQuizTask

  if (isQuestionnaireTask) {
    return [
      'SubmitQuestionnaireTaskModal',
      { id: task.customFields?.questionnaireId ?? 0, taskId: task.id },
      false,
    ] as const
  }

  if (isQuizTask) {
    return [
      'SubmitQuizTaskModal',
      {
        taskId: task.id,
        handoutId: task.customFields?.relatedHandoutId ?? 0,
      },
      false,
    ] as const
  }

  return [defaultModal, { id: task.parentTaskId || task.id }, true] as const
}

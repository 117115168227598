/**
 * @file 「北京市监测平台」插件相关工具函数
 */

import { isReactNative, stringifyURL } from '@seiue/util'

/**
 * 后续可迁移为插件设置
 */
const schoolIdToParams = {
  // 史家小学
  124: {
    user: 'sjxx',
    id: '4026bc4d803c34c30180737af2d72d19',
  },
  // 北大附中石景山学校
  132: {
    user: 'zhjs',
    id: '4026bc4d81d22bca0181d2a3d9252bf7',
  },
}

/**
 * 注册「北京市监测平台」
 *
 * @param schoolId - 学校 ID
 */
export const registerBjMonitorPlatform = (schoolId: number) => {
  if (!isReactNative) {
    // 先清除再创建, 防止重复创建
    const removeEffect = () => {
      document.querySelector('#_bxtj')?.remove()
      document.querySelector('#_bxtjiframe')?.remove()
    }

    removeEffect()

    const platformUrl = 'https://bjedures.bjedu.cn/bjjw_logdb/bxlog.js'

    const scriptUrl = stringifyURL(
      platformUrl,
      (schoolIdToParams as any)[schoolId],
    )

    setTimeout(() => {
      const _s = document.createElement('script')
      _s.setAttribute('type', 'text/javascript')
      _s.setAttribute('id', '_bxtj')
      _s.setAttribute('async', String(true))
      _s.setAttribute('src', scriptUrl)
      const body = document.getElementsByTagName('body')
      body[0].appendChild(_s)
    }, 500)
  }
}

import { EvaluationGroupBizTypeEnum } from './evaluation-group-biz-type-enum'
import { EvaluationTargetEnum } from './evaluation-target-enum'
import {
  GroupEvaluatedMember,
  GroupEvaluatedMemberInterface,
} from './group-evaluated-member'
import {
  EvaluationScoreStatsDimension,
  EvaluationScoreStatsDimensionInterface,
} from './evaluation-score-stats-dimension'
import { AnswerViewTypeEnum } from './answer-view-type-enum'
import { EvaluationTypeEnum } from './evaluation-type-enum'
import { FormTemplate, FormTemplateInterface } from './extends/form-template'
import { Satisfaction, SatisfactionInterface } from './satisfaction'
import {
  EvaluationClassTeacherIds,
  EvaluationClassTeacherIdsInterface,
} from './evaluation-class-teacher-ids'

export interface EvaluationWithIsFilledInterface {
  isFilled?: boolean
  semesterId: number
  formTemplateId: number
  /**
   * 评教群组类型
   */
  groupBizType: EvaluationGroupBizTypeEnum
  /**
   * 评教群组 Ids
   */
  groupBizIds: number[]
  evaluationTarget: EvaluationTargetEnum
  /**
   * 评教结果已发送
   */
  resultSent: boolean
  /**
   * 发生变化的群组 Ids，用于同步更新检测
   */
  updatedGroupBizIds: number[]
  /**
   * 每个群组中参评的代课教师和参评/不参评的任课教师
   */
  groupEvaluatedMembers?: GroupEvaluatedMemberInterface[]
  /**
   * 分数统计维度
   */
  scoreStatsDimensions: EvaluationScoreStatsDimensionInterface[]
  /**
   * 作答视图
   */
  viewType: AnswerViewTypeEnum
  /**
   * 评教类型
   */
  type: EvaluationTypeEnum
  /**
   * 是否需要登录填写评教
   */
  needLogin: boolean
  /**
   * 表单模板
   */
  formTemplate?: FormTemplateInterface
  /**
   * 教学满意度调查
   */
  satisfaction?: SatisfactionInterface | null
  /**
   * 课程班评教中参与评教的班级和任课教师 Ids
   */
  classTeacherIds?: EvaluationClassTeacherIdsInterface[]
  /**
   * expand total_amount
   * 匿名评教 - 应提交总人数
   */
  totalAmount?: number
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
}

export class EvaluationWithIsFilled implements EvaluationWithIsFilledInterface {
  isFilled?: boolean
  semesterId: number
  formTemplateId: number
  /**
   * 评教群组类型
   */
  groupBizType: EvaluationGroupBizTypeEnum
  /**
   * 评教群组 Ids
   */
  groupBizIds: number[]
  evaluationTarget: EvaluationTargetEnum
  /**
   * 评教结果已发送
   */
  resultSent: boolean
  /**
   * 发生变化的群组 Ids，用于同步更新检测
   */
  updatedGroupBizIds: number[]
  /**
   * 每个群组中参评的代课教师和参评/不参评的任课教师
   */
  groupEvaluatedMembers?: GroupEvaluatedMember[]
  /**
   * 分数统计维度
   */
  scoreStatsDimensions: EvaluationScoreStatsDimension[]
  /**
   * 作答视图
   */
  viewType: AnswerViewTypeEnum
  /**
   * 评教类型
   */
  type: EvaluationTypeEnum
  /**
   * 是否需要登录填写评教
   */
  needLogin: boolean
  /**
   * 表单模板
   */
  formTemplate?: FormTemplate
  /**
   * 教学满意度调查
   */
  satisfaction?: Satisfaction | null
  /**
   * 课程班评教中参与评教的班级和任课教师 Ids
   */
  classTeacherIds?: EvaluationClassTeacherIds[]
  /**
   * expand total_amount
   * 匿名评教 - 应提交总人数
   */
  totalAmount?: number
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: EvaluationWithIsFilledInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      isFilled: _isFilled,
      semesterId: _semesterId,
      formTemplateId: _formTemplateId,
      groupBizType: _groupBizType,
      groupBizIds: _groupBizIds,
      evaluationTarget: _evaluationTarget,
      resultSent: _resultSent,
      updatedGroupBizIds: _updatedGroupBizIds,
      groupEvaluatedMembers: _groupEvaluatedMembers,
      scoreStatsDimensions: _scoreStatsDimensions,
      viewType: _viewType,
      type: _type,
      needLogin: _needLogin,
      formTemplate: _formTemplate,
      satisfaction: _satisfaction,
      classTeacherIds: _classTeacherIds,
      totalAmount: _totalAmount,
      id: _id,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.isFilled = _isFilled
    this.semesterId = _semesterId
    this.formTemplateId = _formTemplateId
    this.groupBizType = _groupBizType
    this.groupBizIds = _groupBizIds
    this.evaluationTarget = _evaluationTarget
    this.resultSent = _resultSent
    this.updatedGroupBizIds = _updatedGroupBizIds
    this.groupEvaluatedMembers = _groupEvaluatedMembers
      ? _groupEvaluatedMembers.map(i => new GroupEvaluatedMember(i))
      : _groupEvaluatedMembers
    this.scoreStatsDimensions = _scoreStatsDimensions
      ? _scoreStatsDimensions.map(i => new EvaluationScoreStatsDimension(i))
      : _scoreStatsDimensions
    this.viewType = _viewType
    this.type = _type
    this.needLogin = _needLogin
    this.formTemplate = _formTemplate
      ? new FormTemplate(_formTemplate)
      : _formTemplate
    this.satisfaction = _satisfaction
      ? new Satisfaction(_satisfaction)
      : _satisfaction
    this.classTeacherIds = _classTeacherIds
      ? _classTeacherIds.map(i => new EvaluationClassTeacherIds(i))
      : _classTeacherIds
    this.totalAmount = _totalAmount
    this.id = _id
    this.schoolId = _schoolId
  }

  static propKeys = [
    'isFilled',
    'semesterId',
    'formTemplateId',
    'groupBizType',
    'groupBizIds',
    'evaluationTarget',
    'resultSent',
    'updatedGroupBizIds',
    'groupEvaluatedMembers',
    'scoreStatsDimensions',
    'viewType',
    'type',
    'needLogin',
    'formTemplate',
    'satisfaction',
    'classTeacherIds',
    'totalAmount',
    'id',
    'schoolId',
  ]
}

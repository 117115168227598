import {
  get,
  isArray,
  isEqual,
  isObject,
  map,
  max,
  min,
  sum,
} from '@seiue/util'
import Decimal from 'decimal.js'
import { camelize } from 'humps'

import {
  AggExpr,
  AggMethodEnum,
  BinaryExpr,
  BinaryOp,
  Expr,
  ExprType,
} from 'packages/sdks-next/nuwa'

import { builtinFunctions } from './functions'
import { isValidBinaryExpr } from './utils'

export type PermissionChecker = (permissionName: string) => boolean

/**
 * 表达式执行器
 */
export class Evaluator {
  functions: Record<string, (...args: any[]) => any> = {}

  lazyFunctions: string[] = []

  hasPermission?: PermissionChecker

  /**
   * 构造函数
   *
   * @param hasPermission - 权限检查函数
   * @constructor
   */
  constructor(hasPermission?: PermissionChecker) {
    this.hasPermission = hasPermission
    this.registerBuiltinFunctions()
  }

  /**
   * 注册内置函数
   *
   * @private
   */
  private registerBuiltinFunctions() {
    Object.keys(builtinFunctions).forEach(name => {
      this.register(
        name,
        builtinFunctions[name as keyof typeof builtinFunctions],
      )
    })
  }

  /**
   * 注册函数
   *
   * @param name - 函数名称
   * @param func - 函数实现
   */
  public register(name: string, func: (...args: any[]) => any) {
    if (this.functions[name]) {
      throw new Error(`Function ${name} already exists`)
    }

    this.functions[name] = func
  }

  /**
   * 标记函数为延迟计算
   *
   * @param name - 函数名称
   */
  public markAsLazy(name: string) {
    this.lazyFunctions.push(name)
  }

  /**
   * 执行表达式
   *
   * @param expr - 表达式
   * @param variables - 变量列表
   * @returns 表达式执行结果
   */
  public evaluate(expr: Expr, variables: Record<string, any>): any {
    if (expr.type === ExprType.And) {
      return expr.exprs.every(e => !!this.evaluate(e, variables))
    }

    if (expr.type === ExprType.Or) {
      return expr.exprs.some(e => !!this.evaluate(e, variables))
    }

    if (expr.type === ExprType.Column) {
      return get(variables, camelize(expr.name))
    }

    if (expr.type === ExprType.Field) {
      const variable = get(variables, camelize(expr.ref))
      return get(variable, camelize(expr.name))
    }

    if (expr.type === ExprType.Literal) {
      return expr.value
    }

    if (expr.type === ExprType.Variable) {
      return get(variables, camelize(expr.name))
    }

    if (expr.type === ExprType.Func) {
      const args = this.lazyFunctions.includes(expr.name)
        ? expr.args
        : expr.args.map(arg => this.evaluate(arg, variables))

      const func = this.functions[expr.name]
      if (!func) {
        throw new Error(`Function ${expr.name} not found`)
      }

      return func(...args)
    }

    if (expr.type === ExprType.Binary) {
      return this.evaluateBinaryExpr(expr, variables)
    }

    if (expr.type === ExprType.Agg) {
      return this.evaluateAggExpr(expr, variables)
    }

    throw new Error(`Unsupported expression type`)
  }

  /**
   * 执行表达式(异步)
   *
   * @param expr - 表达式
   * @param variables - 变量列表
   * @returns 表达式执行结果
   */
  public async evaluateAsync(
    expr: Expr,
    variables: Record<string, any>,
  ): Promise<any> {
    if (expr.type === ExprType.Literal) {
      return expr.value
    }

    if (expr.type === ExprType.Variable) {
      return variables[camelize(expr.name)]
    }

    if (expr.type === ExprType.Func) {
      const args = await Promise.all(
        expr.args.map(async arg => this.evaluateAsync(arg, variables)),
      )

      const func = this.functions[expr.name]
      if (!func) {
        throw new Error(`Function ${expr.name} not found`)
      }

      return func(...args)
    }

    if (expr.type === ExprType.Binary) {
      return this.evaluateBinaryExpr(expr, variables)
    }

    if (expr.type === ExprType.Agg) {
      return this.evaluateAggExpr(expr, variables)
    }

    throw new Error(`Unsupported expression type: ${expr.type}`)
  }

  /**
   * 执行二元表达式
   *
   * @param expr - 表达式
   * @param variables - 变量列表
   * @returns 表达式执行结果
   * @private
   */
  private evaluateBinaryExpr(
    expr: BinaryExpr,
    variables: Record<string, any>,
  ): any {
    const left = this.evaluate(expr.left, variables)
    const right = expr.right ? this.evaluate(expr.right, variables) : undefined

    if (!isValidBinaryExpr(expr.op, left, right)) {
      return null
    }

    switch (expr.op) {
      case BinaryOp.Eq:
        return left === right
      case BinaryOp.Neq:
        return left !== right
      case BinaryOp.Gt:
        return left > right
      case BinaryOp.Gte:
        return left >= right
      case BinaryOp.Lt:
        return left < right
      case BinaryOp.Lte:
        return left <= right

      case BinaryOp.Plus:
        return new Decimal(left).add(new Decimal(right)).toNumber()
      case BinaryOp.Minus:
        return new Decimal(left).sub(new Decimal(right)).toNumber()
      case BinaryOp.Multiply:
        return new Decimal(left).mul(new Decimal(right)).toNumber()
      case BinaryOp.Divide:
        return new Decimal(left).div(new Decimal(right)).toNumber()
      case BinaryOp.Mod:
        return new Decimal(left).mod(new Decimal(right)).toNumber()
      case BinaryOp.Xor:
        // eslint-disable-next-line no-bitwise
        return left ^ right
      case BinaryOp.Between:
        return left >= right[0] && left <= right[1]
      case BinaryOp.Contains:
        return Evaluator.evaluateContains(left, right, false)
      case BinaryOp.NotContains:
        return Evaluator.evaluateContains(left, right, true)
      case BinaryOp.In:
        return right.includes(left)
      case BinaryOp.NotIn:
        return !right.includes(left)
      case BinaryOp.Overlaps:
        if (!isArray(left)) {
          return null
        }

        return left.some((item: any) => right.includes(item))
      case BinaryOp.NotOverlaps:
        if (!isArray(left)) {
          return null
        }

        return !left.some((item: any) => right.includes(item))
      case BinaryOp.StartsWith:
        return left.startsWith(right)
      case BinaryOp.EndsWith:
        return left.endsWith(right)
      case BinaryOp.IsEmpty:
        return (
          left === undefined ||
          left === null ||
          left === '' ||
          isEqual(left, [])
        )
      case BinaryOp.IsNotEmpty:
        return !(
          left === undefined ||
          left === null ||
          left === '' ||
          isEqual(left, [])
        )
      case BinaryOp.HasPermission:
        if (!right) return false
        return !!this.hasPermission?.(right)
      default:
        throw new Error(`Unsupported binary operator: ${expr.op}`)
    }
  }

  /**
   * 判断是否为带 id 的对象
   *
   * @param value - 待判断的值
   * @returns 是否为带 id 的对象
   * @private
   */
  private static isObjectWithId(value: any): boolean {
    return !!(isObject(value) && (value as any).id)
  }

  /**
   * 判断是否包含
   *
   * @param left - 左值
   * @param right - 右值
   * @param inverse - 是否取反
   * @returns 是否包含
   * @private
   */
  private static evaluateContains(left: any, right: any, inverse: boolean) {
    if (!isArray(left)) {
      return null
    }

    let _left = left
    let _right = right
    // TODO 比较 hack 的实现，后期需要获取每个字段的类型，通过类型来评断处理逻辑
    if (
      Evaluator.isObjectWithId(_left?.[0]) &&
      Evaluator.isObjectWithId(_right)
    ) {
      _left = map(_left, 'id')
      _right = _right.id
    }

    const result = _left.includes(_right)

    return inverse ? !result : result
  }

  /**
   * 执行聚合表达式
   *
   * @param expr - 表达式
   * @param variables - 变量列表
   * @returns 表达式执行结果
   */
  private evaluateAggExpr(expr: AggExpr, variables: Record<string, any>): any {
    const innerValue = this.evaluate(expr.expr, variables)
    switch (expr.method) {
      case AggMethodEnum.Count:
        if (innerValue === null || innerValue === undefined) return 0
        return isArray(innerValue) ? innerValue.length : 1
      case AggMethodEnum.Avg:
        if (!isArray(innerValue)) return null
        return innerValue.length ? sum(innerValue) / innerValue.length : null
      case AggMethodEnum.Min:
        if (!isArray(innerValue) || !innerValue.length) return null
        return min(innerValue)
      case AggMethodEnum.Max:
        if (!isArray(innerValue) || !innerValue.length) return null
        return max(innerValue)
      case AggMethodEnum.Sum:
        if (!isArray(innerValue)) return null
        return sum(innerValue)
      default:
        return null
    }
  }

  /**
   * 表达式转换为字符串
   *
   * @param expr - 表达式
   * @param indent - 缩进
   * @returns 表达式字符串
   */
  static convertExprToString(expr: Expr, indent = 0): string {
    if (!expr.type) return ''
    switch (expr.type) {
      case ExprType.Binary:
        return `(${Evaluator.convertExprToString(expr.left)} ${
          expr.op
        } ${Evaluator.convertExprToString(expr.right)})`
      case ExprType.Literal:
        return expr.value !== null ? JSON.stringify(expr.value) : 'null'
      case ExprType.Func: {
        const args = expr.args.map(arg =>
          Evaluator.convertExprToString(arg, indent + 2),
        )

        const argsString = args.join(', ')
        const useNewLine = expr.name.length + argsString.length > 45

        if (useNewLine) {
          return `${expr.name}(\n${args
            .map(arg => `${' '.repeat(indent + 2)}${arg}`)
            .join(',\n')}\n${' '.repeat(indent)})`
        }

        return `${expr.name}(${argsString})`
      }

      case ExprType.Variable:
        return `{${expr.name}}`
      case ExprType.And:
        return expr?.exprs.map(Evaluator.convertExprToString).join(' && ')
      case ExprType.Or:
        return expr?.exprs.map(Evaluator.convertExprToString).join(' || ')
      default:
        throw new Error(`Unsupported expression type: ${expr.type}`)
    }
  }
}

import { CheckLimitsTypeEnum } from './check-limits-type-enum'

export interface CheckLimitsInterface {
  /**
   * 录入修改考勤限制天数
   */
  day?: number
  /**
   * 录入修改考勤限制课节-课节开始前分钟数
   */
  beforeStartAt?: number
  /**
   * 录入修改考勤限制课节-课节结束后分钟数
   */
  afterEndAt?: number
  /**
   * 限制类型
   */
  type: CheckLimitsTypeEnum
}

export class CheckLimits implements CheckLimitsInterface {
  /**
   * 录入修改考勤限制天数
   */
  day?: number
  /**
   * 录入修改考勤限制课节-课节开始前分钟数
   */
  beforeStartAt?: number
  /**
   * 录入修改考勤限制课节-课节结束后分钟数
   */
  afterEndAt?: number
  /**
   * 限制类型
   */
  type: CheckLimitsTypeEnum

  constructor(props: CheckLimitsInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      day: _day,
      beforeStartAt: _beforeStartAt,
      afterEndAt: _afterEndAt,
      type: _type,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.day = _day
    this.beforeStartAt = _beforeStartAt
    this.afterEndAt = _afterEndAt
    this.type = _type
  }

  static propKeys = ['day', 'beforeStartAt', 'afterEndAt', 'type']
}

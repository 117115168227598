export interface ExamScoringSettingsInterface {
  gradeGroupId?: number | null
  handoutSubjectId?: number | null
}

export class ExamScoringSettings implements ExamScoringSettingsInterface {
  gradeGroupId?: number | null
  handoutSubjectId?: number | null

  constructor(props: ExamScoringSettingsInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      gradeGroupId: _gradeGroupId,
      handoutSubjectId: _handoutSubjectId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.gradeGroupId = _gradeGroupId
    this.handoutSubjectId = _handoutSubjectId
  }

  static propKeys = ['gradeGroupId', 'handoutSubjectId']
}

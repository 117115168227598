import {
  ComputeRuleConditionValue,
  ComputeRuleConditionValueInterface,
} from './compute-rule-condition-value'

export interface ComputeRuleConditionInterface {
  items?: string[]
  /**
   * 当为暂不评价（不参与评价）时（目前仅支持 false）
   */
  isScoreValid: boolean
  /**
   * 暂不评价类型
   */
  invalidTypeIds?: number[]
  /**
   * 是否算分
   */
  shouldComputed?: boolean
  /**
   * 不算分时，要转换的目标暂不评价类型 ID
   */
  targetInvalidTypeId?: number
  /**
   * 换算一级维度的占分
   */
  withins: ComputeRuleConditionValueInterface[]
}

export class ComputeRuleCondition implements ComputeRuleConditionInterface {
  items?: string[]
  /**
   * 当为暂不评价（不参与评价）时（目前仅支持 false）
   */
  isScoreValid: boolean
  /**
   * 暂不评价类型
   */
  invalidTypeIds?: number[]
  /**
   * 是否算分
   */
  shouldComputed?: boolean
  /**
   * 不算分时，要转换的目标暂不评价类型 ID
   */
  targetInvalidTypeId?: number
  /**
   * 换算一级维度的占分
   */
  withins: ComputeRuleConditionValue[]

  constructor(props: ComputeRuleConditionInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      items: _items,
      isScoreValid: _isScoreValid,
      invalidTypeIds: _invalidTypeIds,
      shouldComputed: _shouldComputed,
      targetInvalidTypeId: _targetInvalidTypeId,
      withins: _withins,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.items = _items
    this.isScoreValid = _isScoreValid
    this.invalidTypeIds = _invalidTypeIds
    this.shouldComputed = _shouldComputed
    this.targetInvalidTypeId = _targetInvalidTypeId
    this.withins = _withins
      ? _withins.map(i => new ComputeRuleConditionValue(i))
      : _withins
  }

  static propKeys = [
    'items',
    'isScoreValid',
    'invalidTypeIds',
    'shouldComputed',
    'targetInvalidTypeId',
    'withins',
  ]
}

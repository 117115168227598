/**
 * @file check plugin
 */

import {
  ModuleEnum,
  PluginEnum,
  useHasManageablePlugin,
  useHasManageablePluginFunction,
  useHasPluginWithAnyPermissionFunction,
  useHasUsablePlugin,
} from 'packages/feature-utils/plugins'

/**
 * 查看是否拥有 me plugin 入口
 * @deprecated use useHasUsablePlugin from packages/feature-utils/plugins instead
 */
export const useHasPluginPermission = (name: PluginEnum | ModuleEnum) => {
  return useHasUsablePlugin(name)
}

/**
 * @deprecated use useHasManageablePluginFunction from 'packages/feature-utils/plugins' instead
 */
export const useHasPluginManagementPermissionFn = () => {
  const f = useHasManageablePluginFunction()

  return (name: string, { pluginId }: { pluginId?: number } = {}) => {
    return f(name as any, { id: pluginId })
  }
}

/**
 * 查看是否拥有 manage plugin 入口
 * @deprecated use useHasManageablePlugin from 'packages/feature-utils/plugins' instead
 */
export const useHasPluginManagementPermission = (
  name: string,
  options?: { pluginId?: number },
) => {
  return useHasManageablePlugin(name as any, { id: options?.pluginId })
}

/**
 * 查看是否拥有 plugin 入口
 * 只要 used 权限或者 managed 权限有任意一个即可
 * @deprecated use useHasPluginWithAnyPermissionFunction from packages/feature-utils/plugins instead
 */
export const useHasAnyPluginPermissionFun = () => {
  return useHasPluginWithAnyPermissionFunction()
}

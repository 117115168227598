/**
 * @file 审批流消息类型
 */

export enum WorkflowMessageType {
  /*
   * 发起人收到审批状态变更
   */
  Updated = 'seiue.form.workflow.flow_updated',
  /*
   * 抄送人收到消息
   */
  StageUpdated = 'seiue.form.workflow.flow_node_updated',
  /*
   * 审批人收到消息
   */
  Reminder = 'seiue.form.workflow.urge_for_approver',
  /**
   * 评论消息
   */
  CommentRemind = 'workflow.flow_commented',
}

export type WorkflowMessageAttributes = {
  flow?: { id?: number; workflowId?: number }
  workflow?: { extensionType?: string | null }
  relatedData?: {
    // 表单数据 id
    formId?: number
    // 无代码页面名称
    pageName?: string
    // 无代码应用名称
    pluginName?: string
  }
  // 评论 ID
  commentId?: number
}

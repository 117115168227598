/**
 * 基础模块名枚举 (与后端约定)
 */
import { RequireProps } from '@seiue/util'

import type { EnabledSchoolPlugin } from 'packages/sdks-next/chalk'

/**
 * 应用初始化时加载的全局插件数据的类型 (expand 了 plugin)
 */
export type EnabledPlugin = RequireProps<EnabledSchoolPlugin, 'plugin'>

export enum ModuleEnum {
  /**
   * 用户管理
   */
  Reflection = 'reflection',
  /**
   * 权限管理
   */
  Permission = 'permission',
  /**
   * 空间管理
   */
  Place = 'place',
  /**
   * 学期管理
   */
  Semester = 'semester',
  /**
   * 插件商店
   */
  Plugin = 'plugin',
  /**
   * 个性化设置
   */
  CustomSetting = 'custom-setting',
  /**
   * 多语言
   */
  I18n = 'i18n',
  /**
   * 课程班
   */
  Class = 'class',

  /**
   * 课程班 - 过程性评价
   */
  ClassAssessment = 'class-assessment',
  /**
   * 调代课申请管理
   */
  Adjustment = 'adjustment',
  /**
   * 行政班
   */
  AdminClass = 'adminclass',
  /**
   * 行政班考勤
   */
  AdminClassAttendance = 'adminclass-attendance',
  /**
   * 宿舍
   */
  Dorm = 'dorm',
  /**
   * 导师
   */
  Mentor = 'mentor',
  /**
   * 考试管理
   */
  Exam = 'exam',
  /**
   * 评教评学
   */
  Evaluation = 'evaluation',
  /**
   * 课时统计
   */
  ClassHour = 'class-hour',
  /**
   * 通知
   */
  Notice = 'notice',
  /**
   * 档案 - 目标 - 个人目标
   */
  ProfileGoal = 'profile-goal',
  /**
   * 目标管理, 目前不是插件
   */
  Goal = 'goal',
  /**
   * 档案 - 目标 - 学业目标
   */
  ProfileAcademicGoal = 'academic-goal',
  /**
   * 档案 - 目标 - 升学目标
   */
  ProfileIntlGoal = 'intl_goal',
  /**
   * 档案 - 近况
   */
  ProfileRecent = 'profile-recent',
  /**
   * 反馈
   */
  Feedback = 'feedback',
  /**
   * 档案 - 反馈
   *
   * @deprecated 用 Feedback 替代
   */
  ProfileFeedback = 'profile-feedback',
  /**
   * 档案 - 成绩
   */
  ProfileGrade = 'profile-grade',
  /**
   * 档案 - 群组
   */
  ProfileGroup = 'profile-group',
  /**
   * 档案 - 选科
   *
   * @deprecated 用 Direction 替代
   */
  ProfileDirection = 'profile-direction',
  /**
   * 日程
   */
  Schedule = 'schedule',
  /**
   * 课程班考勤
   */
  Attendance = 'attendance',
  /**
   * 课程库
   */
  Course = 'course',
  /**
   * 选科
   */
  Direction = 'direction',
  /**
   * 课程申报
   */
  ClassDeclaration = 'class_declaration',
  /**
   * 学期评语
   */
  SemesterReview = 'semester-review',
}

/**
 * 插件名枚举
 */
export enum PluginEnum {
  /**
   * 选课
   */
  Election = 'election',
  /**
   * 课业任务
   */
  ClassTasks = 'class-tasks',
  /**
   * 排课
   */
  Athena = 'athena',
  /**
   * 课程申报
   */
  ClassDeclaration = 'class_declaration',
  /**
   * 德育评价
   */
  MoralAssessment = 'moral_assessment',
  /**
   * 活动
   */
  Event = 'event',
  /**
   * 排考场
   */
  ExamRooms = 'exam_schedule',
  /**
   * 数据采集
   */
  DataSync = 'data-sync',
  /**
   * 认证
   */
  Certification = 'certification',
  /**
   * 认证积分
   */
  CertificationCredit = 'certification_credit',
  /**
   * 审批流
   * 因为应用商店对应的名字是审批流，所以 Approval 的值对应 workflow
   */
  Approval = 'workflow',
  /**
   * 网盘
   */
  NetDisk = 'netdisk',
  /**
   * 学籍管理
   */
  StudentStatus = 'student_status',
  /**
   * 新版学籍管理
   */
  StudentStatusProfile = 'student-status-profile',
  /**
   * 学分
   */
  Credit = 'credit',
  /**
   * 通用群组
   */
  CustomizedGroup = 'customized-group',
  /**
   * 请假
   */
  Absence = 'absence',
  /**
   * 约谈
   */
  Chat = 'chat',
  /**
   * ClassIn
   */
  ClassIn = 'classin',
  /**
   * 出入校管理
   */
  Eems = 'eems',
  /**
   * 异常考勤短信提醒
   */
  AbnormalAttendSms = 'abnormal-attend-sms',
  /**
   * 异常考勤通知
   */
  AbnormalAttendNotify = 'abnormal-attend-notify',
  /**
   * 短信平台
   */
  SmsPlatform = 'sms-platform',
  /**
   * 课程班总成绩提交审核
   */
  GradeSubmitReview = 'grade-submit-review',
  /**
   * 北京市监测平台
   */
  BjMonitorPlatform = 'bj-monitor-platform',
  /**
   * 深中教学满意度调查插件
   */
  SzTeachSatisficing = 'sz-teach-satisfacing',
  /**
   * 竞停生成绩认定
   */
  StopStudentGrade = 'stop-student-grade',
  /**
   * 深中成绩单
   */
  SzGradesReports = 'sz-grades-reports',
  /**
   * 课表查询
   */
  SzzxSchedule = 'szzx-schedule',
  /**
   * 学案
   */
  Handout = 'handout',
  /**
   * 问卷
   */
  Questionnaire = 'questionnaire',
  /**
   * 场地预约
   */
  Venue = 'venue',
  /**
   * 深中选科（志愿调研）
   */
  SzSubjectSelectionStat = 'sz-direction-stat',
  /**
   * 深圳中学定制补考规则
   */
  SZCustomResitRule = 'sz-custom-resit-rule',
  /**
   * 学业目标
   */
  AcademicGoal = 'academic-goal',
  /**
   * 广州中学新生入学问卷
   */
  GzFreshmanQuestionnaire = 'gz-freshman-question',
  /**
   * 广州中学考试成绩分析
   */
  GzExamResAnalysis = 'gz_exam_res_analysis',
  /**
   * 广州中学管理中心配置
   */
  GzzxHomeMenusSetting = 'gzzx_home_menus_setting',
  /**
   * 广州中学档案独立入口
   */
  GzArchive = 'gz_archive',
  /**
   * 广州中学待办布局优化
   */
  GzzxTodoLayout = 'gzzx_todo_layout',
  /**
   * 单点登陆
   */
  SSO = 'sso',
  /**
   * 人事管理
   */
  PersonnelProfile = 'personnel-profile',
  /**
   * 作业任务备课资料
   */
  TaskTeachingMaterial = 'task-teaching-material',
  /**
   * 素养评价
   */
  AccAssessment = 'accomplishment',
  /**
   * 批量更新素养评价
   */
  BatchAccAssessment = 'batch_acc_assessment',
  /**
   * 成功标准
   */
  SuccessCriteria = 'success_criteria',
  /**
   * 公开课
   */
  OpenCourse = 'open_course',
  /**
   * 审批
   */
  Workflow = 'workflow',
  /**
   * 审批中心
   */
  WorkflowCenter = 'workflow_center',
  /**
   * 学生心理档案
   */
  PsychologicalFile = 'psychological-file',
  /**
   * 深圳中学补考管理
   */
  ExamResit = 'exam-resit',
  /**
   * 定制成绩单
   */
  Cgr = 'cgr',
  /**
   * 教师档案填报
   */
  TeacherProfile = 'teacher_profile',
  /**
   * 停课
   */
  Tkgl = 'tkgl',
  /**
   * 素养转化成绩
   */
  AccScoreConverter = 'accomplishment_score_converter',
  /**
   * 即时聊天
   */
  Im = 'im',
  /**
   * 课程表
   */
  ClassTimetable = 'class_timetable',
  /**
   * 组织架构
   */
  OrganizationStructure = 'organization-structure',
  /**
   * 高级个性化设置
   */
  Personalization = 'personalization',
  /**
   * 报表中心
   */
  ReportCenter = 'report-center',
  /**
   * 编排工具
   */
  Gst = 'gst',
  /**
   * 圆点课堂
   */
  LMS = 'lms',
  /**
   * 教师考核
   */
  TeacherAssessments = 'teacher_assessment',
  /**
   * 契约
   */
  Contract = 'contract',
  /**
   * 宿舍
   */
  Dorm = 'dorm',
  /**
   * 阅卷系统
   */
  OnlineScoring = 'online-scoring',
  /**
   * 电子证书
   */
  ElecCertificate = 'elec-certificate',
  /**
   * 直播任务
   */
  LiveTasks = 'live-tasks',
  /**
   * 学生档案课程数据看板
   */
  MoonshotBoard = 'moonshot_kanban',
  /**
   * 成绩计算规则
   */
  GradeComputeRule = 'grade_compute_rule',
  /**
   * 考试
   */
  ExamsFrontend = 'exams-frontend',
  /**
   * 十五中定制成绩单
   */
  Bj15gGradesReports = 'bj15g-grades-reports',
  /**
   * 通知管理分类
   */
  NoticeType = 'notice_type',
  /**
   * 调代课
   */
  Adjustment = 'adjustment',
  /**
   * 国际校目标管理
   */
  IntlGoal = 'intl_goal',
  /**
   * 第三方阅卷
   */
  ThirdPartyScoring = 'third-party-scoring',
  /**
   * 光大阅卷
   */
  GuangDaScoring = 'guangda-scoring',
  /**
   * 学生聚合工具
   */
  StudentMerge = 'student-merge',
  /**
   * 新开普食堂订餐
   */
  NewcapecOrder = 'newcapec-order',
  /**
   * 访客系统
   */
  VisitorCenter = 'caller',
  /**
   * 靠谱 ai 工具
   */
  KaopuAiTool = 'kaopu-ai-tool',
  /**
   * 更靠谱的 AI 助手
   */
  ChalkAI = 'chalk-ai',
  /**
   * Chalk AI Copilot
   */
  Copilot = 'copilot',
  /**
   * 听评课
   */
  ClassReview = 'class-review',
  /**
   * 学期评语
   */
  SemesterReview = 'semester-review',
  /**
   * AI 教师
   */
  AITeacher = 'ai-teacher',
  /**
   * 教师考勤
   */
  TeacherAttendance = 'teacher-attendance',
  /**
   * 数据大屏
   */
  DataScreen = 'data-screen',
}

// 强制开启的模块
export const forceEnabledModules = [
  ModuleEnum.Reflection,
  ModuleEnum.Semester,
  ModuleEnum.Place,
  ModuleEnum.Class,
  ModuleEnum.Notice,
  ModuleEnum.CustomSetting,
  ModuleEnum.Permission,
  ModuleEnum.Plugin,
]

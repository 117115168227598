import { ExamReflectionTypeEnum } from './exam-reflection-type-enum'
import { ExamReflectionRoleEnum } from './exam-reflection-role-enum'
import { ExamReflectionStatusEnum } from './exam-reflection-status-enum'

export interface SupporterInterface {
  id?: number | null
  type: ExamReflectionTypeEnum
  role: ExamReflectionRoleEnum
  status?: ExamReflectionStatusEnum
  sessionId: number
  examSubjectId?: number | null
  rid: number
  name: string
  usin: string
  admissionTicketNum?: string
  discipline?: string
  sessionLimited?: number
  minuteLimited?: number
  examinerRules?: string | null
  deleted?: boolean | null
  /**
   * 考试 id
   */
  examId: number
  /**
   * 学校 ID
   */
  schoolId: number
}

export class Supporter implements SupporterInterface {
  id?: number | null
  type: ExamReflectionTypeEnum
  role: ExamReflectionRoleEnum
  status?: ExamReflectionStatusEnum
  sessionId: number
  examSubjectId?: number | null
  rid: number
  name: string
  usin: string
  admissionTicketNum?: string
  discipline?: string
  sessionLimited?: number
  minuteLimited?: number
  examinerRules?: string | null
  deleted?: boolean | null
  /**
   * 考试 id
   */
  examId: number
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: SupporterInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      type: _type,
      role: _role,
      status: _status,
      sessionId: _sessionId,
      examSubjectId: _examSubjectId,
      rid: _rid,
      name: _name,
      usin: _usin,
      admissionTicketNum: _admissionTicketNum,
      discipline: _discipline,
      sessionLimited: _sessionLimited,
      minuteLimited: _minuteLimited,
      examinerRules: _examinerRules,
      deleted: _deleted,
      examId: _examId,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
    this.type = _type
    this.role = _role
    this.status = _status
    this.sessionId = _sessionId
    this.examSubjectId = _examSubjectId
    this.rid = _rid
    this.name = _name
    this.usin = _usin
    this.admissionTicketNum = _admissionTicketNum
    this.discipline = _discipline
    this.sessionLimited = _sessionLimited
    this.minuteLimited = _minuteLimited
    this.examinerRules = _examinerRules
    this.deleted = _deleted
    this.examId = _examId
    this.schoolId = _schoolId
  }

  static propKeys = [
    'id',
    'type',
    'role',
    'status',
    'sessionId',
    'examSubjectId',
    'rid',
    'name',
    'usin',
    'admissionTicketNum',
    'discipline',
    'sessionLimited',
    'minuteLimited',
    'examinerRules',
    'deleted',
    'examId',
    'schoolId',
  ]
}

/**
 * @file 校历消息注册
 */
import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

import icon from './calendar.png'

registerDomainMessage({
  type: MessageDomainEnum.Schcal,
  getLabel: () => $t('校历'),
  labelStyle: calculateLabelStyle('#FFF1E3', '#C05400'),
  icon,
  messages: [
    'holiday_created',
    'holiday_deleted',
    'holiday_updated',
    'makeup_created',
    'makeup_deleted',
    'makeup_updated',
  ].map(type => ({
    type,
    important: true,
  })),
})

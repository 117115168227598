import { Scope, ScopeInterface } from './scope'
import { GroupMember, GroupMemberInterface } from './group-member'
import {
  GroupMemberLimits,
  GroupMemberLimitsInterface,
} from './group-member-limits'
import { GroupLabels, GroupLabelsInterface } from './group-labels'
import { GroupStatusEnum } from './group-status-enum'
import { Discussion, DiscussionInterface } from './discussion'
import {
  GroupExtraFields,
  GroupExtraFieldsInterface,
} from './group-extra-fields'

export interface ScopedRelationInterface {
  scopes: ScopeInterface[]
  /**
   * 关系｜岗位名称
   */
  name?: string | null
  /**
   * 关系｜岗位描述
   */
  description?: string | null
  /**
   * Group 所对应的作用域，取值为：
   * - 行政班（adminclass）
   * - 宿舍（dorm）
   * - 课程（course）
   * - 档案群组（relation）
   * - 课程班（class.semester-<semester_id>）
   */
  scope: string
  schoolId: number
  /**
   * 创建人 id
   */
  creatorId: number
  /**
   * 业务 id
   */
  bizId: string
  /**
   * 成员 expanded
   */
  members?: GroupMemberInterface[]
  /**
   * 管理员，为成员 members 的子集 expanded
   */
  managers?: GroupMemberInterface[]
  /**
   * 成员限制
   */
  limits: GroupMemberLimitsInterface[]
  /**
   * 群组关联的 Labels
   */
  labels?: GroupLabelsInterface
  /**
   * 群组的状态
   */
  status?: GroupStatusEnum
  /**
   * 所关联的讨论区 expanded
   */
  discussion?: DiscussionInterface | null
  extraFields?: GroupExtraFieldsInterface
  /**
   * e.g.g seiue.class_group, seiue.adminclass_group, seiue.dorm_group .
   * ..
   */
  type?: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class ScopedRelation implements ScopedRelationInterface {
  scopes: Scope[]
  /**
   * 关系｜岗位名称
   */
  name?: string | null
  /**
   * 关系｜岗位描述
   */
  description?: string | null
  /**
   * Group 所对应的作用域，取值为：
   * - 行政班（adminclass）
   * - 宿舍（dorm）
   * - 课程（course）
   * - 档案群组（relation）
   * - 课程班（class.semester-<semester_id>）
   */
  scope: string
  schoolId: number
  /**
   * 创建人 id
   */
  creatorId: number
  /**
   * 业务 id
   */
  bizId: string
  /**
   * 成员 expanded
   */
  members?: GroupMember[]
  /**
   * 管理员，为成员 members 的子集 expanded
   */
  managers?: GroupMember[]
  /**
   * 成员限制
   */
  limits: GroupMemberLimits[]
  /**
   * 群组关联的 Labels
   */
  labels?: GroupLabels
  /**
   * 群组的状态
   */
  status?: GroupStatusEnum
  /**
   * 所关联的讨论区 expanded
   */
  discussion?: Discussion | null
  extraFields?: GroupExtraFields
  /**
   * e.g.g seiue.class_group, seiue.adminclass_group, seiue.dorm_group .
   * ..
   */
  type?: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: ScopedRelationInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      scopes: _scopes,
      name: _name,
      description: _description,
      scope: _scope,
      schoolId: _schoolId,
      creatorId: _creatorId,
      bizId: _bizId,
      members: _members,
      managers: _managers,
      limits: _limits,
      labels: _labels,
      status: _status,
      discussion: _discussion,
      extraFields: _extraFields,
      type: _type,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.scopes = _scopes ? _scopes.map(i => new Scope(i)) : _scopes
    this.name = _name
    this.description = _description
    this.scope = _scope
    this.schoolId = _schoolId
    this.creatorId = _creatorId
    this.bizId = _bizId
    this.members = _members ? _members.map(i => new GroupMember(i)) : _members
    this.managers = _managers
      ? _managers.map(i => new GroupMember(i))
      : _managers
    this.limits = _limits ? _limits.map(i => new GroupMemberLimits(i)) : _limits
    this.labels = _labels ? new GroupLabels(_labels) : _labels
    this.status = _status
    this.discussion = _discussion ? new Discussion(_discussion) : _discussion
    this.extraFields = _extraFields
      ? new GroupExtraFields(_extraFields)
      : _extraFields
    this.type = _type
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'scopes',
    'name',
    'description',
    'scope',
    'schoolId',
    'creatorId',
    'bizId',
    'members',
    'managers',
    'limits',
    'labels',
    'status',
    'discussion',
    'extraFields',
    'type',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

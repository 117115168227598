/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { LocaleTextLocaleEnum } from './locale-text-locale-enum'
import { LocaleText, LocaleTextInterface } from './locale-text'
import type { QueryLocaleTextsInVersionQuery } from './query-locale-texts-in-version-query'

const buildConfig = createConfigBuilder('chalk')

export const i18nApi$queryLocaleTextsInVersion = {
  config(
    version: string,
    locale: LocaleTextLocaleEnum,
    queryLocaleTextsInVersionQuery?: QueryLocaleTextsInVersionQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/i18n/locale-texts/versions/{version}/locale/{locale}',
      {
        version,
        locale,
      },
      queryLocaleTextsInVersionQuery,
    )
  },

  /**
   * @summary 查询某学校指定版本、地域的语言文本集
   * @param version 指定版本。可传 latest ，获取最新版本
   * @param locale
   * @param [queryLocaleTextsInVersionQuery]
   * @param [i18nQuery.belongsTo]
   * @param [i18nQuery.belongsToIn]
   * @param [i18nQuery.schoolId]
   * @param [i18nQuery.fields]
   * @param [i18nQuery.modules.name]
   * @param [i18nQuery.modules.nameLike]
   * @param [i18nQuery.modules.nameIn]
   * @param [i18nQuery.modules.enabled]
   * @param [options]
   * @return AxiosResponsePromise<LocaleText[]>
   */

  api(
    version: string,
    locale: LocaleTextLocaleEnum,
    queryLocaleTextsInVersionQuery?: QueryLocaleTextsInVersionQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<LocaleText[]> {
    return axios(
      i18nApi$queryLocaleTextsInVersion.config(
        version,
        locale,
        queryLocaleTextsInVersionQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new LocaleText(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = LocaleText[]>(
    {
      version,
      locale,
      query,
    }: {
      version: string
      locale: LocaleTextLocaleEnum
      query?: QueryLocaleTextsInVersionQuery
    },
    queryOptions?: QueryOptionsWithSelect<LocaleText[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<LocaleText[], TSelected>(
      i18nApi$queryLocaleTextsInVersion.api,
      'I18n.queryLocaleTextsInVersion',
      queryOptions,
      version,
      locale,
      query,
    )
  },
}

// @ts-ignore
i18nApi$queryLocaleTextsInVersion.api._name_ = `I18n.queryLocaleTextsInVersion`

/**
 * @file 用于日程事件卡片的一些工具函数
 */

import { isDevelopment } from '@seiue/env'
import { omit } from '@seiue/util'

import { CardColor, CalendarEventType } from './types'

export interface BackgroundColorMap {
  // 文字颜色
  deepText: string
  // 条纹深色
  lightBackground: string
  // 条纹浅色
  lightBackgroundMask?: string
  // 左边框颜色，即标准色
  deepBackground: string
}

// 缓存课程的颜色，根据课程名区分
const lessonColorCache: string[] = []
// 缓存群组的颜色，根据课程名区分
const groupColorCache: string[] = []

// 日程事件颜色
const eventColors: CardColor[] = [
  {
    deepText: '#447AE6',
    deepBackground: '#477CFF',
    lightBackground: '#E0EDFF',
    lightBackgroundMask: '#D9E9FF',
  },
  {
    deepText: '#1D8E89',
    deepBackground: '#15B8B1',
    lightBackground: '#E1FBFA',
    lightBackgroundMask: '#D4F8F6',
  },
  {
    deepText: '#C47A00',
    deepBackground: '#E99D0A',
    lightBackground: '#FFF5E3',
    lightBackgroundMask: '#FFF0D5',
  },
  {
    deepText: '#B5295F',
    deepBackground: '#D45586',
    lightBackground: '#FFEBEF',
    lightBackgroundMask: '#FCE4E9',
  },
  {
    deepText: '#6A4BBE',
    deepBackground: '#8E6BF1',
    lightBackground: '#EEECFF',
    lightBackgroundMask: '#E5E2FF',
  },
  {
    deepText: '#3F5A9B',
    deepBackground: '#5975BA',
    lightBackground: '#DBE3F5',
    lightBackgroundMask: '#D5DEF6',
  },
  {
    deepText: '#3E8B57',
    deepBackground: '#60C381',
    lightBackground: '#E7F9ED',
    lightBackgroundMask: '#D9F9E4',
  },
  {
    deepText: '#77577D',
    deepBackground: '#8E6796',
    lightBackground: '#EBE3EC',
    lightBackgroundMask: '#E6DDE8',
  },
  {
    deepText: '#C05400',
    deepBackground: '#EA7B25',
    lightBackground: '#FFF1E3',
    lightBackgroundMask: '#FFEAD3',
  },
  {
    deepText: '#5C618F',
    deepBackground: '#757AA6',
    lightBackground: '#E4E6F2',
    lightBackgroundMask: '#DEE0F0',
  },
  {
    deepText: '#137DAD',
    deepBackground: '#17A0DE',
    lightBackground: '#DCF4FF',
    lightBackgroundMask: '#CAEEFF',
  },
  {
    deepText: '#526C6F',
    deepBackground: '#829B9E',
    lightBackground: '#DAE4E5',
    lightBackgroundMask: '#D1DFE1',
  },
  {
    deepText: '#9E37B4',
    deepBackground: '#C85BE0',
    lightBackground: '#F7E9FA',
    lightBackgroundMask: '#F3DFF7',
  },
  {
    deepText: '#317572',
    deepBackground: '#4A9995',
    lightBackground: '#CDE6E5',
    lightBackgroundMask: '#C3E0DF',
  },
]

/**
 * 课程或者群组的取色规则：在对应的颜色集中从头开始循环
 *
 * @param name - 群组名称
 * @param allColors - 颜色列表
 * @param colorCache - 颜色缓存列表
 * @returns 随机颜色盘
 */
export const genRandomColors: (
  name: string,
  allColors: CardColor[],
  colorCache: string[],
) => CardColor = (name, allColors, colorCache) => {
  let index = colorCache.findIndex(v => v === name)
  const colorLen = allColors.length
  if (index === -1) {
    colorCache.push(name)
    index = colorCache.length - 1
  }

  const colorIndex = index % colorLen
  return allColors[colorIndex] || {}
}

/**
 * 获取课节颜色盘
 *
 * @param name - 课节名称
 * @param useBackgroundMask - 是否使用背景遮罩, 默认为 false
 * @returns 颜色盘
 */
export const genLessonColors = (
  name: string,
  useBackgroundMask = false,
): CardColor => {
  const colors = genRandomColors(name, eventColors, lessonColorCache)
  if (!useBackgroundMask) {
    return omit(colors, ['lightBackgroundMask'])
  }

  return colors
}

const getChatColors = (isEmptyChat?: boolean) =>
  isEmptyChat
    ? {
        deepText: 'rgba(6, 12, 25, 0.45)',
        deepBackground: '#fff',
        lightBackground: '#fff',
        lightBackgroundMask: '#fff',
      }
    : {
        deepText: '#6A4BBE',
        deepBackground: '#8E6BF1',
        lightBackground: '#E5E2FF',
        lightBackgroundMask: '#EEECFF',
      }

/**
 * 获取事件背景色
 *
 * @param args - args
 * @param args.type - 事件类型
 * @param args.title - 事件标题
 * @param args.isEmptyChat - 是否为空的约谈事件
 * @param args.usePlainColor - 使用素色（黑/白）
 * @returns 事件背景色
 */
export const getBackgroundColor = ({
  type,
  title,
  isEmptyChat,
  usePlainColor,
}: {
  type: string
  title: string
  isEmptyChat?: boolean
  usePlainColor?: boolean
}): BackgroundColorMap => {
  if (usePlainColor) {
    return {
      deepText: 'rgba(6, 12, 25, 0.65)',
      deepBackground: '#D3D5DB',
      lightBackground: '#F3F4F8',
    }
  }

  switch (type) {
    case CalendarEventType.Talk:
      return {
        deepText: '#6A4BBE',
        deepBackground: '#8E6BF1',
        lightBackground: '#EEECFF',
        lightBackgroundMask: '#E5E2FF',
      }
    case CalendarEventType.Task:
      return {
        deepText: '#B5295F',
        deepBackground: '#D45586',
        lightBackground: '#FFEBEF',
        lightBackgroundMask: '#FCE4E9',
      }
    case CalendarEventType.Owner:
      return {
        deepText: '#C05400',
        deepBackground: '#E99D0A',
        lightBackground: '#FFF5E3',
        lightBackgroundMask: '#FFF0D5',
      }
    case CalendarEventType.LessonFold:
      return {
        deepText: '#325CD9',
        deepBackground: '#DEEDFE',
        lightBackground: '#DEEDFE',
        lightBackgroundMask: '#EAF3FF',
      }
    case CalendarEventType.Lesson:
    case CalendarEventType.LessonForWidgetPreview:
      return genLessonColors(title, false)
    case CalendarEventType.ClassReview:
      return genLessonColors(title, true)
    case CalendarEventType.CustomGroup:
      return genRandomColors(title, eventColors, groupColorCache)
    // 调课日程在 loading 时，需要空白的背景色，所以这里不返回颜色
    case CalendarEventType.ClassAdjustment:
    case CalendarEventType.ClassAdjustmentTeacherReplace:
    case CalendarEventType.ClassAdjustmentLessonFold:
    case CalendarEventType.ClassAdjustmentIdle:
    case CalendarEventType.LessonForClassReview:
      return {
        deepText: '',
        lightBackground: '',
        deepBackground: '',
      }
    case CalendarEventType.Free:
      return {
        deepText: '#3E8B57',
        deepBackground: '#60C381',
        lightBackground: '#E7F9ED',
        lightBackgroundMask: '',
      }
    case CalendarEventType.Venue:
      return {
        deepText: '#447AE6',
        deepBackground: '#477CFF',
        lightBackground: '#E0EDFF',
        lightBackgroundMask: '#D9E9FF',
      }
    case CalendarEventType.Event:
      return {
        deepText: '#9E37B4',
        deepBackground: '#C85BE0',
        lightBackground: '#F3DFF7',
        lightBackgroundMask: '#F7E9FA',
      }
    case CalendarEventType.Chat:
      return getChatColors(isEmptyChat)
    case CalendarEventType.Dorm:
      return {
        deepText: '#137DAD',
        deepBackground: '#17A0DE',
        lightBackground: '#CAEEFF',
        lightBackgroundMask: '#DCF4FF',
      }
    case CalendarEventType.Exam:
      return {
        deepText: '#C05400',
        deepBackground: '#EA7B25',
        lightBackground: '#FFE8D0',
        lightBackgroundMask: '#FDF2E5',
      }
    case CalendarEventType.Live:
      return {
        deepText: '#C05400',
        deepBackground: '#EA7B25',
        lightBackground: '#FFEAD3',
        lightBackgroundMask: '#FFF1E3',
      }
    default:
      if (isDevelopment()) {
        throw new Error('Undefined Event Type')
      } else {
        return {
          deepText: '',
          lightBackground: '',
          deepBackground: '',
        }
      }
  }
}

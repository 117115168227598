/**
 * @file 课程班消息注册
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.MemberType,
  getLabel: () => $t('课程'),
  labelStyle: calculateLabelStyle('#D9E9FF', '#516A9A'),
  messages: ['mentor', 'teacher', 'student', 'guardian', 'admin_class_teacher'],
})

registerDomainMessage({
  type: MessageDomainEnum.Class,
  getLabel: () => $t('课程'),
  labelStyle: calculateLabelStyle('#D9E9FF', '#516A9A'),
  messages: [
    'athena_course_released',
    'athena_course_released_for_member',
    'member_added',
    'member_removed',
    'member_switched',
  ],
})

/**
 * @file register certification messages
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Customized,
  getLabel: () => $t('成绩'),
  labelStyle: calculateLabelStyle('#F7E9FA', '#9E37B4'),
  messages: ['szhpsy_semester_report'],
})

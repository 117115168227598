export interface AttachmentInterface {
  /**
   * file name
   */
  name?: string
  /**
   * size in KB
   */
  size?: number
  /**
   * hash
   */
  hash: string
  /**
   * ext
   */
  ext?: string
}

export class Attachment implements AttachmentInterface {
  /**
   * file name
   */
  name?: string
  /**
   * size in KB
   */
  size?: number
  /**
   * hash
   */
  hash: string
  /**
   * ext
   */
  ext?: string

  constructor(props: AttachmentInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      size: _size,
      hash: _hash,
      ext: _ext,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.size = _size
    this.hash = _hash
    this.ext = _ext
  }

  static propKeys = ['name', 'size', 'hash', 'ext']
}

/**
 * @file 待办 Hook
 */
import { Pagination } from '@seiue/axios'
import { isArray } from '@seiue/util'
import React, { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { useCurrentReflection } from 'packages/features/sessions/utils/data'
import { usePendingTodos } from 'packages/features/todos/data'
import { getTodoDomain } from 'packages/features/todos/utils'
import {
  Api,
  QueryOptions,
  useLoadMore,
  useMutationApi,
  useQueryApi,
} from 'packages/sdks-next'
import {
  QueryExecutorTodosQuery,
  TodoStatusEnum,
  todoApi$queryExecutorTodos,
  todoApi$countExecutorTodos,
  todoApi$ignoreTodos,
  counterApi$listMyCounters,
} from 'packages/sdks-next/chalk'

import {
  TodosRematchDispatch,
  TodosRematchState,
  getReduxRootStore,
} from './store'
import { TodoType } from './types'

/**
 * 通过 query 加载，不过从 redux 获取数据
 *
 * @param query - 查询参数
 * @param queryOptions - 查询选项
 * @returns 待办数据
 */
export const useQueryExecutorTodos = (
  query: QueryExecutorTodosQuery,
  queryOptions?: QueryOptions,
) => {
  const { id: rid } = useCurrentReflection()

  const result = useQueryApi<TodoType[]>(
    (...args: any) =>
      getReduxRootStore().dispatch.todos.queryExecutorTodos({
        rid: args[0],
        query: args[1],
      }),
    // @ts-expect-error _name_ 是隐藏字段
    todoApi$queryExecutorTodos.api._name_,
    queryOptions,
    rid,
    {
      tryExpand: ['related'] as const,
      ...query,
    },
  )

  const currentTodos = useSelector(
    (_state: TodosRematchState) => _state.todos.currentTodos,
  )

  return {
    data: currentTodos,
    loading: result.loading,
    refetching: result.refetching,
    pagination: result.pagination,
    reload: result.reload,
  }
}

/**
 * 加载更多待办
 *
 * @param param0 - 参数
 * @param param0.query - 查询参数
 * @param param0.onlyPendingTodos - 是否只获取未加载待办
 * @param param0.queryOptions - 查询选项
 * @returns 待办数据
 */
export const useLoadMoreTodos = ({
  query,
  onlyPendingTodos,
  queryOptions,
}: {
  query?: QueryExecutorTodosQuery
  onlyPendingTodos?: boolean
  queryOptions?: QueryOptions
} = {}): {
  data: TodoType[] | null
  loading: boolean
  isLoadingMore: boolean
  hasMore: boolean
  totalCount: number
  loadMore: () => void
  pagination: Pagination | undefined
  // reload 会从第一页开始，perPage 条数据
  reload: () => void
} => {
  const { id: rid } = useCurrentReflection()

  const results = useLoadMore(
    {
      api: pageQuery =>
        getReduxRootStore().dispatch.todos.queryExecutorTodos({
          keepPrevData: true,
          rid,
          query: {
            ...query,
            ...pageQuery,
            paginated: 1,
            tryExpand: ['related'],
          },
        }),
      inheritApiId: todoApi$queryExecutorTodos.api,
      appendQueryKeys: [query],
    },
    queryOptions,
  )

  const pendingTodos = usePendingTodos()
  const allTodos = useSelector(
    (_state: TodosRematchState) => _state.todos.currentTodos ?? [],
  )

  let { totalCount } = results

  if (onlyPendingTodos) {
    /*
     * 如果只需要获取 pending 中的统计，那么，单独计算总数。因为 PendingTodos 受一些副作用影响，不直接来自接口
     * 即「未加载待办 + 当前显示待办」
     */
    totalCount = totalCount - (results.data?.length || 0) + pendingTodos.length
  }

  return {
    ...results,
    totalCount,
    data: onlyPendingTodos ? pendingTodos : allTodos,
  }
}

const predicate = ({ queryKey }: any) =>
  // @ts-expect-error _name_ 是隐藏字段
  queryKey[0] === todoApi$queryExecutorTodos.api._name_

const useInvalidateCountQueries = () => {
  const queryClient = useQueryClient()

  return useCallback(async () => {
    await queryClient.invalidateQueries({
      predicate: ({ queryKey }) =>
        queryKey[0] === (todoApi$countExecutorTodos.api as any)._name_,
    })
  }, [queryClient])
}

const reloadingSign: { [key: string]: boolean } = {}

/**
 * 重加载待办
 *
 * @returns Fn
 */
export const useReload = () => {
  const dispatch: TodosRematchDispatch = useDispatch()
  const currentTodo = useSelector(
    (_state: TodosRematchState) => _state.todos.currentTodo,
  )

  const currentTodos = useSelector(
    (_state: TodosRematchState) => _state.todos.currentTodos,
  )

  const queryClient = useQueryClient()
  const invalidateCountQueries = useInvalidateCountQueries()
  const { id: rid } = useCurrentReflection()

  return React.useCallback(
    async (
      relatedQuery?: { id: number } | { type: string; bizId?: string },
    ) => {
      const reloadQuery = currentTodo || relatedQuery

      invalidateCountQueries()

      const refreshAll = async () => {
        if (reloadingSign['all']) return

        try {
          reloadingSign['all'] = true

          await queryClient.invalidateQueries({
            predicate,
          })
        } finally {
          reloadingSign['all'] = false
        }
      }

      // 如果当前没有可关联的资源，那么直接刷新整个列表
      if (!reloadQuery) {
        await refreshAll()

        return
      }

      // 首先清空 currentTodo
      dispatch.todos.setCurrentTodo()

      // 如果缓存了 _todo，则找到 _todo 相关的其他待办，进行刷新
      const relatedTodoIds = currentTodos
        .filter(_t => {
          if (_t.id === (reloadQuery as { id: number }).id) {
            return true
          }

          const _reloadQuery = reloadQuery as { type: string; bizId?: string }

          const _tDomain = getTodoDomain(_t)
          const queryDomain = getTodoDomain(_reloadQuery)

          if (_tDomain && queryDomain && _tDomain === queryDomain) {
            if (_reloadQuery.bizId) {
              return _t.bizId === _reloadQuery.bizId
            }

            return true
          }

          return false
        })
        .map(({ id }) => id)
        .sort((a, b) => a - b) // 做一次 id 排序，保证 query 的一致性

      // 如果啥也没找到，那么直接刷新整个列表
      if (!relatedTodoIds.length) {
        await refreshAll()

        return
      }

      const idIn = relatedTodoIds.join(',')

      if (reloadingSign[idIn]) return

      try {
        reloadingSign[idIn] = true

        // 使用 todoApi，这里的请求不触发副作用
        const { data } = await todoApi$queryExecutorTodos.api(rid, {
          idIn: relatedTodoIds.join(','),
          paginated: 0,
          tryExpand: ['related'] as const,
        })

        dispatch.todos.updateTodos(data)
      } finally {
        reloadingSign[idIn] = false
      }
    },
    [
      currentTodo,
      invalidateCountQueries,
      dispatch.todos,
      currentTodos,
      queryClient,
      rid,
    ],
  )
}

/**
 * 手动配置某个待办为已处理
 *
 * @returns Fn
 */
export const useMarkTodoAsDone = () => {
  const dispatch: TodosRematchDispatch = useDispatch()

  const currentTodos = useSelector(
    (_state: TodosRematchState) => _state.todos.currentTodos,
  )

  return React.useCallback(
    async (relatedQuery: { id: number } | { type: string; bizId?: string }) => {
      const reloadQuery = relatedQuery

      // 如果缓存了 _todo，则找到 _todo 相关的其他待办，进行刷新
      const relatedTodoIds = currentTodos
        .filter(_t => {
          if (_t.id === (reloadQuery as { id: number }).id) {
            return true
          }

          const _reloadQuery = reloadQuery as { type: string; bizId?: string }

          const _tDomain = getTodoDomain(_t)
          const queryDomain = getTodoDomain(_reloadQuery)

          if (_tDomain && queryDomain && _tDomain === queryDomain) {
            if (_reloadQuery.bizId) {
              return _t.bizId === _reloadQuery.bizId
            }

            return true
          }

          return false
        })
        .map(({ id }) => id)
        .sort((a, b) => a - b) // 做一次 id 排序，保证 query 的一致性

      relatedTodoIds.forEach(id => dispatch.todos.markTodoAsDone(id))
    },
    [currentTodos, dispatch],
  )
}

/**
 * 更新待办对应的业务数据
 *
 * @param param - 参数
 * @param param.todo - 待办
 * @param param.api - 更新接口
 * @param param.onSuccess - 成功回调
 * @param param.onSuccess.hide - 更新成功后是否隐藏待办
 * @param param.onSuccess.reload - 更新成功后是否重新加载待办
 * @param param.onSuccess.update - 更新成功后是否更新待办数据
 * 但在现实情况下，少数待办的更新较业务更新有较大的延迟，所以需要主动更新来纠正这个延迟时间段内待办的状态。
 *
 * @returns Fn
 */
export const useTodoUpdater = <T extends Api>({
  todo,
  api,
  onSuccess,
}: {
  todo: TodoType
  api: T
  onSuccess?: {
    hide?: boolean
    reload?: boolean
    update?: (result: any) => Partial<TodoType>
  }
}) => {
  const dispatch: TodosRematchDispatch = useDispatch()
  const reload = useReload()
  const hideOnSuccess = onSuccess?.hide || false
  const reloadOnSuccess = onSuccess?.reload || true
  const updateOnSuccess = onSuccess?.update

  const invalidateCountQueries = useInvalidateCountQueries()

  const {
    api: mutationApi,
    loading,
    success,
  } = useMutationApi((payload: any) => {
    const _payload = isArray(payload) ? payload : [payload]
    return api(..._payload)
  })

  React.useEffect(() => {
    if (!success) return

    if (hideOnSuccess) {
      dispatch.todos.markTodoAsDone(todo.id)
    }
  }, [success, dispatch, todo.id, hideOnSuccess])

  return [
    React.useCallback(
      async (...props: Parameters<T>) => {
        const result = await mutationApi(props)

        if (reloadOnSuccess) {
          await reload({
            id: todo.id,
          })
        }

        if (updateOnSuccess) {
          dispatch.todos.updateTodos([
            updateOnSuccess({
              ...result,
              id: todo.id,
            }),
          ])
        }

        invalidateCountQueries()

        return result
      },
      [
        mutationApi,
        reloadOnSuccess,
        updateOnSuccess,
        invalidateCountQueries,
        reload,
        todo.id,
        dispatch.todos,
      ],
    ),
    loading,
  ] as const
}

/**
 * 批量忽略待办
 *
 * @returns Fn
 */
export const useBatchIgnoreTodos = () => {
  const { api: ignoreTodos } = todoApi$ignoreTodos.useApi({
    reload: [
      todoApi$queryExecutorTodos.api,
      counterApi$listMyCounters.api,
      todoApi$countExecutorTodos.api,
    ],
  })

  const { id: rid } = useCurrentReflection()

  return async (
    query: QueryExecutorTodosQuery,
    beforeConfirm?: () => Promise<boolean>,
  ) => {
    if (beforeConfirm) {
      const result = await beforeConfirm()

      if (!result) return
    }

    const { data } = await todoApi$queryExecutorTodos.api(rid, {
      ...query,
      status: TodoStatusEnum.Pending,
      fields: ['id'],
    })

    await ignoreTodos({
      ids: data.map(({ id }) => id),
      ignored: true,
    })
  }
}

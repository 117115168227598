/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  SemesterCreation,
  SemesterCreationInterface,
} from './semester-creation'
import { Semester, SemesterInterface } from './semester'
import type { CreateSemesterQuery } from './create-semester-query'

const buildConfig = createConfigBuilder('chalk')

export const semesterApi$createSemester = {
  config(
    semesterCreation: SemesterCreationInterface,
    createSemesterQuery?: CreateSemesterQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'post',
      '/system/semesters',
      {},
      createSemesterQuery,
      semesterCreation,
      {
        format: 'json',
        isArray: false,
        defaults: {
          gradeMaps: (v: any) => v || null,
        },
      },
    )
  },

  /**
   * @summary Class CreateSemesterApi
   * @param semesterCreation
   * @param [createSemesterQuery]
   * @param [semesterQuery.expand] 支持 expand:
   *
   * 1 . academic_year 学年
   * 2 . category 分类
   * 3 . is_current 是否当前学期
   * @param [options]
   * @return AxiosResponsePromise<Semester>
   */

  api<const TQuery extends CreateSemesterQuery>(
    semesterCreation: SemesterCreationInterface,
    createSemesterQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Semester, TQuery['expand']>> {
    return axios(
      semesterApi$createSemester.config(
        semesterCreation,
        createSemesterQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new Semester(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({
        payload,
        query,
      }: {
        payload: SemesterCreationInterface
        query?: CreateSemesterQuery
      }) => semesterApi$createSemester.api(payload, query),
      mutationOptions,
    )
  },
}

// @ts-ignore
semesterApi$createSemester.api._name_ = `Semester.createSemester`

import { uniq, uniqBy } from '@seiue/util'
import React, { useMemo } from 'react'

import { useHasAnyManageablePlugin } from 'packages/feature-utils/plugins'
import { useHasCurrentPermissionFunction } from 'packages/features/roles'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'
import {
  AdminClass,
  AdminClassAccessPolicyEnum,
  AdminClassStatusEnum,
  adminClassApi$queryAdminClasses,
} from 'packages/sdks-next/scms'

/**
 * 根据当前用户的身份获取行政班接口的权限
 *
 * @returns 权限标识
 */
export const useAdminClassAccessPolicy = () => {
  const hasCurrentPermission = useHasCurrentPermissionFunction()
  const hasAnyManageablePlugins = useHasAnyManageablePlugin()

  return React.useMemo(() => {
    // 有任意插件管理权限
    if (hasAnyManageablePlugins) {
      return AdminClassAccessPolicyEnum.PluginAdmin
    }

    // 有行政班管理或用户管理权限
    if (
      hasCurrentPermission({
        permission: PermissionNameEnum.CoreAdminClassRead,
        isManager: true,
      }) ||
      hasCurrentPermission({
        permission: PermissionNameEnum.CoreUserRead,
        isManager: true,
      })
    ) {
      return AdminClassAccessPolicyEnum.Default
    }

    // 有课程班管理权限
    if (
      hasCurrentPermission({
        permission: PermissionNameEnum.CoreClassRead,
        isManager: true,
      })
    ) {
      return AdminClassAccessPolicyEnum.ClassAdmin
    }

    // 兜底使用教师身份权限
    return AdminClassAccessPolicyEnum.Teacher
  }, [hasAnyManageablePlugins, hasCurrentPermission])
}

/**
 * 获取行政班选项
 *
 * @param args - 参数
 * @param args.status - 行政班状态，默认为 Normal
 * @param args.valueKey - 选项 value 对应的字段名，默认是 id
 * @param args.policy - 查询行政班使用的权限
 * @param args.disable - 是否禁用请求
 * @param args.instanceId - 实例 id
 * @returns 选项
 */
export const useAdminClassOptions = ({
  status = AdminClassStatusEnum.Normal,
  valueKey = 'id',
  policy: propPolicy,
  disable,
  instanceId,
}: {
  status?: AdminClassStatusEnum
  valueKey?: keyof AdminClass
  policy?: AdminClassAccessPolicyEnum
  disable?: boolean
  instanceId?: number
} = {}) => {
  const policy = useAdminClassAccessPolicy()

  const { data, loading } = adminClassApi$queryAdminClasses.useApi(
    {
      status,
      paginated: 0,
      fields: uniq([
        'id',
        'name',
        'grade',
        'school_id',
        'graduates_in_id',
        valueKey,
      ]).join(','),
      instanceId,
      policy: propPolicy || policy,
      tryExpand: ['grade'],
    },
    { disable, staleTime: 5 * 60 },
  )

  const options = useMemo(() => {
    return (
      uniqBy(
        data?.map(d => ({
          text: d.fullName,
          grade: d.grade?.name,
          name: d.name,
          value:
            valueKey === 'name'
              ? // 按行政班名称筛选的格式：{班级名称}+{年级 id}
                d.grade
                ? `${d.name}+${d.grade.id}`
                : d.name
              : String(d[valueKey]),
        })),
        'value',
      ) || []
    )
  }, [data, valueKey])

  return [options, loading] as const
}

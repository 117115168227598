/**
 * @file AI 教师相关
 */

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator('ai_teacher.unresolved_reminder', ({ attributes }) => {
  const { botId, groupId, conversationId, userId, reminderId } = attributes as {
    botId?: number
    conversationId?: number
    groupId?: number
    messageId?: string
    userId?: number
    reminderId?: number
  }

  return {
    modalName: 'Plugin.AITeacher.ConversationRecord',
    options: {
      id: botId,
      groupId,
      conversationId,
      userId: userId ? `${userId}` : undefined,
      reminderId,
    },
  }
})

registerMessageNavigator('ai_teacher.reply_reminder', ({ attributes }) => {
  const { botId, groupId, conversationId, userId } = attributes as {
    botId?: number
    conversationId?: number
    groupId?: number
    messageId?: string
    userId?: number
  }

  return {
    modalName: 'Plugin.AITeacher.Conversation',
    options: {
      id: botId,
      groupId,
      conversationId,
      userId: userId ? `${userId}` : undefined,
    },
  }
})

registerMessageNavigator('ai_teacher.question_reminder', ({ attributes }) => {
  const { botId, groupId, conversationId, userId } = attributes as {
    botId?: number
    conversationId?: number
    groupId?: number
    messageId?: string
    userId?: number
  }

  return {
    modalName: 'Plugin.AITeacher.ConversationRecord',
    options: {
      id: botId,
      groupId,
      conversationId,
      userId: userId ? `${userId}` : undefined,
    },
  }
})

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'

const buildConfig = createConfigBuilder('vnas')

export const moralApi$queryWriteableItemIds = {
  config(id: number, options?: AxiosRequestExtraConfig): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/moral/assessments/{id}/writeable-item-ids',
      {
        id,
      },
    )
  },

  /**
   * @summary 通用德育评价（德育/教师考核）
   * 查询评价人或者被评价人可录入的评价项
   * @param id
   * @param [options]
   * @return AxiosResponsePromise<number[]>
   */

  api(
    id: number,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<number[]> {
    return axios(moralApi$queryWriteableItemIds.config(id, options)) as any
  },

  useApi<TSelected = number[]>(
    id: number,
    queryOptions?: QueryOptionsWithSelect<number[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<number[], TSelected>(
      moralApi$queryWriteableItemIds.api,
      'Moral.queryWriteableItemIds',
      queryOptions,
      id,
    )
  },
}

// @ts-ignore
moralApi$queryWriteableItemIds.api._name_ = `Moral.queryWriteableItemIds`

import {
  PricingPlanConstraints,
  PricingPlanConstraintsInterface,
} from './pricing-plan-constraints'

export interface PricingPlanInterface {
  name: string
  /**
   * 描述
   */
  description: string
  /**
   * 服务标准
   */
  serviceStandard: string
  /**
   * 学校数量
   */
  schoolNum?: number
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  /**
   * 系统模块（即 source=builtin 的 plugin.name）
   */
  builtinPlugins?: string[]
  /**
   * 商店插件（即 source=system 的 plugin.name）
   */
  systemPlugins?: string[]
  /**
   * 限制
   */
  constraints: PricingPlanConstraintsInterface
}

export class PricingPlan implements PricingPlanInterface {
  name: string
  /**
   * 描述
   */
  description: string
  /**
   * 服务标准
   */
  serviceStandard: string
  /**
   * 学校数量
   */
  schoolNum?: number
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  /**
   * 系统模块（即 source=builtin 的 plugin.name）
   */
  builtinPlugins?: string[]
  /**
   * 商店插件（即 source=system 的 plugin.name）
   */
  systemPlugins?: string[]
  /**
   * 限制
   */
  constraints: PricingPlanConstraints

  constructor(props: PricingPlanInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      description: _description,
      serviceStandard: _serviceStandard,
      schoolNum: _schoolNum,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      builtinPlugins: _builtinPlugins,
      systemPlugins: _systemPlugins,
      constraints: _constraints,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.description = _description
    this.serviceStandard = _serviceStandard
    this.schoolNum = _schoolNum
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
    this.builtinPlugins = _builtinPlugins
    this.systemPlugins = _systemPlugins
    this.constraints = _constraints
      ? new PricingPlanConstraints(_constraints)
      : _constraints
  }

  static propKeys = [
    'name',
    'description',
    'serviceStandard',
    'schoolNum',
    'id',
    'createdAt',
    'updatedAt',
    'builtinPlugins',
    'systemPlugins',
    'constraints',
  ]
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { AchievedScore, AchievedScoreInterface } from './extends/achieved-score'
import type { PersonalAchievedScoresQuery } from './personal-achieved-scores-query'

const buildConfig = createConfigBuilder('vnas')

export const commonApi$personalAchievedScores = {
  config(
    assessmentId: number,
    ownerId: number,
    personalAchievedScoresQuery?: PersonalAchievedScoresQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/personal/assessments/{assessmentId}/achieved-scores',
      {
        assessmentId,
        ownerId,
      },
      personalAchievedScoresQuery,
    )
  },

  /**
   * @summary Class PersonalAchievedScoresApi
   * @param assessmentId
   * @param ownerId 学生ID
   * @param [personalAchievedScoresQuery]
   * @param [commonQuery.itemId]
   * @param [commonQuery.itemIdIn]
   * @param [commonQuery.type]
   * @param [commonQuery.typeIn]
   * @param [commonQuery.withTrashed]
   * @param [options]
   * @return AxiosResponsePromise<AchievedScore[]>
   */

  api(
    assessmentId: number,
    ownerId: number,
    personalAchievedScoresQuery?: PersonalAchievedScoresQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<AchievedScore[]> {
    return axios(
      commonApi$personalAchievedScores.config(
        assessmentId,
        ownerId,
        personalAchievedScoresQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new AchievedScore(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = AchievedScore[]>(
    {
      assessmentId,
      ownerId,
      query,
    }: {
      assessmentId: number
      ownerId: number
      query?: PersonalAchievedScoresQuery
    },
    queryOptions?: QueryOptionsWithSelect<AchievedScore[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<AchievedScore[], TSelected>(
      commonApi$personalAchievedScores.api,
      'Common.personalAchievedScores',
      queryOptions,
      assessmentId,
      ownerId,
      query,
    )
  },
}

// @ts-ignore
commonApi$personalAchievedScores.api._name_ = `Common.personalAchievedScores`

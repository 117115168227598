import { ExamSubject as Base } from '../exam-subject'

export type { ExamSubjectInterface } from '../exam-subject'

/**
 * 考试科目
 */
export class ExamSubject extends Base {
  /**
   * 判断指定学生是否在科目范围中
   *
   * @param rid - 指定学生
   * @returns 是否
   */
  isStudentInRelations(rid: number) {
    return !!this.subjectRelations?.some(relation => relation.rid === rid)
  }
}

import {
  CalendarEvent as CalendarEventBase,
  CalendarEventInterface as CalendarEventInterfaceOriginal,
} from '../calendar-event'

export interface CalendarEventInterface extends CalendarEventInterfaceOriginal {
  /**
   * 所属业务的数据，以课程班日程为例，subject 对应 class 的信息
   */
  subject?: any
  /**
   * 该 Event 对应的业务数据。以课程班日程为例，custom 对应 lesson 的信息
   */
  custom?: { [key: string]: any } & { id: number }
}

/**
 *
 */
export class CalendarEvent extends CalendarEventBase {
  /**
   * 所属业务的数据，以课程班日程为例，subject 对应 class 的信息
   */
  subject?: any

  /**
   * 该 Event 对应的业务数据。以课程班日程为例，custom 对应 lesson 的信息
   */
  custom?: { [key: string]: any } & { id: number }

  /**
   * constructor
   *
   * @param props - props
   */
  constructor(props: CalendarEventInterface) {
    super(props)
    this.subject = props.subject
    this.custom = props.custom
  }
}

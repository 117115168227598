import { AssessmentTypeEnum } from './assessment-type-enum'
import { AssessmentScopeTypeEnum } from './assessment-scope-type-enum'
import { AssessmentStatusEnum } from './assessment-status-enum'

export interface ScoreAssessmentInterface {
  /**
   * 学期 id ，当 type=class（成绩评价）时必有
   */
  semesterId?: number | null
  type: AssessmentTypeEnum
  name: string
  /**
   * 当为成绩评价时，scope_id 为 class_id ，必有
   */
  scopeId?: number | null
  scopeType: AssessmentScopeTypeEnum
  status: AssessmentStatusEnum
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
}

export class ScoreAssessment implements ScoreAssessmentInterface {
  /**
   * 学期 id ，当 type=class（成绩评价）时必有
   */
  semesterId?: number | null
  type: AssessmentTypeEnum
  name: string
  /**
   * 当为成绩评价时，scope_id 为 class_id ，必有
   */
  scopeId?: number | null
  scopeType: AssessmentScopeTypeEnum
  status: AssessmentStatusEnum
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: ScoreAssessmentInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      semesterId: _semesterId,
      type: _type,
      name: _name,
      scopeId: _scopeId,
      scopeType: _scopeType,
      status: _status,
      id: _id,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.semesterId = _semesterId
    this.type = _type
    this.name = _name
    this.scopeId = _scopeId
    this.scopeType = _scopeType
    this.status = _status
    this.id = _id
    this.schoolId = _schoolId
  }

  static propKeys = [
    'semesterId',
    'type',
    'name',
    'scopeId',
    'scopeType',
    'status',
    'id',
    'schoolId',
  ]
}

import { RadarChart, WidgetType } from 'packages/sdks-next/nuwa'

import {
  DataTableExtra,
  PivotTableExtra,
  WidgetChartOption,
  WithoutChartType,
} from '../index'

import { BarChartExtra, StackedBarChartExtra } from './bar'
import { ColumnChartExtra, StackedColumnChartExtra } from './column'
import { LineChartExtra } from './line'
import { PieChartExtra } from './pie'

export * from './utils'
export * from './option'
export * from './bar'
export * from './column'
export * from './line'
export * from './pie'

export type RadarChartExtra = WithoutChartType<
  RadarChart,
  WidgetType.RadarChart
>

/**
 * echart 类型的图表配置
 */
export type EchartWidgetExtra =
  | BarChartExtra
  | StackedBarChartExtra
  | PieChartExtra
  | LineChartExtra
  | ColumnChartExtra
  | StackedColumnChartExtra
  | RadarChartExtra

export type WidgetTableExtra = (DataTableExtra | PivotTableExtra) & {
  chartOption?: WidgetChartOption
}

/**
 * 柱状类型的图表配置
 */
export type BarChartUnion =
  | ColumnChartExtra
  | StackedColumnChartExtra
  | BarChartExtra
  | StackedBarChartExtra

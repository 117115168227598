const _cachedAt: { [key: string]: { [key: string]: number } } = {}

export const setCachedAt = (mainKey: string, subKey: string | number) => {
  _cachedAt[mainKey] = _cachedAt[mainKey] || {}
  _cachedAt[mainKey][subKey] = Date.now()
}

export const getCachedAt = (
  mainKey: string,
  subKey: string | number,
): number | null => _cachedAt[mainKey]?.[subKey] ?? null

export interface AssessmentRelatedDataInterface {
  scopeType?: string
  dormIds?: number[] | null
  /**
   * 教师考核管理员 rids
   */
  adminIds?: number[] | null
  /**
   * 班级排名人数
   */
  rankBase?: number | null
  /**
   * 课程排名人数
   */
  courseRankBase?: number | null
}

export class AssessmentRelatedData implements AssessmentRelatedDataInterface {
  scopeType?: string
  dormIds?: number[] | null
  /**
   * 教师考核管理员 rids
   */
  adminIds?: number[] | null
  /**
   * 班级排名人数
   */
  rankBase?: number | null
  /**
   * 课程排名人数
   */
  courseRankBase?: number | null

  constructor(props: AssessmentRelatedDataInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      scopeType: _scopeType,
      dormIds: _dormIds,
      adminIds: _adminIds,
      rankBase: _rankBase,
      courseRankBase: _courseRankBase,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.scopeType = _scopeType
    this.dormIds = _dormIds
    this.adminIds = _adminIds
    this.rankBase = _rankBase
    this.courseRankBase = _courseRankBase
  }

  static propKeys = [
    'scopeType',
    'dormIds',
    'adminIds',
    'rankBase',
    'courseRankBase',
  ]
}

/**
 * @file 注册心理档案消息
 */

import { PluginEnum } from 'packages/feature-utils/plugins'
import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

import { NoticeRoles } from './message-type'

registerDomainMessage({
  type: MessageDomainEnum.Psychological,
  icon: `plugin.${PluginEnum.PsychologicalFile}`,
  getLabel: () => $t('心理'),
  labelStyle: calculateLabelStyle('#E1FBFA', '#1D8E89'),
  messages: [
    'psychological.need_assess',
    'psychological.add_collaborator',
    'psychological.less_collaborator',
    'psychological.intervention_timeout',
    // 学生问题上报/抄送
    'psychological.notify_report',
  ],
})

registerDomainMessage({
  type: MessageDomainEnum.Psychological,
  icon: `plugin.${PluginEnum.PsychologicalFile}`,
  getLabel: () => $t('测评'),
  labelStyle: calculateLabelStyle('#E1FBFA', '#1D8E89'),
  messages: [
    NoticeRoles.AdminClassTeacher,
    NoticeRoles.Mentor,
    NoticeRoles.Student,
    NoticeRoles.StudentResult,
    NoticeRoles.GuardianReceivedResult,
  ],
})

registerDomainMessage({
  type: MessageDomainEnum.PsyChat,
  icon: `plugin.${PluginEnum.PsychologicalFile}`,
  getLabel: () => $t('咨询'),
  labelStyle: calculateLabelStyle('#E1FBFA', '#1D8E89'),
  messages: [
    'psy_chat.apply_chat',
    'psy_chat.chat_created',
    'psy_chat.cancel_chat_by_guardian',
    'psy_chat.chat_canceled',
    'psy_chat.chat_changed',
  ],
})

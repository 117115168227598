import { PupilWithUser as Base } from '../pupil-with-user'

import {
  getReflectionAdminClassesLabels,
  getReflectionGenderLabel,
  getReflectionGraduatesInAndGradeLabel,
} from './secured-reflection'

export type { PupilWithUserInterface } from '../pupil-with-user'

/**
 * 带用户的家长孩子
 */
export class PupilWithUser extends Base {
  /**
   * 带年级的行政班列表
   *
   * @returns 行政班列表
   */
  get adminClassesLabels() {
    return getReflectionAdminClassesLabels(this)
  }

  /**
   * 带年级的届别
   *
   * @returns 届别
   */
  get graduatesInAndGradeLabel() {
    return getReflectionGraduatesInAndGradeLabel(this)
  }

  /**
   * 性别 label
   *
   * @returns 性别 label
   */
  get genderLabel() {
    return getReflectionGenderLabel(this)
  }
}

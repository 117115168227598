import { aiTeacherApi$queryGroupTeacherBots } from 'packages/sdks-next/ais'

import {
  PluginEnum,
  useHasEnabledParentPluginFunction,
  useHasUsablePlugin,
} from '../plugins'

import { aiTeacherSession } from './session'

/**
 * 查看当前用户是否具有 AI 教师查看权限
 *
 * @returns boolean
 */
export const useHasAITeacherVisiblePermission = () => {
  const hasPluginEnabled = useHasEnabledParentPluginFunction()
  const enabled = hasPluginEnabled(PluginEnum.AITeacher)

  const hasUsable = useHasUsablePlugin(PluginEnum.AITeacher)

  return enabled && hasUsable
}

/**
 * 是否显示 AI 教师卡片
 *
 *
 * @param param - 参数
 * @param param.groupId - 群组 ID
 * @param param.inHome - 是否在首页
 * @param param.isStudentMember - 是否为学生成员
 * @param param.isKlassTeacher - 是否为老师
 * @returns obj
 */
export const useHasAiTeacherCardVisible = ({
  groupId,
  inHome,
  isStudentMember,
  isKlassTeacher,
}: {
  groupId: number
  inHome?: boolean
  isStudentMember?: boolean
  isKlassTeacher?: boolean
}) => {
  const hasPluginEnabled = useHasEnabledParentPluginFunction()
  const enabled = hasPluginEnabled(PluginEnum.AITeacher)

  const hasUsable = useHasUsablePlugin(PluginEnum.AITeacher)

  const token = aiTeacherSession.getToken()

  const { data: groupBots, loading } =
    aiTeacherApi$queryGroupTeacherBots.useApi(groupId, {
      disable: !enabled || !isStudentMember || !token,
    })

  const entryVisible = !!(
    enabled &&
    inHome &&
    token &&
    ((isStudentMember && !!groupBots?.length) || (isKlassTeacher && hasUsable))
  )

  return {
    visible: entryVisible,
    loading,
  }
}

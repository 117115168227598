import { TextStyleType } from 'packages/components/ReportCenter/Reporting/components/TextStyleBar/types'

export enum AxisKey {
  X = 'xAxis',
  Y = 'yAxis',
}

export type AxisTitleConfigType = {
  /**
   * 坐标轴标题
   */
  text?: string
  /**
   * 坐标轴标题样式
   */
  textStyle?: TextStyleType
}

export type ValueAxisType = {
  /**
   * 坐标轴刻度最小值
   */
  min?: number
  /**
   * 坐标轴刻度最大值
   */
  max?: number
  /**
   * 坐标轴间隔大小
   */
  interval?: number
  /**
   * 坐标轴的分割段数
   */
  splitNumber?: number
  /**
   * 轴标题
   */
  title?: AxisTitleConfigType
  /**
   * 对数刻度
   */
  logBase?: number
}

export enum AxisLabelRotateEnum {
  // 垂直向上
  VerticalUp = 90,
  // 垂直向下
  VerticalDown = -90,
  // 右上
  RightUp = 45,
  // 右下
  RightDown = -45,
  // 横排
  Horizontal = 0,
}

export type CategoryAxisType = {
  /**
   * 轴标题
   */
  title?: AxisTitleConfigType

  /**
   * 坐标轴刻度标签配置
   */
  axisLabel?: {
    /**
     * 标签旋转角度
     */
    rotate?: AxisLabelRotateEnum
  }
}

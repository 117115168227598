import { RoleEnum } from './role-enum'
import { GenderEnum } from './gender-enum'
import { StatusEnum } from './status-enum'
import { Term, TermInterface } from './extends/term'

export interface ReflectionInterface {
  schoolId: number
  userId?: number | null
  name: string
  pinyin: string
  account: string
  role: RoleEnum
  ename?: string | null
  email?: string | null
  phone?: string | null
  gender?: GenderEnum | null
  idcard?: string | null
  photo?: string | null
  status?: StatusEnum
  disabled?: boolean
  archivedTypeId?: number | null
  archivedType?: TermInterface | null
  /**
   * 学科 term id
   */
  disciplineId?: number | null
  discipline?: TermInterface | null
  /**
   * 学号/工号
   */
  usin?: string | null
  /**
   * 行政班
   */
  adminClasses?: string[] | null
  /**
   * 宿舍
   */
  dorm?: string | null
  /**
   * 宿舍管理员
   */
  dormAdmins?: string[] | null
  /**
   * 是否是默认身份 1 是默认 0 是正常
   */
  isDefault?: number | null
  /**
   * 床位号
   */
  berth?: string | null
  /**
   * 仅家长有
   */
  pupilId?: number | null
  /**
   * 是否需要修改密码
   */
  passwordChangeNeeded: boolean
  /**
   * 外部人员的关联ID
   */
  outerId?: string | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Reflection implements ReflectionInterface {
  schoolId: number
  userId?: number | null
  name: string
  pinyin: string
  account: string
  role: RoleEnum
  ename?: string | null
  email?: string | null
  phone?: string | null
  gender?: GenderEnum | null
  idcard?: string | null
  photo?: string | null
  status?: StatusEnum
  disabled?: boolean
  archivedTypeId?: number | null
  archivedType?: Term | null
  /**
   * 学科 term id
   */
  disciplineId?: number | null
  discipline?: Term | null
  /**
   * 学号/工号
   */
  usin?: string | null
  /**
   * 行政班
   */
  adminClasses?: string[] | null
  /**
   * 宿舍
   */
  dorm?: string | null
  /**
   * 宿舍管理员
   */
  dormAdmins?: string[] | null
  /**
   * 是否是默认身份 1 是默认 0 是正常
   */
  isDefault?: number | null
  /**
   * 床位号
   */
  berth?: string | null
  /**
   * 仅家长有
   */
  pupilId?: number | null
  /**
   * 是否需要修改密码
   */
  passwordChangeNeeded: boolean
  /**
   * 外部人员的关联ID
   */
  outerId?: string | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: ReflectionInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      userId: _userId,
      name: _name,
      pinyin: _pinyin,
      account: _account,
      role: _role,
      ename: _ename,
      email: _email,
      phone: _phone,
      gender: _gender,
      idcard: _idcard,
      photo: _photo,
      status: _status,
      disabled: _disabled,
      archivedTypeId: _archivedTypeId,
      archivedType: _archivedType,
      disciplineId: _disciplineId,
      discipline: _discipline,
      usin: _usin,
      adminClasses: _adminClasses,
      dorm: _dorm,
      dormAdmins: _dormAdmins,
      isDefault: _isDefault,
      berth: _berth,
      pupilId: _pupilId,
      passwordChangeNeeded: _passwordChangeNeeded,
      outerId: _outerId,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.userId = _userId
    this.name = _name
    this.pinyin = _pinyin
    this.account = _account
    this.role = _role
    this.ename = _ename
    this.email = _email
    this.phone = _phone
    this.gender = _gender
    this.idcard = _idcard
    this.photo = _photo
    this.status = _status
    this.disabled = _disabled
    this.archivedTypeId = _archivedTypeId
    this.archivedType = _archivedType ? new Term(_archivedType) : _archivedType
    this.disciplineId = _disciplineId
    this.discipline = _discipline ? new Term(_discipline) : _discipline
    this.usin = _usin
    this.adminClasses = _adminClasses
    this.dorm = _dorm
    this.dormAdmins = _dormAdmins
    this.isDefault = _isDefault
    this.berth = _berth
    this.pupilId = _pupilId
    this.passwordChangeNeeded = _passwordChangeNeeded
    this.outerId = _outerId
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'schoolId',
    'userId',
    'name',
    'pinyin',
    'account',
    'role',
    'ename',
    'email',
    'phone',
    'gender',
    'idcard',
    'photo',
    'status',
    'disabled',
    'archivedTypeId',
    'archivedType',
    'disciplineId',
    'discipline',
    'usin',
    'adminClasses',
    'dorm',
    'dormAdmins',
    'isDefault',
    'berth',
    'pupilId',
    'passwordChangeNeeded',
    'outerId',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

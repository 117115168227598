/**
 * @file 桌面端路由机制
 */

import { isProduction } from '@seiue/env'
import { stringifyURLQuery } from '@seiue/util'
import { LocationState } from 'history'
import { lazy } from 'react'
import {
  useHistory as useHistoryOriginal,
  useParams as useParamsRaw,
  useLocation as useLocationOriginal,
  useRouteMatch as useRouteMatchOriginal,
} from 'react-router-dom'

import { $t } from 'packages/locale'

import {
  RouteConfig,
  RouteNode,
  ModalRouteNode,
  RouteParamsList,
  RouteParams,
} from './types'
import { formatParams, parseParams, sortRoutes } from './utils'

// HACK FOR EXPORT，其他位置理应从 web-layout export
export { MenuType } from 'packages/web-layout/menu-types'

/**
 * convert RouteConfig to RouteNode
 *
 * @param route - RouteConfig
 * @param parent - parent RouteNode
 * @returns RouteNode
 */
export const convertRoute = (
  route: RouteConfig,
  parent?: RouteNode,
): RouteNode => {
  const { subRoutes, name, path, ...otherConfig } = route
  const converted: RouteNode = {
    ...otherConfig,
    path: `${parent?.path || ''}/${path}`,
    exact: !route.subRoutes,
    parentPath: parent?.path,
    name: name
      ? parent && parent.name
        ? `${parent.name}.${name}`
        : name
      : undefined,
  }

  if (subRoutes) {
    converted.subRoutes = subRoutes.map<RouteNode>(sr =>
      convertRoute(sr, converted),
    )
  }

  return converted
}

/**
 * 系统默认的路由
 */
export const routes: RouteNode[] = [
  convertRoute({
    path: '404',
    component: lazy(() =>
      import('./components/NotFound').then(m => ({ default: m.NotFound })),
    ),
    getTitle: () => $t('找不到页面'),
  }),
  convertRoute({
    path: '403',
    component: lazy(() =>
      import('./components/NotAllowed').then(m => ({ default: m.NotAllowed })),
    ),
    getTitle: () => $t('无访问权限'),
  }),
]

/**
 * 弹窗路由
 */
export const modalRoutes: ModalRouteNode[] = []

/**
 * 新增路由
 *
 * @param newRoutes - 新增的路由
 * @param newModalRoutes - 新增的弹窗路由
 * @returns 新增后路由对象
 */
export const addRoutes = (
  newRoutes?: RouteConfig[],
  newModalRoutes?: ModalRouteNode[],
) => {
  const convertedRoutes = newRoutes?.map(r => convertRoute(r)) || []
  if (convertedRoutes.length) {
    routes.push(...convertedRoutes)
    sortRoutes(routes)
  }

  if (newModalRoutes) modalRoutes.push(...newModalRoutes)
  return {
    routes: convertedRoutes,
    modalRoutes: newModalRoutes,
  }
}

export * from './types'
export * from './hooks'
export * from './utils'

// lazily inited map
let nameToRoute: { [key: string]: RouteNode } | null = null

const genNameToRoute = (routesRaw: RouteNode[]) => {
  const map: { [key: string]: RouteNode } = {}
  const routesToMap = (curRoutes: RouteNode[]) => {
    if (!curRoutes) return {}
    curRoutes.forEach((r: RouteNode) => {
      if (r.name) {
        if (map[r.name] != null) {
          if (isProduction()) {
            // eslint-disable-next-line
            console.error('路由名重复')
          } else {
            throw new Error(
              // eslint-disable-next-line
              `路径 ${r.path} 的路由名称： ${r.name} 已经存在, 请修改为唯一值`,
            )
          }
        }

        map[r.name] = r
      }

      return r.subRoutes && routesToMap(r.subRoutes)
    })

    return map
  }

  return routesToMap(routesRaw)
}

/**
 * 根据路由名称和参数生成 url path
 *
 * @param name - 路由名称
 * @param params - 路由参数
 * @returns url path with query
 */
export const getPath = <
  TRouteParamsList extends RouteParamsList,
  TName extends keyof TRouteParamsList,
>(
  name: TName,
  params?: TRouteParamsList[TName],
) => {
  nameToRoute = nameToRoute ?? genNameToRoute(routes)

  let { path } = nameToRoute[name as any]

  if (!params) return path

  const query: { [key: string]: string } = {}
  let hasQuery = false

  Object.entries(formatParams(params as RouteParams)).forEach(([key, val]) => {
    if (val == null) return

    const endExp = new RegExp(`:${key}$`)
    const midExp = new RegExp(`:${key}/`)

    if (path.match(endExp)) {
      path = path.replace(endExp, val)
    } else if (path.match(midExp)) {
      path = path.replace(midExp, `${val}/`)
    } else {
      query[key] = val
      hasQuery = true
    }
  })

  return hasQuery ? `${path}?${stringifyURLQuery(query)}` : path
}

/**
 * useHistory with RouteParams typings
 *
 * @returns typed { push, replace, goBack } methods
 */
export const useHistory = <TRouteParamsList extends RouteParamsList>() => {
  const history = useHistoryOriginal()

  return {
    push: <TName extends keyof TRouteParamsList>(
      name: TName,
      params?: TRouteParamsList[TName],
      state?: LocationState,
    ) => history.push(getPath(name, params), state),

    replace: <TName extends keyof TRouteParamsList>(
      name: TName,
      params?: TRouteParamsList[TName],
      state?: LocationState,
    ) => history.replace(getPath(name, params), state),

    goBack: () => {
      if (history.length <= 1) {
        /*
         * 如果长度为 1，则将当前路由改变为 Home
         * 然后向内推入一个当前路由的信息
         * 最后正常执行后退，回到首页
         */

        const currentPath = `${history.location.pathname}${history.location.search}`
        const currentState = history.location.state
        history.replace('/')
        history.push(currentPath, currentState)
      }

      history.goBack()
    },
  }
}

/**
 * useParams with RouteParams typings
 *
 * @returns RouteParams[TName]
 */
export const useParams = <
  TRouteParamsList extends RouteParamsList,
  TName extends keyof TRouteParamsList,
>(): TRouteParamsList[TName] =>
  parseParams(useParamsRaw()) as TRouteParamsList[TName]

/**
 * useRouteMatch with RouteParams typings
 *
 * @param name - 路由名称
 * @returns match
 */
export const useRouteMatch = <
  TRouteParamsList extends RouteParamsList,
  TName extends keyof TRouteParamsList,
>(
  name: TName,
) => {
  nameToRoute = nameToRoute ?? genNameToRoute(routes)
  const { path } = nameToRoute[name as any]

  // useRouteMatch 匹配出的参数只支持 string 类型
  return useRouteMatchOriginal<{
    [key in keyof TRouteParamsList[TName]]: string
  }>(path)
}

/**
 * direct export from react-router-dom
 */
export const useLocation = useLocationOriginal

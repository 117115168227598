import { useLocation } from 'packages/route'

/**
 * 根据路由判断当前页面是否为后台页面
 *
 * @returns boolean
 */
export const useIsAdminByPath = () => {
  const location = useLocation()
  return location.pathname.startsWith('/admin/')
}

export enum BinaryOp {
  Eq = '==',
  Neq = '!=',
  Gt = '>',
  Gte = '>=',
  Lt = '<',
  Lte = '<=',
  Plus = '+',
  Minus = '-',
  Multiply = '*',
  Divide = '/',
  Mod = '%',
  Xor = 'xor',
  Between = 'between',
  Contains = 'contains',
  NotContains = 'not contains',
  In = 'in',
  NotIn = 'not in',
  Overlaps = 'overlaps',
  NotOverlaps = 'not overlaps',
  StartsWith = 'starts with',
  EndsWith = 'ends with',
  IsEmpty = 'is empty',
  IsNotEmpty = 'is not empty',
  HasPermission = 'has permission',
}

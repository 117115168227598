/**
 * @file 自定义内容弹窗函数组件
 */

import { Modal } from '@seiue/ui'
import React from 'react'

interface Params {
  component: ({ onCancel }: { onCancel: () => void }) => React.ReactNode // 内容区
  afterClose?: () => void // 关闭后的回调
  width?: number | string // 弹窗宽度
  bodyStyle?: React.CSSProperties // 弹窗内容器样式
  zIndex?: number
}

/**
 * 函数式组件工厂
 * @deprecated use Modal.open from @seiue/ui instead.
 */
export const funcFactory = ({
  component,
  afterClose,
  width,
  bodyStyle,
  zIndex,
}: Params) => {
  const { hide } = Modal.open({
    children: component({
      onCancel: () => {
        hide()
        afterClose?.()
      },
    }),
    header: null,
    footer: null,
    zIndex,
    closable: false,
    width,
    bodyStyle,
    style: {
      maxHeight: 'calc(80vh)',
      overflow: 'hidden',
    },
  })
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { GstTemplate, GstTemplateInterface } from './gst-template'
import type { QueryGstTemplatesQuery } from './query-gst-templates-query'

const buildConfig = createConfigBuilder('scms')

export const gstApi$queryGstTemplates = {
  config(
    queryGstTemplatesQuery?: QueryGstTemplatesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/gst/templates',
      {},
      queryGstTemplatesQuery,
    )
  },

  /**
   * @param [queryGstTemplatesQuery]
   * @param [gstQuery.idIn]
   * @param [gstQuery.name]
   * @param [gstQuery.nameLike]
   * @param [gstQuery.disabled]
   * @param [gstQuery.expand]
   * @param [gstQuery.fields]
   * @param [gstQuery.sort]
   * @param [gstQuery.paginated] 是否分页，默认 1
   * @param [gstQuery.page] 显示的数据页，默认 1
   * @param [gstQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<GstTemplate[]>
   */

  api<const TQuery extends QueryGstTemplatesQuery>(
    queryGstTemplatesQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<GstTemplate, TQuery['expand']>[]> {
    return axios(
      gstApi$queryGstTemplates.config(queryGstTemplatesQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new GstTemplate(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryGstTemplatesQuery,
    TSelected = Expand<GstTemplate, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<GstTemplate, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<GstTemplate, TQuery['expand']>[], TSelected>(
      gstApi$queryGstTemplates.api,
      'Gst.queryGstTemplates',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
gstApi$queryGstTemplates.api._name_ = `Gst.queryGstTemplates`

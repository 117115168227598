export interface RemindSettingInterface {
  range?: string
  time?: string
}

export class RemindSetting implements RemindSettingInterface {
  range?: string
  time?: string

  constructor(props: RemindSettingInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      range: _range,
      time: _time,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.range = _range
    this.time = _time
  }

  static propKeys = ['range', 'time']
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  SchoolExtLocaleText,
  SchoolExtLocaleTextInterface,
} from './school-ext-locale-text'
import type { QuerySchoolExtLocaleTextsQuery } from './query-school-ext-locale-texts-query'

const buildConfig = createConfigBuilder('chalk')

export const i18nApi$querySchoolExtLocaleTexts = {
  config(
    querySchoolExtLocaleTextsQuery?: QuerySchoolExtLocaleTextsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/i18n/school-ext-locale-texts',
      {},
      querySchoolExtLocaleTextsQuery,
    )
  },

  /**
   * @summary 查询学校扩展的语言文本
   * @param [querySchoolExtLocaleTextsQuery]
   * @param [i18nQuery.srcLike]
   * @param [i18nQuery.srcIn]
   * @param [i18nQuery.src]
   * @param [i18nQuery.category]
   * @param [i18nQuery.categoryIn]
   * @param [i18nQuery.dstEnLike]
   * @param [i18nQuery.sort]
   * @param [i18nQuery.paginated] 是否分页，默认 1
   * @param [i18nQuery.page] 显示的数据页，默认 1
   * @param [i18nQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<SchoolExtLocaleText[]>
   */

  api(
    querySchoolExtLocaleTextsQuery?: QuerySchoolExtLocaleTextsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<SchoolExtLocaleText[]> {
    return axios(
      i18nApi$querySchoolExtLocaleTexts.config(
        querySchoolExtLocaleTextsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new SchoolExtLocaleText(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = SchoolExtLocaleText[]>(
    query?: QuerySchoolExtLocaleTextsQuery,
    queryOptions?: QueryOptionsWithSelect<SchoolExtLocaleText[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<SchoolExtLocaleText[], TSelected>(
      i18nApi$querySchoolExtLocaleTexts.api,
      'I18n.querySchoolExtLocaleTexts',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
i18nApi$querySchoolExtLocaleTexts.api._name_ = `I18n.querySchoolExtLocaleTexts`

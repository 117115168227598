import { GradeSourceEnum } from './grade-source-enum'
import { GradeStatusEnum } from './grade-status-enum'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import { GradePassStatusEnum } from './grade-pass-status-enum'
import { Attachment, AttachmentInterface } from './attachment'
import { Class, ClassInterface } from './extends/class'
import { ResitStatus } from './resit-status'
import { ScoreStatus } from './score-status'
import { Term, TermInterface } from './extends/term'
import { GradeData, GradeDataInterface } from './grade-data'
import { OwnerStatusEnum } from './owner-status-enum'

export interface GradeInterface {
  /**
   * 学期 ID
   */
  semesterId?: number | null
  /**
   * 当为评价的总成绩时，为评价 ID （ AssessmentID ）
   */
  sourceId: number
  /**
   * 如果是学段成绩，则为关联的学段 ID ，否则为 null
   */
  stageId?: number | null
  /**
   * 总成绩来源
   */
  source: GradeSourceEnum
  /**
   * 总成绩状态
   */
  status: GradeStatusEnum
  /**
   * 发布时间
   */
  publishedAt?: string | null
  /**
   * 发布人 ID
   */
  publishedBy?: number | null
  /**
   * 所属人 RID （学生）
   */
  ownerId: number
  /**
   * 所属人 expanded
   */
  owner?: SecuredReflectionInterface
  /**
   * 及格状态
   * 未发布时，如果趋势分及格，则为 pending ，否则为 warning
   * 已发布时，如果及格，则为 passed ，否则为 failed
   */
  passStatus: GradePassStatusEnum | null
  /**
   * 已得分。所属学生，在所有已经**发布**的评价项中的汇总得分
   */
  gainedScore?: string | null
  /**
   * 已得分的应得分。所属学生，在所有已经**发布**的评价项中的，全满分时的汇总得分
   */
  shouldGainedScore?: string | null
  /**
   * 已得分率。所属学生的 gained_score / should_gained_score 比值。固定 4 位小数，如 0.9512
   */
  gainedScoreRate?: string | null
  /**
   * 未发布时，等于 trend_score
   * 发布后，当 score != null 时等于 score ，否则等于 gained_score
   */
  studentRealScore?: string | null
  /**
   * student_real_score 对应得分率
   * 已发布有手打分 score/full_score，否则等于 gained_score_rate
   */
  studentRealScoreRate?: string | null
  /**
   * 教师直接打分，直接给总成绩分（与评价项无关）
   */
  score?: string | null
  /**
   * 当 score != null 时等于 score ，否则等于 suggest_score
   */
  realScore?: string | null
  /**
   * 系统算分。所属学生，在所有有分（含所有状态，发布、未发布的）的评价项的汇总得分
   */
  suggestScore?: string | null
  /**
   * 系统算分的应得分。所属学生，在所有有分（含所有状态，发布、未发布的）的评价项中，全满分时的汇总得分
   */
  shouldSuggestScore?: string | null
  /**
   * 系统算分得分率，suggest_score / should_suggest_score 比值。固定 4 位小数，如 0.9512
   */
  suggestScoreRate?: string | null
  /**
   * real_score / 系统算分的应得分 should_suggest_score
   */
  realScoreRate?: string | null
  /**
   * 最终等级，如果自定义过等级，则为自定义等级，否则为 suggest_level
   * 当总成绩为等级类型时，level 由教师直接给出
   */
  level?: string | null
  /**
   * 系统计算 level
   */
  suggestLevel?: string | null
  /**
   * 是否自定义等级
   */
  levelCustomized: boolean
  /**
   * 未发布时，固定为 null
   * 发布后，如果总成绩合格（按评价标准计算），则等于 credit ，否则为 0
   */
  gainedCredit?: string | null
  /**
   * 当为课程班评价时，固定 credit = class.credit
   */
  credit?: string | null
  /**
   * 当总成绩为分数类型时，gpa 由 real_score 按评价标准换算而来，默认为 null
   * 当总成绩为等级类型时，gpa 由 level 按评价标准换算而来，默认为 null
   */
  gpa?: string | null
  /**
   * 补考得分率
   */
  resitScoreRate?: string | null
  /**
   * 趋势（预测）得分 ， 已得分率(gained_score_rate) * 满分(full_score)
   */
  trendScore?: string | null
  trendCredit?: string | null
  trendGpa?: string | null
  trendLevel?: string | null
  trendCourseRank?: number | null
  /**
   * 已得分 gained_score 的排名、基数
   */
  gainedRank?: number | null
  gainedRankBase?: number | null
  gainedCourseRank?: number | null
  gainedCourseRankBase?: number | null
  /**
   * 最终得分 real_score 的评价内排名
   */
  rank?: number | null
  /**
   * rank 的排名基数
   */
  rankBase?: number | null
  /**
   * 同一课程库（如果未关联课程库，则是同一课程编号 csin），
   * 同一学期，
   * 同一上课开始、结束周，
   * 且使用同一评价标准（或都未使用评价标准）的所有课程评价的共同排名
   */
  courseRank?: number | null
  courseRankBase?: number | null
  attachments?: AttachmentInterface[]
  review: string
  editReason: string
  name: string
  /**
   * 延迟发布理由
   */
  delayedReason: string
  hidden: boolean
  klass?: ClassInterface
  /**
   * 满分
   */
  fullScore?: string | null
  planType?: string | null
  subject?: string
  csin?: string
  avgScore?: string | null
  /**
   * 补考fields
   */
  resitStatus?: ResitStatus | null
  resitPassStatus: GradePassStatusEnum | null
  resitScore?: string | null
  resitScoreStatus?: ScoreStatus | null
  resitCredit?: string | null
  resitGpa?: string | null
  resitLevel?: string | null
  resitInvalidReason?: string | null
  resitGradeId?: number | null
  resitSuggestScore?: string | null
  /**
   * 特殊评价规则-录入的等级、分数
   */
  specialLevel?: string | null
  specialScore?: string | null
  /**
   * 暂不评价类型 ID ，暂不评价时必填
   */
  invalidTypeId?: number | null
  /**
   * 暂不评价类型 expanded
   */
  invalidType?: TermInterface | null
  data?: GradeDataInterface
  /**
   * 成员状态
   */
  ownerStatus: OwnerStatusEnum
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Grade implements GradeInterface {
  /**
   * 学期 ID
   */
  semesterId?: number | null
  /**
   * 当为评价的总成绩时，为评价 ID （ AssessmentID ）
   */
  sourceId: number
  /**
   * 如果是学段成绩，则为关联的学段 ID ，否则为 null
   */
  stageId?: number | null
  /**
   * 总成绩来源
   */
  source: GradeSourceEnum
  /**
   * 总成绩状态
   */
  status: GradeStatusEnum
  /**
   * 发布时间
   */
  publishedAt?: string | null
  /**
   * 发布人 ID
   */
  publishedBy?: number | null
  /**
   * 所属人 RID （学生）
   */
  ownerId: number
  /**
   * 所属人 expanded
   */
  owner?: SecuredReflection
  /**
   * 及格状态
   * 未发布时，如果趋势分及格，则为 pending ，否则为 warning
   * 已发布时，如果及格，则为 passed ，否则为 failed
   */
  passStatus: GradePassStatusEnum | null
  /**
   * 已得分。所属学生，在所有已经**发布**的评价项中的汇总得分
   */
  gainedScore?: string | null
  /**
   * 已得分的应得分。所属学生，在所有已经**发布**的评价项中的，全满分时的汇总得分
   */
  shouldGainedScore?: string | null
  /**
   * 已得分率。所属学生的 gained_score / should_gained_score 比值。固定 4 位小数，如 0.9512
   */
  gainedScoreRate?: string | null
  /**
   * 未发布时，等于 trend_score
   * 发布后，当 score != null 时等于 score ，否则等于 gained_score
   */
  studentRealScore?: string | null
  /**
   * student_real_score 对应得分率
   * 已发布有手打分 score/full_score，否则等于 gained_score_rate
   */
  studentRealScoreRate?: string | null
  /**
   * 教师直接打分，直接给总成绩分（与评价项无关）
   */
  score?: string | null
  /**
   * 当 score != null 时等于 score ，否则等于 suggest_score
   */
  realScore?: string | null
  /**
   * 系统算分。所属学生，在所有有分（含所有状态，发布、未发布的）的评价项的汇总得分
   */
  suggestScore?: string | null
  /**
   * 系统算分的应得分。所属学生，在所有有分（含所有状态，发布、未发布的）的评价项中，全满分时的汇总得分
   */
  shouldSuggestScore?: string | null
  /**
   * 系统算分得分率，suggest_score / should_suggest_score 比值。固定 4 位小数，如 0.9512
   */
  suggestScoreRate?: string | null
  /**
   * real_score / 系统算分的应得分 should_suggest_score
   */
  realScoreRate?: string | null
  /**
   * 最终等级，如果自定义过等级，则为自定义等级，否则为 suggest_level
   * 当总成绩为等级类型时，level 由教师直接给出
   */
  level?: string | null
  /**
   * 系统计算 level
   */
  suggestLevel?: string | null
  /**
   * 是否自定义等级
   */
  levelCustomized: boolean
  /**
   * 未发布时，固定为 null
   * 发布后，如果总成绩合格（按评价标准计算），则等于 credit ，否则为 0
   */
  gainedCredit?: string | null
  /**
   * 当为课程班评价时，固定 credit = class.credit
   */
  credit?: string | null
  /**
   * 当总成绩为分数类型时，gpa 由 real_score 按评价标准换算而来，默认为 null
   * 当总成绩为等级类型时，gpa 由 level 按评价标准换算而来，默认为 null
   */
  gpa?: string | null
  /**
   * 补考得分率
   */
  resitScoreRate?: string | null
  /**
   * 趋势（预测）得分 ， 已得分率(gained_score_rate) * 满分(full_score)
   */
  trendScore?: string | null
  trendCredit?: string | null
  trendGpa?: string | null
  trendLevel?: string | null
  trendCourseRank?: number | null
  /**
   * 已得分 gained_score 的排名、基数
   */
  gainedRank?: number | null
  gainedRankBase?: number | null
  gainedCourseRank?: number | null
  gainedCourseRankBase?: number | null
  /**
   * 最终得分 real_score 的评价内排名
   */
  rank?: number | null
  /**
   * rank 的排名基数
   */
  rankBase?: number | null
  /**
   * 同一课程库（如果未关联课程库，则是同一课程编号 csin），
   * 同一学期，
   * 同一上课开始、结束周，
   * 且使用同一评价标准（或都未使用评价标准）的所有课程评价的共同排名
   */
  courseRank?: number | null
  courseRankBase?: number | null
  attachments?: Attachment[]
  review: string
  editReason: string
  name: string
  /**
   * 延迟发布理由
   */
  delayedReason: string
  hidden: boolean
  klass?: Class
  /**
   * 满分
   */
  fullScore?: string | null
  planType?: string | null
  subject?: string
  csin?: string
  avgScore?: string | null
  /**
   * 补考fields
   */
  resitStatus?: ResitStatus | null
  resitPassStatus: GradePassStatusEnum | null
  resitScore?: string | null
  resitScoreStatus?: ScoreStatus | null
  resitCredit?: string | null
  resitGpa?: string | null
  resitLevel?: string | null
  resitInvalidReason?: string | null
  resitGradeId?: number | null
  resitSuggestScore?: string | null
  /**
   * 特殊评价规则-录入的等级、分数
   */
  specialLevel?: string | null
  specialScore?: string | null
  /**
   * 暂不评价类型 ID ，暂不评价时必填
   */
  invalidTypeId?: number | null
  /**
   * 暂不评价类型 expanded
   */
  invalidType?: Term | null
  data?: GradeData
  /**
   * 成员状态
   */
  ownerStatus: OwnerStatusEnum
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: GradeInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      semesterId: _semesterId,
      sourceId: _sourceId,
      stageId: _stageId,
      source: _source,
      status: _status,
      publishedAt: _publishedAt,
      publishedBy: _publishedBy,
      ownerId: _ownerId,
      owner: _owner,
      passStatus: _passStatus,
      gainedScore: _gainedScore,
      shouldGainedScore: _shouldGainedScore,
      gainedScoreRate: _gainedScoreRate,
      studentRealScore: _studentRealScore,
      studentRealScoreRate: _studentRealScoreRate,
      score: _score,
      realScore: _realScore,
      suggestScore: _suggestScore,
      shouldSuggestScore: _shouldSuggestScore,
      suggestScoreRate: _suggestScoreRate,
      realScoreRate: _realScoreRate,
      level: _level,
      suggestLevel: _suggestLevel,
      levelCustomized: _levelCustomized,
      gainedCredit: _gainedCredit,
      credit: _credit,
      gpa: _gpa,
      resitScoreRate: _resitScoreRate,
      trendScore: _trendScore,
      trendCredit: _trendCredit,
      trendGpa: _trendGpa,
      trendLevel: _trendLevel,
      trendCourseRank: _trendCourseRank,
      gainedRank: _gainedRank,
      gainedRankBase: _gainedRankBase,
      gainedCourseRank: _gainedCourseRank,
      gainedCourseRankBase: _gainedCourseRankBase,
      rank: _rank,
      rankBase: _rankBase,
      courseRank: _courseRank,
      courseRankBase: _courseRankBase,
      attachments: _attachments,
      review: _review,
      editReason: _editReason,
      name: _name,
      delayedReason: _delayedReason,
      hidden: _hidden,
      klass: _klass,
      fullScore: _fullScore,
      planType: _planType,
      subject: _subject,
      csin: _csin,
      avgScore: _avgScore,
      resitStatus: _resitStatus,
      resitPassStatus: _resitPassStatus,
      resitScore: _resitScore,
      resitScoreStatus: _resitScoreStatus,
      resitCredit: _resitCredit,
      resitGpa: _resitGpa,
      resitLevel: _resitLevel,
      resitInvalidReason: _resitInvalidReason,
      resitGradeId: _resitGradeId,
      resitSuggestScore: _resitSuggestScore,
      specialLevel: _specialLevel,
      specialScore: _specialScore,
      invalidTypeId: _invalidTypeId,
      invalidType: _invalidType,
      data: _data,
      ownerStatus: _ownerStatus,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.semesterId = _semesterId
    this.sourceId = _sourceId
    this.stageId = _stageId
    this.source = _source
    this.status = _status
    this.publishedAt = _publishedAt
    this.publishedBy = _publishedBy
    this.ownerId = _ownerId
    this.owner = _owner ? new SecuredReflection(_owner) : _owner
    this.passStatus = _passStatus
    this.gainedScore = _gainedScore
    this.shouldGainedScore = _shouldGainedScore
    this.gainedScoreRate = _gainedScoreRate
    this.studentRealScore = _studentRealScore
    this.studentRealScoreRate = _studentRealScoreRate
    this.score = _score
    this.realScore = _realScore
    this.suggestScore = _suggestScore
    this.shouldSuggestScore = _shouldSuggestScore
    this.suggestScoreRate = _suggestScoreRate
    this.realScoreRate = _realScoreRate
    this.level = _level
    this.suggestLevel = _suggestLevel
    this.levelCustomized = _levelCustomized
    this.gainedCredit = _gainedCredit
    this.credit = _credit
    this.gpa = _gpa
    this.resitScoreRate = _resitScoreRate
    this.trendScore = _trendScore
    this.trendCredit = _trendCredit
    this.trendGpa = _trendGpa
    this.trendLevel = _trendLevel
    this.trendCourseRank = _trendCourseRank
    this.gainedRank = _gainedRank
    this.gainedRankBase = _gainedRankBase
    this.gainedCourseRank = _gainedCourseRank
    this.gainedCourseRankBase = _gainedCourseRankBase
    this.rank = _rank
    this.rankBase = _rankBase
    this.courseRank = _courseRank
    this.courseRankBase = _courseRankBase
    this.attachments = _attachments
      ? _attachments.map(i => new Attachment(i))
      : _attachments
    this.review = _review
    this.editReason = _editReason
    this.name = _name
    this.delayedReason = _delayedReason
    this.hidden = _hidden
    this.klass = _klass ? new Class(_klass) : _klass
    this.fullScore = _fullScore
    this.planType = _planType
    this.subject = _subject
    this.csin = _csin
    this.avgScore = _avgScore
    this.resitStatus = _resitStatus
    this.resitPassStatus = _resitPassStatus
    this.resitScore = _resitScore
    this.resitScoreStatus = _resitScoreStatus
    this.resitCredit = _resitCredit
    this.resitGpa = _resitGpa
    this.resitLevel = _resitLevel
    this.resitInvalidReason = _resitInvalidReason
    this.resitGradeId = _resitGradeId
    this.resitSuggestScore = _resitSuggestScore
    this.specialLevel = _specialLevel
    this.specialScore = _specialScore
    this.invalidTypeId = _invalidTypeId
    this.invalidType = _invalidType ? new Term(_invalidType) : _invalidType
    this.data = _data ? new GradeData(_data) : _data
    this.ownerStatus = _ownerStatus
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'semesterId',
    'sourceId',
    'stageId',
    'source',
    'status',
    'publishedAt',
    'publishedBy',
    'ownerId',
    'owner',
    'passStatus',
    'gainedScore',
    'shouldGainedScore',
    'gainedScoreRate',
    'studentRealScore',
    'studentRealScoreRate',
    'score',
    'realScore',
    'suggestScore',
    'shouldSuggestScore',
    'suggestScoreRate',
    'realScoreRate',
    'level',
    'suggestLevel',
    'levelCustomized',
    'gainedCredit',
    'credit',
    'gpa',
    'resitScoreRate',
    'trendScore',
    'trendCredit',
    'trendGpa',
    'trendLevel',
    'trendCourseRank',
    'gainedRank',
    'gainedRankBase',
    'gainedCourseRank',
    'gainedCourseRankBase',
    'rank',
    'rankBase',
    'courseRank',
    'courseRankBase',
    'attachments',
    'review',
    'editReason',
    'name',
    'delayedReason',
    'hidden',
    'klass',
    'fullScore',
    'planType',
    'subject',
    'csin',
    'avgScore',
    'resitStatus',
    'resitPassStatus',
    'resitScore',
    'resitScoreStatus',
    'resitCredit',
    'resitGpa',
    'resitLevel',
    'resitInvalidReason',
    'resitGradeId',
    'resitSuggestScore',
    'specialLevel',
    'specialScore',
    'invalidTypeId',
    'invalidType',
    'data',
    'ownerStatus',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

/**
 * @file 消息 Domain
 */

export enum MessageDomainEnum {
  ClassAssessment = 'class_assessment',
  DormAssessment = 'dorm_assessment',
  MoralAssessment = 'moral_assessment',
  TeacherAssessment = 'teacher_assessment',
  Task = 'task',
  Talk = 'talk',
  Attendance = 'attendance',
  AdminClass = 'admin_class',
  AdminClassAbnormalAttendance = 'adminclass_abnormal_attendance',
  AdminClassMissAttendance = 'adminclass_miss_attendance',
  Dorm = 'dorm',
  Group = 'group',
  Schedule = 'calendar',
  Notification = 'notification',
  Schcal = 'schcal',
  ClassAdjustment = 'class_adjustment',
  Evaluation = 'evaluation',
  // Waiting Delete
  MemberType = 'member_type',
  Class = 'class',
  SubjectSelection = 'direction',
  Venue = 'venue',
  Declaration = 'declaration',
  ExamSchedule = 'exam_schedule',
  Exam = 'exam',
  Certification = 'certification',
  Election = 'election',
  SchoolPlugin = 'school_plugin',
  Event = 'event',
  ClassIn = 'classin',
  ClassStop = 'class_stop',
  CustomGroupSignupSubmission = 'signup_submission',
  CustomGroupNotice = 'group_notice',
  CustomGroup = 'custom_group',
  Cgr = 'cgr',
  Customized = 'customized',
  Questionnaire = 'questionnaire',
  Approval = 'workflow',
  Psychological = 'psychological',
  PsyChat = 'psy_chat',
  Chat = 'chat',
  TeacherProfile = 'teacher_profile',
  Visitor = 'visitor',
  Contract = 'contract',
  LeaveFlow = 'leave_flow',
  Handout = 'handout',
  Reporting = 'reporting',
  SzHomeWorkSurvey = 'sz_homework_survey',
  IntlGoal = 'intl_goal',
  ClassReview = 'class_review',
  AcademicAnswer = 'academic_answer',
  AbnormalAttendance = 'abnormal_attendance',
  MissAttendance = 'miss_attendance',
  Insider = 'insider',
  Message = 'message',
  AITeacher = 'ai-teacher',
}

import { FormGroupBizTypeEnum } from './form-group-biz-type-enum'
import { FormStatusEnum } from './form-status-enum'
import { FormBizStatusEnum } from './form-biz-status-enum'
import { FormAnswer, FormAnswerInterface } from './form-answer'
import { FormFeedback, FormFeedbackInterface } from './form-feedback'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import { FormSourceEnum } from './form-source-enum'

export interface FormInterface {
  formTemplateId?: number
  groupBizType: FormGroupBizTypeEnum
  groupBizId: number
  subjectId: number
  ownerId: number
  status: FormStatusEnum
  /**
   * 不同业务代表的含义不同，比如申报的代表的是审批状态
   */
  bizStatus?: FormBizStatusEnum | null
  answers?: FormAnswerInterface[]
  overallAnswers?: FormAnswerInterface[]
  feedbacks?: FormFeedbackInterface[]
  group?: object | null
  subject?: object | null
  reason?: string | null
  owner?: SecuredReflectionInterface
  duration?: number
  completedAt?: string | null
  source?: FormSourceEnum
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Form implements FormInterface {
  formTemplateId?: number
  groupBizType: FormGroupBizTypeEnum
  groupBizId: number
  subjectId: number
  ownerId: number
  status: FormStatusEnum
  /**
   * 不同业务代表的含义不同，比如申报的代表的是审批状态
   */
  bizStatus?: FormBizStatusEnum | null
  answers?: FormAnswer[]
  overallAnswers?: FormAnswer[]
  feedbacks?: FormFeedback[]
  group?: object | null
  subject?: object | null
  reason?: string | null
  owner?: SecuredReflection
  duration?: number
  completedAt?: string | null
  source?: FormSourceEnum
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: FormInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      formTemplateId: _formTemplateId,
      groupBizType: _groupBizType,
      groupBizId: _groupBizId,
      subjectId: _subjectId,
      ownerId: _ownerId,
      status: _status,
      bizStatus: _bizStatus,
      answers: _answers,
      overallAnswers: _overallAnswers,
      feedbacks: _feedbacks,
      group: _group,
      subject: _subject,
      reason: _reason,
      owner: _owner,
      duration: _duration,
      completedAt: _completedAt,
      source: _source,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.formTemplateId = _formTemplateId
    this.groupBizType = _groupBizType
    this.groupBizId = _groupBizId
    this.subjectId = _subjectId
    this.ownerId = _ownerId
    this.status = _status
    this.bizStatus = _bizStatus
    this.answers = _answers ? _answers.map(i => new FormAnswer(i)) : _answers
    this.overallAnswers = _overallAnswers
      ? _overallAnswers.map(i => new FormAnswer(i))
      : _overallAnswers
    this.feedbacks = _feedbacks
      ? _feedbacks.map(i => new FormFeedback(i))
      : _feedbacks
    this.group = _group
    this.subject = _subject
    this.reason = _reason
    this.owner = _owner ? new SecuredReflection(_owner) : _owner
    this.duration = _duration
    this.completedAt = _completedAt
    this.source = _source
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'formTemplateId',
    'groupBizType',
    'groupBizId',
    'subjectId',
    'ownerId',
    'status',
    'bizStatus',
    'answers',
    'overallAnswers',
    'feedbacks',
    'group',
    'subject',
    'reason',
    'owner',
    'duration',
    'completedAt',
    'source',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { CalendarEvent, CalendarEventInterface } from './extends/calendar-event'
import type { ListPersonalEventsQuery } from './list-personal-events-query'

const buildConfig = createConfigBuilder('chalk')

export const calendarApi$listPersonalEvents = {
  config(
    id: number,
    startTime: string,
    endTime: string,
    listPersonalEventsQuery?: ListPersonalEventsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/calendar/personals/{id}/events',
      {
        id,
        startTime,
        endTime,
      },
      listPersonalEventsQuery,
    )
  },

  /**
   * @summary 获取个人日程事件
   * @param id Reflection ID
   * @param startTime 开始时间
   * @param endTime 结束时间
   * @param [listPersonalEventsQuery]
   * @param [calendarQuery.expand] 支持 initiators 和 address
   * @param [options]
   * @return AxiosResponsePromise<CalendarEvent[]>
   */

  api<const TQuery extends ListPersonalEventsQuery>(
    id: number,
    startTime: string,
    endTime: string,
    listPersonalEventsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<CalendarEvent, TQuery['expand']>[]> {
    return axios(
      calendarApi$listPersonalEvents.config(
        id,
        startTime,
        endTime,
        listPersonalEventsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new CalendarEvent(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends ListPersonalEventsQuery,
    TSelected = Expand<CalendarEvent, TQuery['expand']>[],
  >(
    {
      id,
      startTime,
      endTime,
      query,
    }: {
      id: number
      startTime: string
      endTime: string
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<CalendarEvent, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<CalendarEvent, TQuery['expand']>[], TSelected>(
      calendarApi$listPersonalEvents.api,
      'Calendar.listPersonalEvents',
      queryOptions,
      id,
      startTime,
      endTime,
      query,
    )
  },
}

// @ts-ignore
calendarApi$listPersonalEvents.api._name_ = `Calendar.listPersonalEvents`

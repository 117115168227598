/**
 * @file 报表中心路由注册
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Reporting,
  getLabel: () => $t('预警'),
  labelStyle: calculateLabelStyle('#FFEBEF', '#B5295F'),
  messages: [
    // 报表中心-预警消息
    {
      type: 'warning_received',
      important: true,
    },
  ],
})

import { GenderEnum } from './gender-enum'
import { UserStatusEnum } from './user-status-enum'
import { Reflection, ReflectionInterface } from './reflection'

export interface UserInterface {
  name: string
  gender?: GenderEnum | null
  phone?: string | null
  email?: string | null
  avatar?: string | null
  passwordChanged?: boolean
  status: UserStatusEnum
  reflections?: ReflectionInterface[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class User implements UserInterface {
  name: string
  gender?: GenderEnum | null
  phone?: string | null
  email?: string | null
  avatar?: string | null
  passwordChanged?: boolean
  status: UserStatusEnum
  reflections?: Reflection[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: UserInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      gender: _gender,
      phone: _phone,
      email: _email,
      avatar: _avatar,
      passwordChanged: _passwordChanged,
      status: _status,
      reflections: _reflections,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.gender = _gender
    this.phone = _phone
    this.email = _email
    this.avatar = _avatar
    this.passwordChanged = _passwordChanged
    this.status = _status
    this.reflections = _reflections
      ? _reflections.map(i => new Reflection(i))
      : _reflections
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'name',
    'gender',
    'phone',
    'email',
    'avatar',
    'passwordChanged',
    'status',
    'reflections',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

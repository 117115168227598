/**
 * @file 注册宿舍消息
 */

import { PluginEnum } from 'packages/feature-utils/plugins'
import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

import { MessageTypes } from './message-type'

registerDomainMessage({
  type: MessageDomainEnum.Dorm,
  getLabel: () => $t('宿舍'),
  icon: `plugin.${PluginEnum.Dorm}`,
  labelStyle: calculateLabelStyle('#ffebef', '#b5295f'),
  messages: [
    'deleted',
    'member_added',
    'member_removed',
    MessageTypes.InputAttendance,
  ],
})

/**
 * @file 学期数据相关 utils
 */

import { useSelectedSemester } from 'packages/feature-utils/semesters'

/**
 * @deprecated use useSelectedSemester from 'packages/feature-utils/semesters' instead
 */
export const useSelectedSemesterId = () => {
  return useSelectedSemester()[0]?.id
}

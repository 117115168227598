/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { ChatInstance, ChatInstanceInterface } from './chat-instance'
import type { ListChatInstancesQuery } from './list-chat-instances-query'

const buildConfig = createConfigBuilder('chalk')

export const chatApi$listChatInstances = {
  config(
    listChatInstancesQuery?: ListChatInstancesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/chat/instances',
      {},
      listChatInstancesQuery,
    )
  },

  /**
   * @summary 获取 约谈实例 列表。
   * @param [listChatInstancesQuery]
   * @param [chatQuery.idIn]
   * @param [chatQuery.schoolPluginId] 插件ID
   * @param [chatQuery.type] 类型：约谈、咨询
   * @param [chatQuery.expand] 支持 school_plugin
   * @param [options]
   * @return AxiosResponsePromise<ChatInstance[]>
   */

  api<const TQuery extends ListChatInstancesQuery>(
    listChatInstancesQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<ChatInstance, TQuery['expand']>[]> {
    return axios(
      chatApi$listChatInstances.config(listChatInstancesQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new ChatInstance(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends ListChatInstancesQuery,
    TSelected = Expand<ChatInstance, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<ChatInstance, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<ChatInstance, TQuery['expand']>[], TSelected>(
      chatApi$listChatInstances.api,
      'Chat.listChatInstances',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
chatApi$listChatInstances.api._name_ = `Chat.listChatInstances`

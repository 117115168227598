import { AchievedScore as Base } from '../achieved-score'
import { OwnerStatusEnum } from '../owner-status-enum'

export type { AchievedScoreInterface } from '../achieved-score'

/**
 * 评价项分数
 */
export class AchievedScore extends Base {
  /**
   * 学生是否已离开群组
   *
   * @returns 是否
   */
  get isOwnerLeaved() {
    return this.ownerStatus === OwnerStatusEnum.Leaved
  }

  /**
   * 学生已得星星数量
   *
   * @returns 数量
   */
  get finalGainedStar() {
    if (this.gainedStar) return this.gainedStar
    if (this.gainedScore) return +this.gainedScore
    return 0
  }
}

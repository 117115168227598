/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { GroupMember, GroupMemberInterface } from './group-member'
import type { QueryGroupsMembersQuery } from './query-groups-members-query'

const buildConfig = createConfigBuilder('chalk')

export const groupApi$queryGroupsMembers = {
  config(
    queryGroupsMembersQuery?: QueryGroupsMembersQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/group/groups/members',
      {},
      queryGroupsMembersQuery,
    )
  },

  /**
   * @summary 跨多个群组查询成员
   * @param [queryGroupsMembersQuery]
   * @param [groupQuery.groupIdIn] 群组ID (多个逗号分割)
   * @param [groupQuery.groupBizIdIn] 群组业务ID (多个逗号分割)
   * @param [groupQuery.groupType] 群组类型
   * @param [groupQuery.memberType] member type
   * @param [groupQuery.memberStatus] member status
   * @param [groupQuery.memberIdIn] member ids
   * @param [groupQuery.identity] reflection's identity : name、pinyin、usin
   * @param [groupQuery.name] reflection's name
   * @param [groupQuery.nameLike]
   * @param [groupQuery.role]
   * @param [groupQuery.usin] reflection's usin
   * @param [groupQuery.usinLike]
   * @param [groupQuery.statusIn] 人员状态 normal 正常
   * @param [groupQuery.roleIn] 人员角色
   * @param [groupQuery.account] reflection's account
   * @param [groupQuery.teamIdIn] 所属小组的ids
   * @param [groupQuery.sort] 排序，支持字段：
   * - is_in_team: 是否已分组
   * - member_type_id: 成员类型
   * - reflection.usin: 学号
   * - reflection.pinyin: 姓名拼音
   * - team.weight: 小组排序(权重)
   * - team.id: 小组id
   * @param [groupQuery.expand] expand
   * - group: 所属群组
   * - team: 所属小组
   * - teams: 所属多个小组
   * - reflection : 成员基本信息
   * - class_member_type_info : 所属课程班内的成员类型信息
   * @param [groupQuery.fields]
   * @param [groupQuery.classTypeSettingId] 当前用户的课程班成员类型id
   * @param [groupQuery.distinct] 过滤重复结果
   * @param [groupQuery.needSort] 有些场景不需要排序，简单些就行，兼容之前模式默认为 true
   * @param [groupQuery.ownerId] 默认查自己所在的群组，有权限的管理员可查他人
   * @param [groupQuery.ownerMemberType] 自己在群组中的角色类型
   * @param [groupQuery.ownerMemberStatus] 自己在群组中的状态
   * @param [groupQuery.policy]
   * @param [groupQuery.paginated] 是否分页，默认 1
   * @param [groupQuery.page] 显示的数据页，默认 1
   * @param [groupQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<GroupMember[]>
   */

  api<const TQuery extends QueryGroupsMembersQuery>(
    queryGroupsMembersQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<GroupMember, TQuery['expand']>[]> {
    return axios(
      groupApi$queryGroupsMembers.config(queryGroupsMembersQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new GroupMember(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryGroupsMembersQuery,
    TSelected = Expand<GroupMember, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<GroupMember, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<GroupMember, TQuery['expand']>[], TSelected>(
      groupApi$queryGroupsMembers.api,
      'Group.queryGroupsMembers',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
groupApi$queryGroupsMembers.api._name_ = `Group.queryGroupsMembers`

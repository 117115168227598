import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Dorm,
  getLabel: () => $t('宿舍'),
  labelStyle: calculateLabelStyle('#F7E9FA', '#9E37B4'),
  messages: ['archive_student'],
})

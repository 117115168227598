export interface ScoreRelativeInterface {
  taskId?: number
  attendanceTimeId?: number
  attendanceRecordId?: number
  /**
   * 迁移分数的来源评价
   */
  migratedFromAssessmentId?: number
  /**
   * 分数来源（目前有素养评价转换 acc ）
   */
  source?: string
  /**
   * 来源关联 ID (来源于素养评价时，该 ID 为 converter_plan.id )
   */
  sourceId?: number
  /**
   * 转换时间
   */
  convertedAt?: string
  /**
   * 关联学案 id(与下述目录id同时传)
   */
  handoutId?: number
  /**
   * 关联学案目录 id
   */
  outlineId?: number
  /**
   * 审批流名称（德育审批使用）
   */
  workflowName?: string
  /**
   * 关联审批单（德育审批使用）
   */
  flowId?: number
  /**
   * 加减分展示数量
   */
  scoreNum?: number
}

export class ScoreRelative implements ScoreRelativeInterface {
  taskId?: number
  attendanceTimeId?: number
  attendanceRecordId?: number
  /**
   * 迁移分数的来源评价
   */
  migratedFromAssessmentId?: number
  /**
   * 分数来源（目前有素养评价转换 acc ）
   */
  source?: string
  /**
   * 来源关联 ID (来源于素养评价时，该 ID 为 converter_plan.id )
   */
  sourceId?: number
  /**
   * 转换时间
   */
  convertedAt?: string
  /**
   * 关联学案 id(与下述目录id同时传)
   */
  handoutId?: number
  /**
   * 关联学案目录 id
   */
  outlineId?: number
  /**
   * 审批流名称（德育审批使用）
   */
  workflowName?: string
  /**
   * 关联审批单（德育审批使用）
   */
  flowId?: number
  /**
   * 加减分展示数量
   */
  scoreNum?: number

  constructor(props: ScoreRelativeInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      taskId: _taskId,
      attendanceTimeId: _attendanceTimeId,
      attendanceRecordId: _attendanceRecordId,
      migratedFromAssessmentId: _migratedFromAssessmentId,
      source: _source,
      sourceId: _sourceId,
      convertedAt: _convertedAt,
      handoutId: _handoutId,
      outlineId: _outlineId,
      workflowName: _workflowName,
      flowId: _flowId,
      scoreNum: _scoreNum,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.taskId = _taskId
    this.attendanceTimeId = _attendanceTimeId
    this.attendanceRecordId = _attendanceRecordId
    this.migratedFromAssessmentId = _migratedFromAssessmentId
    this.source = _source
    this.sourceId = _sourceId
    this.convertedAt = _convertedAt
    this.handoutId = _handoutId
    this.outlineId = _outlineId
    this.workflowName = _workflowName
    this.flowId = _flowId
    this.scoreNum = _scoreNum
  }

  static propKeys = [
    'taskId',
    'attendanceTimeId',
    'attendanceRecordId',
    'migratedFromAssessmentId',
    'source',
    'sourceId',
    'convertedAt',
    'handoutId',
    'outlineId',
    'workflowName',
    'flowId',
    'scoreNum',
  ]
}

import { FormTemplateFieldTypeEnum } from './form-template-field-type-enum'
import { DefaultAnswer, DefaultAnswerInterface } from './default-answer'
import {
  FormTemplateFieldContainer,
  FormTemplateFieldContainerInterface,
} from './form-template-field-container'
import {
  FormTemplateFieldRule,
  FormTemplateFieldRuleInterface,
} from './form-template-field-rule'
import { FormTemplateFieldOptionSerialNumberEnum } from './form-template-field-option-serial-number-enum'
import {
  FormTemplateTableField,
  FormTemplateTableFieldInterface,
} from './form-template-table-field'

export interface FormTemplateFieldInterface {
  type: FormTemplateFieldTypeEnum
  name: string
  sort: number
  label: string
  description: string
  targetedBy: string
  required: boolean
  /**
   * 是否只读
   */
  readonly: boolean
  /**
   * 默认值。当为只读模式时，答案的值必须等于默认值
   */
  defaultAnswer?: DefaultAnswerInterface | null
  container?: FormTemplateFieldContainerInterface | null
  rules?: FormTemplateFieldRuleInterface | null
  /**
   * 是否停用
   */
  inactive?: boolean | null
  /**
   * 是否允许下载附件
   */
  allowDownloadAttachments?: boolean
  /**
   * 选项序号
   */
  optionSerialNumber?: FormTemplateFieldOptionSerialNumberEnum
  /**
   * 表格中的列字段
   */
  tableFields?: FormTemplateTableFieldInterface[]
  /**
   * 主键 ID
   */
  id: number
}

export class FormTemplateField implements FormTemplateFieldInterface {
  type: FormTemplateFieldTypeEnum
  name: string
  sort: number
  label: string
  description: string
  targetedBy: string
  required: boolean
  /**
   * 是否只读
   */
  readonly: boolean
  /**
   * 默认值。当为只读模式时，答案的值必须等于默认值
   */
  defaultAnswer?: DefaultAnswer | null
  container?: FormTemplateFieldContainer | null
  rules?: FormTemplateFieldRule | null
  /**
   * 是否停用
   */
  inactive?: boolean | null
  /**
   * 是否允许下载附件
   */
  allowDownloadAttachments?: boolean
  /**
   * 选项序号
   */
  optionSerialNumber?: FormTemplateFieldOptionSerialNumberEnum
  /**
   * 表格中的列字段
   */
  tableFields?: FormTemplateTableField[]
  /**
   * 主键 ID
   */
  id: number

  constructor(props: FormTemplateFieldInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      type: _type,
      name: _name,
      sort: _sort,
      label: _label,
      description: _description,
      targetedBy: _targetedBy,
      required: _required,
      readonly: _readonly,
      defaultAnswer: _defaultAnswer,
      container: _container,
      rules: _rules,
      inactive: _inactive,
      allowDownloadAttachments: _allowDownloadAttachments,
      optionSerialNumber: _optionSerialNumber,
      tableFields: _tableFields,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.type = _type
    this.name = _name
    this.sort = _sort
    this.label = _label
    this.description = _description
    this.targetedBy = _targetedBy
    this.required = _required
    this.readonly = _readonly
    this.defaultAnswer = _defaultAnswer
      ? new DefaultAnswer(_defaultAnswer)
      : _defaultAnswer
    this.container = _container
      ? new FormTemplateFieldContainer(_container)
      : _container
    this.rules = _rules ? new FormTemplateFieldRule(_rules) : _rules
    this.inactive = _inactive
    this.allowDownloadAttachments = _allowDownloadAttachments
    this.optionSerialNumber = _optionSerialNumber
    this.tableFields = _tableFields
      ? _tableFields.map(i => new FormTemplateTableField(i))
      : _tableFields
    this.id = _id
  }

  static propKeys = [
    'type',
    'name',
    'sort',
    'label',
    'description',
    'targetedBy',
    'required',
    'readonly',
    'defaultAnswer',
    'container',
    'rules',
    'inactive',
    'allowDownloadAttachments',
    'optionSerialNumber',
    'tableFields',
    'id',
  ]
}

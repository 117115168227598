export interface IotRuleInterface {
  /**
   * 规则生效的开始时间
   */
  start?: number | null
  /**
   * 规则生效的结束时间, 如果为 null，表示最后一条记录，在 start 之后的所有刷卡均标记为相应的 result 值
   */
  end?: number | null
  /**
   * 该条规则生效时的考勤结果
   */
  result?: string
}

export class IotRule implements IotRuleInterface {
  /**
   * 规则生效的开始时间
   */
  start?: number | null
  /**
   * 规则生效的结束时间, 如果为 null，表示最后一条记录，在 start 之后的所有刷卡均标记为相应的 result 值
   */
  end?: number | null
  /**
   * 该条规则生效时的考勤结果
   */
  result?: string

  constructor(props: IotRuleInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      start: _start,
      end: _end,
      result: _result,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.start = _start
    this.end = _end
    this.result = _result
  }

  static propKeys = ['start', 'end', 'result']
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { IgnoreTodoReq, IgnoreTodoReqInterface } from './ignore-todo-req'

const buildConfig = createConfigBuilder('chalk')

export const todoApi$ignoreTodos = {
  config(
    ignoreTodoReq: IgnoreTodoReqInterface,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'put',
      '/todo/todos/ignore',
      {},
      null,
      ignoreTodoReq,
      {
        format: 'json',
        isArray: false,
        defaults: {
          ignored: (v: any) => v || false,
        },
      },
    )
  },

  /**
   * @summary 忽略某一待办
   * @param ignoreTodoReq
   * @param [options]
   * @return AxiosResponsePromise<null>
   */

  api(
    ignoreTodoReq: IgnoreTodoReqInterface,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<null> {
    return axios(todoApi$ignoreTodos.config(ignoreTodoReq, options)) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      (payload: IgnoreTodoReqInterface) => todoApi$ignoreTodos.api(payload),
      mutationOptions,
    )
  },
}

// @ts-ignore
todoApi$ignoreTodos.api._name_ = `Todo.ignoreTodos`

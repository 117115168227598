/**
 * @file Native 与 Web 公用变量
 * WARNING
 * 不应包含其中一方无法使用的值，比如 px。
 */

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const BackgroundColor = {
  Theme: '#477CFF',
  LightTheme: '#ECF4FF',
  DisableTheme: '#C3DAFF',
  DeepTheme: '#2C57E9',
  PressTheme: '#3958C2',
  Input: '#F6F8FA',
  Base: '#f3f4f8',
  Default: '#F0F1F5',
  Glass: '#F9FAFC',
  White: '#ffffff',
  Alert: 'rgba(50, 56, 66, .06)',
  Modal: 'rgba(50, 56, 66, .3)',
  Backdrop: 'rgba(50, 56, 66, .6)',
  LightYellow: '#FFF5E3',
  DeepYellow: '#E79900',
  LightRed: '#FFEBEF',
  DeepRed: '#D84244',
  LightGreen: '#E3F8F3',
  DeepGreen: '#009F78',
  LightGray: '#E4E6EB',
  Transparent: 'transparent',
  DeepGray: '#414751',
}

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const BorderColor = {
  Deep: 'rgba(6, 12, 25, 0.85)',
  Light: 'rgba(50, 56, 66, .06)',
  Regular: 'rgba(50, 56, 66, .08)',
  Box: '#D3D5D8',
}

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const StatusColor = {
  Green: '#16C89E',
  Blue: '#477CFF',
  Gray: 'rgba(6, 12, 25, 0.25)',
  Red: '#E03437',
  Yellow: '#FCA700',
}

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const ZIndex = {
  Background: -1,
  Zero: 0,
  Regular: 1,
  Popup: 11,
  FullScreen: 111,
  AntModal: 1000,
  Modal: 1111,

  // 操作确认框、小对话框等，可能从 modal 上弹出
  Prompt: 1200,
}

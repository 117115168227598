import { ShareRelation, ShareRelationInterface } from './share-relation'
import {
  ClassTypeWithOnlineC,
  ClassTypeWithOnlineCInterface,
} from './class-type-with-online-c'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import { Organization, OrganizationInterface } from './organization'

export interface ShareInterface {
  /**
   * 可查看被分享学校是否加入课程班和开放课等数据
   */
  shareRelations?: ShareRelationInterface[]
  /**
   * 班级的 expand, 班级字段 class.xxx, 线上课字段 class.online_class.xxx
   */
  class?: ClassTypeWithOnlineCInterface | null
  /**
   * 分享的班级 id
   */
  classId: number
  /**
   * 分享人, 分享校是 school_id
   */
  initiatorId: number
  initiator?: SecuredReflectionInterface | null
  /**
   * 共享范围 结构 [organization_id => [school_id1, school_id2]]
   */
  scopes: object
  /**
   * expand organizations
   * 共享范围内的所有组织
   */
  organizations?: OrganizationInterface[]
  /**
   * 共享范围内的所有学校
   */
  receiveSchoolIds?: number[]
  /**
   * 已经加入课程班的学校数量
   */
  joinedPublicSchoolNum: number
  /**
   * 已经加入开放课的学校数量
   */
  joinedRecordedSchoolNum: number
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Share implements ShareInterface {
  /**
   * 可查看被分享学校是否加入课程班和开放课等数据
   */
  shareRelations?: ShareRelation[]
  /**
   * 班级的 expand, 班级字段 class.xxx, 线上课字段 class.online_class.xxx
   */
  class?: ClassTypeWithOnlineC | null
  /**
   * 分享的班级 id
   */
  classId: number
  /**
   * 分享人, 分享校是 school_id
   */
  initiatorId: number
  initiator?: SecuredReflection | null
  /**
   * 共享范围 结构 [organization_id => [school_id1, school_id2]]
   */
  scopes: object
  /**
   * expand organizations
   * 共享范围内的所有组织
   */
  organizations?: Organization[]
  /**
   * 共享范围内的所有学校
   */
  receiveSchoolIds?: number[]
  /**
   * 已经加入课程班的学校数量
   */
  joinedPublicSchoolNum: number
  /**
   * 已经加入开放课的学校数量
   */
  joinedRecordedSchoolNum: number
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: ShareInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      shareRelations: _shareRelations,
      class: _class,
      classId: _classId,
      initiatorId: _initiatorId,
      initiator: _initiator,
      scopes: _scopes,
      organizations: _organizations,
      receiveSchoolIds: _receiveSchoolIds,
      joinedPublicSchoolNum: _joinedPublicSchoolNum,
      joinedRecordedSchoolNum: _joinedRecordedSchoolNum,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.shareRelations = _shareRelations
      ? _shareRelations.map(i => new ShareRelation(i))
      : _shareRelations
    this.class = _class ? new ClassTypeWithOnlineC(_class) : _class
    this.classId = _classId
    this.initiatorId = _initiatorId
    this.initiator = _initiator ? new SecuredReflection(_initiator) : _initiator
    this.scopes = _scopes
    this.organizations = _organizations
      ? _organizations.map(i => new Organization(i))
      : _organizations
    this.receiveSchoolIds = _receiveSchoolIds
    this.joinedPublicSchoolNum = _joinedPublicSchoolNum
    this.joinedRecordedSchoolNum = _joinedRecordedSchoolNum
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'shareRelations',
    'class',
    'classId',
    'initiatorId',
    'initiator',
    'scopes',
    'organizations',
    'receiveSchoolIds',
    'joinedPublicSchoolNum',
    'joinedRecordedSchoolNum',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

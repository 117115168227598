import { registerMessageNavigator } from 'packages/features/messages/utils'

registerMessageNavigator(
  ['notification.received', 'notification.discussion_message_replied'],
  msg => ({
    modalName: 'InboxNoticeModal',
    options: {
      id: (msg.attributes as any)?.notificationId,
      idType: 'conversationId',
    },
  }),
)

registerMessageNavigator(['notification.withdrawn'], msg => ({
  modalName: 'OutboxNoticeModal',
  options: {
    id: (msg.attributes as any)?.notificationId,
  },
}))

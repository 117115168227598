import { ScopeParam, ScopeParamInterface } from './scope-param'

export interface OpenScopeInterface {
  teacher: ScopeParamInterface
  student: ScopeParamInterface
  guardian: ScopeParamInterface
  staff: ScopeParamInterface
}

export class OpenScope implements OpenScopeInterface {
  teacher: ScopeParam
  student: ScopeParam
  guardian: ScopeParam
  staff: ScopeParam

  constructor(props: OpenScopeInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      teacher: _teacher,
      student: _student,
      guardian: _guardian,
      staff: _staff,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.teacher = _teacher ? new ScopeParam(_teacher) : _teacher
    this.student = _student ? new ScopeParam(_student) : _student
    this.guardian = _guardian ? new ScopeParam(_guardian) : _guardian
    this.staff = _staff ? new ScopeParam(_staff) : _staff
  }

  static propKeys = ['teacher', 'student', 'guardian', 'staff']
}

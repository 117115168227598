export interface NetdiskPermissionOwnerInterface {
  /**
   * 用户姓名｜组织架构名称
   */
  name: string
  /**
   * 用户学工号
   */
  usin?: string | null
  /**
   * 用户角色
   */
  role?: string | null
  /**
   * 用户头像
   */
  avatar?: string | null
  /**
   * 组织架构关联群组ID
   */
  relatedGroupId?: number | null
  /**
   * 组织架构关联群组类型
   */
  relatedGroupType?: string | null
  /**
   * 主键 ID
   */
  id: number
}

export class NetdiskPermissionOwner implements NetdiskPermissionOwnerInterface {
  /**
   * 用户姓名｜组织架构名称
   */
  name: string
  /**
   * 用户学工号
   */
  usin?: string | null
  /**
   * 用户角色
   */
  role?: string | null
  /**
   * 用户头像
   */
  avatar?: string | null
  /**
   * 组织架构关联群组ID
   */
  relatedGroupId?: number | null
  /**
   * 组织架构关联群组类型
   */
  relatedGroupType?: string | null
  /**
   * 主键 ID
   */
  id: number

  constructor(props: NetdiskPermissionOwnerInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      usin: _usin,
      role: _role,
      avatar: _avatar,
      relatedGroupId: _relatedGroupId,
      relatedGroupType: _relatedGroupType,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.usin = _usin
    this.role = _role
    this.avatar = _avatar
    this.relatedGroupId = _relatedGroupId
    this.relatedGroupType = _relatedGroupType
    this.id = _id
  }

  static propKeys = [
    'name',
    'usin',
    'role',
    'avatar',
    'relatedGroupId',
    'relatedGroupType',
    'id',
  ]
}

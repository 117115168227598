import { CourseMemberTypeEnum } from './course-member-type-enum'
import {
  CourseMemberMetadata,
  CourseMemberMetadataInterface,
} from './course-member-metadata'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'

export interface CourseMemberInterface {
  schoolId: number
  courseId: number
  memberId: number
  type: CourseMemberTypeEnum
  metadata?: CourseMemberMetadataInterface
  reflection?: SecuredReflectionInterface | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class CourseMember implements CourseMemberInterface {
  schoolId: number
  courseId: number
  memberId: number
  type: CourseMemberTypeEnum
  metadata?: CourseMemberMetadata
  reflection?: SecuredReflection | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: CourseMemberInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      courseId: _courseId,
      memberId: _memberId,
      type: _type,
      metadata: _metadata,
      reflection: _reflection,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.courseId = _courseId
    this.memberId = _memberId
    this.type = _type
    this.metadata = _metadata ? new CourseMemberMetadata(_metadata) : _metadata
    this.reflection = _reflection
      ? new SecuredReflection(_reflection)
      : _reflection
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'schoolId',
    'courseId',
    'memberId',
    'type',
    'metadata',
    'reflection',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

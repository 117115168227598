/**
 * @file 听评课-navigator 注册
 */

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator(
  'class_review.send_review_arrangement',
  ({ attributes }) => {
    const id = (
      attributes as
        | {
            classReviewId: number
          }
        | undefined
    )?.classReviewId

    return {
      modalName: 'ClassReviewArrangementMessageDetail',
      options: { id },
    }
  },
)

registerMessageNavigator('class_review.send_report', () => null)
registerMessageNavigator('class_review.invalid', () => null)
registerMessageNavigator('class_review.un_completed', ({ attributes }) => {
  const id = (
    attributes as
      | {
          classReviewId: number
        }
      | undefined
  )?.classReviewId

  return {
    modalName: 'Plugin.EditClassReviewFormModal',
    options: {
      reviewId: id,
    },
  }
})

import { ExamineeRuleSortEnum } from './examinee-rule-sort-enum'
import { ScoreSort, ScoreSortInterface } from './score-sort'

export interface ExamineeRuleInterface {
  sort: ExamineeRuleSortEnum
  scoreSortFileUrl?: string
  scoreSortScores?: ScoreSortInterface[]
}

export class ExamineeRule implements ExamineeRuleInterface {
  sort: ExamineeRuleSortEnum
  scoreSortFileUrl?: string
  scoreSortScores?: ScoreSort[]

  constructor(props: ExamineeRuleInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      sort: _sort,
      scoreSortFileUrl: _scoreSortFileUrl,
      scoreSortScores: _scoreSortScores,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.sort = _sort
    this.scoreSortFileUrl = _scoreSortFileUrl
    this.scoreSortScores = _scoreSortScores
      ? _scoreSortScores.map(i => new ScoreSort(i))
      : _scoreSortScores
  }

  static propKeys = ['sort', 'scoreSortFileUrl', 'scoreSortScores']
}

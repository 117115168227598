import { faArrowAltUp } from '@fortawesome/pro-solid-svg-icons'
import { Icon, Modal } from '@seiue/ui'
import { useAtom } from 'jotai'
import styled, { keyframes } from 'styled-components'

import { useIsBJDYSY } from 'packages/features/schools/hooks'
import { $t } from 'packages/locale'
import { RoleEnum } from 'packages/sdks-next/chalk'

import { storeAtom } from '@/store'
import { RootInterrupt } from '@/uikit'

const useIsSystemUpdating = () => {
  const [atomStore] = useAtom(storeAtom)
  const reflection = atomStore.session.currentReflection

  // 是否为北京第一实验
  const isBJDYSY = useIsBJDYSY()

  // 北京第一实验不让家长使用系统
  return isBJDYSY && reflection?.role && reflection.role === RoleEnum.Guardian
}

/**
 * 全局的，阻拦用户使用系统的弹窗
 *
 * @returns React.ReactElement
 */
export const BlockLayoutModal = () => {
  const systemUpdating = useIsSystemUpdating()

  let blockTitle = ''
  let blockText = ''
  const blockIcon = faArrowAltUp

  if (systemUpdating) {
    blockTitle = $t('系统升级中...')
    blockText = $t('系统正在升级中，敬请期待')
  }

  const canShow = RootInterrupt.useCanShow('blockLayout', !blockTitle)

  if (!canShow) return null

  return (
    <Modal visible={true} title={''} closable={false} header={null} width={320}>
      <BlockContent>
        <BlockIconWrapper>
          <BlockIconBackground />
          <Icon icon={blockIcon} size={24} color="#fff" style={{ zIndex: 1 }} />
        </BlockIconWrapper>
        <BlockTitle>{blockTitle}</BlockTitle>
        <BlockText>{blockText}</BlockText>
      </BlockContent>
    </Modal>
  )
}

const BlockContent = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BlockIconWrapper = styled.div`
  height: 44px;
  width: 44px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const rotateAnimation = keyframes`
  100% {
    transform: rotate(360deg)
  }
`

const BlockIconBackground = styled.div`
  background: linear-gradient(
    180deg,
    #15b8b1 0%,
    rgba(21, 184, 177, 0.24) 100%
  );
  z-index: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${rotateAnimation} 4s linear infinite;
`

const BlockTitle = styled.div`
  font-size: 16px;
  margin-top: 19px;
  font-weight: bold;
  color: ${props => props.theme.text._1};
  text-align: center;
`

const BlockText = styled.div`
  font-size: 13px;
  color: ${props => props.theme.text._2};
  margin-top: 12px;
`

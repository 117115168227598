/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { User, UserInterface } from './user'
import type { InfoQuery } from './info-query'

const buildConfig = createConfigBuilder('chalk')

export const oAuthApi$info = {
  config(
    infoQuery?: InfoQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(options, 'get', '/oauth/info', {}, infoQuery)
  },

  /**
   * @summary 获取当前用户信息
   * @param [infoQuery]
   * @param [oAuthQuery.expand] 支持 reflections
   * @param [options]
   * @return AxiosResponsePromise<User>
   */

  api<const TQuery extends InfoQuery>(
    infoQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<User, TQuery['expand']>> {
    return axios(oAuthApi$info.config(infoQuery, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new User(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends InfoQuery,
    TSelected = Expand<User, TQuery['expand']>,
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<User, TQuery['expand']>,
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<User, TQuery['expand']>, TSelected>(
      oAuthApi$info.api,
      'OAuth.info',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
oAuthApi$info.api._name_ = `OAuth.info`

export interface PlanRuleInterface {
  /**
   * 分数类型时，必填。复合类型时，必填
   */
  score?: string
  /**
   * 排名类型时，必填。开启 percent_limit_enabled 比例限制时，必填。复合类型时，必填
   */
  percent?: string
  /**
   * 等级类型时，必填。复合类型时，必填
   */
  level?: string
  /**
   * 开启 GPA 时，必填
   */
  gpa?: string
  /**
   * 表示规则是否禁用，选填（默认为 false）。禁用的规则只会进行部分字段的数据验证
   */
  disabled?: boolean
  /**
   * 顺序
   */
  order?: number
  /**
   * 开启特殊评价规则时，必填
   */
  shouldGainCredit?: boolean
  /**
   * 开启特殊评价规则时，必填
   * - 如是等级类型 其值为 level eg: 'C'
   * - 如是分数类型 其值为 level+score eg: 'C78'
   */
  ruleKey?: string
}

export class PlanRule implements PlanRuleInterface {
  /**
   * 分数类型时，必填。复合类型时，必填
   */
  score?: string
  /**
   * 排名类型时，必填。开启 percent_limit_enabled 比例限制时，必填。复合类型时，必填
   */
  percent?: string
  /**
   * 等级类型时，必填。复合类型时，必填
   */
  level?: string
  /**
   * 开启 GPA 时，必填
   */
  gpa?: string
  /**
   * 表示规则是否禁用，选填（默认为 false）。禁用的规则只会进行部分字段的数据验证
   */
  disabled?: boolean
  /**
   * 顺序
   */
  order?: number
  /**
   * 开启特殊评价规则时，必填
   */
  shouldGainCredit?: boolean
  /**
   * 开启特殊评价规则时，必填
   * - 如是等级类型 其值为 level eg: 'C'
   * - 如是分数类型 其值为 level+score eg: 'C78'
   */
  ruleKey?: string

  constructor(props: PlanRuleInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      score: _score,
      percent: _percent,
      level: _level,
      gpa: _gpa,
      disabled: _disabled,
      order: _order,
      shouldGainCredit: _shouldGainCredit,
      ruleKey: _ruleKey,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.score = _score
    this.percent = _percent
    this.level = _level
    this.gpa = _gpa
    this.disabled = _disabled
    this.order = _order
    this.shouldGainCredit = _shouldGainCredit
    this.ruleKey = _ruleKey
  }

  static propKeys = [
    'score',
    'percent',
    'level',
    'gpa',
    'disabled',
    'order',
    'shouldGainCredit',
    'ruleKey',
  ]
}

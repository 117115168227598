import { createEnumHelper } from '@seiue/util'

import { $t } from 'packages/locale'
import { Attachment } from 'packages/sdks-next/chalk'
import { SecuredReflection } from 'packages/sdks-next/nuwa'

export const PLUGIN_ACADEMIC_ACHIEVEMENT = 'academic-achievement'

export type CollectTypeAndCount = { key: string; value: number }[]
export type CollectLevel = { name: string; value: number }[]
export type MergedTypesAndCount = {
  label: string
  count?: number
  value: string
}[]
export type OptionObject = {
  [key: string]: string
}
export type AttachmentConfig = {
  type: string
  name: string
}
export type EvidencesData = {
  name: string
  type: string
  evidences: Evidence[]
}[]
export type Mime = {
  mime: string
}
export type Evidence = Mime & Attachment
export type Evidences = Evidence[]
export enum TextName {
  Pass = 'pass',
  Review = 'review',
  Reject = 'reject',
  InnovativeCourses = 'innovativeCourses',
}
export enum Status {
  Editing = 'option_ktlipxox',
  Review = 'option_jovcmshr',
  Reject = 'option_csixqand',
  Pass = 'option_tkwvmlot',
}
export const NameToLabelHelper = createEnumHelper(() => ({
  [TextName.Pass]: $t('已通过'),
  [TextName.Review]: $t('待审核'),
  [TextName.Reject]: $t('已驳回'),
  [TextName.InnovativeCourses]: $t('创新课程'),
}))
export type AcademicAchievementCardItem = {
  evidences: Evidences | null
  gainedDate: string
  grantedBy: string
  id: number
  level: string
  name: string
  owner: SecuredReflection
  usin: string
  ownerId: number
  type: string
  status: string
}
export interface AcademicAchievementsQuery {
  paginated?: number
  page?: number
  perPage?: number
  name?: string
  beginDate?: string | undefined
  overDate?: string | undefined
  type?: string | undefined
}

import {
  IconDefinition,
  IconPrefix,
  Transform,
  IconProp,
  FlipProp,
  SizeProp,
  PullProp,
  RotateProp,
  FaSymbol,
} from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isString, isArray, omit } from '@seiue/util'
import classNames from 'classnames'
import React, { CSSProperties, DOMAttributes } from 'react'
import styled from 'styled-components'

type BackwardCompatibleOmit<T, K extends keyof any> = Pick<
  T,
  Exclude<keyof T, K>
>

export type SeIconName = string

export type SeIconPrefix = IconPrefix | 'si'

export type SeIconLookup = {
  prefix: SeIconPrefix
  iconName: SeIconName
}

export type SeIconProp = SeIconLookup

export interface SeIconProps
  extends BackwardCompatibleOmit<DOMAttributes<Element>, 'children' | 'mask'> {
  icon: SeIconProp
  mask?: IconProp
  className?: string
  color?: string
  hoverColor?: string
  spin?: boolean
  pulse?: boolean
  border?: boolean
  fixedWidth?: boolean
  inverse?: boolean
  listItem?: boolean
  flip?: FlipProp
  size?: SizeProp
  pull?: PullProp
  rotation?: RotateProp
  transform?: string | Transform
  symbol?: FaSymbol
  style?: CSSProperties
  tabIndex?: number
  title?: string
  reverse?: boolean
}

/**
 * @deprecated 请用 import { Icon } from '@seiue/ui' 替代
 */
export const SeIcon = (props: SeIconProps) => {
  let { reverse } = props

  // 自定义 Icon 默认反转
  if (reverse === undefined) {
    const { icon } = props

    if (isString(icon)) {
      reverse = icon.indexOf('si') > -1
    } else if (isArray(icon)) {
      reverse = icon[0] === 'si'
    } else {
      reverse = icon.prefix === 'si'
    }
  }

  const args = omit(props, ['reverse', 'onClick'])

  if (reverse) {
    args.style = {
      ...args.style,
      transform: 'rotate(180deg)',
    }
  }

  return (
    <i
      className={classNames(props.className, 'anticon')}
      style={{
        verticalAlign: 0,
        ...args.style,
      }}
      /**
       * DropdownButton 的触发依赖一些事件
       * https://github.com/react-component/trigger/blob/master/src/index.tsx#L38
       *
       * 接收以下事件能够让 SeIcon 作为直接子元素时也能正常工作
       */
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      data-test-id={`${args.icon.prefix}-${args.icon.iconName}`}
    >
      {/* style 和 className 作用于父级 */}
      <Icon
        {...args}
        icon={args.icon as IconDefinition}
        style={{}}
        className=""
      />
    </i>
  )
}

const Icon = styled(FontAwesomeIcon)<{ hoverColor?: string }>`
  &:hover {
    ${props => props.hoverColor && `color:${props.hoverColor};`}
  }
`

/**
 * @file 成绩反思消息
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

import icon from './grade.png'

registerDomainMessage({
  type: MessageDomainEnum.ClassAssessment,
  getLabel: () => $t('成绩'),
  icon,
  labelStyle: calculateLabelStyle('#F7E9FA', '#9E37B4'),
  messages: [
    'afterthought_deleted_for_student',
    'afterthought_message_commented_for_student',
    'afterthought_message_received_for_student',
    'afterthought_message_deleted_for_student',
    'afterthought_uncompleted_notice_for_student',
    'afterthought_published_for_teacher',
    'afterthought_message_received_for_teacher',
    'afterthought_message_deleted_for_teacher',
  ],
})

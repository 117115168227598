import { faAngleUp } from '@fortawesome/pro-regular-svg-icons'
import { Tooltip } from '@seiue/ui'
import { BackTop } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { SeIcon } from 'packages/components/Icon'
import { $t } from 'packages/locale'
import { Color, BorderRadius } from 'packages/themed'

export const BackToTop: React.FC<{
  bottom?: number
  zIndex?: number
  scrollTarget: HTMLElement | null
}> = ({ scrollTarget, bottom, zIndex }) => {
  const button = (
    <Tooltip mode="dark" title={$t('返回顶部')} placement="left">
      <Button>
        <SeIcon icon={faAngleUp} />
      </Button>
    </Tooltip>
  )

  const visibilityHeight = scrollTarget
    ? scrollTarget.offsetHeight * 2
    : undefined

  return scrollTarget ? (
    <BackTop
      style={{
        zIndex: zIndex ?? 200,
        right: '24px',
        bottom: `${bottom || 32}px`,
      }}
      target={() => scrollTarget}
      visibilityHeight={visibilityHeight}
    >
      {button}
    </BackTop>
  ) : null
}

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: ${Color.Gray};
  font-size: 24px;
  background-color: ${Color.White};
  border-radius: ${BorderRadius.Default};
  box-shadow: 0 2px 6px 0 rgba(50, 56, 66, 0.08),
    0 4px 8px 0 rgba(50, 56, 66, 0.04);
  cursor: pointer;
  &:hover {
    color: ${Color.DeepGray};
  }
`

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Exam, ExamInterface } from './extends/exam'
import type { LoadExamQuery } from './load-exam-query'

const buildConfig = createConfigBuilder('vnas')

export const examApi$loadExam = {
  config(
    id: number,
    loadExamQuery?: LoadExamQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/exam/exams/{id}',
      {
        id,
      },
      loadExamQuery,
    )
  },

  /**
   * @summary 查看单个考试
   * @param id
   * @param [loadExamQuery]
   * @param [examQuery.expand] - subjects 科目
   * - subjects.subject_relations 科目下的人员
   * - published_scores_total
   * - pending_scores_total
   * - scores_total
   * - type 考试类型
   * - graduates_in
   * - indicator_groups 档位组（不存在时初始化，只在 load 时使用）
   * - subject_groups 自定义科目组（不存在时初始化，只在 load 时使用）
   * @param [options]
   * @return AxiosResponsePromise<Exam>
   */

  api<const TQuery extends LoadExamQuery>(
    id: number,
    loadExamQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Exam, TQuery['expand']>> {
    return axios(examApi$loadExam.config(id, loadExamQuery, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new Exam(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends LoadExamQuery,
    TSelected = Expand<Exam, TQuery['expand']>,
  >(
    {
      id,
      query,
    }: {
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<Exam, TQuery['expand']>,
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Exam, TQuery['expand']>, TSelected>(
      examApi$loadExam.api,
      'Exam.loadExam',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
examApi$loadExam.api._name_ = `Exam.loadExam`

/**
 * @file 路由相关 Hook，仅供 Web 使用
 */

import { assign, parseURLQuery, stringifyURLQuery } from '@seiue/util'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  useHasEnabledParentPluginFunction,
  useHasManageablePluginFunction,
} from 'packages/feature-utils/plugins'
import {
  useHasCurrentPermissionFunction,
  useHasRoleFunction,
} from 'packages/features/roles/hooks'
import { useCurrentReflection } from 'packages/features/sessions/utils/data'

import {
  composeModalUrl,
  encodeModalQuery,
  useDecodeModalQuery,
} from './compose-modal-url'
import {
  MODAL_QUERY_NAMESPACE,
  ModalMode,
  Query,
  RouteModalParamsList,
  RouteNode,
  RouteParams,
} from './types'
import { formatParams, parseParams } from './utils'

/**
 * 返回 modal query
 *
 * @deprecated use useRouteModalParams instead. see: https://www.yuque.com/kovru3/gfdy75/ou11qa
 *
 * @param defaultModalQuery - 默认 modal query
 * @returns modal query
 */
export const useModalQuery = (defaultModalQuery = {}) => {
  const location = useLocation()
  const history = useHistory()
  const decodeModalQuery = useDecodeModalQuery()

  return useMemo(() => {
    const query = parseURLQuery(location.search)
    let modalQuery: Query = {}

    if (query[MODAL_QUERY_NAMESPACE]) {
      modalQuery = assign(defaultModalQuery, {
        ...decodeModalQuery(query[MODAL_QUERY_NAMESPACE] as string),
      })
    }

    const setModalQuery = (newModalQuery: { [key: string]: any }) => {
      const newQuery = {
        ...query,
        [MODAL_QUERY_NAMESPACE]: encodeModalQuery(newModalQuery),
      }

      history.replace({
        search: stringifyURLQuery(newQuery),
      })
    }

    return [modalQuery, setModalQuery] as const
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, decodeModalQuery])
}

/**
 * route modal params
 *
 * @param defaultQuery - 默认参数
 * @returns query
 */
export const useRouteModalParams = <
  TRouteParamsList extends RouteModalParamsList = any,
  TName extends keyof TRouteParamsList = any,
>(
  defaultQuery: Partial<TRouteParamsList[TName]> = {},
): TRouteParamsList[TName] => {
  const location = useLocation()
  const decodeModalQuery = useDecodeModalQuery()

  const modalQuery = useMemo(() => {
    const allQuery = parseURLQuery(location.search)
    let query: Query = {}

    if (allQuery[MODAL_QUERY_NAMESPACE]) {
      query = assign(defaultQuery, {
        ...decodeModalQuery(allQuery[MODAL_QUERY_NAMESPACE] as string),
      })
    }

    return parseParams(query)
  }, [location.search, defaultQuery, decodeModalQuery])

  return modalQuery as TRouteParamsList[TName]
}

/**
 * 获取当前访问的弹窗路由的名称
 *
 * @returns 当前 modal name
 */
export const useCurrentModalName = () => {
  const location = useLocation()
  const currentModalName = parseURLQuery(location.search)['modal'] as string

  return currentModalName
}

/**
 * 全局监听 modal 关闭事件
 *
 * @param targetModalNames - 需要监听的 modal name 数组（每个 name 需要全局唯一）
 * @param onClose - 关闭之后的回调
 */
export const useOnRouteModalClose = (
  targetModalNames: string[],
  onClose: () => void,
) => {
  const currentModalName = useCurrentModalName()

  const [prevModalName, setPrevModalName] = useState(currentModalName)

  useEffect(() => {
    if (prevModalName !== currentModalName) {
      if (
        prevModalName &&
        !currentModalName &&
        targetModalNames.includes(prevModalName)
      ) {
        onClose()
      }

      setPrevModalName(currentModalName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModalName])
}

/**
 * useModal
 *
 * @deprecated use useRouteModalHistory instead. see: https://www.yuque.com/kovru3/gfdy75/ou11qa
 *
 * @param mode - ModalMode
 * @returns 返回
 */
export const useModal = (mode: ModalMode = ModalMode.Push) => {
  const history = useHistory()

  return useCallback(
    (modalName, query?: { [key: string]: any }) => {
      history[mode]({
        search: composeModalUrl(modalName, query, history.location.search),
      })
    },
    [history, mode],
  )
}

/**
 * 路由历史
 *
 * @returns 历史
 */
export const useRouteModalHistory = <
  TRouteParamsList extends RouteModalParamsList,
>() => {
  const history = useHistory()

  return {
    push: <TName extends keyof TRouteParamsList>(
      name: TName,
      query?: TRouteParamsList[TName],
      state?: any,
    ) => {
      history.push({
        search: composeModalUrl(
          name as any,
          query ? formatParams(query as RouteParams) : query,
          history.location.search,
        ),
        state,
      })
    },
    replace: <TName extends keyof TRouteParamsList>(
      name: TName,
      query?: TRouteParamsList[TName],
      state?: any,
    ) => {
      history.replace({
        search: composeModalUrl(
          name as any,
          query ? formatParams(query as RouteParams) : query,
          history.location.search,
        ),
        state,
      })
    },
  }
}

/**
 * 校验过权限的路由
 *
 * @returns 路由
 */
export const useRouteAuthorization = () => {
  const hasPermission = useHasCurrentPermissionFunction()
  const hasRole = useHasRoleFunction()
  const hasEnabledModule = useHasEnabledParentPluginFunction()
  const hasManageablePlugin = useHasManageablePluginFunction()
  const { schoolId } = useCurrentReflection() || {}

  return useCallback(
    ({
      requirePermissions,
      requireManageablePlugins,
      requireRoles,
      requireSchools,
      requireModules,
      hideSchools,
    }: RouteNode): boolean => {
      if (requireRoles) {
        if (!hasRole(requireRoles)) {
          return false
        }
      }

      if (requirePermissions || requireManageablePlugins) {
        if (
          !requirePermissions?.some(permission =>
            hasPermission({ permission, isManager: true }),
          ) &&
          !requireManageablePlugins?.some(plugin => hasManageablePlugin(plugin))
        ) {
          return false
        }
      }

      if (requireSchools) {
        if (!requireSchools.includes(schoolId)) {
          return false
        }
      }

      if (requireModules) {
        if (!requireModules.some(module => hasEnabledModule(module))) {
          return false
        }
      }

      if (hideSchools?.includes(schoolId)) {
        return false
      }

      return true
    },
    [hasEnabledModule, hasPermission, hasManageablePlugin, hasRole, schoolId],
  )
}

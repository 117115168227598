import { QuestTypeEnum } from './quest-type-enum'
import { SubQuest, SubQuestInterface } from './sub-quest'
import { FormTemplate, FormTemplateInterface } from './form-template'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import { BizModuleEnum } from './biz-module-enum'
import { Form, FormInterface } from './form'
import { Relation, RelationInterface } from './relation'

export interface QuestionnaireInterface {
  formTemplateId: number
  /**
   * 标签ID
   */
  tagIds?: number[] | null
  /**
   * 单日/定时问卷
   */
  type: QuestTypeEnum
  /**
   * 填表人范围类型
   */
  scopeType?: string
  /**
   * 填表人ID
   */
  reflectionIds?: number[] | null
  /**
   * 定时问卷开始日期
   */
  startAt?: string | null
  /**
   * 定时问卷开始日期
   */
  endAt?: string | null
  /**
   * 定时问卷发放时间
   */
  cronTime?: string | null
  /**
   * 定时问卷开启的星期 1-7 7表示星期天 1 表示星期一
   */
  cronWeeks?: number[] | null
  /**
   * 定期问卷的子问卷
   */
  periodicChildrenQuests?: SubQuestInterface[]
  /**
   * 表单模板
   */
  formTemplate?: FormTemplateInterface
  /**
   * 标签信息
   */
  tags?: []
  /**
   * 创建人信息
   */
  creater?: SecuredReflectionInterface | null
  /**
   * 是否已填写
   */
  isFilled?: boolean
  /**
   * 是否有作答记录
   */
  hasAnswers?: boolean
  /**
   * 根据角色分组的 指定填写用户 id 集合
   * eg: [] | ['student' => [1,2,4], 'staff' => [3]]
   */
  groupReflectionIds?: object
  /**
   * 业务模块
   */
  bizModule?: BizModuleEnum | null
  /**
   * 业务模块设置
   */
  bizSettings?: object[] | null
  /**
   * 业务模块-学籍管理-学籍表
   */
  studentStatusConfigLabel?: string | null
  /**
   * 登录者当前问卷form信息
   */
  currentForm?: FormInterface | null
  /**
   * 登录者管理问卷的权限关系数据
   */
  currentRelations?: RelationInterface[]
  /**
   * parent id
   */
  parentId?: number
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
}

export class Questionnaire implements QuestionnaireInterface {
  formTemplateId: number
  /**
   * 标签ID
   */
  tagIds?: number[] | null
  /**
   * 单日/定时问卷
   */
  type: QuestTypeEnum
  /**
   * 填表人范围类型
   */
  scopeType?: string
  /**
   * 填表人ID
   */
  reflectionIds?: number[] | null
  /**
   * 定时问卷开始日期
   */
  startAt?: string | null
  /**
   * 定时问卷开始日期
   */
  endAt?: string | null
  /**
   * 定时问卷发放时间
   */
  cronTime?: string | null
  /**
   * 定时问卷开启的星期 1-7 7表示星期天 1 表示星期一
   */
  cronWeeks?: number[] | null
  /**
   * 定期问卷的子问卷
   */
  periodicChildrenQuests?: SubQuest[]
  /**
   * 表单模板
   */
  formTemplate?: FormTemplate
  /**
   * 标签信息
   */
  tags?: []
  /**
   * 创建人信息
   */
  creater?: SecuredReflection | null
  /**
   * 是否已填写
   */
  isFilled?: boolean
  /**
   * 是否有作答记录
   */
  hasAnswers?: boolean
  /**
   * 根据角色分组的 指定填写用户 id 集合
   * eg: [] | ['student' => [1,2,4], 'staff' => [3]]
   */
  groupReflectionIds?: object
  /**
   * 业务模块
   */
  bizModule?: BizModuleEnum | null
  /**
   * 业务模块设置
   */
  bizSettings?: object[] | null
  /**
   * 业务模块-学籍管理-学籍表
   */
  studentStatusConfigLabel?: string | null
  /**
   * 登录者当前问卷form信息
   */
  currentForm?: Form | null
  /**
   * 登录者管理问卷的权限关系数据
   */
  currentRelations?: Relation[]
  /**
   * parent id
   */
  parentId?: number
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: QuestionnaireInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      formTemplateId: _formTemplateId,
      tagIds: _tagIds,
      type: _type,
      scopeType: _scopeType,
      reflectionIds: _reflectionIds,
      startAt: _startAt,
      endAt: _endAt,
      cronTime: _cronTime,
      cronWeeks: _cronWeeks,
      periodicChildrenQuests: _periodicChildrenQuests,
      formTemplate: _formTemplate,
      tags: _tags,
      creater: _creater,
      isFilled: _isFilled,
      hasAnswers: _hasAnswers,
      groupReflectionIds: _groupReflectionIds,
      bizModule: _bizModule,
      bizSettings: _bizSettings,
      studentStatusConfigLabel: _studentStatusConfigLabel,
      currentForm: _currentForm,
      currentRelations: _currentRelations,
      parentId: _parentId,
      id: _id,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.formTemplateId = _formTemplateId
    this.tagIds = _tagIds
    this.type = _type
    this.scopeType = _scopeType
    this.reflectionIds = _reflectionIds
    this.startAt = _startAt
    this.endAt = _endAt
    this.cronTime = _cronTime
    this.cronWeeks = _cronWeeks
    this.periodicChildrenQuests = _periodicChildrenQuests
      ? _periodicChildrenQuests.map(i => new SubQuest(i))
      : _periodicChildrenQuests
    this.formTemplate = _formTemplate
      ? new FormTemplate(_formTemplate)
      : _formTemplate
    this.tags = _tags
    this.creater = _creater ? new SecuredReflection(_creater) : _creater
    this.isFilled = _isFilled
    this.hasAnswers = _hasAnswers
    this.groupReflectionIds = _groupReflectionIds
    this.bizModule = _bizModule
    this.bizSettings = _bizSettings
    this.studentStatusConfigLabel = _studentStatusConfigLabel
    this.currentForm = _currentForm ? new Form(_currentForm) : _currentForm
    this.currentRelations = _currentRelations
      ? _currentRelations.map(i => new Relation(i))
      : _currentRelations
    this.parentId = _parentId
    this.id = _id
    this.schoolId = _schoolId
  }

  static propKeys = [
    'formTemplateId',
    'tagIds',
    'type',
    'scopeType',
    'reflectionIds',
    'startAt',
    'endAt',
    'cronTime',
    'cronWeeks',
    'periodicChildrenQuests',
    'formTemplate',
    'tags',
    'creater',
    'isFilled',
    'hasAnswers',
    'groupReflectionIds',
    'bizModule',
    'bizSettings',
    'studentStatusConfigLabel',
    'currentForm',
    'currentRelations',
    'parentId',
    'id',
    'schoolId',
  ]
}

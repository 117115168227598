import { FormTemplate, FormTemplateInterface } from './form-template'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'

export interface SubQuestInterface {
  formTemplateId: number
  /**
   * 表单模板
   */
  formTemplate?: FormTemplateInterface
  /**
   * 创建人信息
   */
  creater?: SecuredReflectionInterface | null
  /**
   * 是否已填写
   */
  isFilled?: boolean
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
}

export class SubQuest implements SubQuestInterface {
  formTemplateId: number
  /**
   * 表单模板
   */
  formTemplate?: FormTemplate
  /**
   * 创建人信息
   */
  creater?: SecuredReflection | null
  /**
   * 是否已填写
   */
  isFilled?: boolean
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: SubQuestInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      formTemplateId: _formTemplateId,
      formTemplate: _formTemplate,
      creater: _creater,
      isFilled: _isFilled,
      id: _id,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.formTemplateId = _formTemplateId
    this.formTemplate = _formTemplate
      ? new FormTemplate(_formTemplate)
      : _formTemplate
    this.creater = _creater ? new SecuredReflection(_creater) : _creater
    this.isFilled = _isFilled
    this.id = _id
    this.schoolId = _schoolId
  }

  static propKeys = [
    'formTemplateId',
    'formTemplate',
    'creater',
    'isFilled',
    'id',
    'schoolId',
  ]
}

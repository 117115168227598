import { Term, TermInterface } from './extends/term'
import { PrerequisiteTypeEnum } from './prerequisite-type-enum'
import { CourseStatusEnum } from './course-status-enum'
import { CourseMember, CourseMemberInterface } from './course-member'
import { RelatedCourse, RelatedCourseInterface } from './related-course'

export interface CourseInterface {
  csin: string
  name: string
  ename?: string | null
  /**
   * 学分
   */
  credit?: number | null
  /**
   * term id, type=system.school_structure
   */
  gradeIds?: number[]
  /**
   * expanded
   */
  grades?: TermInterface[]
  /**
   * term id, type=course.domain
   */
  domainId?: number | null
  /**
   * expanded
   */
  domain?: TermInterface | null
  /**
   * term id, type=course.subject
   */
  subjectId?: number | null
  /**
   * expanded
   */
  subject?: TermInterface | null
  /**
   * term id, type=course.en_subject
   */
  enSubjectId?: number | null
  /**
   * expanded
   */
  enSubject?: TermInterface | null
  /**
   * term id, type=course.attribute
   */
  attributeId?: number | null
  /**
   * expanded
   */
  attribute?: TermInterface | null
  /**
   * term id, type=system.semester_category
   */
  semesterCategoryIds?: number[]
  /**
   * 互斥课程 ids
   */
  exclusiveIds?: number[]
  /**
   * 先修课程 ids
   */
  prerequisiteIds?: number[]
  /**
   * 先修课程条件类型
   */
  prerequisiteType?: PrerequisiteTypeEnum | null
  /**
   * 描述
   */
  description?: string
  /**
   * 关联课程班数量
   */
  classNum: number
  /**
   * 状态
   */
  status: CourseStatusEnum
  /**
   * expanded
   */
  chargerMembers?: CourseMemberInterface[]
  /**
   * expanded
   */
  teacherMembers?: CourseMemberInterface[]
  /**
   * expanded
   */
  classTeacherMembers?: CourseMemberInterface[]
  /**
   * expanded
   */
  exclusives?: RelatedCourseInterface[]
  /**
   * expanded
   */
  prerequisites?: RelatedCourseInterface[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Course implements CourseInterface {
  csin: string
  name: string
  ename?: string | null
  /**
   * 学分
   */
  credit?: number | null
  /**
   * term id, type=system.school_structure
   */
  gradeIds?: number[]
  /**
   * expanded
   */
  grades?: Term[]
  /**
   * term id, type=course.domain
   */
  domainId?: number | null
  /**
   * expanded
   */
  domain?: Term | null
  /**
   * term id, type=course.subject
   */
  subjectId?: number | null
  /**
   * expanded
   */
  subject?: Term | null
  /**
   * term id, type=course.en_subject
   */
  enSubjectId?: number | null
  /**
   * expanded
   */
  enSubject?: Term | null
  /**
   * term id, type=course.attribute
   */
  attributeId?: number | null
  /**
   * expanded
   */
  attribute?: Term | null
  /**
   * term id, type=system.semester_category
   */
  semesterCategoryIds?: number[]
  /**
   * 互斥课程 ids
   */
  exclusiveIds?: number[]
  /**
   * 先修课程 ids
   */
  prerequisiteIds?: number[]
  /**
   * 先修课程条件类型
   */
  prerequisiteType?: PrerequisiteTypeEnum | null
  /**
   * 描述
   */
  description?: string
  /**
   * 关联课程班数量
   */
  classNum: number
  /**
   * 状态
   */
  status: CourseStatusEnum
  /**
   * expanded
   */
  chargerMembers?: CourseMember[]
  /**
   * expanded
   */
  teacherMembers?: CourseMember[]
  /**
   * expanded
   */
  classTeacherMembers?: CourseMember[]
  /**
   * expanded
   */
  exclusives?: RelatedCourse[]
  /**
   * expanded
   */
  prerequisites?: RelatedCourse[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: CourseInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      csin: _csin,
      name: _name,
      ename: _ename,
      credit: _credit,
      gradeIds: _gradeIds,
      grades: _grades,
      domainId: _domainId,
      domain: _domain,
      subjectId: _subjectId,
      subject: _subject,
      enSubjectId: _enSubjectId,
      enSubject: _enSubject,
      attributeId: _attributeId,
      attribute: _attribute,
      semesterCategoryIds: _semesterCategoryIds,
      exclusiveIds: _exclusiveIds,
      prerequisiteIds: _prerequisiteIds,
      prerequisiteType: _prerequisiteType,
      description: _description,
      classNum: _classNum,
      status: _status,
      chargerMembers: _chargerMembers,
      teacherMembers: _teacherMembers,
      classTeacherMembers: _classTeacherMembers,
      exclusives: _exclusives,
      prerequisites: _prerequisites,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.csin = _csin
    this.name = _name
    this.ename = _ename
    this.credit = _credit
    this.gradeIds = _gradeIds
    this.grades = _grades ? _grades.map(i => new Term(i)) : _grades
    this.domainId = _domainId
    this.domain = _domain ? new Term(_domain) : _domain
    this.subjectId = _subjectId
    this.subject = _subject ? new Term(_subject) : _subject
    this.enSubjectId = _enSubjectId
    this.enSubject = _enSubject ? new Term(_enSubject) : _enSubject
    this.attributeId = _attributeId
    this.attribute = _attribute ? new Term(_attribute) : _attribute
    this.semesterCategoryIds = _semesterCategoryIds
    this.exclusiveIds = _exclusiveIds
    this.prerequisiteIds = _prerequisiteIds
    this.prerequisiteType = _prerequisiteType
    this.description = _description
    this.classNum = _classNum
    this.status = _status
    this.chargerMembers = _chargerMembers
      ? _chargerMembers.map(i => new CourseMember(i))
      : _chargerMembers
    this.teacherMembers = _teacherMembers
      ? _teacherMembers.map(i => new CourseMember(i))
      : _teacherMembers
    this.classTeacherMembers = _classTeacherMembers
      ? _classTeacherMembers.map(i => new CourseMember(i))
      : _classTeacherMembers
    this.exclusives = _exclusives
      ? _exclusives.map(i => new RelatedCourse(i))
      : _exclusives
    this.prerequisites = _prerequisites
      ? _prerequisites.map(i => new RelatedCourse(i))
      : _prerequisites
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'csin',
    'name',
    'ename',
    'credit',
    'gradeIds',
    'grades',
    'domainId',
    'domain',
    'subjectId',
    'subject',
    'enSubjectId',
    'enSubject',
    'attributeId',
    'attribute',
    'semesterCategoryIds',
    'exclusiveIds',
    'prerequisiteIds',
    'prerequisiteType',
    'description',
    'classNum',
    'status',
    'chargerMembers',
    'teacherMembers',
    'classTeacherMembers',
    'exclusives',
    'prerequisites',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

import { OuterProviderEnum } from './outer-provider-enum'

export interface OuterInfoInterface {
  /**
   * 希悦用户 user id
   */
  userId: number
  /**
   * 外部对接平台分类
   */
  provider: OuterProviderEnum
  /**
   * 外部对接平台的组织ID，例如钉钉的 CorpID
   */
  providerId?: string
  /**
   * 外部平台的用户标识符，例如微信的 OpenID，钉钉的 UnionID
   */
  outerId: string
  /**
   * 外部平台的更多用户信息
   */
  outerInfo?: object | null
}

export class OuterInfo implements OuterInfoInterface {
  /**
   * 希悦用户 user id
   */
  userId: number
  /**
   * 外部对接平台分类
   */
  provider: OuterProviderEnum
  /**
   * 外部对接平台的组织ID，例如钉钉的 CorpID
   */
  providerId?: string
  /**
   * 外部平台的用户标识符，例如微信的 OpenID，钉钉的 UnionID
   */
  outerId: string
  /**
   * 外部平台的更多用户信息
   */
  outerInfo?: object | null

  constructor(props: OuterInfoInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      userId: _userId,
      provider: _provider,
      providerId: _providerId,
      outerId: _outerId,
      outerInfo: _outerInfo,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.userId = _userId
    this.provider = _provider
    this.providerId = _providerId
    this.outerId = _outerId
    this.outerInfo = _outerInfo
  }

  static propKeys = ['userId', 'provider', 'providerId', 'outerId', 'outerInfo']
}

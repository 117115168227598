export interface ExamScheduleNoticeSettingDetailInterface {
  /**
   * 用于通知的字段 (name, usin，gender 必填)
   */
  fields: string[]
  /**
   * 标题
   */
  title: string
  /**
   * 描述
   */
  description: string
}

export class ExamScheduleNoticeSettingDetail
  implements ExamScheduleNoticeSettingDetailInterface
{
  /**
   * 用于通知的字段 (name, usin，gender 必填)
   */
  fields: string[]
  /**
   * 标题
   */
  title: string
  /**
   * 描述
   */
  description: string

  constructor(props: ExamScheduleNoticeSettingDetailInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      fields: _fields,
      title: _title,
      description: _description,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.fields = _fields
    this.title = _title
    this.description = _description
  }

  static propKeys = ['fields', 'title', 'description']
}

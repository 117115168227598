import { times } from '@seiue/util'
// eslint-disable-next-line no-restricted-imports
import { Form, Input } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { $t } from 'packages/locale'
import { Color } from 'packages/themed'

interface ComponentProps {
  labelText?: string
  /**
   * 指定校验码，如果没有指定的话，则随机生成校验码
   */
  code?: string
  onValid: (passed: boolean) => void
}

const generateCode = () =>
  times(6, () => String.fromCharCode(Math.floor(Math.random() * 26) + 97)).join(
    '',
  )

/**
 * @deprecated use InputCaptcha from @seiue/ui instead.
 */
export const InputCaptcha: React.FC<ComponentProps> = ({
  labelText: originLabelText,
  onValid,
  code: outerCode,
}) => {
  const { current: code } = React.useRef(outerCode || generateCode())

  let labelText =
    originLabelText || $t('请在下方输入框中输入“{code}”以确认操作')

  labelText = labelText.replace('{code}', code)

  const [value, setValue] = React.useState('')
  const isError = value.trim() !== code
  const showError = value.trim().length >= code.length && isError

  React.useEffect(() => {
    onValid(!isError)
  }, [isError, onValid])

  return (
    <Wrapper
      labelCol={{ span: 24 }}
      label={labelText}
      validateStatus={showError ? 'error' : undefined}
      help={showError ? $t('输入有误，请重新输入') : undefined}
    >
      <Input
        value={value}
        placeholder={code}
        onChange={v => {
          setValue(v.target.value)
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled(Form.Item)`
  &&& .ant-form-item-label > label {
    height: auto;
    min-height: 24px;
    color: ${Color.Black};
  }
  & input {
    margin-bottom: 3px;
  }
`

import { LimitTypeEnum } from './limit-type-enum'

export interface GroupMemberLimitsInterface {
  type: LimitTypeEnum
  limit: number
  memberType: string
}

export class GroupMemberLimits implements GroupMemberLimitsInterface {
  type: LimitTypeEnum
  limit: number
  memberType: string

  constructor(props: GroupMemberLimitsInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      type: _type,
      limit: _limit,
      memberType: _memberType,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.type = _type
    this.limit = _limit
    this.memberType = _memberType
  }

  static propKeys = ['type', 'limit', 'memberType']
}

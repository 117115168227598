/**
 * @file webLayout 相关 hooks
 */
import { noop, useOnMount } from '@seiue/util'
import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

/**
 * 获取当前所在路径
 */
export const useActivePath = () => {
  const history = useHistory()
  const [activeMenuPath, setActiveMenuPath] = React.useState<string>('')
  const onRouteChangeRef = useRef(noop)

  useOnMount(() => {
    onRouteChangeRef.current = () => {
      const { pathname } = history.location

      setActiveMenuPath(pathname)
    }

    history.listen(onRouteChangeRef.current)
  })

  useEffect(() => {
    onRouteChangeRef.current()
  }, [])

  return activeMenuPath
}

import { MemberTypeEnum, SecuredReflection } from 'packages/sdks-next/chalk'

import { SeAvatarProps } from './SeAvatar'

// 在 AvatarUploader 等特殊场景下会捏造一个缺胳膊少腿的 reflection 作为 placeholder
export type AvatarReflection = Partial<
  Pick<
    SecuredReflection,
    'id' | 'name' | 'role' | 'photo' | 'pinyin' | 'avatar' | 'ename'
  >
>

export enum AvatarTypeEnum {
  'Photo',
  'Avatar',
  'Text',
  'Default',
}

export type AvatarProps = {
  reflection?: AvatarReflection
  size: SeAvatarProps['size'] | number
  showOutline?: boolean
  /**
   * 优先使用照片/头像，如果有值，则无视权限请求
   */
  forceFirst?: 'photo' | 'avatar'
  /**
   * 是否使用调色盘的颜色（仅对文字头像生效）
   */
  usePaletteColor?: boolean
  /**
   * 容器样式，这里使用模糊类型，兼容 go web 和 native
   */
  style?: { [key: string]: any }
  /**
   * 是否启用头像大图（影响 cursor），默认 false
   */
  useAvatarZoomIn?: boolean
  /**
   * 文字头像的字体大小，默认根据 size 自动计算
   */
  textFontSize?: number
  /**
   * 头像点击事件
   *
   * @param url - 头像地址，如果是文字头像则为空
   */
  onClick?: (url?: string) => void
  /**
   * 当能确认头像查看者和被查看者能构成群组关系时传入此参数，表示查看头像的用户是被查看者的某种前台管理身份（任课教师/班主任/导师等）
   * 这样可以不用调用查询群组关系的接口；
   *
   * 使用此参数的典型场景是：课程班考勤页，因为代课业务中，代课教师不一定有课程班教师对学生头像的权限
   * 所以这里传 MemberTypeEnum.Teacher 可以跳过群组关系的查询，来实现和课程班教师相同的权限
   */
  viewerMemberType?: MemberTypeEnum | string
}

export type AvatarData = {
  type: AvatarTypeEnum
  url: string
}

/**
 * @file register certification messages
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Certification,
  getLabel: () => $t('认证'),
  labelStyle: calculateLabelStyle('#D9E9FF', '#516A9A'),
  messages: [
    'certification_reflection_update',
    'certification_delete',
    'certification_reflection_cc',
  ],
})

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { AttendanceBizTypeEnum } from './attendance-biz-type-enum'
import { AttendanceTime, AttendanceTimeInterface } from './attendance-time'
import type { QueryBizAttendancesTimesQuery } from './query-biz-attendances-times-query'

const buildConfig = createConfigBuilder('sams')

export const attendanceRecordApi$queryBizAttendancesTimes = {
  config(
    bizType: AttendanceBizTypeEnum,
    queryBizAttendancesTimesQuery?: QueryBizAttendancesTimesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/attendance/{bizType}/attendance-times',
      {
        bizType,
      },
      queryBizAttendancesTimesQuery,
    )
  },

  /**
   * @summary 教务或教师查询业务下考勤时间段(多BizId)
   * @param bizType
   * @param [queryBizAttendancesTimesQuery]
   * @param [attendanceRecordQuery.bizIdIn]
   * @param [attendanceRecordQuery.planNameIn]
   * @param [attendanceRecordQuery.startAtElt]
   * @param [attendanceRecordQuery.startAtEgt]
   * @param [attendanceRecordQuery.endAtElt]
   * @param [attendanceRecordQuery.endAtEgt]
   * @param [attendanceRecordQuery.date]
   * @param [attendanceRecordQuery.expand] - place
   * @param [options]
   * @return AxiosResponsePromise<AttendanceTime[]>
   */

  api<const TQuery extends QueryBizAttendancesTimesQuery>(
    bizType: AttendanceBizTypeEnum,
    queryBizAttendancesTimesQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<AttendanceTime, TQuery['expand']>[]> {
    return axios(
      attendanceRecordApi$queryBizAttendancesTimes.config(
        bizType,
        queryBizAttendancesTimesQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new AttendanceTime(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryBizAttendancesTimesQuery,
    TSelected = Expand<AttendanceTime, TQuery['expand']>[],
  >(
    {
      bizType,
      query,
    }: {
      bizType: AttendanceBizTypeEnum
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<AttendanceTime, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<AttendanceTime, TQuery['expand']>[], TSelected>(
      attendanceRecordApi$queryBizAttendancesTimes.api,
      'AttendanceRecord.queryBizAttendancesTimes',
      queryOptions,
      bizType,
      query,
    )
  },
}

// @ts-ignore
attendanceRecordApi$queryBizAttendancesTimes.api._name_ = `AttendanceRecord.queryBizAttendancesTimes`

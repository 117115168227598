/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Grade, GradeInterface } from './extends/grade'
import type { QueryKlassGradesQuery } from './query-klass-grades-query'

const buildConfig = createConfigBuilder('vnas')

export const klassApi$queryKlassGrades = {
  config(
    assessmentId: number,
    queryKlassGradesQuery?: QueryKlassGradesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/klass/assessments/{assessmentId}/grades',
      {
        assessmentId,
      },
      queryKlassGradesQuery,
    )
  },

  /**
   * @summary 查询总成绩
   * @param assessmentId
   * @param [queryKlassGradesQuery]
   * @param [klassQuery.expand]
   * @param [klassQuery.paginated] 是否分页，默认 1
   * @param [klassQuery.page] 显示的数据页，默认 1
   * @param [klassQuery.perPage] 每页显示数量，默认 20
   * @param [klassQuery.idIn]
   * @param [klassQuery.semesterIdIn]
   * @param [klassQuery.statusIn]
   * @param [klassQuery.ownerIdIn]
   * @param [klassQuery.stageIdIn] 学段 IDs
   * @param [klassQuery.scoreEgt]
   * @param [klassQuery.scoreElt]
   * @param [klassQuery.gpaEgt]
   * @param [klassQuery.gpaElt]
   * @param [klassQuery.realScoreGt]
   * @param [klassQuery.realScoreLt]
   * @param [klassQuery.realScoreEgt]
   * @param [klassQuery.realScoreElt]
   * @param [klassQuery.suggestScoreEgt]
   * @param [klassQuery.suggestScoreElt]
   * @param [klassQuery.suggestScoreGt]
   * @param [klassQuery.suggestScoreLt]
   * @param [klassQuery.level]
   * @param [klassQuery.levelIn]
   * @param [klassQuery.rankEgt]
   * @param [klassQuery.rankElt]
   * @param [klassQuery.baseRankEgt]
   * @param [klassQuery.baseRankElt]
   * @param [klassQuery.courseRankEgt]
   * @param [klassQuery.courseRankElt]
   * @param [klassQuery.courseBaseRankEgt]
   * @param [klassQuery.courseBaseRankElt]
   * @param [klassQuery.statusIsNot]
   * @param [klassQuery.nameLike]
   * @param [klassQuery.nameIn]
   * @param [klassQuery.csinLike]
   * @param [klassQuery.csinIn]
   * @param [klassQuery.subjectIn]
   * @param [klassQuery.domainIn]
   * @param [klassQuery.creditEgt]
   * @param [klassQuery.creditElt]
   * @param [klassQuery.updatedAtEgt]
   * @param [klassQuery.updatedAtElt]
   * @param [options]
   * @return AxiosResponsePromise<Grade[]>
   */

  api<const TQuery extends QueryKlassGradesQuery>(
    assessmentId: number,
    queryKlassGradesQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Grade, TQuery['expand']>[]> {
    return axios(
      klassApi$queryKlassGrades.config(
        assessmentId,
        queryKlassGradesQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Grade(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryKlassGradesQuery,
    TSelected = Expand<Grade, TQuery['expand']>[],
  >(
    {
      assessmentId,
      query,
    }: {
      assessmentId: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<Grade, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Grade, TQuery['expand']>[], TSelected>(
      klassApi$queryKlassGrades.api,
      'Klass.queryKlassGrades',
      queryOptions,
      assessmentId,
      query,
    )
  },
}

// @ts-ignore
klassApi$queryKlassGrades.api._name_ = `Klass.queryKlassGrades`

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { GroupTeam, GroupTeamInterface } from './group-team'
import type { QueryGroupTeamQuery } from './query-group-team-query'

const buildConfig = createConfigBuilder('chalk')

export const groupApi$queryGroupTeam = {
  config(
    groupId: number,
    queryGroupTeamQuery?: QueryGroupTeamQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/group/groups/{groupId}/teams',
      {
        groupId,
      },
      queryGroupTeamQuery,
    )
  },

  /**
   * @summary 查询组内所有小组
   * @param groupId 群组ID
   * @param [queryGroupTeamQuery]
   * @param [groupQuery.ownerId] 档案子页面使用，传递个人档案所属人 ID
   * @param [groupQuery.sort] 排序，支持字段：
   * 1. weight
   * @param [groupQuery.expand] expand
   * - members
   * - members.reflection : 成员基本信息
   * @param [options]
   * @return AxiosResponsePromise<GroupTeam[]>
   */

  api<const TQuery extends QueryGroupTeamQuery>(
    groupId: number,
    queryGroupTeamQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<GroupTeam, TQuery['expand']>[]> {
    return axios(
      groupApi$queryGroupTeam.config(groupId, queryGroupTeamQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new GroupTeam(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryGroupTeamQuery,
    TSelected = Expand<GroupTeam, TQuery['expand']>[],
  >(
    {
      groupId,
      query,
    }: {
      groupId: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<GroupTeam, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<GroupTeam, TQuery['expand']>[], TSelected>(
      groupApi$queryGroupTeam.api,
      'Group.queryGroupTeam',
      queryOptions,
      groupId,
      query,
    )
  },
}

// @ts-ignore
groupApi$queryGroupTeam.api._name_ = `Group.queryGroupTeam`

import {
  ComputeRuleCondition,
  ComputeRuleConditionInterface,
} from './compute-rule-condition'

export interface ComputeRuleInterface {
  assessmentId: number
  /**
   * 计算规则条件，按顺序计算
   */
  conditions?: ComputeRuleConditionInterface[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 学期 ID
   */
  semesterId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class ComputeRule implements ComputeRuleInterface {
  assessmentId: number
  /**
   * 计算规则条件，按顺序计算
   */
  conditions?: ComputeRuleCondition[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 学期 ID
   */
  semesterId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: ComputeRuleInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      assessmentId: _assessmentId,
      conditions: _conditions,
      id: _id,
      schoolId: _schoolId,
      semesterId: _semesterId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.assessmentId = _assessmentId
    this.conditions = _conditions
      ? _conditions.map(i => new ComputeRuleCondition(i))
      : _conditions
    this.id = _id
    this.schoolId = _schoolId
    this.semesterId = _semesterId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'assessmentId',
    'conditions',
    'id',
    'schoolId',
    'semesterId',
    'createdAt',
    'updatedAt',
  ]
}

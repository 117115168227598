import {
  PluginScopeParam as Base,
  PluginScopeParamInterface,
} from '../plugin-scope-param'

export type { PluginScopeParamInterface }

/**
 * 插件范围
 */
export class PluginScopeParam extends Base {
  /**
   * 是否为全部范围 (所有条件为空)
   *
   * @param scope - 范围
   * @returns boolean
   */
  static isAllScope(scope: PluginScopeParamInterface) {
    return isAllScope(scope)
  }

  /**
   * 是否为全部范围 (所有条件为空)
   *
   * @returns boolean
   */
  get isAllScope() {
    return isAllScope(this)
  }
}

/**
 * 是否为全部范围 (所有条件为空)
 *
 * @param scope - 范围
 * @returns boolean
 */
export const isAllScope = (scope: PluginScopeParamInterface) => {
  return scope && Object.entries(scope).every(([, v]) => v === undefined)
}

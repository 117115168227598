import { moment } from '@seiue/moment'
import { groupBy, sortBy, sumBy } from '@seiue/util'
import React from 'react'

import { Certification } from 'packages/sdks-next/sgms'

export type CertificationGroup = {
  /**
   * 认证分类 id
   */
  typeId: string
  /**
   * 认证分类名称
   */
  typeName: string
  /**
   * 认证分类创建时间
   */
  typeCreatedAt: string
  /**
   * 认证分类的总获得数量
   */
  typePassedCount: number
  /**
   * 认证分类下徽章的最新获得时间
   */
  typePassedAt?: string | null
  /**
   * 认证分类下的徽章列表
   */
  certifications: Certification[]
}

/**
 * 对徽章列表按照特定规则进行分组和排序
 *
 * 分类的排序规则：获得数量从大到小 > 最新获得时间从新到旧
 * 分类下的徽章排序规则：获得数量从大到小 > 最新获得时间从新到旧
 *
 * @param certifications - 徽章列表
 * @returns 分组/排序后的徽章组
 */
export const useCertificationGroups = (
  certifications: Certification[] | null,
): CertificationGroup[] => {
  return React.useMemo(() => {
    if (!certifications?.length) return []

    // 按「分类」分组
    const groups = Object.entries(
      groupBy(certifications, 'certificationTypeId'),
    ).map(([typeId, groupCerts]) => {
      // 按「最新获得时间」倒叙排列徽章列表
      const sortedByPassedAt = sortBy(groupCerts, cert => {
        const passedAt = cert.certReflectionsInfo?.lastedPassed?.passedAt
        return !passedAt ? 0 : moment(passedAt).unix()
      }).reverse()

      // 该分类下已获得总数量
      const typePassedCount = sumBy(
        sortedByPassedAt,
        'certReflectionsInfo.passedCount',
      )

      return {
        typeId,
        typeName: groupCerts[0]?.certificationType?.name ?? '',
        typeCreatedAt: groupCerts[0]?.certificationType?.createdAt ?? '',
        typePassedCount,
        typePassedAt:
          sortedByPassedAt[0]?.certReflectionsInfo?.lastedPassed?.passedAt,
        certifications: sortedByPassedAt,
      }
    })

    // 对分组进行排序
    const sortedGroups = sortGroups(groups)

    return sortedGroups.map(group => {
      return {
        ...group,
        // 对组内的徽章列表排序
        certifications: sortCertifications(group.certifications),
      }
    })
  }, [certifications])
}

const sortGroups = (groups: CertificationGroup[]) => {
  return groups.sort((a, b) => {
    // 分类已获得数量多的优先显示
    if (a.typePassedCount > b.typePassedCount) {
      return -1
    }

    if (a.typePassedCount < b.typePassedCount) {
      return 1
    }

    // 获得数量一致，按最新获得时间倒序
    if (
      a.typePassedCount === b.typePassedCount &&
      a.typePassedAt &&
      b.typePassedAt
    ) {
      return moment(a.typePassedAt).isBefore(b.typePassedAt) ? 1 : -1
    }

    // 无最新获得时间，按照分类的创建时间正序
    return moment(a.typeCreatedAt).isBefore(b.typeCreatedAt) ? -1 : 1
  })
}

/**
 * 对徽章列表按照特定规则排序
 * 徽章排序规则：获得数量从大到小 > 最新获得时间从新到旧
 *
 * @param certs - 徽章列表
 * @returns 排序后的徽章列表
 */
export const sortCertifications = (certs: Certification[]) => {
  return certs.sort((a, b) => {
    const aPassedCount = a.certReflectionsInfo?.passedCount ?? 0
    const bPassedCount = b.certReflectionsInfo?.passedCount ?? 0

    // 徽章已获得数量多的优先显示
    if (aPassedCount > bPassedCount) {
      return -1
    }

    if (aPassedCount < bPassedCount) {
      return 1
    }

    const aPassedAt = a.certReflectionsInfo?.lastedPassed?.passedAt
    const bPassedAt = b.certReflectionsInfo?.lastedPassed?.passedAt

    // 获得数量一致，按最新获得时间倒序
    if (aPassedCount === bPassedCount && aPassedAt && bPassedAt) {
      return moment(aPassedAt).isBefore(bPassedAt) ? 1 : -1
    }

    // 无最新获得时间，按照徽章的创建时间正序
    return moment(a.createdAt).isBefore(b.createdAt) ? -1 : 1
  })
}

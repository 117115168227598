export interface ComputeRuleConditionValueInterface {
  /**
   * 评价项 / 维度名称
   */
  name: string
  /**
   * 占分
   */
  within: string
}

export class ComputeRuleConditionValue
  implements ComputeRuleConditionValueInterface
{
  /**
   * 评价项 / 维度名称
   */
  name: string
  /**
   * 占分
   */
  within: string

  constructor(props: ComputeRuleConditionValueInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      within: _within,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.within = _within
  }

  static propKeys = ['name', 'within']
}

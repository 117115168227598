/**
 * @file register certification messages
 */
import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.TeacherProfile,
  getLabel: () => $t('教师档案'),
  labelStyle: calculateLabelStyle('#F7E9FA', '#9E37B4'),
  messages: [
    'approved',
    'rejected',
    'revoke_approved',
    'revoke_rejected',
    'uncompleted_notice_for_reflection',
  ],
})

/**
 * @file 日程 view helper
 */

import { moment, MomentInput } from '@seiue/moment'

import { $t } from 'packages/locale'

export const FULL_DAY_ITEM_HEIGHT = 20 // 全天日程的高度是
export const FULL_DAY_ITEM_MARGIN_TOP = 5 // 全天日程的高度间距是
export const WEB_45_MINUTE_EVENT_CARD_HEIGHT = 68 // web 端 45 分钟课程的高度
export const MOBILE_45_MINUTE_EVENT_CARD_HEIGHT = 64 // 移动端 45 分钟课程的高度
export const WEB_EVENT_CARD_MIN_HEIGHT = 36 // web 端卡片最低高度

/**
 * 日程里面时间范围的显示
 * （2011-11-11 11:11 至 12:12 或者 2019-12-28 12:23 至 2019-12-29 12:25）
 * @param startAt
 * @param endAt
 * @param isFullDay
 */
export const formatTimePeriod = (
  startAt: MomentInput,
  endAt: MomentInput,
  isFullDay = false,
) => {
  const formatter = 'YYYY-MM-DD'
  const startAtMoment = moment(startAt)
  const endAtMoment = moment(endAt)
  const startDay = startAtMoment.format(formatter)
  const endDay = endAtMoment.format(formatter)
  const startTime = startAtMoment.format('HH:mm')
  const endTime = endAtMoment.format('HH:mm')

  // 如果是同一天的话，需要统一 startAt 和 endAt 的日期显示
  if (startDay === endDay) {
    if (isFullDay) {
      // 全天日程（只有一天）一般是 2019-12-28 00:00:00 ~ 2019-12-28 23:59:59，所以直接显示为 2019-12-28 ～ 2019-12-29
      return `${startDay} ${$t('至')} ${moment(startAt)
        .add(1, 'day')
        .format(formatter)}`
    }

    // 非全天日程显示为 2019-12-28 00:00 ～ 08:00
    return `${startDay} ${startTime} ${$t('至')} ${endTime}`
  }

  if (isFullDay) {
    // 全天日程（多于一天）直接显示为 2019-12-28 ～ 2019-12-29
    return `${startDay} ${$t('至')} ${endDay}`
  }

  // 非全天日程显示为 2019-12-28 00:00 ～ 2019-12-29 08:00
  return `${startDay} ${startTime} ${$t('至')} ${endDay} ${endTime}`
}

/**
 * @file 处理 ModalRoute url
 */

import { ToastMetaError } from '@seiue/error-handler'
import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router'

import { $t } from 'packages/locale'
import { MODAL_QUERY_NAMESPACE, Query, RouteParams } from 'packages/route/types'
import { appendQueryString } from 'packages/utils/urls'

/**
 * 编码 Modal 查询参数
 *
 * @param query - 查询参数
 * @returns 编码后的查询参数
 */
export const encodeModalQuery = (query: Query) =>
  encodeURIComponent(JSON.stringify(query))

/**
 * 解码 Modal 查询参数
 *
 * @returns 解码后的查询参数
 */
export const useDecodeModalQuery = () => {
  const location = useLocation()
  const history = useHistory()

  return useCallback(
    (str: string) => {
      try {
        const modalQuery = JSON.parse(decodeURIComponent(str))
        return modalQuery
      } catch (e) {
        // 替换掉 search，即 modal 相关的任何参数，关闭弹窗
        history.replace(location.pathname)

        // 一般是 url 格式有误，JSON.parse 执行失败
        throw new ToastMetaError({
          title: $t('弹窗打开失败，请检查网址是否正确'),
          report: false,
        })
      }
    },
    [location, history],
  )
}

/**
 * 组合 Modal 路由 URL
 *
 * @param modalName - 弹窗名称
 * @param query - 查询参数
 * @param prevSearch - 前一个查询参数
 * @returns Modal 路由 URL
 */
export const composeModalUrl = (
  modalName: string,
  query?: RouteParams | null,
  prevSearch = '',
) => {
  let modalQuery = ''
  if (query) {
    modalQuery = encodeModalQuery(query)
  }

  return appendQueryString(prevSearch, {
    modal: modalName,
    [MODAL_QUERY_NAMESPACE]: modalQuery,
  })
}

/**
 * @file 通用群组 data
 */

/* eslint-disable no-param-reassign */

import { moment } from '@seiue/moment'
import { compact, uniq } from '@seiue/util'
import React from 'react'

import { CustomizedGroupSource } from 'packages/features/customized-groups'
import { $t } from 'packages/locale'
import { CalendarEvent, CustomGroup, GroupType } from 'packages/sdks-next/chalk'
import {
  AttendanceBizTypeEnum,
  BaseAttendance,
  attendanceRecordApi$queryBizAttendanceTimes,
  attendanceApi$queryAttendancesInfo,
  attendanceRecordApi$queryBizAttendancesTimes,
} from 'packages/sdks-next/sams'

import { CustomGroupAttendanceCalendarEvent } from '../types'

/**
 * 获取通用群组的考勤日程
 *
 * @param props - 组件属性
 * @param props.startAt - 开始时间
 * @param props.endAt - 结束时间
 * @param props.customGroup - 通用群组
 * @param props.groupType - 群组类型
 * @param props.events - 日程
 * @param props.attendance - 考勤
 * @returns 通用群组的考勤日程
 */
export const useCustomGroupAttendanceSchedules = ({
  startAt,
  endAt,
  customGroup,
  groupType,
  events,
  attendance,
}: {
  startAt: string
  endAt: string
  customGroup: CustomGroup
  groupType: GroupType
  events?: CalendarEvent[] | null
  attendance?: BaseAttendance
}) => {
  const { data: times } = attendanceRecordApi$queryBizAttendanceTimes.useApi(
    {
      bizType: AttendanceBizTypeEnum.CustomGroup,
      bizId: customGroup.id,
      query: {
        startAtEgt: startAt,
        endAtElt: endAt,
        tryExpand: ['place'],
      },
    },
    {
      disable: !attendance?.enable,
    },
  )

  const timeIdIn = times?.map(v => v.id).join(',')
  const { data: attendances } = attendanceApi$queryAttendancesInfo.useApi(
    {
      bizTypeIn: AttendanceBizTypeEnum.CustomGroup,
      bizIdIn: `${customGroup.id}`,
      attendanceTimeIdIn: `${timeIdIn}`,
      query: {
        paginated: 0,
        expand: ['checkedAttendanceTimeIds'] as const,
      },
    },
    {
      disable: !attendance?.enable || !timeIdIn,
    },
  )

  return React.useMemo(() => {
    if (!events) return []
    const otherEvents = times?.length
      ? events.filter(
          v =>
            !times.find(
              v1 => v.startTime === v1.startAt && v.endTime === v1.endAt,
            ),
        )
      : events

    const attendanceEvents: CustomGroupAttendanceCalendarEvent[] =
      times?.map(v => {
        // 把考勤主体注入到对应的课节
        const att = attendances?.find(
          a => a.bizId === customGroup.id && a.enable,
        )

        return {
          type: 'group',
          startTime: v.startAt,
          endTime: v.endAt,
          title: customGroup.name,
          address: v.place?.name,
          placeId: null,
          initiatorIds: [],
          initiators: [],
          custom: {
            id: customGroup.id,
            members: customGroup.members,
            typeEntity: groupType,
            attendance: att,
            attendanceButtonText: att?.checkedAttendanceTimeIds?.includes(v.id)
              ? $t('修改考勤')
              : $t('录入考勤'),
          },
        }
      }) ?? []

    return [...otherEvents, ...attendanceEvents]
  }, [events, times, groupType, customGroup, attendances])
}

/**
 * FIXME
 * 判断通用群组的日程是否显示考勤入口，应该由后端直接返回事件对应的节次 id（time_id），而不是前端查所有群组的节次来匹配
 *
 * @param groupEvents - 通用群组日程
 * @returns 通用群组 ID 和节次 ID
 */
export const useCustomGroupIdsAndGroupTimeIds = (
  groupEvents: CustomizedGroupSource[],
) => {
  const { groupEventStartAtEgt, groupEventEndAtElt } = groupEvents.reduce(
    (prev, currentEvent) => {
      if (!prev.groupEventStartAtEgt && !prev.groupEventEndAtElt) {
        prev.groupEventStartAtEgt = currentEvent.startTime
        prev.groupEventEndAtElt = currentEvent.endTime
      } else {
        if (
          moment(currentEvent.startTime).isBefore(prev.groupEventStartAtEgt)
        ) {
          prev.groupEventStartAtEgt = currentEvent.startTime
        }

        if (moment(currentEvent.endTime).isAfter(prev.groupEventEndAtElt)) {
          prev.groupEventEndAtElt = currentEvent.endTime
        }
      }

      return prev
    },
    {
      groupEventStartAtEgt: '',
      groupEventEndAtElt: '',
    },
  )

  const groupIdIn = uniq(compact(groupEvents.map(event => event.custom.id)))
  const { data: customGroupAttendanceTimes } =
    attendanceRecordApi$queryBizAttendancesTimes.useApi(
      {
        bizType: AttendanceBizTypeEnum.CustomGroup,
        query: {
          bizIdIn: groupIdIn.join(','),
          startAtEgt: groupEventStartAtEgt,
          endAtElt: groupEventEndAtElt,
        },
      },
      {
        disable:
          !groupIdIn.length || !groupEventStartAtEgt || !groupEventEndAtElt,
      },
    )

  return {
    groupIdIn,
    customGroupAttendanceTimes,
  } as const
}

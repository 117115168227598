/**
 * @file 注册心理档案消息
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import { registerMessageNavigator } from 'packages/features/messages/utils'
import { Assessment } from 'packages/sdks-next/sgms'

import { NoticeRoles } from './message-type'

registerMessageNavigator(
  `${MessageDomainEnum.Psychological}.${NoticeRoles.Mentor}`,
  message => {
    const attributes = message.attributes as {
      assessmentId: Assessment['id']
      scaleId: Assessment['scaleId']
      scaleIds?: number[]
    }

    const id = attributes?.assessmentId

    if (!id) return null

    return {
      modalName: 'PluginPsychologicalFileAssessmentUnfilledStudentsModal',
      options: { id, groupRole: 'mentor' },
    }
  },
)

registerMessageNavigator(
  `${MessageDomainEnum.Psychological}.${NoticeRoles.AdminClassTeacher}`,
  message => {
    const attributes = message.attributes as {
      assessmentId: Assessment['id']
      scaleId: Assessment['scaleId']
    }

    const id = attributes?.assessmentId

    if (!id) return null

    return {
      modalName: 'PluginPsychologicalFileAssessmentUnfilledStudentsModal',
      options: { id, groupRole: 'admin_class_teacher' },
    }
  },
)

registerMessageNavigator(
  `${MessageDomainEnum.Psychological}.${NoticeRoles.GuardianReceivedResult}`,
  message => {
    const attributes = message.attributes as {
      assessmentId: Assessment['id']
      scaleId: Assessment['scaleId']
      ownerId: number
    }

    const { assessmentId, ownerId } = attributes || {}

    if (!assessmentId || !ownerId) return null

    return {
      modalName: 'PluginPsychologicalDownloadReportModal',
      options: {
        assessmentId,
        reflectionId: ownerId,
        readed: !!message.readed,
        messageId: message.id,
      },
    }
  },
)

registerMessageNavigator(
  `${MessageDomainEnum.Psychological}.${NoticeRoles.StudentResult}`,
  message => {
    const attributes = message.attributes as {
      assessmentId: Assessment['id']
      scaleId: Assessment['scaleId']
      ownerId: number
    }

    const { assessmentId, ownerId } = attributes || {}

    if (!assessmentId || !ownerId) return null

    return {
      modalName: 'PluginPsychologicalDownloadReportModal',
      options: {
        assessmentId,
        reflectionId: ownerId,
        readed: !!message.readed,
        messageId: message.id,
      },
    }
  },
)

registerMessageNavigator(
  `${MessageDomainEnum.Psychological}.${NoticeRoles.Student}`,
  message => {
    const attributes = message.attributes as {
      assessmentId: Assessment['id']
      scaleId: Assessment['scaleId']
    }

    const id = attributes?.assessmentId

    if (!id) return null

    return {
      modalName: 'PluginPsychologicalFileSubmitAssessmentsModal',
      options: { id },
    }
  },
)

registerMessageNavigator(`psychological.need_assess`, message => {
  const attributes = message.attributes as {
    studentId: number
    profileId: number
  }

  const { studentId, profileId } = attributes

  if (!studentId || !profileId) return null

  return {
    path: `/admin/psychological-file/information/students/${profileId}/${studentId}/details/home`,
  }
})

registerMessageNavigator('psychological.notify_report', message => {
  const attributes = message.attributes as {
    problemReportId: number
  }

  const reportId = attributes.problemReportId

  if (!reportId) return null

  return {
    modalName: 'PluginPsyUserReportDetails',
    options: { reportId },
  }
})

registerMessageNavigator(
  ['psy_chat.apply_chat', 'psy_chat.chat_created', 'psy_chat.chat_changed'],
  message => {
    const attributes = message.attributes as {
      chatId: number
      instanceId: number
    }

    const { chatId, instanceId } = attributes || {}

    if (!chatId || !instanceId) return null

    return {
      modalName: 'PluginPsyChatDetailModal',
      options: { id: chatId, chatInstanceId: instanceId },
    }
  },
)

import { createEnumHelper } from '@seiue/util'

import { $t } from 'packages/locale/LocaleProvider'

import { ClassWeek as ClassWeekBase } from '../class-week'
import { ClassWeekTypeEnum } from '../class-week-type-enum'

export type { ClassWeekInterface } from '../class-week'

const weekTypesToLabel = createEnumHelper(() => ({
  [ClassWeekTypeEnum.Weekly]: $t('每周上课'),
  [ClassWeekTypeEnum.Odd]: $t('单周上课'),
  [ClassWeekTypeEnum.Even]: $t('双周上课'),
  [ClassWeekTypeEnum.Custom]: $t('各课节自定义'),
}))

const shortWeekTypesToLabel = createEnumHelper(() => ({
  [ClassWeekTypeEnum.Weekly]: $t('每周'),
  [ClassWeekTypeEnum.Odd]: $t('单周'),
  [ClassWeekTypeEnum.Even]: $t('双周'),
  [ClassWeekTypeEnum.Custom]: $t('自定义'),
}))

/**
 * 上课周
 */
export class ClassWeek extends ClassWeekBase {
  /**
   * 获取上课方式的所有选项
   *
   * @param sourceKey - 原始值在选项 object 中的 key, 默认 value
   * @param mappedKey - 文字标签在选项 object 中的 key, 默认 label
   * @returns 选项数组, 默认 [{ value, label }]
   */
  static getWeekTypeOptions<S extends string, M extends string>(
    sourceKey: S,
    mappedKey: M,
  ) {
    return (
      weekTypesToLabel
        .listOptions(sourceKey, mappedKey)
        // FIXME 等待后端移除 Custom 类型
        .filter(option => option[sourceKey] !== ClassWeekTypeEnum.Custom)
    )
  }

  /**
   * 获取上课方式的文本
   *
   * @returns 文本
   */
  get weekTypeLabel() {
    return weekTypesToLabel.get(this.weekType)
  }

  /**
   * 获取上课方式的缩写文本
   *
   * @returns 缩写文本
   */
  get weekTypeShortLabel() {
    return shortWeekTypesToLabel.get(this.weekType)
  }

  /**
   * 获取上课周的唯一 key
   *
   * @param week - 上课周
   * @returns 唯一 key
   */
  static getUniqKey(week: Pick<ClassWeek, 'startAt' | 'endAt' | 'weekType'>) {
    return `${week.startAt}-${week.endAt}-${week.weekType}`
  }
}

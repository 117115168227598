export type BaseSeriesLabelType<P = any, Content = any> = {
  /**
   * 是否显示标签
   */
  show?: boolean
  /**
   * 标签位置
   */
  position?: P
  /**
   * 重叠时是否隐藏标签
   */
  hideOverlap?: boolean
  /**
   * 要显示内容类型
   */
  content?: Content[]
}

export enum SeriesContentTypeEnum {
  Value = 'value',
  Category = 'category',
  Percent = 'percent',
}

export type CommonSeriesContentType = Exclude<
  SeriesContentTypeEnum,
  SeriesContentTypeEnum.Percent
>

export type BaseSeriesType<
  Label extends BaseSeriesLabelType = BaseSeriesLabelType,
> = {
  label: Label
}

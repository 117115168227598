export enum ScopeEnhancerEnum {
  ReflectionScope = 'seiue.reflection_scope',
  StudentScope = 'seiue.student_scope',
  TeacherScope = 'seiue.teacher_scope',
  GuardianScope = 'seiue.guardian_scope',
  RoleManagerScope = 'seiue.role_manager_scope',
  PlaceScope = 'seiue.place_scope',
  ClassScope = 'seiue.class_scope',
  CourseScope = 'seiue.course_scope',
  StaffScope = 'seiue.staff_scope',
  DormScope = 'seiue.dorm_scope',
  AdminClassScope = 'seiue.admin_class_scope',
  SubjectGroupScope = 'seiue.subject_group_scope',
  HandoutScope = 'seiue.handout_scope',
  ExamScoreScope = 'seiue.exam_score_scope',
  StudentStatusConfigScope = 'seiue.student_status_config_scope',
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { GroupMember, GroupMemberInterface } from './group-member'
import type { FindGroupGroupMembersQuery } from './find-group-group-members-query'

const buildConfig = createConfigBuilder('chalk')

export const groupApi$findGroupGroupMembers = {
  config(
    groupId: number,
    findGroupGroupMembersQuery?: FindGroupGroupMembersQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/group/groups/{groupId}/members',
      {
        groupId,
      },
      findGroupGroupMembersQuery,
    )
  },

  /**
   * @summary 在指定的群组下，查询群组成员
   * @param groupId 群组ID
   * @param [findGroupGroupMembersQuery]
   * @param [groupQuery.ownerId] 档案子页面使用，传递个人档案所属人 ID
   * @param [groupQuery.memberType] member type
   * @param [groupQuery.identity] reflection's identity : name、pinyin、usin
   * @param [groupQuery.name] reflection's name
   * @param [groupQuery.usin] reflection's usin
   * @param [groupQuery.account] reflection's account
   * @param [groupQuery.teamIdIn] 所属小组的ids
   * @param [groupQuery.top] 是否被置顶
   * @param [groupQuery.sort] 排序，支持字段：
   * - top
   * - is_in_team: 是否已分组
   * - member_type_id: 成员类型
   * - reflection.usin: 学号
   * - reflection.pinyin: 姓名拼音
   * - team.weight: 小组排序(权重)
   * - team.id: 小组id
   * @param [groupQuery.expand] expand
   * - team: 所属小组
   * - teams: 所属多个小组
   * - reflection : 成员基本信息
   * - top_info
   * - banned_to_post_info
   * - class_member_type_info : 所属课程班内的成员类型信息
   * @param [groupQuery.classTypeSettingId] 当前用户的课程班成员类型id
   * @param [groupQuery.fields]
   * @param [groupQuery.paginated] 是否分页，默认 1
   * @param [groupQuery.page] 显示的数据页，默认 1
   * @param [groupQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<GroupMember[]>
   */

  api<const TQuery extends FindGroupGroupMembersQuery>(
    groupId: number,
    findGroupGroupMembersQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<GroupMember, TQuery['expand']>[]> {
    return axios(
      groupApi$findGroupGroupMembers.config(
        groupId,
        findGroupGroupMembersQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new GroupMember(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends FindGroupGroupMembersQuery,
    TSelected = Expand<GroupMember, TQuery['expand']>[],
  >(
    {
      groupId,
      query,
    }: {
      groupId: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<GroupMember, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<GroupMember, TQuery['expand']>[], TSelected>(
      groupApi$findGroupGroupMembers.api,
      'Group.findGroupGroupMembers',
      queryOptions,
      groupId,
      query,
    )
  },
}

// @ts-ignore
groupApi$findGroupGroupMembers.api._name_ = `Group.findGroupGroupMembers`

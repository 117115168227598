/**
 * @file Session 相关 Hooks
 */

import { useTimeout } from '@seiue/util'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  AuthorizeParams,
  isSSOLogin,
  SessionState,
  useCurrentReflection,
} from '../utils'

/**
 * 是否是单点登录，现在，仅有腾讯校园小程序是单点登录
 *
 * @returns 是否
 */
export const useIsSSOLogin = () => {
  const authType: AuthorizeParams['type'] | null = useSelector(
    (state: any) => state.session.authType,
  )

  return isSSOLogin(authType)
}

/**
 * 是否是 apollo 登录
 *
 * @returns 是否
 */
export const useIsApolloLogin = () =>
  useSelector(({ session }: any) => session.isApolloLogin)

/**
 * 刷新用户
 *
 * @returns 刷新用户的函数
 */
export const useRefreshMe = () => {
  const dispatch: any = useDispatch()
  const { id } = useCurrentReflection()

  return useCallback(async () => {
    const user = await dispatch.session.getCurrentUser()

    return dispatch.session.setUser({ user, rid: id })
  }, [dispatch, id])
}

/**
 * 当前用户是否登录
 *
 * @returns 是否
 */
export const useIsLoggedIn = () =>
  !!useSelector(
    (state: { session: SessionState }) => state.session.state === 'created',
  )

/**
 * 当前用户是否登录失败
 *
 * @returns 是否
 */
export const useIsNoLogged = () =>
  !!useSelector(
    (state: { session: SessionState }) => state.session.state === 'none',
  )

/**
 * 当用户尝试登录的时间超过 60 秒时，退出登录
 *
 * @param sessionDone - 是否已经登录
 * @param beforeLogout - 退出登录前的 Hook
 */
export const useDestorySessionWhenTimeout = (
  sessionDone: boolean,
  beforeLogout?: () => Promise<boolean> | boolean,
) => {
  const dispatch: any = useDispatch()

  useTimeout(async () => {
    if (!sessionDone) {
      const result = beforeLogout ? await beforeLogout?.() : true

      if (!result) return

      dispatch.session.destroy()
    }
  }, 60000)
}

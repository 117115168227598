/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { AccItem, AccItemInterface } from './acc-item'
import type { QueryAccItemsQuery } from './query-acc-items-query'

const buildConfig = createConfigBuilder('vnas')

export const accomplishmentApi$queryAccItems = {
  config(
    queryAccItemsQuery?: QueryAccItemsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/accomplishment/items',
      {},
      queryAccItemsQuery,
    )
  },

  /**
   * @summary 查询素养
   * @param [queryAccItemsQuery]
   * @param [accomplishmentQuery.expand]
   * @param [accomplishmentQuery.id]
   * @param [accomplishmentQuery.idIn]
   * @param [accomplishmentQuery.assessmentId]
   * @param [accomplishmentQuery.assessmentIdIn]
   * @param [accomplishmentQuery.name]
   * @param [accomplishmentQuery.nameIn]
   * @param [accomplishmentQuery.nameLike]
   * @param [accomplishmentQuery.tag]
   * @param [accomplishmentQuery.tagIn]
   * @param [accomplishmentQuery.tagLike]
   * @param [accomplishmentQuery.enableAggregated]
   * @param [accomplishmentQuery.aggregatedType]
   * @param [accomplishmentQuery.fullScore]
   * @param [accomplishmentQuery.scoringType]
   * @param [accomplishmentQuery.type]
   * @param [accomplishmentQuery.typeIn]
   * @param [accomplishmentQuery.parentId]
   * @param [accomplishmentQuery.parentIdIn]
   * @param [accomplishmentQuery.parentIdIsEmpty]
   * @param [accomplishmentQuery.weight]
   * @param [accomplishmentQuery.weightElt]
   * @param [accomplishmentQuery.weightEgt]
   * @param [accomplishmentQuery.itemRelations.itemId]
   * @param [accomplishmentQuery.itemRelations.itemIdIn]
   * @param [accomplishmentQuery.itemRelations.relationId]
   * @param [accomplishmentQuery.itemRelations.relationIdIn]
   * @param [accomplishmentQuery.itemRelations.relation]
   * @param [accomplishmentQuery.itemRelations.relationIn]
   * @param [accomplishmentQuery.accAssessment.id]
   * @param [accomplishmentQuery.accAssessment.idIn]
   * @param [accomplishmentQuery.accAssessment.scopeId]
   * @param [accomplishmentQuery.accAssessment.scopeIdIn]
   * @param [accomplishmentQuery.accAssessment.typeId]
   * @param [accomplishmentQuery.accAssessment.typeIdIn]
   * @param [accomplishmentQuery.accAssessment.nameIn]
   * @param [accomplishmentQuery.accAssessment.nameLike]
   * @param [accomplishmentQuery.accAssessment.mode]
   * @param [accomplishmentQuery.accAssessment.modeIn]
   * @param [accomplishmentQuery.accAssessment.status]
   * @param [accomplishmentQuery.accAssessment.statusIn]
   * @param [accomplishmentQuery.accAssessment.itemNum]
   * @param [accomplishmentQuery.accAssessment.itemNumEgt]
   * @param [accomplishmentQuery.accAssessment.itemNumElt]
   * @param [accomplishmentQuery.accAssessment.scopedStudentId] 指定学生在评价的范围里
   * @param [accomplishmentQuery.accAssessment.settings]
   * @param [accomplishmentQuery.paginated] 是否分页，默认 1
   * @param [accomplishmentQuery.page] 显示的数据页，默认 1
   * @param [accomplishmentQuery.perPage] 每页显示数量，默认 20
   * @param [accomplishmentQuery.sort]
   * @param [options]
   * @return AxiosResponsePromise<AccItem[]>
   */

  api<const TQuery extends QueryAccItemsQuery>(
    queryAccItemsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<AccItem, TQuery['expand']>[]> {
    return axios(
      accomplishmentApi$queryAccItems.config(queryAccItemsQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new AccItem(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryAccItemsQuery,
    TSelected = Expand<AccItem, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<AccItem, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<AccItem, TQuery['expand']>[], TSelected>(
      accomplishmentApi$queryAccItems.api,
      'Accomplishment.queryAccItems',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
accomplishmentApi$queryAccItems.api._name_ = `Accomplishment.queryAccItems`

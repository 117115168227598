export enum WidgetType {
  DataTable = 'data_table',
  PivotTable = 'pivot_table',
  BarChart = 'bar_chart',
  ColumnChart = 'column_chart',
  PieChart = 'pie_chart',
  LineChart = 'line_chart',
  RadarChart = 'radar_chart',
  Filter = 'filter',
  Container = 'container',
  Paragraph = 'paragraph',
  AdvancedTable = 'advanced_table',
  PageBreak = 'page_break',
  Image = 'image',
  StackedBarChart = 'stacked_bar_chart',
  StackedColumnChart = 'stacked_column_chart',
}

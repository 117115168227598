import { YesOrNoEnum } from './yes-or-no-enum'

export interface MissNoticeInterface {
  /**
   * 未考勤提醒开关
   */
  enable: YesOrNoEnum
  /**
   * 定时提醒时间
   */
  cronTime: string
  /**
   * 定时提醒内容
   */
  content: string
}

export class MissNotice implements MissNoticeInterface {
  /**
   * 未考勤提醒开关
   */
  enable: YesOrNoEnum
  /**
   * 定时提醒时间
   */
  cronTime: string
  /**
   * 定时提醒内容
   */
  content: string

  constructor(props: MissNoticeInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      enable: _enable,
      cronTime: _cronTime,
      content: _content,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.enable = _enable
    this.cronTime = _cronTime
    this.content = _content
  }

  static propKeys = ['enable', 'cronTime', 'content']
}

import { AttendanceBizTypeEnum } from './attendance-biz-type-enum'
import { AttendanceGroup, AttendanceGroupInterface } from './attendance-group'

export interface BaseAttendanceInterface {
  schoolId: number
  semesterId: number
  groupId: number
  bizType: AttendanceBizTypeEnum
  bizId: number
  /**
   * 是否需要考勤
   */
  enable: boolean
  group?: AttendanceGroupInterface | null
  /**
   * 已考勤的 attendance_time_ids
   */
  checkedAttendanceTimeIds?: number[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class BaseAttendance implements BaseAttendanceInterface {
  schoolId: number
  semesterId: number
  groupId: number
  bizType: AttendanceBizTypeEnum
  bizId: number
  /**
   * 是否需要考勤
   */
  enable: boolean
  group?: AttendanceGroup | null
  /**
   * 已考勤的 attendance_time_ids
   */
  checkedAttendanceTimeIds?: number[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: BaseAttendanceInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      semesterId: _semesterId,
      groupId: _groupId,
      bizType: _bizType,
      bizId: _bizId,
      enable: _enable,
      group: _group,
      checkedAttendanceTimeIds: _checkedAttendanceTimeIds,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.semesterId = _semesterId
    this.groupId = _groupId
    this.bizType = _bizType
    this.bizId = _bizId
    this.enable = _enable
    this.group = _group ? new AttendanceGroup(_group) : _group
    this.checkedAttendanceTimeIds = _checkedAttendanceTimeIds
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'schoolId',
    'semesterId',
    'groupId',
    'bizType',
    'bizId',
    'enable',
    'group',
    'checkedAttendanceTimeIds',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

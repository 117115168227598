import { compact, createEnumHelper } from '@seiue/util'

import { getClassDisplayName } from 'packages/feature-utils/classes/class-name'
import { $t } from 'packages/locale/LocaleProvider'
import {
  AssessmentStageStatusEnum,
  AssessmentStatusEnum,
  EvaluateTargetEnum,
  PlanTypeEnum,
} from 'packages/sdks-next/vnas'

import { Assessment as AssessmentBase } from '../assessment'
import { AssessmentScopeTypeEnum } from '../assessment-scope-type-enum'
import { AssessmentTypeEnum } from '../assessment-type-enum'
import { MoralAssessmentTypeEnum } from '../moral-assessment-type-enum'

export type { AssessmentInterface } from '../assessment'

const getAssessmentType = (
  type: MoralAssessmentTypeEnum | AssessmentTypeEnum,
) => {
  switch (type) {
    case MoralAssessmentTypeEnum.Moral:
    case AssessmentTypeEnum.Moral:
      return AssessmentTypeEnum.Moral
    case MoralAssessmentTypeEnum.TeacherAssessment:
    case AssessmentTypeEnum.TeacherAssessment:
      return AssessmentTypeEnum.TeacherAssessment
    default:
      return type
  }
}

const typeToShortLabel = createEnumHelper(() => ({
  [AssessmentTypeEnum.Class]: $t('评价'),
  [AssessmentTypeEnum.Dorm]: $t('评价'),
  [AssessmentTypeEnum.Moral]: $t('评价'),
  [AssessmentTypeEnum.TeacherAssessment]: $t('考核'),
}))

const typeToLabel = createEnumHelper(() => ({
  [AssessmentTypeEnum.Class]: $t('班级评价'),
  [AssessmentTypeEnum.Dorm]: $t('宿舍评价'),
  [AssessmentTypeEnum.Moral]: $t('德育评价'),
  [AssessmentTypeEnum.TeacherAssessment]: $t('教师考核'),
}))

const evaluateTargetToLabel = createEnumHelper(() => ({
  [EvaluateTargetEnum.Reflection]: $t('学生'),
  [EvaluateTargetEnum.ClassGroup]: $t('课程班'),
  [EvaluateTargetEnum.AdminclassGroup]: $t('行政班'),
  [EvaluateTargetEnum.DormGroup]: $t('宿舍'),
}))

/**
 * 评价实例
 */
export class Assessment extends AssessmentBase {
  /**
   * 获取课程完整名称
   *
   * @param isEn - 是否为英文
   * @returns 课程完整名称 = 课程名称 + 年级 + 班级
   */
  getDisplayFullClassName(isEn?: boolean) {
    if (this.klass) {
      return getClassDisplayName(this.klass, isEn)
    }

    return undefined
  }

  /**
   * 获取 type (类型) 对应的文字标签
   *
   * @returns 标签
   */
  get typeLabel() {
    return typeToLabel.get(this.type)
  }

  /**
   * 获取评价对象的文字标签
   *
   * @returns 标签
   */
  get evaluateTargetLabel() {
    return this.evaluateTarget
      ? evaluateTargetToLabel.get(this.evaluateTarget)
      : this.evaluateTarget
  }

  /**
   * 获取评价对象的单位标签
   *
   * @returns 标签
   */
  get evaluateTargetUnitLabel() {
    if (!this.evaluateTarget) return undefined

    return this.evaluateTarget === EvaluateTargetEnum.Reflection
      ? $t('人')
      : $t('个')
  }

  /**
   * 获取是否为课程班的过程性评价
   *
   * @returns 是否为课程班的过程性评价
   */
  get isClassAssessment() {
    return (
      this.type === AssessmentTypeEnum.Class &&
      this.scopeType === AssessmentScopeTypeEnum.Class &&
      !this.isTemplate
    )
  }

  /**
   * 获取是否为课程库的课程评价模板
   *
   * @returns 是否为课程库的课程评价模板
   */
  get isCourseAssessment() {
    return (
      this.type === AssessmentTypeEnum.Class &&
      this.scopeType === AssessmentScopeTypeEnum.Course &&
      this.isTemplate
    )
  }

  /**
   * 获取是否是宿舍类型的评价
   *
   * @returns 是否是宿舍类型的评价
   */
  get isDormAssessment() {
    return (
      this.type === AssessmentTypeEnum.Dorm &&
      this.scopeType === AssessmentScopeTypeEnum.Scope
    )
  }

  /**
   * 获取是否是德育评价
   *
   * @returns 是否是德育评价
   */
  get isMoralAssessment() {
    return this.type === AssessmentTypeEnum.Moral
  }

  /**
   * 获取是否是教师考核
   *
   * @returns 是否是教师考核
   */
  get isTeacherAssessment() {
    return this.type === AssessmentTypeEnum.TeacherAssessment
  }

  /**
   * 获取是否是行政班的德育评价
   *
   * @returns 是否是行政班的德育评价
   */
  get isAdminClassMoral() {
    return (
      !!this.evaluateTarget &&
      this.evaluateTarget === EvaluateTargetEnum.AdminclassGroup
    )
  }

  /**
   * 评价是否已发布
   *
   * @returns 是否
   */
  get isPublished() {
    return this.status === AssessmentStatusEnum.Published
  }

  /**
   * 是否为等级评价标准
   *
   * @returns 是否
   */
  get isLevelPlan() {
    return this.plan?.type === PlanTypeEnum.Level
  }

  /**
   * 关联评价标准的等级列表
   *
   * @returns 等级列表
   */
  get planLevels() {
    // 是否为「按排名及分数」的评价标准
    const isCompositePlan = this.plan?.type === PlanTypeEnum.Composite
    const rules =
      (isCompositePlan
        ? this.plan?.rules.filter(rule => rule.disabled)
        : this.plan?.rules) ?? []

    return compact([...rules, this.plan?.passRule].map(rule => rule?.level))
  }

  /**
   * 判断指定的学段是否已发布
   *
   * @param stageId - 指定学段 id
   * @returns 是否
   */
  isStagePublished(stageId: number) {
    return !!this.assessmentStages?.some(
      stage =>
        stage.stageId === stageId &&
        stage.status === AssessmentStageStatusEnum.Published,
    )
  }

  /**
   * 判断指定的学段是否已通过
   *
   * @param stageId - 指定学段 id
   * @returns 是否
   */
  isStagePassed(stageId: number) {
    return !!this.assessmentStages?.some(
      stage =>
        stage.stageId === stageId &&
        stage.status === AssessmentStageStatusEnum.Passed,
    )
  }

  /**
   * 获取指定学段的发布状态
   *
   * @param stageId - 指定学段 id
   * @returns 发布状态
   */
  getStageStatus(stageId: number) {
    return this.assessmentStages?.find(stage => stage.stageId === stageId)
      ?.status
  }

  /**
   * 获取评价类型的 label
   *
   * @param operationType - 评价类型
   * @returns label
   */
  static getTypeLabel(
    operationType: MoralAssessmentTypeEnum | AssessmentTypeEnum,
  ) {
    const type = getAssessmentType(operationType)
    return typeToLabel.get(type)
  }

  /**
   * 获取评价类型的 short label
   *
   * @param operationType - 评价类型
   * @returns label
   */
  static getTypeShortLabel(
    operationType: MoralAssessmentTypeEnum | AssessmentTypeEnum,
  ) {
    const type = getAssessmentType(operationType)
    return typeToShortLabel.get(type)
  }
}

import { OnlineClassSyncModeEnum } from './online-class-sync-mode-enum'
import { LessonDateScope, LessonDateScopeInterface } from './lesson-date-scope'

export interface BaseOnlineClassInterface {
  /**
   * 渠道课程id
   */
  channelClassId?: number | null
  /**
   * 渠道课程名称
   */
  channelClassName: string
  /**
   * 自定义渠道课程名称
   */
  customChannelClassName?: string | null
  /**
   * 最终渠道课程名称
   */
  finalChannelClassName: string
  /**
   * 云课堂教师
   */
  channelTeacherRids?: number[]
  /**
   * 云课堂教师名称，为了有效地避免跨校查询用户，这里直接冗余名称
   */
  channelTeacherNames?: string[] | null
  /**
   * 渠道
   */
  channel: string
  /**
   * 同步模式
   */
  syncMode: OnlineClassSyncModeEnum
  /**
   * 学期id
   */
  semesterId: number
  /**
   * 课程班id
   */
  classId: number
  /**
   * 是否结课
   */
  isFinished?: boolean
  /**
   * 课程开始时间，兼容公开课
   */
  startTime?: string | null
  /**
   * 课程结束时间，兼容公开课
   */
  endTime?: string | null
  /**
   * 课程过期时间，兼容公开课
   */
  expireTime?: string | null
  /**
   * 已完成课节数：0
   */
  finishedLessonNums: number
  /**
   * 线上课课节数
   */
  totalLessonNums: number
  /**
   * 线上课总时长（分钟）
   */
  totalTime: number
  /**
   * 录课数:0
   */
  recordNums: number
  /**
   * 直播数:0
   */
  liveNums: number
  /**
   * 回放数:0
   */
  replayNums: number
  /**
   * 未绑定reflection_ids
   */
  unbindMemberRids?: number[]
  /**
   * 课程回放地址
   */
  replayUrl?: string
  /**
   * 每次错误产生的错误信息(包含课节和学生的)
   */
  errors?: string[]
  /**
   * 是否需要同步的标志，结合 errors 来判断该数据是否要被修复
   */
  needSynced?: boolean
  /**
   * 课节更新范围 [["start_at_egt" => "xxx", "end_at_elt" => "xxx"]]
   */
  lessonDateScopes?: LessonDateScopeInterface[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class BaseOnlineClass implements BaseOnlineClassInterface {
  /**
   * 渠道课程id
   */
  channelClassId?: number | null
  /**
   * 渠道课程名称
   */
  channelClassName: string
  /**
   * 自定义渠道课程名称
   */
  customChannelClassName?: string | null
  /**
   * 最终渠道课程名称
   */
  finalChannelClassName: string
  /**
   * 云课堂教师
   */
  channelTeacherRids?: number[]
  /**
   * 云课堂教师名称，为了有效地避免跨校查询用户，这里直接冗余名称
   */
  channelTeacherNames?: string[] | null
  /**
   * 渠道
   */
  channel: string
  /**
   * 同步模式
   */
  syncMode: OnlineClassSyncModeEnum
  /**
   * 学期id
   */
  semesterId: number
  /**
   * 课程班id
   */
  classId: number
  /**
   * 是否结课
   */
  isFinished?: boolean
  /**
   * 课程开始时间，兼容公开课
   */
  startTime?: string | null
  /**
   * 课程结束时间，兼容公开课
   */
  endTime?: string | null
  /**
   * 课程过期时间，兼容公开课
   */
  expireTime?: string | null
  /**
   * 已完成课节数：0
   */
  finishedLessonNums: number
  /**
   * 线上课课节数
   */
  totalLessonNums: number
  /**
   * 线上课总时长（分钟）
   */
  totalTime: number
  /**
   * 录课数:0
   */
  recordNums: number
  /**
   * 直播数:0
   */
  liveNums: number
  /**
   * 回放数:0
   */
  replayNums: number
  /**
   * 未绑定reflection_ids
   */
  unbindMemberRids?: number[]
  /**
   * 课程回放地址
   */
  replayUrl?: string
  /**
   * 每次错误产生的错误信息(包含课节和学生的)
   */
  errors?: string[]
  /**
   * 是否需要同步的标志，结合 errors 来判断该数据是否要被修复
   */
  needSynced?: boolean
  /**
   * 课节更新范围 [["start_at_egt" => "xxx", "end_at_elt" => "xxx"]]
   */
  lessonDateScopes?: LessonDateScope[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: BaseOnlineClassInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      channelClassId: _channelClassId,
      channelClassName: _channelClassName,
      customChannelClassName: _customChannelClassName,
      finalChannelClassName: _finalChannelClassName,
      channelTeacherRids: _channelTeacherRids,
      channelTeacherNames: _channelTeacherNames,
      channel: _channel,
      syncMode: _syncMode,
      semesterId: _semesterId,
      classId: _classId,
      isFinished: _isFinished,
      startTime: _startTime,
      endTime: _endTime,
      expireTime: _expireTime,
      finishedLessonNums: _finishedLessonNums,
      totalLessonNums: _totalLessonNums,
      totalTime: _totalTime,
      recordNums: _recordNums,
      liveNums: _liveNums,
      replayNums: _replayNums,
      unbindMemberRids: _unbindMemberRids,
      replayUrl: _replayUrl,
      errors: _errors,
      needSynced: _needSynced,
      lessonDateScopes: _lessonDateScopes,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.channelClassId = _channelClassId
    this.channelClassName = _channelClassName
    this.customChannelClassName = _customChannelClassName
    this.finalChannelClassName = _finalChannelClassName
    this.channelTeacherRids = _channelTeacherRids
    this.channelTeacherNames = _channelTeacherNames
    this.channel = _channel
    this.syncMode = _syncMode
    this.semesterId = _semesterId
    this.classId = _classId
    this.isFinished = _isFinished
    this.startTime = _startTime
    this.endTime = _endTime
    this.expireTime = _expireTime
    this.finishedLessonNums = _finishedLessonNums
    this.totalLessonNums = _totalLessonNums
    this.totalTime = _totalTime
    this.recordNums = _recordNums
    this.liveNums = _liveNums
    this.replayNums = _replayNums
    this.unbindMemberRids = _unbindMemberRids
    this.replayUrl = _replayUrl
    this.errors = _errors
    this.needSynced = _needSynced
    this.lessonDateScopes = _lessonDateScopes
      ? _lessonDateScopes.map(i => new LessonDateScope(i))
      : _lessonDateScopes
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'channelClassId',
    'channelClassName',
    'customChannelClassName',
    'finalChannelClassName',
    'channelTeacherRids',
    'channelTeacherNames',
    'channel',
    'syncMode',
    'semesterId',
    'classId',
    'isFinished',
    'startTime',
    'endTime',
    'expireTime',
    'finishedLessonNums',
    'totalLessonNums',
    'totalTime',
    'recordNums',
    'liveNums',
    'replayNums',
    'unbindMemberRids',
    'replayUrl',
    'errors',
    'needSynced',
    'lessonDateScopes',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

import { ScoreAssessment, ScoreAssessmentInterface } from './score-assessment'
import { Item, ItemInterface } from './extends/item'
import { ScoreTypeEnum } from './score-type-enum'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import { Term, TermInterface } from './extends/term'
import { Attachment, AttachmentInterface } from './attachment'
import { ScoreRelative, ScoreRelativeInterface } from './score-relative'
import { ScoreStatusEnum } from './score-status-enum'
import { ResitStatus } from './resit-status'
import { ScoreStatus } from './score-status'
import {
  ScoreAttendanceInfo,
  ScoreAttendanceInfoInterface,
} from './score-attendance-info'
import { OwnerStatusEnum } from './owner-status-enum'

export interface AchievedScoreInterface {
  /**
   * 班级排名
   */
  rank?: number | null
  /**
   * 排名基数
   */
  rankBase?: number | null
  level?: string | null
  star?: number | null
  gainedLevel?: string | null
  gainedStar?: number | null
  schoolId: number
  assessmentId: number
  assessment?: ScoreAssessmentInterface | null
  itemId: number
  item?: ItemInterface
  type: ScoreTypeEnum
  ownerId: number
  owner?: SecuredReflectionInterface
  /**
   * 打分人，可以被覆盖。加减分初始分时为 null
   */
  evaluatorId?: number | null
  /**
   * 打分人，加减分初始分时为 null
   */
  evaluator?: SecuredReflectionInterface | null
  /**
   * 有效分数（是否参与评价）
   */
  valid: boolean
  /**
   * 暂不评价类型 ID ，暂不评价时必填
   */
  invalidTypeId?: number | null
  /**
   * 暂不评价类型 expanded
   */
  invalidType?: TermInterface | null
  /**
   * 失效原因（不参与评价原因）
   */
  invalidReason?: string
  /**
   * 标签、加减分标签、等级复用 对应 item.tags.[].name
   */
  tag?: string
  /**
   * 区分空和 '0'
   */
  score?: string | null
  /**
   * score 字段打分人 rid （仅 klass 评价有）
   */
  scoreEvaluatedBy?: number | null
  gainedScoreRate?: string | null
  /**
   * 学生已得分（维度分可用，如果没有评价项发布则为 null）
   */
  gainedScore?: string | null
  gainedScoreRank?: number | null
  gainedScoreRankBase?: number | null
  /**
   * 评语
   */
  review?: string
  attachments?: AttachmentInterface[]
  editReason?: string
  /**
   * 初次发布时间，每个分数可能因为任务关联而单独发布；标签和加减分没有发布的概念
   */
  publishedAt?: string | null
  publishedBy?: number | null
  /**
   * 反思
   */
  selfReview?: string
  /**
   * 关联数据，时期和课节名称
   */
  relatedData?: ScoreRelativeInterface
  createdBy?: number | null
  updatedBy?: number | null
  status: ScoreStatusEnum
  avgScore?: string | null
  /**
   * 补考fields
   */
  resitStatus?: ResitStatus | null
  resitScore?: string | null
  resitScoreStatus?: ScoreStatus | null
  resitInvalidReason?: string | null
  resitScoreId?: number | null
  /**
   * 宿舍加减分类型时，记录学生行为发生的时间
   */
  generatedDate?: string | null
  /**
   * 考勤记录信息，包含考勤课节、班级、考勤录入人信息
   */
  attendanceInfo?: ScoreAttendanceInfoInterface | null
  /**
   * 成员状态
   */
  ownerStatus: OwnerStatusEnum
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class AchievedScore implements AchievedScoreInterface {
  /**
   * 班级排名
   */
  rank?: number | null
  /**
   * 排名基数
   */
  rankBase?: number | null
  level?: string | null
  star?: number | null
  gainedLevel?: string | null
  gainedStar?: number | null
  schoolId: number
  assessmentId: number
  assessment?: ScoreAssessment | null
  itemId: number
  item?: Item
  type: ScoreTypeEnum
  ownerId: number
  owner?: SecuredReflection
  /**
   * 打分人，可以被覆盖。加减分初始分时为 null
   */
  evaluatorId?: number | null
  /**
   * 打分人，加减分初始分时为 null
   */
  evaluator?: SecuredReflection | null
  /**
   * 有效分数（是否参与评价）
   */
  valid: boolean
  /**
   * 暂不评价类型 ID ，暂不评价时必填
   */
  invalidTypeId?: number | null
  /**
   * 暂不评价类型 expanded
   */
  invalidType?: Term | null
  /**
   * 失效原因（不参与评价原因）
   */
  invalidReason?: string
  /**
   * 标签、加减分标签、等级复用 对应 item.tags.[].name
   */
  tag?: string
  /**
   * 区分空和 '0'
   */
  score?: string | null
  /**
   * score 字段打分人 rid （仅 klass 评价有）
   */
  scoreEvaluatedBy?: number | null
  gainedScoreRate?: string | null
  /**
   * 学生已得分（维度分可用，如果没有评价项发布则为 null）
   */
  gainedScore?: string | null
  gainedScoreRank?: number | null
  gainedScoreRankBase?: number | null
  /**
   * 评语
   */
  review?: string
  attachments?: Attachment[]
  editReason?: string
  /**
   * 初次发布时间，每个分数可能因为任务关联而单独发布；标签和加减分没有发布的概念
   */
  publishedAt?: string | null
  publishedBy?: number | null
  /**
   * 反思
   */
  selfReview?: string
  /**
   * 关联数据，时期和课节名称
   */
  relatedData?: ScoreRelative
  createdBy?: number | null
  updatedBy?: number | null
  status: ScoreStatusEnum
  avgScore?: string | null
  /**
   * 补考fields
   */
  resitStatus?: ResitStatus | null
  resitScore?: string | null
  resitScoreStatus?: ScoreStatus | null
  resitInvalidReason?: string | null
  resitScoreId?: number | null
  /**
   * 宿舍加减分类型时，记录学生行为发生的时间
   */
  generatedDate?: string | null
  /**
   * 考勤记录信息，包含考勤课节、班级、考勤录入人信息
   */
  attendanceInfo?: ScoreAttendanceInfo | null
  /**
   * 成员状态
   */
  ownerStatus: OwnerStatusEnum
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: AchievedScoreInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      rank: _rank,
      rankBase: _rankBase,
      level: _level,
      star: _star,
      gainedLevel: _gainedLevel,
      gainedStar: _gainedStar,
      schoolId: _schoolId,
      assessmentId: _assessmentId,
      assessment: _assessment,
      itemId: _itemId,
      item: _item,
      type: _type,
      ownerId: _ownerId,
      owner: _owner,
      evaluatorId: _evaluatorId,
      evaluator: _evaluator,
      valid: _valid,
      invalidTypeId: _invalidTypeId,
      invalidType: _invalidType,
      invalidReason: _invalidReason,
      tag: _tag,
      score: _score,
      scoreEvaluatedBy: _scoreEvaluatedBy,
      gainedScoreRate: _gainedScoreRate,
      gainedScore: _gainedScore,
      gainedScoreRank: _gainedScoreRank,
      gainedScoreRankBase: _gainedScoreRankBase,
      review: _review,
      attachments: _attachments,
      editReason: _editReason,
      publishedAt: _publishedAt,
      publishedBy: _publishedBy,
      selfReview: _selfReview,
      relatedData: _relatedData,
      createdBy: _createdBy,
      updatedBy: _updatedBy,
      status: _status,
      avgScore: _avgScore,
      resitStatus: _resitStatus,
      resitScore: _resitScore,
      resitScoreStatus: _resitScoreStatus,
      resitInvalidReason: _resitInvalidReason,
      resitScoreId: _resitScoreId,
      generatedDate: _generatedDate,
      attendanceInfo: _attendanceInfo,
      ownerStatus: _ownerStatus,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.rank = _rank
    this.rankBase = _rankBase
    this.level = _level
    this.star = _star
    this.gainedLevel = _gainedLevel
    this.gainedStar = _gainedStar
    this.schoolId = _schoolId
    this.assessmentId = _assessmentId
    this.assessment = _assessment
      ? new ScoreAssessment(_assessment)
      : _assessment
    this.itemId = _itemId
    this.item = _item ? new Item(_item) : _item
    this.type = _type
    this.ownerId = _ownerId
    this.owner = _owner ? new SecuredReflection(_owner) : _owner
    this.evaluatorId = _evaluatorId
    this.evaluator = _evaluator ? new SecuredReflection(_evaluator) : _evaluator
    this.valid = _valid
    this.invalidTypeId = _invalidTypeId
    this.invalidType = _invalidType ? new Term(_invalidType) : _invalidType
    this.invalidReason = _invalidReason
    this.tag = _tag
    this.score = _score
    this.scoreEvaluatedBy = _scoreEvaluatedBy
    this.gainedScoreRate = _gainedScoreRate
    this.gainedScore = _gainedScore
    this.gainedScoreRank = _gainedScoreRank
    this.gainedScoreRankBase = _gainedScoreRankBase
    this.review = _review
    this.attachments = _attachments
      ? _attachments.map(i => new Attachment(i))
      : _attachments
    this.editReason = _editReason
    this.publishedAt = _publishedAt
    this.publishedBy = _publishedBy
    this.selfReview = _selfReview
    this.relatedData = _relatedData
      ? new ScoreRelative(_relatedData)
      : _relatedData
    this.createdBy = _createdBy
    this.updatedBy = _updatedBy
    this.status = _status
    this.avgScore = _avgScore
    this.resitStatus = _resitStatus
    this.resitScore = _resitScore
    this.resitScoreStatus = _resitScoreStatus
    this.resitInvalidReason = _resitInvalidReason
    this.resitScoreId = _resitScoreId
    this.generatedDate = _generatedDate
    this.attendanceInfo = _attendanceInfo
      ? new ScoreAttendanceInfo(_attendanceInfo)
      : _attendanceInfo
    this.ownerStatus = _ownerStatus
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'rank',
    'rankBase',
    'level',
    'star',
    'gainedLevel',
    'gainedStar',
    'schoolId',
    'assessmentId',
    'assessment',
    'itemId',
    'item',
    'type',
    'ownerId',
    'owner',
    'evaluatorId',
    'evaluator',
    'valid',
    'invalidTypeId',
    'invalidType',
    'invalidReason',
    'tag',
    'score',
    'scoreEvaluatedBy',
    'gainedScoreRate',
    'gainedScore',
    'gainedScoreRank',
    'gainedScoreRankBase',
    'review',
    'attachments',
    'editReason',
    'publishedAt',
    'publishedBy',
    'selfReview',
    'relatedData',
    'createdBy',
    'updatedBy',
    'status',
    'avgScore',
    'resitStatus',
    'resitScore',
    'resitScoreStatus',
    'resitInvalidReason',
    'resitScoreId',
    'generatedDate',
    'attendanceInfo',
    'ownerStatus',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

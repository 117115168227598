import { Term, TermInterface } from './extends/term'
import { SchoolPluginStatusEnum } from './school-plugin-status-enum'
import {
  SchoolPluginAdmins,
  SchoolPluginAdminsInterface,
} from './school-plugin-admins'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import {
  SchoolPluginScopes,
  SchoolPluginScopesInterface,
} from './school-plugin-scopes'
import { SchoolPluginShortcutEnum } from './school-plugin-shortcut-enum'
import { ExpandPlugin, ExpandPluginInterface } from './expand-plugin'

export interface SchoolPluginInterface {
  schoolId: number
  pluginId: number
  label: string
  icon: string
  groupId: number
  group?: TermInterface | null
  simpleDescription: string
  parentId?: number | null
  status: SchoolPluginStatusEnum
  enabled?: boolean | null
  admins: SchoolPluginAdminsInterface
  bizId?: number | null
  category?: string
  subPluginLimit?: number
  subPluginNum?: number
  isSet: boolean
  /**
   * expand 时返回
   */
  adminReflections?: SecuredReflectionInterface[]
  scopes: SchoolPluginScopesInterface
  displayScopes: SchoolPluginScopesInterface
  shortcut: SchoolPluginShortcutEnum
  entryUrls: object
  settingUrl: string
  settings?: object | null
  appliedBy?: number | null
  appliedAt?: string | null
  plugin?: ExpandPluginInterface
  applicant?: SecuredReflectionInterface | null
  /**
   * expand，启用时依赖的插件，即如果想启用当前插件，需要先启用依赖的这些插件
   */
  notEnabledDependentPlugins?: ExpandPluginInterface[]
  /**
   * expand，关闭或卸载时依赖的插件，即如果想关闭或卸载当前插件，需要先关闭或卸载依赖的这些插件
   */
  notDisabledDependentPlugins?: ExpandPluginInterface[]
  /**
   * 权重 越小权重越高
   */
  weight?: number
  /**
   * 插件展示的入口 core application_center
   */
  entrypoints?: string[]
  versionId?: number | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class SchoolPlugin implements SchoolPluginInterface {
  schoolId: number
  pluginId: number
  label: string
  icon: string
  groupId: number
  group?: Term | null
  simpleDescription: string
  parentId?: number | null
  status: SchoolPluginStatusEnum
  enabled?: boolean | null
  admins: SchoolPluginAdmins
  bizId?: number | null
  category?: string
  subPluginLimit?: number
  subPluginNum?: number
  isSet: boolean
  /**
   * expand 时返回
   */
  adminReflections?: SecuredReflection[]
  scopes: SchoolPluginScopes
  displayScopes: SchoolPluginScopes
  shortcut: SchoolPluginShortcutEnum
  entryUrls: object
  settingUrl: string
  settings?: object | null
  appliedBy?: number | null
  appliedAt?: string | null
  plugin?: ExpandPlugin
  applicant?: SecuredReflection | null
  /**
   * expand，启用时依赖的插件，即如果想启用当前插件，需要先启用依赖的这些插件
   */
  notEnabledDependentPlugins?: ExpandPlugin[]
  /**
   * expand，关闭或卸载时依赖的插件，即如果想关闭或卸载当前插件，需要先关闭或卸载依赖的这些插件
   */
  notDisabledDependentPlugins?: ExpandPlugin[]
  /**
   * 权重 越小权重越高
   */
  weight?: number
  /**
   * 插件展示的入口 core application_center
   */
  entrypoints?: string[]
  versionId?: number | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: SchoolPluginInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      pluginId: _pluginId,
      label: _label,
      icon: _icon,
      groupId: _groupId,
      group: _group,
      simpleDescription: _simpleDescription,
      parentId: _parentId,
      status: _status,
      enabled: _enabled,
      admins: _admins,
      bizId: _bizId,
      category: _category,
      subPluginLimit: _subPluginLimit,
      subPluginNum: _subPluginNum,
      isSet: _isSet,
      adminReflections: _adminReflections,
      scopes: _scopes,
      displayScopes: _displayScopes,
      shortcut: _shortcut,
      entryUrls: _entryUrls,
      settingUrl: _settingUrl,
      settings: _settings,
      appliedBy: _appliedBy,
      appliedAt: _appliedAt,
      plugin: _plugin,
      applicant: _applicant,
      notEnabledDependentPlugins: _notEnabledDependentPlugins,
      notDisabledDependentPlugins: _notDisabledDependentPlugins,
      weight: _weight,
      entrypoints: _entrypoints,
      versionId: _versionId,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.pluginId = _pluginId
    this.label = _label
    this.icon = _icon
    this.groupId = _groupId
    this.group = _group ? new Term(_group) : _group
    this.simpleDescription = _simpleDescription
    this.parentId = _parentId
    this.status = _status
    this.enabled = _enabled
    this.admins = _admins ? new SchoolPluginAdmins(_admins) : _admins
    this.bizId = _bizId
    this.category = _category
    this.subPluginLimit = _subPluginLimit
    this.subPluginNum = _subPluginNum
    this.isSet = _isSet
    this.adminReflections = _adminReflections
      ? _adminReflections.map(i => new SecuredReflection(i))
      : _adminReflections
    this.scopes = _scopes ? new SchoolPluginScopes(_scopes) : _scopes
    this.displayScopes = _displayScopes
      ? new SchoolPluginScopes(_displayScopes)
      : _displayScopes
    this.shortcut = _shortcut
    this.entryUrls = _entryUrls
    this.settingUrl = _settingUrl
    this.settings = _settings
    this.appliedBy = _appliedBy
    this.appliedAt = _appliedAt
    this.plugin = _plugin ? new ExpandPlugin(_plugin) : _plugin
    this.applicant = _applicant ? new SecuredReflection(_applicant) : _applicant
    this.notEnabledDependentPlugins = _notEnabledDependentPlugins
      ? _notEnabledDependentPlugins.map(i => new ExpandPlugin(i))
      : _notEnabledDependentPlugins
    this.notDisabledDependentPlugins = _notDisabledDependentPlugins
      ? _notDisabledDependentPlugins.map(i => new ExpandPlugin(i))
      : _notDisabledDependentPlugins
    this.weight = _weight
    this.entrypoints = _entrypoints
    this.versionId = _versionId
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'schoolId',
    'pluginId',
    'label',
    'icon',
    'groupId',
    'group',
    'simpleDescription',
    'parentId',
    'status',
    'enabled',
    'admins',
    'bizId',
    'category',
    'subPluginLimit',
    'subPluginNum',
    'isSet',
    'adminReflections',
    'scopes',
    'displayScopes',
    'shortcut',
    'entryUrls',
    'settingUrl',
    'settings',
    'appliedBy',
    'appliedAt',
    'plugin',
    'applicant',
    'notEnabledDependentPlugins',
    'notDisabledDependentPlugins',
    'weight',
    'entrypoints',
    'versionId',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

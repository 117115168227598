/**
 * @file 全屏弹窗标准 header
 */

import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { Icon } from '@seiue/ui'
import React from 'react'
import styled from 'styled-components'

import { BackgroundColor } from 'packages/theme'

export interface HeaderProps {
  title?: React.ReactNode
  headerRight?: React.ReactNode
  headerBottom?: React.ReactNode
  contentWidth: string
  style?: React.CSSProperties
  onClose?: (e: React.MouseEvent<HTMLElement>) => void
}

/**
 * 全屏弹窗的 Header
 *
 * @param {HeaderProps} param0 - 参数对象
 * @returns FC
 */
export const Header: React.FC<HeaderProps> = ({
  contentWidth,
  style,
  title,
  headerRight,
  headerBottom,
  onClose,
}) => (
  <HeaderWrapper style={style}>
    <ModalHeaderWrapper>
      <HeaderAvailibleBox width={contentWidth}>
        <Title>{title}</Title>
        <HeaderRight>
          {headerRight}
          {onClose ? (
            <CloseButton onClick={onClose}>
              <Icon
                style={{ fontSize: '24px', cursor: 'pointer' }}
                icon={faTimes}
              />
            </CloseButton>
          ) : null}
        </HeaderRight>
      </HeaderAvailibleBox>
    </ModalHeaderWrapper>

    {headerBottom}
  </HeaderWrapper>
)

const HeaderWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const ModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 60px;
  background-color: ${BackgroundColor._0};
`

const HeaderAvailibleBox = styled.div<{ width: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${props => props.width};
`

const Title = styled.h1`
  margin: 0;
  font-size: 24px;
  line-height: 33px;
`

const CloseButton = styled.div`
  width: 24px;
  height: 24px;
  text-align: center;
  cursor: pointer;
`

export enum MenuType {
  // 工作台 - 默认菜单
  Default = 'default',

  // 工作台 - 应用中心
  Apps = 'apps',

  // 管理中心 - 核心功能
  Admin = 'admin',

  // 管理中心 - 应用管理
  AdminApps = 'adminApps',
}

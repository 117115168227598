import { replaceRangeWithChar } from '@seiue/util'
import { useCallback } from 'react'

import { useIsEnLocale } from 'packages/locale/hooks'

/**
 * 能根据当前语言环境动态展示 Reflection 姓名的最小属性类型
 */
export type NameReflection = {
  name: string
  pinyin?: string | null
  ename?: string | null
  usin?: string | null | undefined
}

/**
 * 根据当前语言环境显示 Reflection 姓名
 *
 * @param params - 参数
 * @param params.reflection - Reflection
 * @param params.options - 选项
 * @param params.options.usePrivate - 是否使用私有名
 * @param params.options.withUsin - 是否显示 usin
 * @param isEn - 是否为英文
 * @returns string
 */
export const getI18nName = (
  {
    reflection,
    options,
  }: {
    reflection?: null | NameReflection | NameReflection[]
    options?: { usePrivate?: boolean; withUsin?: boolean }
  },
  isEn?: boolean,
) => {
  if (!reflection) return ''

  const reflections = Array.isArray(reflection) ? reflection : [reflection]
  const { usePrivate, withUsin } = options || {}
  return reflections
    .map(refl => {
      const name = isEn ? refl.ename || refl.pinyin || refl.name : refl.name
      const finalName = usePrivate
        ? replaceRangeWithChar(name, isEn ? 4 : 1, name.length - 1, '*')
        : name

      return withUsin && refl.usin ? `${finalName}/${refl.usin}` : finalName
    })
    .join(isEn ? ', ' : '、')
}

/**
 * 返回一个 $name 方法, 可根据多语言设置获取一个或多个 reflection 的英文名 (fallback 至拼音) 或中文名.
 * 如果是多个 reflection, 中文名会用 、分隔, 英文名用 , 分隔.
 *
 * 返回的方法中的第二个可选参数可传入一些可选的配置，目前支持的配置有：
 * 1. usePrivate 参数用于控制是否将部分字符以 * 显示，用于隐私保护。
 *    中文环境下为第一个字符至最后一个字符，英文环境下为第四个字符至最后一个字符。
 * 2. withUsin 参数用于控制是否在名字后添加 usin
 *
 * @returns $name 方法
 * @example
 * ```javascript
 * const $name = useI18nName()
 * const name = $name(student)
 * const names = $name(students)
 * ```
 */
export const useI18nName = () => {
  const isEn = useIsEnLocale()

  return useCallback(
    (
      reflection?: null | NameReflection | NameReflection[],
      options?: { usePrivate?: boolean; withUsin?: boolean },
    ) => {
      return getI18nName({ reflection, options }, isEn)
    },
    [isEn],
  )
}

/**
 * 获取人员列表在多语言环境下的 pinyin 排序值
 *
 * 在中文下使用 pinyin，英文下使用 ename（若 ename 值为空，后端会拿 pinyin 填充）
 * 若不符合转换规则，则返回原值
 *
 * @param sort - 排序值
 * @returns 适配多语言环境后的排序值
 */
export const useReflectionsPinyinSort = (sort?: string) => {
  const isEn = useIsEnLocale()

  // 非英文环境或者值为空，返回原值
  if (!isEn || !sort) return sort

  return (
    {
      pinyin: 'ename',
      '-pinyin': '-ename',
    }[sort] || sort
  )
}

/**
 * @file 注册出入校的消息跳转路由
 */

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator(['visitor.delayed_leave'], message => {
  const attributes = message.attributes as {
    flowId?: number
  }

  const { flowId } = attributes
  if (!flowId) return null

  return {
    modalName: 'ShowFlowModal',
    options: { flowId, workflowBizType: 'visitor' },
  }
})

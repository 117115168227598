/**
 * 学生档案中允许定义三级 Tab 的路由 Name
 */
export enum StudentProfileSubRoute {
  // 目标
  Goals = 'Goals',
  // 成绩
  Grades = 'Grades',
  // 近况
  Recent = 'Recent',
  // 群组
  Groups = 'Groups',
  // 深圳中学-定制成绩单，非公共插件
  SZGradesReports = 'SZGradesReports',
  // 学籍表 (学籍管理插件)
  StudentStatusProfile = 'StudentStatusProfile',
  // 认证
  Certifications = 'Certifications',
}

/**
 * 教师档案中允许定义三级 Tab 的路由 Name
 */
export enum TeacherProfileSubRoute {
  // 近况
  Recent = 'Recent',
  // 群组
  Customized = 'Customized',
  // 评价
  Evaluation = 'Evaluation',
  // 考试
  Exams = 'Exams',
}

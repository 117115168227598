import { isEqual } from '@seiue/util'
import { useAtom } from 'jotai'

import {
  openedMenusAtom,
  currentMenuTypesAtom,
  MenuRef,
} from 'packages/feature-utils/menus/atoms'

/**
 * 返回一个函数，用于打开菜单
 *
 * @returns 打开菜单的函数
 */
export const useOpenMenuFunction = () => {
  const [menuRefs, set] = useAtom(openedMenusAtom)

  return (menuRef: MenuRef) => {
    if (menuRefs.find(ref => isEqual(ref, menuRef))) {
      return
    }

    menuRefs.push(menuRef)

    set([...menuRefs])
  }
}

export const useCurrentMenuTypes = () => {
  return useAtom(currentMenuTypesAtom)[0]
}

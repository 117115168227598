import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import {
  NetdiskFileWithoutOwner,
  NetdiskFileWithoutOwnerInterface,
} from './netdisk-file-without-owner'

export interface NetdiskOwnerInterface {
  /**
   * 用户层面的 reflection_id 群组层面的 group_id
   */
  ownerId: number
  /**
   * value：student,teacher,guardian,staff,seiue.class_group,seiue.course_group,shared
   * 自定义群组：可通过 queryGroupTypes 获取
   */
  type?: string
  /**
   * 总容量
   */
  totalCapacity: number
  /**
   * 已使用容量
   */
  usedCapacity: number
  /**
   * 使用率
   */
  usedRate: string
  /**
   * 因 owners 中有 group 和 reflection，但是类型不能共存所以此处只 expand $owner_name
   */
  ownerName?: string
  /**
   * 所有者
   */
  ownerManagers?: SecuredReflectionInterface[]
  /**
   * 共享网盘顶级file信息
   */
  topFile?: NetdiskFileWithoutOwnerInterface | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
}

export class NetdiskOwner implements NetdiskOwnerInterface {
  /**
   * 用户层面的 reflection_id 群组层面的 group_id
   */
  ownerId: number
  /**
   * value：student,teacher,guardian,staff,seiue.class_group,seiue.course_group,shared
   * 自定义群组：可通过 queryGroupTypes 获取
   */
  type?: string
  /**
   * 总容量
   */
  totalCapacity: number
  /**
   * 已使用容量
   */
  usedCapacity: number
  /**
   * 使用率
   */
  usedRate: string
  /**
   * 因 owners 中有 group 和 reflection，但是类型不能共存所以此处只 expand $owner_name
   */
  ownerName?: string
  /**
   * 所有者
   */
  ownerManagers?: SecuredReflection[]
  /**
   * 共享网盘顶级file信息
   */
  topFile?: NetdiskFileWithoutOwner | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: NetdiskOwnerInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      ownerId: _ownerId,
      type: _type,
      totalCapacity: _totalCapacity,
      usedCapacity: _usedCapacity,
      usedRate: _usedRate,
      ownerName: _ownerName,
      ownerManagers: _ownerManagers,
      topFile: _topFile,
      id: _id,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.ownerId = _ownerId
    this.type = _type
    this.totalCapacity = _totalCapacity
    this.usedCapacity = _usedCapacity
    this.usedRate = _usedRate
    this.ownerName = _ownerName
    this.ownerManagers = _ownerManagers
      ? _ownerManagers.map(i => new SecuredReflection(i))
      : _ownerManagers
    this.topFile = _topFile ? new NetdiskFileWithoutOwner(_topFile) : _topFile
    this.id = _id
    this.schoolId = _schoolId
  }

  static propKeys = [
    'ownerId',
    'type',
    'totalCapacity',
    'usedCapacity',
    'usedRate',
    'ownerName',
    'ownerManagers',
    'topFile',
    'id',
    'schoolId',
  ]
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { CustomGroup, CustomGroupInterface } from './custom-group'
import type { LoadCustomGroupQuery } from './load-custom-group-query'

const buildConfig = createConfigBuilder('chalk')

export const customGroupApi$loadCustomGroup = {
  config(
    id: number,
    loadCustomGroupQuery?: LoadCustomGroupQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/custom_group/custom_groups/{id}',
      {
        id,
      },
      loadCustomGroupQuery,
    )
  },

  /**
   * @summary Class LoadCustomGroupApi
   * @param id 群组ID
   * @param [loadCustomGroupQuery]
   * @param [customGroupQuery.type]
   * @param [customGroupQuery.expand] expand
   * - members
   * - members.reflection : 成员基本信息
   * - members.reflection
   * - managers
   * - manageds
   * - related
   * - teams.members.reflection
   * - members.teams
   * - type_entity (仅用于通用群组)
   * @param [options]
   * @return AxiosResponsePromise<CustomGroup>
   */

  api<const TQuery extends LoadCustomGroupQuery>(
    id: number,
    loadCustomGroupQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<CustomGroup, TQuery['expand']>> {
    return axios(
      customGroupApi$loadCustomGroup.config(id, loadCustomGroupQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new CustomGroup(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends LoadCustomGroupQuery,
    TSelected = Expand<CustomGroup, TQuery['expand']>,
  >(
    {
      id,
      query,
    }: {
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<CustomGroup, TQuery['expand']>,
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<CustomGroup, TQuery['expand']>, TSelected>(
      customGroupApi$loadCustomGroup.api,
      'CustomGroup.loadCustomGroup',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
customGroupApi$loadCustomGroup.api._name_ = `CustomGroup.loadCustomGroup`

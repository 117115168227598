/**
 * @file 全屏弹窗通用底部操作栏
 */

import { Button, ButtonProps } from '@seiue/ui'
import React from 'react'
import styled from 'styled-components'

import { $t } from 'packages/locale'

export interface FooterProps {
  contentWidth: string
  cancelText?: React.ReactNode
  cancelButtonProps?: ButtonProps
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void
  okText?: React.ReactNode
  okButtonProps?: ButtonProps
  okType?: ButtonProps['type']
  onOk?: (e: React.MouseEvent<HTMLElement>) => void
  style?: React.CSSProperties
  /**
   * footer 按钮区左侧的 slot
   */
  footerActionsLeft?: React.ReactNode
}

/**
 * 全屏弹窗通用底部操作栏
 *
 * @param param0 - 参数
 * @param param0.contentWidth - 内容区域宽度
 * @param param0.cancelText - 取消按钮文案
 * @param param0.cancelButtonProps - 取消按钮属性
 * @param param0.onCancel - 取消按钮点击回调
 * @param param0.okText - 确认按钮文案
 * @param param0.okButtonProps - 确认按钮属性
 * @param param0.okType - 确认按钮类型
 * @param param0.onOk - 确认按钮点击回调
 * @param param0.style - 样式
 * @param param0.children - 自定义内容
 * @param param0.footerActionsLeft - footer 按钮区左侧的 slot
 * @returns - 组件
 */
export const Footer: React.FC<FooterProps> = ({
  contentWidth,
  cancelText,
  cancelButtonProps,
  onCancel,
  okText,
  okButtonProps,
  okType,
  onOk,
  style,
  children,
  footerActionsLeft,
}) => (
  <FooterWrapper style={style}>
    <FooterAvailableBox width={contentWidth}>
      {children || (
        <>
          {footerActionsLeft || null}
          {(onCancel || !onOk) && (
            <Button onClick={onCancel} {...cancelButtonProps}>
              {cancelText || $t('取消')}
            </Button>
          )}
          {!!onOk && (
            <Button
              type={okType || 'primary'}
              onClick={onOk}
              {...okButtonProps}
            >
              {okText || $t('保存')}
            </Button>
          )}
        </>
      )}
    </FooterAvailableBox>
  </FooterWrapper>
)

const FooterWrapper = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  height: 57px;
  background-color: ${p => p.theme.background._0};
  border: 1px solid rgba(50, 56, 66, 0.1);
`

const FooterAvailableBox = styled.div<{ width: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${props => props.width};
`

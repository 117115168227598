/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { GroupCount, GroupCountInterface } from './group-count'
import type { CountMessagesQuery } from './count-messages-query'

const buildConfig = createConfigBuilder('chalk')

export const messageApi$countMessages = {
  config(
    countFields: string,
    countMessagesQuery?: CountMessagesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/me/received-messages-counts',
      {
        countFields,
      },
      countMessagesQuery,
    )
  },

  /**
   * @summary Class CountMessagesApi
   * @param countFields 需要统计的字段，支持 domain 和 readed, 多个通过 逗号 分隔
   * @param [countMessagesQuery]
   * @param [messageQuery.type]
   * @param [messageQuery.domain]
   * @param [messageQuery.domainIn]
   * @param [messageQuery.readed]
   * @param [messageQuery.keyword]
   * @param [messageQuery.startAtEgt]
   * @param [messageQuery.endInElt]
   * @param [messageQuery.notice] 只获取提醒类型的消息，默认为 true
   *
   * 当为 false 时获取 2.0 时的通知
   * @param [messageQuery.conversationId]
   * @param [messageQuery.owner.id] message owner 的 id
   * @param [options]
   * @return AxiosResponsePromise<GroupCount[]>
   */

  api(
    countFields: string,
    countMessagesQuery?: CountMessagesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<GroupCount[]> {
    return axios(
      messageApi$countMessages.config(countFields, countMessagesQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new GroupCount(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = GroupCount[]>(
    {
      countFields,
      query,
    }: {
      countFields: string
      query?: CountMessagesQuery
    },
    queryOptions?: QueryOptionsWithSelect<GroupCount[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<GroupCount[], TSelected>(
      messageApi$countMessages.api,
      'Message.countMessages',
      queryOptions,
      countFields,
      query,
    )
  },
}

// @ts-ignore
messageApi$countMessages.api._name_ = `Message.countMessages`

import {
  FlowNodeStatusEnum,
  FlowStage,
  FlowStageStatusEnum,
  NodeTypeEnum,
} from 'packages/sdks-next/form'

import { Flow as Base } from '../flow'

export type { FlowInterface } from '../flow'

/**
 * 审批流
 */
export class Flow extends Base {
  /**
   * 获取审批流中的待审批阶段
   *
   * @param actorId - 审批人 ID
   * @returns 阶段
   */
  getPendingStage(actorId: number): FlowStage | undefined {
    const pendingCode = this.nodes?.find(
      node => node.status === FlowNodeStatusEnum.Pending,
    )

    return pendingCode?.stages?.find(
      stage =>
        stage.reflectionId === actorId &&
        stage.status === FlowStageStatusEnum.Pending,
    )
  }

  /**
   * 判断指定人员是参与了审批
   *
   * @param rid - 人员 ID
   * @param nodeType - 节点类型
   * @returns boolean
   */
  isReflectionInStage(rid: number, nodeType?: NodeTypeEnum) {
    return !!this.nodes?.some(node =>
      node.stages?.some(
        stage =>
          stage.reflectionId === rid &&
          (!nodeType || nodeType === node.nodeType),
      ),
    )
  }

  /**
   * 获取审批流中的阶段
   *
   * @param stageId - 阶段 ID
   * @returns 阶段
   */
  getStageById(stageId: number) {
    for (const node of this.nodes ?? []) {
      const stage = node.stages?.find(s => s.id === stageId)
      if (stage) return stage
    }

    return undefined
  }
}

/**
 * @file 国际化相关函数
 */

/* eslint-disable seiue/missing-formatted-message */

import { env } from '@seiue/env'
import { createEnumHelper } from '@seiue/util'

import { getStore } from 'packages/feature-utils/store'
import { LocaleTextLocaleEnum } from 'packages/sdks-next/chalk'

import { Messages } from './types'

/**
 * 转化文本型的本地化设置值为特定的枚举值
 *
 * @param value - 语言环境
 * @returns LocaleTextLocaleEnum
 */
export const converLocaleStringToEnum = (value: string) => {
  const lowerCase = value.toLocaleLowerCase()

  if (lowerCase.includes('en')) {
    return LocaleTextLocaleEnum.EnUS
  }

  if (lowerCase.includes('cn')) {
    return LocaleTextLocaleEnum.ZhCN
  }

  // 其他为尚未注册的语言，默认返回中文
  return LocaleTextLocaleEnum.ZhCN
}

/**
 * 获取当前本地正在使用的语言版本
 *
 * @returns 当前版本
 */
export const getLocaleVersion = () => Number(env('CHALK_LOCALE_VERSION'))

/**
 * 提供给用户切换的多语言类型枚举
 */
export const usefulLocaleEnumHelper = createEnumHelper({
  [LocaleTextLocaleEnum.ZhCN]: '中文',
  [LocaleTextLocaleEnum.EnUS]: 'English',
})

export const shortUsefulLocaleEnumHelper = createEnumHelper({
  [LocaleTextLocaleEnum.ZhCN]: '中文',
  [LocaleTextLocaleEnum.EnUS]: 'En',
})

/**
 * 创建一个翻译枚举，通常用于翻译一些接口无法支持多语言的情况
 *
 * @param localeMessage - 翻译文本，比如 { 请假: $t('请假') }
 * @returns enumHelper 一个类 createEnumHelper 所返回的方法，
 */
export const createLocaleMessageHelper = (localeMessage: () => Messages) => {
  const { get, ...otherMethos } = createEnumHelper(localeMessage)

  return {
    get: (key: string) => get(key) || key,
    ...otherMethos,
  }
}

/**
 * 检测当前语言是否为英文
 *
 * @returns 是否
 */
export const isEnLocale = () => {
  return (
    getStore().getState()['locale']?.['locale'] === LocaleTextLocaleEnum.EnUS
  )
}

/**
 * @file 作业调查统计-navigator 注册
 */

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator(
  'sz_homework_survey.task_stats_released',

  ({ attributes }) => {
    const { taskId } = attributes as { taskId: number }

    return {
      path: `/nocode/sz-homework-survey/sep-pages/SurveySta?taskId=${taskId}&fromNotice=true`,
    }
  },
)

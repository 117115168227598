import { createEnumHelper } from '@seiue/util'

import { $t } from 'packages/locale'
import { Todo } from 'packages/sdks-next/chalk'
import { StatusColor } from 'packages/theme'

import { TodoDomainEnum, todoDomainToLabel } from './todo-domain-enum'
import { getTodoDomain } from './utils'

/**
 * 获取待办类型所对应的标签样式
 *
 * @param todo - 待办数据
 * @returns 标签样式
 */
export const getTodoTagProps = (todo: Pick<Todo, 'type'>) => {
  const domain = getTodoDomain(todo)
  const label = todoDomainToLabel.get(domain as any) || $t('失效待办')

  const props = todoDomainEnumToTagColor.get(domain as any)

  return {
    text: label,
    color: props?.color || StatusColor.Invalid.text,
    backgroundColor:
      props?.backgroundColor || StatusColor.Invalid.backgroundLight,
  }
}

export const todoDomainEnumToTagColor = createEnumHelper(() => ({
  [TodoDomainEnum.Certification]: {
    color: '#516A9A',
    backgroundColor: '#D9E9FF',
  },
  [TodoDomainEnum.Venue]: {
    color: '#4b8678',
    backgroundColor: '#c4f0e6',
  },
  [TodoDomainEnum.Evaluation]: {
    color: 'rgb(100, 80, 184)',
    backgroundColor: 'rgb(238, 236, 253)',
  },
  [TodoDomainEnum.Dorm]: {
    backgroundColor: '#E7F9ED',
    color: '#3E8B57',
  },
  [TodoDomainEnum.Attendance]: {
    backgroundColor: '#EBF3FF',
    color: '#447ae6',
  },
  [TodoDomainEnum.Goal]: {
    backgroundColor: '#EEECFF',
    color: '#6A4BBE',
  },
  [TodoDomainEnum.Assessment]: {
    backgroundColor: '#EEECFF',
    color: '#6A4BBE',
  },
  [TodoDomainEnum.SubjectSelection]: {
    backgroundColor: '#F7E9FA',
    color: '#9E37B4',
  },
  [TodoDomainEnum.Event]: {
    backgroundColor: '#f7e9fa',
    color: '#9e37b4',
  },
  [TodoDomainEnum.Questionnaire]: {
    color: 'rgb(100, 80, 184)',
    backgroundColor: 'rgb(238, 236, 253)',
  },
  [TodoDomainEnum.AcademicGoal]: {
    backgroundColor: '#E7F9ED',
    color: '#3E8B57',
  },
  [TodoDomainEnum.Schedule]: {
    color: '#137DAD',
    backgroundColor: '#DCF4FF',
  },
  [TodoDomainEnum.Chat]: {
    backgroundColor: '#EEECFF',
    color: '#6A4BBE',
  },
  [TodoDomainEnum.TeacherProfile]: {
    color: 'rgb(100, 80, 184)',
    backgroundColor: 'rgb(238, 236, 253)',
  },
  [TodoDomainEnum.Notification]: {
    backgroundColor: '#C4F0E6',
    color: '#4B8678',
  },
  [TodoDomainEnum.PluginPsy]: {
    backgroundColor: '#E1FBFA',
    color: '#1D8E89',
  },
  [TodoDomainEnum.AdminClass]: {
    backgroundColor: '#EBF3FF',
    color: '#447ae6',
  },
  [TodoDomainEnum.PsyChat]: {
    backgroundColor: '#EEECFF',
    color: '#6A4BBE',
  },
  [TodoDomainEnum.ClassReview]: {
    backgroundColor: '#E1FBFA',
    color: '#1D8E89',
  },
  [TodoDomainEnum.SemesterReview]: {
    backgroundColor: '#F7E9FA',
    color: '#9E37B4',
  },
}))

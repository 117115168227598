/**
 * @file Modal 弹窗组件
 * 基于 Antd Modal
 */
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { omit } from '@seiue/util'
// eslint-disable-next-line no-restricted-imports
import { Modal as ModalAntd } from 'antd'
import { ModalProps } from 'antd/es/modal'
import React, { ReactNode, useRef, useImperativeHandle } from 'react'
import styled, { css } from 'styled-components'

import { Button, ButtonProps } from 'packages/components/Button'
import { $t } from 'packages/locale'
import { BackgroundColor, Color, FontSize } from 'packages/themed'

export * from './FullScreenModal'
export * from './methods'
export * from './FuncFactory'
export * from './utils'

export interface SeModalProps
  extends Omit<ModalProps, 'okButtonProps' | 'cancelButtonProps' | 'okType'> {
  /*
   * 内容区是否可滚动，我们弹窗模式分两种：
   * 1、弹窗内容区整体滚动，这是默认行为
   * 2、弹窗内容区不可滚动，一般这种情况是内部内容自行处理滚动所有外部无需滚动。设置后，modal 会设置一个默认的高度。
   * 案例参考：apps/chalk/src/features/tasks/pages/Show/index.tsx
   */
  scrollable?: boolean

  fullScreen?: boolean

  // 自定义 Header 右侧内容
  headerRight?: ReactNode
  /**
   * 自定义 modal 头部样式
   */
  headerStyle?: React.CSSProperties

  height?: string | number
  okButtonProps?: ButtonProps & { 'data-test-id'?: string }
  cancelButtonProps?: ButtonProps
  header?: React.ReactNode
  okType?: ButtonProps['type']
  ref?: any

  onClose?: () => void // 直接点击顶部的 X 会触发这个方法
}

type FooterProps = Pick<
  SeModalProps,
  | 'footer'
  | 'onCancel'
  | 'onOk'
  | 'cancelButtonProps'
  | 'cancelText'
  | 'okType'
  | 'okButtonProps'
  | 'okText'
> & {
  okLoadingText?: React.ReactNode
  footerLeftComponent?: React.ReactNode
}

/**
 * @deprecated use ModalFooter from @seiue/ui instead.
 */
export const ModalFooter: React.FC<FooterProps> = props => {
  const {
    footer,
    onCancel,
    onOk,
    cancelButtonProps,
    cancelText,
    okType,
    okButtonProps,
    okText,
    okLoadingText,
    footerLeftComponent,
  } = props

  // 如果 Footer 明确的标识为 null，则不进行任何渲染
  if (footer === null) {
    return null
  }

  if (!footer && !onCancel && !onOk) return null

  return (
    <ModalFooterWrapper>
      {footer || (
        <>
          {footerLeftComponent}
          {onCancel ? (
            <Button onClick={onCancel} {...cancelButtonProps}>
              {cancelText || $t('取消')}
            </Button>
          ) : null}
          {onOk ? (
            <Button
              type={okType || 'primary'}
              onClick={onOk}
              loadingText={okLoadingText}
              {...okButtonProps}
            >
              {okText || $t('保存')}
            </Button>
          ) : null}
        </>
      )}
    </ModalFooterWrapper>
  )
}

interface HeaderProps
  extends Pick<SeModalProps, 'title' | 'closable' | 'onCancel' | 'onClose'> {
  headerRight?: ReactNode
  header?: ReactNode
  style?: React.CSSProperties
}

/**
 * @deprecated use ModalHeader from @seiue/ui instead.
 */
export const ModalHeader: React.FC<HeaderProps> = ({
  header,
  title,
  style,
  headerRight,
  closable,
  onCancel,
  onClose,
}) => {
  if (header === null) return null

  if (header) return <>{header}</>

  return (
    <ModalHeaderWrapper style={style || {}}>
      <ModalTitle>{title}</ModalTitle>
      <ModalHeaderRight>
        {headerRight}
        {closable && (
          <ModalClose
            data-test-id={'close-modal'}
            onClick={e => {
              if (onClose) onClose()
              else onCancel?.(e)
            }}
          >
            <ModalCloseIcon className="se-modal-close-icon" icon={faTimes} />
          </ModalClose>
        )}
      </ModalHeaderRight>
    </ModalHeaderWrapper>
  )
}

/**
 * @deprecated use Modal from @seiue/ui instead.
 */
export const Modal: React.FC<SeModalProps> = React.forwardRef((props, ref) => {
  const {
    closable = true,
    scrollable = true,
    height,
    fullScreen = false,
  } = props

  let modalHeight = typeof height === 'number' ? `${height}px` : height
  if (!modalHeight && !scrollable) {
    // 当设置为不可滚动，且无设置 modalHeight 时，给予一个默认高度
    modalHeight = '80vh'
  }

  if (fullScreen) {
    modalHeight = '100vh'
  }

  const bodyRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => ({
    getBodyDom: () => bodyRef.current,
  }))

  const antdModalProps = omit(props, [
    'header',
    'footer',
    'closable',
    'title',
    'headerRight',
    'cancelButtonProps',
    'onOk',
    'okType',
    'okButtonProps',
    'children',
  ])

  return (
    <ModalAntdStyled
      maskClosable={false}
      keyboard={false}
      centered={true}
      {...antdModalProps}
      title=""
      footer={null}
      closable={false}
      fullScreen={fullScreen}
      bodyStyle={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: fullScreen ? '100vh' : '80vh',
        minHeight: '140px',
        height: modalHeight,
      }}
      destroyOnClose={true}
    >
      <ModalHeader
        header={props.header}
        title={props.title}
        style={props.headerStyle || {}}
        headerRight={props.headerRight}
        closable={closable}
        onCancel={props.onCancel}
        onClose={props.onClose}
      />

      <ModalBody
        ref={bodyRef}
        style={props.bodyStyle}
        scrollable={scrollable}
        fullScreen={fullScreen}
      >
        {props.children}
      </ModalBody>
      <ModalFooter {...props} />
    </ModalAntdStyled>
  )
})

/**
 * @deprecated use Modal from @seiue/ui instead.
 */
export const ModalAntdStyled = styled(ModalAntd)<{ fullScreen?: boolean }>`
  overflow: hidden;
  ${props =>
    props.fullScreen
      ? css`
          /* 父元素是 position: fixed; 所以设置成 100%（不是 100vw），避免某些情况下出现竖直滚动条时因为宽度不够掉到下面而导致的 bug */
          width: 100% !important;
          height: 100% !important;
          padding: 0 !important;
          overflow: hidden;
          .ant-modal-content {
            border-radius: 0;
          }
        `
      : css`
          max-height: 80vh;
        `}
`

const ModalHeaderWrapper = styled.div`
  display: flex;
  flex: 0 0 56px;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  background-color: ${BackgroundColor.Base};
  border-radius: 6px 6px 0 0;
`

const ModalTitle = styled.span`
  color: ${Color.Black};
  font-weight: bold;
  font-size: ${FontSize.H2};
`

const ModalHeaderRight = styled.div`
  display: flex;
  align-items: center;
`

const ModalClose = styled.button`
  width: 56px;
  height: 56px;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    .se-modal-close-icon {
      color: ${Color.Black};
    }
  }
`

const ModalCloseIcon = styled(FontAwesomeIcon)`
  color: ${Color.Gray};
  font-size: 22px;
  cursor: pointer;
  transition: color 0.3s;
`

export const ModalBody = styled.div<{
  scrollable?: boolean
  fullScreen?: boolean
}>`
  flex: 1;
  height: ${({ scrollable }) => (scrollable ? 'auto' : '100%')};
  padding: ${({ fullScreen }) => (fullScreen ? '0px' : '24px')};
  overflow: ${({ scrollable }) => (scrollable ? 'auto' : 'hidden')};
`

const ModalFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 20px;
  text-align: right;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 0 0 6px 6px;
`

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Message, MessageInterface } from './message'
import type { FindAllQuery } from './find-all-query'

const buildConfig = createConfigBuilder('chalk')

export const receivedMessageApi$findAll = {
  config(
    findAllQuery?: FindAllQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/me/received-messages',
      {},
      findAllQuery,
    )
  },

  /**
   * @summary 我收到的消息
   * @param [findAllQuery]
   * @param [receivedMessageQuery.type]
   * @param [receivedMessageQuery.typeNeq]
   * @param [receivedMessageQuery.domain]
   * @param [receivedMessageQuery.domainIn]
   * @param [receivedMessageQuery.readed]
   * @param [receivedMessageQuery.keyword]
   * @param [receivedMessageQuery.startAtEgt]
   * @param [receivedMessageQuery.endInElt]
   * @param [receivedMessageQuery.categoryId]
   * @param [receivedMessageQuery.categoryIdExists]
   * @param [receivedMessageQuery.categoryIdIn]
   * @param [receivedMessageQuery.notice] 只获取提醒类型的消息，默认为 true
   *
   * 当为 false 时获取 2.0 时的通知
   * @param [receivedMessageQuery.paginated] 是否分页
   * @param [receivedMessageQuery.limit] 不分页时限制获取条数
   * @param [receivedMessageQuery.conversationId]
   * @param [receivedMessageQuery.isCc]
   * @param [receivedMessageQuery.isImportant]
   * @param [receivedMessageQuery.receipted]
   * @param [receivedMessageQuery.effectiveBeforeEgt]
   * @param [receivedMessageQuery.owner.id] message owner 的 id
   * @param [receivedMessageQuery.expand]
   * @param [receivedMessageQuery.page] 显示的数据页，默认 1
   * @param [receivedMessageQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<Message[]>
   */

  api<const TQuery extends FindAllQuery>(
    findAllQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Message, TQuery['expand']>[]> {
    return axios(receivedMessageApi$findAll.config(findAllQuery, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Message(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends FindAllQuery,
    TSelected = Expand<Message, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<Message, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Message, TQuery['expand']>[], TSelected>(
      receivedMessageApi$findAll.api,
      'ReceivedMessage.findAll',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
receivedMessageApi$findAll.api._name_ = `ReceivedMessage.findAll`

import { WidgetType } from 'packages/sdks-next/nuwa'

import { EchartWidgetExtra } from '../index'

/**
 * 是否为水平类型的图表
 *
 * @param widget - 图表
 * @returns 是否为水平图表
 */
export const isHorizontalBar = (widget: EchartWidgetExtra) => {
  return [
    WidgetType.ColumnChart,
    WidgetType.StackedColumnChart,
    WidgetType.LineChart,
  ].includes(widget.type)
}

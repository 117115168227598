import {
  GstTemplateResourceSetting,
  GstTemplateResourceSettingInterface,
} from './gst-template-resource-setting'
import {
  GstTemplateConstraint,
  GstTemplateConstraintInterface,
} from './gst-template-constraint'

export interface GstTemplateConfigInterface {
  /**
   * 资源设置
   */
  resourceSettings: GstTemplateResourceSettingInterface[]
  /**
   * 约束设置
   */
  constraints: GstTemplateConstraintInterface[]
}

export class GstTemplateConfig implements GstTemplateConfigInterface {
  /**
   * 资源设置
   */
  resourceSettings: GstTemplateResourceSetting[]
  /**
   * 约束设置
   */
  constraints: GstTemplateConstraint[]

  constructor(props: GstTemplateConfigInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      resourceSettings: _resourceSettings,
      constraints: _constraints,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.resourceSettings = _resourceSettings
      ? _resourceSettings.map(i => new GstTemplateResourceSetting(i))
      : _resourceSettings
    this.constraints = _constraints
      ? _constraints.map(i => new GstTemplateConstraint(i))
      : _constraints
  }

  static propKeys = ['resourceSettings', 'constraints']
}

import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { Semester } from 'packages/sdks-next/chalk'

import { getCurrentReflectionId } from '../store'

import { findNearestSemester } from './utils'

/**
 * 全部学期 atom.
 */
export const semestersAtom = atom<Semester[]>([])

/**
 * 当前学期 atom
 */
export const currentSemesterAtom = atom(get => {
  return get(semestersAtom).find(s => s.isCurrent)
})

/**
 * reflection id -> selected semester id
 */
type SelectedSemesterIdType = {
  [key: string]: number | undefined
}

/**
 * 持久化至本地的全局选中的学期 id
 */
const selectedSemesteridAtomWithStorage =
  atomWithStorage<SelectedSemesterIdType>('selectedSemesterId', {})

/**
 * 当前选择的学期 atom.
 * Fallback 顺序为选中学期 > 当前学期 > 离现在最近的学期.
 */
export const selectedSemesterAtom = atom<
  Semester | undefined,
  number | undefined
>(
  get => {
    const semesters = get(semestersAtom)
    const selectedSemesterId = get(selectedSemesteridAtomWithStorage)[
      getCurrentReflectionId() ?? 0
    ]

    return (
      semesters.find(s => s.id === selectedSemesterId) ??
      get(currentSemesterAtom) ??
      findNearestSemester(semesters)
    )
  },

  // selected semester id setter
  (get, set, semesterId) => {
    set(selectedSemesteridAtomWithStorage, {
      ...get(selectedSemesteridAtomWithStorage),
      [getCurrentReflectionId() ?? 0]: semesterId,
    })
  },
)

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { StageTypeEnum } from './stage-type-enum'
import { Stage, StageInterface } from './extends/stage'
import type { QueryStagesQuery } from './query-stages-query'

const buildConfig = createConfigBuilder('vnas')

export const commonApi$queryStages = {
  config(
    type: StageTypeEnum,
    queryStagesQuery?: QueryStagesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/common/stages',
      {
        type,
      },
      queryStagesQuery,
    )
  },

  /**
   * @summary 查询学段列表
   * @param type
   * @param [queryStagesQuery]
   * @param [commonQuery.paginated] 是否分页，默认 1
   * @param [commonQuery.page] 显示的数据页，默认 1
   * @param [commonQuery.perPage] 每页显示数量，默认 20
   * @param [commonQuery.id]
   * @param [commonQuery.idIn]
   * @param [commonQuery.autoPublish]
   * @param [commonQuery.semesterId] semester_id 和 semester_id_in 二选一
   * @param [commonQuery.semesterIdIn]
   * @param [commonQuery.name]
   * @param [commonQuery.nameLike]
   * @param [commonQuery.endAtEgt]
   * @param [commonQuery.endAtElt]
   * @param [commonQuery.sort]
   * @param [options]
   * @return AxiosResponsePromise<Stage[]>
   */

  api(
    type: StageTypeEnum,
    queryStagesQuery?: QueryStagesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Stage[]> {
    return axios(commonApi$queryStages.config(type, queryStagesQuery, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Stage(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = Stage[]>(
    {
      type,
      query,
    }: {
      type: StageTypeEnum
      query?: QueryStagesQuery
    },
    queryOptions?: QueryOptionsWithSelect<Stage[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Stage[], TSelected>(
      commonApi$queryStages.api,
      'Common.queryStages',
      queryOptions,
      type,
      query,
    )
  },
}

// @ts-ignore
commonApi$queryStages.api._name_ = `Common.queryStages`

import { AccItemRelationEnum } from './acc-item-relation-enum'

export interface AccItemRelationInterface {
  itemId: number
  relationId: number
  relation: AccItemRelationEnum
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class AccItemRelation implements AccItemRelationInterface {
  itemId: number
  relationId: number
  relation: AccItemRelationEnum
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: AccItemRelationInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      itemId: _itemId,
      relationId: _relationId,
      relation: _relation,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.itemId = _itemId
    this.relationId = _relationId
    this.relation = _relation
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'itemId',
    'relationId',
    'relation',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

export interface OutlineCustomFieldsInterface {
  /**
   * 关联过程评价项 id
   */
  itemId?: number | null
}

export class OutlineCustomFields implements OutlineCustomFieldsInterface {
  /**
   * 关联过程评价项 id
   */
  itemId?: number | null

  constructor(props: OutlineCustomFieldsInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      itemId: _itemId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.itemId = _itemId
  }

  static propKeys = ['itemId']
}

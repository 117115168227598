export interface GroupTeamWithoutMembersInterface {
  /**
   * 群组 id
   */
  groupId: number
  /**
   * 小组名称
   */
  name: string
  /**
   * 权重
   */
  weight?: number
  /**
   * 主键 ID
   */
  id: number
}

export class GroupTeamWithoutMembers
  implements GroupTeamWithoutMembersInterface
{
  /**
   * 群组 id
   */
  groupId: number
  /**
   * 小组名称
   */
  name: string
  /**
   * 权重
   */
  weight?: number
  /**
   * 主键 ID
   */
  id: number

  constructor(props: GroupTeamWithoutMembersInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      groupId: _groupId,
      name: _name,
      weight: _weight,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.groupId = _groupId
    this.name = _name
    this.weight = _weight
    this.id = _id
  }

  static propKeys = ['groupId', 'name', 'weight', 'id']
}

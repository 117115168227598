/**
 * @file 全屏步骤条弹窗
 */

import { Steps, ButtonProps, State } from '@seiue/ui'
import { omit, Lazy, Dictionary, isNumber } from '@seiue/util'
import { StepProps, StepsProps } from 'antd/lib/steps'
import React from 'react'
import styled from 'styled-components'

import { ZIndex, BackgroundColor, Border } from 'packages/themed'

import { Content } from './Components/Content'
import { Footer } from './Components/Footer'
import { Header } from './Components/Header'
import {
  PureFullScreenModal,
  PureFullScreenModalProps,
} from './PureFullScreenModal'

export type FullScreenModalSteps = Array<
  StepProps & {
    ref?: React.RefObject<Dictionary<any>>
    component?: React.FC<any> | React.ForwardRefExoticComponent<any>
    componentProps?: Dictionary<any>
  }
>

export interface FullScreenModalWithStepsProps
  extends PureFullScreenModalProps {
  visible: boolean
  title: React.ReactNode
  stepProps: StepsProps & {
    /**
     * 步骤条定义
     */
    steps: FullScreenModalSteps
    /**
     * 是否加载中
     */
    loading?: boolean
  }
  onOk?: (e: React.MouseEvent<HTMLElement>) => void
  onClose?: (e: React.MouseEvent<HTMLElement>) => void
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void
  okText?: React.ReactNode
  okType?: ButtonProps['type']
  cancelText?: React.ReactNode
  footerActionsLeft?: React.ReactNode
  okButtonProps?: ButtonProps
  cancelButtonProps?: ButtonProps
  footer?: React.ReactNode
  zIndex?: number
  contentWidth?: number | string
  stepsWidth?: number
  contentStyle?: React.CSSProperties
  destroyContentWhenInvisible?: boolean
  hideFooter?: boolean
  contentWrapperStyle?: React.CSSProperties
  useBackToTop?: boolean
}

/**
 * 带步骤的全屏弹窗
 *
 * @param root0 - 参数 {@link FullScreenModalWithStepsProps}
 * @param root0.visible - 是否显示
 * @param root0.title - 标题
 * @param root0.stepProps - 步骤条参数
 * @param root0.onClose - 关闭回调
 * @param root0.onCancel - 取消回调
 * @param root0.onOk - 确认回调
 * @param root0.okText - 确认按钮文案
 * @param root0.okType - 确认按钮类型
 * @param root0.okButtonProps - 确认按钮属性
 * @param root0.cancelText - 取消按钮文案
 * @param root0.cancelButtonProps - 取消按钮属性
 * @param root0.footer - 底部内容
 * @param root0.children - 内容
 * @param root0.zIndex - 层级
 * @param root0.contentWidth - 内容宽度
 * @param root0.stepsWidth - 步骤条宽度
 * @param root0.contentStyle - 内容样式
 * @param root0.contentWrapperStyle - 内容包裹样式
 * @param root0.destroyContentWhenInvisible - 隐藏时销毁内容
 * @param root0.hideFooter - 是否隐藏底部
 * @param root0.getContainer - 挂载节点
 * @param root0.useBackToTop - 是否使用返回顶部
 * @param root0.footerActionsLeft - 底部左侧内容
 * @returns 带步骤的全屏弹窗
 */
export const FullScreenModalWithSteps: React.FC<
  FullScreenModalWithStepsProps
> = ({
  visible,
  title,
  stepProps,
  onClose,
  onCancel,
  onOk,
  okText,
  okType,
  okButtonProps,
  cancelText,
  cancelButtonProps,
  footer,
  children,
  zIndex = ZIndex.FullScreen,
  contentWidth = 960,
  stepsWidth,
  contentStyle,
  contentWrapperStyle,
  destroyContentWhenInvisible,
  hideFooter,
  getContainer,
  useBackToTop,
  footerActionsLeft,
}) => {
  const [isReadyToRenderContent, setIsReadyToRenderContent] =
    React.useState(false)

  const innerZIndex = zIndex + 10
  const _contentWidth = isNumber(contentWidth)
    ? `${contentWidth}px`
    : contentWidth

  const shouldShowStepsBar = stepProps.steps.length > 1

  // 是否通过 component prop 渲染 Step
  const isRenderStepByComponent = stepProps.steps.some(step => !!step.component)

  return (
    <PureFullScreenModal
      visible={visible}
      zIndex={zIndex}
      getContainer={getContainer}
      destroyContentWhenInvisible={destroyContentWhenInvisible}
      onReadyStateChange={readyToRenderContent =>
        setIsReadyToRenderContent(readyToRenderContent)
      }
    >
      <Header
        style={{ zIndex: innerZIndex }}
        title={title}
        contentWidth={_contentWidth}
        onClose={onClose || onCancel}
      />

      {shouldShowStepsBar ? (
        <StepsWrapper style={{ zIndex: innerZIndex }}>
          <StepsAvialibleBox
            width={stepsWidth ? `${stepsWidth}px` : _contentWidth}
          >
            <Steps
              {...stepProps}
              steps={stepProps.steps.map(step =>
                omit(step, 'component', 'componentProps'),
              )}
              direction="horizontal"
              size="small"
            />
          </StepsAvialibleBox>
        </StepsWrapper>
      ) : null}

      <ContentWrapper $shouldShowStepsBar={shouldShowStepsBar}>
        <Content
          zIndex={zIndex}
          readyToRenderContent={isReadyToRenderContent}
          contentStyle={contentStyle}
          useBackToTop={useBackToTop}
          style={{
            padding: '16px 0',
            ...contentWrapperStyle,
          }}
          contentWidth={_contentWidth}
        >
          {stepProps.loading ? (
            <State.Loading />
          ) : isRenderStepByComponent ? (
            stepProps.steps.map((step, index) => {
              const StepComponent = step.component
              const isActive = stepProps.current === index

              return StepComponent ? (
                <Step key={index} isActive={isActive}>
                  <Lazy renderWhen={isActive}>
                    <StepComponent ref={step.ref} {...step.componentProps} />
                  </Lazy>
                </Step>
              ) : null
            })
          ) : (
            children
          )}
        </Content>
      </ContentWrapper>

      {!hideFooter ? (
        <Footer
          style={{ zIndex: innerZIndex }}
          contentWidth={_contentWidth}
          cancelText={cancelText}
          cancelButtonProps={cancelButtonProps}
          onCancel={onCancel}
          okText={okText}
          okType={okType}
          okButtonProps={okButtonProps}
          onOk={onOk}
          footerActionsLeft={footerActionsLeft}
        >
          {footer}
        </Footer>
      ) : null}
    </PureFullScreenModal>
  )
}

const StepsWrapper = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  height: 50px;
  background-color: ${BackgroundColor.White};
  border-top: ${Border.Deep};
  box-shadow: 0 10px 12px 0 rgba(50, 56, 66, 0.03);
`

const StepsAvialibleBox = styled.div<{ width: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${props => props.width};
`

const Step = styled.div<{ isActive: boolean }>`
  display: ${props => (props.isActive ? 'block' : 'none')};
`

export const StepTitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: ${p => p.theme.text._1};
  text-align: center;
  margin-bottom: 24px;
  margin-top: 8px;
`

export const StepDescription = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${p => p.theme.text._2};
  margin-top: 10px;
  text-align: center;
  margin-bottom: 24px;
`

const ContentWrapper = styled.div<{ $shouldShowStepsBar: boolean }>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: ${p => (p.$shouldShowStepsBar ? 110 : 60)}px;
  padding-bottom: 57px;
`

import { RoleEnum } from './role-enum'

export interface SchcalScopeInterface {
  role?: RoleEnum
  /**
   * [
   *  'gender_in'=>['m', 'f']
   *  'graduates_in_id_in' => 32770
   * ]
   */
  params?: object
}

export class SchcalScope implements SchcalScopeInterface {
  role?: RoleEnum
  /**
   * [
   *  'gender_in'=>['m', 'f']
   *  'graduates_in_id_in' => 32770
   * ]
   */
  params?: object

  constructor(props: SchcalScopeInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      role: _role,
      params: _params,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.role = _role
    this.params = _params
  }

  static propKeys = ['role', 'params']
}

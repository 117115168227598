import {
  AggregatedMessage,
  AggregatedMessageInterface,
} from './aggregated-message'
import {
  MessageAttributes,
  MessageAttributesInterface,
} from './message-attributes'
import { MessageSender, MessageSenderInterface } from './message-sender'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import { MessageOwner, MessageOwnerInterface } from './message-owner'
import { NotificationSignEnum } from './notification-sign-enum'
import { ReceiptedTypeEnum } from './receipted-type-enum'
import { Discussion, DiscussionInterface } from './discussion'

export interface MessageInterface {
  aggregatedMessages?: AggregatedMessageInterface[]
  id: string
  /**
   * 所属会话ID，当 type=message 时为通知ID。
   */
  conversationId?: string
  title?: string | null
  content?: string
  /**
   * 原始消息，包含变量时使用
   */
  rawContent?: string
  /**
   * 变量通知的变量
   */
  variables?: string
  /**
   * 业务领域类型，如 class , class_assessment, attendance 等
   * 如果消息来自于开放平台应用，则前缀为 `open.` ，接上应用对应的插件 name ，如 `open.gz_big_data`
   */
  domain?: string
  type: string
  readed?: boolean
  notice?: boolean | null
  updatedAt?: string
  createdAt?: string
  attributes?: MessageAttributesInterface
  sender?: MessageSenderInterface
  senderReflection?: SecuredReflectionInterface
  owner?: MessageOwnerInterface
  sign?: string
  categoryId?: number | null
  signType?: NotificationSignEnum | null
  publishedAt?: string
  /**
   * 是否回执, 仅重要通知使用
   */
  receipted?: boolean
  /**
   * 已回执的签名图片 Hash
   */
  receiptedImage?: string | null
  /**
   * 是否重要通知
   */
  isImportant?: boolean
  /**
   * 回执类型
   */
  receiptedType?: ReceiptedTypeEnum | null
  /**
   * 重要通知有效时间
   */
  effectiveBefore?: string
  /**
   * 是否为接收人
   */
  isReceiver?: boolean
  /**
   * 是否为抄送
   */
  isCc?: boolean
  /**
   * 是否抄送给家长
   */
  ccToGuardian?: boolean
  /**
   * 通知所关联的讨论
   */
  discussion?: DiscussionInterface | null
}

export class Message implements MessageInterface {
  aggregatedMessages?: AggregatedMessage[]
  id: string
  /**
   * 所属会话ID，当 type=message 时为通知ID。
   */
  conversationId?: string
  title?: string | null
  content?: string
  /**
   * 原始消息，包含变量时使用
   */
  rawContent?: string
  /**
   * 变量通知的变量
   */
  variables?: string
  /**
   * 业务领域类型，如 class , class_assessment, attendance 等
   * 如果消息来自于开放平台应用，则前缀为 `open.` ，接上应用对应的插件 name ，如 `open.gz_big_data`
   */
  domain?: string
  type: string
  readed?: boolean
  notice?: boolean | null
  updatedAt?: string
  createdAt?: string
  attributes?: MessageAttributes
  sender?: MessageSender
  senderReflection?: SecuredReflection
  owner?: MessageOwner
  sign?: string
  categoryId?: number | null
  signType?: NotificationSignEnum | null
  publishedAt?: string
  /**
   * 是否回执, 仅重要通知使用
   */
  receipted?: boolean
  /**
   * 已回执的签名图片 Hash
   */
  receiptedImage?: string | null
  /**
   * 是否重要通知
   */
  isImportant?: boolean
  /**
   * 回执类型
   */
  receiptedType?: ReceiptedTypeEnum | null
  /**
   * 重要通知有效时间
   */
  effectiveBefore?: string
  /**
   * 是否为接收人
   */
  isReceiver?: boolean
  /**
   * 是否为抄送
   */
  isCc?: boolean
  /**
   * 是否抄送给家长
   */
  ccToGuardian?: boolean
  /**
   * 通知所关联的讨论
   */
  discussion?: Discussion | null

  constructor(props: MessageInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      aggregatedMessages: _aggregatedMessages,
      id: _id,
      conversationId: _conversationId,
      title: _title,
      content: _content,
      rawContent: _rawContent,
      variables: _variables,
      domain: _domain,
      type: _type,
      readed: _readed,
      notice: _notice,
      updatedAt: _updatedAt,
      createdAt: _createdAt,
      attributes: _attributes,
      sender: _sender,
      senderReflection: _senderReflection,
      owner: _owner,
      sign: _sign,
      categoryId: _categoryId,
      signType: _signType,
      publishedAt: _publishedAt,
      receipted: _receipted,
      receiptedImage: _receiptedImage,
      isImportant: _isImportant,
      receiptedType: _receiptedType,
      effectiveBefore: _effectiveBefore,
      isReceiver: _isReceiver,
      isCc: _isCc,
      ccToGuardian: _ccToGuardian,
      discussion: _discussion,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.aggregatedMessages = _aggregatedMessages
      ? _aggregatedMessages.map(i => new AggregatedMessage(i))
      : _aggregatedMessages
    this.id = _id
    this.conversationId = _conversationId
    this.title = _title
    this.content = _content
    this.rawContent = _rawContent
    this.variables = _variables
    this.domain = _domain
    this.type = _type
    this.readed = _readed
    this.notice = _notice
    this.updatedAt = _updatedAt
    this.createdAt = _createdAt
    this.attributes = _attributes
      ? new MessageAttributes(_attributes)
      : _attributes
    this.sender = _sender ? new MessageSender(_sender) : _sender
    this.senderReflection = _senderReflection
      ? new SecuredReflection(_senderReflection)
      : _senderReflection
    this.owner = _owner ? new MessageOwner(_owner) : _owner
    this.sign = _sign
    this.categoryId = _categoryId
    this.signType = _signType
    this.publishedAt = _publishedAt
    this.receipted = _receipted
    this.receiptedImage = _receiptedImage
    this.isImportant = _isImportant
    this.receiptedType = _receiptedType
    this.effectiveBefore = _effectiveBefore
    this.isReceiver = _isReceiver
    this.isCc = _isCc
    this.ccToGuardian = _ccToGuardian
    this.discussion = _discussion ? new Discussion(_discussion) : _discussion
  }

  static propKeys = [
    'aggregatedMessages',
    'id',
    'conversationId',
    'title',
    'content',
    'rawContent',
    'variables',
    'domain',
    'type',
    'readed',
    'notice',
    'updatedAt',
    'createdAt',
    'attributes',
    'sender',
    'senderReflection',
    'owner',
    'sign',
    'categoryId',
    'signType',
    'publishedAt',
    'receipted',
    'receiptedImage',
    'isImportant',
    'receiptedType',
    'effectiveBefore',
    'isReceiver',
    'isCc',
    'ccToGuardian',
    'discussion',
  ]
}

import { FormTemplateTableFieldTypeEnum } from './form-template-table-field-type-enum'
import {
  FormTemplateFieldContainer,
  FormTemplateFieldContainerInterface,
} from './form-template-field-container'

export interface FormTemplateTableFieldInterface {
  /**
   * 由前端生成，使用此 name 作为 key 来关联申请人填写的值。同一表格内 name 不可重复，且值不可修改。
   */
  name: string
  /**
   * 标签
   */
  label: string
  /**
   * 表格字段类型
   */
  type: FormTemplateTableFieldTypeEnum
  /**
   * 是否必填
   */
  required: boolean
  /**
   * 表单字段顺序
   */
  sort: number
  /**
   * 选项值，用于单选按钮、多选按钮
   */
  container?: FormTemplateFieldContainerInterface
}

export class FormTemplateTableField implements FormTemplateTableFieldInterface {
  /**
   * 由前端生成，使用此 name 作为 key 来关联申请人填写的值。同一表格内 name 不可重复，且值不可修改。
   */
  name: string
  /**
   * 标签
   */
  label: string
  /**
   * 表格字段类型
   */
  type: FormTemplateTableFieldTypeEnum
  /**
   * 是否必填
   */
  required: boolean
  /**
   * 表单字段顺序
   */
  sort: number
  /**
   * 选项值，用于单选按钮、多选按钮
   */
  container?: FormTemplateFieldContainer

  constructor(props: FormTemplateTableFieldInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      label: _label,
      type: _type,
      required: _required,
      sort: _sort,
      container: _container,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.label = _label
    this.type = _type
    this.required = _required
    this.sort = _sort
    this.container = _container
      ? new FormTemplateFieldContainer(_container)
      : _container
  }

  static propKeys = ['name', 'label', 'type', 'required', 'sort', 'container']
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'

const buildConfig = createConfigBuilder('chalk')

export const semesterApi$deleteSemester = {
  config(id: number, options?: AxiosRequestExtraConfig): AxiosRequestConfig {
    return buildConfig(options, 'delete', '/system/semesters/{id}', {
      id,
    })
  },

  /**
   * @summary Class DeleteSemesterApi
   * @param id
   * @param [options]
   * @return AxiosResponsePromise<null>
   */

  api(
    id: number,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<null> {
    return axios(semesterApi$deleteSemester.config(id, options)) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      (id: number) => semesterApi$deleteSemester.api(id),
      mutationOptions,
    )
  },
}

// @ts-ignore
semesterApi$deleteSemester.api._name_ = `Semester.deleteSemester`

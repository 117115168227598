/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Scope, ScopeInterface } from './scope'
import type { QueryManagedScopesQuery } from './query-managed-scopes-query'

const buildConfig = createConfigBuilder('chalk')

export const roleApi$queryManagedScopes = {
  config(
    id: number,
    domain: string,
    queryManagedScopesQuery?: QueryManagedScopesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/role/manager/{id}/scopes',
      {
        id,
        domain,
      },
      queryManagedScopesQuery,
    )
  },

  /**
   * @summary Class QueryManagedScopesApi
   * @param id reflection id
   * @param domain 使用该 Scope 的 domain
   * @param [queryManagedScopesQuery]
   * @param [roleQuery.type] Scope 的类型
   * @param [roleQuery.enhancer] 已废弃，同 type 参数
   * @param [roleQuery.permission]
   * @param [roleQuery.roleId]
   * @param [roleQuery.roleIdIn]
   * @param [options]
   * @return AxiosResponsePromise<Scope[]>
   */

  api(
    id: number,
    domain: string,
    queryManagedScopesQuery?: QueryManagedScopesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Scope[]> {
    return axios(
      roleApi$queryManagedScopes.config(
        id,
        domain,
        queryManagedScopesQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Scope(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = Scope[]>(
    {
      id,
      domain,
      query,
    }: {
      id: number
      domain: string
      query?: QueryManagedScopesQuery
    },
    queryOptions?: QueryOptionsWithSelect<Scope[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Scope[], TSelected>(
      roleApi$queryManagedScopes.api,
      'Role.queryManagedScopes',
      queryOptions,
      id,
      domain,
      query,
    )
  },
}

// @ts-ignore
roleApi$queryManagedScopes.api._name_ = `Role.queryManagedScopes`

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  OAuthCredentials,
  OAuthCredentialsInterface,
} from './o-auth-credentials'
import { OAuthToken, OAuthTokenInterface } from './o-auth-token'

const buildConfig = createConfigBuilder('chalk')

export const oAuthApi$authorize = {
  config(
    oAuthCredentials: OAuthCredentialsInterface,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'post',
      '/oauth/tokens',
      {},
      null,
      oAuthCredentials,
      {
        format: 'json',
        isArray: false,
        defaults: {
          username: (v: any) => v || '',
          password: (v: any) => v || '',
          refreshToken: (v: any) => v || '',
          scope: (v: any) => v || '',
        },
      },
    )
  },

  /**
   * @summary Class AuthorizeApi
   * @param oAuthCredentials
   * @param [options]
   * @return AxiosResponsePromise<OAuthToken>
   */

  api(
    oAuthCredentials: OAuthCredentialsInterface,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<OAuthToken> {
    return axios(oAuthApi$authorize.config(oAuthCredentials, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new OAuthToken(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      (payload: OAuthCredentialsInterface) => oAuthApi$authorize.api(payload),
      mutationOptions,
    )
  },
}

// @ts-ignore
oAuthApi$authorize.api._name_ = `OAuth.authorize`

import {
  Icon,
  Dropdown,
  DropdownItem,
  Ellipsis,
  Tooltip,
  IconSource,
  Space,
} from '@seiue/ui'
import React from 'react'
import styled, { css } from 'styled-components'

import { LayoutContext } from './context'

interface Props {
  popoverTitle?: string
  tooltipTitle?: React.ReactNode
  description?: React.ReactNode
  options: DropdownItem[]
  activeItem?: DropdownItem
  onChange: (item: DropdownItem) => void
  renderMenuItemLabel?: (item: DropdownItem) => React.ReactNode
  icon: IconSource
  placeholder?: string
}

/**
 * 侧边栏导航 Footer Slot（学期选择器）
 *
 * @param props - {@link Props}
 * @returns React.ReactElement
 */
export const SiderFooterSelector: React.FC<Props> = props => {
  const {
    popoverTitle,
    tooltipTitle,
    description,
    options,
    activeItem,
    onChange,
    renderMenuItemLabel,
    icon,
    placeholder,
  } = props

  return (
    <LayoutContext.Consumer>
      {({ collapsed }) => (
        <Dropdown
          title={popoverTitle}
          items={options}
          value={activeItem?.value}
          placement="rightBottom"
          onChange={onChange}
          renderItemLabel={renderMenuItemLabel}
        >
          <Selector isCollapsed={collapsed}>
            {!collapsed && (
              <SpaceStyled
                size={6}
                style={{
                  overflow: 'hidden',
                }}
              >
                {description}
                <SelectorInfoTitle text={activeItem?.label || placeholder} />
              </SpaceStyled>
            )}
            <Tooltip
              mouseEnterDelay={1}
              placement={collapsed ? 'right' : 'top'}
              title={tooltipTitle}
              mode="dark"
            >
              <IconStyled icon={icon} size={16} color={'#9398A1'} />
            </Tooltip>
          </Selector>
        </Dropdown>
      )}
    </LayoutContext.Consumer>
  )
}

const Selector = styled.div.attrs((props: { isCollapsed: boolean }) => ({
  isCollapsed: props.isCollapsed,
}))`
  height: 56px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 19px 16px;
  border-top: 1px solid ${props => props.theme.divider._1};

  ${props =>
    !props.isCollapsed
      ? css``
      : css`
          &:hover {
            background-color: #f6f8fa;
          }
        `};

  &:not(:first-child) {
    border-top: ${props =>
      props.isCollapsed ? 'none' : 'solid 1px rgba(255, 255, 255, 0.1)'};
  }
`

const SpaceStyled = styled(Space)`
  .ant-space-item {
    overflow: hidden;
    // description不压缩
    &:first-of-type {
      flex-shrink: 0;
    }
  }
`

const SelectorInfoTitle = styled(Ellipsis)`
  color: ${props => props.theme.text._1};
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  margin-right: 12px;
`

const IconStyled = styled(Icon)`
  padding: 8px;
  &&& {
    cursor: pointer !important;
  }
`

import { AxiosResponsePromise } from '@seiue/axios'
import { StartCase } from '@seiue/util'
import React, { FC, ReactNode } from 'react'

import {
  SpecTodoRelated,
  SpecTodoType,
  TodoDomainEnum,
} from 'packages/features/todos'
import { PluginNameEnum } from 'packages/plugins/types'
import { Expand, MutationOptions } from 'packages/sdks-next'
import {
  Field,
  Flow,
  FlowInterface,
  FlowPreviewReqInterface,
  SecuredReflection,
  WorkflowInterface,
} from 'packages/sdks-next/form'

export * from './field'

export type WithWorkflow = {
  workflow: Expand<WorkflowInterface, ['schoolPlugin']>
}

export interface FlowWithWorkflowInterface extends FlowInterface {
  workflow: Expand<WorkflowInterface, ['schoolPlugin']>
  relatedReflections?: SecuredReflection[]
}

/**
 * 审批流
 */
export class FlowWithWorkflow extends Flow {
  workflow: Expand<WorkflowInterface, ['schoolPlugin']>

  relatedReflections?: SecuredReflection[]

  /**
   * 构造函数
   *
   * @param props - FlowInterface & WithWorkflow
   */
  constructor(
    props: FlowInterface &
      WithWorkflow & {
        relatedReflections?: SecuredReflection[]
      },
  ) {
    super(props)
    this.workflow = props.workflow
    this.relatedReflections = props.relatedReflections
  }
}

/**
 * 与后端约定的审批流扩展服务类型
 */
export enum WorkflowExtensionTypeEnum {
  // 出入校管理
  Visitor = 'seiue.eems:visitor',
  // 请假
  Absence = 'seiue.sams:absence',
  // 请假销假
  AbsenceVerification = 'seiue.sams:absence_verification',
  // 无代码
  NoCode = 'seiue.nuwa:no-code',
  // 调代课
  Adjustment = 'seiue.scms:adjustment',
  // 德育评价
  MoralAssessment = 'seiue.vnas:moral_assessment',
  // 约谈/咨询审批
  Chat = 'seiue.chalk:chat',
  // 退课审批
  ClassMembers = 'seiue.scms:class_members',
  // 活动
  Event = 'seiue.chalk:event',
}

/**
 * 审批插件类型
 */
export type WorkflowBizType =
  | 'approval'
  | 'visitor'
  | 'absence'
  | 'no-code'
  | 'adjustment'
  | 'moral_assessment'
  | 'chat'
  | 'class_members'

export type WorkflowBizTypeCapitalized = StartCase<
  Exclude<WorkflowBizType, 'no-code' | 'adjustment'>
>

/**
 * 将插件名称转换为工作流业务类型名称
 *
 * @param pluginName - 插件名称
 * @returns 业务类型名称
 */
export const convertPluginNameToWorkflowBizType = (
  pluginName: PluginNameEnum,
): WorkflowBizType | null => {
  switch (pluginName) {
    case PluginNameEnum.Approval:
      return 'approval'
    case PluginNameEnum.Eems:
      return 'visitor'
    case PluginNameEnum.Absence:
      return 'absence'
    case PluginNameEnum.Adjustment:
      return 'adjustment'
    default:
      return null
  }
}

/**
 * 该插件是否有审批流
 *
 * @param pluginName - 插件名称
 * @returns 该插件是否有审批流
 */
export const isPluginHasWorkflow = (pluginName?: string): boolean =>
  !!convertPluginNameToWorkflowBizType(pluginName as PluginNameEnum)

/*
 * 与后端约定的审批节点状态
 */
export enum FlowStatusEnum {
  Pending = 'pending',
  Withdrawn = 'withdrawn',
  Rejected = 'rejected',
  Refused = 'refused',
  Approved = 'approved',

  // 请假销假状态
  Verifying = 'verifying',
  Verified = 'verified',
  Submitted = 'submitted',
}

export const AbsenceFlowStatusEnums = [
  FlowStatusEnum.Verified,
  FlowStatusEnum.Verifying,
  FlowStatusEnum.Submitted,
]

/**
 * 审批单表单值定义
 */
export type FlowFieldValue = {
  fieldName: string
  value: any
  /**
   * 格式化后的 value, 目前仅 select 题目才会有该字段
   */
  formattedValue?: any
  /**
   * 班级名称，只有选择班级的表单项用得到
   */
  className?: string
}

/**
 * 审批列表 - 审批单
 */
export type WorkflowCenterCardFlow = Expand<
  FlowWithWorkflow,
  ['initiator', 'nodes', ['nodes', 'stages']]
>
export type FlowCardType = FC<{
  flow: WorkflowCenterCardFlow
  workflowName?: string
  reload?: MutationOptions['reload']
}>

export interface FlowTodoCardProps {
  todo: SpecTodoType<SpecTodoRelated[TodoDomainEnum.Workflow]>
  inFlow: boolean
  simplify?: boolean
  related: SpecTodoRelated[TodoDomainEnum.Workflow]
  /**
   * 格式化业务预览字段
   */
  formatBizFieldToTextValue?: (
    flowField: FlowFieldValue,
    field: Field,
    fieldValues: FlowFieldValue[],
  ) => string
  /**
   * 格式化业务字段 label
   */
  formatBizFieldLabel?: (args: {
    field: Field
    fieldValues: FlowFieldValue[]
  }) => string
  /**
   * 劫持原有的点击行为
   */
  onClick?: (related: SpecTodoRelated[TodoDomainEnum.Workflow]) => void

  /**
   * 标签名称
   */
  tagText?: string

  /**
   * 标题
   */
  title?: string

  /**
   * 子标题
   */
  subTitle?: string

  bottomLeft?: ReactNode
}

/**
 * 审批单待办
 */
export type FlowTodoCardType = React.FC<FlowTodoCardProps>

/**
 * 发起审批
 */
export type NewFlowModalProps = {
  visible: boolean
  onCancel: () => void
  /**
   * 审批插件 id
   */
  workflowId: number
  /**
   * 审批单 id，「再次发起」的场景会传该值，用于填充表单值
   */
  flowId?: number
  /**
   * 自定义参数
   */
  extraParams?: {
    [key: string]: any
  }
  /**
   * 成功之后的回调，返回 true 继续下一步，返回 false 则不进行下一步（现在是跳转到审批中心
   *
   * @param flow - 审批单
   * @returns 是否发起成功
   */
  onSucceed?: (flow: Flow) => boolean | Promise<boolean>

  /**
   * 审批流预览的接口，如传入则使用该接口，配合 flowPreviewLoading 一起使用
   */
  flowPreviewApi?: (
    payload: FlowPreviewReqInterface,
  ) => AxiosResponsePromise<Flow>

  /**
   * 审批流预览的 loading
   */
  flowPreviewLoading?: boolean
}
export type NewFlowModalType = React.FC<NewFlowModalProps>

/**
 * 查看审批单详情
 */
export type ShowFlowModalProps = {
  visible: boolean
  onCancel: () => void
  /**
   * 审批单 id
   */
  flowId: number
  /**
   * 查看的评论 ID
   */
  commentId?: number
}
export type ShowFlowModalType = React.FC<ShowFlowModalProps>

export type EditFlowModalType = React.FC<{
  visible: boolean
  onCancel: () => void
  /**
   * 审批单 id
   */
  flowId: number
  // 额外参数
  extraParams: {
    [key: string]: any
  }
  // 成功之后的回调，返回 true 继续下一步，返回 false 则不进行下一步（现在是跳转到审批中心
  onSucceed?: (flow: Flow) => boolean | Promise<boolean>
}>

/**
 * 适配新导师后，后端移除审批人类型，这里为了兼容按照原先定义，去掉导师/旧导师复制一个
 */
export enum GroupMemberTypeEnum {
  Teacher = 'teacher',
  AdminClassTeacher = 'admin_class_teacher',
  DormManager = 'dorm_manager',
}

export type FlowCardFlow = Expand<
  FlowWithWorkflow,
  ['initiator', 'nodes', ['nodes', 'stages']]
>

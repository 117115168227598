import { WidgetTypeEnum } from './widget-type-enum'

export interface WidgetInterface {
  /**
   * 组件唯一标识
   */
  identity: string
  /**
   * 组件类型
   */
  type: WidgetTypeEnum
  /**
   * 组件关联插件名
   */
  pluginName?: string | null
  /**
   * 前端组件属性
   */
  layoutAttributes: object
}

export class Widget implements WidgetInterface {
  /**
   * 组件唯一标识
   */
  identity: string
  /**
   * 组件类型
   */
  type: WidgetTypeEnum
  /**
   * 组件关联插件名
   */
  pluginName?: string | null
  /**
   * 前端组件属性
   */
  layoutAttributes: object

  constructor(props: WidgetInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      identity: _identity,
      type: _type,
      pluginName: _pluginName,
      layoutAttributes: _layoutAttributes,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.identity = _identity
    this.type = _type
    this.pluginName = _pluginName
    this.layoutAttributes = _layoutAttributes
  }

  static propKeys = ['identity', 'type', 'pluginName', 'layoutAttributes']
}

/**
 * @file 待办相关工具函数
 */
import { createEnumHelper } from '@seiue/util'

import { $t } from 'packages/locale'
import { Todo, TodoStatusEnum } from 'packages/sdks-next/chalk'

import { TodoTypeEnum, todoTypeToDomain } from './todo-type-enum'

/**
 * 获取 todo 的类型
 * @param todo
 */
export const getTodoType = (todo: Pick<Todo, 'type'>) =>
  (todo.type as TodoTypeEnum) || TodoTypeEnum.Unknown

/**
 * 获取 todo 的 Domain
 * @param todo
 */
export const getTodoDomain = (todo: Pick<Todo, 'type'>) => {
  const todoType = getTodoType(todo)
  if (todoType === TodoTypeEnum.Unknown) return null

  return todoTypeToDomain.get(todoType)
}

/**
 * @util 待办已忽略
 */
export const isTodoIgnored = (todo: Todo) =>
  todo.status === TodoStatusEnum.Ignored

/**
 * @util 待办处理中
 */
export const isTodoPending = (todo: Todo) =>
  todo.status === TodoStatusEnum.Pending

/**
 * @util 待办已完成
 */
export const isTodoCompleted = (todo: Todo) =>
  todo.status === TodoStatusEnum.Completed

/**
 * @util 待办已失效
 */
export const isTodoInvalid = (todo: Todo) =>
  todo.status === TodoStatusEnum.Invalid

export const todoStatusToLabel = createEnumHelper(() => ({
  [TodoStatusEnum.Ignored]: $t('已忽略'),
  [TodoStatusEnum.Pending]: $t('待处理'),
  [TodoStatusEnum.Completed]: $t('已完成'),
  [TodoStatusEnum.Invalid]: $t('已失效'),
}))

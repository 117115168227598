import { LocaleTextLocaleEnum } from './locale-text-locale-enum'
import { LocaleTextBelongsToEnum } from './locale-text-belongs-to-enum'
import { LocaleModule, LocaleModuleInterface } from './locale-module'

export interface ReferredLocaleTextInterface {
  /**
   * 区域，如 zh-CN ，en-US 。创建后，不可改
   */
  locale: LocaleTextLocaleEnum
  /**
   * 所属服务。创建后，不可改
   */
  belongsTo: LocaleTextBelongsToEnum
  /**
   * 翻译内容
   */
  content: string
  /**
   * 系统文本，当 locale 为 sys 时，填空字符串即可。创建后，不可改
   */
  refTo: string
  /**
   * 对应的系统文本 ID ，当 locale 为 sys 时，该字段为 null
   */
  refId?: number | null
  /**
   * 版本。创建后，不可改
   */
  version: number
  /**
   * 模块。默认为 通用 。
   * 已废弃，使用 modules 代替。
   */
  module?: string
  /**
   * 所属模块。expanded
   */
  modules?: LocaleModuleInterface[]
  /**
   * 是否与系统的文本一致（修改成自定义后，将为 false ，如果再修改为与系统一致，将为 true）
   */
  isSame: boolean
  /**
   * 是否是新版本中新加的文本
   */
  isNew: boolean
  /**
   * 表示是否被翻译过（不论是人工翻译还是机器翻译）。
   * 只在 school_id=0&locale=en-US 时有效。其余情况均为 false 。school_id=0&locale=en-US 时有效。其余情况均为 false 。
   * 之前的含义：
   * 1. 在 2023-06-30 前，表示是否被人为翻译（修改，不论是否包含中文）过。
   * 2. 在 2022-10-24 前，表示是否被机器翻译过。
   */
  translated: boolean
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class ReferredLocaleText implements ReferredLocaleTextInterface {
  /**
   * 区域，如 zh-CN ，en-US 。创建后，不可改
   */
  locale: LocaleTextLocaleEnum
  /**
   * 所属服务。创建后，不可改
   */
  belongsTo: LocaleTextBelongsToEnum
  /**
   * 翻译内容
   */
  content: string
  /**
   * 系统文本，当 locale 为 sys 时，填空字符串即可。创建后，不可改
   */
  refTo: string
  /**
   * 对应的系统文本 ID ，当 locale 为 sys 时，该字段为 null
   */
  refId?: number | null
  /**
   * 版本。创建后，不可改
   */
  version: number
  /**
   * 模块。默认为 通用 。
   * 已废弃，使用 modules 代替。
   */
  module?: string
  /**
   * 所属模块。expanded
   */
  modules?: LocaleModule[]
  /**
   * 是否与系统的文本一致（修改成自定义后，将为 false ，如果再修改为与系统一致，将为 true）
   */
  isSame: boolean
  /**
   * 是否是新版本中新加的文本
   */
  isNew: boolean
  /**
   * 表示是否被翻译过（不论是人工翻译还是机器翻译）。
   * 只在 school_id=0&locale=en-US 时有效。其余情况均为 false 。school_id=0&locale=en-US 时有效。其余情况均为 false 。
   * 之前的含义：
   * 1. 在 2023-06-30 前，表示是否被人为翻译（修改，不论是否包含中文）过。
   * 2. 在 2022-10-24 前，表示是否被机器翻译过。
   */
  translated: boolean
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: ReferredLocaleTextInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      locale: _locale,
      belongsTo: _belongsTo,
      content: _content,
      refTo: _refTo,
      refId: _refId,
      version: _version,
      module: _module,
      modules: _modules,
      isSame: _isSame,
      isNew: _isNew,
      translated: _translated,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.locale = _locale
    this.belongsTo = _belongsTo
    this.content = _content
    this.refTo = _refTo
    this.refId = _refId
    this.version = _version
    this.module = _module
    this.modules = _modules ? _modules.map(i => new LocaleModule(i)) : _modules
    this.isSame = _isSame
    this.isNew = _isNew
    this.translated = _translated
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'locale',
    'belongsTo',
    'content',
    'refTo',
    'refId',
    'version',
    'module',
    'modules',
    'isSame',
    'isNew',
    'translated',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

export interface RelationRulesInterface {
  /**
   * 德育和教师考核评价人关系 ids
   */
  relationIds?: number[]
  /**
   * 教师考核审核人关系 ids
   */
  reviewRelationIds?: number[]
}

export class RelationRules implements RelationRulesInterface {
  /**
   * 德育和教师考核评价人关系 ids
   */
  relationIds?: number[]
  /**
   * 教师考核审核人关系 ids
   */
  reviewRelationIds?: number[]

  constructor(props: RelationRulesInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      relationIds: _relationIds,
      reviewRelationIds: _reviewRelationIds,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.relationIds = _relationIds
    this.reviewRelationIds = _reviewRelationIds
  }

  static propKeys = ['relationIds', 'reviewRelationIds']
}

/**
 * @file 学期相关工具函数
 */

import { Moment, moment, MomentInput } from '@seiue/moment'
import { times, numberToChinese } from '@seiue/util'

import { $t } from 'packages/locale'
import { Semester } from 'packages/sdks-next/chalk'

/**
 * 根据日期，从学期列表取出对应的学期
 *
 * @param date - 日期
 * @param semesters - 学期列表
 * @returns 学期
 */
export function findSemesterByDate(date: string, semesters: Semester[]) {
  return (
    semesters.find(({ startAt, endAt }) =>
      moment(date).isBetween(startAt, endAt),
    ) ?? null
  )
}

/**
 * 根据学期的开始时间计算 date 的所在周（Index 值）
 *
 * @param date - 日期
 * @param semesterStartAt - 学期开始时间
 * @returns 周数
 */
export function dateToSemesterWeek(
  date: string | Moment,
  semesterStartAt: string,
) {
  if (!date) {
    throw new Error('function dateToSemesterWeek need a date')
  }

  /*
   * 学期开始日期和目标日期相差天数
   * （+ startAtOfTheWeek - 1）是为了确保学期开始日期不是周一导致的渲染错乱
   */
  const momentDate = moment(date)
  const momentStartAt = moment(semesterStartAt)
  if (momentDate.isBefore(momentStartAt)) {
    return 0
  }

  const week = momentStartAt.day()
  const startAtOfTheWeek = week === 0 ? 7 : week

  const diffDays =
    Math.abs(momentStartAt.diff(momentDate, 'days')) + startAtOfTheWeek - 1

  return Math.floor(diffDays / 7) + 1
}

type TranslateFormatter = {
  hasSpace?: boolean
  short?: boolean
  numberToChinese?: boolean
  en?: boolean
}

/**
 * 根据学期，来计算 date 的所在周文本
 * 学期实际开始时间：在系统中，有时候学期的的设置会早于真实的开学时间（比如开学是 9 月 1 号，但为了在系统中更便利的使用，该学期的开始时间被往前提，设置到了 8 月份。
 * 在这种情况下，用户界面的上课周显示，需要按实际开始时间来算，而不是系统学期设置中的开始时间
 *
 * @param date - 日期
 * @param semester - 学期
 * @param formatter -  格式化参数，hasSpace: 周数间插入空格，numberToChinese 启用时无效。short：是否缩写，numberToChinese：周数是否转化为中文，en 返回英语文本，仅在 short 模式下有效（非 short 模式直接切换多语言）
 * @returns string
 */
export function dateToSemesterWeekText(
  date: string,
  semester: Semester,
  formatter?: TranslateFormatter,
) {
  let weekIndex = dateToSemesterWeek(date, semester.startAt)

  // @ts-expect-error displayStartAt 暂未声明
  if (semester.displayStartAt) {
    // @ts-expect-error displayStartAt 暂未声明
    const displayStartMoment = moment(semester.displayStartAt)

    if (moment(date).isBefore(displayStartMoment)) {
      weekIndex = 0
    } else {
      const weekDiff =
        // @ts-expect-error displayStartAt 暂未声明
        dateToSemesterWeek(semester.displayStartAt, semester?.startAt || '') - 1

      weekIndex -= weekDiff
    }
  }

  const isHoliday = weekIndex <= 0 || moment(date).isAfter(semester.endAt)

  if (formatter?.short) {
    if (formatter?.en) {
      return !isHoliday ? `${weekIndex}st` : 'HOL.'
    }

    if (!isHoliday) {
      return formatter?.hasSpace
        ? $t('{week} 周', { week: weekIndex })
        : $t('{week}周', { week: weekIndex })
    }

    return $t('假期')
  }

  let weekValueForTrans = `${weekIndex}`

  if (formatter?.numberToChinese && !formatter?.en) {
    weekValueForTrans = numberToChinese(weekIndex)
  }

  if (!isHoliday) {
    return formatter?.hasSpace && !formatter?.numberToChinese
      ? $t('第 {week} 周', {
          week: weekValueForTrans,
        })
      : $t('第{week}周', {
          week: weekValueForTrans,
        })
  }

  return $t('假期')
}

/**
 * 根据学期的开始时间计算该周的开始时间与结束时间
 *
 * @param week - 第几周
 * @param semesterStartAt - 学期开始时间
 * @returns 开始时间与结束时间
 */
export function semesterWeekToDates(week: number, semesterStartAt: string) {
  const semesterStartAtMoment = moment(semesterStartAt)
  const weekMoment = moment(semesterStartAtMoment).add(week - 1, 'week')
  let startAt = moment(weekMoment).startOf('week')
  const endAt = moment(weekMoment).endOf('week')

  if (startAt.isBefore(semesterStartAtMoment)) {
    startAt = semesterStartAtMoment
  }

  return {
    startAt: startAt.format('YYYY-MM-DD'),
    endAt: endAt.format('YYYY-MM-DD'),
  }
}

/**
 * 根据星期几的数字，格式化为中文
 *
 * @param weekday - 星期几的数字
 * @returns 星期几的中文
 */
export function formatWeekdays(weekday: number) {
  return [
    $t('周日'),
    $t('周一'),
    $t('周二'),
    $t('周三'),
    $t('周四'),
    $t('周五'),
    $t('周六'),
    $t('周日'),
  ][weekday]
}

/**
 * 获取星期枚举
 *
 * @returns 星期枚举
 */
export function getWeekdaysEnum() {
  return [...Array(7)].map((val, index) => ({
    label: formatWeekdays(index + 1),
    value: index + 1,
  }))
}

/**
 * 获取学期的月份
 *
 * @param semester - 学期
 * @returns 月份列表
 */
export function getSemesterMonths(semester: Semester) {
  const startMoment = moment(semester.startAt).startOf('M')
  const endMoment = moment(semester.endAt).startOf('M')

  const monthCount = Math.ceil(endMoment.diff(startMoment, 'months', true)) + 1

  return times(monthCount, index => {
    const nextMonth = moment(startMoment).add(index, 'M')
    return {
      value: nextMonth.month(),
      label: nextMonth.format('MMMM'),
    }
  })
}

/**
 * 获取学期的周数
 *
 * @param semester - 学期
 * @returns 周数
 */
export function getSemesterWeekCount(semester: Semester) {
  const startMoment = moment(semester.startAt).startOf('week')
  const endMoment = moment(semester.endAt).startOf('week')

  return Math.ceil(endMoment.diff(startMoment, 'week', true)) + 1
}

/**
 * 判断日期是否在学期内
 *
 * @param day - 日期
 * @param semester - 学期
 * @returns 是否在学期内
 */
export function isDateInSemester(day: MomentInput, semester: Semester) {
  const dayMoment = moment(day)
  const startMoment = moment(semester.startAt)
  const endMoment = moment(semester.endAt)

  return !dayMoment.isBefore(startMoment) && !dayMoment.isAfter(endMoment)
}

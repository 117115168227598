import { arrayToMap, mapValues } from '@seiue/util'

import { useCurrentSchool } from 'packages/features/schools/hooks'
import { useCurrentReflection } from 'packages/features/sessions'
import { QueryOptions } from 'packages/sdks-next'
import {
  PricingPlanConstraints,
  acmApi$queryConfigItemsByService,
  acmApi$mutationConfigItemsByGroup,
} from 'packages/sdks-next/chalk'

import { useSelectedSemesterId } from '../semesters'

import {
  ConfigGroup,
  ConfigService,
  GroupItemsSetter,
  Merge,
  SchoolConfig,
} from './types'

/**
 * 读取和设置指定学校 (默认当前学校) 的某一组或多组 acm 设置
 *
 * @param args - args
 * @param args.schoolId - 可指定学校, 不传则默认当前学校
 * @param args.service - 要读取的设置组所在的服务
 * @param args.groupIn - 要读取的设置组
 * @param args.appendSemesterIdInScope - 是否在 scope 中添加学期
 * @param queryOptions - sdk query hook 接收的配置项
 * @returns [目标组里所有设置的 object, 加载状态, 刷新函数, 设置目标组的设置项]
 */
export const useSchoolConfigs = <
  TService extends ConfigService,
  TGroupIn extends ConfigGroup<TService>[],
>(
  {
    schoolId: _schoolId,
    service,
    groupIn,
    appendSemesterIdInScope,
  }: {
    schoolId?: number
    service: TService
    groupIn: TGroupIn
    appendSemesterIdInScope?: boolean
  },
  queryOptions?: QueryOptions,
): {
  configs: Merge<SchoolConfig[TService][TGroupIn[number]]> | null
  loading: boolean
  reload: () => void
  setGroupItems: GroupItemsSetter<TService>
} => {
  const me = useCurrentReflection()
  const schoolId = _schoolId ? String(_schoolId) : me?.schoolId

  const semesterId = useSelectedSemesterId()
  const scope = appendSemesterIdInScope
    ? `school.${schoolId}.semester.${semesterId}`
    : `school.${schoolId}`

  const { data, loading, reload } = acmApi$queryConfigItemsByService.useApi(
    {
      scope,
      service,
      query: { groupIn: groupIn.join(',') },
    },
    {
      disable:
        !schoolId ||
        !service ||
        !groupIn.join() ||
        (appendSemesterIdInScope && !semesterId),
      staleTime: 10 * 60,
      ...queryOptions,
    },
  )

  const configs = !data
    ? null
    : (mapValues(arrayToMap(data, 'name'), item => item.value) as any)

  const setGroupItems: GroupItemsSetter<TService> = async (
    group,
    groupItems,
  ) => {
    await acmApi$mutationConfigItemsByGroup.api(
      scope,
      service,
      group,
      // @ts-expect-error keyof SchoolConfig[TService][TGroup] is actually assignable to string
      groupItems,
    )

    reload()
  }

  return { configs, loading, reload, setGroupItems }
}

/**
 * 返回学校模块配置
 *
 * @param constraintName - 配置名称
 * @returns 配置值
 */
export const useSchoolConstrant = <T extends keyof PricingPlanConstraints>(
  constraintName: T,
): PricingPlanConstraints[T] => {
  const currentSchool = useCurrentSchool()

  return currentSchool?.customConstraints?.[constraintName]
}

/**
 * 返回学校最大的可上传附件数量，默认为 10
 *
 * @param max - 最大值
 * @returns 数字
 */
export const useSchoolMaxAttachmentCount = (max?: number) => {
  const globalMaxAttachmentCount = useSchoolConstrant(
    'globalMaxAttachmentCount',
  )

  const _max = max || globalMaxAttachmentCount || 10

  return _max
}

/**
 * @file 审批渲染器注册
 */

import React from 'react'

import {
  FlowTodoCardType,
  FlowCardType,
  WorkflowBizType,
  NewFlowModalType,
  ShowFlowModalType,
  EditFlowModalType,
} from './types'

type ExtendedRenderType = { [key: string]: any }

export type WorkflowRender<ExtendedRender = ExtendedRenderType> = {
  /**
   * 待办卡片（目前仅 chalk 需要注册）
   */
  TodoCard?: React.LazyExoticComponent<FlowTodoCardType>
  /**
   * 审批单卡片（用于审批列表）
   */
  FlowCard?: React.LazyExoticComponent<FlowCardType>
  /**
   * 发起审批
   */
  NewFlowModal?: React.LazyExoticComponent<NewFlowModalType>
  /**
   * 查看审批单详情
   */
  ShowFlowModal?: React.LazyExoticComponent<ShowFlowModalType>
  /**
   * 编辑审批单
   */
  EditFlowModal?: React.LazyExoticComponent<EditFlowModalType>
} & ExtendedRender

/**
 * 审批渲染器存储
 */
export const workflowRenders: {
  [type in WorkflowBizType]?: WorkflowRender
} = {}

/**
 * 注册审批渲染器
 */
export type RegisterWorkflowRenderType<ExtendedRender = ExtendedRenderType> = {
  bizType: WorkflowBizType
  render: WorkflowRender<ExtendedRender>
}

/**
 * 注册审批渲染器
 *
 * @param renders - 审批渲染器
 */
export const registerWorkflowRenders = (
  renders: RegisterWorkflowRenderType | RegisterWorkflowRenderType[],
) => {
  ;[...(Array.isArray(renders) ? renders : [renders])].forEach(
    ({ bizType, render }) => {
      workflowRenders[bizType] = render
    },
  )
}

/**
 * 获取审批渲染器
 *
 * @param bizType - 审批业务类型
 * @returns 审批渲染器
 */
export const getWorkflowRender = (bizType: WorkflowBizType) =>
  workflowRenders[bizType]

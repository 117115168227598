/**
 * @file 成绩动态相关 Utils
 */

import { get } from '@seiue/util'

import { $t } from 'packages/locale/LocaleProvider'
import {
  DisplayTypeEnum,
  ItemTypeEnum,
  ScoringTypeEnum,
  TrendEvent,
  TrendEventTypeEnum,
  ItemTag,
} from 'packages/sdks-next/vnas'

import {
  getTrendDomain,
  isAdditionTrend,
  isGradeTrend,
  isSpecialScoreTrend,
  isSpecialTrend,
  TrendDomainEnum,
} from './trends'

/**
 * data 是否有某个 key 值
 *
 * @param data - 数据源
 * @param keys - keys
 * @returns 是否
 */
const hasValues = (data: any, keys: string[]) =>
  keys.some(key => get(data, key) != null)

type ScoreValue = string | null | undefined

export type TagType = Omit<ItemTag, 'groupName'>

/**
 * 获取标签型分数的显示文本
 *
 * @param tag - 标签
 * @returns 文本
 */
export const getTagScoreText = (tag: TagType): string => {
  const isPositive = (Number(tag.score) || 0) >= 0
  return `${isPositive ? '+' : ''}${tag.score} ${tag.name}`
}

/**
 * 获取动态的分数数据
 *
 * @param trend - 动态
 * @returns 成绩相关数据
 */
export const getTrendScoreData = (trend: TrendEvent) => {
  let oldScore: ScoreValue
  let newScore: ScoreValue
  let fullScore: ScoreValue

  if (isGradeTrend(trend.type)) {
    oldScore = trend.oldGrade?.realScore
    newScore = trend.newGrade?.realScore
    fullScore = trend.assessment?.fullScore
    if (trend.newGrade?.specialScore) {
      newScore = trend.newGrade?.specialScore
      fullScore = undefined
    }

    if (trend.oldGrade?.specialScore) {
      oldScore = trend.oldGrade?.specialScore
      fullScore = undefined
    }
  } else {
    oldScore = trend.oldScore?.score
    // 若同时设为暂不评价，则依然显示老分数
    newScore = trend.isDelayed ? trend.oldScore?.score : trend.newScore?.score
    fullScore = trend.item?.fullScore
  }

  const isSameScore = oldScore === newScore
  const isSingleScore = !oldScore || !newScore

  let label = $t('得分')

  if (
    (trend.item?.type &&
      [ItemTypeEnum.AttendanceItem, ItemTypeEnum.SpecialItem].includes(
        trend.item?.type,
      )) ||
    trend.item?.scoringType === ScoringTypeEnum.Addition
  ) {
    label = $t('合计得分')
  }

  return {
    oldScore,
    fullScore,
    newScore,
    isSameScore,
    isSingleScore,
    label,
  }
}

/**
 * 是否显示分数数据
 *
 * @param trend - 动态
 * @returns 是否
 */
export const showTrendScore = (trend: TrendEvent) =>
  hasValues(getTrendScoreData(trend), ['oldScore', 'newScore'])

/**
 * 获取班级排名数据
 *
 * @param trend - 动态
 * @returns 排名数据
 */
export const getTrendRankData = (trend: TrendEvent) => {
  if (isGradeTrend(trend.type)) {
    return {
      oldRank: trend.oldGrade?.rank,
      oldRankBase: trend.oldGrade?.rankBase,
      newRank: trend.newGrade?.rank,
      newRankBase: trend.newGrade?.rankBase,
    }
  }

  return {
    oldRank: trend.oldScore?.rank,
    oldRankBase: trend.oldScore?.rankBase,
    newRank: trend.newScore?.rank,
    newRankBase: trend.newScore?.rankBase,
  }
}

/**
 * 是否显示班级排名
 *
 * @param trend - 动态
 * @returns 是否
 */
export const showTrendRank = (trend: TrendEvent) =>
  hasValues(getTrendRankData(trend), ['oldRank', 'newRank'])

/**
 * 获取课程排名数据
 *
 * @param trend - 动态
 * @returns 课程排名数据
 */
export const getTrendCourseRankData = (trend: TrendEvent) => {
  if (!isGradeTrend(trend.type)) {
    return {}
  }

  return {
    oldRank: trend.oldGrade?.courseRank,
    oldRankBase: trend.oldGrade?.courseRankBase,
    newRank: trend.newGrade?.courseRank,
    newRankBase: trend.newGrade?.courseRankBase,
  }
}

/**
 * 是否显示课程排名
 *
 * @param trend - 动态
 * @returns 是否
 */
export const showTrendCourseRank = (trend: TrendEvent) =>
  hasValues(getTrendCourseRankData(trend), ['oldRank', 'newRank'])

/**
 * 获取课程打星数据
 *
 * @param trend - 动态
 * @param trend.oldScore - 老分数
 * @param trend.newScore - 新分数
 * @param trend.isDelayed - 是否暂不评价
 * @returns 打星数据
 */
export const getTrendStarData = ({
  oldScore,
  newScore,
  isDelayed,
}: TrendEvent) => {
  const oldStar =
    typeof oldScore?.star === 'number' ? oldScore.star : oldScore?.score

  return {
    oldStar,
    // 若同时设为暂不评价，则依然显示老分数
    newStar: isDelayed
      ? oldStar
      : typeof newScore?.star === 'number'
      ? newScore.star
      : newScore?.score,
  }
}

/**
 * 是否显示打星
 *
 * @param trend - 动态
 * @returns 是否
 */
export const showTrendStar = (trend: TrendEvent) => {
  if (
    !trend.item ||
    (trend.item.scoringType !== ScoringTypeEnum.Star &&
      trend.item.displayType !== DisplayTypeEnum.Star)
  )
    return false

  return hasValues(getTrendStarData(trend), ['oldStar', 'newStar'])
}

/**
 * 获取课程等级数据
 *
 * @param trend - 动态
 * @returns 等级数据
 */
export const getTrendLevelData = (trend: TrendEvent) => {
  if (isGradeTrend(trend.type)) {
    return {
      oldLevel: trend.oldGrade?.specialLevel
        ? trend.oldGrade?.specialLevel
        : trend.oldGrade?.level,
      newLevel: trend.newGrade?.specialLevel
        ? trend.newGrade?.specialLevel
        : trend.newGrade?.level,
    }
  }

  if (trend.item?.scoringType === ScoringTypeEnum.Level) {
    return {
      oldLevel: trend.oldScore?.tag,
      // 若同时设为暂不评价，则依然显示老分数
      newLevel: trend.isDelayed ? trend.oldScore?.tag : trend.newScore?.tag,
      levelDescription: trend.isDelayed ? '' : trend.newScore?.levelDescription,
    }
  }

  return {
    oldLevel: trend.oldGrade?.specialLevel
      ? trend.oldGrade?.specialLevel
      : trend.oldScore?.level,
    // 若为特殊评价，则直接显示特殊评价，若设为暂不评价，则依然显示老分数
    newLevel: trend.newGrade?.specialLevel
      ? trend.newGrade?.specialLevel
      : trend.isDelayed
      ? trend.oldScore?.level
      : trend.newScore?.level,
    levelDescription:
      trend.isDelayed || !!trend.newGrade?.specialLevel
        ? ''
        : trend.newScore?.levelDescription,
  }
}

/**
 * 是否显示分数等级
 *
 * @param trend - 动态
 * @returns 是否
 */
export const showTrendScoreLevel = (trend: TrendEvent) =>
  !isGradeTrend(trend.type) &&
  hasValues(getTrendLevelData(trend), ['oldLevel', 'newLevel'])

/**
 * 是否显示成绩等级
 *
 * @param trend - 动态
 * @returns 是否
 */
export const showTrendGradeLevel = (trend: TrendEvent) =>
  isGradeTrend(trend.type) &&
  hasValues(getTrendLevelData(trend), ['oldLevel', 'newLevel'])

/**
 * 是否显示等级评价项
 *
 * @param trend - 动态
 * @returns 是否
 */
export const showTrendLevel = (trend: TrendEvent) =>
  trend.item?.scoringType === ScoringTypeEnum.Level &&
  hasValues(getTrendLevelData(trend), ['oldLevel', 'newLevel'])

/**
 * 获取绩点数据
 *
 * @param trend - 动态
 * @returns 绩点数据
 */
export const getTrendGpaData = (trend: TrendEvent) => ({
  oldGpa: trend.oldGrade?.gpa,
  newGpa: trend.newGrade?.gpa,
})

/**
 * 是否显示绩点
 *
 * @param trend - 动态
 * @returns 是否
 */
export const showTrendGpa = (trend: TrendEvent) =>
  isGradeTrend(trend.type) &&
  hasValues(getTrendGpaData(trend), ['oldGpa', 'newGpa'])

/**
 * 获取评语数据
 *
 * @param trend - 动态
 * @returns 评语
 */
export const getTrendReviewData = (trend: TrendEvent) => {
  if (isGradeTrend(trend.type)) {
    return {
      oldReview: {
        text: trend?.oldGrade?.review,
        attachments: trend.oldGrade?.attachments,
      },
      newReview: {
        text: trend?.newGrade?.review,
        attachments: trend.newGrade?.attachments,
      },
    }
  }

  return {
    oldReview: {
      text: trend?.oldScore?.review,
      attachments: trend.oldScore?.attachments,
    },
    newReview: {
      // 若同时设为暂不评价，则依然显示老分数
      text: trend.isDelayed ? trend?.oldScore?.review : trend?.newScore?.review,
      // 若同时设为暂不评价，则依然显示老分数
      attachments: trend.isDelayed
        ? trend.oldScore?.attachments
        : trend.newScore?.attachments,
    },
  }
}

/**
 * 是否显示评语
 *
 * @param trend - 动态
 * @returns 是否
 */
export const showTrendReview = (trend: TrendEvent) => {
  const { oldReview, newReview } = getTrendReviewData(trend)
  const domain = getTrendDomain(trend.type)

  if (
    !oldReview?.text &&
    !oldReview?.attachments?.length &&
    !newReview?.text &&
    !newReview?.attachments?.length
  )
    return false

  if (isSpecialTrend(trend.type) && !isSpecialScoreTrend(trend)) {
    if (trend.type === TrendEventTypeEnum.SpecialScoreAdded) {
      return !!newReview?.text
    }

    return true
  }

  return domain !== TrendDomainEnum.Removed
}

/**
 * 获取课程标签数据
 *
 * @param trend - 动态
 * @returns 标签数据
 */
export const getTrendTagsData = (trend: TrendEvent) => {
  const { oldTags, newTags } = trend

  const isAddition = isAdditionTrend(trend.type)
  const isSpecial = isSpecialTrend(trend.type)

  let deleteText = ''

  if (oldTags?.length) {
    if (isSpecial) {
      if (isSpecialScoreTrend(trend)) {
        deleteText = $t('删除了个性化分数："{score}"', {
          score: getTagScoreText({
            score: oldTags[0].score || '',
            name: '',
          }),
        })
      }
    } else {
      deleteText = $t('删除了{type}标签：{tags}', {
        type: isAddition ? $t('加减分') : '',
        tags: oldTags.map(tag => `"${tag.tag}"`).join(',') || '',
      })
    }
  }

  return {
    oldTags,
    newTags,
    deleteText,
    isSingle: !oldTags?.length || !newTags?.length,
  }
}

/**
 * 是否显示标签
 *
 * @param trend - 动态
 * @returns 是否
 */
export const showTrendTags = (trend: TrendEvent) => {
  const { oldTags, newTags } = getTrendTagsData(trend)
  const isSpecial = isSpecialTrend(trend.type)

  if (!oldTags?.length && !newTags?.length) return false

  if (isSpecial) {
    // 删除了个性化评语
    if (
      trend.type === TrendEventTypeEnum.SpecialScoreRemoved &&
      !oldTags?.[0].score &&
      !oldTags?.[0].tag
    )
      return false

    // 增加了个性化评语
    if (
      trend.type === TrendEventTypeEnum.SpecialScoreAdded &&
      !newTags?.[0].score &&
      !newTags?.[0].tag
    ) {
      return false
    }
  }

  return true
}

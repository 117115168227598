/**
 * @file 加密数据相关信息
 */

/**
 * 加密手机号码，隐藏中间四位
 * @param phone 原手机号
 * @return 加密后的手机号
 */
export const encryptPhone: (phone: string) => string = phone =>
  `${phone.slice(0, 3)}****${phone.slice(-4)}`

export const encryptEmail: (email: string) => string = email => {
  const res = email.match(/(^.+)(@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+)$/)

  if (!res) {
    return email
  }

  return `${res[1].slice(0, 3)}${res[1].slice(3).replace(/./g, '*')}${res[2]}`
}

/**
 * @file 将字段转为英文
 */

import {
  faBookOpen,
  faFan,
  faFlagAlt,
  faPenAlt,
} from '@fortawesome/pro-solid-svg-icons'

import { $t } from 'packages/locale'
import { createLocaleMessageHelper } from 'packages/locale/utils'

/* eslint-disable seiue/missing-formatted-message  */
export const getTranslatedTag = createLocaleMessageHelper(() => ({
  课程: $t('课程'),
  自习: $t('自习'),
  升旗: $t('升旗'),
  活动: $t('活动'),
})).get

export const getDefaultClassTag = () => '课程'
export const getActivityClassTag = () => '活动'
export const getOrderedTags = () => [
  {
    tag: getDefaultClassTag(),
    icon: faBookOpen,
  },
  {
    tag: '自习',
    icon: faPenAlt,
  },
  {
    tag: '升旗',
    icon: faFlagAlt,
  },
  {
    tag: getActivityClassTag(),
    icon: faFan,
  },
]

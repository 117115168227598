import {
  ExamSubjectRelation,
  ExamSubjectRelationInterface,
} from './exam-subject-relation'
import { LevelSetting, LevelSettingInterface } from './level-setting'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import { ExamSession, ExamSessionInterface } from './exam-session'
import { ExamScoring, ExamScoringInterface } from './exam-scoring'
import {
  ExamWithoutSubject,
  ExamWithoutSubjectInterface,
} from './exam-without-subject'
import {
  ExamSubjectStats,
  ExamSubjectStatsInterface,
} from './exam-subject-stats'

export interface ExamSubjectInterface {
  examId: number
  levelSettingId: number | null
  name: string
  fullScore?: string | null
  sort: number
  /**
   * {"student": 100, "guardian": 15, "teacher": 20}
   */
  reflectionsTotal?: object
  classesTotal?: number
  subjectRelations?: ExamSubjectRelationInterface[]
  levelSetting?: LevelSettingInterface | null
  /**
   * 出卷教师
   */
  paperManagerIds?: number[]
  /**
   * 出卷教师信息
   * expand paper_managers
   */
  paperManagers?: SecuredReflectionInterface[]
  /**
   * 开启第三方阅卷扩展
   * 题目数量
   * expand scoring_question_total
   */
  scoringQuestionTotal?: number
  /**
   * 开启第三方阅卷扩展
   * 已导入成绩学生数量
   * expand has_review_score_total
   */
  hasReviewScoreTotal?: number
  /**
   * 开启第三方阅卷扩展
   * 考试时间:start_at、end_at
   * expand exam_session
   */
  examSession?: ExamSessionInterface | null
  /**
   * 开启第三方阅卷扩展
   * 阅卷ID:id
   * expand exam_scoring
   */
  examScoring?: ExamScoringInterface | null
  /**
   * 前台-出题教师-考试科目列表
   * expand exam
   */
  exam?: ExamWithoutSubjectInterface
  stats?: ExamSubjectStatsInterface
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class ExamSubject implements ExamSubjectInterface {
  examId: number
  levelSettingId: number | null
  name: string
  fullScore?: string | null
  sort: number
  /**
   * {"student": 100, "guardian": 15, "teacher": 20}
   */
  reflectionsTotal?: object
  classesTotal?: number
  subjectRelations?: ExamSubjectRelation[]
  levelSetting?: LevelSetting | null
  /**
   * 出卷教师
   */
  paperManagerIds?: number[]
  /**
   * 出卷教师信息
   * expand paper_managers
   */
  paperManagers?: SecuredReflection[]
  /**
   * 开启第三方阅卷扩展
   * 题目数量
   * expand scoring_question_total
   */
  scoringQuestionTotal?: number
  /**
   * 开启第三方阅卷扩展
   * 已导入成绩学生数量
   * expand has_review_score_total
   */
  hasReviewScoreTotal?: number
  /**
   * 开启第三方阅卷扩展
   * 考试时间:start_at、end_at
   * expand exam_session
   */
  examSession?: ExamSession | null
  /**
   * 开启第三方阅卷扩展
   * 阅卷ID:id
   * expand exam_scoring
   */
  examScoring?: ExamScoring | null
  /**
   * 前台-出题教师-考试科目列表
   * expand exam
   */
  exam?: ExamWithoutSubject
  stats?: ExamSubjectStats
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: ExamSubjectInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      examId: _examId,
      levelSettingId: _levelSettingId,
      name: _name,
      fullScore: _fullScore,
      sort: _sort,
      reflectionsTotal: _reflectionsTotal,
      classesTotal: _classesTotal,
      subjectRelations: _subjectRelations,
      levelSetting: _levelSetting,
      paperManagerIds: _paperManagerIds,
      paperManagers: _paperManagers,
      scoringQuestionTotal: _scoringQuestionTotal,
      hasReviewScoreTotal: _hasReviewScoreTotal,
      examSession: _examSession,
      examScoring: _examScoring,
      exam: _exam,
      stats: _stats,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.examId = _examId
    this.levelSettingId = _levelSettingId
    this.name = _name
    this.fullScore = _fullScore
    this.sort = _sort
    this.reflectionsTotal = _reflectionsTotal
    this.classesTotal = _classesTotal
    this.subjectRelations = _subjectRelations
      ? _subjectRelations.map(i => new ExamSubjectRelation(i))
      : _subjectRelations
    this.levelSetting = _levelSetting
      ? new LevelSetting(_levelSetting)
      : _levelSetting
    this.paperManagerIds = _paperManagerIds
    this.paperManagers = _paperManagers
      ? _paperManagers.map(i => new SecuredReflection(i))
      : _paperManagers
    this.scoringQuestionTotal = _scoringQuestionTotal
    this.hasReviewScoreTotal = _hasReviewScoreTotal
    this.examSession = _examSession
      ? new ExamSession(_examSession)
      : _examSession
    this.examScoring = _examScoring
      ? new ExamScoring(_examScoring)
      : _examScoring
    this.exam = _exam ? new ExamWithoutSubject(_exam) : _exam
    this.stats = _stats ? new ExamSubjectStats(_stats) : _stats
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'examId',
    'levelSettingId',
    'name',
    'fullScore',
    'sort',
    'reflectionsTotal',
    'classesTotal',
    'subjectRelations',
    'levelSetting',
    'paperManagerIds',
    'paperManagers',
    'scoringQuestionTotal',
    'hasReviewScoreTotal',
    'examSession',
    'examScoring',
    'exam',
    'stats',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

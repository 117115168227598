export interface ClassMemberTypeInfoInterface {
  /**
   * 成员类型名
   */
  name?: string
  /**
   * 成员类型id
   */
  typeSettingId?: number
  /**
   * 是否加入考勤
   */
  joinAttendance?: number
}

export class ClassMemberTypeInfo implements ClassMemberTypeInfoInterface {
  /**
   * 成员类型名
   */
  name?: string
  /**
   * 成员类型id
   */
  typeSettingId?: number
  /**
   * 是否加入考勤
   */
  joinAttendance?: number

  constructor(props: ClassMemberTypeInfoInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      typeSettingId: _typeSettingId,
      joinAttendance: _joinAttendance,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.typeSettingId = _typeSettingId
    this.joinAttendance = _joinAttendance
  }

  static propKeys = ['name', 'typeSettingId', 'joinAttendance']
}

import { State } from '@seiue/ui'
import React, { Suspense } from 'react'
import {
  Redirect,
  RedirectProps,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom'

import { RouteNode, ModalRouteNode, RouteParamsList } from 'packages/route'

import { ModalRoute } from './ModalRoute'

/**
 * @deprecated use RouteComponent instead of React.FC<RouteProps>
 */
export type RouteProps<T = any> = {
  route: RouteNode
} & RouteComponentProps &
  T

type RouteCompProps<
  TRouteParamsList extends RouteParamsList,
  TProps = {},
> = TProps & {
  route: RouteNode & { name?: keyof TRouteParamsList }
}

export type RouteComponent<
  TRouteParamsList extends RouteParamsList,
  TProps = {},
> = React.FC<RouteCompProps<TRouteParamsList, TProps>>

/**
 * 嵌套路由
 *
 * @param props - props
 * @param props.routes - 路由
 * @param props.modalRoutes - 模态路由
 * @param props.redirect - 重定向
 * @param props.authFunction - 权限函数
 * @returns JSX.Element
 */
export const NestedRoute = ({
  routes = [],
  modalRoutes = [],
  redirect = { to: '/404' },
  authFunction = () => true,
  ...params
}: {
  routes?: RouteNode[]
  modalRoutes?: ModalRouteNode[]
  redirect?: RedirectProps
  authFunction?: (route: RouteNode) => boolean
}) => (
  <>
    <Suspense fallback={<State.Loading />}>
      <Switch>
        {routes.map((route: RouteNode) => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={props =>
              authFunction(route) ? (
                <route.component {...props} route={route} {...params} />
              ) : (
                <Redirect to="/403" />
              )
            }
          />
        ))}
        <Redirect {...redirect} />
      </Switch>
    </Suspense>
    <Suspense fallback={<span />}>
      {modalRoutes.map((route: ModalRouteNode) => (
        <ModalRoute key={route.name} route={route} />
      ))}
    </Suspense>
  </>
)

import { isAxiosError } from '@seiue/axios'
import { NoneMetaError } from '@seiue/error-handler'
import humps from 'humps'

import { useCurrentRoles } from 'packages/features/roles'
import { useCurrentReflection } from 'packages/features/sessions'
import {
  LayoutPageIdentityEnum,
  LayoutPlatformEnum,
  personalDictApi$getPersonalDict,
  personalDictApi$setPersonalDict,
  layoutApi$loadMeCurrentLayout,
} from 'packages/sdks-next/chalk'

import {
  PersonalConfigKey,
  PersonalTableConfigKey,
  PersonalConfigs,
} from './types'

/**
 * 读取当前用户的某一项个人设置 (personal dict)
 *
 * @param key - 设置的 key
 * @param options - 配置项
 * @param options.disable - 是否禁用接口请求
 * @returns [设置的值, 更新该设置的方法, loading]
 */
export const usePersonalConfig = <
  Key extends PersonalConfigKey | PersonalTableConfigKey,
  Value = PersonalConfigs[Key],
>(
  key: Key,
  { disable }: { disable?: boolean } = {},
) => {
  const currentReflection = useCurrentReflection()

  const { data, loading, reload } = personalDictApi$getPersonalDict.useApi(
    currentReflection.id,
    {
      staleTime: 10 * 60,
      disable,
    },
  )

  const configs = (data ?? {}) as Partial<PersonalConfigs>

  const setter = async (newVal: PersonalConfigs[Key]) => {
    await personalDictApi$setPersonalDict.api(currentReflection.id, {
      [key]: newVal,
    })

    await reload()
  }

  return [
    /**
     * 如果 key 是 snake_case, 会被 axios 转为 camelCase,
     * 因此如果原 key 不存在, 转为 camelize 再试
     *
     * WARNING: 极端情况下可能会有 key 转 camelize 后与其他 key 重名, 导致 bug
     */
    (configs[key] ??
      configs[humps.camelize(key) as Key] ??
      null) as Value | null,
    setter,
    loading,
    reload,
  ] as const
}

/**
 * 获取当前用户指定页面的视图（后端会根据使用范围返回优先级最高的视图）
 *
 * @param query - query
 * @param query.pageIdentity - 页面标识
 * @param query.platform - 视图平台
 * @param options - 接口配置项
 * @param options.disable - 是否禁用接口请求
 * @returns 指定页面的视图
 */
export const useLoadMyCurrentLayout = (
  {
    pageIdentity,
    platform,
  }: {
    pageIdentity: LayoutPageIdentityEnum
    platform: LayoutPlatformEnum
  },
  options: { disable?: boolean },
) => {
  const roles = useCurrentRoles({ isManager: true })

  const { data, loading, refetching } = layoutApi$loadMeCurrentLayout.useApi(
    {
      pageIdentity,
      platform,
      query: {
        roleIds: roles.map(role => role.id).join(','),
      },
    },
    {
      staleTime: 15 * 60,
      ...options,
      catchError: e => {
        if (isAxiosError(e, 404)) {
          return new NoneMetaError({
            report: false,
          })
        }

        return e
      },
    },
  )

  return {
    data: data || null,
    loading,
    refetching,
  }
}

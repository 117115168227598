/**
 * @file 应用中心菜单
 */

import { Icon } from '@seiue/ui'
import { intersection } from '@seiue/util'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { useCurrentMenuTypes } from 'packages/feature-utils/menus'
import { $t } from 'packages/locale'
import { LayoutContext } from 'packages/web-layout/context'
import { useActivePath } from 'packages/web-layout/hooks'
import { MenuType } from 'packages/web-layout/types'

import { useShowAppCenter } from '../hooks'

/**
 * 应用中心菜单
 *
 * @constructor
 */
export const ApplicationMenu = () => {
  const history = useHistory()
  const activePath = useActivePath()
  const currentMenuTypes = useCurrentMenuTypes()

  const active = activePath === '/user/applications-center'
  const showAppCenter = useShowAppCenter()

  const openApplication = () => {
    history.push('/user/applications-center')
  }

  return (
    <LayoutContext.Consumer>
      {({ collapsed }) =>
        showAppCenter &&
        intersection(currentMenuTypes, [MenuType.Default, MenuType.Apps])
          .length > 0 ? (
          <Wrapper
            onClick={openApplication}
            active={active}
            collapsed={collapsed}
          >
            <IconStyled
              size={collapsed ? 16 : 20}
              icon="ApplicationCenter"
              style={collapsed ? {} : { marginRight: 12 }}
              color={'white'}
            />
            {collapsed ? null : <MenuText>{$t('应用中心')}</MenuText>}
          </Wrapper>
        ) : null
      }
    </LayoutContext.Consumer>
  )
}

const Wrapper = styled.div<{ collapsed: boolean; active: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;

  ${props =>
    !props.collapsed
      ? css`
          height: 52px;
          padding: 0 12px;
          margin: 16px 12px;
          background: ${props.active ? '#EBF3FF' : '#F3F4F8'};
          &:hover {
            background: ${props.active ? '#EBF3FF' : '#EDEFF2'};
          }
        `
      : css`
          width: 40px;
          height: 40px;
          justify-content: center;
          margin: 12px auto;
          background: ${props.active ? '#EBF3FF' : 'transparent'};
          &:hover {
            background: ${props.active ? '#EBF3FF' : '#F3F4F8'};
          }
        `}

  span {
    color: ${props => (props.active ? '#325CD9' : props.theme.text._1)};
  }
`

const MenuText = styled.span`
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
`

const IconStyled = styled(Icon)`
  border-radius: 4px;
  background-color: ${props => props.theme.brand.text0};
  cursor: pointer !important;
`

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

import attendancePng from './attendance.png'

registerDomainMessage({
  type: MessageDomainEnum.AbnormalAttendance,
  getLabel: () => $t('考勤'),
  labelStyle: calculateLabelStyle('#E7F9ED', '#3E8B57'),
  messages: [
    'student',
    'guardian',
    'status_change_to_student',
    'status_change_to_guardian',
  ],
  icon: attendancePng,
})

registerDomainMessage({
  type: MessageDomainEnum.MissAttendance,
  getLabel: () => $t('考勤'),
  labelStyle: calculateLabelStyle('#E7F9ED', '#3E8B57'),
  messages: ['teacher'],
  icon: attendancePng,
})

registerDomainMessage({
  type: MessageDomainEnum.AdminClassAbnormalAttendance,
  getLabel: () => $t('考勤'),
  labelStyle: calculateLabelStyle('#E7F9ED', '#3E8B57'),
  messages: [
    // 行政班学生异常考勤通知
    'student',
  ],
  icon: attendancePng,
})

registerDomainMessage({
  type: MessageDomainEnum.AdminClassMissAttendance,
  getLabel: () => $t('考勤'),
  labelStyle: calculateLabelStyle('#E7F9ED', '#3E8B57'),
  messages: [
    // 行政班教师未考勤提醒
    'teacher',
  ],
  icon: attendancePng,
})

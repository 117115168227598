import { moment } from '@seiue/moment'
import { getPaletteColorByIndex } from '@seiue/theme'
import { first, sortBy, uniqBy } from '@seiue/util'
import { DefaultTheme } from 'styled-components'

import { $t } from 'packages/locale'
import { Expand } from 'packages/sdks-next'
import {
  Assessment,
  Item,
  ItemTag,
  PlanTypeEnum,
  Stage,
} from 'packages/sdks-next/vnas'

import { TagStatusEnum } from '../types'

export * from './tag-item'

/**
 * 计算评价项加减分标签的背景色和文字色
 *
 * @param args - args
 * @param args.theme - 主题色值
 * @param args.tag - 标签
 * @param args.selected - 标签是否已选中
 * @param args.isNeutral - 是否以「中立」样式显示（即非正分，也非负分）
 * @returns 背景色和文字色
 */
export const getAdditionTagColor = ({
  theme,
  tag,
  selected,
  isNeutral,
}: {
  theme: DefaultTheme
  tag: Omit<ItemTag, 'groupName'>
  selected?: boolean
  isNeutral?: boolean
}) => {
  const isPositive = (Number(tag.score) || 0) >= 0

  return {
    backgroundColor:
      selected && !isPositive
        ? theme.status.danger.element
        : selected
        ? theme.status.success.element
        : isNeutral
        ? theme.status.ongoing.backgroundLight
        : isPositive
        ? theme.status.success.backgroundLight
        : theme.status.danger.backgroundLight,
    color: selected
      ? theme.text._0
      : isNeutral
      ? theme.status.ongoing.text
      : isPositive
      ? theme.status.success.text
      : theme.status.danger.text,
  }
}

/**
 * 计算评价项标签的背景色和文字色
 *
 * @param args - args
 * @param args.theme - 主题色值
 * @param args.tag - 标签
 * @param args.selected - 标签是否已选中
 * @returns 背景色和文字色
 */
export const getNormalTagColor = ({
  theme,
  tag,
  selected,
}: {
  theme: DefaultTheme
  tag: Omit<ItemTag, 'groupName'>
  selected?: boolean
}) => {
  return {
    backgroundColor: selected
      ? tag.description === TagStatusEnum.Default
        ? theme.status.ongoing.element
        : tag.description === TagStatusEnum.Positive
        ? theme.status.success.element
        : theme.status.danger.element
      : tag.description === TagStatusEnum.Default
      ? theme.status.ongoing.backgroundLight
      : tag.description === TagStatusEnum.Positive
      ? theme.status.success.backgroundLight
      : theme.status.danger.backgroundLight,
    color: selected
      ? theme.text._0
      : tag.description === TagStatusEnum.Default
      ? theme.status.ongoing.text
      : tag.description === TagStatusEnum.Positive
      ? theme.status.success.text
      : theme.status.danger.text,
  }
}

/**
 * 获取学段名称的 color schama
 *
 * @param stage - 学段
 * @returns color schema
 */
export const getStageColorSchema = (stage: Pick<Stage, 'name'>) => {
  const charCode = Array.from(stage.name).reduce(
    (prev, currentWord) => prev + currentWord.charCodeAt(0),
    0,
  )

  return getPaletteColorByIndex(charCode)
}

/**
 * 获取评价项关联的学段 tooltip 提示
 *
 * @param item - 评价项
 * @returns 学段 tooltip 提示
 */
export const getItemStageTooltip = (item: Item) => {
  if (!item.stage) return null

  const hasTaskEndAt = !!item.taskRelations?.some(
    relation => !!relation.task?.expiredAt,
  )

  return hasTaskEndAt
    ? $t('任务截止时间在 {stageName} 学段内，将计入 {stageName} 成绩', {
        stageName: item.stage.name,
      })
    : $t('评价项分数在 {stageName} 学段内录入，将计入 {stageName} 成绩', {
        stageName: item.stage.name,
      })
}

/**
 * 从指定的学段列表中找出最近的且未结束的学段
 *
 * @param semesterStages - 指定学段列表
 * @returns 最近的且未结束的学段
 */
export const getCurrentSemesterStage = (semesterStages: Stage[]) => {
  return first(
    sortBy(semesterStages, 'endAt').filter(stage =>
      moment(stage.endAt).isAfter(moment()),
    ),
  )
}

/**
 * 获取指定学段的之前学段
 *
 * @param targetStage - 指定学段
 * @param semesterStages - 学段列表
 * @returns 之前学段
 */
export const getPrevSemesterStages = (
  targetStage: Stage,
  semesterStages: Stage[],
) => {
  return semesterStages.filter(stage =>
    moment(stage.endAt).isBefore(targetStage.endAt),
  )
}

/**
 * 判断评价是否显示分数
 *
 * @param assessment - 评价
 * @returns 是否显示分数
 */
export const isAssessmentDisplayScore = (assessment?: Assessment | null) => {
  if (!assessment) return false

  const levelType = assessment?.plan?.type === PlanTypeEnum.Level

  if (levelType) {
    return !!assessment?.itemNum
  }

  return true
}

/**
 * 根据传入的子评价项，补全父级评价项
 *
 * @param param0 - props
 * @param param0.assessment - 评价
 * @param param0.items - 评价项
 * @returns 评价项
 */
export const fulfillParentItems = ({
  assessment,
  items,
}: {
  assessment: Expand<Assessment, ['items']>
  items: Item[]
}) => {
  const nextItems = [...items]

  const getParentItem = (item: Item) => {
    if (!item.pid) return null

    return assessment.items?.find(({ id }) => id === item.pid)
  }

  items.forEach(item => {
    const parentItem = getParentItem(item)

    if (parentItem) {
      nextItems.push(parentItem)

      const grandParentItem = getParentItem(parentItem)

      if (grandParentItem) {
        nextItems.push(grandParentItem)
      }
    }
  })

  return uniqBy(nextItems, 'id')
}

import {
  FormTemplateFieldOption,
  FormTemplateFieldOptionInterface,
} from './form-template-field-option'

export interface FormTemplateFieldContainerInterface {
  options?: FormTemplateFieldOptionInterface[]
  /**
   * 选项数据源，如果该字段未设置时使用 options 的值作为选项
   * 该字段的值是字符串，由各个业务动态注册而来，前端根据其值判断应该那哪里获取数据。
   */
  optionSource?: string
  /**
   * 数据源显示名称
   */
  optionSourceLabel?: string
  /**
   * 复合题｜矩阵单选题分组标识
   */
  group?: string
  /**
   * 是否为复合题｜矩阵单选题的父级题目
   */
  isParent?: boolean
  /**
   * 区县是否选填 (仅用于省市区字段), 默认 false
   */
  isDistrictOptional?: boolean
  /**
   * 是否整体评价，仅用于评教问卷, 默认 false
   */
  isOverallEvaluation?: boolean
  /**
   * 子问题顺序
   * 矩阵单选
   */
  subSort?: number
}

export class FormTemplateFieldContainer
  implements FormTemplateFieldContainerInterface
{
  options?: FormTemplateFieldOption[]
  /**
   * 选项数据源，如果该字段未设置时使用 options 的值作为选项
   * 该字段的值是字符串，由各个业务动态注册而来，前端根据其值判断应该那哪里获取数据。
   */
  optionSource?: string
  /**
   * 数据源显示名称
   */
  optionSourceLabel?: string
  /**
   * 复合题｜矩阵单选题分组标识
   */
  group?: string
  /**
   * 是否为复合题｜矩阵单选题的父级题目
   */
  isParent?: boolean
  /**
   * 区县是否选填 (仅用于省市区字段), 默认 false
   */
  isDistrictOptional?: boolean
  /**
   * 是否整体评价，仅用于评教问卷, 默认 false
   */
  isOverallEvaluation?: boolean
  /**
   * 子问题顺序
   * 矩阵单选
   */
  subSort?: number

  constructor(props: FormTemplateFieldContainerInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      options: _options,
      optionSource: _optionSource,
      optionSourceLabel: _optionSourceLabel,
      group: _group,
      isParent: _isParent,
      isDistrictOptional: _isDistrictOptional,
      isOverallEvaluation: _isOverallEvaluation,
      subSort: _subSort,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.options = _options
      ? _options.map(i => new FormTemplateFieldOption(i))
      : _options
    this.optionSource = _optionSource
    this.optionSourceLabel = _optionSourceLabel
    this.group = _group
    this.isParent = _isParent
    this.isDistrictOptional = _isDistrictOptional
    this.isOverallEvaluation = _isOverallEvaluation
    this.subSort = _subSort
  }

  static propKeys = [
    'options',
    'optionSource',
    'optionSourceLabel',
    'group',
    'isParent',
    'isDistrictOptional',
    'isOverallEvaluation',
    'subSort',
  ]
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import type { CreateOrgFieldGroupsAndFieldsQuery } from './create-org-field-groups-and-fields-query'

const buildConfig = createConfigBuilder('chalk')

export const departmentApi$createOrgFieldGroupsAndFields = {
  config(
    createOrgFieldGroupsAndFieldsQuery?: CreateOrgFieldGroupsAndFieldsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'post',
      '/department/fields',
      {},
      createOrgFieldGroupsAndFieldsQuery,
    )
  },

  /**
   * @param [createOrgFieldGroupsAndFieldsQuery]
   * @param [departmentQuery.isReplace]
   * @param [options]
   * @return AxiosResponsePromise<null>
   */

  api(
    createOrgFieldGroupsAndFieldsQuery?: CreateOrgFieldGroupsAndFieldsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<null> {
    return axios(
      departmentApi$createOrgFieldGroupsAndFields.config(
        createOrgFieldGroupsAndFieldsQuery,
        options,
      ),
    ) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      (query?: CreateOrgFieldGroupsAndFieldsQuery) =>
        departmentApi$createOrgFieldGroupsAndFields.api(query),
      mutationOptions,
    )
  },
}

// @ts-ignore
departmentApi$createOrgFieldGroupsAndFields.api._name_ = `Department.createOrgFieldGroupsAndFields`

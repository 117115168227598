/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  UpdateSemesterAndTimetableReq,
  UpdateSemesterAndTimetableReqInterface,
} from './update-semester-and-timetable-req'
import { Semester, SemesterInterface } from './semester'

const buildConfig = createConfigBuilder('scms')

export const semesterApi$updateSemesterAndTimetable = {
  config(
    id: number,
    updateSemesterAndTimetableReq: UpdateSemesterAndTimetableReqInterface,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'put',
      '/system/semesters/{id}/timetables',
      {
        id,
      },
      null,
      updateSemesterAndTimetableReq,
      {
        format: 'json',
        isArray: false,
        defaults: {
          semester: (v: any) => v || null,
          timetables: (v: any) => v || [],
        },
      },
    )
  },

  /**
   * @summary 更新学期与课表
   * @param id
   * @param updateSemesterAndTimetableReq
   * @param [options]
   * @return AxiosResponsePromise<Semester>
   */

  api(
    id: number,
    updateSemesterAndTimetableReq: UpdateSemesterAndTimetableReqInterface,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Semester> {
    return axios(
      semesterApi$updateSemesterAndTimetable.config(
        id,
        updateSemesterAndTimetableReq,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new Semester(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({
        id,
        payload,
      }: {
        id: number
        payload: UpdateSemesterAndTimetableReqInterface
      }) => semesterApi$updateSemesterAndTimetable.api(id, payload),
      mutationOptions,
    )
  },
}

// @ts-ignore
semesterApi$updateSemesterAndTimetable.api._name_ = `Semester.updateSemesterAndTimetable`

/**
 * @file 新版 Reflection 常用工具函数
 */

import { compact, createEnumHelper } from '@seiue/util'

import { TermTypeEnum } from 'packages/features/terms/types'
import { $t } from 'packages/locale'
import { RoleEnum, Term, termApi$queryTerm } from 'packages/sdks-next/chalk'

export * from './views'
export * from './name'
export * from './setting'

/**
 * 角色与归档字段的对应关系
 */
export const roleEnumToArchivedTermKeyHelper = createEnumHelper({
  [RoleEnum.Student]: TermTypeEnum.StudentArchivedType,
  [RoleEnum.Teacher]: TermTypeEnum.TeacherArchivedType,
  [RoleEnum.Guardian]: TermTypeEnum.StudentArchivedType,
  [RoleEnum.Staff]: TermTypeEnum.StaffArchivedType,
  [RoleEnum.Shadow]: null,
})

/**
 * 获取角色归档的 term
 *
 * @param roles - 角色的数组
 * @returns 归档的 term
 */
export const getRoleArchivedTerms = (roles: RoleEnum[]) => {
  const archivedTermKeys = compact(
    roles.map(role => roleEnumToArchivedTermKeyHelper.get(role)),
  )

  return Promise.all(
    archivedTermKeys.map(termKey => termApi$queryTerm.api(termKey)),
  ).then(res =>
    roles.reduce((result, role) => {
      const termKeysRes = res.find(
        (_r, index) =>
          roleEnumToArchivedTermKeyHelper.get(role) === archivedTermKeys[index],
      )

      if (role === RoleEnum.Guardian) {
        return {
          ...result,
          [role]: termKeysRes?.data?.map(term => ({
            ...term,
            name: `${$t('学生')}${term.name}`,
          })),
        }
      }

      return {
        ...result,
        [role]: termKeysRes?.data,
      }
    }, {} as { [key in RoleEnum]?: Term[] }),
  )
}

/**
 * 所有用户相关的，无法被更改可编辑设置的字段
 */
export const getNoEditableFields = createEnumHelper({
  [RoleEnum.Teacher]: [
    'name',
    'usin',
    'wechat.openid',
    'school_years',
    'work_years',
    'entered_years',
    'teaching_age',
    'archived_type_id',
    'photo',
    'entered_on',
    'discipline_id',
    /**
     * 组织架构 - 部门字段
     */
    'department_ids',
  ],
  [RoleEnum.Student]: [
    'name',
    'usin',
    'graduates_in_id',
    'grade.name',
    'admin_classes',
    'class_teachers',
    'grade',
    'wechat.openid',
    'entered_on',
    'archived_type_id',
    'photo',
    'dorm',
    'dorm_admins',
    'berth',
    'guardian.name',
    'guardian.phone',
    'guardian.usin',
    'guardian.guardian_role_id',
    /**
     * 组织架构 - 部门字段
     */
    'department_ids',
    /**
     * 导师
     */
    'mentors',
  ],
  [RoleEnum.Guardian]: [
    'archived_type_id',
    'wechat.openid',
    'name',
    'usin',
    'pupil.name',
    'pupil.usin',
    'pupil.account',
    'active_status',
  ],
  [RoleEnum.Staff]: ['archived_type_id', 'wechat.openid', 'name', 'usin'],
  [RoleEnum.Shadow]: [] as string[],
})

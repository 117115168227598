/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { GroupRole, GroupRoleInterface } from './group-role'
import type { QueryCustomGroupRolesQuery } from './query-custom-group-roles-query'

const buildConfig = createConfigBuilder('chalk')

export const customGroupApi$queryCustomGroupRoles = {
  config(
    queryCustomGroupRolesQuery?: QueryCustomGroupRolesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/custom_group/group_roles',
      {},
      queryCustomGroupRolesQuery,
    )
  },

  /**
   * @summary Class QueryCustomGroupRolesApi
   * @param [queryCustomGroupRolesQuery]
   * @param [customGroupQuery.groupTypeSysTemplate] 群组类型的模版
   * @param [options]
   * @return AxiosResponsePromise<GroupRole[]>
   */

  api(
    queryCustomGroupRolesQuery?: QueryCustomGroupRolesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<GroupRole[]> {
    return axios(
      customGroupApi$queryCustomGroupRoles.config(
        queryCustomGroupRolesQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new GroupRole(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = GroupRole[]>(
    query?: QueryCustomGroupRolesQuery,
    queryOptions?: QueryOptionsWithSelect<GroupRole[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<GroupRole[], TSelected>(
      customGroupApi$queryCustomGroupRoles.api,
      'CustomGroup.queryCustomGroupRoles',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
customGroupApi$queryCustomGroupRoles.api._name_ = `CustomGroup.queryCustomGroupRoles`

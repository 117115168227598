/**
 * @file 学案-消息 domain 注册
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Handout,
  getLabel: () => $t('学案'),
  labelStyle: calculateLabelStyle('#F7E9FA', '#9E37B4'),
  messages: ['review_publish', 'discussion_commented', 'discussion_replied'],
})

/**
 * @file 德育审批相关的工具函数
 */

import { groupBy, isPositive, pick } from '@seiue/util'

import { LegoFormAnswer } from 'packages/components/LegoForm/types'
import {
  NewWorkflowFormatAnswersByFlow,
  NewWorkflowFormatBizFieldValue,
} from 'packages/features/workflows/components/NewWorkflow/types'
import { FlowFieldValue } from 'packages/features/workflows/types'
import { Attachment } from 'packages/sdks-next/chalk'

import {
  MoralWorkflowEvaluatedValue,
  MoralWorkflowFieldNameEnum,
  MoralWorkflowTagsValue,
} from './types'

/**
 * 发起审批 - 格式化德育审批业务字段的表单值
 *
 * @param args - args
 * @param args.field - 业务字段
 * @param args.answers - 业务字段的提交值
 * @returns 格式化后的表单值
 */
export const formatMoralBizFieldValue: NewWorkflowFormatBizFieldValue = ({
  field,
  answers,
}) => {
  const answer = answers?.[0]
  if (!answer) return null

  switch (field.name) {
    // 被评人
    case MoralWorkflowFieldNameEnum.MoralEvaluated:
      return {
        fieldName: MoralWorkflowFieldNameEnum.MoralEvaluated,
        value: pick(answer, ['rid', 'name', 'usin']),
      }

    // 德育标签
    case MoralWorkflowFieldNameEnum.MoralTags:
      return {
        fieldName: MoralWorkflowFieldNameEnum.MoralTags,
        value: answer['tags'],
      }

    // 说明
    case MoralWorkflowFieldNameEnum.MoralRemark:
      return {
        fieldName: MoralWorkflowFieldNameEnum.MoralRemark,
        value: answer.label ?? '',
      }

    // 证明材料
    case MoralWorkflowFieldNameEnum.MoralAttachment:
      return {
        fieldName: MoralWorkflowFieldNameEnum.MoralAttachment,
        value: answer.attributes?.attachments,
      }
    default:
      return null
  }
}

/**
 * 查看审批单 - 将德育审批业务字段的表单值转换为纯文本
 *
 * @param args - args
 * @param args.fieldName - 字段 name
 * @param args.value - 字段值
 * @returns 字段纯文本值
 */
export const formatMoralBizFieldToTextValue = ({
  fieldName,
  value,
}: FlowFieldValue): string => {
  switch (fieldName) {
    // 被评人
    case MoralWorkflowFieldNameEnum.MoralEvaluated: {
      const evaluatedValue = (value ?? {}) as MoralWorkflowEvaluatedValue
      return `${evaluatedValue.name}/${evaluatedValue.usin}`
    }

    // 德育评价标签
    case MoralWorkflowFieldNameEnum.MoralTags: {
      const tagsValue = (value ?? []) as MoralWorkflowTagsValue
      return Object.entries(groupBy(tagsValue, 'assessmentName'))
        .map(([assessmentName, tags]) => {
          const itemName = tags[0]?.itemName

          return `${assessmentName}/${itemName}/${tags
            .map(
              tag =>
                `${tag.tag} ${
                  tag.score
                    ? isPositive(tag.score)
                      ? `+${tag.score}`
                      : tag.score
                    : ''
                }`,
            )
            .join('；')}`
        })
        .join('')
    }

    // 说明
    case MoralWorkflowFieldNameEnum.MoralRemark:
      return value

    // 证明材料
    case MoralWorkflowFieldNameEnum.MoralAttachment: {
      const attachments = (value ?? []) as Attachment[]
      return attachments.map(attachment => attachment.name).join('、')
    }

    default:
      return ''
  }
}

/**
 * 将德育审批的表单值转换为 answers 格式
 *
 * @param args - args
 * @param args.answers - 答案列表
 * @param args.fields - 字段列表
 * @param args.fieldValues - 表单值
 * @returns LegoFormAnswer[]
 */
export const formatMoralAnswersByFlow: NewWorkflowFormatAnswersByFlow = ({
  answers,
  fields,
  fieldValues,
}) => {
  const bizAnswers: LegoFormAnswer[] = []

  const addBizAnswer = (fieldName: MoralWorkflowFieldNameEnum) => {
    const bizField = fields.find(field => field.name === fieldName)

    const bizValues = fieldValues.find(field => field.fieldName === fieldName)

    if (bizField && bizValues) {
      let bizValue = bizValues.value

      // 德育评价标签
      if (fieldName === MoralWorkflowFieldNameEnum.MoralTags) {
        return
      }

      // 说明
      if (fieldName === MoralWorkflowFieldNameEnum.MoralRemark) {
        bizValue = {
          label: bizValue,
        }
      }

      // 证明材料
      else if (fieldName === MoralWorkflowFieldNameEnum.MoralAttachment) {
        bizValue = {
          attributes: {
            attachments: bizValue,
          },
        }
      }

      bizAnswers.push({
        id: bizField.id,
        formId: 0,
        formTemplateFieldId: bizField.id as number,
        name: bizField.name,
        label: bizField.label,
        ...bizValue,
      })
    }
  }

  addBizAnswer(MoralWorkflowFieldNameEnum.MoralEvaluated)
  addBizAnswer(MoralWorkflowFieldNameEnum.MoralTags)
  addBizAnswer(MoralWorkflowFieldNameEnum.MoralRemark)
  addBizAnswer(MoralWorkflowFieldNameEnum.MoralAttachment)

  return [...bizAnswers, ...answers]
}

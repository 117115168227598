import { TaskSubmission, TaskSubmissionInterface } from './task-submission'
import { TaskReviewResultEnum } from './task-review-result-enum'
import { Attachment, AttachmentInterface } from './attachment'

export interface TaskReviewInterface {
  receiverId: number
  reviewerId: number
  submissionId?: number | null
  submission?: TaskSubmissionInterface | null
  /**
   * 内容(纯文本)
   */
  contentText?: string
  /**
   * 结果
   */
  result: TaskReviewResultEnum
  /**
   * 理由
   */
  reason?: string
  /**
   * 评论内容
   */
  content?: string
  /**
   * 附件
   */
  attachments?: AttachmentInterface[]
  /**
   * 参与评价/暂不评价
   */
  doEvaluation?: boolean
  /**
   * 是否批改了作业成果
   */
  isSubmissionChanged?: boolean
  /**
   * 是否设为优秀作业
   */
  isExcellentSubmission?: boolean
  /**
   * 是否发布任务关联学案成绩
   */
  isPublishHandoutReviews?: boolean
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class TaskReview implements TaskReviewInterface {
  receiverId: number
  reviewerId: number
  submissionId?: number | null
  submission?: TaskSubmission | null
  /**
   * 内容(纯文本)
   */
  contentText?: string
  /**
   * 结果
   */
  result: TaskReviewResultEnum
  /**
   * 理由
   */
  reason?: string
  /**
   * 评论内容
   */
  content?: string
  /**
   * 附件
   */
  attachments?: Attachment[]
  /**
   * 参与评价/暂不评价
   */
  doEvaluation?: boolean
  /**
   * 是否批改了作业成果
   */
  isSubmissionChanged?: boolean
  /**
   * 是否设为优秀作业
   */
  isExcellentSubmission?: boolean
  /**
   * 是否发布任务关联学案成绩
   */
  isPublishHandoutReviews?: boolean
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: TaskReviewInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      receiverId: _receiverId,
      reviewerId: _reviewerId,
      submissionId: _submissionId,
      submission: _submission,
      contentText: _contentText,
      result: _result,
      reason: _reason,
      content: _content,
      attachments: _attachments,
      doEvaluation: _doEvaluation,
      isSubmissionChanged: _isSubmissionChanged,
      isExcellentSubmission: _isExcellentSubmission,
      isPublishHandoutReviews: _isPublishHandoutReviews,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.receiverId = _receiverId
    this.reviewerId = _reviewerId
    this.submissionId = _submissionId
    this.submission = _submission
      ? new TaskSubmission(_submission)
      : _submission
    this.contentText = _contentText
    this.result = _result
    this.reason = _reason
    this.content = _content
    this.attachments = _attachments
      ? _attachments.map(i => new Attachment(i))
      : _attachments
    this.doEvaluation = _doEvaluation
    this.isSubmissionChanged = _isSubmissionChanged
    this.isExcellentSubmission = _isExcellentSubmission
    this.isPublishHandoutReviews = _isPublishHandoutReviews
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'receiverId',
    'reviewerId',
    'submissionId',
    'submission',
    'contentText',
    'result',
    'reason',
    'content',
    'attachments',
    'doEvaluation',
    'isSubmissionChanged',
    'isExcellentSubmission',
    'isPublishHandoutReviews',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

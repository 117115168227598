import { GstTemplateResourceSettingTypeEnum } from './gst-template-resource-setting-type-enum'
import {
  GstTemplateResourceSettingField,
  GstTemplateResourceSettingFieldInterface,
} from './gst-template-resource-setting-field'

export interface GstTemplateResourceSettingInterface {
  name: string
  /**
   * 编排主体｜编排资源标识
   */
  source: string
  /**
   * 编排主体｜编排资源类型
   */
  kind: GstTemplateResourceSettingTypeEnum
  fields: GstTemplateResourceSettingFieldInterface[]
}

export class GstTemplateResourceSetting
  implements GstTemplateResourceSettingInterface
{
  name: string
  /**
   * 编排主体｜编排资源标识
   */
  source: string
  /**
   * 编排主体｜编排资源类型
   */
  kind: GstTemplateResourceSettingTypeEnum
  fields: GstTemplateResourceSettingField[]

  constructor(props: GstTemplateResourceSettingInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      source: _source,
      kind: _kind,
      fields: _fields,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.source = _source
    this.kind = _kind
    this.fields = _fields
      ? _fields.map(i => new GstTemplateResourceSettingField(i))
      : _fields
  }

  static propKeys = ['name', 'source', 'kind', 'fields']
}

import { ScopePatch, ScopePatchInterface } from './scope-patch'

export interface AbnormalNoticeSettingInterface {
  /**
   * 接受异常通知的角色 guardian mentor director
   */
  roles?: string[] | null
  /**
   * 是否允许班主任勾选通知角色
   */
  allowTick?: boolean
  /**
   * 当考勤异常插件开启时，传递所选固定角色之外的其他人员ID
   */
  otherTeacherIds?: number[]
  /**
   * 是否允许发送短信, 默认关闭
   */
  allowSendSms?: boolean
  /**
   * 允许的范围
   */
  allowSendScope?: ScopePatchInterface | null
}

export class AbnormalNoticeSetting implements AbnormalNoticeSettingInterface {
  /**
   * 接受异常通知的角色 guardian mentor director
   */
  roles?: string[] | null
  /**
   * 是否允许班主任勾选通知角色
   */
  allowTick?: boolean
  /**
   * 当考勤异常插件开启时，传递所选固定角色之外的其他人员ID
   */
  otherTeacherIds?: number[]
  /**
   * 是否允许发送短信, 默认关闭
   */
  allowSendSms?: boolean
  /**
   * 允许的范围
   */
  allowSendScope?: ScopePatch | null

  constructor(props: AbnormalNoticeSettingInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      roles: _roles,
      allowTick: _allowTick,
      otherTeacherIds: _otherTeacherIds,
      allowSendSms: _allowSendSms,
      allowSendScope: _allowSendScope,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.roles = _roles
    this.allowTick = _allowTick
    this.otherTeacherIds = _otherTeacherIds
    this.allowSendSms = _allowSendSms
    this.allowSendScope = _allowSendScope
      ? new ScopePatch(_allowSendScope)
      : _allowSendScope
  }

  static propKeys = [
    'roles',
    'allowTick',
    'otherTeacherIds',
    'allowSendSms',
    'allowSendScope',
  ]
}

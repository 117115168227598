/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'

const buildConfig = createConfigBuilder('chalk')

export const personalDictApi$setPersonalDict = {
  config(
    rid: number,
    object: object,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'put',
      '/dict/reflections/{rid}/dict',
      {
        rid,
      },
      null,
      object,
      {
        format: 'json',
        isArray: false,
        defaults: {},
      },
    )
  },

  /**
   * @summary 设置个人 dict
   * @param rid who's dict
   * @param object
   * @param [options]
   * @return AxiosResponsePromise<object>
   */

  api(
    rid: number,
    object: object,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<object> {
    return axios(
      personalDictApi$setPersonalDict.config(rid, object, options),
    ) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({ rid, payload }: { rid: number; payload: object }) =>
        personalDictApi$setPersonalDict.api(rid, payload),
      mutationOptions,
    )
  },
}

// @ts-ignore
personalDictApi$setPersonalDict.api._name_ = `PersonalDict.setPersonalDict`

export interface GroupEvaluatedMemberInterface {
  /**
   * 班级 id
   */
  classId: number
  /**
   * 参与评教的代课教师
   */
  joinedSubstituteTeacherIds?: number[]
  /**
   * 参与评教的任课教师
   */
  joinedTeacherIds?: number[]
  /**
   * 不参与评教的任课教师
   */
  notJoinedTeacherIds?: number[]
}

export class GroupEvaluatedMember implements GroupEvaluatedMemberInterface {
  /**
   * 班级 id
   */
  classId: number
  /**
   * 参与评教的代课教师
   */
  joinedSubstituteTeacherIds?: number[]
  /**
   * 参与评教的任课教师
   */
  joinedTeacherIds?: number[]
  /**
   * 不参与评教的任课教师
   */
  notJoinedTeacherIds?: number[]

  constructor(props: GroupEvaluatedMemberInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      classId: _classId,
      joinedSubstituteTeacherIds: _joinedSubstituteTeacherIds,
      joinedTeacherIds: _joinedTeacherIds,
      notJoinedTeacherIds: _notJoinedTeacherIds,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.classId = _classId
    this.joinedSubstituteTeacherIds = _joinedSubstituteTeacherIds
    this.joinedTeacherIds = _joinedTeacherIds
    this.notJoinedTeacherIds = _notJoinedTeacherIds
  }

  static propKeys = [
    'classId',
    'joinedSubstituteTeacherIds',
    'joinedTeacherIds',
    'notJoinedTeacherIds',
  ]
}

import {
  SchoolPluginAdmins,
  SchoolPluginAdminsInterface,
} from './school-plugin-admins'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import { PluginKindEnum } from './plugin-kind-enum'
import { PluginCategoryEnum } from './plugin-category-enum'
import { ExpandPlugin, ExpandPluginInterface } from './expand-plugin'

export interface EnabledSchoolPluginInterface {
  schoolId: number
  pluginId: number
  label: string
  icon: string
  parentId?: number | null
  admins: SchoolPluginAdminsInterface
  bizId?: number | null
  /**
   * 当前用户是否在可见范围内
   */
  isVisible: boolean
  /**
   * 当前用户是否在管理范围内
   */
  isAdmin: boolean
  /**
   * expand 时返回
   */
  adminReflections?: SecuredReflectionInterface[]
  entrypoints?: string[]
  entryUrls: object
  settings?: object | null
  pluginName: string
  pluginLabel: string
  pluginEntryUrls: object | null
  pluginSettingUrl: string
  pluginKind: PluginKindEnum
  pluginType: string
  pluginCategory: PluginCategoryEnum
  /**
   * 客户端
   */
  pluginPlatforms?: string[]
  plugin?: ExpandPluginInterface
  /**
   * 版本ID
   */
  versionId?: number | null
  /**
   * 分组 id
   */
  groupId: number
  /**
   * 主键 ID
   */
  id: number
}

export class EnabledSchoolPlugin implements EnabledSchoolPluginInterface {
  schoolId: number
  pluginId: number
  label: string
  icon: string
  parentId?: number | null
  admins: SchoolPluginAdmins
  bizId?: number | null
  /**
   * 当前用户是否在可见范围内
   */
  isVisible: boolean
  /**
   * 当前用户是否在管理范围内
   */
  isAdmin: boolean
  /**
   * expand 时返回
   */
  adminReflections?: SecuredReflection[]
  entrypoints?: string[]
  entryUrls: object
  settings?: object | null
  pluginName: string
  pluginLabel: string
  pluginEntryUrls: object | null
  pluginSettingUrl: string
  pluginKind: PluginKindEnum
  pluginType: string
  pluginCategory: PluginCategoryEnum
  /**
   * 客户端
   */
  pluginPlatforms?: string[]
  plugin?: ExpandPlugin
  /**
   * 版本ID
   */
  versionId?: number | null
  /**
   * 分组 id
   */
  groupId: number
  /**
   * 主键 ID
   */
  id: number

  constructor(props: EnabledSchoolPluginInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      pluginId: _pluginId,
      label: _label,
      icon: _icon,
      parentId: _parentId,
      admins: _admins,
      bizId: _bizId,
      isVisible: _isVisible,
      isAdmin: _isAdmin,
      adminReflections: _adminReflections,
      entrypoints: _entrypoints,
      entryUrls: _entryUrls,
      settings: _settings,
      pluginName: _pluginName,
      pluginLabel: _pluginLabel,
      pluginEntryUrls: _pluginEntryUrls,
      pluginSettingUrl: _pluginSettingUrl,
      pluginKind: _pluginKind,
      pluginType: _pluginType,
      pluginCategory: _pluginCategory,
      pluginPlatforms: _pluginPlatforms,
      plugin: _plugin,
      versionId: _versionId,
      groupId: _groupId,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.pluginId = _pluginId
    this.label = _label
    this.icon = _icon
    this.parentId = _parentId
    this.admins = _admins ? new SchoolPluginAdmins(_admins) : _admins
    this.bizId = _bizId
    this.isVisible = _isVisible
    this.isAdmin = _isAdmin
    this.adminReflections = _adminReflections
      ? _adminReflections.map(i => new SecuredReflection(i))
      : _adminReflections
    this.entrypoints = _entrypoints
    this.entryUrls = _entryUrls
    this.settings = _settings
    this.pluginName = _pluginName
    this.pluginLabel = _pluginLabel
    this.pluginEntryUrls = _pluginEntryUrls
    this.pluginSettingUrl = _pluginSettingUrl
    this.pluginKind = _pluginKind
    this.pluginType = _pluginType
    this.pluginCategory = _pluginCategory
    this.pluginPlatforms = _pluginPlatforms
    this.plugin = _plugin ? new ExpandPlugin(_plugin) : _plugin
    this.versionId = _versionId
    this.groupId = _groupId
    this.id = _id
  }

  static propKeys = [
    'schoolId',
    'pluginId',
    'label',
    'icon',
    'parentId',
    'admins',
    'bizId',
    'isVisible',
    'isAdmin',
    'adminReflections',
    'entrypoints',
    'entryUrls',
    'settings',
    'pluginName',
    'pluginLabel',
    'pluginEntryUrls',
    'pluginSettingUrl',
    'pluginKind',
    'pluginType',
    'pluginCategory',
    'pluginPlatforms',
    'plugin',
    'versionId',
    'groupId',
    'id',
  ]
}

import { ProfileMenuRoleEnum } from './profile-menu-role-enum'

export interface MenuSettingInterface {
  /**
   * 档案菜单角色，教师档案菜单/学生档案菜单
   */
  profileRole?: ProfileMenuRoleEnum[]
  /**
   * 学生档案菜单需要初始化的权限，动态的
   */
  defaultProfilePermissions?: string[]
  /**
   * 当选择通用应用和其他应用时，菜单需要挂载的位置
   */
  studentProfileMountOn?: string | null
  teacherProfileMountOn?: string | null
}

export class MenuSetting implements MenuSettingInterface {
  /**
   * 档案菜单角色，教师档案菜单/学生档案菜单
   */
  profileRole?: ProfileMenuRoleEnum[]
  /**
   * 学生档案菜单需要初始化的权限，动态的
   */
  defaultProfilePermissions?: string[]
  /**
   * 当选择通用应用和其他应用时，菜单需要挂载的位置
   */
  studentProfileMountOn?: string | null
  teacherProfileMountOn?: string | null

  constructor(props: MenuSettingInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      profileRole: _profileRole,
      defaultProfilePermissions: _defaultProfilePermissions,
      studentProfileMountOn: _studentProfileMountOn,
      teacherProfileMountOn: _teacherProfileMountOn,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.profileRole = _profileRole
    this.defaultProfilePermissions = _defaultProfilePermissions
    this.studentProfileMountOn = _studentProfileMountOn
    this.teacherProfileMountOn = _teacherProfileMountOn
  }

  static propKeys = [
    'profileRole',
    'defaultProfilePermissions',
    'studentProfileMountOn',
    'teacherProfileMountOn',
  ]
}

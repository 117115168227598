export interface StudentMultiClassInterface {
  /**
   * 多班上课的学生 ID
   */
  rid: number
  /**
   * 其他班的 ids
   */
  classIds?: number[]
}

export class StudentMultiClass implements StudentMultiClassInterface {
  /**
   * 多班上课的学生 ID
   */
  rid: number
  /**
   * 其他班的 ids
   */
  classIds?: number[]

  constructor(props: StudentMultiClassInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      rid: _rid,
      classIds: _classIds,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.rid = _rid
    this.classIds = _classIds
  }

  static propKeys = ['rid', 'classIds']
}

export enum TrendEventTypeEnum {
  ResitGradePublished = 'resit_grade.published',
  ResitGradeRevoked = 'resit_grade.revoked',
  ResitGradeUpdated = 'resit_grade.updated',
  ResitScorePublished = 'resit_score.published',
  ResitScoreRevoked = 'resit_score.revoked',
  ResitScoreUpdated = 'resit_score.updated',
  GradePublished = 'grade.published',
  GradeRevoked = 'grade.revoked',
  GradeUpdated = 'grade.updated',
  StageGradePublished = 'stage_grade.published',
  StageGradeRevoked = 'stage_grade.revoked',
  StageGradeUpdated = 'stage_grade.updated',
  ScorePublished = 'score.published',
  ScoreRevoked = 'score.revoked',
  ScoreUpdated = 'score.updated',
  ReviewPublished = 'review.published',
  ReviewUpdated = 'review.updated',
  ReviewRevoked = 'review.revoked',
  AttendanceScoreAdded = 'attendance_score.added',
  AttendanceScoreUpdated = 'attendance_score.updated',
  AttendanceScoreRemoved = 'attendance_score.removed',
  AdditionScoreAdded = 'addition_score.added',
  AdditionScoreUpdated = 'addition_score.updated',
  AdditionScoreRemoved = 'addition_score.removed',
  TagAdded = 'tag.added',
  TagRemoved = 'tag.removed',
  SpecialScoreAdded = 'special_score.added',
  SpecialScoreRemoved = 'special_score.removed',
  ItemDeleted = 'item.deleted',
}

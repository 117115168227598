/**
 * @file 文件上传相关 hooks
 */
import {
  isUploadableFile,
  FileUploadable,
  Config,
  FileOriginal,
  SeiueFiles,
} from '@seiue/file'
import { isNumber, merge } from '@seiue/util'
import { useState, useCallback, useRef, useEffect } from 'react'

import { useSchoolConstrant } from 'packages/feature-utils/school-configs'

/**
 * 构造可上传文件钩子
 *
 * @param initialOriginalFiles - 原始文件
 * @param config - 上传配置
 * @param netdiskOptions - 网盘相关配置
 * @param netdiskOptions.bizSig - 传入 bizSig 以实现匿名上传
 * @returns 可上传文件与设置文件的函数
 */
export function useUploader<TFileOriginal = FileOriginal>(
  initialOriginalFiles: TFileOriginal[],
  config: Config<TFileOriginal>,
  netdiskOptions?: {
    bizSig?: string
  },
) {
  // File 格式化为可上传文件
  const fileFormatter = useCallback(
    (fileOriginal: TFileOriginal) => {
      const { uploaderFactory, autoUpload } = config

      // 以文件名或 hash 值作为 key
      const originKey = (fileOriginal as any).name || (fileOriginal as any).hash

      const fileKey = `uploadable-${originKey}-${Date.now()}`
      const setFile = (result: Partial<FileUploadable<TFileOriginal>>) => {
        const index = filesRef.current.findIndex(file => file.key === fileKey)

        if (index >= 0) {
          const newFiles = [...filesRef.current]
          newFiles[index] = merge(newFiles[index], result)
          setFiles(newFiles)
        }
      }

      const result = {
        key: fileKey,
        file: fileOriginal,
        ...uploaderFactory(fileOriginal, setFile, config),
      }

      // 放到下一个 MacroTask 再运行，好让 upload 对 file 的影响可以被正常的捕获
      if (autoUpload) {
        setTimeout(() => {
          result.upload?.({
            netdisk: netdiskOptions,
          })
        }, 0)
      }

      return result
    },
    [config, netdiskOptions],
  )

  const initialOriginalFilesRef = useRef(initialOriginalFiles)
  const filesRef = useRef([] as FileUploadable<TFileOriginal>[])

  const [files, setFiles] = useState<FileUploadable<TFileOriginal>[]>(() => {
    const formattedFiles = initialOriginalFilesRef.current.map(fileOriginal =>
      fileFormatter(fileOriginal),
    )

    filesRef.current = formattedFiles

    return formattedFiles
  })

  // 提供给外部使用的 setter
  const outerSetFiles = useCallback(
    (inputFiles: (TFileOriginal | FileUploadable<TFileOriginal>)[]) => {
      const newFiles = inputFiles.map(newFile => {
        if (isUploadableFile<TFileOriginal>(newFile)) {
          return newFile
        }

        return fileFormatter(newFile)
      })

      // 寻找被删除的文件，并取消上传
      const keys = newFiles.map(f => f.key)
      const deletedFiles = files.filter(file => !keys.includes(file.key))
      deletedFiles.forEach(file => {
        if (file.cancel) file.cancel()
      })

      setFiles(newFiles)

      filesRef.current = newFiles
    },
    [files, fileFormatter],
  )

  return [files, outerSetFiles] as const
}

/**
 * 初始化文件服务
 */
export const useInitSeiueFile = () => {
  const maxAttachmentSize = useSchoolConstrant('globalMaxAttachmentSize')

  useEffect(() => {
    if (isNumber(maxAttachmentSize)) {
      SeiueFiles.config({
        limitSize: {
          attachment: maxAttachmentSize,
        },
      })
    }
  }, [maxAttachmentSize])
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Item, ItemInterface } from './extends/item'

const buildConfig = createConfigBuilder('vnas')

export const dormApi$revokeDormItem = {
  config(id: number, options?: AxiosRequestExtraConfig): AxiosRequestConfig {
    return buildConfig(options, 'put', '/dorm/items/{id}/revoke', {
      id,
    })
  },

  /**
   * @summary 教务、老师撤回评价项
   * @param id
   * @param [options]
   * @return AxiosResponsePromise<Item>
   */

  api(
    id: number,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Item> {
    return axios(dormApi$revokeDormItem.config(id, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new Item(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      (id: number) => dormApi$revokeDormItem.api(id),
      mutationOptions,
    )
  },
}

// @ts-ignore
dormApi$revokeDormItem.api._name_ = `Dorm.revokeDormItem`

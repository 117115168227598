import { NodeTypeEnum } from './node-type-enum'
import { FlowNodeStatusEnum } from './flow-node-status-enum'
import { FallbackModeEnum } from './fallback-mode-enum'
import { Comment, CommentInterface } from './comment'
import { FlowStage, FlowStageInterface } from './flow-stage'

export interface FlowNodeInterface {
  /**
   * 节点 name
   */
  nodeName: string
  /**
   * 节点 类型
   */
  nodeType: NodeTypeEnum
  /**
   * 节点 label
   */
  nodeLabel: string
  /**
   * 是否是会签（多人审批方式） 会签：true，或签：false
   */
  nodeCounterSign: boolean
  /**
   * 状态
   */
  status: FlowNodeStatusEnum
  /**
   * 是否需要手写签名。默认 false 不需要
   */
  signNeeded?: boolean
  /**
   * 上一个 flow_node 的 id
   */
  fromFlowNodeId?: number | null
  /**
   * 下一个 flow_node 的 id
   */
  toFlowNodeId?: number | null
  /**
   * true：未找到审批人，管理员审批，预览接口使用
   */
  isFallbackAdmin?: boolean
  fallbackMode?: FallbackModeEnum
  /**
   * 角色名称，预览接口使用
   */
  roleName?: string
  /**
   * expand 评论
   */
  comments?: CommentInterface[]
  /**
   * expand 阶段
   */
  stages?: FlowStageInterface[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 审批流 ID
   */
  workflowId: number
  /**
   * 审批单 ID
   */
  flowId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class FlowNode implements FlowNodeInterface {
  /**
   * 节点 name
   */
  nodeName: string
  /**
   * 节点 类型
   */
  nodeType: NodeTypeEnum
  /**
   * 节点 label
   */
  nodeLabel: string
  /**
   * 是否是会签（多人审批方式） 会签：true，或签：false
   */
  nodeCounterSign: boolean
  /**
   * 状态
   */
  status: FlowNodeStatusEnum
  /**
   * 是否需要手写签名。默认 false 不需要
   */
  signNeeded?: boolean
  /**
   * 上一个 flow_node 的 id
   */
  fromFlowNodeId?: number | null
  /**
   * 下一个 flow_node 的 id
   */
  toFlowNodeId?: number | null
  /**
   * true：未找到审批人，管理员审批，预览接口使用
   */
  isFallbackAdmin?: boolean
  fallbackMode?: FallbackModeEnum
  /**
   * 角色名称，预览接口使用
   */
  roleName?: string
  /**
   * expand 评论
   */
  comments?: Comment[]
  /**
   * expand 阶段
   */
  stages?: FlowStage[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 审批流 ID
   */
  workflowId: number
  /**
   * 审批单 ID
   */
  flowId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: FlowNodeInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      nodeName: _nodeName,
      nodeType: _nodeType,
      nodeLabel: _nodeLabel,
      nodeCounterSign: _nodeCounterSign,
      status: _status,
      signNeeded: _signNeeded,
      fromFlowNodeId: _fromFlowNodeId,
      toFlowNodeId: _toFlowNodeId,
      isFallbackAdmin: _isFallbackAdmin,
      fallbackMode: _fallbackMode,
      roleName: _roleName,
      comments: _comments,
      stages: _stages,
      id: _id,
      schoolId: _schoolId,
      workflowId: _workflowId,
      flowId: _flowId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.nodeName = _nodeName
    this.nodeType = _nodeType
    this.nodeLabel = _nodeLabel
    this.nodeCounterSign = _nodeCounterSign
    this.status = _status
    this.signNeeded = _signNeeded
    this.fromFlowNodeId = _fromFlowNodeId
    this.toFlowNodeId = _toFlowNodeId
    this.isFallbackAdmin = _isFallbackAdmin
    this.fallbackMode = _fallbackMode
    this.roleName = _roleName
    this.comments = _comments ? _comments.map(i => new Comment(i)) : _comments
    this.stages = _stages ? _stages.map(i => new FlowStage(i)) : _stages
    this.id = _id
    this.schoolId = _schoolId
    this.workflowId = _workflowId
    this.flowId = _flowId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'nodeName',
    'nodeType',
    'nodeLabel',
    'nodeCounterSign',
    'status',
    'signNeeded',
    'fromFlowNodeId',
    'toFlowNodeId',
    'isFallbackAdmin',
    'fallbackMode',
    'roleName',
    'comments',
    'stages',
    'id',
    'schoolId',
    'workflowId',
    'flowId',
    'createdAt',
    'updatedAt',
  ]
}

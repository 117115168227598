import { ConfigProvider as ConfigProviderDesktop } from '@seiue/ui'
import React from 'react'
import { IntlContext, IntlShape } from 'react-intl'

import { LocaleProvider } from './LocaleProvider'
import { UsefulLocaleEnum } from './types'

/**
 * ANTD Locale Provider
 *
 * @param param0 - 参数
 * @param param0.locale - 文本
 * @param param0.themeColor - 定义
 * @param param0.children - 子组件
 * @returns ANTD Locale Provider
 */
export const ConfigProviderAntd: React.FC<{
  locale: UsefulLocaleEnum
  themeColor?: string
}> = ({ locale, children, themeColor = '#477cff' }) => (
  <ConfigProviderDesktop locale={locale} themeColor={themeColor}>
    {children}
  </ConfigProviderDesktop>
)

/**
 * 仅提供给桌面端 App 使用（Chalk/Apollo/Preview/IM）
 *
 * @param param0 - 参数
 * @param param0.children - 子组件
 * @param param0.themeColor - 定义
 * @param param0.featuresInited - feature 是否初始化完成
 * @returns ANTD Locale Provider
 */
export const LocaleProviderAntd: React.FC<{
  themeColor: string
  featuresInited: boolean
}> = ({ children, themeColor, featuresInited }) => (
  <LocaleProvider featuresInited={featuresInited}>
    <IntlContext.Consumer>
      {(value: IntlShape) => (
        <ConfigProviderAntd
          locale={value.locale as UsefulLocaleEnum}
          themeColor={themeColor}
        >
          {children}
        </ConfigProviderAntd>
      )}
    </IntlContext.Consumer>
  </LocaleProvider>
)

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { EvaluationForm, EvaluationFormInterface } from './evaluation-form'
import type { QueryEvaluationFormsQuery } from './query-evaluation-forms-query'

const buildConfig = createConfigBuilder('vnas')

export const evaluationApi$queryEvaluationForms = {
  config(
    id: number,
    queryEvaluationFormsQuery?: QueryEvaluationFormsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/evaluation/me/evaluations/{id}/forms',
      {
        id,
      },
      queryEvaluationFormsQuery,
    )
  },

  /**
   * @summary 查询评教人的评教表单
   * @param id
   * @param [queryEvaluationFormsQuery]
   * @param [evaluationQuery.expand] group
   * subject
   * answers
   * feedbacks
   * overall_answers
   * @param [evaluationQuery.sort]
   * @param [options]
   * @return AxiosResponsePromise<EvaluationForm[]>
   */

  api<const TQuery extends QueryEvaluationFormsQuery>(
    id: number,
    queryEvaluationFormsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<EvaluationForm, TQuery['expand']>[]> {
    return axios(
      evaluationApi$queryEvaluationForms.config(
        id,
        queryEvaluationFormsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new EvaluationForm(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryEvaluationFormsQuery,
    TSelected = Expand<EvaluationForm, TQuery['expand']>[],
  >(
    {
      id,
      query,
    }: {
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<EvaluationForm, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<EvaluationForm, TQuery['expand']>[], TSelected>(
      evaluationApi$queryEvaluationForms.api,
      'Evaluation.queryEvaluationForms',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
evaluationApi$queryEvaluationForms.api._name_ = `Evaluation.queryEvaluationForms`

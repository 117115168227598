export interface ClassMetadataInterface {
  /**
   * 可多班上课的地点
   */
  multiClassPlaceIds?: number[]
  /**
   * 可多班上课的人【目前未使用】
   */
  multiClassRids?: number[]
  /**
   * 目前会存储的值，作用未知
   */
  status?: string
  /**
   * 目前会存储的值，作用未知
   */
  formId?: number
  /**
   * 目前会存储的值，作用未知
   */
  declarationId?: number
}

export class ClassMetadata implements ClassMetadataInterface {
  /**
   * 可多班上课的地点
   */
  multiClassPlaceIds?: number[]
  /**
   * 可多班上课的人【目前未使用】
   */
  multiClassRids?: number[]
  /**
   * 目前会存储的值，作用未知
   */
  status?: string
  /**
   * 目前会存储的值，作用未知
   */
  formId?: number
  /**
   * 目前会存储的值，作用未知
   */
  declarationId?: number

  constructor(props: ClassMetadataInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      multiClassPlaceIds: _multiClassPlaceIds,
      multiClassRids: _multiClassRids,
      status: _status,
      formId: _formId,
      declarationId: _declarationId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.multiClassPlaceIds = _multiClassPlaceIds
    this.multiClassRids = _multiClassRids
    this.status = _status
    this.formId = _formId
    this.declarationId = _declarationId
  }

  static propKeys = [
    'multiClassPlaceIds',
    'multiClassRids',
    'status',
    'formId',
    'declarationId',
  ]
}

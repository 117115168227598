import { EffectiveRulesTypeEnum } from './effective-rules-type-enum'

export interface EffectiveRulesInterface {
  /**
   * 考勤生效时间类型
   */
  type: EffectiveRulesTypeEnum
  /**
   * 定时发送时间
   */
  cronTime: string
  /**
   * 延迟发送时间（单位：分钟）
   */
  delayTime: number
}

export class EffectiveRules implements EffectiveRulesInterface {
  /**
   * 考勤生效时间类型
   */
  type: EffectiveRulesTypeEnum
  /**
   * 定时发送时间
   */
  cronTime: string
  /**
   * 延迟发送时间（单位：分钟）
   */
  delayTime: number

  constructor(props: EffectiveRulesInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      type: _type,
      cronTime: _cronTime,
      delayTime: _delayTime,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.type = _type
    this.cronTime = _cronTime
    this.delayTime = _delayTime
  }

  static propKeys = ['type', 'cronTime', 'delayTime']
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Questionnaire, QuestionnaireInterface } from './questionnaire'
import type { LoadMeQuestionnaireQuery } from './load-me-questionnaire-query'

const buildConfig = createConfigBuilder('form')

export const questionnaireApi$loadMeQuestionnaire = {
  config(
    id: number,
    loadMeQuestionnaireQuery?: LoadMeQuestionnaireQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/questionnaire/me/questionnaires/{id}',
      {
        id,
      },
      loadMeQuestionnaireQuery,
    )
  },

  /**
   * @summary 用户-获得问卷题目+问卷信息接口(校内用户、校外用户都使用本接口拿题目)
   * @param id
   * @param [loadMeQuestionnaireQuery]
   * @param [questionnaireQuery.expand] - form_template,
   * - form_template.form_template_fields,
   * - form_template.form_template_fields.container,
   * - form_template.form_template_fields.rules
   * - is_filled
   * - tags
   * - periodic_children_quests
   * - periodic_children_quests.is_filled
   * @param [options]
   * @return AxiosResponsePromise<Questionnaire>
   */

  api<const TQuery extends LoadMeQuestionnaireQuery>(
    id: number,
    loadMeQuestionnaireQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Questionnaire, TQuery['expand']>> {
    return axios(
      questionnaireApi$loadMeQuestionnaire.config(
        id,
        loadMeQuestionnaireQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new Questionnaire(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends LoadMeQuestionnaireQuery,
    TSelected = Expand<Questionnaire, TQuery['expand']>,
  >(
    {
      id,
      query,
    }: {
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<Questionnaire, TQuery['expand']>,
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Questionnaire, TQuery['expand']>, TSelected>(
      questionnaireApi$loadMeQuestionnaire.api,
      'Questionnaire.loadMeQuestionnaire',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
questionnaireApi$loadMeQuestionnaire.api._name_ = `Questionnaire.loadMeQuestionnaire`

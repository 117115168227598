import { createEnumHelper } from '@seiue/util'

import { i18nConnect } from 'packages/feature-utils/i18n/utils'
import { $ct, $t } from 'packages/locale'
import { GenderEnum } from 'packages/sdks-next/chalk'

import {
  SecuredReflection as Base,
  SecuredReflectionInterface,
} from '../secured-reflection'

export type { SecuredReflectionInterface }

const genderToLabel = createEnumHelper(() => ({
  [GenderEnum.F]: $t('女'),
  [GenderEnum.M]: $t('男'),
}))

/**
 * 身份
 */
export class SecuredReflection extends Base {
  /**
   * 带年级的行政班列表
   *
   * @returns 行政班列表
   */
  get adminClassesLabels() {
    return getReflectionAdminClassesLabels(this)
  }

  /**
   * 带年级的届别
   *
   * @returns 届别
   */
  get graduatesInAndGradeLabel() {
    return getReflectionGraduatesInAndGradeLabel(this)
  }

  /**
   * 性别 label
   *
   * @returns 性别 label
   */
  get genderLabel() {
    return getReflectionGenderLabel(this)
  }

  /**
   * 获取 gender (性别) 的所有选项
   *
   * @param sourceKey - 原始值在选项 object 中的 key, 默认 value
   * @param mappedKey - 文字标签在选项 object 中的 key, 默认 label
   * @returns 选项数组, 默认 [{ value, label }]
   */
  static getGenderOptions<S extends string, M extends string>(
    sourceKey: S,
    mappedKey: M,
  ) {
    return genderToLabel.listOptions(sourceKey, mappedKey)
  }
}

/**
 * 获取用户带年级的行政班列表
 *
 * @param reflection - 用户信息
 * @returns 行政班列表
 */
export const getReflectionAdminClassesLabels = (
  reflection: Pick<SecuredReflection, 'adminClasses' | 'grade'>,
) => {
  return (
    reflection.adminClasses?.map(adminClass => {
      const gradeName = reflection.grade?.name
        ? $ct(reflection.grade?.name, 'grade')
        : ''

      return i18nConnect(gradeName, adminClass)
    }) ?? []
  )
}

/**
 * 获取用户带年级的届别
 *
 * @param reflection - 用户信息
 * @returns 用户带年级的届别
 */
export const getReflectionGraduatesInAndGradeLabel = (
  reflection: Pick<SecuredReflection, 'graduatesIn' | 'grade'>,
) => {
  return `${reflection.graduatesIn?.name ?? ''}${
    reflection.grade ? `(${reflection.grade.name})` : ''
  }`
}

/**
 * 获取用户性别 label
 *
 * @param reflection - 用户信息
 * @returns 性别 label
 */
export const getReflectionGenderLabel = (
  reflection: Pick<SecuredReflection, 'gender'>,
) => {
  return reflection.gender ? genderToLabel.get(reflection.gender) : undefined
}

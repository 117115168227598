import {
  GroupMemberLimits,
  GroupMemberLimitsInterface,
} from './group-member-limits'

export interface GroupWithoutMembersInterface {
  /**
   * 创建人 id
   */
  creatorId: number
  /**
   * 群组名称
   */
  name: string
  /**
   * 业务 id
   */
  bizId: string
  /**
   * 成员限制
   */
  limits: GroupMemberLimitsInterface[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  /**
   * 学校 ID
   */
  schoolId: number
}

export class GroupWithoutMembers implements GroupWithoutMembersInterface {
  /**
   * 创建人 id
   */
  creatorId: number
  /**
   * 群组名称
   */
  name: string
  /**
   * 业务 id
   */
  bizId: string
  /**
   * 成员限制
   */
  limits: GroupMemberLimits[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: GroupWithoutMembersInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      creatorId: _creatorId,
      name: _name,
      bizId: _bizId,
      limits: _limits,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.creatorId = _creatorId
    this.name = _name
    this.bizId = _bizId
    this.limits = _limits ? _limits.map(i => new GroupMemberLimits(i)) : _limits
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
    this.schoolId = _schoolId
  }

  static propKeys = [
    'creatorId',
    'name',
    'bizId',
    'limits',
    'id',
    'createdAt',
    'updatedAt',
    'schoolId',
  ]
}

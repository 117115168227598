/**
 * @file 成绩相关工具函数
 */

import { createEnumHelper, isNumber } from '@seiue/util'

import { $t } from 'packages/locale/LocaleProvider'
import {
  Assessment,
  AssessmentScopeTypeEnum,
  AssessmentStageStatusEnum,
  AssessmentStatusEnum,
  EvaluateTargetEnum,
  ExpandedAssessment,
  PlanTypeEnum,
  ScoreLockStatusEnum,
  Setting,
  SettingLockStatusEnum,
} from 'packages/sdks-next/vnas'

/**
 * 获取评价状态标签名
 *
 * @param status - 评价状态
 * @returns 评价状态标签名
 */
export const getAssessmentStatusLabel = (
  status: AssessmentStatusEnum | AssessmentStageStatusEnum,
) =>
  ({
    [AssessmentStatusEnum.Pending]: $t('未提交'),
    [AssessmentStatusEnum.Published]: $t('已发布'),
    [AssessmentStatusEnum.Rejected]: $t('已驳回'),
    [AssessmentStatusEnum.Submitted]: $t('待审核'),
    [AssessmentStatusEnum.Passed]: $t('已通过'),
  }[status])

/**
 * 获取评价状态标签颜色
 *
 * @param status - 评价状态
 * @returns 评价状态标签颜色
 */
export const getAssessmentTagStatus = (status: AssessmentStatusEnum) =>
  ({
    [AssessmentStatusEnum.Pending]: 'default' as const,
    [AssessmentStatusEnum.Published]: 'success' as const,
    [AssessmentStatusEnum.Rejected]: 'danger' as const,
    [AssessmentStatusEnum.Submitted]: 'warning' as const,
    [AssessmentStatusEnum.Passed]: 'ongoing' as const,
  }[status])

/**
 * 评价是否可发布
 *
 * @param assessment - 评价信息
 * @returns 评价是否可发布
 */
export const isAssessmentCanPublish = (assessment: Assessment) =>
  [
    AssessmentStatusEnum.Pending,
    AssessmentStatusEnum.Rejected,
    AssessmentStatusEnum.Submitted,
    AssessmentStatusEnum.Passed,
  ].includes(assessment.status)

/**
 * 评价是否可提交
 *
 * @param assessment - 评价信息
 * @returns 评价是否可提交
 */
export const isAssessmentCanPass = (assessment: Assessment) =>
  [
    AssessmentStatusEnum.Submitted,
    AssessmentStatusEnum.Rejected,
    AssessmentStatusEnum.Pending,
    AssessmentStatusEnum.Published,
  ].includes(assessment.status)

/**
 * 评价是否可驳回
 *
 * @param assessment - 评价信息
 * @returns 评价是否可驳回
 */
export const isAssessmentCanReject = (assessment: Assessment) =>
  [
    AssessmentStatusEnum.Published,
    AssessmentStatusEnum.Submitted,
    AssessmentStatusEnum.Passed,
  ].includes(assessment.status)

/**
 * 获取评价状态标签列表
 *
 * @returns 评价状态标签列表
 */
export const getAssessmentStatusOptions = () =>
  [
    AssessmentStatusEnum.Pending,
    AssessmentStatusEnum.Submitted,
    AssessmentStatusEnum.Published,
    AssessmentStatusEnum.Rejected,
    AssessmentStatusEnum.Passed,
  ].map(v => ({
    label: getAssessmentStatusLabel(v),
    value: v,
  }))

/**
 * 获取评价设置锁定标签名
 *
 * @param status - 评价设置锁定状态
 * @returns 评价设置锁定标签名
 */
export const getSettingLockStatusLabel = (status: SettingLockStatusEnum) =>
  ({
    [SettingLockStatusEnum.All]: $t('已锁定'),
    [SettingLockStatusEnum.Some]: $t('部分锁定'),
    [SettingLockStatusEnum.None]: $t('未锁定'),
  }[status])

/**
 * 获取评价设置锁定标签列表
 *
 * @returns 评价设置锁定标签列表
 */
export const getSettingLockStatusOptions = () =>
  [
    SettingLockStatusEnum.None,
    SettingLockStatusEnum.Some,
    SettingLockStatusEnum.All,
  ].map(v => ({
    label: getSettingLockStatusLabel(v),
    value: v,
  }))

/**
 * 获取评价分数锁定标签名
 *
 * @param status - 评价分数锁定状态
 * @returns 评价分数锁定标签名
 */
export const getScoreLockStatusLabel = (status: ScoreLockStatusEnum) =>
  ({
    [ScoreLockStatusEnum.All]: $t('已锁定'),
    [ScoreLockStatusEnum.Some]: $t('部分锁定'),
    [ScoreLockStatusEnum.None]: $t('未锁定'),
  }[status])

/**
 * 获取评价分数锁定标签列表
 *
 * @returns 评价分数锁定标签列表
 */
export const getScoreLockStatusOptions = () =>
  [
    ScoreLockStatusEnum.None,
    ScoreLockStatusEnum.Some,
    ScoreLockStatusEnum.All,
  ].map(v => ({
    label: getScoreLockStatusLabel(v),
    value: v,
  }))

/*
 * 临时模板名称常量
 * 批量新建时，会创建一个临时模板以构建完整的成绩结构树，一般情况下，离开批量新建页面该模板会被删除
 */
export const TEMPORARY_TEMPLATE = 'TEMPORARY_TEMPLATE'

/**
 * 获取是否是普通模板
 *
 * @param assessment - 评价信息
 * @returns 是否是普通模板
 */
export const isNormalTemplate = (assessment: Assessment) =>
  assessment.isTemplate &&
  assessment.scopeType === AssessmentScopeTypeEnum.Class

/**
 * 获取是否是评价行政班
 *
 * @param assessment - 评价信息
 * @returns 是否是评价行政班
 */
export const isAdminClassMoral = (assessment?: Assessment | null) => {
  return assessment?.evaluateTarget === EvaluateTargetEnum.AdminclassGroup
}

export const planTypeToLabel = createEnumHelper(() => ({
  [PlanTypeEnum.Score]: $t('按分数'),
  [PlanTypeEnum.Rank]: $t('按排名'),
  [PlanTypeEnum.Composite]: $t('按排名及分数'),
  [PlanTypeEnum.Level]: $t('按等级'),
}))

/**
 * 获取分数计算精度方法
 *
 * @returns {get, options} 获取分数计算精度方法和 options
 */
export const precisionToLabel = () => {
  const options = [
    { label: $t('整数'), value: 0 },
    { label: $t('一位'), value: 1 },
    { label: $t('两位'), value: 2 },
  ]

  return {
    get: (precision: number) =>
      options.find(option => option.value === precision)?.label,
    options,
  }
}

/**
 * 获取分数计算精度结果
 *
 * @param props - props
 * @param props.gradeSetting - 成绩设置
 * @param props.assessment - 评价信息
 * @param props.isTotalGrade - 是否为总成绩
 * @returns 分数计算精度结果
 */
export const getPrecision = ({
  gradeSetting,
  assessment,
  isTotalGrade,
}: {
  gradeSetting?: Setting | null
  assessment?: Assessment | ExpandedAssessment | null
  // 是否为总成绩
  isTotalGrade?: boolean
}) => {
  if (assessment && assessment.isClassAssessment && isTotalGrade) {
    const { settings: assessmentSetting } = assessment || {}
    return gradeSetting?.customPrecision
      ? isNumber(assessmentSetting?.precision)
        ? assessmentSetting?.precision
        : gradeSetting.precision
      : gradeSetting?.precision
  }

  // 德育因为没有 gradeSetting，直接从 assessment 中取
  if (assessment && assessment.isMoralAssessment) {
    return assessment.settings?.precision || 0
  }

  return gradeSetting?.precision
}

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

import icon from './notice.png'

registerDomainMessage({
  type: MessageDomainEnum.Notification,
  getLabel: () => $t('通知'),
  icon,
  labelStyle: calculateLabelStyle('#C4F0E6', '#4B8678'),
  messages: ['received', 'withdrawn', 'deleted', 'discussion_message_replied'],
})

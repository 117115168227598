import { AssessmentScopeTypeEnum } from '../assessment-scope-type-enum'
import { AssessmentTypeEnum } from '../assessment-type-enum'
import { ExpandedAssessment as ExpandedAssessmentBase } from '../expanded-assessment'

export type { ExpandedAssessmentInterface } from '../expanded-assessment'

/**
 * 评价实例
 */
export class ExpandedAssessment extends ExpandedAssessmentBase {
  /**
   * 获取是否为课程班的过程性评价
   *
   * @returns 是否为课程班的过程性评价
   */
  get isClassAssessment() {
    return (
      this.type === AssessmentTypeEnum.Class &&
      this.scopeType === AssessmentScopeTypeEnum.Class &&
      !this.isTemplate
    )
  }

  /**
   * 获取是否是宿舍类型的评价
   *
   * @returns 是否是宿舍类型的评价
   */
  get isDormAssessment() {
    return (
      this.type === AssessmentTypeEnum.Dorm &&
      this.scopeType === AssessmentScopeTypeEnum.Scope
    )
  }

  /**
   * 获取是否是德育评价
   *
   * @returns 是否是德育评价
   */
  get isMoralAssessment() {
    return this.type === AssessmentTypeEnum.Moral
  }
}

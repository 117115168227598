/**
 * @file 注册 chalk 的通知详情导航
 */

import { PathToDetailEnum } from 'packages/feature-utils/moral-assessments'
import { registerMessageNavigator } from 'packages/features/messages/utils'

registerMessageNavigator(
  [
    'teacher_assessment.raw_score_addition_for_evaluated',
    'teacher_assessment.raw_score_tag_for_evaluated',
    'teacher_assessment.raw_score_special_for_evaluated',
    'teacher_assessment.score_for_evaluated',
  ],
  msg => {
    const { assessmentId, itemId } =
      (msg.attributes as {
        assessmentId: number
        itemId: number
      }) || {}

    return {
      path: `/teacher-assessments/${PathToDetailEnum.AssessMe}/teacher/${assessmentId}/analyses?itemId=${itemId}`,
    }
  },
)

registerMessageNavigator(
  [
    'teacher_assessment.assessment_published_for_evaluated',
    'teacher_assessment.assessment_revoked_for_evaluated',
  ],
  msg => {
    const { assessmentId } =
      (msg.attributes as {
        assessmentId: number
      }) || {}

    return {
      path: `/teacher-assessments/${PathToDetailEnum.AssessMe}/teacher/${assessmentId}/analyses`,
    }
  },
)

registerMessageNavigator(
  [
    'teacher_assessment.assessment_incomplete_for_evaluator',
    'teacher_assessment.assessment_incomplete_for_evaluated',
  ],
  msg => {
    const { assessmentId } =
      (msg.attributes as {
        assessmentId: number
      }) || {}

    return {
      path: `/teacher-assessments/${assessmentId}/entry`,
    }
  },
)

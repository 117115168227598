/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  EnabledSchoolPlugin,
  EnabledSchoolPluginInterface,
} from './enabled-school-plugin'
import type { QueryEnabledSchoolPluginsQuery } from './query-enabled-school-plugins-query'

const buildConfig = createConfigBuilder('chalk')

export const schoolPluginApi$queryEnabledSchoolPlugins = {
  config(
    queryEnabledSchoolPluginsQuery?: QueryEnabledSchoolPluginsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/plugin/enabled/school-plugins',
      {},
      queryEnabledSchoolPluginsQuery,
    )
  },

  /**
   * @summary 查询当前登录用户可查看的并且已开启的学校插件
   * @param [queryEnabledSchoolPluginsQuery]
   * @param [schoolPluginQuery.isVisible]
   * @param [schoolPluginQuery.plugin.idIn]
   * @param [schoolPluginQuery.plugin.id]
   * @param [schoolPluginQuery.plugin.type]
   * @param [schoolPluginQuery.plugin.source]
   * @param [schoolPluginQuery.plugin.kind]
   * @param [schoolPluginQuery.plugin.applicationType]
   * @param [schoolPluginQuery.plugin.usedSchoolNum]
   * @param [schoolPluginQuery.plugin.usedSchoolNumEgt]
   * @param [schoolPluginQuery.plugin.usedSchoolNumElt]
   * @param [schoolPluginQuery.plugin.category]
   * @param [schoolPluginQuery.plugin.categoryIn]
   * @param [schoolPluginQuery.plugin.name]
   * @param [schoolPluginQuery.plugin.domain]
   * @param [schoolPluginQuery.plugin.domainIn]
   * @param [schoolPluginQuery.plugin.label]
   * @param [schoolPluginQuery.plugin.labelLike]
   * @param [schoolPluginQuery.plugin.platformOverlaps]
   * @param [schoolPluginQuery.plugin.tagOverlaps]
   * @param [schoolPluginQuery.plugin.needApproval]
   * @param [schoolPluginQuery.plugin.popular]
   * @param [schoolPluginQuery.plugin.authorLike]
   * @param [schoolPluginQuery.plugin.isHidden]
   * @param [schoolPluginQuery.plugin.isSpecial]
   * @param [schoolPluginQuery.plugin.applicable]
   * @param [schoolPluginQuery.plugin.expand] - school_plugin 使用时同时需要 school_id 参数
   * - school_plugin.applicant
   * @param [schoolPluginQuery.plugin.sort]
   * @param [schoolPluginQuery.plugin.sourceIn]
   * @param [schoolPluginQuery.idIn]
   * @param [schoolPluginQuery.id]
   * @param [schoolPluginQuery.schoolIdIn]
   * @param [schoolPluginQuery.schoolId]
   * @param [schoolPluginQuery.category]
   * @param [schoolPluginQuery.label]
   * @param [schoolPluginQuery.groupId]
   * @param [schoolPluginQuery.groupIdIn]
   * @param [schoolPluginQuery.labelLike]
   * @param [schoolPluginQuery.labelNotIn]
   * @param [schoolPluginQuery.parentId]
   * @param [schoolPluginQuery.status]
   * @param [schoolPluginQuery.enabled]
   * @param [schoolPluginQuery.shortcut]
   * @param [schoolPluginQuery.appliedBy]
   * @param [schoolPluginQuery.appliedIn]
   * @param [schoolPluginQuery.appliedAtEgt]
   * @param [schoolPluginQuery.appliedAtElt]
   * @param [schoolPluginQuery.applicant.account]
   * @param [schoolPluginQuery.applicant.usin]
   * @param [schoolPluginQuery.applicant.idcard]
   * @param [schoolPluginQuery.applicant.outerId]
   * @param [schoolPluginQuery.applicant.idIn]
   * @param [schoolPluginQuery.applicant.nameIn]
   * @param [schoolPluginQuery.applicant.nameLike]
   * @param [schoolPluginQuery.applicant.enameIn]
   * @param [schoolPluginQuery.applicant.enameLike]
   * @param [schoolPluginQuery.applicant.identity] name, usin, ename, pinyin 的模糊搜索
   * @param [schoolPluginQuery.applicant.accountLike]
   * @param [schoolPluginQuery.applicant.accountIn]
   * @param [schoolPluginQuery.applicant.usinLike]
   * @param [schoolPluginQuery.applicant.usinIn]
   * @param [schoolPluginQuery.applicant.genderIn]
   * @param [schoolPluginQuery.applicant.statusIn]
   * @param [schoolPluginQuery.applicant.archivedTypeIdIn]
   * @param [schoolPluginQuery.applicant.phoneIn]
   * @param [schoolPluginQuery.applicant.phoneLike]
   * @param [schoolPluginQuery.applicant.emailIn]
   * @param [schoolPluginQuery.applicant.emailLike]
   * @param [schoolPluginQuery.applicant.idcardIn]
   * @param [schoolPluginQuery.applicant.idcardLike]
   * @param [schoolPluginQuery.applicant.enteredOnElt]
   * @param [schoolPluginQuery.applicant.enteredOnEgt]
   * @param [schoolPluginQuery.applicant.workStartOnElt]
   * @param [schoolPluginQuery.applicant.workStartOnEgt]
   * @param [schoolPluginQuery.applicant.enteredYearsElt]
   * @param [schoolPluginQuery.applicant.enteredYearsEgt]
   * @param [schoolPluginQuery.applicant.workYearsElt]
   * @param [schoolPluginQuery.applicant.workYearsEgt]
   * @param [schoolPluginQuery.applicant.adminClassesContains]
   * @param [schoolPluginQuery.applicant.adminClassesOverlaps]
   * @param [schoolPluginQuery.applicant.adminClassFullnamesOverlaps]
   * @param [schoolPluginQuery.applicant.adminClassesLike]
   * @param [schoolPluginQuery.applicant.dorm]
   * @param [schoolPluginQuery.applicant.dormLike]
   * @param [schoolPluginQuery.applicant.dormIn]
   * @param [schoolPluginQuery.applicant.dormAdminsContains]
   * @param [schoolPluginQuery.applicant.dormAdminsOverlaps]
   * @param [schoolPluginQuery.applicant.dormAdminsLike]
   * @param [schoolPluginQuery.applicant.role]
   * @param [schoolPluginQuery.applicant.roleIn]
   * @param [schoolPluginQuery.parentIdIsEmpty]
   * @param [schoolPluginQuery.expand] - plugin 所属插件
   * - applicant 申请人
   * - admin_reflections 管理人员 reflections
   * @param [schoolPluginQuery.sort]
   * @param [schoolPluginQuery.entrypointsOverlaps] 入口
   * @param [schoolPluginQuery.isApp] 是否是应用，包含 plugin kind 为 application的，和所有的子插件
   * @param [schoolPluginQuery.adminId]
   * @param [options]
   * @return AxiosResponsePromise<EnabledSchoolPlugin[]>
   */

  api<const TQuery extends QueryEnabledSchoolPluginsQuery>(
    queryEnabledSchoolPluginsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<EnabledSchoolPlugin, TQuery['expand']>[]> {
    return axios(
      schoolPluginApi$queryEnabledSchoolPlugins.config(
        queryEnabledSchoolPluginsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new EnabledSchoolPlugin(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryEnabledSchoolPluginsQuery,
    TSelected = Expand<EnabledSchoolPlugin, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<EnabledSchoolPlugin, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<
      Expand<EnabledSchoolPlugin, TQuery['expand']>[],
      TSelected
    >(
      schoolPluginApi$queryEnabledSchoolPlugins.api,
      'SchoolPlugin.queryEnabledSchoolPlugins',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
schoolPluginApi$queryEnabledSchoolPlugins.api._name_ = `SchoolPlugin.queryEnabledSchoolPlugins`

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Wechat, WechatInterface } from './wechat'
import type { ListUserBoundWechatsQuery } from './list-user-bound-wechats-query'

const buildConfig = createConfigBuilder('chalk')

export const wechatApi$listUserBoundWechats = {
  config(
    userId: number,
    listUserBoundWechatsQuery?: ListUserBoundWechatsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/wechat/users/{userId}/wechats',
      {
        userId,
      },
      listUserBoundWechatsQuery,
    )
  },

  /**
   * @summary 获取用户绑定的微信
   * @param userId
   * @param [listUserBoundWechatsQuery]
   * @param [wechatQuery.provider] 可不传，默认取 acm 配置
   * 传 all 获取所有绑定的微信（含企业微信）
   * @param [options]
   * @return AxiosResponsePromise<Wechat[]>
   */

  api(
    userId: number,
    listUserBoundWechatsQuery?: ListUserBoundWechatsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Wechat[]> {
    return axios(
      wechatApi$listUserBoundWechats.config(
        userId,
        listUserBoundWechatsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Wechat(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = Wechat[]>(
    {
      userId,
      query,
    }: {
      userId: number
      query?: ListUserBoundWechatsQuery
    },
    queryOptions?: QueryOptionsWithSelect<Wechat[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Wechat[], TSelected>(
      wechatApi$listUserBoundWechats.api,
      'Wechat.listUserBoundWechats',
      queryOptions,
      userId,
      query,
    )
  },
}

// @ts-ignore
wechatApi$listUserBoundWechats.api._name_ = `Wechat.listUserBoundWechats`

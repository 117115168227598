/**
 * @file 满意度调查-navigator 注册
 */

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator(
  ['questionnaire.admin_assigned', 'questionnaire.admin_removed'],
  () => ({
    path: `/user/questionnaire/manage`,
  }),
)

registerMessageNavigator(
  [
    'questionnaire.form_approved',
    'questionnaire.form_rejected',
    'questionnaire.form_revoke_approved',
    'questionnaire.form_revoke_rejected',
  ],
  message => {
    const attributes = message.attributes as {
      questionnaireId: number
    }

    const questionnaireId = attributes?.questionnaireId

    if (!questionnaireId) return null

    return {
      modalName: 'SubmitQuestionnaireModal',
      options: {
        id: questionnaireId,
      },
    }
  },
)

import { GroupSchedule, GroupScheduleInterface } from './group-schedule'
import { Place, PlaceInterface } from './place'
import { GroupType, GroupTypeInterface } from './group-type'
import { GroupMember, GroupMemberInterface } from './group-member'
import { ChalkDepartment, ChalkDepartmentInterface } from './chalk-department'
import {
  GroupMemberLimits,
  GroupMemberLimitsInterface,
} from './group-member-limits'
import { GroupLabels, GroupLabelsInterface } from './group-labels'
import { GroupStatusEnum } from './group-status-enum'
import { Discussion, DiscussionInterface } from './discussion'
import {
  GroupExtraFields,
  GroupExtraFieldsInterface,
} from './group-extra-fields'

export interface CustomGroupInterface {
  /**
   * 描述
   */
  description?: string | null
  /**
   * 地点
   */
  placeIds?: number[] | null
  /**
   * 容量
   */
  memberLimit?: number | null
  /**
   * 日程
   */
  schedules?: GroupScheduleInterface[] | null
  /**
   * logo
   */
  logo?: string | null
  /**
   * 日程文本显示
   */
  schedulesText?: string
  /**
   * expand
   * places
   */
  places?: PlaceInterface[]
  typeEntity?: GroupTypeInterface
  members?: GroupMemberInterface[]
  /**
   * 部门名称
   */
  departmentNames?: string[]
  /**
   * 部门IDs
   */
  departmentIds?: number[] | null
  /**
   * 部门实体
   */
  departments?: ChalkDepartmentInterface[]
  schoolId: number
  /**
   * 创建人 id
   */
  creatorId: number
  /**
   * 群组名称
   */
  name: string
  /**
   * 业务 id
   */
  bizId: string
  /**
   * 管理员，为成员 members 的子集 expanded
   */
  managers?: GroupMemberInterface[]
  /**
   * 成员限制
   */
  limits: GroupMemberLimitsInterface[]
  /**
   * 群组关联的 Labels
   */
  labels?: GroupLabelsInterface
  /**
   * 群组的状态
   */
  status?: GroupStatusEnum
  /**
   * 所关联的讨论区 expanded
   */
  discussion?: DiscussionInterface | null
  extraFields?: GroupExtraFieldsInterface
  /**
   * e.g.g seiue.class_group, seiue.adminclass_group, seiue.dorm_group .
   * ..
   */
  type?: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class CustomGroup implements CustomGroupInterface {
  /**
   * 描述
   */
  description?: string | null
  /**
   * 地点
   */
  placeIds?: number[] | null
  /**
   * 容量
   */
  memberLimit?: number | null
  /**
   * 日程
   */
  schedules?: GroupSchedule[] | null
  /**
   * logo
   */
  logo?: string | null
  /**
   * 日程文本显示
   */
  schedulesText?: string
  /**
   * expand
   * places
   */
  places?: Place[]
  typeEntity?: GroupType
  members?: GroupMember[]
  /**
   * 部门名称
   */
  departmentNames?: string[]
  /**
   * 部门IDs
   */
  departmentIds?: number[] | null
  /**
   * 部门实体
   */
  departments?: ChalkDepartment[]
  schoolId: number
  /**
   * 创建人 id
   */
  creatorId: number
  /**
   * 群组名称
   */
  name: string
  /**
   * 业务 id
   */
  bizId: string
  /**
   * 管理员，为成员 members 的子集 expanded
   */
  managers?: GroupMember[]
  /**
   * 成员限制
   */
  limits: GroupMemberLimits[]
  /**
   * 群组关联的 Labels
   */
  labels?: GroupLabels
  /**
   * 群组的状态
   */
  status?: GroupStatusEnum
  /**
   * 所关联的讨论区 expanded
   */
  discussion?: Discussion | null
  extraFields?: GroupExtraFields
  /**
   * e.g.g seiue.class_group, seiue.adminclass_group, seiue.dorm_group .
   * ..
   */
  type?: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: CustomGroupInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      description: _description,
      placeIds: _placeIds,
      memberLimit: _memberLimit,
      schedules: _schedules,
      logo: _logo,
      schedulesText: _schedulesText,
      places: _places,
      typeEntity: _typeEntity,
      members: _members,
      departmentNames: _departmentNames,
      departmentIds: _departmentIds,
      departments: _departments,
      schoolId: _schoolId,
      creatorId: _creatorId,
      name: _name,
      bizId: _bizId,
      managers: _managers,
      limits: _limits,
      labels: _labels,
      status: _status,
      discussion: _discussion,
      extraFields: _extraFields,
      type: _type,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.description = _description
    this.placeIds = _placeIds
    this.memberLimit = _memberLimit
    this.schedules = _schedules
      ? _schedules.map(i => new GroupSchedule(i))
      : _schedules
    this.logo = _logo
    this.schedulesText = _schedulesText
    this.places = _places ? _places.map(i => new Place(i)) : _places
    this.typeEntity = _typeEntity ? new GroupType(_typeEntity) : _typeEntity
    this.members = _members ? _members.map(i => new GroupMember(i)) : _members
    this.departmentNames = _departmentNames
    this.departmentIds = _departmentIds
    this.departments = _departments
      ? _departments.map(i => new ChalkDepartment(i))
      : _departments
    this.schoolId = _schoolId
    this.creatorId = _creatorId
    this.name = _name
    this.bizId = _bizId
    this.managers = _managers
      ? _managers.map(i => new GroupMember(i))
      : _managers
    this.limits = _limits ? _limits.map(i => new GroupMemberLimits(i)) : _limits
    this.labels = _labels ? new GroupLabels(_labels) : _labels
    this.status = _status
    this.discussion = _discussion ? new Discussion(_discussion) : _discussion
    this.extraFields = _extraFields
      ? new GroupExtraFields(_extraFields)
      : _extraFields
    this.type = _type
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'description',
    'placeIds',
    'memberLimit',
    'schedules',
    'logo',
    'schedulesText',
    'places',
    'typeEntity',
    'members',
    'departmentNames',
    'departmentIds',
    'departments',
    'schoolId',
    'creatorId',
    'name',
    'bizId',
    'managers',
    'limits',
    'labels',
    'status',
    'discussion',
    'extraFields',
    'type',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

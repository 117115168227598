/**
 * @file
 * 本文件需被插件注册引用，因此独立并不可被包括到出口文件（index.ts）中，以防扩大打包体积
 */
import type { PermissionChecker } from 'packages/expression/evaluator'
import { EnabledPlugin } from 'packages/feature-utils/plugins'
import {
  PermissionNameEnum,
  PluginSourceEnum,
  SchoolPlugin,
  ScopeAssignment,
} from 'packages/sdks-next/chalk'

/**
 * 获取权限检查器
 *
 * @param pluginName - 当前插件
 * @param currentPermissions - 当前权限
 * @param manageablePlugins - 可管理的插件
 * @param isShadow - 是否是超管
 * @returns 权限检查器
 */
export const getPermissionChecker = (
  pluginName: string,
  currentPermissions: ScopeAssignment[],
  manageablePlugins: EnabledPlugin[],
  isShadow?: boolean,
): PermissionChecker => {
  return (permissionName: string): boolean => {
    if (isShadow) return true

    if (
      currentPermissions.some(
        p => p.scopeId === PermissionNameEnum.CorePluginWrite,
      )
    ) {
      return true
    }

    if (
      currentPermissions.some(
        p => p.scopeId === PermissionNameEnum.CorePluginRead,
      ) &&
      permissionName.endsWith('.read')
    ) {
      return true
    }

    if (manageablePlugins.find(p => p.pluginName === pluginName)) {
      return true
    }

    const parts = permissionName.split('.')
    if (
      parts.length === 4 &&
      parts[0] === 'seiue' &&
      parts[1] === 'plugins' &&
      parts[3] === 'admin'
    ) {
      return !!manageablePlugins.find(p => p.pluginName === parts[2])
    }

    return currentPermissions.some(p => p.scopeId === permissionName)
  }
}

/**
 * 判断是否为无代码应用插件
 *
 * @param plugin - 插件
 * @returns boolean
 */
export const isNoCodeAppPlugin = (plugin: EnabledPlugin | SchoolPlugin) => {
  return (
    !plugin.parentId &&
    plugin.plugin?.source &&
    // 按后端要求，source 为开放平台的插件也视为无代码应用
    [PluginSourceEnum.NoCode, PluginSourceEnum.OpenPlatform].includes(
      plugin.plugin.source,
    )
  )
}

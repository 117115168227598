/**
 * @file 注册契约消息
 */
import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Contract,
  getLabel: () => $t('契约'),
  labelStyle: calculateLabelStyle('#E7F9ED', '#3E8B57'),
  messages: ['deny_reminder', 'admin_deny_reminder'],
})

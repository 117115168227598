import { Group, GroupInterface } from './group'
import { ScopedRelation, ScopedRelationInterface } from './scoped-relation'
import { ScopeAssignment, ScopeAssignmentInterface } from './scope-assignment'
import { RoleLabels, RoleLabelsInterface } from './role-labels'

export interface RoleInterface {
  /**
   * expanded
   */
  groups?: GroupInterface[]
  /**
   * expanded
   */
  relations?: ScopedRelationInterface[]
  description: string
  /**
   * expanded
   */
  assignments?: ScopeAssignmentInterface[]
  enhancer?: string
  name: string
  labels?: RoleLabelsInterface
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Role implements RoleInterface {
  /**
   * expanded
   */
  groups?: Group[]
  /**
   * expanded
   */
  relations?: ScopedRelation[]
  description: string
  /**
   * expanded
   */
  assignments?: ScopeAssignment[]
  enhancer?: string
  name: string
  labels?: RoleLabels
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: RoleInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      groups: _groups,
      relations: _relations,
      description: _description,
      assignments: _assignments,
      enhancer: _enhancer,
      name: _name,
      labels: _labels,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.groups = _groups ? _groups.map(i => new Group(i)) : _groups
    this.relations = _relations
      ? _relations.map(i => new ScopedRelation(i))
      : _relations
    this.description = _description
    this.assignments = _assignments
      ? _assignments.map(i => new ScopeAssignment(i))
      : _assignments
    this.enhancer = _enhancer
    this.name = _name
    this.labels = _labels ? new RoleLabels(_labels) : _labels
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'groups',
    'relations',
    'description',
    'assignments',
    'enhancer',
    'name',
    'labels',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

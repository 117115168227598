/**
 * @file register eems messages
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Insider,
  getLabel: () => $t('出入校'),
  labelStyle: calculateLabelStyle('#c4f0e6', '#4b8678'),
  messages: ['student_ee', 'student_ee_for_student'],
})

import Decimal from 'decimal.js'

/**
 * 使用 decimal.js 计算数字和，解决精度问题，返回 Decimal 类型
 *
 * @param numbers - 要计算的数字
 * @returns 计算后的数字
 */
export const sumToDecimal = (numbers: number[]): Decimal => {
  const sum = numbers.reduce((accumulator, currentValue) => {
    const accumulatedDecimal = new Decimal(accumulator)
    const currentDecimal = new Decimal(currentValue)
    return accumulatedDecimal.plus(currentDecimal)
  }, new Decimal('0'))

  return sum
}

/**
 * 题库年级，2 代表初中，3 代表高中
 */
export enum BankGradeGroupIdEnum {
  /**
   * 初中
   */
  MiddleSchool = 2,
  /**
   * 高中
   */
  HighSchool = 3,
}

export type HandoutPluginSettings = {
  bankSettings?: {
    /**
     * 是否引用关联过题库科目
     */
    relatedTerms?: boolean
    /**
     * 开启的题库学部 id
     */
    gradeGroupIds?: BankGradeGroupIdEnum[]
  }
}

export type SubjectData =
  | {
      courseSubjectId: number
      courseSubjectName: string
    }
  | undefined

/* eslint-disable max-lines-per-function */

import {
  AxiosRequestExtraConfig,
  AxiosResponsePromise,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
  Pagination,
} from '@seiue/axios'
import { env, EnvVar, isDevelopment } from '@seiue/env'
import { AlertMetaError } from '@seiue/error-handler'
import {
  has,
  drop,
  isArray,
  isString,
  omit,
  toPairs,
  mapValues,
  isUndefined,
  hasIn,
  map,
  getRandomId,
  stringifyURLQuery,
  isObject,
  mapKeys,
  concat,
} from '@seiue/util'
import humps from 'humps'
import React, { useEffect, useMemo } from 'react'
import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
  isCancelledError,
  QueryObserverBaseResult,
  Query,
} from 'react-query'

import { isInGoWebview, sendDataToRnWebview } from 'packages/utils/rn-webview'

import {
  Api,
  MutationOptions,
  RequiredParams,
  OptionalParmas,
  BodyMeta,
  Path,
  QueryOptionsWithSelect,
} from './types'

const snakeCase = humps.decamelize

/**
 * expand/tryExpand 中的 item 转成字符串，同时驼峰转下划线
 *
 * @param expand - string | readonly string[]
 * @returns 解析后的字符串
 */
export const parseExpand = (expand: Path) =>
  isString(expand) ? snakeCase(expand) : expand.map(p => snakeCase(p)).join('.')

/**
 * 把 expand/tryExpand 参数格式化成字符串
 *
 * @param exp - query 中的 expand 参数，形如 ['aB', ['bC', 'cD']]
 * @returns 格式化成字符串后的结果，上例为 'a_b,b_c.c_d'
 */
export const stringifyExpandPaths = (exp: string | readonly Path[]) =>
  isString(exp) ? exp : exp.filter(Boolean).map(parseExpand).join(',')

/**
 * 组合 expand 和 tryExpand 参数，输出最终用于请求的 expand 字符串
 *
 * @param input - [expand, tryExpand] 的二元组
 * @returns 最终用于请求的 expand 字符串
 */
export const stringifyExpands = (
  input: [
    string | readonly Path[] | undefined,
    string | readonly Path[] | undefined,
  ],
) =>
  input
    .filter((i): i is string | readonly Path[] => Boolean(i))
    .map(stringifyExpandPaths)
    .join(',')

/**
 * createConfigBuilder
 *
 * @private
 *
 * @param server - 服务名称
 * @returns builder functions
 */
export const createConfigBuilder = (server: string) => {
  // get base url of server
  const envKey = `SERVER_${server.toUpperCase().replace(/-/g, '_')}` as EnvVar

  let baseURL = ''
  try {
    baseURL = env(envKey)
  } catch (err) {
    // Mute error for jest & storybook
    if (
      // eslint-disable-next-line no-process-env
      !process.env['STORYBOOK'] &&
      // eslint-disable-next-line no-process-env
      !process.env['JEST']
    ) {
      throw err
    }
  }

  return (
    options: AxiosRequestExtraConfig = {},
    method: Method,
    path: string,
    requiredParams: RequiredParams,
    optionalParams?: OptionalParmas,
    body?: any,
    bodyMeta?: BodyMeta,
  ) => {
    // init base config
    const config: AxiosRequestConfig = {
      baseURL,
      method,
      headers: {
        'Content-Type':
          bodyMeta?.format === 'form'
            ? 'multipart/form-data'
            : 'application/json',
      },
      ...options,
    }

    // build url
    const pathVars: string[] = []
    config.url = path.replace(/{([a-zA-Z-_]+)}/g, (_match, p1) => {
      if (!hasIn(requiredParams, p1)) {
        throw new Error(
          `[sdks-next]: url 中的参数 [${p1}] 应该为 required, 现在它是可选的，请联系后端修改，然后重新运行 yarn sdk-next`,
        )
      }

      pathVars.push(p1)
      return encodeURIComponent(String(requiredParams[p1]))
    })

    // build query
    const { expand, tryExpand, ...restOptionalParams } = optionalParams || {}

    const query: {
      [key: string]: any
      expand?: string
      sort?: string
      _cacheRes?: boolean
    } = omit(requiredParams, ...pathVars)

    const mapOptionalParams = (params: { [key: string]: any }) => {
      mapValues(params, (v, key) => {
        // 将数组 join 为字符串, 以符合后端接口规范
        if (isArray(v)) {
          query[key] = v.join(',')

          return
        }

        // 将对象转化为 xx.xx 的格式，比如 { a: { b: 1}} => a.b: 1
        if (isObject(v)) {
          mapOptionalParams(mapKeys(v, (_v, _k) => `${key}.${_k}`))

          return
        }

        query[key] = v
      })
    }

    mapOptionalParams(restOptionalParams)

    const expandStr = stringifyExpands([expand, tryExpand])

    if (expandStr) query.expand = expandStr

    // 如果只需要 id，不需要其他字段，那么不需要 expand
    if (query['fields'] && query['fields'] === 'id') {
      if (isDevelopment()) {
        console.info(
          `接口请求 '${config.url}' 因为 fields 仅传了 id，所以底层忽略掉了 expand 传递`,
        )
      }

      delete query.expand
    }

    /*
     * FIXME
     * Need Unit Test
     * 也许需要将 query 参数的整合独立成一个方法再覆盖测试
     */
    if (query.sort) {
      // 这样的处理现在仅支持单字段 sort
      query.sort = snakeCase(query.sort)
    }

    // 旧 SDK 机制支持的一个参数，新 SDK 不再需要，在此过滤以免干扰请求
    delete query._cacheRes

    // conditionStatKeys 是 Condition 的一个用于记录统计的参数，不应该出现在请求中
    delete query['conditionStatKeys']

    /**
     * 处理 query 过长的情况
     * 推荐值 2000，设为 1900 留一些余量给 baseUrl
     */
    if (stringifyURLQuery(query).length > 1900) {
      switch (config.method) {
        case 'get':
          config.method = 'POST'
          config.url = `${config.url}/$query`
          break
        case 'delete':
          config.url = `${config.url}/$delete`
          break
        default:
          throw new AlertMetaError({
            title: '抱歉，服务器发生了错误',
            content: '该错误已自动上报，我们将尽快进行处理。',
            report: true,
            extra: {
              msg: `${config.method} ${config.url} 不支持长度超过 2000 的 url`,
            },
          })
      }

      config.data = query
    } else {
      config.params = query
    }

    // process body
    if (bodyMeta?.format === 'json') {
      const bodyAsArray = bodyMeta.isArray ? body : [body]

      const mapped = bodyAsArray.map((m: any) =>
        mapValues(m, (val: any, key: string) => {
          if (!isUndefined(val) && has(bodyMeta.defaults, key)) {
            return bodyMeta.defaults[key](val)
          }

          return val
        }),
      )

      config.data = bodyMeta.isArray ? mapped : mapped[0]
    } else if (bodyMeta?.format === 'form') {
      const formdata = new FormData()
      toPairs(body).forEach(([key, value]: any) => {
        formdata.append(key, value)
      })

      config.data = formdata
    }

    return config
  }
}

const requirePropPath = (entity: any, paths: readonly string[]): boolean => {
  if (!paths.length) return true

  if (isArray(entity)) {
    if (!entity.length) return true
    if (entity.some(e => e[paths[0]] == null)) return false
    return entity.every(e => requirePropPath(e[paths[0]], drop(paths)))
  }

  if (entity[paths[0]] == null) return false
  return requirePropPath(entity[paths[0]], drop(paths))
}

/**
 * 检查实例或实例数组是否包含（多个）指定路径上的数据，
 * 通常用于检查缓存是否可用
 *
 * @private
 * @param entity - 实例
 * @param paths - 路径列表
 * @returns boolean
 */
export const requirePropPaths = (
  entity: any | any[],
  paths: (string | readonly string[])[] = [],
) => {
  // 向后兼容 expand 为 string 的情况
  if (isString(paths)) return true

  return paths.every(path =>
    requirePropPath(entity, isString(path) ? [path] : path),
  )
}

/**
 * 从参数列表中提取 expand 的值
 *
 * @private
 * @param args - 参数列表
 * @returns expand 值
 */
export const extractExpandNonNullables = (
  args: readonly any[],
): (string | readonly string[])[] | undefined =>
  args.find(arg => arg?.expand)?.expand ?? undefined

const WAIT = Symbol('wait')

/**
 * 配合 useQueryApi 使用, 当一个参数用 wait 包裹再传入时,
 * useQueryApi 将等它变为 truthy 再发送请求,
 * 同时返回 NonNullable 后的类型, 以通过 sdk api 类型检查.
 *
 * 比如某个 id 为 0 或 null, 等用户选择后变为合法 id, 再发出请求.
 *
 * 不可只用来解决类型问题, 比如 url query 中取出的 id,
 * 如果业务逻辑上必然已存在, 只是类型为 any, 应该通过 +id 转换类型而非 wait 来解决
 *
 * @param v - 某个请求参数
 * @returns Symbol | value
 */
export const wait = <T>(v: T): NonNullable<T> => (v || WAIT) as NonNullable<T>

/**
 * 递归地深入检查一组参数中是否包含 WAIT symbol
 *
 * @param args - 参数列表
 * @returns 是否包含 WAIT symbol
 */
const isWaiting = (args: any[] = []): boolean => {
  return args.some((arg: any) => {
    if (arg === WAIT) {
      return true
    }

    if (Array.isArray(arg)) {
      return isWaiting(arg)
    }

    if (typeof arg === 'object' && arg !== null) {
      return isWaiting(Object.values(arg))
    }

    return false
  })
}

// 用于标记 query error 已被 Promise.reject 过, 避免重复抛出被全局错误处理机制重复处理
const REJECTED = Symbol('rejected')

export type UseQueryApiReturns<TReturn> = {
  data: TReturn | null
  loading: boolean
  refetching: boolean
  pagination: Pagination
  reload: QueryObserverBaseResult['refetch']
}

/**
 * 将 api 包装成 query hook
 *
 * @param api - api
 * @param apiName - api 名称
 * @param options - 参数选项
 * @param args - 其他参数，会传递给 api
 * @returns 各种结果
 */
export const useQueryApi = <TReturn, TSelected = TReturn>(
  api: any,
  apiName: string,
  options: QueryOptionsWithSelect<TReturn, TSelected> = {},
  ...args: any[]
): UseQueryApiReturns<TSelected> => {
  const {
    disable,
    staleTime = 0,
    keepPreviousData = false,
    getQueryKey,
    catchError,
    select,
    noCache = false,
    ...otherOptions
  } = options

  // 正常请求, 当: 1. 未被显式 disable; 2. 没有正在等待的参数
  const enabled = !disable && !isWaiting(args)

  const {
    data: res,
    isLoading: loading,
    refetch: reload,
    error,
    isFetching,
  } = useQuery(
    (getQueryKey ?? (k => k))([apiName, ...args, otherOptions]),
    async () => {
      try {
        const result = await api(...args, otherOptions)

        return result
      } catch (e) {
        if (catchError) {
          const newError = catchError(e)

          throw newError
        } else {
          throw e
        }
      }
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData,
      enabled,
      select: !select
        ? undefined
        : (prev: AxiosResponse<TReturn>) => {
            return {
              pagination: prev.pagination,
              data: select(prev.data),
            }
          },

      // 指定的时间内只复用缓存, 不重发请求. 传给 react-query 前把秒转为毫秒
      staleTime: staleTime * 1000,
      // 该标志位与 slateTime = 0 的区别是，当 staleTime = 0 时，请求在发送的同时，也会第一时间返回之前获取过的数据（然后再返回请求后的最新数据）
      // 在某些情况下，上面这种行为会导致问题
      cacheTime: noCache ? 0 : 5 * 60 * 1000,
    },
  )

  useEffect(() => {
    /**
     * 不阻塞渲染, 交给全局错误处理机制处理, 并确保每个错误只被 reject 一次
     * (忽略 react-query 内部取消请求时报的错, 常见于 react native)
     */
    if (error && !isCancelledError(error) && !(error as any)[REJECTED]) {
      ;(error as any)[REJECTED] = true
      Promise.reject(error)
    }
  }, [error])

  return {
    data: res?.data ?? null,
    loading,
    pagination: res?.pagination ?? null,
    reload,
    /**
     * 请求为 enable 状态 & 不是 loading 状态
     */
    refetching: enabled && !loading && isFetching,
  }
}

type MergerFunc<T, R> = (main: T, extras: any[]) => R

/**
 * 合并多个接口的数据
 *
 * @param main - 主数据
 * @param loaders  - 关联数据 loader
 * @param merger - 合并函数
 * @returns merged AxiosResponse
 */
export const mergeApis = async <T, R>(
  main: Promise<AxiosResponse<T>>,
  loaders: ((data: T) => Promise<any>)[],
  merger: MergerFunc<T, R>,
): Promise<AxiosResponse<R>> => {
  const mainRes = await main
  const loadedData = await Promise.all(loaders.map(load => load(mainRes.data)))
  return {
    ...mainRes,
    data: merger(mainRes.data, loadedData),
  }
}

export type UseLoadMoreParams<TData = any> = {
  inheritApiId?: Api
  api: ({
    page,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    perPage,
  }: {
    page: number
    perPage: number
  }) => AxiosResponsePromise<TData[]>
  perPage?: number
  appendQueryKeys?: any[]
}

/**
 * 无限滚动加载
 *
 * @param param0 - 参数
 * @param param0.inheritApiId - 继承指定 api 的 id 以把自身 reload 的触发绑定到该 api 上
 * @param param0.api - 接口
 * @param param0.appendQueryKeys - 定义 api 的查询 key，当 key 改变时 api 将会刷新。key 接收任意类型，转换规则见：https://react-query.tanstack.com/guides/query-keys
 * @param param0.perPage - 每页数据条数
 * @param queryOptions - 包含 disable 可选参数
 * @param queryOptions.disable - 暂停接口请求
 * @param queryOptions.select - data transformer
 * @returns 各种结果
 */
export const useLoadMore = <TData, TSelect = TData>(
  {
    inheritApiId,
    api,
    perPage = 20,
    appendQueryKeys = [],
  }: UseLoadMoreParams<TData>,
  queryOptions?: {
    disable?: boolean
    select?: (data: TData[]) => TSelect[]
  },
): {
  data: TSelect[] | null
  loading: boolean
  isLoadingMore: boolean
  hasMore: boolean
  totalCount: number
  loadMore: () => void
  pagination: Pagination | undefined
  // reload 会从第一页开始，perPage 条数据
  reload: () => void
} => {
  const { disable = false, select } = queryOptions || {}

  const apiId = inheritApiId ? getApiId(inheritApiId) : ''
  const randomId = React.useRef(getRandomId()).current

  const {
    error,
    data: res,
    fetchNextPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
    hasNextPage = false,
    status,
    refetch,
    isFetching,
  } = useInfiniteQuery<AxiosResponse<TData[]>>(
    /*
     * 禁用缓存
     * cache key 不重要，需要用 ref 暂存一下，否则会引发 useInfiniteQuery 无限重复请求
     * cache key 相同也没关系，下面的 cacheTime: 0 会让它失去作用
     * FIXME：reload 时 loading 是 false 而不是期望的 true
     */
    apiId
      ? [apiId, randomId, ...appendQueryKeys]
      : [randomId, ...appendQueryKeys],
    query => api({ page: query.pageParam ?? 1, perPage }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !disable,
      // 禁用缓存
      cacheTime: 0,
      getNextPageParam: last => {
        if (last.pagination) {
          return last.pagination.currentPage < last.pagination.pageCount
            ? last.pagination.currentPage + 1
            : undefined
        }

        return undefined
      },
    },
  )

  const data = React.useMemo(() => {
    const result =
      res?.pages?.reduce((prev, curr) => {
        if (curr?.data?.length) {
          prev.push(...curr.data)
        }

        return prev
      }, [] as TData[]) || null

    return select
      ? result
        ? select(result)
        : null
      : // @ts-expect-error select 未传 undefined 时，TSelect = TData
        (result as TSelect[])
  }, [res?.pages, select])

  const pagination = res?.pages?.[res.pages.length - 1]?.pagination
  const totalCount = pagination?.totalCount ?? 0

  useEffect(() => {
    /**
     * 不阻塞渲染, 交给全局错误处理机制处理, 并确保每个错误只被 reject 一次
     * (忽略 react-query 内部取消请求时报的错, 常见于 react native)
     */
    if (error && !isCancelledError(error) && !(error as any)[REJECTED]) {
      ;(error as any)[REJECTED] = true
      Promise.reject(error)
    }
  }, [error])

  return {
    data,
    loading:
      status === 'loading' ||
      isFetchingPreviousPage ||
      isFetchingNextPage ||
      isFetching,
    isLoadingMore: isFetchingNextPage,
    hasMore: hasNextPage,
    totalCount,
    pagination,
    reload: refetch,
    loadMore: fetchNextPage,
  }
}

/**
 * 组合多个 loadMore
 * 一般用于一个无限滚动列表却需要多个接口获取数据的场景
 *
 * @param loadMoreResults - useLoadMore Hooks
 * @returns 各种结果
 */
export const useComposeLoadMore = <TData>(
  ...loadMoreResults: ReturnType<typeof useLoadMore>[]
) =>
  useMemo(() => {
    const dataArr = loadMoreResults.map(({ data }) => data || []) as TData[][]

    return {
      data: concat([], ...dataArr) as TData[],
      dataArr,
      reload: async () => {
        const [firstLoadMore, ...otherLoadMore] = loadMoreResults
        otherLoadMore.forEach(l => l.reload())
        return firstLoadMore.reload()
      },
      loadMore: () => loadMoreResults.find(l => l.hasMore)?.loadMore(),
      hasMore: !!loadMoreResults.find(l => l.hasMore),
      loading: !!loadMoreResults.find(l => l.loading),
    } as const
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...loadMoreResults])

/**
 * 包装修改数据的 api，内部用
 *
 * @private
 * @param api - api
 * @param param1 - 相关设置
 * @param param1.reload - 调用后，重新加载哪些接口
 * @param param1.reloadPredicate - mutation 请求成功后，更精确的判断需要 reload 的请求
 * @returns 包装后的 api
 */
export const useMutationApi = <TApi extends Api>(
  api: TApi,
  { reload, reloadPredicate }: MutationOptions = {},
) => {
  // Get QueryClient from the context
  const queryClient = useQueryClient()

  const {
    mutateAsync: decoratedApi,
    isLoading: loading,
    isSuccess: success,
  } = useMutation(api, {
    onSuccess: () => {
      if (reload) {
        const predicate = (query: Query) =>
          // we stored api names in queryKey[0]
          map(reload, '_name_').includes(query.queryKey[0]) &&
          (reloadPredicate ? reloadPredicate(query) : true)

        // will only invalidate active queries!
        queryClient.invalidateQueries({
          predicate,
        })

        /**
         * 如果我们处于 native 宿主呼出的 webview 页面中 (通常是表单),
         * 需要同时向 native 发送事件使它刷新同样的 query,
         * 两者非 if else 关系因为可能部分 query 在当前页面 reload,
         * 部分在 native 宿主中 reload
         */
        if (isInGoWebview()) {
          sendDataToRnWebview('reloadQuery', { predicate })
        }
      }
    },
  })

  return { api: decoratedApi as TApi, loading, success } as const
}

/**
 * 获取 api 的唯一标识名称
 *
 * @param api - 接口
 * @returns 标识名称
 */
export const getApiId = (api: Api): string =>
  // @ts-expect-error sdk api 内部带有的隐藏字段，不外放
  api['_name_']

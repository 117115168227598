/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { NetdiskOwner, NetdiskOwnerInterface } from './netdisk-owner'
import type { GetPersonalNetdiskOwnersQuery } from './get-personal-netdisk-owners-query'

const buildConfig = createConfigBuilder('chalk')

export const netdiskApi$getPersonalNetdiskOwners = {
  config(
    reflectionId: number,
    getPersonalNetdiskOwnersQuery?: GetPersonalNetdiskOwnersQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/netdisk/personal/{reflectionId}/owners',
      {
        reflectionId,
      },
      getPersonalNetdiskOwnersQuery,
    )
  },

  /**
   * @summary 获取个人的所有 netdiskOwners 包括自己，以及自己的群组
   * @param reflectionId
   * @param [getPersonalNetdiskOwnersQuery]
   * @param [netdiskQuery.type] 直接传 netdiskOwner type, 可以查单独的
   * @param [netdiskQuery.expand]
   * @param [netdiskQuery.onlyManaged] 是否只获取我管理的
   * @param [options]
   * @return AxiosResponsePromise<NetdiskOwner[]>
   */

  api<const TQuery extends GetPersonalNetdiskOwnersQuery>(
    reflectionId: number,
    getPersonalNetdiskOwnersQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<NetdiskOwner, TQuery['expand']>[]> {
    return axios(
      netdiskApi$getPersonalNetdiskOwners.config(
        reflectionId,
        getPersonalNetdiskOwnersQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new NetdiskOwner(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends GetPersonalNetdiskOwnersQuery,
    TSelected = Expand<NetdiskOwner, TQuery['expand']>[],
  >(
    {
      reflectionId,
      query,
    }: {
      reflectionId: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<NetdiskOwner, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<NetdiskOwner, TQuery['expand']>[], TSelected>(
      netdiskApi$getPersonalNetdiskOwners.api,
      'Netdisk.getPersonalNetdiskOwners',
      queryOptions,
      reflectionId,
      query,
    )
  },
}

// @ts-ignore
netdiskApi$getPersonalNetdiskOwners.api._name_ = `Netdisk.getPersonalNetdiskOwners`

import {
  ClassOverlappedLesson,
  ClassOverlappedLessonInterface,
} from './class-overlapped-lesson'
import {
  StudentMultiClass,
  StudentMultiClassInterface,
} from './student-multi-class'

export interface GroupExtraFieldsInterface {
  /**
   * 可管理的字段数组
   */
  managedFields?: string[]
  relatedGroups?: object[]
  /**
   * 课程班名称
   */
  name?: string
  /**
   * 课程班班级名称
   */
  className?: string
  /**
   * 课程班年级
   */
  gradeNames?: string | null
  /**
   * 课程标签（可能为空字符串，表示没有标签）
   */
  tag?: string
  /**
   * 课程标签 id
   */
  tagId?: string
  /**
   * 课程班年级 ids
   */
  gradeIds?: number[]
  /**
   * 科目名称
   */
  subjectName?: string
  /**
   * 课程开始周，空开始未设置上课时间
   */
  startWeek?: number | null
  /**
   * 课程结束周，空同上
   */
  endWeek?: number | null
  /**
   * 课程班多班上课的 rids 。已经废弃，未来移除。使用 class_overlapped_lesson_list + student_multi_class_list 代替。
   */
  multiClassesRids?: number[]
  /**
   * 与当前课程班有课时重叠的班级，且当前课程班有学生在这些班级里，重叠的课节信息
   */
  classOverlappedLessonList?: ClassOverlappedLessonInterface[]
  /**
   * 当前班学生有多班上课的情况，和具体的多班 ids
   */
  studentMultiClassList?: StudentMultiClassInterface[]
}

export class GroupExtraFields implements GroupExtraFieldsInterface {
  /**
   * 可管理的字段数组
   */
  managedFields?: string[]
  relatedGroups?: object[]
  /**
   * 课程班名称
   */
  name?: string
  /**
   * 课程班班级名称
   */
  className?: string
  /**
   * 课程班年级
   */
  gradeNames?: string | null
  /**
   * 课程标签（可能为空字符串，表示没有标签）
   */
  tag?: string
  /**
   * 课程标签 id
   */
  tagId?: string
  /**
   * 课程班年级 ids
   */
  gradeIds?: number[]
  /**
   * 科目名称
   */
  subjectName?: string
  /**
   * 课程开始周，空开始未设置上课时间
   */
  startWeek?: number | null
  /**
   * 课程结束周，空同上
   */
  endWeek?: number | null
  /**
   * 课程班多班上课的 rids 。已经废弃，未来移除。使用 class_overlapped_lesson_list + student_multi_class_list 代替。
   */
  multiClassesRids?: number[]
  /**
   * 与当前课程班有课时重叠的班级，且当前课程班有学生在这些班级里，重叠的课节信息
   */
  classOverlappedLessonList?: ClassOverlappedLesson[]
  /**
   * 当前班学生有多班上课的情况，和具体的多班 ids
   */
  studentMultiClassList?: StudentMultiClass[]

  constructor(props: GroupExtraFieldsInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      managedFields: _managedFields,
      relatedGroups: _relatedGroups,
      name: _name,
      className: _className,
      gradeNames: _gradeNames,
      tag: _tag,
      tagId: _tagId,
      gradeIds: _gradeIds,
      subjectName: _subjectName,
      startWeek: _startWeek,
      endWeek: _endWeek,
      multiClassesRids: _multiClassesRids,
      classOverlappedLessonList: _classOverlappedLessonList,
      studentMultiClassList: _studentMultiClassList,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.managedFields = _managedFields
    this.relatedGroups = _relatedGroups
    this.name = _name
    this.className = _className
    this.gradeNames = _gradeNames
    this.tag = _tag
    this.tagId = _tagId
    this.gradeIds = _gradeIds
    this.subjectName = _subjectName
    this.startWeek = _startWeek
    this.endWeek = _endWeek
    this.multiClassesRids = _multiClassesRids
    this.classOverlappedLessonList = _classOverlappedLessonList
      ? _classOverlappedLessonList.map(i => new ClassOverlappedLesson(i))
      : _classOverlappedLessonList
    this.studentMultiClassList = _studentMultiClassList
      ? _studentMultiClassList.map(i => new StudentMultiClass(i))
      : _studentMultiClassList
  }

  static propKeys = [
    'managedFields',
    'relatedGroups',
    'name',
    'className',
    'gradeNames',
    'tag',
    'tagId',
    'gradeIds',
    'subjectName',
    'startWeek',
    'endWeek',
    'multiClassesRids',
    'classOverlappedLessonList',
    'studentMultiClassList',
  ]
}

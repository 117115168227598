import {
  ComputedField,
  Datasource,
  DatasourceBase,
  QueryResultField,
  ReportTableField,
} from 'packages/sdks-next/nuwa'

export type SourceField = ReportTableField | QueryResultField
export interface Source {
  id: number
  label: string
  name: string
  type: SourceType
  fields: SourceField[]
}

export type SourceInfo = {
  source?: Source
  sourceFields: SourceField[]
  primaryTableFields: SourceField[]
  relationTableFields: ReportTableField[]
  computedFields: ComputedField[]
  allFields: (ComputedField | SourceField)[]
}

/**
 * 数据源类型
 */
export enum SourceType {
  /**
   * 系统表
   */
  Sys = 'sys',
  /**
   * 自定义表
   */
  User = 'user',
  /**
   * 自定义数据源
   */
  Custom = 'custom',
}

/**
 * 是否是高级查询数据源
 *
 * @param datasource - 数据源
 * @returns 是否是高级查询数据源
 */
export const isAdvancedQueryDatasource = (
  datasource: Datasource | DatasourceBase,
): datasource is Datasource => {
  return 'advancedQuery' in datasource ? !!datasource.advancedQuery : false
}

import {
  PluginScopeParam,
  PluginScopeParamInterface,
} from './extends/plugin-scope-param'

export interface SchoolPluginScopesInterface {
  student: PluginScopeParamInterface
  teacher: PluginScopeParamInterface
  guardian: PluginScopeParamInterface
  staff: PluginScopeParamInterface
}

export class SchoolPluginScopes implements SchoolPluginScopesInterface {
  student: PluginScopeParam
  teacher: PluginScopeParam
  guardian: PluginScopeParam
  staff: PluginScopeParam

  constructor(props: SchoolPluginScopesInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      student: _student,
      teacher: _teacher,
      guardian: _guardian,
      staff: _staff,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.student = _student ? new PluginScopeParam(_student) : _student
    this.teacher = _teacher ? new PluginScopeParam(_teacher) : _teacher
    this.guardian = _guardian ? new PluginScopeParam(_guardian) : _guardian
    this.staff = _staff ? new PluginScopeParam(_staff) : _staff
  }

  static propKeys = ['student', 'teacher', 'guardian', 'staff']
}

import { useAtom } from 'jotai'
import React from 'react'

import { ScopeDomain } from 'packages/feature-utils/plugins'
import { GroupPermissionTypeEnum } from 'packages/features/groups'
import { useCurrentReflection } from 'packages/features/sessions'
import {
  EnhancerEnum,
  GroupTypeEnum,
  PermissionNameEnum,
  roleApi$queryManagedScopes,
} from 'packages/sdks-next/chalk'

import { loadGroupPermissionsForReflection } from '../groups/apis'

import { managedStudentScopesAtom } from './atoms'

/**
 * 检查当前登录用户是否有对学生的档案访问权限
 *
 * @returns 判断是否有档案权限的函数
 */
export const useHasStudentProfilePermission = () => {
  // 当前登录用户所拥有的学生管理范围
  const [managedStudentScopes, setManagedStudentScopes] = useAtom(
    managedStudentScopesAtom,
  )

  const currentRid = useCurrentReflection().id

  return React.useCallback(
    /**
     * 是否有对学生的档案访问权限
     *
     * @param rid - 要查看的学生 id
     * @param permission - 档案权限名称
     * @returns 是否
     */
    async (rid: number, permission: GroupPermissionTypeEnum) => {
      let innerScopes = managedStudentScopes

      if (!innerScopes) {
        const { data: scopes } = await roleApi$queryManagedScopes.api(
          currentRid,
          ScopeDomain.Reflection,
          {
            type: EnhancerEnum.StudentScope,
            permission: PermissionNameEnum.CoreUserRead,
          },
        )

        innerScopes = scopes
        setManagedStudentScopes(scopes)
      }

      // 学生是否在管理范围中
      const isStudentInScope = !!innerScopes?.some(scope =>
        scope.resourceIds?.includes(rid),
      )

      if (isStudentInScope) {
        return true
      }

      // 学生不在管理范围，从档案权限中找
      const groupPermissions = await loadGroupPermissionsForReflection({
        rid: currentRid,
        type: GroupTypeEnum.RelationGroup,
        bizId: `${rid}`,
      })

      return groupPermissions.some(perm => perm === permission)
    },
    [currentRid, managedStudentScopes, setManagedStudentScopes],
  )
}

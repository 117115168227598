export interface ScopeAssignmentInterface {
  scopeId: string
  assigneeId: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class ScopeAssignment implements ScopeAssignmentInterface {
  scopeId: string
  assigneeId: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: ScopeAssignmentInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      scopeId: _scopeId,
      assigneeId: _assigneeId,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.scopeId = _scopeId
    this.assigneeId = _assigneeId
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = ['scopeId', 'assigneeId', 'id', 'createdAt', 'updatedAt']
}

import { createEnumHelper } from '@seiue/util'

import { i18nConnect } from 'packages/feature-utils/i18n/utils'
import { $ct, $t } from 'packages/locale/LocaleProvider'

import { AdminClass as AdminClassBase } from '../admin-class'
import { AdminClassStatusEnum } from '../admin-class-status-enum'

export type { AdminClassInterface } from '../admin-class'

export const statusToLabel = createEnumHelper(() => ({
  [AdminClassStatusEnum.Normal]: $t('正常'),
  [AdminClassStatusEnum.Archived]: $t('归档'),
  [AdminClassStatusEnum.Leaved]: $t('退出'),
}))

/**
 * 行政班实例
 */
export class AdminClass extends AdminClassBase {
  /**
   * 获取一个带年级的行政班全名
   *
   * @returns 全名
   */
  get fullName() {
    const gradeName = this.grade?.name ? $ct(this.grade.name, 'grade') : ''
    return i18nConnect(gradeName, this.name)
  }

  /**
   * 获取 status (状态) 对应的文字标签
   *
   * @returns 标签
   */
  get statusLabel() {
    return statusToLabel.get(this.status)
  }

  /**
   * 获取 status (状态) 的所有选项
   *
   * @param sourceKey - 原始值在选项 object 中的 key, 默认 value
   * @param mappedKey - 文字标签在选项 object 中的 key, 默认 label
   * @returns 选项数组, 默认 [{ value, label }]
   */
  static getStatusOptions<S extends string, M extends string>(
    sourceKey: S,
    mappedKey: M,
  ) {
    return statusToLabel.listOptions(sourceKey, mappedKey)
  }
}

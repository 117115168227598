import { Icon, Badge, ZIndex, Ellipsis } from '@seiue/ui'
import { map, useForceUpdate, useOnUpdate } from '@seiue/util'
import React, { FC, useEffect, useRef, useState } from 'react'
import ReactDom from 'react-dom'
import { PopperProps, usePopper } from 'react-popper'
import styled from 'styled-components'

import {
  PersonalConfigKey,
  usePersonalConfig,
} from 'packages/feature-utils/personal-configs'
import {
  useFindAllUnreadImportantMessages,
  useUnreadMessageCount,
} from 'packages/features/messages/hooks'
import { $t } from 'packages/locale'
import { Message } from 'packages/sdks-next/chalk'

import { useReadMessage } from '@/features/messages/apis'
import { useGetMessageNavigate } from '@/features/messages/components/MessageItem'
import { useHistory } from '@/router'

import { useChalkThemeColors } from '../hooks'
import { ControlBarIconWrapper } from '../styles'

interface Props {
  className?: string
}

const ImportantMessageGuideHOC: FC<{
  messages: Message[] | null
  loading: boolean
}> = ({ messages, loading, children }) => {
  const [loadedImportantMsgIdsConfig, setLoadedIds, loadingConfig] =
    usePersonalConfig(PersonalConfigKey.ChalkLoadedImportantMessages)

  const loadedImportantMsgIdsRef = useRef<string[] | null>(null)

  const [forceUpdate] = useForceUpdate()

  useOnUpdate(() => {
    // 保存首次加载出的数据，直到下次重渲染该组件（刷新）
    if (!loadingConfig && !loadedImportantMsgIdsRef.current) {
      loadedImportantMsgIdsRef.current = loadedImportantMsgIdsConfig || []
    }
  }, [loadingConfig])

  const importantMsgsUnloaded = loadingConfig
    ? []
    : messages?.filter(
        msg => !loadedImportantMsgIdsRef.current?.includes(msg.id),
      ) || []

  useEffect(() => {
    if (!loading && messages) {
      setLoadedIds(map(messages, 'id'))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, messages])

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  )

  const getNavigate = useGetMessageNavigate()
  const readMessage = useReadMessage()

  if (importantMsgsUnloaded.length) {
    return (
      <div
        ref={r => {
          setReferenceElement(r)
        }}
      >
        {children}
        <ImportantGuide
          target={referenceElement}
          onClose={() => {
            loadedImportantMsgIdsRef.current = loadedImportantMsgIdsConfig || []
            forceUpdate()
          }}
          zIndex={ZIndex.Modal - 1}
          placement="bottom"
          offset={[-100, 20]}
          closeButtonStyle={{
            top: '12px',
            right: '12px',
          }}
        >
          <ImportantGuideTitle>
            {$t('你有 {count} 条新的重要消息', {
              count: importantMsgsUnloaded.length,
            })}
          </ImportantGuideTitle>
          <ImportantGuideMsgs>
            {importantMsgsUnloaded.map(msg => {
              const navigate = getNavigate(msg)

              return (
                <ImportantGuideMsg key={msg.id}>
                  <ImportantGuideMsgLabel text={msg.title} />
                  <ImportantGuideMsgAction
                    onClick={async () => {
                      if (navigate) {
                        navigate()
                      } else {
                        await readMessage(msg)
                      }

                      loadedImportantMsgIdsRef.current?.push(msg.id)
                    }}
                  >
                    {navigate ? $t('查看') : $t('已读')}
                  </ImportantGuideMsgAction>
                </ImportantGuideMsg>
              )
            })}
          </ImportantGuideMsgs>
        </ImportantGuide>
      </div>
    )
  }

  return <>{children}</>
}

/**
 * 未读消息提醒
 *
 * @param param0 - 参数
 * @param param0.className - 类名
 * @returns 组件
 */
export const MessageBell: React.FC<Props> = ({ className }) => {
  const history = useHistory()
  const { count: unreadCount } = useUnreadMessageCount()

  const { data: importantMsgs, loading: loadingImportantMsgs } =
    useFindAllUnreadImportantMessages(
      {},
      {
        disable: unreadCount === 0,
      },
    )

  const navigate = () => {
    if (unreadCount !== 0) {
      history.push('Messages.Unread')

      return
    }

    history.push('Messages.Readed')
  }

  return (
    <ImportantMessageGuideHOC
      messages={importantMsgs}
      loading={loadingImportantMsgs}
    >
      <Wrapper className={className}>
        <ControlBarIconWrapper onClick={navigate}>
          {importantMsgs?.length ? (
            <>
              <RemindIcon style={{ marginRight: '4px' }} />
              <ImportantText>
                {$t('{count} 重要', { count: importantMsgs?.length })}
              </ImportantText>
            </>
          ) : (
            <Badge dot={unreadCount !== 0} offset={[5, -4]}>
              <RemindIcon />
            </Badge>
          )}
        </ControlBarIconWrapper>
      </Wrapper>
    </ImportantMessageGuideHOC>
  )
}

const Wrapper = styled.div``

const RemindIcon = styled(Icon).attrs(props => ({
  ...props,
  icon: 'Remind',
  size: 14,
  color: 'white',
}))``

const ImportantText = styled.span`
  margin-right: 4px;
  text-wrap: nowrap;
`

const ImportantGuideTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
`

const ImportantGuideMsgs = styled.div``

const ImportantGuideMsg = styled.div`
  margin-top: 10px;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ImportantGuideMsgLabel = styled(Ellipsis)`
  flex: 1;
  min-width: 0;
`

const ImportantGuideMsgAction = styled.div`
  text-decoration: underline;
  cursor: pointer;
`

// 旧的 Guide 组件，应该用 UI Guide 组件替换
const GuideRaw: FC<{
  target: HTMLElement | null
  onClose: () => void
  zIndex?: number
  placement?: PopperProps<any>['placement']
  offset?: [number, number]
  closeButtonStyle?: React.CSSProperties
  className?: string
  style?: React.CSSProperties
}> = ({
  children,
  target,
  onClose,
  zIndex = ZIndex.Tooltip,
  placement,
  offset,
  closeButtonStyle,
  className,
  style,
}) => {
  const { themeColor } = useChalkThemeColors()

  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)

  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null)

  const { styles, attributes } = usePopper(target, popperElement, {
    placement: placement || 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offset || [0, 8],
        },
      },
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'right'],
        },
      },
    ],
  })

  return ReactDom.createPortal(
    <Popover
      className={className}
      $zIndex={zIndex}
      $backgroundColor={themeColor}
      ref={setPopperElement}
      style={{ ...styles['popper'], ...style }}
      {...attributes['popper']}
      onClick={e => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
      }}
    >
      <CloseButton onClick={onClose} style={closeButtonStyle}>
        <Icon size={12} icon="Close" style={{ cursor: 'pointer' }} />
      </CloseButton>
      <Arrow
        ref={setArrowElement}
        $backgroundColor={themeColor}
        style={{
          ...styles['arrow'],
          ...(placement === 'bottom'
            ? {
                top: 0,
                bottom: 'auto',
              }
            : {}),
        }}
        data-popper-arrow={true}
      />
      {typeof children === 'string' ? <Text>{children}</Text> : children}
    </Popover>,
    document.querySelector('body') as HTMLElement,
  )
}

const Popover = styled.div<{ $zIndex: number; $backgroundColor?: string }>`
  z-index: ${props => props.$zIndex};
  max-width: 200px;
  padding: 10px 12px;
  color: ${props => props.theme.text._0};
  background-color: ${props => props.$backgroundColor || props.theme.brand._1};
  border-radius: 6px;
`

const Text = styled.div`
  font-size: 13px;
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
`

const Arrow = styled.div<{ $backgroundColor?: string }>`
  &,
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 24px;
    height: 24px;
    background: ${props => props.$backgroundColor || props.theme.brand._1};
    transform: rotate(45deg);
    content: '';
  }
`

const ImportantGuide = styled(GuideRaw)`
  width: 270px;
  max-width: 270px;
  padding: 16px;
`

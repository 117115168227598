/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { MoralAssessmentTypeEnum } from './moral-assessment-type-enum'
import { Assessment, AssessmentInterface } from './extends/assessment'
import type { QueryAssessmentsForEvaluatorQuery } from './query-assessments-for-evaluator-query'

const buildConfig = createConfigBuilder('vnas')

export const commonApi$queryAssessmentsForEvaluator = {
  config(
    operationType: MoralAssessmentTypeEnum,
    queryAssessmentsForEvaluatorQuery?: QueryAssessmentsForEvaluatorQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/common/evaluator/assessments',
      {
        operationType,
      },
      queryAssessmentsForEvaluatorQuery,
    )
  },

  /**
   * @summary 通用德育评价（德育/教师考核）查询评价列表（评价人：老师）
   * @param operationType
   * @param [queryAssessmentsForEvaluatorQuery]
   * @param [commonQuery.expand] - me_is_evaluator
   * @param [commonQuery.paginated] 是否分页，默认 1
   * @param [commonQuery.page] 显示的数据页，默认 1
   * @param [commonQuery.perPage] 每页显示数量，默认 20
   * @param [commonQuery.idIn]
   * @param [commonQuery.nameLike]
   * @param [commonQuery.nameIn]
   * @param [commonQuery.semesterId]
   * @param [commonQuery.scopeType]
   * @param [commonQuery.scopeIdIn]
   * @param [commonQuery.statusIn]
   * @param [commonQuery.planIdIn]
   * @param [commonQuery.planIdIsEmpty]
   * @param [commonQuery.fullScoreEgt]
   * @param [commonQuery.fullScoreElt]
   * @param [commonQuery.totalItemScoreEgt]
   * @param [commonQuery.totalItemScoreElt]
   * @param [commonQuery.itemNumEgt]
   * @param [commonQuery.itemNumElt]
   * @param [commonQuery.scopeNumEgt]
   * @param [commonQuery.scopeNumElt]
   * @param [commonQuery.scoreLockStatusIn]
   * @param [commonQuery.settingLockStatusIn]
   * @param [commonQuery.individuation]
   * @param [commonQuery.isArchived]
   * @param [commonQuery.evaluators.memberId]
   * @param [commonQuery.evaluators.memberIdIn]
   * @param [commonQuery.evaluators.memberType]
   * @param [commonQuery.evaluators.memberTypeIn]
   * @param [commonQuery.evaluators.assessmentId]
   * @param [commonQuery.evaluators.assessmentIdIn]
   * @param [commonQuery.evaluators.reflection]
   * @param [commonQuery.evaluateTarget] 评价对象，德育评价使用
   * @param [commonQuery.meIsEvaluator] 是否参与评价（适用于家长、老师、学生场景）
   * @param [commonQuery.lockGrade] 是否锁定总成绩修改
   * @param [commonQuery.isTemplate] 是否为模板
   * @param [commonQuery.assessmentStages.status]
   * @param [commonQuery.assessmentStages.statusIn]
   * @param [commonQuery.assessmentStages.stageId]
   * @param [commonQuery.assessmentStages.stageIdIn]
   * @param [commonQuery.computeRuleIsEmpty] 计算规则是否为空
   * @param [options]
   * @return AxiosResponsePromise<Assessment[]>
   */

  api<const TQuery extends QueryAssessmentsForEvaluatorQuery>(
    operationType: MoralAssessmentTypeEnum,
    queryAssessmentsForEvaluatorQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Assessment, TQuery['expand']>[]> {
    return axios(
      commonApi$queryAssessmentsForEvaluator.config(
        operationType,
        queryAssessmentsForEvaluatorQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Assessment(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryAssessmentsForEvaluatorQuery,
    TSelected = Expand<Assessment, TQuery['expand']>[],
  >(
    {
      operationType,
      query,
    }: {
      operationType: MoralAssessmentTypeEnum
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<Assessment, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Assessment, TQuery['expand']>[], TSelected>(
      commonApi$queryAssessmentsForEvaluator.api,
      'Common.queryAssessmentsForEvaluator',
      queryOptions,
      operationType,
      query,
    )
  },
}

// @ts-ignore
commonApi$queryAssessmentsForEvaluator.api._name_ = `Common.queryAssessmentsForEvaluator`

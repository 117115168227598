import { FormTemplateStatusEnum } from './form-template-status-enum'
import {
  FormTemplateField,
  FormTemplateFieldInterface,
} from './form-template-field'

export interface FormTemplateInterface {
  /**
   * 强制评教
   */
  isForced: boolean
  /**
   * 是否允许修改作答
   */
  allowUpdateAnswers: boolean
  /**
   * 是否允许题目反馈
   */
  enableFeedback: boolean
  /**
   * 是否显示排名
   */
  displayRank: boolean
  /**
   * 是否去掉总分最低分
   */
  removeLowestScore: boolean
  bizType: string
  bizId: number
  groupBizType: string
  description: string
  name: string
  startAt: string | null
  endAt: string | null
  /**
   * 是否显示预览
   */
  allowPreview?: boolean
  /**
   * 题目反馈选项
   */
  feedbackOptions?: string[]
  /**
   * 已完成表单人数量
   */
  completedAmount: number
  /**
   * 未完成表单人数量
   */
  uncompletedAmount: number
  /**
   * 已完成表单数量
   */
  completedFormsAmount: number
  /**
   * 未开始 进行中 已结束
   */
  status: FormTemplateStatusEnum
  /**
   * 表单字段（问题）
   */
  formTemplateFields?: FormTemplateFieldInterface[]
  /**
   * 前置说明
   */
  instructions?: string | null
  /**
   * 是否匿名填写，默认否
   */
  anonymous?: boolean
  /**
   * 本次模版是否隐藏题目序号，默认否
   */
  hideFieldNumber?: boolean
  /**
   * 是否开启审核
   */
  enableVerify?: boolean | null
  /**
   * 是否开启填写须知
   */
  enabledFillinNotice?: boolean
  /**
   * 填写须知
   */
  fillinNotice?: string | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class FormTemplate implements FormTemplateInterface {
  /**
   * 强制评教
   */
  isForced: boolean
  /**
   * 是否允许修改作答
   */
  allowUpdateAnswers: boolean
  /**
   * 是否允许题目反馈
   */
  enableFeedback: boolean
  /**
   * 是否显示排名
   */
  displayRank: boolean
  /**
   * 是否去掉总分最低分
   */
  removeLowestScore: boolean
  bizType: string
  bizId: number
  groupBizType: string
  description: string
  name: string
  startAt: string | null
  endAt: string | null
  /**
   * 是否显示预览
   */
  allowPreview?: boolean
  /**
   * 题目反馈选项
   */
  feedbackOptions?: string[]
  /**
   * 已完成表单人数量
   */
  completedAmount: number
  /**
   * 未完成表单人数量
   */
  uncompletedAmount: number
  /**
   * 已完成表单数量
   */
  completedFormsAmount: number
  /**
   * 未开始 进行中 已结束
   */
  status: FormTemplateStatusEnum
  /**
   * 表单字段（问题）
   */
  formTemplateFields?: FormTemplateField[]
  /**
   * 前置说明
   */
  instructions?: string | null
  /**
   * 是否匿名填写，默认否
   */
  anonymous?: boolean
  /**
   * 本次模版是否隐藏题目序号，默认否
   */
  hideFieldNumber?: boolean
  /**
   * 是否开启审核
   */
  enableVerify?: boolean | null
  /**
   * 是否开启填写须知
   */
  enabledFillinNotice?: boolean
  /**
   * 填写须知
   */
  fillinNotice?: string | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: FormTemplateInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      isForced: _isForced,
      allowUpdateAnswers: _allowUpdateAnswers,
      enableFeedback: _enableFeedback,
      displayRank: _displayRank,
      removeLowestScore: _removeLowestScore,
      bizType: _bizType,
      bizId: _bizId,
      groupBizType: _groupBizType,
      description: _description,
      name: _name,
      startAt: _startAt,
      endAt: _endAt,
      allowPreview: _allowPreview,
      feedbackOptions: _feedbackOptions,
      completedAmount: _completedAmount,
      uncompletedAmount: _uncompletedAmount,
      completedFormsAmount: _completedFormsAmount,
      status: _status,
      formTemplateFields: _formTemplateFields,
      instructions: _instructions,
      anonymous: _anonymous,
      hideFieldNumber: _hideFieldNumber,
      enableVerify: _enableVerify,
      enabledFillinNotice: _enabledFillinNotice,
      fillinNotice: _fillinNotice,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.isForced = _isForced
    this.allowUpdateAnswers = _allowUpdateAnswers
    this.enableFeedback = _enableFeedback
    this.displayRank = _displayRank
    this.removeLowestScore = _removeLowestScore
    this.bizType = _bizType
    this.bizId = _bizId
    this.groupBizType = _groupBizType
    this.description = _description
    this.name = _name
    this.startAt = _startAt
    this.endAt = _endAt
    this.allowPreview = _allowPreview
    this.feedbackOptions = _feedbackOptions
    this.completedAmount = _completedAmount
    this.uncompletedAmount = _uncompletedAmount
    this.completedFormsAmount = _completedFormsAmount
    this.status = _status
    this.formTemplateFields = _formTemplateFields
      ? _formTemplateFields.map(i => new FormTemplateField(i))
      : _formTemplateFields
    this.instructions = _instructions
    this.anonymous = _anonymous
    this.hideFieldNumber = _hideFieldNumber
    this.enableVerify = _enableVerify
    this.enabledFillinNotice = _enabledFillinNotice
    this.fillinNotice = _fillinNotice
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'isForced',
    'allowUpdateAnswers',
    'enableFeedback',
    'displayRank',
    'removeLowestScore',
    'bizType',
    'bizId',
    'groupBizType',
    'description',
    'name',
    'startAt',
    'endAt',
    'allowPreview',
    'feedbackOptions',
    'completedAmount',
    'uncompletedAmount',
    'completedFormsAmount',
    'status',
    'formTemplateFields',
    'instructions',
    'anonymous',
    'hideFieldNumber',
    'enableVerify',
    'enabledFillinNotice',
    'fillinNotice',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

import { createEnumHelper, mapValues, times } from '@seiue/util'

import { ClassSchedule } from 'packages/features/classes/calendar-events/types'
import { $t } from 'packages/locale'
import { Group } from 'packages/sdks-next/chalk'
import { ClassWeekInterface, ClassWeekTypeEnum } from 'packages/sdks-next/scms'

const shortWeekTypesToLabel = createEnumHelper(() => ({
  [ClassWeekTypeEnum.Weekly]: $t('每周'),
  [ClassWeekTypeEnum.Odd]: $t('单周'),
  [ClassWeekTypeEnum.Even]: $t('双周'),
  [ClassWeekTypeEnum.Custom]: $t('自定义'),
}))
/* eslint-disable */
export const CourseFieldGroupName = '课程信息'
export const CourseManagementField = '管理信息'
export const ClassFieldGroupName = '班级信息'
/* eslint-enable */

/**
 * 获取上课周的选项列表
 *
 * @returns 选项列表
 */
export const getWeekTypeEnum = () => [
  {
    label: $t('每周上课'),
    value: 'weekly',
  },
  {
    label: $t('单周上课'),
    value: 'odd',
  },
  {
    label: $t('双周上课'),
    value: 'even',
  },
  {
    label: $t('各课节自定义'),
    value: 'custom',
  },
]

/**
 * 格式化为第 x 周
 *
 * @param num - 周次
 * @returns 格式化后的文本
 */
export const formatWeek = (num: number) => $t('第 {num} 周', { num })

/**
 * 转换的 Class classweek 为周次选项
 *
 * @param classWeeks - 上课周
 * @returns 上课周选项
 */
export const convertClassWeekToWeekOptions = (
  classWeeks: ClassWeekInterface[] | undefined | null,
) =>
  classWeeks?.reduce((result, classWeek) => {
    const weekCount = classWeek.endAt - classWeek.startAt + 1

    times(weekCount, i => {
      const week = classWeek.startAt + i

      const isWeekValueExist = result.some(option => option.value === week)
      if (!isWeekValueExist) {
        const newWeekOption = {
          label: formatWeek(week),
          value: week,
        }

        result.push(newWeekOption)
      }
    })

    return result
  }, [] as { label: string; value: number }[])

/**
 * 根据课程班群组，获取该课程班群组内的学生的多班上课情况
 *
 * @param group - 课程班群组
 * @returns 以 lessonDate|lessonName 为 key 的多班上课学生名单
 */
export const getMultiClassMapByClassGroup = (group?: Group | null) => {
  if (!group) return {}

  const { classOverlappedLessonList, studentMultiClassList } =
    group.extraFields || {}

  const lessonClassIdMap = classOverlappedLessonList?.reduce((r, v) => {
    if (!v.lessons) return r

    const nextR = { ...r }

    v.lessons.forEach(lessonId => {
      if (!nextR[lessonId]) {
        nextR[lessonId] = []
      }

      nextR[lessonId].push(v.classId)
    })

    return nextR
  }, {} as { [key: string]: number[] })

  const classRidMap = studentMultiClassList?.reduce((r, v) => {
    if (!v.classIds) return r
    const nextR = { ...r }
    v.classIds.forEach(classId => {
      if (!nextR[classId]) {
        nextR[classId] = []
      }

      nextR[classId].push(v.rid)
    })

    return nextR
  }, {} as { [key: number]: number[] })

  return mapValues(lessonClassIdMap, classIds => {
    if (!classRidMap) return []

    return classIds.reduce((r, v) => {
      if (!classRidMap[v]) return r

      return [...r, ...classRidMap[v]]
    }, [] as number[])
  })
}

/**
 * 格式化上课周 -- 通过课节名称在 classSchedules 字段(日程 custom 里的字段)名称找相同名称
 *
 * @param root0 --课节名称
 * @param root0.lessonName -- 课节名称
 * @param root0.classSchedules -- 课程班课节列表
 * @param root0.day -- 星期几
 * @returns 上课周
 */
export const formatWeekType = ({
  lessonName,
  classSchedules,
  day,
}: {
  lessonName: string
  classSchedules: ClassSchedule[]
  day: number
}) => {
  return classSchedules
    .filter(s => {
      return s.lessonName === lessonName && day === s.day
    })
    .map(s => {
      return `${shortWeekTypesToLabel.get(s.classWeek.weekType)}[${
        s.classWeek.startAt
      }-${s.classWeek.endAt}]`
    })
    .join(' ')
}

export interface PluginScopeParamInterface {
  idIn?: string
  relateStudent?: boolean
}

export class PluginScopeParam implements PluginScopeParamInterface {
  idIn?: string
  relateStudent?: boolean

  constructor(props: PluginScopeParamInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      idIn: _idIn,
      relateStudent: _relateStudent,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.idIn = _idIn
    this.relateStudent = _relateStudent
  }

  static propKeys = ['idIn', 'relateStudent']
}

/**
 * @file 学期相关 api 方法
 *
 * 学期是不易变动的数据，所以我们默认的为查询接口增加了两分钟的缓存时间
 * 也因此限定了 semesterApi 只能从该文件 export 的限制
 * 如果之后增加了新的接口，也在本文件注册，然后在外部引用本文件
 */

import { moment } from '@seiue/moment'
import { useMemo } from 'react'

import {
  useCurrentSemester,
  useSelectedSemester,
  useSemesterById,
  useSemesters,
} from 'packages/feature-utils/semesters'

/**
 * 查找学期
 *
 * @deprecated use useSemesterById from 'packages/feature-utils/semesters' instead
 *
 * @param id - 学期 id
 * @returns 学期
 */
export const useLoadSemester = (id?: number | string) => {
  const semester = useSemesterById(Number(id))
  const currentSemester = useCurrentSemester()

  return {
    data: semester ?? currentSemester,
    loading: false,
  }
}

/**
 * 获取当前选中的学期
 *
 * @deprecated use useSelectedSemester from 'packages/feature-utils/semesters' instead
 * @returns 学期
 */
export const useLoadSelectedSemester = () => ({
  data: useSelectedSemester()[0],
  loading: false,
})

/**
 * 获取当前学期
 *
 * @deprecated use useCurrentSemester from 'packages/feature-utils/semesters' instead
 * @returns 学期
 */
export const useLoadCurrentSemester = () => ({
  data: useCurrentSemester(),
  loading: false,
})

/**
 * 根据一个特定的日期查询对应的学期，如果该日期没有对应的学期，则返回离该日期最近的学期
 *
 * @deprecated use useSemesterByDate from 'packages/feature-utils/semesters' instead
 *
 * @param date - 日期
 * @returns 学期
 */
export const useLoadSemesterByDate = (date = moment().format('YYYY-MM-DD')) => {
  const [data] = useSemesters()

  const semester = useMemo(() => {
    if (!data?.length) return null

    let target = data.find(s =>
      moment(date).isBetween(moment(s.startAt), moment(s.endAt), null, '[]'),
    )

    // 如果学期并未覆盖目标日期
    if (!target) {
      // 如果只有一个，那就返回这一个
      if (data.length === 1) {
        ;[target] = data
      }

      let index = 0

      // 否则，找离该日期最近的学期
      while (!target || index === data.length - 1) {
        const s = data[index]
        if (index === 0 && moment(date).isAfter(moment(s.endAt))) {
          target = s
          break
        }

        if (
          index === data.length - 1 &&
          moment(date).isBefore(moment(s.startAt))
        ) {
          target = s
          break
        }

        if (moment(date).isAfter(moment(s.endAt))) {
          const lastDiff = moment(data[index - 1].startAt).diff(date)
          const nextDiff = moment(date).diff(moment(s.endAt))

          target = lastDiff > nextDiff ? s : data[index - 1]
          break
        }

        index += 1
      }
    }

    return target || null
  }, [data, date])

  return [semester, false] as const
}

import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import {
  RoleEnum,
  adminClassApi$queryPersonalAdminClasses,
} from 'packages/sdks-next/scms'

import { getCurrentReflection } from '../store'

/**
 * 我的行政班列表
 *
 * 若为教师，则返回班主任是「我」的行政班
 * 若为学生，则返回成员是「我」的行政班
 */
export const myAdminClassesAtom = atom(async () => {
  const currentReflection = getCurrentReflection()

  // 只有学生（班级成员）和教师（班主任）加载行政班列表，其他身份均不参与行政班
  if ([RoleEnum.Student, RoleEnum.Teacher].includes(currentReflection.role)) {
    const { data } = await adminClassApi$queryPersonalAdminClasses.api(
      currentReflection.id,
      {
        paginated: 0,
        tryExpand: ['grade'],
        ownerIsTeacher: currentReflection.role === RoleEnum.Teacher,
      },
    )

    return data
  }

  return []
})

export const myAdminClassesLoadableAtom = loadable(
  atom(get => get(myAdminClassesAtom)),
)

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { MoralAssessmentTypeEnum } from './moral-assessment-type-enum'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import type { QueryEvaluatedForAdminQuery } from './query-evaluated-for-admin-query'

const buildConfig = createConfigBuilder('vnas')

export const commonApi$queryEvaluatedForAdmin = {
  config(
    operationType: MoralAssessmentTypeEnum,
    id: number,
    queryEvaluatedForAdminQuery?: QueryEvaluatedForAdminQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/common/admin/assessments/{id}/evaluated',
      {
        operationType,
        id,
      },
      queryEvaluatedForAdminQuery,
    )
  },

  /**
   * @summary 通用德育评价（德育/教师考核）管理员查询单个评价的被评价成员
   * （看到范围内所有人）
   * @param operationType
   * @param id
   * @param [queryEvaluatedForAdminQuery]
   * @param [commonQuery.tagIn]
   * @param [commonQuery.tagIsEmpty]
   * @param [commonQuery.itemIdIn] 加减分标签筛选时需必传此参数
   * @param [commonQuery.teacherAssessmentAsRole] 教师考核 - 当前用户角色，operation_type=teacher_assessment 时需要，不传默认插件管理员
   * @param [commonQuery.groupId] 群组ID
   * @param [commonQuery.groupIdIn]
   * @param [commonQuery.sort] 支持字段 usin, name, account, grade.real_score, grade.score, grade.rank
   * 如果需要评价项分数排序，可传 item_1.score ，其中 1 表示评价项的 id
   * @param [commonQuery.expand] 同 reflection，expand=grade
   * @param [commonQuery.paginated] 是否分页，默认 1
   * @param [commonQuery.page] 显示的数据页，默认 1
   * @param [commonQuery.perPage] 每页显示数量，默认 20
   * @param [commonQuery.account]
   * @param [commonQuery.usin]
   * @param [commonQuery.idcard]
   * @param [commonQuery.outerId]
   * @param [commonQuery.idIn]
   * @param [commonQuery.nameIn]
   * @param [commonQuery.nameLike]
   * @param [commonQuery.enameIn]
   * @param [commonQuery.enameLike]
   * @param [commonQuery.identity] name, usin, ename, pinyin 的模糊搜索
   * @param [commonQuery.accountLike]
   * @param [commonQuery.accountIn]
   * @param [commonQuery.usinLike]
   * @param [commonQuery.usinIn]
   * @param [commonQuery.genderIn]
   * @param [commonQuery.statusIn]
   * @param [commonQuery.archivedTypeIdIn]
   * @param [commonQuery.phoneIn]
   * @param [commonQuery.phoneLike]
   * @param [commonQuery.emailIn]
   * @param [commonQuery.emailLike]
   * @param [commonQuery.idcardIn]
   * @param [commonQuery.idcardLike]
   * @param [commonQuery.enteredOnElt]
   * @param [commonQuery.enteredOnEgt]
   * @param [commonQuery.workStartOnElt]
   * @param [commonQuery.workStartOnEgt]
   * @param [commonQuery.enteredYearsElt]
   * @param [commonQuery.enteredYearsEgt]
   * @param [commonQuery.workYearsElt]
   * @param [commonQuery.workYearsEgt]
   * @param [commonQuery.adminClassesContains]
   * @param [commonQuery.adminClassesOverlaps]
   * @param [commonQuery.adminClassesLike]
   * @param [commonQuery.dorm]
   * @param [commonQuery.dormLike]
   * @param [commonQuery.dormIn]
   * @param [commonQuery.dormAdminsContains]
   * @param [commonQuery.dormAdminsOverlaps]
   * @param [commonQuery.dormAdminsLike]
   * @param [commonQuery.role]
   * @param [commonQuery.roleIn]
   * @param [options]
   * @return AxiosResponsePromise<SecuredReflection[]>
   */

  api<const TQuery extends QueryEvaluatedForAdminQuery>(
    operationType: MoralAssessmentTypeEnum,
    id: number,
    queryEvaluatedForAdminQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<SecuredReflection, TQuery['expand']>[]> {
    return axios(
      commonApi$queryEvaluatedForAdmin.config(
        operationType,
        id,
        queryEvaluatedForAdminQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new SecuredReflection(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryEvaluatedForAdminQuery,
    TSelected = Expand<SecuredReflection, TQuery['expand']>[],
  >(
    {
      operationType,
      id,
      query,
    }: {
      operationType: MoralAssessmentTypeEnum
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<SecuredReflection, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<
      Expand<SecuredReflection, TQuery['expand']>[],
      TSelected
    >(
      commonApi$queryEvaluatedForAdmin.api,
      'Common.queryEvaluatedForAdmin',
      queryOptions,
      operationType,
      id,
      query,
    )
  },
}

// @ts-ignore
commonApi$queryEvaluatedForAdmin.api._name_ = `Common.queryEvaluatedForAdmin`

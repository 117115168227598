/**
 * @file 公开分享页-通用 helper
 *
 */
/**
 * 当前 url 是否是对外共享的页面
 * @returns 是否
 */
export const isPublicShareURL = () =>
  window?.location?.pathname?.startsWith('/share/')

/**
 * @file 成绩录入 - 视图 utils
 */
import { toNumberScore } from 'packages/features/grades/utils/scores'
import {
  Grade,
  GradeStatusEnum,
  Score,
  ScoreReq,
} from 'packages/sdks-next/vnas'

/**
 * 格式化总成绩得分率
 *
 * @param grade - 成绩
 * @returns 总成绩得分率
 */
export const formatGradeRealScoreRate = (grade?: Grade) =>
  grade?.realScoreRate
    ? `${(
        toNumberScore({ value: grade.realScoreRate, defaultValue: 0 }) * 100
      ).toFixed(0)}%`
    : '-'

/**
 * 格式化总成绩系统算分
 *
 * @param grade - 成绩
 * @returns 总成绩系统算分
 */
export const formatGradeSuggestScore = (grade?: Grade) =>
  grade?.suggestScore || '-'

/**
 * 格式化总成绩排名
 *
 * @param grade - 成绩
 * @returns 总成绩排名
 */
export const formatGradeRank = (grade?: Grade) =>
  (grade?.status !== GradeStatusEnum.Delayed &&
    !grade?.specialLevel &&
    grade?.rank) ||
  '-'

/**
 * 格式化总成绩课程排名
 *
 * @param grade - 成绩
 * @returns 总成绩课程排名
 */
export const formatGradeCourseRank = (grade?: Grade) =>
  (grade?.status !== GradeStatusEnum.Delayed &&
    !grade?.specialLevel &&
    grade?.courseRank) ||
  '-'

/**
 * 格式化分数排名
 *
 * @param score - 分数
 * @returns 分数排名
 */
export const formatScoreRank = (score?: Score | ScoreReq) => {
  if (score && 'ownerStatus' in score && score.isOwnerLeaved) {
    return '-'
  }

  if (score && 'rank' in score) {
    return !score.valid ? '-' : score.rank
  }

  return '-'
}

/**
 * 判断总成绩或评价项分数是否暂不评价
 *
 * @param score - 分数
 * @returns 是否暂不评价
 */
export const isDelayed = (score: ScoreReq | Grade = {}) => {
  if ('valid' in score) {
    return !score.valid
  }

  if ('status' in score) {
    return score.status === GradeStatusEnum.Delayed
  }

  return false
}

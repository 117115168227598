/**
 * @file 校历 Chalk Web 消息导航注册
 */
import { registerMessageNavigator } from 'packages/features/messages/utils'

registerMessageNavigator('schcal', ({ attributes }) => {
  const startAt = (attributes as any)?.startAt || ''
  const activeDay = startAt.split(' ')[0]

  return {
    path: `/school-calendar${
      activeDay
        ? `?activeDay=${activeDay}&semesterId=${(attributes as any).semesterId}`
        : ''
    }`,
  }
})

import { isArray } from '@seiue/util'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ScopeDomain } from 'packages/feature-utils/plugins'
import { useCanWriteUser } from 'packages/feature-utils/schools'
import { SessionState } from 'packages/features/sessions/utils/types'
import {
  EnhancerEnum,
  Labels,
  PermissionNameEnum,
  Role,
  RoleEnum,
  Scope,
  ScopeEnhancerEnum,
  roleApi$queryManagedScopes,
} from 'packages/sdks-next/chalk'

import { useCurrentReflection } from '../sessions/utils/data'

import { PermissionQuery, RoleQuery } from './types'
import {
  filterRoles,
  getDomainByCorePermission,
  getRolesByIsManager,
  hasFullScope,
  hasPermissionInScopeAssignments,
} from './utils'

/**
 * 检查指定 roles 是否包含指定的权限
 *
 * @param query - 查询 query
 * @param roles - 在这些 roles 中查找
 * @returns 是否
 */
export const useHasPermission = (query: PermissionQuery, roles: Role[]) =>
  useMemo(() => !!filterRoles(roles, query).length, [query, roles])

/**
 * 生成一个 function，用于检查当前用户是否有指定的权限
 * 同时从 currentPermissions 和 currentRoles 中查找
 *
 * @returns 判断函数
 */
export const useHasCurrentPermissionFunction = () => {
  const currManagerRoles = useSelector(
    ({ session }: { session: SessionState }) => session.currentManagerRoles,
  )

  const currManagedRoles = useSelector(
    ({ session }: { session: SessionState }) => session.currentManagedRoles,
  )

  const currPermissions = useSelector(
    ({ session }: { session: SessionState }) => session.currentPermissions,
  )

  const canWriteUser = useCanWriteUser()

  return useCallback(
    (query: PermissionQuery) => {
      if (
        !canWriteUser &&
        query.permission === PermissionNameEnum.CoreUserWrite
      )
        return false

      const inCurrPermissions =
        query.isManager !== false &&
        hasPermissionInScopeAssignments(currPermissions, query.permission)

      const currRoles = getRolesByIsManager(
        currManagerRoles,
        currManagedRoles,
        query.isManager,
      )

      const inCurrRoles = !!filterRoles(currRoles, query).length
      return inCurrPermissions || inCurrRoles
    },
    [currManagerRoles, currManagedRoles, currPermissions, canWriteUser],
  )
}

/**
 * 生成一个 function，用于检查当前用户是否有指定的角色
 *
 * @returns 检查函数
 */
export const useHasRoleFunction = () => {
  const { role } = useCurrentReflection()

  return useCallback((roles: RoleEnum[]) => roles.includes(role), [role])
}

/**
 * 检查当前用户是否有指定的权限
 * 从 currentPermissions 和 currentRoles 中查找
 *
 * @param query - 查询参数
 * @returns 是否
 */
export const useHasCurrentPermission = (query: PermissionQuery) => {
  const hasCurrentPermission = useHasCurrentPermissionFunction()
  return useMemo(
    () => hasCurrentPermission(query),
    [query, hasCurrentPermission],
  )
}

/**
 * 检查当前用户是否有多个指定的权限
 * 从 currentPermissions 和 currentRoles 中查找
 *
 * @param permissions - 要查找的权限
 * @returns 是否
 */
export const useHasSomeCurrentPermissions = (
  permissions: PermissionNameEnum[],
) => {
  const hasCurrentPermission = useHasCurrentPermissionFunction()
  return useMemo(
    () => permissions.map(permission => hasCurrentPermission({ permission })),
    [permissions, hasCurrentPermission],
  )
}

/**
 * 获取当前用户是否拥有任意权限
 *
 * @param permissions - 权限
 * @returns 是否
 */
export const useHasAnyCurrentPermissions = (
  permissions: PermissionNameEnum[],
) => useHasSomeCurrentPermissions(permissions).some(item => item)

/**
 * 检查当前用户是不是超级管理员
 *
 * @returns 是否
 */
export const useIsCurrentSuperAdmin = () =>
  useHasCurrentPermission({
    permission: 'seiue.school.super_admin' as any,
    isManager: true,
  })

/**
 * 获取是否是管理者来筛选当前用户的 roles
 *
 * @param isManager - 是否是管理者
 * @returns 是否
 */
export const useCurrentRolesByIsManager = (isManager?: boolean) => {
  const currManagerRoles = useSelector(
    ({ session }: { session: SessionState }) => session.currentManagerRoles,
  )

  const currManagedRoles = useSelector(
    ({ session }: { session: SessionState }) => session.currentManagedRoles,
  )

  return useMemo(
    () => getRolesByIsManager(currManagerRoles, currManagedRoles, isManager),
    [currManagerRoles, currManagedRoles, isManager],
  )
}

/**
 * 根据条件筛选当前用户的 roles
 *
 * @param query - 查询参数
 * @returns 是否
 */
export const useCurrentRoles = (query?: RoleQuery) => {
  const currRoles = useCurrentRolesByIsManager(query?.isManager)

  return useMemo(
    () => (query ? filterRoles(currRoles, query) : currRoles),
    [currRoles, query],
  )
}

/**
 * 获取当前的 roles
 *
 * @param session - 用户登录信息
 * @param query - 查询参数
 * @returns roles
 */
export const getCurrentRoles = (session: SessionState, query?: RoleQuery) => {
  const roles = getRolesByIsManager(
    session.currentManagerRoles,
    session.currentManagedRoles,
    query?.isManager,
  )

  return query ? filterRoles(roles, query) : roles
}

// 用于检测相同的 fullScopeQuery 是否正在请求
const fullScopeQueryStatus: { [queryString: string]: boolean } = {}

/**
 * 判断当前用户是否在某个资源的所有内容上有指定权限
 *
 * @param param0 - 参数
 * @param param0.permission - 权限
 * @param param0.domain -  领域，如果你不传，那么自动去查找权限所对应的领域（现在只支持 Core 权限）
 * @param param0.scope - 资源所对应的 ScopeEnhancer
 * @param param0.disable - 暂不查询
 * @returns 结果与 loading 状态
 */
export const useHasCurrentPermissionOnFullScope = ({
  permission,
  scope,
  domain,
  disable,
}: {
  permission?: PermissionNameEnum
  scope?: ScopeEnhancerEnum
  domain?: ScopeDomain
  disable?: boolean
}) => {
  const dispatch = useDispatch() as any
  const { id: currentReflectionId } = useCurrentReflection()

  const currentScopes = useSelector(
    ({ session }: { session: SessionState }) => session.currentScopes,
  )

  const filteredScopes = useMemo(
    () =>
      currentScopes.find(
        _scope => _scope.enhancer === scope && _scope.permission === permission,
      ),
    [permission, scope, currentScopes],
  )

  const canWriteUser = useCanWriteUser()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (disable || !permission) {
      setLoading(false)
      return
    }

    if (!canWriteUser && permission === PermissionNameEnum.CoreUserWrite) {
      setLoading(false)

      return
    }

    const _key = JSON.stringify({
      domain,
      scope,
      permission,
    })

    // 如果已经该内容已经查询过一次，或者正在查询了，便不再重复查询，以节省请求
    if (!filteredScopes && !fullScopeQueryStatus[_key]) {
      setLoading(true)

      fullScopeQueryStatus[_key] = true

      roleApi$queryManagedScopes
        .api(
          currentReflectionId,
          domain || getDomainByCorePermission(permission) || '',
          {
            enhancer: scope,
            permission,
          },
        )
        .then(({ data }) => {
          dispatch.session.setCurrentScopes({
            permission,
            enhancer: scope,
            scopes: data,
          })
        })
        .finally(() => {
          fullScopeQueryStatus[_key] = false
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [
    filteredScopes,
    currentReflectionId,
    scope,
    permission,
    dispatch.session,
    canWriteUser,
    domain,
    disable,
  ])

  return [
    useMemo(() => {
      if (!filteredScopes) return false
      return hasFullScope(filteredScopes.scopes, filteredScopes.enhancer)
    }, [filteredScopes]),
    loading,
  ] as const
}

interface QueryManagedScopesQuery {
  domain?: ScopeDomain
  permission?: PermissionNameEnum
  scopeName?: ScopeEnhancerEnum
  staleTime?: number
}

/**
 * 获取当前用户在某个资源的所有内容上有指定权限
 *
 * @param param0 - 查询参数
 * @param param0.domain - 业务领域
 * @param param0.permission - 权限名称
 * @param param0.scopeName - 资源对应的 EnhancerName，比如课程班就对应 class_scope
 * @param param0.staleTime - 缓存时间，默认为 0
 * @param options - 选项
 * @param options.disable - 是否禁用
 * @returns 结果与 loading 状态
 */
export const useQueryManagedScopes = (
  { domain, permission, scopeName, staleTime }: QueryManagedScopesQuery,
  options?: {
    disable?: boolean
  },
) => {
  const { id: currentReflectionId } = useCurrentReflection()

  const isSuperAdmin = useIsCurrentSuperAdmin()

  const { data: scopes, loading } = roleApi$queryManagedScopes.useApi(
    {
      id: currentReflectionId,
      domain: domain as ScopeDomain,
      query: {
        permission,
        enhancer: scopeName,
      },
    },
    {
      disable:
        options?.disable ||
        !domain ||
        !scopeName ||
        !permission ||
        isSuperAdmin,
      staleTime,
    },
  )

  const [resourceIds, isManagedAll] = useMemo(() => {
    if (!scopes || !scopeName) return [[] as number[], false] as const

    return [
      scopes.reduce((result, scope) => {
        let nextResult = [...result]

        if (
          (scope.labels?.type as unknown as ScopeEnhancerEnum) === scopeName &&
          scope.resourceIds
        ) {
          nextResult = [...nextResult, ...scope.resourceIds]
        }

        return nextResult
      }, [] as number[]),
      hasFullScope(scopes, scopeName),
    ] as const
  }, [scopes, scopeName])

  return {
    resourceIds,
    isManagedAll: isManagedAll || isSuperAdmin,
    loading,
  }
}

/**
 * 判断当前用户是否有某个资源的某个权限
 *
 * @param param0 - 查询参数
 * @param param0.domain - 业务领域
 * @param param0.permission - 权限名称
 * @param param0.scopeName - 资源对应的 EnhancerName，比如课程班就对应 class_scope
 * @param param0.staleTime - 缓存时间，默认为 0
 * @param resourceId - 资源 ID
 * @param shouldAllManaged - 是否应该管理所有 resourceId 或者部分即可
 * @returns 有某个资源的某个权限,loading
 */
export const useIsManagedResource = (
  { domain, permission, scopeName, staleTime }: QueryManagedScopesQuery,
  resourceId?: number | number[],
  shouldAllManaged = false,
) => {
  const isSuperAdmin = useIsCurrentSuperAdmin()

  const [hasManaged, setHasManaged] = useState(false)

  const { resourceIds, loading, isManagedAll } = useQueryManagedScopes(
    {
      domain,
      permission,
      scopeName,
      staleTime,
    },
    {
      disable: !resourceId,
    },
  )

  useEffect(() => {
    if (resourceId === undefined) return

    if (isSuperAdmin || isManagedAll) setHasManaged(true)
    else {
      const ids = isArray(resourceId) ? resourceId : [resourceId]
      if (shouldAllManaged) {
        setHasManaged(!ids.find(id => !resourceIds.includes(id)))
      } else {
        setHasManaged(!!ids.find(id => resourceIds.includes(id)))
      }
    }
  }, [resourceIds, isManagedAll, resourceId, shouldAllManaged, isSuperAdmin])

  return [hasManaged, loading] as const
}

/**
 * 获取当前用户是否在某个资源的所有内容上有指定权限
 *
 * @param param0 - 查询参数
 * @param param0.domain - 业务领域
 * @param param0.permission - 权限名称
 * @param param0.scopeName - 资源对应的 EnhancerName，比如课程班就对应 class_scope
 * @param param0.staleTime - 缓存时间，默认为 0
 *
 * @returns 有某个资源的某个权限,loading
 */
export const useIsManagedResourceFn = ({
  domain,
  permission,
  scopeName,
  staleTime,
}: QueryManagedScopesQuery) => {
  const { resourceIds, loading, isManagedAll } = useQueryManagedScopes({
    domain,
    permission,
    scopeName,
    staleTime,
  })

  const isSuperAdmin = useIsCurrentSuperAdmin()

  return [
    useCallback(
      (resourceId?: number | number[], shouldAllManaged = false) => {
        if (!resourceId) return false

        if (isSuperAdmin || isManagedAll) return true

        const ids = isArray(resourceId) ? resourceId : [resourceId]
        if (shouldAllManaged) {
          return !ids.find(id => !resourceIds.includes(id))
        }

        return !!ids.find(id => resourceIds.includes(id))
      },
      [resourceIds, isSuperAdmin, isManagedAll],
    ),
    loading,
  ] as const
}

/**
 * 获取角色下的资源范围
 *
 * @param param0 - 参数
 * @param param0.roleId - 角色 id
 * @param param0.domain -  业务领域
 * @returns 资源范围
 */
export const useRoleManagedScopes = ({
  roleId,
  domain,
}: {
  roleId: number
  domain: ScopeDomain
}) => {
  const currentReflection = useCurrentReflection()
  return roleApi$queryManagedScopes.useApi(
    {
      id: currentReflection.id,
      domain,
      query: {
        roleIdIn: `${roleId}`,
      },
    },
    { disable: !roleId },
  )
}

interface StudentLabels extends Labels {
  type: EnhancerEnum.StudentScope
}

interface GuardianLabels extends Labels {
  type: EnhancerEnum.GuardianScope
}

export interface StudentScope extends Scope {
  labels: StudentLabels
}

export interface GuardianScope extends Scope {
  labels: GuardianLabels
}

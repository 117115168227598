/**
 * @file 日程消息注册
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

import { ScheduleMessageEnum } from './message-type'
import icon from './schedule.png'

registerDomainMessage({
  type: MessageDomainEnum.Schedule,
  getLabel: () => $t('日程'),
  labelStyle: calculateLabelStyle('#E1FBFA', '#1D8E89'),
  icon,
  messages: [
    ScheduleMessageEnum.Reminded,
    ScheduleMessageEnum.ClassInAccountUnbind,
    ScheduleMessageEnum.ScheduleCreatedToPartner,
    ScheduleMessageEnum.ScheduleUpdatedToPartner,
    ScheduleMessageEnum.ScheduleMemberUpdatedToOwner,
    ScheduleMessageEnum.ScheduleDeletedToPartner,
  ],
})

import { Attachment, AttachmentInterface } from './attachment'

export interface AccItemGaugeInterface {
  /**
   * 分数类型时必填
   */
  name?: string
  /**
   * 分数类型时必填
   */
  score?: string
  description?: string
  /**
   * 是否低于分数。默认为 false ，表示高于等于。
   */
  isBelow?: boolean
  attachments?: AttachmentInterface[]
}

export class AccItemGauge implements AccItemGaugeInterface {
  /**
   * 分数类型时必填
   */
  name?: string
  /**
   * 分数类型时必填
   */
  score?: string
  description?: string
  /**
   * 是否低于分数。默认为 false ，表示高于等于。
   */
  isBelow?: boolean
  attachments?: Attachment[]

  constructor(props: AccItemGaugeInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      score: _score,
      description: _description,
      isBelow: _isBelow,
      attachments: _attachments,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.score = _score
    this.description = _description
    this.isBelow = _isBelow
    this.attachments = _attachments
      ? _attachments.map(i => new Attachment(i))
      : _attachments
  }

  static propKeys = ['name', 'score', 'description', 'isBelow', 'attachments']
}

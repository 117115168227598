/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import type { LoadPersonalClassinAccountQuery } from './load-personal-classin-account-query'

const buildConfig = createConfigBuilder('chalk')

export const classinApi$loadPersonalClassinAccount = {
  config(
    loadPersonalClassinAccountQuery?: LoadPersonalClassinAccountQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/classin/personal/account',
      {},
      loadPersonalClassinAccountQuery,
    )
  },

  /**
   * @summary 获取 classin 绑定账户
   * 如果是查询他人，则只能得到是否存在（204/404）的结果，不能获取具体信息
   * @param [loadPersonalClassinAccountQuery]
   * @param [classinQuery.reflectionId]
   * @param [options]
   * @return AxiosResponsePromise<null>
   */

  api(
    loadPersonalClassinAccountQuery?: LoadPersonalClassinAccountQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<null> {
    return axios(
      classinApi$loadPersonalClassinAccount.config(
        loadPersonalClassinAccountQuery,
        options,
      ),
    ) as any
  },

  useApi<TSelected = null>(
    query?: LoadPersonalClassinAccountQuery,
    queryOptions?: QueryOptionsWithSelect<null, TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<null, TSelected>(
      classinApi$loadPersonalClassinAccount.api,
      'Classin.loadPersonalClassinAccount',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
classinApi$loadPersonalClassinAccount.api._name_ = `Classin.loadPersonalClassinAccount`

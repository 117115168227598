/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  ConfigItemReq,
  ConfigItemReqInterface,
} from './extends/config-item-req'
import { ConfigItem, ConfigItemInterface } from './extends/config-item'

const buildConfig = createConfigBuilder('chalk')

export const acmApi$mutationConfigItems = {
  config(
    scope: string,
    service: string,
    configItemReq: ConfigItemReqInterface[],
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'patch',
      '/acm/scopes/{scope}/{service}/items',
      {
        scope,
        service,
      },
      null,
      configItemReq,
      {
        format: 'json',
        isArray: true,
        defaults: {},
      },
    )
  },

  /**
   * @summary 批量更改配置项
   * @param scope 作用域
   * @param service 所属服务
   * @param configItemReq
   * @param [options]
   * @return AxiosResponsePromise<ConfigItem[]>
   */

  api(
    scope: string,
    service: string,
    configItemReq: ConfigItemReqInterface[],
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<ConfigItem[]> {
    return axios(
      acmApi$mutationConfigItems.config(scope, service, configItemReq, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new ConfigItem(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({
        scope,
        service,
        payload,
      }: {
        scope: string
        service: string
        payload: ConfigItemReqInterface[]
      }) => acmApi$mutationConfigItems.api(scope, service, payload),
      mutationOptions,
    )
  },
}

// @ts-ignore
acmApi$mutationConfigItems.api._name_ = `Acm.mutationConfigItems`

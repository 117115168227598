/**
 * @file register certification messages
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Questionnaire,
  getLabel: () => $t('问卷'),
  labelStyle: calculateLabelStyle('#F7E9FA', '#9E37B4'),
  messages: [
    'uncompleted_notice_for_reflection',
    'questionnaire.uncompleted_notice',
  ],
})

export interface FormFeedbackInterface {
  formTemplateId: number
  formTemplateFieldId: number
  ownerId: number
  feedback: string
  /**
   * 主键 ID
   */
  id: number
}

export class FormFeedback implements FormFeedbackInterface {
  formTemplateId: number
  formTemplateFieldId: number
  ownerId: number
  feedback: string
  /**
   * 主键 ID
   */
  id: number

  constructor(props: FormFeedbackInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      formTemplateId: _formTemplateId,
      formTemplateFieldId: _formTemplateFieldId,
      ownerId: _ownerId,
      feedback: _feedback,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.formTemplateId = _formTemplateId
    this.formTemplateFieldId = _formTemplateFieldId
    this.ownerId = _ownerId
    this.feedback = _feedback
    this.id = _id
  }

  static propKeys = [
    'formTemplateId',
    'formTemplateFieldId',
    'ownerId',
    'feedback',
    'id',
  ]
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { PatchItemReq, PatchItemReqInterface } from './patch-item-req'
import { Item, ItemInterface } from './extends/item'
import type { PatchDormItemQuery } from './patch-dorm-item-query'

const buildConfig = createConfigBuilder('vnas')

export const dormApi$patchDormItem = {
  config(
    id: number,
    patchItemReq: PatchItemReqInterface,
    patchDormItemQuery?: PatchDormItemQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'patch',
      '/dorm/items/{id}',
      {
        id,
      },
      patchDormItemQuery,
      patchItemReq,
      {
        format: 'json',
        isArray: false,
        defaults: {
          name: (v: any) => v || '',
          description: (v: any) => v || '',
          topN: (v: any) => (v === 0 ? v : v || null),
          lockSetting: (v: any) => v || false,
          lockScore: (v: any) => (v === false ? v : v || null),
          lockRules: (v: any) => v || null,
          reviewNeeded: (v: any) => (v === false ? v : v || null),
          within: (v: any) => (v === false ? v : v || null),
          withinScore: (v: any) => v || null,
          fullScore: (v: any) => v || null,
          initialScore: (v: any) => v || null,
          computeType: (v: any) => v || null,
          weight: (v: any) => (v === 0 ? v : v || null),
          scoringType: (v: any) => v || null,
          tags: (v: any) => v || [],
          displayType: (v: any) => v || null,
          displayRules: (v: any) => v || [],
          displayLevelItems: (v: any) => v || [],
          relation: (v: any) => v || null,
          taskRelations: (v: any) => v || [],
          relationRules: (v: any) => v || null,
          sort: (v: any) => v || 0,
          enableAfterthought: (v: any) => (v === false ? v : v || null),
          stageId: (v: any) => (v === 0 ? v : v || null),
          settings: (v: any) => v || null,
        },
      },
    )
  },

  /**
   * @summary 教务修改评价结构
   * @param id
   * @param patchItemReq
   * @param [patchDormItemQuery]
   * @param [dormQuery.expand]
   * @param [options]
   * @return AxiosResponsePromise<Item>
   */

  api<const TQuery extends PatchDormItemQuery>(
    id: number,
    patchItemReq: PatchItemReqInterface,
    patchDormItemQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Item, TQuery['expand']>> {
    return axios(
      dormApi$patchDormItem.config(
        id,
        patchItemReq,
        patchDormItemQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new Item(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({
        id,
        payload,
        query,
      }: {
        id: number
        payload: PatchItemReqInterface
        query?: PatchDormItemQuery
      }) => dormApi$patchDormItem.api(id, payload, query),
      mutationOptions,
    )
  },
}

// @ts-ignore
dormApi$patchDormItem.api._name_ = `Dorm.patchDormItem`

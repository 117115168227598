import { Attachment, AttachmentInterface } from './attachment'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import {
  DiscussionTopicHistory,
  DiscussionTopicHistoryInterface,
} from './discussion-topic-history'
import {
  DiscussionComment,
  DiscussionCommentInterface,
} from './discussion-comment'
import {
  DiscussionMessage,
  DiscussionMessageInterface,
} from './discussion-message'

export interface DiscussionTopicInterface {
  /**
   * 讨论区Id
   */
  discussionId: number
  /**
   * 创建者Id
   */
  creatorId: number
  /**
   * 话题的文字内容
   */
  content: string
  /**
   * 附件
   */
  attachments?: AttachmentInterface[]
  creator?: SecuredReflectionInterface
  histories?: DiscussionTopicHistoryInterface[]
  comments?: DiscussionCommentInterface[]
  twoComments?: DiscussionCommentInterface[]
  messageCount?: number
  publicMessageCount?: number
  commentCount?: number
  messages?: DiscussionMessageInterface[]
  isTopped?: boolean
  isCollected?: boolean
  collectedAt?: string | null
  isExcellented?: boolean
  excellentedAt?: string | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class DiscussionTopic implements DiscussionTopicInterface {
  /**
   * 讨论区Id
   */
  discussionId: number
  /**
   * 创建者Id
   */
  creatorId: number
  /**
   * 话题的文字内容
   */
  content: string
  /**
   * 附件
   */
  attachments?: Attachment[]
  creator?: SecuredReflection
  histories?: DiscussionTopicHistory[]
  comments?: DiscussionComment[]
  twoComments?: DiscussionComment[]
  messageCount?: number
  publicMessageCount?: number
  commentCount?: number
  messages?: DiscussionMessage[]
  isTopped?: boolean
  isCollected?: boolean
  collectedAt?: string | null
  isExcellented?: boolean
  excellentedAt?: string | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: DiscussionTopicInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      discussionId: _discussionId,
      creatorId: _creatorId,
      content: _content,
      attachments: _attachments,
      creator: _creator,
      histories: _histories,
      comments: _comments,
      twoComments: _twoComments,
      messageCount: _messageCount,
      publicMessageCount: _publicMessageCount,
      commentCount: _commentCount,
      messages: _messages,
      isTopped: _isTopped,
      isCollected: _isCollected,
      collectedAt: _collectedAt,
      isExcellented: _isExcellented,
      excellentedAt: _excellentedAt,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.discussionId = _discussionId
    this.creatorId = _creatorId
    this.content = _content
    this.attachments = _attachments
      ? _attachments.map(i => new Attachment(i))
      : _attachments
    this.creator = _creator ? new SecuredReflection(_creator) : _creator
    this.histories = _histories
      ? _histories.map(i => new DiscussionTopicHistory(i))
      : _histories
    this.comments = _comments
      ? _comments.map(i => new DiscussionComment(i))
      : _comments
    this.twoComments = _twoComments
      ? _twoComments.map(i => new DiscussionComment(i))
      : _twoComments
    this.messageCount = _messageCount
    this.publicMessageCount = _publicMessageCount
    this.commentCount = _commentCount
    this.messages = _messages
      ? _messages.map(i => new DiscussionMessage(i))
      : _messages
    this.isTopped = _isTopped
    this.isCollected = _isCollected
    this.collectedAt = _collectedAt
    this.isExcellented = _isExcellented
    this.excellentedAt = _excellentedAt
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'discussionId',
    'creatorId',
    'content',
    'attachments',
    'creator',
    'histories',
    'comments',
    'twoComments',
    'messageCount',
    'publicMessageCount',
    'commentCount',
    'messages',
    'isTopped',
    'isCollected',
    'collectedAt',
    'isExcellented',
    'excellentedAt',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Semester, SemesterInterface } from './semester'
import type { QuerySemestersQuery } from './query-semesters-query'

const buildConfig = createConfigBuilder('chalk')

export const semesterApi$querySemesters = {
  config(
    querySemestersQuery?: QuerySemestersQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/system/semesters',
      {},
      querySemestersQuery,
    )
  },

  /**
   * @summary Class QuerySemestersApi
   * @param [querySemestersQuery]
   * @param [semesterQuery.idIn] 学期Id
   * @param [semesterQuery.academicYearId] 学年Id
   * @param [semesterQuery.academicYearIdIn]
   * @param [semesterQuery.isTemporary]
   * @param [semesterQuery.categoryId] 学期类型
   * @param [semesterQuery.categoryIdIn]
   * @param [semesterQuery.name] 学期名
   * @param [semesterQuery.nameLike]
   * @param [semesterQuery.paginated] 是否分页，默认 1
   * @param [semesterQuery.page] 显示的数据页，默认 1
   * @param [semesterQuery.perPage] 每页显示数量，默认 20
   * @param [semesterQuery.startAtElt]
   * @param [semesterQuery.startAtEgt]
   * @param [semesterQuery.endAtElt]
   * @param [semesterQuery.endAtEgt]
   * @param [semesterQuery.expand] 支持 expand:
   *
   * 1 . academic_year 学年
   * 2 . category 分类
   * 3 . is_current 是否当前学期
   * @param [options]
   * @return AxiosResponsePromise<Semester[]>
   */

  api<const TQuery extends QuerySemestersQuery>(
    querySemestersQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Semester, TQuery['expand']>[]> {
    return axios(
      semesterApi$querySemesters.config(querySemestersQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Semester(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QuerySemestersQuery,
    TSelected = Expand<Semester, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<Semester, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Semester, TQuery['expand']>[], TSelected>(
      semesterApi$querySemesters.api,
      'Semester.querySemesters',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
semesterApi$querySemesters.api._name_ = `Semester.querySemesters`

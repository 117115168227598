import { GroupRoleEnum } from 'packages/features/groups/types'
import { MessageDomainEnum } from 'packages/features/messages/types'
import { registerMessageNavigator } from 'packages/features/messages/utils'

registerMessageNavigator(
  [`${MessageDomainEnum.SubjectSelection}.uncompleted_notice_for_student`],
  msg => {
    const attrs = msg.attributes as any

    if (attrs) {
      return {
        modalName: 'SubjectSelection.AnswerModal',
        options: { qid: attrs.formTemplateId },
      }
    }

    return null
  },
)

registerMessageNavigator(
  [`${MessageDomainEnum.SubjectSelection}.uncompleted_notice_for_mentor`],
  msg => {
    const attrs = msg.attributes as any

    if (attrs) {
      return {
        modalName: 'SubjectSelection.UnfilledStudentsModal',
        options: {
          id: attrs.formTemplateId,
          groupRole: GroupRoleEnum.Mentor,
        },
      }
    }

    return null
  },
)

registerMessageNavigator(
  [
    `${MessageDomainEnum.SubjectSelection}.uncompleted_notice_for_admin_class_teacher`,
  ],
  msg => {
    const attrs = msg.attributes as any

    if (attrs) {
      return {
        modalName: 'SubjectSelection.UnfilledStudentsModal',
        options: {
          id: attrs.formTemplateId,
          groupRole: GroupRoleEnum.AdminClassTeacher,
        },
      }
    }

    return null
  },
)

registerMessageNavigator(
  [`${MessageDomainEnum.SubjectSelection}.uncompleted_notice_for_guardian`],
  () => null,
)

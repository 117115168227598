import { SendSettingTypeEnum } from './send-setting-type-enum'

export interface SendSettingInterface {
  /**
   * 发送人ID
   */
  ids?: number[]
  /**
   * 管理科目
   */
  subjectIds?: number[]
  /**
   * 管理年级
   */
  gradeIds?: number[]
  /**
   * 管理级部
   */
  jibu?: string[]
  /**
   * 发送人类型
   */
  type?: SendSettingTypeEnum
}

export class SendSetting implements SendSettingInterface {
  /**
   * 发送人ID
   */
  ids?: number[]
  /**
   * 管理科目
   */
  subjectIds?: number[]
  /**
   * 管理年级
   */
  gradeIds?: number[]
  /**
   * 管理级部
   */
  jibu?: string[]
  /**
   * 发送人类型
   */
  type?: SendSettingTypeEnum

  constructor(props: SendSettingInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      ids: _ids,
      subjectIds: _subjectIds,
      gradeIds: _gradeIds,
      jibu: _jibu,
      type: _type,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.ids = _ids
    this.subjectIds = _subjectIds
    this.gradeIds = _gradeIds
    this.jibu = _jibu
    this.type = _type
  }

  static propKeys = ['ids', 'subjectIds', 'gradeIds', 'jibu', 'type']
}

/**
 * @file 根据当前环境获取 Reflection 姓名
 */
import { useCallback } from 'react'

import { useIsEnLocale } from 'packages/locale/hooks'
import { Reflection as OriginReflection } from 'packages/sdks-next/chalk'

export type Reflection =
  | (Pick<OriginReflection, 'name' | 'ename'> & {
      pinyin?: string
    })
  | null

const getReflectionName = (isEn: boolean, reflection?: Reflection) => {
  if (!reflection) return ''

  if (isEn) return reflection.ename || reflection.pinyin || reflection.name
  return reflection.name
}

export const useReflectionName = (reflection?: Reflection) => {
  const isEn = useIsEnLocale()

  return getReflectionName(isEn, reflection)
}

/**
 * @deprecated use useI18nName from feature-utils/reflections instead
 */
export const useGetReflectionName = () => {
  const isEn = useIsEnLocale()

  return useCallback(
    (reflection?: Reflection) => getReflectionName(isEn, reflection),
    [isEn],
  )
}

/**
 * @file 学案-navigator 注册
 */

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator('handout.review_publish', ({ attributes }) => {
  const { classId, rid, outlineId } = attributes as {
    classId: number
    rid: number
    handoutId: number
    outlineId: number
  }

  return {
    path: `/classes/${classId}/for-reflection/${rid}/handout?outlineId=${outlineId}`,
  }
})

registerMessageNavigator(
  ['handout.discussion_commented', 'handout.discussion_replied'],
  ({ attributes }) => {
    const { classId, outlineId, blockId } = attributes as {
      classId: number
      outlineId: number
      blockId: number
    }

    return {
      path: `/classes/${classId}/handout?outlineId=${outlineId}&blockId=${blockId}`,
    }
  },
)

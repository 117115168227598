import { SchcalScope, SchcalScopeInterface } from './schcal-scope'

export interface SchcalInterface {
  semesterId: number
  name: string
  scopes: SchcalScopeInterface[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Schcal implements SchcalInterface {
  semesterId: number
  name: string
  scopes: SchcalScope[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: SchcalInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      semesterId: _semesterId,
      name: _name,
      scopes: _scopes,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.semesterId = _semesterId
    this.name = _name
    this.scopes = _scopes ? _scopes.map(i => new SchcalScope(i)) : _scopes
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'semesterId',
    'name',
    'scopes',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

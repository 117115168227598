import {
  CertReflectionsInfo,
  CertReflectionsInfoInterface,
} from './cert-reflections-info'
import { SchoolPlugin, SchoolPluginInterface } from './school-plugin'
import { CertAdminTypeEnum } from './cert-admin-type-enum'
import { CertCCRoleEnum } from './cert-cc-role-enum'
import { CertStatusEnum } from './cert-status-enum'
import { CertDisplayTypeEnum } from './cert-display-type-enum'
import { Term, TermInterface } from './extends/term'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'

export interface CertificationInterface {
  /**
   * 档案徽章墙认证信息（已获得数量、申请中数量、最后一次获取的详情）
   */
  certReflectionsInfo?: CertReflectionsInfoInterface | null
  /**
   * 调用 createCertsTokens 接口时返回生成的二维码 tokens
   */
  createdTokens?: string[]
  /**
   * 认证所属插件
   */
  schoolPlugin?: SchoolPluginInterface | null
  /**
   * 所属子插件 id
   */
  schoolPluginId: number
  /**
   * 认证类型 id
   */
  certificationTypeId: number
  /**
   * 认证名
   */
  name: string
  /**
   * 负责人类型，必填
   */
  adminTypes: CertAdminTypeEnum[]
  /**
   * 抄送人角色
   */
  ccRoles?: CertCCRoleEnum[]
  /**
   * 认证负责人为班主任时，自定义班主任 rids，null 标识全部班主任
   */
  adminAdminclassTeacherIds?: number[] | null
  /**
   * 认证负责人为任课教师时，自定义任课教师 rids，null 标识全部任课教师
   */
  adminClassTeacherIds?: number[] | null
  /**
   * 认证负责人为指定人员时 rids，null 标识认证负责人不包含指定人员
   */
  adminReflectionIds?: number[] | null
  /**
   * 证书状态
   * 后台创建 - 正常
   * 学生申请创建 - 待审核
   */
  status?: CertStatusEnum
  /**
   * 积分（校园币）
   */
  credit?: number | null
  /**
   * 认证图标
   */
  icon: string
  /**
   * 描述介绍
   */
  description?: string
  /**
   * 学生认证申请状态 默认为0不可申请,1为可申请
   */
  canApply: number
  /**
   * 实体卡片样式
   */
  cardSettings?: object | null
  /**
   * 证书 - 显示形式
   */
  displayType?: CertDisplayTypeEnum | null
  /**
   * 证书 - 认证级别 - 前端配置
   */
  levelName?: string
  /**
   * 证书 - 认证科目
   */
  subjectId?: number
  /**
   * 证书 - 电子证书附件
   */
  attachments?: object[]
  /**
   * 证书 - 电子证书标题来源时间等信息
   */
  extraFields?: object | null
  /**
   * expand certification_type
   */
  certificationType?: TermInterface | null
  /**
   * expand admin_reflections
   */
  adminReflections?: SecuredReflectionInterface[]
  /**
   * expand 认证记录数量
   */
  certReflectionsCount?: number | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Certification implements CertificationInterface {
  /**
   * 档案徽章墙认证信息（已获得数量、申请中数量、最后一次获取的详情）
   */
  certReflectionsInfo?: CertReflectionsInfo | null
  /**
   * 调用 createCertsTokens 接口时返回生成的二维码 tokens
   */
  createdTokens?: string[]
  /**
   * 认证所属插件
   */
  schoolPlugin?: SchoolPlugin | null
  /**
   * 所属子插件 id
   */
  schoolPluginId: number
  /**
   * 认证类型 id
   */
  certificationTypeId: number
  /**
   * 认证名
   */
  name: string
  /**
   * 负责人类型，必填
   */
  adminTypes: CertAdminTypeEnum[]
  /**
   * 抄送人角色
   */
  ccRoles?: CertCCRoleEnum[]
  /**
   * 认证负责人为班主任时，自定义班主任 rids，null 标识全部班主任
   */
  adminAdminclassTeacherIds?: number[] | null
  /**
   * 认证负责人为任课教师时，自定义任课教师 rids，null 标识全部任课教师
   */
  adminClassTeacherIds?: number[] | null
  /**
   * 认证负责人为指定人员时 rids，null 标识认证负责人不包含指定人员
   */
  adminReflectionIds?: number[] | null
  /**
   * 证书状态
   * 后台创建 - 正常
   * 学生申请创建 - 待审核
   */
  status?: CertStatusEnum
  /**
   * 积分（校园币）
   */
  credit?: number | null
  /**
   * 认证图标
   */
  icon: string
  /**
   * 描述介绍
   */
  description?: string
  /**
   * 学生认证申请状态 默认为0不可申请,1为可申请
   */
  canApply: number
  /**
   * 实体卡片样式
   */
  cardSettings?: object | null
  /**
   * 证书 - 显示形式
   */
  displayType?: CertDisplayTypeEnum | null
  /**
   * 证书 - 认证级别 - 前端配置
   */
  levelName?: string
  /**
   * 证书 - 认证科目
   */
  subjectId?: number
  /**
   * 证书 - 电子证书附件
   */
  attachments?: object[]
  /**
   * 证书 - 电子证书标题来源时间等信息
   */
  extraFields?: object | null
  /**
   * expand certification_type
   */
  certificationType?: Term | null
  /**
   * expand admin_reflections
   */
  adminReflections?: SecuredReflection[]
  /**
   * expand 认证记录数量
   */
  certReflectionsCount?: number | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: CertificationInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      certReflectionsInfo: _certReflectionsInfo,
      createdTokens: _createdTokens,
      schoolPlugin: _schoolPlugin,
      schoolPluginId: _schoolPluginId,
      certificationTypeId: _certificationTypeId,
      name: _name,
      adminTypes: _adminTypes,
      ccRoles: _ccRoles,
      adminAdminclassTeacherIds: _adminAdminclassTeacherIds,
      adminClassTeacherIds: _adminClassTeacherIds,
      adminReflectionIds: _adminReflectionIds,
      status: _status,
      credit: _credit,
      icon: _icon,
      description: _description,
      canApply: _canApply,
      cardSettings: _cardSettings,
      displayType: _displayType,
      levelName: _levelName,
      subjectId: _subjectId,
      attachments: _attachments,
      extraFields: _extraFields,
      certificationType: _certificationType,
      adminReflections: _adminReflections,
      certReflectionsCount: _certReflectionsCount,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.certReflectionsInfo = _certReflectionsInfo
      ? new CertReflectionsInfo(_certReflectionsInfo)
      : _certReflectionsInfo
    this.createdTokens = _createdTokens
    this.schoolPlugin = _schoolPlugin
      ? new SchoolPlugin(_schoolPlugin)
      : _schoolPlugin
    this.schoolPluginId = _schoolPluginId
    this.certificationTypeId = _certificationTypeId
    this.name = _name
    this.adminTypes = _adminTypes
    this.ccRoles = _ccRoles
    this.adminAdminclassTeacherIds = _adminAdminclassTeacherIds
    this.adminClassTeacherIds = _adminClassTeacherIds
    this.adminReflectionIds = _adminReflectionIds
    this.status = _status
    this.credit = _credit
    this.icon = _icon
    this.description = _description
    this.canApply = _canApply
    this.cardSettings = _cardSettings
    this.displayType = _displayType
    this.levelName = _levelName
    this.subjectId = _subjectId
    this.attachments = _attachments
    this.extraFields = _extraFields
    this.certificationType = _certificationType
      ? new Term(_certificationType)
      : _certificationType
    this.adminReflections = _adminReflections
      ? _adminReflections.map(i => new SecuredReflection(i))
      : _adminReflections
    this.certReflectionsCount = _certReflectionsCount
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'certReflectionsInfo',
    'createdTokens',
    'schoolPlugin',
    'schoolPluginId',
    'certificationTypeId',
    'name',
    'adminTypes',
    'ccRoles',
    'adminAdminclassTeacherIds',
    'adminClassTeacherIds',
    'adminReflectionIds',
    'status',
    'credit',
    'icon',
    'description',
    'canApply',
    'cardSettings',
    'displayType',
    'levelName',
    'subjectId',
    'attachments',
    'extraFields',
    'certificationType',
    'adminReflections',
    'certReflectionsCount',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

export interface LockRulesInterface {
  /**
   * 开始时间，有值时设置
   */
  startAt?: string | null
  /**
   * 结束时间，有值时限制
   */
  endAt?: string | null
}

export class LockRules implements LockRulesInterface {
  /**
   * 开始时间，有值时设置
   */
  startAt?: string | null
  /**
   * 结束时间，有值时限制
   */
  endAt?: string | null

  constructor(props: LockRulesInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      startAt: _startAt,
      endAt: _endAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.startAt = _startAt
    this.endAt = _endAt
  }

  static propKeys = ['startAt', 'endAt']
}

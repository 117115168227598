import { School, SchoolInterface } from './school'

export interface OrganizationInterface {
  /**
   * 组织名称
   */
  name: string
  /**
   * 组织编码
   */
  number: number
  /**
   * 成员ids
   */
  memberIds?: number[]
  /**
   * 成员 expand
   */
  members?: SchoolInterface[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Organization implements OrganizationInterface {
  /**
   * 组织名称
   */
  name: string
  /**
   * 组织编码
   */
  number: number
  /**
   * 成员ids
   */
  memberIds?: number[]
  /**
   * 成员 expand
   */
  members?: School[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: OrganizationInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      number: _number,
      memberIds: _memberIds,
      members: _members,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.number = _number
    this.memberIds = _memberIds
    this.members = _members ? _members.map(i => new School(i)) : _members
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'name',
    'number',
    'memberIds',
    'members',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

import { useEnabledPlugins } from '../plugins'
import { useSemesters } from '../semesters'

/**
 * 初始化应用运行所需的基础数据: 包括 session, 权限, 学期和插件信息
 *
 * @returns 是否已初始化完毕
 */
export const useBootstrapData = () => {
  // TODO: implement
  return true
}

/**
 * 返回一个重新初始化应用基础数据的 reload 方法,
 * 通常用于用户身份的热切换 (不重启整个页面或应用).
 *
 * @returns reload function
 */
export const useBootstrapDataReload = () => {
  const [, reloadSemesters] = useSemesters()
  const [, , reloadPlugins] = useEnabledPlugins()
  return async () => {
    await Promise.all([reloadPlugins(), reloadSemesters()])
  }
}

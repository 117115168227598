/**
 * @file plugin hooks
 */

import { useRef, useState } from 'react'

import {
  EnabledPlugin,
  useEnabledPlugins,
} from 'packages/feature-utils/plugins'

/**
 * 防止函数多次运行
 *
 * @param params - 参数
 * @param params.fn - 需要防止多次运行的函数
 * @param params.disable - 是否禁用
 */
export const useOnce = ({
  fn,
  disable,
}: {
  fn: Function
  disable?: boolean
}) => {
  const _fn = useRef(fn)
  _fn.current = fn

  const done = useRef(false)

  if (!done.current && !disable) {
    _fn.current?.()
    done.current = true
  }
}

/**
 * 初始化插件。获取当前用户有权限使用的所有插件，并对 features、plugins 初始化
 *
 * @param sessionCreated - boolean 表示用户是否登录
 * @param fn - 插件初始化成功后的回调函数，参数为当前用户有权限使用的插件
 * @returns boolean 插件是否初始化完成
 */
export const usePluginsInit = (
  sessionCreated: boolean,
  fn: (enabledPlugins: EnabledPlugin[]) => void,
) => {
  const [enabledPlugins, requestInited] = useEnabledPlugins({
    disable: !sessionCreated,
  })

  const [pluginInited, setPluginInited] = useState(false)

  useOnce({
    disable: !requestInited,
    fn: () => {
      fn(enabledPlugins)

      setPluginInited(true)
    },
  })

  return pluginInited
}

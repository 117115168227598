import {
  Field,
  FieldTypeEnum as FieldTypeEnumRaw,
} from 'packages/sdks-next/form'

export enum ExtendedFieldTypeEnum {
  /**
   * 无代码表单中的表格字段
   */
  Table = 'table',
  /**
   * 无代码的计算字段
   */
  Computed = 'computed',
}

export type LocalFieldType = FieldTypeEnumRaw | ExtendedFieldTypeEnum

export type LocalField = Omit<Field, 'type'> & {
  /**
   * 字段类型
   */
  type: LocalFieldType
  /**
   * 子字段, 仅在 type 为 table 时有效（暂时只有无代码会用到）
   */
  fields?: LocalField[]
}

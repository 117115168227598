/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { CalendarEvent, CalendarEventInterface } from './extends/calendar-event'
import type { ListCalendarEventsQuery } from './list-calendar-events-query'

const buildConfig = createConfigBuilder('chalk')

export const groupApi$listCalendarEvents = {
  config(
    id: string,
    startTime: string,
    endTime: string,
    listCalendarEventsQuery?: ListCalendarEventsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/group/groups/{id}/calendar-events',
      {
        id,
        startTime,
        endTime,
      },
      listCalendarEventsQuery,
    )
  },

  /**
   * @summary 获取群组日程事件
   * 目前仅支持 seiue.class_group 群组类型。
   * @param id Group ID，群组ID, 同时支持 enhancer + bizId， 如: seiue.class_group:123 其中 123 为 classId。
   * @param startTime 开始时间
   * @param endTime 结束时间
   * @param [listCalendarEventsQuery]
   * @param [groupQuery.expand] 支持 initiators 和 address
   * @param [options]
   * @return AxiosResponsePromise<CalendarEvent[]>
   */

  api<const TQuery extends ListCalendarEventsQuery>(
    id: string,
    startTime: string,
    endTime: string,
    listCalendarEventsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<CalendarEvent, TQuery['expand']>[]> {
    return axios(
      groupApi$listCalendarEvents.config(
        id,
        startTime,
        endTime,
        listCalendarEventsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new CalendarEvent(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends ListCalendarEventsQuery,
    TSelected = Expand<CalendarEvent, TQuery['expand']>[],
  >(
    {
      id,
      startTime,
      endTime,
      query,
    }: {
      id: string
      startTime: string
      endTime: string
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<CalendarEvent, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<CalendarEvent, TQuery['expand']>[], TSelected>(
      groupApi$listCalendarEvents.api,
      'Group.listCalendarEvents',
      queryOptions,
      id,
      startTime,
      endTime,
      query,
    )
  },
}

// @ts-ignore
groupApi$listCalendarEvents.api._name_ = `Group.listCalendarEvents`

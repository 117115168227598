/**
 * @file 请假注册
 */

import { PluginEnum } from 'packages/feature-utils/plugins'
import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.LeaveFlow,
  getLabel: () => $t('请假'),
  labelStyle: calculateLabelStyle('#e7ecf0', '#3e5f8b'),
  icon: `plugin.${PluginEnum.Absence}`,
  messages: [
    'absence_status_changed',
    'agented_absence_approved',
    'stage_received',
    'absence.early_ended_for_initiator',
    'absence.flow_cc_node',
    'flow_commented',
  ],
})

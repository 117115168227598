import { NodeTypeEnum } from './node-type-enum'
import { FlowStageStatusEnum } from './flow-stage-status-enum'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import { Attachment, AttachmentInterface } from './attachment'

export interface FlowStageInterface {
  /**
   * flow_node 对象的 id
   */
  flowNodeId: number
  /**
   * 节点类型，实际场景中后端不返回 exit 类型
   */
  nodeType: NodeTypeEnum
  /**
   * 审批人/办理人/抄送人
   */
  reflectionId: number
  /**
   * 把审批退回给谁了，如果是会签可以退回给指定的人，即 reflection_id 把这个审批退回给 returned_rids
   */
  returnedRids?: number[] | null
  /**
   * 退回的 flow_node id
   */
  returnedFlowNodeId?: number | null
  /**
   * 转交给的人
   */
  forwardedRid?: number | null
  /**
   * 状态
   */
  status: FlowStageStatusEnum
  /**
   * 签名图片
   */
  signImg?: string | null
  /**
   * 撤回/驳回/拒绝/通过/转交/退回 时间
   */
  reviewedAt?: string | null
  /**
   * 审批意见/退回理由/转交原因
   */
  remark?: string | null
  /**
   * expand 审批退回给的人
   */
  returnedReflections?: SecuredReflectionInterface[]
  /**
   * expand 转交给的人
   */
  forwardedReflection?: SecuredReflectionInterface
  /**
   * expand 阶段所属的 审批人/办理人/抄送人
   */
  reflection?: SecuredReflectionInterface
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 审批流 ID
   */
  workflowId: number
  /**
   * 审批单 ID
   */
  flowId: number
  /**
   * 附件
   */
  attachments?: AttachmentInterface[] | null
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class FlowStage implements FlowStageInterface {
  /**
   * flow_node 对象的 id
   */
  flowNodeId: number
  /**
   * 节点类型，实际场景中后端不返回 exit 类型
   */
  nodeType: NodeTypeEnum
  /**
   * 审批人/办理人/抄送人
   */
  reflectionId: number
  /**
   * 把审批退回给谁了，如果是会签可以退回给指定的人，即 reflection_id 把这个审批退回给 returned_rids
   */
  returnedRids?: number[] | null
  /**
   * 退回的 flow_node id
   */
  returnedFlowNodeId?: number | null
  /**
   * 转交给的人
   */
  forwardedRid?: number | null
  /**
   * 状态
   */
  status: FlowStageStatusEnum
  /**
   * 签名图片
   */
  signImg?: string | null
  /**
   * 撤回/驳回/拒绝/通过/转交/退回 时间
   */
  reviewedAt?: string | null
  /**
   * 审批意见/退回理由/转交原因
   */
  remark?: string | null
  /**
   * expand 审批退回给的人
   */
  returnedReflections?: SecuredReflection[]
  /**
   * expand 转交给的人
   */
  forwardedReflection?: SecuredReflection
  /**
   * expand 阶段所属的 审批人/办理人/抄送人
   */
  reflection?: SecuredReflection
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 审批流 ID
   */
  workflowId: number
  /**
   * 审批单 ID
   */
  flowId: number
  /**
   * 附件
   */
  attachments?: Attachment[] | null
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: FlowStageInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      flowNodeId: _flowNodeId,
      nodeType: _nodeType,
      reflectionId: _reflectionId,
      returnedRids: _returnedRids,
      returnedFlowNodeId: _returnedFlowNodeId,
      forwardedRid: _forwardedRid,
      status: _status,
      signImg: _signImg,
      reviewedAt: _reviewedAt,
      remark: _remark,
      returnedReflections: _returnedReflections,
      forwardedReflection: _forwardedReflection,
      reflection: _reflection,
      id: _id,
      schoolId: _schoolId,
      workflowId: _workflowId,
      flowId: _flowId,
      attachments: _attachments,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.flowNodeId = _flowNodeId
    this.nodeType = _nodeType
    this.reflectionId = _reflectionId
    this.returnedRids = _returnedRids
    this.returnedFlowNodeId = _returnedFlowNodeId
    this.forwardedRid = _forwardedRid
    this.status = _status
    this.signImg = _signImg
    this.reviewedAt = _reviewedAt
    this.remark = _remark
    this.returnedReflections = _returnedReflections
      ? _returnedReflections.map(i => new SecuredReflection(i))
      : _returnedReflections
    this.forwardedReflection = _forwardedReflection
      ? new SecuredReflection(_forwardedReflection)
      : _forwardedReflection
    this.reflection = _reflection
      ? new SecuredReflection(_reflection)
      : _reflection
    this.id = _id
    this.schoolId = _schoolId
    this.workflowId = _workflowId
    this.flowId = _flowId
    this.attachments = _attachments
      ? _attachments.map(i => new Attachment(i))
      : _attachments
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'flowNodeId',
    'nodeType',
    'reflectionId',
    'returnedRids',
    'returnedFlowNodeId',
    'forwardedRid',
    'status',
    'signImg',
    'reviewedAt',
    'remark',
    'returnedReflections',
    'forwardedReflection',
    'reflection',
    'id',
    'schoolId',
    'workflowId',
    'flowId',
    'attachments',
    'createdAt',
    'updatedAt',
  ]
}

/**
 * @file 待办 data helper
 */

import React from 'react'
import { useSelector } from 'react-redux'

import { TodoStatusEnum } from 'packages/sdks-next/chalk'

import { TodosRematchState } from './store'

/**
 * 获取所有待处理的待办
 */
export const usePendingTodos = () => {
  const doneTodoIds = useSelector(
    (_state: TodosRematchState) => _state.todos.doneTodoIds ?? [],
  )

  const currentTodos = useSelector(
    (_state: TodosRematchState) => _state.todos.currentTodos ?? [],
  )

  return React.useMemo(
    () =>
      currentTodos.filter(
        todo =>
          !doneTodoIds.find(id => id === todo.id) &&
          todo.status !== TodoStatusEnum.Completed &&
          todo.status !== TodoStatusEnum.Ignored,
      ),
    [currentTodos, doneTodoIds],
  )
}

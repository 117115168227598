export interface AttachedExamInfoInterface {
  examId: number
  examName: string
  relationUpdatedBy?: string
  relationUpdatedAt: string
}

export class AttachedExamInfo implements AttachedExamInfoInterface {
  examId: number
  examName: string
  relationUpdatedBy?: string
  relationUpdatedAt: string

  constructor(props: AttachedExamInfoInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      examId: _examId,
      examName: _examName,
      relationUpdatedBy: _relationUpdatedBy,
      relationUpdatedAt: _relationUpdatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.examId = _examId
    this.examName = _examName
    this.relationUpdatedBy = _relationUpdatedBy
    this.relationUpdatedAt = _relationUpdatedAt
  }

  static propKeys = [
    'examId',
    'examName',
    'relationUpdatedBy',
    'relationUpdatedAt',
  ]
}

/**
 * @file 阴影
 */

import { DividerColor } from './colors'

/**
 * 阴影
 */
export const Shadow = {
  /**
   * 卡片阴影
   */
  Card: '0 3px 16px 0 rgba(50, 56, 66, 0.04)',
  /**
   * 浮窗阴影
   */
  Popover: `0 6px 20px 0 ${DividerColor._2}, 0 2px 8px 0 ${DividerColor._2}`,
  /**
   * 弹窗阴影
   */
  Modal: `0 16px 36px 0 rgba(50, 56, 66, 0.16), 0 6px 16px 0 ${DividerColor._2}`,
} as const

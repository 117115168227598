import { createEnumHelper } from '@seiue/util'

import { $t } from 'packages/locale'
import {
  CertCCRoleEnum,
  CertDisplayTypeEnum,
  CertStatusEnum,
} from 'packages/sdks-next/sgms'

import { CertAdminTypeEnum } from '../cert-admin-type-enum'
import { Certification as Base, CertificationInterface } from '../certification'
import { SchoolPlugin } from '../school-plugin'

export type { CertificationInterface }

const adminTypeToLabel = createEnumHelper(() => ({
  [CertAdminTypeEnum.AdminClassTeacher]: $t('班主任'),
  [CertAdminTypeEnum.Teacher]: $t('任课教师'),
  [CertAdminTypeEnum.Manager]: $t('指定教师'),
}))

const ccRoleToLabel = createEnumHelper(() => ({
  [CertCCRoleEnum.AdminClassTeacher]: $t('班主任'),
}))

const statusToLabel = createEnumHelper(() => ({
  [CertStatusEnum.Normal]: $t('正常'),
  [CertStatusEnum.Pending]: $t('待审核'),
}))

/**
 * 认证（徽章）
 */
export class Certification extends Base {
  /**
   * 获取负责人可读的文本标签
   *
   * @returns 文本标签
   */
  get adminTypesLabel() {
    const labels: string[] = []

    // 班主任
    if (this.adminTypes.includes(CertAdminTypeEnum.AdminClassTeacher)) {
      const label = adminTypeToLabel.get(CertAdminTypeEnum.AdminClassTeacher)
      labels.push(
        this.adminAdminclassTeacherIds?.length
          ? $t('{label}（{count} 人）', {
              label,
              count: this.adminAdminclassTeacherIds.length,
            })
          : label,
      )
    }

    // 任课教师
    if (this.adminTypes.includes(CertAdminTypeEnum.Teacher)) {
      const label = adminTypeToLabel.get(CertAdminTypeEnum.Teacher)
      labels.push(
        this.adminClassTeacherIds?.length
          ? $t('{label}（{count} 人）', {
              label,
              count: this.adminClassTeacherIds.length,
            })
          : label,
      )
    }

    // 指定教师
    if (
      this.adminTypes.includes(CertAdminTypeEnum.Manager) &&
      this.adminReflections?.length
    ) {
      labels.push(
        ...this.adminReflections.map(
          reflection => `${reflection.name}/${reflection.usin}`,
        ),
      )
    }

    return !labels.length ? '' : labels.join('、')
  }

  /**
   * 获取指定负责人类型的文本
   *
   * @param adminType - 负责人类型
   * @returns 负责人文本
   */
  static getAdminTypeLabel(adminType: CertAdminTypeEnum) {
    return adminTypeToLabel.get(adminType)
  }

  /**
   * 获取状态可读的文本标签
   *
   * @returns 文本标签
   */
  get statusLabel() {
    return statusToLabel.get(this.status || CertStatusEnum.Normal)
  }

  /**
   * 获取指定状态的文本
   *
   * @param status - 负责人类型
   * @returns 指定状态的文本
   */
  static getStatusLabel(status: CertStatusEnum) {
    return statusToLabel.get(status)
  }

  /**
   * 获取证书状态的所有选项
   *
   * @param sourceKey - 原始值在选项 object 中的 key, 默认 value
   * @param mappedKey - 文字标签在选项 object 中的 key, 默认 label
   * @returns 选项数组, 默认 [{ value, label }]
   */
  static getStatusOptions<S extends string, M extends string>(
    sourceKey: S,
    mappedKey: M,
  ) {
    return statusToLabel.listOptions(sourceKey, mappedKey)
  }

  /**
   * 获取 adminTypes (负责人) 的所有选项
   *
   * @param sourceKey - 原始值在选项 object 中的 key, 默认 value
   * @param mappedKey - 文字标签在选项 object 中的 key, 默认 label
   * @returns 选项数组, 默认 [{ value, label }]
   */
  static getAdminTypeOptions<S extends string, M extends string>(
    sourceKey: S,
    mappedKey: M,
  ) {
    return adminTypeToLabel.listOptions(sourceKey, mappedKey)
  }

  /**
   * 获取抄送人可读的文本标签
   *
   * @returns 文本标签
   */
  get ccRolesLabel() {
    return this.ccRoles?.map(role => ccRoleToLabel.get(role)).join('、') ?? ''
  }

  /**
   * 获取 ccRoles (抄送人) 的所有选项
   *
   * @param sourceKey - 原始值在选项 object 中的 key, 默认 value
   * @param mappedKey - 文字标签在选项 object 中的 key, 默认 label
   * @returns 选项数组, 默认 [{ value, label }]
   */
  static getCcRoleOptions<S extends string, M extends string>(
    sourceKey: S,
    mappedKey: M,
  ) {
    return ccRoleToLabel.listOptions(sourceKey, mappedKey)
  }

  /**
   * 是否为「处分」认证
   *
   * @returns 是否
   */
  get isPunishment() {
    return getCertificationPluginKind(this.schoolPlugin) === 'online_punishment'
  }

  /**
   * 是否为「实体激励」认证
   *
   * @returns 是否
   */
  get isOfflineIncentive() {
    return getCertificationPluginKind(this.schoolPlugin) === 'offline_incentive'
  }

  /**
   * 是否为「线上虚拟激励」认证
   *
   * @returns 是否
   */
  get isOnlineIncentive() {
    return getCertificationPluginKind(this.schoolPlugin) === 'online_incentive'
  }

  /**
   * 是否为「电子证书」类型认证
   *
   * @returns 是否为「电子证书」类型认证
   */
  get isElecCertificate() {
    return getCertificationPluginKind(this.schoolPlugin) === 'e_certificate'
  }

  /**
   * 「电子证书」类型认证的显示形式是否为「自主上传」
   *
   * @returns 显示形式是否为「自主上传」
   */
  get isOwnerUploadType() {
    return this.displayType === CertDisplayTypeEnum.OwnerUpload
  }

  /**
   * 「电子证书」类型认证的显示形式是否为「电子证书」
   *
   * @returns 显示形式是否为「电子证书」
   */
  get isElecDisplayType() {
    return this.displayType === CertDisplayTypeEnum.ECertificate
  }

  /**
   * 是否可申请「线上虚拟激励」徽章或「电子证书」认证
   *
   * @returns 是否
   */
  get appliable() {
    return !!this.canApply && (this.isOnlineIncentive || this.isElecCertificate)
  }
}

const getCertificationPluginKind = (schoolPlugin?: SchoolPlugin | null) => {
  return (
    schoolPlugin?.settings as
      | {
          kind:
            | 'online_incentive'
            | 'offline_incentive'
            | 'online_punishment'
            | 'e_certificate'
        }
      | undefined
  )?.kind
}

import { EnhancerEnum as EnhancerEnumOriginal } from '../enhancer-enum'

export enum EnhancerEnum {
  // 下方的值是从 EnhancerEnumOriginal 复制而来
  PersonalTask = 'seiue.personal_task',
  ClassActivityTask = 'seiue.class_activity_task',
  ClassHomeworkTask = 'seiue.class_homework_task',
  ClassDocumentTask = 'seiue.class_document_task',
  ClassWebsiteTask = 'seiue.class_website_task',
  ClassQuestionnaireTask = 'seiue.class_questionnaire_task',
  ClassQuizTask = 'seiue.class_quiz_task',
  ClassLiveTask = 'seiue.class_live_task',
  ClassDiscuzTask = 'seiue.class_discuz_task',
  TaskSubmitTodo = 'seiue.task_submit_todo',
  TaskApproveTodo = 'seiue.task_approve_todo',
  ChatTask = 'seiue.chat_task',
  PersonalGroup = 'seiue.personal_group',
  ClassGroup = 'seiue.class_group',
  CourseGroup = 'seiue.course_group',
  RelationGroup = 'seiue.relation_group',
  AdminclassGroup = 'seiue.adminclass_group',
  DormGroup = 'seiue.dorm_group',
  DepartmentGroup = 'seiue.department_group',
  VisitorRole = 'seiue.visitor_role',
  CoreRole = 'seiue.core_role',
  SzGradeReportRole = 'seiue.sz_grade_report_role',
  SzzxClassScheduleRole = 'seiue.szzx_class_schedule_role',
  CgrExamReportRole = 'seiue.cgr_exam_report_role',
  PsychologicalRole = 'seiue.psychological_role',
  CreditRole = 'seiue.credit_role',
  HandoutRole = 'seiue.handout_role',
  DeclarationRole = 'seiue.declaration_role',
  RoleRelation = 'seiue.role_relation',
  QuestionnaireRole = 'seiue.questionnaire_role',
  CallerRole = 'seiue.caller_role',
  ExamReportRole = 'seiue.exam_report_role',
  StudentStatusRole = 'seiue.student_status_role',
  GroupMemberTop = 'seiue.group_member_top',
  DiscussionMemberBannedToPost = 'seiue.discussion_member_banned_to_post',
  DiscussionTopicCollect = 'seiue.discussion_topic_collect',
  DiscussionTopicTop = 'seiue.discussion_topic_top',
  DiscussionTopicExcellent = 'seiue.discussion_topic_excellent',
  TaskDiscussion = 'seiue.task_discussion',
  GroupDiscussion = 'seiue.group_discussion',
  GoalDiscussion = 'seiue.goal_discussion',
  FeedbackDiscussion = 'seiue.feedback_discussion',
  AfterthoughtDiscussion = 'seiue.afterthought_discussion',
  EventDiscussion = 'seiue.event_discussion',
  PsychologicalDiscussion = 'seiue.psychological_discussion',
  NotificationDiscussion = 'seiue.notification_discussion',
  HandoutDiscussion = 'seiue.handout_discussion',
  ChatDiscussion = 'seiue.chat_discussion',
  PsyChatDiscussion = 'seiue.psy_chat_discussion',
  ReflectionScope = 'seiue.reflection_scope',
  StudentScope = 'seiue.student_scope',
  TeacherScope = 'seiue.teacher_scope',
  GuardianScope = 'seiue.guardian_scope',
  RoleManagerScope = 'seiue.role_manager_scope',
  PlaceScope = 'seiue.place_scope',
  ClassScope = 'seiue.class_scope',
  CourseScope = 'seiue.course_scope',
  StaffScope = 'seiue.staff_scope',
  DormScope = 'seiue.dorm_scope',
  AdminClassScope = 'seiue.admin_class_scope',
  SubjectGroupScope = 'seiue.subject_group_scope',
  HandoutScope = 'seiue.handout_scope',
  ExamScoreScope = 'seiue.exam_score_scope',
  StudentStatusConfigScope = 'seiue.student_status_config_scope',
  ReflectionTeacherActivity = 'seiue.reflection.teacher_activity',
  ReflectionStudentActivity = 'seiue.reflection.student_activity',
  ReflectionGuardianActivity = 'seiue.reflection.guardian_activity',
  ReflectionStaffActivity = 'seiue.reflection.staff_activity',
  PlaceActivity = 'seiue.place_activity',
  ScmsClassActivity = 'seiue.scms.class.activity',
  ScmsCourseActivity = 'seiue.scms.course.activity',
  DormActivity = 'seiue.dorm.activity',
  AdminClassActivity = 'seiue.admin_class.activity',
  TaskAssignment = 'seiue.task_assignment',
  TaskTeamAssignment = 'seiue.task_team_assignment',
  DiscussionTopic = 'seiue.discussion_topic',
  DiscussionMessage = 'seiue.discussion_message',
  CustomGroupActivity = 'seiue.custom_group_activity',
  ChatActivity = 'seiue.chat_activity',
  DefaultChat = 'seiue.default_chat',
  PsyChat = 'seiue.psy_chat',
  DefaultSchedule = 'seiue.default_schedule',
  ChatSchedule = 'seiue.chat_schedule',
  LiveSchedule = 'seiue.live_schedule',
  ClassReviewSchedule = 'seiue.class_review_schedule',
  PsychologicalCollaboratorGroup = 'seiue.psychological_collaborator_group',
  ReflectionProfileTeacherActivity = 'seiue.reflection_profile.teacher_activity',
  ReflectionProfileStudentActivity = 'seiue.reflection_profile.student_activity',
  SamsAbsenceActivity = 'seiue.sams.absence.activity',
  AttendanceTeacherDailyNotice = 'seiue.attendance.teacher_daily_notice',
  MentorRole = 'seiue.mentor_role',

  // extended values below 下方的值是自定义扩展的
  LeaveFlowApproval = 'seiue.leave_flow_approval',
  LeaveFlowSubmitCertificate = 'seiue.leave_flow_submit_certificate',
  LeaveFlowVerification = 'seiue.leave_flow_verification',
  GoalNoRecordRemind = 'seiue.goal.no_record_remind',
  MentorTask = 'seiue.mentor_task',
  TalkInviteTodo = 'seiue.talk_invite_todo',
  TalkSubmitTodo = 'seiue.talk_submit_todo',
  TalkActivity = 'seiue.talk_activity',
  Mentor = 'seiue.mentor',
  TalkDiscussion = 'seiue.talk_discussion',
}

// 无实际作用, 只用于触发类型检查以确保新枚举覆盖了原枚举的所有值。
// 当原枚举增加新值时，编译器会报错，此时从 EnhancerEnumOriginal 复制值到 EnhancerEnum 即可。
export const typeTest: { [key: string]: EnhancerEnum } = EnhancerEnumOriginal

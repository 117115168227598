export interface SettingsInterface {
  /**
   * 停宿学生是否需要考勤
   */
  needAttendanceForStoppedStudent?: boolean
}

export class Settings implements SettingsInterface {
  /**
   * 停宿学生是否需要考勤
   */
  needAttendanceForStoppedStudent?: boolean

  constructor(props: SettingsInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      needAttendanceForStoppedStudent: _needAttendanceForStoppedStudent,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.needAttendanceForStoppedStudent = _needAttendanceForStoppedStudent
  }

  static propKeys = ['needAttendanceForStoppedStudent']
}

/**
 * Md5 加密字符串
 *
 * @param str - 参数
 * @returns encrypted str
 */
export const strEncodeMd5 = async (str: string) => {
  const CryptoJS = await import('crypto-js')
  const encryptedStr = CryptoJS.MD5(str).toString()

  return encryptedStr
}

/**
 * @file 用于将子路由 (route.subRoutes) 渲染为页签, 同子路由一样可嵌套
 */

import { Tabs, TabsType } from '@seiue/ui'
import { Result } from 'antd'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import {
  match as MatchProp,
  matchPath,
  useHistory,
  useParams,
} from 'react-router-dom'
import styled, { css } from 'styled-components'

import { $t } from 'packages/locale'
import { RouteNode, useRouteAuthorization } from 'packages/route'
import { NestedRoute } from 'packages/route/components/NestedRoute'

/**
 * 记忆上一次渲染 RouteTabs 时的 pathname,
 * 如果这一次也通过父路由 (未指定具体标签) 访问同样的 tab 组,
 * 则沿用上一次的 active tab, 而非 fallback 至第一个 tab.
 *
 * 需求场景举例:
 * 老师在侧边栏中切换课程班时, 每个课程班会继承上一个课程班的 active tab,
 * 以方便老师对比同一类数据 (比如考勤统计) 或进行同一类操作 (比如录入成绩).
 */
let lastPathname = ''

export type RouteTabsProps = {
  /**
   * 路由信息
   */
  routes?: RouteNode[]
  /**
   * 不传则不 filter，传则只显示按 index 对应 true 的 route
   */
  routesFilter?: boolean[]
  /**
   * 是否进行路由替换
   */
  replace?: boolean
  /**
   * react-router 的 match 对象
   */
  match?: MatchProp
  /**
   * 内容 block 的样式
   */
  contentStyles?: React.CSSProperties
  /**
   * tabBar 的样式
   */
  tabBarStyle?: React.CSSProperties
  /**
   * container 样式
   */
  style?: React.CSSProperties
  /**
   * container 的 className
   */
  className?: string
  /**
   * tabs 的样式
   */
  tabsStyle?: React.CSSProperties
  /**
   * tabs 的类型，主要是一些样式上的差别
   */
  tabsType?: TabsType
  /**
   * 是否隐藏 TabBar 底部 border
   */
  hideTabBarBottomBorder?: boolean
  /**
   * 变化的回调
   */
  onChange?: (activeKey: string) => void
  /**
   * active 路由变化回调，可获取到 active 路由信息
   */
  onActiveRouteChange?: (route: RouteNode) => void
  /**
   * 替换 tab bar，用于二次封装标签头
   */
  tabRender?: (child: RouteNode, index: number) => React.ReactNode
  /**
   * tab bar 上额外的元素
   */
  tabBarExtraContent?:
    | React.ReactNode
    | { left?: React.ReactNode; right?: React.ReactNode }
} & {
  // 支持额外的、透传给子路由的自由参数
  [key: string]: any
}

/**
 * 用于将子路由 (route.subRoutes) 渲染为页签, 同子路由一样可嵌套
 *
 * @param props - RouteTabsProps

 * @returns React.ReactElement
 */
export const RouteTabs: React.FC<RouteTabsProps> = props => {
  const {
    routes = [],
    routesFilter,
    replace = true,
    contentStyles,
    tabBarStyle,
    style,
    className,
    tabsStyle,
    tabsType = 'card',
    hideTabBarBottomBorder,
    onChange: outerOnChange,
    onActiveRouteChange,
    tabRender,
    tabBarExtraContent,
    // 额外自由参数，透传给子路由
    ...params
  } = props

  const history = useHistory()
  const { pathname } = history.location
  const routeParams = useParams<any>()

  // 判断是否有权限访问路由的函数
  const authRoute = useRouteAuthorization()

  // 用 routesFilter 和 authRoute 过滤
  const finalRoutes = useMemo(
    () =>
      routes
        .filter((rt, idx) => !routesFilter || routesFilter[idx])
        .filter(authRoute),
    [routes, authRoute, routesFilter],
  )

  // 将当前路由参数赋给子路由, 得到真实路径
  const tabRoutes = finalRoutes.map(route => ({
    route,
    realPath: Object.keys(routeParams).reduce(
      (res, key) => res.replace(`:${key}`, routeParams[key]),
      route.path,
    ),
  }))

  /**
   * 根据当前 url 计算 active tab
   */
  const activeTabRoute = tabRoutes.find(
    ({ route: { path } }) => !!matchPath(pathname, path),
  )

  /**
   * 根据 lastPathname 计算若 activeTabRoute 未命中时的备用 active tab.
   *
   * 当 activeTabRoute 未命中时, 说明访问的是父路由, 未指定子路由,
   * 则尝试利用 lastPathname fallback 到上一次的 active tab.
   *
   * 若 lastPathname 未命中, 说明本次与上次访问的不是同一套 tab,
   * 则 fallback 到第一个 tab, 并更新已失效的 lastPathname
   */
  const fallbackTabRoute =
    tabRoutes.find(({ route: { path } }) => !!matchPath(lastPathname, path)) ??
    tabRoutes[0]

  /**
   * 当最终的 active tab 不 match lastPathname 时,
   * 说明用户已跳转到其他页签, 则更新 lastPathname
   */
  if (
    (activeTabRoute ?? fallbackTabRoute) &&
    !matchPath(lastPathname, (activeTabRoute ?? fallbackTabRoute).route.path)
  ) {
    lastPathname = pathname
  }

  React.useEffect(() => {
    if (!activeTabRoute || !onActiveRouteChange) return
    onActiveRouteChange(activeTabRoute.route)
  }, [activeTabRoute, onActiveRouteChange])

  const onChange = replace ? history.replace : history.push

  const _className = classNames(['seiue-route-tabs', className])

  return (
    <TabsContainer tabsType={tabsType} className={_className} style={style}>
      {finalRoutes.length ? (
        <>
          <Tabs
            tabBarStyle={{
              ...tabBarStyle,
              position: 'relative',
            }}
            hideTabBarBottomBorder={hideTabBarBottomBorder}
            type={tabsType}
            activeKey={activeTabRoute?.realPath}
            style={tabsStyle}
            onChange={outerOnChange || onChange}
            tabBarExtraContent={tabBarExtraContent}
          >
            {finalRoutes.map((child, index) => (
              <Tabs.TabPane
                key={tabRoutes[index].realPath}
                tab={tabRender?.(child, index) || child.getTitle()}
              />
            ))}
          </Tabs>
          <TabContent
            tabsType={tabsType}
            style={contentStyles}
            data-test-id={`${activeTabRoute?.route.getTitle()}页签`}
          >
            <NestedRoute
              routes={finalRoutes}
              redirect={{
                to: fallbackTabRoute.realPath,
              }}
              {...params}
            />
          </TabContent>
        </>
      ) : (
        <Result status={404} title="404" subTitle={$t('暂无内容')} />
      )}
    </TabsContainer>
  )
}

const TabsContainer = styled.div<{ tabsType: TabsType }>`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  ${props =>
    props.tabsType === 'card' &&
    css`
      background-color: #ffffff;
      border-radius: 6px;
    `};

  .se-tabs_hide-bottom-border {
    .ant-tabs-nav::before {
      border-bottom: none;
    }
  }

  // tab 标签不换行
  .ant-tabs-tab > div {
    white-space: nowrap;
  }
`

const TabContent = styled.div<{ tabsType: TabsType }>`
  ${props =>
    props.tabsType === 'card' &&
    css`
      margin-top: -40px;
      padding: 8px 24px 24px 24px;
    `};
`

import { AppLayout } from '@seiue/ui'
import { compact, get, isArray, noop } from '@seiue/util'
import { useAtom } from 'jotai'
import React, { useEffect, useRef, useState } from 'react'
import { useIsFetching } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ErrorBoundary } from 'packages/components/ErrorBoundary'
import { RouteCrumb } from 'packages/components/Page/Breadcrumbs'
import {
  currentMenuTypesAtom,
  useOpenMenuFunction,
} from 'packages/feature-utils/menus'

import { LayoutContext } from './context'
import { MenuType, type MenuItemUnion } from './types'

export interface LayoutProps {
  /**
   * Header 左侧 slot
   */
  HeaderLeft?: React.ComponentType<any> | React.ReactElement | null
  /**
   * Header 右侧 slot
   */
  HeaderRight?: React.ComponentType<any> | React.ReactElement | null
  /**
   * 侧边栏菜单标题
   */
  siderMenuTitle?: string
  /**
   * 侧边栏菜单定义, 如果是 undefined 则不显示侧边栏
   */
  menus?: MenuItemUnion[] | MenuItemUnion
  /**
   * 侧边栏底部 slot
   */
  siderFooter?: React.ReactNode
  /**
   * 当前应激活的菜单所对应的 path
   */
  activeMenuPath: string
  /**
   * 顶部导航背景色
   */
  navbarBackgroundColor?: string
  /**
   * 侧边栏背景色
   */
  sliderBackgroundColor?: string
  /**
   * logoSlot, 不传则默认展示希悦 logo
   */
  logoSlot?: React.ReactNode
  /**
   * 是否展示「展开/收起」触发器，默认 true
   */
  showCollapsedTrigger?: boolean
  /**
   * 自定义面包屑
   */
  formatBreadcrumbs?: (breadcrumbs: RouteCrumb[]) => RouteCrumb[]
  /**
   * 当前显示菜单类型发生了变更
   */
  onDisplayMenuTypesChanged?: (types: MenuType[]) => void
}

/**
 * chalk & apollo 共同使用的 layout
 *
 * @param props - props
 * @returns component
 */
export const WebLayout: React.FC<LayoutProps> = props => {
  const {
    HeaderLeft,
    HeaderRight,
    siderMenuTitle,
    menus,
    activeMenuPath,
    children,
    siderFooter,
    navbarBackgroundColor,
    sliderBackgroundColor = '#3B404B',
    logoSlot,
    showCollapsedTrigger = true,
    formatBreadcrumbs,
  } = props

  const history = useHistory()

  const [isCollapsed, setIsCollapsed] = useState(false)
  const isAdminMode = history.location.pathname.startsWith('/admin/')

  const [openKeys, setOpenKeys] = React.useState<string[]>([])

  const contentContainerRef = useRef(null)

  const onRouteChangeRef = useRef(noop)

  useEffect(() => {
    onRouteChangeRef.current()
  }, [menus])

  const openMenu = useOpenMenuFunction()
  const [, updateCurrentMenuTypes] = useAtom(currentMenuTypesAtom)

  useEffect(() => {
    if (isArray(menus)) {
      for (const menu of menus) {
        openMenu([...(menu.prefix || []), menu.name || ''])
      }

      updateCurrentMenuTypes(compact(menus.map(menu => menu.type)))
    } else {
      openMenu([...(menus?.prefix || []), menus?.name || ''])
      updateCurrentMenuTypes([menus?.type || MenuType.Default])
    }

    // eslint-disable-next-line
  }, [openKeys, isAdminMode])

  const finalMenus = React.useMemo(
    // 如果 menus = undefined 则不显示侧边栏
    () => (!menus || isArray(menus) ? menus : get(menus, 'subMenus') || []),
    [menus],
  )

  return (
    <LayoutContext.Provider
      value={{
        collapsed: isCollapsed,
        contentContainerEl: contentContainerRef.current,
        activeMenuPath,
        setIsCollapsed,
        formatBreadcrumbs,
      }}
    >
      <AppLayout
        menus={finalMenus}
        activeMenuPath={activeMenuPath}
        logoSlot={logoSlot}
        isCollapsed={isCollapsed}
        showCollapsedTrigger={showCollapsedTrigger}
        onIsCollapsedChange={setIsCollapsed}
        siderMenuTitle={siderMenuTitle}
        siderFooter={siderFooter}
        headerLeft={HeaderLeft}
        headerRight={HeaderRight}
        onOpenKeysChanged={setOpenKeys}
        navbarBackgroundColor={navbarBackgroundColor}
        sliderBackgroundColor={sliderBackgroundColor}
        showLoadingBar={!!useIsFetching()}
        headerClassName="se-web-layout__header"
        siderClassName="se-web-layout__sider"
      >
        <ContentContainer
          className="se-web-layout__container"
          ref={contentContainerRef}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </ContentContainer>
      </AppLayout>
    </LayoutContext.Provider>
  )
}

const ContentContainer = styled.div`
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
`

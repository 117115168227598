/**
 * @file  <a /> for external urls, <Link /> for internal links
 */

import { openWindowSafe } from '@seiue/util'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { BeforeType } from './types'

interface AnyLinkProps {
  /**
   * 跳转地址
   */
  to: string
  /**
   * Hook，在跳转前调用。返回 false 阻止跳转，返回 string 则跳转到该 string 所指的 url
   * 注意，右键打开新窗口始终打开的是 to 指定的 url
   */
  before?: BeforeType
  /**
   * className
   */
  className?: string
  /**
   * style
   */
  style?: React.CSSProperties
}

/**
 * 用于处理内部链接和外部链接的组件
 *
 * @param props - AnyLinkProps
 * @returns React.ReactNode
 */
export const AnyLink: React.FC<AnyLinkProps> = props => {
  const { to, before, className, style, children } = props
  const history = useHistory()
  const isAbsolutePath = to?.startsWith('http')

  const getOnClickFn =
    (navigator: (url: string) => void) =>
    async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault()
      const result = await before?.()
      if (result) {
        navigator(typeof result === 'string' ? result : to)
      }
    }

  if (before) {
    return isAbsolutePath ? (
      <a
        href={to}
        className={className}
        style={style}
        onClick={getOnClickFn(openWindowSafe)}
      >
        {children}
      </a>
    ) : (
      <Link
        to={to}
        className={className}
        style={style}
        onClick={getOnClickFn(history.push)}
      >
        {children}
      </Link>
    )
  }

  return isAbsolutePath ? (
    <a
      href={to}
      className={className}
      style={style}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : (
    <Link to={to} className={className} style={style}>
      {children}
    </Link>
  )
}

import { moment } from '@seiue/moment'
import { useTracking } from '@seiue/tracking'
import { userAgent } from '@seiue/ua'
import { isUndefined, useOnMount } from '@seiue/util'

import { useI18nName } from 'packages/feature-utils/reflections'
import { $t } from 'packages/locale'
import {
  Notification,
  NotificationStatusEnum,
  NotificationSendingMethodEnum,
  NotificationSignEnum,
  Message,
  NotificationReceiver,
} from 'packages/sdks-next/chalk'

export * from './variable-utils'

/**
 * 获取通知状态标签
 *
 * @param status - 通知状态
 * @returns 通知状态标签
 */
export const getNoticeStatusLabel = (status: NotificationStatusEnum) =>
  ({
    [NotificationStatusEnum.Draft]: $t('待发送'),
    [NotificationStatusEnum.Published]: $t('已发送'),
    [NotificationStatusEnum.Withdrawn]: $t('已撤回'),
  }[status])

/**
 * 获取通知是否已读标签
 *
 * @param readed - 是否已读
 * @returns 是否已读标签
 */
export const getNoticeReadedLabel = (readed?: boolean) =>
  readed ? $t('已读') : $t('未读')

/**
 * 获取通知是否回执标签
 *
 * @param receipted - 是否回执
 * @returns 是否回执标签
 */
export const getNoticeReceiptedLabel = (receipted?: boolean | null) =>
  receipted ? $t('已回执') : $t('未回执')

/**
 * 获取通知状态选项
 *
 * @returns 通知状态选项
 */
export const getNoticeStatusOptions = () =>
  Object.values(NotificationStatusEnum).map(v => ({
    value: v,
    label: getNoticeStatusLabel(v),
  }))

/**
 * 获取通知发送时间
 *
 * @param notice - 通知
 * @returns 通知发送时间
 */
export const getNoticeSendAt = (
  notice: Pick<
    Notification,
    'status' | 'method' | 'createdAt' | 'scheduledTime' | 'publishedAt'
  >,
) => {
  // 如果后端算了 publishedAt,那么直接返回
  if (notice.publishedAt) return notice.publishedAt

  // 如果通知状态是草稿，且发送方式是暂不发布，那么没有发送时间
  if (
    notice.status === NotificationStatusEnum.Draft &&
    notice.method === NotificationSendingMethodEnum.Draft
  ) {
    return null
  }

  let sendAt: string | null | undefined = notice.createdAt

  // 如果设定了定时发送，那么定时发送时间就是通知的发送时间
  if (notice.method === NotificationSendingMethodEnum.Future) {
    sendAt = notice.scheduledTime
  }

  if (sendAt) {
    return moment(sendAt).format('YYYY-MM-DD HH:mm')
  }

  return null
}

/**
 * 获取通知落款
 *
 * @param notice - 通知
 * @param notice.signType - 落款类型
 * @param notice.sender - 发送者
 * @param notice.senderReflection - 发送者 Reflection，在没有 senderReflection 时，会使用 sender
 * @param notice.sign - 落款
 * @returns 通知落款
 */
export const useGetNoticeSign = (
  notice?: {
    signType?: string | null
    sender?: Notification['sender']
    senderReflection?: Message['senderReflection']
    sign?: string
  } | null,
) => {
  const $name = useI18nName()

  if (!notice) return ''

  switch (notice.signType) {
    case NotificationSignEnum.Position:
    case NotificationSignEnum.Department:
      return notice.sign
    default:
      return $name(notice.senderReflection) || notice.sender?.name
  }
}

export type NoticeDetailType = Pick<
  Notification,
  | 'title'
  | 'content'
  | 'sign'
  | 'signType'
  | 'createdAt'
  | 'status'
  | 'method'
  | 'scheduledTime'
  | 'attachment'
  | 'isImportant'
  | 'receiptedType'
  | 'cc'
  | 'publishedAt'
  | 'categoryId'
  | 'displayWatermark'
> & {
  rawContent?: string
  sender: Notification['sender'] | undefined
  variables?: NotificationReceiver['variables']
  id?: string
}

/**
 * 转化 message 为 notification
 *
 * @param message - message
 * @returns notification
 */
export const convertMessageToNotification = (
  message: Message,
): NoticeDetailType => ({
  id: message?.conversationId,
  title: message.title || '',
  content: message.content || '',
  // rawContent 为未替换过变量的原始内容
  rawContent: message.rawContent,
  createdAt: message.createdAt || '',
  sender: message.sender,
  status: NotificationStatusEnum.Published,
  method: NotificationSendingMethodEnum.Now,
  isImportant: !!message.isImportant,
  sign: message.sign,
  signType: message.signType as NotificationSignEnum,
  variables: message.variables,
  publishedAt: message.publishedAt || '',
  receiptedType: message.receiptedType,
  categoryId: message.categoryId,
  // Message 是通用类型，displayWatermark 是「通知」业务的字段
  displayWatermark: (message as any).displayWatermark,
})

/**
 * 旧的 notice message 没有 isCc 字段
 *
 * @param message - message
 * @returns 是否是旧的 notice message
 */
export const isLegacyNotice = (message: Pick<Message, 'isCc'>) =>
  isUndefined(message.isCc)

/**
 * 追踪用户从哪个浏览器/环境进的通知分享页
 */
export const useTrackShareVisits = () => {
  const { trackEvent } = useTracking()

  useOnMount(() => {
    trackEvent({
      category: 'notice',
      action: `share_visited_in_${userAgent.browser.name}`,
    })
  })
}

import { schema as normalizrSchema } from '@seiue/normalizr'
import { mapValues, isObject } from '@seiue/util'

import { ArgumentTypes } from 'packages/utils/types'

import {
  EntityEffects,
  QueryEffects,
  QueryEffect,
  EntityEffect,
  RemoveEffect,
  RemoveEffects,
  BatchRemoveEffectPayload,
  DecoratedEntityEffects,
  CacheBatchEffect,
  CacheBatchEffects,
  DecoratedCacheBatchEffects,
  BatchRemoveEffect,
  BatchRemoveEffectObjectPayload,
  BatchRemoveEffectArrayPayload,
} from './types'

export const createCacheDecorators = <TEntity>(
  schema: normalizrSchema.Entity<TEntity>,
  dispatch: any,

  // 根据新旧 sdk 来判断传输的数据结构
  useNextSdk = false,
) => {
  const queryDecorator =
    <TEffect extends QueryEffect<TEntity>>(
      effectKey: string,
      effectFunc: TEffect,
    ) =>
    async (
      payload?: ArgumentTypes<TEffect>[0] & { _cacheRes?: boolean },
      state?: ArgumentTypes<TEffect>[1],
    ) => {
      const { _cacheRes = true, ...effectPayload } =
        payload || ({} as ArgumentTypes<TEffect>[0] & { _cacheRes?: boolean })

      const { data, pagination } = await effectFunc(
        // 新 sdk 不再需要接收 state（所有的缓存对象）
        ...(useNextSdk
          ? [payload]
          : // 如果 payload 为数组则直接传，否则解构后类型就对不上了
            [Array.isArray(payload) ? payload : effectPayload, state]),
      )

      if (_cacheRes) {
        dispatch.entities.setQueried({
          data,
          pagination,
          fetchRemoteKey: `${schema.key}.${effectKey}`,
          query: effectPayload,
          schema,
        })
      }

      return { data, pagination }
    }

  const $cacheQuery = <TEffects extends QueryEffects<TEntity>>(
    effects: TEffects,
  ) =>
    // @ts-ignore
    mapValues(effects, (val, key) => queryDecorator(key, val) as const)

  const entityDecorator =
    <TPayload, TState>(effectFunc: EntityEffect<TEntity>) =>
    async (payload?: TPayload, state?: TState) => {
      const { data } = await effectFunc(
        ...(useNextSdk ? [payload] : [payload, state]),
      )

      dispatch.entities.set({ data, schema })
      return data
    }

  const $cacheEntity = <TEffects extends EntityEffects<TEntity>>(
    effects: TEffects,
  ): DecoratedEntityEffects<TEntity, TEffects> =>
    // @ts-ignore
    mapValues(effects, val => entityDecorator(val))

  const cacheBatchDecorator =
    <TPayload, TState>(effectFunc: CacheBatchEffect<TEntity>) =>
    async (payload?: TPayload, state?: TState) => {
      const { data } = await effectFunc(
        ...(useNextSdk ? [payload] : [payload, state]),
      )

      dispatch.entities.set({ data, schema: [schema] })
      return data
    }

  const $cacheBatch = <TEffects extends CacheBatchEffects<TEntity>>(
    effects: TEffects,
  ): DecoratedCacheBatchEffects<TEntity, TEffects> =>
    // @ts-ignore
    mapValues(effects, val => cacheBatchDecorator(val))

  const uncacheDecorator =
    <TState>(effectFunc: RemoveEffect) =>
    async (payload: any, state?: TState) => {
      await effectFunc(...(useNextSdk ? [payload] : [payload, state]))
      dispatch.entities.remove({ id: payload.id ?? payload, schema })
      return true
    }

  const $uncacheEntity = <TEffects extends RemoveEffects>(
    effects: TEffects,
  ): TEffects =>
    // @ts-ignore
    mapValues(effects, val => uncacheDecorator(val))

  const uncacheBatchDecorator =
    <TState>(effectFunc: BatchRemoveEffect) =>
    async (payload: BatchRemoveEffectPayload, state?: TState) => {
      await effectFunc(
        ...(useNextSdk ? [payload as any, undefined] : [payload as any, state]),
      )

      const ids = Array.isArray(payload)
        ? (payload as BatchRemoveEffectArrayPayload).map(item =>
            isObject(item) ? item.id : item,
          )
        : (payload as BatchRemoveEffectObjectPayload).ids

      dispatch.entities.removeMany({ ids, schema })
      return true
    }

  const $uncacheBatch = <TEffects extends RemoveEffects>(
    effects: TEffects,
  ): TEffects =>
    // @ts-ignore
    mapValues(effects, val => uncacheBatchDecorator(val))

  return {
    $cacheQuery,
    $cacheEntity,
    $cacheBatch,
    $uncacheEntity,
    $uncacheBatch,
  }
}

import { Outline, OutlineInterface } from './outline'

export interface HandoutOutlineRelationInterface {
  /**
   * 学案 id
   */
  handoutId: number
  /**
   * 学案目录 id
   */
  outlineId: number
  itemId: number
  outline?: OutlineInterface | null
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class HandoutOutlineRelation implements HandoutOutlineRelationInterface {
  /**
   * 学案 id
   */
  handoutId: number
  /**
   * 学案目录 id
   */
  outlineId: number
  itemId: number
  outline?: Outline | null
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: HandoutOutlineRelationInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      handoutId: _handoutId,
      outlineId: _outlineId,
      itemId: _itemId,
      outline: _outline,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.handoutId = _handoutId
    this.outlineId = _outlineId
    this.itemId = _itemId
    this.outline = _outline ? new Outline(_outline) : _outline
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'handoutId',
    'outlineId',
    'itemId',
    'outline',
    'createdAt',
    'updatedAt',
  ]
}

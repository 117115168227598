/**
 * @file 颜色变量 web 版本
 */

export {
  BrandColor,
  BackgroundColor,
  BorderColor,
  DividerColor,
  Palette,
  UIColor,
  StatusColor,
} from './colors.native'

/**
 * 字体颜色
 */
export const TextColor = {
  /**
   * 白色
   */
  _0: '#fff',
  /**
   * 与主色对应的文字色
   */
  Brand: '#447ae6',
  /**
   * 标题, 主要正文
   */
  _1: 'rgba(6, 12, 25, 0.85)',
  /**
   * 次要正文, 文字按钮
   */
  _2: 'rgba(6, 12, 25, 0.65)',
  /**
   * 页面内的注释信息
   */
  _3: 'rgba(6, 12, 25, 0.45)',
  /**
   * 控件内的占位信息，禁用状态控件的文字
   */
  _4: 'rgba(6, 12, 25, 0.25)',
} as const

import { Attachment, AttachmentInterface } from './attachment'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'

export interface DiscussionMessageInterface {
  /**
   * 讨论区Id
   */
  discussionId: number
  /**
   * 讨论区Id
   */
  topicId: number
  /**
   * 针对那一条的评论的评论，0代表这是一个一级评论
   */
  parentId: number
  /**
   * 指出那一个是该评论的一级评论
   */
  baseParentId: number
  /**
   * 公开或私有讨论 public, private:id1-id2
   */
  key?: string
  /**
   * 公开时间（当私有转成公有时有值）
   */
  publicAt?: string | null
  /**
   * 消息内容
   */
  content?: string
  /**
   * 管理员是否已读
   */
  managerReaded: boolean
  /**
   * 附件
   */
  attachments?: AttachmentInterface[]
  /**
   * 信息发送者Id
   */
  senderId: number
  /**
   * 信息接受者Id
   */
  receiverId: number
  archivedAt?: string | null
  sender?: SecuredReflectionInterface | null
  receiver?: SecuredReflectionInterface | null
  replyCount?: number
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class DiscussionMessage implements DiscussionMessageInterface {
  /**
   * 讨论区Id
   */
  discussionId: number
  /**
   * 讨论区Id
   */
  topicId: number
  /**
   * 针对那一条的评论的评论，0代表这是一个一级评论
   */
  parentId: number
  /**
   * 指出那一个是该评论的一级评论
   */
  baseParentId: number
  /**
   * 公开或私有讨论 public, private:id1-id2
   */
  key?: string
  /**
   * 公开时间（当私有转成公有时有值）
   */
  publicAt?: string | null
  /**
   * 消息内容
   */
  content?: string
  /**
   * 管理员是否已读
   */
  managerReaded: boolean
  /**
   * 附件
   */
  attachments?: Attachment[]
  /**
   * 信息发送者Id
   */
  senderId: number
  /**
   * 信息接受者Id
   */
  receiverId: number
  archivedAt?: string | null
  sender?: SecuredReflection | null
  receiver?: SecuredReflection | null
  replyCount?: number
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: DiscussionMessageInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      discussionId: _discussionId,
      topicId: _topicId,
      parentId: _parentId,
      baseParentId: _baseParentId,
      key: _key,
      publicAt: _publicAt,
      content: _content,
      managerReaded: _managerReaded,
      attachments: _attachments,
      senderId: _senderId,
      receiverId: _receiverId,
      archivedAt: _archivedAt,
      sender: _sender,
      receiver: _receiver,
      replyCount: _replyCount,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.discussionId = _discussionId
    this.topicId = _topicId
    this.parentId = _parentId
    this.baseParentId = _baseParentId
    this.key = _key
    this.publicAt = _publicAt
    this.content = _content
    this.managerReaded = _managerReaded
    this.attachments = _attachments
      ? _attachments.map(i => new Attachment(i))
      : _attachments
    this.senderId = _senderId
    this.receiverId = _receiverId
    this.archivedAt = _archivedAt
    this.sender = _sender ? new SecuredReflection(_sender) : _sender
    this.receiver = _receiver ? new SecuredReflection(_receiver) : _receiver
    this.replyCount = _replyCount
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'discussionId',
    'topicId',
    'parentId',
    'baseParentId',
    'key',
    'publicAt',
    'content',
    'managerReaded',
    'attachments',
    'senderId',
    'receiverId',
    'archivedAt',
    'sender',
    'receiver',
    'replyCount',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

import { seiueFiles } from '@seiue/file'
import { camelCase } from '@seiue/util'

import {
  colorToIncentiveBadges,
  colorToPunishmentBadges,
} from 'packages/feature-utils/certifications/utils/badge-map'
import { getI18nName } from 'packages/feature-utils/reflections'
import { $t } from 'packages/locale'
import { isEnLocale } from 'packages/locale/utils'
import {
  EnabledSchoolPlugin,
  SchoolPlugin,
  SecuredReflection,
} from 'packages/sdks-next/chalk'
import { Certification } from 'packages/sdks-next/sgms'

import { EnabledPlugin } from '../../plugins'
import {
  CertificationColorName,
  CertificationPluginDisplayEnum,
  CertificationPluginKind,
  CertificationPluginSettings,
} from '../types'

/**
 * 获取认证子插件的认证方式（线上虚拟/实体/处分/电子证书）
 *
 * @param plugin - 认证子插件
 * @returns 认证方式
 */
export const getCertificationPluginKind = (
  plugin: SchoolPlugin | EnabledPlugin | EnabledSchoolPlugin,
) => {
  return (plugin.settings as CertificationPluginSettings).kind
}

/**
 * 是否为「电子证书」认证子插件
 *
 * @param plugin - 认证子插件
 * @returns 认证方式
 */
export const isElecCertPlugin = (
  plugin: SchoolPlugin | EnabledPlugin | EnabledSchoolPlugin,
) => {
  return (
    getCertificationPluginKind(plugin) ===
    CertificationPluginKind.ElecCertifacate
  )
}

/**
 * 是否为「处分」认证子插件
 *
 * @param plugin - 认证子插件
 * @returns 是否
 */
export const isPunishmentCertPlugin = (
  plugin: SchoolPlugin | EnabledPlugin,
) => {
  return (
    getCertificationPluginKind(plugin) ===
    CertificationPluginKind.OnlinePunishment
  )
}

/**
 * 是否为「线上虚拟」认证子插件
 *
 * @param plugin - 认证子插件
 * @returns 是否
 */
export const isOnlineIncentiveCertPlugin = (
  plugin: SchoolPlugin | EnabledPlugin,
) => {
  return (
    getCertificationPluginKind(plugin) ===
    CertificationPluginKind.OnlineIncentive
  )
}

/**
 * 是否为「线下实体」认证子插件
 *
 * @param plugin - 认证子插件
 * @returns 是否
 */
export const isOfflineIncentiveCertPlugin = (
  plugin: SchoolPlugin | EnabledPlugin,
) => {
  return (
    getCertificationPluginKind(plugin) ===
    CertificationPluginKind.OfflineIncentive
  )
}

/**
 * 是否为激励（线上虚拟/线下实体）认证子插件
 *
 * @param plugin - 认证子插件
 * @returns 是否
 */
export const isIncentiveCertPlugin = (plugin: SchoolPlugin | EnabledPlugin) => {
  const kind = getCertificationPluginKind(plugin)

  return [
    CertificationPluginKind.OfflineIncentive,
    CertificationPluginKind.OnlineIncentive,
  ].includes(kind)
}

/**
 * 获取认证子插件的内置徽章列表
 *
 * @param plugin - 认证子插件
 * @param colorName - 色系名称
 * @returns 内置徽章列表
 */
export const getCertificationBadges = (
  plugin: EnabledPlugin,
  colorName: CertificationColorName,
) => {
  // 处分认证的内置徽章
  if (isPunishmentCertPlugin(plugin)) {
    return colorToPunishmentBadges[colorName].listOptions('name', 'image')
  }

  return colorToIncentiveBadges[colorName].listOptions('name', 'image')
}

/**
 * 获取认证的徽章图标地址
 *
 * @param badge - 原始徽章格式，如 red-badge-2+red
 * @returns 徽章图片
 */
export const getCertificationBadgeImage = (badge?: string) => {
  if (!badge) return ''

  const [image, colorName] = badge.split('+') as [
    string,
    CertificationColorName | undefined,
  ]

  // 内置徽章，将徽章的映射值转为图片的绝对地址
  if (colorName) {
    const camelCased = camelCase(image)

    return image.toLowerCase().includes('punishment')
      ? // @ts-expect-error 动态字段
        colorToPunishmentBadges[colorName].get(camelCased)
      : // @ts-expect-error 动态字段
        colorToIncentiveBadges[colorName].get(camelCased)
  }

  // 自定义上传徽章
  return seiueFiles.hashToUrl(image)
}

/**
 * 是否显示认证的整体情况扇面图
 *
 * @param plugin - 认证子插件
 * @returns 是否
 */
export const shouldShowCertificationPolarChart = (
  plugin: SchoolPlugin | EnabledPlugin,
) => {
  return !!(
    plugin.settings as CertificationPluginSettings | undefined
  )?.display?.includes(CertificationPluginDisplayEnum.PolarChart)
}

/**
 * 获取授予认证的抄送人提示文本
 *
 * @param args - args
 * @param args.certifications - 认证列表
 * @param args.students - 学生列表
 * @param args.isPunishment - 是否为「处分」认证
 * @param args.isAdminClassTeacher - 是否为班主任
 * @returns 抄送人提示文本
 */
export const getGrantCertsCcText = ({
  certifications,
  students,
  isAdminClassTeacher,
}: {
  certifications: Certification[]
  students: SecuredReflection[]
  isPunishment: boolean
  isAdminClassTeacher?: boolean
}) => {
  if (!certifications.length || !students.length) return ''

  const isEn = isEnLocale()

  const maxCount = 3
  const isOverMaxStudentCount = students.length > maxCount
  const isOverMaxCertCount = certifications.length > maxCount

  const certCount = certifications.length
  const certNames = certifications
    .slice(0, maxCount)
    .map(cert => `「${cert.name}」`)
    .join('、')

  const studentCount = students.length
  const studentNames = `${students
    .slice(0, maxCount)
    .map(student => getI18nName({ reflection: student }, isEn))
    .join('、')}`

  const [certification] = certifications
  if (
    certifications.length === 1 &&
    certification.ccRoles?.length &&
    !isAdminClassTeacher
  ) {
    return isOverMaxStudentCount
      ? $t(
          '正在将{certNames}授予{studentNames}等 {studentCount} 人，此消息将抄送{ccRoles}',
          {
            certNames,
            studentNames,
            studentCount,
            ccRoles: certification.ccRolesLabel,
          },
        )
      : $t('正在将{certNames}授予{studentNames}，此消息将抄送{ccRoles}', {
          certNames,
          studentNames,
          ccRoles: certification.ccRolesLabel,
        })
  }

  if (isOverMaxCertCount && isOverMaxStudentCount) {
    return $t(
      '正在将{certNames}等 {certCount} 个徽章授予{studentNames}等 {studentCount} 人',
      {
        certNames,
        certCount,
        studentNames,
        studentCount,
      },
    )
  }

  if (isOverMaxCertCount && !isOverMaxStudentCount) {
    return $t('正在将{certNames}等 {certCount} 个徽章授予{studentNames}', {
      certNames,
      certCount,
      studentNames,
    })
  }

  if (isOverMaxStudentCount) {
    return $t('正在将{certNames}授予{studentNames}等 {studentCount} 人', {
      certNames,
      studentNames,
      studentCount,
    })
  }

  return $t('正在将{certNames}授予{studentNames}', {
    certNames,
    studentNames,
  })
}

import {
  getPureAxios,
  AxiosResponseOriginal as AxiosResponse,
  AxiosError,
} from '@seiue/axios'
import { env } from '@seiue/env'
import { stringifyURLQuery } from '@seiue/util'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  AuthTicket,
  OAuthToken,
  oAuthApi$authorize,
  oAuthApi$info,
} from 'packages/sdks-next/chalk'

import { AuthorizeParams } from './types'

/**
 * 腾讯智慧校园单点登录
 *
 * @param param0 - 参数
 * @param param0.code - code
 * @param param0.appid - appid
 * @returns token
 */
export async function authByTencentCode({
  code,
  appid,
}: {
  code: string
  appid: string
}) {
  let resData
  try {
    resData = (
      await getPureAxios().get(
        `${env('SERVER_PASSPORT')}/sso/tencent_campus/async-login`,
        {
          withCredentials: true,
          params: {
            appid,
            code,
            client_id: env('CLIENT_APP_CLIENT_ID'),
          },
        },
      )
    ).data
  } catch (err) {
    // 如果有 response data，在后面统一处理
    resData = (err as AxiosError)?.response?.data

    // 否则重新抛出
    if (!resData) throw err
  }

  if (!resData?.ok) {
    throw new Error(resData?.message || '')
  }

  return resData.token
}

/**
 * for password login in go
 *
 * @param user - 登录信息
 * @param user.username - 用户名
 * @param user.password - 密码
 * @returns 获取 Token 的 Promise
 */
export async function authByUsernameAndPassword(user: {
  username: string
  password: string
}): Promise<OAuthToken> {
  const { data: token } = await oAuthApi$authorize.api(
    {
      ...user,
      grantType: 'password',
      clientId: env('CLIENT_APP_CLIENT_ID'),
      clientSecret: env('CLIENT_APP_CLIENT_SECRET'),
    },
    {
      omitAuthorizationHeader: true,
      omitReflectionHeaders: true,
    },
  )

  return token
}

/**
 * for cellphone captcha login in go
 *
 * @param user - 登录信息
 * @param user.phone - 手机号
 * @param user.secret - 验证码
 * @param user.reminderId - 验证码 id
 * @returns 获取 Token 的 Promise
 */
export async function authByPhoneAndCaptcha(user: {
  phone: string
  secret: string
  reminderId: string
}): Promise<OAuthToken> {
  const { data: token } = await oAuthApi$authorize.api(
    {
      ...user,
      grantType: 'seiue:phone_code',
      clientId: env('CLIENT_APP_CLIENT_ID'),
      clientSecret: env('CLIENT_APP_CLIENT_SECRET'),
    },
    {
      omitAuthorizationHeader: true,
      omitReflectionHeaders: true,
    },
  )

  return token
}

/**
 * 根据授权 Ticket 获取 access token
 *
 * @param authTicket - 授权令牌
 * @returns access token
 */
export const authByTicket = async (authTicket?: AuthTicket) => {
  if (!authTicket) return null

  const { data: token } = await oAuthApi$authorize.api(
    {
      grantType: 'seiue:auth_ticket',
      username: authTicket.ticket,
      password: '',
      clientId: env('CLIENT_APP_CLIENT_ID'),
      clientSecret: env('CLIENT_APP_CLIENT_SECRET'),
    },
    {
      omitAuthorizationHeader: true,
      omitReflectionHeaders: true,
    },
  )

  return token
}

/**
 * 根据 Token 获取用户信息
 *
 * @param token - token
 * @returns 用户信息
 */
export async function getUserByToken(token: OAuthToken) {
  const { data: user } = await oAuthApi$info.api(
    {},
    {
      // @ts-expect-error axios 类型错误
      headers: {
        Authorization: `${token.tokenType} ${token.accessToken}`,
      },
      omitAuthorizationHeader: true,
      omitReflectionHeaders: true,
    },
  )

  return user
}

/**
 * 通过"passport 登录"之外的方式进行登录验证并返回 access token,
 * 包括密码, 验证码, 腾讯校园验证码, 和直接从 url 中提取 token.
 *
 * @param params - 不同方式所需的参数
 * @returns OAuthToken
 */
export function authorize(params: AuthorizeParams): Promise<OAuthToken | null> {
  switch (params.type) {
    // for example: app password login
    case 'password':
      return authByUsernameAndPassword({
        username: params.username,
        password: params.password,
      })
    // for example: app phone & captcha login
    case 'captcha':
      return authByPhoneAndCaptcha({
        phone: params.phone,
        secret: params.secret,
        reminderId: params.reminderId,
      })
    // for example: 深圳中学微信小程序
    case 'tencent':
      return authByTencentCode({
        code: params.code,
        appid: params.appid,
      })
    // for example: pass token via url query to visit chalk in go app webview
    case 'token':
      return Promise.resolve(params.oAuthToken)
    // for example: 小红花大屏扫码登录
    case 'ticket':
      return authByTicket(params.ticket)
    default:
      throw new Error('[authorize] not supported AuthorizeParams type')
  }
}

/**
 * 返回登出方法
 *
 * @returns 登出方法
 */
export const useLogout = () => {
  const dispatch = useDispatch<any>()
  return useCallback(() => dispatch.session.destroy(), [dispatch])
}

let tokenRequestPromise: Promise<AxiosResponse<OAuthToken>> | null = null

/**
 * 获取 Token
 *
 * @param refreshToken - 使用刷新 Token 的 key
 * @returns 新 token
 */
export function requestToken(
  refreshToken?: string,
): Promise<AxiosResponse<OAuthToken>> {
  tokenRequestPromise =
    tokenRequestPromise ||
    (refreshToken
      ? oAuthApi$authorize.api(
          {
            grantType: 'refresh_token',
            clientId: env('CLIENT_APP_CLIENT_ID'),
            clientSecret: env('CLIENT_APP_CLIENT_SECRET'),
            refreshToken,
          },
          {
            omitAuthorizationHeader: true,
          },
        )
      : getPureAxios().post(
          `${env('SERVER_PASSPORT')}/authorize`,
          stringifyURLQuery({
            response_type: 'token',
            client_id: env('CLIENT_WEB_CLIENT_ID'),
          }),
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        )
    ).finally(() => {
      tokenRequestPromise = null
    })

  return tokenRequestPromise
}

import { AttendanceGroupTypeEnum } from './attendance-group-type-enum'
import { Range, RangeInterface } from './range'
import { IotRule, IotRuleInterface } from './iot-rule'

export interface AttendancePlanInterface {
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 学期 ID
   */
  semesterId: number
  /**
   * 考勤组 ID
   */
  groupId: number
  /**
   * 考勤组类型
   */
  groupType: AttendanceGroupTypeEnum
  name: string
  /**
   * 星期
   */
  weekDays?: number[] | null
  /**
   * 时间范围
   */
  ranges?: RangeInterface[] | null
  /**
   * 是否物联网考勤
   */
  iot: boolean
  iotRules?: IotRuleInterface[]
  /**
   * 创建时间
   * 格式为 RFC3339 规则定义，UTC 2017-12-08T00:00:00.00Z CST 2017-12-08T00:08:00.00+08:00
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  /**
   * 创建人.
   */
  createdBy?: number | null
  /**
   * 更新人.
   */
  updatedBy?: number | null
  /**
   * 主键 ID
   */
  id: number
}

export class AttendancePlan implements AttendancePlanInterface {
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 学期 ID
   */
  semesterId: number
  /**
   * 考勤组 ID
   */
  groupId: number
  /**
   * 考勤组类型
   */
  groupType: AttendanceGroupTypeEnum
  name: string
  /**
   * 星期
   */
  weekDays?: number[] | null
  /**
   * 时间范围
   */
  ranges?: Range[] | null
  /**
   * 是否物联网考勤
   */
  iot: boolean
  iotRules?: IotRule[]
  /**
   * 创建时间
   * 格式为 RFC3339 规则定义，UTC 2017-12-08T00:00:00.00Z CST 2017-12-08T00:08:00.00+08:00
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  /**
   * 创建人.
   */
  createdBy?: number | null
  /**
   * 更新人.
   */
  updatedBy?: number | null
  /**
   * 主键 ID
   */
  id: number

  constructor(props: AttendancePlanInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      semesterId: _semesterId,
      groupId: _groupId,
      groupType: _groupType,
      name: _name,
      weekDays: _weekDays,
      ranges: _ranges,
      iot: _iot,
      iotRules: _iotRules,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      createdBy: _createdBy,
      updatedBy: _updatedBy,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.semesterId = _semesterId
    this.groupId = _groupId
    this.groupType = _groupType
    this.name = _name
    this.weekDays = _weekDays
    this.ranges = _ranges ? _ranges.map(i => new Range(i)) : _ranges
    this.iot = _iot
    this.iotRules = _iotRules ? _iotRules.map(i => new IotRule(i)) : _iotRules
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
    this.createdBy = _createdBy
    this.updatedBy = _updatedBy
    this.id = _id
  }

  static propKeys = [
    'schoolId',
    'semesterId',
    'groupId',
    'groupType',
    'name',
    'weekDays',
    'ranges',
    'iot',
    'iotRules',
    'createdAt',
    'updatedAt',
    'createdBy',
    'updatedBy',
    'id',
  ]
}

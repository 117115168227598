/**
 * @file 活动消息
 */

import { PluginEnum } from 'packages/feature-utils/plugins'
import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Event,
  getLabel: () => $t('活动'),
  labelStyle: calculateLabelStyle('#F7E9FA', '#9E37B4'),
  icon: `plugin.${PluginEnum.Event}`,
  messages: [
    'member_invited',
    'member_approved',
    'member_rejected',
    'member_removed',
    'topic_marked_excellent',
    'checkin',
    'discussion_replied',
    'discussion_commented',
    'approval_approved',
    'approval_rejected',
    'approval_expired',
    'approval_revoked',
    'approval_changed',
    'flow_cc',
    'urge_for_approver',
  ],
})

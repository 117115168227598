import { parseURLQuery } from '@seiue/util'
import jwtDecode from 'jwt-decode'

/**
 * 从 access token 中解析 rid, 即当前登录的 reflection id
 *
 * @param accessToken - access token
 * @returns access token 对应的 rid
 */
export const decodeRidFromAccessToken = (accessToken: string) => {
  const { ctx } = (jwtDecode(accessToken) as { ctx: string }) || {}

  if (ctx) {
    return Number(parseURLQuery(ctx)['login_rid'])
  }

  return null
}

/**
 * @file register plugins navigator
 */

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator(['classin.account_insufficient'], ({ attributes }) => {
  const pluginId = (attributes as any)?.pluginId
  if (!pluginId) return null
  return {
    modalName: 'ClassInPluginSetting',
    options: {
      pluginId,
    },
  }
})

registerMessageNavigator(
  ['classin.account_unbind', 'classin.unbind_notify_for_other'],
  () => ({
    path: '/users/current/account/settings',
  }),
)

registerMessageNavigator(['classin.account_unbind_admin'], () => ({
  path: '/admin/classes/normal',
}))

import { AssignmentStatusEnum } from './assignment-status-enum'

export interface TaskAssignmentInterface {
  assigneeId: number
  taskId: number
  /**
   * 任务执行人小组id
   */
  teamId?: number | null
  /**
   * 任务状态
   */
  status: AssignmentStatusEnum
  /**
   * 已读/未读
   */
  isRead?: boolean
  /**
   * 是否逾期
   */
  isOverdue?: boolean
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class TaskAssignment implements TaskAssignmentInterface {
  assigneeId: number
  taskId: number
  /**
   * 任务执行人小组id
   */
  teamId?: number | null
  /**
   * 任务状态
   */
  status: AssignmentStatusEnum
  /**
   * 已读/未读
   */
  isRead?: boolean
  /**
   * 是否逾期
   */
  isOverdue?: boolean
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: TaskAssignmentInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      assigneeId: _assigneeId,
      taskId: _taskId,
      teamId: _teamId,
      status: _status,
      isRead: _isRead,
      isOverdue: _isOverdue,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.assigneeId = _assigneeId
    this.taskId = _taskId
    this.teamId = _teamId
    this.status = _status
    this.isRead = _isRead
    this.isOverdue = _isOverdue
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'assigneeId',
    'taskId',
    'teamId',
    'status',
    'isRead',
    'isOverdue',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

export interface LessonDateScopeInterface {
  startAtEgt: string
  endAtElt: string
}

export class LessonDateScope implements LessonDateScopeInterface {
  startAtEgt: string
  endAtElt: string

  constructor(props: LessonDateScopeInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      startAtEgt: _startAtEgt,
      endAtElt: _endAtElt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.startAtEgt = _startAtEgt
    this.endAtElt = _endAtElt
  }

  static propKeys = ['startAtEgt', 'endAtElt']
}

/**
 * @file 注册约谈消息
 */

import { PluginEnum } from 'packages/feature-utils/plugins'
import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

import { NoticeTypes } from './message-type'

registerDomainMessage({
  type: MessageDomainEnum.Chat,
  getLabel: () => $t('约谈'),
  labelStyle: calculateLabelStyle('#EEECFF', '#6A4BBE'),
  icon: `plugin.${PluginEnum.Chat}`,
  messages: [
    `${MessageDomainEnum.Chat}.${NoticeTypes.Created}`,
    `${MessageDomainEnum.Chat}.${NoticeTypes.Changed}`,
    `${MessageDomainEnum.Chat}.${NoticeTypes.Apply}`,
    `${MessageDomainEnum.Chat}.${NoticeTypes.Canceled}`,
    `${MessageDomainEnum.Chat}.${NoticeTypes.CancelChatByGuardian}`,
    `${MessageDomainEnum.Chat}.${NoticeTypes.GuardianSubmitted}`,
  ],
})

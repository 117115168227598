export enum NodeManagerTypeEnum {
  Guardians = 'guardians',
  GroupAdmin = 'group_admin',
  Role = 'role',
  Custom = 'custom',
  SpecificReflections = 'specific_reflections',
  Department = 'department',
  DepartmentDirectors = 'department_directors',
  CustomByField = 'custom_by_field',
  PluginAdmin = 'plugin_admin',
  ParentPluginAdmin = 'parent_plugin_admin',
  AdjustmentRelatedTeachers = 'adjustment_related_teachers',
  ChatTeachers = 'chat_teachers',
  EventAdmin = 'event_admin',
  EventVenueAdmin = 'event_venue_admin',
  Initiator = 'initiator',
}

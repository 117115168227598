/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { MoralAssessmentTypeEnum } from './moral-assessment-type-enum'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import type { QueryEvaluatedForEvaluatorQuery } from './query-evaluated-for-evaluator-query'

const buildConfig = createConfigBuilder('vnas')

export const commonApi$queryEvaluatedForEvaluator = {
  config(
    operationType: MoralAssessmentTypeEnum,
    id: number,
    queryEvaluatedForEvaluatorQuery?: QueryEvaluatedForEvaluatorQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/common/evaluator/assessments/{id}/evaluated',
      {
        operationType,
        id,
      },
      queryEvaluatedForEvaluatorQuery,
    )
  },

  /**
   * @summary 通用德育评价（德育/教师考核）老师查询单个评价的被评价成员
   * （可以限制评价项，返回指定评价项管理的人员，否则为所有评价项被评价人的并集；可以限制群组 ID）
   * （学生家长自评时不可使用）
   * @param operationType
   * @param id
   * @param [queryEvaluatedForEvaluatorQuery]
   * @param [commonQuery.itemId] 某个评价项下老师可以看到的被批评人
   * 录入时要限制评价项
   * @param [commonQuery.groupType] 在某个群组下的被评人，指定群组类型
   * @param [commonQuery.adminclassId] 随手评只看某个行政班人员
   * @param [commonQuery.groupBizId] 在某个群组下的被评人，群组关联的业务的 ID，非群组 ID
   * @param [commonQuery.tagIn]
   * @param [commonQuery.tagIsEmpty]
   * @param [commonQuery.itemIdIn] 加减分标签筛选时需必传此参数
   * @param [commonQuery.groupId] 群组ID
   * @param [commonQuery.groupIdIn]
   * @param [commonQuery.sort] 支持字段 usin, name, account, grade.real_score, grade.score, grade.rank
   * 如果需要评价项分数排序，可传 item_1.score ，其中 1 表示评价项的 id
   * @param [commonQuery.expand] - 同 reflection，expand=grade
   * @param [commonQuery.paginated] 是否分页，默认 1
   * @param [commonQuery.page] 显示的数据页，默认 1
   * @param [commonQuery.perPage] 每页显示数量，默认 20
   * @param [commonQuery.account]
   * @param [commonQuery.usin]
   * @param [commonQuery.idcard]
   * @param [commonQuery.outerId]
   * @param [commonQuery.idIn]
   * @param [commonQuery.nameIn]
   * @param [commonQuery.nameLike]
   * @param [commonQuery.enameIn]
   * @param [commonQuery.enameLike]
   * @param [commonQuery.identity] name, usin, ename, pinyin 的模糊搜索
   * @param [commonQuery.accountLike]
   * @param [commonQuery.accountIn]
   * @param [commonQuery.usinLike]
   * @param [commonQuery.usinIn]
   * @param [commonQuery.genderIn]
   * @param [commonQuery.statusIn]
   * @param [commonQuery.archivedTypeIdIn]
   * @param [commonQuery.phoneIn]
   * @param [commonQuery.phoneLike]
   * @param [commonQuery.emailIn]
   * @param [commonQuery.emailLike]
   * @param [commonQuery.idcardIn]
   * @param [commonQuery.idcardLike]
   * @param [commonQuery.enteredOnElt]
   * @param [commonQuery.enteredOnEgt]
   * @param [commonQuery.workStartOnElt]
   * @param [commonQuery.workStartOnEgt]
   * @param [commonQuery.enteredYearsElt]
   * @param [commonQuery.enteredYearsEgt]
   * @param [commonQuery.workYearsElt]
   * @param [commonQuery.workYearsEgt]
   * @param [commonQuery.adminClassesContains]
   * @param [commonQuery.adminClassesOverlaps]
   * @param [commonQuery.adminClassesLike]
   * @param [commonQuery.dorm]
   * @param [commonQuery.dormLike]
   * @param [commonQuery.dormIn]
   * @param [commonQuery.dormAdminsContains]
   * @param [commonQuery.dormAdminsOverlaps]
   * @param [commonQuery.dormAdminsLike]
   * @param [commonQuery.role]
   * @param [commonQuery.roleIn]
   * @param [options]
   * @return AxiosResponsePromise<SecuredReflection[]>
   */

  api<const TQuery extends QueryEvaluatedForEvaluatorQuery>(
    operationType: MoralAssessmentTypeEnum,
    id: number,
    queryEvaluatedForEvaluatorQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<SecuredReflection, TQuery['expand']>[]> {
    return axios(
      commonApi$queryEvaluatedForEvaluator.config(
        operationType,
        id,
        queryEvaluatedForEvaluatorQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new SecuredReflection(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryEvaluatedForEvaluatorQuery,
    TSelected = Expand<SecuredReflection, TQuery['expand']>[],
  >(
    {
      operationType,
      id,
      query,
    }: {
      operationType: MoralAssessmentTypeEnum
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<SecuredReflection, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<
      Expand<SecuredReflection, TQuery['expand']>[],
      TSelected
    >(
      commonApi$queryEvaluatedForEvaluator.api,
      'Common.queryEvaluatedForEvaluator',
      queryOptions,
      operationType,
      id,
      query,
    )
  },
}

// @ts-ignore
commonApi$queryEvaluatedForEvaluator.api._name_ = `Common.queryEvaluatedForEvaluator`

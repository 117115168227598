import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'

export interface BaseStarInterface {
  ownerId: number
  owner?: SecuredReflectionInterface | null
  fileId: number
  /**
   * 冗余的文件名称，用于文件被彻底删除后（file = null），在收藏列表的显示
   */
  fileName: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class BaseStar implements BaseStarInterface {
  ownerId: number
  owner?: SecuredReflection | null
  fileId: number
  /**
   * 冗余的文件名称，用于文件被彻底删除后（file = null），在收藏列表的显示
   */
  fileName: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: BaseStarInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      ownerId: _ownerId,
      owner: _owner,
      fileId: _fileId,
      fileName: _fileName,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.ownerId = _ownerId
    this.owner = _owner ? new SecuredReflection(_owner) : _owner
    this.fileId = _fileId
    this.fileName = _fileName
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'ownerId',
    'owner',
    'fileId',
    'fileName',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

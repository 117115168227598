/**
 * @file 待办注册相关方法
 */
import { isArray } from '@seiue/util'

import { Todo } from 'packages/sdks-next/chalk'

import { RegisterTodos, TodoExecution, TodoTypeEnum } from './types'
import { getTodoType } from './utils'

// 待办处理器存储
const executions: {
  [type in TodoTypeEnum]?: TodoExecution
} = {}

/**
 * 注册 Todo 处理器
 * @param todos
 */
export const registerTodos: RegisterTodos = todos => {
  const newTodos = isArray(todos) ? todos : [todos]

  newTodos.forEach(todo => {
    executions[todo.type] = todo
  })
}

/**
 * 获取 todo 卡片的渲染器
 * @param todo
 */
export const getExecution = (todo: Todo) => executions[getTodoType(todo)]

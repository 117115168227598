/**
 * @file 本地化相关 hooks
 */

import { isProduction } from '@seiue/env'
import { reportToSentry } from '@seiue/sentry'
import { useContext } from 'react'
import { IntlContext } from 'react-intl'
import { useSelector } from 'react-redux'

import { LocaleTextLocaleEnum } from 'packages/sdks-next/chalk'

import { setMomentLocale } from './moment'
import { UsefulLocaleEnum } from './types'

/**
 * 本地化初始化已经完成
 *
 * @returns boolean
 */
export const useLocaleInited = () =>
  useSelector((state: any) => state.locale.inited)

/**
 * 获取正在使用的语言
 *
 * @returns UsefulLocaleEnum
 */
export const useLocale = () => {
  const intlContext = useContext(IntlContext)

  if (!intlContext) {
    if (isProduction()) {
      reportToSentry(
        new Error('不能在 LocaleProvider 之外使用 useLocale Hook'),
        {
          ExceptionType: 'LocaleFailure',
        },
      )

      return LocaleTextLocaleEnum.ZhCN
    }

    // eslint-disable-next-line
    throw new Error('不能在 LocaleProvider 之外使用 useLocale Hook')
  }

  return intlContext.locale as UsefulLocaleEnum
}

/**
 * 当前语言是不是英文
 *
 * @returns boolean
 */
export const useIsEnLocale = () => {
  const locale = useLocale()

  return locale === LocaleTextLocaleEnum.EnUS
}

/**
 * 根据语言环境配置 Moment Locale，插件使用
 */
export const useLocaleMoment = () => {
  const locale = useLocale()

  setMomentLocale(locale)
}

export interface LevelSettingRuleInterface {
  rankPercent: number
  scoreEgt: number
  scoreElt: number
  level: string
}

export class LevelSettingRule implements LevelSettingRuleInterface {
  rankPercent: number
  scoreEgt: number
  scoreElt: number
  level: string

  constructor(props: LevelSettingRuleInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      rankPercent: _rankPercent,
      scoreEgt: _scoreEgt,
      scoreElt: _scoreElt,
      level: _level,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.rankPercent = _rankPercent
    this.scoreEgt = _scoreEgt
    this.scoreElt = _scoreElt
    this.level = _level
  }

  static propKeys = ['rankPercent', 'scoreEgt', 'scoreElt', 'level']
}

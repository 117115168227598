export interface GzzxExamScoreAnalysisCommonRuleInterface {
  /**
   * 等级
   */
  level: string
  /**
   * 分数
   */
  score?: number | null
  /**
   * 百分比
   */
  percent?: number | null
  /**
   * 学习建议/锻炼建议
   */
  summary?: string | null
}

export class GzzxExamScoreAnalysisCommonRule
  implements GzzxExamScoreAnalysisCommonRuleInterface
{
  /**
   * 等级
   */
  level: string
  /**
   * 分数
   */
  score?: number | null
  /**
   * 百分比
   */
  percent?: number | null
  /**
   * 学习建议/锻炼建议
   */
  summary?: string | null

  constructor(props: GzzxExamScoreAnalysisCommonRuleInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      level: _level,
      score: _score,
      percent: _percent,
      summary: _summary,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.level = _level
    this.score = _score
    this.percent = _percent
    this.summary = _summary
  }

  static propKeys = ['level', 'score', 'percent', 'summary']
}

import { EvaluationSourceEnum } from './evaluation-source-enum'

export interface AssessmentSettingInterface {
  /**
   * 删除加减分（类型为评教时必填）
   */
  allowDeleteTags?: boolean
  /**
   * 通知（类型为评教时必填）
   */
  noticeStudent?: boolean
  /**
   * 通知导师
   *  - 目前仅德育评价使用、宿舍评价使用
   */
  noticeMentors?: boolean | null
  /**
   * 学生端可见分数（类型为评教时必填）
   */
  displayScore?: boolean
  /**
   * 学生端可见排名（类型为评教时必填）
   */
  displayRank?: boolean
  /**
   * 学生端可见等级（类型为评教时必填）
   */
  displayLevel?: boolean
  /**
   * 分数精度（类型为评教时必填）
   */
  precision?: number
  /**
   * 创建宿舍评价时，通过什么方式选择的评价对象
   */
  evaluationSource?: EvaluationSourceEnum
  /**
   * 教师考核-对比分析
   */
  displayAnalysis?: boolean
  /**
   * 通知班主任
   *  - 宿舍评价使用
   */
  noticeAdminClassTeacher?: boolean | null
  /**
   * 通知家长
   *  - 宿舍评价使用
   */
  noticeGuardian?: boolean | null
}

export class AssessmentSetting implements AssessmentSettingInterface {
  /**
   * 删除加减分（类型为评教时必填）
   */
  allowDeleteTags?: boolean
  /**
   * 通知（类型为评教时必填）
   */
  noticeStudent?: boolean
  /**
   * 通知导师
   *  - 目前仅德育评价使用、宿舍评价使用
   */
  noticeMentors?: boolean | null
  /**
   * 学生端可见分数（类型为评教时必填）
   */
  displayScore?: boolean
  /**
   * 学生端可见排名（类型为评教时必填）
   */
  displayRank?: boolean
  /**
   * 学生端可见等级（类型为评教时必填）
   */
  displayLevel?: boolean
  /**
   * 分数精度（类型为评教时必填）
   */
  precision?: number
  /**
   * 创建宿舍评价时，通过什么方式选择的评价对象
   */
  evaluationSource?: EvaluationSourceEnum
  /**
   * 教师考核-对比分析
   */
  displayAnalysis?: boolean
  /**
   * 通知班主任
   *  - 宿舍评价使用
   */
  noticeAdminClassTeacher?: boolean | null
  /**
   * 通知家长
   *  - 宿舍评价使用
   */
  noticeGuardian?: boolean | null

  constructor(props: AssessmentSettingInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      allowDeleteTags: _allowDeleteTags,
      noticeStudent: _noticeStudent,
      noticeMentors: _noticeMentors,
      displayScore: _displayScore,
      displayRank: _displayRank,
      displayLevel: _displayLevel,
      precision: _precision,
      evaluationSource: _evaluationSource,
      displayAnalysis: _displayAnalysis,
      noticeAdminClassTeacher: _noticeAdminClassTeacher,
      noticeGuardian: _noticeGuardian,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.allowDeleteTags = _allowDeleteTags
    this.noticeStudent = _noticeStudent
    this.noticeMentors = _noticeMentors
    this.displayScore = _displayScore
    this.displayRank = _displayRank
    this.displayLevel = _displayLevel
    this.precision = _precision
    this.evaluationSource = _evaluationSource
    this.displayAnalysis = _displayAnalysis
    this.noticeAdminClassTeacher = _noticeAdminClassTeacher
    this.noticeGuardian = _noticeGuardian
  }

  static propKeys = [
    'allowDeleteTags',
    'noticeStudent',
    'noticeMentors',
    'displayScore',
    'displayRank',
    'displayLevel',
    'precision',
    'evaluationSource',
    'displayAnalysis',
    'noticeAdminClassTeacher',
    'noticeGuardian',
  ]
}

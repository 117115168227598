export interface FormTemplateFieldRuleInterface {
  max?: number
  min?: number
}

export class FormTemplateFieldRule implements FormTemplateFieldRuleInterface {
  max?: number
  min?: number

  constructor(props: FormTemplateFieldRuleInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      max: _max,
      min: _min,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.max = _max
    this.min = _min
  }

  static propKeys = ['max', 'min']
}

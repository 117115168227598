/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  AccConverterPlan,
  AccConverterPlanInterface,
} from './acc-converter-plan'
import type { QueryConverterPlansQuery } from './query-converter-plans-query'

const buildConfig = createConfigBuilder('vnas')

export const accConverterApi$queryConverterPlans = {
  config(
    queryConverterPlansQuery?: QueryConverterPlansQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/acc-converter/plans',
      {},
      queryConverterPlansQuery,
    )
  },

  /**
   * @summary 查询分数转换配置
   * @param [queryConverterPlansQuery]
   * @param [accConverterQuery.id]
   * @param [accConverterQuery.idIn]
   * @param [accConverterQuery.target]
   * @param [accConverterQuery.targetIn]
   * @param [accConverterQuery.mode]
   * @param [accConverterQuery.modeIn]
   * @param [accConverterQuery.type]
   * @param [accConverterQuery.typeIn]
   * @param [accConverterQuery.assessmentId]
   * @param [accConverterQuery.assessmentIdIn]
   * @param [accConverterQuery.paginated] 是否分页，默认 1
   * @param [accConverterQuery.page] 显示的数据页，默认 1
   * @param [accConverterQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<AccConverterPlan[]>
   */

  api(
    queryConverterPlansQuery?: QueryConverterPlansQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<AccConverterPlan[]> {
    return axios(
      accConverterApi$queryConverterPlans.config(
        queryConverterPlansQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new AccConverterPlan(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = AccConverterPlan[]>(
    query?: QueryConverterPlansQuery,
    queryOptions?: QueryOptionsWithSelect<AccConverterPlan[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<AccConverterPlan[], TSelected>(
      accConverterApi$queryConverterPlans.api,
      'AccConverter.queryConverterPlans',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
accConverterApi$queryConverterPlans.api._name_ = `AccConverter.queryConverterPlans`

import { LoadFlowDataType } from 'packages/features/workflows/utils/apis'
import { FormWidgetRefFieldInfo } from 'packages/no-code/basic'
import {
  CustomApprovers,
  FieldPermInterface,
  Workflow,
} from 'packages/sdks-next/form'
import {
  CodedPage,
  CodedPageInterface,
  CustomPage,
  CustomPageInterface,
  FlowViewer,
  FormPage,
  FormPageInterface,
  FormWidget,
  Model,
  Page,
  PageType,
  TablePage,
  TablePageInterface,
} from 'packages/sdks-next/nuwa'

export type PageInterface =
  | FormPageInterface
  | CustomPageInterface
  | TablePageInterface
  | CodedPageInterface

/**
 * 判断是否是表单页面
 *
 * @param page - 页面
 * @returns boolean
 */
export const isFormPage = (page: Page): page is FormPage =>
  page.type === PageType.FormPage

/**
 * 判断是否是自定义页面
 *
 * @param page - 页面
 * @returns boolean
 */
export const isCustomPage = (page: Page): page is CustomPage =>
  page.type === PageType.CustomPage

/**
 * 判断是否是表格页面
 *
 * @param page - 页面
 * @returns boolean
 */
export const isTablePage = (page: Page): page is TablePage =>
  page.type === PageType.TablePage

/**
 * 判断是否是前端页面
 *
 * @param page - 页面
 * @returns boolean
 */
export const isCodedPage = (page: Page): page is CodedPage =>
  page.type === PageType.CodedPage

/**
 * 表单页面系统参数
 */
export enum FormPageSystemParam {
  ID = 'id',
  Mode = 'mode',
}

/**
 * 通用系统参数
 */
export enum PageSystemParam {
  Container = '$container',
}

/**
 * 表单模式
 */
export enum FormMode {
  View = 'view',
  Edit = 'edit',
}

/**
 * 表单页面元数据
 */
export enum FormPageMeta {
  Mode = 'mode',
  Scenario = 'scenario',
  CanEditWorkflowForm = '$canEditWorkflowForm',
  IsFlowForm = '$isFlowForm',
  FlowDataCompleted = '$approveClickable',
  FormMethods = '$formMethods',
}

export type FormHandlerStates = {
  model?: Model
  allowEdit: boolean
  mustEdit: boolean
  flowWidget?: FlowViewer
  workflow?: Workflow
  flow?: CustomApprovers | LoadFlowDataType
  widgetRefFieldMap: Record<string, FormWidgetRefFieldInfo>
}

export type FormHandlerActions = {
  /**
   * 获取组件权限配置的方法
   *
   * @param widget - 组件
   * @returns FieldPermInterface
   */
  getWidgetPermission: (widget: FormWidget) => FieldPermInterface
  /**
   * 检查组件是否可见的方法
   *
   * @param widget - 组件
   * @returns boolean
   */
  isVisible: (widget: FormWidget) => boolean
  /**
   * 检查组件是否可编辑的方法
   *
   * @param widget - 组件
   * @returns boolean
   */
  isEditable: (widget: FormWidget) => boolean
}

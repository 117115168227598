/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { CalendarEvent, CalendarEventInterface } from './extends/calendar-event'
import type { ListPersonalGroupCalendarEventsQuery } from './list-personal-group-calendar-events-query'

const buildConfig = createConfigBuilder('chalk')

export const groupApi$listPersonalGroupCalendarEvents = {
  config(
    id: string,
    startTime: string,
    endTime: string,
    ownerId: number,
    listPersonalGroupCalendarEventsQuery?: ListPersonalGroupCalendarEventsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/group/personal/groups/{id}/calendar-events',
      {
        id,
        startTime,
        endTime,
        ownerId,
      },
      listPersonalGroupCalendarEventsQuery,
    )
  },

  /**
   * @summary 获取群组(学生)日程事件
   * @param id Group ID，群组ID, 同时支持 enhancer + bizId， 如: seiue.class_group:123 其中 123 为 classId。
   * @param startTime 开始时间
   * @param endTime 结束时间
   * @param ownerId 学生ID
   * @param [listPersonalGroupCalendarEventsQuery]
   * @param [groupQuery.expand] 支持 initiators 和 address
   * @param [options]
   * @return AxiosResponsePromise<CalendarEvent[]>
   */

  api<const TQuery extends ListPersonalGroupCalendarEventsQuery>(
    id: string,
    startTime: string,
    endTime: string,
    ownerId: number,
    listPersonalGroupCalendarEventsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<CalendarEvent, TQuery['expand']>[]> {
    return axios(
      groupApi$listPersonalGroupCalendarEvents.config(
        id,
        startTime,
        endTime,
        ownerId,
        listPersonalGroupCalendarEventsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new CalendarEvent(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends ListPersonalGroupCalendarEventsQuery,
    TSelected = Expand<CalendarEvent, TQuery['expand']>[],
  >(
    {
      id,
      startTime,
      endTime,
      ownerId,
      query,
    }: {
      id: string
      startTime: string
      endTime: string
      ownerId: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<CalendarEvent, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<CalendarEvent, TQuery['expand']>[], TSelected>(
      groupApi$listPersonalGroupCalendarEvents.api,
      'Group.listPersonalGroupCalendarEvents',
      queryOptions,
      id,
      startTime,
      endTime,
      ownerId,
      query,
    )
  },
}

// @ts-ignore
groupApi$listPersonalGroupCalendarEvents.api._name_ = `Group.listPersonalGroupCalendarEvents`

import { IPIcon } from '@seiue/ui'
import { CSSProperties } from 'react'

import { ConditionOp } from 'packages/sdks-next/nuwa'

/**
 * 样式类型
 */
export enum ConditionStyleTypeEnum {
  // 图标
  Icon = 'icon',
  // 突出显示单元格
  Cell = 'cell',
}

/**
 * 对比字段类型
 */
export enum ComparisonTypeEnum {
  // 固定值
  Fixed = 'fixed',
  // 其他字段
  Others = 'others',
}

/**
 * 样式规则
 */
export type ConditionRule = {
  /**
   * 条件运算符
   */
  op: ConditionOp
  /**
   * 对比字段类型
   */
  comparisonType: ComparisonTypeEnum
  /**
   * 对比字段值
   * 当为 string 时，表示与固定值对比
   * 当为 string[] 时，表示与其他字段对比
   */
  comparisonValue?: string | string[]
  /**
   * 规则生效时显示的图标（styleType=icon 时生效）
   */
  icon?: IPIcon
  /**
   * 规则生效时显示的图标颜色（styleType=icon 时生效）
   */
  iconColor?: string
  /**
   * 规则生效时显示的样式（styleType=cell 时生效）
   */
  cellStyle?: CSSProperties
}

/**
 * 条件样式
 */
export type ConditionStyle = {
  /**
   * 唯一标识符
   */
  key: string
  /**
   * 样式类型
   */
  styleType: ConditionStyleTypeEnum
  /**
   * 样式规则列表
   */
  rules: ConditionRule[]
  /**
   * 应用的字段
   */
  fields?: string[]
}

import { Ellipsis, DropdownItem, Tag, Guide } from '@seiue/ui'
import React, { useEffect } from 'react'
import styled, { useTheme } from 'styled-components'

import {
  useSelectedSemester,
  useSemesters,
} from 'packages/feature-utils/semesters'
import { $t } from 'packages/locale'
import { SiderFooterSelector } from 'packages/web-layout/SiderFooterSelector'
import { LayoutContext } from 'packages/web-layout/context'

/**
 * 右侧学期选择器
 *
 * @returns React.ReactElement
 */
export const SemesterSelector: React.FC = () => {
  const [semesters] = useSemesters()

  const [selectedSemester, setSelectedSemesterId] = useSelectedSemester()

  const theme = useTheme()
  const { open: openPopover, close: closePopover } = Guide.usePopover()

  // 仅第一次进入页面时，发现选中的学期和当前学期不符合，给予提示
  useEffect(() => {
    if (semesters?.length && selectedSemester) {
      const currentSemester = semesters.find(semester => semester.isCurrent)

      if (currentSemester?.id !== selectedSemester.id) {
        openPopover?.('semester-selector')
      }
    }
    // eslint-disable-next-line
  }, [semesters])

  // 当切换回当前学期后，隐藏
  useEffect(() => {
    if (semesters?.length && selectedSemester) {
      if (
        semesters.find(semester => semester.isCurrent)?.id ===
        selectedSemester.id
      ) {
        closePopover?.('semester-selector')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [semesters, selectedSemester])

  if (!semesters?.length) return null

  return (
    <LayoutContext.Consumer>
      {({ collapsed }) => (
        <Guide.Popover
          description={$t('选中学期不是当前学期，点击可切换')}
          id="semester-selector"
          repeat={false}
          immediate={false}
          expireDate={null}
        >
          <SiderFooterSelector
            popoverTitle={$t('切换学期')}
            options={semesters.map(item => ({
              label: item.name,
              value: item.id,
            }))}
            placeholder={$t('请选择学期')}
            onChange={({ value }) => {
              setSelectedSemesterId(value)
            }}
            renderMenuItemLabel={(item: DropdownItem) => (
              <MenuItem>
                <Ellipsis text={item.label} tooltipProps={{ mode: 'dark' }} />
                {semesters.find(s => s.isCurrent)?.id === item.value && (
                  <MenuItemTag>{$t('当前')}</MenuItemTag>
                )}
              </MenuItem>
            )}
            icon={'Switch'}
            activeItem={
              selectedSemester
                ? {
                    label: selectedSemester.name,
                    value: selectedSemester.id,
                  }
                : undefined
            }
            description={
              semesters.find(s => s.isCurrent)?.id === selectedSemester?.id ? (
                <Tag
                  text={$t('当前')}
                  colors={{
                    backgroundLight: theme.brand._5,
                    text: theme.brand.text0,
                  }}
                />
              ) : (
                ''
              )
            }
            tooltipTitle={!collapsed ? $t('切换学期') : selectedSemester?.name}
          />
        </Guide.Popover>
      )}
    </LayoutContext.Consumer>
  )
}

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  cursor: pointer;
`

const MenuItemTag = styled.div`
  height: 18px;
  margin-left: 12px;
  padding: 0 7px;
  background-color: ${p => p.theme.brand._1};
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  border-radius: 9px;
`

import { Task, TaskInterface } from './task'

export interface TaskRelationInterface {
  taskId: number
  itemId: number
  /**
   * expanded
   */
  task?: TaskInterface | null
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class TaskRelation implements TaskRelationInterface {
  taskId: number
  itemId: number
  /**
   * expanded
   */
  task?: Task | null
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: TaskRelationInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      taskId: _taskId,
      itemId: _itemId,
      task: _task,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.taskId = _taskId
    this.itemId = _itemId
    this.task = _task ? new Task(_task) : _task
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = ['taskId', 'itemId', 'task', 'createdAt', 'updatedAt']
}

import {
  GstTemplateConfig,
  GstTemplateConfigInterface,
} from './gst-template-config'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'

export interface GstTemplateInterface {
  name: string
  description?: string | null
  adminIds: number[]
  disabled: boolean
  config: GstTemplateConfigInterface
  /**
   * expand
   */
  admins?: SecuredReflectionInterface[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class GstTemplate implements GstTemplateInterface {
  name: string
  description?: string | null
  adminIds: number[]
  disabled: boolean
  config: GstTemplateConfig
  /**
   * expand
   */
  admins?: SecuredReflection[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: GstTemplateInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      description: _description,
      adminIds: _adminIds,
      disabled: _disabled,
      config: _config,
      admins: _admins,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.description = _description
    this.adminIds = _adminIds
    this.disabled = _disabled
    this.config = _config ? new GstTemplateConfig(_config) : _config
    this.admins = _admins ? _admins.map(i => new SecuredReflection(i)) : _admins
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'name',
    'description',
    'adminIds',
    'disabled',
    'config',
    'admins',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

/**
 * @file 满意度调查-消息 domain 注册
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Evaluation,
  getLabel: () => $t('教学满意度'),
  labelStyle: calculateLabelStyle('#eeecff', '#6a4bbe'),
  messages: ['sz-teach-satisfacing.completed_notice_for_teacher'],
})

/**
 * @file 注册通用群组消息
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

import {
  NoticeTypes,
  SignupTypes,
  AttendanceTypes,
  MemberChanges,
} from './message-type'

registerDomainMessage({
  type: MessageDomainEnum.CustomGroupNotice,
  getLabel: () => $t('群组公告'),
  labelStyle: calculateLabelStyle('#C4F0E6', '#4B8678'),
  messages: [NoticeTypes.Published],
})

registerDomainMessage({
  type: MessageDomainEnum.CustomGroupSignupSubmission,
  getLabel: () => $t('群组报名'),
  labelStyle: calculateLabelStyle('#f7e9fa', '#9e37b4'),
  messages: [SignupTypes.Approved, SignupTypes.Rejected],
})

registerDomainMessage({
  type: MessageDomainEnum.CustomGroup,
  getLabel: () => $t('群组'),
  labelStyle: calculateLabelStyle('#E7F9ED', '#3E8B57'),
  messages: [
    `${MessageDomainEnum.CustomGroup}.${AttendanceTypes.Student}`,
    `${MessageDomainEnum.CustomGroup}.${AttendanceTypes.Guardian}`,
    `${MessageDomainEnum.CustomGroup}.${AttendanceTypes.Teacher}`,
    `${MessageDomainEnum.CustomGroup}.${MemberChanges.Added}`,
    `${MessageDomainEnum.CustomGroup}.${MemberChanges.Removed}`,
  ],
})

/**
 * @file 注册调代课消息
 */

import { PluginEnum } from 'packages/feature-utils/plugins'
import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.ClassAdjustment,
  getLabel: () => $t('调代课'),
  labelStyle: calculateLabelStyle('#EBE3EC', '#77577D'),
  icon: `plugin.${PluginEnum.Adjustment}`,
  messages: [
    'status_updated',
    {
      type: 'stage_approved',
      important: true,
    },
    'stage_revoked',
    {
      type: 'stage_invalid',
      important: true,
    },
    'stage_cc',
  ],
})

import { uniqBy } from '@seiue/util'
import { useAtomValue } from 'jotai'

import {
  CertificationPluginKind,
  CertificationPluginSettings,
} from 'packages/feature-utils/certifications'
import {
  findEnabledChildPluginsAtom,
  findUsableChildPluginsAtom,
  hasManageableParentPluginAtom,
  PluginEnum,
  useFindEnabledParentPlugin,
  useHasManageablePlugin,
} from 'packages/feature-utils/plugins'
import { useCurrentReflection } from 'packages/features/sessions'
import { TermTypeEnum } from 'packages/features/terms/types'
import { RoleEnum, termApi$queryTerm } from 'packages/sdks-next/chalk'
import {
  CertAdminPolicyEnum,
  QueryCertificationsQuery,
  certificationApi$queryCertifications,
} from 'packages/sdks-next/sgms'

export * from './use-certification-groups'

/**
 * 获取当前登录用户访问认证管理接口时应使用的权限
 *
 * @param certificationPluginId - 认证插件 id
 * @returns 权限标识
 */
export const useCertificationAdminPolicy = (certificationPluginId?: number) => {
  // 插件商店管理员
  const isPluginManager = useAtomValue(
    hasManageableParentPluginAtom(PluginEnum.Certification),
  )

  // 认证子插件管理员
  const isChildPluginManager = useHasManageablePlugin(
    PluginEnum.Certification,
    {
      id: certificationPluginId,
    },
  )

  return isPluginManager || isChildPluginManager
    ? CertAdminPolicyEnum.SchoolPluginAdmin
    : CertAdminPolicyEnum.CertAdmin
}

/**
 * 获取认证分类列表
 *
 * @returns 分类列表
 */
export const useCertificationTypes = () => {
  return termApi$queryTerm.useApi(
    {
      type: TermTypeEnum.CertificationType,
    },
    {
      staleTime: 5 * 60,
    },
  )
}

/**
 * 获取认证科目列表
 *
 * @returns 科目列表
 */
export const useCertificationSubjects = () => {
  return termApi$queryTerm.useApi(
    {
      type: TermTypeEnum.CertificationSubject,
    },
    {
      staleTime: 5 * 60,
    },
  )
}

/**
 * 获取线上虚拟激励的随手评
 *
 * @returns 线上虚拟激励的随手评
 */
export const useOnlineIncentiveShortcuts = () => {
  return useAtomValue(
    findUsableChildPluginsAtom(PluginEnum.Certification),
  ).filter(
    certPlugin =>
      (certPlugin.settings as CertificationPluginSettings).kind ===
      CertificationPluginKind.OnlineIncentive,
  )
}

/**
 * 获取电子证书扩展设置中的 logo url
 *
 * @returns logo url
 */
export const useElecPluginLogoUrl = () => {
  const extension = useFindEnabledParentPlugin(PluginEnum.ElecCertificate)

  return (
    extension?.settings as {
      logo?: string
    }
  )?.logo
}

/**
 * 判断当前用户是否有任意认证的授予权限
 *
 * @returns 是否
 */
export const useHasGrantCertPermission = () => {
  const currentReflection = useCurrentReflection()

  const isTeacher = [RoleEnum.Teacher, RoleEnum.Staff].includes(
    currentReflection.role,
  )

  const { data } = certificationApi$queryCertifications.useApi(
    {
      policy: CertAdminPolicyEnum.Teacher,
      schoolPluginId: 0,
      query: {
        perPage: 1,
      },
    },
    {
      staleTime: 5 * 60,
      disable: !isTeacher,
    },
  )

  return !!data?.length
}

/**
 * 获取有授予权限的认证子插件列表
 *
 * @param args - args
 * @param args.policy - 权限表示
 * @param args.filterTypes - 指定要过滤的认证类型
 * @param args.query - 查询参数
 * @param args.staleTime - 缓存时间
 * @returns 认证子插件列表
 */
export const useGrantableCertPlugins = ({
  policy,
  filterTypes,
  query,
  staleTime,
}: {
  policy: CertAdminPolicyEnum
  filterTypes?: CertificationPluginKind[]
  query?: QueryCertificationsQuery
  staleTime?: number
}) => {
  const currentReflection = useCurrentReflection()

  const isTeacher = [RoleEnum.Teacher, RoleEnum.Staff].includes(
    currentReflection.role,
  )

  const { data: certifications, loading } =
    certificationApi$queryCertifications.useApi(
      {
        policy,
        schoolPluginId: 0,
        query: {
          paginated: 0,
          fields: ['id', 'school_plugin_id'].join(','),
          ...query,
        },
      },
      {
        staleTime: staleTime ?? 5 * 60,
        disable: !isTeacher,
      },
    )

  const certPlugins = useAtomValue(
    findUsableChildPluginsAtom(PluginEnum.Certification),
  )

  const uniqueCertPluginIds = uniqBy(certifications, 'schoolPluginId').map(
    certification => certification.schoolPluginId,
  )

  const uniqueCertPlugins = certPlugins.filter(certPlugin =>
    uniqueCertPluginIds.includes(certPlugin.id),
  )

  return {
    loading,
    data: !filterTypes?.length
      ? uniqueCertPlugins
      : uniqueCertPlugins.filter(certPlugin =>
          filterTypes.includes(
            (certPlugin.settings as CertificationPluginSettings).kind,
          ),
        ),
  }
}

/**
 * 获取已启用的指定类型的认证子插件
 *
 * @param types - 指定认证类型
 * @returns 认证子插件
 */
export const useEnabledCertPluginsByTypes = (
  types: CertificationPluginKind[],
) => {
  return useAtomValue(
    findEnabledChildPluginsAtom(PluginEnum.Certification),
  ).filter(certPlugin =>
    types.includes((certPlugin.settings as CertificationPluginSettings).kind),
  )
}

import { RoleEnum } from './role-enum'
import { GenderEnum } from './gender-enum'
import { StatusEnum } from './status-enum'
import { Term, TermInterface } from './extends/term'

export interface PupilInterface {
  schoolId: number
  name: string
  role: RoleEnum
  pinyin: string
  gender?: GenderEnum | null
  userId?: number | null
  account?: string
  usin?: string | null
  ename?: string | null
  email?: string | null
  phone?: string | null
  idcard?: string | null
  photo?: string | null
  /**
   * 头像
   */
  avatar?: string | null
  status?: StatusEnum
  disabled?: boolean
  archivedTypeId?: number | null
  archivedType?: TermInterface | null
  /**
   * 账户修改时间
   */
  accountUpdatedAt?: string | null
  /**
   * 是否需要修改密码
   */
  passwordChangeNeeded?: boolean | null
  /**
   * 外部人员的关联ID
   */
  outerId?: string | null
  /**
   * 主键 ID
   */
  id: number
}

export class Pupil implements PupilInterface {
  schoolId: number
  name: string
  role: RoleEnum
  pinyin: string
  gender?: GenderEnum | null
  userId?: number | null
  account?: string
  usin?: string | null
  ename?: string | null
  email?: string | null
  phone?: string | null
  idcard?: string | null
  photo?: string | null
  /**
   * 头像
   */
  avatar?: string | null
  status?: StatusEnum
  disabled?: boolean
  archivedTypeId?: number | null
  archivedType?: Term | null
  /**
   * 账户修改时间
   */
  accountUpdatedAt?: string | null
  /**
   * 是否需要修改密码
   */
  passwordChangeNeeded?: boolean | null
  /**
   * 外部人员的关联ID
   */
  outerId?: string | null
  /**
   * 主键 ID
   */
  id: number

  constructor(props: PupilInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      name: _name,
      role: _role,
      pinyin: _pinyin,
      gender: _gender,
      userId: _userId,
      account: _account,
      usin: _usin,
      ename: _ename,
      email: _email,
      phone: _phone,
      idcard: _idcard,
      photo: _photo,
      avatar: _avatar,
      status: _status,
      disabled: _disabled,
      archivedTypeId: _archivedTypeId,
      archivedType: _archivedType,
      accountUpdatedAt: _accountUpdatedAt,
      passwordChangeNeeded: _passwordChangeNeeded,
      outerId: _outerId,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.name = _name
    this.role = _role
    this.pinyin = _pinyin
    this.gender = _gender
    this.userId = _userId
    this.account = _account
    this.usin = _usin
    this.ename = _ename
    this.email = _email
    this.phone = _phone
    this.idcard = _idcard
    this.photo = _photo
    this.avatar = _avatar
    this.status = _status
    this.disabled = _disabled
    this.archivedTypeId = _archivedTypeId
    this.archivedType = _archivedType ? new Term(_archivedType) : _archivedType
    this.accountUpdatedAt = _accountUpdatedAt
    this.passwordChangeNeeded = _passwordChangeNeeded
    this.outerId = _outerId
    this.id = _id
  }

  static propKeys = [
    'schoolId',
    'name',
    'role',
    'pinyin',
    'gender',
    'userId',
    'account',
    'usin',
    'ename',
    'email',
    'phone',
    'idcard',
    'photo',
    'avatar',
    'status',
    'disabled',
    'archivedTypeId',
    'archivedType',
    'accountUpdatedAt',
    'passwordChangeNeeded',
    'outerId',
    'id',
  ]
}

/**
 * @file 满意度调查-navigator 注册
 */

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator(
  'sz-teach-satisfacing.completed_notice_for_teacher',
  ({ attributes }) => {
    const id = (
      attributes as {
        evaluationId: number
        id: number
      }
    )?.evaluationId

    return {
      path: `/plugin/teacher/teaching-satisficing/${id}/results`,
    }
  },
)

import { systemApi$loadSchool } from 'packages/sdks-next/chalk'

/**
 * 根据 id 或 domain 加载 school. 支持云或本地集群.
 *
 * @param idOrDomain - id or domain
 * @returns promise of axios response
 */
export const loadSchool = (idOrDomain: number | string) => {
  const options = {
    omitAuthorizationHeader: true,
    omitReflectionHeaders: true,
  }

  return systemApi$loadSchool.api(
    String(idOrDomain),
    {
      expand: ['customConstraints'],
    },
    options,
  )
}

const reservedDomains = [
  'local',
  'chalk',
  'chalk-c3',
  'chalkboard',
  'chalkboard-c3',
  'go',
  'go-c3',
  'im',
  'im-c3',
  'preview',
  'preview-c3',
  'signature-pad',
  'signature-pad-c3',
  'nuwa',
  'developer',
  'webpack5',
  'webpack5-go',
]

/**
 * 从当前地址中解析学校域名
 *
 * @returns 如果无法解析 (比如非 seiue.com) 或结果是应用标准域名 (比如 chalk-c3) 则返回 null, 否则返回学校域名
 */
export const getSchoolDomainFromURL = () => {
  const hostname = window?.location?.hostname

  if (hostname?.includes('.seiue.com')) {
    const domain = hostname.split('.')[0]
    if (!reservedDomains.includes(domain.toLowerCase())) return domain
  }

  return null
}

/**
 * 从分享页 URL 中获取 school id
 *
 * @returns id
 */
export const getSchoolIdFromShareURL = () => {
  const pathName = window.location.pathname
  const regex = /school_id=(\d+)/
  const schoolIdMatch = window.location.href.match(regex)
  const match = pathName.match(/\/share\/(ra\/)?([0-9]+)\//)
  return match ? +match[2] : schoolIdMatch ? Number(schoolIdMatch[1]) : null
}

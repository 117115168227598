/**
 * @file 账号工具函数入口文件
 */

import { createEnumHelper, PasswordStrengthEnum } from '@seiue/util'

import { $t } from 'packages/locale'

export * from './bind'
export * from './encrypt'

export const passwordStrengthEnumLabelHelper = createEnumHelper(() => ({
  [PasswordStrengthEnum.Weak]: $t('较弱'),
  [PasswordStrengthEnum.Normal]: $t('适中'),
  [PasswordStrengthEnum.Strong]: $t('较强'),
}))

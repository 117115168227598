import { OutlineReviewTypeEnum } from './outline-review-type-enum'
import {
  OutlineCustomFields,
  OutlineCustomFieldsInterface,
} from './outline-custom-fields'

export interface OutlineInterface {
  /**
   * 大纲批阅类型
   */
  reviewType?: OutlineReviewTypeEnum
  name: string
  /**
   * 父id
   */
  parentId?: number
  /**
   * 权重
   */
  weight?: number
  /**
   * 是否发布
   */
  isOutlinePublished?: boolean
  /**
   * 扩展字段
   * - item_id: 关联过程评价项 id
   */
  customFields?: OutlineCustomFieldsInterface | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Outline implements OutlineInterface {
  /**
   * 大纲批阅类型
   */
  reviewType?: OutlineReviewTypeEnum
  name: string
  /**
   * 父id
   */
  parentId?: number
  /**
   * 权重
   */
  weight?: number
  /**
   * 是否发布
   */
  isOutlinePublished?: boolean
  /**
   * 扩展字段
   * - item_id: 关联过程评价项 id
   */
  customFields?: OutlineCustomFields | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: OutlineInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      reviewType: _reviewType,
      name: _name,
      parentId: _parentId,
      weight: _weight,
      isOutlinePublished: _isOutlinePublished,
      customFields: _customFields,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.reviewType = _reviewType
    this.name = _name
    this.parentId = _parentId
    this.weight = _weight
    this.isOutlinePublished = _isOutlinePublished
    this.customFields = _customFields
      ? new OutlineCustomFields(_customFields)
      : _customFields
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'reviewType',
    'name',
    'parentId',
    'weight',
    'isOutlinePublished',
    'customFields',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

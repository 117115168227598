import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

import icon from './grade.png'

registerDomainMessage({
  type: MessageDomainEnum.ClassAssessment,
  getLabel: () => $t('成绩'),
  labelStyle: calculateLabelStyle('#F7E9FA', '#9E37B4'),
  icon,
  messages: [
    'assessment_published_for_teacher',
    'assessment_recalled_for_teacher',
    'assessment_rejected_for_teacher',
    'grade_delayed_for_student',
    'grade_published_for_student',
    'grade_revoked_for_student',
    'grade_updated_for_student',
    'grade_updated_for_teacher',
    'raw_score_addition_for_student',
    'raw_score_addition_for_teacher',
    'raw_score_tag_for_student',
    'raw_score_tag_for_teacher',
    'raw_score_special_for_student',
    'raw_score_special_for_teacher',
    'score_for_student',
    'score_for_teacher',
    'item_operated_for_student',
    'item_operated_for_teacher',
    'stage_grade_updated_for_student',
    'stage_grade_delayed_for_student',
    'stage_grade_revoked_for_student',
    'stage_grade_published_for_student',
    'daily_report',
  ],
})

import { Pagination } from '@seiue/axios'
import {
  normalize,
  denormalize,
  schema as normalizrSchema,
  Schema,
} from '@seiue/normalizr'
import {
  mapValues,
  compact,
  isEqual,
  toPairs,
  without,
  isArray,
  pickBy,
  isString,
} from '@seiue/util'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import uuidByString from 'uuid-by-string'

import {
  entityCacheGC,
  updateReferenceCount,
  useReferenceCount,
  useUpdateReferenceCount,
} from './cache-gc'
import { setCachedAt, getCachedAt } from './cached-at'
import { QueryEffectResult, State, QueriedIds, ReferenceUpdate } from './types'

export * from './types'
export * from './decorators'

// 20 条数据 3kb - 6kb 假设均值 5kb 我们的缓存上限可以基于这个假设进行推断 100 mb ~= 40960 条
const ENTITY_CACHE_ITEMS_THRESHOLD = 40000

export const { deleteKey } = new normalizrSchema.Entity('deleteKeyGetter')

export const isExpired = (
  mainKey: string,
  subKey: string | number | string[] | number[],
  maxAge: number,
): boolean => {
  if (!isArray(subKey)) {
    const cachedAt = getCachedAt(mainKey, subKey) || 0
    return (Date.now() - cachedAt) / 1000 > maxAge
  }

  return subKey.some((id: string | number) => isExpired(mainKey, id, maxAge))
}

const defaultState: State = {
  entityCache: {},
  queryCache: {},
  referenceCount: {},
  totalItems: 0,
}

const reducers = {
  merge(
    state: State,
    entities: {
      [key: string]: {
        [key: string]: any
      }
    },
  ) {
    const { queryCache, referenceCount } = state
    let { totalItems, entityCache } = state
    // 在 merge 之前清理缓存，避免刚写入还没及时更新引用计数的数据被清理
    if (totalItems >= ENTITY_CACHE_ITEMS_THRESHOLD) {
      ;[entityCache, totalItems] = entityCacheGC(entityCache, referenceCount)
    }

    let hasEntityCacheChanged = false
    const deletedEntities: { [key: string]: any[] } = {}
    const mergedEntityCache = mapValues(entities, (results, name) => {
      const current = entityCache[name] || {}
      Object.keys(results).forEach(id => {
        setCachedAt(name, id)

        if (!current[id]) {
          totalItems += 1
        }

        // only update if old data and new data are different
        if (!isEqual(current[id], results[id])) {
          hasEntityCacheChanged = true
          current[id] = { ...current[id], ...results[id] }
        }

        // Record which ids are deleted for each resource
        if (results[id][deleteKey]) {
          deletedEntities[name] = deletedEntities[name] || []

          // eslint-disable-next-line
          deletedEntities[name].push(isNaN(id as any) ? id : +id)

          if (referenceCount[name] && referenceCount[name][id]) {
            // reset reference count
            delete referenceCount[name][id]
          }
        }
      })

      return current
    })

    // Delete ids of deleted entities in query cache
    let hasQueryCacheChanged = false
    toPairs(deletedEntities).forEach(([name, deletedIds]) => {
      toPairs(queryCache).forEach(([fetchRemoteKey, fetchRemoteCache]) => {
        if (fetchRemoteCache && fetchRemoteKey.startsWith(`${name}.`)) {
          toPairs(fetchRemoteCache).forEach(([, cache]) => {
            if (cache) {
              const { ids } = cache

              // eslint-disable-next-line
              cache.ids = without(ids, ...deletedIds)
              if (ids.length !== cache.ids.length) {
                hasQueryCacheChanged = true
              }
            }
          })
        }
      })
    })

    if (!hasEntityCacheChanged && !hasQueryCacheChanged) return state

    const queryCacheToReturn = hasQueryCacheChanged
      ? { ...queryCache }
      : queryCache

    const entityCacheToReturn = hasEntityCacheChanged
      ? {
          ...entityCache,
          ...mergedEntityCache,
        }
      : entityCache

    return {
      queryCache: queryCacheToReturn,
      entityCache: entityCacheToReturn,
      referenceCount,
      totalItems,
    }
  },

  setQueryResults(
    state: State,
    {
      fetchRemoteKey,
      queryKey,
      ids,
      pagination,
      forceTouch = false,
    }: {
      fetchRemoteKey: string
      queryKey: string
      ids: QueriedIds
      pagination: Pagination
      forceTouch?: boolean
    },
  ) {
    setCachedAt(fetchRemoteKey, queryKey)

    const { entityCache, queryCache } = state
    const newState = (fetchRemoteCache: any) => ({
      ...state,
      entityCache,
      queryCache: {
        ...queryCache,
        [fetchRemoteKey]: fetchRemoteCache,
      },
    })

    const cached = (queryCache[fetchRemoteKey] || {})[queryKey]
    if (!cached) {
      /**
       * 之前没有 cache，对同个 fetchRemote 下的其他 cache 保持乐观，
       * 添加该 query 的 cache
       */
      return newState({
        ...queryCache[fetchRemoteKey],
        [queryKey]: { ids, pagination },
      })
    }

    const didCacheExistAndChange =
      cached.ids.length !== ids.length ||
      cached.ids.some((id: string | number, idx: number) => id !== ids[idx]) ||
      !isEqual(cached?.pagination, pagination)

    if (didCacheExistAndChange) {
      /**
       * 缓存命中但与最新结果不同，或 pagination 更新
       * 则在更新缓存前清除同个 fetchRemote 方法下所有同类 query（只有 pagination 相关参数的不同）的缓存
       */
      const cacheToKeep: any = {}
      toPairs(queryCache[fetchRemoteKey] || {}).forEach(([qKey, cache]) => {
        if (qKey.split(':')[0] !== queryKey.split(':')[0]) {
          cacheToKeep[qKey] = cache
        }
      })

      return newState({ ...cacheToKeep, [queryKey]: { ids, pagination } })
    }

    // 缓存命中且与最新结果相同，do nothing unless `forceTouch` is true
    return forceTouch ? newState(queryCache[fetchRemoteKey]) : state
  },

  /**
   * 根据 schemas 清空 query cache
   */
  clearQueryCacheOfSchemas(state: State, schemas: normalizrSchema.Entity[]) {
    if (!schemas.length) return state

    const schemaPrefixRegex = new RegExp(
      `^(${schemas.map(schema => schema.key).join('|')})\\.`,
    )

    return {
      ...state,
      queryCache: pickBy(
        state.queryCache,
        (_val, key) => !key.match(schemaPrefixRegex),
      ),
    }
  },

  /**
   * 用于更新引用计数
   * @param state
   * @param referenceUpdate
   * @param increase - true 为读取时，引用计数 +1 ，false 为释放时，引用计数 -1
   */
  updateReferenceCount(
    state: State,
    referenceUpdate: ReferenceUpdate,
    increase: boolean,
  ) {
    const { referenceCount } = state
    updateReferenceCount(referenceCount, referenceUpdate, increase)
    return {
      ...state,
      referenceCount,
    }
  },
}

export const getQueryKey = (query: { [key: string]: any } = {}) => {
  const { page = '', perPage = '', paginated = '', ...otherQuery } = query
  const prefix = uuidByString(JSON.stringify(otherQuery))
  return `${prefix}:${page}.${perPage}.${paginated}`
}

const effects = (dispatch: any) => {
  const get = (
    { input, schema }: { input: any; schema: Schema },
    rootState: { entities: State },
  ) => {
    const { entities } = rootState

    return denormalize(input, schema, entities.entityCache)
  }

  const set = ({ data, schema }: { data: any; schema: Schema }) => {
    const normalized = normalize(data, schema)
    dispatch.entities.merge(normalized.entities)
    return normalized.result
  }

  const setQueried = <TEntity, TQuery extends { [key: string]: any }>({
    data,
    pagination,
    fetchRemoteKey,
    query,
    schema,
    forceTouch = false,
  }: {
    data: TEntity[]
    pagination: Pagination
    query?: TQuery | string
    fetchRemoteKey: string
    schema: normalizrSchema.Entity<TEntity>
    forceTouch?: boolean
  }) => {
    const normalized = normalize(data, [schema])

    dispatch.entities.merge(normalized.entities)
    dispatch.entities.setQueryResults({
      fetchRemoteKey,
      queryKey: isString(query) ? query : getQueryKey(query),
      ids: normalized.result,
      pagination,
      forceTouch,
    })
  }

  const remove = ({ id, schema }: { id: any; schema: any }) =>
    set({
      data: {
        // FIXME: might be a bug here. what if idAttribute is a function?
        [schema.idAttribute]: id,
        [schema.deleteKey]: true,
      },
      schema,
    })

  const removeMany = ({ ids, schema }: { ids: any[]; schema: any }) => {
    const data = ids.map(id => ({
      // FIXME: might be a bug here. what if idAttribute is a function?
      [schema.idAttribute]: id,
      [schema.deleteKey]: true,
    }))

    set({
      data,
      schema: [schema],
    })
  }

  return {
    get,
    set,
    setQueried,
    remove,
    removeMany,
  }
}

// export entities store
export const entities = {
  state: defaultState,
  reducers,
  effects,
}

// 给定输入 intput 和格式 schema，通过 entities 数据将 input denormalize 化
export function useDenormalized<T>(schema: [Schema<T>], input: any): T[]
export function useDenormalized<T>(schema: Schema<T>, input: any): T
export function useDenormalized(schema: any, input: any) {
  const entityCache = useSelector((state: any) => state.entities.entityCache)

  const denormalized = useMemo(
    () => denormalize(input, schema, entityCache),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [input, entityCache],
  )

  useReferenceCount(denormalized, schema)
  return denormalized
}

export function useDenormalizedQueryResults<TEntity>(
  fetchRemoteKey: string,
  query: { [key: string]: any } = {},
  schema: normalizrSchema.Entity<TEntity>,
): QueryEffectResult<TEntity> | null {
  const queryCache = useSelector(
    (state: any) => state.entities.queryCache[fetchRemoteKey],
  )

  const entityCache = useSelector((state: any) => state.entities.entityCache)

  const res = useMemo(
    () => {
      const queryKey = getQueryKey(query)

      const cached = (queryCache || {})[queryKey]

      if (!cached) return null

      const denormalized = denormalize(cached.ids, [schema], entityCache)

      /**
       * 保守地使用缓存，只有完整取到一页数据，才算命中并返回
       */
      return compact(denormalized).length === cached.ids.length
        ? {
            pagination: cached.pagination,
            data: denormalized,
          }
        : null
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchRemoteKey, JSON.stringify(query), queryCache, entityCache],
  )

  const arraySchema = useMemo(() => [schema], [schema])
  useReferenceCount(res?.data, arraySchema)
  return res
}

/**
 * @deprecated 属于旧缓存机制, 不应在新代码中再使用
 *
 * 以数组形式取得 entities 某个资源的全部数据
 */
export function useDenormalizedAll(schema: normalizrSchema.Entity) {
  const entityCache = useSelector((state: any) => state.entities.entityCache)

  return useMemo(
    () =>
      denormalize(
        Object.keys(entityCache[schema.key] || {}),
        [schema],
        entityCache,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entityCache],
  )
}

/**
 * 以 map 形式取得 entities 某个资源的全部数据
 * FIXME 实际没有 denormalize
 * @param schema
 */
export function useDenormalizedMap(schema: normalizrSchema.Entity) {
  const { key } = schema
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const map = useSelector((state: any) => state.entities.entityCache[key]) || {}
  const referenceUpdate = useMemo(() => {
    const ids = Object.keys(map).sort()
    return { [key]: ids }
  }, [map, key])

  useUpdateReferenceCount(referenceUpdate)
  return map
}

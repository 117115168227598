export interface ScoreAttendanceInfoInterface {
  /**
   * 课程名 班级名
   */
  classFullName?: string
  /**
   * 课节日期
   */
  lessonDate?: string
  /**
   * 课节名
   */
  lessonName?: string
  /**
   * 考勤记录的录入人（最后修改人）
   */
  recorderName?: string
}

export class ScoreAttendanceInfo implements ScoreAttendanceInfoInterface {
  /**
   * 课程名 班级名
   */
  classFullName?: string
  /**
   * 课节日期
   */
  lessonDate?: string
  /**
   * 课节名
   */
  lessonName?: string
  /**
   * 考勤记录的录入人（最后修改人）
   */
  recorderName?: string

  constructor(props: ScoreAttendanceInfoInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      classFullName: _classFullName,
      lessonDate: _lessonDate,
      lessonName: _lessonName,
      recorderName: _recorderName,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.classFullName = _classFullName
    this.lessonDate = _lessonDate
    this.lessonName = _lessonName
    this.recorderName = _recorderName
  }

  static propKeys = [
    'classFullName',
    'lessonDate',
    'lessonName',
    'recorderName',
  ]
}

/**
 * @file desktop error boundary component
 */

import { faPageBreak } from '@fortawesome/pro-duotone-svg-icons'
import { reportToSentry } from '@seiue/sentry'
import { isWebpack4ChunkLoadError } from '@seiue/util'
import React from 'react'
import { ErrorBoundary as ErrorBoundaryRaw } from 'react-error-boundary'
import { useLocation } from 'react-router'
import styled from 'styled-components'

import { SeIcon } from 'packages/components/Icon'
import { $t } from 'packages/locale'

interface FallbackProps {
  error: Error
  resetErrorBoundary: () => void
}

const ErrorFallback: React.FC<FallbackProps> = ({ error }) => {
  // 因为网络不稳定等原因, 下载 js/css 资源时可能会失败
  const message = isWebpack4ChunkLoadError(error)
    ? $t('网络状况不佳，可尝试刷新页面')
    : $t('错误已自动上报，我们将尽快处理')

  return (
    <ErrorHintContainer>
      <SeIcon icon={faPageBreak} style={{ fontSize: '48px' }} />
      <ErrorTitle>{$t('页面加载失败了')}</ErrorTitle>
      <ErrorSubtitle>{message}</ErrorSubtitle>
    </ErrorHintContainer>
  )
}

export const ErrorBoundary: React.FC = props => {
  const location = useLocation()

  return (
    <ErrorBoundaryRaw
      FallbackComponent={ErrorFallback}
      resetKeys={[location]}
      onError={e => {
        if (isWebpack4ChunkLoadError(e)) return

        reportToSentry(e, {
          // ErrorBoundary 中的错误都会导致页面渲染失败
          ExceptionType: 'PageCrash',
        })
      }}
    >
      {props.children}
    </ErrorBoundaryRaw>
  )
}

const ErrorTitle = styled.div`
  margin: 30px 0 10px 0;
  color: #323842;
  font-weight: bold;
  font-size: 18px;
`

const ErrorSubtitle = styled.div`
  color: rgba(6, 12, 25, 0.38);
  font-size: 14px;
`

const ErrorHintContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;

  /* 调整视觉重心 */
  margin-top: -5%;
`

import {
  assign,
  Dictionary,
  escapeStringRegExp,
  findLast,
  matchURL,
  safeParseJSON,
  some,
  sortBy,
  sum,
} from '@seiue/util'
import { RawDraftContentBlock, RawDraftInlineStyleRange } from 'draft-js'

import { convertStringToRaw } from 'packages/components/RichText/utils/convert-string-to-raw'
import { $t } from 'packages/locale'

// 变量识别正则，eg: {{姓名}}
export const VariableRegExp = /{{([^{}]*?)}}/g

/**
 * 特定的变量，以下变量在变量文件中，表头为中文名，但上传后，接口返回的是英文名
 *
 * @returns 变量键值对
 */
export const getSpecialVariableMap = (): { [key: string]: string } => ({
  name: $t('姓名'),
  usin: $t('学工号'),
  role: $t('用户类型'),
})

/**
 * 格式化变量键
 *
 * @param key - 变量 Key
 * @returns 格式化变量名称
 */
export const formatVariableKey = (key: string) => {
  const specialVariableMap = getSpecialVariableMap()

  return specialVariableMap[key] || key
}

const getRegexForKey = (key: string) => {
  return new RegExp(
    `{{\\s*${escapeStringRegExp(formatVariableKey(key))}\\s*}}`,
    'g',
  )
}

/**
 * 计算文本中所有的匹配结果，同时计算截止到当前匹配，已经累计的文本长度差
 *
 * @param text - 原始文本
 * @param regex - 正则
 * @param replaceValue - 替换值
 * @returns 匹配结果
 */
const getAllMatches = (text: string, regex: RegExp, replaceValue: string) => {
  let match = regex.exec(text)

  const matchResult: {
    matchText: string
    start: number
    end: number
    /**
     * 到当前变量匹配已经产生的长度差值，例如：{{姓名}} => 张小三，差值为 6 - 3 = 3
     */
    lengthDiff: number
  }[] = []

  let lengthDiff = 0

  while (match) {
    lengthDiff += replaceValue.length - match[0].length
    matchResult.push({
      start: match.index,
      matchText: match[0],
      end: match.index + match[0].length,
      lengthDiff,
    })

    match = regex.exec(text)
  }

  return matchResult
}

/**
 * 替换富文本中的变量
 *
 * @param json - 模板 Draft Content JSON
 * @param variableString - 变量值
 *
 * @returns 替换后的 Draft Content JSON
 */
export const replaceVariablesForRichTextJSON = (
  json: string,
  variableString: string,
) => {
  const variables = (safeParseJSON(variableString) || {}) as Dictionary<string>
  const variablesArr = Object.entries(variables)

  if (!variablesArr.length) return json

  const rawContent = convertStringToRaw(json)

  // 文本内容均包含在 Draft ContentBlock 中，在这里使用正则来替换模板中的变量
  const newBlocks = rawContent.blocks.map(
    ({ text, inlineStyleRanges: ranges, ...props }: RawDraftContentBlock) => {
      // 样式根据应用范围的起始位置排序，更新时按照顺序
      const inlineStyleRanges = sortBy(ranges, 'offset')

      const update = variablesArr.reduce(
        (prev, [key, value]) => {
          const regexp = getRegexForKey(key)

          // 如果是 Value 本身是 URL（或包含），那么在后面加一个空格，避免 URL 与后面的文字连在一起
          const replaceValue = some(matchURL(value), url => !!url)
            ? `${value} `
            : value

          const prevText = prev.text
          const nextText = prevText.replace(regexp, replaceValue)

          // 找出匹配出变量的坐标和截止到当前位置，已经产生的替换文本前后的长度差
          const replaceMatches = getAllMatches(prevText, regexp, replaceValue)

          const nextInlineStyledRanges = prev.inlineStyleRanges.map(
            styleRange => {
              const { length, offset } = styleRange

              // 样式范围内是否有 match
              const matches = prev.text
                .slice(offset, offset + length)
                .match(regexp)

              const nearestReplaceMatch = findLast(
                replaceMatches,
                m => m.end <= offset,
              )

              // 样式范围内如果有 match，那么该样式的长度需要更新
              if (matches?.length) {
                const lengthDiff = sum(
                  matches.map(m => replaceValue.length - m.length),
                )

                assign(styleRange, { length: length + lengthDiff })
              }

              // 如果该样式前面有替换变量，offset 需要考虑进累计长度差
              if (nearestReplaceMatch) {
                assign(styleRange, {
                  offset: offset + nearestReplaceMatch.lengthDiff,
                })
              }

              return styleRange
            },
          )

          return {
            text: nextText,
            inlineStyleRanges: nextInlineStyledRanges,
          }
        },
        {
          text,
          inlineStyleRanges,
        } as { text: string; inlineStyleRanges: RawDraftInlineStyleRange[] },
      )

      return {
        ...props,
        ...update,
      }
    },
  )

  // 替换后，重新格式化为 JSON
  return JSON.stringify({
    ...rawContent,
    blocks: newBlocks,
  })
}

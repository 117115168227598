/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { ConfigItem, ConfigItemInterface } from './extends/config-item'
import type { QueryConfigItemsByGroupQuery } from './query-config-items-by-group-query'

const buildConfig = createConfigBuilder('chalk')

export const acmApi$queryConfigItemsByGroup = {
  config(
    scope: string,
    service: string,
    group: string,
    queryConfigItemsByGroupQuery?: QueryConfigItemsByGroupQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/acm/scopes/{scope}/{service}/{group}/items',
      {
        scope,
        service,
        group,
      },
      queryConfigItemsByGroupQuery,
    )
  },

  /**
   * @summary 按配置组获取筛选服务内的配置数据
   * @param scope 作用域, 支持:
   *
   * 1. 学校级别配置，格式为 school. + schoolId, 比如 school.1
   * @param service 所属服务
   * @param group 配置组
   * @param [queryConfigItemsByGroupQuery]
   * @param [acmQuery.nameIn] 配置名称
   * @param [options]
   * @return AxiosResponsePromise<ConfigItem[]>
   */

  api(
    scope: string,
    service: string,
    group: string,
    queryConfigItemsByGroupQuery?: QueryConfigItemsByGroupQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<ConfigItem[]> {
    return axios(
      acmApi$queryConfigItemsByGroup.config(
        scope,
        service,
        group,
        queryConfigItemsByGroupQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new ConfigItem(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = ConfigItem[]>(
    {
      scope,
      service,
      group,
      query,
    }: {
      scope: string
      service: string
      group: string
      query?: QueryConfigItemsByGroupQuery
    },
    queryOptions?: QueryOptionsWithSelect<ConfigItem[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<ConfigItem[], TSelected>(
      acmApi$queryConfigItemsByGroup.api,
      'Acm.queryConfigItemsByGroup',
      queryOptions,
      scope,
      service,
      group,
      query,
    )
  },
}

// @ts-ignore
acmApi$queryConfigItemsByGroup.api._name_ = `Acm.queryConfigItemsByGroup`

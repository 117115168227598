/**
 * @file reflection view helpers
 */

import { createBoolEnumHelper, createEnumHelper } from '@seiue/util'

import { $t, createLocaleMessageHelper } from 'packages/locale'
import { RoleEnum, GenderEnum } from 'packages/sdks-next/chalk'

export const roleToLabel = createEnumHelper(() => ({
  [RoleEnum.Student]: $t('学生'),
  [RoleEnum.Teacher]: $t('教师'),
  [RoleEnum.Guardian]: $t('家长'),
  [RoleEnum.Staff]: $t('校外人员'),
  [RoleEnum.Shadow]: $t('用户'),
}))

export const getGuardianFieldLabels = () => ({
  name: $t('姓名'),
  account: $t('账号'),
  phone: $t('手机'),
  guardianRoleId: $t('身份'),
})

export const activeStatusToLabel = createBoolEnumHelper(() => [
  $t('已激活'),
  $t('未激活'),
])

export const genderToLabel = createEnumHelper(() => ({
  [GenderEnum.F]: $t('女'),
  [GenderEnum.M]: $t('男'),
}))

export const getArchivedTag = createLocaleMessageHelper(() => ({
  禁用: $t('禁用'),
  毕业: $t('毕业'),
  休学: $t('休学'),
  退学: $t('退学'),
  离职: $t('离职'),
  停职: $t('停职'),
})).get

import { compact, createEnumHelper } from '@seiue/util'

import { $t } from 'packages/locale'

import { Exam as ExamBase } from '../exam'
import { ExamPublishCcRoleEnum } from '../exam-publish-cc-role-enum'
import { ExamScheduleStatusEnum } from '../exam-schedule-status-enum'
import { ExamStatsSetting } from '../exam-stats-setting'
import { ExamStatusEnum } from '../exam-status-enum'
import { ExamTypeEnum } from '../exam-type-enum'

export type { ExamInterface } from '../exam'

const publishCcRoleToLabel = createEnumHelper(() => ({
  [ExamPublishCcRoleEnum.Student]: $t('学生'),
  [ExamPublishCcRoleEnum.Guardian]: $t('抄送家长'),
  [ExamPublishCcRoleEnum.AdminClassTeacher]: $t('班主任'),
  [ExamPublishCcRoleEnum.Teacher]: $t('任课教师'),
  [ExamPublishCcRoleEnum.Mentor]: $t('导师组'),
}))

/**
 * 考试
 */
export class Exam extends ExamBase {
  /**
   * 是否已发布考试
   *
   * @returns 是否
   */
  get isPublished() {
    return this.status === ExamStatusEnum.Published
  }

  /**
   * 是否已发布排考
   *
   * @returns 是否
   */
  get isSchedulePublished() {
    return this.scheduleStatus === ExamScheduleStatusEnum.Published
  }

  /**
   * 是否是草稿状态
   *
   * @returns 是否
   */
  get isDraft() {
    return this.status === ExamStatusEnum.Draft
  }

  /**
   * 是否启用了成绩单等级转换规则
   *
   * @returns 是否
   */
  get transcriptIndicatorEnabled() {
    return !!(
      this.statsData?.transcriptFieldToIndicatorGroupId &&
      Object.keys(this.statsData.transcriptFieldToIndicatorGroupId).length > 0
    )
  }

  /**
   * 是否为语言类考试（雅思、托福、SAT）
   *
   * @returns 是否
   */
  get isLanguageExam() {
    return [ExamTypeEnum.Ielts, ExamTypeEnum.Toefl, ExamTypeEnum.Sat].includes(
      this.examType,
    )
  }

  /**
   * 获取考试发布范围的角色文本
   *
   * @returns 角色文本
   */
  get publishCcRoleLabels() {
    return (
      this.customData?.publishCcRoles?.map(ccRole =>
        publishCcRoleToLabel.get(ccRole),
      ) ?? []
    )
  }

  /**
   * 判断指定角色是否在考试发布范围内
   *
   * @param ccRole - 抄送角色
   * @returns 是否
   */
  isPublishCcRole(ccRole: ExamPublishCcRoleEnum) {
    return !!this.customData?.publishCcRoles?.includes(ccRole)
  }

  /**
   * 获取考试的科目组合
   *
   * @param schoolExamSetting - 学校的考试设置
   * @returns 科目组合
   */
  getSubjectGroups(schoolExamSetting: ExamStatsSetting | null) {
    if (!schoolExamSetting?.subjectGroups?.length) return []

    const subjectGroupIds = this.statsData?.subjectGroups ?? []
    return compact(
      subjectGroupIds.map(groupId => {
        return schoolExamSetting.subjectGroups?.find(
          group => group.gid === groupId,
        )
      }),
    )
  }
}

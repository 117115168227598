/**
 * @file 注册 chalk 的通知详情导航
 */

import { registerMessageNavigator } from 'packages/features/messages/utils'

registerMessageNavigator(['reporting.warning_received'], msg => {
  const { link } =
    (msg.attributes as {
      link?: string
    }) || {}

  return link
    ? {
        path: link,
        pathInNewWindow: true,
      }
    : null
})

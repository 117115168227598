import { isObject } from '@seiue/util'

import {
  Message,
  Reflection,
  SecuredReflection,
} from 'packages/sdks-next/chalk'

export type Navigator<TRes = any> = (
  message: Message,
  currentReflection: SecuredReflection | Reflection,
) => TRes | (() => Promise<TRes>) | null

export type WebNavigatorRes = {
  /**
   * 标准页面：绝对路径
   */
  path?: string
  /**
   * 标准页面：是否在新窗口打开
   */
  pathInNewWindow?: boolean
  /**
   * 弹窗页面：弹窗名称
   */
  modalName?: string
  /**
   * 弹窗页面：弹窗页面参数
   */
  options?: any
}

/**
 * 判断是否为 chalk 的消息导航器
 *
 * @param navigatorRes - 导航参数
 * @returns 是否
 */
export const isWebNavigatorRes = (
  navigatorRes: any,
): navigatorRes is WebNavigatorRes =>
  isObject(navigatorRes) &&
  ((navigatorRes as any).path || (navigatorRes as any).modalName)

export type WebNavigator = Navigator<WebNavigatorRes>

export type NativeNavigatorRes = readonly [
  string,
  undefined | { [key: string]: any },
]

// React Native 端的导航器类型，我们允许返回 native 和 web 两种类型，
// 因为一个消息类型可能对应多个业务场景，而这些业务场景在 native 端并非都有实现
export type NativeNavigator = Navigator<NativeNavigatorRes | WebNavigatorRes>

export interface LabelStyle {
  color: string
  backgroundColor: string
}

export interface DomainType {
  getLabel: () => string
  labelStyle: LabelStyle
  value: string
  icon?: string
  messages: MessageType[]
  navigator?: Navigator
}

export interface MessageType {
  value: string
  domain: DomainType
  navigator?: Navigator
}

import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'

import { AppNameEnum } from 'packages/no-code/apps/types'

import type { ModuleEnum, PluginEnum, EnabledPlugin } from './types'
import { findUsableOrManageableChildPlugins } from './utils'

/**
 * 当前学校启用的插件
 */
export const enabledPluginsAtom = atom<EnabledPlugin[]>([])

/**
 * 当前用户在使用范围内的插件
 */
export const usablePluginsAtom = atom(get =>
  get(enabledPluginsAtom).filter(p => p.isVisible),
)

/**
 * 当前用户是管理员的插件
 */
export const manageablePluginsAtom = atom(get => {
  return get(enabledPluginsAtom).filter(p => p.isAdmin)
})

/**
 * 按名称筛选当前学校启用的插件
 */
export const findEnabledPluginsAtom = atomFamily(
  (name: ModuleEnum | PluginEnum | AppNameEnum | string) =>
    atom(get => get(enabledPluginsAtom).filter(p => p.pluginName === name)),
)

/**
 * 按名称筛选当前学校启用的父插件
 */
export const findEnabledParentPluginAtom = atomFamily(
  (name: ModuleEnum | PluginEnum | AppNameEnum | string) =>
    atom(get =>
      get(enabledPluginsAtom).find(p => p.pluginName === name && !p.parentId),
    ),
)

/**
 * 按名称筛选当前学校启用的子插件
 */
export const findEnabledChildPluginsAtom = atomFamily(
  (name: ModuleEnum | PluginEnum | AppNameEnum) =>
    atom(get =>
      get(enabledPluginsAtom).filter(
        p => p.pluginName === name && !!p.parentId,
      ),
    ),
)

/**
 * 按名称筛选当前用户可管理或可使用的子插件
 */
export const findUsableOrManageableChildPluginsAtom = atomFamily(
  (name: ModuleEnum | PluginEnum | AppNameEnum) =>
    atom(get =>
      findUsableOrManageableChildPlugins(get(enabledPluginsAtom), name),
    ),
)

/**
 * 根据插件 id 获取当前用户可管理或可使用的子插件
 */
export const findUsableOrManageableChildPluginAtom = atomFamily(
  (pluginId: number) =>
    atom(get =>
      get(enabledPluginsAtom).find(
        p => p.id === pluginId && !!p.parentId && (p.isVisible || p.isAdmin),
      ),
    ),
)

/**
 * 检查当前学校是否启用了指定插件
 */
export const hasEnabledPluginAtom = atomFamily(
  (name: ModuleEnum | PluginEnum | AppNameEnum) =>
    atom(get => get(enabledPluginsAtom).some(p => p.pluginName === name)),
)

/**
 * 按名称筛选当前用户是管理员的父插件 (最多只会有一个)
 */
export const findManageableParentPluginAtom = atomFamily(
  (name: ModuleEnum | PluginEnum | AppNameEnum) =>
    atom(get =>
      get(manageablePluginsAtom).find(
        p => p.pluginName === name && !p.parentId,
      ),
    ),
)

/**
 * 检查当前用户是否为指定插件的父插件管理员
 */
export const hasManageableParentPluginAtom = atomFamily(
  (name: ModuleEnum | PluginEnum | AppNameEnum) =>
    atom(get =>
      get(manageablePluginsAtom).some(
        p => p.pluginName === name && !p.parentId,
      ),
    ),
)

/**
 * 按名称筛选当前用户是管理员的子插件
 */
export const findManageableChildPluginsAtom = atomFamily(
  (name: ModuleEnum | PluginEnum | AppNameEnum) =>
    atom(get =>
      get(manageablePluginsAtom).filter(
        p => p.pluginName === name && p.parentId,
      ),
    ),
)

/**
 * 按名称获取当前用户在使用范围内的插件
 */
export const findUsablePluginsAtom = atomFamily(
  (name: ModuleEnum | PluginEnum | AppNameEnum) =>
    atom(get => get(usablePluginsAtom).filter(p => p.pluginName === name)),
)

/**
 * 检查当前用户是否在指定插件的使用范围内
 */
export const hasUsablePluginAtom = atomFamily(
  (name: ModuleEnum | PluginEnum | AppNameEnum) =>
    atom(get => get(usablePluginsAtom).some(p => p.pluginName === name)),
)

/**
 * 按名称获取当前用户在使用范围内的子插件
 */
export const findUsableChildPluginsAtom = atomFamily(
  (name: ModuleEnum | PluginEnum | AppNameEnum) =>
    atom(get =>
      get(usablePluginsAtom).filter(p => p.pluginName === name && p.parentId),
    ),
)

/**
 * @file register evaluation messages
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

import icon from './plugin.png'

registerDomainMessage({
  type: MessageDomainEnum.SchoolPlugin,
  getLabel: () => $t('插件商店'),
  icon,
  labelStyle: calculateLabelStyle('#eeecff', '#6a4bbe'),
  messages: ['agreed', 'rejected'],
})

/**
 * @file 顶部导航菜单
 */
import { Icon, Ellipsis, ZIndex } from '@seiue/ui'
import { isEqual, uniqBy } from '@seiue/util'
import { Menu } from 'antd'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'

import {
  usePersonalConfig,
  PersonalConfigKey,
} from 'packages/feature-utils/personal-configs'
import { $t } from 'packages/locale'
import { LayoutContext } from 'packages/web-layout/context'
import { getMenuLabel, matchMenu } from 'packages/web-layout/menu-utils'
import { LayoutMenus, MenuItemUnion } from 'packages/web-layout/types'

import { DropdownMenu } from '@/App/Layout/DropdownMenu'
import { useAddRecentEntry } from '@/features/plugins/utils/entry'

import { getDefaultMenuPath } from './utils'

/**
 * 顶部导航栏的左侧菜单, 目前包括"工作台"和"管理中心"
 *
 * @param args - args
 * @param args.menus - 系统中所有菜单的信息
 * @param args.navbarBackgroundColor - 导航栏背景色
 * @param args.dropdownMenuBackgroundColor - 下拉菜单背景色
 * @param args.showRecent - 是否显示最近访问
 * @param args.showTabs - 是否显示 Tabs
 * @param args.style - 容器样式
 *
 * @returns react element
 *
 */
export const TopperMenu = ({
  menus,
  navbarBackgroundColor,
  dropdownMenuBackgroundColor,
  showRecent,
  showTabs,
  style,
}: {
  menus: LayoutMenus
  navbarBackgroundColor: string
  dropdownMenuBackgroundColor: string
  showRecent?: boolean
  showTabs?: boolean
  style?: React.CSSProperties
}) => {
  const history = useHistory()
  const layoutContext = useContext(LayoutContext)
  const { activeMenuPath } = layoutContext
  const hoverTimer = React.useRef<ReturnType<typeof setTimeout>>()

  const [dropdownMenus, setDropdownMenus] = React.useState<any[] | null>(null)

  const [recentSetting, setRecentSetting] = usePersonalConfig(
    PersonalConfigKey.ChalkRecentVisitMenusConfig,
  )

  const flattenMenus = React.useMemo(
    () => [...menus.admin, ...menus.adminApps],
    [menus],
  )

  const filteredRecentMenus = React.useMemo(() => {
    return (recentSetting || []).filter(menu => {
      return matchMenu(menu.path, flattenMenus)
    })
  }, [flattenMenus, recentSetting])

  const topBarActiveKey = activeMenuPath.startsWith('/admin/')
    ? ['admin']
    : ['home']

  const manageMenus: {
    label: string
    subMenus: MenuItemUnion[]
  }[] = []

  if (menus.admin.length) {
    manageMenus.push({
      label: $t('核心功能'),
      subMenus: menus.admin,
    })
  }

  if (menus.adminApps.length) {
    manageMenus.push({
      label: $t('应用管理'),
      subMenus: menus.adminApps,
    })
  }

  const addRecent = useAddRecentEntry()

  return (
    <Wrapper navbarBackgroundColor={navbarBackgroundColor} style={style}>
      <Menu
        selectedKeys={topBarActiveKey}
        mode="horizontal"
        theme="dark"
        style={{ backgroundColor: navbarBackgroundColor, paddingLeft: 12 }}
      >
        <MenuItemStyled
          eventKey={'home'}
          onClick={() => {
            history.push('/')
          }}
          onMouseEnter={() => {
            setDropdownMenus(null)
          }}
          onMouseLeave={() => {
            setDropdownMenus(null)
          }}
        >
          {$t('工作台')}
        </MenuItemStyled>
        {manageMenus?.length ? (
          <MenuItemStyled
            $hovered={!!dropdownMenus}
            eventKey={'admin'}
            onMouseEnter={() => {
              if (hoverTimer.current) {
                clearTimeout(hoverTimer.current)
                hoverTimer.current = undefined
              }

              setDropdownMenus(manageMenus)
            }}
            onMouseLeave={() => {
              hoverTimer.current = setTimeout(() => {
                setDropdownMenus(null)
              }, 200)
            }}
          >
            <MenuContentWrapper>
              <span>{$t('管理中心')}</span>
              <IconStyled icon="ArrowDown" size={16} />
            </MenuContentWrapper>
          </MenuItemStyled>
        ) : null}
      </Menu>
      {dropdownMenus?.length ? (
        <DropdownMenu
          style={{
            position: 'absolute',
            width: 960,
            zIndex: ZIndex.Prompt + 1,
          }}
          title={$t('管理中心')}
          showTabs={showTabs}
          menus={dropdownMenus}
          onClose={() => setDropdownMenus(null)}
          onClickMenu={menu => {
            if (menu.name) {
              addRecent('menu', menu.name)
            }

            const path = getDefaultMenuPath(menu)
            const newRecent = uniqBy(
              [
                {
                  path,
                  label: getMenuLabel(menu),
                },
                ...(recentSetting || []),
              ],
              'path',
            ).slice(0, 5)

            if (!isEqual(recentSetting, newRecent)) {
              setRecentSetting(newRecent)
            }

            setDropdownMenus(null)
          }}
          topRightSlot={
            filteredRecentMenus.length && showRecent ? (
              <RecentVisits>
                {$t('最近访问：{pages}', {
                  pages: filteredRecentMenus.map(
                    (item: { label: string; path: string }, index: number) => (
                      <VisitItem
                        text={item.label}
                        key={index}
                        onClick={() => {
                          setDropdownMenus(null)
                          if (item.path.startsWith('http')) {
                            window.open(item.path, '_blank', 'noopener')
                          } else {
                            history.push(item.path)
                          }
                        }}
                        splitColor={dropdownMenuBackgroundColor}
                      >
                        {item.label}
                      </VisitItem>
                    ),
                  ),
                })}
              </RecentVisits>
            ) : null
          }
          dropdownMenuBackgroundColor={dropdownMenuBackgroundColor}
          onMouseEnter={() => {
            if (hoverTimer.current) {
              clearTimeout(hoverTimer.current)
              hoverTimer.current = undefined
            }

            setDropdownMenus(dropdownMenus)
          }}
          onMouseLeave={() => {
            setDropdownMenus(null)
          }}
        />
      ) : null}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ navbarBackgroundColor: string }>`
  position: relative;
  flex-grow: 1;

  &&& {
    .ant-menu-item {
      padding: 0 12px;
      color: rgba(255, 255, 255, 0.65);
    }

    .ant-menu-item-selected {
      color: #fff;
    }
    // 主题的一些配置
    .ant-menu-sub {
      background: ${props => props.navbarBackgroundColor};
    }
    .ant-menu-item {
      background: ${props => props.navbarBackgroundColor};
      :hover {
        background: rgba(0, 0, 0, 0.24);
      }
    }
  }
`

const MenuItemStyled = styled(Menu.Item)<{ $hovered?: boolean }>`
  ${props =>
    props.$hovered
      ? css`
          &&& {
            background: rgba(0, 0, 0, 0.24) !important;
          }
        `
      : ''};
`

const MenuContentWrapper = styled.div`
  display: flex;
  align-items: center;
`

const RecentVisits = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-left: 24px;
`

const VisitItem = styled(Ellipsis)<{ splitColor: string }>`
  max-width: 96px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.65);
  cursor: pointer;
  position: relative;
  padding-right: 8px;
  :hover {
    color: #fff;
  }
  & + & {
    padding: 0 8px;
    :before {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      width: 1px;
      height: 14px;
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
`

const IconStyled = styled(Icon)`
  &&& {
    margin: 0 0 0 8px;
  }
`

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  PatchItemWithIdReq,
  PatchItemWithIdReqInterface,
} from './patch-item-with-id-req'
import { Item, ItemInterface } from './extends/item'

const buildConfig = createConfigBuilder('vnas')

export const dormApi$patchDormItems = {
  config(
    patchItemWithIdReq: PatchItemWithIdReqInterface[],
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'patch',
      '/dorm/items/$batch',
      {},
      null,
      patchItemWithIdReq,
      {
        format: 'json',
        isArray: true,
        defaults: {
          multiEvaluators: (v: any) => v || false,
          isShortcut: (v: any) => v || false,
          name: (v: any) => v || '',
          description: (v: any) => v || '',
          topN: (v: any) => (v === 0 ? v : v || null),
          lockSetting: (v: any) => v || false,
          lockScore: (v: any) => (v === false ? v : v || null),
          lockRules: (v: any) => v || null,
          reviewNeeded: (v: any) => (v === false ? v : v || null),
          within: (v: any) => (v === false ? v : v || null),
          withinScore: (v: any) => v || null,
          fullScore: (v: any) => v || null,
          initialScore: (v: any) => v || null,
          computeType: (v: any) => v || null,
          weight: (v: any) => (v === 0 ? v : v || null),
          scoringType: (v: any) => v || null,
          tags: (v: any) => v || [],
          displayType: (v: any) => v || null,
          displayRules: (v: any) => v || [],
          displayLevelItems: (v: any) => v || [],
          relation: (v: any) => v || null,
          taskRelations: (v: any) => v || [],
          relationRules: (v: any) => v || null,
          sort: (v: any) => v || 0,
          enableAfterthought: (v: any) => (v === false ? v : v || null),
          stageId: (v: any) => (v === 0 ? v : v || null),
          settings: (v: any) => v || null,
        },
      },
    )
  },

  /**
   * @summary Class PatchDormItemsApi
   * 教务批量修改评价结构
   * @param patchItemWithIdReq
   * @param [options]
   * @return AxiosResponsePromise<Item[]>
   */

  api(
    patchItemWithIdReq: PatchItemWithIdReqInterface[],
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Item[]> {
    return axios(dormApi$patchDormItems.config(patchItemWithIdReq, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Item(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      (payload: PatchItemWithIdReqInterface[]) =>
        dormApi$patchDormItems.api(payload),
      mutationOptions,
    )
  },
}

// @ts-ignore
dormApi$patchDormItems.api._name_ = `Dorm.patchDormItems`

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { ConfigItem, ConfigItemInterface } from './extends/config-item'
import type { QueryConfigItemsByServiceQuery } from './query-config-items-by-service-query'

const buildConfig = createConfigBuilder('chalk')

export const acmApi$queryConfigItemsByService = {
  config(
    scope: string,
    service: string,
    queryConfigItemsByServiceQuery?: QueryConfigItemsByServiceQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/acm/scopes/{scope}/{service}/items',
      {
        scope,
        service,
      },
      queryConfigItemsByServiceQuery,
    )
  },

  /**
   * @summary 获取筛选服务内的配置数据
   * @param scope 作用域, 同 queryConfigItemsByGroup
   * @param service 所属服务
   * @param [queryConfigItemsByServiceQuery]
   * @param [acmQuery.groupIn] 配置组
   * @param [acmQuery.nameIn] 配置名称
   * @param [options]
   * @return AxiosResponsePromise<ConfigItem[]>
   */

  api(
    scope: string,
    service: string,
    queryConfigItemsByServiceQuery?: QueryConfigItemsByServiceQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<ConfigItem[]> {
    return axios(
      acmApi$queryConfigItemsByService.config(
        scope,
        service,
        queryConfigItemsByServiceQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new ConfigItem(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = ConfigItem[]>(
    {
      scope,
      service,
      query,
    }: {
      scope: string
      service: string
      query?: QueryConfigItemsByServiceQuery
    },
    queryOptions?: QueryOptionsWithSelect<ConfigItem[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<ConfigItem[], TSelected>(
      acmApi$queryConfigItemsByService.api,
      'Acm.queryConfigItemsByService',
      queryOptions,
      scope,
      service,
      query,
    )
  },
}

// @ts-ignore
acmApi$queryConfigItemsByService.api._name_ = `Acm.queryConfigItemsByService`

import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import { getCurrentReflection } from 'packages/feature-utils/store'
import { RoleEnum } from 'packages/sdks-next/chalk'
import {
  Assessment,
  MoralAssessmentTypeEnum,
  MoralPolicyEnum,
  commonApi$queryAssessmentsForEvaluator,
  commonApi$queryAssessmentsForEvaluated,
} from 'packages/sdks-next/vnas'

/**
 * 德育评价-我的录入 atom
 */
export const myTypeInMoralAssessmentsAtom = atom<Promise<Assessment[]>>(
  async () => {
    let assessments: Assessment[] = []
    const me = getCurrentReflection()
    if (me.role === RoleEnum.Teacher) {
      const { data } = await commonApi$queryAssessmentsForEvaluator.api(
        MoralAssessmentTypeEnum.Moral,
      )

      assessments = data
    } else {
      const { data } = await commonApi$queryAssessmentsForEvaluated.api(
        MoralAssessmentTypeEnum.Moral,
        me.id,
        MoralPolicyEnum.Evaluated,
        {
          meIsEvaluator: true,
        },
      )

      assessments = data
    }

    return assessments
  },
)

/**
 * 加载完的德育评价-我的录入 atom
 */
export const myTypeInMoralAssessmentsLoadableAtom = loadable(
  atom(get => get(myTypeInMoralAssessmentsAtom)),
)

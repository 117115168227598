import { PlanTypeEnum } from './plan-type-enum'
import { PlanCategoryEnum } from './plan-category-enum'
import { PlanRule, PlanRuleInterface } from './plan-rule'
import { PlanRoundTypeEnum } from './plan-round-type-enum'

export interface PlanInterface {
  name: string
  type: PlanTypeEnum
  category: PlanCategoryEnum
  rules: PlanRuleInterface[]
  /**
   * 及格线规则
   */
  passRule?: PlanRuleInterface | null
  /**
   * 开启 及格线
   */
  passEnabled: boolean
  /**
   * 开启 绩点
   */
  gpaEnabled: boolean
  /**
   * 开启 限定人数比例
   */
  percentLimitEnabled: boolean
  /**
   * 开启 学分绩点
   */
  creditGpaEnabled: boolean
  assessmentNum: number
  /**
   * 开启 人数向上取整规则
   */
  ceilEnabled: boolean
  /**
   * 取整标准
   */
  roundType: PlanRoundTypeEnum
  /**
   * 特殊评价规则
   */
  specialRules?: PlanRuleInterface[] | null
  /**
   * 开启 特殊评价
   */
  specialEnabled: boolean
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
}

export class Plan implements PlanInterface {
  name: string
  type: PlanTypeEnum
  category: PlanCategoryEnum
  rules: PlanRule[]
  /**
   * 及格线规则
   */
  passRule?: PlanRule | null
  /**
   * 开启 及格线
   */
  passEnabled: boolean
  /**
   * 开启 绩点
   */
  gpaEnabled: boolean
  /**
   * 开启 限定人数比例
   */
  percentLimitEnabled: boolean
  /**
   * 开启 学分绩点
   */
  creditGpaEnabled: boolean
  assessmentNum: number
  /**
   * 开启 人数向上取整规则
   */
  ceilEnabled: boolean
  /**
   * 取整标准
   */
  roundType: PlanRoundTypeEnum
  /**
   * 特殊评价规则
   */
  specialRules?: PlanRule[] | null
  /**
   * 开启 特殊评价
   */
  specialEnabled: boolean
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: PlanInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      type: _type,
      category: _category,
      rules: _rules,
      passRule: _passRule,
      passEnabled: _passEnabled,
      gpaEnabled: _gpaEnabled,
      percentLimitEnabled: _percentLimitEnabled,
      creditGpaEnabled: _creditGpaEnabled,
      assessmentNum: _assessmentNum,
      ceilEnabled: _ceilEnabled,
      roundType: _roundType,
      specialRules: _specialRules,
      specialEnabled: _specialEnabled,
      id: _id,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.type = _type
    this.category = _category
    this.rules = _rules ? _rules.map(i => new PlanRule(i)) : _rules
    this.passRule = _passRule ? new PlanRule(_passRule) : _passRule
    this.passEnabled = _passEnabled
    this.gpaEnabled = _gpaEnabled
    this.percentLimitEnabled = _percentLimitEnabled
    this.creditGpaEnabled = _creditGpaEnabled
    this.assessmentNum = _assessmentNum
    this.ceilEnabled = _ceilEnabled
    this.roundType = _roundType
    this.specialRules = _specialRules
      ? _specialRules.map(i => new PlanRule(i))
      : _specialRules
    this.specialEnabled = _specialEnabled
    this.id = _id
    this.schoolId = _schoolId
  }

  static propKeys = [
    'name',
    'type',
    'category',
    'rules',
    'passRule',
    'passEnabled',
    'gpaEnabled',
    'percentLimitEnabled',
    'creditGpaEnabled',
    'assessmentNum',
    'ceilEnabled',
    'roundType',
    'specialRules',
    'specialEnabled',
    'id',
    'schoolId',
  ]
}

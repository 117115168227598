import { OpenTimeLimit, OpenTimeLimitInterface } from './open-time-limit'

export interface OpenTimeRangeInterface {
  /**
   * 周几 （1，2，3，4，5，6，7）
   */
  weekDays: number[]
  ranges?: OpenTimeLimitInterface[]
}

export class OpenTimeRange implements OpenTimeRangeInterface {
  /**
   * 周几 （1，2，3，4，5，6，7）
   */
  weekDays: number[]
  ranges?: OpenTimeLimit[]

  constructor(props: OpenTimeRangeInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      weekDays: _weekDays,
      ranges: _ranges,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.weekDays = _weekDays
    this.ranges = _ranges ? _ranges.map(i => new OpenTimeLimit(i)) : _ranges
  }

  static propKeys = ['weekDays', 'ranges']
}

/**
 * 主题色名称
 */
export enum ThemeNameEnum {
  // 默认的主题色是由 theme.palette 中 background 组成
  Default = 'default',
  Palette1 = '1',
  Palette2 = '2',
  Palette3 = '3',
  Palette4 = '4',
  Palette5 = '5',
}

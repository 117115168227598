/**
 * @file register plugins navigator
 */

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator(
  ['customized.szhpsy_semester_report'],
  ({ attributes }) => {
    const { semesterId } = attributes as {
      semesterId: string
    }

    return {
      path: `/plugin/szhpsy-grade-export?semesterId=${semesterId}`,
    }
  },
)

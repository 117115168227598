/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import type { QueryEvaluatedForEvaluatedQuery } from './query-evaluated-for-evaluated-query'

const buildConfig = createConfigBuilder('vnas')

export const moralApi$queryEvaluatedForEvaluated = {
  config(
    id: number,
    queryEvaluatedForEvaluatedQuery?: QueryEvaluatedForEvaluatedQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/moral/evaluated/assessments/{id}/evaluated',
      {
        id,
      },
      queryEvaluatedForEvaluatedQuery,
    )
  },

  /**
   * @summary 适用行政班德育评价，评价班级的班主任查看在当前评价内自己管理范围内的班级
   * @param id
   * @param [queryEvaluatedForEvaluatedQuery]
   * @param [moralQuery.expand] 同 reflection，expand=grade
   * @param [moralQuery.paginated] 是否分页，默认 1
   * @param [moralQuery.page] 显示的数据页，默认 1
   * @param [moralQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<SecuredReflection[]>
   */

  api<const TQuery extends QueryEvaluatedForEvaluatedQuery>(
    id: number,
    queryEvaluatedForEvaluatedQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<SecuredReflection, TQuery['expand']>[]> {
    return axios(
      moralApi$queryEvaluatedForEvaluated.config(
        id,
        queryEvaluatedForEvaluatedQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new SecuredReflection(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryEvaluatedForEvaluatedQuery,
    TSelected = Expand<SecuredReflection, TQuery['expand']>[],
  >(
    {
      id,
      query,
    }: {
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<SecuredReflection, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<
      Expand<SecuredReflection, TQuery['expand']>[],
      TSelected
    >(
      moralApi$queryEvaluatedForEvaluated.api,
      'Moral.queryEvaluatedForEvaluated',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
moralApi$queryEvaluatedForEvaluated.api._name_ = `Moral.queryEvaluatedForEvaluated`

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Timetable, TimetableInterface } from './timetable'
import type { QueryTimetablesQuery } from './query-timetables-query'

const buildConfig = createConfigBuilder('scms')

export const timetableApi$queryTimetables = {
  config(
    queryTimetablesQuery?: QueryTimetablesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/timetable/timetables',
      {},
      queryTimetablesQuery,
    )
  },

  /**
   * @summary Class QueryTimetablesApi
   * @param [queryTimetablesQuery]
   * @param [timetableQuery.semesterId]
   * @param [timetableQuery.semesterIdIn]
   * @param [timetableQuery.classIdIn]
   * @param [timetableQuery.class.idIn]
   * @param [timetableQuery.class.idNotIn]
   * @param [timetableQuery.class.expand]
   * @param [timetableQuery.class.sort]
   * @param [timetableQuery.class.identity] csin/nama 或查询
   * @param [timetableQuery.class.csinIn]
   * @param [timetableQuery.class.csinLike]
   * @param [timetableQuery.class.nameIn]
   * @param [timetableQuery.class.nameLike]
   * @param [timetableQuery.class.creditEgt]
   * @param [timetableQuery.class.creditElt]
   * @param [timetableQuery.class.subjectIdIsEmpty]
   * @param [timetableQuery.class.subjectIdIn]
   * @param [timetableQuery.class.subjectIdNotIn]
   * @param [timetableQuery.class.domainIdIn]
   * @param [timetableQuery.class.attributeIdIn]
   * @param [timetableQuery.class.tagIdIn]
   * @param [timetableQuery.class.gradeIdsOverlaps]
   * @param [timetableQuery.class.gradeIdsIsEmpty]
   * @param [timetableQuery.class.courseId]
   * @param [timetableQuery.class.courseIdIn]
   * @param [timetableQuery.class.courseIdNotIn]
   * @param [timetableQuery.class.classNameIn]
   * @param [timetableQuery.class.classNameLike]
   * @param [timetableQuery.class.capacityEgt]
   * @param [timetableQuery.class.capacityElt]
   * @param [timetableQuery.class.teachers]
   * @param [timetableQuery.class.teachersIsEmpty]
   * @param [timetableQuery.class.classWeekEgt]
   * @param [timetableQuery.class.classWeekElt]
   * @param [timetableQuery.class.studentNumsEgt]
   * @param [timetableQuery.class.studentNumsElt]
   * @param [timetableQuery.class.classHourEgt]
   * @param [timetableQuery.class.classHourElt]
   * @param [timetableQuery.class.placeIdsOverlaps]
   * @param [timetableQuery.class.placeIdsIsEmpty]
   * @param [timetableQuery.class.timetableIdIn]
   * @param [timetableQuery.class.classTimes]
   * @param [timetableQuery.class.classTimesIsEmpty]
   * @param [timetableQuery.class.course]
   * @param [timetableQuery.class.metadata]
   * @param [timetableQuery.class.adminClasses]
   * @param [timetableQuery.class.classType] 课程班类型
   * @param [timetableQuery.class.classTypeIn]
   * @param [timetableQuery.class.classLessons]
   * @param [timetableQuery.class.lessonIdentityContains]
   * @param [timetableQuery.class.hasAdminClass] true - 获取关联行政班的课程班信息
   * false - 此参数不生效
   * @param [options]
   * @return AxiosResponsePromise<Timetable[]>
   */

  api(
    queryTimetablesQuery?: QueryTimetablesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Timetable[]> {
    return axios(
      timetableApi$queryTimetables.config(queryTimetablesQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Timetable(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = Timetable[]>(
    query?: QueryTimetablesQuery,
    queryOptions?: QueryOptionsWithSelect<Timetable[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Timetable[], TSelected>(
      timetableApi$queryTimetables.api,
      'Timetable.queryTimetables',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
timetableApi$queryTimetables.api._name_ = `Timetable.queryTimetables`

import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import { FlowTag, FlowTagInterface } from './flow-tag'
import { FlowNode, FlowNodeInterface } from './flow-node'
import { FlowStage, FlowStageInterface } from './flow-stage'

export interface FlowInterface {
  /**
   * expand 发起人
   */
  initiator?: SecuredReflectionInterface
  /**
   * expand 审批单标签
   */
  tags?: FlowTagInterface[]
  /**
   * expand 节点，注意：为了方便前端渲染，在这里的节点是按审批顺序排序，不是按时间排序。
   */
  nodes?: FlowNodeInterface[]
  /**
   * expanded 阶段
   */
  stages?: FlowStageInterface[]
  /**
   * expand 当前正在进行中的节点
   */
  pendingNode?: FlowNodeInterface | null
  /**
   * expand 当前处理人
   */
  pendingReflections?: SecuredReflectionInterface[]
  /**
   * 审批流 ID
   */
  workflowId: number
  /**
   * 学校插件 ID
   */
  schoolPluginId: number
  /**
   * 发起人 rid
   */
  initiatorId: number
  /**
   * 状态（各业务可自定义状态）
   */
  status: string
  /**
   * 可预测的（已知退出路径）
   */
  predictable: boolean
  /**
   * 用户提交的表单值，结构是 "[{field_name: xxx, value: xxx, class_name: xxx}]"
   * 当组件是日期区间时， value 的结构是 {start: xxx, end: xxx}
   */
  fieldValues: object[]
  /**
   * 评论
   */
  remark: string | null
  /**
   * 评价 1-5 颗星
   */
  stars: number | null
  /**
   * 催办次数
   */
  totalUrged: number
  /**
   * 办理结束时间
   */
  finishedAt?: string | null
  /**
   * 工作日处理时长（分钟）
   */
  weekdayMinutes?: number | null
  /**
   * 自然日处理时长（分钟）
   */
  fullDayMinutes?: number | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Flow implements FlowInterface {
  /**
   * expand 发起人
   */
  initiator?: SecuredReflection
  /**
   * expand 审批单标签
   */
  tags?: FlowTag[]
  /**
   * expand 节点，注意：为了方便前端渲染，在这里的节点是按审批顺序排序，不是按时间排序。
   */
  nodes?: FlowNode[]
  /**
   * expanded 阶段
   */
  stages?: FlowStage[]
  /**
   * expand 当前正在进行中的节点
   */
  pendingNode?: FlowNode | null
  /**
   * expand 当前处理人
   */
  pendingReflections?: SecuredReflection[]
  /**
   * 审批流 ID
   */
  workflowId: number
  /**
   * 学校插件 ID
   */
  schoolPluginId: number
  /**
   * 发起人 rid
   */
  initiatorId: number
  /**
   * 状态（各业务可自定义状态）
   */
  status: string
  /**
   * 可预测的（已知退出路径）
   */
  predictable: boolean
  /**
   * 用户提交的表单值，结构是 "[{field_name: xxx, value: xxx, class_name: xxx}]"
   * 当组件是日期区间时， value 的结构是 {start: xxx, end: xxx}
   */
  fieldValues: object[]
  /**
   * 评论
   */
  remark: string | null
  /**
   * 评价 1-5 颗星
   */
  stars: number | null
  /**
   * 催办次数
   */
  totalUrged: number
  /**
   * 办理结束时间
   */
  finishedAt?: string | null
  /**
   * 工作日处理时长（分钟）
   */
  weekdayMinutes?: number | null
  /**
   * 自然日处理时长（分钟）
   */
  fullDayMinutes?: number | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: FlowInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      initiator: _initiator,
      tags: _tags,
      nodes: _nodes,
      stages: _stages,
      pendingNode: _pendingNode,
      pendingReflections: _pendingReflections,
      workflowId: _workflowId,
      schoolPluginId: _schoolPluginId,
      initiatorId: _initiatorId,
      status: _status,
      predictable: _predictable,
      fieldValues: _fieldValues,
      remark: _remark,
      stars: _stars,
      totalUrged: _totalUrged,
      finishedAt: _finishedAt,
      weekdayMinutes: _weekdayMinutes,
      fullDayMinutes: _fullDayMinutes,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.initiator = _initiator ? new SecuredReflection(_initiator) : _initiator
    this.tags = _tags ? _tags.map(i => new FlowTag(i)) : _tags
    this.nodes = _nodes ? _nodes.map(i => new FlowNode(i)) : _nodes
    this.stages = _stages ? _stages.map(i => new FlowStage(i)) : _stages
    this.pendingNode = _pendingNode ? new FlowNode(_pendingNode) : _pendingNode
    this.pendingReflections = _pendingReflections
      ? _pendingReflections.map(i => new SecuredReflection(i))
      : _pendingReflections
    this.workflowId = _workflowId
    this.schoolPluginId = _schoolPluginId
    this.initiatorId = _initiatorId
    this.status = _status
    this.predictable = _predictable
    this.fieldValues = _fieldValues
    this.remark = _remark
    this.stars = _stars
    this.totalUrged = _totalUrged
    this.finishedAt = _finishedAt
    this.weekdayMinutes = _weekdayMinutes
    this.fullDayMinutes = _fullDayMinutes
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'initiator',
    'tags',
    'nodes',
    'stages',
    'pendingNode',
    'pendingReflections',
    'workflowId',
    'schoolPluginId',
    'initiatorId',
    'status',
    'predictable',
    'fieldValues',
    'remark',
    'stars',
    'totalUrged',
    'finishedAt',
    'weekdayMinutes',
    'fullDayMinutes',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

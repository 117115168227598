/**
 * @file 通用群组 data helper
 */

import { compact } from '@seiue/util'

import { useHasPluginManagementPermission } from 'packages/features/plugins/utils/check-plugin'
import { useIsCurrentSuperAdmin } from 'packages/features/roles'
import { useCurrentReflection } from 'packages/features/sessions/utils'
import {
  CustomGroup,
  GroupMember,
  GroupStatusEnum,
  GroupType,
} from 'packages/sdks-next/chalk'

/**
 * 根据 memberType 过滤 groupMembers
 *
 * @param members - 群组成员
 * @param memberType - 群组成员类型
 * @returns 群组成员 name 数组
 */
export const getGroupMembersByMemberType = (
  members: GroupMember[],
  memberType: string,
) =>
  compact(
    members.filter(m => m.memberType === memberType).map(m => m.reflection),
  )

/**
 * 根据 memberType 过滤 groupMembers， 返回 Ids
 *
 * @param members - 群组成员
 * @param memberType - 群组成员类型
 * @returns 群组成员 Id 数组
 */
export const getGroupMemberIdsByMemberType = (
  members: GroupMember[],
  memberType: string,
) =>
  compact(members.filter(m => m.memberType === memberType).map(m => m.memberId))

/**
 * 计算群组的 memberType 和 adminMemberType
 *
 * @param groupType - 群组类型
 * @returns 群组成员类型
 */
export const calcGroupMemberType = (groupType?: GroupType | null) => {
  const memberType = groupType?.roles?.find(v => !v.isAdmin)?.machineName
  const adminMemberType = groupType?.roles?.find(v => v.isAdmin)?.machineName

  return [memberType, adminMemberType]
}

/**
 * 是否有管理子插件的权限
 * 1、超管
 * 2、插件管理员
 * 3、子插件管理员
 * 4、群组管理员
 *
 * @param groupType - 群组类型
 * @returns 是否有管理插件的权限
 */
export const useIsCustomGroupAdmin = (groupType?: GroupType | null) => {
  const currentReflection = useCurrentReflection()
  const hasPermission = useHasPluginManagementPermission('customized-group')
  const isAdmin = groupType?.adminIds?.includes(currentReflection.id) ?? false
  const isSuper = useIsCurrentSuperAdmin()

  return isSuper || hasPermission || isAdmin
}

/**
 * 是否可以编辑子插件下的群组
 *
 * @param group - 群组
 * @returns 是否可以编辑群组
 */
export const useCanEditGroup = (group?: CustomGroup | null) => {
  const currentReflection = useCurrentReflection()
  const groupType = group?.typeEntity
  const isAdmin = useIsCustomGroupAdmin(groupType)
  const [, adminType] = calcGroupMemberType(groupType)
  const adminMembers = group?.members
    ?.filter(v => v.memberType === adminType)
    .map(v => v.memberId)

  const canEditGroup =
    group?.status === GroupStatusEnum.Normal &&
    (adminMembers?.includes(currentReflection.id) ?? false)

  return isAdmin || canEditGroup
}

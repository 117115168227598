/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import type { QueryReflectionsQuery } from './query-reflections-query'

const buildConfig = createConfigBuilder('form')

export const workflowApi$queryReflections = {
  config(
    queryReflectionsQuery?: QueryReflectionsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/workflow/reflections',
      {},
      queryReflectionsQuery,
    )
  },

  /**
   * @summary 查询学校下的人 （权限代理）
   * @param [queryReflectionsQuery]
   * @param [workflowQuery.schoolPluginId] workflow 对应的 school_plugin_id ，权限判断用
   * @param [workflowQuery.flowId] 权限判断用
   * @param [workflowQuery.paginated] 是否分页，默认 1
   * @param [workflowQuery.page] 显示的数据页，默认 1
   * @param [workflowQuery.perPage] 每页显示数量，默认 20
   * @param [workflowQuery.account]
   * @param [workflowQuery.usin]
   * @param [workflowQuery.idcard]
   * @param [workflowQuery.outerId]
   * @param [workflowQuery.idIn]
   * @param [workflowQuery.nameIn]
   * @param [workflowQuery.nameLike]
   * @param [workflowQuery.enameIn]
   * @param [workflowQuery.enameLike]
   * @param [workflowQuery.identity] name, usin, ename, pinyin 的模糊搜索
   * @param [workflowQuery.accountLike]
   * @param [workflowQuery.accountIn]
   * @param [workflowQuery.usinLike]
   * @param [workflowQuery.usinIn]
   * @param [workflowQuery.genderIn]
   * @param [workflowQuery.statusIn]
   * @param [workflowQuery.archivedTypeIdIn]
   * @param [workflowQuery.phoneIn]
   * @param [workflowQuery.phoneLike]
   * @param [workflowQuery.emailIn]
   * @param [workflowQuery.emailLike]
   * @param [workflowQuery.idcardIn]
   * @param [workflowQuery.idcardLike]
   * @param [workflowQuery.enteredOnElt]
   * @param [workflowQuery.enteredOnEgt]
   * @param [workflowQuery.workStartOnElt]
   * @param [workflowQuery.workStartOnEgt]
   * @param [workflowQuery.enteredYearsElt]
   * @param [workflowQuery.enteredYearsEgt]
   * @param [workflowQuery.workYearsElt]
   * @param [workflowQuery.workYearsEgt]
   * @param [workflowQuery.adminClassesContains]
   * @param [workflowQuery.adminClassesOverlaps]
   * @param [workflowQuery.adminClassesLike]
   * @param [workflowQuery.dorm]
   * @param [workflowQuery.dormLike]
   * @param [workflowQuery.dormIn]
   * @param [workflowQuery.dormAdminsContains]
   * @param [workflowQuery.dormAdminsOverlaps]
   * @param [workflowQuery.dormAdminsLike]
   * @param [workflowQuery.role]
   * @param [workflowQuery.roleIn]
   * @param [options]
   * @return AxiosResponsePromise<SecuredReflection[]>
   */

  api(
    queryReflectionsQuery?: QueryReflectionsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<SecuredReflection[]> {
    return axios(
      workflowApi$queryReflections.config(queryReflectionsQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new SecuredReflection(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = SecuredReflection[]>(
    query?: QueryReflectionsQuery,
    queryOptions?: QueryOptionsWithSelect<SecuredReflection[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<SecuredReflection[], TSelected>(
      workflowApi$queryReflections.api,
      'Workflow.queryReflections',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
workflowApi$queryReflections.api._name_ = `Workflow.queryReflections`

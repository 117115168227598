/**
 * @file group data helper
 */

import { useMemo } from 'react'

import { useSelectedSemesterId } from 'packages/features/semesters/utils/data'
import { Group } from 'packages/sdks-next/chalk'

/**
 * 筛选当前学期所在的 groups
 * @param groups
 */
export const useFilterJoinedGroupsBySelectedSemester = (
  groups: Group[] | null,
) => {
  const semesterId = useSelectedSemesterId()
  return useMemo(() => {
    if (!groups) return null
    if (!semesterId) return groups
    return groups.filter(group => {
      const label = group.labels as any
      if (!label.semesterId) return true
      // label.semesterId 是 string，待后端修正为 number
      return Number(label.semesterId) === semesterId
    })
  }, [groups, semesterId])
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { ClassinField, ClassinFieldInterface } from './classin-field'

const buildConfig = createConfigBuilder('chalk')

export const classinApi$queryClassinSettings = {
  config(options?: AxiosRequestExtraConfig): AxiosRequestConfig {
    return buildConfig(options, 'get', '/classin/classin-settings', {})
  },

  /**
   * @summary 获取classin存入acm的设置
   * @param [options]
   * @return AxiosResponsePromise<ClassinField>
   */

  api(options?: AxiosRequestExtraConfig): AxiosResponsePromise<ClassinField> {
    return axios(classinApi$queryClassinSettings.config(options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new ClassinField(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = ClassinField>(
    queryOptions?: QueryOptionsWithSelect<ClassinField, TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<ClassinField, TSelected>(
      classinApi$queryClassinSettings.api,
      'Classin.queryClassinSettings',
      queryOptions,
    )
  },
}

// @ts-ignore
classinApi$queryClassinSettings.api._name_ = `Classin.queryClassinSettings`

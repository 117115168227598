import {
  CertificationLogResource,
  CertificationLogResourceInterface,
} from './certification-log-resource'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'

export interface CertificationLogInterface {
  resource?: CertificationLogResourceInterface | null
  /**
   * 主键 ID
   */
  id: string
  /**
   * 学校 Id
   */
  schoolId: string
  /**
   * 文本描述
   */
  summary: string
  /**
   * 自定义属性
   */
  attributes?: object | null
  /**
   * 操作时间
   */
  operatedAt: string
  /**
   * 操作者ID
   */
  operatorId: string
  /**
   * 操作者IP
   */
  ip?: string
  /**
   * 操作者OS
   */
  os?: string
  /**
   * 操作者浏览器
   */
  browser?: string
  /**
   * 操作者地址
   */
  address?: string
  /**
   * 操作者对象
   */
  operator?: SecuredReflectionInterface | null
  /**
   * 事件类型
   */
  eventAction?: string | null
}

export class CertificationLog implements CertificationLogInterface {
  resource?: CertificationLogResource | null
  /**
   * 主键 ID
   */
  id: string
  /**
   * 学校 Id
   */
  schoolId: string
  /**
   * 文本描述
   */
  summary: string
  /**
   * 自定义属性
   */
  attributes?: object | null
  /**
   * 操作时间
   */
  operatedAt: string
  /**
   * 操作者ID
   */
  operatorId: string
  /**
   * 操作者IP
   */
  ip?: string
  /**
   * 操作者OS
   */
  os?: string
  /**
   * 操作者浏览器
   */
  browser?: string
  /**
   * 操作者地址
   */
  address?: string
  /**
   * 操作者对象
   */
  operator?: SecuredReflection | null
  /**
   * 事件类型
   */
  eventAction?: string | null

  constructor(props: CertificationLogInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      resource: _resource,
      id: _id,
      schoolId: _schoolId,
      summary: _summary,
      attributes: _attributes,
      operatedAt: _operatedAt,
      operatorId: _operatorId,
      ip: _ip,
      os: _os,
      browser: _browser,
      address: _address,
      operator: _operator,
      eventAction: _eventAction,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.resource = _resource
      ? new CertificationLogResource(_resource)
      : _resource
    this.id = _id
    this.schoolId = _schoolId
    this.summary = _summary
    this.attributes = _attributes
    this.operatedAt = _operatedAt
    this.operatorId = _operatorId
    this.ip = _ip
    this.os = _os
    this.browser = _browser
    this.address = _address
    this.operator = _operator ? new SecuredReflection(_operator) : _operator
    this.eventAction = _eventAction
  }

  static propKeys = [
    'resource',
    'id',
    'schoolId',
    'summary',
    'attributes',
    'operatedAt',
    'operatorId',
    'ip',
    'os',
    'browser',
    'address',
    'operator',
    'eventAction',
  ]
}

import { Term, TermInterface } from './extends/term'

export interface ChildPlaceInterface {
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId?: number
  /**
   * 父ID
   */
  parentId?: number
  /**
   * 名称
   */
  name: string
  /**
   * 描述
   */
  description?: string | null
  /**
   * 容量
   */
  capacity?: number | null
  /**
   * 允许同时多活动数量，0为不允许
   */
  concurrencyLimit?: number
  /**
   * 空间分类 expand
   */
  category?: TermInterface | null
  /**
   * 状态
   */
  status?: number
  /**
   * 空间所在建筑 expand
   */
  building?: TermInterface | null
  /**
   * 空间所在楼层
   */
  floor?: number | null
  /**
   * 空间分类ID
   */
  categoryId?: number | null
  /**
   * 所属建筑ID
   */
  buildingId?: number | null
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class ChildPlace implements ChildPlaceInterface {
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId?: number
  /**
   * 父ID
   */
  parentId?: number
  /**
   * 名称
   */
  name: string
  /**
   * 描述
   */
  description?: string | null
  /**
   * 容量
   */
  capacity?: number | null
  /**
   * 允许同时多活动数量，0为不允许
   */
  concurrencyLimit?: number
  /**
   * 空间分类 expand
   */
  category?: Term | null
  /**
   * 状态
   */
  status?: number
  /**
   * 空间所在建筑 expand
   */
  building?: Term | null
  /**
   * 空间所在楼层
   */
  floor?: number | null
  /**
   * 空间分类ID
   */
  categoryId?: number | null
  /**
   * 所属建筑ID
   */
  buildingId?: number | null
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: ChildPlaceInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      schoolId: _schoolId,
      parentId: _parentId,
      name: _name,
      description: _description,
      capacity: _capacity,
      concurrencyLimit: _concurrencyLimit,
      category: _category,
      status: _status,
      building: _building,
      floor: _floor,
      categoryId: _categoryId,
      buildingId: _buildingId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
    this.schoolId = _schoolId
    this.parentId = _parentId
    this.name = _name
    this.description = _description
    this.capacity = _capacity
    this.concurrencyLimit = _concurrencyLimit
    this.category = _category ? new Term(_category) : _category
    this.status = _status
    this.building = _building ? new Term(_building) : _building
    this.floor = _floor
    this.categoryId = _categoryId
    this.buildingId = _buildingId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'id',
    'schoolId',
    'parentId',
    'name',
    'description',
    'capacity',
    'concurrencyLimit',
    'category',
    'status',
    'building',
    'floor',
    'categoryId',
    'buildingId',
    'createdAt',
    'updatedAt',
  ]
}

export enum TermTypeEnum {
  SystemAcademicYear = 'system.academic_year',
  SystemSemesterCategory = 'system.semester_category',
  SystemGraduatesIn = 'system.graduates_in',
  SystemSchoolStructure = 'system.school_structure',
  GuardianRole = 'guardian.role',
  StudentArchivedType = 'student.archived_type',
  TeacherArchivedType = 'teacher.archived_type',
  StaffArchivedType = 'staff.archived_type',
  /**
   * @deprecated 请勿使用，教师学科和课程科目已统一，请使用 TermTypeEnum.CourseSubject
   */
  TeacherDiscipline = 'teacher.discipline',
  PlaceCategory = 'place.category',
  PlaceBuilding = 'place.building',
  CourseSubject = 'course.subject',
  CourseEnSubject = 'course.en_subject',
  CourseAttribute = 'course.attribute',
  CourseDomain = 'course.domain',
  ClassTag = 'class.tag',
  GoalDirectionType = 'goal.direction_type',
  GoalGoalType = 'goal.goal_type',
  VenueVenueType = 'venue.venue_type',
  CertificationType = 'certification.certification_type',
  CertificationSubject = 'certification.subject',
  DirectionSubject = 'direction.subject_type',
  GoalSubjectsSelection = 'academic_goal.subject_type',
  PsychologicalInterventionInSchoolType = 'psychological.in_school_type',
  PsychologicalInterventionOutSchoolType = 'psychological.out_school_type',
  PsychologicalSlightProblemType = 'psychological.slight_problem',
  PsychologicalObviouslyProblemType = 'psychological.obviously_problem',
  PsycoFileProblemType = 'psychological.problem_type',
  PsycoFileProblemSource = 'psychological.problem_source',
  HandoutGrade = 'handout.grade',
  /**
   * @deprecated 请勿使用，学案学科和课程科目已统一，请使用 TermTypeEnum.CourseSubject
   */
  HandoutSubject = 'handout.subject',
  HandoutOutline = 'handout.outline',
  HandoutSubjectKnowledgePoint = 'handout.subject_knowledge_point',
  HandoutSubjectCoreLiteracy = 'handout.subject_core_literacy',
  HandoutSubjectAbility = 'handout.subject_ability',
  HandoutTag = 'handout.tag',
  QuestionnaireTag = 'questionnaire.questionnaire_tag',
  QuestionnaireSettings = 'questionnaire.settings',
  ExamType = 'exam.exam_type',
  ReportCategory = 'reporting.report_category',
  /**
   * 通知管理分析
   */
  'NotifCategory' = 'notif.category',
  ScoreInvalidType = 'score.invalid_type',
  /**
   * 应用分类
   */
  ApplicationGroup = 'application.group',
  /**
   * 用于处理前端模拟 term 格式的数据
   */
  Simulation = 'simulation',
  /**
   * 学期评语任务类型
   */
  SemesterReviewTaskType = 'semester_review.task_type',
}

import { DepartmentTypeEnum } from './department-type-enum'

export interface DepartmentInterface {
  /**
   * 学校 ID
   */
  schoolId?: number
  /**
   * 创建时间
   */
  createdAt?: string
  /**
   * 更新时间
   */
  updatedAt?: string
  selfMemberCounts?: number
  allMemberCounts?: number
  parentId?: number
  type?: DepartmentTypeEnum
  name: string
  description?: string
  weight?: number
  relatedGroupType?: string
  relatedGroupId?: number
  adminIds?: number[]
  /**
   * 主键 ID
   */
  id: number
}

export class Department implements DepartmentInterface {
  /**
   * 学校 ID
   */
  schoolId?: number
  /**
   * 创建时间
   */
  createdAt?: string
  /**
   * 更新时间
   */
  updatedAt?: string
  selfMemberCounts?: number
  allMemberCounts?: number
  parentId?: number
  type?: DepartmentTypeEnum
  name: string
  description?: string
  weight?: number
  relatedGroupType?: string
  relatedGroupId?: number
  adminIds?: number[]
  /**
   * 主键 ID
   */
  id: number

  constructor(props: DepartmentInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      selfMemberCounts: _selfMemberCounts,
      allMemberCounts: _allMemberCounts,
      parentId: _parentId,
      type: _type,
      name: _name,
      description: _description,
      weight: _weight,
      relatedGroupType: _relatedGroupType,
      relatedGroupId: _relatedGroupId,
      adminIds: _adminIds,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
    this.selfMemberCounts = _selfMemberCounts
    this.allMemberCounts = _allMemberCounts
    this.parentId = _parentId
    this.type = _type
    this.name = _name
    this.description = _description
    this.weight = _weight
    this.relatedGroupType = _relatedGroupType
    this.relatedGroupId = _relatedGroupId
    this.adminIds = _adminIds
    this.id = _id
  }

  static propKeys = [
    'schoolId',
    'createdAt',
    'updatedAt',
    'selfMemberCounts',
    'allMemberCounts',
    'parentId',
    'type',
    'name',
    'description',
    'weight',
    'relatedGroupType',
    'relatedGroupId',
    'adminIds',
    'id',
  ]
}

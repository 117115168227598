/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Item, ItemInterface } from './extends/item'
import type { LoadKlassItemQuery } from './load-klass-item-query'

const buildConfig = createConfigBuilder('vnas')

export const defaultApi$loadKlassItem = {
  config(
    id: number,
    loadKlassItemQuery?: LoadKlassItemQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/klass/items/{id}',
      {
        id,
      },
      loadKlassItemQuery,
    )
  },

  /**
   * @summary Class LoadKlassItemApi
   * 教务教师获取单个评价结构
   * @param id
   * @param [loadKlassItemQuery]
   * @param [defaultQuery.expand]
   * @param [options]
   * @return AxiosResponsePromise<Item>
   */

  api<const TQuery extends LoadKlassItemQuery>(
    id: number,
    loadKlassItemQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Item, TQuery['expand']>> {
    return axios(
      defaultApi$loadKlassItem.config(id, loadKlassItemQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new Item(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends LoadKlassItemQuery,
    TSelected = Expand<Item, TQuery['expand']>,
  >(
    {
      id,
      query,
    }: {
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<Item, TQuery['expand']>,
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Item, TQuery['expand']>, TSelected>(
      defaultApi$loadKlassItem.api,
      'Default.loadKlassItem',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
defaultApi$loadKlassItem.api._name_ = `Default.loadKlassItem`

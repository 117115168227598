import { env } from '@seiue/env'
import {
  TrackingProvider as TrackingProviderRaw,
  useTracking,
} from '@seiue/tracking'
import { parseURLQuery } from '@seiue/util'
import { Location } from 'history'
import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import {
  useEnableTracking,
  useSetTrackingUserId,
} from 'packages/features/tracking/utils'
import { findMatchRoute, modalRoutes, routes } from 'packages/route'

/**
 * 埋点记录页面访问
 */
const usePageViewTracking = () => {
  const history = useHistory()
  const lastLocationRef = useRef<Location | null>(null)
  const { trackPageView } = useTracking()

  useEffect(() => {
    const unlisten = history.listen(location => {
      // visiting new page
      if (location.pathname !== lastLocationRef.current?.pathname) {
        const route = findMatchRoute(location.pathname, routes)
        trackPageView({ documentTitle: route?.getTitle() })
      } else {
        const urlQuery = parseURLQuery(location.search)
        const lastUrlQuery = parseURLQuery(lastLocationRef.current.search)

        // opening new modal
        if (urlQuery['modal'] && lastUrlQuery['modal'] !== urlQuery['modal']) {
          const modalRoute = modalRoutes.find(
            mr => mr.name === urlQuery['modal'],
          )

          trackPageView({ documentTitle: modalRoute?.getTitle() })
        }
      }

      lastLocationRef.current = location
    })

    return () => {
      unlisten()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, trackPageView])
}

const Inner: React.FC = ({ children }) => {
  // 设置埋点用户 id
  useSetTrackingUserId()

  // Track page views
  usePageViewTracking()
  return <>{children}</>
}

/**
 * Matomo tracking provider
 *
 * @param params - params
 * @param params.children - children nodes
 * @returns react node
 */
export const TrackingProvider: React.FC = ({ children }) => {
  const enabled = useEnableTracking()

  return (
    <TrackingProviderRaw
      siteId={+env('THIRD_PARTY_MATOMO_CHALK_ID')}
      enabled={enabled}
    >
      <Inner>{children}</Inner>
    </TrackingProviderRaw>
  )
}

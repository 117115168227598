/**
 * @file register plugins navigator
 */
import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator(
  [
    'teacher_profile.approved',
    'teacher_profile.rejected',
    'teacher_profile.revoke_approved',
    'teacher_profile.revoke_rejected',
    'teacher_profile.uncompleted_notice_for_reflection',
  ],
  ({ attributes }) => {
    const id = attributes?.id
    return {
      modalName: 'SubmitTeacherProfileModal',
      options: { formId: id },
    }
  },
)

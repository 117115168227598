/**
 * @file 注册调代课消息
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.IntlGoal,
  getLabel: () => $t('升学目标'),
  labelStyle: calculateLabelStyle('#EBE3EC', '#77577D'),
  messages: [
    'goal_submitted',
    {
      type: 'goal_submitted',
      important: true,
    },
    'goal_changed',
    {
      type: 'goal_changed',
      important: true,
    },
  ],
})

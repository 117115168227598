import { GstTemplateResourceSettingFieldTypeEnum } from './gst-template-resource-setting-field-type-enum'

export interface GstTemplateResourceSettingFieldInterface {
  /**
   * 字段名称对应值 eg:name
   */
  name: string
  /**
   * 字段名称 eg:姓名
   */
  label: string
  /**
   * 字段类型
   */
  type: GstTemplateResourceSettingFieldTypeEnum
  /**
   * 若类型为enum，此字段存储字段选项
   */
  options?: string[] | null
  /**
   * 字段描述
   */
  description?: string | null
  /**
   * 固定字段值为true
   * 添加字段值为false
   */
  isDefault: boolean
}

export class GstTemplateResourceSettingField
  implements GstTemplateResourceSettingFieldInterface
{
  /**
   * 字段名称对应值 eg:name
   */
  name: string
  /**
   * 字段名称 eg:姓名
   */
  label: string
  /**
   * 字段类型
   */
  type: GstTemplateResourceSettingFieldTypeEnum
  /**
   * 若类型为enum，此字段存储字段选项
   */
  options?: string[] | null
  /**
   * 字段描述
   */
  description?: string | null
  /**
   * 固定字段值为true
   * 添加字段值为false
   */
  isDefault: boolean

  constructor(props: GstTemplateResourceSettingFieldInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      label: _label,
      type: _type,
      options: _options,
      description: _description,
      isDefault: _isDefault,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.label = _label
    this.type = _type
    this.options = _options
    this.description = _description
    this.isDefault = _isDefault
  }

  static propKeys = [
    'name',
    'label',
    'type',
    'options',
    'description',
    'isDefault',
  ]
}

// 各种注册

import { $t } from 'packages/locale'

import 'packages/features/schedules/message/register'
import 'packages/features/exam-rooms/message/register'
import 'packages/features/exams/message/register'
import 'packages/features/venues/message/register'
import 'packages/features/grades/messages/class/register'
import 'packages/features/grades/messages/dorm/register'
import 'packages/features/grades/messages/moral/register'
import 'packages/features/grades/messages/grade-reflection/register'
import 'packages/features/attendances/message/register'
import 'packages/features/discussions/message/register'
import 'packages/features/academics/message/register'
import 'packages/features/tasks/message/register'
import 'packages/features/notices/message/register'
import 'packages/features/school-calendars/message/register'
import 'packages/features/class-declarations/message/register'
import 'packages/features/class-stop/message/register'
import 'packages/features/evaluations/message/register'
import 'packages/features/plugins/message/register'
import 'packages/features/classes/message/register'
import 'packages/features/subject-selections/message/register'
import 'packages/features/certification/message/register'
import 'packages/plugins/message/register'
import 'packages/features/elections/message/register'
import 'packages/features/events/message/register'
import 'packages/features/cgr/message/register'
import 'packages/features/questionnaire/message/register'
import 'packages/features/teacher-profile/message/register'
import 'packages/features/workflows/message/register'
import 'packages/features/admin-classes/message/register'
import 'packages/features/eems/message/register'
import 'packages/features/dorm/message/register'

import { MessageDomainEnum } from '../types'

import { calculateLabelStyle, registerDomainMessage } from './utils'

export * from './utils'
export * from './types'

registerDomainMessage({
  type: MessageDomainEnum.Message,
  getLabel: () => $t('消息'),
  labelStyle: calculateLabelStyle('#EDF9E7', '#619348'),

  /*
   * HACK
   * 注册它自己
   */
  messages: [''],
})

/**
 * @file 活动消息
 */

import { registerMessageNavigator } from 'packages/features/messages/register'
import { Message } from 'packages/sdks-next/chalk'

registerMessageNavigator(
  ['event.member_invited', 'event.member_approved'],
  ({ attributes }) => {
    const { eventId, instanceId } = attributes as any
    if (eventId && instanceId)
      return {
        path: `/plugin/event/${instanceId}/view/${eventId}/as/member/home`,
      }
    return null
  },
)

registerMessageNavigator(['event.approval_approved'], ({ attributes }) => {
  const { eventId, instanceId } = attributes as any
  if (eventId && instanceId)
    return {
      path: `/plugin/event/${instanceId}/view/${eventId}/as/initiator/home`,
    }
  return null
})

registerMessageNavigator(
  ['event.member_rejected', 'event.member_removed'],
  ({
    attributes,
    schoolId,
  }: Message & {
    schoolId?: number
  }) => {
    const { eventId, instanceId } = attributes as any
    if (eventId && instanceId)
      return {
        path: `/share/${schoolId}/events/${instanceId}/info/${eventId}`,
      }
    return null
  },
)

registerMessageNavigator(['event.checkin'], ({ attributes }) => {
  const { eventId, instanceId } = attributes as any
  if (eventId && instanceId)
    return {
      path: `/plugin/event/${instanceId}/view/${eventId}/as/member/attendance`,
    }
  return null
})

registerMessageNavigator(
  [
    'event.approval_rejected',
    'event.approval_expired',
    'event.approval_revoked',
    'event.approval_changed',
    'event.flow_cc',
  ],
  ({ attributes }) => {
    const { eventId, instanceId } = attributes as any

    if (eventId && instanceId) {
      return {
        modalName: 'ApplicationDetail',
        options: {
          pid: instanceId,
          eid: eventId,
        },
      }
    }

    return null
  },
)

registerMessageNavigator('event.topic_marked_excellent', ({ attributes }) => {
  const { receivedByManager } = attributes as any
  const [instanceId, eventId] = (attributes as any).subjectIdentity.split('|')
  if (eventId && instanceId)
    return {
      path: `/plugin/event/${instanceId}/view/${eventId}/as/${
        receivedByManager ? 'initiator' : 'member'
      }/discussion`,
    }
  return null
})

registerMessageNavigator('event.discussion_replied', ({ attributes }) => {
  const { id: eventId, subjectIdentity, receivedByManager } = attributes as any
  const [instanceId] = (subjectIdentity as string).split('|')

  if (eventId && instanceId)
    return {
      path: `/plugin/event/${instanceId}/view/${eventId}/as/${
        receivedByManager ? 'initiator' : 'member'
      }/discussion`,
    }
  return null
})

registerMessageNavigator('event.discussion_commented', ({ attributes }) => {
  const { id: eventId, subjectIdentity, receivedByManager } = attributes as any
  const [instanceId] = (subjectIdentity as string).split('|')

  if (eventId && instanceId)
    return {
      path: `/plugin/event/${instanceId}/view/${eventId}/as/${
        receivedByManager ? 'initiator' : 'member'
      }/discussion`,
    }
  return null
})

registerMessageNavigator(['event.urge_for_approver'], ({ attributes }) => {
  const { relatedData } = attributes as any

  const { id: eventId, instanceId } = relatedData?.event || {}

  if (eventId && instanceId) {
    return {
      modalName: 'ApplicationDetail',
      options: {
        pid: instanceId,
        eid: eventId,
      },
    }
  }

  return null
})

/**
 * @file 与 react native 中 webview 相关的 utils
 */

/**
 * 触发事件到 webview
 *
 * @deprecated use postToWebview from sodash-rn instead
 *
 * @param type - 事件类型
 * @param data - 事件数据
 */
export const sendDataToRnWebview = (type: string, data: any) => {
  const rnWebView = (window as any).ReactNativeWebView
  if (rnWebView) rnWebView.postMessage(JSON.stringify({ type, data }))
}

/**
 * 检测是否在 go 宿主的 webview 环境中
 *
 * @returns - boolean
 */
export const isInGoWebview = () =>
  !!window?.navigator?.userAgent?.includes('c3app')

import {
  ClassTypeWithOnlineC,
  ClassTypeWithOnlineCInterface,
} from './class-type-with-online-c'
import { BaseShare, BaseShareInterface } from './base-share'
import { OpenScope, OpenScopeInterface } from './open-scope'

export interface ShareRelationInterface {
  /**
   * 分享的班级 id
   */
  classId: number
  /**
   * 班级的 expand, 班级字段 class.xxx, 线上课字段 class.online_class.xxx
   */
  class?: ClassTypeWithOnlineCInterface | null
  /**
   * 分享的id
   */
  shareId: number
  /**
   * 分享的expand, 取共享校范围或者已加入课程班的学校数量 share.xxx
   */
  share?: BaseShareInterface | null
  /**
   * 接受分享学校的id
   */
  sharedSchoolId: number
  /**
   * 已经根据分享的班级加入了哪些课程班
   */
  joinedClassIds?: number[]
  /**
   * 是否已经加入开放课
   */
  joinedRecordedClass: boolean
  /**
   * {"student": {"id_in": ""}, "teacher": {}, "staff": {}}
   * 不涵盖 {"id_in": ""}，涵盖所有 {}, 部分涵盖 {"id_in": "1,2"}
   */
  openScope?: OpenScopeInterface
  /**
   * 冗余的课程班教师
   */
  classTeacherNames?: string[]
  /**
   * 冗余的课程年级信息
   */
  classGradeNames?: string[]
  onlineClassTimes?: number
  className: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class ShareRelation implements ShareRelationInterface {
  /**
   * 分享的班级 id
   */
  classId: number
  /**
   * 班级的 expand, 班级字段 class.xxx, 线上课字段 class.online_class.xxx
   */
  class?: ClassTypeWithOnlineC | null
  /**
   * 分享的id
   */
  shareId: number
  /**
   * 分享的expand, 取共享校范围或者已加入课程班的学校数量 share.xxx
   */
  share?: BaseShare | null
  /**
   * 接受分享学校的id
   */
  sharedSchoolId: number
  /**
   * 已经根据分享的班级加入了哪些课程班
   */
  joinedClassIds?: number[]
  /**
   * 是否已经加入开放课
   */
  joinedRecordedClass: boolean
  /**
   * {"student": {"id_in": ""}, "teacher": {}, "staff": {}}
   * 不涵盖 {"id_in": ""}，涵盖所有 {}, 部分涵盖 {"id_in": "1,2"}
   */
  openScope?: OpenScope
  /**
   * 冗余的课程班教师
   */
  classTeacherNames?: string[]
  /**
   * 冗余的课程年级信息
   */
  classGradeNames?: string[]
  onlineClassTimes?: number
  className: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: ShareRelationInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      classId: _classId,
      class: _class,
      shareId: _shareId,
      share: _share,
      sharedSchoolId: _sharedSchoolId,
      joinedClassIds: _joinedClassIds,
      joinedRecordedClass: _joinedRecordedClass,
      openScope: _openScope,
      classTeacherNames: _classTeacherNames,
      classGradeNames: _classGradeNames,
      onlineClassTimes: _onlineClassTimes,
      className: _className,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.classId = _classId
    this.class = _class ? new ClassTypeWithOnlineC(_class) : _class
    this.shareId = _shareId
    this.share = _share ? new BaseShare(_share) : _share
    this.sharedSchoolId = _sharedSchoolId
    this.joinedClassIds = _joinedClassIds
    this.joinedRecordedClass = _joinedRecordedClass
    this.openScope = _openScope ? new OpenScope(_openScope) : _openScope
    this.classTeacherNames = _classTeacherNames
    this.classGradeNames = _classGradeNames
    this.onlineClassTimes = _onlineClassTimes
    this.className = _className
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'classId',
    'class',
    'shareId',
    'share',
    'sharedSchoolId',
    'joinedClassIds',
    'joinedRecordedClass',
    'openScope',
    'classTeacherNames',
    'classGradeNames',
    'onlineClassTimes',
    'className',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

import { Term, TermInterface } from './extends/term'

export interface SemesterInterface {
  /**
   * Id
   */
  id: number
  /**
   * 学校Id
   */
  schoolId: number
  /**
   * 学年Id
   */
  academicYearId: number
  /**
   * 学期类型Id
   */
  categoryId: number
  /**
   * 学期名
   */
  name: string
  /**
   * 学期开始时间
   */
  startAt: string
  /**
   * 学期结束时间
   */
  endAt: string
  /**
   * 年级届别对应关系
   */
  gradeMaps?: object | null
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt?: string | null
  /**
   * expand
   * 学年
   */
  academicYear?: TermInterface
  /**
   * expand
   * 学期类型
   */
  category?: TermInterface
  /**
   * 是否是当前学期
   */
  isCurrent?: boolean
  /**
   * 如果是当前学期,当前的周数
   */
  currentWeek?: number | null
}

export class Semester implements SemesterInterface {
  /**
   * Id
   */
  id: number
  /**
   * 学校Id
   */
  schoolId: number
  /**
   * 学年Id
   */
  academicYearId: number
  /**
   * 学期类型Id
   */
  categoryId: number
  /**
   * 学期名
   */
  name: string
  /**
   * 学期开始时间
   */
  startAt: string
  /**
   * 学期结束时间
   */
  endAt: string
  /**
   * 年级届别对应关系
   */
  gradeMaps?: object | null
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt?: string | null
  /**
   * expand
   * 学年
   */
  academicYear?: Term
  /**
   * expand
   * 学期类型
   */
  category?: Term
  /**
   * 是否是当前学期
   */
  isCurrent?: boolean
  /**
   * 如果是当前学期,当前的周数
   */
  currentWeek?: number | null

  constructor(props: SemesterInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      schoolId: _schoolId,
      academicYearId: _academicYearId,
      categoryId: _categoryId,
      name: _name,
      startAt: _startAt,
      endAt: _endAt,
      gradeMaps: _gradeMaps,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      academicYear: _academicYear,
      category: _category,
      isCurrent: _isCurrent,
      currentWeek: _currentWeek,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
    this.schoolId = _schoolId
    this.academicYearId = _academicYearId
    this.categoryId = _categoryId
    this.name = _name
    this.startAt = _startAt
    this.endAt = _endAt
    this.gradeMaps = _gradeMaps
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
    this.academicYear = _academicYear ? new Term(_academicYear) : _academicYear
    this.category = _category ? new Term(_category) : _category
    this.isCurrent = _isCurrent
    this.currentWeek = _currentWeek
  }

  static propKeys = [
    'id',
    'schoolId',
    'academicYearId',
    'categoryId',
    'name',
    'startAt',
    'endAt',
    'gradeMaps',
    'createdAt',
    'updatedAt',
    'academicYear',
    'category',
    'isCurrent',
    'currentWeek',
  ]
}

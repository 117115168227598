/**
 * @file 课程班消息详情注册
 */

import { env } from '@seiue/env'

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator(
  ['member_type.student', 'member_type.teacher'],
  ({ attributes }) => ({
    path: `/classes/${(attributes as any)?.id}`,
  }),
)

registerMessageNavigator(
  ['class.athena_course_released'],
  ({ id, attributes }) => {
    if ((attributes as any)?.result) {
      return undefined
    }

    return {
      path: `${env('CLIENT_ATHENA')}/task/release-errors/${id}`,
      pathInNewWindow: true,
    }
  },
)

registerMessageNavigator('class_assessment.daily_report', ({ id }) => ({
  modalName: 'ClassGradeDailyReportModal',
  options: {
    id,
  },
}))

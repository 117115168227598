/**
 * @file 全屏弹窗主体部分
 */

import React, { useRef } from 'react'
import styled from 'styled-components'

import { BackToTop } from 'packages/components/Page/BackToTop'

export interface ContentProps {
  style?: React.CSSProperties
  contentStyle?: React.CSSProperties
  contentWrapperStyle?: React.CSSProperties
  readyToRenderContent: boolean
  contentWidth: string
  zIndex?: number
  useBackToTop?: boolean
  children?: React.ReactNode
}

export interface ContentRef {
  scrollTo: (x: number, y: number) => void
}

export const Content = React.forwardRef<ContentRef, ContentProps>(
  (
    {
      style,
      readyToRenderContent,
      contentWidth,
      contentStyle,
      contentWrapperStyle,
      zIndex,
      children,
      useBackToTop = false,
    },
    ref,
  ) => {
    const contentRef = useRef<HTMLDivElement>(null)

    React.useImperativeHandle(ref, () => ({
      scrollTo: (x, y) => {
        contentRef.current?.scrollTo(x, y)
      },
    }))

    return (
      <ContentWrapper
        className="seiue-full-screen-modal__content"
        style={{ ...style, ...contentWrapperStyle }}
        ref={contentRef}
      >
        {readyToRenderContent && (
          <ContentAvailibleBox width={contentWidth} style={contentStyle}>
            {children}
          </ContentAvailibleBox>
        )}
        {useBackToTop ? (
          <BackToTop
            scrollTarget={contentRef.current}
            bottom={73}
            zIndex={zIndex}
          />
        ) : null}
      </ContentWrapper>
    )
  },
)

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 84px 0 80px 0;
  overflow: auto;
`

const ContentAvailibleBox = styled.div<{ width: string }>`
  width: ${props => props.width};
  margin: 0 auto;
`

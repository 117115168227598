import type { SchoolExtLocaleTextCategoryEnum } from '../school-ext-locale-text-category-enum'

export * from '../school-ext-locale-text-category-enum'

/**
 * 直接提取出分类字符串 "common" | "plugin"... 的 string union
 * 这样使用时 $ct('text', 'common')，而不是
 * $ct('text', SchoolExtLocaleTextCategoryEnum.Common)
 */
export type LocaleTextCategory = `${SchoolExtLocaleTextCategoryEnum}`

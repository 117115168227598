import { ExamSessionTypeEnum } from './exam-session-type-enum'
import { ExamineeRule, ExamineeRuleInterface } from './examinee-rule'
import { ExamSessionStatusEnum } from './exam-session-status-enum'
import {
  ExaminerVenueScheduleResult,
  ExaminerVenueScheduleResultInterface,
} from './examiner-venue-schedule-result'
import { Patroller, PatrollerInterface } from './patroller'
import { Supporter, SupporterInterface } from './supporter'

export interface ExamSessionInterface {
  type: ExamSessionTypeEnum
  name: string
  date?: string | null
  startAt?: string | null
  endAt?: string | null
  /**
   * 考试时长
   */
  minutes?: number | null
  /**
   * 考生编排规则
   */
  examineeRules: ExamineeRuleInterface
  /**
   * expand 关联科目名称
   */
  examSubjectName?: string
  /**
   * 参与本场次考试的学生 rid
   */
  examineeRids?: number[]
  /**
   * 状态
   */
  status: ExamSessionStatusEnum
  /**
   * expand 关联监考安排
   */
  examinerVenueScheduleResults?: ExaminerVenueScheduleResultInterface[]
  patrollers?: PatrollerInterface[] | null
  /**
   * expand 考务及机动监考组 - 本质是 ExamReflection 避免循环引用使用
   */
  supporters?: SupporterInterface[] | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 考试 id
   */
  examId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class ExamSession implements ExamSessionInterface {
  type: ExamSessionTypeEnum
  name: string
  date?: string | null
  startAt?: string | null
  endAt?: string | null
  /**
   * 考试时长
   */
  minutes?: number | null
  /**
   * 考生编排规则
   */
  examineeRules: ExamineeRule
  /**
   * expand 关联科目名称
   */
  examSubjectName?: string
  /**
   * 参与本场次考试的学生 rid
   */
  examineeRids?: number[]
  /**
   * 状态
   */
  status: ExamSessionStatusEnum
  /**
   * expand 关联监考安排
   */
  examinerVenueScheduleResults?: ExaminerVenueScheduleResult[]
  patrollers?: Patroller[] | null
  /**
   * expand 考务及机动监考组 - 本质是 ExamReflection 避免循环引用使用
   */
  supporters?: Supporter[] | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 考试 id
   */
  examId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: ExamSessionInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      type: _type,
      name: _name,
      date: _date,
      startAt: _startAt,
      endAt: _endAt,
      minutes: _minutes,
      examineeRules: _examineeRules,
      examSubjectName: _examSubjectName,
      examineeRids: _examineeRids,
      status: _status,
      examinerVenueScheduleResults: _examinerVenueScheduleResults,
      patrollers: _patrollers,
      supporters: _supporters,
      id: _id,
      schoolId: _schoolId,
      examId: _examId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.type = _type
    this.name = _name
    this.date = _date
    this.startAt = _startAt
    this.endAt = _endAt
    this.minutes = _minutes
    this.examineeRules = _examineeRules
      ? new ExamineeRule(_examineeRules)
      : _examineeRules
    this.examSubjectName = _examSubjectName
    this.examineeRids = _examineeRids
    this.status = _status
    this.examinerVenueScheduleResults = _examinerVenueScheduleResults
      ? _examinerVenueScheduleResults.map(
          i => new ExaminerVenueScheduleResult(i),
        )
      : _examinerVenueScheduleResults
    this.patrollers = _patrollers
      ? _patrollers.map(i => new Patroller(i))
      : _patrollers
    this.supporters = _supporters
      ? _supporters.map(i => new Supporter(i))
      : _supporters
    this.id = _id
    this.schoolId = _schoolId
    this.examId = _examId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'type',
    'name',
    'date',
    'startAt',
    'endAt',
    'minutes',
    'examineeRules',
    'examSubjectName',
    'examineeRids',
    'status',
    'examinerVenueScheduleResults',
    'patrollers',
    'supporters',
    'id',
    'schoolId',
    'examId',
    'createdAt',
    'updatedAt',
  ]
}

export interface EvaluationScoreStatsDimensionInterface {
  name: string
  fields?: string[]
}

export class EvaluationScoreStatsDimension
  implements EvaluationScoreStatsDimensionInterface
{
  name: string
  fields?: string[]

  constructor(props: EvaluationScoreStatsDimensionInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      fields: _fields,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.fields = _fields
  }

  static propKeys = ['name', 'fields']
}

import {
  CertificationReflection,
  CertificationReflectionInterface,
} from './extends/certification-reflection'

export interface CertReflectionsInfoInterface {
  /**
   * 已获得学生认证次数
   */
  passedCount: number
  /**
   * 审批中学生次数
   */
  waitingCount: number
  /**
   * 最后一次获得的详情（授予人、获得类型、获得时间）
   */
  lastedPassed?: CertificationReflectionInterface | null
}

export class CertReflectionsInfo implements CertReflectionsInfoInterface {
  /**
   * 已获得学生认证次数
   */
  passedCount: number
  /**
   * 审批中学生次数
   */
  waitingCount: number
  /**
   * 最后一次获得的详情（授予人、获得类型、获得时间）
   */
  lastedPassed?: CertificationReflection | null

  constructor(props: CertReflectionsInfoInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      passedCount: _passedCount,
      waitingCount: _waitingCount,
      lastedPassed: _lastedPassed,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.passedCount = _passedCount
    this.waitingCount = _waitingCount
    this.lastedPassed = _lastedPassed
      ? new CertificationReflection(_lastedPassed)
      : _lastedPassed
  }

  static propKeys = ['passedCount', 'waitingCount', 'lastedPassed']
}

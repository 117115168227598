/**
 * @file 全局脏表单检查组件
 * 如果页面上有未保存的内容，组件会弹窗拦截跳转
 */

import { env } from '@seiue/env'
import { parseURLQuery } from '@seiue/util'
import { Location } from 'history'
import React, { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Prompt, useLocation } from 'react-router-dom'

import { $t } from 'packages/locale'

import { PageDirtyFlagsState } from './store'

export * from './store'
export * from './use-page-dirty-flag'

const listener = (e: any) => {
  // Cancel the event as stated by the standard.
  e.preventDefault()
  // Chrome requires returnValue to be set.
  e.returnValue = ''
}

/**
 * 检查是否没有离开页面
 *
 * @param location - 路由信息
 * @param nextLocation - 下一个路由信息
 * @returns boolean
 */
const isNotLeaving = (location: Location, nextLocation: Location) => {
  const onSamePage = location.pathname === nextLocation.pathname
  const locationQueries = parseURLQuery(location.search)
  const nextLocationQueries = parseURLQuery(nextLocation.search)
  const isOpeningModalRoute =
    !!nextLocationQueries['modal'] &&
    typeof nextLocationQueries['modalQuery'] === 'string'

  const isOpeningFirstModal = !locationQueries['modal']
  const isOpeningSameModal =
    locationQueries['modal'] === nextLocationQueries['modal']

  return (
    onSamePage &&
    isOpeningModalRoute &&
    (isOpeningFirstModal || isOpeningSameModal)
  )
}

/**
 * 全局脏表单检查组件
 *
 * @returns React.ReactNode
 */
export const PageDirtyPrompt: React.FC = () => {
  const location = useLocation()
  const locationKey = location.key
  const locationKeys = useSelector(
    ({ pageDirtyFlags }: { pageDirtyFlags: PageDirtyFlagsState }) =>
      pageDirtyFlags.locationKeys,
  )

  const isDirty = useMemo(() => {
    const flags = locationKey && locationKeys[locationKey]
    if (!flags) return false
    return Object.values(flags).some(Boolean)
  }, [locationKeys, locationKey])

  const getMessage = (nextLocation: Location) => {
    const defaultMessage = $t('确认要离开页面吗？你将丢失尚未发布的内容')

    return isNotLeaving(location, nextLocation) ? true : defaultMessage
  }

  useEffect(() => {
    if (isDirty && env('ENV') !== 'test') {
      window.addEventListener('beforeunload', listener)
    }

    return () => window.removeEventListener('beforeunload', listener)
  }, [isDirty])

  return <Prompt message={getMessage} when={isDirty} />
}

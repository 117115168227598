export interface SecurityGroupInterface {
  /**
   * 学校id
   */
  schoolId: number
  /**
   * 主键 ID
   */
  id: number
  /**
   * 所属领域
   */
  domain: string
  /**
   * 分组名称
   */
  name: string
  /**
   * 包含字段
   */
  fieldNames?: string[] | null
}

export class SecurityGroup implements SecurityGroupInterface {
  /**
   * 学校id
   */
  schoolId: number
  /**
   * 主键 ID
   */
  id: number
  /**
   * 所属领域
   */
  domain: string
  /**
   * 分组名称
   */
  name: string
  /**
   * 包含字段
   */
  fieldNames?: string[] | null

  constructor(props: SecurityGroupInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      id: _id,
      domain: _domain,
      name: _name,
      fieldNames: _fieldNames,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.id = _id
    this.domain = _domain
    this.name = _name
    this.fieldNames = _fieldNames
  }

  static propKeys = ['schoolId', 'id', 'domain', 'name', 'fieldNames']
}

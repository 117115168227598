import { Moment, willOverlap } from '@seiue/moment'

import { TimetableDetail } from 'packages/sdks-next/chalk'

/**
 * 过滤出指定范围内的课表详情
 *
 * @param details - 课表详情列表
 * @param range - 范围
 * @returns 过滤后的课表详情
 */
export const filterTimetableDetailsByRange = (
  details: TimetableDetail[],
  range: [Moment, Moment],
) => {
  return details.filter(item => {
    return item.effectiveDates?.some(effectiveDate => {
      return willOverlap(
        effectiveDate,
        {
          startAt: range[0],
          endAt: range[1],
        },
        {
          inclusive: true,
        },
      )
    })
  })
}

/* eslint-disable seiue/missing-formatted-message */
// FIXME: 需要重构此文件，以令其更好的适配国际化内容

/* eslint-disable @typescript-eslint/naming-convention */

import { RoleEnum } from 'packages/sdks-next/chalk'

export type RoleName = 'student' | 'teacher' | 'admin' | 'guardian'

export enum RoleLabel {
  '学生' = 1,
  '老师' = 2,
  '教务' = 4,
  '家长' = 8,
}

export enum Role {
  student = 1,
  teacher = 2,
  admin = 4,
  guardian = 8,
}

/**
 * 判断用户角色
 */
export function hasRole(roleName: RoleName, role: Role): boolean {
  // 排除大部分异常 Role 值
  if (role < 0 || role > 15) return false

  // eslint-disable-next-line no-bitwise
  return !!(Role[roleName] & role)
}

/**
 * 对 2.0 多角色数据进行角色断言
 * 但凡有 role 包含老师角色，都视为老师
 */
export function normalizeRole(role: Role) {
  if (hasRole('teacher', role)) {
    return Role.teacher
  }

  return role
}

/**
 * 获取角色标签
 * 2.0 的多角色用户按教务，教师，家长，学生的顺序进行判断与获取
 */
export function getRoleLabel(role: Role) {
  let label = ''

  ;[1, 8, 2, 4].forEach(value => {
    if (hasRole(Role[value] as RoleName, role)) {
      label = RoleLabel[value]
    }
  })

  return label
}

export const v3RoleToV2 = (role: RoleEnum) => {
  switch (role) {
    case RoleEnum.Student:
      return Role.student
    case RoleEnum.Guardian:
      return Role.guardian
    case RoleEnum.Teacher:
      return Role.teacher
    default:
      return Role.teacher
  }
}

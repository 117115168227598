import { Place, PlaceInterface } from './place'
import { RepeatTypeEnum } from './repeat-type-enum'
import { RepeatSetting, RepeatSettingInterface } from './repeat-setting'
import { FinishTypeEnum } from './finish-type-enum'
import { RemindSetting, RemindSettingInterface } from './remind-setting'

export interface GroupScheduleInterface {
  id?: number
  place?: PlaceInterface | null
  isFullday?: boolean
  startTime: string
  endTime: string
  repeatType?: RepeatTypeEnum
  repeatSettings?: RepeatSettingInterface
  finishType?: FinishTypeEnum
  finishCount?: number
  finishDate?: string | null
  remindSettings?: RemindSettingInterface
  placeId?: number | null
  memberIds?: number[]
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class GroupSchedule implements GroupScheduleInterface {
  id?: number
  place?: Place | null
  isFullday?: boolean
  startTime: string
  endTime: string
  repeatType?: RepeatTypeEnum
  repeatSettings?: RepeatSetting
  finishType?: FinishTypeEnum
  finishCount?: number
  finishDate?: string | null
  remindSettings?: RemindSetting
  placeId?: number | null
  memberIds?: number[]
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: GroupScheduleInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      place: _place,
      isFullday: _isFullday,
      startTime: _startTime,
      endTime: _endTime,
      repeatType: _repeatType,
      repeatSettings: _repeatSettings,
      finishType: _finishType,
      finishCount: _finishCount,
      finishDate: _finishDate,
      remindSettings: _remindSettings,
      placeId: _placeId,
      memberIds: _memberIds,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
    this.place = _place ? new Place(_place) : _place
    this.isFullday = _isFullday
    this.startTime = _startTime
    this.endTime = _endTime
    this.repeatType = _repeatType
    this.repeatSettings = _repeatSettings
      ? new RepeatSetting(_repeatSettings)
      : _repeatSettings
    this.finishType = _finishType
    this.finishCount = _finishCount
    this.finishDate = _finishDate
    this.remindSettings = _remindSettings
      ? new RemindSetting(_remindSettings)
      : _remindSettings
    this.placeId = _placeId
    this.memberIds = _memberIds
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'id',
    'place',
    'isFullday',
    'startTime',
    'endTime',
    'repeatType',
    'repeatSettings',
    'finishType',
    'finishCount',
    'finishDate',
    'remindSettings',
    'placeId',
    'memberIds',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

import { ChatInstanceSettings as Base } from '../chat-instance-settings'
import { ChatInstanceSettingsStudentApplyChatModeEnum } from '../chat-instance-settings-student-apply-chat-mode-enum'

export type { ChatInstanceSettingsInterface } from '../chat-instance-settings'

/**
 * 约谈实例
 */
export class ChatInstanceSettings extends Base {
  /**
   * 是否为定期预约模式
   *
   * @returns 是否
   */
  get isRegularMode() {
    return (
      this.studentApplyChatMode ===
      ChatInstanceSettingsStudentApplyChatModeEnum.Regular
    )
  }
}

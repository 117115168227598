import { AxiosResponsePromise } from '@seiue/axios'

import {
  ListFilterOptionsQuery,
  ListVariablesQuery,
  Report,
  ReportInterface,
  ReportQuery,
  ReportVariable,
} from 'packages/sdks-next/nuwa'

import { WidgetExtra } from './widget'

export * from '../Report/types'

export enum ReportNameEnum {
  /**
   * 宿舍数据统计
   */
  DormAssessmentAnalysis = 'dorm-assessment-analysis',

  /**
   * 过评录入统计
   */
  ScoreAnalysis = 'score-analysis',

  /**
   * 心理测评-心理预警分析
   */
  PsyForewarningAnalysis = 'psy-forewarning-analysis',
}

export type VariableExtra = ReportVariable & {
  value: any
}

export type ReportQueryExtra = Omit<ReportQuery, 'id'> & {
  id: string | number
}

export type ReportExtra = Omit<ReportInterface, 'widgets'> & {
  widgets: WidgetExtra[]
}

export interface ReportApis {
  /**
   * 加载报表配置的方法
   *
   * @param args - 参数
   */
  loadReport?: (identity: string) => AxiosResponsePromise<Report>
  /**
   * 加载报表查询配置的方法
   *
   * @param args - 参数
   */
  loadReportQueries?: (identity: string) => AxiosResponsePromise<ReportQuery[]>
  /**
   * 查询报表中变量的方法
   *
   * @param args - 参数
   */
  loadVariables?: (
    identity: string,
    query: ListVariablesQuery,
  ) => AxiosResponsePromise<ReportVariable[]>
  /**
   * 查询报表中筛选条件中选项的方法
   *
   * @param args - 参数
   */
  loadFilterOptions?: (
    identity: string,
    filterName: string,
    query?: ListFilterOptionsQuery,
  ) => AxiosResponsePromise<any[]>
}

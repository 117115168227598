// 普通函数，前后端通用
import { moment } from '@seiue/moment'
import {
  get,
  sum,
  max,
  min,
  includes,
  map,
  numberToChineseMoney,
  isNaN,
} from '@seiue/util'
import { camelize } from 'humps'

import { sumToDecimal } from 'packages/utils/decimal'

/**
 * 拼接字符串
 *
 * @param args - 字符串列表
 * @returns 拼接后的字符串
 */
export const concat = (...args: any[]): any => args.join('')

/**
 * 获取字段值
 *
 * @param record - 数据
 * @param name - 字段名称
 * @returns 字段值
 */
export const attr = (record: Record<string, any>, name: string) => {
  return get(record, camelize(name))
}

/**
 * 获取数组中的某个字段
 *
 * @param list - 数组
 * @param name - 字段名称
 * @returns 字段值列表
 */
export const _map = (list: any[], name: any) => {
  return map(list, camelize(name))
}

/**
 * 求和
 *
 * @param nums - 数字列表
 * @returns 求和结果
 */
export const _sum = (nums: (number | string)[]) => {
  if (!nums.length) return 0

  const filteredNums = nums.filter(num => num != null)

  if (!filteredNums.length) return null

  // 后端的 Decimal 类型字段存的的是一个字符串, 需要转换成数字
  return sumToDecimal(filteredNums.map(num => Number(num))).toNumber()
}

/**
 * 转换数字为中文大写金额
 *
 * @param num - 数字
 * @returns 中文大写金额
 */
export const toChineseMoney = (num?: number | string | null) => {
  const parsed = Number(num)
  if (num == null || isNaN(parsed)) {
    return ''
  }

  return numberToChineseMoney(Number(num))
}

/**
 * 求平均值
 *
 * @param nums - 数字列表
 * @returns 平均值
 */
export const _avg = (nums: number[]) => {
  return nums.length ? sum(nums) / nums.length : null
}

/**
 * 求最大值
 *
 * @param nums - 数字列表
 * @returns 最大值
 */
export const _max = (nums: number[]) => {
  return max(nums)
}

/**
 * 求最小值
 *
 * @param nums - 数字列表
 * @returns 最小值
 */
export const _min = (nums: number[]) => {
  return min(nums)
}

/**
 * 是否包含
 *
 * @param list - 字符串或者数组
 * @param str - 字符串
 * @returns boolean
 */
export const contains = (list: string | any[], str: string) => {
  return includes(list, str)
}

/**
 * if 语句
 *
 * @param condition - 条件
 * @param then - then
 * @param _else - else
 * @returns then 或者 else
 */
export const _if = (condition: boolean, then: any, _else: any): any =>
  condition ? then : _else

/**
 * if null 语句
 *
 * @param condition - 条件
 * @param then - then
 * @returns condition 或者 then
 */
export const ifNull = (condition: boolean, then: any): any =>
  condition !== null && condition !== undefined ? condition : then

// RFC 3339 格式
export const RFC3339_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

/**
 * 获取当前时间
 *
 * @param format - 格式
 * @returns 当前时间
 */
export const now = (format?: string) => {
  if (!format) {
    return moment().format(RFC3339_FORMAT)
  }

  return moment().format(format)
}

/**
 * 获取当前日期
 *
 * @returns 当前日期
 */
export const date = () => {
  return moment().format('YYYY-MM-DD')
}

/**
 * 获取当前年份
 *
 * @returns 当前年份
 */
export const _year = () => {
  return moment().format('YYYY')
}

/**
 * 内置函数
 */
export const builtinFunctions = {
  concat,
  if: _if,
  if_null: ifNull,
  now,
  date,
  attr,
  map: _map,
  sum: _sum,
  avg: _avg,
  max: _max,
  min: _min,
  contains,
  year: _year,
  to_chinese_money: toChineseMoney,
}

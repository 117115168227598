/**
 * @file 下拉菜单的 MenuGroup 组件
 */
import { Ellipsis, Icon } from '@seiue/ui'
import React from 'react'
import styled from 'styled-components'

import { useIsEnLocale } from 'packages/locale'

import { getDefaultMenuPath } from '../utils'

import { AnyLink } from './AnyLink'
import { MenuGroup as MenuGroupType, MenuSingleItem } from './types'

/**
 * 下拉菜单的 MenuGroup 组件
 *
 * @param param - Argument Object
 * @param param.menu - 菜单项
 * @param param.onClickMenu - 点击菜单项的回调
 * @param param.dropdownMenuBackgroundColor - 背景色
 * @constructor
 */
export const MenuGroup = ({
  menu,
  onClickMenu,
  dropdownMenuBackgroundColor,
}: {
  menu: MenuGroupType
  onClickMenu?: (menu: MenuSingleItem) => void
  dropdownMenuBackgroundColor: string
}) => {
  const isEn = useIsEnLocale()

  const containerRef = React.useRef<HTMLDivElement>(null)

  // 瀑布流 grid 布局网格计算
  const updateContainerGridStyle = () => {
    const el = containerRef.current
    if (el) {
      const { height } = el?.getBoundingClientRect()
      // 设置当前跨越几个网格(每个网格 10px)
      el.style.gridRowEnd = `span ${height / 10}`
    }
  }

  React.useEffect(() => {
    updateContainerGridStyle()
  }, [menu])

  React.useEffect(() => {
    window.addEventListener('resize', updateContainerGridStyle)
    return () => {
      window.removeEventListener('resize', updateContainerGridStyle)
    }
  }, [])

  return menu?.subMenus?.length ? (
    <GroupWrapper
      ref={containerRef}
      className="management-center-item"
      $isEn={isEn}
    >
      <GroupHeader>
        <Ellipsis text={menu.label}>
          {menu.icon && <Icon icon={menu.icon} size={16} {...menu.iconProps} />}
          <GroupHeaderText>{menu.label}</GroupHeaderText>
        </Ellipsis>
        <Divider color={dropdownMenuBackgroundColor} />
      </GroupHeader>
      <GroupBody $isEn={isEn}>
        {menu.subMenus.map((item, index) => (
          <MenuItem
            to={getDefaultMenuPath(item)}
            before={async () => {
              onClickMenu?.(item)
              return item.before ? item.before() : true
            }}
            key={index}
            $isEn={isEn}
          >
            <Ellipsis text={item.label} />
            <Icon
              className={'arrow-icon'}
              icon="ArrowRightLong"
              size={16}
              style={{ cursor: 'pointer' }}
            />
          </MenuItem>
        ))}
      </GroupBody>
    </GroupWrapper>
  ) : null
}

const GroupWrapper = styled.div<{ $isEn: boolean }>`
  width: ${p => (p.$isEn ? '248px' : '195px')};
  height: fit-content;
  padding: 12px;
  grid-row-start: auto;
`

const GroupHeader = styled.div`
  position: relative;
  height: 38px;
  line-height: 38px;
  color: #fff;
  padding: 0 12px;
`

const GroupHeaderText = styled.span`
  margin-left: 6px;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
`

const Divider = styled.div<{ color: string }>`
  width: 36px;
  height: 2px;
  background-color: ${p => p.color};
  position: absolute;
`

const GroupBody = styled.div<{ $isEn: boolean }>`
  width: ${p => (p.$isEn ? '248px' : '195px')};
  display: grid;
  grid-row-gap: 8px;
  padding: 12px 12px 12px 0;
`

const MenuItem = styled(AnyLink)<{ $isEn: boolean }>`
  &&& {
    width: ${p => (p.$isEn ? '248px' : '195px')};
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    color: rgba(255, 255, 255, 0.65);
    font-weight: 400;
    font-size: 13px;
    line-height: 32px;
    .arrow-icon {
      opacity: 0;
      margin-right: 8px;
      transition: opacity 200ms ease-in, -webkit-transform 200ms ease-in;
    }
    &:hover {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 4px;
      .arrow-icon {
        opacity: 1;
        transform: translateX(4px);
      }
    }
  }
`

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  PatchItemWithIdReq,
  PatchItemWithIdReqInterface,
} from './patch-item-with-id-req'
import { Item, ItemInterface } from './extends/item'
import type { PatchKlassItemQuery } from './patch-klass-item-query'

const buildConfig = createConfigBuilder('vnas')

export const defaultApi$patchKlassItem = {
  config(
    id: number,
    patchItemWithIdReq: PatchItemWithIdReqInterface,
    patchKlassItemQuery?: PatchKlassItemQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'patch',
      '/klass/items/{id}',
      {
        id,
      },
      patchKlassItemQuery,
      patchItemWithIdReq,
      {
        format: 'json',
        isArray: false,
        defaults: {
          multiEvaluators: (v: any) => v || false,
          isShortcut: (v: any) => v || false,
          name: (v: any) => v || '',
          description: (v: any) => v || '',
          topN: (v: any) => (v === 0 ? v : v || null),
          lockSetting: (v: any) => v || false,
          lockScore: (v: any) => (v === false ? v : v || null),
          lockRules: (v: any) => v || null,
          reviewNeeded: (v: any) => (v === false ? v : v || null),
          within: (v: any) => (v === false ? v : v || null),
          withinScore: (v: any) => v || null,
          fullScore: (v: any) => v || null,
          initialScore: (v: any) => v || null,
          computeType: (v: any) => v || null,
          weight: (v: any) => (v === 0 ? v : v || null),
          scoringType: (v: any) => v || null,
          tags: (v: any) => v || [],
          displayType: (v: any) => v || null,
          displayRules: (v: any) => v || [],
          displayLevelItems: (v: any) => v || [],
          relation: (v: any) => v || null,
          taskRelations: (v: any) => v || [],
          relationRules: (v: any) => v || null,
          sort: (v: any) => v || 0,
          enableAfterthought: (v: any) => (v === false ? v : v || null),
          stageId: (v: any) => (v === 0 ? v : v || null),
          settings: (v: any) => v || null,
        },
      },
    )
  },

  /**
   * @summary Class PatchKlassItemApi
   * 教务教师批量修改评价结构
   * @param id
   * @param patchItemWithIdReq
   * @param [patchKlassItemQuery]
   * @param [defaultQuery.withSubDimensionsLock] 锁定、解锁当前结构下的维度设置
   * @param [defaultQuery.withItemsLockSetting] 锁定、解锁当前结构下的评价项设置
   * @param [defaultQuery.withItemsLockScore] 锁定、解锁当前结构下的评价项分数录入
   * @param [defaultQuery.withItemsLockRulesStartAt] 锁定评价项时的允许录入开始时间
   * @param [defaultQuery.withItemsLockRulesEndAt] 锁定评价项时的允许录入结束时间
   * @param [defaultQuery.expand]
   * @param [options]
   * @return AxiosResponsePromise<Item>
   */

  api<const TQuery extends PatchKlassItemQuery>(
    id: number,
    patchItemWithIdReq: PatchItemWithIdReqInterface,
    patchKlassItemQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Item, TQuery['expand']>> {
    return axios(
      defaultApi$patchKlassItem.config(
        id,
        patchItemWithIdReq,
        patchKlassItemQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new Item(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({
        id,
        payload,
        query,
      }: {
        id: number
        payload: PatchItemWithIdReqInterface
        query?: PatchKlassItemQuery
      }) => defaultApi$patchKlassItem.api(id, payload, query),
      mutationOptions,
    )
  },
}

// @ts-ignore
defaultApi$patchKlassItem.api._name_ = `Default.patchKlassItem`

/**
 * @file import plugins message register
 */

import '../features/customized-groups/message/register'
import '../features/szhpsy-grades-report/message/register'
import '../features/psychological-file/message/register'
import '../features/class-in/message/register'
import '../features/dorms/message/register'
import '../features/chat/message/register'
import '../features/eems/message/register'
import '../features/contract/message/register'
import '../features/sz-teaching-satisficing/message/register'
import '../features/absence/message/register'
import '../features/questionnaire/message/register'
import '../features/handout/message/register'
import '../features/teacher-assessment/register'
import '../features/report/register'
import '../features/sz-homework-survey/message/register'
import '../features/adjustment/message/register'
import '../features/intl-goal/message/register'
import '../features/class-review/message/register'
import '../features/ai-teacher/message/register'

import { $t } from 'packages/locale'
import { MemberTypeEnum } from 'packages/sdks-next/chalk'

/**
 * 生成系统角色
 *
 * @param props - props
 * @param props.adminClassEnabled - 班级管理是否开启, 默认开启
 * @param props.dormEnabled - 宿舍是否开启，默认开启
 * @returns 系统角色
 */
export const genProfileSystemRoles = (props?: {
  adminClassEnabled?: boolean
  dormEnabled?: boolean
}) => {
  const { adminClassEnabled = true, dormEnabled = true } = props || {}
  return [
    {
      value: MemberTypeEnum.Admin,
      label: $t('学生自己'),
      disabled: true,
    },
    {
      value: MemberTypeEnum.Guardian,
      label: $t('家长'),
    },
    ...(adminClassEnabled
      ? [
          {
            value: MemberTypeEnum.AdminClassTeacher,
            label: $t('班主任'),
          },
        ]
      : []),

    {
      value: MemberTypeEnum.Teacher,
      label: $t('任课教师'),
    },

    ...(dormEnabled
      ? [
          {
            value: MemberTypeEnum.DormManager,
            label: $t('宿舍管理员'),
          },
        ]
      : []),
  ]
}

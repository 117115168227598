export interface ScoreSortInterface {
  rid: number
  score: string
}

export class ScoreSort implements ScoreSortInterface {
  rid: number
  score: string

  constructor(props: ScoreSortInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      rid: _rid,
      score: _score,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.rid = _rid
    this.score = _score
  }

  static propKeys = ['rid', 'score']
}

import { $t } from 'packages/locale'

/**
 * 解析 Draft Content JSON
 * @param value
 */
export const convertStringToRaw = (value?: string) => {
  if (value) {
    try {
      const result = JSON.parse(value)

      return {
        ...result,
        blocks:
          result.blocks?.map((block: any) => {
            // HACK，处理一个奇怪的情况，有些时候 text key 前面会有一个空格
            if (block[' text']) {
              return {
                ...block,
                text: block[' text'],
              }
            }

            return block
          }) || [],
      }
    } catch (e) {
      console.warn($t('富文本解析失败：{text}', { text: value }))
    }
  }

  // 如果解析失败，直接返回该字符串
  return {
    blocks: [
      {
        key: 'fakeKey',
        text: value,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { TaskAssignment, TaskAssignmentInterface } from './task-assignment'
import type { QueryTaskAssignmentsQuery } from './query-task-assignments-query'

const buildConfig = createConfigBuilder('chalk')

export const taskApi$queryTaskAssignments = {
  config(
    id: number,
    queryTaskAssignmentsQuery?: QueryTaskAssignmentsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/task/v2/tasks/{id}/assignments',
      {
        id,
      },
      queryTaskAssignmentsQuery,
    )
  },

  /**
   * @summary Class QueryTaskAssignmentsApi
   * @param id 任务ID
   * @param [queryTaskAssignmentsQuery]
   * @param [taskQuery.accessForRid] 通过指定用户访问调用的接口，用于权限判断
   * 比如导师、班主任等，通过某个导生、班级学生的档案跳转时，用于指定该学生的 id
   * @param [taskQuery.expand] expand
   * - submission 最新提交记录
   * - review 最新review记录
   * - assignee 分配者个人信息
   * - like_count 获得点赞数
   * @param [options]
   * @return AxiosResponsePromise<TaskAssignment[]>
   */

  api<const TQuery extends QueryTaskAssignmentsQuery>(
    id: number,
    queryTaskAssignmentsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<TaskAssignment, TQuery['expand']>[]> {
    return axios(
      taskApi$queryTaskAssignments.config(
        id,
        queryTaskAssignmentsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new TaskAssignment(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryTaskAssignmentsQuery,
    TSelected = Expand<TaskAssignment, TQuery['expand']>[],
  >(
    {
      id,
      query,
    }: {
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<TaskAssignment, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<TaskAssignment, TQuery['expand']>[], TSelected>(
      taskApi$queryTaskAssignments.api,
      'Task.queryTaskAssignments',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
taskApi$queryTaskAssignments.api._name_ = `Task.queryTaskAssignments`

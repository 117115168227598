import { Button, State } from '@seiue/ui'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { $t } from 'packages/locale'

type NotFoundProps = {
  /**
   * 是否更新页面标题, 默认 true
   */
  useTitle?: boolean
}

/**
 * 404 页面
 *
 * @param props - NotFoundProps
 * @constructor
 */
export const NotFound: React.FC<NotFoundProps> = props => {
  const { useTitle = true } = props

  useEffect(() => {
    if (useTitle) window.document.title = $t('页面不存在')
  })

  const history = useHistory()

  return (
    <Container>
      <State.Empty
        reversed={true}
        type="not-found"
        size="large"
        description={
          <Description>
            <Title>{$t('抱歉，未找到你要访问的页面')}</Title>
            <ButtonStyled
              type="primary"
              size="large"
              onClick={() => {
                history.push('/')
              }}
            >
              {$t('回到首页')}
            </ButtonStyled>
          </Description>
        }
      />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  color: ${p => p.theme.text._1};
  margin-top: 12px;
  margin-bottom: 56px;
`

const ButtonStyled = styled(Button)`
  font-size: 14px;
  width: 220px;
`

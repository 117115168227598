import { RoleEnum } from './role-enum'
import { StatusEnum } from './status-enum'
import { GenderEnum } from './gender-enum'

export interface ReflectionV3Interface {
  uid?: number | null
  usin?: string | null
  role?: RoleEnum | null
  status?: StatusEnum | null
  gender?: GenderEnum | null
  /**
   * 学校 id
   */
  schoolId: number
  /**
   * 姓名
   */
  name: string
  /**
   * 姓名拼音
   */
  pinyin: string
  /**
   * 头像 url
   */
  avatarUrl?: string | null
  /**
   * 主键 ID
   */
  id: number
}

export class ReflectionV3 implements ReflectionV3Interface {
  uid?: number | null
  usin?: string | null
  role?: RoleEnum | null
  status?: StatusEnum | null
  gender?: GenderEnum | null
  /**
   * 学校 id
   */
  schoolId: number
  /**
   * 姓名
   */
  name: string
  /**
   * 姓名拼音
   */
  pinyin: string
  /**
   * 头像 url
   */
  avatarUrl?: string | null
  /**
   * 主键 ID
   */
  id: number

  constructor(props: ReflectionV3Interface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      uid: _uid,
      usin: _usin,
      role: _role,
      status: _status,
      gender: _gender,
      schoolId: _schoolId,
      name: _name,
      pinyin: _pinyin,
      avatarUrl: _avatarUrl,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.uid = _uid
    this.usin = _usin
    this.role = _role
    this.status = _status
    this.gender = _gender
    this.schoolId = _schoolId
    this.name = _name
    this.pinyin = _pinyin
    this.avatarUrl = _avatarUrl
    this.id = _id
  }

  static propKeys = [
    'uid',
    'usin',
    'role',
    'status',
    'gender',
    'schoolId',
    'name',
    'pinyin',
    'avatarUrl',
    'id',
  ]
}

import { createEnumHelper } from '@seiue/util'

import { $t } from 'packages/locale'
import {
  ComputeTypeEnum,
  DisplayLevelItemEnum,
  DisplayTypeEnum,
  ItemStatusEnum,
  ItemTypeEnum,
  RelationsEnum,
  ScoreStatusEnum,
  ScoringTypeEnum,
} from 'packages/sdks-next/vnas'

import { AchievedScore } from '../achieved-score'
import { Item as ItemBase } from '../item'

export type { ItemInterface } from '../item'

export const relationToLabel = createEnumHelper(() => ({
  [RelationsEnum.System]: $t('系统评价'),
  [RelationsEnum.MarkSelf]: $t('自评'),
  [RelationsEnum.GuardianMarkStudent]: $t('家长评价'),
  /**
   * 教师考核中的 relation
   */
  [RelationsEnum.TeacherMarkTeacher]: $t('自定义'),
  /**
   * 后端在评价设置中，勾选个性化评价选项时，会将 relation 默认设置为该值
   * 但其实不用在页面展示。为覆盖全 helper 中的 relation，故将此值附为空字符串
   * 课程班-评价项使用
   */
  [RelationsEnum.TeacherMarkStudent]: $t('教师评价'),
  /**
   * 德育评价中的教师评价
   */
  [RelationsEnum.Custom]: $t('教师评价'),
  /**
   * 德育评价中的小组互评
   */
  [RelationsEnum.StudentMarkInTeam]: $t('小组互评'),
}))

/**
 * 评价项实例
 */
export class Item extends ItemBase {
  /**
   * 获取 type (类型) 对应的文字标签
   *
   * @returns 标签
   */
  get relationLabel() {
    return this.relation ? relationToLabel.get(this.relation) : ''
  }

  /**
   * 判断评价项是否已发布
   *
   * @returns 是否
   */
  get isPublished() {
    return this.status === ItemStatusEnum.Published
  }

  /**
   * 判断评价项是否可设置暂不评价
   *
   * @returns delayable
   */
  get isDelayable() {
    return (
      this.type !== ItemTypeEnum.SpecialItem &&
      this.scoringType &&
      ![
        ScoringTypeEnum.Review,
        ScoringTypeEnum.Addition,
        ScoringTypeEnum.Tag,
      ].includes(this.scoringType)
    )
  }

  /**
   * 判断评价方式是否为评语
   *
   * @returns 是否
   */
  get isScoringTypeReview() {
    return this.scoringType === ScoringTypeEnum.Review
  }

  /**
   * 是否为维度或子维度
   *
   * @returns 是否
   */
  get isDimension() {
    return [ItemTypeEnum.Dimension, ItemTypeEnum.SubDimension].includes(
      this.type,
    )
  }

  /**
   * 是否为平均维度/子维度
   *
   * @returns 是否
   */
  get isAvgDimension() {
    return this.isDimension && this.computeType === ComputeTypeEnum.Avg
  }

  /**
   * 是否为平均子维度
   *
   * @returns 是否
   */
  get isAvgSubDimension() {
    return (
      this.type === ItemTypeEnum.SubDimension &&
      this.computeType === ComputeTypeEnum.Avg
    )
  }

  /**
   * 是否为加权平均维度
   *
   * @returns 是否
   */
  get isWeightedAvgDimension() {
    return this.isDimension && this.computeType === ComputeTypeEnum.WeightedAvg
  }

  /**
   * 是否为占分维度
   *
   * @returns 是否
   */
  get isWithinDimension() {
    return this.isDimension && !!this.within
  }

  /**
   * 是否为星星维度
   *
   * @returns 是否
   */
  get isStarDimension() {
    return this.isDimension && this.displayType === DisplayTypeEnum.Star
  }

  /**
   * 是否为占分加减分
   *
   * @returns 是否
   */
  get isWithinAddition() {
    return this.scoringType === ScoringTypeEnum.Addition && !!this.within
  }

  /**
   * 是否为占分考勤
   *
   * @returns 是否
   */
  get isWithinAttendance() {
    return this.type === ItemTypeEnum.AttendanceItem && !!this.within
  }

  /**
   * 是否为评价项
   *
   * @returns 是否
   */
  get isItem() {
    return this.type === ItemTypeEnum.Item
  }

  /**
   * 是否为考勤评价项
   *
   * @returns 是否
   */
  get isAttendanceItem() {
    return this.type === ItemTypeEnum.AttendanceItem
  }

  /**
   * 是否为个性化评价
   *
   * @returns 是否
   */
  get isSpecialItem() {
    return this.type === ItemTypeEnum.SpecialItem
  }

  /**
   * 是否为等级分数（等级评价项且显示分数）
   *
   * @returns 是否
   */
  get isLevelScore() {
    return (
      this.scoringType === ScoringTypeEnum.Level &&
      !!this.displayLevelItems?.includes(DisplayLevelItemEnum.Score)
    )
  }

  /**
   * 判断当前评价项分数是否已发布
   *
   * @param score - 评价项分数
   * @returns 是否
   */
  isScorePublished(score?: AchievedScore | null) {
    return (
      (score?.itemId === this.id &&
        score?.status === ScoreStatusEnum.Published) ||
      this.isPublished
    )
  }

  /**
   * 是否为任务关联的评价项
   * 如果关联了，那么该评价项会被视为默认发布的评价项（但其状态依然是 Pending）
   * 在这个情况下，分数一旦录入，该学生的分数会自动发布
   *
   * @returns 是否
   */
  get isTaskRelated() {
    return !!this.taskRelations?.length
  }
}

/**
 * @file 素养转化成绩 apis
 */

import {
  AccConvertPlanTargetEnum,
  accConverterApi$queryConverterPlans,
} from 'packages/sdks-next/vnas'

/**
 * 获取素养转化成绩
 *
 * @param props - 组件属性
 * @param props.disable - 是否禁用
 * @returns 素养转化成绩
 */
export const useAccScoreConverterPlan = ({
  disable,
}: {
  disable?: boolean
} = {}) => {
  const { data: plans, loading } = accConverterApi$queryConverterPlans.useApi(
    {
      target: AccConvertPlanTargetEnum.ClassAssessment,
      assessmentId: 0,
    },
    { disable },
  )

  const currentPlan = plans?.[0]

  return {
    loading,
    data: currentPlan,
  }
}

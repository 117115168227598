import { cloneDeep } from '@seiue/util'

interface Flags {
  [key: string]: boolean
}
export interface PageDirtyFlagsState {
  locationKeys: {
    [key: string]: Flags
  }
}

const state: PageDirtyFlagsState = {
  locationKeys: {},
}

const reducers = {
  setFlag(
    prevState: PageDirtyFlagsState,
    {
      locationKey,
      id,
      isDirty,
    }: { locationKey: string; id: string; isDirty: boolean },
  ) {
    const copy = cloneDeep(prevState)
    if (!copy.locationKeys[locationKey]) {
      copy.locationKeys[locationKey] = {}
    }

    copy.locationKeys[locationKey][id] = isDirty
    return { ...prevState, ...copy }
  },
  removeFlag(
    prevState: PageDirtyFlagsState,
    { locationKey, id }: { locationKey: string; id: string },
  ) {
    const copy = cloneDeep(prevState)
    delete copy.locationKeys[locationKey][id]
    return { ...prevState, ...copy }
  },
}

export const pageDirtyFlags = {
  state,
  reducers,
}

import { $t } from 'packages/locale'
import { TranscriptSourceFieldEnum } from 'packages/sdks-next/vnas'

export const getSourceLabel = (source: TranscriptSourceFieldEnum) =>
  ({
    [TranscriptSourceFieldEnum.Csin]: $t('课程编号'),
    [TranscriptSourceFieldEnum.Name]: $t('课程名称'),
    [TranscriptSourceFieldEnum.Credit]: $t('学分'),
    [TranscriptSourceFieldEnum.Attribute]: $t('属性'),
    [TranscriptSourceFieldEnum.Subject]: $t('科目'),
    [TranscriptSourceFieldEnum.Domain]: $t('领域'),
    [TranscriptSourceFieldEnum.Grades]: $t('年级'),
  }[source])

import { GroupMember, GroupMemberInterface } from './group-member'
import {
  GroupMemberLimits,
  GroupMemberLimitsInterface,
} from './group-member-limits'
import { GroupType, GroupTypeInterface } from './group-type'

export interface GroupInterface {
  schoolId: number
  /**
   * 创建人 id
   */
  creatorId: number
  /**
   * 群组名称
   */
  name: string
  /**
   * 业务 id
   */
  bizId: string
  /**
   * 成员 expanded
   */
  members?: GroupMemberInterface[]
  /**
   * 管理员，为成员 members 的子集 expanded
   */
  managers?: GroupMemberInterface[]
  /**
   * 成员限制
   */
  limits: GroupMemberLimitsInterface[]
  /**
   * e.g.g seiue.class_group, seiue.adminclass_group, seiue.dorm_group .
   * ..
   */
  type?: string
  /**
   * expand group type entity
   */
  typeEntity?: GroupTypeInterface
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Group implements GroupInterface {
  schoolId: number
  /**
   * 创建人 id
   */
  creatorId: number
  /**
   * 群组名称
   */
  name: string
  /**
   * 业务 id
   */
  bizId: string
  /**
   * 成员 expanded
   */
  members?: GroupMember[]
  /**
   * 管理员，为成员 members 的子集 expanded
   */
  managers?: GroupMember[]
  /**
   * 成员限制
   */
  limits: GroupMemberLimits[]
  /**
   * e.g.g seiue.class_group, seiue.adminclass_group, seiue.dorm_group .
   * ..
   */
  type?: string
  /**
   * expand group type entity
   */
  typeEntity?: GroupType
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: GroupInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      creatorId: _creatorId,
      name: _name,
      bizId: _bizId,
      members: _members,
      managers: _managers,
      limits: _limits,
      type: _type,
      typeEntity: _typeEntity,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.creatorId = _creatorId
    this.name = _name
    this.bizId = _bizId
    this.members = _members ? _members.map(i => new GroupMember(i)) : _members
    this.managers = _managers
      ? _managers.map(i => new GroupMember(i))
      : _managers
    this.limits = _limits ? _limits.map(i => new GroupMemberLimits(i)) : _limits
    this.type = _type
    this.typeEntity = _typeEntity ? new GroupType(_typeEntity) : _typeEntity
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'schoolId',
    'creatorId',
    'name',
    'bizId',
    'members',
    'managers',
    'limits',
    'type',
    'typeEntity',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

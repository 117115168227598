/**
 * @file z-index 变量
 */

/**
 * 元素层级
 */
export const ZIndex = {
  /**
   * 弹窗所在层级
   */
  Modal: 1000,
  /**
   * 浮窗所在层级
   */
  Popover: 1030,
  /**
   * 下拉菜单, 下拉选择 (如日期选择) 等
   */
  Dropdown: 1050,
  /**
   * 操作确认框、小对话框等
   */
  Prompt: 1060,
  /**
   * toast 所在层级
   */
  Toast: 1070,
  /**
   * tooltip 所在层级
   */
  Tooltip: 2000,
} as const

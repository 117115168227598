/**
 * @file 待办领域枚举
 *
 * Todo 所属的业务领域，由后端提供的元信息，接入新业务时需扩展此处定义。
 * 该定义在后端是分布式注册的，因此不能直接定义在 openapi 文档中。
 * See full list at https://www.yuque.com/kovru3/gfdy75/ipc6u6
 */

import { createEnumHelper } from '@seiue/util'

import { $t } from 'packages/locale'

export enum TodoDomainEnum {
  Venue = 'venue',
  Evaluation = 'evaluation',
  Goal = 'goal',
  Talk = 'talk',
  Dorm = 'dorm',
  Task = 'task',
  Attendance = 'attendance',
  ClassAdjustment = 'class_adjustment',
  Assessment = 'assessment',
  LeaveFlow = 'leave_flow',
  SubjectSelection = 'direction',
  Certification = 'certification',
  Declaration = 'declaration',
  Election = 'election',
  Event = 'event',
  CustomGroup = 'custom_group',
  AcademicGoal = 'academic_goal',
  Questionnaire = 'questionnaire',
  Workflow = 'workflow',
  NoCodeWorkflow = 'no-code.workflow',
  // 学籍
  StudentStatus = 'student_status',
  PluginPsy = 'psychological',
  // 心理咨询
  PsyChat = 'psy_chat',
  Schedule = 'owner.calendar',
  OnlineClass = 'online_class',
  Chat = 'chat',
  TeacherProfile = 'teacher_profile',
  Notification = 'notification',
  Handout = 'handout',
  AdminClass = 'adminclass',
  Contract = 'contract',
  OnlineScoring = 'exam_scoring',
  GzAcademicCredit = 'gz-academic-credit',
  SzHomeworkSurvey = 'sz-homework-survey',
  EventWorkflow = 'event_workflow',
  ClassReview = 'class-review',
  SemesterReview = 'semester_review',
}

/**
 * 领域枚举转化为 Label
 *
 * 这里的声明将直接影响代办列表 Filter 的显示
 */
export const todoDomainToLabel = createEnumHelper(() => ({
  [TodoDomainEnum.Notification]: $t('通知'),
  [TodoDomainEnum.Venue]: $t('场地预约'),
  [TodoDomainEnum.Evaluation]: $t('评教评学'),
  [TodoDomainEnum.Task]: $t('任务'),
  [TodoDomainEnum.Dorm]: $t('宿舍'),
  [TodoDomainEnum.Attendance]: $t('考勤'),
  [TodoDomainEnum.LeaveFlow]: $t('请假'),
  [TodoDomainEnum.Goal]: $t('目标'),
  [TodoDomainEnum.Assessment]: $t('成绩'),
  [TodoDomainEnum.ClassAdjustment]: $t('调代课'),
  [TodoDomainEnum.SubjectSelection]: $t('选科'),
  [TodoDomainEnum.Certification]: $t('认证'),
  [TodoDomainEnum.Declaration]: $t('课程申报'),
  [TodoDomainEnum.Election]: $t('选课'),
  [TodoDomainEnum.Event]: $t('活动'),
  [TodoDomainEnum.CustomGroup]: $t('群组'),
  [TodoDomainEnum.AcademicGoal]: $t('目标'),
  [TodoDomainEnum.Questionnaire]: $t('问卷'),
  [TodoDomainEnum.Workflow]: $t('审批'),
  [TodoDomainEnum.NoCodeWorkflow]: $t('审批'),
  [TodoDomainEnum.PluginPsy]: $t('心理'),
  [TodoDomainEnum.Schedule]: $t('日程'),
  [TodoDomainEnum.OnlineClass]: $t('插件'),
  [TodoDomainEnum.Chat]: $t('约谈'),
  [TodoDomainEnum.TeacherProfile]: $t('教师档案'),
  [TodoDomainEnum.Handout]: $t('学案'),
  [TodoDomainEnum.AdminClass]: $t('行政班'),
  [TodoDomainEnum.Contract]: $t('契约'),
  [TodoDomainEnum.OnlineScoring]: $t('阅卷'),
  [TodoDomainEnum.GzAcademicCredit]: $t('教师赋分'),
  [TodoDomainEnum.SzHomeworkSurvey]: $t('作业调查'),
  [TodoDomainEnum.PsyChat]: $t('咨询'),
  [TodoDomainEnum.ClassReview]: $t('听评课'),
  [TodoDomainEnum.SemesterReview]: $t('学期评语'),
}))

/**
 * @file 调代课消息 Chalk 导航
 */
import { registerMessageNavigator } from 'packages/features/messages/register'
import { RoleEnum } from 'packages/sdks-next/chalk'

registerMessageNavigator('class_adjustment', (message, reflection) => {
  const { disabledOpen } =
    (message.attributes as {
      disabledOpen: boolean
    }) || {}

  // 产品确定，学生无需看到教师发起的调代课详情（会看到一些隐私信息），消息中即可看到所需要的信息
  if (reflection.role === RoleEnum.Student || disabledOpen) {
    return null
  }

  return {
    path: `/class-adjustments/${message.attributes?.id}/detail`,
  }
})

/**
 * @file 自定义文本翻译
 *
 * 一般针对系统中用户自定义的内容进行翻译
 *
 * 目前用于请假/考勤标签翻译
 */

import { reportToSentry } from '@seiue/sentry'
import { flatten, partition } from '@seiue/util'

import {
  SchoolExtLocaleText,
  SchoolExtLocaleTextCategoryEnum,
  i18nApi$querySchoolExtLocaleTexts,
} from 'packages/sdks-next/chalk'

let customTexts: SchoolExtLocaleText[] = []

/**
 * 初始化自定义文本翻译
 */
export const initCustomLocale = async () => {
  try {
    const { data: texts } = await i18nApi$querySchoolExtLocaleTexts.api({
      sort: '-id',
      paginated: 0,
    })

    // 这里将通用分类的文本放在后面，搜索匹配的时候，就可以实现先找指定分类，如果没有再找通用分类
    customTexts =
      flatten(
        partition(
          texts,
          text => text.category !== SchoolExtLocaleTextCategoryEnum.Common,
        ),
      ) || []
  } catch (e) {
    // 静默失败
    reportToSentry(e)
    customTexts = []
  }
}

/**
 * 获取自定义文本翻译
 *
 * @returns 自定义文本翻译
 */
export const getCustomLocaleTexts = () => {
  return customTexts
}

import { RoleEnum } from './role-enum'

export interface ReflectionProfileInterface {
  /**
   * 学校ID
   */
  schoolId: number
  /**
   * 角色
   */
  role: RoleEnum
  /**
   * 主键 ID
   */
  id: number
}

export class ReflectionProfile implements ReflectionProfileInterface {
  /**
   * 学校ID
   */
  schoolId: number
  /**
   * 角色
   */
  role: RoleEnum
  /**
   * 主键 ID
   */
  id: number

  constructor(props: ReflectionProfileInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      role: _role,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.role = _role
    this.id = _id
  }

  static propKeys = ['schoolId', 'role', 'id']
}

export interface SchoolCrmReqInterface {
  /**
   * 服务开始时间
   */
  serviceStartAt?: string | null
  /**
   * 服务结束时间
   */
  serviceEndAt?: string | null
  /**
   * 使用开始时间
   */
  usageStartAt?: string | null
  /**
   * 使用结束时间
   */
  usageEndAt?: string | null
  /**
   * 试用开始时间
   */
  trialStartAt?: string | null
  /**
   * 试用结束时间
   */
  trialEndAt?: string | null
}

export class SchoolCrmReq implements SchoolCrmReqInterface {
  /**
   * 服务开始时间
   */
  serviceStartAt?: string | null
  /**
   * 服务结束时间
   */
  serviceEndAt?: string | null
  /**
   * 使用开始时间
   */
  usageStartAt?: string | null
  /**
   * 使用结束时间
   */
  usageEndAt?: string | null
  /**
   * 试用开始时间
   */
  trialStartAt?: string | null
  /**
   * 试用结束时间
   */
  trialEndAt?: string | null

  constructor(props: SchoolCrmReqInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      serviceStartAt: _serviceStartAt,
      serviceEndAt: _serviceEndAt,
      usageStartAt: _usageStartAt,
      usageEndAt: _usageEndAt,
      trialStartAt: _trialStartAt,
      trialEndAt: _trialEndAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.serviceStartAt = _serviceStartAt
    this.serviceEndAt = _serviceEndAt
    this.usageStartAt = _usageStartAt
    this.usageEndAt = _usageEndAt
    this.trialStartAt = _trialStartAt
    this.trialEndAt = _trialEndAt
  }

  static propKeys = [
    'serviceStartAt',
    'serviceEndAt',
    'usageStartAt',
    'usageEndAt',
    'trialStartAt',
    'trialEndAt',
  ]
}

/**
 * 认证插件类型
 */
export enum CertificationPluginKind {
  /**
   * 线上虚拟激励
   */
  OnlineIncentive = 'online_incentive',
  /**
   * 实体激励
   */
  OfflineIncentive = 'offline_incentive',
  /**
   * 处分
   */
  OnlinePunishment = 'online_punishment',
  /**
   * 电子证书
   */
  ElecCertifacate = 'e_certificate',
}

/**
 * 认证插件的徽章样式
 */
export enum CertificationPluginBadgeStyle {
  /**
   * 方形
   */
  Square = 'square',
  /**
   * 圆形
   */
  Round = 'round',
}

/**
 * 显示设置
 */
export enum CertificationPluginDisplayEnum {
  /**
   * 扇面图
   */
  PolarChart = 'polar_chart',
}

/**
 * 认证插件的业务配置项
 */
export type CertificationPluginSettings = {
  /**
   * 认证方式
   */
  kind: CertificationPluginKind
  /**
   * 徽章样式（only 实体激励）
   */
  badgeStyle?: CertificationPluginBadgeStyle
  /**
   * 实体卡片显示的学校名称（only 实体激励）
   */
  schoolName?: string
  /**
   * 实体卡片显示的描述（only 实体激励）
   */
  remark?: string
  /**
   * 认证范围（范围内的学生，可以在个人档案中查看此认证相关信息）
   */
  studentScopes?: object
  /**
   * 显示设置
   */
  display?: CertificationPluginDisplayEnum[]
  /**
   * 不显示未获得的激励（未获得且不可申请）
   */
  hiddenUnapply?: boolean
}

/**
 * 徽章实体卡片配置项
 */
export type CertificationCardSettings = {
  /**
   * 实体卡片的颜色
   */
  colorName?: CertificationColorName
}

/**
 * 徽章/实体徽章颜色
 */
export type CertificationColorName =
  | 'red'
  | 'blue'
  | 'purple'
  | 'orange'
  | 'green'

export interface StatsGradeRadarChartInterface {
  /**
   * 满分（注意：业务上雷达图的满分对应的实际是占分 within_score 而不是 full_score）
   */
  withinScore?: string | null
  /**
   * 初始分（维度下的评价项的初始分根据占分换算后的总和）
   */
  initialScore?: string | null
  /**
   * 得分
   */
  score: string
  /**
   * 平均分
   */
  avg: number
  /**
   * Item 名称
   */
  name: string
  /**
   * 打分方式
   */
  scoringType?: string | null
}

export class StatsGradeRadarChart implements StatsGradeRadarChartInterface {
  /**
   * 满分（注意：业务上雷达图的满分对应的实际是占分 within_score 而不是 full_score）
   */
  withinScore?: string | null
  /**
   * 初始分（维度下的评价项的初始分根据占分换算后的总和）
   */
  initialScore?: string | null
  /**
   * 得分
   */
  score: string
  /**
   * 平均分
   */
  avg: number
  /**
   * Item 名称
   */
  name: string
  /**
   * 打分方式
   */
  scoringType?: string | null

  constructor(props: StatsGradeRadarChartInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      withinScore: _withinScore,
      initialScore: _initialScore,
      score: _score,
      avg: _avg,
      name: _name,
      scoringType: _scoringType,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.withinScore = _withinScore
    this.initialScore = _initialScore
    this.score = _score
    this.avg = _avg
    this.name = _name
    this.scoringType = _scoringType
  }

  static propKeys = [
    'withinScore',
    'initialScore',
    'score',
    'avg',
    'name',
    'scoringType',
  ]
}

import { AttendanceGroupTypeEnum } from './attendance-group-type-enum'
import { AttendanceGroupModeEnum } from './attendance-group-mode-enum'
import { EffectiveRules, EffectiveRulesInterface } from './effective-rules'
import { MissNotice, MissNoticeInterface } from './miss-notice'
import { CheckLimits, CheckLimitsInterface } from './check-limits'
import { AttendancePlan, AttendancePlanInterface } from './attendance-plan'
import {
  AbnormalNoticeSetting,
  AbnormalNoticeSettingInterface,
} from './abnormal-notice-setting'
import { AttendanceTimeTypeEnum } from './attendance-time-type-enum'
import { Scope, ScopeInterface } from './scope'
import { Settings, SettingsInterface } from './settings'

export interface AttendanceGroupInterface {
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 学期 ID
   */
  semesterId: number
  /**
   * 类型
   */
  type: AttendanceGroupTypeEnum
  /**
   * 默认是否需要考勤
   */
  defaultAttendanceRequired: boolean
  /**
   * 考勤模式
   */
  mode: AttendanceGroupModeEnum
  /**
   * 考勤标签
   */
  tags: string[]
  /**
   * 默认考勤标签
   */
  defaultTag: string
  /**
   * 包含请假类型的标签，用来渲染异常考勤通知的选项
   */
  tagsWithAbsenceType?: string[] | null
  /**
   * 考勤生效规则
   */
  effectiveRules: EffectiveRulesInterface
  /**
   * 异常考勤通知标签设置
   */
  abnormalNoticeTags: string[]
  /**
   * 异常考勤标签设置
   */
  abnormalTags: string[]
  /**
   * 未考勤提醒设置
   */
  missNotice: MissNoticeInterface
  /**
   * 录入修改考勤限制
   */
  checkLimits: CheckLimitsInterface
  plans?: AttendancePlanInterface[]
  abnormalNoticeSetting?: AbnormalNoticeSettingInterface | null
  /**
   * 考勤事件段设置类型
   */
  attendanceTimeType?: AttendanceTimeTypeEnum | null
  /**
   * 也可使用 $abnormal_notice_setting 的 allow_send_scope
   */
  allowSendScope?: ScopeInterface | null
  settings?: SettingsInterface | null
  /**
   * 创建时间
   * 格式为 RFC3339 规则定义，UTC 2017-12-08T00:00:00.00Z CST 2017-12-08T00:08:00.00+08:00
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  /**
   * 创建人.
   */
  createdBy?: number | null
  /**
   * 更新人.
   */
  updatedBy?: number | null
  /**
   * 主键 ID
   */
  id: number
}

export class AttendanceGroup implements AttendanceGroupInterface {
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 学期 ID
   */
  semesterId: number
  /**
   * 类型
   */
  type: AttendanceGroupTypeEnum
  /**
   * 默认是否需要考勤
   */
  defaultAttendanceRequired: boolean
  /**
   * 考勤模式
   */
  mode: AttendanceGroupModeEnum
  /**
   * 考勤标签
   */
  tags: string[]
  /**
   * 默认考勤标签
   */
  defaultTag: string
  /**
   * 包含请假类型的标签，用来渲染异常考勤通知的选项
   */
  tagsWithAbsenceType?: string[] | null
  /**
   * 考勤生效规则
   */
  effectiveRules: EffectiveRules
  /**
   * 异常考勤通知标签设置
   */
  abnormalNoticeTags: string[]
  /**
   * 异常考勤标签设置
   */
  abnormalTags: string[]
  /**
   * 未考勤提醒设置
   */
  missNotice: MissNotice
  /**
   * 录入修改考勤限制
   */
  checkLimits: CheckLimits
  plans?: AttendancePlan[]
  abnormalNoticeSetting?: AbnormalNoticeSetting | null
  /**
   * 考勤事件段设置类型
   */
  attendanceTimeType?: AttendanceTimeTypeEnum | null
  /**
   * 也可使用 $abnormal_notice_setting 的 allow_send_scope
   */
  allowSendScope?: Scope | null
  settings?: Settings | null
  /**
   * 创建时间
   * 格式为 RFC3339 规则定义，UTC 2017-12-08T00:00:00.00Z CST 2017-12-08T00:08:00.00+08:00
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  /**
   * 创建人.
   */
  createdBy?: number | null
  /**
   * 更新人.
   */
  updatedBy?: number | null
  /**
   * 主键 ID
   */
  id: number

  constructor(props: AttendanceGroupInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      semesterId: _semesterId,
      type: _type,
      defaultAttendanceRequired: _defaultAttendanceRequired,
      mode: _mode,
      tags: _tags,
      defaultTag: _defaultTag,
      tagsWithAbsenceType: _tagsWithAbsenceType,
      effectiveRules: _effectiveRules,
      abnormalNoticeTags: _abnormalNoticeTags,
      abnormalTags: _abnormalTags,
      missNotice: _missNotice,
      checkLimits: _checkLimits,
      plans: _plans,
      abnormalNoticeSetting: _abnormalNoticeSetting,
      attendanceTimeType: _attendanceTimeType,
      allowSendScope: _allowSendScope,
      settings: _settings,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      createdBy: _createdBy,
      updatedBy: _updatedBy,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.semesterId = _semesterId
    this.type = _type
    this.defaultAttendanceRequired = _defaultAttendanceRequired
    this.mode = _mode
    this.tags = _tags
    this.defaultTag = _defaultTag
    this.tagsWithAbsenceType = _tagsWithAbsenceType
    this.effectiveRules = _effectiveRules
      ? new EffectiveRules(_effectiveRules)
      : _effectiveRules
    this.abnormalNoticeTags = _abnormalNoticeTags
    this.abnormalTags = _abnormalTags
    this.missNotice = _missNotice ? new MissNotice(_missNotice) : _missNotice
    this.checkLimits = _checkLimits
      ? new CheckLimits(_checkLimits)
      : _checkLimits
    this.plans = _plans ? _plans.map(i => new AttendancePlan(i)) : _plans
    this.abnormalNoticeSetting = _abnormalNoticeSetting
      ? new AbnormalNoticeSetting(_abnormalNoticeSetting)
      : _abnormalNoticeSetting
    this.attendanceTimeType = _attendanceTimeType
    this.allowSendScope = _allowSendScope
      ? new Scope(_allowSendScope)
      : _allowSendScope
    this.settings = _settings ? new Settings(_settings) : _settings
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
    this.createdBy = _createdBy
    this.updatedBy = _updatedBy
    this.id = _id
  }

  static propKeys = [
    'schoolId',
    'semesterId',
    'type',
    'defaultAttendanceRequired',
    'mode',
    'tags',
    'defaultTag',
    'tagsWithAbsenceType',
    'effectiveRules',
    'abnormalNoticeTags',
    'abnormalTags',
    'missNotice',
    'checkLimits',
    'plans',
    'abnormalNoticeSetting',
    'attendanceTimeType',
    'allowSendScope',
    'settings',
    'createdAt',
    'updatedAt',
    'createdBy',
    'updatedBy',
    'id',
  ]
}

import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import { AdminClass, AdminClassInterface } from './extends/admin-class'

export interface MemberInterface {
  /**
   * 行政班id
   */
  classId: number
  /**
   * 用户id
   */
  reflectionId: number
  /**
   * 用户expand
   */
  reflection?: SecuredReflectionInterface | null
  adminClass?: AdminClassInterface | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
}

export class Member implements MemberInterface {
  /**
   * 行政班id
   */
  classId: number
  /**
   * 用户id
   */
  reflectionId: number
  /**
   * 用户expand
   */
  reflection?: SecuredReflection | null
  adminClass?: AdminClass | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: MemberInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      classId: _classId,
      reflectionId: _reflectionId,
      reflection: _reflection,
      adminClass: _adminClass,
      id: _id,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.classId = _classId
    this.reflectionId = _reflectionId
    this.reflection = _reflection
      ? new SecuredReflection(_reflection)
      : _reflection
    this.adminClass = _adminClass ? new AdminClass(_adminClass) : _adminClass
    this.id = _id
    this.schoolId = _schoolId
  }

  static propKeys = [
    'classId',
    'reflectionId',
    'reflection',
    'adminClass',
    'id',
    'schoolId',
  ]
}

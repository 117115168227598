/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  PatchItemWithIdReq,
  PatchItemWithIdReqInterface,
} from './patch-item-with-id-req'
import { Item, ItemInterface } from './extends/item'
import type { PatchKlassItemsQuery } from './patch-klass-items-query'

const buildConfig = createConfigBuilder('vnas')

export const defaultApi$patchKlassItems = {
  config(
    patchItemWithIdReq: PatchItemWithIdReqInterface[],
    patchKlassItemsQuery?: PatchKlassItemsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'patch',
      '/klass/items/$batch',
      {},
      patchKlassItemsQuery,
      patchItemWithIdReq,
      {
        format: 'json',
        isArray: true,
        defaults: {
          multiEvaluators: (v: any) => v || false,
          isShortcut: (v: any) => v || false,
          name: (v: any) => v || '',
          description: (v: any) => v || '',
          topN: (v: any) => (v === 0 ? v : v || null),
          lockSetting: (v: any) => v || false,
          lockScore: (v: any) => (v === false ? v : v || null),
          lockRules: (v: any) => v || null,
          reviewNeeded: (v: any) => (v === false ? v : v || null),
          within: (v: any) => (v === false ? v : v || null),
          withinScore: (v: any) => v || null,
          fullScore: (v: any) => v || null,
          initialScore: (v: any) => v || null,
          computeType: (v: any) => v || null,
          weight: (v: any) => (v === 0 ? v : v || null),
          scoringType: (v: any) => v || null,
          tags: (v: any) => v || [],
          displayType: (v: any) => v || null,
          displayRules: (v: any) => v || [],
          displayLevelItems: (v: any) => v || [],
          relation: (v: any) => v || null,
          taskRelations: (v: any) => v || [],
          relationRules: (v: any) => v || null,
          sort: (v: any) => v || 0,
          enableAfterthought: (v: any) => (v === false ? v : v || null),
          stageId: (v: any) => (v === 0 ? v : v || null),
          settings: (v: any) => v || null,
        },
      },
    )
  },

  /**
   * @summary Class PatchKlassItemsApi
   * 教务批量修改评价结构
   * @param patchItemWithIdReq
   * @param [patchKlassItemsQuery]
   * @param [defaultQuery.withSubDimensionsLock] 锁定、解锁当前结构下的维度设置
   * @param [defaultQuery.withItemsLockSetting] 锁定、解锁当前结构下的评价项设置
   * @param [defaultQuery.withItemsLockScore] 锁定、解锁当前结构下的评价项分数录入
   * @param [defaultQuery.withItemsLockRulesStartAt] 锁定评价项时的允许录入开始时间
   * @param [defaultQuery.withItemsLockRulesEndAt] 锁定评价项时的允许录入结束时间
   * @param [defaultQuery.refreshStageGrades] 刷新当前结构下的学段成绩（仅在 stage_id 存在变更时有效）
   * @param [options]
   * @return AxiosResponsePromise<Item[]>
   */

  api(
    patchItemWithIdReq: PatchItemWithIdReqInterface[],
    patchKlassItemsQuery?: PatchKlassItemsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Item[]> {
    return axios(
      defaultApi$patchKlassItems.config(
        patchItemWithIdReq,
        patchKlassItemsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Item(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({
        payload,
        query,
      }: {
        payload: PatchItemWithIdReqInterface[]
        query?: PatchKlassItemsQuery
      }) => defaultApi$patchKlassItems.api(payload, query),
      mutationOptions,
    )
  },
}

// @ts-ignore
defaultApi$patchKlassItems.api._name_ = `Default.patchKlassItems`

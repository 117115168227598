/**
 * @file 满意度调查-消息 domain 注册
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Questionnaire,
  getLabel: () => $t('问卷'),
  labelStyle: calculateLabelStyle('#F7E9FA', '#9E37B4'),
  messages: [
    'questionnaire.admin_assigned',
    'questionnaire.admin_removed',
    'questionnaire.form_approved',
    'questionnaire.form_rejected',
    'questionnaire.form_revoke_approved',
    'questionnaire.form_revoke_rejected',
  ],
})

export interface DisplayRuleInterface {
  /**
   * 等级名或者标签名
   */
  name?: string
  /**
   * 分数区间 >= 为空标识不限制
   */
  scoreEgt?: string | null
  /**
   * 分数区间 < 为空标识不限制
   */
  scoreLt?: string | null
  /**
   * 分数区间 > 为空标识不限制
   */
  scoreGt?: string | null
  /**
   * 分数区间 <= 为空标识不限制
   */
  scoreElt?: string | null
  /**
   * 描述
   */
  description?: string
}

export class DisplayRule implements DisplayRuleInterface {
  /**
   * 等级名或者标签名
   */
  name?: string
  /**
   * 分数区间 >= 为空标识不限制
   */
  scoreEgt?: string | null
  /**
   * 分数区间 < 为空标识不限制
   */
  scoreLt?: string | null
  /**
   * 分数区间 > 为空标识不限制
   */
  scoreGt?: string | null
  /**
   * 分数区间 <= 为空标识不限制
   */
  scoreElt?: string | null
  /**
   * 描述
   */
  description?: string

  constructor(props: DisplayRuleInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      scoreEgt: _scoreEgt,
      scoreLt: _scoreLt,
      scoreGt: _scoreGt,
      scoreElt: _scoreElt,
      description: _description,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.scoreEgt = _scoreEgt
    this.scoreLt = _scoreLt
    this.scoreGt = _scoreGt
    this.scoreElt = _scoreElt
    this.description = _description
  }

  static propKeys = [
    'name',
    'scoreEgt',
    'scoreLt',
    'scoreGt',
    'scoreElt',
    'description',
  ]
}

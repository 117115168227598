import { Grade as GradeBase } from '../grade'
import { GradeStatusEnum } from '../grade-status-enum'
import { OwnerStatusEnum } from '../owner-status-enum'

export type { GradeInterface } from '../grade'

/**
 * 总成绩数据
 */
export class Grade extends GradeBase {
  /**
   * 是否是补考
   *
   * @returns boolean
   */
  get isResit() {
    return 'resitScoreStatus' in this
      ? 'resitGradeId' in this
        ? !!this.resitGradeId
        : false
      : false
  }

  /**
   * 获取计算后的等级
   * 如果是补考，则取补考数据的等级
   * 如果特殊评价有等级，优先取特殊评价的等级
   * 以上都不是则取原始的等级
   *
   * @returns level
   */
  get computedLevel() {
    return this.isResit
      ? this.resitLevel
      : this.specialLevel
      ? this.specialLevel
      : this.level
  }

  /**
   * 计算后的分数
   * 如果是补考，则用补考的分数
   * 如果是特殊评价，则取特殊评价分数，评价标准为按等级的话，取 specialLevel
   * 以上都不是的情况，取 this.score || this.suggestScore
   *
   * @returns score
   */
  get computedScore() {
    // 如果有补考成绩则优先展示补考成绩
    if (this.isResit) {
      return this.resitScore || '-'
    }

    if (this.specialLevel) {
      // 评价标准为按等级的话则没有 specialScore
      if (!this.specialScore) {
        return this.specialLevel
      }

      return this.specialScore
    }

    return this.score || this.realScore || this.suggestScore || ''
  }

  /**
   * 计算后的排名
   * 如果是补考或者特殊评价，则不展示排名
   * 否则取 this.rank
   *
   * @returns rank
   */
  get computedRank() {
    return this.isResit
      ? '-'
      : (this?.status !== GradeStatusEnum.Delayed &&
          !this?.specialLevel &&
          this?.rank) ||
          '-'
  }

  /**
   * 计算后的得分率
   * 如果是补考，去补考的得分率，否则取 this.realScoreRate
   *
   * @returns rate
   */
  get computedScoreRate() {
    return this.isResit ? this.resitScoreRate : this.realScoreRate
  }

  /**
   * 格式化总成绩课程排名
   * 如果是补考或特殊评价，不计算排名，返回 ‘-’
   * 如果不是暂不评价且不是特殊评价，取 this.courseRank
   *
   * @returns 总成绩课程排名
   */
  get computedCourseRank() {
    return this.isResit
      ? '-'
      : (this.status !== GradeStatusEnum.Delayed &&
          !this.specialLevel &&
          this.courseRank) ||
          '-'
  }

  /**
   * 计算 gpa
   * 如果是补考，取补考的 this.resitGpa
   * 如果不是暂不评价，取 this.gpa
   * 其他情况为 '-'
   *
   * @returns gpa
   */
  get computedGpa() {
    return this.isResit
      ? this.resitGpa
      : this.status !== GradeStatusEnum.Delayed
      ? this.gpa
      : '-'
  }

  /**
   * 学生是否已离开群组
   *
   * @returns 是否
   */
  get isOwnerLeaved() {
    return this.ownerStatus === OwnerStatusEnum.Leaved
  }
}

import { ItemTypeEnum } from './item-type-enum'
import { ItemStatusEnum } from './item-status-enum'
import { LockRules, LockRulesInterface } from './lock-rules'
import { ComputeTypeEnum } from './compute-type-enum'
import { ScoringTypeEnum } from './scoring-type-enum'
import { ItemTag, ItemTagInterface } from './item-tag'
import { DisplayTypeEnum } from './display-type-enum'
import { DisplayRule, DisplayRuleInterface } from './display-rule'
import { DisplayLevelItemEnum } from './display-level-item-enum'
import { RelationsEnum } from './relations-enum'
import { RelationRules, RelationRulesInterface } from './relation-rules'
import { TaskRelation, TaskRelationInterface } from './task-relation'
import {
  HandoutOutlineRelation,
  HandoutOutlineRelationInterface,
} from './handout-outline-relation'
import {
  CompleteScoresCounts,
  CompleteScoresCountsInterface,
} from './complete-scores-counts'
import { Stage, StageInterface } from './extends/stage'
import { AssessmentStage, AssessmentStageInterface } from './assessment-stage'

export interface ParentItemInterface {
  schoolId: number
  assessmentId: number
  pid: number
  type: ItemTypeEnum
  /**
   * 评价项类型为维度平均时，取最高分数项的个数
   */
  topN?: number | null
  name: string
  pathname: string[]
  description?: string
  status?: ItemStatusEnum | null
  lockSetting: boolean
  lockScore?: boolean | null
  lockRules?: LockRulesInterface | null
  reviewNeeded?: boolean | null
  within?: boolean | null
  withinScore?: string | null
  fullScore?: string | null
  initialScore?: string | null
  computeType?: ComputeTypeEnum | null
  weight?: number | null
  scoringType?: ScoringTypeEnum | null
  tags?: ItemTagInterface[]
  displayType?: DisplayTypeEnum | null
  displayRules?: DisplayRuleInterface[]
  displayLevelItems?: DisplayLevelItemEnum[]
  relation?: RelationsEnum | null
  /**
   * 德育和教师考核评价关系
   */
  relationRules?: RelationRulesInterface | null
  publishedAt?: string | null
  publishedBy?: number | null
  sort: number
  enableAfterthought?: boolean | null
  taskRelations?: TaskRelationInterface[]
  handoutOutlineRelations?: HandoutOutlineRelationInterface[]
  /**
   * 已完成录入分数数量和应录入总数，加减分和标签为空，非课程班评价项为空
   */
  completedScoresCounts?: CompleteScoresCountsInterface
  /**
   * 学段 expanded
   */
  stage?: StageInterface | null
  /**
   * 学段 id expanded
   */
  stageId?: number | null
  /**
   * 学段关系状态 expanded
   */
  assessmentStage?: AssessmentStageInterface | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class ParentItem implements ParentItemInterface {
  schoolId: number
  assessmentId: number
  pid: number
  type: ItemTypeEnum
  /**
   * 评价项类型为维度平均时，取最高分数项的个数
   */
  topN?: number | null
  name: string
  pathname: string[]
  description?: string
  status?: ItemStatusEnum | null
  lockSetting: boolean
  lockScore?: boolean | null
  lockRules?: LockRules | null
  reviewNeeded?: boolean | null
  within?: boolean | null
  withinScore?: string | null
  fullScore?: string | null
  initialScore?: string | null
  computeType?: ComputeTypeEnum | null
  weight?: number | null
  scoringType?: ScoringTypeEnum | null
  tags?: ItemTag[]
  displayType?: DisplayTypeEnum | null
  displayRules?: DisplayRule[]
  displayLevelItems?: DisplayLevelItemEnum[]
  relation?: RelationsEnum | null
  /**
   * 德育和教师考核评价关系
   */
  relationRules?: RelationRules | null
  publishedAt?: string | null
  publishedBy?: number | null
  sort: number
  enableAfterthought?: boolean | null
  taskRelations?: TaskRelation[]
  handoutOutlineRelations?: HandoutOutlineRelation[]
  /**
   * 已完成录入分数数量和应录入总数，加减分和标签为空，非课程班评价项为空
   */
  completedScoresCounts?: CompleteScoresCounts
  /**
   * 学段 expanded
   */
  stage?: Stage | null
  /**
   * 学段 id expanded
   */
  stageId?: number | null
  /**
   * 学段关系状态 expanded
   */
  assessmentStage?: AssessmentStage | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: ParentItemInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      assessmentId: _assessmentId,
      pid: _pid,
      type: _type,
      topN: _topN,
      name: _name,
      pathname: _pathname,
      description: _description,
      status: _status,
      lockSetting: _lockSetting,
      lockScore: _lockScore,
      lockRules: _lockRules,
      reviewNeeded: _reviewNeeded,
      within: _within,
      withinScore: _withinScore,
      fullScore: _fullScore,
      initialScore: _initialScore,
      computeType: _computeType,
      weight: _weight,
      scoringType: _scoringType,
      tags: _tags,
      displayType: _displayType,
      displayRules: _displayRules,
      displayLevelItems: _displayLevelItems,
      relation: _relation,
      relationRules: _relationRules,
      publishedAt: _publishedAt,
      publishedBy: _publishedBy,
      sort: _sort,
      enableAfterthought: _enableAfterthought,
      taskRelations: _taskRelations,
      handoutOutlineRelations: _handoutOutlineRelations,
      completedScoresCounts: _completedScoresCounts,
      stage: _stage,
      stageId: _stageId,
      assessmentStage: _assessmentStage,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.assessmentId = _assessmentId
    this.pid = _pid
    this.type = _type
    this.topN = _topN
    this.name = _name
    this.pathname = _pathname
    this.description = _description
    this.status = _status
    this.lockSetting = _lockSetting
    this.lockScore = _lockScore
    this.lockRules = _lockRules ? new LockRules(_lockRules) : _lockRules
    this.reviewNeeded = _reviewNeeded
    this.within = _within
    this.withinScore = _withinScore
    this.fullScore = _fullScore
    this.initialScore = _initialScore
    this.computeType = _computeType
    this.weight = _weight
    this.scoringType = _scoringType
    this.tags = _tags ? _tags.map(i => new ItemTag(i)) : _tags
    this.displayType = _displayType
    this.displayRules = _displayRules
      ? _displayRules.map(i => new DisplayRule(i))
      : _displayRules
    this.displayLevelItems = _displayLevelItems
    this.relation = _relation
    this.relationRules = _relationRules
      ? new RelationRules(_relationRules)
      : _relationRules
    this.publishedAt = _publishedAt
    this.publishedBy = _publishedBy
    this.sort = _sort
    this.enableAfterthought = _enableAfterthought
    this.taskRelations = _taskRelations
      ? _taskRelations.map(i => new TaskRelation(i))
      : _taskRelations
    this.handoutOutlineRelations = _handoutOutlineRelations
      ? _handoutOutlineRelations.map(i => new HandoutOutlineRelation(i))
      : _handoutOutlineRelations
    this.completedScoresCounts = _completedScoresCounts
      ? new CompleteScoresCounts(_completedScoresCounts)
      : _completedScoresCounts
    this.stage = _stage ? new Stage(_stage) : _stage
    this.stageId = _stageId
    this.assessmentStage = _assessmentStage
      ? new AssessmentStage(_assessmentStage)
      : _assessmentStage
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'schoolId',
    'assessmentId',
    'pid',
    'type',
    'topN',
    'name',
    'pathname',
    'description',
    'status',
    'lockSetting',
    'lockScore',
    'lockRules',
    'reviewNeeded',
    'within',
    'withinScore',
    'fullScore',
    'initialScore',
    'computeType',
    'weight',
    'scoringType',
    'tags',
    'displayType',
    'displayRules',
    'displayLevelItems',
    'relation',
    'relationRules',
    'publishedAt',
    'publishedBy',
    'sort',
    'enableAfterthought',
    'taskRelations',
    'handoutOutlineRelations',
    'completedScoresCounts',
    'stage',
    'stageId',
    'assessmentStage',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

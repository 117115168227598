/**
 * @file Moment 多语言
 */

import { setMomentLocale as _setMomentLocale } from '@seiue/moment'

import { LocaleTextLocaleEnum } from 'packages/sdks-next/chalk'

import { UsefulLocaleEnum } from './types'

export const setMomentLocale = (
  locale: UsefulLocaleEnum = LocaleTextLocaleEnum.ZhCN,
) => {
  _setMomentLocale(locale === LocaleTextLocaleEnum.ZhCN ? 'zh-cn' : 'en')
}

import { CertificationReflectionTypeEnum } from './certification-reflection-type-enum'
import { CertificationReflectionRespSourceEnum } from './certification-reflection-resp-source-enum'
import { CertificationReflectionStatusEnum } from './certification-reflection-status-enum'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import {
  CertificationBase,
  CertificationBaseInterface,
} from './certification-base'
import {
  CertificationLog,
  CertificationLogInterface,
} from './certification-log'
import { Attachment, AttachmentInterface } from './attachment'

export interface CertificationReflectionInterface {
  /**
   * 认证 ID
   */
  certificationId: number
  /**
   * 认证获取方式
   */
  type: CertificationReflectionTypeEnum
  /**
   * 认证获取来源
   */
  source: CertificationReflectionRespSourceEnum
  /**
   * 认证所属人员 rid
   */
  reflectionId: number
  /**
   * 审批人
   */
  adminReflectionId?: number
  /**
   * 证书 - 指导老师ids
   */
  adminReviewTeacherIds?: number[] | null
  /**
   * 证书 - 指导老师
   */
  adminReviewTeacherNames?: string
  /**
   * 证书获得时间
   */
  getAt?: string | null
  /**
   * 认证审批状态
   */
  status: CertificationReflectionStatusEnum
  /**
   * 认证发起时间
   */
  appliedAt?: string
  /**
   * 通过时间
   */
  passedAt?: string | null
  /**
   * 负责人审核理由
   */
  adminReason?: string
  /**
   * 申请理由
   */
  reason?: string
  /**
   * 相关链接
   */
  links?: object[]
  /**
   * 相关附件
   */
  appendixes?: object[]
  /**
   * 证书 - 电子证书相关附件
   */
  reflectionAttachments?: object[]
  /**
   * 相关二维码
   */
  token?: string
  /**
   * expand reflection 用户信息
   */
  reflection?: SecuredReflectionInterface | null
  /**
   * expand admin_reflection 审核人信息
   */
  adminReflection?: SecuredReflectionInterface | null
  certification?: CertificationBaseInterface | null
  /**
   * expand=logs 审核日志
   */
  logs?: CertificationLogInterface[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  /**
   * 业务类型
   * 目前仅支持 group
   */
  bizType?: string | null
  /**
   * 业务 id
   * 如 group_id
   */
  bizId?: number | null
  /**
   * 授予评语
   */
  adminReview?: string
  /**
   * 授予评语附件
   */
  adminReviewAttachments?: AttachmentInterface[]
}

export class CertificationReflection
  implements CertificationReflectionInterface
{
  /**
   * 认证 ID
   */
  certificationId: number
  /**
   * 认证获取方式
   */
  type: CertificationReflectionTypeEnum
  /**
   * 认证获取来源
   */
  source: CertificationReflectionRespSourceEnum
  /**
   * 认证所属人员 rid
   */
  reflectionId: number
  /**
   * 审批人
   */
  adminReflectionId?: number
  /**
   * 证书 - 指导老师ids
   */
  adminReviewTeacherIds?: number[] | null
  /**
   * 证书 - 指导老师
   */
  adminReviewTeacherNames?: string
  /**
   * 证书获得时间
   */
  getAt?: string | null
  /**
   * 认证审批状态
   */
  status: CertificationReflectionStatusEnum
  /**
   * 认证发起时间
   */
  appliedAt?: string
  /**
   * 通过时间
   */
  passedAt?: string | null
  /**
   * 负责人审核理由
   */
  adminReason?: string
  /**
   * 申请理由
   */
  reason?: string
  /**
   * 相关链接
   */
  links?: object[]
  /**
   * 相关附件
   */
  appendixes?: object[]
  /**
   * 证书 - 电子证书相关附件
   */
  reflectionAttachments?: object[]
  /**
   * 相关二维码
   */
  token?: string
  /**
   * expand reflection 用户信息
   */
  reflection?: SecuredReflection | null
  /**
   * expand admin_reflection 审核人信息
   */
  adminReflection?: SecuredReflection | null
  certification?: CertificationBase | null
  /**
   * expand=logs 审核日志
   */
  logs?: CertificationLog[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  /**
   * 业务类型
   * 目前仅支持 group
   */
  bizType?: string | null
  /**
   * 业务 id
   * 如 group_id
   */
  bizId?: number | null
  /**
   * 授予评语
   */
  adminReview?: string
  /**
   * 授予评语附件
   */
  adminReviewAttachments?: Attachment[]

  constructor(props: CertificationReflectionInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      certificationId: _certificationId,
      type: _type,
      source: _source,
      reflectionId: _reflectionId,
      adminReflectionId: _adminReflectionId,
      adminReviewTeacherIds: _adminReviewTeacherIds,
      adminReviewTeacherNames: _adminReviewTeacherNames,
      getAt: _getAt,
      status: _status,
      appliedAt: _appliedAt,
      passedAt: _passedAt,
      adminReason: _adminReason,
      reason: _reason,
      links: _links,
      appendixes: _appendixes,
      reflectionAttachments: _reflectionAttachments,
      token: _token,
      reflection: _reflection,
      adminReflection: _adminReflection,
      certification: _certification,
      logs: _logs,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      bizType: _bizType,
      bizId: _bizId,
      adminReview: _adminReview,
      adminReviewAttachments: _adminReviewAttachments,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.certificationId = _certificationId
    this.type = _type
    this.source = _source
    this.reflectionId = _reflectionId
    this.adminReflectionId = _adminReflectionId
    this.adminReviewTeacherIds = _adminReviewTeacherIds
    this.adminReviewTeacherNames = _adminReviewTeacherNames
    this.getAt = _getAt
    this.status = _status
    this.appliedAt = _appliedAt
    this.passedAt = _passedAt
    this.adminReason = _adminReason
    this.reason = _reason
    this.links = _links
    this.appendixes = _appendixes
    this.reflectionAttachments = _reflectionAttachments
    this.token = _token
    this.reflection = _reflection
      ? new SecuredReflection(_reflection)
      : _reflection
    this.adminReflection = _adminReflection
      ? new SecuredReflection(_adminReflection)
      : _adminReflection
    this.certification = _certification
      ? new CertificationBase(_certification)
      : _certification
    this.logs = _logs ? _logs.map(i => new CertificationLog(i)) : _logs
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
    this.bizType = _bizType
    this.bizId = _bizId
    this.adminReview = _adminReview
    this.adminReviewAttachments = _adminReviewAttachments
      ? _adminReviewAttachments.map(i => new Attachment(i))
      : _adminReviewAttachments
  }

  static propKeys = [
    'certificationId',
    'type',
    'source',
    'reflectionId',
    'adminReflectionId',
    'adminReviewTeacherIds',
    'adminReviewTeacherNames',
    'getAt',
    'status',
    'appliedAt',
    'passedAt',
    'adminReason',
    'reason',
    'links',
    'appendixes',
    'reflectionAttachments',
    'token',
    'reflection',
    'adminReflection',
    'certification',
    'logs',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
    'bizType',
    'bizId',
    'adminReview',
    'adminReviewAttachments',
  ]
}

/**
 * Apollo 上的成绩评价设置类型
 */
export type ApolloClassAssessmentConfig = {
  /**
   * 默认展开成绩汇总结构，默认 0（即不展开）
   */
  expandSummaryStructureByDefault: number
  /**
   * 总成绩取值逻辑（实时成绩/预测成绩），默认 suggest 实时成绩
   */
  gradeRealScoreMode: 'suggest' | 'trend'
  /**
   * 成绩汇总颜色配置，默认 ['#21a18e', '#e79900', '#e4524b']
   */
  summaryColors: string[]
  /**
   * 总成绩未发布时对学生隐藏，默认 0
   */
  hideUnpublishedGradeForStudent: number
}

/**
 * 标签打分时的标签状态
 */
export enum TagStatusEnum {
  Default = 'default',
  Positive = 'positive',
  Negative = 'negative',
}

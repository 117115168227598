export interface GroupTypeInterface {
  schoolId: number
  name: string
  machineName: string
  description?: string
  adminIds?: number[]
  archiveAfterDays?: number | null
  logo?: string
  groupAdminRoleName?: string
  groupMemberRoleName?: string
  isCustom?: boolean
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class GroupType implements GroupTypeInterface {
  schoolId: number
  name: string
  machineName: string
  description?: string
  adminIds?: number[]
  archiveAfterDays?: number | null
  logo?: string
  groupAdminRoleName?: string
  groupMemberRoleName?: string
  isCustom?: boolean
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: GroupTypeInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      name: _name,
      machineName: _machineName,
      description: _description,
      adminIds: _adminIds,
      archiveAfterDays: _archiveAfterDays,
      logo: _logo,
      groupAdminRoleName: _groupAdminRoleName,
      groupMemberRoleName: _groupMemberRoleName,
      isCustom: _isCustom,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.name = _name
    this.machineName = _machineName
    this.description = _description
    this.adminIds = _adminIds
    this.archiveAfterDays = _archiveAfterDays
    this.logo = _logo
    this.groupAdminRoleName = _groupAdminRoleName
    this.groupMemberRoleName = _groupMemberRoleName
    this.isCustom = _isCustom
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'schoolId',
    'name',
    'machineName',
    'description',
    'adminIds',
    'archiveAfterDays',
    'logo',
    'groupAdminRoleName',
    'groupMemberRoleName',
    'isCustom',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

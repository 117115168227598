import { SubjectSettingTypeEnum } from './subject-setting-type-enum'

export interface SubjectSettingInterface {
  /**
   * 科目ID - 取值于term
   */
  id?: number[]
  /**
   * 学科类型
   */
  type?: SubjectSettingTypeEnum
}

export class SubjectSetting implements SubjectSettingInterface {
  /**
   * 科目ID - 取值于term
   */
  id?: number[]
  /**
   * 学科类型
   */
  type?: SubjectSettingTypeEnum

  constructor(props: SubjectSettingInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      type: _type,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
    this.type = _type
  }

  static propKeys = ['id', 'type']
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { OuterInfo, OuterInfoInterface } from './outer-info'
import type { GetUserOuterInfosQuery } from './get-user-outer-infos-query'

const buildConfig = createConfigBuilder('chalk')

export const userApi$getUserOuterInfos = {
  config(
    uid: number,
    getUserOuterInfosQuery?: GetUserOuterInfosQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/user/users/{uid}/outer_infos',
      {
        uid,
      },
      getUserOuterInfosQuery,
    )
  },

  /**
   * @summary 获取用户绑定的第三方平台的 outerId 以及更多信息
   * @param uid
   * @param [getUserOuterInfosQuery]
   * @param [userQuery.provider]
   * @param [userQuery.providerId]
   * @param [options]
   * @return AxiosResponsePromise<OuterInfo[]>
   */

  api(
    uid: number,
    getUserOuterInfosQuery?: GetUserOuterInfosQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<OuterInfo[]> {
    return axios(
      userApi$getUserOuterInfos.config(uid, getUserOuterInfosQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new OuterInfo(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = OuterInfo[]>(
    {
      uid,
      query,
    }: {
      uid: number
      query?: GetUserOuterInfosQuery
    },
    queryOptions?: QueryOptionsWithSelect<OuterInfo[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<OuterInfo[], TSelected>(
      userApi$getUserOuterInfos.api,
      'User.getUserOuterInfos',
      queryOptions,
      uid,
      query,
    )
  },
}

// @ts-ignore
userApi$getUserOuterInfos.api._name_ = `User.getUserOuterInfos`

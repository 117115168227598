/**
 * @file 消息/通知相关的 api
 */

import { get, omit } from '@seiue/util'
import { useCallback } from 'react'
import { Query } from 'react-query'

import { CounterSubjectEnum, useCounters } from 'packages/features/counter'
import { useCurrentReflection } from 'packages/features/sessions/utils/data'
import { QueryOptions } from 'packages/sdks-next'
import {
  FindAllQuery,
  receivedMessageApi$findAll,
  receivedMessageApi$load,
  receivedMessageApi$updateById,
  counterApi$listMyCounters,
  receivedMessageApi$updateAll,
  messageApi$countMessages,
} from 'packages/sdks-next/chalk'

import { importantMessageTypes } from '../utils'

/**
 * 获取消息
 *
 * @param query - 查询参数
 * @param queryOptions - 查询配置
 * @returns findAll Returns
 */
export function useFindAllMessages(
  query?: FindAllQuery,
  queryOptions?: QueryOptions,
) {
  const currentReflection = useCurrentReflection()

  return receivedMessageApi$findAll.useApi(
    {
      ...query,
      notice: true,
      'owner.id': currentReflection.id,
      expand: ['aggregatedMessages'],
    },
    queryOptions,
  )
}

/**
 * 查询所有的未读的重要消息
 *
 * @param query - 查询参数
 * @param queryOptions - 查询配置
 * @returns findAll Returns
 */
export function useFindAllUnreadImportantMessages(
  query?: FindAllQuery,
  queryOptions?: QueryOptions,
) {
  return useFindAllMessages(
    {
      ...omit(query, ['page', 'perPage']),
      readed: false,
      type: importantMessageTypes.join(','),
      // 该接口不支持 paginated: false，所以这里设置一个很大的值
      perPage: 999,
    },
    queryOptions,
  )
}

/**
 * 获取通知
 *
 * @param query - 查询参数
 * @param queryOptions - 查询配置
 * @returns findAll Returns
 */
export function useFindAllNotices(
  query?: FindAllQuery,
  queryOptions?: QueryOptions,
) {
  const currentReflection = useCurrentReflection()

  return receivedMessageApi$findAll.useApi(
    {
      ...query,
      type: 'message',
      sort: '-published_at,-created_at',
      'owner.id': currentReflection.id,
      expand: ['senderReflection'],
    },
    queryOptions,
  )
}

/**
 * 根据 id 或者 conversationId 获取 ReceivedMessage
 *
 * @param param0 - 参数
 * @param param0.id - id 或者 conversationId
 * @param param0.idType - id 类型
 * @returns ReceivedMessage
 */
export function useFindReceivedMessage({
  id,
  idType,
}: {
  id: string
  idType: 'id' | 'conversationId'
}) {
  const { data: messageDirect, loading: loading1 } =
    receivedMessageApi$load.useApi(
      {
        id,
        query: {
          tryExpand: ['discussion'],
        },
      },
      {
        disable: idType !== 'id',
      },
    )

  const { data: messagesByConversation, loading: loading2 } = useFindAllNotices(
    {
      conversationId: id,
      notice: false,
      tryExpand: ['discussion'],
    },
    {
      disable: idType !== 'conversationId',
    },
  )

  if (idType === 'id') {
    return [messageDirect, loading1] as const
  }

  return [messagesByConversation?.[0], loading2] as const
}

/**
 * 获取未读通知数量
 *
 * @returns 未读通知数量
 */
export function useUnreadNoticeCount() {
  const { counters, loading, refresh } = useCounters(
    CounterSubjectEnum.UnreadNotifications,
  )

  const [noticeifationCounter] = counters

  return { count: noticeifationCounter?.count || 0, loading, refresh }
}

/**
 * 获取未读消息数量
 *
 * @returns 未读消息数量
 */
export function useUnreadMessageCount() {
  const { counters, loading, refresh } = useCounters(
    CounterSubjectEnum.UnreadMessage,
  )

  const [noticeifationCounter] = counters

  return { count: noticeifationCounter?.count || 0, loading, refresh }
}

const getReloadPredicate = (type: 'message' | 'notice') => (query: Query) => {
  if (query.queryKey[0] === get(receivedMessageApi$findAll.api, '_name_')) {
    const apiQuery = query.queryKey[1] as FindAllQuery | undefined

    if (type === 'message') {
      return !!query.queryKey.includes('message') || apiQuery?.notice === true
    }

    if (type === 'notice') {
      return apiQuery?.type === 'message'
    }
  }

  return true
}

function useRead(type: 'message' | 'notice') {
  const reloadPredicate = getReloadPredicate(type)

  const { api } = receivedMessageApi$updateById.useApi({
    reload: [counterApi$listMyCounters.api, receivedMessageApi$findAll.api],
    reloadPredicate,
  })

  return useCallback(
    (id: string) =>
      api({
        id,
        payload: {
          readed: true,
        },
      }),
    [api],
  )
}

/**
 * 标记通知为已读
 *
 * @returns 标记通知为已读
 */
export function useReadNotice() {
  return useRead('notice')
}

/**
 * 标记消息为已读
 *
 * @returns 标记消息为已读
 */
export function useReadMessage() {
  return useRead('message')
}

/**
 * 标记所有通知为已读
 *
 * @returns 标记所有通知为已读
 */
export function useReadAllNotices() {
  const currentReflection = useCurrentReflection()

  const reloadPredicate = getReloadPredicate('notice')

  const { api } = receivedMessageApi$updateAll.useApi({
    reload: [
      counterApi$listMyCounters.api,
      receivedMessageApi$findAll.api,
      messageApi$countMessages.api,
    ],
    reloadPredicate,
  })

  return useCallback(
    () =>
      api({
        payload: {
          readed: true,
        },
        query: {
          type: 'message',
          'owner.id': currentReflection.id,
        },
      }),
    [api, currentReflection.id],
  )
}

/**
 * 标记所有通知为已读
 *
 * @returns 标记所有通知为已读
 */
export function useReadAllMessages() {
  const currentReflection = useCurrentReflection()

  const reloadPredicate = getReloadPredicate('message')

  const { api } = receivedMessageApi$updateAll.useApi({
    reload: [
      counterApi$listMyCounters.api,
      receivedMessageApi$findAll.api,
      messageApi$countMessages.api,
    ],
    reloadPredicate,
  })

  return useCallback(
    () =>
      api({
        payload: {
          readed: true,
        },
        query: {
          notice: true,
          'owner.id': currentReflection.id,
        },
      }),
    [api, currentReflection.id],
  )
}

/**
 * 返回对通知进行回执的方法
 *
 * @returns 对通知进行回执的方法
 */
export const useReceiptNotice = () => {
  const { api } = receivedMessageApi$updateById.useApi({
    reload: [receivedMessageApi$findAll.api, receivedMessageApi$load.api],
  })

  return useCallback(
    (id: string, receiptedImage?: string) =>
      api({
        id,
        payload: {
          receipted: true,
          receiptedImage,
        },
      }),
    [api],
  )
}

import { Pagination } from '@seiue/axios'

import { useCurrentReflection } from 'packages/features/sessions/utils/data'
import { useFindAll, wait } from 'packages/sdks-next'
import {
  calendarEventSchema,
  Group,
  EnhancerEnum,
  RoleEnum,
  CalendarEvent,
  calendarApi$listPersonalEvents,
  groupApi$listPersonalGroupCalendarEvents,
  groupApi$listCalendarEvents,
} from 'packages/sdks-next/chalk'
import { RequirePropPathList } from 'packages/utils/types'

/**
 * 获取个人日程
 *
 * @param props - 组件属性
 * @param props.id - 用户 ID
 * @param props.startTime - 开始时间
 * @param props.endTime - 结束时间
 * @returns 个人日程
 */
export function useFindPersonalSchedules({
  id,
  startTime,
  endTime,
}: {
  startTime: string
  endTime: string
  id?: number
}) {
  return calendarApi$listPersonalEvents.useApi({
    id: wait(id),
    startTime,
    endTime,
    query: {
      expand: ['address', 'initiators'] as const,
    },
  })
}

/**
 * 获取课程班相关联的日程
 *
 * @param props - 组件属性
 * @param props.group - 群组
 * @param props.enhancer - 增强器
 * @param props.start - 开始时间
 * @param props.end - 结束时间
 * @param props.rid - 用户 ID
 * @returns 课程班相关联的日程
 */
export function useFindClassSchedules({
  group,
  enhancer,
  start,
  end,
  rid,
}: {
  group: Group
  enhancer?: EnhancerEnum
  start: string
  end: string
  rid: number
}): [
  RequirePropPathList<CalendarEvent, ['address', 'initiators']>[] | null,
  Pagination | null,
  boolean,
  () => void,
] {
  const reflection = useCurrentReflection()

  const param =
    reflection.role === RoleEnum.Student || rid !== reflection.id
      ? ({
          schema: calendarEventSchema,
          api: groupApi$listPersonalGroupCalendarEvents.api,
          args: [
            `${enhancer || group.labels?.type}:${group.bizId}`,
            start,
            end,
            rid,
            {
              expand: ['address', 'initiators'],
            },
          ],
        } as any)
      : ({
          schema: calendarEventSchema,
          api: groupApi$listCalendarEvents.api,
          args: [
            `${enhancer || group.labels?.type}:${group.bizId}`,
            start,
            end,
            {
              expand: ['address', 'initiators'],
            },
          ],
        } as any)

  return (useFindAll as any)(param as any)
}

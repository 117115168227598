import { useAtom, useSetAtom } from 'jotai'
import { useMemo } from 'react'

import {
  joinedGroupsLoadableAtom,
  reloadJoinedGroupsAtom,
} from 'packages/feature-utils/groups/atoms'
import { GroupScopeEnum } from 'packages/features/groups/types'
import {
  GroupType,
  JoinedGroup,
  MemberTypeEnum,
} from 'packages/sdks-next/chalk'

/**
 * 获取当前选中学期当前用户加入的群组
 *
 * @returns 加入的群组列表
 */
export const useJoinedGroups = () => {
  const result = useAtom(joinedGroupsLoadableAtom)[0]

  return useMemo(() => {
    if (result.state === 'loading' || result.state === 'hasError') {
      return [[], []] as [JoinedGroup[], GroupType[]]
    }

    return result.data
  }, [result])
}

/**
 * 获取当前选中学期当前用户加入的课程班群组
 *
 * @param memberType - 成员类型
 * @returns 加入的课程班群组列表
 */
export const useJoinedClassGroups = (memberType?: MemberTypeEnum) => {
  const [groups] = useJoinedGroups()

  return groups.filter(
    group =>
      group.type === 'seiue.class_group' &&
      (group.memberType === memberType || !memberType),
  )
}

/**
 * 获取当前用户加入的行政班群组
 *
 * @param memberType - 成员类型
 * @returns 加入的行政班群组列表
 */
export const useJoinedAdminClassGroups = (memberType?: MemberTypeEnum) => {
  const [groups] = useJoinedGroups()
  const refreshData = useSetAtom(reloadJoinedGroupsAtom)

  return [
    useMemo(
      () =>
        groups.filter(
          group =>
            group.type === 'seiue.adminclass_group' &&
            (group.memberType === memberType || !memberType),
        ),
      [groups, memberType],
    ),
    refreshData,
  ] as const
}

/**
 * 在我加入的群组中检查，在某些群组范围中我是否有指定的成员类型
 *
 * @param semesterId - 学期 ID
 * @param memberTypes - 成员类型
 * @param scopes - 群组范围 Scopes
 * @returns boolean
 */
export const useHasMemberTypesInJoinedGroups = (
  semesterId: number,
  memberTypes: MemberTypeEnum[],
  scopes: GroupScopeEnum[],
) => {
  const [groups] = useJoinedGroups()
  const realScopes = scopes.map(scope => {
    if (scope === GroupScopeEnum.Class) {
      return `${GroupScopeEnum.Class}.semester-${semesterId}`
    }

    return scope
  })

  return groups.some(group => {
    return (
      memberTypes.includes(group.memberType as MemberTypeEnum) &&
      realScopes.includes(group.scope)
    )
  })
}

/* eslint-disable multiline-comment-style */
/**
 * @file 提供国际化翻译包 provider 和用于标记需国际化文本的 $t
 */

import { env, isTest } from '@seiue/env'
import { isObject, uniq } from '@seiue/util'
import { useAtom } from 'jotai'
import React from 'react'
/* eslint-disable no-restricted-imports */
import { IntlProvider, IntlContext, IntlShape } from 'react-intl'
/* eslint-enable no-restricted-imports */
import { Props } from 'react-intl/dist/components/message'
import { OptionalIntlConfig } from 'react-intl/dist/components/provider'
import { useDispatch, useSelector } from 'react-redux'

import { findEnabledParentPluginAtom } from 'packages/feature-utils/plugins/atoms'
import { ModuleEnum } from 'packages/feature-utils/plugins/types'
import {
  LocaleTextCategory,
  LocaleTextLocaleEnum,
} from 'packages/sdks-next/chalk'

import { localeAtom } from './atoms'
import { getCustomLocaleTexts } from './custom-locale'
import { setErrorHandlerLocale } from './error-handler'
import { setMomentLocale } from './moment'
import { State as LocaleState } from './store'
import { Messages, UsefulLocaleEnum } from './types'

let rootIntlContext: IntlShape

export type LPProps = Partial<OptionalIntlConfig>

interface PLPProps extends LPProps {
  locale: UsefulLocaleEnum
  messages: Messages
}

const onErrorHandler = (err: any) => {
  /*
   * 忽略由于没有显示声明翻译文本而导致的 i18n 报错
   * 这个错误不影响实际文本的渲染
   */
  if (
    env('ENV') !== 'production' &&
    !((err as any)?.message ?? err).includes('Missing message')
  ) {
    console.warn(err)
  }
}

/**
 * 基础 Provider
 *
 * @param param0 - 参数
 * @returns 组件
 */
export const PlainLocaleProvider: React.FC<PLPProps> = param0 => {
  const { locale, messages, children, ...props } = param0

  setErrorHandlerLocale(locale)
  setMomentLocale(locale)

  return (
    <IntlProvider {...props} locale={locale} messages={messages}>
      <IntlContext.Consumer>
        {(value: IntlShape) => {
          rootIntlContext = value
          return children
        }}
      </IntlContext.Consumer>
    </IntlProvider>
  )
}

/**
 * Locale Provider，在外层使用本组件后，才可使用 $t
 *
 * @param param0 - 参数
 * @param param0.featuresInited - 分支是否已初始化
 * @param param0.props - 其他参数
 * @returns 组件
 */
export const LocaleProvider: React.FC<
  LPProps & {
    featuresInited: boolean
  }
> = ({ featuresInited, ...props }) => {
  const dispatch = useDispatch<any>()
  const {
    inited,
    refreshSign,
    locale: stateLocale,
    messages,
  } = useSelector((state: { locale: LocaleState }) => state.locale)

  const [locale, setLocale] = useAtom(localeAtom)

  /**
   * 为了规避与 sdks-next 和 feature-utils 的循环引用问题,
   * 使用比较底层的写法替代 useHasEnabledParentPlugin
   */
  const i18nEnabled = !!useAtom(findEnabledParentPluginAtom(ModuleEnum.I18n))[0]

  React.useEffect(() => {
    if (featuresInited) {
      dispatch.locale.inited(i18nEnabled)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresInited])

  // 刷新时，将内容赋空值再渲染
  // WHY？这里按理说应该可以直接根据 locale state 的 inited/locale 来渲染，但为什么没这么做呢？
  React.useEffect(() => {
    setLocale(prevLocale => {
      if (refreshSign) {
        requestAnimationFrame(() => {
          dispatch.locale.setRefreshSign(false)
        })

        return null
      }

      if (stateLocale && inited) {
        // 初始化完成后，赋值渲染

        return stateLocale
      }

      return prevLocale
    })
  }, [dispatch, refreshSign, stateLocale, inited, setLocale])

  if (!locale) return null

  return (
    <PlainLocaleProvider
      {...props}
      locale={locale}
      messages={messages}
      onError={onErrorHandler}
    />
  )
}

interface FormatMessage {
  (
    text: string,
    values?: Record<string, any>,
    options?: {
      description?: Props['description']
      messages?: Record<string, Record<string, string>>
    },
  ): string
}

/**
 * 多语言翻译函数
 *
 * @param text - 文本
 * @param values - 文本变量值
 * @param options - 配置
 * @returns 文本
 */
export const $t: FormatMessage = (text, values, options = {}) => {
  const intlContext = rootIntlContext

  if (!intlContext) {
    if (!isTest()) {
      console.error(
        `[packages/locale $t] $t 文本“${text}”时缺少上下文。使用 $t 时，你 React App 需要提供 i18n 上下文。具体请看 /apps/chalk/src/index.tsx`,
      )
    }

    return text
  }

  if (options.messages && isObject(intlContext.messages)) {
    const currentMessages = options.messages[intlContext.locale]

    if (currentMessages) {
      Object.entries(currentMessages).forEach(([key, value]) => {
        const hasOldTranslation = Object.prototype.hasOwnProperty.call(
          intlContext.messages,
          key,
        )

        const isNewTranslationUseful = key !== value // 即非 "中文": "中文" 的情况
        if (!hasOldTranslation || isNewTranslationUseful)
          intlContext.messages[key] = value
      })
    }
  }

  return intlContext.formatMessage(
    {
      id: text,
      defaultMessage: text,
      description: options.description,
    },
    values,
  )
}

/**
 * 自定义多语言翻译函数
 *
 * 用于翻译一些由用户自己创建的文本，比如请假标签、考勤标签等
 * 目前支持请假、考勤的翻译
 *
 * @param text - 文本
 * @param category - 分类，默认使用 "默认" 分类
 * @returns 翻译过的文本
 */
export const $ct = (
  text: string | null | undefined,
  category: LocaleTextCategory = 'common',
) => {
  if (!text) return ''

  const intlContext = rootIntlContext

  if (!intlContext) {
    if (!isTest()) {
      console.error(
        `[packages/locale $ct] $ct 文本“${text}”时缺少上下文。使用 $ct 时，React App 需要提供 i18n 上下文。具体请看 /apps/chalk/src/index.tsx`,
      )
    }

    return text
  }

  if (intlContext.locale === LocaleTextLocaleEnum.EnUS) {
    let categories = uniq([category, 'common'])

    // 如果 categories 是考勤或请假，就要同时从这两个 categories 去查找，因为考勤结果有时候会返回请假类型
    if (['attendance', 'absence'].includes(category)) {
      categories = uniq([category, 'attendance', 'absence', 'common'])
    }

    // 按照 categories 优先级查找
    const localeText = getCustomLocaleTexts()
      .filter(
        item => item.src === text && categories.includes(item.category || ''),
      )
      .sort((a, b) => {
        const categoryAIndex = categories.indexOf(a.category || '')
        const categoryBIndex = categories.indexOf(b.category || '')

        // 如果 category 不在 categories 中，则保持原顺序
        return (
          (categoryAIndex === -1 ? Infinity : categoryAIndex) -
          (categoryBIndex === -1 ? Infinity : categoryBIndex)
        )
      })?.[0]?.dstEn

    return localeText || text
  }

  return text
}

/**
 * @file message type
 */
export enum NoticeTypes {
  Published = 'published',
}

export enum SignupTypes {
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum AttendanceTypes {
  Student = 'abnormal_attendance.student',
  Guardian = 'abnormal_attendance.guardian',
  Teacher = 'miss_attendance.teacher',
}

/**
 * 人员变动
 */
export enum MemberChanges {
  Added = 'member_added',
  Removed = 'member_removed',
}

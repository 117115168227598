/**
 * @file 任务关联的评价项 utils
 */

import { useMemo } from 'react'

import { isItemHasRawScores } from 'packages/features/grades/utils'
import { AssignedTask, Task, TaskEnhancerEnum } from 'packages/sdks-next/chalk'
import {
  AssessmentStageStatusEnum,
  AssessmentStatusEnum,
  Item,
  ScoreTypeEnum,
  defaultApi$loadKlassItem,
  defaultApi$getItemScores,
  commonApi$personalAchievedScores,
} from 'packages/sdks-next/vnas'

/**
 * 尽可能缓存式的获取任务的评价项
 * 该评价项需携带 assessment 信息
 *
 * @param task - 任务
 * @returns 评价项信息
 */
export const useTaskItem = (task?: Task | AssignedTask | null) => {
  const itemId = task?.customFields?.itemId || 0

  return defaultApi$loadKlassItem.useApi(
    {
      id: itemId,
      query: {
        tryExpand: ['assessment', 'assessmentStage', 'stage'],
      },
    },
    { disable: !itemId },
  )
}

/**
 * 任务评价项分数
 *
 * @param param0 - 参数
 * @param param0.item - 评价项
 * @param param0.ownerId - 分数所有者的 id
 * @returns 分数
 */
export const useTaskItemScores = ({
  item,
  ownerId,
}: {
  item?: Item | null
  ownerId?: number
}) => {
  const scoreType = !item
    ? undefined
    : isItemHasRawScores(item)
    ? ScoreTypeEnum.Raw
    : ScoreTypeEnum.ItemScore

  const {
    data: scores,
    reload,
    loading,
  } = defaultApi$getItemScores.useApi(
    {
      id: item?.id as number,
      query: {
        type: scoreType,
        paginated: 0,
      },
    },
    { disable: !item?.id || !scoreType },
  )

  const taskScores = useMemo(() => {
    if (!scores?.length) return undefined

    if (!ownerId) return scores

    return scores.filter(score => score.ownerId === ownerId)
  }, [scores, ownerId])

  return [taskScores, reload, loading] as const
}

/**
 * 我的任务评价项分数
 *
 * @param param0 - 参数
 * @param param0.item - 评价项
 * @param param0.rid - 分数所有者的 id
 * @param param0.withTrashed - withTrashed
 * @returns 分数
 */
export const useMyTaskItemScores = ({
  item,
  rid,
  withTrashed,
}: {
  item?: Item | null
  rid: number
  withTrashed: boolean
}) => {
  const scoreType = !item
    ? undefined
    : isItemHasRawScores(item)
    ? ScoreTypeEnum.Raw
    : ScoreTypeEnum.ItemScore

  const { data: scores, reload } = commonApi$personalAchievedScores.useApi(
    {
      ownerId: rid,
      assessmentId: item?.assessmentId as number,
      query: {
        itemId: item?.id as number,
        type: scoreType,
        withTrashed,
      },
    },
    { disable: !item?.assessmentId || !item?.id || !scoreType },
  )

  const taskScores = useMemo(() => {
    if (!scores?.length) return undefined
    return scores
  }, [scores])

  return [taskScores, reload] as const
}

/**
 * 当前任务类型是否可以绑定评价项
 *
 * @param enhancer - 任务类型
 * @returns boolean
 */
export const isTaskEnableAssessmentItem = (enhancer: string) =>
  [
    TaskEnhancerEnum.ClassActivityTask,
    TaskEnhancerEnum.ClassHomeworkTask,
    TaskEnhancerEnum.ClassQuizTask,
  ].includes(enhancer as TaskEnhancerEnum)

/**
 * 判断任务关联的评价项所在的评价是否已发布
 *
 * @param item - 关联评价项
 * @returns 是否
 */
export const isTaskItemAssessmentPublished = (item?: Item | null) => {
  return (
    !!item?.assessment?.status &&
    [
      AssessmentStatusEnum.Published,
      AssessmentStatusEnum.Passed,
      AssessmentStatusEnum.Submitted,
    ].includes(item.assessment.status)
  )
}

/**
 * 判断任务关联的评价项所在的学段是否已发布
 *
 * @param item - 关联评价项
 * @returns 是否
 */
export const isTaskItemStagePublished = (item?: Item | null) => {
  if (item?.stage && item.assessmentStage) {
    return [
      AssessmentStageStatusEnum.Submitted,
      AssessmentStageStatusEnum.Passed,
      AssessmentStageStatusEnum.Published,
    ].includes(item.assessmentStage.status)
  }

  return false
}

import { getClassDisplayName } from 'packages/feature-utils/classes/class-name'

import { Class as ClassBase } from '../class'

export type { ClassInterface } from '../class'

/**
 * 课程班
 */
export class Class extends ClassBase {
  /**
   * 获取课程班的展示名称，课程名称 + 年级 + 班级名称，英文环境下没有设置英文名默认用中文
   *
   * @param isEn - 是否在英文环境
   * @returns string
   */
  getDisplayName(isEn?: boolean): string {
    return getClassDisplayName(this, isEn)
  }

  /**
   * 获取上课周展示的文本
   *
   * @returns 上课周文本
   */
  get classWeeksLabel() {
    return !this.classWeeks?.length
      ? ''
      : this.classWeeks
          .map(
            classWeek =>
              `${classWeek.startAt}-${classWeek.endAt} (${classWeek.weekTypeLabel})`,
          )
          .join(',')
  }
}

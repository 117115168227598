/**
 * @file sdk-next hook
 */

import { AxiosResponsePromise, Pagination } from '@seiue/axios'
import { schema as normalizrSchema } from '@seiue/normalizr'
import {
  isNumber,
  isPlainObject,
  isString,
  trim,
  useLazyCopy,
  PromiseOf,
  useForceUpdate,
} from '@seiue/util'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  getQueryKey,
  isExpired,
  QueryEffectResult,
  useDenormalized,
  useDenormalizedQueryResults,
} from 'packages/entities-store'

import { ExpandedEntity, FindExpandableQuery } from './types'
import { extractExpandNonNullables, requirePropPaths } from './utils'

const useClearQueryCache = (
  defaultSchema: normalizrSchema.Entity,
  clearQueryCache?: boolean | normalizrSchema.Entity[],
) => {
  const { entities } = useDispatch<any>()
  const lazyClearQueryCache = useLazyCopy(clearQueryCache)

  return useCallback(() => {
    if (lazyClearQueryCache) {
      const schemas =
        lazyClearQueryCache === true ? [defaultSchema] : lazyClearQueryCache

      entities.clearQueryCacheOfSchemas(schemas)
    }
  }, [defaultSchema, entities, lazyClearQueryCache])
}

/**
 * @util
 * 装饰并返回一个返回值为单个资源的 api 方法，调用装饰后的方法将会在请求返回后缓存请求结果
 * @param schema 资源的 schema
 * @param api api 方法
 * @param clearQueryCache 同时清除 schema 的 query cache，传 true 则清除对象为一同传入的 schema，也可以传入一个 schema 数组去清除其他 schema 的 query cache。如有需要可移植到其他 hook。
 * @return decorated api function
 */
export const useCache = <
  TEntity,
  TApi extends (...args: readonly any[]) => AxiosResponsePromise<TEntity>,
>({
  schema,
  api,
  clearQueryCache = false,
}: {
  schema: normalizrSchema.Entity<TEntity>
  api: TApi
  clearQueryCache?: boolean | normalizrSchema.Entity[]
}) => {
  const { entities } = useDispatch<any>()
  const clearQueryCacheMethod = useClearQueryCache(schema, clearQueryCache)
  return useCallback(
    async (...args: Parameters<TApi>) => {
      const res = await api(...args)
      entities.set({ data: res.data, schema })
      clearQueryCacheMethod()
      return res
    },
    [api, entities, schema, clearQueryCacheMethod],
  )
}

/**
 * @util
 * 装饰并返回一个返回值为单个资源的 api 方法（通常是创建或更新），调用装饰后的方法将会在请求返回后根据资源 id 删除对应的缓存
 * @param schema 资源的 schema
 * @param api api 方法
 * @param [getCacheId=(...params) => params[0]] 指定由 api 的 args 计算资源 id 的方法
 * @return decorated api function
 */
export const useUncache = <
  TEntity,
  TApi extends (...args: any[]) => AxiosResponsePromise<null>,
>({
  schema,
  api,
  getCacheId = (...[id]: Parameters<TApi>) => id,
}: {
  schema: normalizrSchema.Entity<TEntity>
  api: TApi
  getCacheId?: (...params: Parameters<TApi>) => string | number
}) => {
  const { entities } = useDispatch<any>()
  return useCallback(
    async (...args: Parameters<TApi>) => {
      const res = await api(...args)
      entities.remove({ id: getCacheId(...args), schema })
      return res
    },
    [schema, api, entities, getCacheId],
  )
}

/**
 * @util
 * 装饰并返回一个返回值为多个资源的 api 方法（通常是批量创建或更新），调用装饰后的方法将会在请求返回后缓存请求结果
 * @param schema 资源的 schema
 * @param api api 方法
 * @return decorated api function
 */
export const useBatchCache = <
  TEntity,
  TApi extends (...args: any[]) => AxiosResponsePromise<TEntity[]>,
>({
  schema,
  api,
}: {
  schema: normalizrSchema.Entity<TEntity>
  api: TApi
}) => {
  const { entities } = useDispatch<any>()
  return useCallback(
    async (...args: Parameters<TApi>) => {
      const res = await api(...args)
      entities.set({ data: res.data, schema: [schema] })
      return res
    },
    [schema, api, entities],
  )
}

/**
 * @util
 * 装饰并返回一个返回值为多个资源的 api 方法（通常是批量删除），调用装饰后的方法将会在请求结束后删除指定的缓存
 * @param schema 资源的 schema
 * @param api api 方法
 * @param [getCacheIds=(...params) => params[0].split(',)] 指定由 api 的 args 计算资源 ids 的方法
 * @return decorated api function
 */
export const useBatchUncache = <
  TEntity,
  TApi extends (...args: any[]) => AxiosResponsePromise<null>,
>({
  schema,
  api,
  getCacheIds = (...[idIn]: Parameters<TApi>) => idIn.split(',').map(trim),
}: {
  schema: normalizrSchema.Entity<TEntity>
  api: TApi
  getCacheIds?: (...params: Parameters<TApi>) => (string | number)[]
}) => {
  const { entities } = useDispatch<any>()
  return useCallback(
    async (...args: Parameters<TApi>) => {
      const res = await api(...args)
      const ids = getCacheIds(...args)
      entities.removeMany({ ids, schema })
      return res
    },
    [schema, api, entities, getCacheIds],
  )
}

const useIsCacheValid = (
  cache: any,
  cachedAtMainKey: string,
  cachedAtSubKey: string | number | string[] | number[],
  useCacheOption: number | boolean,
  args: readonly any[],
) =>
  useMemo(() => {
    const expands = extractExpandNonNullables(args)
    return !(
      // cache 是否存在
      (
        !cache ||
        // cache 是否过期
        (isNumber(useCacheOption) &&
          isExpired(cachedAtMainKey, cachedAtSubKey, useCacheOption)) ||
        // cache 是否完整包含所需的关联数据
        !(Array.isArray(cache) ? cache : [cache]).every((c: any) =>
          requirePropPaths(c, expands),
        )
      )
    )
  }, [args, cache, useCacheOption, cachedAtMainKey, cachedAtSubKey])

/**
 * @util
 * 装饰并运行加载单个资源的 api 方法，在发送请求前先尝试使用缓存（行为可配置），在加载后更新缓存
 * @param schema 资源的 schema
 * @param api api 方法
 * @param args api 方法的参数数组
 * @param [getCacheId=(...params) => params[0]] 指定由 api 的 args 计算资源 id 的方法
 * @param [useCache=true] 配置缓存行为，true 为默认行为，在发送请求前先尝试读取缓存，false 则不读缓存。传 number 则指定最大缓存年龄（秒数），读到年龄在该限制以内的缓存不会再 call api，读到年龄超出限制的缓存会忽略然后 call api。这个特性只适用于访问频繁、且变动频率很低的数据，比如学期、当前用户权限。最大缓存年龄可设置为稍大于一个典型用户访问 session 的长度，推荐 1 小时（3600）。
 * @return [result, loading]
 */
export const useFind = <
  TEntity,
  TArgs extends readonly any[],
  TApi extends (...args: TArgs) => AxiosResponsePromise<any>,
  TQuery extends FindExpandableQuery<TArgs>,
>({
  schema,
  api: apiRaw,
  args,
  getCacheId = (...[id]: any[]) => id,
  useCache: useCacheOption = true,
  waitFor,
}: {
  schema: normalizrSchema.Entity<TEntity>
  api: TApi
  args: TArgs
  getCacheId?: (...params: any[]) => string | number
  useCache?: number | boolean
  waitFor?: boolean
}): [ExpandedEntity<TEntity, TQuery> | null, boolean, () => void] => {
  const api = useCache({ schema, api: apiRaw as any })

  // if try to return cached result first
  const cacheFirstOrOnly = useCacheOption !== false

  // keep the reference stable if content stays same
  const lazyArgs = useLazyCopy(args)

  // compute and validate cache id
  const cacheId = getCacheId(...args)
  if (cacheFirstOrOnly && !isString(cacheId) && !isNumber(cacheId)) {
    throw new Error(
      `[useFind] cacheId 应为 string 或 number, 但现在是 ${JSON.stringify(
        cacheId,
      )}. 请添加或修正 getCacheId. 如果当前上下文不能算出 cacheId, 可设置 useCache: false 跳过读取缓存.`,
    )
  }

  // try retrieve cache from cache store
  const cache = useDenormalized(schema, cacheId) as TEntity | null

  // decide if the cache is complete and still fresh
  const validCache = useIsCacheValid(
    cache,
    schema.key,
    cacheId,
    useCacheOption,
    lazyArgs,
  )
    ? cache
    : null

  // store and return fetched if cache === false (!cacheFirstOrOnly)
  const [fetched, setFetched] = useState<TEntity | null>(null)

  /**
   * loading 与 refreshingData 的区别是：
   * loading 为 true 表示未命中缓存数据，
   * refreshingData 为 true 表示命中了缓存，在后台刷新
   */
  const [loading, setLoading] = useState(
    !(cacheFirstOrOnly ? validCache : fetched),
  )

  // forceReload() to force re-send api
  const [forceReload] = useForceUpdate()

  // waitFor != true 或 cache-only 模式下 cache 依然有效时，不发请求
  const shouldCallApi =
    (waitFor ?? true) && !(isNumber(useCacheOption) && validCache)

  useEffect(() => {
    if (!shouldCallApi) return

    if (!cacheFirstOrOnly || !validCache) {
      setLoading(true)
    }

    api(...lazyArgs)
      .then(({ data }) => {
        /**
         * only setFetched when not cache-first or cache-only
         * when in cache-first or cache-only, we're not using fetched at all
         * putting setFetched in if-condition reduces 2 re-runs of the hook
         */
        if (!cacheFirstOrOnly) {
          setFetched(data)
        }
      })
      .finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyArgs, api, shouldCallApi, forceReload])

  return [cacheFirstOrOnly ? validCache : fetched, loading, forceReload] as any
}

export const useFindRaw = <
  TArgs extends readonly any[],
  TApi extends (...args: TArgs) => AxiosResponsePromise<any>,
  TQuery extends FindExpandableQuery<TArgs>,
>({
  api,
  args,
  waitFor,
}: {
  api: TApi
  args: TArgs
  waitFor?: boolean
}): [
  ExpandedEntity<PromiseOf<ReturnType<TApi>>['data'], TQuery> | null,
  boolean,
  () => void,
] => {
  const [fetchedData, setData] = React.useState<any>(null)

  // keep the reference stable if content stays same
  const lazyArgs = useLazyCopy(args)
  const [loading, setLoading] = React.useState(waitFor !== false)

  // forceReload() to force re-send api
  const [forceReload] = useForceUpdate()

  // waitFor != true 或 cache-only 模式下 cache 依然有效时，不发请求
  const shouldCallApi = waitFor ?? true

  useEffect(() => {
    if (!shouldCallApi) return

    setLoading(true)
    api(...lazyArgs)
      .then(({ data }) => {
        setData(data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [lazyArgs, api, shouldCallApi, forceReload])

  return [fetchedData, loading, forceReload] as any
}

/**
 * @util
 * 装饰并运行加载多个资源的 api 方法，在发送请求前先尝试使用缓存（行为可配置），在加载后更新缓存
 * @param schema 资源的 schema
 * @param api api 方法
 * @param args api 方法的参数数组
 * @param [useCache=true] 配置缓存行为，true 为默认行为，在发送请求前先尝试读取缓存，false 则不读缓存。传 number 则指定最大缓存年龄（秒数），读到年龄在该限制以内的缓存不会再 call api，读到年龄超出限制的缓存会忽略然后 call api。这个特性只适用于访问频繁、且变动频率很低的数据，比如学期、当前用户权限。最大缓存年龄可设置为稍大于一个典型用户访问 session 的长度，推荐 1 小时（3600）。
 * @param [waitFor] 传入 waitFor 之后会等待该值变为 true 后再发送请求，一般用于等待该请求的一些依赖数据加载完毕，比如在 app 渲染初期等待 currentReflection 加载完毕
 * @return [data, pagination, loading, forceReload]
 */
export const useFindAll = <
  TEntity,
  TArgs extends readonly any[],
  TApi extends (...args: TArgs) => AxiosResponsePromise<any[]>,
  TQuery extends FindExpandableQuery<TArgs>,
>({
  schema,
  api,
  args,
  useCache: useCacheOption = true,
  waitFor,
}: {
  schema: normalizrSchema.Entity<TEntity>
  api: TApi
  args: TArgs
  useCache?: number | boolean
  waitFor?: boolean
}): [
  ExpandedEntity<TEntity, TQuery>[] | null,
  Pagination | null,
  boolean,
  () => void,
] => {
  const { entities } = useDispatch<any>()

  // if try to return cached result first
  const cacheFirstOrOnly = useCacheOption !== false

  // keep the reference stable if content stays same
  const lazyArgs = useLazyCopy(args)

  /**
   * args [] -> query {} -> lazyCopy
   * 转化为向后兼容 query cache 的格式
   */
  const lazyQuery = useMemo(
    () =>
      lazyArgs.reduce((q, arg, idx) => {
        if (isPlainObject(arg)) return { ...q, ...arg }
        return { ...q, [`arg${idx}`]: arg }
      }, {}),
    [lazyArgs],
  )

  // used as part of cache key for this api's results
  const requestName = (api as any)._name_
  const apiCacheKey = `${schema.key}.${requestName}`
  const queryKey = useMemo(() => getQueryKey(lazyQuery), [lazyQuery])

  // try retrieve cache from cache store
  const cache = useDenormalizedQueryResults(apiCacheKey, lazyQuery, schema)

  // decide if the cache is still fresh
  const validCache = useIsCacheValid(
    cache?.data,
    apiCacheKey,
    queryKey,
    useCacheOption,
    lazyArgs,
  )
    ? cache
    : null

  // store and return fetched if cache === false (!cacheFirstOrOnly)
  const [fetched, setFetched] = useState<QueryEffectResult<TEntity> | null>(
    null,
  )

  /**
   * loading 与 refreshingData 的区别是：
   * loading 为 true 表示未命中缓存数据，
   * refreshingData 为 true 表示命中了缓存，在后台刷新
   */
  const [loading, setLoading] = useState(
    !(cacheFirstOrOnly ? validCache : fetched),
  )

  // forceReload() to force re-send api
  const [forceReload] = useForceUpdate()

  // waitFor != true 或 cache-only 模式下 cache 依然有效时，不发请求
  const shouldCallApi =
    (waitFor ?? true) && !(isNumber(useCacheOption) && validCache)

  useEffect(() => {
    if (!shouldCallApi) {
      setLoading(false)
      return
    }

    if (!cacheFirstOrOnly || !validCache) {
      setLoading(true)
    }

    api(...lazyArgs)
      .then(({ data, pagination }) => {
        // cache new result
        entities.setQueried({
          data,
          pagination,
          fetchRemoteKey: apiCacheKey,
          query: queryKey,
          schema,

          /**
           * 在 cache-only 模式下当新数据和缓存完全一致时，也强制更新缓存，
           * 否则虽然 cachedAt 更新了但 validCache 因为没有触发重计算会一直为空
           */
          forceTouch: isNumber(useCacheOption),
        })

        /**
         * only setFetched when not cache-first or cache-only
         * when in cache-first or cache-only, we're not using fetched at all
         * putting setFetched in if-condition reduces 2 re-runs of the hook
         */
        if (!cacheFirstOrOnly) {
          setFetched({ data, pagination })
        }
      })
      .finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyArgs, shouldCallApi, forceReload])

  const toReturn = cacheFirstOrOnly ? validCache : fetched
  return [
    toReturn?.data || null,
    toReturn?.pagination || null,
    loading,
    forceReload,
  ] as any
}

/**
 * @util
 * 简单封装了 raw api 提供 loading 和 forceReload，相当于 useFindAll 的无缓存版本
 */
export const useFindAllRaw = <
  TArgs extends readonly any[],
  TApi extends (...args: TArgs) => AxiosResponsePromise<any[]>,
  TQuery extends FindExpandableQuery<TArgs>,
>(findAllArgs: {
  api: TApi
  args: TArgs
  waitFor?: boolean
}): [
  ExpandedEntity<PromiseOf<ReturnType<TApi>>['data'][number], TQuery>[] | null,
  Pagination | null,
  boolean,
  () => void,
] => {
  const [data, setData] = React.useState<any>(null)
  const [pagination, setPagination] = React.useState<Pagination>(null)
  const [loading, setLoading] = React.useState(findAllArgs.waitFor !== false)
  const [forceReload] = useForceUpdate()

  const copyArgs = useLazyCopy(findAllArgs)
  React.useEffect(() => {
    const { api, args, waitFor } = copyArgs

    if (waitFor === false) return

    setLoading(true)

    api(...args)
      .then(res => {
        setData(res.data)
        setPagination(res.pagination)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [copyArgs, forceReload])

  return [data, pagination, loading, forceReload] as any
}

export enum ExprType {
  And = 'and',
  Or = 'or',
  Binary = 'binary',
  Func = 'func',
  Literal = 'literal',
  Variable = 'variable',
  Column = 'column',
  Field = 'field',
  Agg = 'agg',
}

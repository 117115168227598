import { Scope, ScopeInterface } from './scope'
import { SchoolPlugin, SchoolPluginInterface } from './school-plugin'
import {
  FormTemplateFieldWithoutId,
  FormTemplateFieldWithoutIdInterface,
} from './form-template-field-without-id'
import { EventInstanceType } from './event-instance-type'

export interface EventInstanceInterface {
  initiatorScopes?: ScopeInterface[]
  schoolPlugin?: SchoolPluginInterface
  formTitle: string
  formDescription: string
  formFields: FormTemplateFieldWithoutIdInterface[]
  type?: EventInstanceType | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class EventInstance implements EventInstanceInterface {
  initiatorScopes?: Scope[]
  schoolPlugin?: SchoolPlugin
  formTitle: string
  formDescription: string
  formFields: FormTemplateFieldWithoutId[]
  type?: EventInstanceType | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: EventInstanceInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      initiatorScopes: _initiatorScopes,
      schoolPlugin: _schoolPlugin,
      formTitle: _formTitle,
      formDescription: _formDescription,
      formFields: _formFields,
      type: _type,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.initiatorScopes = _initiatorScopes
      ? _initiatorScopes.map(i => new Scope(i))
      : _initiatorScopes
    this.schoolPlugin = _schoolPlugin
      ? new SchoolPlugin(_schoolPlugin)
      : _schoolPlugin
    this.formTitle = _formTitle
    this.formDescription = _formDescription
    this.formFields = _formFields
      ? _formFields.map(i => new FormTemplateFieldWithoutId(i))
      : _formFields
    this.type = _type
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'initiatorScopes',
    'schoolPlugin',
    'formTitle',
    'formDescription',
    'formFields',
    'type',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

import { GroupTeam, GroupTeamInterface } from './group-team'
import { AssignmentStatusEnum } from './assignment-status-enum'
import { TaskAssignee, TaskAssigneeInterface } from './task-assignee'
import { TaskSubmission, TaskSubmissionInterface } from './task-submission'
import { TaskReview, TaskReviewInterface } from './task-review'
import { Assignment, AssignmentInterface } from './assignment'

export interface TaskAssignmentInterface {
  assigneeId: number
  taskId: number
  /**
   * 任务执行人小组id
   */
  teamId?: number | null
  /**
   * 小组信息
   */
  team?: GroupTeamInterface | null
  /**
   * 任务状态
   */
  status: AssignmentStatusEnum
  /**
   * assignee 的个人信息（ reflection 的子集） expanded
   */
  assignee?: TaskAssigneeInterface | null
  /**
   * 已读/未读
   */
  isRead?: boolean
  /**
   * 是否逾期
   */
  isOverdue?: boolean
  /**
   * 是否选为优秀作业
   */
  isExcellent?: boolean
  /**
   * 完成任务时间
   */
  achievedAt?: string | null
  /**
   * 最新的提交成果
   */
  submission?: TaskSubmissionInterface | null
  /**
   * 最新的评审信息
   */
  review?: TaskReviewInterface | null
  /**
   * 测验任务关联的handout assignment
   */
  handoutAssignment?: AssignmentInterface | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class TaskAssignment implements TaskAssignmentInterface {
  assigneeId: number
  taskId: number
  /**
   * 任务执行人小组id
   */
  teamId?: number | null
  /**
   * 小组信息
   */
  team?: GroupTeam | null
  /**
   * 任务状态
   */
  status: AssignmentStatusEnum
  /**
   * assignee 的个人信息（ reflection 的子集） expanded
   */
  assignee?: TaskAssignee | null
  /**
   * 已读/未读
   */
  isRead?: boolean
  /**
   * 是否逾期
   */
  isOverdue?: boolean
  /**
   * 是否选为优秀作业
   */
  isExcellent?: boolean
  /**
   * 完成任务时间
   */
  achievedAt?: string | null
  /**
   * 最新的提交成果
   */
  submission?: TaskSubmission | null
  /**
   * 最新的评审信息
   */
  review?: TaskReview | null
  /**
   * 测验任务关联的handout assignment
   */
  handoutAssignment?: Assignment | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: TaskAssignmentInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      assigneeId: _assigneeId,
      taskId: _taskId,
      teamId: _teamId,
      team: _team,
      status: _status,
      assignee: _assignee,
      isRead: _isRead,
      isOverdue: _isOverdue,
      isExcellent: _isExcellent,
      achievedAt: _achievedAt,
      submission: _submission,
      review: _review,
      handoutAssignment: _handoutAssignment,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.assigneeId = _assigneeId
    this.taskId = _taskId
    this.teamId = _teamId
    this.team = _team ? new GroupTeam(_team) : _team
    this.status = _status
    this.assignee = _assignee ? new TaskAssignee(_assignee) : _assignee
    this.isRead = _isRead
    this.isOverdue = _isOverdue
    this.isExcellent = _isExcellent
    this.achievedAt = _achievedAt
    this.submission = _submission
      ? new TaskSubmission(_submission)
      : _submission
    this.review = _review ? new TaskReview(_review) : _review
    this.handoutAssignment = _handoutAssignment
      ? new Assignment(_handoutAssignment)
      : _handoutAssignment
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'assigneeId',
    'taskId',
    'teamId',
    'team',
    'status',
    'assignee',
    'isRead',
    'isOverdue',
    'isExcellent',
    'achievedAt',
    'submission',
    'review',
    'handoutAssignment',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

/**
 * @file register plugins navigator
 */

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator(
  [
    'questionnaire.uncompleted_notice_for_reflection',
    'questionnaire.uncompleted_notice',
  ],
  ({ attributes }) => {
    const id = attributes?.id
    return {
      modalName: 'SubmitQuestionnaireModal',
      options: { id },
    }
  },
)

/**
 * @file apis
 */
import React from 'react'

import { QueryOptions } from 'packages/sdks-next'
import {
  AdminClass,
  Class,
  EvaluationGroupBizTypeEnum,
  LoadEvaluationQuery,
  evaluationApi$loadEvaluation,
  evaluationApi$queryEvaluationForms,
  classApi$queryClasses,
  adminClassApi$queryAdminClasses,
} from 'packages/sdks-next/vnas'

/**
 * 获取评教信息
 *
 * @param props - 参数
 * @param props.id - 评教 id
 * @param props.query - 查询参数
 * @param queryOptions - 查询选项
 * @returns 评教信息
 */
export const useLoadEvaluation = (
  { id, query = {} }: { id: number; query?: LoadEvaluationQuery },
  queryOptions?: QueryOptions,
) =>
  evaluationApi$loadEvaluation.useApi(
    {
      id,
      query: {
        ...query,
        expand: [
          'formTemplate',
          ['formTemplate', 'formTemplateFields'],
        ] as const,
      },
    },
    queryOptions,
  )

/**
 * 获取评教表单
 *
 * @param props - 参数
 * @param props.evaluationId - 评教 id
 * @param props.hasOverallEvaluation - 是否有总体评价的题目
 * @param queryOptions - 查询选项
 * @returns 评教表单
 */
export const useQueryEvaluationForms = (
  {
    evaluationId,
    hasOverallEvaluation = false,
  }: { evaluationId: number; hasOverallEvaluation?: boolean },
  queryOptions?: QueryOptions,
) =>
  evaluationApi$queryEvaluationForms.useApi(
    {
      id: evaluationId,
      query: {
        expand: [
          'feedbacks',
          hasOverallEvaluation ? 'overallAnswers' : 'answers',
        ],
        tryExpand: ['group', 'subject'],
      },
    },
    queryOptions,
  )

/**
 * 通过群组 ids 获取评教群组列表
 *
 * @param props - 参数
 * @param props.groupBizType - 群组业务类型
 * @param props.ids - 群组 ids
 * @returns 评教群组列表
 */
export const useEvaluationGroupBizListByIds = ({
  groupBizType,
  ids,
}: {
  groupBizType: EvaluationGroupBizTypeEnum
  ids: string
}) => {
  const [groupBizList, setGroupBizList] = React.useState<
    Class[] | AdminClass[]
  >()

  React.useEffect(() => {
    const loadData = async () => {
      const api =
        groupBizType === EvaluationGroupBizTypeEnum.ClassGroup
          ? classApi$queryClasses.api
          : adminClassApi$queryAdminClasses.api

      const { data } = await (
        api as (params: { idIn: string }) => Promise<any>
      )({ idIn: ids })

      setGroupBizList(data)
    }

    if (ids) {
      loadData()
    }
  }, [groupBizType, ids, setGroupBizList])

  return [groupBizList as any[] | undefined] as const
}

export interface OAuthTokenInterface {
  /**
   * Token 类型，目前仅支持 Bearer
   */
  tokenType: string
  /**
   * Token 过期时间，单位为秒
   */
  expiresIn: number
  /**
   * 请求应该携带的 Access Token
   */
  accessToken: string
  /**
   * 用于刷新 Access Token 的 Refresh Token，有效期为 30 天, 仅 password 授权类型存在此字段。
   */
  refreshToken?: string
}

export class OAuthToken implements OAuthTokenInterface {
  /**
   * Token 类型，目前仅支持 Bearer
   */
  tokenType: string
  /**
   * Token 过期时间，单位为秒
   */
  expiresIn: number
  /**
   * 请求应该携带的 Access Token
   */
  accessToken: string
  /**
   * 用于刷新 Access Token 的 Refresh Token，有效期为 30 天, 仅 password 授权类型存在此字段。
   */
  refreshToken?: string

  constructor(props: OAuthTokenInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      tokenType: _tokenType,
      expiresIn: _expiresIn,
      accessToken: _accessToken,
      refreshToken: _refreshToken,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.tokenType = _tokenType
    this.expiresIn = _expiresIn
    this.accessToken = _accessToken
    this.refreshToken = _refreshToken
  }

  static propKeys = ['tokenType', 'expiresIn', 'accessToken', 'refreshToken']
}

import { AdminClass, AdminClassInterface } from './extends/admin-class'
import { ClassSelection, ClassSelectionInterface } from './class-selection'
import { TeacherHour, TeacherHourInterface } from './teacher-hour'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import { Timetable, TimetableInterface } from './timetable'
import { ClassWeek, ClassWeekInterface } from './extends/class-week'
import { ClassWeekTypeEnum } from './class-week-type-enum'
import { Place, PlaceInterface } from './place'
import {
  ClassScheduleWithId,
  ClassScheduleWithIdInterface,
} from './class-schedule-with-id'
import { Course, CourseInterface } from './course'
import { Term, TermInterface } from './extends/term'
import { ClassTypeEnum } from './class-type-enum'

export interface LessonClassInterface {
  /**
   * 关联行政班 expanded
   */
  adminClasses?: AdminClassInterface[]
  /**
   * 课程id
   */
  courseId?: number | null
  /**
   * 课程班名称
   */
  className: string
  /**
   * 课程班英文名称（未设置时为空字符串）
   */
  classEname: string
  /**
   * 容量
   */
  capacity?: number | null
  /**
   * 教师 expanded
   */
  teachers?: ClassSelectionInterface[]
  /**
   * 任课教师课时 expanded
   */
  teacherHoursInfo?: TeacherHourInterface[]
  /**
   * 代课教师课时 expanded
   */
  substituteTeacherHoursInfo?: TeacherHourInterface[]
  /**
   * 学生 expanded
   */
  students?: ClassSelectionInterface[]
  /**
   * 教师基础信息 expanded
   */
  teachersInfo?: SecuredReflectionInterface[]
  /**
   * 学生基础信息 expanded
   */
  studentsInfo?: SecuredReflectionInterface[]
  /**
   * 课表 ID
   */
  timetableId: number
  /**
   * 课表 expand
   */
  timetable?: TimetableInterface | null
  classWeekCustom?: boolean
  /**
   * 上课周时间段
   */
  classWeeks?: ClassWeekInterface[] | null
  /**
   * 上课方式
   */
  weekType?: ClassWeekTypeEnum
  /**
   * 空间id
   */
  placeIds?: number[] | null
  /**
   * 空间 expand
   */
  places?: PlaceInterface[]
  /**
   * 上课时间 expanded
   */
  classTimes?: ClassScheduleWithIdInterface[]
  classHour?: number
  studentNums?: number
  /**
   * 学生组成（男/女）
   */
  studentGenderInfo?: string | null
  /**
   * 修改生效时间
   */
  workTime?: string | null
  /**
   * expanded
   */
  course?: CourseInterface | null
  /**
   * term id, type=class.tag
   */
  tagId?: number | null
  /**
   * expanded
   */
  tag?: TermInterface | null
  photo?: string | null
  /**
   * 课程班类型
   */
  classType: ClassTypeEnum
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 学期 ID
   */
  semesterId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  csin: string
  name: string
  ename?: string | null
  /**
   * 学分
   */
  credit?: number | null
  /**
   * term id, type=system.school_structure
   */
  gradeIds?: number[]
  /**
   * expanded
   */
  grades?: TermInterface[]
  /**
   * term id, type=course.domain
   */
  domainId?: number | null
  /**
   * expanded
   */
  domain?: TermInterface | null
  /**
   * term id, type=course.subject
   */
  subjectId?: number | null
  /**
   * expanded
   */
  subject?: TermInterface | null
  /**
   * term id, type=course.en_subject
   */
  enSubjectId?: number | null
  /**
   * expanded
   */
  enSubject?: TermInterface | null
  /**
   * term id, type=course.attribute
   */
  attributeId?: number | null
  /**
   * expanded
   */
  attribute?: TermInterface | null
  /**
   * term id, type=system.semester_category
   */
  semesterCategoryId?: number | null
  description?: string
}

export class LessonClass implements LessonClassInterface {
  /**
   * 关联行政班 expanded
   */
  adminClasses?: AdminClass[]
  /**
   * 课程id
   */
  courseId?: number | null
  /**
   * 课程班名称
   */
  className: string
  /**
   * 课程班英文名称（未设置时为空字符串）
   */
  classEname: string
  /**
   * 容量
   */
  capacity?: number | null
  /**
   * 教师 expanded
   */
  teachers?: ClassSelection[]
  /**
   * 任课教师课时 expanded
   */
  teacherHoursInfo?: TeacherHour[]
  /**
   * 代课教师课时 expanded
   */
  substituteTeacherHoursInfo?: TeacherHour[]
  /**
   * 学生 expanded
   */
  students?: ClassSelection[]
  /**
   * 教师基础信息 expanded
   */
  teachersInfo?: SecuredReflection[]
  /**
   * 学生基础信息 expanded
   */
  studentsInfo?: SecuredReflection[]
  /**
   * 课表 ID
   */
  timetableId: number
  /**
   * 课表 expand
   */
  timetable?: Timetable | null
  classWeekCustom?: boolean
  /**
   * 上课周时间段
   */
  classWeeks?: ClassWeek[] | null
  /**
   * 上课方式
   */
  weekType?: ClassWeekTypeEnum
  /**
   * 空间id
   */
  placeIds?: number[] | null
  /**
   * 空间 expand
   */
  places?: Place[]
  /**
   * 上课时间 expanded
   */
  classTimes?: ClassScheduleWithId[]
  classHour?: number
  studentNums?: number
  /**
   * 学生组成（男/女）
   */
  studentGenderInfo?: string | null
  /**
   * 修改生效时间
   */
  workTime?: string | null
  /**
   * expanded
   */
  course?: Course | null
  /**
   * term id, type=class.tag
   */
  tagId?: number | null
  /**
   * expanded
   */
  tag?: Term | null
  photo?: string | null
  /**
   * 课程班类型
   */
  classType: ClassTypeEnum
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 学期 ID
   */
  semesterId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  csin: string
  name: string
  ename?: string | null
  /**
   * 学分
   */
  credit?: number | null
  /**
   * term id, type=system.school_structure
   */
  gradeIds?: number[]
  /**
   * expanded
   */
  grades?: Term[]
  /**
   * term id, type=course.domain
   */
  domainId?: number | null
  /**
   * expanded
   */
  domain?: Term | null
  /**
   * term id, type=course.subject
   */
  subjectId?: number | null
  /**
   * expanded
   */
  subject?: Term | null
  /**
   * term id, type=course.en_subject
   */
  enSubjectId?: number | null
  /**
   * expanded
   */
  enSubject?: Term | null
  /**
   * term id, type=course.attribute
   */
  attributeId?: number | null
  /**
   * expanded
   */
  attribute?: Term | null
  /**
   * term id, type=system.semester_category
   */
  semesterCategoryId?: number | null
  description?: string

  constructor(props: LessonClassInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      adminClasses: _adminClasses,
      courseId: _courseId,
      className: _className,
      classEname: _classEname,
      capacity: _capacity,
      teachers: _teachers,
      teacherHoursInfo: _teacherHoursInfo,
      substituteTeacherHoursInfo: _substituteTeacherHoursInfo,
      students: _students,
      teachersInfo: _teachersInfo,
      studentsInfo: _studentsInfo,
      timetableId: _timetableId,
      timetable: _timetable,
      classWeekCustom: _classWeekCustom,
      classWeeks: _classWeeks,
      weekType: _weekType,
      placeIds: _placeIds,
      places: _places,
      classTimes: _classTimes,
      classHour: _classHour,
      studentNums: _studentNums,
      studentGenderInfo: _studentGenderInfo,
      workTime: _workTime,
      course: _course,
      tagId: _tagId,
      tag: _tag,
      photo: _photo,
      classType: _classType,
      id: _id,
      schoolId: _schoolId,
      semesterId: _semesterId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      csin: _csin,
      name: _name,
      ename: _ename,
      credit: _credit,
      gradeIds: _gradeIds,
      grades: _grades,
      domainId: _domainId,
      domain: _domain,
      subjectId: _subjectId,
      subject: _subject,
      enSubjectId: _enSubjectId,
      enSubject: _enSubject,
      attributeId: _attributeId,
      attribute: _attribute,
      semesterCategoryId: _semesterCategoryId,
      description: _description,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.adminClasses = _adminClasses
      ? _adminClasses.map(i => new AdminClass(i))
      : _adminClasses
    this.courseId = _courseId
    this.className = _className
    this.classEname = _classEname
    this.capacity = _capacity
    this.teachers = _teachers
      ? _teachers.map(i => new ClassSelection(i))
      : _teachers
    this.teacherHoursInfo = _teacherHoursInfo
      ? _teacherHoursInfo.map(i => new TeacherHour(i))
      : _teacherHoursInfo
    this.substituteTeacherHoursInfo = _substituteTeacherHoursInfo
      ? _substituteTeacherHoursInfo.map(i => new TeacherHour(i))
      : _substituteTeacherHoursInfo
    this.students = _students
      ? _students.map(i => new ClassSelection(i))
      : _students
    this.teachersInfo = _teachersInfo
      ? _teachersInfo.map(i => new SecuredReflection(i))
      : _teachersInfo
    this.studentsInfo = _studentsInfo
      ? _studentsInfo.map(i => new SecuredReflection(i))
      : _studentsInfo
    this.timetableId = _timetableId
    this.timetable = _timetable ? new Timetable(_timetable) : _timetable
    this.classWeekCustom = _classWeekCustom
    this.classWeeks = _classWeeks
      ? _classWeeks.map(i => new ClassWeek(i))
      : _classWeeks
    this.weekType = _weekType
    this.placeIds = _placeIds
    this.places = _places ? _places.map(i => new Place(i)) : _places
    this.classTimes = _classTimes
      ? _classTimes.map(i => new ClassScheduleWithId(i))
      : _classTimes
    this.classHour = _classHour
    this.studentNums = _studentNums
    this.studentGenderInfo = _studentGenderInfo
    this.workTime = _workTime
    this.course = _course ? new Course(_course) : _course
    this.tagId = _tagId
    this.tag = _tag ? new Term(_tag) : _tag
    this.photo = _photo
    this.classType = _classType
    this.id = _id
    this.schoolId = _schoolId
    this.semesterId = _semesterId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
    this.csin = _csin
    this.name = _name
    this.ename = _ename
    this.credit = _credit
    this.gradeIds = _gradeIds
    this.grades = _grades ? _grades.map(i => new Term(i)) : _grades
    this.domainId = _domainId
    this.domain = _domain ? new Term(_domain) : _domain
    this.subjectId = _subjectId
    this.subject = _subject ? new Term(_subject) : _subject
    this.enSubjectId = _enSubjectId
    this.enSubject = _enSubject ? new Term(_enSubject) : _enSubject
    this.attributeId = _attributeId
    this.attribute = _attribute ? new Term(_attribute) : _attribute
    this.semesterCategoryId = _semesterCategoryId
    this.description = _description
  }

  static propKeys = [
    'adminClasses',
    'courseId',
    'className',
    'classEname',
    'capacity',
    'teachers',
    'teacherHoursInfo',
    'substituteTeacherHoursInfo',
    'students',
    'teachersInfo',
    'studentsInfo',
    'timetableId',
    'timetable',
    'classWeekCustom',
    'classWeeks',
    'weekType',
    'placeIds',
    'places',
    'classTimes',
    'classHour',
    'studentNums',
    'studentGenderInfo',
    'workTime',
    'course',
    'tagId',
    'tag',
    'photo',
    'classType',
    'id',
    'schoolId',
    'semesterId',
    'createdAt',
    'updatedAt',
    'csin',
    'name',
    'ename',
    'credit',
    'gradeIds',
    'grades',
    'domainId',
    'domain',
    'subjectId',
    'subject',
    'enSubjectId',
    'enSubject',
    'attributeId',
    'attribute',
    'semesterCategoryId',
    'description',
  ]
}

export interface SchoolManagerInterface {
  /**
   * 管理员账号
   */
  usin: string
  /**
   * 用户名字，默认为 admin
   */
  name: string
  uid: number
  /**
   * reflection id
   */
  id: number
  role: number
  schoolId: number
  status: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class SchoolManager implements SchoolManagerInterface {
  /**
   * 管理员账号
   */
  usin: string
  /**
   * 用户名字，默认为 admin
   */
  name: string
  uid: number
  /**
   * reflection id
   */
  id: number
  role: number
  schoolId: number
  status: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: SchoolManagerInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      usin: _usin,
      name: _name,
      uid: _uid,
      id: _id,
      role: _role,
      schoolId: _schoolId,
      status: _status,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.usin = _usin
    this.name = _name
    this.uid = _uid
    this.id = _id
    this.role = _role
    this.schoolId = _schoolId
    this.status = _status
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'usin',
    'name',
    'uid',
    'id',
    'role',
    'schoolId',
    'status',
    'createdAt',
    'updatedAt',
  ]
}

import { EvaluationTypeEnum } from '../evaluation-type-enum'
import { EvaluationWithIsFilled as Base } from '../evaluation-with-is-filled'

export type { EvaluationWithIsFilledInterface } from '../evaluation-with-is-filled'

/**
 * 评教
 */
export class EvaluationWithIsFilled extends Base {
  /**
   * 是否为匿名评教
   *
   * @returns 是否
   */
  get isAnonymous() {
    return this.type === EvaluationTypeEnum.Anonymous
  }

  /**
   * 是否为匿名评教且不需要登录
   *
   * @returns 是否
   */
  get isNoNeedLoginAnonymous() {
    return this.isAnonymous && !this.needLogin
  }

  /**
   * 是否为匿名评教且不需要登录
   *
   * @param evaluation - 评教
   * @param evaluation.type - 评教类型
   * @param evaluation.needLogin - 是否需要登录
   * @returns 是否
   */
  static isNoNeedLoginAnonymous(evaluation: {
    type?: EvaluationTypeEnum
    needLogin?: boolean
  }) {
    return (
      evaluation.type === EvaluationTypeEnum.Anonymous && !evaluation.needLogin
    )
  }
}

import { SchoolStatus } from './school-status'
import { SchoolType } from './school-type'
import { SchoolEnvEnum } from './school-env-enum'
import { SchoolCrmReq, SchoolCrmReqInterface } from './school-crm-req'
import { SchoolManager, SchoolManagerInterface } from './school-manager'
import { SchoolChalkVerEnum } from './school-chalk-ver-enum'
import { SchoolPlugin, SchoolPluginInterface } from './school-plugin'
import { PricingPlan, PricingPlanInterface } from './pricing-plan'
import {
  PricingPlanConstraints,
  PricingPlanConstraintsInterface,
} from './pricing-plan-constraints'

export interface GroupSchoolInterface {
  parent?: object | null
  /**
   * 学校英文标志，同时用作子域名
   */
  identity: string
  /**
   * 学校名称
   */
  name: string
  /**
   * 学校英文名称
   */
  ename: string
  /**
   * 学校所在省份
   */
  province: string
  /**
   * 学校所在城市
   */
  city: string
  /**
   * 学校名称拼音
   */
  pinyin: string
  /**
   * 该学校系统网址
   */
  url: string
  /**
   * 学校所在地区（新 apollo 后改为省份）
   */
  district: string
  /**
   * 学校 logo 地址
   */
  avatarUrl?: string | null
  /**
   * 学校状态
   */
  status: SchoolStatus
  /**
   * 学校类型
   */
  type: SchoolType
  /**
   * 环境
   */
  env: SchoolEnvEnum
  /**
   * 归属的上级学校 id ，独立学校/集团校该值应为 0
   */
  parentId?: number
  /**
   * 是否隐藏，隐藏的学校不出现在学校列表
   */
  hidden: number
  /**
   * 学校简介
   */
  description: string
  /**
   * 学校 CRM 信息，expanded
   */
  crm?: SchoolCrmReqInterface | null
  /**
   * 学校超管 ID
   */
  managedBy: number
  /**
   * 学校超管
   */
  manager?: SchoolManagerInterface | null
  /**
   * 学校使用的 chalk 版本，默认为 2.0
   */
  chalkVer: SchoolChalkVerEnum
  /**
   * 学校插件
   */
  schoolPlugins?: SchoolPluginInterface[]
  /**
   * 内建学校插件，模块开启关闭
   */
  builtinPlugins?: string[]
  /**
   * 插件商店插件的开关
   */
  systemPlugins?: string[]
  /**
   * 增购模块 expanded
   */
  extraPlugins?: string[]
  /**
   * 价格版本 ID
   */
  pricingPlanId: number
  /**
   * 价格版本 expanded
   */
  pricingPlan?: PricingPlanInterface
  /**
   * 自定义模块限制 expanded
   */
  customConstraints?: PricingPlanConstraintsInterface
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class GroupSchool implements GroupSchoolInterface {
  parent?: object | null
  /**
   * 学校英文标志，同时用作子域名
   */
  identity: string
  /**
   * 学校名称
   */
  name: string
  /**
   * 学校英文名称
   */
  ename: string
  /**
   * 学校所在省份
   */
  province: string
  /**
   * 学校所在城市
   */
  city: string
  /**
   * 学校名称拼音
   */
  pinyin: string
  /**
   * 该学校系统网址
   */
  url: string
  /**
   * 学校所在地区（新 apollo 后改为省份）
   */
  district: string
  /**
   * 学校 logo 地址
   */
  avatarUrl?: string | null
  /**
   * 学校状态
   */
  status: SchoolStatus
  /**
   * 学校类型
   */
  type: SchoolType
  /**
   * 环境
   */
  env: SchoolEnvEnum
  /**
   * 归属的上级学校 id ，独立学校/集团校该值应为 0
   */
  parentId?: number
  /**
   * 是否隐藏，隐藏的学校不出现在学校列表
   */
  hidden: number
  /**
   * 学校简介
   */
  description: string
  /**
   * 学校 CRM 信息，expanded
   */
  crm?: SchoolCrmReq | null
  /**
   * 学校超管 ID
   */
  managedBy: number
  /**
   * 学校超管
   */
  manager?: SchoolManager | null
  /**
   * 学校使用的 chalk 版本，默认为 2.0
   */
  chalkVer: SchoolChalkVerEnum
  /**
   * 学校插件
   */
  schoolPlugins?: SchoolPlugin[]
  /**
   * 内建学校插件，模块开启关闭
   */
  builtinPlugins?: string[]
  /**
   * 插件商店插件的开关
   */
  systemPlugins?: string[]
  /**
   * 增购模块 expanded
   */
  extraPlugins?: string[]
  /**
   * 价格版本 ID
   */
  pricingPlanId: number
  /**
   * 价格版本 expanded
   */
  pricingPlan?: PricingPlan
  /**
   * 自定义模块限制 expanded
   */
  customConstraints?: PricingPlanConstraints
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: GroupSchoolInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      parent: _parent,
      identity: _identity,
      name: _name,
      ename: _ename,
      province: _province,
      city: _city,
      pinyin: _pinyin,
      url: _url,
      district: _district,
      avatarUrl: _avatarUrl,
      status: _status,
      type: _type,
      env: _env,
      parentId: _parentId,
      hidden: _hidden,
      description: _description,
      crm: _crm,
      managedBy: _managedBy,
      manager: _manager,
      chalkVer: _chalkVer,
      schoolPlugins: _schoolPlugins,
      builtinPlugins: _builtinPlugins,
      systemPlugins: _systemPlugins,
      extraPlugins: _extraPlugins,
      pricingPlanId: _pricingPlanId,
      pricingPlan: _pricingPlan,
      customConstraints: _customConstraints,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.parent = _parent
    this.identity = _identity
    this.name = _name
    this.ename = _ename
    this.province = _province
    this.city = _city
    this.pinyin = _pinyin
    this.url = _url
    this.district = _district
    this.avatarUrl = _avatarUrl
    this.status = _status
    this.type = _type
    this.env = _env
    this.parentId = _parentId
    this.hidden = _hidden
    this.description = _description
    this.crm = _crm ? new SchoolCrmReq(_crm) : _crm
    this.managedBy = _managedBy
    this.manager = _manager ? new SchoolManager(_manager) : _manager
    this.chalkVer = _chalkVer
    this.schoolPlugins = _schoolPlugins
      ? _schoolPlugins.map(i => new SchoolPlugin(i))
      : _schoolPlugins
    this.builtinPlugins = _builtinPlugins
    this.systemPlugins = _systemPlugins
    this.extraPlugins = _extraPlugins
    this.pricingPlanId = _pricingPlanId
    this.pricingPlan = _pricingPlan
      ? new PricingPlan(_pricingPlan)
      : _pricingPlan
    this.customConstraints = _customConstraints
      ? new PricingPlanConstraints(_customConstraints)
      : _customConstraints
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'parent',
    'identity',
    'name',
    'ename',
    'province',
    'city',
    'pinyin',
    'url',
    'district',
    'avatarUrl',
    'status',
    'type',
    'env',
    'parentId',
    'hidden',
    'description',
    'crm',
    'managedBy',
    'manager',
    'chalkVer',
    'schoolPlugins',
    'builtinPlugins',
    'systemPlugins',
    'extraPlugins',
    'pricingPlanId',
    'pricingPlan',
    'customConstraints',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

/**
 * @file 德育路由注册
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.MoralAssessment,
  getLabel: () => $t('德育评价'),
  labelStyle: calculateLabelStyle('#cde6e5', '#317572'),
  messages: [
    // 加减分增删
    'raw_score_addition_for_evaluated',

    'raw_score_addition_for_evaluated_to_mentor',

    // 标签增删
    'raw_score_tag_for_evaluated',

    'raw_score_tag_for_evaluated_to_mentor',

    // 个性化评价增删
    'raw_score_special_for_evaluated',

    'raw_score_special_for_evaluated_to_mentor',

    // 普通分数发布、修改
    'score_for_evaluated',

    'score_for_evaluated_to_mentor',

    // 评价结算
    'assessment_published_for_evaluated',

    // 评价撤销结算
    'assessment_revoked_for_evaluated',

    // 未评价提醒
    'assessment_incomplete_for_evaluator',
  ],
})

/**
 * @file 注册出入校的消息类型
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Visitor,
  getLabel: () => $t('访客'),
  labelStyle: calculateLabelStyle('#c4f0e6', '#4b8678'),
  messages: ['delayed_leave', 'upcoming', 'access_approved'],
})

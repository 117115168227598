/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { SSOProvider, SSOProviderInterface } from './sso-provider'
import type { GetSSOProvidersQuery } from './get-sso-providers-query'

const buildConfig = createConfigBuilder('chalk')

export const ssoApi$getSSOProviders = {
  config(
    getSSOProvidersQuery?: GetSSOProvidersQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/sso/providers',
      {},
      getSSOProvidersQuery,
    )
  },

  /**
   * @summary 获取已经支持单点登录的平台
   * @param [getSSOProvidersQuery]
   * @param [ssoQuery.schoolEnabled] 筛选学校已经启用的平台
   * @param [options]
   * @return AxiosResponsePromise<SSOProvider[]>
   */

  api(
    getSSOProvidersQuery?: GetSSOProvidersQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<SSOProvider[]> {
    return axios(ssoApi$getSSOProviders.config(getSSOProvidersQuery, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new SSOProvider(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = SSOProvider[]>(
    query?: GetSSOProvidersQuery,
    queryOptions?: QueryOptionsWithSelect<SSOProvider[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<SSOProvider[], TSelected>(
      ssoApi$getSSOProviders.api,
      'Sso.getSSOProviders',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
ssoApi$getSSOProviders.api._name_ = `Sso.getSSOProviders`

/**
 * @file register navigator
 */

import { registerMessageNavigator } from 'packages/features/messages/register'

registerMessageNavigator(
  'exam.schedule_result_for_examinee',
  ({ attributes, owner }) => {
    const examId = (attributes as any)?.examId
    const rid = (owner as any)?.rid

    if (!examId || !rid) return null
    return {
      path: `/exam-rooms/${examId}/examinees/${rid}`,
      pathInNewWindow: true,
    }
  },
)

registerMessageNavigator(
  'exam.schedule_result_for_examiner',
  ({ attributes }) => {
    const examId = (attributes as any)?.examId

    if (!examId) return null
    return {
      path: `/exam-rooms/${examId}/examiners-me`,
      pathInNewWindow: true,
    }
  },
)

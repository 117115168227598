import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'

registerDomainMessage({
  type: MessageDomainEnum.Task,
  getLabel: () => $t('任务'),
  icon: `plugin.${PluginNameEnum.ClassTasks}`,
  labelStyle: calculateLabelStyle('#ECF4FF', '#447AE6'),
  messages: [
    'approved',
    'approval_canceled',
    'reject_canceled',
    'deleted',
    'task_updated',
    'rejected',
    'rejected_canceled',
    'submitted',
    'discussion_replied',
    'assigned',
    'canceled',
    'mark_excellent',
    'assignment_liked',
    // 修改/发布测验任务成绩消息
    'publish_quiz_score',
    'modify_quiz_score',
    // 讨论任务中被点赞
    'discussion_liked',
    // 讨论任务中评论被删除
    'discussion_comment_archived',
    // 讨论任务中回复被删除
    'discussion_reply_archived',
    // 讨论任务中话题被删除
    'discussion_topic_archived',
    // 讨论任务中被回复
    'discussion_commented',
    // 一键提醒未提交
    'submission_notified',
  ],
})

import { Attachment, AttachmentInterface } from './attachment'
import { SubmissionStatusEnum } from './submission-status-enum'
import {
  TaskReviewedAttachment,
  TaskReviewedAttachmentInterface,
} from './task-reviewed-attachment'

export interface TaskSubmissionInterface {
  submitterId: number
  /**
   * 内容(纯文本)
   */
  contentText?: string
  /**
   * 如果任务被 openapi 创建/修改，则该值为请求应用的 id；2.0 迁移为 chalk2
   * 默认为 null
   */
  outerSource?: string | null
  /**
   * 内容
   */
  content: string
  /**
   * 附件
   */
  attachments?: AttachmentInterface[]
  /**
   * 状态
   */
  status?: SubmissionStatusEnum | null
  /**
   * 附件批阅
   *  - 教师创建附件批阅后才有数据
   */
  reviewedAttachments?: TaskReviewedAttachmentInterface[] | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class TaskSubmission implements TaskSubmissionInterface {
  submitterId: number
  /**
   * 内容(纯文本)
   */
  contentText?: string
  /**
   * 如果任务被 openapi 创建/修改，则该值为请求应用的 id；2.0 迁移为 chalk2
   * 默认为 null
   */
  outerSource?: string | null
  /**
   * 内容
   */
  content: string
  /**
   * 附件
   */
  attachments?: Attachment[]
  /**
   * 状态
   */
  status?: SubmissionStatusEnum | null
  /**
   * 附件批阅
   *  - 教师创建附件批阅后才有数据
   */
  reviewedAttachments?: TaskReviewedAttachment[] | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: TaskSubmissionInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      submitterId: _submitterId,
      contentText: _contentText,
      outerSource: _outerSource,
      content: _content,
      attachments: _attachments,
      status: _status,
      reviewedAttachments: _reviewedAttachments,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.submitterId = _submitterId
    this.contentText = _contentText
    this.outerSource = _outerSource
    this.content = _content
    this.attachments = _attachments
      ? _attachments.map(i => new Attachment(i))
      : _attachments
    this.status = _status
    this.reviewedAttachments = _reviewedAttachments
      ? _reviewedAttachments.map(i => new TaskReviewedAttachment(i))
      : _reviewedAttachments
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'submitterId',
    'contentText',
    'outerSource',
    'content',
    'attachments',
    'status',
    'reviewedAttachments',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

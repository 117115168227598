/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { GroupType, GroupTypeInterface } from './group-type'
import type { QueryGroupTypesQuery } from './query-group-types-query'

const buildConfig = createConfigBuilder('chalk')

export const customGroupApi$queryGroupTypes = {
  config(
    queryGroupTypesQuery?: QueryGroupTypesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/custom_group/group_types',
      {},
      queryGroupTypesQuery,
    )
  },

  /**
   * @summary Class QueryGroupTypesApi
   * @param [queryGroupTypesQuery]
   * @param [customGroupQuery.idIn]
   * @param [customGroupQuery.isCustom]
   * @param [customGroupQuery.machineName]
   * @param [customGroupQuery.expand] expand
   * - roles
   * @param [options]
   * @return AxiosResponsePromise<GroupType[]>
   */

  api<const TQuery extends QueryGroupTypesQuery>(
    queryGroupTypesQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<GroupType, TQuery['expand']>[]> {
    return axios(
      customGroupApi$queryGroupTypes.config(queryGroupTypesQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new GroupType(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryGroupTypesQuery,
    TSelected = Expand<GroupType, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<GroupType, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<GroupType, TQuery['expand']>[], TSelected>(
      customGroupApi$queryGroupTypes.api,
      'CustomGroup.queryGroupTypes',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
customGroupApi$queryGroupTypes.api._name_ = `CustomGroup.queryGroupTypes`

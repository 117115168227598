import { Term, TermInterface } from './extends/term'
import { Place, PlaceInterface } from './place'
import { ReflectionV3, ReflectionV3Interface } from './reflection-v-3'
import { Member, MemberInterface } from './member'
import { AdminClassStatusEnum } from './admin-class-status-enum'

export interface PersonalAdminClassInterface {
  /**
   * 班级名称
   */
  name: string
  /**
   * 届别termId
   */
  graduatesInId: number
  /**
   * 届别expand
   */
  graduatesIn?: TermInterface | null
  /**
   * 年级expand
   */
  grade?: TermInterface | null
  /**
   * 学部expand
   */
  gradeDepartment?: TermInterface | null
  /**
   * 教室
   */
  placeId?: number | null
  place?: PlaceInterface | null
  /**
   * 班主任
   */
  teacherIds?: number[]
  teachers?: ReflectionV3Interface[]
  /**
   * 学生 expanded
   */
  students?: MemberInterface[]
  /**
   * 状态
   */
  status: AdminClassStatusEnum
  /**
   * 学生数量
   */
  studentNums?: number
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
}

export class PersonalAdminClass implements PersonalAdminClassInterface {
  /**
   * 班级名称
   */
  name: string
  /**
   * 届别termId
   */
  graduatesInId: number
  /**
   * 届别expand
   */
  graduatesIn?: Term | null
  /**
   * 年级expand
   */
  grade?: Term | null
  /**
   * 学部expand
   */
  gradeDepartment?: Term | null
  /**
   * 教室
   */
  placeId?: number | null
  place?: Place | null
  /**
   * 班主任
   */
  teacherIds?: number[]
  teachers?: ReflectionV3[]
  /**
   * 学生 expanded
   */
  students?: Member[]
  /**
   * 状态
   */
  status: AdminClassStatusEnum
  /**
   * 学生数量
   */
  studentNums?: number
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: PersonalAdminClassInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      graduatesInId: _graduatesInId,
      graduatesIn: _graduatesIn,
      grade: _grade,
      gradeDepartment: _gradeDepartment,
      placeId: _placeId,
      place: _place,
      teacherIds: _teacherIds,
      teachers: _teachers,
      students: _students,
      status: _status,
      studentNums: _studentNums,
      id: _id,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.graduatesInId = _graduatesInId
    this.graduatesIn = _graduatesIn ? new Term(_graduatesIn) : _graduatesIn
    this.grade = _grade ? new Term(_grade) : _grade
    this.gradeDepartment = _gradeDepartment
      ? new Term(_gradeDepartment)
      : _gradeDepartment
    this.placeId = _placeId
    this.place = _place ? new Place(_place) : _place
    this.teacherIds = _teacherIds
    this.teachers = _teachers
      ? _teachers.map(i => new ReflectionV3(i))
      : _teachers
    this.students = _students ? _students.map(i => new Member(i)) : _students
    this.status = _status
    this.studentNums = _studentNums
    this.id = _id
    this.schoolId = _schoolId
  }

  static propKeys = [
    'name',
    'graduatesInId',
    'graduatesIn',
    'grade',
    'gradeDepartment',
    'placeId',
    'place',
    'teacherIds',
    'teachers',
    'students',
    'status',
    'studentNums',
    'id',
    'schoolId',
  ]
}

export enum AdminClassAccessPolicyEnum {
  Default = 'default',
  Owner = 'owner',
  PluginAdmin = 'plugin_admin',
  NotificationSender = 'notification_sender',
  Notification = 'notification',
  Teacher = 'teacher',
  ClassAdmin = 'class_admin',
  Event = 'event',
  Chat = 'chat',
}

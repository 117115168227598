/**
 * @file register messages
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Exam,
  getLabel: () => $t('考试'),
  labelStyle: calculateLabelStyle('#FFF1E3', '#C05400'),
  messages: [
    // 抄送人收到考试成绩报告
    'exam.stats_received',
    // 班主任收到考试成绩报告
    'exam.published_for_adminclass_teacher',
    // 学生收到考试成绩报告
    'exam.published_for_examinee',
    // 学生收到的单科成绩报告
    'exam.published_scoring_for_examinee',
    // 任课教师收到考试成绩报告
    'exam.published_for_teacher',
    // 导师收到考试成绩报告
    'exam.published_for_mentor',
  ].map(type => ({
    type,
    important: true,
  })),
})

registerDomainMessage({
  type: MessageDomainEnum.Exam,
  getLabel: () => $t('考勤'),
  labelStyle: calculateLabelStyle('#F7585B', '#FDEFEF'),
  messages: [
    // 学生收到补考信息
    'exam.update_reflection_status_for_guardian_miss',
  ].map(type => ({
    type,
    important: false,
  })),
})

registerDomainMessage({
  type: MessageDomainEnum.Exam,
  getLabel: () => $t('考勤'),
  labelStyle: calculateLabelStyle('#E3F8F3', '#00795C'),
  messages: [
    // 学生收到补考信息
    'exam.update_reflection_status_for_guardian_normal',
  ].map(type => ({
    type,
    important: false,
  })),
})

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Semester, SemesterInterface } from './semester'
import type { LoadSemesterQuery } from './load-semester-query'

const buildConfig = createConfigBuilder('chalk')

export const semesterApi$loadSemester = {
  config(
    id: number,
    loadSemesterQuery?: LoadSemesterQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/system/semesters/{id}',
      {
        id,
      },
      loadSemesterQuery,
    )
  },

  /**
   * @summary Class LoadSemesterApi
   * @param id
   * @param [loadSemesterQuery]
   * @param [semesterQuery.expand] 支持 expand:
   *
   * 1 . academic_year 学年
   * 2 . category 分类
   * 3 . is_current 是否当前学期
   * @param [options]
   * @return AxiosResponsePromise<Semester>
   */

  api<const TQuery extends LoadSemesterQuery>(
    id: number,
    loadSemesterQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Semester, TQuery['expand']>> {
    return axios(
      semesterApi$loadSemester.config(id, loadSemesterQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new Semester(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends LoadSemesterQuery,
    TSelected = Expand<Semester, TQuery['expand']>,
  >(
    {
      id,
      query,
    }: {
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<Semester, TQuery['expand']>,
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Semester, TQuery['expand']>, TSelected>(
      semesterApi$loadSemester.api,
      'Semester.loadSemester',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
semesterApi$loadSemester.api._name_ = `Semester.loadSemester`

import { Score, ScoreReq } from 'packages/sdks-next/vnas'

export enum PathToDetailEnum {
  /*
   * 从参与录入菜单跳转到详情页
   */
  TypeIn = 'type-in',
  /*
   * 从我的评价菜单跳转到详情页
   */
  AssessMe = 'assess-me',
  /*
   * 从全局搜索跳转到详情页
   */
  Search = 'search',
}

export type SavedScoreType = Score | ScoreReq
export type SavedScoresType = SavedScoreType[]

/**
 * 德育审批流 - 被评人配置定义
 */
export type MoralWorkflowEvaluatedContainer = {
  /**
   * 被评人类型
   * initiator: 发起人本人
   * custom: 发起人自选
   */
  type?: 'initiator' | 'custom'
}

/**
 * 德育审批流 - 被评人值定义
 */
export type MoralWorkflowEvaluatedValue = {
  rid: number
  name: string
  usin: string
}

/**
 * 德育审批流 - 标签配置定义
 */
export type MoralWorkflowTagsContainer = {
  tags: {
    assessmentId: number
    itemId: number
    tags: string[]
  }[]
}

/**
 * 德育审批流 - 标签值定义
 */
export type MoralWorkflowTagsValue = {
  assessmentName: string
  itemName: string
  itemId: number
  tag: string
  score?: string
}[]

/**
 * 德育审批流 - 业务字段名的定义
 */
export enum MoralWorkflowFieldNameEnum {
  // 被评人
  MoralEvaluated = 'moral_evaluated',
  // 德育标签
  MoralTags = 'moral_tags',
  // 说明
  MoralRemark = 'moral_remark',
  // 证明材料
  MoralAttachment = 'moral_attachment',
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Role, RoleInterface } from './role'
import type { JoinedRolesQuery } from './joined-roles-query'

const buildConfig = createConfigBuilder('chalk')

export const roleApi$joinedRoles = {
  config(
    joinedRolesQuery?: JoinedRolesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(options, 'get', '/me/roles', {}, joinedRolesQuery)
  },

  /**
   * @summary 查询我的角色列表
   * @param [joinedRolesQuery]
   * @param [roleQuery.asManager] 是否以管理员身份获取，默认是
   * @param [roleQuery.managedId]
   * @param [roleQuery.ids] 指定 role ids
   * @param [roleQuery.permission]
   * @param [roleQuery.labels.type] 类型，用于标志业务类型，比如: seiue.personal_task, seiue.personal_group, seiue.mentor_role
   * @param [roleQuery.labels.domain] 归属领域，如：role
   * @param [roleQuery.labels.admin] 是否为管理员
   * @param [roleQuery.labels.moduleAdmin] 是否为模块总管理员
   * @param [roleQuery.labels.taskId] 常用Id
   * @param [roleQuery.labels.talkId]
   * @param [roleQuery.labels.reviewId]
   * @param [roleQuery.labels.examId]
   * @param [roleQuery.expand]
   * @param [roleQuery.paginated] 是否分页，默认 1
   * @param [roleQuery.page] 显示的数据页，默认 1
   * @param [roleQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<Role[]>
   */

  api<const TQuery extends JoinedRolesQuery>(
    joinedRolesQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Role, TQuery['expand']>[]> {
    return axios(roleApi$joinedRoles.config(joinedRolesQuery, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Role(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends JoinedRolesQuery,
    TSelected = Expand<Role, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<Role, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Role, TQuery['expand']>[], TSelected>(
      roleApi$joinedRoles.api,
      'Role.joinedRoles',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
roleApi$joinedRoles.api._name_ = `Role.joinedRoles`

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { GroupTypeEnum } from './group-type-enum'
import {
  GroupRolePermission,
  GroupRolePermissionInterface,
} from './group-role-permission'

const buildConfig = createConfigBuilder('chalk')

export const groupApi$getPermissionsByGroupType = {
  config(
    groupType: GroupTypeEnum,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(options, 'get', '/group/{groupType}/permissions', {
      groupType,
    })
  },

  /**
   * @summary 根据群组类型获取权限
   * @param groupType 群组类型
   * @param [options]
   * @return AxiosResponsePromise<GroupRolePermission[]>
   */

  api(
    groupType: GroupTypeEnum,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<GroupRolePermission[]> {
    return axios(groupApi$getPermissionsByGroupType.config(groupType, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new GroupRolePermission(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = GroupRolePermission[]>(
    groupType: GroupTypeEnum,
    queryOptions?: QueryOptionsWithSelect<GroupRolePermission[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<GroupRolePermission[], TSelected>(
      groupApi$getPermissionsByGroupType.api,
      'Group.getPermissionsByGroupType',
      queryOptions,
      groupType,
    )
  },
}

// @ts-ignore
groupApi$getPermissionsByGroupType.api._name_ = `Group.getPermissionsByGroupType`

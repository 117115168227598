import {
  formatItemLockAllowedTime,
  isScoreLocked,
} from 'packages/features/grades/utils'
import { $t } from 'packages/locale'
import {
  Assessment,
  AssessmentStatusEnum,
  Item,
  ItemInterface,
} from 'packages/sdks-next/vnas'

/**
 * 获取已发布的评价的提示
 *
 * @param assessment - 评价
 * @returns string | undefined
 */
export const getPublishedAssessmentTip = (assessment: Assessment) => {
  // 评价是否已结算
  const isAssessmentPublished =
    assessment.status === AssessmentStatusEnum.Published

  if (isAssessmentPublished) {
    return $t('本次综合评价已结算')
  }

  return undefined
}

type ValidItemActionParam = {
  /**
   * 评价对象
   */
  assessment: Assessment
  /**
   * 评价项
   */
  item: Item | ItemInterface
}

/**
 * 获取不能保存/修改分数的原因
 *
 * @param params - ValidItemActionParam
 * @returns 原因
 */
export const getNotSaveScoresReason = (params: ValidItemActionParam) => {
  const { assessment, item } = params
  // 评价是否已结算
  const publishedAssessmentTip = getPublishedAssessmentTip(assessment)

  if (publishedAssessmentTip) {
    return publishedAssessmentTip
  }

  // 评价项录入锁定
  if (isScoreLocked(item)) {
    const allowedTime = formatItemLockAllowedTime(item)

    return allowedTime
      ? $t(
          '评价项已被锁定录入，录入开放时间为 {time}如需现在修改成绩，请先联系管理员解锁录入',
          {
            time: allowedTime,
          },
        )
      : $t('评价项已被锁定录入，如需现在修改成绩，请先联系管理员解锁录入')
  }

  return ''
}

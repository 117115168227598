export interface MessageAttributesInterface {
  /**
   * 提醒相关业务的 id
   * 具体的业务由 message.type 确定
   */
  id?: number
  /**
   * 约谈部分消息有此字段
   */
  talkReceiverId?: number
  /**
   * 讨论区部分有这些字段
   */
  discussionId?: number
  messageId?: number
  url?: string
  /**
   * 通知 ID
   */
  notificationId?: string
  /**
   * 通知消息 id
   */
  notificationMessageId?: string
  /**
   * 聚合消息的 id 列表
   */
  aggregatedIds?: string[]
}

export class MessageAttributes implements MessageAttributesInterface {
  /**
   * 提醒相关业务的 id
   * 具体的业务由 message.type 确定
   */
  id?: number
  /**
   * 约谈部分消息有此字段
   */
  talkReceiverId?: number
  /**
   * 讨论区部分有这些字段
   */
  discussionId?: number
  messageId?: number
  url?: string
  /**
   * 通知 ID
   */
  notificationId?: string
  /**
   * 通知消息 id
   */
  notificationMessageId?: string
  /**
   * 聚合消息的 id 列表
   */
  aggregatedIds?: string[]

  constructor(props: MessageAttributesInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      talkReceiverId: _talkReceiverId,
      discussionId: _discussionId,
      messageId: _messageId,
      url: _url,
      notificationId: _notificationId,
      notificationMessageId: _notificationMessageId,
      aggregatedIds: _aggregatedIds,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
    this.talkReceiverId = _talkReceiverId
    this.discussionId = _discussionId
    this.messageId = _messageId
    this.url = _url
    this.notificationId = _notificationId
    this.notificationMessageId = _notificationMessageId
    this.aggregatedIds = _aggregatedIds
  }

  static propKeys = [
    'id',
    'talkReceiverId',
    'discussionId',
    'messageId',
    'url',
    'notificationId',
    'notificationMessageId',
    'aggregatedIds',
  ]
}

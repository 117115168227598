// 注意： 这里只定义当前应用下的枚举

import { createEnumHelper } from '@seiue/util'

import { $t } from 'packages/locale'

export const APP_NAME = 'gz-academic-credit'
export const PLUGIN_ACADEMIC_CREDIT = 'gz-academic-credit'
/**
 * 页面名称枚举, 此处定义应用的前端页面使用到的页面名称
 *
 */
export enum PageName {
  // 赋分任务详情
  TaskDetail = 'TaskDetail',
  // 赋分任务详情 - 赋分任务详情列表 tab
  TaskDetailListTab = 'TaskDetailListTab',
  // 赋分任务详情 - 审核组列表 tab
  TaskDetailReviewersTab = 'taskReviewers',
  // 赋分任务详情 - 审核组表单
  TaskDetailReviewersForm = 'page_mejizkkk',
  // 赋分任务审核页面 - 审核员视角
  AssignmentTaskReview = 'AssignmentTaskReview',
  // 赋分任务审核页面 - 教师视角
  AssignmentTask = 'AssignmentTask',
  // 教师赋分总表
  SummaryTable = 'SummaryTable',
  // 题目表格名称
  QuestionTableName = 'page_ycpqqvwi',
  // 档案- 赋分申报总表
  ProfileSummaryTable = 'profileSummaryTable',
  // 赋分申报总表表格
  CreditPage = 'page_ycpqqvwi',
}

/**
 * 应用模型枚举
 */
export enum ModelName {
  // 教师任职记录
  TeacherWorkingRecords = 'teacher_working_records',
}

/**
 * 页面参数名称
 */
export enum PageParamName {
  TaskReviewersFormTaskId = 'page_paramjrupybpx',
}

export const codedPageNameToLabelHelper = createEnumHelper(() => ({
  [PageName.TaskDetailListTab]: $t('赋分详情'),
  [PageName.TaskDetailReviewersTab]: $t('审核组详情'),
}))

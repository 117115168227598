/**
 * @file menu utils
 */

import { AppLayout } from '@seiue/ui'
import {
  filter,
  isString,
  cloneDeep,
  isFunction,
  getReactNodeInnerText,
} from '@seiue/util'
import { Atom, atom, useAtom } from 'jotai'
import { loadable } from 'jotai/utils'
import { matchPath } from 'react-router-dom'

import { useHasCurrentPermissionFunction } from 'packages/features/roles'
import { PermissionQuery } from 'packages/features/roles/types'
import { findMatchRoute, routes } from 'packages/route'
import type { RouteNode } from 'packages/route/types'

import {
  MenuGroup,
  LayoutMenus,
  MenuType,
  MenuItemUnion,
  isExtraMenuGroup,
} from './types'

const loadableValue = loadable<MenuItemUnion[]>(atom(() => []))
type LoadableAtomType = typeof loadableValue

export type MenuProvider = Atom<MenuItemUnion[]> | LoadableAtomType

const menuProviders: MenuProvider[] = []

/**
 * 注册菜单提供者
 *
 * @param p - 菜单提供者
 */
export const addMenuProvider = (p: MenuProvider) => {
  menuProviders.push(p)
}

const rootAtom = atom(get => {
  const menus: MenuItemUnion[] = []

  menuProviders.forEach(p => {
    // @ts-expect-error loadable 的类型定义有问题
    const value = get(p) as any

    const tmp = value.state ? value.data || [] : value

    menus.push(...tmp)
  })

  return menus
})

const checkPermission = (
  fn: (query: PermissionQuery) => boolean,
  menu: MenuItemUnion,
) => {
  const { permission } = menu
  if (!permission) {
    return true
  }

  if (Array.isArray(permission)) {
    for (const item of permission) {
      if (fn({ permission: item })) {
        return true
      }
    }

    return false
  }

  if (isFunction(permission)) {
    return permission(fn)
  }

  console.warn('incorrect permission setting for menu:', menu)

  return true
}

const filterMenus = (
  permissionFunc: (query: PermissionQuery) => boolean,
  menus: MenuItemUnion[],
): MenuItemUnion[] => {
  const newMenus: MenuItemUnion[] = []

  menus.forEach(target => {
    const menu = cloneDeep(target)

    if (isExtraMenuGroup(menu)) {
      menu.subMenus = filterMenus(permissionFunc, menu.subMenus)

      if (
        (menu.subMenus.length || menu.lazy === true) &&
        checkPermission(permissionFunc, menu)
      ) {
        newMenus.push(menu)
      }
    } else {
      newMenus.push(menu)
    }
  })

  return newMenus.filter(tmp => checkPermission(permissionFunc, tmp))
}

const sortMenus = (menus: MenuItemUnion[]) => {
  menus.forEach(menu => {
    if (isExtraMenuGroup(menu)) {
      sortMenus(menu.subMenus)
    }
  })

  menus.sort((a, b) => {
    const sort1 = a.sort || 0
    const sort2 = b.sort || 0

    if (sort1 < sort2) {
      return -1
    }

    if (sort1 === sort2) {
      return 0
    }

    return 1
  })
}

/**
 * 获取所有菜单
 *
 * @returns 所有菜单
 */
export const useAllMenus = (): MenuItemUnion[] => {
  const [menuPatches] = useAtom(rootAtom)
  const allMenus: MenuItemUnion[] = []

  // 解决目前插件注册顺序的问题
  menuPatches.sort((a, b) => {
    const sort1 = a.sort || 0
    const sort2 = b.sort || 0

    if (sort1 < sort2) {
      return -1
    }

    if (sort1 === sort2) {
      return 0
    }

    return 1
  })

  const permissionFunc = useHasCurrentPermissionFunction()

  const addMenus = (prefixes: String[], menus: MenuItemUnion[]) => {
    menus.forEach(menu => {
      let nodeToAdd = allMenus

      prefixes.forEach(prefix => {
        const subMenu = nodeToAdd.find(m => m.name === prefix)
        if (!subMenu) {
          // noop
        } else if (isExtraMenuGroup(subMenu)) {
          nodeToAdd = subMenu.subMenus
        } else {
          throw new Error('Unable to add menu item to MenuSingleItem')
        }
      })

      nodeToAdd.push(cloneDeep(menu))
    })
  }

  menuPatches.forEach(menu => {
    addMenus(menu.prefix || [], [menu])
  })

  const filterredMenus = filterMenus(permissionFunc, allMenus)

  sortMenus(filterredMenus)

  return filterredMenus
}

/**
 * 从路由中提取菜单栏设置
 *
 * @param _routes - 路由声明
 * @param authRoute - 路由鉴权方法
 * @returns 菜单定义
 */
export const extractMenus = (
  _routes: RouteNode[],
  authRoute: (r: RouteNode) => boolean,
) => {
  const menus: LayoutMenus = { default: [], apps: [], admin: [], adminApps: [] }

  const extract = (route: RouteNode) => {
    if (!route.menu || !authRoute(route)) return

    const item = {
      route,
      path: route.path,
      label: route.getTitle(),
      icon: route.menu.icon,
    }

    const group = route.menu.group()

    if (
      isString(group) &&
      [
        MenuType.Default,
        MenuType.Apps,
        MenuType.Admin,
        MenuType.AdminApps,
      ].includes(group)
    ) {
      menus[group].push(item)
    } else {
      const [menuType, groupLabel] = group as [MenuType, string]

      // Apps 和 AdminApps 为单层结构，没有 subGroup
      if ([MenuType.Apps, MenuType.AdminApps].includes(menuType)) {
        menus[menuType].push(item)
        return
      }

      const subGroup = menus[menuType].find(g => g.label === groupLabel)

      if (subGroup && isExtraMenuGroup(subGroup)) subGroup.subMenus.push(item)
      else
        (menus[menuType] as MenuItemUnion[]).push({
          label: groupLabel,
          subMenus: [item],
          icon: item.icon,
        })
    }

    if (route.subRoutes) route.subRoutes.forEach(extract)
  }

  _routes.forEach(extract)
  return menus
}

/**
 * 依据路由匹配菜单项
 *
 * @param path - 路由
 * @param menus - 菜单集
 * @param exact - 是否精确匹配
 * @returns - 菜单项
 */
export const matchMenu = (
  path: string,
  menus: MenuItemUnion[],
  exact = true,
): MenuItemUnion | undefined => {
  /*
   * 考虑到动态路由，menu 中的 path 可能是子路由的情况，
   * 这里需要拿到路由的 path，再和和菜单的 path 模糊匹配
   */
  const route = findMatchRoute(path, routes)

  const findTargetMenu: (
    _menus: MenuItemUnion[],
  ) => MenuItemUnion | undefined = (_menus: MenuItemUnion[]) => {
    for (const menu of _menus) {
      let isMatched = false

      if ('path' in menu && menu.path) {
        isMatched = exact
          ? menu.path === path
          : !!matchPath(menu.path, {
              path: route?.path,
              exact: true,
              strict: false,
            })
      }

      if (isMatched) {
        return menu
      }

      if (isExtraMenuGroup(menu)) {
        const flag = findTargetMenu(menu.subMenus)
        if (flag) return flag
      }
    }

    return undefined
  }

  return findTargetMenu(menus)
}

/**
 * 拓展菜单
 *
 * @param layoutMenus - 主菜单
 * @param extraMenus - 扩展菜单
 * @returns  - 扩展后的菜单
 */
export function mergeExtraMenus(
  layoutMenus: LayoutMenus,
  extraMenus: MenuItemUnion[],
): LayoutMenus {
  return {
    [MenuType.Default]: [
      ...layoutMenus[MenuType.Default],
      ...filter(extraMenus, { type: MenuType.Default }),
    ],
    [MenuType.Apps]: [
      ...layoutMenus[MenuType.Apps],
      ...filter(extraMenus, { type: MenuType.Apps }),
    ],
    [MenuType.Admin]: [
      ...layoutMenus[MenuType.Admin],
      ...filter(extraMenus, { type: MenuType.Admin }),
    ],
    [MenuType.AdminApps]: [
      ...layoutMenus[MenuType.AdminApps],
      ...filter(extraMenus, { type: MenuType.AdminApps }),
    ],
  }
}

/**
 * 根据 group 信息，整合 menu 中重复的 group 的 subMenus
 *
 * @param layoutMenus - 菜单
 * @returns 整合后的菜单
 */
export const mergeGroupSubMenus = (layoutMenus: LayoutMenus): LayoutMenus =>
  Object.entries(layoutMenus).reduce(
    (result, [key, menus]) => {
      const menuMap: { [key: string]: MenuItemUnion } = {}

      menus.forEach((menu, index) => {
        if (isExtraMenuGroup(menu)) {
          const labelKey = AppLayout.getMenuKey(menu as any)
          if (!menuMap[labelKey]) {
            menuMap[labelKey] = menu
          } else {
            const targetMenu = menuMap[labelKey] as MenuGroup

            menuMap[labelKey] = {
              ...targetMenu,
              ...menu,
              subMenus: [...targetMenu.subMenus, ...menu.subMenus],
            }
          }
        } else {
          menuMap[index] = menu
        }
      })

      return {
        ...result,
        [key]: Object.values(menuMap),
      }
    },
    {
      [MenuType.Default]: [],
      [MenuType.Apps]: [],
      [MenuType.Admin]: [],
      [MenuType.AdminApps]: [],
    } as LayoutMenus,
  )

/**
 * 获取菜单的文字标签
 *
 * @param menu - 菜单
 * @returns 文字标签
 */
export const getMenuLabel = (
  menu: Pick<MenuItemUnion, 'label' | 'labelText'>,
) => {
  return menu.labelText || getReactNodeInnerText(menu.label)
}

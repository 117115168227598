/**
 * @file 注册宿舍消息
 */

import { registerMessageNavigator } from 'packages/features/messages/utils'

import { DormDailyNoticeProps } from '../types'

import { MessageTypes } from './message-type'

registerMessageNavigator([MessageTypes.InputAttendance], message => {
  const data = message.attributes as DormDailyNoticeProps

  return {
    modalName: 'DormAttendanceInputAttendanceModal',
    options: {
      dormIdIn: data.bizIdIn,
      startAt: data.attendanceTimeStartAt,
      endAt: data.attendanceTimeEndAt,
      timeRangeRef: data.attendanceTimeRangeRef,
      displayTitle: data.name,
      displayStartTime: data.startTime,
      displayEndTime: data.endTime,
    },
  }
})

/**
 * @file schedule types
 */

import { Moment } from '@seiue/moment'

import { CalendarEvent as OriginCalendarEvent } from 'packages/sdks-next/chalk'
import { Lesson as OriginLesson } from 'packages/sdks-next/scms'

/**
 * 定义日程卡片的类型（与后端约定，无法在 sdk 里给出）
 */
export enum CalendarEventType {
  // 周日程下，课程数量大于 2 时显示折叠卡片
  LessonFold = 'lessonFold',
  /**
   * 课程
   */
  Lesson = 'lesson',
  /**
   * 课程（用于日程小组件的预览）
   */
  LessonForWidgetPreview = 'lessonForWidgetPreview',
  Task = 'task',
  Talk = 'talk',
  Owner = 'owner',
  /**
   * 调代课相关，前端自定的类型，后端数据无此类型
   */
  ClassAdjustment = 'classAdjustment',
  ClassAdjustmentTeacherReplace = 'classAdjustmentTeacherReplace', // 代课教师日程卡片
  ClassAdjustmentLessonFold = 'classAdjustmentLessonFold', // 周日程下，课程数量大于 2 时显示折叠卡片
  ClassAdjustmentIdle = 'classAdjustmentIdle',
  /**
   * 场地预约
   */
  Venue = 'venue',
  /**
   * 自定义群组
   */
  CustomGroup = 'group',
  /**
   * 活动
   */
  Event = 'event',
  /**
   * 约谈/咨询
   */
  Chat = 'chat',
  /**
   * 宿舍
   */
  Dorm = 'dorm',
  /**
   * 考试
   */
  Exam = 'exam',
  /**
   * 直播
   */
  Live = 'live',
  /**
   * 空闲卡片
   */
  Free = 'free',
  /**
   * 听评课
   */
  ClassReview = 'class_review',
  /**
   * 可供选择用于创建听评课的课节
   */
  LessonForClassReview = 'lesson_for_class_review',
}

export type CalendarView = 'week' | 'day' | string

export interface CardColor {
  deepText: string // 深色文字颜色，用于未到来事件
  deepBackground: string // 深色背景颜色，用于进行中事件
  lightBackground: string // 浅色背景颜色，用于未到来事件
  lightBackgroundMask?: string // 浅色背景颜色蒙版颜色，用于特殊的未到来事件
}

// 日程卡片右侧工作区
interface EventAction {
  getLabel: () => string
  show?: (source: any) => boolean // 是否显示，如课程的录入考勤和录入成绩显示需要判断一些条件
  callback: (source: any) => void
}

export interface WeekdayType {
  label: string
  value: Moment
  content: string
}

export type CalendarLessonType = OriginLesson & {
  style?: { [key: string]: any }
}

/**
 * 基础事件字段
 */
export type CalendarEventProps = {
  title: string
  type: string
  start: string
  end: string
  // 卡片右侧的工作区，如：课程卡片的录入成绩
  actions?: EventAction[]
  // 从后端获取的数据源
  source: OriginCalendarEvent
  style?: any
  // 是否隐藏卡片右侧的工作区
  hideActions?: boolean
}

export type WeekCalendarProps = {
  [key: string]: CalendarEventProps[] | undefined
  all?: CalendarEventProps[]
}

export type CustomEventRenderProps<T extends OriginCalendarEvent> =
  CardColor & {
    // 从后端获取的数据源
    source: T
    // 视图模式
    view: CalendarView
    // 计算出的坐标
    style?: any
    // 是否隐藏操作按钮
    hideActions?: boolean
    // 是否显示在一行
    oneLine?: boolean
    // 是否是日程所有者
    isOwner?: boolean
    // 上层组件的 reload，适用于非 modalRoute 关闭的时候刷新数据，如删除弹框
    reload?: Function
    // 近况的日程是只读的
    readonly?: boolean
    // 日程对应的 rid
    rid: number
    // 渲染的是否是折叠后的子项， 默认 false
    isFoldItem?: boolean
    // 当前事件卡片宽度
    cardWidth?: number
  }

/**
 * 携带有原始 index 的 event props，计算坐标的时候用
 */
export type CalendarEventWithIndex = OriginCalendarEvent & {
  // 原始的索引
  index: number
  // 持续时间
  duration: number
  // 交叉的日程的个数
  crossCount: number
  // 计算坐标 left 用
  leftIndex: number
  // 包含的日程（持续时间小于自己的日程集，计算坐标用）
  childEvents?: CalendarEventWithIndex[]
}

import { schema } from '@seiue/normalizr'
import { CalendarEvent } from './extends/calendar-event'

export const calendarEventSchema = new schema.Entity<CalendarEvent>(
  'chalk_calendar_event',
  {},
  {
    idAttribute: event =>
      `${event.type}_${event.custom.id || event.custom.bizId}`,
  },
)

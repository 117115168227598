/**
 * @file 注册审批流消息
 */

import { registerMessageNavigator } from 'packages/features/messages/register'
import { WorkflowExtensionTypeEnum } from 'packages/features/workflows/types'
import { getWorkflowBizType } from 'packages/features/workflows/utils/data'
import { FormMode } from 'packages/no-code/basic/types/page'
import { getPath } from 'packages/route'

import { WorkflowMessageAttributes, WorkflowMessageType } from './message-type'

registerMessageNavigator(
  [
    WorkflowMessageType.Reminder,
    WorkflowMessageType.Updated,
    WorkflowMessageType.StageUpdated,
    WorkflowMessageType.CommentRemind,
  ],
  message => {
    const attributes = message.attributes as WorkflowMessageAttributes

    const { workflowId, id: flowId } = attributes.flow || {}

    if (!flowId || !workflowId) return null

    if (
      attributes.workflow?.extensionType === WorkflowExtensionTypeEnum.NoCode
    ) {
      const { pluginName, pageName, formId } = attributes.relatedData || {}
      return {
        path: getPath('NoCode.SeperatedPage', {
          pluginName,
          pageName,
          id: formId,
          mode: FormMode.View,
        }),
      }
    }

    return {
      modalName: 'ShowFlowModal',
      options: {
        workflowBizType: getWorkflowBizType(attributes.workflow?.extensionType),
        flowId,
        commentId: attributes.commentId,
      },
    }
  },
)

import { moment } from '@seiue/moment'
import { groupBy, values } from '@seiue/util'
import { useMemo } from 'react'

import {
  AssignedTask,
  AssignmentStatusEnum,
  EnhancerEnum,
  SubmissionStatusEnum,
  TaskAssignment,
  TaskSubmission,
  taskApi$queryTaskAssignments,
} from 'packages/sdks-next/chalk'
import { AssessmentStatusEnum } from 'packages/sdks-next/vnas'
import { ServiceEnum } from 'packages/shared-stores/configs'

import { useSchoolConfigs } from '../school-configs'

import { useTaskItem } from './assessment-item'

/**
 * 查找任务的 assignments，并找出当前提交者的 assignment（如果是团队任务，一并找到 teamAssignments）
 *
 * @param param0 - 参数
 * @param param0.taskId - 任务 id
 * @param param0.assigneeId - 任务提交人
 * @param param0.isTeamwork - 是否是团队任务
 * @returns result
 */
export const useQueryTaskAssignments = ({
  taskId,
  assigneeId,
  isTeamwork,
}: {
  taskId: number
  assigneeId: number
  isTeamwork: boolean
}) => {
  const {
    data: allAssignments,
    loading,
    reload,
  } = taskApi$queryTaskAssignments.useApi({
    id: taskId,
    query: {
      tryExpand: [
        'team',
        'assignee',
        ['assignee', 'photo'],
        'submission',
        ['submission', 'reviewedAttachments'],
        ['submission', 'reviewedAttachments', 'reviewedData'],
        'review',
      ],
    },
  })

  const allTeamedAssignments = useMemo(
    () => (isTeamwork ? values(groupBy(allAssignments, 'teamId')) : []),
    [allAssignments, isTeamwork],
  )

  const currentAssignment = allAssignments?.find(
    asmt => asmt.assigneeId === assigneeId,
  )

  const currentTeamAssignments: TaskAssignment[] | undefined = isTeamwork
    ? allTeamedAssignments.find(teamed =>
        teamed.some(asmt => asmt.assigneeId === assigneeId),
      )
    : []

  return {
    allAssignments,
    allTeamedAssignments,
    currentAssignment,
    currentTeamAssignments,
    loading,
    reload,
  }
}

/**
 * 查询任务管理/设置中，是否允许已批阅的学生再次提交成果
 *
 * @returns allowResubmit, loading
 */
export const useAllowResubmit = () => {
  const { configs, loading } = useSchoolConfigs({
    service: ServiceEnum.Chalk,
    groupIn: ['task.settings'],
  })

  const allowResubmit = Boolean(configs?.allow_resubmit)

  return {
    allowResubmit,
    loading,
  }
}

/**
 * 根据 assignedTask 和 taskAssignment 计算任务的属性来决定页面的逻辑和展示
 *
 * @param param0 - 参数
 * @param param0.assignedTask - 任务
 * @param param0.taskAssignment - 当前提交者的 assignment
 * @returns predicates
 */
export const useTaskPredicates = ({
  assignedTask,
  taskAssignment,
}: {
  assignedTask: AssignedTask
  taskAssignment: TaskAssignment | undefined
}) => {
  const { allowResubmit, loading: loadingConfigs } = useAllowResubmit()

  const { submission } = taskAssignment || {}

  const isDraft = submission?.status === SubmissionStatusEnum.Draft

  const isSubmitted = !isDraft && !!submission

  const { data: assessmentItem, loading: loadingAssessmentItem } =
    useTaskItem(assignedTask)

  const isAssessmentPublished =
    assessmentItem?.assessment?.status &&
    [AssessmentStatusEnum.Published, AssessmentStatusEnum.Submitted].includes(
      assessmentItem.assessment.status,
    )

  const isApproved = assignedTask.status === AssignmentStatusEnum.Approved
  const isRejected = assignedTask.status === AssignmentStatusEnum.Rejected

  const lastSubmitted =
    submission?.status === SubmissionStatusEnum.Published ? submission : null

  const isLastSubmitOverdue =
    (!!assignedTask.expiredAt && !lastSubmitted) ||
    (lastSubmitted &&
      moment(assignedTask.expiredAt).isSameOrBefore(lastSubmitted.createdAt))

  const isEnded =
    !!assignedTask.expiredAt && moment().isSameOrAfter(assignedTask.expiredAt)

  // 已经逾期且不允许逾期提交，或者已驳回
  const isClosed =
    isEnded &&
    !assignedTask.allowOverdueSubmit &&
    assignedTask.status !== AssignmentStatusEnum.Rejected

  // 是否是草稿且未关闭
  const isSaved = isDraft && !isClosed

  // 是否可以进行提交
  const canSubmit =
    (!isClosed ||
      // 最后一次提交早于截止时间
      !isLastSubmitOverdue) &&
    !isAssessmentPublished &&
    !loadingAssessmentItem

  // 是否可以进行撤回
  const canRetrieve = isSubmitted && !isApproved && !isRejected && !isClosed

  const canSave = !isApproved && !isClosed

  const canResubmit =
    !isClosed && // 「未结束」或者「结束+允许晚交」
    (isRejected ||
      (allowResubmit &&
        isApproved &&
        assignedTask.labels?.type !== EnhancerEnum.MentorTask)) // 「已驳回」或者「批阅后允许再次提交」

  const canCancel = canResubmit || isSaved

  const predicates = {
    isSaved,
    isSubmitted,
    isApproved,
    isRejected,
    isClosed,
    canRetrieve,
    canSave,
    canCancel,
    canSubmit,
    canResubmit,
    allowResubmit,
  }

  const loading = loadingConfigs || loadingAssessmentItem

  return [predicates, loading] as const
}

/**
 * 判断任务提交内容是否有值
 *
 * @param submission - 任务提交
 * @returns isEmpty
 */
export const isEmptySubmission = (submission: TaskSubmission) => {
  return !!submission.content.length && !!submission.attachments?.length
}

import {
  ComputeRuleCondition,
  ComputeRuleConditionInterface,
} from './compute-rule-condition'

export interface GradeDataInterface {
  ruleCondition?: ComputeRuleConditionInterface | null
}

export class GradeData implements GradeDataInterface {
  ruleCondition?: ComputeRuleCondition | null

  constructor(props: GradeDataInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      ruleCondition: _ruleCondition,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.ruleCondition = _ruleCondition
      ? new ComputeRuleCondition(_ruleCondition)
      : _ruleCondition
  }

  static propKeys = ['ruleCondition']
}

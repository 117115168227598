export interface TimetableDetailLessonInterface {
  /**
   * 课节名称
   */
  name: string
  /**
   * 开始时间（可不传，实际在计算课节时会跳过）
   */
  startAt?: string
  /**
   * 结束时间（可不传，实际在计算课节时会跳过）
   */
  endAt?: string
}

export class TimetableDetailLesson implements TimetableDetailLessonInterface {
  /**
   * 课节名称
   */
  name: string
  /**
   * 开始时间（可不传，实际在计算课节时会跳过）
   */
  startAt?: string
  /**
   * 结束时间（可不传，实际在计算课节时会跳过）
   */
  endAt?: string

  constructor(props: TimetableDetailLessonInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      startAt: _startAt,
      endAt: _endAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.startAt = _startAt
    this.endAt = _endAt
  }

  static propKeys = ['name', 'startAt', 'endAt']
}

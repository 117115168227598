/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Grade, GradeInterface } from './extends/grade'
import type { QueryDormGradesQuery } from './query-dorm-grades-query'

const buildConfig = createConfigBuilder('vnas')

export const dormApi$queryDormGrades = {
  config(
    assessmentId: number,
    queryDormGradesQuery?: QueryDormGradesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/dorm/assessments/{assessmentId}/grades',
      {
        assessmentId,
      },
      queryDormGradesQuery,
    )
  },

  /**
   * @summary Class QueryGradesApi
   * @param assessmentId
   * @param [queryDormGradesQuery]
   * @param [dormQuery.paginated] 是否分页，默认 1
   * @param [dormQuery.page] 显示的数据页，默认 1
   * @param [dormQuery.perPage] 每页显示数量，默认 20
   * @param [dormQuery.idIn]
   * @param [dormQuery.semesterIdIn]
   * @param [dormQuery.statusIn]
   * @param [dormQuery.ownerIdIn]
   * @param [dormQuery.stageIdIn] 学段 IDs
   * @param [dormQuery.scoreEgt]
   * @param [dormQuery.scoreElt]
   * @param [dormQuery.gpaEgt]
   * @param [dormQuery.gpaElt]
   * @param [dormQuery.realScoreGt]
   * @param [dormQuery.realScoreLt]
   * @param [dormQuery.realScoreEgt]
   * @param [dormQuery.realScoreElt]
   * @param [dormQuery.suggestScoreEgt]
   * @param [dormQuery.suggestScoreElt]
   * @param [dormQuery.suggestScoreGt]
   * @param [dormQuery.suggestScoreLt]
   * @param [dormQuery.level]
   * @param [dormQuery.levelIn]
   * @param [dormQuery.rankEgt]
   * @param [dormQuery.rankElt]
   * @param [dormQuery.baseRankEgt]
   * @param [dormQuery.baseRankElt]
   * @param [dormQuery.courseRankEgt]
   * @param [dormQuery.courseRankElt]
   * @param [dormQuery.courseBaseRankEgt]
   * @param [dormQuery.courseBaseRankElt]
   * @param [dormQuery.statusIsNot]
   * @param [dormQuery.nameLike]
   * @param [dormQuery.nameIn]
   * @param [dormQuery.csinLike]
   * @param [dormQuery.csinIn]
   * @param [dormQuery.subjectIn]
   * @param [dormQuery.domainIn]
   * @param [dormQuery.creditEgt]
   * @param [dormQuery.creditElt]
   * @param [dormQuery.updatedAtEgt]
   * @param [dormQuery.updatedAtElt]
   * @param [options]
   * @return AxiosResponsePromise<Grade[]>
   */

  api(
    assessmentId: number,
    queryDormGradesQuery?: QueryDormGradesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Grade[]> {
    return axios(
      dormApi$queryDormGrades.config(
        assessmentId,
        queryDormGradesQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Grade(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = Grade[]>(
    {
      assessmentId,
      query,
    }: {
      assessmentId: number
      query?: QueryDormGradesQuery
    },
    queryOptions?: QueryOptionsWithSelect<Grade[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Grade[], TSelected>(
      dormApi$queryDormGrades.api,
      'Dorm.queryDormGrades',
      queryOptions,
      assessmentId,
      query,
    )
  },
}

// @ts-ignore
dormApi$queryDormGrades.api._name_ = `Dorm.queryDormGrades`

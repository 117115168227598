import { AxiosRequestExtraConfig } from '@seiue/axios'
import { find } from '@seiue/util'

import { TermTypeEnum } from 'packages/features/terms/types'
import { $t } from 'packages/locale'
import {
  QueryOptionsWithSelect,
  useQueryApi,
  UseQueryApiReturns,
} from 'packages/sdks-next'
import {
  QueryTermQuery,
  Term,
  termApi$queryTerm,
} from 'packages/sdks-next/chalk'

type QueryTermsParams = {
  type?: TermTypeEnum | string
  /**
   * 是否排除已归档的 term
   */
  excludeArchived?: boolean
}

type TermsByType = Record<string, Term[]>

/**
 * 查询 terms 的通用方法，返回的 term 会根据 weight, id 排序
 *
 * @param {QueryTermsParams} param0 - 查询参数
 * @param options - 用于控制是否禁用查询和返回值的处理
 * @returns UseQueryApiReturns
 */
export const useTerms = <TReturn = Term[]>(
  { type, excludeArchived = false }: QueryTermsParams,
  options?: Pick<
    QueryOptionsWithSelect<Term[], TReturn>,
    'disable' | 'select' | 'staleTime'
  >,
) =>
  // eslint-disable-next-line no-restricted-properties
  termApi$queryTerm.useApi(
    {
      type: type ?? '',
      query: {
        archivedAtIsEmpty: excludeArchived ? true : undefined,
        sort: 'weight,id',
      },
    },
    {
      disable: !type || options?.disable,
      select: options?.select,
      staleTime: options?.staleTime,
    },
  )

/**
 * 根据 term 返回 select 组件所需的 options
 *
 * @param {QueryTermsParams} param0 - 查询参数
 * @returns 未归档选项
 */
export const useTermOptions = ({
  type,
  excludeArchived = false,
}: QueryTermsParams) =>
  useTerms(
    { type, excludeArchived },
    {
      select: mapTermsToSelectOptions,
    },
  )

/**
 * 根据 term 返回 Table 筛选所需的 options，在 Searcher 中会隐藏 disabled 的选项
 *
 * @param type - 选项类型
 * @param options - 配置项
 * @param options.staleTime - 在指定的时间内只复用缓存, 不重发请求
 * @param options.disabled - 是否禁用请求
 * @returns 未归档选项
 */
export const useTermOptionsForTable = (
  type: TermTypeEnum,
  options?: {
    staleTime?: number
    disabled?: boolean
  },
) =>
  useTerms(
    { type },
    {
      staleTime: options?.staleTime,
      disable: options?.disabled,
      select: mapTermsToTableOptions,
    },
  )

/**
 * 根据 id 获取对应的 term
 *
 * @param type - 类型
 * @param id - id
 * @returns term
 */
export const useTermById = (type: TermTypeEnum, id?: number | null) => {
  const { data: allTerms } = useTerms({
    type,
  })

  if (!id) return undefined
  return find(allTerms, term => term.id === id)
}

/**
 * 根据 id 获取对应的 term
 *
 * @param type - 类型
 * @param id - id
 * @returns term
 */
export const useTermNameById = (type: TermTypeEnum, id?: number | null) => {
  const term = useTermById(type, id)

  if (!term) return undefined
  return term.name
}

/**
 * 查询多个类型的 terms hook
 *
 * @param param - 参数
 * @param param.types - term 类型数组
 * @param param.query - 查询参数
 * @param queryOptions - 查询配置
 * @returns 多个类型的 terms
 */
export const useTermsByTypes = <
  const TQuery extends QueryTermQuery,
  TSelected = TermsByType,
>(
  {
    types,
    query,
  }: {
    types: TermTypeEnum[]
    query?: TQuery
  },
  queryOptions?: QueryOptionsWithSelect<TermsByType, TSelected>,
): UseQueryApiReturns<TSelected> => {
  return useQueryApi(
    queryTermsByTypes,
    'TermsByTypes',
    queryOptions,
    types,
    query,
  )
}

/**
 * 查询多个类型的 terms 方法
 *
 * @param types - term 类型数组
 * @param query - 查询参数
 * @param query.excludeArchived - 是否排除已归档的 term
 * @param options - queryApi 的 option 参数
 * @returns 返回一个对象，该对象的键是类型，值是对应类型的 terms
 */
export const queryTermsByTypes = async (
  types: TermTypeEnum[],
  query?: Omit<QueryTermsParams, 'type'>,
  options?: AxiosRequestExtraConfig,
) => {
  const termsPromises = types.map(type =>
    termApi$queryTerm.api(
      type,
      {
        archivedAtIsEmpty: query?.excludeArchived ? true : undefined,
        sort: 'weight,id',
      },
      options,
    ),
  )

  const termsResults = await Promise.all(termsPromises)

  const termsByType = {} as TermsByType
  types.forEach((type, index) => {
    termsByType[type] = termsResults[index].data
  })

  return {
    data: termsByType,
  }
}

/**
 * 根据 terms 返回 Table 筛选所需的 options，在 Searcher 中会隐藏 disabled 的选项
 *
 * @param terms - terms
 * @returns term options
 */
export const mapTermsToTableOptions = (terms: Term[]) => {
  return terms.map(({ name, id, archivedAt }) => ({
    text: name,
    value: `${id}`,
    disabled: !!archivedAt,
  }))
}

export type SelectOption<T = number> = {
  label: string
  value: T
  disabled: boolean
}

export function mapTermsToSelectOptions(
  terms: Term[],
  useStringValue: true,
): SelectOption<string>[]

export function mapTermsToSelectOptions(
  terms: Term[],
  useStringValue?: false,
): SelectOption[]

/**
 * 根据 terms 返回 Select 组件所需的 options
 *
 * @param terms - terms
 * @param useStringValue - 是否使用 string 类型的 value
 * @returns term options
 */
export function mapTermsToSelectOptions(
  terms: Term[],
  useStringValue?: boolean,
) {
  return terms.map(({ name, id, archivedAt }) => ({
    label: archivedAt ? $t('{name}（已归档）', { name }) : name,
    value: useStringValue ? String(id) : id,
    disabled: !!archivedAt,
  }))
}

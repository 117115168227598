export interface CompleteScoresCountsInterface {
  /**
   * 实际已完成录入分数的学生数量
   */
  actual?: number
  /**
   * 应录入分数的学生总数
   */
  expected?: number
  /**
   * 暂不评价的学生数量
   */
  invalid?: number
}

export class CompleteScoresCounts implements CompleteScoresCountsInterface {
  /**
   * 实际已完成录入分数的学生数量
   */
  actual?: number
  /**
   * 应录入分数的学生总数
   */
  expected?: number
  /**
   * 暂不评价的学生数量
   */
  invalid?: number

  constructor(props: CompleteScoresCountsInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      actual: _actual,
      expected: _expected,
      invalid: _invalid,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.actual = _actual
    this.expected = _expected
    this.invalid = _invalid
  }

  static propKeys = ['actual', 'expected', 'invalid']
}

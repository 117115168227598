/**
 * @file register messages
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.ExamSchedule,
  getLabel: () => $t('排考场'),
  labelStyle: calculateLabelStyle('#FFF1E3', '#C05400'),
  messages: [
    {
      type: 'exam.schedule_result_for_examinee',
      important: true,
    },
    {
      type: 'exam.schedule_result_for_examiner',
      important: true,
    },
  ],
})

/**
 * @file message type
 */

export enum NoticeRoles {
  AdminClassTeacher = 'unsubmit_notice_to_admin_class_teacher',
  Mentor = 'unsubmit_notice_to_mentor',
  Student = 'unsubmit_notice_to_student',
  StudentResult = 'notify_student_result',
  GuardianReceivedResult = 'notify_guardian_result',
}

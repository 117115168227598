/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Message, MessageInterface } from './message'
import type { LoadQuery } from './load-query'

const buildConfig = createConfigBuilder('chalk')

export const receivedMessageApi$load = {
  config(
    id: string,
    loadQuery?: LoadQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/me/received-messages/{id}',
      {
        id,
      },
      loadQuery,
    )
  },

  /**
   * @summary Class LoadMessageApi
   * @param id
   * @param [loadQuery]
   * @param [receivedMessageQuery.expand]
   * @param [options]
   * @return AxiosResponsePromise<Message>
   */

  api<const TQuery extends LoadQuery>(
    id: string,
    loadQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Message, TQuery['expand']>> {
    return axios(receivedMessageApi$load.config(id, loadQuery, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new Message(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends LoadQuery,
    TSelected = Expand<Message, TQuery['expand']>,
  >(
    {
      id,
      query,
    }: {
      id: string
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<Message, TQuery['expand']>,
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Message, TQuery['expand']>, TSelected>(
      receivedMessageApi$load.api,
      'ReceivedMessage.load',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
receivedMessageApi$load.api._name_ = `ReceivedMessage.load`

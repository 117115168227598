import {
  ConfigItem as ConfigItemBase,
  ConfigItemInterface as ConfigItemInterfaceOriginal,
} from '../config-item'

export interface ConfigItemInterface extends ConfigItemInterfaceOriginal {
  /**
   * 配置项的值
   */
  value: any
}

/**
 *
 */
export class ConfigItem extends ConfigItemBase {
  /**
   * 配置项的值
   */
  value: any

  /**
   * constructor
   *
   * @param props - props
   */
  constructor(props: ConfigItemInterface) {
    super(props)
    this.value = props.value
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Assessment, AssessmentInterface } from './extends/assessment'
import type { QueryAssessmentsForRecorderQuery } from './query-assessments-for-recorder-query'

const buildConfig = createConfigBuilder('vnas')

export const teacherAssessmentApi$queryAssessmentsForRecorder = {
  config(
    queryAssessmentsForRecorderQuery?: QueryAssessmentsForRecorderQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/teacher_assessment/recorder/assessments',
      {},
      queryAssessmentsForRecorderQuery,
    )
  },

  /**
   * @summary 教师考核评价人和被评价人查询评价列表（被评价人通过自评的方式录入）
   * Recorder = Evaluator + Evaluated，因为要分页，无法复用 queryAssessmentsForEvaluated/Evaluator
   * @param [queryAssessmentsForRecorderQuery]
   * @param [teacherAssessmentQuery.expand]
   * @param [teacherAssessmentQuery.paginated] 是否分页，默认 1
   * @param [teacherAssessmentQuery.page] 显示的数据页，默认 1
   * @param [teacherAssessmentQuery.perPage] 每页显示数量，默认 20
   * @param [teacherAssessmentQuery.idIn]
   * @param [teacherAssessmentQuery.nameLike]
   * @param [teacherAssessmentQuery.nameIn]
   * @param [teacherAssessmentQuery.semesterId]
   * @param [teacherAssessmentQuery.scopeType]
   * @param [teacherAssessmentQuery.scopeIdIn]
   * @param [teacherAssessmentQuery.statusIn]
   * @param [teacherAssessmentQuery.planIdIn]
   * @param [teacherAssessmentQuery.planIdIsEmpty]
   * @param [teacherAssessmentQuery.fullScoreEgt]
   * @param [teacherAssessmentQuery.fullScoreElt]
   * @param [teacherAssessmentQuery.totalItemScoreEgt]
   * @param [teacherAssessmentQuery.totalItemScoreElt]
   * @param [teacherAssessmentQuery.itemNumEgt]
   * @param [teacherAssessmentQuery.itemNumElt]
   * @param [teacherAssessmentQuery.scopeNumEgt]
   * @param [teacherAssessmentQuery.scopeNumElt]
   * @param [teacherAssessmentQuery.scoreLockStatusIn]
   * @param [teacherAssessmentQuery.settingLockStatusIn]
   * @param [teacherAssessmentQuery.individuation]
   * @param [teacherAssessmentQuery.isArchived]
   * @param [teacherAssessmentQuery.evaluators.memberId]
   * @param [teacherAssessmentQuery.evaluators.memberIdIn]
   * @param [teacherAssessmentQuery.evaluators.memberType]
   * @param [teacherAssessmentQuery.evaluators.memberTypeIn]
   * @param [teacherAssessmentQuery.evaluators.assessmentId]
   * @param [teacherAssessmentQuery.evaluators.assessmentIdIn]
   * @param [teacherAssessmentQuery.evaluators.reflection]
   * @param [teacherAssessmentQuery.evaluateTarget] 评价对象，德育评价使用
   * @param [teacherAssessmentQuery.meIsEvaluator] 是否参与评价（适用于家长、老师、学生场景）
   * @param [teacherAssessmentQuery.lockGrade] 是否锁定总成绩修改
   * @param [teacherAssessmentQuery.isTemplate] 是否为模板
   * @param [teacherAssessmentQuery.assessmentStages.status]
   * @param [teacherAssessmentQuery.assessmentStages.statusIn]
   * @param [teacherAssessmentQuery.assessmentStages.stageId]
   * @param [teacherAssessmentQuery.assessmentStages.stageIdIn]
   * @param [teacherAssessmentQuery.computeRuleIsEmpty] 计算规则是否为空
   * @param [options]
   * @return AxiosResponsePromise<Assessment[]>
   */

  api<const TQuery extends QueryAssessmentsForRecorderQuery>(
    queryAssessmentsForRecorderQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Assessment, TQuery['expand']>[]> {
    return axios(
      teacherAssessmentApi$queryAssessmentsForRecorder.config(
        queryAssessmentsForRecorderQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Assessment(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryAssessmentsForRecorderQuery,
    TSelected = Expand<Assessment, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<Assessment, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Assessment, TQuery['expand']>[], TSelected>(
      teacherAssessmentApi$queryAssessmentsForRecorder.api,
      'TeacherAssessment.queryAssessmentsForRecorder',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
teacherAssessmentApi$queryAssessmentsForRecorder.api._name_ = `TeacherAssessment.queryAssessmentsForRecorder`

import {
  ProviderDefinition,
  ProviderDefinitionInterface,
} from './provider-definition'

export interface SSOProviderInterface {
  /**
   * egg: wework
   */
  name: string
  /**
   * egg: 企业微信
   */
  label: string
  /**
   * 描述
   */
  description?: string
  /**
   * 字段定义
   */
  definitions?: ProviderDefinitionInterface[]
  camelize?: boolean
  /**
   * 是否废弃，废弃的不允许再新增
   * 默认 false
   */
  deprecated?: boolean
  pcHomepage?: string | null
  mobileHomepage?: string | null
}

export class SSOProvider implements SSOProviderInterface {
  /**
   * egg: wework
   */
  name: string
  /**
   * egg: 企业微信
   */
  label: string
  /**
   * 描述
   */
  description?: string
  /**
   * 字段定义
   */
  definitions?: ProviderDefinition[]
  camelize?: boolean
  /**
   * 是否废弃，废弃的不允许再新增
   * 默认 false
   */
  deprecated?: boolean
  pcHomepage?: string | null
  mobileHomepage?: string | null

  constructor(props: SSOProviderInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      label: _label,
      description: _description,
      definitions: _definitions,
      camelize: _camelize,
      deprecated: _deprecated,
      pcHomepage: _pcHomepage,
      mobileHomepage: _mobileHomepage,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.label = _label
    this.description = _description
    this.definitions = _definitions
      ? _definitions.map(i => new ProviderDefinition(i))
      : _definitions
    this.camelize = _camelize
    this.deprecated = _deprecated
    this.pcHomepage = _pcHomepage
    this.mobileHomepage = _mobileHomepage
  }

  static propKeys = [
    'name',
    'label',
    'description',
    'definitions',
    'camelize',
    'deprecated',
    'pcHomepage',
    'mobileHomepage',
  ]
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { GroupTypeEnum } from './group-type-enum'
import { Group, GroupInterface } from './group'
import type { GetGroupByBizQuery } from './get-group-by-biz-query'

const buildConfig = createConfigBuilder('chalk')

export const groupApi$getGroupByBiz = {
  config(
    type: GroupTypeEnum,
    bizId: string,
    getGroupByBizQuery?: GetGroupByBizQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/group/{type}/groups/{bizId}',
      {
        type,
        bizId,
      },
      getGroupByBizQuery,
    )
  },

  /**
   * @summary 根据关联实体查询其关联的群组
   * @param type 群组类型（enhancer）
   * @param bizId 关联实体ID
   * @param [getGroupByBizQuery]
   * @param [groupQuery.ownerId] 档案子页面使用，传递个人档案所属人 ID
   * @param [groupQuery.status] 根据群组状态筛选
   * @param [groupQuery.fieldLevel]
   * @param [groupQuery.calLeaveStatusByRid] 计算指定用户在群组中的状态
   * @param [groupQuery.memberType] 成员类型
   * @param [groupQuery.memberId] 成员rid
   * @param [groupQuery.withTrashed] 包含软删除的group
   * @param [groupQuery.gmWithTrashed] 包含成员已经离开的群组
   * @param [groupQuery.gmOnlyTrashed] 仅成员已经离开的群组
   * @param [groupQuery.expand] expand
   * - members
   * - members.reflection : 成员基本信息
   * - members.reflection
   * - managers
   * - manageds
   * - related
   * - teams.members.reflection
   * - members.teams
   * - type_entity (仅用于通用群组)
   * @param [options]
   * @return AxiosResponsePromise<Group>
   */

  api<const TQuery extends GetGroupByBizQuery>(
    type: GroupTypeEnum,
    bizId: string,
    getGroupByBizQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Group, TQuery['expand']>> {
    return axios(
      groupApi$getGroupByBiz.config(type, bizId, getGroupByBizQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new Group(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends GetGroupByBizQuery,
    TSelected = Expand<Group, TQuery['expand']>,
  >(
    {
      type,
      bizId,
      query,
    }: {
      type: GroupTypeEnum
      bizId: string
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<Group, TQuery['expand']>,
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Group, TQuery['expand']>, TSelected>(
      groupApi$getGroupByBiz.api,
      'Group.getGroupByBiz',
      queryOptions,
      type,
      bizId,
      query,
    )
  },
}

// @ts-ignore
groupApi$getGroupByBiz.api._name_ = `Group.getGroupByBiz`

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Score, ScoreInterface } from './extends/score'
import type { GetItemScoresQuery } from './get-item-scores-query'

const buildConfig = createConfigBuilder('vnas')

export const defaultApi$getItemScores = {
  config(
    id: number,
    getItemScoresQuery?: GetItemScoresQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/common/items/{id}/scores',
      {
        id,
      },
      getItemScoresQuery,
    )
  },

  /**
   * @summary Class QueryItemScoresApi
   * 查询评价结构的分数
   * @param id 评价项 ID
   * @param [getItemScoresQuery]
   * @param [defaultQuery.type]
   * @param [defaultQuery.ownerIdIn]
   * @param [defaultQuery.scoreGt]
   * @param [defaultQuery.scoreLt]
   * @param [defaultQuery.scoreEgt]
   * @param [defaultQuery.scoreElt]
   * @param [defaultQuery.tag]
   * @param [defaultQuery.tagIn]
   * @param [defaultQuery.valid]
   * @param [defaultQuery.createdAtEgt] 分数创建时间大于等于
   * @param [defaultQuery.createdAtElt] 分数创建时间小于等于
   * @param [defaultQuery.expand] - owner 分数所有者
   * - evaluator 打分人
   * - item 评价项
   * - attendance_info 包含考勤课节、班级、考勤录入人信息
   * @param [defaultQuery.policy] -德育评价必传此参数
   * - 教务查看 admin
   * - 教师作为评价人查看 evaluator
   * @param [defaultQuery.teacherAssessmentAsRole] 教师考核 - 当前用户角色，operation_type=teacher_assessment 时需要，不传默认插件管理员
   * @param [defaultQuery.paginated] 是否分页，默认 1
   * @param [defaultQuery.page] 显示的数据页，默认 1
   * @param [defaultQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<Score[]>
   */

  api<const TQuery extends GetItemScoresQuery>(
    id: number,
    getItemScoresQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Score, TQuery['expand']>[]> {
    return axios(
      defaultApi$getItemScores.config(id, getItemScoresQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Score(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends GetItemScoresQuery,
    TSelected = Expand<Score, TQuery['expand']>[],
  >(
    {
      id,
      query,
    }: {
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<Score, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Score, TQuery['expand']>[], TSelected>(
      defaultApi$getItemScores.api,
      'Default.getItemScores',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
defaultApi$getItemScores.api._name_ = `Default.getItemScores`

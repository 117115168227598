export interface CourseMemberMetadataInterface {
  classIds?: number[]
}

export class CourseMemberMetadata implements CourseMemberMetadataInterface {
  classIds?: number[]

  constructor(props: CourseMemberMetadataInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      classIds: _classIds,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.classIds = _classIds
  }

  static propKeys = ['classIds']
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Group, GroupInterface } from './group'
import type { LoadGroupQuery } from './load-group-query'

const buildConfig = createConfigBuilder('chalk')

export const groupApi$loadGroup = {
  config(
    id: string,
    loadGroupQuery?: LoadGroupQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/group/groups/{id}',
      {
        id,
      },
      loadGroupQuery,
    )
  },

  /**
   * @summary Class LoadGroupApi
   * @param id 实体ID
   * @param [loadGroupQuery]
   * @param [groupQuery.ownerId] 档案子页面使用，传递个人档案所属人 ID
   * @param [groupQuery.calLeaveStatusByRid] 计算指定用户在群组中的状态
   * @param [groupQuery.memberType] 成员类型
   * @param [groupQuery.memberId] 成员rid
   * @param [groupQuery.withTrashed] 包含软删除的group
   * @param [groupQuery.gmWithTrashed] 包含成员已经离开的群组
   * @param [groupQuery.gmOnlyTrashed] 仅成员已经离开的群组
   * @param [groupQuery.expand] expand
   * - members
   * - members.reflection : 成员基本信息
   * - members.reflection
   * - managers
   * - manageds
   * - related
   * - teams.members.reflection
   * - members.teams
   * - type_entity (仅用于通用群组)
   * @param [options]
   * @return AxiosResponsePromise<Group>
   */

  api<const TQuery extends LoadGroupQuery>(
    id: string,
    loadGroupQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Group, TQuery['expand']>> {
    return axios(groupApi$loadGroup.config(id, loadGroupQuery, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new Group(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends LoadGroupQuery,
    TSelected = Expand<Group, TQuery['expand']>,
  >(
    {
      id,
      query,
    }: {
      id: string
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<Group, TQuery['expand']>,
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Group, TQuery['expand']>, TSelected>(
      groupApi$loadGroup.api,
      'Group.loadGroup',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
groupApi$loadGroup.api._name_ = `Group.loadGroup`

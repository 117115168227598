/**
 * @file 成绩动态相关 Utils
 */

import { createEnumHelper } from '@seiue/util'

import { $t } from 'packages/locale/LocaleProvider'
import { TrendEvent, TrendEventTypeEnum } from 'packages/sdks-next/vnas'

// 动态领域划分
export enum TrendDomainEnum {
  Published = 'published',
  Updated = 'updated',
  Removed = 'removed',
  Revoked = 'revoked',
}

/**
 * 获取动态所对应的领域
 *
 * @param eventType - 动态类型
 * @returns 领域
 */
export const getTrendDomain = (eventType?: TrendEventTypeEnum) => {
  switch (eventType) {
    case TrendEventTypeEnum.GradePublished:
    case TrendEventTypeEnum.ScorePublished:
    case TrendEventTypeEnum.AdditionScoreAdded:
    case TrendEventTypeEnum.ReviewPublished:
    case TrendEventTypeEnum.SpecialScoreAdded:
    case TrendEventTypeEnum.TagAdded:
    case TrendEventTypeEnum.AttendanceScoreAdded:
    case TrendEventTypeEnum.ResitGradePublished:
    case TrendEventTypeEnum.ResitScorePublished:
    case TrendEventTypeEnum.StageGradePublished:
      return TrendDomainEnum.Published
    case TrendEventTypeEnum.ScoreUpdated:
    case TrendEventTypeEnum.GradeUpdated:
    case TrendEventTypeEnum.ReviewUpdated:
    case TrendEventTypeEnum.AttendanceScoreUpdated:
    case TrendEventTypeEnum.ResitGradeUpdated:
    case TrendEventTypeEnum.ResitScoreUpdated:
    case TrendEventTypeEnum.StageGradeUpdated:
      return TrendDomainEnum.Updated
    case TrendEventTypeEnum.AdditionScoreRemoved:
    case TrendEventTypeEnum.TagRemoved:
    case TrendEventTypeEnum.ItemDeleted:
    case TrendEventTypeEnum.SpecialScoreRemoved:
    case TrendEventTypeEnum.AttendanceScoreRemoved:
      return TrendDomainEnum.Removed
    case TrendEventTypeEnum.ReviewRevoked:
    case TrendEventTypeEnum.GradeRevoked:
    case TrendEventTypeEnum.ScoreRevoked:
    case TrendEventTypeEnum.ResitGradeRevoked:
    case TrendEventTypeEnum.ResitScoreRevoked:
    case TrendEventTypeEnum.StageGradeRevoked:
      return TrendDomainEnum.Revoked
    default:
      return undefined
  }
}

/**
 * 动态领域标签 Helper
 *
 * @param type - 动态领域类型
 * @returns helper
 */
export const trendDomainLabelHelper = createEnumHelper(() => ({
  [TrendDomainEnum.Published]: $t('发布'),
  [TrendDomainEnum.Removed]: $t('删除'),
  [TrendDomainEnum.Updated]: $t('修改'),
  [TrendDomainEnum.Revoked]: $t('撤回'),
}))

/**
 * 获取动态领域标签
 *
 * @param domain - 动态领域类型
 * @returns 标签
 */
export const getTrendDomainLabel = (domain?: TrendDomainEnum) =>
  domain ? trendDomainLabelHelper.get(domain) : ''

/**
 * 获取动态的描述文本
 *
 * @param trend - 动态类型
 * @returns 文本
 */
export const getTrendEventTypeLabel = (trend: TrendEvent) => {
  const trendDomainEnum = getTrendDomain(trend.type)
  const domainLabel = getTrendDomainLabel(trendDomainEnum)
  let target = trend.item?.name
  let object = $t('成绩')

  switch (trend.type) {
    case TrendEventTypeEnum.GradeRevoked:
    case TrendEventTypeEnum.GradeUpdated:
    case TrendEventTypeEnum.GradePublished:
      target = trend.assessment?.name
      object = $t('总成绩')
      break
    case TrendEventTypeEnum.StageGradeRevoked:
    case TrendEventTypeEnum.StageGradeUpdated:
    case TrendEventTypeEnum.StageGradePublished:
      target = trend.assessment?.name
      object = $t('学段成绩')
      break
    case TrendEventTypeEnum.ItemDeleted:
      object = $t('评价项')
      break
    case TrendEventTypeEnum.AdditionScoreRemoved:
    case TrendEventTypeEnum.AdditionScoreAdded:
      object = $t('加减分标签')
      break
    case TrendEventTypeEnum.SpecialScoreRemoved:
    case TrendEventTypeEnum.SpecialScoreAdded:
      if (trend.newTags?.[0]?.score || trend.oldTags?.[0]?.score) {
        object = $t('个性化分数')
      } else {
        object = $t('个性化评语')
      }

      break
    case TrendEventTypeEnum.TagAdded:
    case TrendEventTypeEnum.TagRemoved:
      object = $t('标签')
      break
    case TrendEventTypeEnum.AttendanceScoreRemoved:
    case TrendEventTypeEnum.AttendanceScoreAdded:
    case TrendEventTypeEnum.AttendanceScoreUpdated:
      object = $t('考勤标签')
      break
    case TrendEventTypeEnum.ResitGradePublished:
    case TrendEventTypeEnum.ResitGradeRevoked:
    case TrendEventTypeEnum.ResitGradeUpdated:
      target = trend.assessment?.name
      object = $t('补考总成绩')
      break
    case TrendEventTypeEnum.ResitScorePublished:
    case TrendEventTypeEnum.ResitScoreRevoked:
    case TrendEventTypeEnum.ResitScoreUpdated:
      object = $t('补考成绩')
      break
    default:
  }

  return $t('{action}了 {target} 的{object}', {
    action: domainLabel,
    target,
    object,
  })
}

/**
 * 是否是总成绩相关的动态
 *
 * @param trendType - 动态类型
 * @returns 是否
 */
export const isGradeTrend = (trendType?: TrendEventTypeEnum) => {
  if (!trendType) return false
  return [
    TrendEventTypeEnum.GradeRevoked,
    TrendEventTypeEnum.GradeUpdated,
    TrendEventTypeEnum.GradePublished,
    TrendEventTypeEnum.ResitGradePublished,
    TrendEventTypeEnum.ResitGradeRevoked,
    TrendEventTypeEnum.ResitGradeUpdated,
    TrendEventTypeEnum.StageGradePublished,
    TrendEventTypeEnum.StageGradeUpdated,
    TrendEventTypeEnum.StageGradeRevoked,
  ].includes(trendType)
}

/**
 * 获取动态所对应的评价名称
 *
 * @param trend - 动态
 * @returns 评价项名称
 */
export const getTrendTargetName = (trend: TrendEvent) => {
  if (isGradeTrend(trend.type)) return ''

  return trend.assessment?.name
}

/**
 * 是否展示动态内容
 *
 * @param type - 动态类型
 * @returns 是否
 */
export const showTrendContent = (type?: TrendEventTypeEnum) => {
  if (!type) return false

  if (
    [
      TrendEventTypeEnum.AdditionScoreRemoved,
      TrendEventTypeEnum.TagRemoved,
      TrendEventTypeEnum.SpecialScoreRemoved,
    ].includes(type)
  )
    return true

  const domain = getTrendDomain(type)

  if (!domain) return false
  return [TrendDomainEnum.Published, TrendDomainEnum.Updated].includes(domain)
}

/**
 * 是否是加减分动态
 *
 * @param type - 动态类型
 * @returns 是否
 */
export const isAdditionTrend = (type?: TrendEventTypeEnum) => {
  if (!type) return false
  return [
    TrendEventTypeEnum.AdditionScoreAdded,
    TrendEventTypeEnum.AdditionScoreRemoved,
    // 考勤这里只有添加会显示标签
    TrendEventTypeEnum.AttendanceScoreAdded,
  ].includes(type)
}

/**
 * 是否是个性化动态
 *
 * @param type - 动态类型
 * @returns 是否
 */
export const isSpecialTrend = (type?: TrendEventTypeEnum) => {
  if (!type) return false

  return [
    TrendEventTypeEnum.SpecialScoreAdded,
    TrendEventTypeEnum.SpecialScoreRemoved,
  ].includes(type)
}

/**
 * 是否是分数型个性化动态
 *
 * @param trend - 动态
 * @returns 是否
 */
export const isSpecialScoreTrend = (trend: TrendEvent) => {
  if (isSpecialTrend(trend.type)) {
    return !!trend.newTags?.[0]?.score || !!trend.oldTags?.[0]?.score
  }

  return false
}

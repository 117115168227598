import { Dropdown, DropdownItem, Icon } from '@seiue/ui'
import React from 'react'

import {
  ModuleEnum,
  useHasEnabledParentPlugin,
} from 'packages/feature-utils/plugins'
import { RequirePluginEnabled } from 'packages/features/plugins/components'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { useCurrentReflection } from 'packages/features/sessions/utils/data'
import { useLocale } from 'packages/locale/hooks'
import { usefulLocaleEnumHelper } from 'packages/locale/utils'
import { LocaleTextLocaleEnum, RoleEnum } from 'packages/sdks-next/chalk'

import { useDispatch } from '@/store'

import { ControlBarIconWrapper } from '../styles'

interface LanguageProps {
  className?: string
}

/**
 * 多语言切换
 *
 * @param param0 - 参数
 * @param param0.className - Icon 容器类名
 * @returns 组件
 */
export const Language: React.FC<LanguageProps> = ({ className }) => {
  const i18nEnabled = useHasEnabledParentPlugin(ModuleEnum.I18n)

  const locale = useLocale()
  const dispatch = useDispatch()

  const currentReflection = useCurrentReflection()

  const isEn = locale === LocaleTextLocaleEnum.EnUS
  const isTeacher =
    currentReflection.role === RoleEnum.Teacher ||
    currentReflection.role === RoleEnum.Shadow

  const canReportTranslationIssue = isEn && isTeacher
  const reportTranslationIssueFlag = 'report-translation-issue'

  const localeOptions: DropdownItem[] = usefulLocaleEnumHelper.listOptions(
    'value',
    'label',
  )

  if (canReportTranslationIssue) {
    localeOptions[localeOptions.length - 1].divider = true
  }

  return !locale && i18nEnabled ? null : (
    <RequirePluginEnabled name={SchoolModulesEnum.I18n}>
      <Dropdown
        value={locale}
        closeOnChanged={false}
        trigger={'hover'}
        placement="bottom"
        items={[
          ...localeOptions,
          ...(canReportTranslationIssue
            ? [
                {
                  label: 'Report translation issue',
                  value: reportTranslationIssueFlag,
                },
              ]
            : []),
        ]}
        onChange={async ({ value }) => {
          if (value === reportTranslationIssueFlag) {
            window.open(
              'https://bjseiue.feishu.cn/share/base/form/shrcn00vWJkYyMOVz4Y4N2YIcWf',
              '_blank',
            )

            return
          }

          await dispatch.locale.updateLocale(value)
          window.location.reload()
        }}
      >
        <ControlBarIconWrapper className={className}>
          <Icon icon="Translate" size={14} color="white" />
        </ControlBarIconWrapper>
      </Dropdown>
    </RequirePluginEnabled>
  )
}

/**
 * @file 注册通用群组消息
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

import { AttendanceTypes } from './message-type'

registerDomainMessage({
  type: MessageDomainEnum.AdminClass,
  getLabel: () => $t('行政班'),
  labelStyle: calculateLabelStyle('#E7F9ED', '#3E8B57'),
  messages: [
    `${MessageDomainEnum.AdminClass}_${AttendanceTypes.Student}`,
    `${MessageDomainEnum.AdminClass}_${AttendanceTypes.Guardian}`,
    `${MessageDomainEnum.AdminClass}_${AttendanceTypes.Teacher}`,
    'admin_class.member_added',
    'admin_class.member_removed',
    'admin_class.member_switched',
  ],
})

export interface WechatInterface {
  userId: number
  openid: string
  isWework: boolean
  /**
   * 有部分数据没有 nickname
   */
  nickname?: string | null
  wechatInfo?: object
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Wechat implements WechatInterface {
  userId: number
  openid: string
  isWework: boolean
  /**
   * 有部分数据没有 nickname
   */
  nickname?: string | null
  wechatInfo?: object
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: WechatInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      userId: _userId,
      openid: _openid,
      isWework: _isWework,
      nickname: _nickname,
      wechatInfo: _wechatInfo,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.userId = _userId
    this.openid = _openid
    this.isWework = _isWework
    this.nickname = _nickname
    this.wechatInfo = _wechatInfo
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'userId',
    'openid',
    'isWework',
    'nickname',
    'wechatInfo',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

import { SecuredGuardian as Base } from '../secured-guardian'

import {
  getReflectionAdminClassesLabels,
  getReflectionGenderLabel,
  getReflectionGraduatesInAndGradeLabel,
} from './secured-reflection'

export type { SecuredGuardianInterface } from '../secured-guardian'

/**
 * 家长
 */
export class SecuredGuardian extends Base {
  /**
   * 带年级的行政班列表
   *
   * @returns 行政班列表
   */
  get adminClassesLabels() {
    return getReflectionAdminClassesLabels(this)
  }

  /**
   * 带年级的届别
   *
   * @returns 届别
   */
  get graduatesInAndGradeLabel() {
    return getReflectionGraduatesInAndGradeLabel(this)
  }

  /**
   * 性别 label
   *
   * @returns 性别 label
   */
  get genderLabel() {
    return getReflectionGenderLabel(this)
  }
}

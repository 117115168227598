/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { AdminClass, AdminClassInterface } from './extends/admin-class'
import type { QueryAdminClassesQuery } from './query-admin-classes-query'

const buildConfig = createConfigBuilder('vnas')

export const adminClassApi$queryAdminClasses = {
  config(
    queryAdminClassesQuery?: QueryAdminClassesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/evaluation/admin-classes',
      {},
      queryAdminClassesQuery,
    )
  },

  /**
   * @summary Class QueryAdminClassesApi
   * @param [queryAdminClassesQuery]
   * @param [adminClassQuery.expand] expand
   * teachers 班主任的用户信息
   * place 空间信息
   * students 所有学生
   * students.reflection 学生的用户信息
   * classes 关联的课程班信息
   * classes.class 课程班详细信息
   * grade grade年级term信息和grade_department学部term信息
   * graduates_in 届别term信息
   * @param [adminClassQuery.sort]
   * @param [adminClassQuery.paginated] 是否分页，默认 1
   * @param [adminClassQuery.page] 显示的数据页，默认 1
   * @param [adminClassQuery.perPage] 每页显示数量，默认 20
   * @param [adminClassQuery.idIn]
   * @param [adminClassQuery.nameLike]
   * @param [adminClassQuery.nameIn]
   * @param [adminClassQuery.graduatesInIdIn]
   * @param [adminClassQuery.placeIdIn]
   * @param [adminClassQuery.place.nameLike]
   * @param [adminClassQuery.place.nameIn]
   * @param [adminClassQuery.place.buildingIdIn]
   * @param [adminClassQuery.place.floor]
   * @param [adminClassQuery.place.type]
   * @param [adminClassQuery.placeIdIsEmpty]
   * @param [adminClassQuery.studentNumsEgt]
   * @param [adminClassQuery.studentNumsElt]
   * @param [adminClassQuery.teachers.nameLike]
   * @param [adminClassQuery.teachers.nameIn]
   * @param [adminClassQuery.teachers.id]
   * @param [adminClassQuery.teachers.idIn]
   * @param [adminClassQuery.teachers.idNeq]
   * @param [adminClassQuery.teachers.idNotIn]
   * @param [adminClassQuery.status]
   * @param [adminClassQuery.gradeIdIn]
   * @param [adminClassQuery.graderParentIdIn]
   * @param [adminClassQuery.option]
   * @param [adminClassQuery.withTrashed] 查询包含已软删除的数据
   * @param [options]
   * @return AxiosResponsePromise<AdminClass[]>
   */

  api<const TQuery extends QueryAdminClassesQuery>(
    queryAdminClassesQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<AdminClass, TQuery['expand']>[]> {
    return axios(
      adminClassApi$queryAdminClasses.config(queryAdminClassesQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new AdminClass(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryAdminClassesQuery,
    TSelected = Expand<AdminClass, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<AdminClass, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<AdminClass, TQuery['expand']>[], TSelected>(
      adminClassApi$queryAdminClasses.api,
      'AdminClass.queryAdminClasses',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
adminClassApi$queryAdminClasses.api._name_ = `AdminClass.queryAdminClasses`

import { TodoStatusEnum } from './todo-status-enum'
import { Reflection, ReflectionInterface } from './reflection'

export interface TodoInterface {
  /**
   * 学校 Id
   */
  schoolId: number
  domain: string
  type: string
  bizId: string
  /**
   * 标题
   */
  title: string
  /**
   * 状态
   */
  status: TodoStatusEnum
  /**
   * 是否已读
   */
  read: boolean
  /**
   * 过期时间
   */
  expiredAt?: string | null
  /**
   * 执行者Id
   */
  executorId: number
  /**
   * 失效原因
   */
  reason: string
  /**
   * 是否置顶
   */
  isTop: boolean
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  /**
   * 执行者 expanded
   */
  executor?: ReflectionInterface
  /**
   * related 待办关联的资源，例如约谈 expanded
   */
  related?: object | null
}

export class Todo implements TodoInterface {
  /**
   * 学校 Id
   */
  schoolId: number
  domain: string
  type: string
  bizId: string
  /**
   * 标题
   */
  title: string
  /**
   * 状态
   */
  status: TodoStatusEnum
  /**
   * 是否已读
   */
  read: boolean
  /**
   * 过期时间
   */
  expiredAt?: string | null
  /**
   * 执行者Id
   */
  executorId: number
  /**
   * 失效原因
   */
  reason: string
  /**
   * 是否置顶
   */
  isTop: boolean
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
  /**
   * 执行者 expanded
   */
  executor?: Reflection
  /**
   * related 待办关联的资源，例如约谈 expanded
   */
  related?: object | null

  constructor(props: TodoInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      domain: _domain,
      type: _type,
      bizId: _bizId,
      title: _title,
      status: _status,
      read: _read,
      expiredAt: _expiredAt,
      executorId: _executorId,
      reason: _reason,
      isTop: _isTop,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      executor: _executor,
      related: _related,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.domain = _domain
    this.type = _type
    this.bizId = _bizId
    this.title = _title
    this.status = _status
    this.read = _read
    this.expiredAt = _expiredAt
    this.executorId = _executorId
    this.reason = _reason
    this.isTop = _isTop
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
    this.executor = _executor ? new Reflection(_executor) : _executor
    this.related = _related
  }

  static propKeys = [
    'schoolId',
    'domain',
    'type',
    'bizId',
    'title',
    'status',
    'read',
    'expiredAt',
    'executorId',
    'reason',
    'isTop',
    'id',
    'createdAt',
    'updatedAt',
    'executor',
    'related',
  ]
}

import { QuestionSetting, QuestionSettingInterface } from './question-setting'
import {
  SatisfactionLevelSetting,
  SatisfactionLevelSettingInterface,
} from './satisfaction-level-setting'
import { SendSetting, SendSettingInterface } from './send-setting'
import { SubjectSetting, SubjectSettingInterface } from './subject-setting'

export interface SatisfactionInterface {
  /**
   * 评教ID
   */
  evaluationId: number
  /**
   * 题目分组设置
   */
  questionSetting?: QuestionSettingInterface[]
  /**
   * 等级设置
   */
  levelSetting?: SatisfactionLevelSettingInterface[]
  /**
   * 发送人设置
   */
  sendSetting?: SendSettingInterface[]
  /**
   * 学科设置
   */
  subjectSetting?: SubjectSettingInterface[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
}

export class Satisfaction implements SatisfactionInterface {
  /**
   * 评教ID
   */
  evaluationId: number
  /**
   * 题目分组设置
   */
  questionSetting?: QuestionSetting[]
  /**
   * 等级设置
   */
  levelSetting?: SatisfactionLevelSetting[]
  /**
   * 发送人设置
   */
  sendSetting?: SendSetting[]
  /**
   * 学科设置
   */
  subjectSetting?: SubjectSetting[]
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: SatisfactionInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      evaluationId: _evaluationId,
      questionSetting: _questionSetting,
      levelSetting: _levelSetting,
      sendSetting: _sendSetting,
      subjectSetting: _subjectSetting,
      id: _id,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.evaluationId = _evaluationId
    this.questionSetting = _questionSetting
      ? _questionSetting.map(i => new QuestionSetting(i))
      : _questionSetting
    this.levelSetting = _levelSetting
      ? _levelSetting.map(i => new SatisfactionLevelSetting(i))
      : _levelSetting
    this.sendSetting = _sendSetting
      ? _sendSetting.map(i => new SendSetting(i))
      : _sendSetting
    this.subjectSetting = _subjectSetting
      ? _subjectSetting.map(i => new SubjectSetting(i))
      : _subjectSetting
    this.id = _id
    this.schoolId = _schoolId
  }

  static propKeys = [
    'evaluationId',
    'questionSetting',
    'levelSetting',
    'sendSetting',
    'subjectSetting',
    'id',
    'schoolId',
  ]
}

import { Item, ItemInterface } from './extends/item'
import { Grade, GradeInterface } from './extends/grade'
import {
  StatsGradeRadarChart,
  StatsGradeRadarChartInterface,
} from './stats-grade-radar-chart'
import { MemberTeam, MemberTeamInterface } from './member-team'
import { AssessmentStage, AssessmentStageInterface } from './assessment-stage'
import { AssessmentTypeEnum } from './assessment-type-enum'
import { AssessmentScopeTypeEnum } from './assessment-scope-type-enum'
import { Scope, ScopeInterface } from './scope'
import { AssessmentStatusEnum } from './assessment-status-enum'
import { SettingLockStatusEnum } from './setting-lock-status-enum'
import { ScoreLockStatusEnum } from './score-lock-status-enum'
import { Plan, PlanInterface } from './extends/plan'
import { Class, ClassInterface } from './extends/class'
import { Course, CourseInterface } from './course'
import {
  AssessmentRelatedData,
  AssessmentRelatedDataInterface,
} from './assessment-related-data'
import { Member, MemberInterface } from './member'
import {
  AssessmentAfterthoughtData,
  AssessmentAfterthoughtDataInterface,
} from './assessment-afterthought-data'
import {
  AssessmentSetting,
  AssessmentSettingInterface,
} from './assessment-setting'
import { EvaluateTargetEnum } from './evaluate-target-enum'
import { ComputeRule, ComputeRuleInterface } from './compute-rule'

export interface AssessmentInterface {
  /**
   * expanded
   */
  items?: ItemInterface[]
  /**
   * 成绩
   * expanded
   */
  grade?: GradeInterface | null
  /**
   * 雷达图
   * expanded
   */
  radar?: StatsGradeRadarChartInterface[]
  /**
   * expanded 当前登录人是此评价的打分人
   */
  meIsEvaluator?: boolean
  /**
   * expand
   */
  teams?: MemberTeamInterface[]
  /**
   * 学段关系 expanded
   */
  assessmentStages?: AssessmentStageInterface[]
  /**
   * expand scope_reflection_num
   * 被评人数 - 宿舍评价app端使用
   * 需expand scope
   */
  scopeReflectionNum?: number
  /**
   * 学期 id ，当 type=class（成绩评价）时必有
   */
  semesterId?: number | null
  type: AssessmentTypeEnum
  name: string
  description: string
  isTemplate: boolean
  /**
   * 宿舍评价和德育评价用，表示是否开启个性化评价
   */
  individuation: boolean
  /**
   * 评价标准 id
   */
  planId?: number | null
  /**
   * 当为成绩评价时，scope_id 为 class_id ，必有
   */
  scopeId?: number | null
  scopeType: AssessmentScopeTypeEnum
  scope?: ScopeInterface | null
  status: AssessmentStatusEnum
  /**
   * 设置锁定状态
   */
  settingLockStatus: SettingLockStatusEnum
  /**
   * 分数锁定状态
   */
  scoreLockStatus: ScoreLockStatusEnum
  /**
   * 满分
   */
  fullScore: string
  rejectedReason: string
  publishedAt?: string | null
  publishedBy?: number | null
  /**
   * 评价项数量
   */
  itemNum: number
  /**
   * 评价项设置总分
   */
  totalItemScore: string
  plan?: PlanInterface | null
  /**
   * expanded 关联班级，当 type=class（成绩评价）时必有
   */
  klass?: ClassInterface | null
  /**
   * expanded
   */
  course?: CourseInterface | null
  relatedData?: AssessmentRelatedDataInterface
  isArchived: boolean
  archivedAt?: string | null
  /**
   * 参与评价的范围数量，人数/宿舍数，目前仅宿舍评价可用
   */
  scopeNum: number
  evaluators?: MemberInterface[]
  /**
   * 被评价主体数量，德育评价时 expand 可以返回
   */
  memberEvaluatedCount?: number
  /**
   * 评价范围（被评价主体业务 ids）
   */
  memberEvaluatedRids?: number[]
  /**
   * 教师考核审核人可录入的评价项 Ids
   */
  writeableItemIdsForReviewer?: number[]
  enableAfterthought?: boolean | null
  /**
   * expanded 关联反思，当 type=afterthought（反思）时必有
   */
  afterthought?: AssessmentAfterthoughtDataInterface | null
  /**
   * 高级设置，德育评价时非空
   */
  settings?: AssessmentSettingInterface | null
  /**
   * 进档案，德育评价时非空
   */
  profileDisplay?: boolean | null
  /**
   * 评价对象，德育评价时非空
   */
  evaluateTarget?: EvaluateTargetEnum | null
  /**
   * 被锁定分数的学生 Rids，德育评价使用
   */
  lockedEvaluatedIds?: number[]
  /**
   * 计算规则 expanded
   */
  computeRule?: ComputeRuleInterface | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Assessment implements AssessmentInterface {
  /**
   * expanded
   */
  items?: Item[]
  /**
   * 成绩
   * expanded
   */
  grade?: Grade | null
  /**
   * 雷达图
   * expanded
   */
  radar?: StatsGradeRadarChart[]
  /**
   * expanded 当前登录人是此评价的打分人
   */
  meIsEvaluator?: boolean
  /**
   * expand
   */
  teams?: MemberTeam[]
  /**
   * 学段关系 expanded
   */
  assessmentStages?: AssessmentStage[]
  /**
   * expand scope_reflection_num
   * 被评人数 - 宿舍评价app端使用
   * 需expand scope
   */
  scopeReflectionNum?: number
  /**
   * 学期 id ，当 type=class（成绩评价）时必有
   */
  semesterId?: number | null
  type: AssessmentTypeEnum
  name: string
  description: string
  isTemplate: boolean
  /**
   * 宿舍评价和德育评价用，表示是否开启个性化评价
   */
  individuation: boolean
  /**
   * 评价标准 id
   */
  planId?: number | null
  /**
   * 当为成绩评价时，scope_id 为 class_id ，必有
   */
  scopeId?: number | null
  scopeType: AssessmentScopeTypeEnum
  scope?: Scope | null
  status: AssessmentStatusEnum
  /**
   * 设置锁定状态
   */
  settingLockStatus: SettingLockStatusEnum
  /**
   * 分数锁定状态
   */
  scoreLockStatus: ScoreLockStatusEnum
  /**
   * 满分
   */
  fullScore: string
  rejectedReason: string
  publishedAt?: string | null
  publishedBy?: number | null
  /**
   * 评价项数量
   */
  itemNum: number
  /**
   * 评价项设置总分
   */
  totalItemScore: string
  plan?: Plan | null
  /**
   * expanded 关联班级，当 type=class（成绩评价）时必有
   */
  klass?: Class | null
  /**
   * expanded
   */
  course?: Course | null
  relatedData?: AssessmentRelatedData
  isArchived: boolean
  archivedAt?: string | null
  /**
   * 参与评价的范围数量，人数/宿舍数，目前仅宿舍评价可用
   */
  scopeNum: number
  evaluators?: Member[]
  /**
   * 被评价主体数量，德育评价时 expand 可以返回
   */
  memberEvaluatedCount?: number
  /**
   * 评价范围（被评价主体业务 ids）
   */
  memberEvaluatedRids?: number[]
  /**
   * 教师考核审核人可录入的评价项 Ids
   */
  writeableItemIdsForReviewer?: number[]
  enableAfterthought?: boolean | null
  /**
   * expanded 关联反思，当 type=afterthought（反思）时必有
   */
  afterthought?: AssessmentAfterthoughtData | null
  /**
   * 高级设置，德育评价时非空
   */
  settings?: AssessmentSetting | null
  /**
   * 进档案，德育评价时非空
   */
  profileDisplay?: boolean | null
  /**
   * 评价对象，德育评价时非空
   */
  evaluateTarget?: EvaluateTargetEnum | null
  /**
   * 被锁定分数的学生 Rids，德育评价使用
   */
  lockedEvaluatedIds?: number[]
  /**
   * 计算规则 expanded
   */
  computeRule?: ComputeRule | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: AssessmentInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      items: _items,
      grade: _grade,
      radar: _radar,
      meIsEvaluator: _meIsEvaluator,
      teams: _teams,
      assessmentStages: _assessmentStages,
      scopeReflectionNum: _scopeReflectionNum,
      semesterId: _semesterId,
      type: _type,
      name: _name,
      description: _description,
      isTemplate: _isTemplate,
      individuation: _individuation,
      planId: _planId,
      scopeId: _scopeId,
      scopeType: _scopeType,
      scope: _scope,
      status: _status,
      settingLockStatus: _settingLockStatus,
      scoreLockStatus: _scoreLockStatus,
      fullScore: _fullScore,
      rejectedReason: _rejectedReason,
      publishedAt: _publishedAt,
      publishedBy: _publishedBy,
      itemNum: _itemNum,
      totalItemScore: _totalItemScore,
      plan: _plan,
      klass: _klass,
      course: _course,
      relatedData: _relatedData,
      isArchived: _isArchived,
      archivedAt: _archivedAt,
      scopeNum: _scopeNum,
      evaluators: _evaluators,
      memberEvaluatedCount: _memberEvaluatedCount,
      memberEvaluatedRids: _memberEvaluatedRids,
      writeableItemIdsForReviewer: _writeableItemIdsForReviewer,
      enableAfterthought: _enableAfterthought,
      afterthought: _afterthought,
      settings: _settings,
      profileDisplay: _profileDisplay,
      evaluateTarget: _evaluateTarget,
      lockedEvaluatedIds: _lockedEvaluatedIds,
      computeRule: _computeRule,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.items = _items ? _items.map(i => new Item(i)) : _items
    this.grade = _grade ? new Grade(_grade) : _grade
    this.radar = _radar ? _radar.map(i => new StatsGradeRadarChart(i)) : _radar
    this.meIsEvaluator = _meIsEvaluator
    this.teams = _teams ? _teams.map(i => new MemberTeam(i)) : _teams
    this.assessmentStages = _assessmentStages
      ? _assessmentStages.map(i => new AssessmentStage(i))
      : _assessmentStages
    this.scopeReflectionNum = _scopeReflectionNum
    this.semesterId = _semesterId
    this.type = _type
    this.name = _name
    this.description = _description
    this.isTemplate = _isTemplate
    this.individuation = _individuation
    this.planId = _planId
    this.scopeId = _scopeId
    this.scopeType = _scopeType
    this.scope = _scope ? new Scope(_scope) : _scope
    this.status = _status
    this.settingLockStatus = _settingLockStatus
    this.scoreLockStatus = _scoreLockStatus
    this.fullScore = _fullScore
    this.rejectedReason = _rejectedReason
    this.publishedAt = _publishedAt
    this.publishedBy = _publishedBy
    this.itemNum = _itemNum
    this.totalItemScore = _totalItemScore
    this.plan = _plan ? new Plan(_plan) : _plan
    this.klass = _klass ? new Class(_klass) : _klass
    this.course = _course ? new Course(_course) : _course
    this.relatedData = _relatedData
      ? new AssessmentRelatedData(_relatedData)
      : _relatedData
    this.isArchived = _isArchived
    this.archivedAt = _archivedAt
    this.scopeNum = _scopeNum
    this.evaluators = _evaluators
      ? _evaluators.map(i => new Member(i))
      : _evaluators
    this.memberEvaluatedCount = _memberEvaluatedCount
    this.memberEvaluatedRids = _memberEvaluatedRids
    this.writeableItemIdsForReviewer = _writeableItemIdsForReviewer
    this.enableAfterthought = _enableAfterthought
    this.afterthought = _afterthought
      ? new AssessmentAfterthoughtData(_afterthought)
      : _afterthought
    this.settings = _settings ? new AssessmentSetting(_settings) : _settings
    this.profileDisplay = _profileDisplay
    this.evaluateTarget = _evaluateTarget
    this.lockedEvaluatedIds = _lockedEvaluatedIds
    this.computeRule = _computeRule
      ? new ComputeRule(_computeRule)
      : _computeRule
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'items',
    'grade',
    'radar',
    'meIsEvaluator',
    'teams',
    'assessmentStages',
    'scopeReflectionNum',
    'semesterId',
    'type',
    'name',
    'description',
    'isTemplate',
    'individuation',
    'planId',
    'scopeId',
    'scopeType',
    'scope',
    'status',
    'settingLockStatus',
    'scoreLockStatus',
    'fullScore',
    'rejectedReason',
    'publishedAt',
    'publishedBy',
    'itemNum',
    'totalItemScore',
    'plan',
    'klass',
    'course',
    'relatedData',
    'isArchived',
    'archivedAt',
    'scopeNum',
    'evaluators',
    'memberEvaluatedCount',
    'memberEvaluatedRids',
    'writeableItemIdsForReviewer',
    'enableAfterthought',
    'afterthought',
    'settings',
    'profileDisplay',
    'evaluateTarget',
    'lockedEvaluatedIds',
    'computeRule',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}

export interface FlowTagInterface {
  /**
   * 标签 ID
   */
  tagId: number
  /**
   * 标签名
   */
  name: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 审批流 ID
   */
  workflowId: number
  /**
   * 学校插件 ID
   */
  schoolPluginId: number
  /**
   * 审批单 ID
   */
  flowId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class FlowTag implements FlowTagInterface {
  /**
   * 标签 ID
   */
  tagId: number
  /**
   * 标签名
   */
  name: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 审批流 ID
   */
  workflowId: number
  /**
   * 学校插件 ID
   */
  schoolPluginId: number
  /**
   * 审批单 ID
   */
  flowId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: FlowTagInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      tagId: _tagId,
      name: _name,
      id: _id,
      schoolId: _schoolId,
      workflowId: _workflowId,
      schoolPluginId: _schoolPluginId,
      flowId: _flowId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.tagId = _tagId
    this.name = _name
    this.id = _id
    this.schoolId = _schoolId
    this.workflowId = _workflowId
    this.schoolPluginId = _schoolPluginId
    this.flowId = _flowId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'tagId',
    'name',
    'id',
    'schoolId',
    'workflowId',
    'schoolPluginId',
    'flowId',
    'createdAt',
    'updatedAt',
  ]
}

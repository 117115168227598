export interface QuestionSettingInterface {
  /**
   * 题目区间 - 值为1-10 意为第一题～第十题
   */
  number?: string
  /**
   * 题目组名称
   */
  name?: string
}

export class QuestionSetting implements QuestionSettingInterface {
  /**
   * 题目区间 - 值为1-10 意为第一题～第十题
   */
  number?: string
  /**
   * 题目组名称
   */
  name?: string

  constructor(props: QuestionSettingInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      number: _number,
      name: _name,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.number = _number
    this.name = _name
  }

  static propKeys = ['number', 'name']
}

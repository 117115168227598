import { appendURLQuery } from '@seiue/util'

import { registerMessageNavigator } from 'packages/features/messages/utils'
import { RoleEnum } from 'packages/sdks-next/chalk'
import { Assessment } from 'packages/sdks-next/vnas'

registerMessageNavigator(
  [
    'class_assessment.assessment_published_for_teacher',
    'class_assessment.assessment_recalled_for_teacher',
    'class_assessment.assessment_rejected_for_teacher',
  ],
  (msg, currentReflection) => {
    // 家长查看时暂时禁用跳转，因为跳转页面需要单独适配
    if (currentReflection.role === RoleEnum.Guardian) return null

    const scopeId = (msg.attributes as Assessment)?.scopeId

    if (!scopeId) return null

    return {
      path: `/classes/${scopeId}/grades`,
    }
  },
)

registerMessageNavigator(
  ['class_assessment.grade_updated_for_teacher'],
  (msg, currentReflection) => {
    // 家长查看时暂时禁用跳转，因为跳转页面需要单独适配
    if (currentReflection.role === RoleEnum.Guardian) return null

    const scopeId = (msg.attributes as any)?.assessment?.scopeId

    if (!scopeId) return null

    return {
      path: `/classes/${scopeId}/grades`,
    }
  },
)

registerMessageNavigator(
  [
    'class_assessment.raw_score_addition_for_teacher',
    'class_assessment.raw_score_tag_for_teacher',
    'class_assessment.raw_score_special_for_teacher',
    'class_assessment.score_for_teacher',
    'class_assessment.item_operated_for_teacher',
  ],
  (msg, currentReflection) => {
    // 家长查看时暂时禁用跳转，因为跳转页面需要单独适配
    if (currentReflection.role === RoleEnum.Guardian) return null

    const { attributes } = msg as any

    return {
      path: `/classes/${attributes?.scopeId}/grades/entry?itemId=${attributes.itemId}`,
    }
  },
)

registerMessageNavigator(
  [
    'class_assessment.grade_delayed_for_student',
    'class_assessment.grade_published_for_student',
    'class_assessment.grade_revoked_for_student',
    'class_assessment.grade_updated_for_student',
    'class_assessment.grade_updated_for_teacher',
    'class_assessment.stage_grade_updated_for_student',
    'class_assessment.stage_grade_delayed_for_student',
    'class_assessment.stage_grade_revoked_for_student',
    'class_assessment.stage_grade_published_for_student',
  ],
  (msg, currentReflection) => {
    // 家长查看时暂时禁用跳转，因为跳转页面需要单独适配
    if (currentReflection.role === RoleEnum.Guardian) return null

    const { scopeId, stageId } =
      (msg.attributes as { scopeId: number; stageId?: number }) ?? {}

    if (!scopeId) return null
    return {
      path: appendURLQuery(`/classes/${scopeId}/student-grades`, {
        stageId,
        tab: stageId ? 'stage' : 'assessment',
      }),
    }
  },
)

registerMessageNavigator(
  [
    'class_assessment.raw_score_addition_for_student',
    'class_assessment.raw_score_tag_for_student',
    'class_assessment.raw_score_special_for_student',
    'class_assessment.score_for_student',
    'class_assessment.item_operated_for_student',
  ],
  (msg, currentReflection) => {
    // 家长查看时暂时禁用跳转，因为跳转页面需要单独适配
    if (currentReflection.role === RoleEnum.Guardian) return null

    const { attributes } = msg as any
    return {
      path: appendURLQuery(`/classes/${attributes?.scopeId}/student-grades`, {
        tab: 'assessment',
        itemId: attributes?.itemId,
      }),
    }
  },
)

import {
  CalendarInitiator,
  CalendarInitiatorInterface,
} from './calendar-initiator'
import { CalendarOwner, CalendarOwnerInterface } from './calendar-owner'

export interface CalendarEventInterface {
  /**
   * 事件类型
   */
  type: string
  /**
   * 事件唯一标识
   */
  remark?: string | null
  /**
   * 事件开始时间
   */
  startTime: string
  /**
   * 事件结束时间
   */
  endTime: string
  /**
   * 事件标题
   */
  title: string
  /**
   * 事件发起人 IDs
   */
  initiatorIds: number[]
  /**
   * 事件发起人
   */
  initiators?: CalendarInitiatorInterface[]
  /**
   * 事件地址, 如果 place_id 非 null，address 取值为 place.name, 否则有业务自定义
   */
  address?: string
  /**
   * 事件地址 place_id
   */
  placeId: number | null
  /**
   * 日程拥有者 ID ，r 开头是用户，p 开头是空间，后面数字代表 ID
   */
  ownerId?: string | null
  /**
   * 日程拥有者
   */
  owner?: CalendarOwnerInterface | null
}

export class CalendarEvent implements CalendarEventInterface {
  /**
   * 事件类型
   */
  type: string
  /**
   * 事件唯一标识
   */
  remark?: string | null
  /**
   * 事件开始时间
   */
  startTime: string
  /**
   * 事件结束时间
   */
  endTime: string
  /**
   * 事件标题
   */
  title: string
  /**
   * 事件发起人 IDs
   */
  initiatorIds: number[]
  /**
   * 事件发起人
   */
  initiators?: CalendarInitiator[]
  /**
   * 事件地址, 如果 place_id 非 null，address 取值为 place.name, 否则有业务自定义
   */
  address?: string
  /**
   * 事件地址 place_id
   */
  placeId: number | null
  /**
   * 日程拥有者 ID ，r 开头是用户，p 开头是空间，后面数字代表 ID
   */
  ownerId?: string | null
  /**
   * 日程拥有者
   */
  owner?: CalendarOwner | null

  constructor(props: CalendarEventInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      type: _type,
      remark: _remark,
      startTime: _startTime,
      endTime: _endTime,
      title: _title,
      initiatorIds: _initiatorIds,
      initiators: _initiators,
      address: _address,
      placeId: _placeId,
      ownerId: _ownerId,
      owner: _owner,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.type = _type
    this.remark = _remark
    this.startTime = _startTime
    this.endTime = _endTime
    this.title = _title
    this.initiatorIds = _initiatorIds
    this.initiators = _initiators
      ? _initiators.map(i => new CalendarInitiator(i))
      : _initiators
    this.address = _address
    this.placeId = _placeId
    this.ownerId = _ownerId
    this.owner = _owner ? new CalendarOwner(_owner) : _owner
  }

  static propKeys = [
    'type',
    'remark',
    'startTime',
    'endTime',
    'title',
    'initiatorIds',
    'initiators',
    'address',
    'placeId',
    'ownerId',
    'owner',
  ]
}

/**
 * @file Check whether the plugin is enabled
 */

import React from 'react'

import {
  ModuleEnum,
  PluginEnum,
  useHasEnabledParentPlugin,
} from 'packages/feature-utils/plugins'

export const RequirePluginEnabled: React.FC<{
  /**
   * 插件名称，可以是内置模块或插件
   */
  name: ModuleEnum | PluginEnum

  /**
   * 占位组件
   */
  placeholder?: React.ReactNode
}> = ({ name, placeholder, children }) => {
  const pluginEnabled = useHasEnabledParentPlugin(name)
  return <>{!pluginEnabled ? placeholder || null : children}</>
}

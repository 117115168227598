export interface CertificationLogResourceInterface {
  type?: string
  id?: number
  oldPassedAt?: string | null
  oldStatus?: string | null
  newStatus?: string
  adminReason?: string
}

export class CertificationLogResource
  implements CertificationLogResourceInterface
{
  type?: string
  id?: number
  oldPassedAt?: string | null
  oldStatus?: string | null
  newStatus?: string
  adminReason?: string

  constructor(props: CertificationLogResourceInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      type: _type,
      id: _id,
      oldPassedAt: _oldPassedAt,
      oldStatus: _oldStatus,
      newStatus: _newStatus,
      adminReason: _adminReason,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.type = _type
    this.id = _id
    this.oldPassedAt = _oldPassedAt
    this.oldStatus = _oldStatus
    this.newStatus = _newStatus
    this.adminReason = _adminReason
  }

  static propKeys = [
    'type',
    'id',
    'oldPassedAt',
    'oldStatus',
    'newStatus',
    'adminReason',
  ]
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { BizLog, BizLogInterface } from './biz-log'
import type { QueryTeacherAssessmentScoreLogsQuery } from './query-teacher-assessment-score-logs-query'

const buildConfig = createConfigBuilder('vnas')

export const teacherAssessmentApi$queryTeacherAssessmentScoreLogs = {
  config(
    itemId: number,
    queryTeacherAssessmentScoreLogsQuery?: QueryTeacherAssessmentScoreLogsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/teacher_assessment/scores/logs',
      {
        itemId,
      },
      queryTeacherAssessmentScoreLogsQuery,
    )
  },

  /**
   * @summary 教师考核 - 需审核的评价项中，管理员和审核人员修改分数的日志
   * @param itemId 分数评价项 ID
   * @param [queryTeacherAssessmentScoreLogsQuery]
   * @param [teacherAssessmentQuery.ownerId] 分数所属人 ID
   * @param [teacherAssessmentQuery.policy] 支持 admin 和 evaluated 两种策略
   * @param [teacherAssessmentQuery.teacherAssessmentAsRole] 教师考核 - 当前用户角色，policy=admin 时需要，不传默认插件管理员
   * @param [teacherAssessmentQuery.paginated] 是否分页，默认 1
   * @param [teacherAssessmentQuery.page] 显示的数据页，默认 1
   * @param [teacherAssessmentQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<BizLog[]>
   */

  api(
    itemId: number,
    queryTeacherAssessmentScoreLogsQuery?: QueryTeacherAssessmentScoreLogsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<BizLog[]> {
    return axios(
      teacherAssessmentApi$queryTeacherAssessmentScoreLogs.config(
        itemId,
        queryTeacherAssessmentScoreLogsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new BizLog(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = BizLog[]>(
    {
      itemId,
      query,
    }: {
      itemId: number
      query?: QueryTeacherAssessmentScoreLogsQuery
    },
    queryOptions?: QueryOptionsWithSelect<BizLog[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<BizLog[], TSelected>(
      teacherAssessmentApi$queryTeacherAssessmentScoreLogs.api,
      'TeacherAssessment.queryTeacherAssessmentScoreLogs',
      queryOptions,
      itemId,
      query,
    )
  },
}

// @ts-ignore
teacherAssessmentApi$queryTeacherAssessmentScoreLogs.api._name_ = `TeacherAssessment.queryTeacherAssessmentScoreLogs`

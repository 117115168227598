import { getRandomId } from '@seiue/util'
import { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

/**
 * 注册脏检查标志
 *
 * @param isDirty - 是否脏
 */
export const usePageDirtyFlag = (isDirty: boolean) => {
  const dispatch: any = useDispatch()
  const { current: id } = useRef(getRandomId())

  const location = useLocation()
  const locationKey = location.key || ''

  useEffect(() => {
    dispatch.pageDirtyFlags.setFlag({ locationKey, id, isDirty })
  }, [dispatch.pageDirtyFlags, id, isDirty, locationKey])

  const { current: removeChecker } = useRef(() => {
    dispatch.pageDirtyFlags.removeFlag({ locationKey, id })
  })

  // 组件卸载时自动清除
  useEffect(() => removeChecker, [removeChecker])
}

import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import {
  GroupTeamWithoutMembers,
  GroupTeamWithoutMembersInterface,
} from './group-team-without-members'
import {
  GroupWithoutMembers,
  GroupWithoutMembersInterface,
} from './group-without-members'
import {
  ClassMemberTypeInfo,
  ClassMemberTypeInfoInterface,
} from './class-member-type-info'

export interface GroupMemberInterface {
  /**
   * 群组 id
   */
  groupId: number
  /**
   * expanded
   */
  reflection?: SecuredReflectionInterface | null
  /**
   * 是否置顶
   */
  top?: boolean
  /**
   * 是否已分组
   */
  isInTeam?: boolean
  /**
   * 所属小组
   */
  team?: GroupTeamWithoutMembersInterface | null
  /**
   * 所属小组（多个）
   */
  teams?: GroupTeamWithoutMembersInterface[]
  /**
   * 所属群组
   */
  group?: GroupWithoutMembersInterface
  /**
   * 所关联课程班的成员类型信息
   */
  memberSetting?: ClassMemberTypeInfoInterface | null
  /**
   * 状态
   *  - 标示成员状态，不同的群组类型下可能有不同的含义，如 relationGroup中 archived状态代表成员来源的群组被归档，成员也随之变为归档状态
   */
  status?: string
  /**
   * 成员 rid
   */
  memberId: number
  /**
   * 成员类型，如管理员等
   */
  memberType: string
  /**
   * 主键 ID
   */
  id: number
}

export class GroupMember implements GroupMemberInterface {
  /**
   * 群组 id
   */
  groupId: number
  /**
   * expanded
   */
  reflection?: SecuredReflection | null
  /**
   * 是否置顶
   */
  top?: boolean
  /**
   * 是否已分组
   */
  isInTeam?: boolean
  /**
   * 所属小组
   */
  team?: GroupTeamWithoutMembers | null
  /**
   * 所属小组（多个）
   */
  teams?: GroupTeamWithoutMembers[]
  /**
   * 所属群组
   */
  group?: GroupWithoutMembers
  /**
   * 所关联课程班的成员类型信息
   */
  memberSetting?: ClassMemberTypeInfo | null
  /**
   * 状态
   *  - 标示成员状态，不同的群组类型下可能有不同的含义，如 relationGroup中 archived状态代表成员来源的群组被归档，成员也随之变为归档状态
   */
  status?: string
  /**
   * 成员 rid
   */
  memberId: number
  /**
   * 成员类型，如管理员等
   */
  memberType: string
  /**
   * 主键 ID
   */
  id: number

  constructor(props: GroupMemberInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      groupId: _groupId,
      reflection: _reflection,
      top: _top,
      isInTeam: _isInTeam,
      team: _team,
      teams: _teams,
      group: _group,
      memberSetting: _memberSetting,
      status: _status,
      memberId: _memberId,
      memberType: _memberType,
      id: _id,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.groupId = _groupId
    this.reflection = _reflection
      ? new SecuredReflection(_reflection)
      : _reflection
    this.top = _top
    this.isInTeam = _isInTeam
    this.team = _team ? new GroupTeamWithoutMembers(_team) : _team
    this.teams = _teams
      ? _teams.map(i => new GroupTeamWithoutMembers(i))
      : _teams
    this.group = _group ? new GroupWithoutMembers(_group) : _group
    this.memberSetting = _memberSetting
      ? new ClassMemberTypeInfo(_memberSetting)
      : _memberSetting
    this.status = _status
    this.memberId = _memberId
    this.memberType = _memberType
    this.id = _id
  }

  static propKeys = [
    'groupId',
    'reflection',
    'top',
    'isInTeam',
    'team',
    'teams',
    'group',
    'memberSetting',
    'status',
    'memberId',
    'memberType',
    'id',
  ]
}

/**
 * @file ACM 字段组及相关设置 Enum，主要和后端约定
 */

/**
 * 与后端约定，@hujin
 */
export enum ServiceEnum {
  Chalk = 'seiue.chalk',
  SGMS = 'seiue.sgms',
  SCMS = 'seiue.scms',
  VNAS = 'seiue.vnas',
  Form = 'seiue.form',
  Nuwa = 'seiue.nuwa',
  AIS = 'seiue.ais',
  EEMS = 'seiue.eems',
}

/**
 * 与后端约定，@hujin
 */
export enum SchoolConfigGroupNameEnum {
  Global = 'system.global',
  Login = 'system.login',
  Theme = 'system.theme',
  UserMenu = 'system.user_menu',
  HomeworkTask = 'homework_task',
  ElectionSettings = 'election.settings',
  GlobalSearch = 'search.settings',
  ClassSettings = 'class.settings',
  ClassAssessment = 'class_assessment',
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { School, SchoolInterface } from './school'
import type { QuerySchoolsQuery } from './query-schools-query'

const buildConfig = createConfigBuilder('chalk')

export const systemApi$querySchools = {
  config(
    querySchoolsQuery?: QuerySchoolsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(options, 'get', '/system/schools', {}, querySchoolsQuery)
  },

  /**
   * @summary 查询学校列表
   * @param [querySchoolsQuery]
   * @param [systemQuery.id] 学校 ID 列表
   * @param [systemQuery.idIn]
   * @param [systemQuery.identity] 学校 identity 列表
   * @param [systemQuery.identityIn]
   * @param [systemQuery.identityLike]
   * @param [systemQuery.pinyin] 学校拼音模糊匹配
   * @param [systemQuery.pinyinLike]
   * @param [systemQuery.pinyinIn]
   * @param [systemQuery.name] 学校名称模糊匹配
   * @param [systemQuery.nameLike]
   * @param [systemQuery.nameIn]
   * @param [systemQuery.hidden] hidden 字段查询，默认 0 查询非隐藏的学校
   * @param [systemQuery.hiddenIn]
   * @param [systemQuery.type] 学校类型
   * @param [systemQuery.typeIn]
   * @param [systemQuery.env] 环境
   * @param [systemQuery.envIn]
   * @param [systemQuery.status] 学校状态
   * @param [systemQuery.statusIn]
   * @param [systemQuery.parentId] 某集团的所有分校
   * @param [systemQuery.parentIdIn]
   * @param [systemQuery.paginated] 是否分页，默认 1
   * @param [systemQuery.page] 显示的数据页，默认 1
   * @param [systemQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<School[]>
   */

  api(
    querySchoolsQuery?: QuerySchoolsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<School[]> {
    return axios(systemApi$querySchools.config(querySchoolsQuery, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new School(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = School[]>(
    query?: QuerySchoolsQuery,
    queryOptions?: QueryOptionsWithSelect<School[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<School[], TSelected>(
      systemApi$querySchools.api,
      'System.querySchools',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
systemApi$querySchools.api._name_ = `System.querySchools`
